import isEmpty from 'lodash/isEmpty';
import NCSelectedLineItem from 'rapidfab/components/records/run/NCSelectedLineItem';
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import {
  Button,
  Modal,
  OverlayTrigger,
  Tooltip,
} from 'react-bootstrap';
import _find from 'lodash/find';
import { FormattedMessage } from 'react-intl';
import { runResourceType } from 'rapidfab/types';
import NCSelectedPiece from './NCSelectedPiece';
import SplitConfirmationModal from './split_confirmation_modal/SplitConfirmationModal';

const NCReviewModalContainer = ({
  selectedPiecesList,
  selectedPrints,
  postProcessorsByUri,
  run,
  onClose,
  ncreviewSubmitCallback,
  selectedLineItemsData,
  isAllPrintsSelected,
}) => {
  const [showSplitConfirmationModal, setShowSplitConfirmationModal] = useState(false);

  const handleSubmit = () => {
    if (!isAllPrintsSelected) {
      setShowSplitConfirmationModal(true);
    } else {
      ncreviewSubmitCallback(false);
    }
  };

  const shouldModalSubmitBeDisabled = () => {
    // check if 'non_conformance_review'(post processor)
    // exists at the run's location.
    const ppOnSameLocation = _find(postProcessorsByUri, postProcessor => {
      const { location, post_processor_special_type } = postProcessor;
      if (location === run?.location && post_processor_special_type === 'non_conformance_review') {
        return postProcessor;
      }
      return null;
    });
    // NC Review Workstation Location == Current run Workstation Location;
    // if TRUE, then submit button enabled,
    // if FALSE, then submit button disabled + hover over text.
    return !(ppOnSameLocation && Object.keys(ppOnSameLocation).length !== 0);
  };

  const isSubmitDisabled = shouldModalSubmitBeDisabled();
  return (
    <>
      <Modal show onHide={onClose} backdrop="static">
        <Modal.Header closeButton>
          <b>
            <FormattedMessage id="nc.sendToNcReport" defaultMessage="Send to Non-conformance Review" />
          </b>
        </Modal.Header>
        <Modal.Body>

          <div className="d-flex">
            <div className="col-md-6">
              <span>
                <FormattedMessage id="nc.details1" defaultMessage="Sending to the " />
                <span style={{ backgroundColor: '#e7e7e7' }}>
                  <b>
                    <FormattedMessage id="nc.ncReviewWorkStation" defaultMessage="Non-conformance Review Workstation" />
                  </b>
                </span>
              </span>
              <span>
                <FormattedMessage
                  id="nc.details2"
                  defaultMessage=" automatically inserts the Non-conformance Review step as the next process for these piece(s)’ workflows."
                />
              </span>
                  &nbsp;
                  &nbsp;
              <div>
                <FormattedMessage
                  id="nc.details3"
                  defaultMessage="The Non-conformance Review board will review and decide what to do with the pieces based on the reported Non-conformance(s). This process will be scheduled as the pieces' next manufacturing Run and will be completed at your "
                />
                <span style={{ backgroundColor: '#e7e7e7' }}>
                  <b>
                    <FormattedMessage id="nc.ncReviewWorkStation" defaultMessage="Non-conformance Review Workstation" />
                  </b>
                </span>.
              </div>
            </div>

            <div className="col-md-6">
              {isEmpty(selectedLineItemsData) ?
                <NCSelectedPiece selectedPiecesList={selectedPiecesList} /> :
                <NCSelectedLineItem selectedLineItems={selectedLineItemsData} />}
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer style={{ display: 'flex', justifyContent: 'end' }}>
          <div className="mr15">
            <Button variant="secondary" onClick={() => onClose()}>
              Cancel
            </Button>
          </div>
          <div className="mr15">
            <OverlayTrigger
              className="ml15 "
              placement="right"
              overlay={isSubmitDisabled
                ? (
                  <Tooltip>
                    <FormattedMessage
                      id="ncr.submit.disable.warning"
                      defaultMessage="No Review Workstation at this Location. Please add one"
                    />
                  </Tooltip>
                )
                : <></>}
            >
              <div>
                <Button
                  variant="success"
                  onClick={handleSubmit}
                  disabled={isSubmitDisabled}
                  style={isSubmitDisabled ? { pointerEvents: 'none' } : {}}
                >
                  Submit
                </Button>
              </div>
            </OverlayTrigger>
          </div>
        </Modal.Footer>
      </Modal>
      {showSplitConfirmationModal && (
        <SplitConfirmationModal
          selectedLineItemsData={selectedLineItemsData}
          selectedPiecesList={selectedPrints}
          handleClose={() => setShowSplitConfirmationModal(false)}
          callback={ncreviewSubmitCallback}
        />
      )}
    </>
  );
};

NCReviewModalContainer.propTypes = {
  selectedPiecesList: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  selectedPrints: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  run: runResourceType.isRequired,
  postProcessorsByUri: PropTypes.shape({}).isRequired,
  onClose: PropTypes.func.isRequired,
  ncreviewSubmitCallback: PropTypes.func.isRequired,
  selectedLineItemsData: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  isAllPrintsSelected: PropTypes.bool.isRequired,
};

export default NCReviewModalContainer;
