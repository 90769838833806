import React from 'react';
import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUser } from '@fortawesome/free-solid-svg-icons';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import { FormattedMessage } from 'rapidfab/i18n';
import { ICON_COLORS } from 'rapidfab/constants';
import { useSelector } from 'react-redux';
import { getUsersByUri } from 'rapidfab/selectors';
import BreakpointRenderer from './BreakpointRenderer';

const AssignedUserBadge = ({ assignedUser, assignedTo, breakpoint, onlyToolTip }) => {
  const users = useSelector(getUsersByUri);
  return (
    <OverlayTrigger
      placement="top"
      overlay={(
        <Tooltip>
          {
            assignedUser ? (
              <FormattedMessage
                id="assignedUser"
                defaultMessage="{user} is assigned to this {assignedTo}"
                values={{ user: users[assignedUser]?.name, assignedTo }}
              />
            ) : (
              <FormattedMessage
                id="nonAssignedUser"
                defaultMessage="There is no one assigned to this {assignedTo}"
                values={{ assignedTo }}
              />
            )
          }
        </Tooltip>
      )}
    >
      <span>
        <FontAwesomeIcon className="spacer-right" icon={faUser} style={{ color: assignedUser ? ICON_COLORS.GREEN : ICON_COLORS.GREY }} />
        {!onlyToolTip && (
          <BreakpointRenderer breakpoint={breakpoint}>
            {users[assignedUser]?.name}
          </BreakpointRenderer>
        )}
      </span>
    </OverlayTrigger>
  );
};

AssignedUserBadge.propTypes = {
  assignedUser: PropTypes.string.isRequired,
  assignedTo: PropTypes.string.isRequired,
  breakpoint: PropTypes.number.isRequired,
  onlyToolTip: PropTypes.bool,
};

AssignedUserBadge.defaultProps = {
  onlyToolTip: false,
};

export default AssignedUserBadge;
