import React from 'react';
import PropTypes from 'prop-types';

import {
  FormLabel,
  FormGroup,
  Container,
  FormText,
} from 'react-bootstrap';
import { Form } from 'react-final-form';

import { FormControlTextArea } from 'rapidfab/components/formTools';
import BreadcrumbNav from 'rapidfab/components/BreadcrumbNav';
import { FormattedMessage } from 'rapidfab/i18n';
import SaveButton from 'rapidfab/components/SaveButton';
import FormGroupField from 'rapidfab/components/forms/FormGroupField';

const Banner = ({
  onSubmit,
  submitting,
  initialValues,
}) => (
  <Container>
    <BreadcrumbNav breadcrumbs={['admin', 'banner']} />

    <div className="page-header">
      <h1>
        Bureau Banner
      </h1>
    </div>
    <Form onSubmit={onSubmit} initialValues={initialValues}>
      {({ handleSubmit, dirty }) => (
        <form onSubmit={handleSubmit}>
          <FormGroup className="form-group">
            <FormLabel>
              <FormattedMessage id="message" defaultMessage="Message" />: *
            </FormLabel>
            <FormControlTextArea type="text" name="message" initialValue={initialValues.message} required />
            <FormText>
              (To add headers please use # or ## before entering header text. To add
              links please use: [Link Text Goes Here](https://www.link.com). Links
              must start with http:// or https://.
            </FormText>
          </FormGroup>
          <FormGroupField
            name="link"
            required
            label={<FormattedMessage id="link" defaultMessage="Link" />}
          />
          <FormGroup>
            <SaveButton
              showSaveIcon={false}
              isSaving={submitting}
              label={(
                <FormattedMessage
                  id="updateBureauBanner"
                  defaultMessage="Update Bureau Banner"
                />
              )}
              bsStyle="default"
              className="m-t block"
              disabled={!dirty}
            />
          </FormGroup>
        </form>
      )}
    </Form>
  </Container>
);

Banner.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  initialValues: PropTypes.shape({
    message: PropTypes.string.isRequired,
    link: PropTypes.string.isRequired,
  }).isRequired,
  submitting: PropTypes.bool.isRequired,
};

export default Banner;
