import PropTypes from 'prop-types';
import { faQuestionCircle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import classNames from 'classnames';
import { FormattedMessage } from 'rapidfab/i18n';
import { getLineItemQuoteProcessStepsForLineItem } from 'rapidfab/selectors';
import React, { useEffect } from 'react';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import { useSelector } from 'react-redux';

const SelectWorkstepSidebar = ({ lineItemUri, workstep, setWorkstep, editMode }) => {
  const processSteps = useSelector(state => getLineItemQuoteProcessStepsForLineItem(state, lineItemUri));

  useEffect(() => {
    if (workstep || !processSteps.length) return;
    setWorkstep(processSteps[0].uri);
  }, [processSteps]);

  return (
    <>
      <p className="mb15 font-weight-500">
        <FormattedMessage
          id="line_item.workflows_steps"
          defaultMessage="Workflow Steps"
        />

        {editMode && (
          <OverlayTrigger
            placement="top"
            overlay={(
              <Tooltip>
                <FormattedMessage
                  id="line_item.workflows_steps_edit_mode_on"
                  defaultMessage="Turn off Edit Mode to switch between Workflow Steps"
                />
              </Tooltip>
            )}
          >
            <FontAwesomeIcon className="spacer-left" icon={faQuestionCircle} />
          </OverlayTrigger>
        )}
      </p>

      <div className={classNames('quoteProcessStepSelectData', editMode && 'quoteProcessStepSelectDataDisabled')}>
        {processSteps.map(({ name, uri }, index) => (
          <div
            role="button"
            tabIndex={0}
            key={uri}
            className={classNames(uri === workstep && 'quoteProcessStepSelectActive')}
            onClick={() => setWorkstep(uri)}
          >
            {
              name?.length > 30 ? (
                <OverlayTrigger
                  placement="top"
                  overlay={(
                    <Tooltip>
                      {name}
                    </Tooltip>
                  )}
                >
                  <p className="quoteProcessStepSelect">
                    {index + 1}. {name}
                  </p>
                </OverlayTrigger>
              ) : (
                <p className="quoteProcessStepSelect">
                  {index + 1}. {name}
                </p>
              )
            }
          </div>
        ))}
      </div>
    </>
  );
};

SelectWorkstepSidebar.propTypes = {
  lineItemUri: PropTypes.string.isRequired,
  editMode: PropTypes.bool.isRequired,
  workstep: PropTypes.string.isRequired,
  setWorkstep: PropTypes.func.isRequired,
};

export default SelectWorkstepSidebar;
