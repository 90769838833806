import _map from 'lodash/map';
import { createSelector } from 'reselect';
import * as baseStateSelectors from 'rapidfab/selectors/baseStateSelectors';
import { getStateResources } from 'rapidfab/selectors/helpers/base';

export const getProsperPrinterMaterials = createSelector(
  [baseStateSelectors.getStateProsperPrinterMaterial, getStateResources],
  (uuids, resources) => _map(uuids, uuid => resources[uuid]),
);

export const getProspers = createSelector(
  [baseStateSelectors.getStateProspers, getStateResources],
  (uuids, resources) => _map(uuids, uuid => resources[uuid]),
);
