import _map from 'lodash/map';
import _find from 'lodash/find';
import _get from 'lodash/get';

const truncateFieldPositionBasedOnVisibilityOptions = (
  fieldReferences,
  fieldValuesByUri,
  parentFieldValuesByUri,
) => _map(fieldReferences, customFieldReference => {
  // No need to proceed if there are no `visible_on` settings for the field.
  // The field is visible based on `position` value
  if (!customFieldReference.meta || !customFieldReference.meta.visible_on) {
    return customFieldReference;
  }

  const { visible_on } = customFieldReference.meta;

  const isVisible = visible_on.some(visibleOnConfig => {
    const visibleOnField = _find(
      fieldReferences,
      { field_id: visibleOnConfig.custom_field_id },
    );

    let visibleOnFieldValue;

    if (!visibleOnField) {
      // Search in dependent fields when field id not listed in current entity
      visibleOnFieldValue = _get(
        parentFieldValuesByUri,
        [visibleOnConfig.custom_field_id, 'value'],
      );
    } else {
      visibleOnFieldValue = _get(
        fieldValuesByUri,
        [visibleOnField.uri, 'value'],
      );
    }

    return visibleOnConfig.custom_field_values.includes(visibleOnFieldValue);
  });

  if (!isVisible) {
    // Hide field via `position` in case it did not meet `visible_on` conditions
    return {
      ...customFieldReference,
      position: null,
    };
  }
  return customFieldReference;
});

export default truncateFieldPositionBasedOnVisibilityOptions;
