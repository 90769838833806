import React from 'react';
import PropTypes from 'prop-types';
import { Container } from 'react-bootstrap';

import { PRINT_STATUS_MAP } from 'rapidfab/mappings';
import BreadcrumbNav from 'rapidfab/components/BreadcrumbNav';
import Loading from 'rapidfab/components/Loading';
import Locations from 'rapidfab/components/locations';

import Table from 'rapidfab/components/Tables/Table';

const Prints = ({ fetching, gridData, isOrderDueDateFeatureEnabled }) => {
  const breadcrumbs = ['prints'];

  const columns = [
    {
      type: 'uuid',
      uid: 'field.id',
      accessor: 'uuid',
      defaultMessage: 'ID',
      isSortable: false,
      resource: 'print',
    },
    {
      type: 'translatable',
      uid: 'field.status',
      accessor: 'status',
      defaultMessage: 'Status',
      isSortable: true,
      mapping: PRINT_STATUS_MAP,
    },
    {
      type: 'record',
      uid: 'field.name',
      accessor: 'name',
      defaultMessage: 'Name',
      isSortable: true,
      resource: 'print',
      uri: 'uri',
    },
    {
      type: 'text',
      uid: 'field.customer_name',
      accessor: 'customerName',
      defaultMessage: 'Customer Name',
      isSortable: true,
    },
    isOrderDueDateFeatureEnabled &&
    {
      type: 'time',
      uid: 'field.dueDate',
      accessor: 'dueDate',
      defaultMessage: 'Due Date',
      isSortable: true,
    },
  ];

  return (
    <Container fluid className="container">
      <BreadcrumbNav breadcrumbs={breadcrumbs} />
      <Locations />
      <hr />
      {fetching ? (
        <Loading />
      ) : (
        <Table
          data={gridData}
          columns={columns}
          isFetching={fetching}
          isFilteringEnabled={false}
          isManualSoringEnabled={false}
          withDefaultPagination
          limit={50}
        />
      )}
    </Container>
  );
};

Prints.propTypes = {
  fetching: PropTypes.bool.isRequired,
  gridData: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      status: PropTypes.string,
      name: PropTypes.string,
      customerName: PropTypes.string,
      dueDate: PropTypes.date,
    }),
  ).isRequired,
  isOrderDueDateFeatureEnabled: PropTypes.bool.isRequired,
};

export default Prints;
