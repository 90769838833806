import _filter from 'lodash/filter';
import _find from 'lodash/find';
import _keyBy from 'lodash/keyBy';
import _map from 'lodash/map';
import * as baseStateSelectors from 'rapidfab/selectors/baseStateSelectors';
import { getPredicate, getStateResources } from 'rapidfab/selectors/helpers/base';
import { createSelector } from 'reselect';

export const getSlicedModels = createSelector(
  [baseStateSelectors.getStateSlicedModels, getStateResources],
  (uuids, resources) => _map(uuids, uuid => resources[uuid]),
);

export const getSlicedModelsForBuildFiles = createSelector(
  [getPredicate, getSlicedModels],
  (buildFiles, slicedModels) => {
    const buildFileUris = _map(buildFiles, 'uri');
    return _filter(slicedModels, slicedModel =>
      buildFileUris.includes(slicedModel.build_file),
    );
  },
);

export const getManuallySlicedBuildFileForRun = createSelector(
  [getPredicate, getSlicedModels],
  (runURI, slicedModels) => _find(slicedModels, { archived: null, run: runURI }),
);

export const getSlicedModelsByUri = createSelector(
  [getSlicedModels],
  config => _keyBy(config, 'uri'),
);
