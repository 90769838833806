import React from 'react';
import PropTypes from 'prop-types';
import { CAPTURE_STATUS_MAP } from 'rapidfab/mappings';

import TableWithSearching from 'rapidfab/components/Tables/TableWithSearching';
import { extractUuid, getShortUUID } from 'rapidfab/utils/uuidUtils';
import { Link } from 'react-router-dom';
import { getRouteURI } from 'rapidfab/utils/uriUtils';
import { ROUTES } from 'rapidfab/constants';

const Captures = componentProps => {
  const { data, fetching, onSort } = componentProps;

  const columns = [
    {
      type: 'custom',
      uid: 'field.uri',
      accessor: 'uri',
      defaultMessage: 'ID',
      isSortable: true,
      Cell: ({ value: uri }) => (
        <Link to={getRouteURI(ROUTES.CAPTURE, { uuid: extractUuid(uri) }, {}, true)}>
          <p>
            {getShortUUID(extractUuid(uri))}
          </p>
        </Link>
      ),
    },
    {
      type: 'translatable',
      uid: 'field.status',
      accessor: 'status',
      defaultMessage: 'Status',
      mapping: CAPTURE_STATUS_MAP,
      isSortable: true,
    },
    {
      type: 'text',
      uid: 'field.type',
      accessor: 'capture_type_name',
      defaultMessage: 'Type',
      isSortable: false,
    },
    {
      type: 'custom',
      uid: 'field.printer',
      accessor: 'printer_names',
      defaultMessage: 'Printer',
      isSortable: true,
      Cell: ({ value }) => (
        value ? value.join(', ') : null
      ),
    },
  ];

  return (
    <TableWithSearching
      {...componentProps}
      data={data}
      columns={columns}
      withBreadcrumbs
      breadcrumbs={['track', 'captures']}
      isFetching={fetching}
      isManualSoringEnabled
      manualSortingFunc={onSort}
      initialSortedDesc
      initialSortedColumn="updated"
    />
  );
};

Captures.propTypes = {
  data: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  value: PropTypes.string.isRequired,
};

export default Captures;
