import { useEffect, useRef, useState } from 'react';
import _isEqual from 'lodash/isEqual';

/**
 * @description A custom React hook that works similarly to useEffect, but uses deep comparison
 * on the dependencies to determine whether the effect function should be called. Useful
 * when the dependencies array contains complex data structures like objects or arrays.
 *
 * @param {Function} callback - The function to be executed when the dependencies change.
 * @param {Array} dependencies - The array of dependencies to watch for changes.
 *
 * @example
 * const [data, setData] = useState({ key1: 'value1', key2: 'value2' });
 *
 * useDeepCompareEffect(() => {
 *   // Your effect code here, which will run when `data` changes,
 *   // determined by a deep comparison instead of a shallow comparison.
 * }, [data]);
 *
 * @returns {void}
 */

function useDeepCompareEffect(callback, dependencies) {
  const [state, setState] = useState(0);
  const dependenciesRef = useRef();
  const callbackRef = useRef(callback);

  useEffect(() => {
    callbackRef.current = callback;
  }, [callback]);

  if (!_isEqual(dependencies, dependenciesRef.current)) {
    dependenciesRef.current = dependencies;
    setState(prevState => prevState + 1);
  }

  useEffect(() => callbackRef.current(), [state]);
}

export default useDeepCompareEffect;
