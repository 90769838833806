import Constants from 'rapidfab/constants';

export function modelRotation(rotation, uuid) {
  return {
    type: Constants.MODEL_ROTATION,
    rotation,
    uuid,
  };
}

export function setModelRotation(rotation) {
  return dispatch => {
    dispatch(modelRotation(rotation));
  };
}
