import { createSelector } from 'reselect';
import _filter from 'lodash/filter';
import _sortBy from 'lodash/sortBy';
import _keyBy from 'lodash/keyBy';
import { getPrepTaskRecords } from 'rapidfab/selectors/prepTaskRecord';
import { getPredicate } from 'rapidfab/selectors/helpers/base';

export const getPrepTaskRecordsForPrepWorkflowRecordOrderedByPosition = createSelector(
  [getPredicate, getPrepTaskRecords],
  (prepWorkflowRecord, prepTaskRecords) => {
    if (!prepWorkflowRecord) {
      return [];
    }
    return _sortBy(
      _filter(prepTaskRecords, { prep_workflow_record: prepWorkflowRecord.uri }),
      'position',
    );
  },
);

export const getPrepTaskRecordsForPrepWorkflowRecordByPrepTaskInWorkflowUUID = createSelector(
  [getPredicate, getPrepTaskRecords],
  (prepWorkflowRecord, prepTaskRecords) => {
    if (!prepWorkflowRecord) {
      return [];
    }
    return _keyBy(
      _filter(prepTaskRecords, { prep_workflow_record: prepWorkflowRecord.uri }),
      'prep_task_in_workflow',
    );
  },
);
