import React from 'react';
import PropTypes from 'prop-types';
import _map from 'lodash/map';
import {
  Container,
  ButtonToolbar,
  SplitButton,
  Dropdown,
  Row,
  Col,
  FormGroup,
  FormLabel,
  FormControl,
} from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { FormattedMessage } from 'react-intl';
import Error from 'rapidfab/components/error';
import { Currencies } from 'rapidfab/constants';

import BreadcrumbNav from 'rapidfab/components/BreadcrumbNav';
import SaveButtonTitle from 'rapidfab/components/SaveButtonTitle';
import { getShortUUID } from 'rapidfab/utils/uuidUtils';

import { Form, Field } from 'react-final-form';
import { finalFormInputTypes } from 'rapidfab/types';
import { FormControlSelect } from 'rapidfab/components/formTools';
import Loading from 'rapidfab/components/Loading';
import { faBan } from '@fortawesome/free-solid-svg-icons';

const ConversionForm = ({
  onFormSubmit,
  onFormDelete,
  apiErrors,
  initialFormValues,
  submitting,
}) => (
  <Form
    onSubmit={onFormSubmit}
    initialValues={initialFormValues}
    render={({ handleSubmit }) => (
      <form id="conversions" onSubmit={handleSubmit}>
        <Container fluid>
          <BreadcrumbNav
            breadcrumbs={['organize', 'currencies', initialFormValues?.id ? `${initialFormValues.currency} (${getShortUUID(initialFormValues.uuid)})` : 'New']}
          />

          <div className="clearfix">
            <ButtonToolbar className="pull-right">
              <SplitButton
                disabled={submitting}
                form="conversions"
                id="uxSaveDropdown"
                type="submit"
                variant="success"
                size="sm"
                title={submitting ? <Loading /> : <SaveButtonTitle />}
                pullRight
              >
                <Dropdown.Item
                  eventKey={1}
                  onClick={() => onFormDelete(initialFormValues.uuid)}
                  disabled={!initialFormValues?.id}
                >
                  <FontAwesomeIcon icon={faBan} />{' '}
                  <FormattedMessage id="button.delete" defaultMessage="Delete" />
                </Dropdown.Item>
              </SplitButton>
            </ButtonToolbar>
          </div>

          <hr />

          <Row>
            <Col xs={12}>
              <Error errors={apiErrors} />
            </Col>
          </Row>

          <Row>
            <Col xs={12}>
              <FormGroup className="form-group" controlId="uxMultiplier">
                <FormLabel>Currency Multiplier: *</FormLabel>
                <Field
                  name="value"
                  type="number"
                  render={props => (
                    <FormControl
                      {...props.input}
                      step="0.01"
                      required
                    />
                  )}
                />
              </FormGroup>

              <FormGroup className="form-group" controlId="uxCurrency">
                <FormLabel>Currency: *</FormLabel>
                <Field
                  name="currency"
                  render={props => (
                    <FormControlSelect
                      {...props.input}
                      required
                    >
                      <option key="placeholder" value="" selected disabled>
                        Select a currency
                      </option>
                      {_map(Currencies, currency => (
                        <option key={currency} value={currency}>
                          {currency}
                        </option>
                      ))}
                    </FormControlSelect>
                  )}
                />
              </FormGroup>
            </Col>
          </Row>
        </Container>
      </form>
    )}
  />
);

ConversionForm.propTypes = {
  onFormSubmit: PropTypes.func.isRequired,
  onFormDelete: PropTypes.func.isRequired,
  initialFormValues: PropTypes.shape({
    id: PropTypes.string,
    uuid: PropTypes.string,
    value: PropTypes.number,
    currency: PropTypes.string,
  }).isRequired,
  input: finalFormInputTypes.isRequired,
  apiErrors: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  submitting: PropTypes.bool.isRequired,
};

export default ConversionForm;
