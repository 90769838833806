import React from 'react';
import {
  Container,
  SplitButton,
  ButtonToolbar,
  Dropdown,
  Row,
  Col,
  FormGroup,
  FormLabel,
  FormControl,
} from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { FormattedMessage } from 'react-intl';
import BreadcrumbNav from 'rapidfab/components/BreadcrumbNav';
import SaveButtonTitle from 'rapidfab/components/SaveButtonTitle';
import { getShortUUID } from 'rapidfab/utils/uuidUtils';
import { identity } from 'rapidfab/components/formTools';
import Loading from 'rapidfab/components/Loading';
import { Form, Field } from 'react-final-form';
import { faBan } from '@fortawesome/free-solid-svg-icons';
import SlicerConfigurationFile from 'rapidfab/components/SlicerConfigurationFile';
import PropTypes from 'prop-types';
import { finalFormInputTypes } from 'rapidfab/types';

// Will be needed in V1 Photocentric
import SlicerTest from 'rapidfab/components/SlicerTest';

const SlicerConfigurationForm = ({
  onFormSubmit,
  submitting,
  slicerConfig,
  onDelete,
  initiateSlicingTest,
  ...otherProps
}) => (
  !slicerConfig ? <Loading /> : (
    <Form
      onSubmit={onFormSubmit}
      initialValues={slicerConfig}
      render={({ handleSubmit }) => (
        <form id="slicerConfiguration" onSubmit={handleSubmit}>
          <Container fluid className="p-b">
            <BreadcrumbNav
              breadcrumbs={[
                'organize',
                'slicerConfiguration',
                slicerConfig?.id
                  ? `${slicerConfig?.name} (${getShortUUID(slicerConfig?.uuid)})`
                  : 'New',
              ]}
            />

            <div className="clearfix">
              <ButtonToolbar className="pull-right">
                <SplitButton
                  disabled={submitting}
                  form="slicerConfiguration"
                  id="uxSaveDropdown"
                  type="submit"
                  variant="success"
                  size="sm"
                  title={submitting ? <Loading /> : <SaveButtonTitle />}
                  pullRight
                >
                  <Dropdown.Item
                    eventKey={1}
                    onClick={onDelete}
                  >
                    <FontAwesomeIcon icon={faBan} />{' '}
                    <FormattedMessage id="button.delete" defaultMessage="Delete" />
                  </Dropdown.Item>
                </SplitButton>
              </ButtonToolbar>
            </div>

            <hr />

            <Row>
              <Col sm={6}>
                <FormGroup className="form-group" controlId="uxName">
                  <FormLabel>
                    <FormattedMessage id="field.name" defaultMessage="Name" />: *
                  </FormLabel>
                  <Field
                    name="name"
                    type="text"
                    render={props => (
                      <FormControl
                        {...props.input}
                        disabled
                        readOnly
                        required
                      />
                    )}
                  />
                </FormGroup>
                <FormGroup className="form-group" controlId="uxDescription">
                  <FormLabel>
                    <FormattedMessage
                      id="field.description"
                      defaultMessage="Description"
                    />: *
                  </FormLabel>
                  <Field
                    name="description"
                    parse={identity}
                    render={props => (
                      <FormControl
                        {...props.input}
                        as="textarea"
                      />
                    )}
                  />
                </FormGroup>
                <FormGroup className="form-group" controlId="uxName">
                  <FormLabel>
                    <FormattedMessage id="field.targetMachine" defaultMessage="Machine" />: *
                  </FormLabel>
                  <Field
                    name="machine"
                    type="text"
                    render={props => (
                      <FormControl
                        {...props.input}
                        disabled
                        readOnly
                        required
                      />
                    )}
                  />
                </FormGroup>
                <SlicerConfigurationFile slicerConfig={slicerConfig} />
              </Col>
              <Col>
                {/* Will be completed in V1 */}
                <SlicerTest initiateSlicingTest={initiateSlicingTest} {...otherProps} />
              </Col>
            </Row>
          </Container>
        </form>
      )}
    />
  )
);

SlicerConfigurationForm.propTypes = {
  slicerConfig: PropTypes.shape({
    name: PropTypes.string.isRequired,
    uuid: PropTypes.string.isRequired,
    id: PropTypes.string.isRequired,
  }).isRequired,
  onFormSubmit: PropTypes.func.isRequired,
  initiateSlicingTest: PropTypes.func.isRequired,
  onDelete: PropTypes.func.isRequired,
  submitting: PropTypes.bool.isRequired,
  input: finalFormInputTypes.isRequired,
};

export default SlicerConfigurationForm;
