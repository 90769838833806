import * as baseStateSelectors from 'rapidfab/selectors/baseStateSelectors';
import { createSelector } from 'reselect';

export const getDebugModeUserInfo = createSelector(
  [baseStateSelectors.getStateDebugModeUserInfo],
  data => data,
);

export const getShowDebugModeUserInfoModal = createSelector(
  [getDebugModeUserInfo],
  data => data.show,
);
