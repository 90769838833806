/* eslint-disable no-alert */
import React, { useEffect, useState } from 'react';
import dayjs from 'dayjs';
import _isEmpty from 'lodash/isEmpty';
import PropTypes from 'prop-types';
import Actions from 'rapidfab/actions';
import { API_RESOURCES, MODAL_TEMPLATES, MODEL_LIBRARY_TYPES, PRINTER_FORM_COMMAND_TYPES, PRINTER_FORM_COMMAND_VIEW_PASSWORD } from 'rapidfab/constants';
import ModelLibrariesContainer from 'rapidfab/containers/organize/ModelLibrariesContainer';
import { useModal } from 'rapidfab/hooks';
import Alert from 'rapidfab/utils/alert';
import { Button, Card, Col, FormControl, FormGroup, FormLabel, Image, Modal, Row } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';

const ModelPickerModal = ({
  setSelectedModel,
  close,
}) => {
  const handleSelectModel = model => {
    setSelectedModel(model);
    close();
  };

  return (
    <Modal size="lg" show onHide={close}>
      <Modal.Header closeButton>
        Model Library
      </Modal.Header>
      <Modal.Body>
        <ModelLibrariesContainer
          showHeader={false}
          showModelDropZone={false}
          handleSelect={handleSelectModel}
          typeFilter={MODEL_LIBRARY_TYPES.PRODUCT}
        />
      </Modal.Body>
    </Modal>
  );
};

ModelPickerModal.propTypes = {
  setSelectedModel: PropTypes.func.isRequired,
  close: PropTypes.func.isRequired,
};

const ModelPicker = ({
  selectedModel: [
    selectedModel,
    setSelectedModel,
  ],
}) => {
  const [showModelPickerModal, setShowModelPickerModal] = useState(false);

  return (
    <>
      {showModelPickerModal && (
        <ModelPickerModal
          setSelectedModel={setSelectedModel}
          close={() => setShowModelPickerModal(false)}
        />
      )}
      <div>
        <hr />
        <Button
          variant="primary"
          onClick={() => setShowModelPickerModal(true)}
        >
          {selectedModel ? 'Change Model' : 'Choose Model'}
        </Button>
        {selectedModel && (
          <Button
            className="spacer-left"
            variant="danger"
            onClick={() => setSelectedModel(null)}
          >
            Remove Model
          </Button>
        )}
        <div className="mt-2" id="selectedModelDataWrapper">
          <b>Model:</b>
          {selectedModel ? (
            <Card>
              <Card.Body>
                <Row>
                  <Col xs={3}>
                    <Image src={selectedModel.snapshot_content} thumbnail />
                  </Col>
                  <Col xs={9} id="modelInformation">
                    <span>
                      <b>Name:</b>
                      <p>{selectedModel.name}</p>
                    </span>
                    <span>
                      <b>Created:</b>
                      <p>{dayjs(selectedModel.created).format('L LT')}</p>
                    </span>
                  </Col>
                </Row>
              </Card.Body>
            </Card>
          ) : (
            <div className="spacer-left">
              <i>(None) - Please select a model.</i>
            </div>
          )}
        </div>
      </div>
    </>
  );
};

ModelPicker.propTypes = {
  selectedModel: PropTypes.arrayOf(PropTypes.shape({
    selectedModel: PropTypes.shape({}).isRequired,
    setSelectedModel: PropTypes.func.isRequired,
  })).isRequired,
};

const PrinterFormCommandView = ({ printer }) => {
  const dispatch = useDispatch();

  const [openWarningPromptModal, WarningPromptModal] = useModal();

  const isCommandFetching = useSelector(state => state.ui.nautilus[API_RESOURCES.COMMAND].post.fetching);

  const commandConfigurationInitialState = {
    modelPickerVisible: false,
    jobIdFieldVisible: false,
  };

  const [isViewEnabled, setIsViewEnabled] = useState(false);
  const [selectedModel, setSelectedModel] = useState(null);
  const [enteredJobId, setEnteredJobId] = useState(null);
  const [commandType, setCommandType] = useState('');
  const [commandConfigurationState, setCommandConfigurationState] = useState(commandConfigurationInitialState);

  useEffect(() => {
    // Reset state when enabled/disabled.
    setCommandType('');
    setCommandConfigurationState(commandConfigurationInitialState);
  }, [isViewEnabled]);

  useEffect(() => {
    setCommandConfigurationState(commandConfigurationInitialState);

    if (commandType === PRINTER_FORM_COMMAND_TYPES.START_JOB) {
      setCommandConfigurationState({ ...commandConfigurationInitialState, modelPickerVisible: true });
    } else if (commandType === PRINTER_FORM_COMMAND_TYPES.CANCEL_JOB) {
      setCommandConfigurationState({ ...commandConfigurationInitialState, jobIdFieldVisible: true });
    } else {
      /* ... */
    }
  }, [commandType]);

  const handleChangeCommand = event => {
    setCommandType(event.target.value);
  };

  const handleSendCommand = async () => {
    let payload = {
      modeler: printer.modeler,
    };

    if (commandType === PRINTER_FORM_COMMAND_TYPES.START_JOB) {
      payload = {
        ...payload,
        model: selectedModel.additive.model,
        type: PRINTER_FORM_COMMAND_TYPES.START_JOB,
      };
    } else if (commandType === PRINTER_FORM_COMMAND_TYPES.CANCEL_JOB) {
      payload = {
        ...payload,
        job_external_id: enteredJobId,
        type: PRINTER_FORM_COMMAND_TYPES.CANCEL_JOB,
      };
    }

    const sendCommandResponse = await dispatch(Actions.Api.nautilus[API_RESOURCES.COMMAND].post(payload));

    if (sendCommandResponse.type === 'RESOURCE_POST_SUCCESS') {
      Alert.success('Created command successfully.');
      setCommandConfigurationState(commandConfigurationInitialState);
      setCommandType('');
    }
  };

  const toggleViewEnabled = () => {
    const passwordMatches = password => password === PRINTER_FORM_COMMAND_VIEW_PASSWORD;

    // If view is not enabled, clicking to toggle to -> true
    if (!isViewEnabled) {
      openWarningPromptModal({
        size: 'sm',
        title: 'Warning',
        textEntryBodyText: 'Warning: You are directly interacting with printers, existing print runs will be affected. Please enter the password to proceed.',
        modalTemplate: MODAL_TEMPLATES.TEXT_ENTRY,
        onModalConfirm: (state, onHide) => {
          if (_isEmpty(state?.textInput)) {
            alert('Please enter the password to proceed.');
            return;
          }

          if (passwordMatches(state.textInput)) {
            setIsViewEnabled(true);
            onHide();
          } else {
            alert('Incorrect password.');
          }
        },
        inputType: 'password',
      });
    } else {
      // View is already enabled, toggle straight to -> false
      setIsViewEnabled(false);
    }
  };

  const isSendCommandButtonDisabled =
    !isViewEnabled ||
    isCommandFetching ||
    (commandType === '') ||
    (commandType === PRINTER_FORM_COMMAND_TYPES.START_JOB && !selectedModel) ||
    (commandType === PRINTER_FORM_COMMAND_TYPES.CANCEL_JOB && _isEmpty(enteredJobId));

  return ([
    <WarningPromptModal id="warningPromptModal" />,
    <Card className="mt15" bg="dark">
      <Card.Header className="pd-exp inverse">
        <div className="d-flex align-items-center justify-content-between">
          Commands
          <Button
            onClick={toggleViewEnabled}
            size="xs"
            variant={isViewEnabled ? 'danger' : 'success'}
            bg={isViewEnabled ? 'danger' : 'success'}
          >
            {isViewEnabled ? 'Disable' : 'Enable'}
          </Button>
        </div>
      </Card.Header>
      <div className="card-body-wrapper">
        <Card.Body>
          <FormGroup className="form-group" controlId="commandType">
            <FormLabel>
              Command Type:
            </FormLabel>
            <FormControl
              disabled={!isViewEnabled}
              name="selectCommand"
              onChange={handleChangeCommand}
              as="select"
              value={commandType}
            >
              <option id={null} value="">
                Select a command
              </option>
              <option id={PRINTER_FORM_COMMAND_TYPES.START_JOB} value={PRINTER_FORM_COMMAND_TYPES.START_JOB}>
                Start Job
              </option>
              <option id={PRINTER_FORM_COMMAND_TYPES.CANCEL_JOB} value={PRINTER_FORM_COMMAND_TYPES.CANCEL_JOB}>
                Cancel Job
              </option>
            </FormControl>
          </FormGroup>
          {commandConfigurationState.modelPickerVisible && (
            <ModelPicker selectedModel={[selectedModel, setSelectedModel]} />
          )}
          {commandConfigurationState.jobIdFieldVisible && (
            <FormGroup className="form-group" controlId="jobId">
              <FormLabel>
                Job ID:
              </FormLabel>
              <FormControl
                name="jobId"
                onChange={event => setEnteredJobId(event.target.value)}
                placeholder="Enter Job ID"
                as="input"
              />
            </FormGroup>
          )}
          <Button
            className="pull-right mb15 mt15"
            variant="success"
            onClick={handleSendCommand}
            disabled={isSendCommandButtonDisabled}
          >
            Send Command
          </Button>
        </Card.Body>
      </div>
    </Card>,
  ]);
};

PrinterFormCommandView.propTypes = {
  printer: PropTypes.shape({
    modeler: PropTypes.string,
  }).isRequired,
};

export default PrinterFormCommandView;
