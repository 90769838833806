import ToolingStockActionModalContainer from 'rapidfab/containers/inventory/ToolingStockActionModalContainer';
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import Actions from 'rapidfab/actions';
import * as Selectors from 'rapidfab/selectors';
import { API_RESOURCES } from 'rapidfab/constants';
import { useDispatch, useSelector } from 'react-redux';
import ToolingStockActionsComponent from 'rapidfab/components/records/ToolingStockActions';
import { getToolingStockActionsByToolingStock } from 'rapidfab/selectors/toolingStockActions';
import usePagination from 'rapidfab/hooks/usePagination';
import usePrevious from 'rapidfab/hooks';

const ToolingStockActionContainer = ({ toolingStock, vendorUser }) => {
  const dispatch = useDispatch();

  const users = useSelector(state => Selectors.getUsersByUri(state));
  const actions =
    useSelector(state =>
      getToolingStockActionsByToolingStock(state, toolingStock.uri),
    ) ?? [];
  const isVendorUser = useSelector(Selectors.isVendorServiceProvider);

  const [isActionModalShown, setIsActionModalShown] = useState(false);

  const fetching = useSelector(
    state =>
      state.ui.nautilus[API_RESOURCES.TOOLING_STOCK_ACTION].get.fetching ||
      state.ui.nautilus[API_RESOURCES.USERS].list.fetching,
  );

  const {
    paginationState,
    setTotalItems,
    setPage,
    nextPage,
    prevPage,
    totalPaginatedPages,
  } = usePagination(50);

  const onInitialize = async () => {
    dispatch(Actions.Api.nautilus[API_RESOURCES.TOOLING_STOCK_ACTION].clear('list'));
    dispatch(
      Actions.Api.nautilus[API_RESOURCES.TOOLING_STOCK_ACTION].list({
        tooling_stock: toolingStock?.uri,
      },
      { limit: paginationState.pageLimit,
        offset: paginationState.offset > 0 ? paginationState.offset : 0 }, {}, {}, true),
    ).then(printersResponse => {
      setTotalItems(printersResponse.json?.meta?.count);
    });
    if (!isVendorUser) {
      dispatch(Actions.Api.nautilus[API_RESOURCES.USERS].list());
    }
  };

  useEffect(() => {
    onInitialize();
  }, [toolingStock?.uri, dispatch]);

  const previousPage = usePrevious(paginationState.activePage);

  useEffect(() => {
    if (previousPage !== paginationState.activePage) {
      onInitialize();
    }
  }, [
    paginationState.pageLimit,
    paginationState.offset,
    paginationState.activePage,
  ]);

  return (
    <>
      <ToolingStockActionsComponent
        fetching={fetching}
        usersByUri={users}
        actions={actions}
        showActionModal={() => setIsActionModalShown(true)}
        vendorUserName={vendorUser?.name}
        isVendorUser={isVendorUser}
        pagination={{
          ...paginationState,
          nextPage,
          prevPage,
          setPage,
          totalPaginatedPages,
        }}
      />
      {isActionModalShown && (
        <ToolingStockActionModalContainer
          show={isActionModalShown}
          hideModal={() => setIsActionModalShown(false)}
          toolingStock={toolingStock}
        />
      )}
    </>
  );
};

ToolingStockActionContainer.propTypes = {
  toolingStock: PropTypes.shape({
    uri: PropTypes.string.isRequired,
    status: PropTypes.string.isRequired,
  }).isRequired,
  vendorUser: PropTypes.shape({
    name: PropTypes.string.isRequired,
  }).isRequired,
};

export default ToolingStockActionContainer;
