import React from 'react';
import PropTypes from 'prop-types';
import {
  FormGroup,
  Form,
} from 'react-bootstrap';
import ListItem from 'rapidfab/components/records/order/line_item_dialog/ListItem';

const ListLineItems = ({ files, selectedFiles, setSelectedFiles, lineItemStatuses }) => {
  const handleSelectAll = event => {
    if (event.target.checked) {
      setSelectedFiles(files);
    } else {
      setSelectedFiles([]);
    }
  };

  const handleFileSelect = (event, file) => {
    if (event.target.checked) {
      setSelectedFiles([...selectedFiles, file]);
    } else {
      setSelectedFiles([...selectedFiles.filter(item => item.uri !== file.uri)]);
    }
  };

  return (
    <div>
      <Form>
        <FormGroup>
          <Form.Check
            id="select-all-files"
            type="checkbox"
            label="Select All"
            className="mb15"
            onChange={handleSelectAll}
            checked={selectedFiles.length === files.length}
          />

          {
            files?.map(lineItem => (
              <ListItem
                key={lineItem.uri}
                isPending={lineItemStatuses.get(lineItem.uri)?.isPending}
                error={lineItemStatuses.get(lineItem.uri)?.error}
                isSelected={selectedFiles.find(file => file.uri === lineItem.uri)}
                handleFileSelect={handleFileSelect}
                lineItem={lineItem}
              />
            ))
          }
        </FormGroup>
      </Form>
    </div>
  );
};

ListLineItems.propTypes = {
  files: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string.isRequired,
      error: PropTypes.shape({
        title: PropTypes.string,
        description: PropTypes.string,
      }),
    }),
  ),
};

ListLineItems.defaultProps = {
  files: [],
};

ListLineItems.propTypes = {
  files: PropTypes.arrayOf(PropTypes.shape({
    uri: PropTypes.string.isRequired,
  })),
  selectedFiles: PropTypes.arrayOf(PropTypes.shape({
    uri: PropTypes.string.isRequired,
  })).isRequired,
  setSelectedFiles: PropTypes.func.isRequired,
  lineItemStatuses: PropTypes.shape({
    get: PropTypes.func.isRequired,
  }).isRequired,
};

export default ListLineItems;
