import React from 'react';
import PropTypes from 'prop-types';
import ReactTagInput from '@pathofdev/react-tag-input';
import 'rapidfab/styles/componentStyles/tag-input.scss';

const TagsInput = ({ tags, onChange }) => (
  <ReactTagInput
    tags={tags || []}
    onChange={onChange}
    editable
  />
);

TagsInput.defaultProps = {
  tags: [],
};

TagsInput.propTypes = {
  tags: PropTypes.arrayOf(PropTypes.string),
  onChange: PropTypes.func.isRequired,
};

export default TagsInput;
