import Constants from 'rapidfab/constants';

function reducer(state = {}, action) {
  switch (action.type) {
    case Constants.STORE_CURRENCY:
      // Update local storage
      return { ...state, selectedCurrency: action.selectedCurrency };
    default:
      return state;
  }
}

export default reducer;
