import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import _get from 'lodash/get';
import _set from 'lodash/set';

import Actions from 'rapidfab/actions';
import * as Selectors from 'rapidfab/selectors';
import { API_RESOURCES, ROUTES } from 'rapidfab/constants';
import AnatomicalModel from 'rapidfab/components/AnatomicalModel/AnatomicalModel';
import Alert from 'rapidfab/utils/alert';
import { getRouteURI } from 'rapidfab/utils/uriUtils';
import { extractUuid } from 'rapidfab/utils/uuidUtils';

import Loading from 'rapidfab/components/Loading';
import dayjs from 'dayjs';

import { ANATOMICAL_MODEL_CONTAINER } from 'rapidfab/constants/forms';
import { FormattedMessage } from 'react-intl';

const AnatomicalModelContainer = props => {
  const uuid = useSelector(Selectors.getRouteUUID);
  const anatomicalModel = useSelector(state => Selectors.getUUIDResource(state, uuid));
  const isLoading = useSelector(state => state.ui.nautilus[API_RESOURCES.ANATOMICAL_MODEL].get.fetching);
  const isSubmitting = useSelector(state => state.ui.nautilus[API_RESOURCES.ANATOMICAL_MODEL].post.fetching
    || state.ui.nautilus[API_RESOURCES.ANATOMICAL_MODEL].put.fetching
    || state.ui.nautilus[API_RESOURCES.ANATOMICAL_MODEL].delete.fetching);
  const initialValues = {
    // Scale is 100% by default
    scale_to_print: 100,
    ...anatomicalModel,
  };

  const initialFormValues = {};
  Object
    .keys(initialValues)
    .filter(key => ANATOMICAL_MODEL_CONTAINER.FIELDS.includes(key))
    .forEach(key => {
      initialFormValues[key] = initialValues[key];
    });

  // Remove timezone and convert just to genetic date format (YYYY-MM-DD)
  ANATOMICAL_MODEL_CONTAINER.DATE_FIELDS.forEach(
    fieldName => {
      if (initialFormValues[fieldName]) {
        const fieldDate = dayjs(initialFormValues[fieldName]);
        initialFormValues[fieldName] = fieldDate.format('YYYY-MM-DD');
      }
    },
  );

  const selected = {
    uuid,
    initialFormValues,
    isSubmitting,
    isLoading,
    anatomicalModel,
  };

  const dispatch = useDispatch();

  // eslint-disable-next-line no-shadow
  const onInitialize = currentUUID => {
    if (currentUUID) {
      dispatch(Actions.Api.nautilus[API_RESOURCES.ANATOMICAL_MODEL].get(currentUUID));
    }
  };

  useEffect(() => {
    onInitialize(uuid);
  }, [uuid]);

  // eslint-disable-next-line consistent-return
  const onSave = async payload => {
    const updatedPayload = { ...payload };

    ANATOMICAL_MODEL_CONTAINER.DATE_FIELDS.forEach(
      fieldName => {
        // Convert dates to ISO string in order to include timezone
        if (updatedPayload[fieldName]) {
          const date = new Date(`${updatedPayload[fieldName]}T00:00`);
          updatedPayload[fieldName] = date.toISOString();
        }
      },
    );

    // Nullable fields must be submitted even if null value is here
    // If null value is not in payload, nautilus will not update it
    ANATOMICAL_MODEL_CONTAINER.NULL_FIELDS.forEach(
      fieldName => {
        const field = _get(payload, fieldName);
        if (!field) {
          _set(updatedPayload, fieldName, null);
        }
      },
    );

    if (updatedPayload.uuid) {
      await dispatch(Actions.Api.nautilus[API_RESOURCES.ANATOMICAL_MODEL]
        .put(updatedPayload.uuid, updatedPayload));
      return Alert.success(
        <FormattedMessage
          id="toaster.anatomicalModel.updated"
          defaultMessage="Anatomical Model successfully updated."
        />);
    }

    const response = await dispatch(Actions.Api.nautilus[API_RESOURCES.ANATOMICAL_MODEL]
      .post(updatedPayload));
    const { location } = response.headers;
    const currentUUID = extractUuid(location);
    Alert.success(
      <FormattedMessage
        id="toaster.anatomicalModel.created"
        defaultMessage="Anatomical Model successfully created."
      />,
    );
    window.location.hash = getRouteURI(ROUTES.ANATOMICAL_MODEL_EDIT, { uuid: currentUUID });
  };

  const onDelete = async currentUUID => {
    if (!currentUUID) {
      return;
    }
    await dispatch(Actions.Api.nautilus[API_RESOURCES.ANATOMICAL_MODEL].delete(currentUUID));
    window.location.hash = getRouteURI(ROUTES.ANATOMICAL_MODELS);
  };

  const recreateBuildFile = currentUUID => {
    if (!currentUUID) {
      return;
    }
    dispatch(Actions.Api.nautilus[API_RESOURCES.ANATOMICAL_MODEL].put(currentUUID, {
      recreate_build_file: true,
    }));
  };

  return isLoading
    ? <Loading />
    : (
      <AnatomicalModel
        {...props}
        {...selected}
        onDelete={onDelete}
        onSave={onSave}
        recreateBuildFile={recreateBuildFile}
      />
    );
};

export default AnatomicalModelContainer;
