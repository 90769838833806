import { faPlus } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import BureauBarcodeIcon from 'rapidfab/components/BureauBarcodeIcon';
import { DebugModeBadge } from 'rapidfab/components/DebugMode/DebugModeComponents';
import FeedstocksTabs from 'rapidfab/components/inventory/FeedstocksTabs';
import TruncatedItemsList from 'rapidfab/components/TruncatedItemsList';
import React from 'react';
import PropTypes from 'prop-types';
import { PERMANENT_CONTAINER_STATUS_MAP } from 'rapidfab/mappings';
import { Button } from 'react-bootstrap';
import { FormattedMessage } from 'react-intl';
import { Link } from 'react-router-dom';
import { ROUTES } from 'rapidfab/constants';

import TableWithSearching from 'rapidfab/components/Tables/TableWithSearching';

import { extractUuid, getShortUUID } from 'rapidfab/utils/uuidUtils';
import { getRouteURI } from 'rapidfab/utils/uriUtils';

const PermanentContainers = ({
  data,
  fetching,
  onSort,
  subLocations,
  isDebugModeEnabled,
  batches,
  locations,
  materials,
  ...componentProps
}) => {
  const navbarLink = (
    <div className="pull-right d-flex">
      <Button
        variant="primary"
        size="sm"
        href={getRouteURI(ROUTES.PERMANENT_CONTAINER_NEW)}
        className="pull-right"
      >
        <FontAwesomeIcon icon={faPlus} />{' '}
        <FormattedMessage
          id="record.permanentContainer.add"
          defaultMessage="Add Permanent Container"
        />
      </Button>
    </div>
  );

  const breadcrumbs = ['inventory', 'materialStocks', 'permanentContainers'];

  const columns = [
    isDebugModeEnabled && (
      {
        debugMode: true,
        type: 'uuid',
        uid: 'field.id',
        accessor: 'uuid',
        defaultMessage: 'ID',
        resource: 'material-batch',
        route: ROUTES.PERMANENT_CONTAINER,
        Header: () => ([
          <DebugModeBadge />,
          <span>ID</span>,
        ]),
      }
    ),
    {
      type: 'custom',
      uid: 'field.name',
      accessor: 'name',
      defaultMessage: 'Name',
      isSortable: true,
      Cell: ({ row: { original } }) => {
        if (!original.name) {
          return 'N/A';
        }
        return (
          <>
            <a
              className="QueuesStyleQrLink"
              href={getRouteURI(ROUTES.PRINT_PERMANENT_CONTAINER_CONTAINERS_QR_CODES,
                { uuid: extractUuid(original.uri) })}
              style={{ margin: 0 }}
            >
              <BureauBarcodeIcon className="spacer-right spacer-left" />
            </a>
            <Link
              to={getRouteURI(ROUTES.PERMANENT_CONTAINER, { uuid: extractUuid(original.uri) }, null, true)}
            >
              {original.name}
            </Link>
          </>
        );
      },
    },
    {
      type: 'translatable',
      uid: 'field.status',
      accessor: 'status',
      defaultMessage: 'Status',
      mapping: PERMANENT_CONTAINER_STATUS_MAP,
      isSortable: true,
    },
    {
      type: 'custom',
      uid: 'field.material_restrictions',
      accessor: 'material_restrictions',
      defaultMessage: 'Allowed Materials',
      Cell: ({ value }) => {
        const materialRestrictionNames = value.map(materialRestriction => {
          const material = materials.find(material => material.uri === materialRestriction);
          return material?.name;
        });
        return (
          value.length ? (
            <TruncatedItemsList data={materialRestrictionNames} />
          ) : 'All'
        );
      },
    },
    {
      type: 'custom',
      uid: 'field.batch_id',
      accessor: 'current_batch',
      defaultMessage: 'Batch ID',
      Cell: ({ value }) =>
        (
          value ? (
            <Link
              to={getRouteURI(ROUTES.MATERIAL_BATCH, { uuid: extractUuid(value) }, null, true)}
            >
              {getShortUUID(value)}
            </Link>
          ) : 'N/A'
        ),
    },
    {
      type: 'custom',
      uid: 'field.contained_material',
      accessor: 'current_batch_material',
      defaultMessage: 'Contained Material',
      isSortable: false,
      Header: () => (
        <span>Contained Material</span>
      ),
      Cell: ({ row: { original } }) => {
        const batch = original.current_batch;
        if (!batch) {
          return 'N/A';
        }
        const materials = batches[batch]?.materials;
        return (
          materials?.length ? (
            materials.map(material => (
              <Link
                to={getRouteURI(ROUTES.MATERIALS, null, { uuid: extractUuid(material.uri) }, true)}
              >
                {material.name}
              </Link>
            ))
          ) : 'N/A'
        );
      },
    },
    {
      type: 'custom',
      uid: 'field.amount_loaded',
      accessor: 'current_batch_amount',
      defaultMessage: 'Amount Loaded',
      Cell: ({ row: { original } }) => {
        const batch = batches[original.current_batch];

        if (!batch) {
          return 'N/A';
        }

        return <span>{batch.quantity} {batch.units}</span>;
      },
    },
    {
      type: 'location',
      uid: 'field.location',
      accessor: 'location',
      defaultMessage: 'Location',
      resource: 'location',
      resources: locations,
      isSortable: true,
    },
    {
      type: 'custom',
      uid: 'field.sub_location',
      accessor: 'sub_location',
      defaultMessage: 'Sub-Location',
      resource: 'sub_location',
      resources: subLocations,
      Cell: ({ value }) => {
        const subLocation = subLocations.find(subLocation => subLocation.uri === value);
        return (
          <span>{subLocation ? subLocation?.name : 'N/A'}</span>
        );
      },
    },
  ];

  return (
    <TableWithSearching
      {...componentProps}
      breadcrumbs={breadcrumbs}
      isFetching={fetching}
      showLocationsFilter
      subheader={<FeedstocksTabs activeKey="permanent-containers" />}
      locations={Object.values(locations)}
      data={data}
      columns={columns}
      navbar={navbarLink}
      isManualSoringEnabled
      manualSortingFunc={onSort}
      initialSortedDesc
      isFilteringEnabled
      withBreadcrumbs
      showSubLocationsFilter
    />
  );
};

PermanentContainers.defaultProps = {
  sort: '',
  search: '',
};

PermanentContainers.propTypes = {
  onPageChange: PropTypes.func.isRequired,
  onLimitChange: PropTypes.func.isRequired,
  onSearch: PropTypes.func.isRequired,
  onFilterUpdate: PropTypes.func.isRequired,
  onSort: PropTypes.func.isRequired,
  sort: PropTypes.string,
  search: PropTypes.string,
  recordListItems: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  filters: PropTypes.shape({}).isRequired,
  recordListStore: PropTypes.shape({}).isRequired,
  offset: PropTypes.number.isRequired,
  limit: PropTypes.number.isRequired,
  data: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  fetching: PropTypes.bool.isRequired,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  row: PropTypes.shape({
    original: PropTypes.shape({
      name: PropTypes.string.isRequired,
      uuid: PropTypes.string.isRequired,
      uri: PropTypes.string.isRequired,
      location: PropTypes.string.isRequired,
      sub_location: PropTypes.string.isRequired,
      current_batch: PropTypes.oneOfType(
        [PropTypes.oneOf([null]), PropTypes.shape({
          quantity: PropTypes.number,
          units: PropTypes.string,
        })]).isRequired,
    }),
  }).isRequired,
  locations: PropTypes.arrayOf(PropTypes.shape({
    uri: PropTypes.string,
  })).isRequired,
  subLocations: PropTypes.arrayOf(
    PropTypes.shape({
      uri: PropTypes.string,
    })).isRequired,
  isDebugModeEnabled: PropTypes.bool.isRequired,
  batches: PropTypes.shape({}).isRequired,
  materials: PropTypes.arrayOf(PropTypes.shape({
    uri: PropTypes.string,
  })).isRequired,
};

export default PermanentContainers;
