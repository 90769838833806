import PropTypes from 'prop-types';
import React, { createContext, useContext } from 'react';

const ModalGroupedSectionContext = createContext(null);

export const ModalGroupedSectionProvider = ({
  children,
  sectionsWithMissingFields,
  groupedSectionTitles,
  handleSetSectionVisibilityState,
}) => {
  const sharedProps = { sectionsWithMissingFields, groupedSectionTitles, handleSetSectionVisibilityState };
  return (
    <ModalGroupedSectionContext.Provider value={sharedProps}>
      {children}
    </ModalGroupedSectionContext.Provider>
  );
};

ModalGroupedSectionProvider.propTypes = {
  children: PropTypes.node.isRequired,
  sectionsWithMissingFields: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  groupedSectionTitles: PropTypes.arrayOf(PropTypes.string).isRequired,
  handleSetSectionVisibilityState: PropTypes.func.isRequired,
};

export const useModalGroupedSection = () => useContext(ModalGroupedSectionContext);
