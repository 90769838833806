import _map from 'lodash/map';
import { createSelector } from 'reselect';

import * as baseStateSelectors from './baseStateSelectors';
import { getStateResources } from './helpers/base';

// eslint-disable-next-line import/prefer-default-export
export const getFeatures = createSelector(
  [baseStateSelectors.getStateFeatures, getStateResources],
  (uuids, resources) => _map(uuids, uuid => resources[uuid]),
);
