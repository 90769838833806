import React from 'react';
import { useSelector } from 'react-redux';

import * as Selectors from 'rapidfab/selectors';

import ModelLibrariesByFiltersContainer from 'rapidfab/containers/ModelLibrariesByFiltersContainer';
import PropTypes from 'prop-types';

const CustomLibraryContainer = props => {
  const modelLibraries = useSelector(Selectors.getModelLibraries);

  return (
    <ModelLibrariesByFiltersContainer
      {...props}
      ownerUri={props.ownerUri}
      customLibraryName={props.customLibraryName}
      modelLibraries={modelLibraries}
    />
  );
};

CustomLibraryContainer.propTypes = {
  ownerUri: PropTypes.string.isRequired,
  customLibraryName: PropTypes.string.isRequired,
};
export default CustomLibraryContainer;
