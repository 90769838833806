import React from 'react';
import { faPlus } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import PropTypes from 'prop-types';
import Actions from 'rapidfab/actions';
import Loading from 'rapidfab/components/Loading';
import { API_RESOURCES, ORDER_SHIPPING_GROUPING_OPTIONS, PRIORITIES, ROUTES } from 'rapidfab/constants';
import { useFetchMultipleResources } from 'rapidfab/hooks';
import * as Selectors from 'rapidfab/selectors';
import { generateRandomWord } from 'rapidfab/utils/stringUtils';
import { getRouteURI } from 'rapidfab/utils/uriUtils';
import { extractUuid } from 'rapidfab/utils/uuidUtils';
import { Button } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

/**
 * Small button component that will simply submit a POST to order
 */
const DebugModeQuickCreateOrder = ({ className }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const isCreating = useSelector(state => state.ui.nautilus[API_RESOURCES.ORDER].post.fetching);

  // Light call to only fetch one resource, as this is quick creation.
  useFetchMultipleResources([
    { resource: API_RESOURCES.BUREAU, configuration: [{}, { limit: 1 }] },
    { resource: API_RESOURCES.SHIPPING, configuration: [{}, { limit: 1 }] },
  ]);

  const bureau = useSelector(Selectors.getBureau);
  const shippings = useSelector(Selectors.getShippings);
  const locations = useSelector(Selectors.getLocations);

  const handleQuickCreateOrder = async () => {
    const orderPayload = {
      application_type: null,
      bureau: bureau.uri,
      due_date: new Date().toISOString(),
      custom_field_values: [],
      customer_email: `${generateRandomWord()}@authentise.com`,
      customer_name: generateRandomWord(),
      customer_po: null,
      ip_sensitivity: null,
      location: locations[0].uri,
      name: `Quick-Created Order for "${generateRandomWord()}"`,
      order_owner: null,
      order_type: null,
      priority: PRIORITIES.NORMAL,
      region: null,
      shipping: {
        address: `${Math.floor(Math.random() * 999)} ${generateRandomWord(5)} Road`,
        name: generateRandomWord(),
        tracking: null,
        uri: shippings[0].uri,
      },
      shipping_grouping: ORDER_SHIPPING_GROUPING_OPTIONS.BY_ORDER,
    };

    const response = await dispatch(Actions.Api.nautilus[API_RESOURCES.ORDER].post(orderPayload));
    const { location: createdOrderURI } = response.headers;

    navigate(getRouteURI(ROUTES.ORDER_EDIT, { uuid: extractUuid(createdOrderURI) }, {}, true));
  };

  return (
    <Button
      variant="warning"
      size="sm"
      onClick={handleQuickCreateOrder}
      className={`pull-right ${className}`}
      disabled={isCreating}
    >
      {isCreating ? <Loading /> : (
        <>
          <FontAwesomeIcon icon={faPlus} />{' '}
          Quick Add Order
        </>
      )}
    </Button>
  );
};

export default DebugModeQuickCreateOrder;

DebugModeQuickCreateOrder.propTypes = {
  className: PropTypes.string.isRequired,
};
