export default function splitTasksIntoParts(list, activeItemIndex) {
  // Trying to show no more nor less than 2 items on each side of the active item
  // (if there are enough items on the other side of the active item)
  const idealItemsCountBeforeActiveItem = 2;
  const idealItemsCountAfterActiveItem = 2;

  const lastItemIndex = list.length - 1;

  const currentCenteredItemIndex = Math.max(
    Math.min(
      activeItemIndex === -1 ? 0 : activeItemIndex,
      lastItemIndex - idealItemsCountAfterActiveItem,
    ),
    idealItemsCountBeforeActiveItem,
  );

  // 2 items to the left, or from the beginning
  const firstItemIndexInMiddleGroup = Math.max(
    0,
    currentCenteredItemIndex - idealItemsCountBeforeActiveItem,
  );

  // 2 items to the left, or till the end
  const firstItemIndexInNextGroup = Math.min(
    // Using +1 since slice does not include the `end` item
    lastItemIndex + 1,
    // `After` items count + currently Queued item
    currentCenteredItemIndex + idealItemsCountAfterActiveItem + 1,
  );

  return {
    previous: list.slice(0, firstItemIndexInMiddleGroup),
    middle: list.slice(firstItemIndexInMiddleGroup, firstItemIndexInNextGroup),
    // Slice does not include the `end` item, so using +1 to get all up to the last element
    next: list.slice(firstItemIndexInNextGroup, lastItemIndex + 1),
  };
}
