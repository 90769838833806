import PropTypes from 'prop-types';
import { FormattedMessage } from 'rapidfab/i18n';
import { getModels } from 'rapidfab/selectors';
import React, { useMemo } from 'react';
import { Image } from 'react-bootstrap';
import { useSelector } from 'react-redux';
import Loading from '../Loading';

const ModelView = ({ lineItemWorkflow }) => {
  const models = useSelector(getModels);
  const model = useMemo(() => models?.find(m => m.uri === lineItemWorkflow?.model) ?? null,
    [lineItemWorkflow, models],
  );
  const fetching = useSelector(state => state.ui.nautilus.model.get.fetching);
  const noModel = !model?.snapshot_content;

  if (fetching) {
    return (
      <div className="line-item-quote-modal-model-fetching">
        <Loading />
      </div>
    );
  }

  if (noModel) {
    return (
      <div
        className="line-item-quote-modal-no-model"
      >
        <div>
          <FormattedMessage
            id="line_item.no_model"
            defaultMessage="No Model"
          />
        </div>
      </div>
    );
  }

  return (
    <Image src={model.snapshot_content} className="PiecesCardRightDataCardModel" thumbnail />
  );
};

ModelView.propTypes = {
  lineItemWorkflow: PropTypes.shape({
    model: PropTypes.string,
  }).isRequired,
};

export default ModelView;
