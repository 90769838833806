import { createSelector } from 'reselect';
import * as baseStateSelectors from 'rapidfab/selectors/baseStateSelectors';
import { getStateResources } from 'rapidfab/selectors/helpers/base';
import _map from 'lodash/map';
import _keyBy from 'lodash/keyBy';

export const getMaterialTestOperations = createSelector(
  [baseStateSelectors.getStateMaterialTestOperations, getStateResources],
  (uuids, resources) => _map(uuids, uuid => resources[uuid]),
);

export const getMaterialTestOperationLinkings = createSelector(
  [baseStateSelectors.getStateMaterialTestOperationLinkings, getStateResources],
  (uuids, resources) => _map(uuids, uuid => resources[uuid]),
);

export const getMaterialTestInstructions = createSelector(
  [baseStateSelectors.getStateMaterialTestInstructions, getStateResources],
  (uuids, resources) => _map(uuids, uuid => resources[uuid]),
);

export const getMaterialTestInstructionsByUri = createSelector(
  [getMaterialTestInstructions], materialTestInstructions =>
    _keyBy(materialTestInstructions, 'uri'),
);

export const getMaterialTestUnits = createSelector(
  [baseStateSelectors.getStateMaterialTestUnits, getStateResources],
  (uuids, resources) => _map(uuids, uuid => resources[uuid]),
);

export const getMaterialTestUnitsByUri = createSelector(
  [getMaterialTestUnits], materialTestUnits =>
    _keyBy(materialTestUnits, 'uri'),
);

export const getMaterialTestInstructionReports = createSelector(
  [baseStateSelectors.getStateMaterialTestInstructionReports, getStateResources],
  (uuids, resources) => _map(uuids, uuid => resources[uuid]),
);

export const materialTestInstructionReportsByUri = createSelector(
  [getMaterialTestInstructionReports], materialTestInstructionReports =>
    _keyBy(materialTestInstructionReports, 'uri'),
);

export const getMaterialTestInstructionReportsByUri = createSelector(
  [getMaterialTestInstructionReports], materialTestPanels =>
    _keyBy(materialTestPanels, 'uri'),
);

export const getMaterialTestInstructionReportsByTestPanelUri = createSelector(
  [getMaterialTestInstructionReports], materialTestPanels =>
    _keyBy(materialTestPanels, 'material_test_panel'),
);

export const getMaterialTestPanels = createSelector(
  [baseStateSelectors.getStateMaterialTestPanels, getStateResources],
  (uuids, resources) => _map(uuids, uuid => resources[uuid]),
);

export const getMaterialTestPanelsByUri = createSelector(
  [getMaterialTestPanels], materialTestPanels =>
    _keyBy(materialTestPanels, 'uri'),
);
