import _clone from 'lodash/clone';
import { API_RESOURCES } from 'rapidfab/constants';
import Actions from 'rapidfab/actions';

export const findImpersonationUser = async (dispatch, usernameSearchString) => {
  const response = await dispatch(Actions.Api.nautilus[API_RESOURCES.USERS].list({
    username: usernameSearchString,
  }));
  const user = response?.json.resources[0];
  return user;
};

export const transformUsernameSearchString = usernameSearchString => {
  let searchString = _clone(usernameSearchString).trim();

  /* Check username includes @, and if we want to impersonate Authentise users only */
  if (!searchString.includes('@')) {
    const usernameHandle = searchString?.split('@')[0];

    /* Append `@authentise` to the end of the username to allow users to have the option of
      just entering handles */
    searchString = `${usernameHandle}@authentise.com`.trim();
    return searchString;
  }
  return searchString;
};
