import React from 'react';
import PropTypes from 'prop-types';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import { MODELER_STATUS_TOOLTIP_MAP } from 'rapidfab/mappings';
import { MODELER_STATUSES } from 'rapidfab/constants';

const ModelerStatusDot = ({ modeler }) => {
  const { status, error, latest_upload } = modeler;
  let {
    // Show mapped status message by default
    message,
    // Use mapped status class name by default
    status: statusClassName,
  } = MODELER_STATUS_TOOLTIP_MAP[status];

  if (error) {
    // In case custom error is provided - use it
    message = error;
    // Still use default Status class name
  }

  if (!error && !latest_upload) {
    // When there is no error - check if modeler connected ever
    message = 'The modeler has never connected';
    // For Non-connected modelers we use color from Scylla
    // (colors/styles might be updated later based on product needs)
    statusClassName = 'primary';
  }
  return (
    <OverlayTrigger
      placement="right"
      overlay={
        <Tooltip id="tooltip">{message}</Tooltip>
      }
    >
      <div className={`dot ${statusClassName}`} />
    </OverlayTrigger>
  );
};

ModelerStatusDot.defaultProps = {
  modeler: {
    status: MODELER_STATUSES.IDLE,
    error: null,
    latest_upload: null,
  },
};

ModelerStatusDot.propTypes = {
  modeler: PropTypes.shape({
    status: PropTypes.string,
    error: PropTypes.string,
    latest_upload: PropTypes.string,
  }),
};

export default ModelerStatusDot;
