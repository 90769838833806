import { createSelector } from 'reselect';
import _map from 'lodash/map';
import { getFeatures } from 'rapidfab/selectors/feature';
import { getPredicate, getStateResources } from 'rapidfab/selectors/helpers/base';
import * as baseStateSelectors from '../baseStateSelectors';

export const isFeatureEnabled = createSelector(
  [getPredicate, getFeatures],
  /** @returns {boolean} */
  (featureName, features) => features.some(
    feature => feature.name === featureName && feature.enabled,
  ),
);

export const someFeaturesEnabled = createSelector(
  [getPredicate, getFeatures],
  (featureNames, features) => features.some(
    feature => featureNames.includes(feature.name) && feature.enabled,
  ),
);

export const getEnabledFeatureNames = createSelector(
  [baseStateSelectors.getStateFeatures, getStateResources],
  // If feature is in the store, then this feature is enabled.
  // This action is guaranteed by backend
  (uuids, resources) => _map(uuids, uuid =>
    resources[uuid].enabled && resources[uuid].name,
  ).filter(Boolean),
);
