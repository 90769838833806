import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import Actions from 'rapidfab/actions';
import Alert from 'rapidfab/utils/alert';
import ShipmentAddressModal from 'rapidfab/components/records/ShipmentAddressModal';
import { API_RESOURCES, SHIPMENT_ADDRESS_TYPES } from 'rapidfab/constants';
import { shipmentResourceType } from 'rapidfab/types';
import { FormattedMessage } from 'react-intl';

const ShipmentAddressModalContainer = props => {
  const { shipment, addressType, onClose } = props;
  const initialFormValues = shipment[addressType];
  const dispatch = useDispatch();

  const onSave = payload => (
    dispatch(Actions.Api.nautilus[API_RESOURCES.SHIPMENT].put(payload.uuid, payload))
  );

  const [isSaving, setIsSaving] = useState(false);

  const onSubmit = formValues => {
    setIsSaving(true);
    const payload = { uuid: shipment.uuid, [addressType]: formValues };
    onSave(payload)
      .then(() => {
        Alert.success(<FormattedMessage
          id="toaster.shipment.addressUpdated"
          defaultMessage="Shipment address successfully updated"
        />);
        onClose();
      })
      .finally(() => {
        setIsSaving(false);
      });
  };

  return (
    <ShipmentAddressModal
      {...props}
      initialFormValues={initialFormValues}
      isSaving={isSaving}
      onSubmit={onSubmit}
    />
  );
};

ShipmentAddressModalContainer.propTypes = {
  shipment: shipmentResourceType.isRequired,
  onClose: PropTypes.func.isRequired,
  addressType: PropTypes.oneOf(Object.values(SHIPMENT_ADDRESS_TYPES)).isRequired,
};

export default ShipmentAddressModalContainer;
