import { Col, ListGroupItem, Row } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import SelectSingle from 'rapidfab/components/forms/SelectSingle';
import { PROCESS_STEP_SHIPPING_DIRECTION } from 'rapidfab/constants';
import { getEndpointFromURI } from 'rapidfab/utils/uriUtils';
import GuidelineSuggestionContext from 'rapidfab/context/GuidelineSuggestionContext';
import { faArrowRight, faArrows, faMinus } from '@fortawesome/free-solid-svg-icons';
import { faLightbulb } from '@fortawesome/free-regular-svg-icons';

// Printer and Post-Processor types may have specific workstations, while shipping NOT
const isSpecificWorkstationAllowed = processType => getEndpointFromURI(processType.uri).endpointName !== 'shipping';

const ProcessType = ({
  processType,
  isDisabled,
  index,
  removeSelectedAt,
  workstationsGroupedByType,
  selectWorkstation,
  selectWorkstationCheckboxChanged,
}) => {
  const guidelineSuggestionContext = useContext(GuidelineSuggestionContext);
  const { selectedGuidelineSuggestion } = guidelineSuggestionContext ?? {};
  const { suggestion } = selectedGuidelineSuggestion ?? {};

  let listGroupClasses = 'picklist-group-item';
  if (isDisabled) {
    listGroupClasses += ' picklist-group-item__disabled';
  }
  const processTypeKey = `${processType.id}+${index}`;
  return (
    <ListGroupItem key={processTypeKey} className={listGroupClasses} id={processType.id}>
      <Row className="picklist-group-item-row">
        <Col xs={1}>
          <div className="d-flex align-items-center">
            {!isDisabled && (
              <span
                className="me-1"
                tabIndex={index}
                role="button"
                onClick={() => removeSelectedAt(index)}
              >
                <FontAwesomeIcon icon={faMinus} />
              </span>
            )}

            <FontAwesomeIcon icon={faArrows} />
          </div>
        </Col>
        <Col xs={9}>
          {
            suggestion &&
            suggestion.process_step_template.workstation_type_uri ===
            processType.uri && (
              <div className="spacer-right">
                <i>
                  (Added suggestion
                  <FontAwesomeIcon icon={faLightbulb} className="spacer-left" />
                  )
                </i>
              </div>
            )
          }
          {/*
          TODO: The next 2 conditions were changed:
          Previously, Shipping Step did not have "workstation" and "workstation_type" values (were null),
          but after the recent BE update, we now have the workstation URI here and the condition ->
          "processType.specificWorkstation" works now. But we have no data there as it is a part of the huge change
          for the feature purposes and we do not have an actual shipping workstation.
          It is a temporary fix for the UI, to hide "Single Select" and show the name of the Shipping Step.
          */}
          {(
            !processType.specificWorkstation
            || (processType.specificWorkstation && !isSpecificWorkstationAllowed(processType))) && (
            <span>{processType.name}</span>
          )}
          {processType.specificWorkstation && isSpecificWorkstationAllowed(processType) && (
            <SelectSingle
              name={String(index)}
              data={workstationsGroupedByType[processType.uri] || []}
              value={processType.workstationUri}
              handleOnChange={selectWorkstation}
            />
          )}
        </Col>
        {isSpecificWorkstationAllowed(processType) && (
          <Col xs={2}>
            <input
              id={`selectWorkstationCheckbox${index}`}
              className="pull-right"
              checked={!!processType.specificWorkstation}
              type="checkbox"
              disabled={isDisabled}
              onChange={() => selectWorkstationCheckboxChanged(index)}
            />
          </Col>
        )}
        {processType.shippingDirection === PROCESS_STEP_SHIPPING_DIRECTION.BUREAU_TO_CUSTOMER && (
          <Col xs={2}>
            <FontAwesomeIcon icon={faArrowRight} className="pull-right" />
          </Col>
        )}
      </Row>
    </ListGroupItem>
  );
};

ProcessType.propTypes = {
  workstationsGroupedByType: PropTypes.shape({}).isRequired,
  removeSelectedAt: PropTypes.func.isRequired,
  selectWorkstationCheckboxChanged: PropTypes.func.isRequired,
  selectWorkstation: PropTypes.func.isRequired,
  processType: PropTypes.shape({
    shippingDirection: PropTypes.string,
    specificWorkstation: PropTypes.string,
    workstationUri: PropTypes.string,
    uri: PropTypes.string,
    name: PropTypes.string,
    id: PropTypes.string,
  }).isRequired,
  isDisabled: PropTypes.bool.isRequired,
  index: PropTypes.number.isRequired,
};

export default ProcessType;
