import { createSelector } from 'reselect';
import { getPredicate } from 'rapidfab/selectors/helpers/base';
import _filter from 'lodash/filter';
import { getModels } from 'rapidfab/selectors/model';

// eslint-disable-next-line import/prefer-default-export
export const getModelsForDesignFile = createSelector(
  [getPredicate, getModels],
  (designFile, models) => {
    if (!designFile) {
      return [];
    }
    return _filter(models, { design_file: designFile.uri });
  },
);
