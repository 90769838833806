import _isEmpty from 'lodash/isEmpty';
import _isEqual from 'lodash/isEqual';
import PropTypes from 'prop-types';
import usePrevious from 'rapidfab/hooks';
import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import _map from 'lodash/map';
import _uniq from 'lodash/uniq';

import Actions from 'rapidfab/actions';
import * as Selectors from 'rapidfab/selectors';
import { API_RESOURCES, FEATURES } from 'rapidfab/constants';
import withRecordsListHandling from 'rapidfab/containers/hocs/withRecordsListHandling';
import PostProcessorsComponent from 'rapidfab/components/assets/postProcessors';

const PostProcessorServicesContainer = props => {
  const postProcessorTypes = useSelector(Selectors.getPostProcessorTypesByUri);
  const isServiceProviderRole = useSelector(Selectors.isServiceProvider);
  const isGroupQualificationsFeatureEnabled = useSelector(state =>
    Selectors.isFeatureEnabled(state, FEATURES.GROUP_QUALIFICATIONS));

  const dispatch = useDispatch();
  const onInitialize = async () => {
    dispatch(Actions.Api.nautilus[API_RESOURCES.POST_PROCESSOR_TYPE].list());
  };

  const onInitializeAdditionalResources = async postProcessorServices => {
    if (!_isEmpty(postProcessorServices)) {
      const postProcessorUris = _map(postProcessorServices, 'uri');

      const processProviderResponse = await dispatch(Actions.Api.nautilus[API_RESOURCES.PROCESS_PROVIDER]
        .list({ post_processor: postProcessorUris }));

      if (!_isEmpty(processProviderResponse.json?.resources)) {
        const serviceProviderUris = _uniq(_map(processProviderResponse.json.resources, 'service_provider'));
        await dispatch(Actions.Api.nautilus[API_RESOURCES.SERVICE_PROVIDER].list({ uri: serviceProviderUris }));
      }
    }
  };

  useEffect(() => {
    // eslint-disable-next-line no-return-await
    (async () => await onInitialize())();
  }, []);

  const previousData = usePrevious(_map(props.data, 'uri'));

  useEffect(() => {
    if (!_isEqual(previousData, _map(props.data, 'uri'))) {
      onInitializeAdditionalResources(props.data);
    }
  }, [props.data]);

  return (
    <PostProcessorsComponent
      {...props}
      isServices
      postProcessorTypes={postProcessorTypes}
      isServiceProviderRole={isServiceProviderRole}
      isGroupQualificationsFeatureEnabled={isGroupQualificationsFeatureEnabled}
    />
  );
};

PostProcessorServicesContainer.propTypes = {
  data: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
};

export default withRecordsListHandling(
  PostProcessorServicesContainer,
  Selectors.getServicePostProcessorsWithServiceProviderData,
  ['post-processor'],
  {
    defaultLimit: 25,
    staticQueryParams: {
      // Show only active Assignments
      is_service: true,
    },
  },
);
