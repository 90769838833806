import { faQuestionCircle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import _find from 'lodash/find';
import PropTypes from 'prop-types';
import BreadcrumbNav from 'rapidfab/components/BreadcrumbNav';
import Loading from 'rapidfab/components/Loading';
import SaveButtonTitle from 'rapidfab/components/SaveButtonTitle';
import { selectInputStyles } from 'rapidfab/constants/styles';
import NewOrderFormContainer from 'rapidfab/containers/records/order/NewOrderFormContainer';
import React from 'react';
import { Button, ButtonToolbar, Card, Col, Container, Form as BSForm, Row } from 'react-bootstrap';
import { Form } from 'react-final-form';
import { FormattedMessage } from 'react-intl';
import Select from 'react-select';

const NewOrderComponent = ({
  bannerMessage,
  bannerLink,
  isUserRestricted,
  onSubmit,
  submitting,
  initCustomFieldValues,
  onCustomFieldChange,
  customOrderFieldReferences,
  orderTemplateProps,
  setInitialOrderFields,
}) => {
  const {
    formattedOrderTemplates,
    orderTemplateUri,
    handleSetOrderTemplate,
    orderTemplatesFetching,
    handleClearForm,
  } = orderTemplateProps;

  const onTemplateChange = (selectedOption, form) => {
    // Null - for react-select clear option
    if (selectedOption === null) {
      return handleClearForm(form);
    }

    return handleSetOrderTemplate(selectedOption?.value, form);
  };

  const breadcrumbs = ['plan', 'orders', 'New Order'];
  const orderTemplateValue = _find(formattedOrderTemplates,
    { value: orderTemplateUri }) || null;

  return (
    <Container fluid>
      <BreadcrumbNav breadcrumbs={breadcrumbs} />

      <Form
        onSubmit={onSubmit}
        mutators={{
          initCustomFieldValues,
          onCustomFieldChange,
        }}
        render={({ handleSubmit, form, values }) => (
          <BSForm id="new" horizontal onSubmit={handleSubmit}>
            <Row>
              <Col xs={4}>
                <Select
                  options={formattedOrderTemplates}
                  onChange={selectedOption => onTemplateChange(selectedOption, form)}
                  placeholder="Select Order Template"
                  value={orderTemplateValue}
                  styles={selectInputStyles}
                  isLoading={orderTemplatesFetching}
                  components={{ LoadingIndicator: () => (<Loading inline className="spacer-right" />) }}
                  isClearable
                />
              </Col>
              <Col xs={8}>
                <div className="pull-right d-flex">
                  <Button
                    disabled={submitting}
                    type="submit"
                    value="submit"
                    variant="success"
                    size="sm"
                  >
                    {submitting ? <Loading /> : <SaveButtonTitle resourceName="Order" />}
                  </Button>
                </div>

              </Col>
            </Row>
            <div className="pull-right">
              <a href="https://authentise.zendesk.com/hc/en-us/articles/115003329226-Creating-an-Order">
                <FormattedMessage id="help.link" defaultMessage="Help" />
                <FontAwesomeIcon className="spacer-left" icon={faQuestionCircle} />
              </a>
            </div>
            <hr />

            {isUserRestricted && bannerMessage && (
              <div className="jumbotron">
                <p>{bannerMessage}</p>
                <Button href={bannerLink}>
                  <FormattedMessage
                    id="moreInformation"
                    defaultMessage="More Information"
                  />
                </Button>
              </div>
            )}

            <Card bg="dark">
              <Card.Header className="pd-exp inverse">Order</Card.Header>
              <div className="card-body-wrapper">
                <Card.Body>
                  <NewOrderFormContainer
                    formValues={values}
                    initCustomFieldValues={form.mutators.initCustomFieldValues}
                    onCustomFieldChange={form.mutators.onCustomFieldChange}
                    customOrderFieldReferences={customOrderFieldReferences}
                    setInitialOrderFields={setInitialOrderFields}
                  />
                </Card.Body>
              </div>
            </Card>

            <ButtonToolbar
              className="clearfix"
              style={{
                marginTop: '0.5rem',
                marginBottom: '3rem',
              }}
            >
              <div className="flexed-pull-right">
                <Button
                  id="new"
                  type="submit"
                  variant="success"
                  onClick={() => window.scrollTo(0, 0)}
                >
                  Add Line Items
                </Button>
              </div>
            </ButtonToolbar>
          </BSForm>
        )}
      />
    </Container>
  );
};

NewOrderComponent.defaultProps = {
  bannerMessage: null,
  bannerLink: null,
};

NewOrderComponent.propTypes = {
  bannerMessage: PropTypes.string,
  bannerLink: PropTypes.string,
  isUserRestricted: PropTypes.bool.isRequired,
  onSubmit: PropTypes.func.isRequired,
  submitting: PropTypes.bool.isRequired,
  initCustomFieldValues: PropTypes.func.isRequired,
  onCustomFieldChange: PropTypes.func.isRequired,
  customOrderFieldReferences: PropTypes.arrayOf(PropTypes.string).isRequired,
  orderTemplateProps: PropTypes.shape({
    formattedOrderTemplates: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
    orderTemplateUri: PropTypes.string.isRequired,
    handleSetOrderTemplate: PropTypes.func.isRequired,
    orderTemplatesFetching: PropTypes.bool.isRequired,
    handleClearForm: PropTypes.func.isRequired,
  }).isRequired,
  handleClearForm: PropTypes.func.isRequired,
  setInitialOrderFields: PropTypes.func.isRequired,
};

export default NewOrderComponent;
