import React, { useState } from 'react';
import PropTypes from 'prop-types';
import {
  Button,
  Form, Col,
  Modal,
  ModalBody,
  ModalHeader,
  ModalTitle, Row,
} from 'react-bootstrap';
import Loading from 'rapidfab/components/Loading';

const LineItemPrepWorkflowCompleteModal = ({
  isSubmitting,
  onClose,
  onLineItemConfirm,
  onLineItemLeavePending,
  prepWorkflow,
  lineItemName,
}) => {
  const [alwaysApply, setAlwaysApply] = useState(false);
  return (
    <Modal show onHide={onClose} backdrop="static">
      <ModalHeader closeButton>
        <ModalTitle>
          Completed Prep Workflow
        </ModalTitle>
      </ModalHeader>
      <ModalBody>
        <Row>
          <Col md={{ span: 10, offset: 1 }}>
            <div className="mb15">
              This is the last task in <b>{prepWorkflow.name}</b> prep workflow{lineItemName ? <> for line item <b>{lineItemName}</b>.</> : '.' }
            </div>
            <div className="text-center mb15">
              <Button
                className="mr15"
                type="button"
                variant="success"
                disabled={isSubmitting}
                onClick={() => onLineItemConfirm(alwaysApply)}
              >
                { isSubmitting ? <Loading /> : 'Confirm Line Item' }
              </Button>
              <Button
                className="ml15"
                type="button"
                variant="info"
                disabled={isSubmitting}
                onClick={() => onLineItemLeavePending(alwaysApply)}
              >
                Leave Line Item as Pending
              </Button>
            </div>
            <div className="mb15">
              Would you like your answer to be applied to all future prep workflows?
            </div>
            <div>
              <Form.Check
                name="alwaysApply"
                value={alwaysApply}
                onChange={({ target }) => setAlwaysApply(target.checked)}
                type="checkbox"
                label="Always apply answer"
              />
            </div>
          </Col>
        </Row>
      </ModalBody>
    </Modal>
  );
};

LineItemPrepWorkflowCompleteModal.propTypes = {
  prepWorkflow: PropTypes.shape({
    name: PropTypes.string.isRequired,
  }).isRequired,
  lineItemName: PropTypes.string.isRequired,
  isSubmitting: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  onLineItemConfirm: PropTypes.func.isRequired,
  onLineItemLeavePending: PropTypes.func.isRequired,
};

export default LineItemPrepWorkflowCompleteModal;
