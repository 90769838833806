import _find from 'lodash/find';
import _get from 'lodash/get';
import _isEmpty from 'lodash/isEmpty';
import PropTypes from 'prop-types';
import Actions from 'rapidfab/actions';
import ImpersonationModal from 'rapidfab/components/modals/ImpersonationModal';
import {
  API_RESOURCES,
  IMPERSONATION_FAVORITE_USERS_LIST,
  IMPERSONATION_USER_PROFILE_CONTEXT,
} from 'rapidfab/constants';
import usePrevious, { useIsPageReloading } from 'rapidfab/hooks';
import useFavoriteUsers from 'rapidfab/hooks/useFavoriteUsers';
import * as Selectors from 'rapidfab/selectors';
import Alert from 'rapidfab/utils/alert';
import { transformUsernameSearchString } from 'rapidfab/utils/impersonation';
import isFetchingInitial from 'rapidfab/utils/isFetchingInitial';
import React, { useEffect, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';

const ImpersonationModalContainer = ({ session, handleCloseModal }) => {
  const bureau = useSelector(Selectors.getBureau);
  const users = useSelector(Selectors.getUsers);
  const usersFetching = useSelector(state => state.ui.nautilus[API_RESOURCES.USERS].list.fetching);
  const isInitialLoading = useSelector(state => isFetchingInitial(state.ui.nautilus[API_RESOURCES.USERS].list));
  const isImpersonatingLoading = useSelector(
    state => state.ui.nautilus[API_RESOURCES.SESSIONS].put.fetching);

  const {
    favoriteUsers,
    addFavoriteUser,
    removeFavoriteUser,
    renameFavoriteUserName,
  } = useFavoriteUsers(JSON.parse(localStorage.getItem(IMPERSONATION_FAVORITE_USERS_LIST)) || []);

  const isPageCurrentlyReloading = useIsPageReloading();

  const userUriAlreadyImpersonating = _get(session, 'currentUser.impersonating');

  // Current user input value which should be user email
  const [userEmail, setUserEmail] = useState('');
  // Custom error under the input field
  const [userError, setUserError] = useState(null);
  // The user which was found when you type the user email and click "search"
  const [selectedUser, setSelectedUser] = useState(null);
  // The user you are currently impersonating
  const [impersonatedUser, setImpersonatedUser] = useState(null);
  // The state which shows the Edit Favorite Username input field
  const [favoriteUserNameToEdit, setFavoriteUserNameToEdit] = useState(null);
  // The local value of the favorite user input name field
  const [favoriteUserNameValue, setFavoriteUserNameValue] = useState(null);
  // The state which shows the loading spinner on the impersonation button
  const [impersonationLoadingStates, setImpersonationLoadingStates] = useState({});

  const [emailsCopiedState, setEmailsCopiedState] = useState({});

  const dispatch = useDispatch();

  const handleToggleImpersonationLoadingState = (userUri, loadingState, context) => {
    if (!userUri || !context) return;
    setImpersonationLoadingStates(previous => ({ ...previous, [`${userUri}_${context}`]: loadingState }));
  };

  const handleToggleUserEmailCopyState = (email, copyState, context) => {
    if (!email || !context) return;
    setEmailsCopiedState(previous => ({ ...previous, [`${email}_${context}`]: copyState }));
  };

  const handleCopyEmail = async (email, context) => {
    try {
      await navigator.clipboard.writeText(email);
      handleToggleUserEmailCopyState(email, true, context);
      setTimeout(() => {
        handleToggleUserEmailCopyState(email, false, context);
      }, 2000); // Reverts the copied state back after 2 seconds
    } catch {
      Alert.error(
        <FormattedMessage
          id="impersonation.errorCopyEmail"
          defaultMessage="Couldn't copy the email. Please try again."
        />);
    }
  };

  // Fetch only users from the current bureau on the initial load
  const onInitialFetchUsersByBureau = async () => {
    await dispatch(Actions.Api.nautilus[API_RESOURCES.USERS].clear());
    // 100 users pre-fetch
    await dispatch(Actions.Api.nautilus[API_RESOURCES.USERS]
      .list({ bureau: bureau.uri }, { limit: 100 }, {}, {}, true));
  };

  const findUserByFilter = async filter => {
    if (!filter) return null;
    setUserError(null);
    const userResponse =
      await dispatch(Actions.Api.nautilus[API_RESOURCES.USERS].list(filter));

    return userResponse?.json?.resources?.[0];
  };

  // The method which is called when you click "search" button
  // Either selects the user from the list or fetches the user by the email
  const handleSearchUser = async userEmail => {
    if (_isEmpty(userEmail) || userEmail === '') {
      return;
    }
    // On each new search - clear the current selected user
    setSelectedUser(null);

    const availableUser = _find(users, ['username', userEmail]);

    // If we have the user pre-fetched in the user's list, no need to search again
    if (availableUser) {
      setSelectedUser(availableUser);
    } else {
      await dispatch(Actions.Api.nautilus[API_RESOURCES.USERS].clear());
      try {
        // Otherwise, find the user by the email
        const fetchedUser = await findUserByFilter({ username: transformUsernameSearchString(userEmail) });
        if (fetchedUser) {
          setSelectedUser(fetchedUser);
        } else {
          setUserError('The user was not found. Please check the user email and try again.');
        }
      } catch {
        setUserError((
          <FormattedMessage
            id="impersonation.somethingWentWrong"
            defaultMessage="Something went wrong, please try again later."
          />
        ));
      }
    }
  };

  const endImpersonation = async (
    shouldReload = true,
    userUri,
    context,
  ) => {
    handleToggleImpersonationLoadingState(userUri, true, context);
    try {
      await dispatch(Actions.Api.nautilus[API_RESOURCES.SESSIONS]
        .put(0, { impersonating: null }));
    } catch (error) {
      Alert.error(error.message);
    } finally {
      handleToggleImpersonationLoadingState(userUri, false, context);
    }

    if (shouldReload) {
      window.location.reload();
    }
  };

  const onImpersonate = async (currentUser, context) => {
    const userUri = currentUser || selectedUser?.uri;
    if (!userUri) return;

    if (userUriAlreadyImpersonating) {
      await endImpersonation(false, null, context);
    }

    try {
      handleToggleImpersonationLoadingState(userUri, true, context);
      await dispatch(Actions.Api.nautilus[API_RESOURCES.SESSIONS].put(
        0,
        { impersonating: userUri },
      ));
      window.location.reload();
    } catch {
      setUserError((
        <FormattedMessage
          id="impersonation.somethingWentWrong"
          defaultMessage="Something went wrong, please try again later."
        />
      ));
    } finally {
      handleToggleImpersonationLoadingState(userUri, false, context);
    }
  };

  const previousUser = usePrevious(selectedUser);
  const previousUserEmail = usePrevious(userEmail);

  const isUserToSearchAlreadySelected = previousUser?.username === userEmail || previousUserEmail === userEmail;

  // Select / Fetch the impersonated user on the initial load (if we are already impersonating someone)
  const handleGetImpersonatedUser = async () => {
    if (userUriAlreadyImpersonating) {
      // Check if we already have this user from the users list
      const impersonatedUser = _find(users, ['uri', userUriAlreadyImpersonating]);

      // If the user we impersonate is already in the user's list - no need to fetch it
      if (impersonatedUser) {
        setImpersonatedUser(impersonatedUser);
      } else {
        try {
          // Otherwise, find the user by the user URI (which is the user we are impersonating)
          const fetchedUser = await findUserByFilter({ uri: userUriAlreadyImpersonating });

          if (fetchedUser) {
            setImpersonatedUser(fetchedUser);
          } else {
            setUserError((
              <FormattedMessage
                id="impersonation.userNotFound"
                defaultMessage="The user was not found. Please check the user email and try again."
              />
            ));
          }
        } catch {
          setUserError((
            <FormattedMessage
              id="impersonation.somethingWentWrong"
              defaultMessage="Something went wrong, please try again later."
            />
          ));
        }
      }
    }
  };

  // This method tries to find the user by the email and impersonate it on "Enter" press
  const onImpersonateEnterPress = async email => {
    const user = await findUserByFilter({ username: transformUsernameSearchString(email) });

    if (_isEmpty(user)) {
      Alert.error(`User "${email}" couldn't be found.`);
    }

    if (user) {
      await onImpersonate(user.uri, IMPERSONATION_USER_PROFILE_CONTEXT.SEARCH);
    } else {
      setUserError((
        <FormattedMessage
          id="impersonation.userNotFound"
          defaultMessage="The user was not found. Please check the user email and try again."
        />
      ));
    }
  };

  const isUserFavorite = userEmail => favoriteUsers.find(favoriteUser => favoriteUser.username === userEmail);
  const handleFavoriteUser = userEmail => {
    const isAlreadyFavorite = favoriteUsers.some(user => user.username === userEmail);

    if (isAlreadyFavorite) {
      removeFavoriteUser(userEmail);
    } else {
      const allCurrentUsers = [...users, selectedUser, impersonatedUser].filter(Boolean);
      const currentUser = _find(allCurrentUsers, ['username', userEmail]);

      if (currentUser) {
        addFavoriteUser(currentUser);
      } else {
        setUserError((
          <FormattedMessage
            id="impersonation.favoriteUserError"
            defaultMessage="Something went wrong while trying to favorite the user."
          />
        ));
      }
    }
  };

  const handleEditFavoriteUserName = (userEmail, newUserName) => {
    if (!userEmail || !newUserName) return;
    renameFavoriteUserName(userEmail, newUserName);
    setFavoriteUserNameToEdit(null);
  };

  const handleSetFavoriteUserToEdit = email => {
    const currentUser = favoriteUsers.find(user => user.username === email);
    if (currentUser) {
      setFavoriteUserNameToEdit(currentUser);
      setFavoriteUserNameValue(currentUser.name);
    }
  };

  const findImpersonatedUserEditedName = currentlyImpersonatedUser => {
    if (Array.isArray(favoriteUsers)) {
      const favoriteUser = favoriteUsers.find(({ username }) => username === currentlyImpersonatedUser.username);
      return favoriteUser?.name || currentlyImpersonatedUser.name;
    }
    return currentlyImpersonatedUser.name;
  };

  useEffect(() => {
    onInitialFetchUsersByBureau();
  }, []);

  useEffect(() => {
    handleGetImpersonatedUser();
  }, [userUriAlreadyImpersonating]);

  return (
    <ImpersonationModal
      user={selectedUser}
      fetchingProps={{
        isPageCurrentlyReloading,
        isImpersonatingLoading,
        usersFetching,
        isInitialLoading,
        impersonationLoadingStates,
      }}
      handleSearchUser={handleSearchUser}
      onImpersonateEnterPress={onImpersonateEnterPress}
      onImpersonate={onImpersonate}
      endImpersonation={endImpersonation}
      emailProps={{
        handleCopyEmail,
        emailsCopiedState,
      }}
      handleCloseModal={handleCloseModal}
      userAdditionalProps={{
        userEmail,
        setUserEmail,
        isUserToSearchAlreadySelected,
        userError,
        impersonatedUser,
        findImpersonatedUserEditedName,
        isAlreadyImpersonating: !!userUriAlreadyImpersonating,
        impersonatedUserUri: userUriAlreadyImpersonating,
      }}
      favoriteUsersProps={{
        favoriteUsers,
        favoriteUserNameToEdit,
        handleSetFavoriteUserToEdit,
        handleEditFavoriteUserName,
        setFavoriteUserNameValue,
        favoriteUserNameValue,
        isUserFavorite,
        handleFavoriteUser,
      }}
    />
  );
};

ImpersonationModalContainer.propTypes = {
  session: PropTypes.shape({
    currentUser: PropTypes.shape({
      impersonating: PropTypes.string,
    }),
  }).isRequired,
  handleCloseModal: PropTypes.func.isRequired,
};

export default ImpersonationModalContainer;
