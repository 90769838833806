import React, { useState, useEffect } from 'react';
import Pagination from 'react-bootstrap/Pagination';
import PropTypes from 'prop-types';

const PaginationBar = ({ onPageNumberSelect, numberOfPages, currentPageNumber, size }) => {
  const [pageArrayState, setPageArrayState] = useState([]);

  useEffect(() => {
    const totalPages = Number(numberOfPages.length);
    const currentPage = Number(currentPageNumber);
    let pageArray = [];

    if (totalPages > 1) {
      if (totalPages <= 9) {
        let index = 1;
        while (index <= totalPages) {
          pageArray.push(index);
          index += 1;
        }
      } else if (currentPage <= 5) pageArray = [1, 2, 3, 4, 5, 6, 7, 8, '', totalPages];
      else if (totalPages - currentPage <= 4) {
        pageArray = [
          1,
          '',
          totalPages - 7,
          totalPages - 6,
          totalPages - 5,
          totalPages - 4,
          totalPages - 3,
          totalPages - 2,
          totalPages - 1,
          totalPages,
        ];
      } else {
        pageArray = [
          1,
          '',
          currentPage - 3,
          currentPage - 2,
          currentPage - 1,
          currentPage,
          currentPage + 1,
          currentPage + 2,
          currentPage + 3,
          '',
          totalPages,
        ];
      }
    }
    setPageArrayState(pageArray);
  }, [numberOfPages, currentPageNumber]);
  return (

    <Pagination size={size || 'lg'} md={6} sm={3}>
      {pageArrayState.map((element, index) => {
        const paginationReactElements = [];
        if (index === 0) {
          paginationReactElements.push(
            <Pagination.First
              key="firstpage"
              onClick={
                currentPageNumber === 1
                  ? () => {}
                  : () => {
                    onPageNumberSelect(1);
                  }
              }
            />,

            <Pagination.Prev
              key="prevpage"
              onClick={
                currentPageNumber === 1
                  ? () => {}
                  : () => {
                    onPageNumberSelect(currentPageNumber - 1);
                  }
              }
            />,
          );
        }
        if (element === '') paginationReactElements.push(<Pagination.Ellipsis key={element} />);
        else {
          paginationReactElements.push(
            <Pagination.Item
              key={element}
              active={currentPageNumber === element}
              onClick={
                currentPageNumber === element
                  ? () => {}
                  : () => {
                    onPageNumberSelect(element);
                  }
              }
            >
              {element}
            </Pagination.Item>,
          );
        }
        if (index === pageArrayState.length - 1) {
          paginationReactElements.push(
            <Pagination.Next
              key="nextpage"
              onClick={
                currentPageNumber === element
                  ? () => {}
                  : () => {
                    onPageNumberSelect(currentPageNumber + 1);
                  }
              }
            />,

            <Pagination.Last
              key="lastpage"
              onClick={
                currentPageNumber === element
                  ? () => {}
                  : () => {
                    onPageNumberSelect(element);
                  }
              }
            />,
          );
        }
        return paginationReactElements;
      })}
    </Pagination>

  );
};

PaginationBar.propTypes = {
  onPageNumberSelect: PropTypes.func.isRequired,
  numberOfPages: PropTypes.arrayOf(PropTypes.number).isRequired,
  currentPageNumber: PropTypes.number.isRequired,
  size: PropTypes.string.isRequired,

};

export default PaginationBar;
