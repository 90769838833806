import React from 'react';
import PropTypes from 'prop-types';
import { Button, FormControl, ButtonToolbar, Row, Col } from 'react-bootstrap';
import _range from 'lodash/range';
import PaginationBar from 'rapidfab/components/RecordList/PaginationBar';

const Pagination = ({
  listStore,
  offset,
  limit,
  onPageChange,
  onSetPage,
  size,
}) => {
  let pageCount = null;
  let currentPageNumber = null;
  let availablePages = [];

  const isAvailableNextLink = Boolean(listStore?.links && listStore?.links.next);
  const isAvailablePreviousLink = Boolean(
    listStore?.links && listStore?.links.prev,
  );
  const isAvailableTotalCount = Boolean(listStore?.meta && listStore?.meta.count);

  if (isAvailableNextLink || isAvailablePreviousLink || isAvailableTotalCount) {
    pageCount = listStore.meta.count && Math.ceil(listStore.meta.count / limit, 10);
    currentPageNumber = Number.parseInt(offset / limit, 10) + 1;

    // pageCount + 1 because _.range doesn't include last page
    // _range(1, 3) => [1, 2], but needed [1, 2, 3]
    availablePages = _range(1, pageCount + 1);
  }

  const onPageSelect = page => {
    if (onSetPage && pageCount) {
      return onSetPage(page - 1);
    }
    const pageOffset = (page - 1) * limit;
    return onPageChange(pageOffset);
  };

  if (!pageCount) {
    // Render Next/Prev buttons as a fall-back,
    // for cases, when page count is not available in the response headers
    // It will also be rendered when there are no results
    return (
      <ButtonToolbar className="" style={{ marginBottom: '.5em' }}>
        <Button
          disabled={!isAvailablePreviousLink}
          onClick={() => {
            onPageChange(offset - limit);
          }}
          size="sm"
          style={{ marginRight: '5px' }}
        >
          Prev
        </Button>
        <Button
          disabled={!isAvailableNextLink}
          size="sm"
          onClick={() => {
            onPageChange(offset + limit);
          }}
        >
          Next
        </Button>
      </ButtonToolbar>
    );
  }
  return (
    <Row className="mb15">
      <Col lg={9} md={12} sm={8} xs>
        <PaginationBar
          currentPageNumber={currentPageNumber}
          numberOfPages={availablePages}
          onPageNumberSelect={pageNumber => onPageSelect(pageNumber)}
          size={size}
        />
      </Col>
      <Col lg={3} md={12} sm={4}>

        <FormControl
          as="select"
          value={currentPageNumber}
          onChange={event => onPageSelect(event.target.value)}
        >
          {availablePages.map(page => (
            <option key={page} value={page}>
              Page {page}
            </option>
          ))}
        </FormControl>
      </Col>
    </Row>
  );
};

Pagination.propTypes = {
  listStore: PropTypes.shape({
    links: PropTypes.shape({
      next: PropTypes.string,
      prev: PropTypes.string,
    }),
    meta: PropTypes.shape({
      count: PropTypes.number,
    }),
  }).isRequired,
  offset: PropTypes.number.isRequired,
  limit: PropTypes.number.isRequired,
  onPageChange: PropTypes.func.isRequired,
  size: PropTypes.string,
  onSetPage: PropTypes.func,
};

Pagination.defaultProps = {
  size: 'lg',
  onSetPage: null,
};

export default Pagination;
