import { createSelector } from 'reselect';
import * as baseStateSelectors from 'rapidfab/selectors/baseStateSelectors';
import { getStateResources } from 'rapidfab/selectors/helpers/base';
import _map from 'lodash/map';
import _filter from 'lodash/filter';
import _keyBy from 'lodash/keyBy';

// eslint-disable-next-line import/prefer-default-export
export const getComments = createSelector(
  [baseStateSelectors.getStateComments, getStateResources],
  (uuids, resources) => _map(uuids, uuid => resources[uuid]),
);
export const getModelLibraryComments = createSelector(
  [getComments],
  comments => _filter(comments, { related_table_name: 'model_library' }),
);

export const getModelLibraryCommentsByModelUUID = createSelector(
  [getModelLibraryComments],
  modelLibraryComments => _keyBy(modelLibraryComments, 'related_uuid'),
);
