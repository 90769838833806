import { faCheck, faChevronDown, faChevronUp, faTrash } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import _capitalize from 'lodash/capitalize';
import PropTypes from 'prop-types';
import Actions from 'rapidfab/actions';
import Loading from 'rapidfab/components/Loading';
import Alert from 'rapidfab/utils/alert';
import { truncateText } from 'rapidfab/utils/stringUtils';
import { getEndpointFromURI } from 'rapidfab/utils/uriUtils';
import React, { useState } from 'react';
import { Badge, Button, Card, OverlayTrigger, Table, Tooltip, FormControl } from 'react-bootstrap';
import { FormattedMessage } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import Constants from 'rapidfab/constants';
import { DebugModeBadge } from 'rapidfab/components/DebugMode/DebugModeComponents';
import { faClone } from '@fortawesome/free-regular-svg-icons';

const DebugModeDataPanel = props => {
  const { data, style, className } = props;

  const dispatch = useDispatch();

  let resourceTypeName = null;

  // Guard clause: If getEndpointFromUri is called on blank data, it throws an error.
  if (data?.uri) {
    resourceTypeName = getEndpointFromURI(data?.uri).endpointName;
  }

  const isDeleting = useSelector(state =>
    state.ui.nautilus[resourceTypeName]?.delete.fetching);
  const deletedResourceUuid = useSelector(state =>
    state.ui.nautilus[resourceTypeName]?.delete.uuid);

  const [isOpen, setIsOpen] = useState(false);
  const [copiedValueState, setCopiedValueState] = useState({});

  const handleDeleteResource = async () => {
    const resourceDeletionResponse = await dispatch(Actions.Api.nautilus[resourceTypeName]?.delete(data?.uuid))
      .catch(error => Alert.error(error.message));

    if (resourceDeletionResponse && resourceDeletionResponse.type === Constants.RESOURCE_DELETE_SUCCESS) {
      Alert.success(
        <FormattedMessage
          id="toaster.resource.deleted"
          defaultMessage="Resource successfully deleted."
        />,
      );
    }
  };

  const handleCopyToClipboard = value => {
    try {
      navigator.clipboard.writeText(value);
    } catch (error) {
      Alert.error("There was an issue copying to clipboard, this may be due to your browser's privacy settings.");
      console.error(error);
    }
  };

  return (
    <Card style={style} className={`${className} w-100`}>
      <Card.Header>
        <p>
          <DebugModeBadge />
          {`Data for '${data?.name}'` || 'Data'}
          <Button variant="success" onClick={() => setIsOpen(!isOpen)} size="xs" className="pull-right">
            {isOpen ? 'Close' : 'Open'}
            <FontAwesomeIcon className="spacer-left" icon={isOpen ? faChevronUp : faChevronDown} />
          </Button>
          <Button
            onClick={() => {
              handleCopyToClipboard(data?.uuid);
              setCopiedValueState({
                uuid: true,
              });
            }}
            size="xs"
            className="pull-right spacer-right"
          >
            Copy UUID
            <FontAwesomeIcon className="spacer-left" icon={copiedValueState.uuid ? faCheck : faClone} />
          </Button>
          <Button
            onClick={() => {
              handleCopyToClipboard(data?.uri);
              setCopiedValueState({
                uri: true,
              });
            }}
            size="xs"
            className="pull-right spacer-right"
          >
            Copy URI
            <FontAwesomeIcon className="spacer-left" icon={copiedValueState.uri ? faCheck : faClone} />
          </Button>
          <Button
            onClick={() => {
              handleCopyToClipboard(JSON.stringify(data));
              setCopiedValueState({
                json: true,
              });
            }}
            size="xs"
            className="pull-right spacer-right"
          >
            Copy JSON
            <FontAwesomeIcon className="spacer-left" icon={copiedValueState.json ? faCheck : faClone} />
          </Button>
          {resourceTypeName && (
            <Button
              variant="danger"
              onClick={handleDeleteResource}
              size="xs"
              disabled={isDeleting && data?.uuid === deletedResourceUuid}
              className="pull-right spacer-right"
            >
              {isDeleting && data?.uuid === deletedResourceUuid ? (
                <Loading />
              ) : (
                <FormattedMessage
                  id="button.delete"
                  defaultMessage="Delete"
                />
              )}
              {' '}
              {_capitalize(resourceTypeName)}
              <FontAwesomeIcon className="spacer-left" icon={faTrash} />
            </Button>
          )}
        </p>
      </Card.Header>
      {isOpen && (
        <Card.Body>
          <div>
            <Table size="sm">
              <p>
                UUID:
                <Badge
                  bg=""
                  role="button"
                  onClick={() => {
                    handleCopyToClipboard(data?.uuid);
                    setCopiedValueState({
                      uuid: true,
                    });
                  }}
                  className="text-white spacer-left badge badge-sm"
                >
                  {data?.uuid ?? 'N/A'}
                </Badge>
              </p>
              <p>
                URI:
                <Badge
                  bg=""
                  className="text-white spacer-left badge badge-sm"
                  role="button"
                  onClick={() => {
                    handleCopyToClipboard(data?.uri);
                    setCopiedValueState({
                      uri: true,
                    });
                  }}
                >
                  {data?.uri ?? 'N/A'}
                </Badge>
              </p>
              <p>
                JSON object:
                <Badge
                  role="button"
                  bg=""
                  className="text-white spacer-left badge badge-sm"
                  onClick={() => {
                    handleCopyToClipboard(JSON.stringify(data));
                    setCopiedValueState({
                      json: true,
                    });
                  }}
                >
                  {truncateText(JSON.stringify(data), 50) ?? 'Not found'}
                </Badge>
              </p>
            </Table>
          </div>
          {
            Object.entries(data).map(([key, value]) => (
              <OverlayTrigger
                overlay={(
                  <Tooltip>
                    {
                      copiedValueState[key] ?
                        <p>Copied</p>
                        :
                        <p>Click to copy</p>
                    }
                  </Tooltip>
                )}
              >
                <p>
                  {key}:
                  <FormControl
                    onClick={() => {
                      handleCopyToClipboard(JSON.stringify(value));
                      setCopiedValueState({ [key]: true });
                    }}
                    value={JSON.stringify(value)}
                    className="flex-1 h5 w-100 p-0"
                  />
                </p>
              </OverlayTrigger>
            ))
          }
        </Card.Body>
      )}
    </Card>
  );
};

export default DebugModeDataPanel;

DebugModeDataPanel.propTypes = {
  data: PropTypes.shape({
    uri: PropTypes.string,
    uuid: PropTypes.string,
    json: PropTypes.shape({}),
    name: PropTypes.string,
  }).isRequired,
  style: PropTypes.shape({}).isRequired,
  className: PropTypes.string.isRequired,
};
