import React from 'react';
import PropTypes from 'prop-types';
import { Badge, OverlayTrigger, Tooltip } from 'react-bootstrap';
import { FormattedMessage } from 'rapidfab/i18n';
import { PRIORITIES_MAP } from 'rapidfab/mappings';
import { PRIORITIES } from 'rapidfab/constants';

const getBsStyle = value => {
  if (value >= PRIORITIES.CRITICAL) return 'danger';
  if (value >= PRIORITIES.HIGH) return 'warning';
  if (value >= PRIORITIES.NORMAL) return 'info';
  return 'info';
};

const getLabel = value => {
  if (value >= PRIORITIES.CRITICAL) return PRIORITIES_MAP[PRIORITIES.CRITICAL];
  if (value >= PRIORITIES.HIGH) return PRIORITIES_MAP[PRIORITIES.HIGH];
  if (value >= PRIORITIES.NORMAL) return PRIORITIES_MAP[PRIORITIES.NORMAL];
  return PRIORITIES_MAP[PRIORITIES.LOW];
};

const PriorityLabel = ({ value, labelOnly, labelClassName, containerClassName }) => {
  if (labelOnly) {
    return (
      <span className={labelClassName}>
        <Badge bg={getBsStyle(value)} className="badge-sm">
          <FormattedMessage {...getLabel(value)} />
        </Badge>
      </span>
    );
  }

  return (
    <div className={`${containerClassName} d-flex justify-content-start`}>
      <OverlayTrigger
        overlay={<Tooltip id={value}>{value}</Tooltip>}
        placement="right"
      >
        <span className={labelClassName}>
          <Badge bg={getBsStyle(value)} className="badge-sm">
            <FormattedMessage {...getLabel(value)} />
          </Badge>
        </span>
      </OverlayTrigger>
    </div>
  );
};

PriorityLabel.defaultProps = {
  labelOnly: false,
  labelClassName: '',
  value: PRIORITIES.NORMAL,
  containerClassName: '',
};

PriorityLabel.propTypes = {
  value: PropTypes.number,
  labelOnly: PropTypes.bool,
  labelClassName: PropTypes.string,
  containerClassName: PropTypes.string,
};

export default PriorityLabel;
