import React from 'react';
import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button, Modal, Row, Col, Tooltip, OverlayTrigger, Badge } from 'react-bootstrap';
import _map from 'lodash/map';
import _filter from 'lodash/filter';
import WorkChecklistContainer from 'rapidfab/containers/records/WorkChecklistContainer';
import Loading from 'rapidfab/components/Loading';
import { getEndpointFromURI, getEntityTypeTitleFromUri } from 'rapidfab/utils/uriUtils';
import { extractUuid, getShortUUID } from 'rapidfab/utils/uuidUtils';
import { FormattedMessage } from 'react-intl';
import ResourceLink from 'rapidfab/components/ResourceLink';
import 'rapidfab/styles/componentStyles/workflowStepsModal.scss';
import CancelOrDeleteModal from 'rapidfab/components/CancelOrDeleteModal';
import { faCheck, faClose, faExclamationCircle, faLock, faPencil, faPlus, faTrash } from '@fortawesome/free-solid-svg-icons';
import { faClock } from '@fortawesome/free-regular-svg-icons';

const RelatedWorkChecklist = ({
  checklist,
  onChange,
  onDelete,
  showDelete,
  formID,
  relatedURI,
  readOnly,
  lineItemChecklistLinking,
  completeProcessSteps,
  selectedProcessStep,
  ...restProps
}) => {
  // Link to Initial Related Entity should be visible only if it is provider
  // and Checklist is not a Custom one for the current Entity
  const isChecklistOwner = checklist.initial_related_uri === relatedURI;
  // TODO: This needs to be replaced with entity check via constant (once available)
  const { endpointName: relatedUriEndpoint } = checklist.initial_related_uri
    ? getEndpointFromURI(checklist.initial_related_uri)
    : { endpointName: null };
  const isChecklistOwnedByLineItem = relatedUriEndpoint === 'line-item';
  const showChecklistLinkToOwner =
    // Related Uri is set
    checklist.initial_related_uri &&
    // And current entity is not the owner of the checklist
    !isChecklistOwner &&
    // And owner of the checklist is not a Line Item
    // (since there is no Line Item page exists)
    !isChecklistOwnedByLineItem;
  // We need to show checklist owner info,
  // only if it is provider and current entity is not the owner
  const showChecklistOwnerInfo =
    checklist.initial_related_uri && !isChecklistOwner;
  const processStepURI = lineItemChecklistLinking.process_step;
  const isStepComplete = completeProcessSteps.includes(processStepURI);
  const isNotTemplate = checklist?.uri;

  return (
    <div className={`mb15 ${lineItemChecklistLinking?.process_step !== selectedProcessStep ? 'hidden' : ''}`}>
      <div className="clearfix">
        {!readOnly && showDelete && isNotTemplate && (
          <Button
            variant="default"
            title="Delete"
            className="pull-right"
            disabled={isStepComplete}
            onClick={() => onDelete()}
          >
            <FontAwesomeIcon icon={faTrash} />
          </Button>
        )}
        <h4 className="d-flex align-items-center">
          {
            isStepComplete && (
              <OverlayTrigger
                placement="right"
                overlay={(
                  <Tooltip>
                    The process step is already complete, any further changes are restricted
                  </Tooltip>
                )}
              >
                <FontAwesomeIcon icon={faLock} className="spacer-right" />
              </OverlayTrigger>
            )
          }

          {showChecklistOwnerInfo && (
            <div>
              From
              {checklist.initial_related_uri &&
                ` ${getEntityTypeTitleFromUri(checklist.initial_related_uri)}`}
              {isChecklistOwnedByLineItem &&
                ` ${getShortUUID(checklist.initial_related_uri)}`}
              {showChecklistLinkToOwner && (
                <>
                  : <ResourceLink uri={checklist.initial_related_uri} />
                </>
              )}
            </div>
          )}
        </h4>
      </div>
      <WorkChecklistContainer
        {...restProps}
        form={`work_checklist_${formID}`}
        readOnly={readOnly}
        initialValues={checklist}
        customHandlers
        onChange={onChange}
        processStepURI={processStepURI}
        isStepComplete={isStepComplete}
      />
    </div>
  );
};

RelatedWorkChecklist.defaultProps = {
  selectedProcessStep: '',
};

RelatedWorkChecklist.propTypes = {
  formID: PropTypes.string.isRequired,
  checklist: PropTypes.shape({
    name: PropTypes.string.isRequired,
    uri: PropTypes.string,
    initial_related_uri: PropTypes.string,
  }).isRequired,
  showDelete: PropTypes.bool.isRequired,
  onChange: PropTypes.func.isRequired,
  onDelete: PropTypes.func.isRequired,
  relatedURI: PropTypes.string.isRequired,
  readOnly: PropTypes.bool.isRequired,
  lineItemChecklistLinking: PropTypes.shape({
    process_step: PropTypes.string,
  }).isRequired,
  completeProcessSteps: PropTypes.arrayOf(PropTypes.string).isRequired,
  selectedProcessStep: PropTypes.string,
};

const WorkChecklistModal = ({
  initialChecklistLinkings,
  onChecklistChange,
  onChecklistDelete,
  onAvailableProcessStepSelect,
  selectedAvailableProcessStep,
  onAvailableProcessStepChange,
  isLineItemCustomWorkflow,
  onSave,
  onClose,
  sending,
  fetching,
  onCustomWorkflowSelect,
  isAnyRelatedChecklistLinkingExists,
  processSteps,
  showDelete,
  relatedURI,
  readOnly,
  selectedProcessStep,
  selectProcessStep,
  changedSteps,
  ...restProps
}) => {
  const processStepsURIs = _map(processSteps, 'uri');
  // // Limit all linkings by process steps URIs
  // // It's used only when needed to return only one checklist linking
  // // but can be useful where linkings are exists, but process steps - not
  const checklistLinkings = _filter(initialChecklistLinkings, linking =>
    processStepsURIs.includes(linking.process_step),
  );

  const [confirmClose, setConfirmClose] = React.useState(false);

  const handleCloseModalWindow = () => {
    if (changedSteps.length && !confirmClose) {
      return setConfirmClose(true);
    }

    return onClose();
  };

  const renderModalHeader = () => {
    if (fetching) {
      return <Loading />;
    }
    return isAnyRelatedChecklistLinkingExists ? (
      <Badge bg="primary" text="light">Edit Work
        Instructions
      </Badge>
    ) : (
      <Badge bg="success" text="light">Create Work
        Instructions
      </Badge>
    );
  };

  const renderIndicatorState = (hasChecklist, awaitingChanges) => {
    if (fetching) {
      return <Loading />;
    }

    if (awaitingChanges) {
      return <FontAwesomeIcon icon={faClock} />;
    }

    if (hasChecklist) {
      return <FontAwesomeIcon icon={faCheck} />;
    }

    return <FontAwesomeIcon icon={faPlus} />;
  };

  return (
    <Modal show onHide={handleCloseModalWindow} backdrop="static" className="workflowStepsModalWindow">
      <Modal.Header>
        <div className="d-flex justify-content-between align-items-center w-full">
          <Modal.Title className="w-full">
            <Row>
              <Col md={9}>
                <div className="d-flex align-items-center">
                  {renderModalHeader()}
                  {readOnly && (
                    <p className="workflowStepsModalWindowHeaderTitle">For Custom Line Item Production Workflow</p>
                  )}
                </div>
              </Col>
            </Row>
          </Modal.Title>
          <div className="workflowStepsModalWindowIconsContainer">
            <Button variant="default" type="button" className="workflowStepsModalWindowButton" onClick={handleCloseModalWindow}>
              <FontAwesomeIcon icon={faClose} className="workflowStepsModalWindowButtonIcon" />
            </Button>
          </div>
        </div>
      </Modal.Header>
      <Modal.Body>
        <Row>
          <Col md={3} className="workflowStepsModalWindowLeftSide">
            <div className="workflowStepsModalWindowStepSelectData" style={onCustomWorkflowSelect && { height: '90%' }}>
              <div className="d-flex align-items-baseline">
                <p className="workflowStepsModalWindowStepSelectDataTitle">
                  Workflow Steps
                </p>
                {readOnly && (
                  <OverlayTrigger
                    placement="bottom"
                    overlay={(
                      <Tooltip>
                        <small>
                          Work Instructions locked for production. To edit, please change
                          each piece&apos;s workflow or duplicate the line item to restart
                          planning.
                        </small>
                      </Tooltip>
                    )}
                  >
                    <FontAwesomeIcon className="spacer-left" style={{ color: '#ffc103' }} icon={faExclamationCircle} />
                  </OverlayTrigger>
                )}
              </div>
              {(!processSteps.length && fetching) && <Loading />}
              {processSteps.map(({ name, id, uri }, index) => {
                const currentCheckList = checklistLinkings.find(checklist => checklist.process_step === uri) || null;
                const hasChecklist = currentCheckList && currentCheckList?.work_checklist?.uri;
                const stepSelectClass = selectedProcessStep === uri ? 'workflowStepsModalWindowStepSelectActive' : '';
                const awaitingChanges = changedSteps.includes(uri);

                return (
                  <div
                    className={`workflowStepsModalWindowStepSelect ${stepSelectClass}`}
                    role="button"
                    tabIndex={0}
                    onClick={() => selectProcessStep(uri)}
                    key={id}
                  >
                    {
                      name.length > 30 ? (
                        <OverlayTrigger
                          placement="top"
                          overlay={(
                            <Tooltip>
                              {name}
                            </Tooltip>
                          )}
                        >
                          <div className="d-flex align-items-baseline justify-content-between">
                            <p className="m-a-0 workflowStepsModalWindowShortenedText">
                              {index + 1}. {name}
                            </p>
                            <div className="workflowStepsModalWindowStepSelectIcon">
                              {
                                fetching ?
                                  <Loading /> :
                                  <FontAwesomeIcon icon={hasChecklist ? faCheck : faPlus} />
                              }
                            </div>
                          </div>
                        </OverlayTrigger>
                      ) : (
                        <div className="d-flex align-items-baseline justify-content-between">
                          <p className="m-a-0">
                            {index + 1}. {name}
                          </p>
                          <div className="workflowStepsModalWindowStepSelectIcon">
                            {renderIndicatorState(hasChecklist, awaitingChanges)}
                          </div>
                        </div>
                      )
                    }
                  </div>
                );
              },
              )}
              {(onCustomWorkflowSelect && !fetching) && (
                <Button
                  block
                  className="workflowStepsModalWindowCustomizeButton"
                  variant="info"
                  disabled={readOnly}
                  onClick={() => onCustomWorkflowSelect()}
                >
                  <FontAwesomeIcon icon={faPencil} />{' '}
                  <FormattedMessage
                    id="button.customizeLineItemWorkflow"
                    defaultMessage="Customize Workflow"
                  />
                </Button>
              )}
            </div>
          </Col>
          <Col md={7} className="bl-light" style={{ width: '70%' }}>
            { fetching ? <Loading className="mt15 mb15 center" size="2x" /> :
              (
                <div className="workflowStepsModalWindowStepModalNoBorder list-group-quote">
                  {checklistLinkings.map(lineItemChecklistLinking => {
                    const processStepUUID = extractUuid(
                      lineItemChecklistLinking.process_step,
                    );

                    return (
                      <RelatedWorkChecklist
                        {...restProps}
                        fetching={fetching}
                        key={processStepUUID}
                        relatedURI={relatedURI}
                        readOnly={readOnly}
                        formID={processStepUUID}
                        checklist={lineItemChecklistLinking.work_checklist}
                        lineItemChecklistLinking={lineItemChecklistLinking}
                        showDelete={showDelete}
                        selectedProcessStep={selectedProcessStep}
                        onDelete={() =>
                          onChecklistDelete(lineItemChecklistLinking.process_step)}
                        onChange={updatedFields =>
                          onChecklistChange(
                            lineItemChecklistLinking.process_step,
                            updatedFields,
                          )}
                      />
                    );
                  })}
                </div>
              ) }
          </Col>
        </Row>
        {
          confirmClose && (
            <CancelOrDeleteModal
              modalType="confirmClose"
              priorityBackdrop
              handleConfirm={() => onClose()}
              handleOpen={() => {
                setConfirmClose(false);
              }}
            />
          )
        }
      </Modal.Body>
      <Modal.Footer className="workflowStepsModalWindowStepModalFooter">
        <div>
          <Button variant="primary" disabled={fetching} onClick={onClose}>
            {checklistLinkings.length > 0 && !readOnly ?
              <FormattedMessage id="button.cancel" defaultMessage="Cancel" /> :
              <FormattedMessage id="button.close" defaultMessage="Close" />}
          </Button>
          <Button variant="success" onClick={onSave} disabled={sending || fetching || readOnly}>
            {sending ?
              <Loading /> :
              <FormattedMessage id="button.saveall" defaultMessage="Save All" />}
          </Button>
        </div>
      </Modal.Footer>
    </Modal>
  );
};

WorkChecklistModal.defaultProps = {
  onCustomWorkflowSelect: null,
  fetching: false,
  sending: false,
  readOnly: false,
  selectedProcessStep: '',
};

WorkChecklistModal.propTypes = {
  onCustomWorkflowSelect: PropTypes.func,
  initialChecklistLinkings: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  isAnyRelatedChecklistLinkingExists: PropTypes.bool.isRequired,
  isLineItemCustomWorkflow: PropTypes.bool.isRequired,
  fetching: PropTypes.bool,
  sending: PropTypes.bool,
  onSave: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
  onChecklistDelete: PropTypes.func.isRequired,
  onChecklistChange: PropTypes.func.isRequired,
  selectedAvailableProcessStep: PropTypes.string.isRequired,
  onAvailableProcessStepChange: PropTypes.func.isRequired,
  onAvailableProcessStepSelect: PropTypes.func.isRequired,
  processSteps: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  showDelete: PropTypes.bool.isRequired,
  relatedURI: PropTypes.string.isRequired,
  readOnly: PropTypes.bool,
  selectedProcessStep: PropTypes.string,
  selectProcessStep: PropTypes.func.isRequired,
  changedSteps: PropTypes.arrayOf(PropTypes.string).isRequired,
};

export default WorkChecklistModal;
