import JeniClusters from 'rapidfab/components/inventory/JeniClusters';
import { API_RESOURCES } from 'rapidfab/constants';
import { useFetchMultipleResources } from 'rapidfab/hooks';
import React from 'react';
import * as Selectors from 'rapidfab/selectors';

const JeniClustersContainer = props => {
  const { JENI_CLUSTER, LOCATION } = useFetchMultipleResources([
    { resource: API_RESOURCES.JENI_CLUSTER },
    { resource: API_RESOURCES.LOCATION, selector: Selectors.getLocationsByUri },
  ]);

  const { data: jeniClusters } = JENI_CLUSTER;
  const { data: locationsByUri } = LOCATION;

  const selected = {
    locationsByUri,
  };

  return (
    <JeniClusters
      {...props}
      {...selected}
      data={jeniClusters}
    />
  );
};

export default JeniClustersContainer;
