/* eslint-disable no-param-reassign, unicorn/no-array-reduce */
import { createSelector } from 'reselect';
import { getStateUI } from 'rapidfab/selectors/helpers/base';
import _get from 'lodash/get';
import _concat from 'lodash/concat';
import _flatMapDeep from 'lodash/flatMapDeep';
import _some from 'lodash/some';
import _map from 'lodash/map';

// Collects all errors from all resources.
// Used in main App component to add toasters with errors
export const getErrors = createSelector(
  [getStateUI],
  stateUI => Object.keys(stateUI).reduce(
    (stateErrors, service) => [
      ...stateErrors,
      ...Object.keys(stateUI[service]).reduce(
        (serviceErrors, resource) => [
          ...serviceErrors,
          ...Object.keys(stateUI[service][resource]).reduce(
            (resourceErrors, method) => {
              // Include resource name to errors.
              // Used to suppress some errors based on method name
              let methodErrors = [...stateUI[service][resource][method].errors];
              methodErrors = methodErrors.map(o => ({ ...o, resource }));
              return [
                ...resourceErrors,
                ...methodErrors,
              ];
            },
            [],
          ),
        ],
        [],
      ),
    ],
    [],
  ),
);

export const getResourceErrors = (state, path) => {
  const methods = _get(state.ui, path);
  if (!methods) {
    throw new Error(`Could not find methods by path: ${path}`);
  }
  return _concat(
    methods.list.errors,
    methods.get.errors,
    methods.post.errors,
    methods.put.errors,
    methods.delete.errors,
    methods.replace.errors,
  );
};

export const isAnythingFetching = createSelector(
  [getStateUI],
  stateUI => {
    const stateValues = Object.values(stateUI.nautilus);
    const allFetchingStates = _flatMapDeep(stateValues, value => _map(value, 'fetching'));
    return _some(allFetchingStates, Boolean);
  },
);
