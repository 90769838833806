import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { extractUuid } from 'rapidfab/utils/uuidUtils';
import OrderProgress from '../../../components/records/order/OrderProgress';
import {
  getPiecesForOrder,
  getPrintsByUri,
  getRunEstimates,
  getRunsForOrder,
  getUUIDResource,
  getRunActualsForRuns,
} from '../../../selectors';
import Actions from '../../../actions';
import { API_RESOURCES, PAGINATION_IGNORE_DEFAULT_LIMIT } from '../../../constants';

const OrderProgressContainer = ({
  loadInitialData,
  orderUri,
  ...props
}) => {
  React.useEffect(() => {
    loadInitialData();
  }, [props.orderUri]);
  return <OrderProgress {...props} />;
};

OrderProgressContainer.propTypes = {
  orderUri: PropTypes.string.isRequired,
  loadInitialData: PropTypes.func.isRequired,
};

const mapStateToProps = (state, { orderUri }) => {
  const order = getUUIDResource(state, extractUuid(orderUri));
  const runs = getRunsForOrder(state, order);
  const runActuals = getRunActualsForRuns(state, runs);
  const orderPieces = getPiecesForOrder(state, order);
  const printsByUri = getPrintsByUri(state);
  const runEstimates = getRunEstimates(state);
  const orderRunsEstimates = runEstimates.filter(estimate => runs.map(run => run.uri).includes(estimate.run));
  const isFetching = state.ui.nautilus[API_RESOURCES.PIECE].list.fetching
    || state.ui.nautilus[API_RESOURCES.RUN].list.fetching
    || state.ui.nautilus[API_RESOURCES.SCHEDULE_RUNS].list.fetching
    || state.ui.nautilus[API_RESOURCES.PRINT].list.fetching;
  return {
    order,
    runs,
    runActuals,
    orderPieces,
    printsByUri,
    orderRunsEstimates,
    isFetching,
  };
};

const mapDispatchToProps = (dispatch, { orderUri }) => ({
  loadInitialData: async () => {
    const promises = [
      dispatch(Actions.Api.nautilus[API_RESOURCES.RUN].list(
        { by_order: orderUri },
        { limit: PAGINATION_IGNORE_DEFAULT_LIMIT },
      )),
    ];
    return Promise.all(promises).then(([runsResponse]) => {
      const runUris = runsResponse?.json?.resources.map(r => r.uri);
      if (runUris?.length) {
        dispatch(Actions.Api.nautilus[API_RESOURCES.SCHEDULE_RUNS].list(
          { run: runUris },
          { limit: PAGINATION_IGNORE_DEFAULT_LIMIT },
        ));
      }
    });
  },
});

const areStatePropsEqual = (next, previous) => JSON.stringify(previous) === JSON.stringify(next);

export default connect(mapStateToProps, mapDispatchToProps, null, { areStatePropsEqual })(OrderProgressContainer);
