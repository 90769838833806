import React from 'react';
import PropTypes from 'prop-types';
import Icon, { ICON_NAMES } from 'rapidfab/icons';

const ChangeInformationText = ({ children }) => (
  <div className="d-flex align-items-center product-change-information">
    <div className="d-flex align-items-center change-information-content">
      <div className="change-information-content-wrapper">
        {children}
      </div>
    </div>
    <Icon name={ICON_NAMES.CHEVRON} className="right-chevron-icon" size="large" />
  </div>
);

ChangeInformationText.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
};

export default ChangeInformationText;
