import Actions from 'rapidfab/actions';
import { API_RESOURCES } from 'rapidfab/constants';
import _keyBy from 'lodash/keyBy';
import _keys from 'lodash/keys';

// eslint-disable-next-line import/prefer-default-export
export const loadBuildLibraries = (
  dispatch,
  queryParams,
  limit = 30,
  offset = 0,
  onComplete = null,
) => {
  dispatch(Actions.Api.nautilus[API_RESOURCES.BUILD_FILE_LIBRARY].clear('list'));

  return dispatch(Actions.Api.nautilus[API_RESOURCES.BUILD_FILE_LIBRARY].list(
    queryParams,
    { limit, offset },
    {},
    {},
    true,
  ))
    .then(buildLibrariesResponse => {
      const { resources } = buildLibrariesResponse.json;

      /* Get the data `build_file_template` pertaining to this `build-library` */
      const buildFileUris = _keys(_keyBy(resources, 'build_file_template'));
      if (buildFileUris.length) {
        dispatch(Actions.Api.nautilus[API_RESOURCES.BUILD_FILE].list({ uri: buildFileUris }));
      }

      /* Get the data `pieces` pertaining to this `build-library` */
      const pieceUris = _keys(_keyBy(resources, 'pieces')).filter(uri => uri !== '');

      if (pieceUris.length) {
        dispatch(Actions.Api.nautilus[API_RESOURCES.PIECE].list({ uri: pieceUris }));
      }

      if (typeof onComplete === 'function') {
        onComplete(buildLibrariesResponse.json);
      }
    });
};
