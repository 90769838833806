import { createSelector } from 'reselect';
import * as baseStateSelectors from 'rapidfab/selectors/baseStateSelectors';
import { getPredicate, getStateResources } from 'rapidfab/selectors/helpers/base';
import _map from 'lodash/map';
import _isEmpty from 'lodash/isEmpty';
import _filter from 'lodash/filter';

export const getSensorResources = createSelector(
  [baseStateSelectors.getStateSensorResources, getStateResources],
  (uuids, resources) => _map(uuids, uuid => resources[uuid]),
);

export const getSensorDataResources = createSelector(
  [baseStateSelectors.getStateSensorDataResources, getStateResources],
  (uuids, resources) => _map(uuids, uuid => resources[uuid]),
);

/**
 * getSensorDataForSensorResources
 * ---
 * Will return `/sensor-data/` resource objects for each of its parent `/sensor/` resource objects.
 *
 * @typedef
 * @returns {
 *    Array<{
 *     ...sensor,
 *     sensorData: {
 *       ...sensorData
 *     }
 *   }>
 * }
 *
 */
export const getSensorDataForSensorResources = createSelector(
  [getSensorDataResources, getSensorResources],
  (sensorDataResources, sensorResources) => sensorResources.map(sensorResourceItem => {
    if (_isEmpty(sensorDataResources) || _isEmpty(sensorResources)) {
      return [];
    }

    return {
      ...sensorResourceItem,
      sensorData: _filter(sensorDataResources, { sensor: sensorResourceItem.uri }),
    };
  }),
);

/**
 * @description Gets all material batch sensor data objects available.
 */
export const getMaterialBatchSensorData = createSelector(
  [baseStateSelectors.getStateMaterialBatchSensorData, getStateResources],
  (uuids, resources) => _map(uuids, uuid => resources[uuid]),
);

/**
 * @description Gets all material batch sensor data objects filtered by material batch URI.
 */
export const getMaterialBatchSensorDataFilteredByMaterialBatchUri = createSelector(
  [getPredicate, getMaterialBatchSensorData],
  (materialBatchUri, sensorData) => {
    if (!materialBatchUri) {
      return [];
    }

    return sensorData.filter(sensorDataItem => (
      sensorDataItem.material_batch === materialBatchUri
    ));
  },
);
