import React from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { Button, Image } from 'react-bootstrap';
import { FormattedDateTime, FormattedMessage } from 'rapidfab/i18n';
import { materialTypeResourceType, modelType, labelResourceType } from 'rapidfab/types';
import CastorCostingIssue from 'rapidfab/components/organize/CastorCostingIssue';
import Table from 'rapidfab/components/Tables/Table';
import TableCellLabels from 'rapidfab/components/TableCellLabels';
import { FEATURES } from 'rapidfab/constants';
import { isFeatureEnabled } from 'rapidfab/selectors';

const ModelLibrariesList = ({
  modelLibraries,
  materialsByUri,
  labelsByUri,
  modelsByUri,
  handleSelect,
  isHawkingDeploymentFeatureEnabled,
  ascDescValue,
  sortValue,
  bureau,
  usersByUri,
}) => {
  const isDigitalDesignWarehouseFeatureEnabled = useSelector(
    state => isFeatureEnabled(state, FEATURES.DIGITAL_DESIGN_WAREHOUSE),
  );
  const isRobozeBureauOrderFieldsFeatureEnabled = useSelector(state => isFeatureEnabled(
    state,
    FEATURES.ROBOZE_GIGAFACTORY_BUREAU_ORDER_FIELDS,
  ));
  const isIntegrationCastorFeatureEnabled = useSelector(state => isFeatureEnabled(state, FEATURES.INTEGRATION_CASTOR));
  const renderCustomHeader = (value, ascDesc) => {
    if (sortValue === value) {
      return <span>{!ascDesc ? ' ▼' : ' ▲'}</span>;
    }

    return null;
  };

  const columns = [
    {
      type: 'custom',
      uid: 'field.model',
      accessor: 'additive.model',
      defaultMessage: 'Model',
      Cell: ({ row: { original }, value }) => {
        const model = modelsByUri[value];
        const modelSnapshotContent = model && model.snapshot_content;
        const snapshotContent = original.snapshot_content || modelSnapshotContent;
        return (
          <Image
            className="model-library-thumbnail column"
            src={snapshotContent}
            alt=""
            thumbnail
          />
        );
      },
    },
    {
      type: 'custom',
      uid: 'field.name',
      accessor: 'name',
      defaultMessage: 'Name',
      Header: () => (
        <>
          <FormattedMessage id="field.name" defaultMessage="Name" />
          {renderCustomHeader('name', ascDescValue)}
        </>
      ),
      Cell: ({ row: { original }, value }) => (
        <Button variant="link" onClick={() => handleSelect(original)}>
          {value}
        </Button>
      ),
    },
    !isHawkingDeploymentFeatureEnabled && (
      {
        type: 'custom',
        uid: 'field.type',
        accessor: 'type',
        defaultMessage: 'Type',
        Cell: ({ value }) => (
          value || <FormattedMessage id="notAvailable" defaultMessage="N/A" />
        ),
      }
    ),
    {
      type: 'custom',
      uid: 'field.labels',
      accessor: 'labels',
      defaultMessage: 'Labels',
      Cell: ({ row: { original } }) => {
        const { labels: labelUris } = original;
        if (!labelUris || !labelUris.length) {
          return (
            <FormattedMessage id="notAvailable" defaultMessage="N/A" />
          );
        }
        return (
          <TableCellLabels
            labelUris={labelUris}
            labelsByUri={labelsByUri}
          />
        );
      },
    },
    {
      type: 'custom',
      uid: 'field.baseMaterial',
      accessor: 'additive.base_material',
      defaultMessage: 'Base Material',
      Cell: ({ value }) => (
        (value && materialsByUri[value])
          ? materialsByUri[value]?.name
          : <FormattedMessage id="notAvailable" defaultMessage="N/A" />
      ),
    },
    {
      type: 'custom',
      uid: 'field.owner',
      accessor: 'owner',
      defaultMessage: 'Owner',
      Cell: ({ value }) => (
        usersByUri[value] ?
          <p>{usersByUri[value]?.name}</p> :
          <p>{bureau.name}</p>
      ),
    },
    {
      type: 'custom',
      uid: 'field.updated',
      accessor: 'updated',
      defaultMessage: 'Updated',
      Header: () => (
        <>
          <FormattedMessage id="field.updated" defaultMessage="Updated" />
          {renderCustomHeader('updated', ascDescValue)}
        </>
      ),
      Cell: ({ value }) => (
        value
          ? (
            <FormattedDateTime
              value={value}
            />
          )
          : (
            <FormattedMessage
              id="notAvailable"
              defaultMessage="N/A"
            />
          )
      ),
    },
    isDigitalDesignWarehouseFeatureEnabled &&
    isIntegrationCastorFeatureEnabled &&
    isRobozeBureauOrderFieldsFeatureEnabled && {
      type: 'custom',
      uid: 'field.issues',
      accessor: 'uri',
      defaultMessage: 'Issues',
      isSortable: false,
      Cell: ({ value }) => (
        <div className="d-flex justify-content-center">
          <CastorCostingIssue uri={value} />
        </div>
      )
      ,
    },
  ];

  return (
    <Table
      tableID="modelLibraries"
      data={modelLibraries}
      columns={columns}
      isFilteringEnabled={false}
      withDefaultPagination={false}
      isManualSoringEnabled={false}
      resetDefaultSortingState
      isUpdatedColumnShown={false}
    />
  );
};

ModelLibrariesList.propTypes = {
  modelLibraries: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  materialsByUri: PropTypes.objectOf(materialTypeResourceType).isRequired,
  labelsByUri: PropTypes.objectOf(labelResourceType).isRequired,
  modelsByUri: PropTypes.objectOf(modelType).isRequired,
  handleSelect: PropTypes.func.isRequired,
  isHawkingDeploymentFeatureEnabled: PropTypes.bool.isRequired,
  row: PropTypes.shape({
    id: PropTypes.string,
    name: PropTypes.string,
    original: PropTypes.shape({
      labels: PropTypes.arrayOf(PropTypes.string),
      snapshot_content: PropTypes.string,
    }),
  }).isRequired,
  // eslint-disable-next-line react/no-unused-prop-types
  value: PropTypes.string.isRequired,
  ascDescValue: PropTypes.bool.isRequired,
  sortValue: PropTypes.string.isRequired,
  bureau: PropTypes.shape({
    name: PropTypes.string,
  }).isRequired,
  usersByUri: PropTypes.shape({
    name: PropTypes.string,
  }).isRequired,
};

export default ModelLibrariesList;
