import React from 'react';

const ArrowsRepeat = props => (
  <svg
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    x="0px"
    y="0px"
    width="923.566px"
    height="923.567px"
    viewBox="0 0 923.566 923.567"
    xmlSpace="preserve"
    {...props}
  >
    <g>
      <path d="M89.845,553.404l41.747-42.251v60.153c0,86.847,70.654,157.5,157.5,157.5h487.981c28.994,0,52.5-23.505,52.5-52.5 s-23.506-52.5-52.5-52.5H289.092c-28.948,0-52.5-23.551-52.5-52.5v-58.944l42.192,41.688c10.23,10.11,23.565,15.155,36.896,15.155 c13.541,0,27.078-5.207,37.348-15.6c20.38-20.625,20.18-53.866-0.445-74.245L219.312,347.678 c-9.83-9.713-23.088-15.155-36.899-15.155c-0.104,0-0.21,0-0.314,0.001c-13.924,0.083-27.244,5.695-37.03,15.599L15.155,479.603 c-20.38,20.625-20.18,53.866,0.445,74.245S69.467,574.029,89.845,553.404z" />
      <path d="M833.723,370.164l-41.747,42.251V352.26c0-86.846-70.653-157.5-157.5-157.5H146.495c-28.995,0-52.5,23.505-52.5,52.5 c0,28.995,23.505,52.5,52.5,52.5h487.98c28.948,0,52.5,23.551,52.5,52.5v58.944l-42.191-41.689 c-20.624-20.379-53.866-20.18-74.244,0.445c-20.38,20.625-20.181,53.866,0.444,74.245l133.271,131.682 c10.231,10.11,23.566,15.155,36.896,15.155c13.541,0,27.078-5.207,37.348-15.6l129.912-131.48 c20.38-20.625,20.18-53.866-0.445-74.245C887.343,349.338,854.101,349.539,833.723,370.164z" />
    </g>
  </svg>

);

export default ArrowsRepeat;
