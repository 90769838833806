import React from 'react';
import PropTypes from 'prop-types';
import FormRow from 'rapidfab/components/FormRow';
import { Form } from 'react-bootstrap';
import { ORDER_SHIPPING_GROUPING_OPTIONS } from 'rapidfab/constants';
import { reduxFormFieldType } from 'rapidfab/types';

const WholeOrderShipmentCheckbox = ({ field, disabled }) => (
  <FormRow
    id="field.wholeOrderShipment"
    defaultMessage="Whole Order Shipment"
  >
    <Form.Check
      disabled={disabled}
      checked={field.value === ORDER_SHIPPING_GROUPING_OPTIONS.BY_ORDER}
      onChange={event => {
        field.onChange(event.target.checked
          ? ORDER_SHIPPING_GROUPING_OPTIONS.BY_ORDER
          : ORDER_SHIPPING_GROUPING_OPTIONS.BY_BATCHING_STRATEGY,
        );
      }}
      type="checkbox"
    />
  </FormRow>
);

WholeOrderShipmentCheckbox.defaultProps = {
  disabled: false,
};

WholeOrderShipmentCheckbox.propTypes = {
  field: reduxFormFieldType.isRequired,
  disabled: PropTypes.bool,
};

export default WholeOrderShipmentCheckbox;
