import React, { useEffect, memo } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import { extractUuid } from 'rapidfab/utils/uuidUtils';
import Actions from 'rapidfab/actions';
import Loading from 'rapidfab/components/Loading';
import { getShipmentsForOrder, getUUIDResource } from 'rapidfab/selectors';
import { API_RESOURCES, PAGINATION_IGNORE_DEFAULT_LIMIT } from 'rapidfab/constants';
import OrderShipments from 'rapidfab/components/records/order/edit/OrderShipments';

const OrderShipmentsContainer = props => {
  const { orderUri } = props;
  const orderUuid = extractUuid(orderUri);
  const order = useSelector(state => getUUIDResource(state, orderUuid));
  const shipments = useSelector(state => getShipmentsForOrder(state, order));

  const { fetching } = useSelector(state => state.ui.nautilus[API_RESOURCES.SHIPMENT].list);

  const dispatch = useDispatch();
  /* eslint-disable no-shadow */
  const onInitialize = orderUri =>
    dispatch(Actions.Api.nautilus[API_RESOURCES.SHIPMENT]
      .list({ order: orderUri }, { limit: PAGINATION_IGNORE_DEFAULT_LIMIT }));

  useEffect(() => {
    onInitialize(orderUri);
  }, [orderUri]);

  if (fetching) {
    return <Loading />;
  }

  return <OrderShipments shipments={shipments} />;
};

OrderShipmentsContainer.propTypes = {
  orderUri: PropTypes.string.isRequired,
};

export default memo(OrderShipmentsContainer);
