import { createSelector } from 'reselect';
import { getPermissions } from 'rapidfab/selectors/permissions';

const PERMISSIONS_RIGHTS = {
  IMPERSONATION: 'impersonation',
};

const PERMISSIONS_NAMESPACE = 'nautilus';

// eslint-disable-next-line import/prefer-default-export
export const hasImpersonatePermissions = createSelector(
  [getPermissions],
  permissions => permissions.some(
    permission =>
      permission.namespace === PERMISSIONS_NAMESPACE && permission.right === PERMISSIONS_RIGHTS.IMPERSONATION,
  ),
);
