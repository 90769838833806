import { createSelector } from 'reselect';
import * as baseStateSelectors from 'rapidfab/selectors/baseStateSelectors';
import { getPredicate, getStateResources } from 'rapidfab/selectors/helpers/base';
import _map from 'lodash/map';
import _keyBy from 'lodash/keyBy';
import _filter from 'lodash/filter';

export const getRunActuals = createSelector(
  [baseStateSelectors.getStateRunActuals, getStateResources],
  (uuids, resources) => _map(uuids, uuid => resources[uuid]),
);

export const getRunActualsByRunUri = createSelector([getRunActuals], runActuals =>
  _keyBy(runActuals, 'run'),
);

export const getRunActualsForRuns = createSelector(
  [getPredicate, getRunActuals],
  (runs, runActuals) => {
    if (!runs) {
      return [];
    }
    const runUris = new Set(runs.map(run => run.uri));
    return _filter(runActuals, runActual => runUris.has(runActual.run));
  },
);

export const getRunActualsKeyedByRunUri = createSelector(
  [getRunActuals],
  runActuals => _keyBy(runActuals, 'run'),
);
