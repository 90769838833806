import React, { forwardRef, useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import ReactSelect from 'react-select';
import { selectInputStyles, selectInputStylesLightMode } from 'rapidfab/constants/styles';
import _find from 'lodash/find';
import { FormattedMessage } from 'rapidfab/i18n';
import Loading from 'rapidfab/components/Loading';
import useElementScroll, { isScrolledToBottom } from 'rapidfab/hooks/useElementScroll';
import { Picky } from 'react-picky';
import Feature from '../Feature';

/**
 * @typedef {Object} SelectSingleLazyProps
 * @property {string} name
 * @property {string} placeholder
 * @property {object[]} data
 * @property {string} labelKey
 * @property {string} valueKey
 * @property {string | number} value
 * @property {() => void} handleOnChange
 * @property {boolean} includeFilter
 * @property {boolean} imitateOnChangeEvent
 * @property {() => void} renderOptionCallback
 * @property {() => void} renderLabelCallback
 * @property {() => void} isOptionDisabledCallback
 *
 * @property {boolean} required
 * @property {boolean} disabled
 * @property {boolean} defaultFocusFilter
 *
 * @property {() => void} onFetchMore
 * @property {string} dataTestId
 * @property {boolean} nullable
 * @property {boolean} lightMode
 */

/**
 * @type React.ForwardRefRenderFunction<HTMLInputElement, SelectSingleLazyProps>
 */
const SelectSingleLazy = forwardRef((props, forwardedRef) => {
  const [isLoading, setIsLoading] = useState(false);
  const options = useMemo(() => {
    const options = props.data.map(option => ({
      value: option[props.valueKey],
      label: option[props.labelKey],
    }));
    if (!props.required) {
      return [{ value: '', label: 'None' }, ...options];
    }

    return options;
  },
  [props.data, props.valueKey, props.labelKey]);

  const selected = useMemo(
    () => options.find(option => option.value === props.value),
    [props.value, options],
  );

  const handleOnChange = selectedData => {
    const {
      name,
      imitateOnChangeEvent,
      nullable,
    } = props;
    const value = selectedData ? selectedData.value : null;

    if (!imitateOnChangeEvent) {
      props.handleOnChange(name, value);
      return;
    }

    // Imitating regular select `onChange` event
    const fakeEvent = {
      target: {
        type: 'select',
        value: nullable && !value ? null : value,
        name,
      },
      stopPropagation: () => {},
      preventDefault: () => {},
    };
    props.handleOnChange(fakeEvent);
  };

  const renderItem = ({ label, value }) => {
    const item = props.data.find(option => option[props.valueKey] === value);

    if (props.renderOptionCallback) {
      return props.renderOptionCallback(item);
    }
    if (item && props.renderLabelCallback) {
      return props.renderLabelCallback(item);
    }

    return label;
  };

  const fetchMore = () => {
    if (!props.onFetchMore) return;
    setIsLoading(true);
    props.onFetchMore().finally(() => setIsLoading(false));
  };

  const fetchIfFewItems = () => {
    // If there's less than 10, the dropdown won't be scrollable,
    // so fetchMore wouldn't be called
    if (props.data.length < 10) {
      fetchMore();
    }
  };

  return (
    <div className="position-relative w-100">
      <ReactSelect
        ref={forwardedRef}
        value={selected}
        options={options}
        styles={props.lightMode ? selectInputStylesLightMode : selectInputStyles}
        placeholder={props.placeholder ?? 'Choose...'}
        hideSelectedOptions={false}
        isSearchable={props.includeFilter}
        closeMenuOnSelect
        isClearable={false}
        isDisabled={props.disabled}
        onMenuOpen={fetchIfFewItems}
        onMenuScrollToBottom={fetchMore}
        onChange={handleOnChange}
        className="wrap-text"
        isLoading={isLoading}
        isOptionDisabled={props.isOptionDisabledCallback ? props.isOptionDisabledCallback : undefined}
        formatOptionLabel={renderItem}
      />
    </div>
  );
});

SelectSingleLazy.defaultProps = {
  placeholder: null,
  includeFilter: true,
  value: null,
  labelKey: 'name',
  valueKey: 'uri',
  // Made for backwards compatibility with the logic created for regular Selects
  // Do not use it for any new case. Better create appropriate onChange handler
  imitateOnChangeEvent: false,
  renderOptionCallback: null,
  renderLabelCallback: null,
  isOptionDisabledCallback: null,
  required: false,
  disabled: false,
  onFetchMore: null,
  nullable: false,
  lightMode: false,
};

SelectSingleLazy.propTypes = {
  name: PropTypes.string.isRequired,
  // Used in getDerivedStateFromProps
  // eslint-disable-next-line react/no-unused-prop-types
  placeholder: PropTypes.string,
  // Used in getDerivedStateFromProps
  // eslint-disable-next-line react/no-unused-prop-types
  data: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  labelKey: PropTypes.string,
  valueKey: PropTypes.string,
  // Used in getDerivedStateFromProps
  // eslint-disable-next-line react/no-unused-prop-types
  value: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
  ]),
  handleOnChange: PropTypes.func.isRequired,
  includeFilter: PropTypes.bool,
  imitateOnChangeEvent: PropTypes.bool,
  renderOptionCallback: PropTypes.func,
  renderLabelCallback: PropTypes.func,
  isOptionDisabledCallback: PropTypes.func,
  // Used in getDerivedStateFromProps
  // eslint-disable-next-line react/no-unused-prop-types
  required: PropTypes.bool,
  disabled: PropTypes.bool,
  onFetchMore: PropTypes.func,
  nullable: PropTypes.bool,
  lightMode: PropTypes.bool,
};

const OFFSET = 20;

const SelectList = ({
  renderItem,
  items,
  selectValue,
  labelKey,
  valueKey,
  onFetchMore,
}) => {
  const [loading, setLoading] = useState(false);

  const [calledForMore, setCalledForMore] = useState(false);

  const anchor = React.useRef();
  const scrollPosition = useElementScroll(anchor);
  const scrolledToBottom = isScrolledToBottom(anchor, scrollPosition, OFFSET);

  useEffect(() => {
    if (!calledForMore && onFetchMore && scrolledToBottom && !loading) {
      setLoading(true);
      setCalledForMore(true);
      onFetchMore()
        .finally(() => setLoading(false));
    }
  }, [scrolledToBottom, calledForMore, loading]);

  useEffect(() => {
    if (calledForMore && !loading) {
      setCalledForMore(false);
    }
  }, [loading]);

  return (
    <div
      ref={anchor}
      style={{
        overflowY: 'scroll',
        maxHeight: '260px',
      }}
    >
      {items.map(item => (
        renderItem({
          item,
          selectValue,
          labelKey,
          valueKey,
        })
      ))}
      {loading && <Loading />}
    </div>
  );
};

SelectList.defaultProps = {
  onFetchMore: null,
};

SelectList.propTypes = {
  renderItem: PropTypes.func.isRequired,
  items: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  selectValue: PropTypes.func.isRequired,
  labelKey: PropTypes.string.isRequired,
  valueKey: PropTypes.string.isRequired,
  onFetchMore: PropTypes.func,
};

const OldSelectSingleLazy = forwardRef((props, forwardedRef) => {
  const [value, setValue] = useState([]);
  const [options, setOptions] = useState([]);
  const [placeholder, setPlaceholder] = useState(null);
  const [searchText, setSearchText] = useState('');

  useEffect(() => {
    const {
      valueKey,
      labelKey,
      value,
      required,
    } = props;
    let {
      placeholder,
      data,
    } = props;
    if (!placeholder) {
      placeholder = required ?
        (<FormattedMessage id="field.choose" defaultMessage="Choose…" />)
        : (<FormattedMessage id="field.none" defaultMessage="None" />);
    }

    const selectedItem = _find(data, { [valueKey]: value });

    if (!required && !searchText) {
      const emptyPlaceholder = {
        [labelKey]: placeholder,
        [valueKey]: '',
      };
      data = [emptyPlaceholder, ...data];
    }
    setPlaceholder(placeholder);
    setValue(selectedItem ? [selectedItem] : []);
    setOptions(data.filter(option =>
      !option[valueKey] || option[labelKey]?.toLowerCase().includes(searchText.toLowerCase())));
  }, [
    JSON.stringify([props.placeholder, props.required, props.data, props.value, props.valueKey, props.labelKey]),
    searchText,
  ]);

  const handleOnChange = selectedData => {
    const {
      name,
      valueKey,
      imitateOnChangeEvent,
      nullable,
    } = props;
    const value = selectedData ? selectedData[valueKey] : null;
    if (!imitateOnChangeEvent) {
      props.handleOnChange(name, value);
      return;
    }
    // Imitating regular select `onChange` event
    const fakeEvent = {
      target: {
        type: 'select',
        value: nullable && !value ? null : value,
        name,
      },
      stopPropagation: () => {},
      preventDefault: () => {},
    };
    props.handleOnChange(fakeEvent);
  };

  const renderItem = renderProps => {
    const {
      item,
      selectValue,
      labelKey,
      valueKey,
    } = renderProps;

    const {
      isOptionDisabledCallback,
      renderLabelCallback,
      renderOptionCallback,
    } = props;

    if (renderOptionCallback) {
      return renderOptionCallback(renderProps);
    }

    const isOptionsDisabled = isOptionDisabledCallback && isOptionDisabledCallback(item);

    const label = renderLabelCallback ? renderLabelCallback(item) : item[labelKey];
    return (
      // eslint-disable-next-line jsx-a11y/no-static-element-interactions
      <div
        key={item[valueKey]}
        onClick={() => !isOptionsDisabled && selectValue(item)}
        className={isOptionsDisabled && 'text-muted'}
      >
        {label}
      </div>
    );
  };

  const {
    name,
    labelKey,
    valueKey,
    includeFilter,
    disabled,
    defaultFocusFilter,
    required,
  } = props;

  return (
    <div className="position-relative w-100">
      <Picky
        ref={forwardedRef}
        placeholder={placeholder}
        options={options}
        labelKey={labelKey}
        valueKey={valueKey}
        includeFilter={includeFilter}
        defaultFocusFilter={defaultFocusFilter}
        filterTermProcessor={term => {
          setSearchText(term);
          return term;
        }}
        onOpen={() => setSearchText('')}
        onClose={() => setSearchText('')}
        value={value}
        keepOpen={false}
        multiple={false}
        clearFilterOnClose
        onChange={values => handleOnChange(values)}
        disabled={disabled}
        className="wrap-text"
        buttonProps={{
          'data-testid': `picky-input-${props.dataTestId || props.name}`,
        }}
        renderList={({
          selectValue,
        }) => (
          <SelectList
            labelKey={labelKey}
            valueKey={valueKey}
            items={options}
            selectValue={selectValue}
            renderItem={renderItem}
            onFetchMore={props.onFetchMore}
          />
        )}
      />
      {/*
          Adding transparent input to add `required` browser validation when needed
          since Picky does not have validation options
          1px height is required to show browser validation popup right under the picky dropdown
        */}
      {required && (
        <input
          name={name}
          tabIndex={-1}
          autoComplete="off"
          className="position-absolute opacity-0 w-100 p-0 m-0 border-0 pe-none"
          style={{
            height: '1px',
          }}
          value={value}
          // onChange needed to prevent console warnings
          onChange={() => {}}
          required
        />
      )}
    </div>
  );
});

OldSelectSingleLazy.defaultProps = {
  placeholder: null,
  includeFilter: true,
  value: null,
  labelKey: 'name',
  valueKey: 'uri',
  // Made for backwards compatibility with the logic created for regular Selects
  // Do not use it for any new case. Better create appropriate onChange handler
  imitateOnChangeEvent: false,
  renderOptionCallback: null,
  renderLabelCallback: null,
  isOptionDisabledCallback: null,
  required: false,
  disabled: false,
  defaultFocusFilter: true,
  onFetchMore: null,
  dataTestId: '',
  nullable: false,
};
OldSelectSingleLazy.propTypes = {
  name: PropTypes.string.isRequired,
  // Used in getDerivedStateFromProps
  // eslint-disable-next-line react/no-unused-prop-types
  placeholder: PropTypes.string,
  // Used in getDerivedStateFromProps
  // eslint-disable-next-line react/no-unused-prop-types
  data: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  labelKey: PropTypes.string,
  valueKey: PropTypes.string,
  // Used in getDerivedStateFromProps
  // eslint-disable-next-line react/no-unused-prop-types
  value: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
  ]),
  handleOnChange: PropTypes.func.isRequired,
  includeFilter: PropTypes.bool,
  imitateOnChangeEvent: PropTypes.bool,
  renderOptionCallback: PropTypes.func,
  renderLabelCallback: PropTypes.func,
  isOptionDisabledCallback: PropTypes.func,
  // Used in getDerivedStateFromProps
  // eslint-disable-next-line react/no-unused-prop-types
  required: PropTypes.bool,
  disabled: PropTypes.bool,
  defaultFocusFilter: PropTypes.bool,
  onFetchMore: PropTypes.func,
  dataTestId: PropTypes.string,
  nullable: PropTypes.bool,
};

const Export = props => (
  <>
    <Feature featureName="experiment-alpha">
      <SelectSingleLazy {...props} />
    </Feature>
    <Feature featureName="experiment-alpha" isInverted>
      <OldSelectSingleLazy {...props} />
    </Feature>
  </>
);

export default Export;
