import React, { useState } from 'react';
import { connect, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { extractUuid } from 'rapidfab/utils/uuidUtils';
import _chunk from 'lodash/chunk';

import OrdersGanttChart from 'rapidfab/components/records/order/OrdersGanttChart';
import { API_RESOURCES, PAGINATION_IGNORE_DEFAULT_LIMIT, LIST_BY_URIS_CHUNK_SIZE } from 'rapidfab/constants';
import Actions from 'rapidfab/actions';
import * as Selectors from 'rapidfab/selectors';
import { getPrintsByUri, getPrints } from 'rapidfab/selectors';
import { orderType } from 'rapidfab/types';
import dayjs from 'dayjs';
import Alert from 'rapidfab/utils/alert';
import { FormattedMessage } from 'react-intl';

const OrdersGanttChartContainer = ({ loadInitialData, ...props }) => {
  const isDebugModeEnabled = useSelector(Selectors.getIsDebugModeEnabled);
  const scheduledRuns = useSelector(Selectors.getScheduledRuns);

  const [defaultStartTime, setDefaultStartTime] = useState(dayjs());
  const [defaultEndTime, setDefaultEndTime] = useState(dayjs().add(7, 'day'));

  const handleScroll = (start, end) => {
    setDefaultStartTime(dayjs(start));
    setDefaultEndTime(dayjs(end));
  };

  React.useEffect(() => {
    loadInitialData(defaultStartTime.subtract(3, 'month').format(), defaultEndTime.add(3, 'month').format());
  }, [JSON.stringify(props.orders),
    JSON.stringify(defaultStartTime),
    JSON.stringify(defaultEndTime),

  ]);

  React.useEffect(() => {
    if (props.piecesWorkflowURIs.length) {
      props.loadWorkflowsByUri(props.piecesWorkflowURIs);
    }
  }, [props.piecesWorkflowURIs]);

  return (
    <OrdersGanttChart
      {...props}
      handleGanttScroll={handleScroll}
      defaultStartTime={defaultStartTime}
      defaultEndTime={defaultEndTime}
      isDebugModeEnabled={isDebugModeEnabled}
      scheduledRuns={scheduledRuns}
    />
  );
};

OrdersGanttChartContainer.propTypes = {
  loadInitialData: PropTypes.func.isRequired,
  orders: PropTypes.arrayOf(orderType).isRequired,
  piecesWorkflowURIs: PropTypes.arrayOf(PropTypes.string).isRequired,
  loadWorkflowsByUri: PropTypes.func.isRequired,
};

const areStatePropsEqual = (next, previous) => JSON.stringify(previous) === JSON.stringify(next);

const mapStateToProps = (state, { orders, customFetching }) => {
  const isFetching = typeof customFetching !== 'undefined'
    ? customFetching
    : state.ui.nautilus[API_RESOURCES.ORDER].list.fetching
    || state.ui.nautilus[API_RESOURCES.RUN].list.fetching
    || state.ui.nautilus[API_RESOURCES.PIECE].list.fetching
    || state.ui.nautilus[API_RESOURCES.PRINT].list.fetching
    || state.ui.nautilus[API_RESOURCES.RUN_ACTUALS].list.fetching
    || state.ui.nautilus[API_RESOURCES.SCHEDULE_RUNS].list.fetching;
  const runsByOrderUri = {};
  const runActualsByRunUri = {};
  const runEstimatesByRunUri = {};
  const piecesByOrderUri = {};
  const runEstimates = Selectors.getRunEstimates(state);
  const prints = getPrints(state);
  const printsByUri = getPrintsByUri(state);
  const allRunsByPieceUri = {};
  const workflowByPieceUri = {};
  const piecesWorkflowURIs = [];

  orders.forEach(order => {
    const orderRuns = Selectors.getRunsForOrder(state, order);
    runsByOrderUri[order.uri] = orderRuns;
    const orderPieces = Selectors.getPiecesForOrder(state, order);
    piecesByOrderUri[order.uri] = orderPieces;

    orderPieces.forEach(piece => {
      const allPiecesRuns = Selectors.getRunsForPiece(state, piece);

      if (piece.workflow) {
        piecesWorkflowURIs.push(piece.workflow);
      }
      const workflow = piece && piece.workflow &&
        Selectors.getUUIDResource(state, extractUuid(piece.workflow));
      workflowByPieceUri[piece.uri] = workflow;
      if (allPiecesRuns.length) {
        allRunsByPieceUri[piece.uri] = allPiecesRuns;
      }
    });

    orderRuns.forEach(run => {
      const runEstimate = runEstimates.find(estimate => estimate.run === run.uri);
      const runActual = Selectors.getRunActualsForRun(state, run);
      if (runEstimate) {
        runEstimatesByRunUri[run.uri] = runEstimate;
      }
      if (runActual) {
        runActualsByRunUri[runActual.run] = runActual;
      }
    });
  });
  const buildsByRunUri = Selectors.getBuildsByRunUri(state);

  return {
    isFetching,
    runsByOrderUri,
    runEstimatesByRunUri,
    piecesByOrderUri,
    prints,
    printsByUri,
    runActualsByRunUri,
    allRunsByPieceUri,
    workflowByPieceUri,
    piecesWorkflowURIs,
    buildsByRunUri,
  };
};
const mapDispatchToProps = (dispatch, { orders }) => ({
  loadInitialData: (estimateStartTime, estimateEndTime) => {
    const promises = [];
    const orderUris = orders.map(o => o.uri);
    if (orderUris.length === 0) {
      return Promise.all([]);
    }

    const loadResourceByChunks = (resource, endpoint) => {
      if (endpoint === API_RESOURCES.SCHEDULE_RUNS) {
        _chunk(resource, LIST_BY_URIS_CHUNK_SIZE).forEach(resourceChunk => {
          dispatch(Actions.Api.nautilus[endpoint].list({ run: resourceChunk, '>estimates.start': estimateStartTime, '<estimates.end': estimateEndTime },
            { limit: PAGINATION_IGNORE_DEFAULT_LIMIT }, {}, {}, true));
        });
      } else {
        _chunk(resource, LIST_BY_URIS_CHUNK_SIZE).forEach(resourceChunk => {
          dispatch(Actions.Api.nautilus[endpoint].list({ run: resourceChunk },
            { limit: PAGINATION_IGNORE_DEFAULT_LIMIT }, {}, {}, true));
        });
      }
    };

    dispatch(Actions.Api.nautilus[API_RESOURCES.RUN].list(
      { by_order: orderUris },
      { limit: PAGINATION_IGNORE_DEFAULT_LIMIT },
    )).then(response => {
      const runUris = response?.json?.resources.map(r => r.uri);
      if (runUris?.length) {
        if (runUris.length > 250) {
          Alert.warning(
            <FormattedMessage
              id="toaster.warning.largeRequest"
              defaultMessage="Please wait...large request is being made."
            />,
          );
        }

        loadResourceByChunks(runUris, API_RESOURCES.SCHEDULE_RUNS);
        loadResourceByChunks(runUris, API_RESOURCES.BUILD);
      }
    }).finally(() => {
      promises.push(
        dispatch(Actions.Api.nautilus[API_RESOURCES.RUN_ACTUALS].list(
          { by_order: orderUris },
          { limit: PAGINATION_IGNORE_DEFAULT_LIMIT },
        )),
        dispatch(Actions.Api.nautilus[API_RESOURCES.PIECE].list(
          { order: orderUris },
          { limit: PAGINATION_IGNORE_DEFAULT_LIMIT },
        )),
        dispatch(Actions.Api.nautilus[API_RESOURCES.PRINT].list(
          { order: orderUris },
          { limit: PAGINATION_IGNORE_DEFAULT_LIMIT },
        )),
      );
    });
    return Promise.all(promises);
  },
  loadWorkflowsByUri:
      workflowURIs =>
        dispatch(Actions.Api.nautilus[API_RESOURCES.WORKFLOW].list(
          { uri: [...new Set(workflowURIs)], include_custom_workflows: true },
        )),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
  null,
  { areStatePropsEqual })(OrdersGanttChartContainer);
