import PropTypes from 'prop-types';
import React, { createContext, useContext, useState } from 'react';

const ImpersonationContext = createContext(null);

export const ImpersonationContextProvider = ({
  children,
}) => {
  const [isImpersonationModalActive, setIsImpersonationModalActive] = useState(false);

  return (
    <ImpersonationContext.Provider value={{
      isImpersonationModalActive,
      setIsImpersonationModalActive,
    }}
    >
      {children}
    </ImpersonationContext.Provider>
  );
};

ImpersonationContextProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

export const useImpersonationContext = () => useContext(ImpersonationContext);
