import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import {
  Col,
  ListGroup,
  ListGroupItem,
  Card,
  Row,
  Button,
} from 'react-bootstrap';
import * as Selectors from 'rapidfab/selectors';
import { extractUuid } from 'rapidfab/utils/uuidUtils';
import MappedColumn from 'rapidfab/components/RecordList/MappedColumn';
import {
  FORMATTED_DURATION_TYPES,
  FormattedMessage,
  FormattedOptionalDuration,
} from 'rapidfab/i18n';
import { PREP_TASK_RECORD_STATUSES_MAP } from 'rapidfab/mappings';
import PrepTaskRecordModalContainer from 'rapidfab/containers/track/PrepTaskRecordModalContainer';
import { prepWorkflowResourceType } from 'rapidfab/types';

const PrepWorkflowSteps = ({
  prepWorkflow,
  prepTasksByUri,
  prepTaskRecordsByPrepTaskInWorkflowUUID,
}) => {
  const [prepTaskRecordUUIDForModal, setPrepTaskRecordUUIDForModal] =
    useState(null);

  const closeModal = () => {
    setPrepTaskRecordUUIDForModal(null);
  };

  const showModal = prepTaskRecordUUID => {
    setPrepTaskRecordUUIDForModal(prepTaskRecordUUID);
  };

  if (!prepWorkflow) {
    return <Card>Prep Workflow is not found.</Card>;
  }

  return (
    <>
      <Card bg="dark">
        <ListGroup fill>
          <ListGroupItem key="header">
            <Row>
              <Col xs={4}>
                <b>
                  <FormattedMessage id="field.name" defaultMessage="Name" />
                </b>
              </Col>
              <Col xs={4}>
                <b>
                  <FormattedMessage id="field.status" defaultMessage="Status" />
                </b>
              </Col>
              <Col xs={4}>
                <b>
                  <FormattedMessage id="time" defaultMessage="Time" />
                </b>
              </Col>
            </Row>
          </ListGroupItem>

          {prepWorkflow.tasks_in_workflow.map(
            ({ prep_task: prepTaskUri, uuid: prepTaskInWorkflowUuid }) => {
              const prepTask = prepTasksByUri && prepTasksByUri[prepTaskUri];
              // Task record may not be exist at some moment,
              // keep in mind that null values are expected here
              const prepTaskRecord =
                prepTaskRecordsByPrepTaskInWorkflowUUID &&
                prepTaskRecordsByPrepTaskInWorkflowUUID[prepTaskInWorkflowUuid];
              return (
                <ListGroupItem key={prepTaskInWorkflowUuid}>
                  <Row>
                    <Col xs={4}>
                      {!prepTask && 'N/A'}
                      {prepTask &&
                        (prepTaskRecord ? (
                          <Button
                            className="p-a-0"
                            variant="link"
                            role="button"
                            tabIndex={0}
                            onClick={() => showModal(prepTaskRecord.uuid)}
                          >
                            {prepTask.name}
                          </Button>
                        ) : (
                          prepTask.name
                        ))}
                    </Col>
                    <Col xs={4}>
                      {prepTaskRecord ? (
                        <MappedColumn
                          mapping={PREP_TASK_RECORD_STATUSES_MAP}
                          value={prepTaskRecord.status}
                        />
                      ) : (
                        'N/A'
                      )}
                    </Col>
                    <Col xs={4}>
                      <FormattedOptionalDuration
                        intervalFormat={FORMATTED_DURATION_TYPES.HH_MM_SS}
                        value={
                          prepTaskRecord &&
                          prepTaskRecord.actuals.in_progress_time
                        }
                      />
                    </Col>
                  </Row>
                </ListGroupItem>
              );
            },
          )}
        </ListGroup>
      </Card>
      {prepTaskRecordUUIDForModal && (
        <PrepTaskRecordModalContainer
          prepTaskRecordUUID={prepTaskRecordUUIDForModal}
          onClose={closeModal}
        />
      )}
    </>
  );
};

PrepWorkflowSteps.defaultProps = {
  prepWorkflow: null,
  prepWorkflowRecord: null,
  prepTasksByUri: {},
  prepTaskRecordsByPrepTaskInWorkflowUUID: {},
};

PrepWorkflowSteps.propTypes = {
  // Used in mapStateToProps
  // eslint-disable-next-line react/no-unused-prop-types
  lineItemUri: PropTypes.string.isRequired,
  prepWorkflow: prepWorkflowResourceType,
  prepWorkflowRecord: PropTypes.shape({}),
  prepTaskRecordsByPrepTaskInWorkflowUUID: PropTypes.objectOf(
    PropTypes.shape({}),
  ),
  prepTasksByUri: PropTypes.objectOf(PropTypes.shape({})),
};

const mapStateToProps = (state, ownProps) => {
  const lineItem = Selectors.getUUIDResource(
    state,
    extractUuid(ownProps.lineItemUri),
  );

  const prepWorkflow =
    lineItem && Selectors.getPrepWorkflowForLineItem(state, lineItem);
  const prepWorkflowRecord =
    lineItem && Selectors.getPrepWorkflowRecordForLineItem(state, lineItem);
  const prepTaskRecordsByPrepTaskInWorkflowUUID =
    prepWorkflowRecord &&
    Selectors.getPrepTaskRecordsForPrepWorkflowRecordByPrepTaskInWorkflowUUID(
      state,
      prepWorkflowRecord,
    );

  const prepTasksByUri = Selectors.getPrepTasksByUri(state);
  return {
    prepWorkflow,
    prepWorkflowRecord,
    prepTaskRecordsByPrepTaskInWorkflowUUID,
    prepTasksByUri,
  };
};

export default connect(mapStateToProps)(PrepWorkflowSteps);
