export default ({
  isNormalUser,
}) => {
  const lineItemCustomFieldReferencesOverrides = {};

  if (!isNormalUser) {
    lineItemCustomFieldReferencesOverrides.spec_number = {
      meta: {
        visible_on: [{
          custom_field_id: 'print_to_specs',
          custom_field_related_table_name: 'line_item',
          custom_field_values: ['yes'],
        }],
      },
      // Always required (when visible)
      required: true,
    };

    lineItemCustomFieldReferencesOverrides.stress_class_tooling = {
      meta: {
        visible_on: [{
          // `build_type` is an Order level field.
          // Due to UI/UX issue these `visible_on` options can be applied to Restricted user only,
          // if order level field values are provided to `getvisibleCustomFields`
          custom_field_id: 'build_type',
          custom_field_related_table_name: 'order',
          custom_field_values: ['tool'],
        }],
      },
    };

    lineItemCustomFieldReferencesOverrides.use_temperature_tooling = {
      meta: {
        visible_on: [{
          // `build_type` is an Order level field.
          // Due to UI/UX issue these `visible_on` options can be applied to Restricted user only,
          // if order level field values are provided to `getvisibleCustomFields`
          custom_field_id: 'build_type',
          custom_field_related_table_name: 'order',
          custom_field_values: ['tool'],
        }],
      },
    };
  }

  return lineItemCustomFieldReferencesOverrides;
};
