import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import * as Selectors from 'rapidfab/selectors';
import Actions from 'rapidfab/actions';

import CreateRuns from 'rapidfab/components/plan/CreateRuns';
import withRecordsListHandling from 'rapidfab/containers/hocs/withRecordsListHandling';
import { useLocation } from 'react-router-dom';
import { API_RESOURCES } from 'rapidfab/constants';

const CreateRunsContainer = props => {
  const location = useLocation();
  // autoRedirectToRun flag is used temporary to show loader instead of list page
  // in order not to confuse users for now
  const autoRedirectToRun = Boolean(location?.state && location.state.autoRedirectToRun);
  // CreateRuns UUID is provided in URL when user creates new run.
  // Might need to be used to open specific CreateRuns item modal in future
  const createRunsItemUUID = location?.state && location.state.uuid;
  const createRunsItem = useSelector(state => createRunsItemUUID &&
    Selectors.getUUIDResource(state, createRunsItemUUID));
  const printersByUri = useSelector(Selectors.getPrintersByUri);

  const dispatch = useDispatch();
  const onInitialize = () => dispatch(Actions.Api.nautilus[API_RESOURCES.PRINTER].list());

  useEffect(() => {
    // eslint-disable-next-line no-return-await
    (async () => await onInitialize())();
  }, []);

  return (
    <CreateRuns
      {...props}
      autoRedirectToRun={autoRedirectToRun}
      createRunsItem={createRunsItem}
      printersByUri={printersByUri}
    />
  );
};

export default withRecordsListHandling(
  CreateRunsContainer,
  Selectors.getCreateRuns,
  ['create-runs'],
  {
    useLocationFiltering: false,
    multicolumnSearch: true,
  },
);
