import React from 'react';
import PropTypes from 'prop-types';
import Fa from 'react-fontawesome';
import Icon, { ICON_NAMES } from 'rapidfab/icons';

// Either provide FaIconName or the icon svg
const SidebarLink = ({
  uri,
  iconName,
  label,
  icon,
  className,
  trailingContent = null,
}) => {
  const currentHash = window.location.hash;
  const classData = [
    currentHash === uri ? 'active' : null,
    className,
  ];

  return (
    <li className={classData.join(' ')}>
      <a href={uri}>
        {icon
          ? <Icon name={icon} className="fa-icon-style spacer-right" />
          : <Fa name={iconName} className="spacer-right" />}
        {label}
        {trailingContent && (
          <div className="pull-right">
            {trailingContent}
          </div>
        )}
      </a>
    </li>
  );
};
SidebarLink.defaultProps = {
  icon: null,
  iconName: '',
  className: '',
};

SidebarLink.defaultProps = {
  trailingContent: null,
};

SidebarLink.propTypes = {
  uri: PropTypes.string.isRequired,
  iconName: PropTypes.string,
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.node]).isRequired,
  icon: PropTypes.oneOf(Object.values(ICON_NAMES)),
  className: PropTypes.string,
  trailingContent: PropTypes.element,
};

export default SidebarLink;
