import React from 'react';

const CheckWithWarning = props => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 -0.5 39 39"
    {...props}
  >
    <g fill="none" fillRule="evenodd" stroke="none" strokeWidth="1">
      <g transform="translate(-523 -720)">
        <g transform="translate(523 720)">
          <path
            fill="#24882B"
            fillRule="nonzero"
            d="M33 0H3C1.34 0 0 1.34 0 3v30c0 1.66 1.34 3 3 3h30c1.66 0 3-1.34 3-3V3c0-1.66-1.34-3-3-3z"
          />
          <path
            fill="#FFF"
            fillRule="nonzero"
            d="M14.25 26.58L6.7 19 8.83 16.89 14.25 22.31 27.2 9.42 29.31 11.55z"
          />
          <path
            fill="#FD9726"
            d="M38.816 35.879l-7.687-13.22c-.375-.655-1.22-.843-1.875-.468-.188.094-.282.281-.47.469l-7.593 13.219c-.375.656-.187 1.5.469 1.875.187.093.469.187.656.187h15.281c.75 0 1.407-.656 1.407-1.406 0-.188-.094-.469-.188-.656z"
          />
          <path
            fill="#000"
            d="M31.222 33.629v1.687h-2.437V33.63h2.437zm0-6.563v4.969h-2.437v-4.969h2.437z"
          />
        </g>
      </g>
    </g>
  </svg>
);

export default CheckWithWarning;
