import {
  faCheckCircle,
  faInfoCircle,
  faQuestionCircle,
  faTriangleExclamation,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import PropTypes from 'prop-types';
import Tooltip from 'rapidfab/components/Tooltip';
import { MATERIAL_BATCH_POWDER_QUALITY_STATUSES } from 'rapidfab/constants';
import { MATERIAL_BATCH_POWDER_QUALITY_STATUS_MAP } from 'rapidfab/mappings';
import React from 'react';
import { FormattedMessage } from 'react-intl';

const BatchPowderQualityStatusBadge = ({ qualityStatus }) => {
  const BADGE_DATA = {
    [MATERIAL_BATCH_POWDER_QUALITY_STATUSES.NEW]:
      { icon: faCheckCircle, color: '#1AC98E', text: 'New powder is good to use' },
    [MATERIAL_BATCH_POWDER_QUALITY_STATUSES.SIEVED]:
      { icon: faCheckCircle, color: '#1AC98E', text: 'Ready for use' },
    [MATERIAL_BATCH_POWDER_QUALITY_STATUSES.DONE]:
      { icon: faInfoCircle, color: '#222529', text: null },
    [MATERIAL_BATCH_POWDER_QUALITY_STATUSES.UNKNOWN]:
      { icon: faQuestionCircle, color: '#222529', text: 'Consider sieving this batch before use' },
    [MATERIAL_BATCH_POWDER_QUALITY_STATUSES.UNSIEVED]:
      { icon: faTriangleExclamation, color: '#FF9903', text: 'Consider sieving this batch before use' },
  };

  if (!qualityStatus || !BADGE_DATA[qualityStatus]) {
    return null;
  }

  return (
    <Tooltip
      id="powder_quality_tooltip"
      placement="bottom"
      hideTooltipText={!BADGE_DATA[qualityStatus].text}
      trigger={(
        <span
          className="powder_quality_badge"
        >
          <FontAwesomeIcon style={{ color: BADGE_DATA[qualityStatus].color, marginRight: '5px' }} icon={BADGE_DATA[qualityStatus].icon} />
          <FormattedMessage {...MATERIAL_BATCH_POWDER_QUALITY_STATUS_MAP[qualityStatus]} />
        </span>
      )}
    >
      <div>{BADGE_DATA[qualityStatus].text}</div>
    </Tooltip>

  );
};

export default BatchPowderQualityStatusBadge;

BatchPowderQualityStatusBadge.propTypes = {
  qualityStatus: PropTypes.oneOf(Object.values(MATERIAL_BATCH_POWDER_QUALITY_STATUSES)).isRequired,
};
