import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Card } from 'react-bootstrap';
import Toggle from 'react-toggle';
import SmoothCollapse from 'react-smooth-collapse';
import Loading from 'rapidfab/components/Loading';
import _isEmpty from 'lodash/isEmpty';
import _orderBy from 'lodash/orderBy';
import Comment from 'rapidfab/components/comments/Comment';
import { faChevronDown, faChevronUp } from '@fortawesome/free-solid-svg-icons';
import ThreadsComment from 'rapidfab/components/comments/ThreadsComment';
import CommentForm from './CommentForm';

const PanelHeader = ({ collapsed, setCollapsed }) => {
  const onClickCollapse = () => setCollapsed(!collapsed);
  return (
    <div
      onClick={onClickCollapse}
      role="button"
      className="panel-header"
      tabIndex={0}
    >
      <FormattedMessage id="comments" defaultMessage="Comments" />
      <FontAwesomeIcon size="md" icon={collapsed ? faChevronDown : faChevronUp} />
    </div>
  );
};
PanelHeader.propTypes = {
  collapsed: PropTypes.bool.isRequired,
  setCollapsed: PropTypes.func.isRequired,
};

const ToggleCommentsSort = ({ isAscending, setIsAscending }) => {
  const onChangeToggle = () => setIsAscending(!isAscending);

  return (
    <span className="comment-sort-toggle-container">
      <span className="mr15">Sort newest first</span>
      <Toggle
        onChange={onChangeToggle}
        checked={isAscending}
        icons={false}
        className="comment-sort-toggle"
      />
      <span className="ml15">Sort oldest first</span>
    </span>
  );
};
ToggleCommentsSort.propTypes = {
  isAscending: PropTypes.bool.isRequired,
  setIsAscending: PropTypes.func.isRequired,
};

/** Nested list of mapped posted (readonly) comments. */
const CommentsListView = ({
  comments,
  threadsMessages,
  isThreadsCommentsInFlowsFeatureEnabled,
  showCommentActionAssignment,
  showCommentRelatedResource,
  resourceType,
}) => {
  if (isThreadsCommentsInFlowsFeatureEnabled) {
    return (
      threadsMessages.map(message => (
        <ThreadsComment
          editMode={false}
          messageData={message}
          comments={comments}
        />
      ))
    );
  }

  if (_isEmpty(comments)) {
    return <div><em>Nobody has written comments related to this {resourceType} yet.</em></div>;
  }

  return (
    comments.map(commentObject => (
      <Comment
        comment={commentObject}
        showCommentActionAssignment={showCommentActionAssignment}
        showRelatedResource={showCommentRelatedResource}
      />
    ))
  );
};

CommentsListView.propTypes = {
  comments: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  threadsMessages: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  isThreadsCommentsInFlowsFeatureEnabled: PropTypes.bool.isRequired,
  showCommentActionAssignment: PropTypes.bool.isRequired,
  showCommentRelatedResource: PropTypes.bool.isRequired,
  resourceType: PropTypes.string.isRequired,
};

/*
 * If you need to render read-only comments
 * you might need to consider to use ReadOnlyComments
 */
const Comments = ({
  bgInverse,
  comments,
  onSubmit,
  isLoading,
  isSubmitting,
  usersByUsername,
  showCommentActionAssignment,
  showCommentRelatedResource,
  fetchAllUsers,
  labels,
  handlePushLabelsToModelLibrary,
  isModelLibraryRoute,
  isThreadsCommentsInFlowsFeatureEnabled,
  resourceTableName,
  resourceUUID,
  threadsMessages,
  resourceType,
}) => {
  const [collapsed, setCollapsed] = useState(false);

  const [isAscending, setIsAscending] = useState(true);
  const sortedComments = _orderBy(comments, 'created', [
    isAscending ? 'asc' : 'desc',
  ]);

  return (
    <div className="comments">
      <Card bg={bgInverse ? 'light' : 'dark'} className="m-b table-responsive">
        <Card.Header className="pd-exp inverse">
          <PanelHeader collapsed={collapsed} setCollapsed={setCollapsed} />
        </Card.Header>
        <SmoothCollapse expanded={!collapsed}>
          <div className="card-body-wrapper">
            <Card.Body>
              <div className="comments-container">
                {!_isEmpty(sortedComments) && (
                  <ToggleCommentsSort
                    isAscending={isAscending}
                    setIsAscending={setIsAscending}
                  />
                )}
                {isLoading ? <Loading /> : (
                  <CommentsListView
                    comments={sortedComments}
                    threadsMessages={threadsMessages}
                    isThreadsCommentsInFlowsFeatureEnabled={isThreadsCommentsInFlowsFeatureEnabled}
                    showCommentActionAssignment={showCommentActionAssignment}
                    showCommentRelatedResource={showCommentRelatedResource}
                    resourceType={resourceType}
                  />
                )}
                {onSubmit && (
                  isThreadsCommentsInFlowsFeatureEnabled ? (
                    <ThreadsComment
                      editMode
                      resourceTableName={resourceTableName}
                      resourceUUID={resourceUUID}
                      comments={threadsMessages}
                    />
                  ) : (
                    <CommentForm
                      isSubmitting={isSubmitting}
                      onSubmit={onSubmit}
                      usersByUsername={usersByUsername}
                      fetchAllUsers={fetchAllUsers}
                      labels={labels}
                      isModelLibraryRoute={isModelLibraryRoute}
                      handlePushLabelsToModelLibrary={handlePushLabelsToModelLibrary}
                    />
                  )
                )}
              </div>
            </Card.Body>
          </div>
        </SmoothCollapse>
      </Card>
    </div>
  );
};

Comments.defaultProps = {
  showCommentActionAssignment: true,
  usersByUsername: {},
  onSubmit: null,
  showCommentRelatedResource: false,
  fetchAllUsers: () => {},
  labels: [],
  handlePushLabelsToModelLibrary: () => {},
  isModelLibraryRoute: false,
};

Comments.propTypes = {
  comments: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  // Required if user can add comments for this entity:
  onSubmit: PropTypes.func,
  isLoading: PropTypes.bool.isRequired,
  isSubmitting: PropTypes.bool.isRequired,
  bgInverse: PropTypes.bool.isRequired,
  // Required when showCommentActionAssignment is true:
  usersByUsername: PropTypes.shape({}),
  showCommentActionAssignment: PropTypes.bool,
  showCommentRelatedResource: PropTypes.bool,
  fetchAllUsers: PropTypes.func,
  labels: PropTypes.arrayOf(PropTypes.shape({})),
  handlePushLabelsToModelLibrary: PropTypes.func,
  isModelLibraryRoute: PropTypes.bool,
  isThreadsCommentsInFlowsFeatureEnabled: PropTypes.bool.isRequired,
  resourceTableName: PropTypes.string.isRequired,
  resourceUUID: PropTypes.string.isRequired,
  resourceType: PropTypes.string.isRequired,
  threadsMessages: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
};

export default Comments;
