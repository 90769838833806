import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import { Col, FormLabel, FormGroup } from 'react-bootstrap';

const FormRow = ({
  id,
  className,
  defaultMessage,
  children,
  isRequired,
  hidden,
  labelSuffix,
  ...props
}) => (
  <FormGroup hidden={hidden} className={`form-group row ${className || ''}`} {...props}>
    <Col xs={3}>
      <FormLabel className="control-label--text-left">
        {id && <FormattedMessage id={id} defaultMessage={defaultMessage} />}
        {!id && <span>{defaultMessage}</span>}
        {defaultMessage && ':'}
        {isRequired && ' *'}
        {labelSuffix && (
          <span>
            &nbsp;
            {labelSuffix}
          </span>
        )}
      </FormLabel>
    </Col>
    <Col xs={9}>{children}</Col>
  </FormGroup>
);

FormRow.propTypes = {
  id: PropTypes.string,
  defaultMessage: PropTypes.string,
  children: PropTypes.node.isRequired,
  isRequired: PropTypes.bool,
  hidden: PropTypes.bool,
  labelSuffix: PropTypes.node,
  className: PropTypes.string.isRequired,
};

FormRow.defaultProps = {
  defaultMessage: null,
  isRequired: false,
  hidden: false,
  id: null,
  labelSuffix: null,
};

export default FormRow;
