import { faCoins, faIndustry, faInfoCircle, faObjectGroup, faRulerCombined } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import _capitalize from 'lodash/capitalize';
import _filter from 'lodash/filter';
import PropTypes from 'prop-types';
import FormattedLocalizedCost from 'rapidfab/components/FormattedLocalizedCost';
import Loading from 'rapidfab/components/Loading';
import ResourceIconTooltip from 'rapidfab/components/ResourceIconTooltip';
import TruncatedTitle from 'rapidfab/components/TruncatedTitle';
import { ROUTES } from 'rapidfab/constants';
import { resetRcTooltipInnerStyle } from 'rapidfab/constants/styles';
import MaterialsPrinterTypeIcon from 'rapidfab/icons/materials-printer-type-icon';
import { pluralWord } from 'rapidfab/utils/stringUtils';
import { getRouteURI } from 'rapidfab/utils/uriUtils';
import { extractUuid } from 'rapidfab/utils/uuidUtils';
import RCTooltip from 'rc-tooltip';
import React from 'react';
import { Button } from 'react-bootstrap';
import { Link } from 'react-router-dom';

const MaterialCard = ({
  material,
  manufacturersByUri,
  printerTypes,
  printerTypesFetching,
  handleShowPrinterTypeModal,
  setMaterialTypeModalVisible,
}) => {
  const materialName = material?.name || 'N/A';
  const materialType = _capitalize(material.type) || 'Unassigned';
  const manufacturer = manufacturersByUri[material?.manufacturer]?.name;
  const materialPrinterTypes = _filter(
    printerTypes, ({ materials }) => materials.includes(material.uri));

  const handleSetSinglePrinterType = printerTypeUUID => {
    if (!handleShowPrinterTypeModal) return;

    handleShowPrinterTypeModal(printerTypeUUID);
  };

  const renderPrinterTypesTooltip = (materialPrinterTypes, material) => (
    <RCTooltip
      placement="right"
      id="printerTypesTooltip"
      destroyTooltipOnHide
      overlayInnerStyle={resetRcTooltipInnerStyle}
      mouseLeaveDelay={0.4}
      overlay={(
        <div className="darkTooltip">
          <p className="materialPrinterTypeTooltipTitle">
            Printer Types Supported:
          </p>
          {materialPrinterTypes.slice(0, 10).map(printerType => (
            <ul key={printerType.uri}>
              <li>{printerType.name}</li>
            </ul>
          ))}
          {materialPrinterTypes.length > 10 && (
            <Link
              to={getRouteURI(ROUTES.MATERIALS, null, { uuid: material.uuid }, true)}
            >
              <div className="text-center">Click here to see +{materialPrinterTypes.length - 10} more</div>
            </Link>
          )}
        </div>
      )}
    >
      <FontAwesomeIcon icon={faInfoCircle} className="spacer-left" />
    </RCTooltip>

  );

  const renderMaterialPrinterTypes = (materialPrinterTypes, material) => {
    if (printerTypesFetching) return <Loading />;
    const singlePrinterType = materialPrinterTypes.length === 1 && materialPrinterTypes[0]?.uuid;

    if (!materialPrinterTypes.length) return 'N/A';

    if (singlePrinterType) {
      if (!handleShowPrinterTypeModal) {
        return (
          <TruncatedTitle
            title={materialPrinterTypes[0].name}
            maxTextLength={30}
            inline
          />
        );
      }

      return (
        <Button
          variant="link"
          className="printer-type-icon p-a-0 font-weight-200 d-flex align-items-start"
          onClick={() => handleSetSinglePrinterType(singlePrinterType)}
        >
          <TruncatedTitle
            title={materialPrinterTypes[0].name}
            maxTextLength={30}
            inline
          />
        </Button>
      );
    }

    return (
      <div>
        {materialPrinterTypes.length}
        {renderPrinterTypesTooltip(materialPrinterTypes, material)}
      </div>
    );
  };

  const renderMaterialName = () => {
    if (!setMaterialTypeModalVisible) {
      return (
        <Link
          to={getRouteURI(ROUTES.MATERIALS, null, { uuid: material.uuid }, true)}
        >
          <TruncatedTitle
            title={materialName}
            maxTextLength={30}
            inline
          />
        </Link>
      );
    }

    return (
      <Button
        variant="link"
        className="p-a-0 font-weight-200 d-flex align-items-start"
        onClick={() => setMaterialTypeModalVisible(true)}
      >
        <TruncatedTitle
          title={materialName}
          maxTextLength={35}
          inline
        />
      </Button>
    );
  };

  return (
    <div
      className="card ResourceCard"
      style={material.color && { borderLeft: `4px solid ${material.color}` }}
      key={material.uri}
    >

      <div className="ResourceCardSmallTopView">
        <div className="ResourceCardSmallResources">
          <div className="d-flex">
            <div className="spacer-right">
              <FontAwesomeIcon icon={faObjectGroup} />
            </div>
            <div>
              <div>
                {renderMaterialName()}
              </div>
              <div className="ResourceCardMaterialType">
                {materialType}
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="ResourceCardBottomViewCard mb15">
        <div className="mb10">
          <ResourceIconTooltip
            faIcon={faIndustry}
            iconClassNames="spacer-right"
            text="Manufacturer"
          />
          {manufacturer ? (
            <Link
              to={getRouteURI(ROUTES.MANUFACTURER_EDIT, { uuid: extractUuid(material.manufacturer) }, null, true)}
            >
              <TruncatedTitle
                title={manufacturer}
                maxTextLength={30}
                inline
              />
            </Link>
          ) : 'N/A'}
        </div>
        <div className="d-flex align-items-center">
          <ResourceIconTooltip
            customIcon={MaterialsPrinterTypeIcon}
            customIconClassNames="printer-types-for-materials"
            text={`Printer ${pluralWord('Type', materialPrinterTypes)}`}
          />
          {renderMaterialPrinterTypes(materialPrinterTypes, material)}
        </div>
      </div>
      <div className="ResourceCardBottomViewCard">
        <div className="d-flex align-items-center justify-content-between">
          <div>
            <ResourceIconTooltip
              faIcon={faCoins}
              iconClassNames="spacer-right"
              text="Material Cost"
            />
            {material.cost ? (
              <TruncatedTitle
                title={(<FormattedLocalizedCost value={material.cost} />)}
                maxTextLength={35}
                inline
              />
            ) : 'N/A'}
          </div>
          <div>
            <ResourceIconTooltip
              faIcon={faRulerCombined}
              iconClassNames="spacer-right"
              text="Material Units"
            />
            {material.units || 'N/A'}
          </div>
        </div>
      </div>
    </div>
  );
};

MaterialCard.defaultProps = {
  handleShowPrinterTypeModal: null,
  setMaterialTypeModalVisible: null,
};

MaterialCard.propTypes = {
  material: PropTypes.shape({
    uri: PropTypes.string,
    name: PropTypes.string,
    type: PropTypes.string,
    manufacturer: PropTypes.string,
    cost: PropTypes.number,
    units: PropTypes.string,
    color: PropTypes.string,
    uuid: PropTypes.string,
  }).isRequired,
  manufacturersByUri: PropTypes.shape({}).isRequired,
  printerTypes: PropTypes.arrayOf(PropTypes.shape({
    uri: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
  })).isRequired,
  printerTypesFetching: PropTypes.bool.isRequired,
  handleShowPrinterTypeModal: PropTypes.func,
  setMaterialTypeModalVisible: PropTypes.func,
};

export default MaterialCard;
