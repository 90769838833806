import Constants from 'rapidfab/constants';

function reducer(state = {}, action) {
  switch (action.type) {
    case Constants.STORE_TRACEABILITY_REPORT_FILTERS:
      return { ...state, filters: action.filters };
    default:
      return state;
  }
}

export default reducer;
