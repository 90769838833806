import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import dayjs from 'dayjs';
import * as Selectors from 'rapidfab/selectors';
import Actions from 'rapidfab/actions';
import fetchReport from 'rapidfab/actions/analytics';
import { API_RESOURCES } from 'rapidfab/constants';
import AnalyticsComponent from 'rapidfab/components/analytics';

const AnalyticsContainer = () => {
  const builds = useSelector(Selectors.getBuilds);
  const modelers = useSelector(Selectors.getModelers);
  const printers = useSelector(Selectors.getPrinters);
  const downtime = useSelector(Selectors.getDowntimes);

  const [toDate, setToDate] = useState(() => dayjs().format('YYYY-MM-DD'));
  const [toDateValue, setToDateValue] = useState(() => dayjs().format('YYYY-MM-DD'));
  const [fromDate, setFromDate] = useState(() => dayjs().subtract(1, 'month').format('YYYY-MM-DD'));
  const [fromDateValue, setFromDateValue] = useState(() => dayjs().subtract(1, 'month').format('YYYY-MM-DD'));

  const dispatch = useDispatch();

  const onInitialize = () => {
    dispatch(Actions.Api.nautilus[API_RESOURCES.DOWNTIME].list());
    dispatch(Actions.Api.nautilus[API_RESOURCES.BUILD].list());
    dispatch(Actions.Api.nautilus[API_RESOURCES.PRINTER].list());
    dispatch(Actions.Api.nautilus[API_RESOURCES.MODELER].list());
  };

  useEffect(() => {
    onInitialize();
  }, []);

  const handleDownload = async (templateName, startDate, endDate) => {
    const response = await dispatch(fetchReport(templateName, startDate, endDate)).callApi();
    window.location.href = response.url;
  };

  const handleChange = event => {
    const { name, value } = event.target;
    switch (name) {
      case 'toDateValue':
        setToDateValue(value);
        break;
      case 'fromDateValue':
        setFromDateValue(value);
        break;
      default:
        break;
    }
  };

  const onSubmit = () => {
    setToDate(toDateValue);
    setFromDate(fromDateValue);
  };

  return (
    <AnalyticsComponent
      toDate={toDate}
      toDateValue={toDateValue}
      fromDate={fromDate}
      fromDateValue={fromDateValue}
      handleChange={handleChange}
      onSubmit={onSubmit}
      handleDownload={handleDownload}
      downtime={downtime}
      builds={builds}
      modelers={modelers}
      printers={printers}
    />
  );
};

export default AnalyticsContainer;
