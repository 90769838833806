import _uniq from 'lodash/uniq';

export function isFeatureEnabled(features, featureName) {
  return features.some(
    feature => feature.name === featureName && feature.enabled,
  );
}

// returns true if at least one of the `featureNames` is enabled
export const isSomeFeaturesEnabled = (features, featureNames) => features.some(
  feature => featureNames.includes(feature.name) && feature.enabled,
);

/* Returns true if all `featureNames` are enabled */
export const isAllFeaturesEnabled = (features, featureNames) => {
  const filteredFeatures = _uniq(features.filter(
    feature => featureNames.includes(feature.name) && feature.enabled,
  ));
  return filteredFeatures.length === _uniq(featureNames).length;
};
