import { faObjectGroup } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import RCTooltip from 'rc-tooltip';
import React from 'react';
import PropTypes from 'prop-types';

const TooltipItem = ({ data }) => (
  <p className="truncated-items-tooltip-overlay">
    {data.map((item, index) => (
      <span
        data-cy="hidden_items"
        key={item}
      >
        <FontAwesomeIcon className="spacer-right" icon={faObjectGroup} />
        <strong>&quot;{item}&quot;{index === data.length - 1 ? '' : ', '}</strong>
      </span>
    ))}
  </p>
);

TooltipItem.propTypes = {
  data: PropTypes.arrayOf(PropTypes.string).isRequired,
};

const TruncatedItemsList = ({ data, showCount = 3 }) => {
  const firstItemsToShow = data.slice(0, showCount);
  const restItems = data.slice(showCount);
  return (
    <div className="d-flex align-items-center">
      <div className="mt5 mb5 mr10">
        {firstItemsToShow.map((item, index) => (
          <div
            className="d-flex align-items-baseline"
            data-cy="hidden_items"
            key={item}
          >
            <FontAwesomeIcon className="spacer-right" icon={faObjectGroup} />
            <span>{item}{index === data.length - 1 ? '' : ','}</span>
          </div>
        ))}
      </div>

      {restItems.length ? (
        <RCTooltip
          placement="bottom"
          destroyTooltipOnHide
          mouseLeaveDelay={0.4}
          overlay={(
            <TooltipItem
              data={restItems}
              listStyle
            />
          )}
        >
          <div data-cy="rest-labels">{`+${restItems.length}`}</div>
        </RCTooltip>

      ) : null}
    </div>
  );
};

TruncatedItemsList.propTypes = {
  data: PropTypes.arrayOf(PropTypes.string).isRequired,
  showCount: PropTypes.number,
};

TruncatedItemsList.defaultProps = {
  showCount: 3,
};

export default TruncatedItemsList;
