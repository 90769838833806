import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import Actions from 'rapidfab/actions';

import CustomFields from 'rapidfab/components/admin/CustomFields';
import withRecordsListHandling from 'rapidfab/containers/hocs/withRecordsListHandling';
import { getCustomFields } from 'rapidfab/selectors/customField';
import * as Selectors from 'rapidfab/selectors';
import { API_RESOURCES } from 'rapidfab/constants';

const CustomFieldsContainer = props => {
  const { recordListItems } = props;
  const [updatedData, setUpdatedData] = useState([]);

  useEffect(() => {
    props.onInitialize();
  }, []);

  useEffect(() => {
    setUpdatedData(recordListItems);
  }, [JSON.stringify(recordListItems)]);

  const onSearch = search => {
    const trimmedSearch = search.trim().toLowerCase();
    const updatedRecordListItems = recordListItems
      .filter(({ field_name, field_id, uri, uuid }) => (
        field_name.toLowerCase().includes(trimmedSearch) ||
        field_id.toLowerCase().includes(trimmedSearch) ||
        uri === trimmedSearch ||
        uuid.toLowerCase().includes(trimmedSearch)
      ));
    setUpdatedData(updatedRecordListItems);
  };

  return <CustomFields {...props} data={updatedData} onSearch={onSearch} />;
};

CustomFieldsContainer.propTypes = {
  onInitialize: PropTypes.func.isRequired,
  filters: PropTypes.shape({}).isRequired,
  recordListItems: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
};

const mapStateToProps = state => {
  const role = Selectors.getCurrentUserRoleMax(state);
  return ({
    // bureau: Selectors.getBureauUri(state),
    // fetching: isFetchingInitial(
    //     state.ui.nautilus.custom-fields.list,
    // ),
    custom_fields: Selectors.getCustomFields(state),
    role,

  });
};
const mapDispatchToProps = dispatch => ({
  onInitialize: () => {
    dispatch(Actions.Api.nautilus[API_RESOURCES.CUSTOM_FIELD].list(
      {},
      {},
      {},
      {},
      false,
    ));
  },
});

export default withRecordsListHandling(
  connect(mapStateToProps, mapDispatchToProps)(CustomFieldsContainer),
  getCustomFields,
  ['custom_field'],
  {
    searchBy: 'field_name',
    defaultSort: '-field_name',
    useLocationFiltering: false,
    multicolumnSearch: false,
  },
);
