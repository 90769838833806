import { FormattedMessage } from 'rapidfab/i18n';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import {
  getUUIDResource,
  getRouteUUID,
} from 'rapidfab/selectors';
import Actions from 'rapidfab/actions';

import Loading from 'rapidfab/components/Loading';
import QrContainer from 'rapidfab/components/qr/Container';
import { Col, Container, Row } from 'react-bootstrap';
import BreadcrumbNav from 'rapidfab/components/BreadcrumbNav';
import { extractUuid } from 'rapidfab/utils/uuidUtils';
import { getRouteURI } from 'rapidfab/utils/uriUtils';
import {
  API_RESOURCES,
  ROUTES,
} from 'rapidfab/constants';

const PermanentContainerContainers = () => {
  const uuid = useSelector(state => getRouteUUID(state));
  const permanentContainer = useSelector(state => getUUIDResource(state, uuid));
  const [batch, setBatch] = React.useState(null);
  const isLoading = useSelector(state =>
    state.ui.nautilus[API_RESOURCES.MATERIAL_CONTAINER].get.fetching
    || state.ui.nautilus[API_RESOURCES.MATERIAL_BATCH].get.fetching
    || state.ui.nautilus[API_RESOURCES.MATERIAL_CONTAINER].list.fetching);

  const dispatch = useDispatch();

  const onInitialize = async permanentContainerUUID => {
    if (!permanentContainerUUID) return null;

    const permanentContainer = await dispatch(Actions.Api.nautilus[API_RESOURCES.MATERIAL_CONTAINER]
      .get(permanentContainerUUID, true));
    const batchUri = permanentContainer?.json?.current_batch;

    if (batchUri) {
      const batch = await dispatch(Actions.Api.nautilus[API_RESOURCES.MATERIAL_BATCH].get(extractUuid(batchUri), true));
      if (batch?.json) {
        setBatch(batch.json);
      }
    }

    return window.print();
  };

  useEffect(() => {
    (async () => {
      await onInitialize(uuid);
    })();
  }, []);

  const renderContainer = () => {
    if (!permanentContainer) return null;

    const materialName = batch ? batch.materials.find(m => m.uri === permanentContainer.material)?.name : null;
    const lotUri = batch ? permanentContainer.material_lot || batch.material_lots[0] : null;

    return (
      <>
        <div className="hide-on-print">
          <h2>
            <FormattedMessage
              id="field.permanentContainerRecord"
              defaultMessage="Permanent Container"
            />
          </h2>
        </div>

        <QrContainer
          lotUri={lotUri}
          materialName={materialName}
          container={permanentContainer}
        />
      </>
    );
  };

  if (isLoading || !permanentContainer) {
    return <Loading />;
  }

  return (
    <Container fluid>
      <Row>
        <Col lg={12}>
          <div className="hide-on-print">
            <BreadcrumbNav
              breadcrumbs={[
                'permanentContainers',
                {
                  message: `Permanent Container (${permanentContainer.name})`,
                  href: getRouteURI(ROUTES.PERMANENT_CONTAINER, { uuid: extractUuid(permanentContainer.uri) }),
                },
                `Print Permanent Container ${permanentContainer.name}`,
              ]}
            />
          </div>
          {renderContainer()}
        </Col>
      </Row>
    </Container>
  );
};

export default PermanentContainerContainers;
