import React, { Component } from 'react';
import PropTypes from 'prop-types';
import * as Selectors from 'rapidfab/selectors';

import ServiceProviderJob from 'rapidfab/components/records/order/edit/ServiceProviderJob';
import { connect } from 'react-redux';
import { API_RESOURCES, SERVICE_PROVIDER_JOB_STATUS } from 'rapidfab/constants';
import Actions from 'rapidfab/actions';
import { extractUuid, getShortUUID } from 'rapidfab/utils/uuidUtils';
import Alert from 'react-s-alert';
import _omit from 'lodash/omit';
import { toISOStringWithoutTime } from 'rapidfab/utils/timeUtils';

import { SERVICE_PROVIDER_JOB_CONTAINER } from 'rapidfab/constants/forms';
import { FormattedMessage } from 'react-intl';

class ServiceProviderJobContainer extends Component {
  constructor(props) {
    super(props);

    this.state = {
      fetching: false,
    };
    this.onBidRequest = this.onBidRequest.bind(this);
    this.onSubmit = this.onSubmit.bind(this);
    this.onUpdateRating = this.onUpdateRating.bind(this);
    this.onStatusTransformation = this.onStatusTransformation.bind(this);
  }

  componentDidUpdate(prevProps) {
    const { fetching } = this.props;
    if ((fetching !== prevProps.fetching) && !fetching && this.state.fetching) {
      // eslint-disable-next-line react/no-did-update-set-state
      this.setState({ fetching });
    }
  }

  onSubmit(values, updatedFields) {
    const requiredFields = new Set([
      'id',
      'uri',
      'uuid',
      'currency',
      'cost',
      'due_at',
      'provider_notes',
      'shipping_tracking',
      'status',
    ]);
    const formValues = {};
    Object
      .keys(values)
      .filter(key => requiredFields.has(key))
      .forEach(key => {
        formValues[key] = values[key];
      });

    const { onUpdate, isServiceProvider } = this.props;

    const autogeneratedPayload = {};

    const costUpdated = updatedFields.includes('cost');
    const dueAtUpdated = updatedFields.includes('due_at');

    if (
      !isServiceProvider &&
      values.status === SERVICE_PROVIDER_JOB_STATUS.BID_REQUESTED &&
      (costUpdated || dueAtUpdated)
    ) {
      // It will be updated note when bureau changes some fields after bid request
      // Please look into [ch7001] for details
      const updatedFieldsVerbose = `${(costUpdated && 'the price') || ''}${(dueAtUpdated && costUpdated && ' and ') || ''}${(dueAtUpdated && 'due-date') || ''}`;
      autogeneratedPayload.provider_notes = `Bureau user manually entered ${updatedFieldsVerbose}`;
    }

    const payload = { ...formValues, ...autogeneratedPayload };

    this.setState({ fetching: true });

    onUpdate(payload);
  }

  onBidRequest(providerURI) {
    const { lineItem, onCreate, defaultCurrency, outsourceProcessStepUri } = this.props;

    this.setState({ fetching: true });

    onCreate(providerURI, lineItem, defaultCurrency, outsourceProcessStepUri);
  }

  onStatusTransformation(values, status) {
    const requiredFields = new Set([
      'id',
      'uri',
      'uuid',
      'currency',
      'cost',
      'due_at',
      'provider_notes',
      'shipping_tracking',
      'status',
    ]);
    const formValues = {};
    Object
      .keys(values)
      .filter(key => requiredFields.has(key))
      .forEach(key => {
        formValues[key] = values[key];
      });

    const { onUpdate } = this.props;
    const payload = { ...formValues, status };

    this.setState({ fetching: true });

    onUpdate(payload);
  }

  onUpdateRating(rating) {
    const { values, onUpdate } = this.props;
    const payload = { ...values, job_rating: rating };
    return onUpdate(payload);
  }

  render() {
    const { onBidRequest, onStatusTransformation, onSubmit, onUpdateRating } = this;
    const {
      lineItem,
    } = this.props;
    const { fetching } = this.state;

    const lineItemShortUUID = getShortUUID(lineItem);

    return (
      <ServiceProviderJob
        {...this.props}
        lineItemShortUUID={lineItemShortUUID}
        onBidRequest={onBidRequest}
        onSubmit={onSubmit}
        onUpdateRating={onUpdateRating}
        onStatusTransformation={onStatusTransformation}
        fetching={fetching}
      />
    );
  }
}

ServiceProviderJobContainer.propTypes = {
  fields: PropTypes.shape({}).isRequired,
  fetching: PropTypes.bool.isRequired,
  lineItem: PropTypes.string.isRequired,
  onCreate: PropTypes.func.isRequired,
  onUpdate: PropTypes.func.isRequired,
  provider: PropTypes.shape({}).isRequired,
  job: PropTypes.shape({}),
  initialValues: PropTypes.shape({}).isRequired,
  isServiceProvider: PropTypes.bool.isRequired,
  values: PropTypes.shape({
    status: PropTypes.string,
  }).isRequired,
  defaultCurrency: PropTypes.string.isRequired,
  availableConversions: PropTypes.arrayOf(PropTypes.shape({
    currency: PropTypes.string.isRequired,
  })).isRequired,
  outsourceProcessStepUri: PropTypes.string,
};

ServiceProviderJobContainer.defaultProps = {
  job: null,
  outsourceProcessStepUri: null,
};

function mapStateToProps(state, props) {
  const initialValues = props.job || {};
  const isServiceProvider = Selectors.isServiceProvider(state);
  const fetching = state.ui.nautilus[API_RESOURCES.SERVICE_PROVIDER_JOB].put.fetching ||
    state.ui.nautilus[API_RESOURCES.SERVICE_PROVIDER_JOB].post.fetching ||
    state.ui.nautilus[API_RESOURCES.SERVICE_PROVIDER_JOB].get.fetching;

  const defaultCurrency = Selectors.getBureauDefaultCurrency(state);

  const availableConversions = Selectors.getConversions(state);

  // convert ISO date to yyyy-mm-dd for html input
  if (initialValues.due_at) {
    const date = new Date(initialValues.due_at);
    initialValues.due_at = toISOStringWithoutTime(date);
  }

  const initialFormValues = {};
  Object
    .keys(initialValues)
    .filter(key => SERVICE_PROVIDER_JOB_CONTAINER.FIELDS.includes(key))
    .forEach(key => {
      initialFormValues[key] = initialValues[key];
    });

  return {
    initialFormValues,
    isServiceProvider,
    fetching,
    defaultCurrency,
    availableConversions,
  };
}

const mapDispatchToProps = dispatch => ({
  onCreate: (serviceProvider, lineItem, defaultCurrency, processStepUri) => {
    const serviceProviderJobPayload = {
      service_provider: serviceProvider,
      line_item: lineItem,
      currency: defaultCurrency,
      cost: null,
      due_at: null,
    };

    if (processStepUri) {
      serviceProviderJobPayload.process_step = processStepUri;
    }

    dispatch(Actions.Api.nautilus[API_RESOURCES.SERVICE_PROVIDER_JOB].post(serviceProviderJobPayload))
      .then(response => dispatch(Actions.Api.nautilus[API_RESOURCES.SERVICE_PROVIDER_JOB]
        .get(extractUuid(response.headers.location))));
  },

  onUpdate: formValues => {
    const serviceProviderJobUUID = extractUuid(formValues.uri);

    // Remove read-only fields from payload
    const payload = _omit(formValues, SERVICE_PROVIDER_JOB_CONTAINER.READONLY_FIELDS);

    SERVICE_PROVIDER_JOB_CONTAINER.FLOAT_FIELDS.forEach(
      fieldName => {
        const floatValue = Number.parseFloat(payload[fieldName]);
        if (floatValue) {
          payload[fieldName] = floatValue;
        }
      },
    );

    SERVICE_PROVIDER_JOB_CONTAINER.NULL_FIELDS.forEach(
      fieldName => {
        if (payload[fieldName] === '') {
          payload[fieldName] = null;
        }
      },
    );

    return dispatch(Actions.Api.nautilus[API_RESOURCES.SERVICE_PROVIDER_JOB]
      .put(serviceProviderJobUUID, payload)).then(() => {
      dispatch(Actions.Api.nautilus[API_RESOURCES.SERVICE_PROVIDER_JOB].get(serviceProviderJobUUID));
      Alert.success(<FormattedMessage
        id="toaster.serviceProviderJob.updated"
        defaultMessage="Successfully updated"
      />);
    });
  },
});

const areStatePropsEqual = (next, previous) => JSON.stringify(previous) === JSON.stringify(next);

export default connect(mapStateToProps, mapDispatchToProps, null, { areStatePropsEqual })(ServiceProviderJobContainer);
