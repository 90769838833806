import BureauBarcodeIcon from 'rapidfab/components/BureauBarcodeIcon';
import React, { memo } from 'react';
import PropTypes from 'prop-types';
import {
  Col, Container, Image, OverlayTrigger,
  Row, Tooltip as BSTooltip,
} from 'react-bootstrap';

import 'rapidfab/styles/componentStyles/piecesCards.scss';
import 'rapidfab/styles/componentStyles/progressBars.scss';
import toPairs from 'lodash/toPairs';
import { Link } from 'react-router-dom';
import { getRouteURI } from 'rapidfab/utils/uriUtils';
import {
  FEATURES,
  PIECE_STATUSES,
  ROUTES,
  STATUS_COLOR_CODE_TYPES,
} from 'rapidfab/constants';
import { extractUuid } from 'rapidfab/utils/uuidUtils';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faArrowAltCircleUp,
  faCalendarAlt, faCopy, faExpandArrowsAlt,
  faObjectGroup, faPrint,
  faWrench,
} from '@fortawesome/free-solid-svg-icons';
import Tooltip from 'rapidfab/components/Tooltip';
import Feature from 'rapidfab/components/Feature';
import Config from 'rapidfab/config';
import Loading from 'rapidfab/components/Loading';
import _isEmpty from 'lodash/isEmpty';
import PriorityLabel from 'rapidfab/components/records/run/PriorityLabel';
import StatusColorCode from 'rapidfab/components/StatusColorCode';
import { FormattedDate, FormattedMessage } from 'rapidfab/i18n';
import {
  ORDER_STATUS_MAP,
  PIECE_CARDS_TOOLTIP_THRESHOLDS,
  PIECE_STATUS_COLOR_CODE_MAPPING,
  RUN_STATUS_MAP,
} from 'rapidfab/mappings';
import ModalThreeScene from 'rapidfab/components/ModalThreeScene';
import { truncateText } from 'rapidfab/utils/stringUtils';

const PiecesCardView = memo(({
  renderHeaderView,
  groupedPieces,
  locations,
  modelsByUri,
  ordersByUri,
  runs,
  paginationComponent: PaginationComponent,
  fetchingState,
}) => {
  const {
    piecesFetching,
    ordersFetching,
    printRelatedDataFetching,
    modelsFetching,
  } = fetchingState;
  const showTooltipCondition = (type, text) => {
    const thresholdData = PIECE_CARDS_TOOLTIP_THRESHOLDS[type];

    if (!_isEmpty(thresholdData)) {
      return text?.length >= thresholdData.threshold;
    }
    return false;
  };

  const renderTextWithTooltipConditionally = (key, text, link) => {
    const shouldShowTooltip = showTooltipCondition(key, text);
    const typeConfig = PIECE_CARDS_TOOLTIP_THRESHOLDS[key];
    const truncatedText = shouldShowTooltip ? truncateText(text, typeConfig.threshold) : text;

    const displayText = link ? (
      <span>
        <Link
          className="capitalize"
          to={link}
        >
          <span>{truncatedText}</span>
        </Link>
      </span>
    ) : (
      <span
        className="capitalize"
      >
        {truncatedText}
      </span>
    );

    if (shouldShowTooltip) {
      return (
        <Tooltip
          id={`${key}_tooltip`}
          placement="top"
          trigger={displayText}
        >
          <span className="wrap-text">{text}</span>
        </Tooltip>
      );
    }

    return displayText;
  };

  const renderQRCodeIcon = piece => {
    if (piece.current_print) {
      return (
        <Feature featureName={FEATURES.QR_PRINTS_TRAVELER}>
          <a
            href={`${Config.HOST.QR}/traveler/print/${
              extractUuid(piece.current_print)
            }`}
            target="_blank"
            rel="noopener noreferrer"
          >
            <BureauBarcodeIcon className="font-size-16 spacer-right spacer-left" />
          </a>
        </Feature>
      );
    }
    return null;
  };

  const renderPieceName = piece => {
    const pieceName = piece.name.toLowerCase();
    return renderTextWithTooltipConditionally('pieceName', pieceName, getRouteURI(
      ROUTES.PIECE_EDIT,
      { uuid: extractUuid(piece.uri) },
      {}, true));
  };

  const renderLocationName = location => {
    if (!_isEmpty(location)) {
      return location.name;
    }

    return 'Unassigned';
  };

  const renderTooltipIconCondition = (icon, text, placement = 'right') => (
    <OverlayTrigger
      placement={placement}
      overlay={(
        <BSTooltip>
          {text}
        </BSTooltip>
      )}
    >
      <div className="PiecesCardIcon">
        <FontAwesomeIcon className="spacer-right" icon={icon} />
      </div>
    </OverlayTrigger>
  );

  const renderCurrentStepData = (piece, run) => {
    const step = piece.current_step_position;
    const noWorkflowSet = !piece.workflow;
    const pieceStatus = piece.status;
    const isScheduled = [
      PIECE_STATUSES.CONFIRMED,
      PIECE_STATUSES.NEW,
      PIECE_STATUSES.QUEUED_FOR_PRINTING,
    ].includes(pieceStatus);
    const pieceLineItem = piece.line_item;

    let stepTextValue;

    if (noWorkflowSet) {
      stepTextValue = 'No Workflow Set';
    } else if (isScheduled) {
      stepTextValue = 'Not Scheduled';
    } else {
      stepTextValue = 'N/A';
    }

    if (run) {
      return renderTextWithTooltipConditionally(
        'workstationName',
        `#${step} - ${run.workstation_name}`,
        getRouteURI(ROUTES.RUN_EDIT, { uuid: extractUuid(run.uri) }, {}, true),
      );
    }

    if (noWorkflowSet && pieceLineItem) {
      return (
        <Link
          to={getRouteURI(
            ROUTES.ORDER_EDIT,
            { uuid: extractUuid(piece.order) },
            {}, true)}
          state={{ highlightNoWorkflowLineItemUri: pieceLineItem }}
        >
          <span className="capitalize">No Workflow, Set Up</span>
        </Link>
      );
    }
    return stepTextValue;
  };

  const renderPieceBaseData = (piece, run) => {
    if (printRelatedDataFetching) {
      return (
        <div className="PiecesCardRightDataCard">
          <Loading />
        </div>
      );
    }
    const materialName = piece.material_name || 'No Material Set';

    return (
      <div className="PiecesCardRightDataCard">
        <div className="mb6 d-flex align-items-center">
          {renderTooltipIconCondition(faObjectGroup, 'Material')}
          {renderTextWithTooltipConditionally('materialName', materialName, getRouteURI(
            ROUTES.MATERIALS,
            null,
            { uuid: extractUuid(piece.material) },
            true))}
        </div>
        <div className="d-flex align-items-center">
          {renderTooltipIconCondition(faPrint, 'Current Step - Workflow')}
          <div className="d-flex align-items-center justify-content-between w-full">
            {renderCurrentStepData(piece, run)}
            {
              run?.status && (
                <OverlayTrigger
                  placement="top"
                  overlay={(
                    <BSTooltip>
                      <div>
                        <FormattedMessage
                          id={RUN_STATUS_MAP[run.status].id}
                          defaultMessage={RUN_STATUS_MAP[run.status].defaultMessage}
                        />
                      </div>
                    </BSTooltip>
                  )}
                >
                  <div>
                    <StatusColorCode
                      status={run.status}
                      type={STATUS_COLOR_CODE_TYPES.RUN_STATUS}
                      sizeClassName="regular"
                    />
                  </div>
                </OverlayTrigger>
              )
            }
          </div>
        </div>
      </div>
    );
  };

  const renderOrderBaseData = order => {
    if (!order) return null;
    if (ordersFetching) {
      return (
        <div className="PiecesCardRightDataCard">
          <Loading />
        </div>
      );
    }
    const orderName = order.name;

    return (
      <div className="PiecesCardRightDataCard">
        <div className="mb6 d-flex align-items-center">
          {renderTooltipIconCondition(faCopy, 'Order Name')}
          <div className="d-flex align-items-center justify-content-between w-full">
            {renderTextWithTooltipConditionally('orderName', orderName, getRouteURI(
              ROUTES.ORDER_EDIT,
              { uuid: extractUuid(order.uri) },
              {}, true))}

            <OverlayTrigger
              placement="top"
              overlay={(
                <BSTooltip>
                  <div>
                    <FormattedMessage
                      id={ORDER_STATUS_MAP[order.status].id}
                      defaultMessage={ORDER_STATUS_MAP[order.status].defaultMessage}
                    />
                  </div>
                </BSTooltip>
              )}
            >
              <div>
                <StatusColorCode
                  status={order.status}
                  type={STATUS_COLOR_CODE_TYPES.ORDER_STATUS}
                  sizeClassName="regular"
                />
              </div>
            </OverlayTrigger>
          </div>
        </div>
        <div className="mb6 d-flex align-items-center">
          {renderTooltipIconCondition(faCalendarAlt, 'Due Date')}
          {order.due_date ? <FormattedDate value={order.due_date} /> : 'No Due Date'}
        </div>
        <div className="d-flex align-items-center">
          {renderTooltipIconCondition(faArrowAltCircleUp, 'Order Priority')}
          <div className="PiecesCardRightDataCardOrderPriority">
            <PriorityLabel
              value={order.priority}
              containerClassName="justify-content-start"
            />
          </div>
        </div>
      </div>
    );
  };

  const renderPieceModelData = (model, piece) => {
    const noModel = !model || !model?.snapshot_content;
    if (modelsFetching && noModel) {
      return (
        <div className="PiecesCardRightDataCardModelButton">
          <Loading />
        </div>
      );
    }

    return (
      <div className="PiecesCardRightDataCardModelContainer">
        <button
          type="button"
          className={`${noModel ? 'PiecesCardRightDataCardModelButton' : ''} modalStyle`}
          style={(piece?.status && noModel) ?
            { boxShadow: `0 0 8px 2px ${PIECE_STATUS_COLOR_CODE_MAPPING[piece.status]}` } :
            {}}
        >
          {
            noModel ? (
              <div
                className="PiecesCardRightDataCardNoModel"
              >
                <div>No Model</div>
              </div>
            ) : (
              <>
                <ModalThreeScene
                  showMfgOrientationPanel={false}
                  snapshot={model.snapshot_content || 'LOADING'}
                  model={model.content}
                  unit={model.user_unit}
                  fileUnit={model.file_unit}
                  size={model.size}
                  volume={model.volume_mm}
                  customLayout={openModalFunction => (
                    <>
                      <Image src={model.snapshot_content} className="PiecesCardRightDataCardModel" thumbnail />
                      <div role="button" tabIndex={0} className="PiecesCardRightDataCardModelButtonIconContainer" onClick={openModalFunction}>
                        <FontAwesomeIcon icon={faExpandArrowsAlt} className="PiecesCardRightDataCardModelButtonIcon" />
                      </div>
                    </>
                  )}
                  customContainerClass="PiecesCardRightDataCardModelButton"
                  customContainerStyle={
                    piece?.status ?
                      { boxShadow: `0 0 8px 2px ${PIECE_STATUS_COLOR_CODE_MAPPING[piece.status]}` } :
                      {}
                  }
                />
              </>
            )
          }
        </button>
        {
          (!noModel && model?.size) && (
            <p className="PiecesCardRightDataCardModelDimensions">
              <p className="m-b-0">
                {(model.size.x)?.toFixed(2) ?? 'N/A'} x {(model.size.y)?.toFixed(2) ?? 'N/A'} x
                {(model.size.z)?.toFixed(2) ?? 'N/A'} {model.user_unit}
              </p>
            </p>
          )
        }
      </div>
    );
  };

  return (
    <Container>
      {renderHeaderView()}
      {
        piecesFetching ? <Loading /> : (
          <Row>
            <Col xs={12}>
              <div className="PiecesCardListCards">
                {
                  Object.keys(groupedPieces).length ?
                    toPairs(groupedPieces).map(([locationUri, pieces]) => {
                      const location = locations[locationUri];
                      const locationName = renderLocationName(location);

                      return (
                        <React.Fragment key={locationUri}>
                          <div className="PiecesCardListLocation">
                            <span className="spacer-right">Location</span>
                            {
                              location ? (
                                <Link
                                  to={getRouteURI(
                                    ROUTES.LOCATIONS,
                                    null, { uuid: extractUuid(location?.uri) }, true)}
                                >
                                  <span className="capitalize">{locationName}</span>
                                </Link>
                              ) : (
                                <span className="capitalize">{locationName}</span>
                              )
                            }
                          </div>

                          <div className="card-list PiecesCardList mb30">
                            {pieces.map(piece => {
                              if (!piece) return null;

                              const model = modelsByUri[piece.model];
                              const order = ordersByUri[piece.order];
                              const print = piece.current_print;
                              const run = runs.find(run => run.prints.includes(print));

                              return (
                                <div
                                  className="card PiecesCard"
                                  key={piece.uri}
                                >

                                  <div className="PiecesCardLeftData">
                                    <div className="PiecesCardPieceInfo">
                                      {renderTooltipIconCondition(faWrench, 'Piece Name')}
                                      <div className="PiecesCardPieceInfoData">
                                        <div className="d-flex align-items-center">
                                          {renderPieceName(piece)}
                                          {renderQRCodeIcon(piece)}
                                        </div>
                                      </div>
                                    </div>
                                    {renderPieceModelData(model, piece)}
                                  </div>
                                  <div className="PiecesCardRightData">
                                    {renderPieceBaseData(piece, run)}
                                    {renderOrderBaseData(order)}
                                  </div>
                                </div>
                              );
                            })}
                          </div>
                        </React.Fragment>
                      );
                    })
                    : (
                      <h1 className="text-center mt-2">Nothing found</h1>
                    )
                }
              </div>
            </Col>
            <PaginationComponent />
          </Row>
        )
      }
    </Container>
  );
},
);

PiecesCardView.propTypes = {
  renderHeaderView: PropTypes.func.isRequired,
  groupedPieces: PropTypes.shape({}).isRequired,
  locations: PropTypes.shape({
    uri: PropTypes.string,
  }).isRequired,
  fetchingState: PropTypes.shape({
    piecesFetching: PropTypes.bool,
    ordersFetching: PropTypes.bool,
    printRelatedDataFetching: PropTypes.bool,
    modelsFetching: PropTypes.bool,
  }).isRequired,
  modelsByUri: PropTypes.shape({}).isRequired,
  ordersByUri: PropTypes.shape({}).isRequired,
  runs: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  paginationComponent: PropTypes.func.isRequired,
};

export default PiecesCardView;
