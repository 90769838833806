import { createSelector } from 'reselect';
import * as baseStateSelectors from 'rapidfab/selectors/baseStateSelectors';
import { getStateResources } from 'rapidfab/selectors/helpers/base';
import _map from 'lodash/map';
import _keyBy from 'lodash/keyBy';

export const getLineItemActuals = createSelector(
  [baseStateSelectors.getStateLineItemActuals, getStateResources],
  (uuids, resources) => _map(uuids, uuid => resources[uuid]),
);

export const getLineItemActualsByLineItemUri = createSelector(
  [getLineItemActuals],
  lineItemActuals => _keyBy(lineItemActuals, 'line_item'),
);
