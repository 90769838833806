import { createSelector } from 'reselect';
import { getPredicate } from 'rapidfab/selectors/helpers/base';
import { getShipments } from 'rapidfab/selectors/shipment';
import _filter from 'lodash/filter';

// eslint-disable-next-line import/prefer-default-export
export const getShipmentsForOrder = createSelector(
  [getShipments, getPredicate],
  (shipments, order) => {
    if (!order) {
      return [];
    }
    return _filter(shipments, { order: order.uri });
  },
);
