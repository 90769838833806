import RCTooltip from 'rc-tooltip';
import React from 'react';
import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  Button,
  OverlayTrigger,
  Tooltip,
} from 'react-bootstrap';
import { extractUuid } from 'rapidfab/utils/uuidUtils';
import { documentType } from 'rapidfab/types';
import dayjs from 'dayjs';
import { CAROUSEL_ALLOWED_EXTENSIONS, COLORS } from 'rapidfab/constants';
import { faStar as faStarHollow } from '@fortawesome/free-regular-svg-icons';
import { faBug, faExclamationCircle, faStar, faTimes, faTriangleExclamation } from '@fortawesome/free-solid-svg-icons';
import { getStringFileExtension } from 'rapidfab/utils/fileUtils';
import { FormattedMessage } from 'react-intl';

const DocumentItem = ({
  className,
  document,
  onDelete,
  openVersionModal,
  usersByUri,
  isLatestVersion,
  isHawkingUser,
  handleAddDocumentToCarousel,
  carousel,
  isCarouselFetching,
  documentFetchingData,
}) => {
  const { uri, name, content, user: userUri, previous_documents, created, virus_scan: virusScanStatus } = document;
  const uuid = extractUuid(uri);
  const user = usersByUri[userUri];
  const versionNumber = previous_documents && previous_documents.length + 1;
  const isQuarantined = virusScanStatus === 'quarantined';
  const isAddedToCarousel = carousel && carousel.snapshots.some(({ snapshot }) => snapshot === document.snapshot);
  const isCarouselAllowedExtensions = CAROUSEL_ALLOWED_EXTENSIONS.includes(getStringFileExtension(name));
  const carouselFileTypes = CAROUSEL_ALLOWED_EXTENSIONS.join(', ');

  const renderAddToCarouselButton = () => {
    if (!handleAddDocumentToCarousel) return null;

    if (isCarouselAllowedExtensions) {
      return (
        <FontAwesomeIcon
          role="button"
          className="mr15"
          onClick={() => !isCarouselFetching && handleAddDocumentToCarousel(document)}
          icon={isAddedToCarousel ? faStar : faStarHollow}
        />
      );
    }

    return (
      <OverlayTrigger
        overlay={(
          <Tooltip>
            <FormattedMessage
              id="supportedCarouselFormats"
              defaultMessage={`Only ${carouselFileTypes} can be added to the carousel`}
              values={{ carouselFileTypes }}
            />
          </Tooltip>
        )}
      >
        <FontAwesomeIcon
          role="button"
          className="mr15 half-opacity not-allowed-cursor"
          icon={faStarHollow}
        />
      </OverlayTrigger>
    );
  };

  return (
    <div className={`d-flex align-items-center justify-content-between ${className}`}>
      {renderAddToCarouselButton()}
      <div>
        {
          isQuarantined && (
            <div className="pull-left spacer-right">
              <FontAwesomeIcon icon={faBug} className="spacer-right" style={{ color: COLORS.RED }} />
              <RCTooltip
                placement="top"
                destroyTooltipOnHide
                overlayInnerStyle={{ padding: '10px', wordBreak: 'break-word', textAlign: 'center' }}
                mouseLeaveDelay={0.4}
                overlay={(
                  <div className="tooltipDefaultWidth">
                    <p>The file you&apos;re trying to download has been flagged as potentially malicious.
                      In order to mitigate the risk of harm, it has been compressed into a zip file.
                    </p>
                    <p className="mb0">For guidance on how to proceed, please reach out to us via  <a href="https://authentise.zendesk.com/hc/en-us/articles/360049023911-Suspicious-File-Uploads" rel="noreferrer" target="_blank">Zendesk</a>.</p>
                  </div>
                )}
              >
                <FontAwesomeIcon icon={faTriangleExclamation} style={{ color: COLORS.ORANGE }} />
              </RCTooltip>
            </div>
          )
        }
        <a style={{ color: isQuarantined && COLORS.RED }} href={content}>{name || uuid}</a>
        {' '}
        <span>
          (last update: by {user ? (user.name || user.username) : '-'} at {dayjs(created).format('DD MMM YYYY, HH:mm')})
        </span>
        {' '}
        <span
          className={openVersionModal && 'link'}
          role="button"
          tabIndex={0}
          onClick={openVersionModal}
        >
          Version {versionNumber} {isLatestVersion === true && '(Current)'}
        </span>
        {!content && (
          <OverlayTrigger
            placement="top"
            overlay={(
              <Tooltip>
                Please refresh the page to access your uploaded document.
              </Tooltip>
            )}
          >
            <FontAwesomeIcon icon={faExclamationCircle} className="spacer-left" />
          </OverlayTrigger>
        )}
      </div>
      <div>
        {onDelete && (
          <Button
            size="xs"
            className={isHawkingUser ? 'pull-right hawking-primary' : 'pull-right'}
            variant={!isHawkingUser && 'danger'}
            onClick={() => onDelete(document)}
            disabled={documentFetchingData.delete.fetching &&
              documentFetchingData.delete.uuid === extractUuid(document.uri)}
          >
            <FontAwesomeIcon icon={faTimes} />
          </Button>
        )}
      </div>
    </div>
  );
};

DocumentItem.defaultProps = {
  onDelete: null,
  openVersionModal: null,
  isLatestVersion: null,
  className: '',
  handleAddDocumentToCarousel: null,
};

DocumentItem.propTypes = {
  document: documentType.isRequired,
  onDelete: PropTypes.func,
  openVersionModal: PropTypes.func,
  usersByUri: PropTypes.objectOf(PropTypes.shape({
    name: PropTypes.string,
    username: PropTypes.string,
  })).isRequired,
  isLatestVersion: PropTypes.bool,
  isHawkingUser: PropTypes.bool.isRequired,
  className: PropTypes.string,
  handleAddDocumentToCarousel: PropTypes.func,
  carousel: PropTypes.shape({
    snapshots: PropTypes.arrayOf(PropTypes.shape({})),
  }).isRequired,
  isCarouselFetching: PropTypes.bool.isRequired,
  documentFetchingData: PropTypes.shape({
    delete: PropTypes.shape({
      fetching: PropTypes.bool,
      uuid: PropTypes.string,
    }),
  }).isRequired,
};

export default DocumentItem;
