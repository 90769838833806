import React, { useMemo, useState } from 'react';
import _filter from 'lodash/filter';
import _forEach from 'lodash/forEach';
import _map from 'lodash/map';
import _set from 'lodash/set';
import _toNumber from 'lodash/toNumber';
import _values from 'lodash/values';
import PropTypes from 'prop-types';
import Feature from 'rapidfab/components/Feature';
import SaveButton from 'rapidfab/components/SaveButton';
import Tooltip from 'rapidfab/components/Tooltip';
import NonHawkingFeature from 'rapidfab/components/hawking/NonHawkingFeature';
import {
  ALLOWED_MODEL_EXTENSIONS,
  FEATURES,
  MATERIAL_FAMILY, MAX_FILE_SIZE,
  MODEL_LAYER_THICKNESS_SETTINGS,
  MODEL_LIBRARY_TYPES,
  MODEL_UNITS_BY_FEATURE,
} from 'rapidfab/constants';
import { FormattedMessage, FormattedMessageMappingOption } from 'rapidfab/i18n';
import { MODEL_LIBRARY_TYPE_MAP, MODEL_UNITS_MAP } from 'rapidfab/mappings';
import 'rapidfab/styles/hawking/main.scss';
import { finalFormInputTypes, materialTypeResourceType } from 'rapidfab/types';
import {
  Form as BSForm,
  Button,
  FormControl,
  FormGroup,
  FormLabel,
  FormText,
  Modal,
} from 'react-bootstrap';
import { Field, Form } from 'react-final-form';
import Select from 'react-select';
import { readableFileSize } from 'rapidfab/utils/fileUtils';
import ConfirmationModal from 'rapidfab/components/ConfirmationModal';

const ModelLibraryCreateModal = ({
  bureau,
  onClose,
  modelFile,
  otherModelFiles,
  onSubmitModelAndModelLibrary,
  baseMaterialsByUri,
  onSuccessSubmit,
  isHawkingUser,
  initialFormValues,
  specimenLibraryEnabled,
  isBothDDWAndIntegrationCastorEnabled,
  isRobozeDDWFeatureEnabled,
  isPOCUKOrderFieldsFeatureEnabled,
}) => {
  const [isSaving, setIsSaving] = useState(false);
  const [isApplyToAll, setIsApplyToAll] = useState(false);
  const [isProductType, setIsProductType] = useState(false);
  const [maxFileSizeWarning, setMaxFileSizeWarning] = useState(null);

  const onIsApplyToAllChange = ({ target: { checked } }) =>
    setIsApplyToAll(checked);

  const onFormSubmit = payload => {
    if (modelFile?.size > MAX_FILE_SIZE && !maxFileSizeWarning) {
      const maxFileSize = readableFileSize(MAX_FILE_SIZE);
      const fileSize = readableFileSize(modelFile.size);
      setMaxFileSizeWarning(
        `Allowed max file ${maxFileSize} size exceeded (received file size is ${fileSize}). Would you like to upload the file anyway?`,
      );
      return;
    }
    setMaxFileSizeWarning(null);

    setIsSaving(true);

    _set(payload, 'type', isProductType ? MODEL_LIBRARY_TYPES.PRODUCT : MODEL_LIBRARY_TYPES.SPECIMEN);
    _set(payload, 'layer_thickness', _toNumber(payload.layer_thickness));
    if (payload.base_material) {
      _set(payload, 'base_material', payload.base_material.uri);
    }

    if (payload.desired_quantity) {
      _set(payload, 'desired_quantity', _toNumber(payload.desired_quantity));
    }

    onSubmitModelAndModelLibrary(payload, modelFile)
      .then(response => {
        if (isApplyToAll) {
          _forEach(otherModelFiles, async otherModelFile => {
            const fileNameWithoutExtension = otherModelFile.name.split('.').slice(0, -1).join('.');
            // Using same payload but current model file name without extension as Name
            const otherModelFilePayload = {
              ...payload,
              name: fileNameWithoutExtension,
            };
            await onSubmitModelAndModelLibrary(otherModelFilePayload, otherModelFile);
          });
        }
        return typeof onSuccessSubmit === 'function'
          // Call success submit callback if there is any
          ? onSuccessSubmit(response.payload, isApplyToAll)
          // Just close the modal otherwise
          : onClose();
      })
      .finally(() => {
        setIsSaving(false);
      });
  };

  const bureauName = bureau?.name.split(' ')[0].trim();

  const showApplyToAllCheckbox = !!otherModelFiles.length;

  const otherFileNames = _map(otherModelFiles, 'name');

  const modelFileNameParts = modelFile.name.split('.');
  const extension = modelFileNameParts.pop().toLowerCase();

  const materialFamilies = new Set(_map(_values(baseMaterialsByUri), 'material_family'));

  const baseMaterialDropdownOptionsWithFamilies = [
    // 'Priority'/pinned material family opt-group. Must be at the top of the dropdown list.
    {
      label: bureauName,
      //    ------------------------------------------   ⚠️this may change 👇🏼 this may change⚠️
      options: _filter(_values(baseMaterialsByUri), { material_family: MATERIAL_FAMILY.PRIORITY }),
    },
    // Rest of material families and opt-groups.
    ..._map([...materialFamilies], materialFamilyString => {
      // Priority option is already handled above.
      if (materialFamilyString === MATERIAL_FAMILY.PRIORITY) {
        return null;
      }

      if (!materialFamilyString || materialFamilyString === null) {
        return {
          label: 'Uncategorized Material Families',
          options: _filter(_values(baseMaterialsByUri), { material_family: null }),
        };
      }

      return {
        label: materialFamilyString,
        options: _filter(_values(baseMaterialsByUri), { material_family: materialFamilyString }),
      };
    }),
  ];

  const showModelUnits = useMemo(() => {
    if (isPOCUKOrderFieldsFeatureEnabled) {
      return MODEL_UNITS_BY_FEATURE[FEATURES.POC_UK_ORDER_FIELDS];
    }
    return Object.keys(MODEL_UNITS_MAP);
  }, [isPOCUKOrderFieldsFeatureEnabled]);

  return (
    <Modal show onHide={onClose} backdrop="static">
      <Form
        onSubmit={onFormSubmit}
        initialValues={initialFormValues}
        render={({ handleSubmit, values }) => {
          const isProductTypeSelected = values?.type === MODEL_LIBRARY_TYPES.PRODUCT;
          setIsProductType(isProductTypeSelected);
          return (
            <form onSubmit={handleSubmit}>
              {!maxFileSizeWarning ? (
                <>
                  <Modal.Header closeButton>
                    <Modal.Title>
                      New Model Library Item
                    </Modal.Title>
                  </Modal.Header>
                  <Modal.Body>
                    <FormGroup controlId="mlFile">
                      <FormLabel className="spacer-right">
                        <FormattedMessage id="field.file" defaultMessage="File" />:
                      </FormLabel>
                      {modelFile.name}
                      <p>
                        <sup>
                          <b>
                            <FormattedMessage
                              id="field.note"
                              defaultMessage="Note"
                            />:{' '}
                          </b>
                          <i>
                            <FormattedMessage
                              id="uploadProductNote"
                              defaultMessage="Assemblies are not currently supported."
                            />
                          </i>
                        </sup>
                      </p>
                    </FormGroup>
                    <FormGroup className="form-group" controlId="mlName">
                      <FormLabel>
                        <FormattedMessage id="field.name" defaultMessage="Name" />: *
                      </FormLabel>
                      <Field
                        name="name"
                        type="text"
                        render={props => (
                          <FormControl
                            {...props.input}
                            required
                          />
                        )}
                      />
                    </FormGroup>
                    <FormGroup className="form-group" controlId="mlPartNumber">
                      <FormLabel>
                        <FormattedMessage id="partNumber" defaultMessage="Part Number" />:
                      </FormLabel>
                      <Field
                        name="part_number"
                        render={props => (
                          <FormControl
                            {...props.input}
                          />
                        )}
                      />
                    </FormGroup>
                    <Feature featureNames={[FEATURES.HAWKING_DEPLOYMENT, FEATURES.INTEGRATION_CASTOR]}>
                      <FormGroup className="form-group" controlId="mlMaterial">
                        <FormLabel>
                          <FormattedMessage id="field.baseMaterial" defaultMessage="Base Material" />: *
                        </FormLabel>
                        <Field
                          name="base_material"
                          render={props => (
                            <Select
                              {...props.input}
                              options={baseMaterialDropdownOptionsWithFamilies.filter(Boolean)}
                              getOptionLabel={option => option.name}
                              getOptionValue={option => option.uri}
                              required
                            />
                          )}
                        />
                      </FormGroup>
                    </Feature>
                    <NonHawkingFeature>
                      {!isBothDDWAndIntegrationCastorEnabled && (
                        <FormGroup className="form-group" controlId="mlType">
                          <FormLabel>
                            <FormattedMessage id="field.type" defaultMessage="Type" />: *
                          </FormLabel>
                          {specimenLibraryEnabled ? (
                            <Field
                              name="type"
                              render={props => (
                                <FormControl
                                  required
                                  as="select"
                                  {...props.input}
                                >

                                  {Object.values(MODEL_LIBRARY_TYPES).map(type => (
                                    <FormattedMessageMappingOption
                                      mapping={MODEL_LIBRARY_TYPE_MAP}
                                      value={type}
                                    />

                                  ))}

                                </FormControl>
                              )}
                            />
                          ) : (
                            <Field
                              name="type"
                              type="text"
                              render={props => (
                                <FormControl
                                  {...props.input}
                                  required
                                  value={MODEL_LIBRARY_TYPES.PRODUCT}
                                  disabled
                                  readOnly
                                />
                              )}
                            />
                          )}
                          <FormText>
                            <small>
                              <FormattedMessage
                                id="record.modelLibrary.cannotChangeType"
                                defaultMessage="Type cannot be changed once a model is added to the library."
                              />
                            </small>
                          </FormText>
                        </FormGroup>
                      )}
                      {
                        isProductTypeSelected && !isBothDDWAndIntegrationCastorEnabled && (
                          <FormGroup className="form-group" controlId="mlLayerThickness">
                            <FormLabel>
                              <FormattedMessage
                                id="field.layer_thickness"
                                defaultMessage="Layer Thickness"
                              />
                            </FormLabel>: *
                            <Field
                              name="layer_thickness"
                              type="number"
                              render={props => (
                                <FormControl
                                  {...props.input}
                                  min={MODEL_LAYER_THICKNESS_SETTINGS.MIN}
                                  max={MODEL_LAYER_THICKNESS_SETTINGS.MAX}
                                  step={MODEL_LAYER_THICKNESS_SETTINGS.STEP}
                                  required
                                />
                              )}
                            />
                          </FormGroup>
                        )
                      }
                    </NonHawkingFeature>
                    {/* file upladed is in allowed model extension (ie STL for now) */}
                    {(ALLOWED_MODEL_EXTENSIONS.includes(extension)) && (
                      <FormGroup className="form-group" controlId="modelFileUnits">
                        <b><FormattedMessage id="modelFileUnits" defaultMessage="Model File Units" />: *</b>
                        <Field
                          name="file_unit"
                          render={props => (
                            <FormControl
                              {...props.input}
                              as="select"
                              required
                            >
                              {showModelUnits.map(modelUnit => (
                                <FormattedMessageMappingOption
                                  mapping={MODEL_UNITS_MAP}
                                  value={modelUnit}
                                  key={modelUnit}
                                />
                              ))}
                            </FormControl>
                          )}
                        />
                      </FormGroup>
                    )}
                    {(isBothDDWAndIntegrationCastorEnabled && isRobozeDDWFeatureEnabled) && (
                      <FormGroup className="form-group" controlId="desiredQuantity">
                        <b><FormattedMessage id="field.desired_quantity" defaultMessage="Desired Quantity" />: *</b>
                        <Field
                          name="desired_quantity"
                          type="number"
                          render={props => (
                            <FormControl
                              {...props.input}

                              required
                            />
                          )}
                        />
                      </FormGroup>
                    )}
                  </Modal.Body>
                  <Modal.Footer>
                    {showApplyToAllCheckbox && (
                      <BSForm.Check
                        className="spacer-left"
                        checked={isApplyToAll}
                        onChange={onIsApplyToAllChange}
                        type="checkbox"
                        label={(
                          <>
                            Apply to all ({otherFileNames.length} more)
                            <Tooltip id="applyToAll">
                              <span>{otherFileNames.join(', ')}</span>
                            </Tooltip>
                          </>
                        )}
                      />
                    )}
                    <Button variant="default" onClick={onClose}>
                      <FormattedMessage id="button.cancel" defaultMessage="Cancel" />
                    </Button>
                    <SaveButton isSaving={isSaving} className={isHawkingUser && 'hawking-primary'} variant={!isHawkingUser && 'success'} />
                  </Modal.Footer>
                </>
              )
                : (
                  <ConfirmationModal
                    handleCancel={() => {
                      setMaxFileSizeWarning(null);
                      onClose();
                    }}
                    handleConfirm={handleSubmit}
                    confirmButtonContent="Yes"
                    message={maxFileSizeWarning}
                  />
                )}
            </form>
          );
        }}
      />
    </Modal>
  );
};

ModelLibraryCreateModal.defaultProps = {
  modelFile: null,
  onSuccessSubmit: null,
};

ModelLibraryCreateModal.propTypes = {
  onClose: PropTypes.func.isRequired,
  modelFile: PropTypes.shape({
    name: PropTypes.string,
    size: PropTypes.number,
  }),
  otherModelFiles: PropTypes.arrayOf(PropTypes.shape({
    name: PropTypes.string,
  })).isRequired,
  onSubmitModelAndModelLibrary: PropTypes.func.isRequired,
  initialFormValues: PropTypes.shape({
    name: PropTypes.string.isRequired,
    type: PropTypes.string.isRequired,
    layer_thickness: PropTypes.number.isRequired,
    base_material: PropTypes.string.isRequired,
    file_unit: PropTypes.string.isRequired,
  }).isRequired,
  values: PropTypes.shape({
    type: PropTypes.string,
  }).isRequired,
  input: finalFormInputTypes.isRequired,
  baseMaterialsByUri: PropTypes.objectOf(materialTypeResourceType).isRequired,
  onSuccessSubmit: PropTypes.func,
  isHawkingUser: PropTypes.bool.isRequired,
  specimenLibraryEnabled: PropTypes.bool.isRequired,
  isRobozeDDWFeatureEnabled: PropTypes.bool.isRequired,
  isBothDDWAndIntegrationCastorEnabled: PropTypes.bool.isRequired,
  bureau: PropTypes.shape({
    name: PropTypes.string,
  }).isRequired,
  isPOCUKOrderFieldsFeatureEnabled: PropTypes.bool.isRequired,
};

export default ModelLibraryCreateModal;
