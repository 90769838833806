import React from 'react';
import PropTypes from 'prop-types';

import {
  Button,
  FormLabel,
  FormControl,
  FormGroup,
  Container,
} from 'react-bootstrap';

import BreadcrumbNav from 'rapidfab/components/BreadcrumbNav';
import { FormattedMessage } from 'rapidfab/i18n';
import Loading from 'rapidfab/components/Loading';

const Recalculation = ({
  handleInputChange,
  handleSubmit,
  submitting,
  enabled,
}) => (
  <Container>
    <BreadcrumbNav breadcrumbs={['admin', 'recalculation']} />

    <div className="page-header">
      <h1>
        Recalculation Trigger
      </h1>
    </div>

    <form onSubmit={handleSubmit}>
      <FormGroup>
        <FormLabel>
          <FormattedMessage id="recalculation_trigger" defaultMessage="Recalculation Trigger" />
        </FormLabel>

        <FormControl as="select" placeholder="select" name="enabled" value={enabled} onChange={handleInputChange}>
          <option value="true">Automatically recalculate</option>
          <option value="false">Ask every time</option>
        </FormControl>
      </FormGroup>

      <FormGroup>
        <Button className="m-t-sm" variant="default block" type="submit">
          {submitting ? (
            <Loading />
          ) : (
            <FormattedMessage
              id="updateTrigger"
              defaultMessage="Update Trigger"
            />
          )}
        </Button>
      </FormGroup>
    </form>
  </Container>
);

Recalculation.defaultProps = {};

Recalculation.propTypes = {
  handleInputChange: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  submitting: PropTypes.bool.isRequired,
  enabled: PropTypes.string.isRequired,
};

export default Recalculation;
