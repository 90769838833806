import { createSelector } from 'reselect';
import * as baseStateSelectors from 'rapidfab/selectors/baseStateSelectors';
import { getStateResources } from 'rapidfab/selectors/helpers/base';
import _map from 'lodash/map';
import _filter from 'lodash/filter';
import _keyBy from 'lodash/keyBy';

export const getPostProcessors = createSelector(
  [baseStateSelectors.getStatePostProcessors, getStateResources],
  (uuids, resources) => _map(uuids, uuid => resources[uuid]),
);

export const getNonServicePostProcessors = createSelector(
  [getPostProcessors],
  postProcessors => _filter(postProcessors, { is_service: false }),
);

export const getServicePostProcessors = createSelector(
  [getPostProcessors],
  postProcessors => _filter(postProcessors, { is_service: true }),
);

export const getPostProcessorsByUri = createSelector(
  [getPostProcessors],
  postProcessors => _keyBy(postProcessors, 'uri'),
);
