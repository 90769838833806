import { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import * as Selectors from 'rapidfab/selectors';
import { FEATURES } from 'rapidfab/constants';
import { isFeatureEnabled, isSomeFeaturesEnabled, isAllFeaturesEnabled } from 'rapidfab/utils/featureFlagUtils';

class Feature extends Component {
  render() {
    const {
      children,
      featureName,
      isInverted,
      features,
      featureNames,
      /**
       * `featureNamesEnforceAll` ensures EVERY feature passed in `featureNames` array is
       * true, by default it is if at least one is true
       */
      featureNamesEnforceAll = false,
    } = this.props;

    if ((!featureName && !featureNames) || (featureName && featureNames)) {
      throw new Error('You have to provide only featureName or featureNames props');
    }

    let isEnabled;

    if (featureNames) {
      if (featureNamesEnforceAll === true) {
        /* If `featureNamesEnforceAll` is true; check if ALL `featureNames` are enabled */
        isEnabled = isAllFeaturesEnabled(features, featureNames);
      } else {
        /* If an array of features of is passed in and `featureNamesEnforceAll` is false; return
        true if at least ONE of the features is enabled */
        isEnabled = isSomeFeaturesEnabled(features, featureNames);
      }
    } else {
      isEnabled = isFeatureEnabled(features, featureName);
    }

    if (isInverted) {
      isEnabled = !isEnabled;
    }

    if (isEnabled) {
      return children;
    }

    return null;
  }
}

Feature.defaultProps = {
  featureName: null,
  featureNames: null,
};

Feature.propTypes = {
  children: PropTypes.node.isRequired,
  featureName: PropTypes.oneOf([...Object.values(FEATURES), 'experiment-alpha']),
  featureNames: PropTypes.arrayOf(PropTypes.oneOf(Object.values(FEATURES))),
  isInverted: PropTypes.bool,
  features: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  featureNamesEnforceAll: PropTypes.bool,
};

Feature.defaultProps = {
  isInverted: false,
  featureNamesEnforceAll: false,
};

function mapStateToProps(state) {
  const { feature } = state.ui.nautilus;
  return {
    features: Selectors.getFeatures(state),
    fetching: feature.list.fetching,
    apiErrors: feature.list.errors,
  };
}

export default connect(mapStateToProps)(Feature);
