import {
  Button,
  Col,
  ListGroup,
  ListGroupItem,
  Card,
  Row,
} from 'react-bootstrap';
import PropTypes from 'prop-types';
import React from 'react';
import DataRow from 'rapidfab/components/records/order/DataRow';
import ServiceProviderJobContainer from 'rapidfab/containers/records/order/ServiceProviderJobContainer';
import ModelThumbnail from '../ModelThumbnail';

const LineItem = ({ lineItem, serviceProvider, onModelDownload, job }) => (

  <div className="jumbotron">
    <h2>Line Item &quot;{lineItem?.model.name}&quot;</h2>
    <hr />
    <Row>
      <Col xs={3}>
        {lineItem?.model.snapshot_content && (
          <ModelThumbnail snapshot={lineItem?.model.snapshot_content} />
        )}
      </Col>
      <Col xs={9}>
        <DataRow id="field.bureau" defaultMessage="Bureau">
          {lineItem.bureau.name || '-'}
        </DataRow>
        <DataRow id="field.baseMaterial" defaultMessage="Base Material">
          {lineItem.materials.base.name || '-'}
        </DataRow>
        <DataRow id="field.supportMaterial" defaultMessage="Support Material">
          {lineItem.materials.support.name || '-'}
        </DataRow>
        <DataRow id="field.model" defaultMessage="Model">
          {lineItem.model ? (
            <Button
              size="sm"
              variant="link"
              onClick={() =>
                onModelDownload(lineItem.model.name, lineItem.model.content)}
              role="button"
              tabIndex={0}
              style={{ padding: 0 }}
            >
              {lineItem.model.name}
            </Button>
          ) : (
            '-'
          )}
        </DataRow>

        <DataRow id="modelUnit" defaultMessage="Model Unit">
          {lineItem?.model.file_unit || '-'}
        </DataRow>
        <DataRow id="modelType" defaultMessage="Model Type">
          {lineItem?.model.type || '-'}
        </DataRow>
        <DataRow id="field.quantity" defaultMessage="Quantity">
          {lineItem.quantity || '-'}
        </DataRow>
        <DataRow id="field.status" defaultMessage="Status">
          {lineItem.status || '-'}
        </DataRow>
        <DataRow id="field.notes" defaultMessage="Notes">
          {lineItem.notes || '-'}
        </DataRow>
        <DataRow id="workflow" defaultMessage="Production Workflow">
          {lineItem.workflow.name || '-'}
        </DataRow>
      </Col>
    </Row>
    <hr />
    <Row>
      <Col xs={6}>
        <Card className="panel">
          <ListGroup fill>
            <ListGroupItem key="header" className="header-item">
              <Row>
                <Col xs={3}>
                  <b>Service Provider</b>
                </Col>
                <Col xs={3}>
                  <b>Cost</b>
                </Col>
                <Col xs={3}>
                  <b>Due</b>
                </Col>
                <Col xs={3}>
                  <b>Status</b>
                </Col>
              </Row>
            </ListGroupItem>
            {job.process_step && (
              <ListGroupItem>
                <Row>
                  <Col xs={12}>
                    <b>
                      <span>{job.workstation_name}</span>
                    </b>
                  </Col>
                </Row>
              </ListGroupItem>
            )}
            <ServiceProviderJobContainer
              key={job.uuid}
              form={job.uuid}
              provider={serviceProvider}
              lineItem={job.line_item}
              job={job}
              outsourceProcessStepUri={job.process_step}
            />
          </ListGroup>
        </Card>
      </Col>
    </Row>
  </div>

);

LineItem.propTypes = {
  lineItem: PropTypes.shape({
    model: PropTypes.shape({
      snapshot_content: PropTypes.string,
      content: PropTypes.string,
      unit: PropTypes.string,
      type: PropTypes.string,
      name: PropTypes.string,
      file_unit: PropTypes.string,
    }),
    bureau: PropTypes.shape({
      name: PropTypes.string,
    }).isRequired,
    materials: PropTypes.shape({
      base: PropTypes.shape({
        name: PropTypes.string,
      }),
      support: PropTypes.shape({
        name: PropTypes.string,
      }),
    }),
    quantity: PropTypes.string,
    status: PropTypes.string,
    notes: PropTypes.string,
    workflow: PropTypes.shape({
      name: PropTypes.shape({}),
    }),
  }).isRequired,
  job: PropTypes.shape({
    uuid: PropTypes.string.isRequired,
    process_step: PropTypes.string,
    workstation_name: PropTypes.string,
    line_item: PropTypes.shape({}),
  }).isRequired,
  serviceProvider: PropTypes.shape({}).isRequired,
  onModelDownload: PropTypes.func.isRequired,
};

export default LineItem;
