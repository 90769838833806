import React from 'react';
import PropTypes from 'prop-types';
import {
  Button,
  Modal,
  Form,
  FormGroup,
  Col,
  FormControl,
  FormLabel,
} from 'react-bootstrap';
import { FormattedMessage } from 'react-intl';
import Loading from 'rapidfab/components/Loading';
import { faPlus } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const AddFromDBButton = ({
  show,
  text,
  modalTitle,
  itemLabel,
  manualFunction,
  manualCreateHref,
  manualCreateText,
  manufacturers,
  items,
  item,
  manufacturer,
  sending,
  externalMaterialDbFeatureEnabled,
  onButtonClick,
  onClose,
  onManufacturerChange,
  onItemChange,
  onAdd,
  disabled,
  ...otherProps
}) => {
  if (!externalMaterialDbFeatureEnabled) {
    return (
      <Button
        variant="primary"
        size="sm"
        onClick={manualFunction || null}
        href={manualFunction ? null : manualCreateHref}
        disabled={disabled}
        {...otherProps}
      >
        <FontAwesomeIcon icon={faPlus} /> {text}
      </Button>
    );
  }

  const handleSubmit = event => {
    event.preventDefault();
    onAdd();
  };

  return (
    <>
      <Button
        variant="primary"
        size="sm"
        onClick={onButtonClick}
        disabled={disabled}
        {...otherProps}
      >
        <FontAwesomeIcon icon={faPlus} /> {text}
      </Button>
      <Modal show={show} onHide={onClose}>
        <Modal.Header closeButton>
          <Modal.Title>{modalTitle}</Modal.Title>
        </Modal.Header>
        <Form onSubmit={handleSubmit} horizontal>
          <Modal.Body>
            <FormGroup controlId="manufacturer">
              <Col as={FormLabel} sm={3} className="text-right">
                <FormattedMessage
                  id="field.manufacturer"
                  defaultMessage="Manufacturer"
                />
                : *
              </Col>
              <Col sm={9} className="mb15">
                <FormControl
                  required
                  as="select"
                  value={manufacturer || ''}
                  onChange={({ target }) => onManufacturerChange(target.value)}
                >
                  <FormattedMessage id="field.choose" defaultMessage="Choose…">
                    {choose => (
                      <option value="" disabled>
                        {choose}
                      </option>
                    )}
                  </FormattedMessage>
                  {manufacturers.map(({ uri, name }) => (
                    <option key={uri} value={uri}>
                      {name}
                    </option>
                  ))}
                </FormControl>
              </Col>
            </FormGroup>
            <FormGroup controlId="type">
              <Col as={FormLabel} sm={3} className="text-right">
                {itemLabel}: *
              </Col>
              <Col sm={9}>
                <FormControl
                  className="formControlSelect"
                  required
                  as="select"
                  disabled={items.length === 0}
                  value={item || ''}
                  onChange={({ target }) => onItemChange(target.value)}
                >
                  <FormattedMessage id="field.choose" defaultMessage="Choose…">
                    {choose => (
                      <option value="" disabled>
                        {choose}
                      </option>
                    )}
                  </FormattedMessage>
                  {items.map(({ uri, name }) => (
                    <option key={uri} value={uri}>
                      {name}
                    </option>
                  ))}
                </FormControl>
              </Col>
            </FormGroup>
          </Modal.Body>
          <Modal.Footer>
            <Button
              onClick={manualFunction || null}
              href={manualFunction ? null : manualCreateHref}
            >{manualCreateText}
            </Button>
            <Button variant="success" type="submit">
              {sending ? (
                <Loading />
              ) : (
                <FormattedMessage id="button.add" defaultMessage="Add" />
              )}
            </Button>
          </Modal.Footer>
        </Form>
      </Modal>
    </>
  );
};

AddFromDBButton.propTypes = {
  show: PropTypes.bool.isRequired,
  text: PropTypes.node.isRequired,
  modalTitle: PropTypes.node.isRequired,
  itemLabel: PropTypes.node.isRequired,
  manualCreateHref: PropTypes.string.isRequired,
  manualCreateText: PropTypes.node.isRequired,
  onButtonClick: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
  onManufacturerChange: PropTypes.func.isRequired,
  onItemChange: PropTypes.func.isRequired,
  onAdd: PropTypes.func.isRequired,
  sending: PropTypes.bool.isRequired,
  manufacturers: PropTypes.arrayOf(
    PropTypes.shape({
      uri: PropTypes.string.isRequired,
      name: PropTypes.string.isRequired,
    }),
  ).isRequired,
  items: PropTypes.arrayOf(
    PropTypes.shape({
      uri: PropTypes.string.isRequired,
      name: PropTypes.string.isRequired,
    }),
  ).isRequired,
  manufacturer: PropTypes.string,
  item: PropTypes.string,
  externalMaterialDbFeatureEnabled: PropTypes.bool.isRequired,
  disabled: PropTypes.bool.isRequired,
  manualFunction: PropTypes.func,
};

AddFromDBButton.defaultProps = {
  item: null,
  manufacturer: null,
  manualFunction: null,
};

export default AddFromDBButton;
