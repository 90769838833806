import { createSelector } from 'reselect';
import * as baseStateSelectors from 'rapidfab/selectors/baseStateSelectors';
import _find from 'lodash/find';
import { getStateResources, getPredicate } from 'rapidfab/selectors/helpers/base';
import _map from 'lodash/map';

export const getConversions = createSelector(
  [baseStateSelectors.getStateConversions, getStateResources],
  (uuids, resources) => _map(uuids, uuid => resources[uuid]),
);

export const getSelectedCurrency = createSelector(
  [baseStateSelectors.getStateSelectedCurrency, getConversions],
  (selectedCurrency, conversions) => (
    _find(conversions, { currency: selectedCurrency }) ? selectedCurrency : null
  ),
);

export const getCurrencyConversion = createSelector(
  [getPredicate, getConversions],
  (currencyCode, currencyConversions) => _find(currencyConversions, { currency: currencyCode }),
);

export const getBureauDefaultCurrency = createSelector(
  [getConversions],
  conversions => {
    const bureauDefaultConversion = _find(conversions, { is_bureau_default: true });
    return bureauDefaultConversion && bureauDefaultConversion.currency;
  },
);
