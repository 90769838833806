import React, { useEffect, useState } from 'react';
import { faClose, faPencil } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { FormattedMessage } from 'rapidfab/i18n';
import { Col, Modal, Row } from 'react-bootstrap';
import { API_RESOURCES } from 'rapidfab/constants';
import { getLineItemsForOrder, getRouteUUIDResource } from 'rapidfab/selectors';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import SelectWorkstepSidebar from './SelectWorkstepSidebar';
import LineItemHeader from './LineItemHeader';
import WorkstepDetails from './WorkstepDetails';
import 'rapidfab/styles/componentStyles/line-item-quote-modal.scss';
import Loading from '../Loading';

const EditLineItemQuoteModal = ({ initialLineItemUri, show, close }) => {
  const order = useSelector(getRouteUUIDResource);
  const lineItems = useSelector(state => getLineItemsForOrder(state, order));
  const [selectedLineItem, setSelectedLineItem] = useState(0);
  const lineItemUri = lineItems[selectedLineItem]?.uri;
  const [workstep, setWorkstep] = useState(null);

  const [editMode, setEditMode] = useState(false);

  useEffect(() => {
    const index = lineItems.findIndex(li => li.uri === initialLineItemUri);
    if (index === -1) return;
    setSelectedLineItem(index);
  }, [initialLineItemUri, lineItems]);

  const nextLineItem = () => {
    setSelectedLineItem(previous => (previous === lineItems.length - 1 ? 0 : previous + 1));
  };

  const previousLineItem = () => {
    setSelectedLineItem(previous => (previous === 0 ? lineItems.length - 1 : previous - 1));
  };

  const isLoading = useSelector(state =>
    state.ui.nautilus[API_RESOURCES.LINE_ITEM_QUOTE].get.fetching ||
    state.ui.nautilus[API_RESOURCES.LINE_ITEM_QUOTE].put.fetching ||
    state.ui.nautilus[API_RESOURCES.LINE_ITEM_WORKSTEP_COST_ESTIMATE].get.fetching ||
    state.ui.nautilus[API_RESOURCES.LINE_ITEM_WORKSTEP_COST_ESTIMATE].put.fetching ||
    state.ui.nautilus[API_RESOURCES.LINE_ITEM_WORKSTEP_COST_ESTIMATE].post.fetching ||
    state.ui.nautilus[API_RESOURCES.LINE_ITEM_WORKSTEP_ESTIMATE].get.fetching ||
    state.ui.nautilus[API_RESOURCES.LINE_ITEM_WORKSTEP_ESTIMATE].put.fetching ||
    state.ui.nautilus[API_RESOURCES.LINE_ITEM_WORKSTEP_ESTIMATE].post.fetching ||
    state.ui.nautilus[API_RESOURCES.LINE_ITEM_WORKSTEP_ESTIMATE].delete.fetching,
  );

  return (
    <Modal
      size="xl"
      show={show}
      onHide={close}
      backdrop="static"
      className="quoteProcessStepModalShow"
      dialogClassName="line-item-quote-modal"
    >
      <Modal.Header>
        <Modal.Title className="w-100 d-flex align-items-center justify-content-between">
          <div className="d-flex align-items-center">
            <FontAwesomeIcon icon={faPencil} />
            <p className="custom-darken-modal-title-text mt0">
              <FormattedMessage
                id="line_item.edit_order_quote"
                defaultMessage="Edit Order Quote"
              />
            </p>
          </div>
          <FontAwesomeIcon
            icon={faClose}
            onClick={close}
            tabIndex={0}
            role="button"
            className="custom-darken-modal-button"
          />
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Row>
          <Col md={3}>
            <SelectWorkstepSidebar
              lineItemUri={lineItemUri}
              workstep={workstep}
              setWorkstep={setWorkstep}
              editMode={editMode}
            />
          </Col>
          <Col md={9} className="bl-light">
            <LineItemHeader
              lineItemUri={lineItemUri}
              changeSelectedLineItem={{
                next: nextLineItem,
                prev: previousLineItem,
                hasNext: selectedLineItem !== lineItems.length - 1,
                hasPrev: selectedLineItem !== 0,
              }}
            />
            {(!workstep || isLoading) ? (
              <Loading />
            ) : (
              <WorkstepDetails
                lineItemUri={lineItemUri}
                workstepUri={workstep}
                editMode={editMode}
                setEditMode={setEditMode}
              />
            )}
          </Col>
        </Row>
      </Modal.Body>
    </Modal>
  );
};

EditLineItemQuoteModal.propTypes = {
  initialLineItemUri: PropTypes.string.isRequired,
  show: PropTypes.func.isRequired,
  close: PropTypes.func.isRequired,
};

export default EditLineItemQuoteModal;
