import React from 'react';

const SuccessCheckmarkAnimation = () => (
  <div className="checkmarkContainer">
    <div className="checkmarkWrapper">
      <svg className="svgContainer" viewBox="0 0 154 139">
        <path
          d="M98.3,13.8C89.55,9.15,79.6,6.5,69,6.5C34.5,6.5,6.5,34.5,6.5,69s28,62.5,62.5,62.5s62.5-28,62.5-62.5 c0-4.25-0.45-8.4-1.25-12.45"
          className="circleGray"
        />
        <path
          d="M98.3,13.8C89.55,9.15,79.6,6.5,69,6.5C34.5,6.5,6.5,34.5,6.5,69s28,62.5,62.5,62.5s62.5-28,62.5-62.5 c0-4.25-0.45-8.4-1.25-12.45"
          className="circleLightBlue"
        />
        <path
          d="M98.3,13.8C89.55,9.15,79.6,6.5,69,6.5C34.5,6.5,6.5,34.5,6.5,69s28,62.5,62.5,62.5s62.5-28,62.5-62.5 c0-4.25-0.45-8.4-1.25-12.45"
          className="circleGreen"
        />
        <polyline points="36.25,61.75 65.75,89.75 142.25,9.25" className="tickGray" />
        <polyline points="36.25,61.75 65.75,89.75 142.25,9.25" className="tickYellow" />
        <polyline points="36.25,61.75 65.75,89.75 142.25,9.25" className="tickGreen" />
      </svg>
    </div>
  </div>
);

export default SuccessCheckmarkAnimation;
