import React from 'react';
import { useSelector } from 'react-redux';

import * as Selectors from 'rapidfab/selectors';

import ModelLibrariesByFiltersContainer from 'rapidfab/containers/ModelLibrariesByFiltersContainer';

const HawkingCompanyLibraryContainer = props => {
  const bureauUri = useSelector(Selectors.getBureauUri);
  const companyModelLibraries = useSelector(Selectors.getCompanyModelLibraries);

  return (
    <ModelLibrariesByFiltersContainer
      {...props}
      ownerUri={bureauUri}
      modelLibraries={companyModelLibraries}
    />
  );
};

export default HawkingCompanyLibraryContainer;
