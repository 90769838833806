import React from 'react';
import PropTypes from 'prop-types';
import { Navigate } from 'react-router-dom';
import { ROUTES } from 'rapidfab/constants';

export default function PublicRoute({ session, component: Component }) {
  // eslint-disable-next-line react/prop-types
  if (!session.role) {
    return <Component />;
  }
  return <Navigate to={ROUTES.HOME} />;
}

PublicRoute.propTypes = {
  session: PropTypes.shape({
    fetching: PropTypes.bool,
    currentUser: PropTypes.shape({}),
    role: PropTypes.string,
  }).isRequired,
  component: PropTypes.oneOfType([PropTypes.node, PropTypes.elementType]).isRequired,
};
