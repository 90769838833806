import Constants from 'rapidfab/constants';
import * as Sentry from '@sentry/react';
import _get from 'lodash/get';
import isIgnoredError from '../utils/isIgnoredError';

function jsonTryParse(text) {
  try {
    return JSON.parse(text || null);
  } catch {
    return null;
  }
}

function apiMiddleware({ dispatch, getState }) {
  return next => action => {
    const {
      api,
      callApi,
      filters,
      payload,
      shouldCallAPI = () => true,
      types,
      uuid,
    } = action;
    if (!types) {
      return next(action);
    }

    const [requestType, successType, failureType] = types;
    const createAPIAction = updates => ({
      api,
      filters,
      uuid,
      payload,
      type: successType,
      ...updates,
    });

    if (
      !Array.isArray(types) ||
      types.length !== 3 ||
      !types.every(type => typeof type === 'string')
    ) {
      throw new Error('Expected an array of three string types.');
    }

    if (typeof callApi !== 'function') {
      throw new TypeError('Expected fetch to be a function.');
    }

    const state = getState();
    if (!shouldCallAPI(state)) {
      return new Promise(resolve => {
        const method = api.method.toLowerCase();
        const resourceData = _get(state, [
          'ui',
          api.host,
          api.resource,
          method,
          'json',
        ]);

        resolve(
          next(
            createAPIAction({
              json: resourceData,
              type: Constants.RESOURCE_REQUEST_SUPPRESSED,
            }),
          ),
        );
      });
    }

    dispatch(
      createAPIAction({
        type: requestType,
      }),
    );

    const handleError = (errors, status) => {
      if (isIgnoredError(errors)) {
        return;
      }

      let sanitizedErrors = null;
      const url = uuid
        ? `${api.host}/${api.resource}/${uuid}/`
        : `${api.host}/${api.resource}/`;
      const failedToFetch = errors.message === 'Failed to fetch';
      if (typeof errors === 'object' && errors.message) {
        const errorMessage = failedToFetch
          ? `Failed to ${api.method} ${url}. Got status ${status}`
          : errors.message;
        sanitizedErrors = [{ code: 'api-error', title: errorMessage }];
      }
      dispatch(
        createAPIAction({
          errors: sanitizedErrors || errors,
          type: failureType,
        }),
      );
      if (failedToFetch) {
        Sentry.captureException(
          new Error(
            `Failed to ${api.method} ${url}. ${
              status ? `Got status ${status}` : null
            }`,
          ),
          {
            extra: { api, uuid, filters, errors, payload },
          },
        );
      }
    };

    const handleSingleResponse = response =>
      response.text().then(text => {
        const json = jsonTryParse(text);
        if (response.status >= 400) {
          const error = new Error(
            `Error calling API on ${failureType} response status ${response.status}`,
            createAPIAction({}),
          );
          /* Backend can return the object contains "errors.message" or just "message",
           so put another condition to handle this type of error information comes from the response. */
          if (json?.errors?.length) {
            handleError(json.errors, response.status);
          } else if (json?.message) {
            handleError(json, response.status);
          } else {
            handleError(error, response.status);
          }
          throw error;
        }
        if (text && !json) {
          const error = new Error('Could not parse response', text);
          handleError(error, response.status);
          throw error;
        }

        // eslint-disable-next-line consistent-return
        return dispatch(
          createAPIAction({
            json,
            headers: {
              location: response.headers.get('Location'),
              uploadLocation: response.headers.get('X-Upload-Location'),
              // Used for Location resource related settings
              locationSettings: response.headers.get('Location-Settings'),
            },
            type: successType,
          }),
        );
      });

    const handleChainedResponses = async response => {
      // Unpack from Array of Arrays to single Array
      const responses = response.flat();

      /*
        1. Prepare response object based on the last successful response data
        2. Create JSON data in order to merge the resources of all the chunked responses.
      */
      const responseData = {};
      const jsonData = [];

      /*
        for await ... of is used to complete all async logic inside and prepare
        the response object and JSON data to the return method.
      */

      // eslint-disable-next-line no-restricted-syntax
      for await (const promiseResponse of responses) {
        // Do the regular logic which we did for the single response.
        const text = await promiseResponse.text();
        const json = jsonTryParse(text);
        if (promiseResponse.status >= 400) {
          const error = new Error(
            `Error calling API on ${failureType} response status ${promiseResponse.status}`,
            createAPIAction({}),
          );

          /*
            Backend can return the object contains "errors.message" or just "message",
            so put another condition to handle this type of error information comes from the response.
          */

          if (json?.errors?.length) {
            handleError(json.errors, promiseResponse.status);
          } else if (json?.message) {
            handleError(json, promiseResponse.status);
          } else {
            handleError(error, promiseResponse.status);
          }
          throw error;
        }
        if (text && !json) {
          const error = new Error('Could not parse response', text);
          handleError(error, promiseResponse.status);
          throw error;
        }

        /*
           If no errors on each step, prepare the responseData of the last successful response
           and push the JSON data to the jsonData array.
        */

        responseData.headers = {
          location: promiseResponse.headers.get('Location'),
          uploadLocation: promiseResponse.headers.get('X-Upload-Location'),
          // Used for Location resource related settings
          locationSettings: promiseResponse.headers.get('Location-Settings'),
        };
        responseData.type = successType;
        jsonData.push(json);
      }

      // -> Merge the resources of all the chunked responses.

      // eslint-disable-next-line unicorn/no-array-reduce
      const mergedJson = jsonData.reduce((accumulator, current) => {
        // Make sure we have some resources to merge
        const resources = current?.resources || [];
        const accumulatorResources = accumulator?.resources || [];

        // -> Merge and return a single object of resources.
        return {
          ...accumulator,
          resources: [...accumulatorResources, ...resources],
        };
      });

      return dispatch(
        createAPIAction({
          json: mergedJson,
          ...responseData,
        }),
      );
    };

    const handleResponse = response => {
      if (Array.isArray(response)) {
        return handleChainedResponses(response);
      }
      return handleSingleResponse(response);
    };

    return callApi().then(handleResponse, handleError);
  };
}

export default apiMiddleware;
