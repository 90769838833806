import React, { useContext } from 'react';
import { Card, Col, Form, FormControl, Row } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { COLORS } from 'rapidfab/constants';
import { ColorCode } from 'rapidfab/components/GuidelinesEngine/GuidelineSummaryBlock';
import GuidelineContext from 'rapidfab/context/GuidelineContext';
import _capitalize from 'lodash/capitalize';
import GuidelineValue from 'rapidfab/components/GuidelinesEngine/GuidelineValue';
import { faMinusCircle, faPlusCircle, faWarning } from '@fortawesome/free-solid-svg-icons';

const GuidelineIfBlock = () => {
  const guidelineContext = useContext(GuidelineContext);

  const {
    guidelineExpressions,
    listExpressionFieldNamesBySelectedFieldType,
    getNamedExpressionBySelectedFieldName,
    listComparators,
  } = guidelineContext;

  return (
    <>
      {
        guidelineExpressions.expressions.map(({ id, uuid }, index) => {
          const addAndDeleteExpressionButtons = (
            <Col md={1} className="d-flex align-items-center">
              {
                index === 0 && guidelineExpressions.expressions.length === 1 ? (
                  <FontAwesomeIcon
                    onClick={() => guidelineContext
                      .dispatchGuidelineExpressionsAction({
                        type: 'GUIDELINE_EXPRESSIONS_ADD_NEW',
                        payload: null,
                      })}
                    size="lg"
                    icon={faPlusCircle}
                    role="button"
                  />
                )
                  : (
                    <div className="d-flex">
                      {id !== 0 && (
                        <FontAwesomeIcon
                          disabled
                          className="spacer-right"
                          onClick={() => guidelineContext
                            .dispatchGuidelineExpressionsAction({
                              type: 'GUIDELINE_EXPRESSIONS_DELETE_AT',
                              payload: null,
                              uuid,
                            })}
                          size="lg"
                          icon={faMinusCircle}
                          role="button"
                        />
                      )}
                      <FontAwesomeIcon
                        onClick={() => guidelineContext
                          .dispatchGuidelineExpressionsAction({
                            type: 'GUIDELINE_EXPRESSIONS_ADD_NEW',
                            payload: null,
                          })}
                        size="lg"
                        icon={faPlusCircle}
                        role="button"
                      />
                    </div>
                  )
              }
            </Col>
          );

          const fieldTypeOptions = ([
            <option data-label="Line Item" key="line-item" value="line-item">
              Line Item
            </option>,
            <option data-label="Order" key="order" value="order">
              Order
            </option>,
            <option data-label="Workflow Step" key="process_step" value="process-step">
              Workflow Step
            </option>,
          ]);

          const regularRow = (
            <Row className="spacer-top">
              <Col className="d-flex align-items-center">
                <ColorCode color={COLORS.BLUE} className="spacer-right" />
                <FormControl
                  value={guidelineExpressions.expressions[index].field_type?.value}
                  required
                  data-cy="guidelines-field-type-block"
                  as="select"
                  onChange={event =>
                    guidelineContext
                      .dispatchGuidelineExpressionsAction({
                        type: 'GUIDELINE_EXPRESSIONS_FIELD_TYPE_CHANGE',
                        payload: {
                          label: event.target.selectedOptions[0].getAttribute('data-label'),
                          value: event.target.value,
                        },
                        uuid,
                      })}
                >
                  <option key={null} value="">
                    Field Type
                  </option>
                  {fieldTypeOptions}
                </FormControl>
              </Col>
              <Col className="d-flex align-items-center">
                <ColorCode color={COLORS.GREEN} className="spacer-right" />
                <FormControl
                  value={guidelineExpressions.expressions[index].field?.value}
                  required
                  data-cy="guidelines-field-block"
                  as="select"
                  onChange={event =>
                    guidelineContext
                      .dispatchGuidelineExpressionsAction({
                        type: 'GUIDELINE_EXPRESSIONS_FIELD_CHANGE',
                        payload: {
                          label: event.target.selectedOptions[0].getAttribute('data-label'),
                          value: event.target.value,
                        },
                        uuid,
                      })}
                >
                  <option key={null} value="">
                    Field
                  </option>
                  {
                    listExpressionFieldNamesBySelectedFieldType(uuid)?.map(
                      expression => (
                        <option data-label={expression.name} key={expression.name} value={expression.uri}>
                          {expression.name}
                        </option>
                      ),
                    )
                  }
                </FormControl>
              </Col>
              <Col className="d-flex align-items-center">
                <ColorCode color={COLORS.PURPLE} className="spacer-right" />
                <FormControl
                  disabled={guidelineExpressions.expressions[index].field === null ||
                    guidelineExpressions.expressions[index].field === ''}
                  value={guidelineExpressions.expressions[index].comparator?.label}
                  required
                  data-cy="guidelines-comparator-block"
                  as="select"
                  onChange={event =>
                    guidelineContext
                      .dispatchGuidelineExpressionsAction({
                        type: 'GUIDELINE_EXPRESSIONS_COMPARATOR_CHANGE',
                        payload: {
                          label: event.target.value,
                          value: event.target.selectedOptions[0].getAttribute('data-uri'),
                        },
                        uuid,
                      })}
                >
                  <option key={null} value="">
                    Comparator
                  </option>
                  {
                    listComparators(uuid)?.map(
                      comparator => (
                        <option
                          data-label={comparator.name}
                          data-uri={comparator.uri}
                          key={comparator.name}
                          value={comparator.name}
                        >
                          {_capitalize(comparator.name)}
                        </option>
                      ),
                    )
                  }
                </FormControl>
              </Col>
              <Col className="d-flex align-items-center">
                <ColorCode color={COLORS.WHITE} className="spacer-right" />
                <GuidelineValue
                  uuid={uuid}
                  index={index}
                  getNamedExpressionBySelectedFieldName={getNamedExpressionBySelectedFieldName}
                />
              </Col>
              {addAndDeleteExpressionButtons}
            </Row>
          );

          return regularRow;
        })
      }
      {
        guidelineExpressions.expressions.length > 1 && (
          <>
            <div className="py-2 d-flex align-items-center">
              <Form.Check
                disabled={guidelineExpressions.join === 'OR'}
                className="mr15"
                inline
                name="andConditionJoin"
                checked={guidelineExpressions.join === 'AND'}
                onClick={event => guidelineContext
                  .dispatchGuidelineExpressionsAction({
                    type: 'GUIDELINE_EXPRESSIONS_JOIN_CHANGE',
                    payload: event.currentTarget.checked ? 'AND' : null,
                  })}
                type="checkbox"
                label="AND"
              />
              <Form.Check
                disabled={guidelineExpressions.join === 'AND'}
                className="mr15"
                inline
                name="orConditionJoin"
                checked={guidelineExpressions.join === 'OR'}
                onClick={event => guidelineContext
                  .dispatchGuidelineExpressionsAction({
                    type: 'GUIDELINE_EXPRESSIONS_JOIN_CHANGE',
                    payload: event.currentTarget.checked ? 'OR' : null,
                  })}
                type="checkbox"
                label="OR"
              />
              <hr className="flex-grow-1" />
            </div>
            {
              guidelineExpressions.expressions.length > 1 && guidelineExpressions.join === null && (
                <Card bg="danger">
                  <Card.Body>
                    <p>
                      <FontAwesomeIcon className="spacer-right" icon={faWarning} />
                      Please select a join (AND/OR) before continuing.
                    </p>
                  </Card.Body>
                </Card>
              )
            }
          </>
        )
      }
    </>
  );
};

export default GuidelineIfBlock;
