import React, { useState } from 'react';
import PropTypes from 'prop-types';
import {
  Button,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  FormControl,
} from 'react-bootstrap';
import Loading from 'rapidfab/components/Loading';
import { FormattedMessage } from 'react-intl';

const LineItemDuplicateModal = ({
  onClose,
  onDuplicate,
  isLineItemDuplicating,
  quantity: currentQuantity,
}) => {
  const [quantity, setQuantity] = useState(currentQuantity);
  const onSubmit = event => {
    event.preventDefault();
    onDuplicate(Number(quantity));
  };
  return (
    <Modal show onHide={onClose} backdrop="static">
      <ModalHeader closeButton />
      <form onSubmit={onSubmit}>
        <ModalBody>
          <FormattedMessage
            id="record.lineItem.duplicateModalMessage"
            defaultMessage="You cannot edit a confirmed line item in production.  We can make a duplicate in this order.  How many copies of this do you want to make of this in a new line item?"
          />
          <FormControl
            className="mt15"
            type="number"
            step="1"
            min="1"
            onChange={event => setQuantity(event.target.value)}
            value={quantity}
            required
          />
        </ModalBody>
        <ModalFooter>
          <Button
            variant="primary"
            onClick={onClose}
            disabled={isLineItemDuplicating}
          >
            <FormattedMessage id="button.cancel" defaultMessage="Cancel" />
          </Button>
          <Button
            type="submit"
            variant="success"
            disabled={isLineItemDuplicating}
          >
            {isLineItemDuplicating ? (
              <Loading />
            ) : (
              <FormattedMessage
                id="button.duplicate"
                defaultMessage="Duplicate"
              />
            )}
          </Button>
        </ModalFooter>
      </form>
    </Modal>
  );
};

LineItemDuplicateModal.defaultProps = {};

LineItemDuplicateModal.propTypes = {
  onClose: PropTypes.func.isRequired,
  onDuplicate: PropTypes.func.isRequired,
  isLineItemDuplicating: PropTypes.bool.isRequired,
  quantity: PropTypes.string.isRequired,
};

export default LineItemDuplicateModal;
