import _filter from 'lodash/filter';
import _groupBy from 'lodash/groupBy';
import _reduce from 'lodash/reduce';
import _maxBy from 'lodash/maxBy';

export default function specimensByWorkflow(specimens, workflowURIs) {
  /**
   * Get list of used specimens for run based on production workflows of run
   * Be aware that method returns only specimens with max quantity, e.g.;
   *
   * Specimen1(ModelLibrary1, parent_workflow=Workflow1, workflow=WorkflowSpecimen1, quantity=5)
   * Specimen2(ModelLibrary1, parent_workflow=Workflow2, workflow=WorkflowSpecimen2, quantity=2)
   * Specimen3(ModelLibrary2, parent_workflow=Workflow1, workflow=WorkflowSpecimen3, quantity=2)
   * Specimen4(ModelLibrary2, parent_workflow=Workflow1, workflow=WorkflowSpecimen4, quantity=3)
   * Specimen5(ModelLibrary2, parent_workflow=Workflow1, workflow=WorkflowSpecimen5, quantity=1)
   *
   * Method creates dict
   * {
   *   ModelLibrary1: [Specimen1, Specimen2]
   *   ModelLibrary2: [Specimen3, Specimen4, Specimen5]
   * }
   *
   * And returns max value of specimen quantity for each model library
   * Specimen1 > Specimen2
   * Specimen4 > Specimen3 > Specimen5
   *
   * [Specimen1, Specimen4]
   *
   * @return {array} Array with specimen objects
   * */
  const workflowSpecimens = _filter(
    specimens,
    specimen => workflowURIs.includes(specimen.parent_workflow),
  );
  const workflowSpecimensByModelLibrary = _groupBy(workflowSpecimens, 'model_library');

  return _reduce(workflowSpecimensByModelLibrary, (result, specimen) => {
    result.push(_maxBy(specimen, 'quantity'));
    return result;
  }, []);
}
