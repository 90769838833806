import React, { useEffect } from 'react';
import Actions from 'rapidfab/actions';
import * as Selectors from 'rapidfab/selectors';
import _get from 'lodash/get';
import _set from 'lodash/set';
import { API_RESOURCES, ROUTES } from 'rapidfab/constants';
import { getRouteURI } from 'rapidfab/utils/uriUtils';
import { useDispatch, useSelector } from 'react-redux';
import { extractUuid } from 'rapidfab/utils/uuidUtils';
import Alert from 'rapidfab/utils/alert';

import { SHIPPING_CONTAINER } from 'rapidfab/constants/forms';
import Shipping from 'rapidfab/components/records/shipping';
import { FormattedMessage } from 'react-intl';

function redirect() {
  window.location.hash = getRouteURI(ROUTES.SHIPPINGS);
}

const redirectToNewShipping = uri => {
  window.location = getRouteURI(ROUTES.SHIPPING_EDIT, { uuid: extractUuid(uri) });
};

const ShippingContainer = props => {
  const uuid = useSelector(Selectors.getRouteUUID);
  const initialValues = useSelector(state => Selectors.getInitialValuesBureau(state, props));
  const defaultCurrency = useSelector(Selectors.getBureauDefaultCurrency);
  const submitting = useSelector(state => Selectors.getResourceFetching(state, 'nautilus.shipping'));

  SHIPPING_CONTAINER.NULL_FIELDS.forEach(
    fieldName => {
      if (initialValues[fieldName] === null) {
        initialValues[fieldName] = '';
      }
    },
  );

  const initialFormValues = {};
  Object
    .keys(initialValues)
    .filter(key => SHIPPING_CONTAINER.FIELDS.includes(key))
    .forEach(key => {
      initialFormValues[key] = initialValues[key];
    });

  const selected = {
    uuid,
    initialFormValues,
    submitting,
    defaultCurrency,
  };

  const dispatch = useDispatch();
  const onInitialize = currentUUID => {
    if (currentUUID) {
      dispatch(Actions.Api.nautilus[API_RESOURCES.SHIPPING].get(currentUUID));
    }
  };
  const onFormSubmit = formValues => {
    const empty = {};

    SHIPPING_CONTAINER.FIELDS.forEach(
      fieldName => {
        _set(empty, fieldName);
      });

    const payload = { ...empty, ...formValues };

    SHIPPING_CONTAINER.NULL_FIELDS.forEach(
      fieldName => {
        const field = _get(payload, fieldName);
        if (field === '') {
          _set(payload, fieldName, null);
        }
      },
    );

    SHIPPING_CONTAINER.FLOAT_FIELDS.forEach(
      fieldName => {
        const field = _get(payload, fieldName);
        if (field) {
          _set(payload, fieldName, Number.parseFloat(field));
        }
      },
    );

    SHIPPING_CONTAINER.INTEGER_FIELDS.forEach(
      fieldName => {
        const field = _get(payload, fieldName);
        if (field) {
          _set(payload, fieldName, Number(field));
        }
      },
    );

    if (payload.uuid) {
      dispatch(Actions.Api.nautilus[API_RESOURCES.SHIPPING]
        .put(payload.uuid, payload))
        .then(() => Alert.success(
          <FormattedMessage
            id="toaster.shipping.updated"
            defaultMessage="Shipping partner {uuid} successfully updated."
            values={{ uuid: payload.uuid }}
          />,
        ));
    } else {
      dispatch(Actions.Api.nautilus[API_RESOURCES.SHIPPING].post(payload))
        .then(response => redirectToNewShipping(response.headers.location))
        .then(() => Alert.success(
          <FormattedMessage
            id="toaster.shipping.created"
            defaultMessage="Shipping partner successfully created."
          />,
        ));
    }
  };
  const onDelete = currentUUID => {
    if (currentUUID) {
      dispatch(Actions.Api.nautilus[API_RESOURCES.SHIPPING].delete(currentUUID))
        .then(() => Alert.success(
          <FormattedMessage
            id="toaster.shipping.deleted"
            defaultMessage="Shipping partner {uuid} successfully deleted."
            values={{ uuid: currentUUID }}
          />,
        ))
        .then(redirect);
    }
  };

  useEffect(() => {
    if (uuid) {
      onInitialize(uuid);
    }
  }, [uuid]);

  return (
    <Shipping
      {...props}
      {...selected}
      onDelete={onDelete}
      onFormSubmit={onFormSubmit}
    />
  );
};

export default ShippingContainer;
