import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage, useIntl } from 'react-intl';
import {
  Button,
  Col,
  Form as BSForm,
  FormControl,
  FormGroup,
  FormLabel,
  Container,
  Modal,
  Row,
} from 'react-bootstrap';
import { reduxFormFieldType } from 'rapidfab/types';
import Alert from 'rapidfab/utils/alert';
import '../../styles/hawking/main.scss';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Field, Form } from 'react-final-form';
import _isNil from 'lodash/isNil';
import { faCheckSquare, faClose, faInfoCircle, faPlus, faSave, faSquare } from '@fortawesome/free-solid-svg-icons';
import { CUSTOM_FIELD_RIGHTS, CUSTOM_FIELD_TYPES } from '../../constants';
import Tooltip from '../Tooltip';
import SelectMultiple from '../forms/SelectMultiple';

const OptionsAddDialog = ({
  onClose,
  handleAddOption,
  fieldId,
  isHawkingUser,
}) => {
  const [payload, setPayload] = useState({
    key: '',
    value: '',
    disabled: false,
    meta: {},
  });
  const isValid = payload?.key && payload?.value;
  const onAddOption = () => {
    handleAddOption('options', {
      ...payload,
    });
  };

  return (
    <Modal show onHide={onClose} backdrop="static">
      <Modal.Header closeButton>
        <Modal.Title>Add Option to {fieldId}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Container fluid>
          <FormGroup controlId="optionKey" className="form-group">
            <Row>
              <Col xs={4}><FormLabel>Display Name (Key):</FormLabel></Col>
              <Col xs={8}>
                <FormControl
                  required
                  value={payload.key}
                  onChange={({ target }) => {
                    setPayload({
                      ...payload,
                      key: target.value || '',
                    });
                  }}
                />
              </Col>
            </Row>
          </FormGroup>
          <FormGroup controlId="optionValue" className="form-group">
            <Row>
              <Col xs={4}><FormLabel>Internal Value (Value):</FormLabel></Col>
              <Col xs={8}>
                <FormControl
                  required
                  value={payload.value}
                  onChange={({ target }) => {
                    setPayload({
                      ...payload,
                      value: target.value || '',
                    });
                  }}
                />
              </Col>
            </Row>
          </FormGroup>
        </Container>
      </Modal.Body>
      <Modal.Footer>
        <Button onClick={onClose}>
          <FormattedMessage id="button.cancel" defaultMessage="Cancel" />
        </Button>
        <Button
          onClick={onAddOption}
          disabled={!isValid}
          variant={!isHawkingUser && 'success'}
        >
          <FormattedMessage id="button.addOption" defaultMessage="Add Option" />
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

OptionsAddDialog.propTypes = {
  onClose: PropTypes.func.isRequired,
  handleAddOption: PropTypes.func.isRequired,
  fieldId: PropTypes.string.isRequired,
  isHawkingUser: PropTypes.bool.isRequired,
};

const CustomFieldEditModal = ({
  initialValues,
  uri,
  onClose,
  onUpdateCustomField,
  isHawkingUser,
  customFieldsByUri,
}) => {
  const [isShowOptionsAddModal, setIsShowOptionsAddModal] = useState(false);
  const [isShowMainModal, setIsShowMainModal] = useState(true);
  const [optionsInitialValues, setOptionsInitialValues] = useState(initialValues?.options || []);
  const intl = useIntl();
  const enabledTooltipMessage = intl.formatMessage({
    id: 'custom_field.detail.enabledTooltip',
    defaultMessage: 'Disabled fields do not take new input, but will show up read-only if old data is in them',
  });
  const [isSaving, setIsSaving] = useState(false);
  const customField = customFieldsByUri[uri];

  const handleOpenAddOptionModal = () => {
    setIsShowOptionsAddModal(true);
    setIsShowMainModal(false);
  };
  const onCloseAddOptionModal = () => {
    setIsShowOptionsAddModal(false);
    setIsShowMainModal(true);
  };

  const handleAddOption = ([fieldName, option], state, { changeValue }) => {
    const stateOptions = state.formState.values?.options;
    const newOptions = stateOptions ? [...stateOptions, option] : [option];

    if (optionsInitialValues.some(item => item.key === option.key)) {
      Alert.error(
        <FormattedMessage
          id="toaster.error.custom_field.keyAlreadyExists"
          defaultMessage="Field with this key already exists."
        />);
      return;
    }
    setOptionsInitialValues(previous => [...previous, option]);
    changeValue(state, fieldName, () => newOptions);
    onCloseAddOptionModal();
  };

  const isValid = payload => {
    const cf = Object.values(customFieldsByUri)
      .find(item => (payload.field_id === item.field_id) && (payload.related_table_name === item.related_table_name));
    if (cf) {
      const message = `Custom field with field_id "${payload.field_id}" for the table "${payload.related_table_name}" already exists`;
      Alert.error(message);
      return false;
    }
    return true;
  };
  const onSubmit = async payload => {
    if (!isValid(payload)) {
      return;
    }
    setIsSaving(true);
    try {
      await onUpdateCustomField(uri, { ...payload, options: payload.options || [] });
      setIsSaving(false);
      onClose();
    } catch {
      setIsSaving(false);
    }
  };
  const use = CUSTOM_FIELD_RIGHTS.READ in customField.rights
  && CUSTOM_FIELD_RIGHTS.WRITE in customField.rights ? 'Read-Write' : 'Read Only';

  return (
    <>
      <Form
        onSubmit={onSubmit}
        initialValues={initialValues}
        mutators={{
          handleAddOption,
        }}
        render={({ handleSubmit, values, form }) => (
          <form onSubmit={handleSubmit}>
            <Modal show={isShowMainModal} onHide={onClose} backdrop="static">
              <Modal.Header>
                <Modal.Title className="w-full">
                  <div className="d-flex align-items-center justify-content-between">
                    <Modal.Title className="w-full">
                      <FormattedMessage
                        id="custom_field.edit.title"
                        defaultMessage="Edit custom field: {fieldId}"
                        values={{ fieldId: customField.field_id }}
                      />
                    </Modal.Title>
                    <Button
                      size="sm"
                      className="spacer-right"
                      variant="primary"
                      type="submit"
                      onClick={() => onSubmit(values)}
                      disabled={customField.smart || isSaving}
                    >
                      <FontAwesomeIcon icon={faSave} />
                    </Button>
                    <Button
                      onClick={onClose}
                      size="sm"
                      className={isHawkingUser && 'hawking-secondary'}
                    >
                      <FontAwesomeIcon icon={faClose} />
                    </Button>
                  </div>
                </Modal.Title>
              </Modal.Header>

              <Modal.Body>
                <Container fluid>
                  <Row className="m-b-sm">
                    <Col xs={6}>Related Table: {customField.related_table_name}</Col>
                    <Col xs={6}>Field Key: {customField.field_id}</Col>
                  </Row>
                  <Row className="m-b-sm">
                    <Col xs={6}>Data Type: <span className="text-capitalize">{customField.type}</span></Col>
                    <Col xs={6}>Data Format: <span className="text-capitalize">{customField.format || 'None'}</span>
                    </Col>
                  </Row>
                  <Row className="m-b-sm">
                    <Col xs={6}>
                      <FontAwesomeIcon className="spacer-right" name={customField.is_smart ? faCheckSquare : faSquare} />
                      <span>Smart Field</span>
                    </Col>
                    <Col xs={6}>Units:
                      <span
                        className="text-capitalize"
                      >{customField.meta?.unit || 'None'}
                      </span>
                    </Col>
                  </Row>
                  <Row>
                    <Col xs={12}>Use: Standard User {use}</Col>
                  </Row>
                </Container>
                <hr className="m-a-sm" />

                <div className="m-l-sm m-b-sm"><b>Field Contents:</b></div>
                <Container fluid>
                  <Row className="m-b-sm">
                    <Col xs={3}>Tooltip:</Col>
                    <Col xs={9}>
                      <Field
                        name="meta.tooltip"
                        initialValue={initialValues?.meta?.tooltip}
                        parse={value => (_isNil(value) ? null : value)}
                        render={props => (
                          <FormControl {...props.input} />
                        )}
                      />
                    </Col>
                  </Row>
                  <Row className="m-b-sm">
                    <Col xs={3}>Default Value: {initialValues?.required && '*'}</Col>
                    <Col xs={3}>
                      <Field
                        name="default_value"
                        parse={value => (_isNil(value) ? null : value)}
                        initialValue={initialValues?.default_value}
                        render={props => (
                          <FormControl required={initialValues?.required} {...props.input} />
                        )}
                      />
                    </Col>
                    <Col xs={6}>
                      <div className="d-flex">
                        <span>Value Required: &nbsp;</span>
                        <Field
                          name="required"
                          type="checkbox"
                          initialValue={initialValues?.required}
                          render={props => (
                            <BSForm.Check
                              className="m-t-0 m-b-0"
                              {...props.input}
                            />
                          )}
                        />
                      </div>
                    </Col>
                  </Row>
                  {customField.type === CUSTOM_FIELD_TYPES.ARRAY && (
                    <Row>
                      <Col xs={3}>Options: </Col>
                      <Col xs={8}>
                        <div className="picky-spacer-wrapper">
                          <Field
                            name="options"
                            initialValue={initialValues?.options}
                            render={({ input }) => (
                              <SelectMultiple
                                handleOnClose={input.onChange}
                                title={values?.meta.placeholder || '----------'}
                                name={input.name}
                                required
                                data={optionsInitialValues}
                                selectedData={input.value || []}
                                valueKey="value"
                                labelKey="key"
                                includeFilter={false}
                                imitateOnChangeEvent
                              />
                            )}
                          />
                        </div>
                      </Col>
                      <Col>
                        <Button
                          onClick={handleOpenAddOptionModal}
                          className={isHawkingUser && 'hawking-secondary'}
                        >
                          <FontAwesomeIcon icon={faPlus} />
                        </Button>
                      </Col>
                    </Row>
                  )}
                  <Row>
                    <Col
                      className="mt15"
                      xs={12}
                    >
                      <Field
                        name="enabled"
                        initialValue={initialValues?.enabled}
                        type="checkbox"
                        render={props => (
                          <BSForm.Check
                            className="pull-left m-t-0 m-b-0 m-r-sm"
                            {...props.input}
                          />
                        )}
                      />
                      <span>Field is Enabled</span>
                      <Tooltip
                        id="custom_field.detail.tooltip"
                        placement="bottom"
                        trigger={<FontAwesomeIcon className="spacer-left spacer-right" icon={faInfoCircle} />}
                      >
                        {enabledTooltipMessage}
                      </Tooltip>

                    </Col>
                  </Row>
                  <Row>
                    <Col xs={12}>
                      <Field
                        name="is_visible_for_restricted"
                        initialValue={initialValues?.is_visible_for_restricted}
                        type="checkbox"
                        render={props => (
                          <BSForm.Check
                            className="pull-left m-t-0 m-b-0 m-r-sm"
                            {...props.input}
                          />
                        )}
                      />
                      <span>Visible for Restricted Users</span>
                    </Col>
                  </Row>
                </Container>

                <hr className="m-a-sm" />

                <div className="m-l-sm m-b-sm"><b>Display Settings:</b></div>
                <Container fluid>
                  <Row className="m-b-sm">
                    <Col xs={4}>Placeholder:</Col>
                    <Col xs={6}>
                      <Field
                        name="meta.placeholder"
                        parse={value => (_isNil(value) ? null : value)}
                        initialValue={initialValues?.meta?.placeholder}
                        render={props => (
                          <FormControl {...props.input} />
                        )}
                      />
                    </Col>
                  </Row>
                  <Row className="m-b-sm">
                    <Col xs={4}>Display Position:</Col>
                    <Col xs={4}>
                      <Field
                        name="position"
                        type="number"
                        initialValue={initialValues?.position}
                        render={props => (
                          <FormControl required min={1} {...props.input} />
                        )}
                      />
                    </Col>
                  </Row>
                  <Row className="m-b-sm">
                    <Col xs={4}>Display Name (User Friendly):</Col>
                    <Col xs={4}>
                      <Field
                        name="field_name"
                        initialValue={initialValues?.field_name}
                        render={props => (
                          <FormControl required {...props.input} />
                        )}
                      />
                    </Col>
                  </Row>
                  <Row>
                    <Col xs={4}>Description:</Col>
                    <Col xs={8}>
                      <Field
                        name="description"
                        initialValue={initialValues?.description}
                        parse={value => (_isNil(value) ? null : value)}
                        render={props => (
                          <FormControl as="textarea" {...props.input} />
                        )}
                      />
                    </Col>
                  </Row>
                </Container>
              </Modal.Body>
            </Modal>
            {
              isShowOptionsAddModal && (
                <OptionsAddDialog
                  onClose={onCloseAddOptionModal}
                  handleAddOption={form.mutators.handleAddOption}
                  fieldId={initialValues?.field_id}
                  isHawkingUser={isHawkingUser}
                />
              )
            }
          </form>
        )}
      />
    </>
  );
};

CustomFieldEditModal.propTypes = {
  onClose: PropTypes.func.isRequired,
  uri: PropTypes.string.isRequired,
  initialValues: PropTypes.shape({
    field_id: reduxFormFieldType,
    field_name: reduxFormFieldType,
    related_table_name: reduxFormFieldType,
    type: reduxFormFieldType,
    default_value: reduxFormFieldType,
    required: reduxFormFieldType,
    enabled: reduxFormFieldType,
    position: reduxFormFieldType,
    description: reduxFormFieldType,
    options: PropTypes.arrayOf(PropTypes.shape({
      key: reduxFormFieldType.isRequired,
      value: reduxFormFieldType.isRequired,
    })),
    meta: PropTypes.shape({
      tooltip: reduxFormFieldType,
      placeholder: reduxFormFieldType,
    }),
    is_visible_for_restricted: PropTypes.bool,
  }).isRequired,
  onUpdateCustomField: PropTypes.func.isRequired,
  isHawkingUser: PropTypes.bool.isRequired,
  customFieldsByUri: PropTypes.shape({}).isRequired,
  input: PropTypes.shape({}).isRequired,
};

export default CustomFieldEditModal;
