import React from 'react';
import PropTypes from 'prop-types';
import Loading from 'rapidfab/components/Loading';
import {
  Button,
  Modal,
  ModalBody, ModalFooter,
  ModalHeader,
  ModalTitle,
} from 'react-bootstrap';
import ResourceReadOnlyViewRow, { RESOURCE_READ_ONLY_VIEW_FIELD_TYPES } from 'rapidfab/components/ResourceReadOnlyView/ResourceReadOnlyViewRow';
import ResourceReadOnlyView from 'rapidfab/components/ResourceReadOnlyView/ResourceReadOnlyView';
import { FormattedMessage } from 'react-intl';

const ModelerDeleteModal = ({
  onClose,
  isDeleting,
  handleSubmit,
  isLoading,
  modeler,
  modelerType,
}) => {
  if (isLoading) {
    return <Modal show backdrop="static"><ModalBody><Loading /></ModalBody></Modal>;
  }

  return (
    <Modal size="lg" show onHide={onClose} backdrop="static">
      <ModalHeader closeButton>
        <ModalTitle>
          Delete Printer Link: {modeler && modeler.name}
        </ModalTitle>
      </ModalHeader>
      <ModalBody>
        <ResourceReadOnlyView
          entity={modeler}
        >
          <ResourceReadOnlyViewRow
            name="ip_address"
            label="IP Address"
          />
          <ResourceReadOnlyViewRow name="serial_number" />
          <ResourceReadOnlyViewRow
            name="type"
            type={RESOURCE_READ_ONLY_VIEW_FIELD_TYPES.CUSTOM}
            customValue={modelerType && modelerType.name}
          />
        </ResourceReadOnlyView>
      </ModalBody>
      <ModalFooter>
        <span className="pull-left">
          <FormattedMessage
            id="message.entityDeletePrompt"
            defaultMessage="Are you sure you want to delete this {entityType}?"
            values={{ entityType: 'Printer Link' }}
          />
        </span>
        <Button
          className="pull-right ml5"
          onClick={handleSubmit}
          variant="danger"
          disabled={isDeleting}
        >
          {
            isDeleting
              ? <Loading />
              : <FormattedMessage id="button.delete" defaultMessage="Delete" />
          }
        </Button>
        <Button className="pull-right" onClick={onClose}>
          <FormattedMessage id="button.cancel" defaultMessage="Cancel" />
        </Button>
      </ModalFooter>
    </Modal>
  );
};

ModelerDeleteModal.defaultProps = {
  isDeleting: false,
  isLoading: false,
  modeler: null,
  modelerType: null,
};

ModelerDeleteModal.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
  isDeleting: PropTypes.bool,
  isLoading: PropTypes.bool,
  modeler: PropTypes.shape({
    name: PropTypes.string,
  }),
  modelerType: PropTypes.shape({
    name: PropTypes.string,
  }),
};

export default ModelerDeleteModal;
