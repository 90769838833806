export const RELATED_TABLE_NAMES = {
  COMMENT_ACTION: 'comment_action',
  LINE_ITEM: 'line_item',
  MODEL: 'model',
  MODEL_LIBRARY: 'model_library',
  PIECE: 'piece',
  PREP_TASK_RECORD: 'prep_task_record',
  RUN: 'run',
  STOCK: 'stock',
  ORDER: 'order',
  SHIPMENT: 'shipment',
  MATERIAL: 'material',
  MATERIAL_BATCH: 'material_batch',
  MATERIAL_BATCH_ACTION: 'material_batch_action',
  MATERIAL_LOT: 'material_lot',
  TOOLING_STOCK_TYPE: 'tooling_type',
  MATERIAL_TEST_INSTRUCTION_REPORT: 'material_test_instruction_report',
  TOOLING_STOCK: 'tooling_stock',
  DOWNTIME: 'downtime',
};

export const COMMENT_RELATED_TABLE_NAMES = {
  MODEL_LIBRARY: RELATED_TABLE_NAMES.MODEL_LIBRARY,
  ORDER: RELATED_TABLE_NAMES.ORDER,
  PREP_TASK_RECORD: RELATED_TABLE_NAMES.PREP_TASK_RECORD,
  RUN: RELATED_TABLE_NAMES.RUN,
};

export const DOCUMENT_RELATED_TABLE_NAMES = {
  MATERIAL: RELATED_TABLE_NAMES.MATERIAL,
  MATERIAL_BATCH: RELATED_TABLE_NAMES.MATERIAL_BATCH,
  MATERIAL_BATCH_ACTION: RELATED_TABLE_NAMES.MATERIAL_BATCH_ACTION,
  MATERIAL_LOT: RELATED_TABLE_NAMES.MATERIAL_LOT,
  TOOLING_STOCK_TYPE: RELATED_TABLE_NAMES.TOOLING_STOCK_TYPE,
  LINE_ITEM: RELATED_TABLE_NAMES.LINE_ITEM,
  MODEL_LIBRARY: RELATED_TABLE_NAMES.MODEL_LIBRARY,
  ORDER: RELATED_TABLE_NAMES.ORDER,
  PIECE: RELATED_TABLE_NAMES.PIECE,
  PREP_TASK_RECORD: RELATED_TABLE_NAMES.PREP_TASK_RECORD,
  RUN: RELATED_TABLE_NAMES.RUN,
  SHIPMENT: RELATED_TABLE_NAMES.SHIPMENT,
  MATERIAL_TEST_INSTRUCTION_REPORT:
    RELATED_TABLE_NAMES.MATERIAL_TEST_INSTRUCTION_REPORT,
  TOOLING_STOCK: RELATED_TABLE_NAMES.TOOLING_STOCK,
};
