import React from 'react';
import { connect } from 'react-redux';
import Profile from 'rapidfab/components/profile/Profile';
import Actions from 'rapidfab/actions';
import * as Selectors from 'rapidfab/selectors';

import { API_RESOURCES } from 'rapidfab/constants';
import { getBureauSettings } from 'rapidfab/selectors';

const ProfileContainer = props => <Profile {...props} />;

const mapStateToProps = state => {
  const selectedCurrency = Selectors.getSelectedCurrency(state);
  const defaultCurrency = Selectors.getBureauDefaultCurrency(state);
  const bureauSettings = getBureauSettings(state);
  const isContactlessLoginEnabled = bureauSettings?.contactless_logon_enabled;
  return {
    units: state.units,
    user: Selectors.getSession(state),
    settings: Selectors.getPreferencesUserSettings(state),
    availableConversions: Selectors.getConversions(state),
    selectedCurrency: selectedCurrency || defaultCurrency,
    isContactlessLoginEnabled,
  };
};

const mapDispatchToProps = dispatch => ({
  onSetUnitsMetric: () => dispatch(Actions.Units.setMetric),
  onSetUnitsImperial: () => dispatch(Actions.Units.setImperial),
  onUserSettingsUpdate: updatedUserSettings =>
    dispatch(Actions.Preferences.setUserSettings(updatedUserSettings)),
  onSelectCurrency: currencyCode =>
    dispatch(Actions.DynamicCurrency.setSelectedCurrency(currencyCode)),
  getContactlessLogonQRCode: async uuid => {
    const user = await dispatch(Actions.Api.nautilus[API_RESOURCES.USERS]
      .get(uuid, {}, { get_contactless_logon: true }));
    const { qr_logon_code: qrLogonCode } = user.json;
    return qrLogonCode;
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(ProfileContainer);
