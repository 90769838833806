import BureauBarcodeIcon from 'rapidfab/components/BureauBarcodeIcon';
import Feature from 'rapidfab/components/Feature';
import Loading from 'rapidfab/components/Loading';
import React, { memo } from 'react';
import PropTypes from 'prop-types';
import BreadcrumbNav from 'rapidfab/components/BreadcrumbNav';
import { Container, Row, Col, Card, Button } from 'react-bootstrap';
import { getShortUUID, extractUuid } from 'rapidfab/utils/uuidUtils';
import { getRouteURI } from 'rapidfab/utils/uriUtils';
import Documents from 'rapidfab/components/records/Documents';
import { DOCUMENT_RELATED_TABLE_NAMES, FEATURES, ROUTES } from 'rapidfab/constants';
import ResourceReadOnlyView from 'rapidfab/components/ResourceReadOnlyView/ResourceReadOnlyView';
import ResourceReadOnlyViewRow, {
  RESOURCE_READ_ONLY_VIEW_FIELD_TYPES,
} from 'rapidfab/components/ResourceReadOnlyView/ResourceReadOnlyViewRow';
import { Field, Form } from 'react-final-form';
import { FormattedMessage } from 'rapidfab/i18n';
import FormGroupField from 'rapidfab/components/forms/FormGroupField';
import { TOOLING_STOCK_STATUSES_MAP } from 'rapidfab/mappings';
import ToolingStockActionContainer from 'rapidfab/containers/records/ToolingStockActionsContainer';

const ToolingStock = ({
  toolingStock,
  toolingStockType,
  location,
  subLocation,
  run,
  workstation,
  handleSubmitNotes,
  isVendorUser,
  toolingTypeVendorUser,
  vendorUserFetching,
}) => {
  const breadcrumbs = [
    'inventory',
    'toolingStocks',
    `${toolingStock.name} (${getShortUUID(toolingStock.uri)})`,
  ];

  return (
    <Container fluid>
      <BreadcrumbNav breadcrumbs={breadcrumbs} />
      <Row>
        <Col xs={{ span: 12 }} md={{ span: 6 }}>
          <Card bg="dark" className="mb15">
            <Card.Header className="pd-exp accent">
              <FormattedMessage
                id="inventory.toolingStock"
                defaultMessage="Tool"
              />
              {!isVendorUser && (
                <a
                  href={getRouteURI(ROUTES.TOOLING_STOCK_QR_CODES, {
                    uuid: toolingStock.uuid,
                  })}
                  className="pull-right text-white"
                >
                  <BureauBarcodeIcon
                    className="spacer-right"
                    qrCodeText="Print"
                    barcodeText="Print"
                  />
                </a>
              )}
            </Card.Header>
            <div className="card-body-wrapper-accent">
              <Card.Body>
                <Form
                  initialValues={{ name: toolingStock.name, notes: toolingStock.notes }}
                  onSubmit={handleSubmitNotes}
                  render={({ handleSubmit }) => (
                    <form onSubmit={handleSubmit}>
                      <ResourceReadOnlyView entity={toolingStock}>
                        {isVendorUser ? (
                          <ResourceReadOnlyViewRow
                            name="name"
                            label="Name"
                            type={RESOURCE_READ_ONLY_VIEW_FIELD_TYPES.CUSTOM}
                            customValue={toolingStock.name}
                          />
                        ) : (
                          <Field
                            name="name"
                            type="text"
                            render={props => (
                              <FormGroupField
                                {...props.input}
                                name="name"
                                controlId="uxName"
                                label={(
                                  <FormattedMessage
                                    id="field.name"
                                    defaultMessage="Name"
                                  />
                                )}
                              />
                            )}
                          />
                        )}
                        <ResourceReadOnlyViewRow
                          name="type"
                          label="Tool Type"
                          type={RESOURCE_READ_ONLY_VIEW_FIELD_TYPES.CUSTOM}
                          customValue={toolingStockType.name}
                        />

                        {!isVendorUser && (
                          <>
                            <Feature featureName={FEATURES.VENDOR_SERVICE_PROVIDER}>
                              <ResourceReadOnlyViewRow
                                name="service_provider"
                                label="Vendor"
                                type={RESOURCE_READ_ONLY_VIEW_FIELD_TYPES.CUSTOM}
                                customValue={(vendorUserFetching ? <Loading inline /> : toolingTypeVendorUser?.name ?? 'N/A')}
                              />
                            </Feature>

                            <ResourceReadOnlyViewRow
                              name="location_name"
                              label="Location"
                              type={RESOURCE_READ_ONLY_VIEW_FIELD_TYPES.CUSTOM}
                              customValue={location?.name ?? 'N/A'}
                            />
                            <ResourceReadOnlyViewRow
                              name="sub_location_name"
                              label="Sub-Location"
                              type={RESOURCE_READ_ONLY_VIEW_FIELD_TYPES.CUSTOM}
                              customValue={subLocation?.name ?? 'N/A'}
                            />
                          </>
                        )}

                        <ResourceReadOnlyViewRow
                          name="status"
                          label="Status"
                          type={RESOURCE_READ_ONLY_VIEW_FIELD_TYPES.MAPPED}
                          map={TOOLING_STOCK_STATUSES_MAP}
                        />

                        {!isVendorUser && (
                          <>
                            <ResourceReadOnlyViewRow
                              name="run_name"
                              label="Run"
                              type={RESOURCE_READ_ONLY_VIEW_FIELD_TYPES.CUSTOM}
                              customValue={run?.name ?? 'N/A'}
                            />

                            <ResourceReadOnlyViewRow
                              name="workstation_name"
                              label="Workstation"
                              type={RESOURCE_READ_ONLY_VIEW_FIELD_TYPES.CUSTOM}
                              customValue={workstation?.name ?? 'N/A'}
                            />
                          </>
                        )}
                        {toolingStockType.refurbish_after > 1 && (
                          <ResourceReadOnlyViewRow
                            name="use_count"
                            label="Usage Cycle"
                            type={RESOURCE_READ_ONLY_VIEW_FIELD_TYPES.TEXT}
                          />
                        )}
                        <ResourceReadOnlyViewRow
                          name="lifetime_use_count"
                          label="Lifetime Use Count"
                          type={RESOURCE_READ_ONLY_VIEW_FIELD_TYPES.TEXT}
                        />
                        <Field
                          name="notes"
                          type="text"
                          render={props => (
                            <FormGroupField
                              {...props.input}
                              as="textarea"
                              name="notes"
                              controlId="uxNotes"
                              disabled={isVendorUser}
                              label={(
                                <FormattedMessage
                                  id="field.notes"
                                  defaultMessage="Notes"
                                />
                              )}
                            />
                          )}
                        />
                        {!isVendorUser && (
                          <Button
                            size="sm"
                            variant="success"
                            type="submit"
                            className="pull-right mb-2"
                          >
                            <FormattedMessage
                              id="button.save"
                              defaultMessage="Save"
                            />
                          </Button>
                        )}
                      </ResourceReadOnlyView>
                    </form>
                  )}
                />
              </Card.Body>
            </div>
          </Card>
          <Documents
            relatedTable={DOCUMENT_RELATED_TABLE_NAMES.TOOLING_STOCK}
            relatedUUID={extractUuid(toolingStock.uri)}
          />
        </Col>
        <Col xs={{ span: 12 }} md={{ span: 6 }}>
          <ToolingStockActionContainer
            toolingStock={toolingStock}
            vendorUser={toolingTypeVendorUser}
          />
        </Col>
      </Row>
    </Container>
  );
};

ToolingStock.propTypes = {
  toolingStock: PropTypes.shape({
    uuid: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    uri: PropTypes.string.isRequired,
    notes: PropTypes.string.isRequired,
    status: PropTypes.string.isRequired,
    use_count: PropTypes.number.isRequired,
  }).isRequired,
  toolingStockType: PropTypes.shape({
    name: PropTypes.string.isRequired,
    uri: PropTypes.string.isRequired,
    refurbish_after: PropTypes.number.isRequired,
  }).isRequired,
  location: PropTypes.shape({
    name: PropTypes.string.isRequired,
    uri: PropTypes.string.isRequired,
  }).isRequired,
  subLocation: PropTypes.shape({
    name: PropTypes.string.isRequired,
    uri: PropTypes.string.isRequired,
  }).isRequired,
  run: PropTypes.shape({
    name: PropTypes.string.isRequired,
    uri: PropTypes.string.isRequired,
  }),
  workstation: PropTypes.shape({
    name: PropTypes.string.isRequired,
    uri: PropTypes.string.isRequired,
  }),
  handleSubmitNotes: PropTypes.func.isRequired,
  input: PropTypes.shape({
    props: PropTypes.shape({}).isRequired,
  }).isRequired,
  isVendorUser: PropTypes.bool.isRequired,
  toolingTypeVendorUser: PropTypes.oneOfType([
    PropTypes.shape({}), undefined,
  ]),
  vendorUserFetching: PropTypes.bool,
};

ToolingStock.defaultProps = {
  run: undefined,
  workstation: undefined,
  toolingTypeVendorUser: undefined,
  vendorUserFetching: false,
};

export default memo(ToolingStock);
