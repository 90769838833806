import { createSelector } from 'reselect';
import { getStateResources } from 'rapidfab/selectors/helpers/base';
import { getStateAssignments } from 'rapidfab/selectors/baseStateSelectors';
import _map from 'lodash/map';
import _filter from 'lodash/filter';

export const getAssignments = createSelector(
  [getStateAssignments, getStateResources],
  (uuids, resources) => _map(uuids, uuid => resources[uuid]),
);

export const getActiveAssignments = createSelector(
  [getAssignments],
  assignments => _filter(assignments, { is_active: true }),
);
