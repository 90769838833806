import Actions from 'rapidfab/actions';
import { API_RESOURCES, LIST_BY_URIS_CHUNK_SIZE, PAGINATION_IGNORE_DEFAULT_LIMIT } from 'rapidfab/constants';
import _map from 'lodash/map';
import _chunk from 'lodash/chunk';

const loadCastorCostingConfigInChunks = (dispatch, castorCostingUris) => {
  _chunk(castorCostingUris, LIST_BY_URIS_CHUNK_SIZE).forEach(castorCostingUrisChunk => {
    dispatch(Actions.Api.nautilus[API_RESOURCES.CASTOR_COSTING_CONFIG].list(
      { castor_costing: castorCostingUrisChunk },
      { limit: PAGINATION_IGNORE_DEFAULT_LIMIT },
    ));
  });
};

// eslint-disable-next-line import/prefer-default-export
export const loadCastorCostingConfig = (dispatch, modelLibraryUri) => (
  dispatch(Actions.Api.nautilus[API_RESOURCES.CASTOR_COSTING].list(
    { 'model-library': modelLibraryUri },
    { limit: PAGINATION_IGNORE_DEFAULT_LIMIT },
  ))
    .then(response => {
      const castorCostingUris = _map(response.json.resources, 'uri');
      loadCastorCostingConfigInChunks(dispatch, castorCostingUris);
    })
);

// eslint-disable-next-line import/prefer-default-export
export const loadCastorCostingConfigs = (dispatch, modelLibraryUris) => (
  dispatch(Actions.Api.nautilus[API_RESOURCES.CASTOR_COSTING].list(
    { 'model-library': modelLibraryUris.join(',') },
    { limit: PAGINATION_IGNORE_DEFAULT_LIMIT },
  ))
    .then(response => {
      const castorCostingUris = _map(response.json.resources, 'uri');
      loadCastorCostingConfigInChunks(dispatch, castorCostingUris);
    })
);
