import { createSelector } from 'reselect';
import { getStatePieces } from 'rapidfab/selectors/baseStateSelectors';
import { getStateResources } from 'rapidfab/selectors/helpers/base';
import _map from 'lodash/map';
import _keyBy from 'lodash/keyBy';
import _filter from 'lodash/filter';
import { getPrintingPrintsCreated } from 'rapidfab/selectors/print';
import _groupBy from 'lodash/groupBy';

export const getPieces = createSelector(
  [getStatePieces, getStateResources],
  (uuids, resources) => _map(uuids, uuid => resources[uuid]),
);

export const getPiecesByUri = createSelector(
  [getPieces],
  pieces => _keyBy(pieces, 'uri'),
);

export const getPiecesWithPrintingPrintsCreated = createSelector(
  [getPiecesByUri, getPrintingPrintsCreated],
  // eslint-disable-next-line lodash/prefer-compact
  (piecesByUri, prints) => _filter(_map(prints, print => piecesByUri[print.piece] || null)),
);

export const getPiecesGroupedByLocation = createSelector(
  [getPieces],
  pieces => _groupBy(pieces, 'location'),
);
