import React from 'react';
import { useYBugApi } from 'rapidfab/context/YBugContext';
import Config from 'rapidfab/config';
import { faLightbulb } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const YBugFeedbackButton = () => {
  const YbugApi = useYBugApi();

  if (!YbugApi || !Config.YBUG_ID) {
    // Hide the feedback button if Ybug is not loaded or we need to skip it.
    return null;
  }

  const openYbugReport = () => {
    YbugApi.open('feedback');
  };

  return (
    <FontAwesomeIcon
      id="feedback-icon-btn"
      role="button"
      icon={faLightbulb}
      title="Feedback"
      className="mr15 ml15"
      onClick={openYbugReport}
    />
  );
};

export default YBugFeedbackButton;
