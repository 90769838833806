import { useState, useEffect, useRef } from 'react';
import { NAVBAR_HEIGHT_PX } from 'rapidfab/constants';

const useScrollToComponent = () => {
  const [scrollToIncomplete, setScrollToIncomplete] = useState(false);
  const scrollRef = useRef(null);

  useEffect(() => {
    if (scrollRef?.current && scrollToIncomplete) {
      const offsetTop = scrollRef.current.getBoundingClientRect().top - NAVBAR_HEIGHT_PX;
      const offsetBottom = scrollRef.current.getBoundingClientRect().bottom;
      if (offsetBottom >= window.innerHeight) {
        window.scrollTo({ top: offsetTop, behavior: 'smooth' });
        setScrollToIncomplete(false);
      }
    }
  }, [scrollRef, scrollToIncomplete]);

  return [scrollRef, setScrollToIncomplete];
};

export default useScrollToComponent;
