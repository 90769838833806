import LoginWrapper from 'rapidfab/components/LoginWrapper';
import LoginForm from 'rapidfab/components/LoginForm';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import _isEmpty from 'lodash/isEmpty';
import * as Selectors from 'rapidfab/selectors';
import Actions from 'rapidfab/actions';
import {
  API_RESOURCES, XEROX_HAWKING_BRANDING, XEROX_LOGIN_ROUTE_PREFIX,
  XEROX_BUSINESS_DOMAIN, STANLEY_X_BRANDING, IDENTITY_PROVIDER_TYPE,
} from 'rapidfab/constants';
import { useLocation } from 'react-router-dom';

const LoginContainer = () => {
  const isLoading = useSelector(state =>
    Selectors.getResourceFetching(state, 'nautilus.user_sso') ||
    Selectors.getResourceFetching(state, 'nautilus.sessions'));
  // Use raw data as user_sso resource identifier is not valid API endpoint
  // (email as resource identifier instead of uuid)
  const { json: userSSOData, errors: userSSOErrors } = useSelector(
    state => state.ui.nautilus[API_RESOURCES.USER_SSO].get,
  );
  const selected = {
    isLoading,
    userSSOData,
    userSSOErrors,
    queryParams: {},
  };

  const dispatch = useDispatch();

  const [showAdUsernameField, setShowAdUsernameField] = useState(false);

  const onEmailSubmit = email => {
    dispatch(Actions.Api.nautilus[API_RESOURCES.USER_SSO].get(email));
  };

  const onSubmit = (email, password, callback) => {
    const payload = { username: email, password };
    dispatch(Actions.Api.nautilus[API_RESOURCES.SESSIONS].post(payload))
      .then(() => callback());
  };

  const onSubmitWithLDAPSLogin = (email, adUsername, password, callback) => {
    const payload = {
      username: email,
      ldap_username: adUsername,
      password,
      logon_type: 'ldap',
    };

    dispatch(Actions.Api.nautilus[API_RESOURCES.SESSIONS].post(payload))
      .then(() => callback());
  };

  // Errors from user_sso endpoint means disabled USER_SSO integration for this user
  let isPasswordVisible = selected.userSSOErrors.length > 0;
  let ssoURI = null;

  const location = useLocation();

  const isHawkingLogin =
    location.pathname.includes(XEROX_LOGIN_ROUTE_PREFIX) ||
    window.location.toString().includes(XEROX_BUSINESS_DOMAIN);
  const isStanleyXLogin = location.pathname.includes('stanley-select/login');

  useEffect(() => {
    // only SAML at the moment
    if (!_isEmpty(selected.userSSOData) &&
    selected.userSSOData.identity_provider_type === IDENTITY_PROVIDER_TYPE.SAML) {
      if (!selected.userSSOData.allow_password_login) {
        window.location = selected.userSSOData.sso_uri;
      } else {
        // Allow to login via password + with SSO button
        isPasswordVisible = true;
        ssoURI = selected.userSSOData.sso_uri;
      }
    } else if (!_isEmpty(selected.userSSOData) &&
    selected.userSSOData.identity_provider_type === IDENTITY_PROVIDER_TYPE.LDAPS) {
      setShowAdUsernameField(true);
    } else {
      //
    }
  }, [selected]);

  const onSuccessLogin = () => {
    if (isHawkingLogin) {
      localStorage.setItem(XEROX_HAWKING_BRANDING, 'true');
    } else if (isStanleyXLogin) {
      localStorage.setItem(STANLEY_X_BRANDING, 'true');
    }
    window.location.reload();
  };

  return (
    <LoginWrapper
      mainText="Sign In"
      subText="To streamline your manufacturing operations with Authentise Flows"
    >
      <LoginForm
        loading={isLoading}
        onEmailSubmit={onEmailSubmit}
        onSubmit={(login, password) => onSubmit(login, password, onSuccessLogin)}
        onSubmitWithLDAPSLogin={
          (email, adUsername, password) => onSubmitWithLDAPSLogin(email, adUsername, password, onSuccessLogin)
        }
        ssoURI={ssoURI}
        withPassword={isPasswordVisible}
        showAdUsernameField={showAdUsernameField}
      />
    </LoginWrapper>
  );
};

export default LoginContainer;
