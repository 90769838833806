import PropTypes from 'prop-types';
import Actions from 'rapidfab/actions';
import { API_RESOURCES, FEATURES } from 'rapidfab/constants';
import { FormattedMessage } from 'rapidfab/i18n';
import { getLineItemQuoteByLineItemUri, getLineItemQuoteProcessStepsForLineItem, getLineItemsByUri, getLineItemWorkstepEstimatesByUri, getLineItemWorkstepCostEstimates } from 'rapidfab/selectors';
import { convertHoursToSeconds } from 'rapidfab/utils/timeUtils';
import React, { useState } from 'react';
import { Form, ListGroup } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import Feature from '../Feature';
import { buildLineItemQuoteWorkstepPayload, useWorkstepTableData } from './utils';
import WorkstepSummary from './WorkstepSummary';
import { WorkstepTable, WorkstepTableHeader } from './WorkstepTable';

const WorkstepDetails = ({ lineItemUri, workstepUri, editMode, setEditMode }) => {
  const [schedulingEnabled, setSchedulingEnabled] = useState(false);
  const lineItem = useSelector(getLineItemsByUri)[lineItemUri];
  const workstepEstimates = useSelector(getLineItemWorkstepEstimatesByUri);
  const workstepCostEstimates = useSelector(getLineItemWorkstepCostEstimates);
  const lineItemQuote = useSelector(state => getLineItemQuoteByLineItemUri(state, lineItemUri));
  const worksteps = useSelector(state => getLineItemQuoteProcessStepsForLineItem(state, lineItemUri));
  const workstep = worksteps.find(step => step.uri === workstepUri);
  const { data, total } = useWorkstepTableData(lineItem, workstep);
  const dispatch = useDispatch();

  const updateSchedulingData = async data => {
    const laborHours = data.find(field => field.key === 'labor')?.count;
    const workstationHours = data.find(field => field.key === 'workstation')?.count;
    const payload = {
      line_item: lineItemUri,
      process_step: workstepUri,
      labor_duration: convertHoursToSeconds(laborHours),
      workstation_duration: convertHoursToSeconds(workstationHours),
    };
    const estimate = workstepEstimates[workstepUri];
    if (estimate) {
      if (workstep.labor_duration === payload.labor_duration
        && workstep.workstation_duration === payload.workstation_duration) {
        // The durations are the same, so we can skip the update
        return;
      }
      await dispatch(Actions.Api.nautilus[API_RESOURCES.LINE_ITEM_WORKSTEP_ESTIMATE].put(estimate.uuid, payload));
    } else {
      await dispatch(Actions.Api.nautilus[API_RESOURCES.LINE_ITEM_WORKSTEP_ESTIMATE].post(payload));
    }
  };

  const updateWorkstepEstimatePayload = async data => {
    const costEstimate = workstepCostEstimates.find(estimate => estimate.process_step === workstepUri);
    const payload = {
      line_item: lineItemUri,
      process_step: workstepUri,
      overhead_cost_per_piece_in_run: data.overhead_cost_per_piece_in_run,
      overhead_cost_per_run: data.overhead_cost_per_run,
      number_of_runs: 1,
    };

    if (costEstimate) {
      await dispatch(Actions.Api.nautilus[API_RESOURCES.LINE_ITEM_WORKSTEP_COST_ESTIMATE]
        .put(costEstimate.uuid, payload));
    } else {
      await dispatch(Actions.Api.nautilus[API_RESOURCES.LINE_ITEM_WORKSTEP_COST_ESTIMATE]
        .post(payload));
    }
  };
  const saveQuote = async data => {
    const payload = {
      ...buildLineItemQuoteWorkstepPayload(data.charges, lineItem.piece_count),
      process_step: workstepUri,
    };

    // The order of execution is important, because after the /line-item-workstep-cost-estimate/ request,
    // the values for line-item-quote will be updated to the standard ones

    // If we have additional charges, update/create workstep cost estimates
    if (payload.additional_charges) {
      await updateWorkstepEstimatePayload(payload);
    }

    // If scheduling is enabled, create/update schedule
    // If it's disabled but estimate exists, delete it
    if (schedulingEnabled) {
      await updateSchedulingData(data);
    } else if (workstepEstimates[workstepUri]) {
      dispatch(Actions.Api.nautilus[API_RESOURCES.LINE_ITEM_WORKSTEP_ESTIMATE]
        .delete(workstepEstimates[workstepUri].uuid));
    }

    await dispatch(Actions.Api.nautilus[API_RESOURCES.LINE_ITEM_QUOTE].put(lineItemQuote.uuid, {
      work_steps_quote_details: [payload],
    }));
    await dispatch(Actions.Api.nautilus[API_RESOURCES.LINE_ITEM_QUOTE].get(lineItemQuote.uuid));
  };

  return (
    <>
      <ListGroup fill className="mb15">
        <WorkstepTableHeader editMode={editMode} />
        <WorkstepTable
          tableData={data}
          editMode={editMode}
          setEditMode={setEditMode}
          workstepUri={workstepUri}
          saveQuote={saveQuote}
        />
        {editMode && (
          <Feature featureName={FEATURES.WORKSTEP_ESTIMATE}>
            <div className="checkbox mb15 d-flex justify-content-end">
              <Form.Check
                inline
                className="quoteProcessStepScheduling"
                name="timeEstimatesWhenScheduled"
                disabled={!editMode}
                checked={schedulingEnabled}
                onChange={() => setSchedulingEnabled(previous => !previous)}
                type="checkbox"
                label={(
                  <FormattedMessage
                    id="process_step.schedulingTimeEstimates"
                    defaultMessage="Use time estimates when scheduling"
                  />
                )}
              />
            </div>
          </Feature>
        )}
      </ListGroup>
      <WorkstepSummary total={total} tableData={data} lineItem={lineItem} workstep={workstep} />
    </>
  );
};

WorkstepDetails.propTypes = {
  lineItemUri: PropTypes.string.isRequired,
  workstepUri: PropTypes.string.isRequired,
  editMode: PropTypes.bool.isRequired,
  setEditMode: PropTypes.func.isRequired,
};

export default WorkstepDetails;
