import React from 'react';
import { faExternalLink, faMinus, faPlus, faTrash } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import _filter from 'lodash/filter';
import _map from 'lodash/map';
import _toNumber from 'lodash/toNumber';
import PropTypes from 'prop-types';
import FormRow from 'rapidfab/components/FormRow';
import FormattedLocalizedCost from 'rapidfab/components/FormattedLocalizedCost';
import ShoppingCartItemDataRow from 'rapidfab/components/ShoppingCart/ShoppingCartItemDataRow';
import { ROUTES } from 'rapidfab/constants';
import { FormattedMessage } from 'rapidfab/i18n';
import { extractUuid } from 'rapidfab/utils/uuidUtils';
import { getRouteURI } from 'rapidfab/utils/uriUtils';
import { getShoppingCartItemsTotalPrice,
  getIsOrderCreatedThroughShoppingCart, getCartItemMaterial } from 'rapidfab/utils/shoppingCartUtils';
import { Button, Card, Col, FormControl, Row, Table } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { FormattedDate } from 'react-intl';
import Loading from 'rapidfab/components/Loading';

// eslint-disable-next-line no-unused-vars
const AdditionalCostsManagementView = ({
  showAdditionalCostsManagementViewState: {
    setShowAdditionalCostsManagementView,
  },
  additionalCostsState: {
    emptyAdditionalCostSchema,
    additionalCosts,
    setAdditionalCosts,
  },
}) => {
  const handleUpdateAdditionalCosts = (event, index) => {
    const updatedAdditionalCosts = [
      ...additionalCosts,
    ];

    if (event.target.id === 'additionalCostName') {
      updatedAdditionalCosts[index] = {
        ...updatedAdditionalCosts[index],
        name: event.target.value,
      };
    } else if (event.target.id === 'additionalCostAmount') {
      updatedAdditionalCosts[index] = {
        ...updatedAdditionalCosts[index],
        amount: _toNumber(event.target.value),
      };
    } else {
      /* ... */
    }

    setAdditionalCosts(updatedAdditionalCosts);
  };

  return (
    _map(additionalCosts, (_, costItemIndex) => (
      <tr>
        <>
          <td colSpan={3}>
            <Button
              size="xs"
              onClick={() => setAdditionalCosts(previous =>
                [...previous, emptyAdditionalCostSchema])}
            >
              <FontAwesomeIcon icon={faPlus} />
            </Button>
            <Button
              size="xs"
              className="spacer-left"
              onClick={() => {
                if (additionalCosts.length < 2) {
                  setAdditionalCosts([emptyAdditionalCostSchema]);

                  setShowAdditionalCostsManagementView(false);
                } else {
                  setAdditionalCosts(
                    _filter(additionalCosts, (_, index) => index !== costItemIndex));
                }
              }}
            >
              <FontAwesomeIcon icon={additionalCosts.length < 2 ? faTrash : faMinus} />
            </Button>
          </td>
          <td>
            <input
              className="w-100"
              defaultValue={additionalCosts[costItemIndex].name}
              id="additionalCostName"
              onBlur={event => handleUpdateAdditionalCosts(event, costItemIndex)}
              placeholder="Additional Cost Name"
            />
          </td>
          <td>
            <input
              className="w-100"
              id="additionalCostAmount"
              onBlur={event => handleUpdateAdditionalCosts(event, costItemIndex)}
              type="number"
              placeholder="0"
            />
          </td>
        </>
      </tr>
    ))
  );
};

const DDWBuyerShoppingCart = ({
  materials,
  shoppingCart,
  shoppingCartItems,
  modelsByUri,
  modelLibrariesByUri,
  // shoppingCartOwner,
  orderForBuyerShoppingCart,
  fetchingAdditionalData,
}) => {
  const {
    isFetchingModelRelatedData,
    isFetchingUsers,
  } = fetchingAdditionalData;
  // Every item must have a `price_per` key.
  const orderIsCreatedThroughShoppingCart = getIsOrderCreatedThroughShoppingCart(shoppingCartItems);

  return (
    <div>
      <h1>{shoppingCart.name}</h1>
      <div
        className="card-list mb30"
        style={{ overflowY: 'auto' }}
      >
        {shoppingCartItems.map(cartItem => {
          const cartItemModelLibrary = modelLibrariesByUri[cartItem.source];
          const cartItemModelLibraryModel = modelsByUri[cartItemModelLibrary?.additive.model];
          const cartItemMaterial = getCartItemMaterial(cartItem, cartItemModelLibrary, materials);

          return isFetchingModelRelatedData ? <Loading /> : (
            <div
              style={{ maxWidth: 600 }}
              className="card"
              key={cartItem?.uri}
              role="button"
              tabIndex={0}
            >
              <img className="card-img-top" src={cartItemModelLibraryModel?.snapshot_content} alt="" />
              <div className="card-body d-flex align-items-center justify-content-between">
                <h5 className="card-title" style={{ maxWidth: '80px' }}>{cartItemModelLibrary?.name}</h5>
                {
                  cartItem && cartItem.labels && cartItem.labels.length > 0 && (
                    <div className="spacer-top" />
                  )
                }
                <FormControl
                  style={{ maxWidth: 70 }}
                  className="ml10"
                  size="sm"
                  type="number"
                  min={1}
                  defaultValue={cartItem.quantity}
                  disabled
                />
              </div>
              {!!cartItem.price_per && (
                <div className="card-footer text-white">
                  <table className="w-100">
                    <ShoppingCartItemDataRow
                      titleId="quote_price_factor.price"
                      titleDefaultMessage="Price"
                      value={(
                        <FormattedLocalizedCost value={cartItem.price_per} />
                      )}
                    />
                    {cartItem.material_override && (
                      <ShoppingCartItemDataRow
                        titleId="material"
                        titleDefaultMessage="Material"
                        value={(
                          <Link
                            to={getRouteURI(ROUTES.MATERIALS, null, { uuid: extractUuid(cartItemMaterial?.uri) }, true)}
                          >
                            {cartItemMaterial?.name || 'N/A'}
                          </Link>
                        )}
                      />
                    )}
                  </table>
                </div>
              )}
            </div>
          );
        })}
      </div>
      <hr />
      {orderIsCreatedThroughShoppingCart && (
        <Card>
          <Card.Header>
            <h2>
              <FormattedMessage
                id="field.order"
                defaultMessage="Order"
              />
            </h2>
          </Card.Header>
          <Card.Body>
            <Row>
              <Col xs={4}>
                <h3 className="mb15">Details</h3>
                <Button
                  variant="success"
                  className="w-100 mb15"
                  onClick={() => window.open(
                    getRouteURI(ROUTES.ORDER_EDIT, { uuid: extractUuid(shoppingCart.order) }),
                    '_blank',
                  )}
                >
                  {/* TODO: i18n */}
                  View Order
                  <FontAwesomeIcon
                    className="spacer-left"
                    icon={faExternalLink}
                  />
                </Button>
                <FormRow
                  defaultMessage="Created By"
                >
                  {
                    isFetchingUsers ? <Loading /> : (
                      <FormControl
                        value={orderForBuyerShoppingCart?.created_by}
                        disabled
                      />
                    )
                  }
                </FormRow>
                <FormRow
                  defaultMessage="Created Date"
                >
                  <FormattedDate
                    value={orderForBuyerShoppingCart?.created}
                  />
                </FormRow>
              </Col>
              <Col xs={8}>
                <h3 className="mb15">Cost Breakdown</h3>
                <Table id="costBreakdownTable" className="pd-exp inverse">
                  <thead>
                    <tr>
                      <th className="text-left">
                        <FormattedMessage id="field.name" defaultMessage="Name" />
                      </th>
                      <th className="text-left">
                        <FormattedMessage id="field.material" defaultMessage="Material" />
                      </th>
                      <th className="text-left">
                        <FormattedMessage id="field.quantity" defaultMessage="Quantity" />
                      </th>
                      <th className="text-left">Price Per Unit</th>
                      <th className="text-left">
                        <FormattedMessage id="line_item.quote_price_factor.price" defaultMessage="Price" />
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {shoppingCartItems.filter(item => item.price_per).map(cartItem => {
                      const cartItemModelLibrary = modelLibrariesByUri[cartItem.source];
                      const cartItemMaterial = getCartItemMaterial(cartItem, cartItemModelLibrary, materials);

                      return (
                        <tr>
                          <td>{modelLibrariesByUri[cartItem.source]?.name}</td>
                          <td>
                            <Link
                              to={getRouteURI(ROUTES.MATERIALS, null, { uuid: extractUuid(cartItemMaterial?.uri) },
                                true)}
                            >
                              {cartItemMaterial?.name || 'N/A'}
                            </Link>
                          </td>
                          <td>{cartItem.quantity}</td>
                          <td>
                            <FormattedLocalizedCost value={cartItem.price_per} />
                          </td>
                          <td>
                            <FormattedLocalizedCost value={cartItem.price_per * cartItem.quantity} />
                          </td>
                        </tr>
                      );
                    })}
                    {/* Omitted for MVP 👇🏼 */}
                    {/* {showAdditionalCostsManagementView ? (
                        <AdditionalCostsManagementView
                          showAdditionalCostsManagementViewState={{
                            showAdditionalCostsManagementView,
                            setShowAdditionalCostsManagementView,
                          }}
                          additionalCostsState={{
                            emptyAdditionalCostSchema,
                            additionalCosts,
                            setAdditionalCosts,
                          }}
                        />
                      ) : ([
                        <td colSpan={3}>
                          <Button
                            variant="success"
                            onClick={() => setShowAdditionalCostsManagementView(true)}
                            size="xs"
                          >
                            Add Additional Costs
                            <FontAwesomeIcon className="spacer-left" icon={faPlus} />
                          </Button>
                        </td>,
                        <td>
                          <i>No Additional Costs</i>
                        </td>,
                        <td>
                          <FormattedLocalizedCost value={0} />
                        </td>,
                      ])} */}
                    {/* == End of omission 👆🏼 == */}
                  </tbody>
                  <thead />
                  <tbody>
                    <tr>
                      <td colSpan={3} />
                      <td>
                        <b>
                          <FormattedMessage
                            id="total"
                            defaultMessage="Total"
                          />
                        </b>
                      </td>
                      <td>
                        <FormattedLocalizedCost
                          value={getShoppingCartItemsTotalPrice(shoppingCartItems.filter(item => item.price_per))}
                        />
                      </td>
                    </tr>
                  </tbody>
                </Table>
              </Col>
            </Row>
          </Card.Body>
        </Card>
      )}
    </div>
  );
};

export default DDWBuyerShoppingCart;

DDWBuyerShoppingCart.propTypes = {
  shoppingCart: PropTypes.shape({
    name: PropTypes.string,
    order: PropTypes.string,
  }).isRequired,
  materials: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  materialsByUri: PropTypes.shape({}).isRequired,
  shoppingCartItems: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  modelsByUri: PropTypes.shape({}).isRequired,
  modelLibrariesByUri: PropTypes.shape({}).isRequired,
  shoppingCartOwner: PropTypes.shape({
    username: PropTypes.string,
    name: PropTypes.string,
  }).isRequired,
  orderForBuyerShoppingCart: PropTypes.shape({
    created_by: PropTypes.string,
    created: PropTypes.string,
  }).isRequired,
  fetchingAdditionalData: PropTypes.shape({
    isFetchingUsers: PropTypes.bool,
    isFetchingModelRelatedData: PropTypes.bool,
  }).isRequired,
};
