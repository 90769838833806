/* eslint-disable import/prefer-default-export */
import dayjs from 'dayjs';
import PropTypes from 'prop-types';
import { DATE_LOCALES, useDateLocale } from 'rapidfab/hooks';
import React, { useCallback, useMemo } from 'react';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';

/*
 * Custom date input component using `react-datepicker` library.
 * Native HTML date input's date format cannot be modified, so we use a third-party library.
 */
export const DateInput = ({
  value,
  onChange,
  name,
  className,
  placeholder,
  max,
  min,
  required,
  disabled,
}) => {
  const { locale } = useDateLocale();

  // DatePicker doesn't like dayjs's date formats
  const dateFormat = locale === DATE_LOCALES.ISO ? 'yyyy/MM/dd' : 'MM/dd/yyyy';
  const placeholderText = placeholder ?? locale === DATE_LOCALES.ISO ? 'YYYY / MM / DD' : 'MM / DD / YYYY';

  /** Check and prevent crashes when non-parseable values are passed in. */
  const checkValid = useCallback(value => (dayjs(value).isValid() ? value : undefined), []);

  // DatePicker requires a Date instance as the `selected`, `max`, `min` values
  const selected = useMemo(() => (checkValid(value) ? dayjs(value).tz('UTC').toDate() : undefined), [value]);
  const maxDate = useMemo(() => (checkValid(max) ? dayjs(max).tz('UTC').toDate() : undefined), [max]);
  const minDate = useMemo(() => (checkValid(min) ? dayjs(min).tz('UTC').toDate() : undefined), [min]);

  // The DatePicker component's onChange returns a Date object; HTML date inputs
  // use date strings (https://developer.mozilla.org/en-US/docs/Web/HTML/Element/input/date#value)
  // So format and wrap in a DOM event before propagating
  const handleChange = date => {
    const formatted = dayjs(date).format('YYYY-MM-DD');
    onChange({ target: { value: formatted, name } });
  };

  return (
    <DatePicker
      name={name}
      // React-DatePicker's input is of type=text, so `value` is the actual text input in the field
      // `selected` is the current selected date, so we want to control that instead
      selected={selected}
      onChange={handleChange}
      dateFormat={dateFormat}
      maxDate={maxDate}
      minDate={minDate}
      required={required}
      disabled={disabled}
      placeholderText={placeholderText}
      className="form-control"
      wrapperClassName={className}
    />
  );
};

DateInput.propTypes = {
  value: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  name: PropTypes.string.isRequired,
  className: PropTypes.string,
  required: PropTypes.bool,
  disabled: PropTypes.bool,
  max: PropTypes.string,
  min: PropTypes.string,
  placeholder: PropTypes.string,
};

DateInput.defaultProps = {
  className: 'w-100',
  required: false,
  disabled: false,
  max: undefined,
  min: undefined,
  placeholder: undefined,
};
