import React from 'react';
import PropTypes from 'prop-types';
import StepperStep from 'rapidfab/components/stepper/StepperStep';

const Stepper = ({ steps, activeStep }) => (
  <div>
    {steps.map(o => (
      <StepperStep
        key={o.step}
        step={o}
        active={o.step === activeStep}
        link={o.step < activeStep}
      />
    ))}
  </div>
);

Stepper.defaultProps = {
  steps: [],
  activeStep: 0,
};

Stepper.propTypes = {
  steps: PropTypes.arrayOf(PropTypes.shape({})),
  activeStep: PropTypes.number,
};

export default (Stepper);
