import React from 'react';
import { faClockRotateLeft } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { FormattedMessage } from 'rapidfab/i18n';
import { Col, OverlayTrigger, Row, Tooltip } from 'react-bootstrap';
import { getLineItemWorkstepEstimatesByUri, getUsersByUri } from 'rapidfab/selectors';
import { useSelector } from 'react-redux';
import FormattedLocalizedCost from 'rapidfab/components/FormattedLocalizedCost';
import { API_RESOURCES, FEATURES } from 'rapidfab/constants';
import dayjs from 'dayjs';
import PropTypes from 'prop-types';
import Feature from '../Feature';
import Loading from '../Loading';

const convertPrice = value => new Intl.NumberFormat('en-US', {
  style: 'currency',
  currency: 'USD',
}).format(value);

const WorkstepSummary = ({ lineItem, workstep, total, tableData, editMode }) => {
  const maxHours = Math.max(...tableData.filter(row => row.unit === 'Hour').map(row => row.total / row.perUnit));

  return (
    <div className="line-item-quote-modal-summary">
      <h4>
        <FormattedMessage
          id="field.summary"
          defaultMessage="Summary"
        />
      </h4>

      <Row className="g7">
        <Col xs={6} className="font-weight-200 line-item-quote-modal-col-dashed-line">
          <div className="d-flex align-items-center justify-content-between">
            <p className="mb0">
              <FormattedMessage
                id="line_item_quote.per_piece_duration"
                defaultMessage="Per Piece Duration"
              />
            </p>
            <div className="d-flex align-items-center justify-content-between">
              <FontAwesomeIcon icon={faClockRotateLeft} className="spacer-right" />
              <p className="mb0 quoteProcessStepModalInputTextPrice">
                {Math.round(maxHours)} h
              </p>
            </div>

          </div>
          <div className="d-flex align-items-center justify-content-between">
            <p
              className="mb0"
            >
              <FormattedMessage
                id="line_item_quote.per_line_item_duration"
                defaultMessage="Per Line Item Duration"
              />
              <span className="spacer-left">
                ({lineItem.piece_count} pieces)

              </span>
            </p>
            <div className="d-flex align-items-center justify-content-between">
              <FontAwesomeIcon icon={faClockRotateLeft} className="spacer-right" />

              <p className="mb0 quoteProcessStepModalInputTextPrice">
                {(lineItem.piece_count * Math.round(maxHours))} h

              </p>
            </div>

          </div>
        </Col>
        <Col xs={6} className="font-weight-500">
          <div className="d-flex align-items-center justify-content-between">
            <p className="mb0">
              <FormattedMessage
                id="line_item_quote.per_piece_total_price"
                defaultMessage="Total Price Per Piece"
              />
            </p>
            <div>
              <OverlayTrigger
                placement="top"
                overlay={(
                  <Tooltip>
                    {convertPrice(total / lineItem.piece_count)}
                  </Tooltip>
                )}
              >
                <p className="quoteProcessStepModalInputTextPrice">
                  <strong>
                    <FormattedLocalizedCost value={total / lineItem.piece_count} />
                  </strong>
                </p>
              </OverlayTrigger>
            </div>
          </div>
          <div className="d-flex align-items-center justify-content-between">
            <p className="mb0">
              <FormattedMessage
                id="line_item_quote.per_line_item_total_price"
                defaultMessage="Total Price Per Line Item"
              />
            </p>
            <div>
              <OverlayTrigger
                placement="top"
                overlay={(
                  <Tooltip>
                    {convertPrice(total)}
                  </Tooltip>
                )}
              >
                <p className="mb0 quoteProcessStepModalInputTextPrice">
                  <strong>
                    <FormattedLocalizedCost value={total} />
                  </strong>
                </p>
              </OverlayTrigger>
            </div>
          </div>
        </Col>
      </Row>
      <Row>
        {!editMode && (
          <>
            <LastEdited processStep={workstep} />
            <Feature featureName={FEATURES.WORKSTEP_ESTIMATE}>
              <ScheduleLastEdited processStep={workstep} />
            </Feature>
          </>
        )}
      </Row>
    </div>
  );
};

WorkstepSummary.propTypes = {
  lineItem: PropTypes.shape({
    piece_count: PropTypes.number,
  }).isRequired,
  workstep: PropTypes.shape({}).isRequired,
  total: PropTypes.number.isRequired,
  tableData: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  editMode: PropTypes.bool.isRequired,
};

const LastEdited = ({ processStep }) => {
  const isUpdated = !!processStep?.updated;
  const users = useSelector(getUsersByUri);

  return (
    <div className="mb20">
      <p className="mb0">
        <strong>
          <FormattedMessage
            id="line_item_quote.last_edited"
            defaultMessage="Quote Last Edited"
          />:
        </strong>
        {!isUpdated &&
        ' This workflow step quote has never been edited'}
        {isUpdated && (
          processStep.updated_by ? (
            <span className="spacer-left">{`${users[processStep.updated_by]?.name} (${dayjs(processStep.updated)
              .format('YYYY-MM-DD h:mm A')})`}
            </span>
          ) : (
            <span className="spacer-left">{`Updated at (${dayjs(processStep.updated)
              .format('YYYY-MM-DD h:mm A')})`}
            </span>
          )
        )}
      </p>
    </div>
  );
};

LastEdited.propTypes = {
  processStep: PropTypes.shape({
    updated_by: PropTypes.string,
    updated: PropTypes.string,
    uri: PropTypes.string,
  }).isRequired,
};

const ScheduleLastEdited = ({ processStep }) => {
  const estimate = useSelector(getLineItemWorkstepEstimatesByUri)[processStep.uri];
  const users = useSelector(getUsersByUri);
  const fetching = useSelector(state =>
    state.ui.nautilus[API_RESOURCES.LINE_ITEM_WORKSTEP_ESTIMATE].get.fetching ||
    state.ui.nautilus[API_RESOURCES.USERS].get.fetching,
  );

  if (fetching) {
    return <Loading className="ml15" inline />;
  }

  const isUpdated = estimate?.updated_by;

  return (
    <p className="mb0">
      <strong>
        <FormattedMessage
          id="line_item_quote.schedule_last_edited"
          defaultMessage="Schedule Last Edited"
        />:
      </strong>
      {!isUpdated && ' Not Stored as scheduled time'}
      {isUpdated && (
        <span>{` ${users[estimate?.updated_by]?.name} (${dayjs(estimate.updated)
          .format('YYYY-MM-DD h:mm A')})`}
        </span>
      )}
    </p>
  );
};

ScheduleLastEdited.propTypes = {
  processStep: PropTypes.shape({
    uri: PropTypes.string,
  }).isRequired,
};

export default WorkstepSummary;
