import React from 'react';
import PropTypes from 'prop-types';
import { ButtonToolbar, Button } from 'react-bootstrap';
import { FormattedMessage } from 'rapidfab/i18n';
import RunMaterialToggle from 'rapidfab/components/records/run/RunMaterialToggle';
import Loading from 'rapidfab/components/Loading';

const SelectPiecesPanelHeader = ({
  showSearch,
  toggleChecked,
  onToggleChange,
  fetching,
  selectLabel,
  handleSearchSubmit,
  handleSearchValue,
  searchValue,
}) => (
  <div className="vm-panel-header fixed-select-pieces-panel">
    <span>{selectLabel}</span>

    <ButtonToolbar className="pull-right">
      {
        fetching ?
          <Loading /> :
          (
            <RunMaterialToggle
              checked={toggleChecked}
              onChange={onToggleChange}
              disabled={fetching}
            />
          )
      }
    </ButtonToolbar>

    { showSearch && (
      <>
        <ButtonToolbar className="pull-right">
          <Button
            size="sm"
            variant="success"
            onClick={handleSearchSubmit}
          >
            <FormattedMessage
              id="record.run.search"
              defaultMessage="Search"
            />
          </Button>
        </ButtonToolbar>
        <form
          onSubmit={handleSearchSubmit}
          className="form-group form-group-sm react-bs-table-search-form pull-right m-b-0 mr15"
          style={{ maxWidth: 212 }}
        >
          <input
            type="text"
            className="form-control"
            placeholder="Search"
            value={searchValue}
            onChange={handleSearchValue}
          />
        </form>
      </>
    )}
  </div>
);

SelectPiecesPanelHeader.defaultProps = {
  showSearch: false,
  handleSearchSubmit: () => {},
  handleSearchValue: () => {},
  searchValue: '',
};

SelectPiecesPanelHeader.propTypes = {
  showSearch: PropTypes.bool,
  toggleChecked: PropTypes.bool.isRequired,
  onToggleChange: PropTypes.func.isRequired,
  fetching: PropTypes.bool.isRequired,
  selectLabel: PropTypes.string.isRequired,
  handleSearchSubmit: PropTypes.func,
  handleSearchValue: PropTypes.func,
  searchValue: PropTypes.string,
};

export default SelectPiecesPanelHeader;
