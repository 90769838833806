import React from 'react';
import Loading from 'rapidfab/components/Loading';
import {
  Button,
  Modal,
  ModalBody,
  ModalFooter,
} from 'react-bootstrap';
import Iframe from 'react-iframe';
import PropTypes from 'prop-types';

const NebumindModal = ({ nebumindData, onClose }) => {
  if (!nebumindData) return <Loading />;

  return (
    <Modal size="xl" show onHide={onClose}>
      <ModalBody>
        <div className="mb15" style={{ width: '100%' }}>
          <Iframe
            height="1000px"
            width="100%"
            url="https://external.nebumind.com/3dview/SELECT%20AVG(%22density%22::sensor)%20as%20'color'%20FROM%20%22density%22::sensor%20IF%20%20%22parttype%22::tag%20==%20'OtfCT'%20GROUP%20BY%20CUBE(maxEdgeLength=8.20344)"
          />
        </div>
      </ModalBody>

      <ModalFooter>
        <Button variant="primary" onClick={() => onClose()}>
          Close
        </Button>
      </ModalFooter>
    </Modal>
  );
};

NebumindModal.propTypes = {
  nebumindData: PropTypes.shape({
    visualization: PropTypes.string.isRequired,
  }).isRequired,
  onClose: PropTypes.func.isRequired,
};

export default NebumindModal;
