import React from 'react';
import PropTypes from 'prop-types';

import { Nav, NavItem, NavLink } from 'react-bootstrap';
import { FEATURES, ROUTES } from 'rapidfab/constants';
import { getRouteURI } from 'rapidfab/utils/uriUtils';
import Feature from 'rapidfab/components/Feature';

const FeedstocksTabs = ({ activeKey }) => (
  <Feature featureName={FEATURES.MANUFACTURING_EXECUTION_SYSTEM}>
    <Nav
      variant="tabs"
      activeKey={activeKey}
      // `undefined` is needed to allow `href` in NavItem-s
      onSelect={undefined}
      id="feedstocksTabs"
    >
      <NavItem>
        <NavLink eventKey="material-stocks" href={getRouteURI(ROUTES.STOCKS)}>
          Material Stocks
        </NavLink>
      </NavItem>
      <Feature featureName={FEATURES.MATERIAL_MANAGEMENT}>
        <NavItem>
          <NavLink eventKey="material-lots" href={getRouteURI(ROUTES.MATERIAL_LOTS)}>
            Material Lots
          </NavLink>
        </NavItem>
        <NavItem>
          <NavLink eventKey="material-batches" href={getRouteURI(ROUTES.MATERIAL_BATCHES)}>
            Material Batches
          </NavLink>
        </NavItem>
        <NavItem>
          <NavLink eventKey="permanent-containers" href={getRouteURI(ROUTES.PERMANENT_CONTAINERS)}>
            Permanent Containers
          </NavLink>
        </NavItem>
      </Feature>
    </Nav>
  </Feature>
);

FeedstocksTabs.propTypes = {
  activeKey: PropTypes.oneOf(['material-stocks', 'material-lots', 'material-batches', 'permanent-containers']).isRequired,
};

export default FeedstocksTabs;
