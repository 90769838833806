// This is a temporary file
// which contains constants that will removed once functionality is not needed

// eslint-disable-next-line import/prefer-default-export
export const ANATOMICAL_MODEL_ASSEMBLY_TYPES = {
  NONE: 'none',
  GLUED: 'glued',
  MAGNET: 'magnet',
};

export const ANATOMICAL_MODEL_STATUSES = {
  PENDING: 'pending',
  PROCESSING: 'processing',
  COMPLETE: 'complete',
  ERROR: 'error',
};

export const ANATOMY_FAMILIES = [
  'none',
  'blood_vessel',
  'general_anatomy',
  'musculoskeletal',
  'structural_heart',
];
export const ANATOMY_ELEMENTS = [
  'none',
  'clot',
  'fixtures',
  'frame',
  'gel_support',
  'inlets',
  'reinforcements',
  'solid_tumor',
  'valve_annulus',
  'valve_leaflet',
  'vascular_calcification',
  'vessel_wall',
  'dense_connective_tissues',
  'hollow_internal_organs',
  'solid_internal_organs',
  'facet_joints',
  'general_bone',
  'intervertebral_discs',
  'ligament',
  'long_bone',
  'nerves',
  'ribs',
  'skull',
  'vertabra',
  'myocardium',
  'reinforcement',
  'valve_cordae',
  'valvular_calcification',
];
export const ANATOMY_PROPERTIES = [
  'none',
  'stiffness',
  'rigidity',
  'type',
  'compliance',
  'density',
  'porosity',
  'rigidity_level',
];

export const PART_STATUSES = {
  NOT_UPLOADED: 'not-uploaded',
  PROCESSING: 'processing',
  PROCESSED: 'processed',
  ERROR: 'error',
};

// Keys are copied from `MANUFACTURING_PROCESSES` constant
// to be able to map values in the UI for the 3rd part config
export const MANUFACTURING_PROCESS_TO_PRINTER_CONFIGURATION_MAP = {
  SLA: 'SLA',
  SLS: 'SLS',
  FDM: 'FDM',
  Polyjet: 'Polyjet',
  'Polyjet Anatomy': 'Polyjet - Anatomy',
};

// This Json is provided via 3rd party
// Leaving all metadata to be able to replace values when needed
// Copy-paste json content into this constant, when there is a new version
export const PRINTER_CONFIGURATION_JSON = {
  version: 6,
  date: '2020-07-27',
  language: 'en',
  printerTechnology: [
    {
      name: 'Polyjet',
      description: 'Stratasys J750 Printer',
      rgb: true,
      transparency: true,
      defaultThickness: '2.0',
      flexibility: [
        '30',
        '40',
        '50',
        '60',
        '70',
        '85',
        '95',
      ],
      color: [],
      finish: [
        'Standard',
        'Sanding',
        'Polishing',
      ],
      hollowShells: true,
      hollowDefault: true,
      anatomy: null,
    },
    {
      name: 'Polyjet - Anatomy',
      description: 'Stratasys J750 Digital Anatomy Printer',
      rgb: false,
      transparency: false,
      defaultThickness: '2.0',
      flexibility: [],
      color: [],
      finish: ['Standard'],
      hollowShells: false,
      hollowDefault: false,
      anatomy: {
        name: 'J750 Digital Anatomy Printer V1.0',
        description: 'GrabCAD version 1.22',
        family: [{
          name: 'Blood Vessel',
          element: [{
            name: 'Clot',
            visible: true,
            property: [{
              name: 'Stiffness',
              value: [{
                name: 'Fibrotic',
                default: true,
                color: [
                  '82427DFF',
                  'FAFF9EFF',
                ],
              },
              {
                name: 'Highly Fribrotic',
                color: [
                  'A62162FF',
                  'F0F0F0FF',
                ],
              },
              {
                name: 'Soft',
                color: [
                  '815184FF',
                  'F9FE8AFF',
                ],
              },
              ],
            }],
          },
          {
            name: 'Fixtures',
            visible: false,
            property: [{
              name: 'Rigidity',
              value: [{
                name: '1',
                default: true,
                color: [
                  '84638DFF',
                  'FBFE80FF',
                ],
              },
              {
                name: '2',
                color: [
                  '815184FF',
                  'F9FE8AFF',
                ],
              },
              {
                name: '3',
                color: [
                  '80467FFF',
                  'F9FE94FF',
                ],
              },
              {
                name: '4',
                color: [
                  '82427DFF',
                  'F9FE94FF',
                ],
              },
              {
                name: '5',
                color: [
                  '863476FF',
                  'FCFFA8FF',
                ],
              },
              {
                name: '6',
                color: [
                  'A62162FF',
                  'F0F0F0FF',
                ],
              },
              ],
            }],
          },
          {
            name: 'Frame',
            visible: false,
            property: [{
              name: 'Rigidity',
              value: [{
                name: '1',
                default: true,
                color: [
                  'FFFF64FF',
                ],
              },
              {
                name: '2',
                color: [
                  '84638DFF',
                  'FBFE80FF',
                ],
              },
              {
                name: '3',
                color: [
                  '815184FF',
                  'F9FE8AFF',
                ],
              },
              {
                name: '4',
                color: [
                  '80467FFF',
                  'F9FE94FF',
                ],
              },
              {
                name: '5',
                color: [
                  '82427DFF',
                  'FAFF9EFF',
                ],
              },
              {
                name: '6',
                color: [
                  '863476FF',
                  'FCFFA8FF',
                ],
              },
              {
                name: '7',
                color: [
                  'A62162FF',
                  'F0F0F0FF',
                ],
              },
              ],
            }],
          },
          {
            name: 'Gel Support',
            visible: false,
            property: [{
              name: '',
              value: [{
                name: '',
                default: true,
                color: [
                  'FFFF64FF',
                ],
              }],
            }],
          },
          {
            name: 'Inlets',
            visible: true,
            property: [{
              name: 'Rigidity',
              value: [{
                name: '1',
                default: true,
                color: [
                  '82427DFF',
                  'FAFF9EFF',
                ],
              },
              {
                name: '2',
                color: [
                  '863476FF',
                  'FCFFA8FF',
                ],
              },
              {
                name: '3',
                color: [
                  '8B2B72FF',
                  'FDFFB2FF',
                ],
              },
              {
                name: '4',
                color: [
                  'A62162FF',
                  'F0F0F0FF',
                ],
              },
              ],
            }],
          },
          {
            name: 'Reinforcements',
            visible: false,
            property: [{
              name: 'Rigidity',
              value: [{
                name: '1',
                default: true,
                color: [
                  '82427DFF',
                  'FAFF9EFF',
                ],
              },
              {
                name: '2',
                color: [
                  '8B2B72FF',
                  'FDFFB2FF',
                ],
              },
              {
                name: '3',
                color: [
                  'A62162FF',
                  'F0F0F0FF',
                ],
              },
              ],
            }],
          },
          {
            name: 'Solid Tumor',
            visible: true,
            property: [{
              name: 'Type',
              value: [{
                name: 'A: Soft non-encapsulated tumor',
                default: true,
                color: [
                  'EE4F1AFF',
                ],
              },
              {
                name: 'B: Soft encapsulated tumor',
                color: [
                  'C03A0EFF',
                ],
              },
              {
                name: 'C: Stiff non-encapsulated tumor',
                color: [
                  'F0F0F0FF',
                ],
              },
              {
                name: 'D: Soft uniform tumor',
                color: [
                  'FFFF64FF',
                ],
              },
              ],
            }],
          },
          {
            name: 'Valve Annulus',
            visible: true,
            property: [{
              name: 'Stiffness',
              value: [{
                name: 'Soft',
                default: true,
                color: [
                  'E6D2CAFF',
                ],
              },
              {
                name: 'Moderately Stiff',
                color: [
                  'EDE0DAFF',
                ],
              },
              {
                name: 'Stiffened',
                color: [
                  'DADADAFF',
                ],
              },
              ],
            }],
          },
          {
            name: 'Valve Leaflet',
            visible: true,
            property: [{
              name: 'Stiffness',
              value: [{
                name: 'Moderately Stiff',
                default: true,
                color: [
                  '815184FF',
                  'F9FE8AFF',
                ],
              },
              {
                name: 'Stiffened',
                color: [
                  '82427DFF',
                  'FAFF9EFF',
                ],
              },
              {
                name: 'Stiff',
                color: [
                  'A62162FF',
                  'F0F0F0FF',
                ],
              },
              ],
            }],
          },
          {
            name: 'Vascular Calcification',
            visible: true,
            property: [{
              name: 'Stiffness',
              value: [{
                name: 'Soft Calcium Deposits',
                default: true,
                color: [
                  '815184FF',
                  'F9FE8AFF',
                ],
              },
              {
                name: 'Moderately Stiff Calcium Deposits',
                color: [
                  '82427DFF',
                  'FAFF9EFF',
                ],
              },
              {
                name: 'Stiff Calcium Deposits',
                color: [
                  'A62162FF',
                  'F0F0F0FF',
                ],
              },
              ],
            }],
          },
          {
            name: 'Vessel Wall',
            visible: true,
            property: [{
              name: 'Compliance',
              value: [{
                name: 'Compliant',
                default: true,
                color: [
                  'FFFF64FF',
                ],
              },
              {
                name: 'Moderately Compliant',
                color: [
                  '84638DFF',
                  'FBFE80FF',
                ],
              },
              {
                name: 'Slightly Compliant',
                color: [
                  '815184FF',
                  'F9FE8AFF',
                ],
              },
              {
                name: 'Low Compliant',
                color: [
                  '80467FFF',
                  'F9FE94FF',
                ],
              },
              {
                name: 'Semi-Rigid',
                color: [
                  '82427DFF',
                  'FAFF9EFF',
                ],
              },
              {
                name: 'Rigid',
                color: [
                  '863476FF',
                  'FCFFA8FF',
                ],
              },
              ],
            }],
          },
          ],
        },
        {
          name: 'General Anatomy',
          element: [{
            name: 'Dense Connective Tissues',
            visible: true,
            property: [{
              name: 'Stiffness',
              value: [{
                name: 'Soft',
                default: true,
                color: [
                  '80467FFF',
                  'F9FE94FF',
                ],
              },
              {
                name: 'Rigid',
                color: [
                  '82427DFF',
                  'FAFF9EFF',
                ],
              },
              {
                name: 'Highly Rigid',
                color: [
                  '863476FF',
                  'FCFFA8FF',
                ],
              },
              ],
            }],
          },
          {
            name: 'Hollow Internal Organs',
            visible: true,
            property: [{
              name: 'Stiffness',
              value: [{
                name: 'Compliant',
                default: true,
                color: [
                  'FFFF64FF',
                ],
              },
              {
                name: 'Moderately Compliant',
                color: [
                  '84638DFF',
                  'FBFE80FF',
                ],
              },
              {
                name: 'Slightly Compliant',
                color: [
                  '815184FF',
                  'F9FE8AFF',
                ],
              },
              {
                name: 'Low Compliant',
                color: [
                  '80467FFF',
                  'F9FE94FF',
                ],
              },
              {
                name: 'Semi-Rigid',
                color: [
                  '82427DFF',
                  'FAFF9EFF',
                ],
              },
              {
                name: 'Rigid',
                color: [
                  '863476FF',
                  'FCFFA8FF',
                ],
              },
              {
                name: 'Highly-Rigid',
                color: [
                  'A62162FF',
                  'F0F0F0FF',
                ],
              },
              ],
            }],
          },
          {
            name: 'Solid Internal Organs',
            visible: true,
            property: [{
              name: 'Stiffness',
              value: [{
                name: 'Fiber Contraction 1',
                default: true,
                color: [
                  'FC8D8DFF',
                ],
              },
              {
                name: 'Fiber Contraction 2',
                color: [
                  'FA5F5FFF',
                ],
              },
              {
                name: 'Fiber Contraction 3',
                color: [
                  'F81D1DFF',
                ],
              },
              {
                name: 'Fiber Contraction 4',
                color: [
                  'D20606FF',
                ],
              },
              {
                name: 'Fiber Contraction 5',
                color: [
                  'A30505FF',
                ],
              },
              {
                name: 'Fiber Contraction 6',
                color: [
                  '646464FF',
                ],
              },
              {
                name: 'Fiber Contraction 7',
                color: [
                  '646464FF',
                ],
              },
              ],
            }],
          },
          {
            name: 'Solid Tumor',
            visible: true,
            property: [{
              name: 'Type',
              value: [{
                name: 'A: Soft non-encapsulated tumor',
                default: true,
                color: [
                  'EE4F1AFF',
                ],
              },
              {
                name: 'B: Soft encapsulated tumor',
                color: [
                  'C03A0EFF',
                ],
              },
              {
                name: 'C: Stiff non-encapsulated tumor',
                color: [
                  'F0F0F0FF',
                ],
              },
              {
                name: 'D: Soft uniform tumor',
                color: [
                  'FFFF64FF',
                ],
              },
              ],
            }],
          },
          ],
        },
        {
          name: 'Musculoskeletal',
          element: [{
            name: 'Facet Joints',
            visible: true,
            property: [{
              name: 'Stiffness',
              value: [{
                name: 'Soft',
                default: true,
                color: [
                  'FC8D8DFF',
                ],
              },
              {
                name: 'Moderately Stiff',
                color: [
                  'FA5F5FFF',
                ],
              },
              {
                name: 'Stiff',
                color: [
                  'A30505FF',
                ],
              },
              ],
            }],
          },
          {
            name: 'General Bone',
            visible: true,
            property: [{
              name: 'Stiffness',
              value: [{
                name: 'Porous',
                default: true,
                color: [
                  'E4DDCBFF',
                ],
              },
              {
                name: 'Slightly Dense',
                color: [
                  'EDE9DEFF',
                ],
              },
              {
                name: 'Dense',
                color: [
                  'F0ECE3FF',
                ],
              },
              ],
            }],
          },
          {
            name: 'Intervertebral Discs',
            visible: true,
            property: [{
              name: 'Type',
              value: [{
                name: 'Normal',
                default: true,
                color: [
                  'FDF5B7FF',
                ],
              },
              {
                name: 'Degenerated',
                color: [
                  'C39438FF',
                ],
              },
              ],
            }],
          },
          {
            name: 'Ligament',
            visible: true,
            property: [{
              name: 'Stiffness',
              value: [{
                name: 'Soft',
                default: true,
                color: [
                  'FFFF64FF',
                ],
              },
              {
                name: 'Moderately Stiff',
                color: [
                  '84638DFF',
                  'FBFE80FF',
                ],
              },
              {
                name: 'Stiff',
                color: [
                  '815184FF',
                  'F9FE8AFF',
                ],
              },
              ],
            }],
          },
          {
            name: 'Long Bone',
            visible: true,
            property: [{
              name: 'Type',
              value: [{
                name: 'Miniature',
                default: true,
                color: [
                  'F9F9EEFF',
                ],
              },
              {
                name: 'Typical',
                color: [
                  'EAE7D7FF',
                ],
              },
              ],
            }],
          },
          {
            name: 'Nerves',
            visible: true,
            property: [{
              name: 'Stiffness',
              value: [{
                name: 'Soft',
                default: true,
                color: [
                  'FC8D8DFF',
                ],
              },
              {
                name: 'Moderately Stiff',
                color: [
                  'FA5F5FFF',
                ],
              },
              {
                name: 'Stiff',
                color: [
                  'A30505FF',
                ],
              },
              ],
            }],
          },
          {
            name: 'Ribs',
            visible: true,
            property: [{
              name: 'Stiffness',
              value: [{
                name: 'Moderately Stiff',
                default: true,
                color: [
                  'FEFBE7FF',
                ],
              },
              {
                name: 'Very Stiff',
                color: [
                  'FFF5D7FF',
                ],
              },
              ],
            }],
          },
          {
            name: 'Skull',
            visible: true,
            property: [{
              name: 'Density',
              value: [{
                name: 'Dense',
                default: true,
                color: [
                  'EBE8DCFF',
                ],
              }],
            }],
          },
          {
            name: 'Vertabra',
            visible: true,
            property: [{
              name: 'Porosity',
              value: [{
                name: 'Porous',
                default: true,
                color: [
                  'E0DCC9FF',
                ],
              },
              {
                name: 'Dense',
                color: [
                  'EBE8DCFF',
                ],
              },
              ],
            }],
          },
          ],
        },
        {
          name: 'Structural Heart',
          element: [{
            name: 'Clot',
            visible: true,
            property: [{
              name: 'Stiffness',
              value: [{
                name: 'Soft',
                default: true,
                color: [
                  '815184FF',
                  'F9FE8AFF',
                ],
              },
              {
                name: 'Fibrotic',
                default: true,
                color: [
                  '82427DFF',
                  'FAFF9EFF',
                ],
              }, {
                name: 'Highly Fribrotic',
                default: true,
                color: [
                  'A62162FF',
                  'F0F0F0FF',
                ],
              },
              ],
            }],
          },
          {
            name: 'Frame',
            visible: false,
            property: [{
              name: 'Rigidity Level',
              value: [{
                name: '1',
                default: true,
                color: [
                  'FFFF64FF',
                ],
              },
              {
                name: '2',
                color: [
                  '84638DFF',
                  'FBFE80FF',
                ],
              },
              {
                name: '3',
                color: [
                  '815184FF',
                  'F9FE8AFF',
                ],
              },
              {
                name: '4',
                color: [
                  '82427DFF',
                  'FAFF9EFF',
                ],
              },
              {
                name: '5',
                color: [
                  '863476FF',
                  'FCFFA8FF',
                ],
              },
              {
                name: '6',
                color: [
                  '8B2B72FF',
                  'FDFFB2FF',
                ],
              },
              {
                name: '7',
                color: [
                  'A62162FF',
                  'F0F0F0FF',
                ],
              },
              ],
            }],
          },
          {
            name: 'Myocardium',
            visible: true,
            property: [{
              name: 'Stiffness',
              value: [{
                name: 'Highly Contractile',
                default: true,
                color: [
                  'FC8D8DFF',
                ],
              },
              {
                name: 'Moderately Stiff',
                color: [
                  'FA5F5FFF',
                ],
              },
              {
                name: 'Stiffened',
                color: [
                  'F81D1DFF',
                ],
              },
              {
                name: 'Very Stiff',
                color: [
                  'D20606FF',
                ],
              },
              {
                name: 'Extremely Stiff',
                color: [
                  'A30505FF',
                ],
              },
              ],
            }],
          },
          {
            name: 'Reinforcement',
            visible: false,
            property: [{
              name: 'Rigidity Level',
              value: [{
                name: '1',
                default: true,
                color: [
                  '815184FF',
                  'F9FE8AFF',
                ],
              },
              {
                name: '2',
                color: [
                  '82427DFF',
                  'FAFF9EFF',
                ],
              },
              {
                name: '3',
                color: [
                  'A62162FF',
                  'F0F0F0FF',
                ],
              },
              ],
            }],
          },
          {
            name: 'Solid Tumor',
            visible: true,
            property: [{
              name: 'Type',
              value: [{
                name: 'A: Soft non-encapsulated tumor',
                default: true,
                color: [
                  'EE4F1AFF',
                ],
              },
              {
                name: 'B: Soft encapsulated tumor',
                color: [
                  'C03A0EFF',
                ],
              },
              {
                name: 'C: Stiff non-encapsulated tumor',
                color: [
                  'F0F0F0FF',
                ],
              },
              {
                name: 'D: Soft uniform tumor',
                color: [
                  'FFFF64FF',
                ],
              },
              ],
            }],
          },
          {
            name: 'Valve Annulus',
            visible: true,
            property: [{
              name: 'Stiffness',
              value: [{
                name: 'Soft Healthy',
                default: true,
                color: [
                  'E6D2CAFF',
                ],
              },
              {
                name: 'Moderately Stiff',
                color: [
                  'EDE0DAFF',
                ],
              },
              {
                name: 'Stiffened Diseased',
                color: [
                  'DADADAFF',
                ],
              },
              ],
            }],
          },
          {
            name: 'Valve Cordae',
            visible: true,
            property: [{
              name: 'Stiffness',
              value: [{
                name: 'Highly Extensible',
                default: true,
                color: [
                  'FC8D8DFF',
                ],
              },
              {
                name: 'Extensible',
                color: [
                  'A30505FF',
                ],
              },
              {
                name: 'Stiffened',
                color: [
                  'FFFF64FF',
                ],
              },
              ],
            }],
          },
          {
            name: 'Valve Leaflet',
            visible: true,
            property: [{
              name: 'Stiffness',
              value: [{
                name: 'Soft Healthy',
                default: true,
                color: [
                  'FFFF64FF',
                ],
              },
              {
                name: 'Moderately Stiff',
                default: true,
                color: [
                  '815184FF',
                  'F9FE8AFF',
                ],
              },
              {
                name: 'Stiffened',
                default: true,
                color: [
                  '82427DFF',
                  'FAFF9EFF',
                ],
              },
              {
                name: 'Extensively Stiff',
                default: true,
                color: [
                  '8B2B72FF',
                  'FDFFB2FF',
                ],
              },
              ],
            }],
          },
          {
            name: 'Valvular Calcification',
            visible: true,
            property: [{
              name: 'Stiffness',
              value: [{
                name: 'Soft',
                default: true,
                color: [
                  '815184FF',
                  'F9FE8AFF',
                ],
              },
              {
                name: 'Moderately Stiff',
                color: [
                  '82427DFF',
                  'FAFF9EFF',
                ],
              },
              {
                name: 'Stiff',
                color: [
                  'A62162FF',
                  'F0F0F0FF',
                ],
              },
              ],
            }],
          },
          {
            name: 'Vessel Wall',
            visible: true,
            property: [{
              name: 'Compliance',
              value: [{
                name: 'Compliant',
                default: true,
                color: [
                  'FFFF64FF',
                ],
              },
              {
                name: 'Slightly Compliant',
                color: [
                  '815184FF',
                  'F9FE8AFF',
                ],
              },
              {
                name: 'Low Compliant',
                color: [
                  '80467FFF',
                  'F9FE94FF',
                ],
              },
              ],
            }],
          },
          ],
        },
        ],
      },
    },
    {
      name: 'FDM',
      description: 'Fused Deposition Modeling Printer',
      rgb: false,
      transparency: false,
      defaultThickness: '2.0',
      flexibility: [],
      color: [
        'FFFFFFFF',
        '000000FF',
        'FFFF00FF',
        'FF0000FF',
        '0000FFFF',
        '808080FF',
        '00FF00FF',
      ],
      finish: ['Standard'],
      hollowShells: true,
      hollowDefault: true,
      anatomy: null,
    },
    {
      name: 'SLA',
      description: 'Vat Polymerization Printer',
      rgb: false,
      transparency: false,
      defaultThickness: '2.0',
      flexibility: [],
      color: ['FFFFFF00'],
      finish: [
        'Standard',
        'Sanding',
        'Polishing',
      ],
      hollowShells: true,
      hollowDefault: true,
      anatomy: null,
    },
    {
      name: 'SLS',
      description: 'Powder Bed Fusion Printer',
      rgb: false,
      transparency: false,
      defaultThickness: '2.0',
      flexibility: [],
      color: [
        'FFFFFFFF',
      ],
      finish: ['Standard'],
      hollowShells: true,
      hollowDefault: true,
      anatomy: null,
    },
  ],
};
