import Constants from 'rapidfab/constants';

export function createRunStartProgress(id, createRunUri) {
  return {
    type: Constants.CREATE_RUN_PROGRESS_START,
    id,
    createRunUri,
  };
}

export function createRunRestartStartProgress(oldId, newId, newCreateRunUri) {
  return {
    type: Constants.CREATE_RUN_PROGRESS_RESTART,
    oldId,
    newId,
    newCreateRunUri,
  };
}

export function createRunUpdateProcessing(id) {
  return {
    type: Constants.CREATE_RUN_PROGRESS_PROCESSING,
    id,
  };
}

export function createRunUpdateScheduling(id) {
  return {
    type: Constants.CREATE_RUN_PROGRESS_FINISHING,
    id,
  };
}

export function createRunSuccess(id, runDetails) {
  return {
    type: Constants.CREATE_RUN_PROGRESS_SUCCESS,
    id,
    runDetails,
  };
}

export function createRunProgressClear() {
  return {
    type: Constants.CREATE_RUN_PROGRESS_CLEAR,
  };
}

export function createRunProgressError(id, errorDetails) {
  return {
    type: Constants.CREATE_RUN_PROGRESS_ADD_ERROR,
    id,
    errorDetails,
  };
}
