import React from 'react';
import PropTypes from 'prop-types';

import { Nav, NavItem, NavLink } from 'react-bootstrap';
import { FEATURES, ROUTES } from 'rapidfab/constants';
import { getRouteURI } from 'rapidfab/utils/uriUtils';
import Feature from 'rapidfab/components/Feature';

const AssetTypesTabs = ({ activeKey }) => (
  <Nav
    variant="tabs"
    activeKey={activeKey}
    // `undefined` is needed to allow `href` in NavItem-s
    onSelect={undefined}
    id="assetTypesTabs"
  >
    <Feature isInverted featureName={FEATURES.POWDER_WORKFLOW}>
      <NavItem>
        <NavLink eventKey="printer-types" href={getRouteURI(ROUTES.PRINTER_TYPES)}>
          Printer Types
        </NavLink>
      </NavItem>
    </Feature>
    <Feature featureName={FEATURES.MANUFACTURING_EXECUTION_SYSTEM}>
      <NavItem>
        <NavLink eventKey="post-processor-types" href={getRouteURI(ROUTES.POST_PROCESSOR_TYPES)}>
          Post Processor Types
        </NavLink>
      </NavItem>
    </Feature>
  </Nav>
);

AssetTypesTabs.propTypes = {
  activeKey: PropTypes.oneOf(['printer-types', 'post-processor-types']).isRequired,
};

export default AssetTypesTabs;
