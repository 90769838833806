import React, { useEffect } from 'react';
import { useSelector, useDispatch, connect } from 'react-redux';

import PropTypes from 'prop-types';
import Actions from 'rapidfab/actions';
import * as Selectors from 'rapidfab/selectors';
import { API_RESOURCES } from 'rapidfab/constants';
import { extractUuid } from 'rapidfab/utils/uuidUtils';
import ModelerDeleteModal from 'rapidfab/components/ModelerDeleteModal';

const ModelerDeleteModalContainer = ({ uuid, onDelete, ...otherProps }) => {
  const isLoading = useSelector(state =>
    state.ui.nautilus[API_RESOURCES.MODELER].get.fetching ||
    state.ui.nautilus[API_RESOURCES.MODELER_TYPE].get.fetching);

  const isDeleting = useSelector(state => state.ui.nautilus[API_RESOURCES.MODELER].delete.fetching);

  const modeler = useSelector(state => (uuid ? Selectors.getUUIDResource(state, uuid) : null));

  const modelerTypesByUri = useSelector(Selectors.getModelerTypesByUri);
  const selected = {
    isLoading,
    isDeleting,
    modeler,
    modelerType: modeler && modelerTypesByUri[modeler.type],
  };

  const dispatch = useDispatch();
  const onInitialize = async modelerUuid => {
    const response = await dispatch(Actions.Api.nautilus[API_RESOURCES.MODELER].get(modelerUuid));
    const { type: modelerTypeUri } = response.json;
    dispatch(Actions.Api.nautilus[API_RESOURCES.MODELER_TYPE].get(extractUuid(modelerTypeUri)));
  };
  const onSubmit = id => dispatch(Actions.Api.nautilus[API_RESOURCES.MODELER].delete(id));

  useEffect(() => {
    (async () => onInitialize(uuid))();
  }, []);

  const handleSubmit = async () => {
    await onSubmit(uuid);
    onDelete();
  };

  return (
    <ModelerDeleteModal
      {...otherProps}
      {...selected}
      handleSubmit={handleSubmit}
    />
  );
};

ModelerDeleteModalContainer.defaultProps = {
  modeler: null,
  // UUID can be null when modeler is already deleted, but modal is not yet closed
  uuid: null,
};

ModelerDeleteModalContainer.propTypes = {
  isLoading: PropTypes.bool.isRequired,
  isDeleting: PropTypes.bool.isRequired,
  uuid: PropTypes.string,
  modeler: PropTypes.shape({}),
  onInitialize: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
  onDelete: PropTypes.func.isRequired,
};

function mapStateToProps(state, props) {
  const isLoading =
    state.ui.nautilus[API_RESOURCES.MODELER].get.fetching
    || state.ui.nautilus[API_RESOURCES.MODELER_TYPE].get.fetching;

  const isDeleting = state.ui.nautilus[API_RESOURCES.MODELER].delete.fetching;

  const modeler = props.uuid
    && Selectors.getUUIDResource(state, props.uuid);

  const modelerTypesByUri = Selectors.getModelerTypesByUri(state);

  return {
    isLoading,
    isDeleting,
    modeler,
    modelerType: modeler && modelerTypesByUri[modeler.type],
  };
}

function mapDispatchToProps(dispatch) {
  return {
    onInitialize: uuid => {
      dispatch(Actions.Api.nautilus[API_RESOURCES.MODELER].get(uuid)).then(response => {
        const { type: modelerTypeUri } = response.json;
        dispatch(Actions.Api.nautilus[API_RESOURCES.MODELER_TYPE].get(extractUuid(modelerTypeUri)));
      });
    },
    onSubmit: uuid =>
      dispatch(Actions.Api.nautilus[API_RESOURCES.MODELER].delete(uuid)),
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(ModelerDeleteModalContainer);
