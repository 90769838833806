import { faList, faThLarge } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import _range from 'lodash/range';
import MaterialsCards from 'rapidfab/components/inventory/MaterialsCards';
import MaterialsTable from 'rapidfab/components/inventory/MaterialsTable';
import React from 'react';
import PropTypes from 'prop-types';

import {
  Button,
  ButtonToolbar,
  Col,
  Container,
  Dropdown,
  DropdownButton,
  FormControl,
  Row,
} from 'react-bootstrap';
import { FormattedMessage } from 'react-intl';

import BreadcrumbNav from 'rapidfab/components/BreadcrumbNav';
import Loading from 'rapidfab/components/Loading';
import AddFromDB from 'rapidfab/containers/inventory/AddFromDBContainer';
import { ROUTES, VIEW_MODE_OPTIONS } from 'rapidfab/constants';
import { getRouteURI } from 'rapidfab/utils/uriUtils';

const Materials = ({
  materials,
  fetching,
  externalMaterialDbFeatureEnabled,
  setIsCreateMaterialTypeModalShown,
  isCardsView,
  groupedMaterials,
  manufacturersByUri,
  printerTypes,
  printerTypesFetching,
  filters,
  viewModeProps,
  pagination,
  handleShowPrinterTypeModal,
}) => {
  const {
    globalFilter,
    setGlobalFilter,
  } = filters ?? {};

  const {
    viewMode,
    setViewMode,
  } = viewModeProps ?? {};

  const {
    activePage,
    setPage,
    pageLimit,
    totalItems,
    nextPage,
    prevPage,
    totalPaginatedPages,
  } = pagination ?? {};

  const renderHeaderView = () => {
    const isListView = !isCardsView;
    return (
      <Row>
        <Col xs={isListView && { span: 12 }} lg={isListView && { span: 10, offset: 1 }} className="d-flex justify-content-between">
          <Col xs={4}>
            <div className="d-flex align-items-center">
              <FormControl
                type="text"
                value={globalFilter}
                placeholder="Filter"
                onChange={({ target }) => setGlobalFilter(target.value)}
              />
            </div>
          </Col>
          <div className="pagination-panel">
            <ButtonToolbar className="pull-right toolbar spacer-left">
              <Button
                variant="default"
                text="dark"
                className={viewMode === VIEW_MODE_OPTIONS.CARDS ? 'btn-view-mode-active' : ''}
                onClick={() => setViewMode(VIEW_MODE_OPTIONS.CARDS)}
              >
                <FontAwesomeIcon icon={faThLarge} size="lg" />
              </Button>
              <Button
                text="dark"
                variant="default"
                className={viewMode === VIEW_MODE_OPTIONS.LIST ? 'btn-view-mode-active' : ''}
                active={viewMode === VIEW_MODE_OPTIONS.LIST}
                onClick={() => setViewMode(VIEW_MODE_OPTIONS.LIST)}
              >
                <FontAwesomeIcon icon={faList} size="lg" />
              </Button>
            </ButtonToolbar>
            <ButtonToolbar className="pull-right">
              <Button
                disabled={activePage === 0 || fetching}
                className="spacer-right"
                onClick={prevPage}
              >Prev
              </Button>
              <DropdownButton className="spacer-right" title={`Page ${activePage + 1}`}>
                {_range(0, totalPaginatedPages).map(value => (
                  <Dropdown.Item
                    active={value === pageLimit}
                    key={value}
                    eventKey={value}
                    onClick={() => setPage(value)}
                  >
                    {value + 1}
                  </Dropdown.Item>
                ))}
              </DropdownButton>
              <Button
                disabled={((
                  activePage === (totalPaginatedPages - 1) ||
                  // No need to show the next button if there are no more printers to show
                  totalItems === 0
                )) || fetching}
                onClick={nextPage}
              >Next
              </Button>
            </ButtonToolbar>
          </div>
        </Col>
      </Row>
    );
  };

  return (
    <Container fluid>
      <BreadcrumbNav breadcrumbs={['inventory', 'materials']} />

      <Row>
        <Col xs={12}>
          <AddFromDB
            type="material"
            buttonText={(
              <FormattedMessage
                id="record.material.add"
                defaultMessage="Add Material"
              />
            )}
            modalTitle={(
              <FormattedMessage
                id="materialdb.search"
                defaultMessage="Material Search"
              />
            )}
            manualFunction={() => setIsCreateMaterialTypeModalShown(true)}
            itemLabel={<FormattedMessage id="field.material" defaultMessage="Material" />}
            manualCreateHref={getRouteURI(ROUTES.MATERIAL_NEW)}
            manualCreateText={(
              <FormattedMessage
                id="materialdb.notlisted"
                defaultMessage="My material isn’t listed"
              />
            )}
            className="pull-right"
          />
        </Col>
      </Row>

      <hr />

      <Row>
        <Col xs={12}>
          {fetching ? (
            <Loading />
          ) : (
            <>
              {isCardsView ? (
                <Container>
                  <MaterialsCards
                    printerTypesFetching={printerTypesFetching}
                    groupedMaterials={groupedMaterials}
                    manufacturersByUri={manufacturersByUri}
                    printerTypes={printerTypes}
                    renderHeaderView={renderHeaderView}
                    handleShowPrinterTypeModal={handleShowPrinterTypeModal}
                  />
                </Container>
              ) : (
                <MaterialsTable
                  materials={materials}
                  externalMaterialDbFeatureEnabled={externalMaterialDbFeatureEnabled}
                  renderHeaderView={renderHeaderView}
                />
              )}
            </>
          )}
        </Col>
      </Row>
    </Container>
  );
};

Materials.propTypes = {
  fetching: PropTypes.bool.isRequired,
  materials: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  externalMaterialDbFeatureEnabled: PropTypes.bool,
  setIsCreateMaterialTypeModalShown: PropTypes.func.isRequired,
  isCardsView: PropTypes.bool.isRequired,
  groupedMaterials: PropTypes.shape({}).isRequired,
  manufacturersByUri: PropTypes.shape({}).isRequired,
  printerTypes: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  printerTypesFetching: PropTypes.bool.isRequired,
  filters: PropTypes.shape({
    globalFilter: PropTypes.string,
    setGlobalFilter: PropTypes.func,
  }).isRequired,
  viewModeProps: PropTypes.shape({
    viewMode: PropTypes.string,
    setViewMode: PropTypes.func,
  }).isRequired,
  pagination: PropTypes.shape({
    activePage: PropTypes.number,
    setPage: PropTypes.func,
    pageLimit: PropTypes.number,
    totalItems: PropTypes.number,
    nextPage: PropTypes.func,
    prevPage: PropTypes.func,
    totalPaginatedPages: PropTypes.number,
  }).isRequired,
  handleShowPrinterTypeModal: PropTypes.func.isRequired,
};

Materials.defaultProps = {
  externalMaterialDbFeatureEnabled: false,
};

export default Materials;
