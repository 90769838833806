import MaterialTypeModal from 'rapidfab/components/modals/MaterialTypeModal';
import { MATERIAL_TYPE_SECTION_FIELDS_LIST } from 'rapidfab/mappings';
import {
  handleGetMissingRequiredFieldsForSection,
  validateRequiredField,
} from 'rapidfab/utils/formHelpers';
import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import Actions from 'rapidfab/actions';
import {
  FEATURES,
  MATERIAL_UNITS_MEASUREMENT_CORE,
  MATERIAL_UNITS_BY_UNITS_MEASUREMENT_CORE,
  PAGINATION_IGNORE_DEFAULT_LIMIT,
  ROUTES,
  DEFAULT_MATERIAL_TYPE_COLOR,
  API_RESOURCES,
  manufacturerDefaultPayload,
  MATERIAL_TYPE_REQUIRED_FIELD_TITLES,
} from 'rapidfab/constants';
import * as Selectors from 'rapidfab/selectors';
import _differenceBy from 'lodash/differenceBy';
import _filter from 'lodash/filter';
import _uniq from 'lodash/uniq';
import { getRouteURI } from 'rapidfab/utils/uriUtils';
import { extractUuid } from 'rapidfab/utils/uuidUtils';
import _get from 'lodash/get';
import _set from 'lodash/set';
import _isEmpty from 'lodash/isEmpty';
import _map from 'lodash/map';
import _flatMap from 'lodash/flatMap';

import { MATERIAL_CONTAINER } from 'rapidfab/constants/forms';
import { useNavigate, useSearchParams } from 'react-router-dom';
import Alert from 'rapidfab/utils/alert-new';
import { FormattedMessage } from 'react-intl';

const initialGroupedSectionsVisibilityState = {
  general: true,
  typesCost: true,
  advanced: true,
};

const MaterialTypeModalContainer = props => {
  const bureauUri = useSelector(Selectors.getBureauUri);
  const [searchParams] = useSearchParams();
  const { externalUUID } = props;
  const uuid = externalUUID || searchParams.get('uuid');
  const material = useSelector(state => Selectors.getUUIDResource(state, uuid));
  const defaultCurrency = useSelector(Selectors.getBureauDefaultCurrency);
  const printerTypes = useSelector(Selectors.getPrinterTypes);
  const printerTypesWithMaterial = useSelector(state =>
    Selectors.getPrinterTypesWithMaterialForMaterial(state, material));
  const stocks = useSelector(state => Selectors.getStocksForMaterial(state, material));
  const materialsByUri = useSelector(Selectors.getMaterialsByUri);
  const locationsByUri = useSelector(Selectors.getLocationsByUri);
  const submitting = useSelector(state =>
    state.ui.nautilus[API_RESOURCES.MATERIAL].put.fetching
    || state.ui.nautilus[API_RESOURCES.MATERIAL].post.fetching);
  const isManufacturerFetching = useSelector(state => state.ui.nautilus[API_RESOURCES.MANUFACTURER].post.fetching);
  const manufacturers = useSelector(Selectors.getManufacturers);
  const materialTestOperations = useSelector(Selectors.getMaterialTestOperations);
  const materialTestOperationLinkings = useSelector(Selectors.getMaterialTestOperationLinkings);
  const materialTestInstructions = useSelector(Selectors.getMaterialTestInstructions);
  const materialTestInstructionsByUri = useSelector(Selectors.getMaterialTestInstructionsByUri);
  const materialTestUnitsByUri = useSelector(Selectors.getMaterialTestUnitsByUri);
  const fromTemplate = uuid && !!material?.base_template;
  const navigate = useNavigate();
  const isMaterialTestPanelFeatureEnabled = useSelector(state => Selectors.isFeatureEnabled(
    state, FEATURES.MATERIAL_TEST_PANEL,
  ));

  const fetching = useSelector(state => state.ui.nautilus[API_RESOURCES.MATERIAL].get.fetching
    || state.ui.nautilus[API_RESOURCES.PRINTER_TYPE].list.fetching
    || state.ui.nautilus[API_RESOURCES.LOCATION].list.fetching
    || state.ui.nautilus[API_RESOURCES.MANUFACTURER].list.fetching
    || state.ui.nautilus[API_RESOURCES.MATERIAL_TEST_UNIT].list.fetching
    || state.ui.nautilus[API_RESOURCES.MATERIAL_TEST_OPERATION].list.fetching
    || state.ui.nautilus[API_RESOURCES.MATERIAL_TEST_INSTRUCTION].list.fetching
    || state.ui.nautilus[API_RESOURCES.MATERIAL_TEST_OPERATION_LINKING].list.fetching);

  const deleting = useSelector(state => state.ui.nautilus[API_RESOURCES.MATERIAL].delete.fetching);

  const externalMaterialDatabaseFeatureEnabled = useSelector(state => Selectors.isFeatureEnabled(
    state, FEATURES.EXTERNAL_MATERIAL_DB,
  ));
  const materialManagementEnabled = useSelector(state =>
    Selectors.isFeatureEnabled(state, FEATURES.MATERIAL_MANAGEMENT));
  const isPOCUKOrderFieldsFeatureEnabled = useSelector(
    state => Selectors.isFeatureEnabled(state, FEATURES.POC_UK_ORDER_FIELDS),
  );

  const [printerTypesSelected, setPrinterTypesSelected] = useState([]);

  useEffect(() => {
    setPrinterTypesSelected(printerTypesWithMaterial.map(pt => pt.uri));
  }, [printerTypesWithMaterial]);

  const initialFormValues = {
    color: DEFAULT_MATERIAL_TYPE_COLOR,
    color_opacity: 1,
    ...material,
  };

  MATERIAL_CONTAINER.NULL_FIELDS.forEach(
    fieldName => {
      if (initialFormValues[fieldName] === null) {
        initialFormValues[fieldName] = '';
      }
    },
  );

  MATERIAL_CONTAINER.FLOAT_FIELDS.forEach(
    fieldName => {
      if (initialFormValues[fieldName]) {
        initialFormValues[fieldName] = String(initialFormValues[fieldName]);
      }
    },
  );

  const initialValues = {};
  Object
    .keys(initialFormValues)
    .filter(key => MATERIAL_CONTAINER.FIELDS.includes(key))
    .forEach(key => {
      initialValues[key] = initialFormValues[key];
    });

  const [isMaterialTestSelected, setIsMaterialTestSelected] = useState(false);
  const [selectedMaterialTestOperations, setSelectedMaterialTestOperations] = useState([]);
  const [shouldValidateDensity, setShouldValidateDensity] = useState(false);

  const [groupedSectionsVisibilityState, setGroupedSectionsVisibilityState] =
    useState(initialGroupedSectionsVisibilityState);

  const [sectionsWithMissingFields, setSectionsWithMissingFields] = useState({});

  const handleCloseModal = () => {
    if (props.isVisible) {
      return props.hideModal();
    }
    return navigate(getRouteURI(ROUTES.MATERIALS, null, null, true), { replace: true });
  };

  const selected = {
    uuid,
    initialFormValues: initialValues,
    material,
    stocks,
    locationsByUri,
    materialsByUri,
    printerTypes,
    printerTypesWithMaterial,
    submitting,
    manufacturers,
    fromTemplate,
    externalMaterialDbFeatureEnabled: externalMaterialDatabaseFeatureEnabled,
    defaultCurrency,
    materialManagementEnabled,
    materialTestOperations,
    materialTestOperationLinkings,
    bureauUri,
    isMaterialTestPanelFeatureEnabled,
    materialTestInstructions,
    materialTestInstructionsByUri,
    materialTestUnitsByUri,
    isManufacturerFetching,
    handleCloseModal,
    sectionsWithMissingFields,
    groupedSectionsVisibilityState,
    fetching,
    deleting,
    printerTypesSelected,
  };

  const dispatch = useDispatch();

  const redirectToNewMaterial = uri => {
    navigate(getRouteURI(ROUTES.MATERIALS, null, { uuid: extractUuid(uri) }, true), { replace: true });
  };

  const onInitializeMaterialTestLinkingsAndOperations = (materialUri = null) => {
    if (isMaterialTestPanelFeatureEnabled) {
      dispatch(Actions.Api.nautilus[API_RESOURCES.MATERIAL_TEST_UNIT].list());
      dispatch(Actions.Api.nautilus[API_RESOURCES.MATERIAL_TEST_OPERATION_LINKING].list({
        related_uri: [materialUri, bureauUri].filter(Boolean),
      })).then(materialTestOperationLinkingResponse => {
        const responseOperationLinkings = materialTestOperationLinkingResponse.json?.resources;
        if (_isEmpty(responseOperationLinkings)) {
          return;
        }

        const selectedOperations = new Set(responseOperationLinkings.filter(
          linking => linking.related_uri === materialUri,
        ).map(
          linking => linking.material_test_operation,
        ));
        setSelectedMaterialTestOperations(
          responseOperationLinkings.filter(
            linking => linking.related_uri === bureauUri && selectedOperations.has(linking.material_test_operation),
          ),
        );
        setIsMaterialTestSelected(selectedOperations.size > 0);
        dispatch(Actions.Api.nautilus[API_RESOURCES.MATERIAL_TEST_OPERATION].list({
          uri: _map(responseOperationLinkings, 'material_test_operation'),
        }));
        dispatch(Actions.Api.nautilus[API_RESOURCES.MATERIAL_TEST_INSTRUCTION].list({
          uri: _flatMap(responseOperationLinkings, 'material_test_instructions'),
        }, { limit: PAGINATION_IGNORE_DEFAULT_LIMIT }));
      });
    }
  };

  const onInitialize = currentUUID => {
    dispatch(Actions.Api.nautilus[API_RESOURCES.MANUFACTURER].list());
    dispatch(Actions.Api.nautilus[API_RESOURCES.PRINTER_TYPE].list());

    dispatch(Actions.Api.nautilus[API_RESOURCES.LOCATION].list({}, { limit: PAGINATION_IGNORE_DEFAULT_LIMIT }));
    if (currentUUID) {
      dispatch(Actions.Api.nautilus[API_RESOURCES.MATERIAL].get(currentUUID))
        .then(materialResponse => {
          const { uri } = materialResponse.json;
          dispatch(Actions.Api.nautilus[API_RESOURCES.STOCK].list(
            { material: uri },
            { limit: PAGINATION_IGNORE_DEFAULT_LIMIT },
          ));
          onInitializeMaterialTestLinkingsAndOperations(uri);
        });
    } else {
      onInitializeMaterialTestLinkingsAndOperations();
    }
  };

  const validateMissingFormFields = ({
    name,
    description,
    manufacturer,
    cost,
    type,
    density,
    units,
  }) => {
    const errors = {
      name: validateRequiredField(name, MATERIAL_CONTAINER.FIELDS.name),
      description: validateRequiredField(description, MATERIAL_CONTAINER.FIELDS.description),
      manufacturer: validateRequiredField(manufacturer, MATERIAL_CONTAINER.FIELDS.manufacturer),
      cost: validateRequiredField(cost, MATERIAL_CONTAINER.FIELDS.cost),
      type: validateRequiredField(type, MATERIAL_CONTAINER.FIELDS.type),
      units: validateRequiredField(units, MATERIAL_CONTAINER.FIELDS.units),
    };

    // Conditionally rendered field
    const densityError = validateRequiredField(density, MATERIAL_CONTAINER.FIELDS.density);

    if (shouldValidateDensity) {
      errors.density = densityError;
    }

    return {
      someFieldsNotValidated: Object.values(errors).some(Boolean),
      errors,
    };
  };
  const handleValidationErrors = errors => {
    const { missingRequiredFieldsBySections, sectionsToExpand } =
      handleGetMissingRequiredFieldsForSection(
        errors,
        MATERIAL_TYPE_SECTION_FIELDS_LIST,
        MATERIAL_TYPE_REQUIRED_FIELD_TITLES,
        'general',
      );

    // Update the state with sections that have missing fields
    setSectionsWithMissingFields(missingRequiredFieldsBySections);

    // Update the state to expand the sections that have required fields empty
    setGroupedSectionsVisibilityState(prevState => ({
      ...prevState,
      ...sectionsToExpand,
    }));
  };

  // eslint-disable-next-line no-shadow
  const onFormSubmit = ({ printer_types_selected, ...payload }) => {
    if (_isEmpty(selectedMaterialTestOperations) && isMaterialTestSelected) {
      // alerts error when user checks material test but doesnt include any operations. This causes
      // issues with some of the request in this submit function
      Alert.error('You have no material operations selected. Please select an operation to use material test otherwise deselect material test.');
      return;
    }
    const validatedPayload = { ...payload,
      third_party_fulfillment: payload.third_party_fulfillment
        ? payload.third_party_fulfillment
        : false };

    if (
      validatedPayload.density &&
      !MATERIAL_UNITS_BY_UNITS_MEASUREMENT_CORE[MATERIAL_UNITS_MEASUREMENT_CORE.WEIGHT]
        .includes(validatedPayload.units)
    ) {
      // Density is needed for Weight Units only
      // Clear density for Volume Units if one was set previously
      validatedPayload.density = null;
    }
    MATERIAL_CONTAINER.FLOAT_FIELDS.forEach(
      fieldName => {
        const field = _get(payload, fieldName);
        if (field) {
          _set(validatedPayload, fieldName, Number.parseFloat(field));
        }
      },
    );

    MATERIAL_CONTAINER.NULL_FIELDS.forEach(fieldName => {
      if (payload[fieldName] === '' || payload[fieldName] === undefined) {
        validatedPayload[fieldName] = null;
      }
    });

    MATERIAL_CONTAINER.STRING_FIELDS.forEach(
      fieldName => {
        const field = _get(payload, fieldName);
        if (!field) {
          _set(payload, fieldName, '');
        }
      },
    );

    validatedPayload.bureau = bureauUri;
    if (!payload.color && payload.uuid) {
      delete validatedPayload.color;
    }

    const materialPromise = payload.uuid
      ? dispatch(Actions.Api.nautilus[API_RESOURCES.MATERIAL].put(payload.uuid, validatedPayload))
      : dispatch(Actions.Api.nautilus[API_RESOURCES.MATERIAL].post(validatedPayload));

    materialPromise.then(response => {
      const materialUri = response.payload.uri;

      const materialTestOperationPayloads = selectedMaterialTestOperations.map(selectedOperation => ({
        material_test_operation: selectedOperation.material_test_operation,
      }));
      if (isMaterialTestPanelFeatureEnabled) {
        if (isMaterialTestSelected) {
          // for the PUT method, it is forbidden to transfer an empty payload
          dispatch(Actions.Api.nautilus[API_RESOURCES.MATERIAL_TEST_OPERATION_WORK_CHECKLISTS_FOR_MATERIAL]
            .put(extractUuid(materialUri),
              materialTestOperationPayloads.length ? materialTestOperationPayloads : [{}]),
          )
            .then(() => {
              /* Refresh the test operation linkings once they've has been saved. */
              dispatch(Actions.Api.nautilus[API_RESOURCES.MATERIAL_TEST_OPERATION_LINKING].clear());
              dispatch(Actions.Api.nautilus[API_RESOURCES.MATERIAL_TEST_OPERATION_LINKING].list({
                related_uri: [materialUri, bureauUri].filter(Boolean),
              }));

              // This takes the payload and creates an list of operations needed to dispatch into redux state.
              // and request the newly created records so that can appear properly
              const materialTestOperationUrisForInstruction = materialTestOperationPayloads
                .map(({ material_test_operation }) => material_test_operation);

              dispatch(Actions.Api.nautilus[API_RESOURCES.MATERIAL_TEST_INSTRUCTION]
                .list({ material_test_operation: [materialTestOperationUrisForInstruction] }));
            });
        } else {
          dispatch(Actions.Api.nautilus[API_RESOURCES.MATERIAL_TEST_OPERATION_WORK_CHECKLISTS_FOR_MATERIAL]
            .put(extractUuid(materialUri), [{}]),
          )
            .then(() => {
              /* Refresh the test operation linkings once they've has been saved. */
              dispatch(Actions.Api.nautilus[API_RESOURCES.MATERIAL_TEST_OPERATION_LINKING].clear());
              dispatch(Actions.Api.nautilus[API_RESOURCES.MATERIAL_TEST_OPERATION_LINKING].list({
                related_uri: [materialUri, bureauUri].filter(Boolean),
              }))
                .then(materialTestOperationLinkingResponse => {
                  const responseOperationLinkings = materialTestOperationLinkingResponse.json?.resources;
                  if (_isEmpty(responseOperationLinkings)) {
                    return;
                  }

                  const selectedOperations = new Set(responseOperationLinkings.filter(
                    linking => linking.related_uri === materialUri,
                  )
                    .map(
                      linking => linking.material_test_operation,
                    ));
                  setSelectedMaterialTestOperations(
                    responseOperationLinkings.filter(
                      linking =>
                        linking.related_uri === bureauUri && selectedOperations.has(linking.material_test_operation),
                    ),
                  );
                  setIsMaterialTestSelected(selectedOperations.size > 0);
                  dispatch(Actions.Api.nautilus[API_RESOURCES.MATERIAL_TEST_OPERATION].list({
                    uri: _map(responseOperationLinkings, 'material_test_operation'),
                  }));
                  dispatch(Actions.Api.nautilus[API_RESOURCES.MATERIAL_TEST_INSTRUCTION].list({
                    uri: _flatMap(responseOperationLinkings, 'material_test_instructions'),
                  }, { limit: PAGINATION_IGNORE_DEFAULT_LIMIT }));
                });
            });
        }
      }

      if (printer_types_selected) {
        const selectedPrinterTypes = printer_types_selected.map(uri => printerTypes.find(pt => pt.uri === uri));
        const removedPrinterTypes = _differenceBy(printerTypesWithMaterial, selectedPrinterTypes, 'uri');
        // add material to selected printer types
        Promise.all(selectedPrinterTypes.map(printer_type =>
          dispatch(Actions.Api.nautilus[API_RESOURCES.PRINTER_TYPE].put(printer_type.uuid, {
            materials: _uniq([...printer_type.materials, materialUri]),
          })),
        ));

        // remove material from printer type if the printer type is unchecked
        Promise.all(removedPrinterTypes.map(printer_type =>
          dispatch(Actions.Api.nautilus[API_RESOURCES.PRINTER_TYPE].put(printer_type.uuid, {
            materials: _filter(printer_type.materials, uri => uri !== materialUri),
          })),
        ));
      }
      if (!payload.uuid) {
        redirectToNewMaterial(materialUri);
      }
    }).then(response => {
      Alert.success(<FormattedMessage
        id="toaster.materialType.saved"
        defaultMessage="Material type successfully saved."
      />);
      if (response) redirectToNewMaterial(response.headers.location);
    });
  };

  const handleSubmit = values => {
    const formValidationResults = validateMissingFormFields(values);
    if (!formValidationResults.someFieldsNotValidated) {
      onFormSubmit(values);
      setSectionsWithMissingFields({});
    } else {
      handleValidationErrors(formValidationResults.errors);
    }
  };

  const onDelete = currentUUID => {
    if (currentUUID) {
      dispatch(Actions.Api.nautilus[API_RESOURCES.MATERIAL].delete(currentUUID))
        .then(() => Alert.success(
          <FormattedMessage
            id="toaster.material.deleted"
            defaultMessage="Material successfully deleted"
          />,
        ))
        .then(handleCloseModal);
    }
  };

  const handleSubmitManufacturer = async name => {
    if (!name) {
      return null;
    }

    const payload = { name, ...manufacturerDefaultPayload };
    const manufacturerResponse = await dispatch(Actions.Api.nautilus[API_RESOURCES.MANUFACTURER].post(payload));
    const manufacturerUri = manufacturerResponse?.headers?.location;

    if (manufacturerUri) {
      await dispatch(Actions.Api.nautilus[API_RESOURCES.MANUFACTURER].list());
      Alert.success(
        <FormattedMessage
          id="toaster.manufacturer.created"
          defaultMessage="Manufacturer successfully created."
        />,
      );
      return manufacturerUri;
    }

    return null;
  };

  const handleSetSectionVisibilityState = section => {
    if (!section) return;
    setGroupedSectionsVisibilityState(previous => ({
      ...previous,
      [section]: !previous[section],
    }));
  };

  useEffect(() => onInitialize(uuid), [uuid]);

  useEffect(() => {
    if (uuid) {
      setGroupedSectionsVisibilityState({
        general: true,
        buildPacking: false,
        costPricing: false,
        advanced: false,
      });
    } else {
      setGroupedSectionsVisibilityState(initialGroupedSectionsVisibilityState);
    }
  }, [uuid]);

  return (
    <MaterialTypeModal
      {...props}
      {...selected}
      handleSubmitManufacturer={handleSubmitManufacturer}
      handleSetSectionVisibilityState={handleSetSectionVisibilityState}
      setShouldValidateDensity={setShouldValidateDensity}
      setPrinterTypesSelected={setPrinterTypesSelected}
      onDelete={onDelete}
      onFormSubmit={handleSubmit}
      materialTestState={{
        isMaterialTestSelected,
        setIsMaterialTestSelected,
        selectedMaterialTestOperations,
        setSelectedMaterialTestOperations,
      }}
      isPOCUKOrderFieldsFeatureEnabled={isPOCUKOrderFieldsFeatureEnabled}
    />
  );
};

MaterialTypeModalContainer.defaultProps = {
  uuid: null,
  fromTemplate: false,
  externalUUID: null,
};

MaterialTypeModalContainer.propTypes = {
  uuid: PropTypes.string,
  fromTemplate: PropTypes.bool,
  isVisible: PropTypes.bool.isRequired,
  hideModal: PropTypes.func.isRequired,
  externalUUID: PropTypes.string,
};

export default MaterialTypeModalContainer;
