import Api, { RESOURCES } from 'rapidfab/api';
import { makeApiActions } from './makeApiActions';

import * as DownloadModel from './downloadModel';
import * as LocationFilter from './locationFilter';
import * as TraceabilityReportFilters from './traceabilityReportFilters';
import * as SubmittedOrders from './submittedOrders';
import * as DynamicCurrency from './dynamicCurrency';
import * as ModelRotation from './modelRotation';
import * as EventStream from './eventStream';
import * as I18n from './i18n';
import * as UploadModel from './uploadModel';
import * as FileLoader from './fileLoader';
import * as CreateRunProgress from './createRunProgress';
import * as Url from './url';
import * as Preferences from './preferences';
import * as UI from './ui';
import * as Pager from './pager';
import * as RouteUUID from './routeUUID';
import * as Units from './units';
import * as Admin from './admin';
import * as DebugModeUserInfo from './debugModeUserInfo';

const Actions = {
  Api: makeApiActions(Api, RESOURCES),
  DownloadModel,
  EventStream,
  I18n,
  LocationFilter,
  TraceabilityReportFilters,
  SubmittedOrders,
  DynamicCurrency,
  ModelRotation,
  Pager,
  UploadModel,
  CreateRunProgress,
  FileLoader,
  Url,
  Preferences,
  UI,
  RouteUUID,
  Units,
  Admin,
  DebugModeUserInfo,
};

export default Actions;
