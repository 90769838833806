import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import Actions from 'rapidfab/actions';
import {
  FEATURES,
  MATERIAL_UNITS_MEASUREMENT_CORE,
  MATERIAL_UNITS_BY_UNITS_MEASUREMENT_CORE,
  PAGINATION_IGNORE_DEFAULT_LIMIT,
  ROUTES,
  DEFAULT_MATERIAL_TYPE_COLOR, API_RESOURCES, manufacturerDefaultPayload,
} from 'rapidfab/constants';
import * as Selectors from 'rapidfab/selectors';
import MaterialForm from 'rapidfab/components/records/material';
import _differenceBy from 'lodash/differenceBy';
import _filter from 'lodash/filter';
import _uniq from 'lodash/uniq';
import { getRouteURI } from 'rapidfab/utils/uriUtils';
import { extractUuid } from 'rapidfab/utils/uuidUtils';
import _get from 'lodash/get';
import _set from 'lodash/set';
import _isEmpty from 'lodash/isEmpty';
import _map from 'lodash/map';
import _flatMap from 'lodash/flatMap';

import { MATERIAL_CONTAINER } from 'rapidfab/constants/forms';
import { useNavigate } from 'react-router-dom';
import Alert from 'rapidfab/utils/alert';
import { FormattedMessage } from 'react-intl';

const MaterialContainer = props => {
  const material = useSelector(state => Selectors.getInitialValuesBureau(state, props));
  const bureauUri = useSelector(Selectors.getBureauUri);
  const uuid = useSelector(Selectors.getRouteUUID);
  const defaultCurrency = useSelector(Selectors.getBureauDefaultCurrency);
  const printerTypes = useSelector(Selectors.getPrinterTypes);
  const printerTypesWithMaterial = useSelector(state => Selectors.getPrinterTypesWithMaterial(state, props));
  const stocks = useSelector(state => Selectors.getStocksForMaterial(state, material));
  const materialsByUri = useSelector(Selectors.getMaterialsByUri);
  const locationsByUri = useSelector(Selectors.getLocationsByUri);
  const submitting = useSelector(state => Selectors.getResourceFetching(state, 'nautilus.material'));
  const isManufacturerFetching = useSelector(state => state.ui.nautilus[API_RESOURCES.MANUFACTURER].post.fetching);
  const manufacturers = useSelector(Selectors.getManufacturers);
  const materialTestOperations = useSelector(Selectors.getMaterialTestOperations);
  const materialTestOperationLinkings = useSelector(Selectors.getMaterialTestOperationLinkings);
  const materialTestInstructions = useSelector(Selectors.getMaterialTestInstructions);
  const materialTestInstructionsByUri = useSelector(Selectors.getMaterialTestInstructionsByUri);
  const materialTestUnitsByUri = useSelector(Selectors.getMaterialTestUnitsByUri);
  const fromTemplate = uuid && !!material.base_template;
  const navigate = useNavigate();
  const isMaterialTestPanelFeatureEnabled = useSelector(state => Selectors.isFeatureEnabled(
    state, FEATURES.MATERIAL_TEST_PANEL,
  ));
  const externalMaterialDatabaseFeatureEnabled = useSelector(state => Selectors.isFeatureEnabled(
    state, FEATURES.EXTERNAL_MATERIAL_DB,
  ));
  const materialManagementEnabled = useSelector(state =>
    Selectors.isFeatureEnabled(state, FEATURES.MATERIAL_MANAGEMENT));
  const isPOCUKOrderFieldsFeatureEnabled = useSelector(
    state => Selectors.isFeatureEnabled(state, FEATURES.POC_UK_ORDER_FIELDS),
  );

  const initialFormValues = {
    color: DEFAULT_MATERIAL_TYPE_COLOR,
    color_opacity: 1,
    ...material,
  };

  MATERIAL_CONTAINER.NULL_FIELDS.forEach(
    fieldName => {
      if (initialFormValues[fieldName] === null) {
        initialFormValues[fieldName] = '';
      }
    },
  );

  MATERIAL_CONTAINER.FLOAT_FIELDS.forEach(
    fieldName => {
      if (initialFormValues[fieldName]) {
        initialFormValues[fieldName] = String(initialFormValues[fieldName]);
      }
    },
  );

  const initialValues = {};
  Object
    .keys(initialFormValues)
    .filter(key => MATERIAL_CONTAINER.FIELDS.includes(key))
    .forEach(key => {
      initialValues[key] = initialFormValues[key];
    });

  const [isMaterialTestSelected, setIsMaterialTestSelected] = useState(false);
  const [selectedMaterialTestOperations, setSelectedMaterialTestOperations] = useState([]);

  const selected = {
    uuid,
    initialFormValues: initialValues,
    material,
    stocks,
    locationsByUri,
    materialsByUri,
    printerTypes,
    printerTypesWithMaterial,
    submitting,
    manufacturers,
    fromTemplate,
    externalMaterialDbFeatureEnabled: externalMaterialDatabaseFeatureEnabled,
    defaultCurrency,
    materialManagementEnabled,
    materialTestOperations,
    materialTestOperationLinkings,
    bureauUri,
    isMaterialTestPanelFeatureEnabled,
    materialTestInstructions,
    materialTestInstructionsByUri,
    materialTestUnitsByUri,
    isManufacturerFetching,
  };

  const dispatch = useDispatch();

  const redirect = () => navigate(getRouteURI(ROUTES.MATERIALS, {}, {}, true));

  const redirectToNewMaterial = uri => {
    window.location = getRouteURI(ROUTES.MATERIALS, null, { uuid: extractUuid(uri) }, true);
  };

  const onInitializeMaterialTestLinkingsAndOperations = (materialUri = null) => {
    if (isMaterialTestPanelFeatureEnabled) {
      dispatch(Actions.Api.nautilus[API_RESOURCES.MATERIAL_TEST_UNIT].list());
      dispatch(Actions.Api.nautilus[API_RESOURCES.MATERIAL_TEST_OPERATION_LINKING].list({
        related_uri: [materialUri, bureauUri].filter(Boolean),
      })).then(materialTestOperationLinkingResponse => {
        const responseOperationLinkings = materialTestOperationLinkingResponse.json?.resources;
        if (_isEmpty(responseOperationLinkings)) {
          return;
        }

        const selectedOperations = new Set(responseOperationLinkings.filter(
          linking => linking.related_uri === materialUri,
        ).map(
          linking => linking.material_test_operation,
        ));
        setSelectedMaterialTestOperations(
          responseOperationLinkings.filter(
            linking => linking.related_uri === bureauUri && selectedOperations.has(linking.material_test_operation),
          ),
        );
        setIsMaterialTestSelected(selectedOperations.size > 0);
        dispatch(Actions.Api.nautilus[API_RESOURCES.MATERIAL_TEST_OPERATION].list({
          uri: _map(responseOperationLinkings, 'material_test_operation'),
        }));
        dispatch(Actions.Api.nautilus[API_RESOURCES.MATERIAL_TEST_INSTRUCTION].list({
          uri: _flatMap(responseOperationLinkings, 'material_test_instructions'),
        }, { limit: PAGINATION_IGNORE_DEFAULT_LIMIT }));
      });
    }
  };

  const onInitialize = currentUUID => {
    dispatch(Actions.Api.nautilus[API_RESOURCES.MANUFACTURER].list());
    dispatch(Actions.Api.nautilus[API_RESOURCES.PRINTER_TYPE].list());

    dispatch(Actions.Api.nautilus[API_RESOURCES.LOCATION].list({}, { limit: PAGINATION_IGNORE_DEFAULT_LIMIT }));
    if (currentUUID) {
      dispatch(Actions.Api.nautilus[API_RESOURCES.MATERIAL].get(currentUUID))
        .then(materialResponse => {
          const { uri } = materialResponse.json;
          dispatch(Actions.Api.nautilus[API_RESOURCES.STOCK].list(
            { material: uri },
            { limit: PAGINATION_IGNORE_DEFAULT_LIMIT },
          ));
          onInitializeMaterialTestLinkingsAndOperations(uri);
        });
    } else {
      onInitializeMaterialTestLinkingsAndOperations();
    }
  };

  // eslint-disable-next-line no-shadow
  const onFormSubmit = ({ printer_types_selected, ...payload }) => {
    if (_isEmpty(selectedMaterialTestOperations) && isMaterialTestSelected) {
      // alerts error when user checks material test but doesnt include any operations. This causes
      // issues with some of the request in this submit function
      Alert.error('You have no material operations selected. Please select an operation to use material test otherwise deselect material test.');
      return;
    }
    const validatedPayload = { ...payload,
      third_party_fulfillment: payload.third_party_fulfillment
        ? payload.third_party_fulfillment
        : false };

    if (
      validatedPayload.density &&
      !MATERIAL_UNITS_BY_UNITS_MEASUREMENT_CORE[MATERIAL_UNITS_MEASUREMENT_CORE.WEIGHT]
        .includes(validatedPayload.units)
    ) {
      // Density is needed for Weight Units only
      // Clear density for Volume Units if one was set previously
      validatedPayload.density = null;
    }
    MATERIAL_CONTAINER.FLOAT_FIELDS.forEach(
      fieldName => {
        const field = _get(payload, fieldName);
        if (field) {
          _set(validatedPayload, fieldName, Number.parseFloat(field));
        }
      },
    );

    MATERIAL_CONTAINER.NULL_FIELDS.forEach(fieldName => {
      if (payload[fieldName] === '' || payload[fieldName] === undefined) {
        validatedPayload[fieldName] = null;
      }
    });

    MATERIAL_CONTAINER.STRING_FIELDS.forEach(
      fieldName => {
        const field = _get(payload, fieldName);
        if (!field) {
          _set(payload, fieldName, '');
        }
      },
    );
    const materialPromise = payload.uuid
      ? dispatch(Actions.Api.nautilus[API_RESOURCES.MATERIAL].put(payload.uuid, validatedPayload))
      : dispatch(Actions.Api.nautilus[API_RESOURCES.MATERIAL].post(validatedPayload));

    materialPromise.then(response => {
      const materialUri = response.payload.uri;

      const materialTestOperationPayloads = selectedMaterialTestOperations.map(selectedOperation => ({
        material_test_operation: selectedOperation.material_test_operation,
      }));
      if (isMaterialTestPanelFeatureEnabled) {
        if (isMaterialTestSelected) {
          // for the PUT method, it is forbidden to transfer an empty payload
          dispatch(Actions.Api.nautilus[API_RESOURCES.MATERIAL_TEST_OPERATION_WORK_CHECKLISTS_FOR_MATERIAL]
            .put(extractUuid(materialUri),
              materialTestOperationPayloads.length ? materialTestOperationPayloads : [{}]),
          )
            .then(() => {
              /* Refresh the test operation linkings once they've has been saved. */
              dispatch(Actions.Api.nautilus[API_RESOURCES.MATERIAL_TEST_OPERATION_LINKING].clear());
              dispatch(Actions.Api.nautilus[API_RESOURCES.MATERIAL_TEST_OPERATION_LINKING].list({
                related_uri: [materialUri, bureauUri].filter(Boolean),
              }));

              // This takes the payload and creates an list of operations needed to dispatch into redux state.
              // and request the newly created records so that can appear properly
              const materialTestOperationUrisForInstruction = materialTestOperationPayloads
                .map(({ material_test_operation }) => material_test_operation);

              dispatch(Actions.Api.nautilus[API_RESOURCES.MATERIAL_TEST_INSTRUCTION]
                .list({ material_test_operation: [materialTestOperationUrisForInstruction] }));
            });
        } else {
          dispatch(Actions.Api.nautilus[API_RESOURCES.MATERIAL_TEST_OPERATION_WORK_CHECKLISTS_FOR_MATERIAL]
            .put(extractUuid(materialUri), [{}]),
          )
            .then(() => {
              /* Refresh the test operation linkings once they've has been saved. */
              dispatch(Actions.Api.nautilus[API_RESOURCES.MATERIAL_TEST_OPERATION_LINKING].clear());
              dispatch(Actions.Api.nautilus[API_RESOURCES.MATERIAL_TEST_OPERATION_LINKING].list({
                related_uri: [materialUri, bureauUri].filter(Boolean),
              }))
                .then(materialTestOperationLinkingResponse => {
                  const responseOperationLinkings = materialTestOperationLinkingResponse.json?.resources;
                  if (_isEmpty(responseOperationLinkings)) {
                    return;
                  }

                  const selectedOperations = new Set(responseOperationLinkings.filter(
                    linking => linking.related_uri === materialUri,
                  )
                    .map(
                      linking => linking.material_test_operation,
                    ));
                  setSelectedMaterialTestOperations(
                    responseOperationLinkings.filter(
                      linking =>
                        linking.related_uri === bureauUri && selectedOperations.has(linking.material_test_operation),
                    ),
                  );
                  setIsMaterialTestSelected(selectedOperations.size > 0);
                  dispatch(Actions.Api.nautilus[API_RESOURCES.MATERIAL_TEST_OPERATION].list({
                    uri: _map(responseOperationLinkings, 'material_test_operation'),
                  }));
                  dispatch(Actions.Api.nautilus[API_RESOURCES.MATERIAL_TEST_INSTRUCTION].list({
                    uri: _flatMap(responseOperationLinkings, 'material_test_instructions'),
                  }, { limit: PAGINATION_IGNORE_DEFAULT_LIMIT }));
                });
            });
        }
      }

      if (printer_types_selected) {
        const removedPrinterTypes = _differenceBy(printerTypesWithMaterial, printer_types_selected, 'uri');
        // add material to selected printer types
        Promise.all(printer_types_selected.map(printer_type =>
          dispatch(Actions.Api.nautilus[API_RESOURCES.PRINTER_TYPE].put(printer_type.uuid, {
            materials: _uniq([...printer_type.materials, materialUri]),
          })),
        ));

        // remove material from printer type if the printer type is unchecked
        Promise.all(removedPrinterTypes.map(printer_type =>
          dispatch(Actions.Api.nautilus[API_RESOURCES.PRINTER_TYPE].put(printer_type.uuid, {
            materials: _filter(printer_type.materials, uri => uri !== materialUri),
          })),
        ));
      }
      if (!payload.uuid) {
        navigate(getRouteURI(ROUTES.MATERIALS, null, { uuid: extractUuid(materialUri) }, true));
      }
    }).then(response => {
      Alert.success(<FormattedMessage
        id="toaster.materialType.saved"
        defaultMessage="Material type successfully saved."
      />);
      if (response) redirectToNewMaterial(response.headers.location);
    });
  };
  const onDelete = currentUUID => {
    if (currentUUID) {
      dispatch(Actions.Api.nautilus[API_RESOURCES.MATERIAL].delete(currentUUID))
        .then(() => Alert.success(
          <FormattedMessage
            id="toaster.material.deleted"
            defaultMessage="Material successfully deleted"
          />,
        ))
        .then(redirect);
    }
  };

  const handleSubmitManufacturer = async name => {
    if (!name) {
      return null;
    }

    const payload = { name, ...manufacturerDefaultPayload };
    const manufacturerResponse = await dispatch(Actions.Api.nautilus[API_RESOURCES.MANUFACTURER].post(payload));
    const manufacturerUri = manufacturerResponse?.headers?.location;

    if (manufacturerUri) {
      await dispatch(Actions.Api.nautilus[API_RESOURCES.MANUFACTURER].list());
      Alert.success(
        <FormattedMessage
          id="toaster.manufacturer.created"
          defaultMessage="Manufacturer successfully created."
        />,
      );
      return manufacturerUri;
    }

    return null;
  };

  useEffect(() => onInitialize(uuid), [uuid]);

  return (
    <MaterialForm
      {...props}
      {...selected}
      handleSubmitManufacturer={handleSubmitManufacturer}
      onDelete={onDelete}
      onFormSubmit={onFormSubmit}
      materialTestState={{
        isMaterialTestSelected,
        setIsMaterialTestSelected,
        selectedMaterialTestOperations,
        setSelectedMaterialTestOperations,
      }}
      isPOCUKOrderFieldsFeatureEnabled={isPOCUKOrderFieldsFeatureEnabled}
    />
  );
};

MaterialContainer.defaultProps = {
  uuid: null,
  fromTemplate: false,
};

MaterialContainer.propTypes = {
  uuid: PropTypes.string,
  fromTemplate: PropTypes.bool,
};

export default MaterialContainer;
