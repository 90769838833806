import Loading from 'rapidfab/components/Loading';
import OrderTemplate from 'rapidfab/components/organize/OrderTemplate';
import * as Selectors from 'rapidfab/selectors';
import { getRequiredDefaultCustomFields } from 'rapidfab/utils/lineItemUtils';
import { toISOStringWithoutTime } from 'rapidfab/utils/timeUtils';
import { extractUuid } from 'rapidfab/utils/uuidUtils';
import { useDispatch, useSelector } from 'react-redux';
import {
  getCustomOrderFieldReferences, getPermissionLocationUris,
  getRolesCurrentUser, getRouteUUID,
  getRouteUUIDResource,
  isFeatureEnabled,
} from 'rapidfab/selectors';
import {
  API_RESOURCES,
  FEATURES, MODAL_TEMPLATES, PAGINATION_IGNORE_DEFAULT_LIMIT,
  ROUTES,
  USER_ROLES,
} from 'rapidfab/constants';
import { ORDER_SUMMARY } from 'rapidfab/constants/forms';
import Actions from 'rapidfab/actions';
import { getRouteURI } from 'rapidfab/utils/uriUtils';
import { useModal } from 'rapidfab/hooks';
import React, { useCallback, useEffect } from 'react';
import Alert from 'rapidfab/utils/alert';
import { createOrReplaceArray } from 'rapidfab/utils/arrayUtils';
import getInitialCustomFieldValues from 'rapidfab/utils/getInitialCustomFieldValues';
import * as Sentry from '@sentry/react';
import { FormattedMessage } from 'rapidfab/i18n';
import { useNavigate } from 'react-router-dom';

const OrderTemplateContainer = () => {
  const uuid = useSelector(getRouteUUID);
  const order = useSelector(getRouteUUIDResource);
  const bureau = useSelector(Selectors.getBureau);

  const initialValues = {
    ...order,
  } || {};

  const isFetching = useSelector(state => state.ui.nautilus[API_RESOURCES.ORDER].get.fetching);

  const initialFormValues = {};
  Object
    .keys(initialValues)
    .filter(key => ORDER_SUMMARY.FIELDS.includes(key))
    .forEach(key => {
      initialFormValues[key] = initialValues[key];
    });

  if (order?.template_name) {
    initialFormValues.template_name = order.template_name;
  }

  // convert ISO date to yyyy-mm-dd for html input
  if (initialFormValues?.due_date) {
    const date = new Date(initialFormValues.due_date);
    initialFormValues.due_date = toISOStringWithoutTime(date);
  }

  const customOrderFieldReferences = useSelector(getCustomOrderFieldReferences) || [];
  const permissionLocationUris = useSelector(getPermissionLocationUris);

  const isOrderSubmitting = useSelector(state =>
    state.ui.nautilus[API_RESOURCES.ORDER].put.fetching || state.ui.nautilus[API_RESOURCES.ORDER].post.fetching);
  const templateDeleting = useSelector(state =>
    state.ui.nautilus[API_RESOURCES.ORDER].delete.fetching);
  const isRobozeDDWFeatureEnabled = useSelector(state => isFeatureEnabled(state, FEATURES.ROBOZE_DDW));
  const currentUserRoles = useSelector(getRolesCurrentUser);
  const [openWarningModal, WarningModal] = useModal();
  const [confirmDeleteTemplate, setConfirmDeleteTemplate] = React.useState(false);
  const [orderOwnerFetched, setOrderOwnerFetched] = React.useState(false);

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const onDelete = useCallback(currentUUID => {
    dispatch(Actions.Api.nautilus[API_RESOURCES.ORDER].delete(currentUUID))
      .then(() => {
        setConfirmDeleteTemplate(false);
        Alert.success('Template successfully deleted');
        navigate(getRouteURI(ROUTES.TEMPLATES, {}, {}, true));
      })
      .catch(error => {
        Alert.error(error);
        console.error(error);
        Sentry.captureException(error);
      });
  }, [dispatch]);

  const onCreateTemplate = (payload, showWarning) => {
    const userRoles = new Set(currentUserRoles.map(role => role.role));
    const showLocationOrderAccessWarning = !userRoles.has(USER_ROLES.MANAGER)
      && !userRoles.has(USER_ROLES.GLOBAL_USER)
      && userRoles.has(USER_ROLES.LOCATION_USER)
      && !permissionLocationUris.includes(payload.location);
    if (showWarning && showLocationOrderAccessWarning) {
      openWarningModal({
        modalTemplate: MODAL_TEMPLATES.YES_OR_NO,
        title: '',
        bodyText: <FormattedMessage
          id="message.locationOrderAccessWarning"
          defaultMessage="Order not assigned to a location that you have access to. You will no longer have access to this Order until a Global or Manager User re-assigns this to your Location."
        />,
        onConfirm: () => onCreateTemplate(payload, false),
      });
      return;
    }

    const orderPayload = {
      application_type: payload.application_type,
      bureau: bureau.uri,
      due_date: payload.due_date
        ? new Date(payload.due_date).toISOString()
        : null,
      custom_field_values: payload.custom_field_values || [],
      customer_email: payload.customer_email,
      customer_name: payload.customer_name,
      customer_po: payload.customer_po,
      ip_sensitivity: payload.ip_sensitivity,
      location: payload.location,
      name: payload.name,
      order_owner:
        payload.order_owner === '' ? null : payload.order_owner,
      order_type: payload.order_type,
      priority: payload?.priority && Number.parseInt(payload.priority, 10),
      region: payload.region,
      shipping: {
        address: payload.shipping.address,
        name: payload.shipping.name,
        tracking: payload.shipping.tracking,
        uri: payload.shipping.uri,
      },
      shipping_grouping: payload.shipping.uri
        ? payload.shipping_grouping
        : null,
      template_name: payload.template_name.trim(),
    };

    const requiredCustomFields =
      getRequiredDefaultCustomFields(customOrderFieldReferences, payload.custom_field_values);

    orderPayload.custom_field_values = [
      ...orderPayload.custom_field_values,
      ...requiredCustomFields];

    Object.keys(orderPayload).forEach(key => {
      if (orderPayload[key] == null || orderPayload[key] === 'none') {
        delete orderPayload[key];
      }
    });

    dispatch(Actions.Api.nautilus[API_RESOURCES.ORDER].post(orderPayload))
      .then(response => {
        const orderUuid = extractUuid(response.payload.uri);
        Alert.success('Template successfully created');
        navigate(getRouteURI(ROUTES.ORDER_TEMPLATES_EDIT, { uuid: orderUuid }, {}, true));
      })
      .catch(error => {
        // eslint-disable-next-line no-console
        console.error(error);
        Alert.error(error.message);
      });
  };

  const onSave = useCallback((formValues, showWarning) => {
    const payload = formValues;

    if (!payload.uuid) {
      // New Template Creation (Not Update Existing)
      return onCreateTemplate(formValues, showWarning);
    }

    Object.keys(payload).forEach(key => {
      if (payload[key] === 'none') payload[key] = null;
    });

    ORDER_SUMMARY.NULL_FIELDS.forEach(
      fieldName => {
        if (payload[fieldName] === '') {
          payload[fieldName] = null;
        }
      },
    );

    if (payload.due_date) {
      const date = new Date(payload.due_date);
      payload.due_date = date.toISOString();
    } else {
      payload.due_date = null;
    }

    if (!payload.shipping.uri) {
      payload.shipping_grouping = null;
      // payload.shipping_grouping = 'by_order';
    }

    payload.priority = Number.parseInt(payload.priority, 10);

    const requiredCustomFields =
      getRequiredDefaultCustomFields(customOrderFieldReferences, payload.custom_field_values);

    // Submit required Custom Field Values which are `Required` but not yet provided
    payload.custom_field_values = [
      ...payload.custom_field_values,
      ...requiredCustomFields];

    const userRoles = new Set(currentUserRoles.map(role => role.role));
    const showLocationOrderAccessWarning = !userRoles.has(USER_ROLES.MANAGER)
      && !userRoles.has(USER_ROLES.GLOBAL_USER)
      && userRoles.has(USER_ROLES.LOCATION_USER)
      && !permissionLocationUris.includes(payload.location);
    if (showWarning && showLocationOrderAccessWarning) {
      openWarningModal({
        modalTemplate: MODAL_TEMPLATES.YES_OR_NO,
        title: '',
        bodyText: <FormattedMessage
          id="message.locationOrderAccessWarning"
          defaultMessage="Order not assigned to a location that you have access to. You will no longer have access to this Order until a Global or Manager User re-assigns this to your Location."
        />,
        onConfirm: () => onSave(payload, false),
      });
      return null;
    }

    try {
      dispatch(Actions.Api.nautilus[API_RESOURCES.ORDER].put(payload.uuid, payload))
        .then(async () => {
          Alert.success('Order Template successfully updated.');
          if (showLocationOrderAccessWarning) {
            dispatch(Actions.Api.nautilus[API_RESOURCES.ORDER].clear('list'));
            navigate(getRouteURI(ROUTES.ORDERS, {}, {}, true));
          } else {
            await dispatch(Actions.Api.nautilus[API_RESOURCES.ORDER].get(payload.uuid));
          }
        });
    } catch (error) {
      Sentry.captureException(error);
    }
    return true;
  }, [dispatch, JSON.stringify(currentUserRoles), JSON.stringify(permissionLocationUris)]);

  const onCustomFieldChange = useCallback(([field, customField], state, { changeValue }) => {
    const customFieldValues = state.formState.values.custom_field_values;

    const customFieldValuesReplaced = createOrReplaceArray(
      customFieldValues,
      { custom_field: customField.customFieldReferenceUri },
      { value: customField.value },
    );

    changeValue(state, field, () => (customFieldValuesReplaced));
  }, []);

  const initCustomFieldValues = useCallback(([field], state, { changeValue }) => {
    const customFieldValues = state.formState.values.custom_field_values;

    const updatedCustomOrderFieldValues = getInitialCustomFieldValues(
      customOrderFieldReferences, customFieldValues,
    );
    changeValue(state, field, () => (updatedCustomOrderFieldValues));
  }, [customOrderFieldReferences]);

  const onInitializeOrder = async uuid => {
    if (uuid) {
      const response = await dispatch(Actions.Api.nautilus[API_RESOURCES.ORDER].get(uuid));
      const order = response?.json;

      // Check if order is not a template -> redirect to the actual order
      if (!order?.is_template && order?.uri) {
        // Stop processing the template and redirect to the order record page
        return navigate(getRouteURI(ROUTES.ORDER_EDIT, { uuid: extractUuid(order.uri) }, {}, true));
      }
    }
    return null;
  };

  const onInitialize = bureauUri => {
    dispatch(Actions.Api.nautilus[API_RESOURCES.SHIPPING].list());
    dispatch(Actions.Api.nautilus[API_RESOURCES.MATERIAL].list({ bureau: bureauUri }));
    dispatch(Actions.Api.nautilus[API_RESOURCES.SERVICE_PROVIDER]
      .list({}, { limit: PAGINATION_IGNORE_DEFAULT_LIMIT }));
    dispatch(Actions.Api.nautilus[API_RESOURCES.SHIPPING].list());
    dispatch(Actions.Api.nautilus[API_RESOURCES.LOCATION].list());
  };

  const getOrderOwner = () => {
    if (order?.order_owner && !orderOwnerFetched) {
      dispatch(Actions.Api.nautilus[API_RESOURCES.USERS].get(order.order_owner));
      setOrderOwnerFetched(true);
    }
  };

  useEffect(() => {
    onInitializeOrder(uuid)
      .then(() => onInitialize(bureau?.uri));
  }, [uuid]);

  useEffect(() => {
    getOrderOwner();
  }, [order, orderOwnerFetched]);

  const dispatched = {
    onDelete,
    onSave,
    onCustomFieldChange,
    initCustomFieldValues,
    setConfirmDeleteTemplate,
  };

  const selected = {
    initialFormValues,
    order,
    isOrderSubmitting,
    customOrderFieldReferences,
    isRobozeDDWFeatureEnabled,
    confirmDeleteTemplate,
    templateDeleting,
  };

  return (
    <>
      <WarningModal id="orderLocationWarningModal" />
      {isFetching ? <Loading /> : <OrderTemplate {...selected} {...dispatched} />}
    </>
  );
};

export default OrderTemplateContainer;
