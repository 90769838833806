import React from 'react';
import PropTypes from 'prop-types';
import {
  FormattedMessage,
} from 'rapidfab/i18n';
import { hhmmss, parseHhmmss } from 'rapidfab/utils/timeUtils';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';

const TimeFormattedHHMMSSWithTooltip = ({ timeInSeconds, children }) => {
  // hhmmss formats a number of seconds into hh:mm:ss format
  // parseHhmmss then parses that string with regex to an object with hours minutes and seconds
  const { hours, minutes, seconds } = parseHhmmss(hhmmss(timeInSeconds));
  return (
    <OverlayTrigger
      placement="top"
      overlay={(
        <Tooltip>
          <p> <FormattedMessage
            id="estimatesTimeTooltip"
            defaultMessage="{hours} hours, {minutes} minutes, and {seconds} seconds."
            values={{ hours, minutes, seconds }}
          />
          </p>
        </Tooltip>
      )}
    >
      <span>
        {children}
      </span>
    </OverlayTrigger>

  );
};
TimeFormattedHHMMSSWithTooltip.propTypes = {
  timeInSeconds: PropTypes.number.isRequired,
  children: PropTypes.node.isRequired,
};

export default TimeFormattedHHMMSSWithTooltip;
