import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Button, Col, FormControl, Row } from 'react-bootstrap';
import NonHawkingFeature from 'rapidfab/components/hawking/NonHawkingFeature';
import Loading from 'rapidfab/components/Loading';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCube, faEdit, faSave, faSuperscript, faTimes, faTrash } from '@fortawesome/free-solid-svg-icons';

const EditModalHeader = ({
  name,
  onSaveName,
  onClose,
  onDelete,
  isHawkingUser,
  isModelDataEditable,
  partNumber,
}) => {
  const [isEditing, setIsEditing] = useState(false);
  const [isSaving, setIsSaving] = useState(false);
  const [newName, setNewName] = useState(name);

  const onSetEditing = () => setIsEditing(true);
  const onChangeInput = event => setNewName(event.target.value);
  const onCancel = () => {
    setIsEditing(false);
    setNewName(name);
  };
  const onClickSave = async () => {
    if (newName !== name) {
      try {
        setIsSaving(true);
        await onSaveName(newName);
        setIsSaving(false);
        setIsEditing(false);
      } catch {
        setIsSaving(false);
      }
    } else {
      onCancel();
    }
  };

  return (
    <div className="form-inline d-flex align-items-center justify-content-between">
      <Row className="w-100">
        <Col xs={4}>
          {isEditing ? (
            <div className="d-flex align-items-center flex-1">
              <FormControl
                value={newName}
                onChange={onChangeInput}
                disabled={isSaving}
                className="flex-1 h5 w-100"
                style={{ marginBottom: 0 }}
              />
              {isSaving ? (
                <Loading className="ml15" />
              ) : (
                <>
                  <Button size="xs" onClick={onCancel} className="btn-default ml15">
                    <FontAwesomeIcon icon={faTimes} />
                  </Button>
                  <Button size="xs" onClick={onClickSave} className="btn-default ml15">
                    <FontAwesomeIcon icon={faSave} />
                  </Button>
                </>
              )}
            </div>
          ) : (
            <div className="d-flex align-items-center flex-1">
              <FontAwesomeIcon className="spacer-right" icon={faCube} />
              <div className="wrap-text">{name}</div>
              {
                isModelDataEditable && (
                  <Button size="xs" onClick={onSetEditing} className="ml15 btn-default">
                    <FontAwesomeIcon icon={faEdit} />
                  </Button>
                )
              }
            </div>
          )}
        </Col>
        <Col xs={4}>
          {partNumber && (
            <div className="d-flex align-items-center text-wrap">
              <FontAwesomeIcon className="spacer-right" icon={faSuperscript} />
              <div>{partNumber}</div>
            </div>
          )}
        </Col>
      </Row>
      <div className="d-flex ml30 justify-content-end pull-right">
        {
          isModelDataEditable && (
            <NonHawkingFeature>
              <span style={{ marginRight: '0.5em', cursor: 'pointer' }}>
                <FontAwesomeIcon icon={faTrash} onClick={onDelete} />
              </span>
            </NonHawkingFeature>
          )
        }
        {isHawkingUser && (
          <span style={{ marginRight: '0.5em', cursor: 'pointer' }}>
            <FontAwesomeIcon icon={faTrash} onClick={onDelete} />
          </span>
        )}
        <Button className="btn-default" size="xs" onClick={onClose}>
          <FontAwesomeIcon icon={faTimes} />
        </Button>
      </div>
    </div>
  );
};

EditModalHeader.propTypes = {
  name: PropTypes.string.isRequired,
  onSaveName: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
  onDelete: PropTypes.func.isRequired,
  isHawkingUser: PropTypes.bool,
  isModelDataEditable: PropTypes.bool.isRequired,
  partNumber: PropTypes.string.isRequired,
};

EditModalHeader.defaultProps = {
  isHawkingUser: false,
};

export default EditModalHeader;
