import React from 'react';
import PropTypes from 'prop-types';
import { EVENT_KEY_MAP } from 'rapidfab/mappings';
import _startCase from 'lodash/startCase';

const EventKeyName = ({ eventKey }) => {
  if (EVENT_KEY_MAP[eventKey]) {
    return <span>{EVENT_KEY_MAP[eventKey]}</span>;
  }

  // Return human-readable key itself as a fall-back
  return <span>{_startCase(eventKey)}</span>;
};

EventKeyName.propTypes = {
  eventKey: PropTypes.string.isRequired,
};

export default EventKeyName;
