import React from 'react';
import PropTypes from 'prop-types';
import AuthentiseLogo from 'rapidfab/images/logo.png';
import XeroxLogo from 'rapidfab/images/xerox-logo-red.png';

const TosLogo = ({ isHawkingUser }) => {
  if (isHawkingUser) {
    return <img alt="logo" src={XeroxLogo} style={{ height: '50px' }} />;
  }
  return <img alt="logo" src={AuthentiseLogo} />;
};

TosLogo.propTypes = {
  isHawkingUser: PropTypes.bool.isRequired,
};

export default TosLogo;
