import cadfileDA from './cadfile/da.json';
import documentsDA from './documents/da.json';
import estimatesDA from './estimates/da.json';
import quoteDA from './quote/da.json';
import shipmentsDA from './shipments/da.json';
import summaryDA from './summary/da.json';

const recordsDA = Object.assign(cadfileDA, documentsDA, estimatesDA, quoteDA, shipmentsDA, summaryDA);

export default recordsDA;
