import _map from 'lodash/map';
import _keyBy from 'lodash/keyBy';
import { createSelector } from 'reselect';
import { getLineItems } from 'rapidfab/selectors/lineItem';
import { getLineItemWorkflowTypeObjectKey } from 'rapidfab/utils/lineItemUtils';
import * as baseStateSelectors from './baseStateSelectors';
import { getPredicate, getStateResources } from './helpers/base';

export const getOrders = createSelector(
  [baseStateSelectors.getStateOrders, getStateResources],
  (uuids, resources) => _map(uuids, uuid => resources[uuid]),
);

export const getOrdersByUri = createSelector([getOrders],
  orders => _keyBy(orders, 'uri'),
);

export const getOrderTemplates = createSelector(
  [getOrders],
  // Get only orders with is_template: true
  orders => orders.filter(order => order.is_template),
);

export const getOrderTemplatesSortedAlphabetically = createSelector(
  [getOrderTemplates],
  orderTemplates => orderTemplates.sort((a, b) =>
    a.template_name.localeCompare(b.template_name)),
);

// Added for the feature purposes of the Stanley X Deployment, might not be needed in the future
export const getOrdersURIsByModelURI = createSelector(
  [getPredicate, getLineItems],
  (modelURI, lineItems) => _map(lineItems, lineItem => {
    const workflowTypeKey = getLineItemWorkflowTypeObjectKey(lineItem);
    if (lineItem[workflowTypeKey].model === modelURI) {
      return lineItem.order;
    }
    return [];
  }).flat(),
);
