import { createSelector } from 'reselect';
import { getStateResources } from 'rapidfab/selectors/helpers/base';
import _map from 'lodash/map';
import * as baseStateSelectors from 'rapidfab/selectors/baseStateSelectors';
import _keyBy from 'lodash/keyBy';

export const getWorkflows = createSelector(
  [baseStateSelectors.getStateWorkflows, getStateResources],
  (uuids, resources) => _map(uuids, uuid => resources[uuid]),
);

export const getWorkflowsByUri = createSelector(
  [getWorkflows],
  workflows => _keyBy(workflows, 'uri'),
);

export const getWorkflowsByUuid = createSelector(
  [getWorkflows],
  workflows => _keyBy(workflows, 'uuid'),
);
