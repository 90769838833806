import { createSelector } from 'reselect';
import * as baseStateSelectors from 'rapidfab/selectors/baseStateSelectors';
import { getPredicate, getStateResources } from 'rapidfab/selectors/helpers/base';
import _map from 'lodash/map';
import _keyBy from 'lodash/keyBy';

export const getSubLocations = createSelector(
  [baseStateSelectors.getStateSubLocations, getStateResources],
  (uuids, resources) => _map(uuids, uuid => resources[uuid]),
);

export const getSubLocationsByUri = createSelector([getSubLocations], subLocations =>
  _keyBy(subLocations, 'uri'),
);

export const getSubLocationsForLocation = createSelector(
  [getPredicate, getSubLocations],
  (locationUri, subLocations) => subLocations.filter(object => object.location === locationUri),
);

export const getSubLocationsByLocationUri = createSelector(
  [getSubLocations], locations => _keyBy(locations, 'location'),
);
