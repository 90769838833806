import React from 'react';
import PropTypes from 'prop-types';
import * as Sentry from '@sentry/react';
import { COMMON_ERRORS as catchableErrors } from 'rapidfab/constants';
import _values from 'lodash/values';

class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      hasError: false,
    };
  }

  componentDidUpdate(prevProps) {
    const { routeHash } = this.props;
    if (routeHash !== prevProps.routeHash) {
      // If state is changed, we need to remove this error message
      // eslint-disable-next-line react/no-did-update-set-state
      this.setState({ hasError: false });
    }
  }

  componentDidCatch(error, errorInfo) {
    const { routeHash } = this.props;

    // Send error to Sentry
    Sentry.captureException(error, { extra: errorInfo, routeHash });

    /* If error is because a value is undefined, we don't want to generate a full
    blackwall. */
    if (_values(catchableErrors).some(commonErrorString =>
      error.toString().includes(commonErrorString))) {
      // eslint-disable-next-line no-console
      console.error(`%cCAUGHT ERROR (undefined value): ${error} ${errorInfo.componentStack}`,
        'font-weight: bold; background: yellow; color: black; padding: 3px; font-size: 14px');
      this.setState({
        hasError: true,
      });

      return;
    }

    // Display fallback UI
    this.setState({ hasError: true });
  }

  render() {
    let errorMessage = (
      <>
        <p>Please reload to try again.</p>
        <p>If the problem persists contact <a href="mailto:support@authentise.com">support@authentise.com</a> or file an Urgent ticket on our Zendesk portal.</p>
      </>
    );

    const { session } = this.props;
    const { isHawkingUser } = session;

    if (isHawkingUser) {
      errorMessage = (
        <>
          <p>Please reload to try again.</p>
          <p>If the problem persists contact <a href="mailto:elemsupport@xerox.com">elemsupport@xerox.com</a> or use the Contact Us form to reach out for support.</p>
        </>
      );
    }

    if (this.state.hasError) {
      return (
        <div className="text-center m-t">
          <h1>Your page has failed to load</h1>
          { errorMessage }
        </div>
      );
    }
    return this.props.children;
  }
}

ErrorBoundary.propTypes = {
  children: PropTypes.element.isRequired,
  routeHash: PropTypes.string.isRequired,
  session: PropTypes.shape({
    isHawkingUser: PropTypes.bool,
  }).isRequired,
};

export default ErrorBoundary;
