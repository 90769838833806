import { faPencil } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import _find from 'lodash/find';
import PropTypes from 'prop-types';
import LocationUserRoleBadgeIcon from 'rapidfab/components/admin/LocationUserBadgeIcon';
import UserRolesBadges from 'rapidfab/components/admin/UserRolesBadges';
import Loading from 'rapidfab/components/Loading';
import { ProfileAvatar } from 'rapidfab/components/navbar/ProfileAvatar';
import Tooltip from 'rapidfab/components/Tooltip';
import { USER_MODAL_MODES } from 'rapidfab/constants';
import { truncateText } from 'rapidfab/utils/stringUtils';
import React, { useState } from 'react';
import { Button, Form, ListGroupItem } from 'react-bootstrap';

const User = ({
  user,
  roles,
  handleSelectionChange,
  isUserSelected,
  multiSelect,
  locationsByUri,
  setCurrentUserPreview,
  setUserModalMode,
}) => {
  const [isSelecting, setIsSelecting] = useState(false);

  const handleViewUser = () => {
    setUserModalMode(USER_MODAL_MODES.VIEW);
    setCurrentUserPreview(user);
  };

  const handleUserSelection = async event => {
    event.preventDefault();
    if (multiSelect) {
      setIsSelecting(true);
      await handleSelectionChange(user.uri);
      setIsSelecting(false);
    } else {
      handleViewUser();
    }
  };

  const isGuest = roles && !!_find(roles, { role: 'self-registered-guest' });
  const userName = user.name || user.username;

  const locationRoles = roles?.filter(role => role.role === 'location-user') || [];
  const generalRoles = roles?.filter(role => role.role !== 'location-user') || [];

  const renderUserData = (userInfo, maxChars, isEmail) => {
    if (isGuest && isEmail) {
      return 'Guest';
    }
    const className = isEmail ? 'profile-avatar-full-email' : 'profile-avatar-full-name';

    return userInfo.length >= maxChars ? (
      <Tooltip
        id="user-info-truncated"
        placement="top"
        trigger={(<div className={className}>{truncateText(userInfo, maxChars)}</div>)}
      >
        {userInfo}
      </Tooltip>
    ) : (
      <div className={className}>{userInfo}</div>
    );
  };

  return (
    <ListGroupItem
      className={`clearfix users-list-item ${isUserSelected ? 'active' : ''}`}
      key={user.uuid}
      onClick={handleUserSelection}
    >
      {isSelecting && (<Loading className="pull-left" inline />)}
      <div className="d-flex align-items-center justify-content-between">
        <div className="d-flex align-items-center">
          {multiSelect && !isSelecting && (
            <Form.Check
              className="pull-left m-t-0 m-b-0 spacer-right"
              checked={isUserSelected}
              // Click handled is needed to show cursor and prevent warnings.
              // List group item onClick handled is used instead
              onClick={() => {}}
              type="checkbox"
            />
          )}
          <div className="d-flex align-items-center">
            <ProfileAvatar userName={user.name || user.username} size="md" />
            <div className="ml15 mr15">
              <div className="mb0 font-size-16 font-weight-600">{renderUserData(userName, 100)}</div>
              <div className="mb0">{renderUserData(user.username, 80, true)}</div>
            </div>
            {!multiSelect && (
              <>
                <UserRolesBadges roles={generalRoles} />
                <LocationUserRoleBadgeIcon
                  locationRoles={locationRoles}
                  locationsByUri={locationsByUri}
                  handleViewUser={handleViewUser}
                />
              </>
            )}
          </div>
        </div>
        {!multiSelect && (
          <Button variant="link" className="users-list-edit-button" onClick={handleViewUser}>
            <FontAwesomeIcon icon={faPencil} />
          </Button>
        )}
      </div>
    </ListGroupItem>
  );
};

User.propTypes = {
  handleSelectionChange: PropTypes.func,
  user: PropTypes.shape({
    name: PropTypes.string,
    username: PropTypes.string,
    uuid: PropTypes.string.isRequired,
    uri: PropTypes.string.isRequired,
  }).isRequired,
  roles: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  isUserSelected: PropTypes.bool,
  multiSelect: PropTypes.bool.isRequired,
  locationsByUri: PropTypes.shape({}).isRequired,
  setCurrentUserPreview: PropTypes.func.isRequired,
  setUserModalMode: PropTypes.func.isRequired,
};

User.defaultProps = {
  handleSelectionChange: () => {},
  isUserSelected: false,
};

export default User;
