import { faWrench } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import isEmpty from 'lodash/isEmpty';
import { getRouteURI, renderSelectedItemsTitle } from 'rapidfab/utils/uriUtils';
import React from 'react';
import PropTypes from 'prop-types';
import { extractUuid, getShortUUID } from 'rapidfab/utils/uuidUtils';
import {
  Button,
  Modal,
} from 'react-bootstrap';
import { FormattedMessage } from 'react-intl';
import { ROUTES } from 'rapidfab/constants';

const getExternalResourceLink = (route, uri, uriText) => (
  <a
    href={getRouteURI(route, { uuid: extractUuid(uri) })}
    rel="noreferrer"
    target="_blank"
  >
    {getShortUUID(uriText || uri)}
  </a>
);

const SplitConfirmationModal = ({ handleClose, selectedPiecesList, selectedLineItemsData, callback }) => {
  const renderTitle = () => {
    const hasLineItemsData = !isEmpty(selectedLineItemsData);
    const { id, defaultMessage } = renderSelectedItemsTitle(hasLineItemsData);

    return (
      <FormattedMessage id={id} defaultMessage={defaultMessage} />
    );
  };

  const renderSelectedItems = () => {
    if (!isEmpty(selectedLineItemsData)) {
      return (
        <>
          {selectedLineItemsData.map(({ line_item, order, count }, index) => (
            <>
              {getExternalResourceLink(ROUTES.ORDER_EDIT, order, line_item)}:{' '}
              <span><FontAwesomeIcon icon={faWrench} /> {count}</span>
              {index !== selectedLineItemsData.length - 1 && ', '}
            </>
          ))}
        </>
      );
    }
    return selectedPiecesList.map((piece, index) =>
      (
        <>
          {getExternalResourceLink(ROUTES.PIECE_EDIT, piece.piece)}
          {index !== selectedPiecesList.length - 1 && ', '}
        </>
      ));
  };

  return (
    <Modal style={{ zIndex: 999999 }} show onHide={handleClose} backdrop="static">
      <Modal.Header closeButton>
        <strong>
          <FormattedMessage id="nc.splitReview" defaultMessage="Split future runs?" />
        </strong>
      </Modal.Header>

      <Modal.Body>
        <strong>
          {renderTitle()}:{' '}
          {renderSelectedItems()}
        </strong>
        <p>
          <FormattedMessage
            id="splitRunModal.firstLine"
            defaultMessage="Should the other Pieces in the Run wait for the Selected Pieces to complete their new workflow?"
          />
        </p>
        <p>
          <FormattedMessage
            id="splitRunModal.secondLine"
            defaultMessage="If not, split them into their own Runs to and skip the wait."
          />
        </p>
      </Modal.Body>
      <Modal.Footer>
        <Button onClick={() => {
          callback(false);
          handleClose();
        }}
        >
          <FormattedMessage id="nc.keepSchedule" defaultMessage="Keep the schedule" />
        </Button>

        <Button
          onClick={() => {
            callback(true);
            handleClose();
          }}
          variant="success"
        >
          <FormattedMessage id="nc.splitTheSchedule" defaultMessage="Split the schedule" />
        </Button>

      </Modal.Footer>
    </Modal>
  );
};

SplitConfirmationModal.propTypes = {
  selectedPiecesList: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  handleClose: PropTypes.func.isRequired,
  callback: PropTypes.func.isRequired,
  selectedLineItemsData: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
};

export default SplitConfirmationModal;
