import { createSelector } from 'reselect';
import { getPredicate } from 'rapidfab/selectors/helpers/base';
import _find from 'lodash/find';
import { getZverseConversions } from 'rapidfab/selectors/zverseConversion';
import { getExpertConversions } from 'rapidfab/selectors/expertConversion';

// eslint-disable-next-line import/prefer-default-export
export const getZverseConversionForModel = createSelector(
  [getPredicate, getZverseConversions],
  (model, specimens) => (
    model
      ? _find(specimens, { uri: model.zverse_conversion })
      : null
  ),
);
export const getExpertConversionForModel = createSelector(
  [getPredicate, getExpertConversions],
  (model, specimens) => (
    model
      ? _find(specimens, { uri: model.expert_conversion })
      : null
  ),
);
