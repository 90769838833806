import { faExternalLink } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import PropTypes from 'prop-types';
import Loading from 'rapidfab/components/Loading';
import { API_RESOURCES, ROUTES } from 'rapidfab/constants';
import { getRouteURI } from 'rapidfab/utils/uriUtils';
import React from 'react';
import { Card, Col, ListGroup, ListGroupItem, Row } from 'react-bootstrap';
import { Link } from 'react-router-dom';

const ResourceListItem = ({
  resourceType,
  route,
  resourceCount,
  isLoading,
  resourceState,
  handleSetLocationFilters,
}) => {
  const { uri } = resourceState;
  return (
    <ListGroupItem className="resource-list-item-card-item">
      <Row>
        <Col xs={4}>
          <span>{resourceType}</span>
        </Col>
        <Col xs={4}>
          <span>{isLoading ? <Loading inline /> : resourceCount}</span>
        </Col>
        <Col xs={4}>
          {
            isLoading ? (
              <Loading inline />
            ) : (
              <Link
                onClick={() => handleSetLocationFilters(uri, '')}
                rel="noopener noreferrer"
                to={{
                  pathname: getRouteURI(route,
                    null,
                    null, true),
                }}
              >
                <FontAwesomeIcon icon={faExternalLink} />
              </Link>
            )
          }
        </Col>
      </Row>
    </ListGroupItem>
  );
};

ResourceListItem.defaultProps = {
  resourceCount: null,
  isLoading: false,
};

ResourceListItem.propTypes = {
  resourceType: PropTypes.string.isRequired,
  resourceCount: PropTypes.number,
  isLoading: PropTypes.bool,
  route: PropTypes.string.isRequired,
  resourceState: PropTypes.shape({
    uri: PropTypes.string.isRequired,
    subLocationUUID: PropTypes.string.isRequired,
    subLocationUri: PropTypes.string.isRequired,
  }).isRequired,
  handleSetLocationFilters: PropTypes.func.isRequired,
};

const ResourceListTable = ({
  locationUri,
  renderResourceCount,
  materialResourcesFetching,
  handleSetLocationFilters,
}) => (
  <Card bg="dark" className="mb15 resource-list-item-card">
    <ListGroup fill>
      <div key="header" className="resource-list-item-card-header">
        <b>All Material Stock Held at Location</b>
      </div>

      <ResourceListItem
        resourceType="Lots"
        route={ROUTES.MATERIAL_LOTS}
        resourceState={{
          uri: locationUri,
        }}
        resourceCount={renderResourceCount(API_RESOURCES.MATERIAL_LOT, locationUri)}
        isLoading={materialResourcesFetching}
        handleSetLocationFilters={handleSetLocationFilters}
      />

      <ResourceListItem
        resourceType="Batches"
        route={ROUTES.MATERIAL_BATCHES}
        resourceState={{
          uri: locationUri,
        }}
        resourceCount={renderResourceCount(API_RESOURCES.MATERIAL_BATCH, locationUri)}
        isLoading={materialResourcesFetching}
        handleSetLocationFilters={handleSetLocationFilters}
      />

    </ListGroup>
  </Card>
);

ResourceListTable.propTypes = {
  locationUri: PropTypes.string.isRequired,
  renderResourceCount: PropTypes.func.isRequired,
  materialResourcesFetching: PropTypes.bool.isRequired,
  handleSetLocationFilters: PropTypes.func.isRequired,
};

export default ResourceListTable;
