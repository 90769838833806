import { Chart } from 'chart.js';
import React, { Component, memo } from 'react';
import PropTypes from 'prop-types';

class PieChart extends Component {
  constructor() {
    super();
    this.myRef = React.createRef();
  }

  componentDidMount() {
    const { ctx } = this;
    const { onSegmentClick, materials } = this.props;

    const data = {
      labels: materials?.map(entry => entry.name),
      datasets: [
        {
          label: 'My First Dataset',
          data: materials?.map(entry => entry.percentage),
          backgroundColor: [
            'rgba(255, 99, 132, 1)',
            'rgba(54, 180, 235, 1)',
            'rgba(30, 120, 235, 1)',
          ],
          hoverOffset: 4,
        },
      ],
    };

    // eslint-disable-next-line no-new
    new Chart(ctx, {
      type: 'pie',
      data,
      options: {
        legend: {
          display: true,
          onClick: (event, item) => {
            event.stopPropagation();
            onSegmentClick(event, item);
          },
          onHover(event) {
            // eslint-disable-next-line no-param-reassign
            event.target.style.cursor = 'pointer';
          },
          onLeave(event) {
            // eslint-disable-next-line no-param-reassign
            event.target.style.cursor = 'default';
          },
        },
      },
    });
  }

  render() {
    return (
      <div>
        <canvas width="200" height="200" ref={context => { this.ctx = context; }} />
      </div>
    );
  }
}

PieChart.propTypes = {
  onSegmentClick: PropTypes.func.isRequired,
  materials: PropTypes.arrayOf(
    PropTypes.shape({}),
  ).isRequired,
};

export default memo(PieChart);
