import { faCheckCircle, faExternalLink, faRefresh, faTimesCircle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import PropTypes from 'prop-types';
import 'rapidfab/styles/componentStyles/create-run-progress-stepper.scss';
import Loading from 'rapidfab/components/Loading';
import { CREATE_RUN_PROGRESS_STATUSES, ROUTES } from 'rapidfab/constants';
import {
  BUILD_PACKER_TYPES_MAP,
  CREATE_RUN_STEPPER_PROGRESS_STATUS_MAPPING,
} from 'rapidfab/mappings';
import { getRouteURI } from 'rapidfab/utils/uriUtils';
import { extractUuid } from 'rapidfab/utils/uuidUtils';
import React, { memo, useEffect, useState } from 'react';
import { Button } from 'react-bootstrap';
import { Link } from 'react-router-dom';

const CreateRunProgressStep = memo(({
  step = 1,
  stepStatus,
  currentStatus,
  statusDescription,
  isComplete,
  isError,
  errorDetails,
  showRefreshDataInfo,
  handleRefreshDataForRunCreation,
  refreshingRunProgress,
  refreshDataComplete,
  selectedPrinter,
  clearModalWindow,
}) => {
  const { message } = errorDetails;

  const { initialText, completeText } = statusDescription;

  // The step is not complete, not errored and not active overall.
  const isInactiveDueToError = currentStatus === CREATE_RUN_PROGRESS_STATUSES.ERROR
    && !isError
    && !isComplete
    && currentStatus !== stepStatus;

  const stepperClassList = [
    'create-run-progress-step',
    isComplete ? 'create-run-progress-step-complete' : '',
    currentStatus === stepStatus && !isError ? 'create-run-progress-step-active' : '',
    showRefreshDataInfo &&
    currentStatus === CREATE_RUN_PROGRESS_STATUSES.PROCESSING &&
    stepStatus === CREATE_RUN_PROGRESS_STATUSES.PROCESSING ? 'create-run-progress-step-refresh-suggestion' : '',
    isError ? 'create-run-progress-step-error' : '',
    isInactiveDueToError ? 'create-run-progress-step-inactive' : '',
  ].join(' ');

  const statusTextClassList = [
    'create-run-progress-step-details-status-description',
    isError ? 'create-run-progress-step-error-color' : '',
  ].join(' ');

  const renderStatusText = () => {
    // If the step is not active, skip showing the status text description
    if (!isComplete && !isError && currentStatus !== stepStatus) return null;

    if (isError) {
      return (
        <div>
          <p>{message || 'An error occurred while processing this step.'}</p>
          <div className="text-white">
            <p className="mb0">Packing
              Type: <strong>{BUILD_PACKER_TYPES_MAP[selectedPrinter?.printer_type?.build_packer_type]?.defaultMessage || 'N/A'}</strong>
            </p>
            <p>Printer Type:&nbsp;
              {selectedPrinter?.printer_type?.uri ? (
                <Link
                  to={getRouteURI(ROUTES.PRINTER_TYPE_EDIT,
                    { uuid: extractUuid(selectedPrinter?.printer_type?.uri) }, null, true)}
                  onClick={clearModalWindow}
                >
                  {selectedPrinter?.printer_type?.name}
                  {' '}
                  <FontAwesomeIcon className="spacer-left" icon={faExternalLink} />
                </Link>
              ) : 'N/A'}
            </p>
          </div>
        </div>
      );
    }

    if (isComplete) {
      return completeText;
    }
    return initialText;
  };

  const renderRefreshDataSuggestion = () => (
    <p className="create-run-progress-step-details-status-refresh-suggestion-text">
      This step is taking longer than expected. Would you like to retrieve the latest data?
      <Button
        variant="link"
        className="spacer-left create-run-progress-step-details-status-refresh-suggestion-btn"
        onClick={handleRefreshDataForRunCreation}
      >
        <FontAwesomeIcon
          icon={faRefresh}
          spin={refreshingRunProgress}
          className="spacer-right"
        />
        Refresh
      </Button>
    </p>
  );

  const showEverythingUpToDate = () =>
    <p className="create-run-progress-step-details-status-refresh-suggestion-text" style={{ color: '#1ac98e' }}>Everything is up to date.</p>;

  return (
    <div className={stepperClassList}>
      <div className="create-run-progress-step-circle">
        {isComplete && <FontAwesomeIcon icon={faCheckCircle} color="#3FBA03" className="font-size-20" />}
        {isError && <FontAwesomeIcon icon={faTimesCircle} color="#DE4C3B" className="font-size-20" />}
      </div>
      <div className="create-run-progress-step-details">
        <div className="create-run-progress-step-details-title">Step {step}</div>
        <div
          className="create-run-progress-step-details-status-text"
        >
          {CREATE_RUN_STEPPER_PROGRESS_STATUS_MAPPING[stepStatus]}
        </div>
        {(isComplete || currentStatus === stepStatus) && <div className="create-run-progress-step-details-status-text-divider" />}
        <div className={statusTextClassList}>
          {currentStatus === stepStatus && !isError && <Loading inline className="spacer-right" /> }
          {renderStatusText()}
          {currentStatus === stepStatus && showRefreshDataInfo && renderRefreshDataSuggestion()}
          {currentStatus === stepStatus && refreshDataComplete && showEverythingUpToDate()}
        </div>
      </div>
    </div>
  );
});

CreateRunProgressStep.defaultProps = {
  errorDetails: {},
};

CreateRunProgressStep.propTypes = {
  step: PropTypes.number.isRequired,
  stepStatus: PropTypes.string.isRequired,
  currentStatus: PropTypes.string.isRequired,
  statusDescription: PropTypes.shape({
    initialText: PropTypes.string.isRequired,
    completeText: PropTypes.string.isRequired,
  }).isRequired,
  isComplete: PropTypes.bool.isRequired,
  isError: PropTypes.bool.isRequired,
  errorDetails: PropTypes.shape({
    dateFinished: PropTypes.string,
    message: PropTypes.string,
    unfittedPiecesLength: PropTypes.number,
  }),
  showRefreshDataInfo: PropTypes.bool.isRequired,
  handleRefreshDataForRunCreation: PropTypes.func.isRequired,
  refreshingRunProgress: PropTypes.bool.isRequired,
  refreshDataComplete: PropTypes.bool.isRequired,
  selectedPrinter: PropTypes.shape({
    printer_type: PropTypes.shape({
      build_packer_type: PropTypes.string,
      name: PropTypes.string,
      uri: PropTypes.string,
    }),
  }).isRequired,
  clearModalWindow: PropTypes.func.isRequired,
};

const CreateRunProgressStepper = ({
  status,
  errorDetails,
  packingMethod,
  showRefreshDataInfo,
  handleRefreshDataForRunCreation,
  refreshingRunProgress,
  refreshDataComplete,
  selectedPrinter,
  clearModalWindow,
}) => {
  // Render the status description text based on the current status
  const getStatusDescription = status => {
    switch (status) {
      case CREATE_RUN_PROGRESS_STATUSES.PENDING:
        return {
          initialText: 'The runs are prepared and queued for packing.',
          completeText: 'The runs are prepared for packing.',
        };
      case CREATE_RUN_PROGRESS_STATUSES.PROCESSING:
        return {
          initialText: (
            packingMethod ? (
              <>We are currently packing the runs using the&nbsp;
                <strong>{packingMethod}</strong> method. Please hold tight.
              </>
            ) : (
              'We are currently packing the runs using the 3D Build Packing method. Please hold tight.'
            )
          ),
          completeText: 'The runs have been packed.',
        };
      case CREATE_RUN_PROGRESS_STATUSES.FINISHING:
        return {
          initialText: 'Runs will be added to schedule shortly.',
          completeText: 'Finished.',
        };
      default:
        return { initialText: '', completeText: '' };
    }
  };

  // Get the last successful status to identify where we should trigger Error Step UI
  const [lastSuccessfulStatus, setLastSuccessfulStatus] = useState(null);

  useEffect(() => {
    // If the status is not an error, set the last successful status
    if (status !== CREATE_RUN_PROGRESS_STATUSES.ERROR) {
      setLastSuccessfulStatus(status);
    }
  }, [status]);

  // Current Steps in the Stepper UI
  const steps = [
    CREATE_RUN_PROGRESS_STATUSES.PENDING,
    CREATE_RUN_PROGRESS_STATUSES.PROCESSING,
    CREATE_RUN_PROGRESS_STATUSES.FINISHING,
  ];

  const getStepStatus = stepIndex => {
    // Get the current step status
    const stepStatus = steps[stepIndex];
    // Check if the step is complete or if step index is less than the last successful status
    const isComplete = status === CREATE_RUN_PROGRESS_STATUSES.COMPLETE
      || stepIndex < steps.indexOf(lastSuccessfulStatus);
    // Check if the step is an error or if the step index is the last successful status
    const isError = status === CREATE_RUN_PROGRESS_STATUSES.ERROR
      && stepIndex === steps.indexOf(lastSuccessfulStatus);

    return { stepStatus, isComplete, isError };
  };

  return (
    <div className="create-run-progress-stepper">
      {steps.map((step, index) => {
        const { stepStatus, isComplete, isError } = getStepStatus(index);
        return (
          <CreateRunProgressStep
            key={step}
            step={index + 1}
            stepStatus={stepStatus}
            currentStatus={status}
            statusDescription={getStatusDescription(stepStatus)}
            isComplete={isComplete}
            isError={isError}
            errorDetails={errorDetails}
            showRefreshDataInfo={showRefreshDataInfo}
            handleRefreshDataForRunCreation={handleRefreshDataForRunCreation}
            refreshDataComplete={refreshDataComplete}
            refreshingRunProgress={refreshingRunProgress}
            selectedPrinter={selectedPrinter}
            clearModalWindow={clearModalWindow}
          />
        );
      })}
    </div>
  );
};

CreateRunProgressStepper.defaultProps = {
  errorDetails: {},
};

CreateRunProgressStepper.propTypes = {
  status: PropTypes.string.isRequired,
  packingMethod: PropTypes.string.isRequired,
  errorDetails: PropTypes.shape({
    dateFinished: PropTypes.string,
    message: PropTypes.string,
    unfittedPiecesLength: PropTypes.number,
  }),
  showRefreshDataInfo: PropTypes.bool.isRequired,
  handleRefreshDataForRunCreation: PropTypes.func.isRequired,
  refreshingRunProgress: PropTypes.bool.isRequired,
  refreshDataComplete: PropTypes.bool.isRequired,
  selectedPrinter: PropTypes.shape({}).isRequired,
  clearModalWindow: PropTypes.func.isRequired,
};

export default CreateRunProgressStepper;
