import React from 'react';
import PropTypes from 'prop-types';
import ServiceProvidersGrid from 'rapidfab/components/organize/ServiceProvidersGrid';
import { Button, Col, Container, Row } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { FormattedMessage } from 'react-intl';
import BreadcrumbNav from 'rapidfab/components/BreadcrumbNav';
import Loading from 'rapidfab/components/Loading';
import { ROUTES } from 'rapidfab/constants';
import { getRouteURI } from 'rapidfab/utils/uriUtils';
import { faPlus } from '@fortawesome/free-solid-svg-icons';

const ServiceProviders = ({ type, providers, fetching }) => (
  <Container fluid>
    <BreadcrumbNav
      breadcrumbs={[
        'organize',
        type === 'vendor' ? 'vendorServiceProviders' : 'serviceProviders',
      ]}
    />

    <Row>
      <Col xs={12}>
        <Button
          variant="primary"
          size="sm"
          href={getRouteURI(
            type === 'vendor'
              ? ROUTES.VENDOR_SERVICE_PROVIDER_NEW
              : ROUTES.SERVICE_PROVIDER_NEW,
          )}
          className="pull-right"
        >
          <FontAwesomeIcon icon={faPlus} />{' '}
          {type === 'vendor' ? (
            <FormattedMessage
              id="record.vendorServiceProvider.add"
              defaultMessage="Add Vendor Service Provider"
            />
          ) : (
            <FormattedMessage
              id="record.serviceProvider.add"
              defaultMessage="Add Service Provider"
            />
          )}
        </Button>
      </Col>
    </Row>

    <hr />

    <Row>
      <Col xs={12}>
        {fetching ? (
          <Loading />
        ) : (
          <ServiceProvidersGrid providers={providers} type={type} />
        )}
      </Col>
    </Row>
  </Container>
);

ServiceProviders.propTypes = {
  fetching: PropTypes.bool.isRequired,
  providers: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  type: PropTypes.string.isRequired,
};

export default ServiceProviders;
