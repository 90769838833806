import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { NavDropdown } from 'react-bootstrap';
import Actions from 'rapidfab/actions';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { getBureauDefaultCurrency, getConversions, getSelectedCurrency } from 'rapidfab/selectors';
import { CurrencySymbols } from 'rapidfab/constants';
import { faCheck } from '@fortawesome/free-solid-svg-icons';

const CurrencyMenu = ({ selectedCurrency, availableConversions, selectCurrency }) => (
  <NavDropdown
    title={
      /* eslint-disable-next-line react/no-danger */
      <span dangerouslySetInnerHTML={{ __html: CurrencySymbols[selectedCurrency] }} />
    }
    id="uxNavCurrency"
    aria-label="Choose Currency"
  >
    {availableConversions.map(availableConversion => (
      <NavDropdown.Item
        key={availableConversion.currency}
        onClick={() => selectCurrency(availableConversion.currency)}
      >
        <FontAwesomeIcon
          icon={faCheck}
          style={{ visibility: availableConversion.currency === selectedCurrency ? null : 'hidden' }}
        />
        <span
          style={{ margin: '0 2px 0 6px' }}
          /* eslint-disable-next-line react/no-danger */
          dangerouslySetInnerHTML={{ __html: CurrencySymbols[availableConversion.currency] }}
        />
        {availableConversion.currency}
      </NavDropdown.Item>
    ))}
  </NavDropdown>
);

CurrencyMenu.propTypes = {
  selectedCurrency: PropTypes.string,
  availableConversions: PropTypes.arrayOf(
    PropTypes.shape({
      currency: PropTypes.string.isRequired,
    }),
  ).isRequired,
  selectCurrency: PropTypes.func.isRequired,
};

CurrencyMenu.defaultProps = {
  selectedCurrency: null,
};

const mapStateToProps = state => {
  const defaultCurrency = getBureauDefaultCurrency(state);
  const selectedCurrency = getSelectedCurrency(state);

  const availableConversions = getConversions(state);

  return {
    selectedCurrency: selectedCurrency || defaultCurrency,
    availableConversions,
  };
};

const mapDispatchToProps = dispatch => ({
  selectCurrency: currencyCode => {
    dispatch(Actions.DynamicCurrency.setSelectedCurrency(currencyCode));
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(CurrencyMenu);
