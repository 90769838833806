import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import { Button } from 'react-bootstrap';
import Loading from 'rapidfab/components/Loading';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSave } from '@fortawesome/free-solid-svg-icons';

const SaveButton = ({
  resourceName,
  isSaving,
  label,
  showSaveIcon,
  bsStyle,
  bsSize,
  onClick,
  className,
  disabled,
  form,
}) => (
  <Button
    variant={bsStyle}
    type="submit"
    disabled={isSaving || disabled}
    size={bsSize}
    onClick={onClick}
    className={className}
    form={form}
  >
    {isSaving && <Loading inline className="spacer-right" />}
    <span>
      {showSaveIcon && <FontAwesomeIcon className="spacer-right" icon={faSave} />}
      {label}
      {resourceName && (
        <span className="spacer-left">
          {resourceName}
        </span>
      )}
    </span>
  </Button>
);

SaveButton.defaultProps = {
  className: null,
  resourceName: null,
  isSaving: false,
  label: <FormattedMessage id="button.save" defaultMessage="Save" />,
  showSaveIcon: true,
  bsStyle: 'success',
  bsSize: null,
  onClick: null,
  disabled: false,
  form: null,
};
SaveButton.propTypes = {
  resourceName: PropTypes.node,
  isSaving: PropTypes.bool,
  label: PropTypes.node,
  showSaveIcon: PropTypes.bool,
  bsStyle: PropTypes.oneOf(['primary', 'success', 'default', 'secondary']),
  bsSize: PropTypes.string,
  onClick: PropTypes.func,
  className: PropTypes.string,
  disabled: PropTypes.bool,
  form: PropTypes.string,
};

export default SaveButton;
