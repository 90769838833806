import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import Actions from 'rapidfab/actions';
import * as Selectors from 'rapidfab/selectors';

import EditRestrictedOrderComponent from 'rapidfab/components/records/order/restricted/edit/EditRestrictedOrder';
import Loading from 'rapidfab/components/Loading';
import { loadOrderRelatedUsers } from 'rapidfab/dispatchers/order';
import { API_RESOURCES } from 'rapidfab/constants';
import Alert from 'rapidfab/utils/alert';
import { FormattedMessage } from 'rapidfab/i18n';

const EditRestrictedOrder = () => {
  const uuid = useSelector(Selectors.getRouteUUID);
  const routeUUID = useSelector(Selectors.getRouteUUID);
  const order = useSelector(state => Selectors.getUUIDResource(state, uuid));

  const dispatch = useDispatch();

  useEffect(() => {
    // Fetch order and related resources
    dispatch(Actions.Api.nautilus[API_RESOURCES.ORDER].get(uuid))
      .then(response => {
        // eslint-disable-next-line no-shadow
        const order = response?.json;

        if (order) {
          // Even though line items are not used on this page, we still need them
          // for proper redirect on submit if there is a pending line item (See OrderSummary component)
          // and to disable order fields based on line items status (see EditOrderForm)
          dispatch(
            Actions.Api.nautilus[API_RESOURCES.LINE_ITEM].list({ order: order?.uri }),
          );

          loadOrderRelatedUsers(dispatch, order);
        } else {
          Alert.error(<FormattedMessage
            id="toaster.error.loadingOrder"
            defaultMessage="Something went wrong while loading the order, please try again."
          />);
        }
      });

    // Fetch resource options for input selections
    dispatch(Actions.Api.nautilus[API_RESOURCES.LOCATION].list());
    dispatch(Actions.Api.nautilus[API_RESOURCES.SHIPPING].list());
    dispatch(Actions.Api.nautilus[API_RESOURCES.USERS].list());
  }, []);

  const loading = !order || !routeUUID;

  return (
    <div>
      {loading ? <Loading /> : <EditRestrictedOrderComponent />}
    </div>
  );
};

export default EditRestrictedOrder;
