import { faArrowDownWideShort, faPencil } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import _capitalize from 'lodash/capitalize';
import PropTypes from 'prop-types';
import FormattedLocalizedCost from 'rapidfab/components/FormattedLocalizedCost';
import Loading from 'rapidfab/components/Loading';
import TruncatedTitle from 'rapidfab/components/TruncatedTitle';
import React from 'react';
import { Image } from 'react-bootstrap';
import { FormattedMessage } from 'react-intl';

const renderModelData = (model, modelFetching) => {
  const noModel = !model || !model?.snapshot_content;
  if (modelFetching && noModel) {
    return (
      <div className="line-item-quote-modal-model-fetching">
        <Loading />
      </div>
    );
  }

  if (noModel) {
    return (
      <div
        className="line-item-quote-modal-no-model"
      >
        <div>
          <FormattedMessage
            id="line_item.no_model"
            defaultMessage="No Model"
          />
        </div>
      </div>
    );
  }

  return (
    <Image src={model.snapshot_content} className="PiecesCardRightDataCardModel" thumbnail />
  );
};

const OrderQuoteLineItemSummaryPreview = ({
  lineItem,
  model,
  modelFetching,
  labelBgColor,
  workflowsByUri,
  labelTextColor,
  handleShowOrderModal,
  renderTotalProcessData,
}) => (
  <div className="d-flex align-items-start justify-content-between mb15">
    <div className="d-flex align-items-center">
      <div className="line-item-quote-modal-model">
        {renderModelData(model, modelFetching)}
      </div>
      <div>
        <p className="mb5 font-weight-500 text-white">
          <TruncatedTitle
            title={lineItem?.name}
            maxTextLength={40}
            inline
          />
          <FontAwesomeIcon
            icon={faPencil}
            color="#0dcaf0"
            className="spacer-left font-weight-500 cursor-pointer"
            onClick={() => handleShowOrderModal(lineItem)}
          />
        </p>

        <div className="d-flex align-items-center mb5 font-weight-500">
          <span
            className="badge badge-sm"
            style={{
              backgroundColor: labelBgColor,
              color: labelTextColor,
            }}
          >
            {_capitalize(lineItem.status)}
          </span>
        </div>
        <div className="font-weight-500">
          <FontAwesomeIcon icon={faArrowDownWideShort} className="spacer-right" />
          <TruncatedTitle
            title={workflowsByUri[lineItem?.workflow]?.name || 'N/A'}
            maxTextLength={48}
            inline
            titleClassName="text-white"
          />
        </div>
      </div>
    </div>
    <div>
      <div className="font-weight-500 text-white">
        <FormattedLocalizedCost value={renderTotalProcessData[lineItem?.uri] || 0} />
      </div>
      <div className="text-right font-weight-100 font-size-13">
        <span className="spacer-left">Qty: {lineItem?.quantity || 'N/A'}</span>
      </div>
    </div>
  </div>
);

OrderQuoteLineItemSummaryPreview.propTypes = {
  handleShowOrderModal: PropTypes.func.isRequired,
  labelBgColor: PropTypes.string.isRequired,
  labelTextColor: PropTypes.string.isRequired,
  lineItem: PropTypes.shape({
    name: PropTypes.string,
    quantity: PropTypes.number,
    status: PropTypes.string,
    uri: PropTypes.string,
    workflow: PropTypes.string,
  }).isRequired,
  model: PropTypes.shape({
    snapshot_content: PropTypes.string,
  }).isRequired,
  modelFetching: PropTypes.bool.isRequired,
  renderTotalProcessData: PropTypes.objectOf(PropTypes.number).isRequired,
  workflowsByUri: PropTypes.objectOf(PropTypes.shape({
    name: PropTypes.string,
  })).isRequired,
};

export default OrderQuoteLineItemSummaryPreview;
