import _keyBy from 'lodash/keyBy';
import { createSelector } from 'reselect';
import _map from 'lodash/map';
import { getProcessSteps } from 'rapidfab/selectors/processStep';
import _find from 'lodash/find';
import _filter from 'lodash/filter';
import { getLineItemQuoteByLineItemUri } from 'rapidfab/selectors/legacy-selectors';

export const getLineItemQuoteProcessStepsForLineItem = createSelector(
  [getLineItemQuoteByLineItemUri, getProcessSteps],
  (lineItemQuote, processSteps) => {
    if (!lineItemQuote) {
      return [];
    }
    const lineItemProcessStepsUris = _map(lineItemQuote.work_steps_quote_details, 'process_step');

    const processStepsByUri = _keyBy(processSteps, 'uri');
    const orderedLineItemProcessSteps = lineItemProcessStepsUris.map(uri =>
      processStepsByUri[uri]).filter(Boolean);

    return orderedLineItemProcessSteps.map(lineItemProcessStep => {
      const workStepQuoteDetails = _find(
        lineItemQuote.work_steps_quote_details,
        { process_step: lineItemProcessStep.uri },
      );
      return {
        ...lineItemProcessStep,
        work_steps_quote_details: workStepQuoteDetails,
      };
    });
  },
);

export const getLineItemQuoteProcessStepsForLineItems = createSelector(
  [state => state, getProcessSteps, (state, lineItems) => lineItems],
  (state, processSteps, lineItems) => {
    const processStepsByUri = _keyBy(processSteps, 'uri');

    const result = {};

    lineItems.forEach(lineItem => {
      const lineItemQuote = getLineItemQuoteByLineItemUri(state, lineItem.uri);

      if (!lineItemQuote) {
        result[lineItem.uri] = [];
        return;
      }

      const lineItemProcessStepsUris = _map(lineItemQuote.work_steps_quote_details, 'process_step');

      const orderedLineItemProcessSteps = lineItemProcessStepsUris
        .map(uri => processStepsByUri[uri])
        .filter(Boolean);

      result[lineItem.uri] = orderedLineItemProcessSteps.map(lineItemProcessStep => {
        const workStepQuoteDetails = _find(
          lineItemQuote.work_steps_quote_details,
          { process_step: lineItemProcessStep.uri },
        );
        return {
          ...lineItemProcessStep,
          work_steps_quote_details: workStepQuoteDetails,
        };
      });
    });

    return result;
  },
);

export const getSeparateLineItemQuoteProcessStepsForLineItem = createSelector(
  [getLineItemQuoteProcessStepsForLineItem],
  processStepsForLineItem => _filter(
    processStepsForLineItem,
    processStep => processStep.work_steps_quote_details.separate,
  ),
);

export const getNonSeparateInQuoteLineItemQuoteProcessStepsForLineItem = createSelector(
  [getLineItemQuoteProcessStepsForLineItem],
  processSteps => _filter(
    processSteps,
    processStep =>
      !processStep.work_steps_quote_details.separate
      && processStep.work_steps_quote_details.in_quote,
  ),
);
