import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Button } from 'react-bootstrap';

import Actions from 'rapidfab/actions';
import { FormattedMessage } from 'rapidfab/i18n';
import { buildFileResourceType } from 'rapidfab/types';
import { BUILD_FILE_STATUSES } from 'rapidfab/constants';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Loading from 'rapidfab/components/Loading';
import { faExclamationCircle } from '@fortawesome/free-solid-svg-icons';

const RunModelDownload = ({
  handleDownload,
  isDownloading,
  buildFiles,
  runName,
  runUUID,
}) => (
  <span>
    Download:{' '}
    {isDownloading ? (
      <Button variant="link" size="sm" disabled>
        <FormattedMessage id="downloading" defaultMessage="Downloading…" />
      </Button>
    ) : (
      <span>
        {buildFiles.map(buildFile => {
          if (buildFile.status === BUILD_FILE_STATUSES.PENDING) {
            // nothing to render when file is not yet uploaded
            return null;
          }
          return (
            <Button
              variant="link"
              size="sm"
              onClick={() => handleDownload(buildFile, runName, runUUID)}
              disabled={buildFile.status === BUILD_FILE_STATUSES.PROCESSING}
            >
              {
                // TODO: Add some tooltip explaining the error reason
                buildFile.status === BUILD_FILE_STATUSES.ERROR
                && (<FontAwesomeIcon icon={faExclamationCircle} className="spacer-right" />)
              }
              {buildFile.status === BUILD_FILE_STATUSES.PROCESSING && (<Loading inline className="spacer-right" />)}
              {buildFile.format.toUpperCase()} {buildFile.format !== 'stl' && '(Read Only)'}
            </Button>
          );
        })}
      </span>
    )}
  </span>
);

RunModelDownload.propTypes = {
  runName: PropTypes.string.isRequired,
  runUUID: PropTypes.string.isRequired,
  handleDownload: PropTypes.func.isRequired,
  isDownloading: PropTypes.bool.isRequired,
  buildFiles: PropTypes.arrayOf(buildFileResourceType).isRequired,
};

const mapDispatchToProps = dispatch => ({
  handleDownload: (buildFile, runName, runUUID) => {
    dispatch(
      Actions.DownloadModel.downloadContent(
        `${runName.slice(0, 16)}-${runUUID}`,
        buildFile.content,
      ),
    );
  },
});

const mapStateToProps = state => ({
  isDownloading: state.downloadModel.downloadingModel,
});

export default connect(mapStateToProps, mapDispatchToProps)(RunModelDownload);
