import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import * as Selectors from 'rapidfab/selectors';

class VisibleFor extends Component {
  /* VisibleFor is used to render child component based on user role
   *
   * You can show/hide content by user state/role (see conditionalProps).
   */
  hasAccess() {
    const {
      isCurrentUserRestricted,
      isCurrentUserServiceProvider,
      isCurrentUserManager,
      restricted,
      unrestricted,
      bureauUserRole,
      serviceProviderRole,
      session,
      logged,
      unlogged,
      manager,
      nonManager,
    } = this.props;

    if (restricted || unrestricted) {
      if (restricted && !isCurrentUserRestricted) {
        return false;
      }

      if (unrestricted && isCurrentUserRestricted) {
        return false;
      }
    }

    if (bureauUserRole || serviceProviderRole) {
      if (bureauUserRole && isCurrentUserServiceProvider) {
        return false;
      }

      if (serviceProviderRole && !isCurrentUserServiceProvider) {
        return false;
      }
    }

    if (logged || unlogged) {
      if (logged && !session) {
        return false;
      }

      if (unlogged && session) {
        return false;
      }
    }

    if (manager || nonManager) {
      if (manager && !isCurrentUserManager) {
        return false;
      }

      if (nonManager && isCurrentUserManager) {
        return false;
      }
    }

    return true;
  }

  render() {
    const {
      children,
      restricted,
      unrestricted,
      bureauUserRole,
      serviceProviderRole,
      logged,
      unlogged,
      manager,
      nonManager,
    } = this.props;

    const mutuallyExclusiveProps = [
      [restricted, unrestricted],
      [logged, unlogged],
      [bureauUserRole, serviceProviderRole],
      [manager, nonManager],
    ];

    if (!mutuallyExclusiveProps.flat().filter(Boolean).length) {
      throw new Error('You have to provide at least one conditional prop for VisibleFor component');
    }

    // Filter out pair with only true values
    if (mutuallyExclusiveProps.filter(a => a[0] && a[1]).length > 0) {
      throw new Error('You provided at least 1 pair of mutually exclusive props in VisibleFor component');
    }

    if (!this.hasAccess()) {
      return null;
    }

    return <>{children}</>;
  }
}

VisibleFor.propTypes = {
  children: PropTypes.node.isRequired,
  restricted: PropTypes.bool,
  unrestricted: PropTypes.bool,
  bureauUserRole: PropTypes.bool,
  serviceProviderRole: PropTypes.bool,
  manager: PropTypes.bool,
  nonManager: PropTypes.bool,
  isCurrentUserRestricted: PropTypes.bool.isRequired,
  isCurrentUserServiceProvider: PropTypes.bool.isRequired,
  isCurrentUserManager: PropTypes.bool.isRequired,
  logged: PropTypes.bool,
  unlogged: PropTypes.bool,
  session: PropTypes.shape({}),
};

VisibleFor.defaultProps = {
  // You MUST use only one of [unrestricted, restricted, unlogged, logged, nonManager, manager]
  unrestricted: false,
  restricted: false,
  bureauUserRole: false,
  serviceProviderRole: false,
  logged: false,
  unlogged: false,
  manager: false,
  nonManager: false,

  session: null,
};

function mapStateToProps(state) {
  return {
    isCurrentUserRestricted: Selectors.isCurrentUserRestricted(state),
    isCurrentUserServiceProvider: Selectors.isServiceProvider(state),
    isCurrentUserManager: Selectors.isSessionManager(state),
    session: Selectors.getSession(state),
  };
}

export default connect(mapStateToProps)(VisibleFor);
