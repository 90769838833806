import { createSelector } from 'reselect';
import { getStateLocationSettings } from 'rapidfab/selectors/baseStateSelectors';
import { getStateResources } from 'rapidfab/selectors/helpers/base';
import _map from 'lodash/map';

// eslint-disable-next-line import/prefer-default-export
export const getLocationSettings = createSelector(
  [getStateLocationSettings, getStateResources],
  (uuids, resources) => _map(uuids, uuid => resources[uuid]),
);
