import PropTypes from 'prop-types';
import CreateNewRunProgressModalContainer from 'rapidfab/containers/modals/CreateNewRunProgressModalContainer';
import useTrackRunCreationStatus from 'rapidfab/hooks/useTrackRunCreationStatus';
import React, { createContext, useCallback, useContext, useState } from 'react';

export const CreateRunProgressContext = createContext(null);

export const CreateRunProgressProvider = ({
  children,
}) => {
  // Get the new Run Creation URI to setup the progress tracking
  const [createRunUri, setCreateRunUri] = useState(null);
  // Get the Selected Printer to Identify the Build Packer Type
  const [selectedPrinter, setSelectedPrinter] = useState(null);
  // Get the Run Creation Payload to attempt the Run Creation (Try Again)
  const [runCreationPayload, setRunCreationPayload] = useState(null);
  // Reset the Build Plate if we create another run
  const [resetBuildPlate, setResetBuildPlate] = useState(false);

  // Track the Run Creation Status (eventStream)
  useTrackRunCreationStatus(createRunUri);

  // Clean up when everything is Done
  const cleanUpContextResources = () => {
    setCreateRunUri(null);
    setSelectedPrinter(null);
    setRunCreationPayload(null);
    setRunCreationPayload(false);
  };

  // Conditionally reset the New Run Build Plate
  const handleResetBuildPlate = useCallback((flag = true) => setResetBuildPlate(flag), []);

  return (
    <CreateRunProgressContext.Provider value={{
      createRunUri,
      setCreateRunUri,
      setSelectedPrinter,
      selectedPrinter,
      setRunCreationPayload,
      resetBuildPlate,
      handleResetBuildPlate,
    }}
    >
      <CreateNewRunProgressModalContainer
        cleanUpContextResources={cleanUpContextResources}
        setCreateRunUri={setCreateRunUri}
        selectedPrinter={selectedPrinter}
        runCreationPayload={runCreationPayload}
        handleResetBuildPlate={handleResetBuildPlate}
      />
      {children}
    </CreateRunProgressContext.Provider>
  );
};

CreateRunProgressProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

export const useCreateRunProgressContext = () => useContext(CreateRunProgressContext);
