import PropTypes from 'prop-types';
import BuildPlateLineItemsModeIncompatiblePieces
  from 'rapidfab/components/records/run/BuildPlateLineItemsModeIncompatiblePieces';
import { API_RESOURCES } from 'rapidfab/constants';
import React from 'react';
import { useSelector } from 'react-redux';

const BuildPlateLineItemsModeIncompatiblePiecesContainer = ({
  isVisible,
  onClose,
  reworkedPieces,
  fetchMorePieces,
  lineItemUri,
}) => {
  const reworkingPiecesFetching = useSelector(state =>
    state.ui.nautilus[API_RESOURCES.PIECES_BY_RUN].list.fetching);

  return (
    <BuildPlateLineItemsModeIncompatiblePieces
      show={isVisible}
      lineItemUri={lineItemUri}
      onClose={onClose}
      incompatiblePiecesList={reworkedPieces}
      reworkingPiecesFetching={reworkingPiecesFetching}
      fetchMorePieces={fetchMorePieces}
    />
  );
};
BuildPlateLineItemsModeIncompatiblePiecesContainer.propTypes = {
  isVisible: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  reworkedPieces: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  fetchMorePieces: PropTypes.func.isRequired,
  lineItemUri: PropTypes.string.isRequired,
};

export default BuildPlateLineItemsModeIncompatiblePiecesContainer;
