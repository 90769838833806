import { faClose, faFile } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import PropTypes from 'prop-types';
import Loading from 'rapidfab/components/Loading';
import {
  FILE_EXTENSIONS,
} from 'rapidfab/constants';
import { getFileExtension, onDropzoneFileRejected } from 'rapidfab/utils/fileUtils';
import { pluralWord } from 'rapidfab/utils/stringUtils';
import React, { useState } from 'react';
import { Button, Container, Modal } from 'react-bootstrap';
import Dropzone from 'react-dropzone';
import { Form } from 'react-final-form';
import { FormattedMessage } from 'react-intl';

const VALID_EXTENSIONS = [FILE_EXTENSIONS.CSV, FILE_EXTENSIONS.TXT];

const MSNavImportsModal = ({
  onHide,
  saveAndUploadMSNavFiles,
  submitting: [isSubmitting],
}) => {
  const [uploadedMSNavFiles, setUploadedMSNavFiles] = useState([]);

  const onDropAccepted = files => {
    setUploadedMSNavFiles([...uploadedMSNavFiles, files].flat());
  };

  const handleSubmit = async () => {
    await saveAndUploadMSNavFiles(uploadedMSNavFiles);
  };

  const onDropValidate = file => {
    const fileExtension = getFileExtension(file);

    if (fileExtension && !VALID_EXTENSIONS.includes(fileExtension)) {
      // Dropzone expects object to be returned in case of error
      // (the result is used in onDropRejected)
      return {
        message: `File must be one of extension: ${VALID_EXTENSIONS.join(', ')}`,
      };
    }
    // Dropzone expects null when everything is fine
    return null;
  };

  return (
    <Form
      onSubmit={handleSubmit}
      render={({ handleSubmit }) => (
        <Modal onHide={onHide} show>
          <Modal.Header closeButton>
            <FormattedMessage
              id="msnavUpload"
              defaultMessage="MSNav Upload"
            />
          </Modal.Header>
          <Modal.Body>
            <Dropzone
              onDropAccepted={onDropAccepted}
              validator={onDropValidate}
              onDropRejected={onDropzoneFileRejected}
              accept={VALID_EXTENSIONS.map(extension => `.${extension}`)}
              multiple
            >
              {({ getRootProps, getInputProps }) => (
                <div {...getRootProps({ role: 'button' })}>
                  <input {...getInputProps()} />
                  <Container fluid>
                    <div className="jumbotron">
                      <h4>Drag and Drop CSV Files Here</h4>
                      <h5>Or click to upload</h5>
                    </div>
                  </Container>
                </div>
              )}
            </Dropzone>
            <div className="mt15">
              {uploadedMSNavFiles.length > 0 && (
                <div className="d-flex justify-content-between align-items-center">
                  <p>
                    <FontAwesomeIcon icon={faFile} />{' '}
                    {uploadedMSNavFiles.length} {pluralWord('file', uploadedMSNavFiles)} uploaded
                  </p>
                  <Button
                    bg="danger"
                    variant="danger"
                    onClick={() => setUploadedMSNavFiles([])}
                    size="xs"
                  >
                    <FontAwesomeIcon icon={faClose} />
                  </Button>
                </div>
              )}
            </div>
          </Modal.Body>
          <Modal.Footer>
            <Button
              bg="danger"
              variant="danger"
              onClick={onHide}
            >
              <FormattedMessage id="close" defaultMessage="Close" />
            </Button>
            <Button
              type="submit"
              onClick={handleSubmit}
              disabled={!uploadedMSNavFiles || !uploadedMSNavFiles?.length || isSubmitting}
            >
              {isSubmitting ? <Loading /> : (
                <FormattedMessage
                  id="button.upload"
                  defaultMessage="Upload"
                />
              )}
            </Button>
          </Modal.Footer>
        </Modal>
      )}
    />
  );
};

export default MSNavImportsModal;

MSNavImportsModal.propTypes = {
  onHide: PropTypes.bool.isRequired,
  saveAndUploadMSNavFiles: PropTypes.func.isRequired,
  isMsnavFileFetching: PropTypes.bool.isRequired,
  submitting: PropTypes.shape({
    isSubmitting: PropTypes.bool,
    setIsSubmitting: PropTypes.func,
  }).isRequired,
  latestMSNavEvent: PropTypes.shape({
    topic: PropTypes.string,
  }).isRequired,
};
