/** @typedef{import("rapidfab/types/tools").ToolLink} ToolLink */
import { createSelector } from 'reselect';
import { getStateToolLinks } from 'rapidfab/selectors/baseStateSelectors';
import {
  getStateResources,
  getPredicate,
} from 'rapidfab/selectors/helpers/base';
import _map from 'lodash/map';
import _filter from 'lodash/filter';

export const getToolLinks = createSelector(
  [getStateToolLinks, getStateResources],
  /**
   * @returns {(ToolLink & {uuid: string})[]}
   */
  (uuids, resources) => _map(uuids, uuid => resources[uuid]),
);

export const getRunToolLinks = createSelector(
  [getPredicate, getToolLinks],
  /**
   * @param {string[]} runUris
   */
  (runUris, links) => _filter(links, ({ run }) => runUris.includes(run)),
);
