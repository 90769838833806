export default () => {
  const orderCustomFieldReferencesOverrides = {
    destination: {
      meta: {
        tooltip: 'We need to know the final destination for any of the parts we might make. If you are planning to send parts out of the U.S., we must gather additional information about how and where the parts will be used in order to comply with U.S. export regulations.',
      },
    },
    verify_non_itar: {
      meta: {
        visible_on: [{
          custom_field_id: 'destination',
          custom_field_values: [
            'a_us_address_for_export',
            'an_address_outside_the_us',
          ],
        }],
      },
      // Always required (when visible)
      required: true,
    },
    mes_child_order_number: {
      meta: {
        visible_on: [{
          custom_field_id: 'build_type',
          custom_field_values: ['tool'],
        }],
      },
    },
    customer_requested_delivery: {
      meta: {
        tooltip: 'BAM will review order and confirm a committed date',
      },
    },
  };

  return orderCustomFieldReferencesOverrides;
};
