import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import {
  Button,
  Card,
  Row,
  Col,
  ListGroup,
  ListGroupItem,
  Badge,
  Dropdown,
  ProgressBar, OverlayTrigger, Tooltip,
} from 'react-bootstrap';
import { FormattedMessage } from 'rapidfab/i18n';
import Loading from 'rapidfab/components/Loading';
import PartAnalysisMethodsPanel from 'rapidfab/components/hawking/PartAnalysisMethodsPanel';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Alert from 'rapidfab/utils/alert';
import {
  CASTOR_COSTING_CONFIG_MANUFACTURABILITY_OPTIONS,
  CASTOR_COSTING_FAILED_STATUSES,
  CASTOR_COSTING_STATUSES,
} from 'rapidfab/constants';
import { CASTOR_COSTING_MAPPING } from 'rapidfab/mappings';
import {
  castorCostingResourceType,
  castorCostingConfigResourceType,
  modelLibraryType,
} from 'rapidfab/types';
import ConfirmationModal from 'rapidfab/components/ConfirmationModal';
import {
  faArrowsRotate,
  faCircleCheck,
  faCircleXmark,
  faEllipsisV,
  faFileArrowDown,
  faFileExcel,
  faFileImport, faFileMedical,
  faPlus,
  faRefresh,
  faShoppingCart,
  faTimes,
} from '@fortawesome/free-solid-svg-icons';

const REFRESH_ENABLED_DELAY = 25_000;

const FontAwesomeIconComponent = ({ color, icon }) => (
  <FontAwesomeIcon color={color} className="spacer-right" icon={icon} />
);

FontAwesomeIconComponent.propTypes = {
  color: PropTypes.string.isRequired,
  icon: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.object,
  ]).isRequired,
};

const CastorCosting = ({
  castorCosting,
  castorCostingConfigs,
  fetchCastorCosting,
  fetchCastorCostingConfig,
  deleteCastorCosting,
  modelLibrary,
  modelUri,
  fetching,
  shoppingCartFetching,
  castorGotoOncloseUrl,
  deleteCastorCostingConfig,
  isZverseFileUploaded,
  model,
  isIntegrationCastorFeatureEnabled,
  castorCartModal,
  handleSaveShoppingCart,
  isRobozeBureauOrderFieldsFeatureEnabled,
}) => {
  const [isLoading, setIsLoading] = useState(false);
  const [isDeleting, setIsDeleting] = useState(false);
  const [visibleConfig, setVisibleConfig] = useState(castorCostingConfigs[0]);
  const [count, setCount] = useState(0);
  const [displayProgressBar, setDisplayProgressBar] = useState(false);
  const [confirmCastorConfigRemove, setConfirmCastorConfigRemove] = useState(false);
  const [isRefreshEnabled, setIsRefreshEnabled] = useState(true);
  const robozeAndCastorFeaturesEnabled = isRobozeBureauOrderFieldsFeatureEnabled && isIntegrationCastorFeatureEnabled;

  const { isCastorConfirmationModalVisible, setIsCastorConfirmationModalVisible } = castorCartModal;

  const isFailedStatus = castorCosting && Object.values(CASTOR_COSTING_FAILED_STATUSES).includes(castorCosting.status);

  const CASTOR_COSTING_STATUS_ICON_MAP = {
    [CASTOR_COSTING_STATUSES.PROJECT_UP]: { color: '#1ca8dd', icon: faFileImport },
    [CASTOR_COSTING_STATUSES.PROJECT_UP_FAILED]: { color: '#dc3545', icon: faCircleXmark },
    [CASTOR_COSTING_STATUSES.FILES_UP]: { color: '#448638', icon: faFileArrowDown },
    [CASTOR_COSTING_STATUSES.FILES_UP_FAILED]: { color: '#dc3545', icon: faFileExcel },
    [CASTOR_COSTING_STATUSES.PROCESSING]: { color: '#1ca8dd', icon: faFileMedical },
    [CASTOR_COSTING_STATUSES.UP_TO_DATE]: { color: '#448638', icon: faCircleCheck },
    [CASTOR_COSTING_STATUSES.REFETCH_NEEDED]: { color: '#1ca8dd', icon: faArrowsRotate },
    [CASTOR_COSTING_STATUSES.FAILED]: { color: '#dc3545', icon: faCircleXmark },
  };

  const generateCastorIconStatus = status => {
    const statusIcon = CASTOR_COSTING_STATUS_ICON_MAP[status];

    if (!statusIcon) {
      return null;
    }

    return <FontAwesomeIconComponent color={statusIcon.color} icon={statusIcon.icon} />;
  };

  useEffect(() => {
    if (castorCosting && castorCosting.status === CASTOR_COSTING_STATUSES.FILES_UP && displayProgressBar === false) {
      setIsRefreshEnabled(false);
      setTimeout(() => {
        setIsRefreshEnabled(true);
      }, REFRESH_ENABLED_DELAY);
    }
  }, [castorCosting, displayProgressBar]);

  const handleFetchCastorCosting = () => {
    if (!modelLibrary.additive.base_material) {
      Alert.error(<FormattedMessage
        id="toaster.error.castorCosting.setBaseMaterialBeforeGeneratingMfgCfg"
        defaultMessage="Set the base material before generating the manufacturing configuration"
      />);
    } else {
      setIsLoading(true);
      setDisplayProgressBar(true);
      fetchCastorCosting(modelLibrary.uri, modelUri)
        .finally(() => setIsLoading(false));
    }
  };

  const handleRemoveCastorConfig = () => {
    setIsDeleting(true);
    deleteCastorCostingConfig(visibleConfig.uuid)
      .finally(() => {
        setIsDeleting(false);
        setConfirmCastorConfigRemove(false);
      });
  };

  const handleRefresh = () => {
    setIsLoading(true);
    fetchCastorCostingConfig(castorCosting.uuid)
      .finally(() => setIsLoading(false));
  };

  const handleDelete = () => {
    setIsDeleting(true);
    deleteCastorCosting(castorCosting.uuid)
      .finally(() => setIsDeleting(false));
  };

  useEffect(() => {
    let intervalId;
    // if progress bar is set to be displayed, start timer
    if (displayProgressBar) {
      intervalId = setInterval(() => {
        setCount(currentCount => currentCount + 1);
      }, 1000);
    }

    // once 30 seconds has passed, clear interval, reset count, and remove
    // progress bar
    if (count > 30) {
      clearInterval(intervalId);
      setCount(0);
      setDisplayProgressBar(false);
    }

    return () => clearInterval(intervalId);
  }, [count, displayProgressBar]);

  // once castorCostingConfigs are loaded, set first as visible
  useEffect(() => {
    if (castorCostingConfigs.length) {
      setVisibleConfig(castorCostingConfigs[0]);
    }
  }, [castorCostingConfigs.length]);

  const display3DPartsNeedMessage = isZverseFileUploaded && !castorCosting && !displayProgressBar;
  const displayCreateMessage = !castorCosting && !displayProgressBar && !display3DPartsNeedMessage;
  const displayRefreshMessage = castorCosting && !castorCostingConfigs.length && !displayProgressBar;
  const displayContent = castorCosting && Boolean(castorCostingConfigs.length) && !displayProgressBar;

  const displayAddConfigLinkButton = castorCosting
    && castorCosting.castor_new_config_url
    && !isFailedStatus;

  if (fetching) return <Loading />;

  const castorCostingStatus = castorCosting?.status && castorCosting?.notes === 'awaitingAnalysis' &&
    !castorCostingConfigs.length ? CASTOR_COSTING_STATUSES.PROCESSING : castorCosting?.status;

  const handleAddScenarioToCart = async (quantity, uri) => {
    await handleSaveShoppingCart(quantity, uri);
    if (!shoppingCartFetching) setIsCastorConfirmationModalVisible(false);

    Alert.success(<FormattedMessage id="toaster.castor_part.added" defaultMessage="The part was successfully added to the cart." />);
  };

  const renderCastorLastColumn = (isNotPrintable, castorCostingConfig) => {
    if (robozeAndCastorFeaturesEnabled) {
      if (isNotPrintable) {
        return (
          <OverlayTrigger
            placement="top"
            overlay={(
              <Tooltip>
                <FormattedMessage id="castor.cannotAddToShoppingCart" defaultMessage="Cannot add Unprintable Scenario to the Shopping Cart" />
              </Tooltip>
            )}
          >
            <Button
              className="spacer-right"
              disabled
              style={{ pointerEvents: 'all' }}
              onClick={() => (robozeAndCastorFeaturesEnabled
                ? handleAddScenarioToCart(visibleConfig.configuration_quantity, visibleConfig.uri)
                : setIsCastorConfirmationModalVisible(true))}
            >
              <FontAwesomeIcon icon={faShoppingCart} />
            </Button>
          </OverlayTrigger>
        );
      }

      return (
        <Button
          className="spacer-right"
          disabled={isNotPrintable}
          onClick={() => (robozeAndCastorFeaturesEnabled
            ? handleAddScenarioToCart(visibleConfig.configuration_quantity, visibleConfig.uri)
            : setIsCastorConfirmationModalVisible(true))}
        >
          <FontAwesomeIcon icon={faShoppingCart} />
        </Button>
      );
    }

    return castorCostingConfig.configuration_years_of_demand;
  };

  return (
    <Row>
      <Col xs={12}>
        <div className="d-flex align-items-center">
          <h4 className="mb0">
            <b>
              <FormattedMessage
                id="hawking.analysisScenarios"
                defaultMessage="Analysis Scenarios"
              />
            </b>
          </h4>
          {castorCostingStatus && (
            <Badge className="castor-status-badge m-l-sm">
              {generateCastorIconStatus(castorCostingStatus)}
              <FormattedMessage {...CASTOR_COSTING_MAPPING[castorCostingStatus]} />
            </Badge>
          )}
          {!castorCosting && modelUri && !display3DPartsNeedMessage && (
            <Button
              className="btn-default m-l-sm"
              disabled={isLoading || isDeleting || displayProgressBar}
              size="xs"
              onClick={handleFetchCastorCosting}
            >
              {isLoading ? <Loading inline /> : <FontAwesomeIcon icon={faPlus} />}
            </Button>
          )}
          {castorCosting && (
            <Button
              className="btn-default m-l-sm castor-btn"
              disabled={isLoading || isDeleting || displayProgressBar || !isRefreshEnabled}
              size="xs"
              onClick={handleRefresh}
            >
              {isLoading ? <Loading inline className="spacer-right" /> : <FontAwesomeIcon icon={faRefresh} className="spacer-right" />}
              <FormattedMessage id="button.refresh" defaultMessage="Refresh" />
            </Button>
          )}
          {displayAddConfigLinkButton && (
            <Button
              disabled={isLoading || isDeleting || displayProgressBar}
              role="link"
              className="btn-primary m-l-sm castor-btn"
              href={`${castorCosting.castor_new_config_url}&back=${castorGotoOncloseUrl}`}
              rel="noopener noreferrer"
              size="xs"
            >
              <FontAwesomeIcon icon={faPlus} className="spacer-right" />
              <FormattedMessage id="button.addScenario" defaultMessage="Add Scenario" />
            </Button>
          )}
          {isFailedStatus && (
            <Button
              className="btn-default m-l-sm castor-btn"
              disabled={isLoading || isDeleting || displayProgressBar}
              size="xs"
              onClick={handleDelete}
            >
              {isDeleting ?
                <Loading inline className="spacer-right" /> :
                <FontAwesomeIcon icon={faTimes} className="spacer-right" />}
              <FormattedMessage id="button.deleteScenario" defaultMessage="Delete Scenario" />
            </Button>
          )}
        </div>
      </Col>
      {displayProgressBar && (
        <Col xs={12}>
          <ProgressBar now={(count / 30) * 100} />
        </Col>
      )}
      {displayCreateMessage && (
        <Col xs={12}>
          <Card bg="light" className="p-a">
            <FormattedMessage
              id="hawking.castor.add"
              defaultMessage="Press the Plus button to initiate part analysis"
            />
          </Card>
        </Col>
      )}
      {display3DPartsNeedMessage && (
        <Col xs={12}>
          <Card bg="light" className="p-a">
            <FormattedMessage
              id="hawking.castor.need3DParts"
              defaultMessage="3D Part files are required to create a Part Analysis Report. Add a 3D file or convert the 2D file using the file conversion service."
            />
          </Card>
        </Col>
      )}
      {displayRefreshMessage && (
        <Col xs={12}>
          <Card bg="light" className="p-a">
            {
              model.polygons < 30000 ? (
                <FormattedMessage
                  id="hawking.castor.refresh"
                  defaultMessage="Part analysis in progress. Results will appear automatically when complete. If no results appear after a few minutes, press the Refresh button"
                />
              ) : (
                <FormattedMessage
                  id="hawking.castor.refresh_complex"
                  defaultMessage="Part analysis in progress. Results will appear automatically when complete. Due to the complexity of this part, the analysis could take longer than usual (up to 30 minutes)."
                />
              )
            }

          </Card>
        </Col>
      )}
      {displayContent && (
        <div className="part-analysis-scenarios d-flex justify-content-between">
          <Col xs={6}>
            <Card bg="light" style={{ margin: '10px 10px 0 0' }}>
              <ListGroup className="mb0">
                <ListGroupItem>
                  <Row>
                    <Col xs={5}>
                      <b>
                        <FormattedMessage id="field.name" defaultMessage="Name" />
                      </b>
                    </Col>
                    <Col xs={2}>
                      <b>
                        <FormattedMessage id="field.material" defaultMessage="Material" />
                      </b>
                    </Col>
                    <Col xs={2}>
                      <b>
                        <FormattedMessage id="field.quantity" defaultMessage="Quantity" />
                      </b>
                    </Col>
                    <Col xs={2}>
                      <b>
                        {robozeAndCastorFeaturesEnabled ? (
                          <FormattedMessage
                            id="modelLibrary.shoppingCart.addToCart"
                            defaultMessage="Add to Cart"
                          />
                        ) : (
                          <FormattedMessage id="years" defaultMessage="Years" />
                        )}
                      </b>
                    </Col>
                  </Row>
                </ListGroupItem>
                {castorCostingConfigs.map(castorCostingConfig => {
                  const isActive = castorCostingConfig.uri === (visibleConfig && visibleConfig.uri);
                  const isNotPrintable = castorCostingConfig.additive_manufacturable
                    === CASTOR_COSTING_CONFIG_MANUFACTURABILITY_OPTIONS.NOT_PRINTABLE ||
                    !castorCostingConfig.additive_manufacturable;

                  return (
                    <ListGroupItem
                      className={isActive && 'active'}
                      onClick={() => setVisibleConfig(castorCostingConfig)}
                      key={castorCostingConfig.uri}
                    >
                      <Row>
                        <Col xs={5}>{castorCostingConfig.configuration_name}</Col>
                        <Col xs={2}>{castorCostingConfig.configuration_material_name}</Col>
                        <Col xs={2}>{castorCostingConfig.configuration_quantity}</Col>
                        <Col xs={2}>
                          {renderCastorLastColumn(isNotPrintable, castorCostingConfig)}
                        </Col>
                        <Col xs={1}>
                          <Dropdown>
                            <Dropdown.Toggle variant="link" className="dropdown-toggle castor-dropdown-toggle">
                              <FontAwesomeIcon
                                bsRole="toggle"
                                role="button"
                                className="ellipsis-menu-icon"
                                icon={faEllipsisV}
                              />
                            </Dropdown.Toggle>
                            <Dropdown.Menu className="dropdown-menu">
                              <Dropdown.Item
                                eventKey="1"
                                // Adding back param(which includes authentise url for the model)
                                // this url is for castor, to use when closing it's page,
                                // castor can redirect back to the given gotoOnclose url(provided in the back param).
                                href={`${castorCostingConfig.configuration_ui_url}&back=${castorGotoOncloseUrl}`}
                                rel="noopener noreferrer"
                              >
                                <FormattedMessage id="button.open" defaultMessage="Open" />
                              </Dropdown.Item>
                              {/* <MenuItem
                                eventKey="2"
                                disabled
                              >
                                <FormattedMessage id="button.download" defaultMessage="Download" />
                              </MenuItem> */}
                              <Dropdown.Item
                                eventKey="3"
                                disabled={isLoading || isDeleting}
                                onClick={() => setConfirmCastorConfigRemove(true)}
                              >
                                {isDeleting ? <Loading inline /> : <FormattedMessage id="button.delete" defaultMessage="Delete" />}
                              </Dropdown.Item>
                            </Dropdown.Menu>
                          </Dropdown>
                        </Col>
                      </Row>
                    </ListGroupItem>
                  );
                })}
              </ListGroup>
            </Card>
          </Col>
          <Col xs={6}>
            {visibleConfig && (
              <PartAnalysisMethodsPanel
                castorCostingConfig={visibleConfig}
                currency={castorCosting.currency}
                castorGotoOncloseUrl={castorGotoOncloseUrl}
                robozeAndCastorFeaturesEnabled={robozeAndCastorFeaturesEnabled}
              />
            )}
          </Col>
        </div>
      )}
      {
        (isCastorConfirmationModalVisible && visibleConfig && modelLibrary) && (
          <ConfirmationModal
            handleCancel={() => setIsCastorConfirmationModalVisible(false)}
            handleConfirm={() => handleAddScenarioToCart(visibleConfig.configuration_quantity, visibleConfig.uri)}
            customConfirmVariant="primary"
            customCancelVariant="danger"
            confirmButtonContent={
              shoppingCartFetching ? (
                <Loading />
              ) : (
                <FormattedMessage id="button.add" defaultMessage="Add" />
              )
            }
            cancelButtonContent={
              <FormattedMessage id="button.cancel" defaultMessage="Cancel" />
            }
            swapButtons
            message={`You are going to add
            ${visibleConfig.configuration_quantity} parts at $${visibleConfig.additive_total_cost} per unit to the cart.
             This is will have a total price of $${(visibleConfig.additive_total_cost * visibleConfig.configuration_quantity).toFixed(2)} for
              this batch of parts. Add to cart?`}
          />
        )
      }
      {
        confirmCastorConfigRemove ? (
          castorCostingConfigs.length === 1 ? (
            <ConfirmationModal
              handleConfirm={() => setConfirmCastorConfigRemove(false)}
              message="A product must have at least one part scenario. To delete this scenario, you must first create a new scenario or delete the entire product."
            />
          ) : (
            <ConfirmationModal
              handleCancel={() => setConfirmCastorConfigRemove(false)}
              handleConfirm={() => handleRemoveCastorConfig()}
              message="Warning: you are about to delete this Part Scenario. Would you like to delete this?"
            />
          )

        ) : null
      }
    </Row>
  );
};
CastorCosting.defaultProps = {
  modelUri: null,
  castorCosting: null,
};
CastorCosting.propTypes = {
  fetching: PropTypes.bool.isRequired,
  fetchCastorCosting: PropTypes.func.isRequired,
  fetchCastorCostingConfig: PropTypes.func.isRequired,
  deleteCastorCosting: PropTypes.func.isRequired,
  modelLibrary: modelLibraryType.isRequired,
  modelUri: PropTypes.string,
  castorCosting: castorCostingResourceType,
  castorCostingConfigs: PropTypes.arrayOf(castorCostingConfigResourceType).isRequired,
  castorGotoOncloseUrl: PropTypes.string.isRequired,
  deleteCastorCostingConfig: PropTypes.func.isRequired,
  isZverseFileUploaded: PropTypes.bool.isRequired,
  model: PropTypes.shape({
    polygons: PropTypes.number,
  }).isRequired,
  isRobozeBureauOrderFieldsFeatureEnabled: PropTypes.bool.isRequired,
  isIntegrationCastorFeatureEnabled: PropTypes.bool.isRequired,
  setAddToCartModalVisible: PropTypes.func.isRequired,
  isCastorConfirmationModalVisible: PropTypes.bool.isRequired,
  castorCartModal: PropTypes.shape({
    isCastorConfirmationModalVisible: PropTypes.bool,
    setIsCastorConfirmationModalVisible: PropTypes.func,
  }).isRequired,
  handleSaveShoppingCart: PropTypes.func.isRequired,
  shoppingCartFetching: PropTypes.bool.isRequired,
};

export default CastorCosting;
