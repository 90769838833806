import React from 'react';

const Check = props => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 40 40"
    {...props}
  >
    <g fill="none" fillRule="evenodd" stroke="none" strokeWidth="1">
      <g fillRule="nonzero" transform="translate(-521 -650)">
        <g transform="translate(521 650)">
          <circle cx="20" cy="20" r="20" fill="#24882B" />
          <path
            fill="#FFF"
            d="M16.25 28.58L8.7 21 10.83 18.89 16.25 24.31 29.2 11.42 31.31 13.55z"
          />
        </g>
      </g>
    </g>
  </svg>
);

export default Check;
