import Actions from 'rapidfab/actions';
import { LIST_BY_URIS_CHUNK_SIZE, PAGINATION_IGNORE_DEFAULT_LIMIT } from 'rapidfab/constants';
import _map from 'lodash/map';
import _chunk from 'lodash/chunk';

// eslint-disable-next-line import/prefer-default-export
export const loadPiecesWithPrints = (dispatch, pieceQueryParams) => {
  // FIXME It must by removed when ".delete" event from event stream is implemented
  //  See [ch17572] to track this
  //  Clear all pieces (some of them may be stale,
  //  when production workflow is changed, for example),
  //  but we still not have support for deleted events
  dispatch(Actions.Api.nautilus.piece.clear('list'));

  return dispatch(Actions.Api.nautilus.piece.list(
    pieceQueryParams,
    { limit: PAGINATION_IGNORE_DEFAULT_LIMIT },
  )).then(piecesResponse => {
    const pieces = piecesResponse.json.resources;
    const pieceUris = _map(pieces, 'uri');
    _chunk(pieceUris, LIST_BY_URIS_CHUNK_SIZE).forEach(pieceUrisChunk => {
      dispatch(
        Actions.Api.nautilus.print.list(
          {
            piece: pieceUrisChunk,
          },
          {
            limit: PAGINATION_IGNORE_DEFAULT_LIMIT,
          },
          {},
          {},
          true,
        ),
      );
    });
  });
};
