import PropTypes from 'prop-types';
import Table from 'rapidfab/components/Tables/Table';
import { ROUTES } from 'rapidfab/constants';
import { getRouteURI } from 'rapidfab/utils/uriUtils';
import { getShortUUID } from 'rapidfab/utils/uuidUtils';
import React from 'react';
import { Link } from 'react-router-dom';

const MaterialsTable = ({ materials, externalMaterialDbFeatureEnabled, renderHeaderView }) => {
  const columns = [
    {
      type: 'custom',
      uid: 'field.id',
      accessor: 'uuid',
      defaultMessage: 'ID',
      resource: 'material',
      Cell: ({ row: { original: data } }) => (
        <Link
          to={getRouteURI(ROUTES.MATERIALS, null, { uuid: data.uuid }, true)}
        >
          {getShortUUID(data.uri)}
        </Link>
      ),
    },
    {
      type: 'text',
      uid: 'field.name',
      accessor: 'name',
      defaultMessage: 'Name',
      isSortable: true,
    },
    {
      type: 'caps',
      uid: 'field.type',
      accessor: 'type',
      defaultMessage: 'Type',
      isSortable: true,
    },
    {
      type: 'color',
      uid: 'field.color',
      accessor: 'color',
      defaultMessage: 'Color',
      isSortable: true,
    },
    !externalMaterialDbFeatureEnabled && (
      {
        type: 'bool',
        id: 'field.thirdParty',
        accessor: 'third_party_fulfillment',
        defaultMessage: 'Third Party',
        isSortable: true,
      }
    ),
  ];

  return (
    <>
      {renderHeaderView()}

      <Table
        tableID="materials"
        data={materials}
        columns={columns}
        isFilteringEnabled={false}
        withDefaultPagination={false}
        isManualSoringEnabled={false}
        initialSortedColumn="name"
        initialSortedDesc={false}
        isUpdatedColumnShown={false}
      />
    </>
  );
};

MaterialsTable.propTypes = {
  materials: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  externalMaterialDbFeatureEnabled: PropTypes.bool.isRequired,
  row: PropTypes.shape({
    original: PropTypes.shape({
      uuid: PropTypes.string,
      uri: PropTypes.string,
    }),
  }).isRequired,
  renderHeaderView: PropTypes.func.isRequired,
};

export default MaterialsTable;
