import commonDA from './common/da.json';
import buildDA from './buildPacking/da.json';
import castorDA from './castor/da.json';
import commentDA from './comment/da.json';
import conversionDA from './conversion/da.json';
import keysDA from './keys/da.json';
import locationDA from './location/da.json';
import materialDA from './material/da.json';
import modelDA from './model/da.json';
import modelerDA from './modeler/da.json';
import orderDA from './order/da.json';
import pieceDA from './piece/da.json';
import printDA from './print/da.json';
import prioritiesDA from './priorities/da.json';
import processDA from './process/da.json';
import ratingDA from './rating/da.json';
import runDA from './run/da.json';
import serviceDA from './service/da.json';
import shipmentDA from './shipment/da.json';
import stockDA from './stock/da.json';
import taskDA from './task/da.json';
import workDA from './work/da.json';

import commonDE from './common/de.json';
import buildDE from './buildPacking/de.json';
import castorDE from './castor/de.json';
import commentDE from './comment/de.json';
import conversionDE from './conversion/de.json';
import keysDE from './keys/de.json';
import locationDE from './location/de.json';
import materialDE from './material/de.json';
import modelDE from './model/de.json';
import modelerDE from './modeler/de.json';
import orderDE from './order/de.json';
import pieceDE from './piece/de.json';
import printDE from './print/de.json';
import prioritiesDE from './priorities/de.json';
import processDE from './process/de.json';
import ratingDE from './rating/de.json';
import runDE from './run/de.json';
import serviceDE from './service/de.json';
import shipmentDE from './shipment/de.json';
import stockDE from './stock/de.json';
import taskDE from './task/de.json';
import workDE from './work/de.json';

import commonEN from './common/en.json';
import buildEN from './buildPacking/en.json';
import castorEN from './castor/en.json';
import commentEN from './comment/en.json';
import conversionEN from './conversion/en.json';
import keysEN from './keys/en.json';
import locationEN from './location/en.json';
import materialEN from './material/en.json';
import modelEN from './model/en.json';
import modelerEN from './modeler/en.json';
import orderEN from './order/en.json';
import pieceEN from './piece/en.json';
import printEN from './print/en.json';
import prioritiesEN from './priorities/en.json';
import processEN from './process/en.json';
import ratingEN from './rating/en.json';
import runEN from './run/en.json';
import serviceEN from './service/en.json';
import shipmentEN from './shipment/en.json';
import stockEN from './stock/en.json';
import taskEN from './task/en.json';
import workEN from './work/en.json';

import commonFR from './common/fr.json';
import buildFR from './buildPacking/fr.json';
import castorFR from './castor/fr.json';
import commentFR from './comment/fr.json';
import conversionFR from './conversion/fr.json';
import keysFR from './keys/fr.json';
import locationFR from './location/fr.json';
import materialFR from './material/fr.json';
import modelFR from './model/fr.json';
import modelerFR from './modeler/fr.json';
import orderFR from './order/fr.json';
import pieceFR from './piece/fr.json';
import printFR from './print/fr.json';
import prioritiesFR from './priorities/fr.json';
import processFR from './process/fr.json';
import ratingFR from './rating/fr.json';
import runFR from './run/fr.json';
import serviceFR from './service/fr.json';
import shipmentFR from './shipment/fr.json';
import stockFR from './stock/fr.json';
import taskFR from './task/fr.json';
import workFR from './work/fr.json';

import commonJA from './common/ja.json';
import buildJA from './buildPacking/ja.json';
import castorJA from './castor/ja.json';
import commentJA from './comment/ja.json';
import conversionJA from './conversion/ja.json';
import keysJA from './keys/ja.json';
import locationJA from './location/ja.json';
import materialJA from './material/ja.json';
import modelJA from './model/ja.json';
import modelerJA from './modeler/ja.json';
import orderJA from './order/ja.json';
import pieceJA from './piece/ja.json';
import printJA from './print/ja.json';
import prioritiesJA from './priorities/ja.json';
import processJA from './process/ja.json';
import ratingJA from './rating/ja.json';
import runJA from './run/ja.json';
import serviceJA from './service/ja.json';
import shipmentJA from './shipment/ja.json';
import stockJA from './stock/ja.json';
import taskJA from './task/ja.json';
import workJA from './work/ja.json';

export const mappingsDA = Object.assign(
  commonDA,
  buildDA,
  castorDA,
  commentDA,
  conversionDA,
  keysDA,
  locationDA,
  materialDA,
  modelDA,
  modelerDA,
  orderDA,
  pieceDA,
  printDA,
  prioritiesDA,
  processDA,
  ratingDA,
  runDA,
  serviceDA,
  shipmentDA,
  stockDA,
  taskDA,
  workDA,
);

export const mappingsDE = Object.assign(
  commonDE,
  buildDE,
  castorDE,
  commentDE,
  conversionDE,
  keysDE,
  locationDE,
  materialDE,
  modelDE,
  modelerDE,
  orderDE,
  pieceDE,
  printDE,
  prioritiesDE,
  processDE,
  ratingDE,
  runDE,
  serviceDE,
  shipmentDE,
  stockDE,
  taskDE,
  workDE,
);

export const mappingsEN = Object.assign(
  commonEN,
  buildEN,
  castorEN,
  commentEN,
  conversionEN,
  keysEN,
  locationEN,
  materialEN,
  modelEN,
  modelerEN,
  orderEN,
  pieceEN,
  printEN,
  prioritiesEN,
  processEN,
  ratingEN,
  runEN,
  serviceEN,
  shipmentEN,
  stockEN,
  taskEN,
  workEN,
);

export const mappingsFR = Object.assign(
  commonFR,
  buildFR,
  castorFR,
  commentFR,
  conversionFR,
  keysFR,
  locationFR,
  materialFR,
  modelFR,
  modelerFR,
  orderFR,
  pieceFR,
  printFR,
  prioritiesFR,
  processFR,
  ratingFR,
  runFR,
  serviceFR,
  shipmentFR,
  stockFR,
  taskFR,
  workFR,
);

export const mappingsJA = Object.assign(
  commonJA,
  buildJA,
  castorJA,
  commentJA,
  conversionJA,
  keysJA,
  locationJA,
  materialJA,
  modelJA,
  modelerJA,
  orderJA,
  pieceJA,
  printJA,
  prioritiesJA,
  processJA,
  ratingJA,
  runJA,
  serviceJA,
  shipmentJA,
  stockJA,
  taskJA,
  workJA,
);
