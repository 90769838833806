import React from 'react';
import { useSelector } from 'react-redux';
import * as Selectors from 'rapidfab/selectors';
import { FEATURES } from 'rapidfab/constants';
import TosLogo from 'rapidfab/components/TosLogo';

const TosLogoContainer = props => {
  const isHawkingUser = useSelector(state =>
    Selectors.isFeatureEnabled(state, FEATURES.HAWKING_DEPLOYMENT));

  return (
    <TosLogo
      {...props}
      isHawkingUser={isHawkingUser}
    />
  );
};

export default TosLogoContainer;
