import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Button } from 'react-bootstrap';

import DeleteButtonTitle from 'rapidfab/components/DeleteButtonTitle';
import CancelOrDeleteModal from 'rapidfab/components/CancelOrDeleteModal';
import Loading from 'rapidfab/components/Loading';

const DeleteButton = ({
  disabled,
  handleOpen,
  handleConfirm,
  modalType,
  resourceName,
}) => (
  <div>
    <Button
      onClick={() => handleOpen('delete')}
      type="button"
      disabled={disabled}
      variant="danger"
    >
      {
        disabled ?
          <Loading />
          :
          <DeleteButtonTitle resourceName={resourceName} />
      }
    </Button>
    {modalType && (
      <CancelOrDeleteModal
        modalType={modalType}
        handleConfirm={handleConfirm}
        handleOpen={handleOpen}
      />
    )}
  </div>
);

DeleteButton.defaultProps = {
  disabled: false,
};

DeleteButton.propTypes = {
  disabled: PropTypes.bool,
  handleConfirm: PropTypes.func.isRequired,
  handleOpen: PropTypes.func.isRequired,
  modalType: PropTypes.string.isRequired,
  resourceName: PropTypes.string.isRequired,
};

class DeleteButtonContainer extends Component {
  constructor(props) {
    super(props);

    this.state = { modalType: '' };
    this.handleConfirm = this.handleConfirm.bind(this);
    this.handleOpen = this.handleOpen.bind(this);
  }

  handleConfirm() {
    const { modalType } = this.state;
    if (modalType === 'cancel') {
      this.props.onCancel();
    } else if (modalType === 'delete') {
      this.props.onDelete();
    }
    this.handleOpen('');
  }

  handleOpen(modalType) {
    this.setState({ modalType });
  }

  render() {
    return (
      <DeleteButton
        {...this.props}
        {...this.state}
        handleConfirm={this.handleConfirm}
        handleOpen={this.handleOpen}
      />
    );
  }
}

DeleteButtonContainer.defaultProps = {
  onCancel: null,
  disabled: false,
};

DeleteButtonContainer.propTypes = {
  disabled: PropTypes.bool,
  onCancel: PropTypes.func,
  onDelete: PropTypes.func.isRequired,
  resourceName: PropTypes.string.isRequired,
};

export default DeleteButtonContainer;
