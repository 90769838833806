import { createSelector } from 'reselect';
import { getStateResources } from 'rapidfab/selectors/helpers/base';
import _map from 'lodash/map';
import _keyBy from 'lodash/keyBy';
import * as baseStateSelectors from 'rapidfab/selectors/baseStateSelectors';

export const getMaterialPurchaseOrders = createSelector(
  [baseStateSelectors.getStateMaterialPurchaseOrders, getStateResources],
  (uuids, resources) => _map(uuids, uuid => resources[uuid]),
);

export const getMaterialPurchaseOrdersByUri = createSelector(
  [getMaterialPurchaseOrders],
  purchaseOrders => _keyBy(purchaseOrders, 'uri'),
);
