import { createSelector } from 'reselect';
import { PRINT_ERROR_STATUSES } from 'rapidfab/constants';
import { getPredicate } from './base';
import { getPrints } from '../print';
import { getProcessSteps } from '../processStep';
import { getRunsByUri } from '../run';

// !!!ATTENTION!!! ALL prints are used here (including Remanufactured ones)
// eslint-disable-next-line import/prefer-default-export
export const getNextPrintWithProcessStep = createSelector(
  [getPredicate, getPrints, getRunsByUri, getProcessSteps],
  (print, prints, runsByUri, processSteps) => {
    const nextProcessStepPosition = print.process_step_position + 1;

    // Find all next prints for next process step position
    // We may have multiple prints with the same process_step_position
    // for example, when print is remanufactured
    const bestCandidates = prints.filter(p =>
      p.piece === print.piece && p.process_step_position === nextProcessStepPosition,
    );

    if (!bestCandidates.length) {
      return null;
    }

    const bestCandidate =
      // Try to find first non-error print
      bestCandidates.find(p => !PRINT_ERROR_STATUSES.includes(p.status))
      // OR use just first print (if all statuses are incomplete)
      || bestCandidates[0];

    const candidateRun = runsByUri[bestCandidate.run];

    const processStep = processSteps.find(pstep => pstep.uri === bestCandidate.process_step);

    // Patch print with process step entity
    let nextRunWithProcessSteps = {
      ...bestCandidate,
      process_step: processStep,
    };
    // Patch print with workstation type and workstation name entity
    if (candidateRun) {
      nextRunWithProcessSteps = {
        ...nextRunWithProcessSteps,
        workstation_type_name: candidateRun.workstation_type_name,
        workstation_name: candidateRun.workstation_name,
      };
    }

    return nextRunWithProcessSteps;
  },
);
