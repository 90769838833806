import { useState, useEffect } from 'react';

/* This hook is strictly linked to the "Product" Navigate Restrictions if we have Incomplete Line Items,
   but on the feature we would make it more flexible and use in the other places of the application.
   No such places for now, so let it be for the one component only. */

const usePreventNavigate = (incompleteLineItems, isRestrictedUser, isReviewingOrder) => {
  const [leaveConfirmation, setLeaveConfirmation] = useState(false);
  const [cannotRedirect, setCannotRedirect] = useState(false);
  const [triggerNextToRender, setTriggerNextToRender] = useState(false);
  const [linkToForward, setLinkToForward] = useState(null);

  const handleRedirectRestrictedConditions = () => {
    if (incompleteLineItems?.length && isReviewingOrder) {
      setCannotRedirect(true);
    }

    if (!incompleteLineItems?.length || !isReviewingOrder) {
      setCannotRedirect(false);
    }
  };

  const handleRedirectConditions = () => {
    setCannotRedirect(!!incompleteLineItems?.length);
  };

  useEffect(() => {
    if (isRestrictedUser) {
      handleRedirectRestrictedConditions();
    } else {
      handleRedirectConditions();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [incompleteLineItems, isReviewingOrder, isRestrictedUser]);

  const listener = event => {
    if (event.target.getAttribute('href')) {
      /* Menu navigation is also <a> and has "href",
         we should not show any modals while just clicking on the navigation item. */
      if (event.target.id.includes('uxNav') || event.target.classList.contains('nav-link')) return;
      event.preventDefault();
      setLinkToForward(event.target.hash);
      setLeaveConfirmation(true);
    }
  };

  useEffect(() => {
    if (cannotRedirect && !triggerNextToRender) {
      window.addEventListener('click', listener);
    } else {
      window.removeEventListener('click', listener);
    }
    return () => {
      window.removeEventListener('click', listener);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [cannotRedirect, window, triggerNextToRender]);

  return [leaveConfirmation, setLeaveConfirmation, setLinkToForward, setTriggerNextToRender, linkToForward];
};

export default usePreventNavigate;
