import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import Actions from 'rapidfab/actions';
import * as Selectors from 'rapidfab/selectors';
import ServiceProvidersComponent from 'rapidfab/components/organize/ServiceProviders';
import { API_RESOURCES } from 'rapidfab/constants';
import PropTypes from 'prop-types';

const ServiceProvidersContainer = props => {
  const { fetching } = useSelector(
    state => state.ui.nautilus[API_RESOURCES.SERVICE_PROVIDER].list,
  );
  const providers = useSelector(state => (
    props.type === 'vendor'
      ? Selectors.getVendorServiceProviders(state)
      : Selectors.getServiceProviders(state)
  ),
  );

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(
      Actions.Api.nautilus[API_RESOURCES.SERVICE_PROVIDER].list({
        service_provider_type: props.type,
      }),
    );
  }, [dispatch, props.type]);

  return (
    <ServiceProvidersComponent
      {...props}
      fetching={fetching}
      providers={providers}
    />
  );
};

ServiceProvidersContainer.propTypes = {
  type: PropTypes.string.isRequired,
};

export default ServiceProvidersContainer;
