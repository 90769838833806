import React from 'react';
import PropTypes from 'prop-types';
import Toggle from 'react-toggle';

const RunMaterialToggle = ({ checked, disabled, onChange }) => (
  <span>
    <span className="toggleTitle" role="button" onClick={() => !disabled && onChange()} tabIndex={0}>Pieces</span>
    <Toggle
      checked={checked}
      icons={false}
      onChange={onChange}
      className="toggleSlider"
      disabled={disabled}
    />
    <span className="toggleTitle" role="button" onClick={() => !disabled && onChange()} tabIndex={0}>Line Items</span>
  </span>
);

RunMaterialToggle.defaultProps = {
  checked: false,
  disabled: false,
};

RunMaterialToggle.propTypes = {
  checked: PropTypes.bool,
  disabled: PropTypes.bool,
  onChange: PropTypes.func.isRequired,
};

export default RunMaterialToggle;
