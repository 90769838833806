import React from 'react';
import { FormattedMessage } from 'rapidfab/i18n';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faCalendarCheck, faChartSimple,
  faCog, faCubes,
  faListCheck, faTableCellsLarge,
} from '@fortawesome/free-solid-svg-icons';

export const Plan = () => (
  <span>
    <FontAwesomeIcon icon={faListCheck} fixedWidth /> <FormattedMessage id="plan" defaultMessage="Plan" />
  </span>
);

export const Track = () => (
  <span>
    <FontAwesomeIcon icon={faCalendarCheck} fixedWidth /> <FormattedMessage id="track" defaultMessage="Track" />
  </span>
);

export const Inventory = () => (
  <span>
    <FontAwesomeIcon icon={faTableCellsLarge} fixedWidth />{' '}
    <FormattedMessage id="inventory" defaultMessage="Inventory" />
  </span>
);

export const Assets = () => (
  <span>
    <FontAwesomeIcon icon={faCubes} fixedWidth />{' '}
    <FormattedMessage id="assets" defaultMessage="Assets" />
  </span>
);

export const Organize = () => (
  <span>
    <FontAwesomeIcon icon={faCog} fixedWidth />{' '}
    <FormattedMessage id="organize" defaultMessage="Organize" />
  </span>
);

export const Analytics = () => (
  <span>
    <FontAwesomeIcon icon={faChartSimple} fixedWidth />{' '}
    <FormattedMessage id="analytics" defaultMessage="Analytics" />
  </span>
);
