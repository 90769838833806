import React from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import formatNumber from 'rapidfab/utils/numberFormatting';
import LOCALE_MAP from 'rapidfab/constants/localeMap';
import truncateLargeNumber from 'rapidfab/utils/truncateLargeNumber';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';

const FormattedNumberByLocales = ({ number, shouldTruncateNumber }) => {
  const { locale } = useSelector(state => state.i18n);
  const formattedLocale = LOCALE_MAP[locale];
  const numberValidForTruncating = shouldTruncateNumber && (String(number).length > 10);

  const formattedNumber = numberValidForTruncating ? truncateLargeNumber(formatNumber(number, formattedLocale))
    : formatNumber(number, formattedLocale);

  return (
    numberValidForTruncating ? (
      <OverlayTrigger
        placement="top"
        overlay={(
          <Tooltip>
            <div>{formatNumber(number, formattedLocale)}</div>
          </Tooltip>
        )}
      >
        <div>{formattedNumber}</div>
      </OverlayTrigger>
    )
      : (<div>{formattedNumber}</div>)
  );
};

FormattedNumberByLocales.propTypes = {
  number: PropTypes.number.isRequired,
  shouldTruncateNumber: PropTypes.bool,
};

FormattedNumberByLocales.defaultProps = {
  shouldTruncateNumber: false,
};

export default FormattedNumberByLocales;
