import { createSelector } from 'reselect';
import {
  getStateAnatomicalModelAssemblies,
  getStateAnatomicalModelParts,
  getStateAnatomicalModels,
} from 'rapidfab/selectors/baseStateSelectors';
import { getPredicate, getStateResources } from 'rapidfab/selectors/helpers/base';
import _map from 'lodash/map';
import _groupBy from 'lodash/groupBy';
import _filter from 'lodash/filter';

export const getAnatomicalModels = createSelector(
  [getStateAnatomicalModels, getStateResources],
  (uuids, resources) => _map(uuids, uuid => resources[uuid]),
);

export const getAnatomicalModelAssemblies = createSelector(
  [getStateAnatomicalModelAssemblies, getStateResources],
  (uuids, resources) => _map(uuids, uuid => resources[uuid]),
);

export const getAnatomicalModelAssembliesForModel = createSelector(
  [getPredicate, getAnatomicalModelAssemblies],
  (model, assemblies) => {
    if (!model) {
      return [];
    }
    return _filter(assemblies, { anatomical_model: model.uri });
  },
);

export const getAnatomicalModelParts = createSelector(
  [getStateAnatomicalModelParts, getStateResources],
  (uuids, resources) => _map(uuids, uuid => resources[uuid]),
);

export const getAnatomicalModelPartsByAssemblyUri = createSelector(
  [getAnatomicalModelParts],
  parts => _groupBy(parts, 'anatomical_model_assembly'),
);
