import React from 'react';
import { Route, Routes } from 'react-router-dom';
import PropTypes from 'prop-types';
import AdminSettingsContainer from 'rapidfab/containers/admin/AdminSettingsContainer';
import { FEATURES } from '../constants';
import PrivateRoute from './PrivateRoute';
import BannerContainer from '../containers/admin/BannerContainer';
import LabelsContainer from '../containers/admin/LabelsContainer';
import RecalculationContainer from '../containers/admin/RecalculationContainer';
import AdminUsersContainer from '../containers/admin/AdminUsersContainer';
import AdminUserGroupsContainer from '../containers/admin/AdminUserGroupsContainer';
import AdminContainer from '../containers/admin/AdminContainer';
import CustomFieldsContainer from '../containers/admin/CustomFieldsContainer';
import GuidelinesEngineContainer from '../containers/admin/GuidelinesEngineContainer';
import AnalysisSettings from '../containers/admin/hawking/AnalysisSettings';
import { ROUTER_PERMISSION } from '../constantsRouter';
import NotFound from '../components/404';
import GuidelineContainer from '../containers/admin/GuidelineContainer';
import MaterialTestsContainer from '../containers/admin/MaterialTestsContainer';
import SecureFileCheckoutsContainer from '../containers/admin/SecureFileCheckoutsContainer';

const AdminRoutes = ({ session }) =>
  (
    <Routes>
      <Route
        index
        path="/"
        element={<PrivateRoute session={session} component={AdminContainer} permission={ROUTER_PERMISSION.MANAGER} />}
      />
      <Route
        path="/banner"
        element={(
          <PrivateRoute
            routeIsHiddenFromRestrictedUsers
            session={session}
            component={BannerContainer}
            permission={ROUTER_PERMISSION.MANAGER}
            features={{
              [FEATURES.HAWKING_DEPLOYMENT]: false,
            }}
          />
        )}
      />
      <Route
        path="/labels"
        element={<PrivateRoute session={session} component={LabelsContainer} permission={ROUTER_PERMISSION.MANAGER} />}
      />
      <Route
        path="/recalculation"
        element={(
          <PrivateRoute
            routeIsHiddenFromRestrictedUsers
            session={session}
            component={RecalculationContainer}
            permission={ROUTER_PERMISSION.MANAGER}
            features={{
              [FEATURES.HAWKING_DEPLOYMENT]: false,
            }}
          />
        )}
      />
      <Route
        path="/analysis-settings"
        element={(
          <PrivateRoute
            routeIsHiddenFromRestrictedUsers
            session={session}
            component={AnalysisSettings}
            permission={ROUTER_PERMISSION.MANAGER}
            features={{
              [FEATURES.HAWKING_DEPLOYMENT]: true,
            }}
          />
        )}
      />
      <Route
        path="/settings"
        element={(
          <PrivateRoute
            routeIsHiddenFromRestrictedUsers
            session={session}
            component={AdminSettingsContainer}
            permission={ROUTER_PERMISSION.MANAGER}
            features={{
              [FEATURES.HAWKING_DEPLOYMENT]: false,
            }}
          />
        )}
      />
      <Route
        path="/users"
        element={(
          <PrivateRoute
            routeIsHiddenFromRestrictedUsers
            session={session}
            component={AdminUsersContainer}
            permission={ROUTER_PERMISSION.MANAGER}
          />
        )}
      />
      <Route
        path="user-groups"
        element={(
          <PrivateRoute
            routeIsHiddenFromRestrictedUsers
            session={session}
            component={AdminUserGroupsContainer}
            permission={ROUTER_PERMISSION.MANAGER}
            decidingFeatures={[FEATURES.PREP_WORKFLOW, FEATURES.GROUP_QUALIFICATIONS]}
          />
        )}
      />
      <Route
        path="/custom-fields"
        element={(
          <PrivateRoute
            routeIsHiddenFromRestrictedUsers
            session={session}
            component={CustomFieldsContainer}
            permission={ROUTER_PERMISSION.ALL}
          />
        )}
      />
      <Route
        path="/material-tests"
        element={(
          <PrivateRoute
            routeIsHiddenFromRestrictedUsers
            session={session}
            component={MaterialTestsContainer}
            permission={ROUTER_PERMISSION.ALL}
          />
        )}
      />
      <Route
        path="/secure-file-checkout"
        element={(
          <PrivateRoute
            routeIsHiddenFromRestrictedUsers
            session={session}
            component={SecureFileCheckoutsContainer}
            permission={ROUTER_PERMISSION.ALL}
          />
        )}
      />
      <Route
        path="/guidelines-engine"
        element={(
          <PrivateRoute
            routeIsHiddenFromRestrictedUsers
            session={session}
            component={GuidelinesEngineContainer}
            features={[FEATURES.GUIDELINES_ENGINE]}
            permission={ROUTER_PERMISSION.MANAGER}
          />
        )}
      />
      <Route
        path="/records/guideline"
        element={(
          <PrivateRoute
            routeIsHiddenFromRestrictedUsers
            session={session}
            component={GuidelineContainer}
            features={[FEATURES.GUIDELINES_ENGINE]}
            permission={ROUTER_PERMISSION.BUREAU_ONLY}
          />
        )}
      />
      <Route
        path="/records/guideline/:uuid"
        element={(
          <PrivateRoute
            routeIsHiddenFromRestrictedUsers
            session={session}
            component={GuidelineContainer}
            features={[FEATURES.GUIDELINES_ENGINE]}
            permission={ROUTER_PERMISSION.BUREAU_ONLY}
          />
        )}
      />
      <Route
        path="*"
        element={<NotFound />}
      />
    </Routes>
  );

AdminRoutes.propTypes = {
  session: PropTypes.shape({}).isRequired,
};

export default AdminRoutes;
