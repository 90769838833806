import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Button } from 'react-bootstrap';
import { FormattedMessage } from 'rapidfab/i18n';
import SelectSingle from 'rapidfab/components/forms/SelectSingle';
import { userResourceType } from 'rapidfab/types';

const EditOwner = ({
  users,
  isBureauOwner,
  newOwnerUri,
  setNewOwnerUri,
  onPublish,
}) => {
  const [isPublishingMode, setIsPublishingMode] = useState(false);

  if (isPublishingMode) {
    return (
      <div>
        <FormattedMessage
          id="hawking.confirmPublish"
          defaultMessage="Are you sure you want to make this model library item viewable by everyone in your bureau?"
        />
        <div className="mt15 pull-right">
          <Button size="xs" variant="default" onClick={() => setIsPublishingMode(false)}>
            <FormattedMessage id="button.cancel" defaultMessage="Cancel" />
          </Button>
          <Button
            className="ml15"
            size="xs"
            variant="primary"
            onClick={onPublish}
          >
            <FormattedMessage id="yes" defaultMessage="Yes" />
          </Button>
        </div>
      </div>
    );
  }

  return (
    <div>
      <b><FormattedMessage id="field.user" defaultMessage="User" />:</b>
      <SelectSingle
        name="modelLibraryOwner"
        className="mb15"
        data={users}
        labelKey="name"
        valueKey="uri"
        value={newOwnerUri}
        handleOnChange={event => setNewOwnerUri(event.target.value)}
        required
        imitateOnChangeEvent
      />
      {isBureauOwner
        ? (
          <div className="mt15">
            <FormattedMessage
              id="hawking.removeFromCompanyLibrary"
              defaultMessage="Currently the model library is owned by the bureau.  Changing the owner to a user will remove the model library item from the company library."
            />
          </div>
        )
        : (
          <>
            <hr />
            <Button
              size="xs"
              variant="primary"
              onClick={() => setIsPublishingMode(true)}
            >
              Publish to Company Library
            </Button>
          </>
        )}
    </div>
  );
};
EditOwner.defaultProps = {
  newOwnerUri: null,
};
EditOwner.propTypes = {
  users: PropTypes.arrayOf(userResourceType).isRequired,
  isBureauOwner: PropTypes.bool.isRequired,
  newOwnerUri: PropTypes.string,
  setNewOwnerUri: PropTypes.func.isRequired,
  onPublish: PropTypes.func.isRequired,
};

export default EditOwner;
