import {
  Card,
  Form,
  FormControl,
  FormGroup,
  InputGroup,
} from 'react-bootstrap';
import React from 'react';
import { weekDays } from 'rapidfab/constants/dates';
import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { FormattedMessage } from 'react-intl';
import utcOffSet from 'rapidfab/utils/utcTimezoneOffSet';
import { FormControlSelect } from 'rapidfab/components/formTools';
import { faMinusCircle, faPlus, faPlusCircle, faTrash } from '@fortawesome/free-solid-svg-icons';

const LaborAvailableSchedule = ({
  handleChange,
  handleSetNonStopTime,
  nonStopEnabled,
  scheduleTime,
  workSchedule,
  setWorkSchedule,
  handleSetWorkday,
  workDays,
  UTC_TimeZone,
  setUTC_Timezone,
  isCustomDarkCardStyle,
}) => {
  const handleRenderTimeInputs = time => (
    <>
      {
        weekDays.map(day => {
          const isAvailableWorkday = nonStopEnabled ? true : workDays.includes(day);

          return (
            <div className="d-flex justify-content-start align-items-baseline w-100 laborWeekday" data-cy={day}>
              <p
                className="laborWeekdays"
              >{day}:
              </p>
              {
                isAvailableWorkday ?
                  (
                    <div className="d-flex justify-content-start align-items-baseline w-full">
                      <FormGroup className="form-group d-flex align-items-center w-full mr15" controlId="startTime">
                        <FormControl
                          data-cy="start-input"
                          name="LaborScheduleStartTime"
                          className="laborTimeInput w-full"
                          onChange={event => handleChange(event, day, 'start')}
                          required
                          disabled={nonStopEnabled}
                          type="time"
                          value={nonStopEnabled ? '00:00' : time[day]?.start}
                        />
                        <InputGroup.Text
                          className={nonStopEnabled || !isAvailableWorkday ?
                            'laborTimeInputText laborTimeInputTextDisabled' :
                            'laborTimeInputText'}
                        >
                          <span className="laborTimeInputTextAddition">start</span>
                        </InputGroup.Text>
                      </FormGroup>

                      <FormGroup className="form-group d-flex align-items-center mr15 w-full" controlId="startTime">
                        <FormControl
                          data-cy="end-input"
                          name="startTime"
                          className="laborTimeInput w-full"
                          onChange={event => handleChange(event, day, 'finish')}
                          required
                          disabled={nonStopEnabled}
                          type="time"
                          value={nonStopEnabled ? '23:59' : time[day]?.finish}
                        />
                        <InputGroup.Text
                          className={nonStopEnabled || !isAvailableWorkday ?
                            'laborTimeInputText laborTimeInputTextDisabled' :
                            'laborTimeInputText'}
                        >
                          <span className="laborTimeInputTextAddition">end</span>
                        </InputGroup.Text>
                      </FormGroup>
                      <FontAwesomeIcon
                        className="ml15"
                        size="lg"
                        icon={faTrash}
                        role="button"
                        onClick={() => handleSetWorkday(day)}
                      />
                    </div>
                  ) : (
                    <div className="d-flex justify-content-between align-items-center form-group w-full">
                      <i>
                        <FormattedMessage
                          id="notAWorkingDay"
                          defaultMessage="This is not a working day"
                        />
                      </i>
                      <FontAwesomeIcon
                        className="ml15"
                        size="lg"
                        icon={faPlus}
                        role="button"
                        onClick={() => handleSetWorkday(day)}
                      />
                    </div>
                  )
              }
            </div>
          );
        })
      }
    </>
  );

  return (
    <Card bg={`${isCustomDarkCardStyle ? '' : 'dark'}`} className={`mb15 ${isCustomDarkCardStyle ? 'custom-darken-modal--card' : ''}`} data-cy="labor-schedule">
      <Card.Header className={`${isCustomDarkCardStyle ? 'pd-exp custom-darken-modal--card-header' : 'pd-exp inverse'}`}>
        <div className="d-flex align-items-center justify-content-between">
          <FormattedMessage id="laborAvailableSchedule" defaultMessage="Labor Available Schedule" />
          <div className="d-flex align-items-center">
            <FontAwesomeIcon
              data-cy="labor-schedule-add-btn"
              className={`pointer ${isCustomDarkCardStyle ? 'text-white' : 'removeLaborData'}`}
              icon={workSchedule ? faMinusCircle : faPlusCircle}
              size="lg"
              onClick={setWorkSchedule}
            />
          </div>
        </div>
      </Card.Header>
      {
        workSchedule ? (
          <div className={`${isCustomDarkCardStyle ? '' : 'card-body-wrapper'}`}>
            <Card.Body className={`${isCustomDarkCardStyle ? 'custom-darken-modal--card-body' : ''}`}>
              <Form.Check
                className="mr15 mb30"
                inline
                name="enable_non_stop"
                checked={nonStopEnabled}
                onClick={handleSetNonStopTime}
                type="checkbox"
                label={(
                  <FormattedMessage
                    id="alwaysOnSchedule"
                    defaultMessage="This workstation’s schedule is always on, operated 24/7."
                  />
                )}
              />
              <Form.Label htmlFor="UTC-timezone">Please use your correct timezone for scheduling</Form.Label>
              <FormControlSelect
                required
                aria-label="UTC-timezone"
                className="mr15 mb30"
                onChange={event => { setUTC_Timezone(event.target.value); }}
                value={UTC_TimeZone}
                id="UTC-timezone"
              >
                {utcOffSet && Object.keys(utcOffSet).map(currentKey =>
                  <option key={currentKey} value={utcOffSet[currentKey]}>{currentKey}</option>)}
              </FormControlSelect>
              {handleRenderTimeInputs(scheduleTime)}
            </Card.Body>
          </div>
        ) : null
      }
    </Card>
  );
};

LaborAvailableSchedule.defaultProps = {
  isCustomDarkCardStyle: false,
};

LaborAvailableSchedule.propTypes = {
  handleChange: PropTypes.func.isRequired,
  handleSetNonStopTime: PropTypes.func.isRequired,
  nonStopEnabled: PropTypes.bool.isRequired,
  scheduleTime: PropTypes.shape({}).isRequired,
  workSchedule: PropTypes.bool.isRequired,
  setWorkSchedule: PropTypes.func.isRequired,
  handleSetWorkday: PropTypes.func.isRequired,
  workDays: PropTypes.arrayOf(PropTypes.string).isRequired,
  UTC_TimeZone: PropTypes.number.isRequired,
  setUTC_Timezone: PropTypes.func.isRequired,
  isCustomDarkCardStyle: PropTypes.bool,
};

export default LaborAvailableSchedule;
