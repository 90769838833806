import React from 'react';
import { useSelector } from 'react-redux';
import { isFeatureEnabled,
  getBureau,
  getSession,
  isCurrentUserRestricted,
  getBureauBranding } from 'rapidfab/selectors';
import { FEATURES } from 'rapidfab/constants';
import NavbarBranding from 'rapidfab/components/navbar/NavbarBranding';

const NavbarBrandingContainer = props => {
  const isHawkingUser = useSelector(state => isFeatureEnabled(state, FEATURES.HAWKING_DEPLOYMENT));
  const isAuthentisePDMUser = useSelector(state => isFeatureEnabled(state, FEATURES.AUTHENTISE_PDM));
  const isStanleyXUser = useSelector(state => isFeatureEnabled(state, FEATURES.STANLEY_X_DEPLOYMENT));
  const isDanfossUser = useSelector(state => isFeatureEnabled(state, FEATURES.ORDER_BUSINESS_SEGMENT));
  const isDigitalDesignWarehouseFeatureEnabled =
    useSelector(state => isFeatureEnabled(state, FEATURES.DIGITAL_DESIGN_WAREHOUSE));
  const isRestrictedUser = useSelector(isCurrentUserRestricted);
  const bureau = useSelector(state => getBureau(state));
  const session = useSelector(state => getSession(state));
  const bureauBranding = useSelector(getBureauBranding);

  const selected = {
    isHawkingUser,
    isAuthentisePDMUser,
    isStanleyXUser,
    isDanfossUser,
    bureau,
    session,
    isRestrictedUser,
    isDigitalDesignWarehouseFeatureEnabled,
    bureauBranding,
  };

  return (
    <NavbarBranding {...props} {...selected} />
  );
};

export default NavbarBrandingContainer;
