import dayjs from 'dayjs';
import React, { useEffect, useRef, useState } from 'react';
import { calculateAverageFromArray } from 'rapidfab/utils/mathUtils';
import { Chart } from 'chart.js';
import _map from 'lodash/map';
import _debounce from 'lodash/debounce';
import { CHART_COLORS, API_RESOURCES } from 'rapidfab/constants';
import PropTypes from 'prop-types';
import { Button } from 'react-bootstrap';
import FormRow from 'rapidfab/components/FormRow';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCalendar, faStopwatch, faTimeline } from '@fortawesome/free-solid-svg-icons';
import { useSelector } from 'react-redux';
import { DateInput } from './forms/DateInput';

const SENSOR_DATA_LINE_CHART_UNITS = {
  DAY: 'day',
  MONTH: 'month',
  HOUR: 'minute',
};

const SensorDataLineChart = ({
  data: sensorData,
  initialLineChartStartDate = dayjs().subtract(2, 'day'),
  initialLineChartEndDate = dayjs().add(2, 'day'),
  selectedSubLocation,
  setStateCallback = null,
}) => {
  const [lineChartStartDate, setLineChartStartDate] = useState(initialLineChartStartDate);
  const [lineChartEndDate, setLineChartEndDate] = useState(initialLineChartEndDate);

  const isLoading = useSelector(state =>
    state.ui.nautilus[API_RESOURCES.SENSOR_DATA].list.fetching ||
    state.ui.nautilus[API_RESOURCES.MATERIAL_BATCH_SENSOR_DATA].list.fetching);

  const [timeUnitState, setTimeUnitState] = useState({
    unit: SENSOR_DATA_LINE_CHART_UNITS.DAY,
    specificity: 'YYYY-MM-DD',
    xAxisLabelFormat: 'ddd D',
  });

  const timeUnits = [];

  const temperatureValues = [];
  const humidityValues = [];

  const difference = dayjs(lineChartEndDate).diff(lineChartStartDate, timeUnitState.unit);

  const isMinutesSpecificity = timeUnitState.unit === SENSOR_DATA_LINE_CHART_UNITS.HOUR;

  useEffect(() => {
    if (setStateCallback) {
      // This callback is used for passing state from this component (SensorDataLineChart.jsx), upwards.
      setStateCallback({
        lineChartStartDate,
        lineChartEndDate,
        difference: dayjs(lineChartEndDate).diff(lineChartStartDate, 'hour'),
      });
    }
  }, [lineChartStartDate, lineChartEndDate, difference]);

  useEffect(() => {
    if (isMinutesSpecificity) {
      timeUnits.push(dayjs(lineChartStartDate).startOf('hour'));

      for (let index = 0; index <= (difference / 60); index++) {
        const nextHour = dayjs(timeUnits[index]).add(1, 'hour');
        timeUnits.push(nextHour);
      }
    } else {
      timeUnits.push(lineChartStartDate);

      for (let index = 0; index <= difference; index++) {
        const nextTimeUnit = dayjs(timeUnits[index]).add(1, timeUnitState.unit);
        timeUnits.push(nextTimeUnit);
      }
    }
  }, [lineChartStartDate,
    lineChartEndDate,
    selectedSubLocation,
    JSON.stringify(timeUnitState)]);

  // Averaged data sensor-data values, based on time specificity.
  const averagedValues = {};

  if (sensorData) {
    // Get all the values for the time unit specificity.
    sensorData.forEach(sensorDataItem => {
      const timeUnitAccessor = dayjs(sensorDataItem.timestamp).format(timeUnitState.specificity);

      averagedValues[timeUnitAccessor] = {
        ...averagedValues[timeUnitAccessor],
        temperatureValues: averagedValues[timeUnitAccessor]?.temperatureValues.length ?
          [...averagedValues[timeUnitAccessor].temperatureValues, sensorDataItem.temperature] :
          [sensorDataItem.temperature],
        humidityValues: averagedValues[timeUnitAccessor]?.humidityValues.length ?
          [...averagedValues[timeUnitAccessor].humidityValues, sensorDataItem.humidity] :
          [sensorDataItem.humidity],
      };
    });

    // Average the values for the adjustable time unit specificity.
    Object.entries(averagedValues).forEach(([unitOfTime, sensorValuesObjectItem]) => {
      averagedValues[unitOfTime] = {
        ...sensorValuesObjectItem,
        averagedTemperatureValue: calculateAverageFromArray(sensorValuesObjectItem.temperatureValues),
        averagedHumidityValue: calculateAverageFromArray(sensorValuesObjectItem.humidityValues),
      };
    });
  }

  const chartRef = useRef(null);

  useEffect(() => {
    if (isLoading) return;

    if (timeUnits.length) {
      timeUnits.forEach(unitOfTime => {
        const averagedSensorValues = averagedValues[dayjs(unitOfTime).format(timeUnitState.specificity)];

        if (averagedSensorValues?.averagedTemperatureValue) {
          temperatureValues.push(averagedSensorValues?.averagedTemperatureValue);
          humidityValues.push(averagedSensorValues?.averagedHumidityValue);
        } else {
          temperatureValues.push(null);
          humidityValues.push(null);
        }
      });
    }
  }, [timeUnits,
    isLoading,
    lineChartStartDate,
    lineChartEndDate,
    selectedSubLocation,
    JSON.stringify(timeUnitState)]);

  const handleUpdateChartDebounced = _debounce(() => {
    chartRef.current.data.datasets[0].data = temperatureValues;
    chartRef.current.data.datasets[1].data = humidityValues;

    chartRef.current.data.labels = _map(timeUnits, unitOfTime =>
      dayjs(unitOfTime).format(timeUnitState.xAxisLabelFormat));

    chartRef.current.update();
  }, 300);

  useEffect(() => {
    handleUpdateChartDebounced();

    return () => {
      handleUpdateChartDebounced.cancel();
    };
  }, [lineChartStartDate,
    lineChartEndDate,
    selectedSubLocation,
    JSON.stringify(timeUnitState)]);

  useEffect(() => {
    const context = chartRef.current.getContext('2d');
    chartRef.current = new Chart(context, {
      type: 'line',
      data: {
        labels: _map(timeUnits, unitOfTime => dayjs(unitOfTime).format(timeUnitState.xAxisLabelFormat)),
        datasets: [
          {
            label: 'Temperature',
            data: temperatureValues,
            fill: false,
            borderColor: CHART_COLORS.RED,
            tension: 0.1,
          },
          {
            label: 'Humidity',
            data: humidityValues,
            fill: false,
            borderColor: CHART_COLORS.BLUE,
            tension: 0.1,
          },
        ],
      },
      options: {
        scales: {
          y: {
            min: 0,
            max: 100,
          },
        },
      },
    });

    return () => {
      chartRef.current.destroy();
    };
  }, []);

  return (
    <div>
      <div className="d-flex justify-content-center align-items-center mb15">
        <Button
          id="month"
          size="sm"
          variant="dark"
          onClick={() =>
            setTimeUnitState({
              ...timeUnitState,
              unit: SENSOR_DATA_LINE_CHART_UNITS.MONTH,
              specificity: 'YYYY-MM',
              xAxisLabelFormat: 'MMMM',
            })}
          disabled={timeUnitState.unit === SENSOR_DATA_LINE_CHART_UNITS.MONTH}
        >
          <FontAwesomeIcon icon={faCalendar} className="spacer-right" />
          Month
        </Button>
        <Button
          id="day"
          size="sm"
          variant="dark"
          className="spacer-left"
          onClick={() =>
            setTimeUnitState({
              ...timeUnitState,
              unit: SENSOR_DATA_LINE_CHART_UNITS.DAY,
              specificity: 'YYYY-MM-DD',
              xAxisLabelFormat: 'ddd D',
            })}
          disabled={timeUnitState.unit === SENSOR_DATA_LINE_CHART_UNITS.DAY}
        >
          <FontAwesomeIcon icon={faTimeline} className="spacer-right" />
          Day
        </Button>
        <Button
          id="hour"
          size="sm"
          variant="dark"
          className="spacer-left"
          onClick={() => {
            setTimeUnitState({
              ...timeUnitState,
              unit: SENSOR_DATA_LINE_CHART_UNITS.HOUR,
              specificity: 'YYYY-MM-DD HH:mm',
              xAxisLabelFormat: 'HH:mm',
            });

            setLineChartEndDate(dayjs(lineChartStartDate).add(1, 'day'));
          }}
          disabled={timeUnitState.unit === SENSOR_DATA_LINE_CHART_UNITS.HOUR}
        >
          <FontAwesomeIcon icon={faStopwatch} className="spacer-right" />
          Hour
        </Button>
      </div>
      {/* Main Chart */}
      <canvas ref={chartRef} />
      <div
        id="lineChartDatePicker"
        className="w-100 d-flex align-items-center spacer-top"
      >
        <FormRow
          defaultMessage="Start"
          className="flex-1"
        >
          <DateInput
            value={dayjs(lineChartStartDate).format('YYYY-MM-DD')}
            name="line_chart_start_date"
            onChange={event => {
              /* If `minute` timeUnits is selected, we only want to display values for that 24h
                                     day range, otherwise we may potentially overload the chart with too many values. */
              if (timeUnitState.unit === SENSOR_DATA_LINE_CHART_UNITS.HOUR) {
                setLineChartEndDate(dayjs(event.target.value)
                  .add(1, 'day'));
              }

              setLineChartStartDate(event.target.value);
            }}
          />
        </FormRow>
        <FormRow
          defaultMessage="End"
          className="flex-1 spacer-left"
        >
          <DateInput
            value={dayjs(lineChartEndDate).format('YYYY-MM-DD')}
            name="line_chart_end_date"
            onChange={event => {
              setLineChartEndDate(event.target.value);
            }}
            disabled={timeUnitState.unit === SENSOR_DATA_LINE_CHART_UNITS.HOUR}
          />
        </FormRow>
      </div>
    </div>
  );
};

export default SensorDataLineChart;

SensorDataLineChart.propTypes = {
  data: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  initialLineChartStartDate: PropTypes.string.isRequired,
  initialLineChartEndDate: PropTypes.string.isRequired,
  selectedSubLocation: PropTypes.shape({}).isRequired,
  setStateCallback: PropTypes.func.isRequired,
};
