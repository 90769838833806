import _get from 'lodash/get';
import _set from 'lodash/set';
import { MANUFACTURER_FORM } from 'rapidfab/constants/forms';
import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import Actions from 'rapidfab/actions';
import Manufacturer from 'rapidfab/components/records/manufacturer';
import { API_RESOURCES, ROUTES } from 'rapidfab/constants';
import { getRouteURI } from 'rapidfab/utils/uriUtils';
import { getRouteUUID, getUUIDResource } from 'rapidfab/selectors';
import Alert from 'rapidfab/utils/alert';
import { FormattedMessage } from 'react-intl';
import { extractUuid } from 'rapidfab/utils/uuidUtils';

function redirect() {
  window.location.hash = getRouteURI(ROUTES.MANUFACTURERS);
}

const redirectToNewManufacturer = uri => {
  window.location = getRouteURI(ROUTES.MANUFACTURER_EDIT, { uuid: extractUuid(uri) });
};

const ManufacturerContainer = props => {
  const uuid = useSelector(getRouteUUID);
  const manufacturer = useSelector(state => getUUIDResource(state, uuid));
  const isSubmitting = useSelector(state => state.ui.nautilus[API_RESOURCES.MANUFACTURER].post.fetching
    || state.ui.nautilus[API_RESOURCES.MANUFACTURER].put.fetching
    || state.ui.nautilus[API_RESOURCES.MANUFACTURER].delete.fetching);

  const selected = {
    uuid,
    manufacturer,
    isSubmitting,
  };

  const dispatch = useDispatch();

  const onInitialize = currentUUID => {
    dispatch(Actions.Api.nautilus[API_RESOURCES.MANUFACTURER].get(currentUUID));
  };
  const onUpdate = (payload, currentUUID) =>
    dispatch(
      Actions.Api.nautilus[API_RESOURCES.MANUFACTURER].put(currentUUID, payload))
      .then(() => Alert.success(
        <FormattedMessage
          id="toaster.manufacturer.updated"
          defaultMessage="Manufacturer {uuid} successfully updated."
          values={{ uuid: currentUUID }}
        />,
      ));
  const onCreate = payload => {
    const updatedPayload = { ...payload };

    // Set empty string for null fields
    // To prevent validation errors
    MANUFACTURER_FORM.NULL_FIELDS.forEach(
      fieldName => {
        const field = _get(payload, fieldName);
        if (!field) {
          _set(updatedPayload, fieldName, '');
        }
      },
    );

    return dispatch(Actions.Api.nautilus[API_RESOURCES.MANUFACTURER].post(updatedPayload))
      .then(response => {
        Alert.success(
          <FormattedMessage
            id="toaster.manufacturer.created"
            defaultMessage="Manufacturer successfully created."
          />,
        );
        if (response) redirectToNewManufacturer(response.headers.location);
      });
  };
  const onDelete = currentUUID => {
    if (currentUUID) {
      dispatch(Actions.Api.nautilus[API_RESOURCES.MANUFACTURER].delete(currentUUID))
        .then(() => Alert.success(
          <FormattedMessage
            id="toaster.manufacturer.deleted"
            defaultMessage="Manufacturer {uuid} successfully deleted."
            values={{ uuid: currentUUID }}
          />,
        ))
        .then(redirect);
    }
  };

  useEffect(() => {
    if (uuid) {
      onInitialize(uuid);
    }
  }, [uuid]);

  const onSubmit = payload => {
    if (uuid) {
      onUpdate(payload, uuid);
    } else {
      onCreate(payload, uuid);
    }
  };

  return (
    <Manufacturer
      {...props}
      {...selected}
      onSubmit={onSubmit}
      onDelete={onDelete}
    />
  );
};

export default ManufacturerContainer;
