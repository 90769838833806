import React, { memo, useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import {
  Button,
  Col,
  Container,
  Row,
  FormControl,
  FormLabel,
  Card,
  FormGroup,
  ListGroupItem,
  ListGroup,
} from 'react-bootstrap';

import BreadcrumbNav from 'rapidfab/components/BreadcrumbNav';
import { FormattedMessage } from 'rapidfab/i18n';
import UsersContainer from 'rapidfab/containers/admin/UsersContainer';
import _clone from 'lodash/clone';
import { extractUuid } from 'rapidfab/utils/uuidUtils';
import CancelOrDeleteModal from 'rapidfab/components/CancelOrDeleteModal';
import Loading from 'rapidfab/components/Loading';
import { Link } from 'react-router-dom';
import { getRouteURI } from 'rapidfab/utils/uriUtils';
import { ROUTES } from 'rapidfab/constants';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faExternalLink,
  faEye,
  faInfoCircle,
  faPencil,
  faSave,
  faTrash,
} from '@fortawesome/free-solid-svg-icons';
import RCTooltip from 'rc-tooltip';

const GROUP_STATES = {
  EDITING: 'editing',
  SAVING: 'saving',
  SAVING_MEMBER: 'saving-member',
  DELETE_CONFIRMATION: 'delete-confirmation',
  DELETING: 'deleting',
};

const GROUP_TYPES = {
  CUSTOM: 'CUSTOM',
  SYSTEM: 'SYSTEM',
};

const UsersList = memo(({ isShown,
  selectedGroup,
  filter,
  filterChange,
  userSelectionToggled,
  readOnly }) => {
  const scrollPosition = document.body.scrollHeight / 3;
  useEffect(() => {
    if (window.scrollY >= scrollPosition) {
      window.scrollTo({ top: 0, behavior: 'smooth' });
    }
  }, [scrollPosition, selectedGroup]);

  if (!isShown) {
    return null;
  }

  const selectedUsersURIs = selectedGroup?.members || [];

  return (
    <Card bg="dark" className="scrollableUserList">
      <Card.Header className="pd-exp inverse stickyHeader">{`${selectedGroup.name} group members`}</Card.Header>
      <Card.Body>
        {
          !readOnly && (
            <FormGroup className="form-group">
              <FormLabel>Search</FormLabel>{' '}
              <FormControl
                type="text"
                value={filter || ''}
                onChange={event =>
                  filterChange(event.target.value)}
              />
            </FormGroup>
          )
        }

        <UsersContainer
          multiSelect
          handleSelectionChange={userSelectionToggled}
          filter={filter}
          selectedUsersURIs={selectedUsersURIs}
          readOnly={readOnly}
        />
      </Card.Body>
    </Card>
  );
});

UsersList.propTypes = {
  isShown: PropTypes.bool.isRequired,
  selectedGroup: PropTypes.shape({
    name: PropTypes.string,
    members: PropTypes.arrayOf(PropTypes.string),
  }).isRequired,
  filter: PropTypes.string,
  filterChange: PropTypes.func,
  userSelectionToggled: PropTypes.func,
  readOnly: PropTypes.bool,
};

UsersList.defaultProps = {
  filter: '',
  filterChange: () => {},
  userSelectionToggled: () => {},
  readOnly: false,
};

const CustomGroups = memo(({
  groups: customGroups,
  saveGroup,
  saveGroupMembers,
  deleteGroup,
  handleShowGroup,
  groupType,
  fetching,
}) => {
  const [filter, setFilter] = useState('');
  const [groups, setGroups] = useState([]);
  const [selectedGroupIndex, setSelectedGroupIndex] = useState(null);
  const [selectedGroupState, setSelectedGroupState] = useState(null);
  const [editingGroupName, setEditingGroupName] = useState('');

  useEffect(() => {
    setGroups(customGroups);
  }, [JSON.stringify(customGroups)]);

  useEffect(() => {
    if (groupType !== GROUP_TYPES.CUSTOM) {
      setSelectedGroupIndex(null);
      setSelectedGroupState(null);
    }
  }, [groupType]);

  const selectGroup = index => {
    handleShowGroup(GROUP_TYPES.CUSTOM);
    setSelectedGroupIndex(index);
    setSelectedGroupState(GROUP_STATES.EDITING);
    setEditingGroupName(groups[index] && groups[index].name);
  };

  useEffect(() => {
    if (selectedGroupIndex) {
      // In case groups list is changed (new group is added) - refresh group selection data
      selectGroup(selectedGroupIndex);
    }
  }, [groups.length]);

  const addNewGroup = () => {
    selectGroup(groups.length);
    setGroups([
      ...groups,
      {
        name: '',
        members: [],
      },
    ]);
  };

  const handleFilterChange = changedFilterValue => {
    setFilter(changedFilterValue);
  };

  const userSelectionToggled = async userUri => {
    const selectedGroup = groups[selectedGroupIndex];

    const changedGroupMembers = [...selectedGroup.members];
    const memberIndex = changedGroupMembers.indexOf(
      userUri,
    );

    const isMemberAddedToTheGroup = memberIndex === -1;

    if (isMemberAddedToTheGroup) {
      changedGroupMembers.push(userUri);
    } else {
      changedGroupMembers.splice(memberIndex, 1);
    }

    setSelectedGroupState(GROUP_STATES.SAVING_MEMBER);
    await saveGroupMembers(
      extractUuid(selectedGroup.uri),
      {
        members: changedGroupMembers,
      },
    );
    setSelectedGroupState(GROUP_STATES.EDITING);
  };

  const cancelGroupSelect = () => {
    if (!groups[selectedGroupIndex].uri) {
      const updatedGroups = _clone(groups);
      updatedGroups.splice(selectedGroupIndex, 1);
      setGroups(updatedGroups);
    }
    setSelectedGroupIndex(null);
    setSelectedGroupState(null);
  };

  const handleSaveGroup = () => {
    setSelectedGroupState(GROUP_STATES.SAVING);
    const selectedGroup = groups[selectedGroupIndex];
    saveGroup(
      selectedGroup.uri ? extractUuid(selectedGroup.uri) : null,
      {
        name: editingGroupName,
      },
    ).then(() => {
      setSelectedGroupState(null);
      setSelectedGroupIndex(null);
    }).catch(() => {
      // Return back to edit mode on error
      setSelectedGroupState(GROUP_STATES.EDITING);
    });
  };

  const onDeleteGroupClick = index => {
    setSelectedGroupIndex(index);
    setSelectedGroupState(GROUP_STATES.DELETE_CONFIRMATION);
  };

  const handleDeleteGroup = () => {
    setSelectedGroupState(GROUP_STATES.DELETING);
    deleteGroup(extractUuid(groups[selectedGroupIndex].uri)).finally(() => {
      setSelectedGroupIndex(null);
      setSelectedGroupState(null);
    });
  };

  const isAnyGroupEditing = selectedGroupIndex !== null;
  const isAddingNewGroup = groups[selectedGroupIndex] && !groups[selectedGroupIndex].uri;

  const selectedGroup = selectedGroupIndex !== null && groups[selectedGroupIndex];

  return (
    <div>
      <h2 className="mb30">
        <FormattedMessage id="customGroups" defaultMessage="Custom Groups" />
        <RCTooltip
          placement="top"
          destroyTooltipOnHide
          overlayInnerStyle={{ padding: '10px', wordBreak: 'break-word' }}
          mouseLeaveDelay={0.4}
          overlay={(
            <p className="tooltipDefaultWidth">These are user created/removable permissions groups.
              (See <a href="https://authentise.zendesk.com/hc/en-us/articles/4402895769620-Group-Qualifications-User-Groups">Link to Zendesk</a>
              &nbsp;for more details). Bureau Managers can create/edit/delete Custom Groups
              and can add/remove users from them.
            </p>
          )}
        >
          <FontAwesomeIcon icon={faInfoCircle} className="spacer-left" />
        </RCTooltip>
      </h2>

      <Row className="p-b-md">
        <Col xs={12}>
          <div>
            <Button onClick={addNewGroup} disabled={isAnyGroupEditing}>
              <FormattedMessage
                id="addNewGroup"
                defaultMessage="Add New Group"
              />
            </Button>
          </div>
        </Col>
      </Row>

      {
        fetching ? (
          <Loading inline className="mb30 spacer-left" />
        ) : (
          <Row className="mb30">
            <Col xs={12} sm={6}>
              <ListGroup>
                {groups.map((group, groupIndex) => {
                  const isGroupSelected = selectedGroupIndex === groupIndex;
                  const isGroupEditing =
                    isGroupSelected && selectedGroupState === GROUP_STATES.EDITING;
                  const isGroupEditingOrSaving =
                    isGroupSelected &&
                    [
                      GROUP_STATES.EDITING,
                      GROUP_STATES.SAVING,
                      GROUP_STATES.SAVING_MEMBER,
                    ].includes(selectedGroupState);
                  const isGroupDeleting =
                    isGroupSelected && selectedGroupState === GROUP_STATES.DELETING;
                  const isGroupSaving =
                    isGroupSelected && selectedGroupState === GROUP_STATES.SAVING;
                  const groupKey = `${extractUuid(group.uri)}-${groupIndex}`;
                  return (
                    <ListGroupItem
                      className={`clearfix ${isGroupSelected ? 'active' : ''}`}
                      key={groupKey}
                    >
                      {isGroupEditingOrSaving ? (
                        <FormGroup className="form-inline mb0">
                          <FormControl
                            type="text"
                            placeholder="Group Name"
                            value={editingGroupName || ''}
                            onChange={({ target: { value } }) =>
                              setEditingGroupName(value)}
                            disabled={!isGroupEditing}
                          />
                          <div className="pull-right">
                            <Button
                              className="ml5"
                              variant="primary"
                              size="sm"
                              onClick={handleSaveGroup}
                              disabled={!isGroupEditing}
                            >
                              {isGroupSaving && (
                                <>
                                  <Loading inline />{' '}
                                </>
                              )}
                              <FontAwesomeIcon icon={faSave} />
                            </Button>
                            <Button
                              className="ml5"
                              variant="default"
                              size="sm"
                              onClick={cancelGroupSelect}
                              disabled={!isGroupEditing}
                            >
                              <FormattedMessage
                                id="button.cancel"
                                defaultMessage="Cancel"
                              />
                            </Button>
                          </div>
                        </FormGroup>
                      ) : (
                        <>
                          <span>{group.name}</span>
                          <div className="pull-right">
                            <Button
                              variant="primary"
                              size="xs"
                              onClick={() => selectGroup(groupIndex)}
                              disabled={isAnyGroupEditing}
                            >
                              <FontAwesomeIcon icon={faPencil} />
                            </Button>
                            <Button
                              className="ml5"
                              variant="danger"
                              size="xs"
                              onClick={() => onDeleteGroupClick(groupIndex)}
                              disabled={isAnyGroupEditing}
                            >
                              {isGroupDeleting && (
                                <>
                                  <Loading inline />{' '}
                                </>
                              )}
                              <FontAwesomeIcon icon={faTrash} />
                            </Button>
                          </div>
                        </>
                      )}
                    </ListGroupItem>
                  );
                })}
              </ListGroup>
            </Col>
            <Col xs={12} sm={6}>
              <UsersList
                isShown={
                  groupType === GROUP_TYPES.CUSTOM &&
                  !!selectedGroup &&
                  // And not a new one
                  !isAddingNewGroup &&
                  // And when group is in editing state only (or saving members)
                  [GROUP_STATES.EDITING, GROUP_STATES.SAVING_MEMBER].includes(
                    selectedGroupState,
                  )
                }
                selectedGroup={selectedGroup}
                userSelectionToggled={userSelectionToggled}
                filter={filter}
                filterChange={handleFilterChange}
              />
            </Col>
          </Row>
        )
      }

      {selectedGroupState === GROUP_STATES.DELETE_CONFIRMATION && (
        <CancelOrDeleteModal
          modalType="delete"
          handleConfirm={() => handleDeleteGroup()}
          // `handleOpen` actually triggered on modal close
          handleOpen={cancelGroupSelect}
        />
      )}
    </div>
  );
});

CustomGroups.propTypes = {
  groups: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  saveGroup: PropTypes.func.isRequired,
  deleteGroup: PropTypes.func.isRequired,
  saveGroupMembers: PropTypes.func.isRequired,
  handleShowGroup: PropTypes.func.isRequired,
  groupType: PropTypes.string.isRequired,
  fetching: PropTypes.bool.isRequired,
};

const SystemGroups = memo(({
  groups: systemGroups,
  getUsersByGroup,
  handleShowGroup,
  groupType,
  fetching,
}) => {
  const [groups, setGroups] = useState([]);
  const [selectedGroupIndex, setSelectedGroupIndex] = useState(null);

  useEffect(() => {
    if (groupType !== GROUP_TYPES.SYSTEM) {
      setSelectedGroupIndex(null);
    }
  }, [groupType]);

  useEffect(() => {
    setGroups(systemGroups);
  }, [JSON.stringify(systemGroups)]);

  const selectGroup = (index, groupUri) => {
    handleShowGroup(GROUP_TYPES.SYSTEM);
    getUsersByGroup(groupUri);
    setSelectedGroupIndex(index);
  };

  useEffect(() => {
    if (selectedGroupIndex) {
      // In case groups list is changed (new group is added) - refresh group selection data
      selectGroup(selectedGroupIndex);
    }
  }, [groups.length]);

  const selectedGroup = selectedGroupIndex !== null && groups[selectedGroupIndex];

  return (
    <div>
      <h2 className="mb30">
        <FormattedMessage id="systemGroups" defaultMessage="System Groups" />
        <RCTooltip
          placement="top"
          destroyTooltipOnHide
          overlayInnerStyle={{ padding: '10px', wordBreak: 'break-word' }}
          mouseLeaveDelay={0.4}
          overlay={(
            <p className="tooltipDefaultWidth">These Groups are automatically controlled by User Roles and the modules you have subscribed to
              (See <a href="https://authentise.zendesk.com/hc/en-us/articles/4402895769620-Group-Qualifications-User-Groups">Link to Zendesk</a>
              &nbsp;for more details). Bureau Managers can add/remove users by changing User Roles
              but cannot directly create/delete these Groups.
            </p>
          )}
        >
          <FontAwesomeIcon icon={faInfoCircle} className="spacer-left" />
        </RCTooltip>
        <div>
          <Link
            className="font-size-12"
            rel="noopener noreferrer"
            to={getRouteURI(ROUTES.ADMIN_USERS,
              {},
              {}, true)}
          >
            <FormattedMessage
              className="font-size-12"
              id="inventory.manageUsers"
              defaultMessage="Manage Users"
            /> <FontAwesomeIcon icon={faExternalLink} />
          </Link>
        </div>
      </h2>

      {
        fetching ? (
          <Loading inline className="spacer-left" />
        ) : (
          <Row className="mb30">
            <Col xs={12} sm={6}>
              <ListGroup>
                {systemGroups.map((group, groupIndex) => {
                  const isGroupSelected = selectedGroupIndex === groupIndex;
                  const groupKey = `${extractUuid(group.uri)}-${groupIndex}`;
                  return (
                    <ListGroupItem
                      className={`clearfix readOnly ${isGroupSelected ? 'active' : ''}`}
                      key={groupKey}
                    >
                      <>
                        <span>{group.name}</span>
                        <div
                          tabIndex="0"
                          role="button"
                          className="pull-right"
                          onClick={() => selectGroup(groupIndex, group.uri)}
                        >
                          <FontAwesomeIcon icon={faEye} />
                        </div>
                      </>
                    </ListGroupItem>
                  );
                })}
              </ListGroup>
            </Col>
            <Col xs={12} sm={6}>
              <UsersList
                isShown={groupType === GROUP_TYPES.SYSTEM && !!selectedGroup}
                selectedGroup={selectedGroup}
                readOnly
              />
            </Col>
          </Row>
        )
      }

    </div>
  );
});

SystemGroups.propTypes = {
  groups: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  getUsersByGroup: PropTypes.func.isRequired,
  handleShowGroup: PropTypes.func.isRequired,
  groupType: PropTypes.string.isRequired,
  fetching: PropTypes.bool.isRequired,
};

const AdminUserGroups = memo(({
  groups,
  saveGroup,
  saveGroupMembers,
  deleteGroup,
  systemGroups,
  getUsersByGroup,
  fetchingState,
}) => {
  const [groupType, setGroupType] = useState(null);

  const handleShowGroup = groupType => {
    setGroupType(GROUP_TYPES[groupType] || null);
  };

  return (
    <Container>
      <BreadcrumbNav breadcrumbs={['admin', 'userGroups']} />

      <div className="page-header">
        <h1>
          <FormattedMessage id="userGroups" defaultMessage="User Groups" />
        </h1>
      </div>

      <CustomGroups
        deleteGroup={deleteGroup}
        groups={groups}
        saveGroup={saveGroup}
        saveGroupMembers={saveGroupMembers}
        handleShowGroup={handleShowGroup}
        groupType={groupType}
        fetching={fetchingState.groupsFetching}
      />

      <SystemGroups
        getUsersByGroup={getUsersByGroup}
        groups={systemGroups}
        handleShowGroup={handleShowGroup}
        groupType={groupType}
        fetching={fetchingState.systemGroupsFetching}
      />

    </Container>
  );
});

AdminUserGroups.propTypes = {
  groups: PropTypes.arrayOf(PropTypes.shape({
    uri: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    members: PropTypes.arrayOf(PropTypes.string).isRequired,
  })).isRequired,
  saveGroup: PropTypes.func.isRequired,
  saveGroupMembers: PropTypes.func.isRequired,
  deleteGroup: PropTypes.func.isRequired,
  systemGroups: PropTypes.arrayOf(PropTypes.shape({
    uri: PropTypes.string,
    name: PropTypes.string,
    members: PropTypes.arrayOf(PropTypes.string),
  })).isRequired,
  getUsersByGroup: PropTypes.func.isRequired,
  fetchingState: PropTypes.shape({
    groupsFetching: PropTypes.bool.isRequired,
    systemGroupsFetching: PropTypes.bool.isRequired,
  }).isRequired,
};

export default AdminUserGroups;
