import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import _map from 'lodash/map';
import _compact from 'lodash/compact';

import Actions from 'rapidfab/actions';
import * as Selectors from 'rapidfab/selectors';

import { MaterialLots } from 'rapidfab/components/inventory';
import { API_RESOURCES, PAGINATION_IGNORE_DEFAULT_LIMIT } from 'rapidfab/constants';
import withRecordsListHandling from 'rapidfab/containers/hocs/withRecordsListHandling';

const MaterialLotsContainer = props => {
  const onOrderMaterialLots = useSelector(Selectors.getOnOrderMaterialLots);
  const inventoryMaterialLots = useSelector(Selectors.getInventoryMaterialLots);
  const subLocations = useSelector(Selectors.getSubLocations);

  const dispatch = useDispatch();
  const onInitialize = materialPoUris => {
    dispatch(Actions.Api.nautilus[API_RESOURCES.MATERIAL_PURCHASE_ORDER].list(
      { uri: materialPoUris }, { limit: PAGINATION_IGNORE_DEFAULT_LIMIT },
    ));
  };

  useEffect(() => {
    const materialPoUris = _compact(_map(onOrderMaterialLots, 'material_purchase_order'));
    if (materialPoUris.length) {
      onInitialize(materialPoUris);
    }
    dispatch(Actions.Api.nautilus[API_RESOURCES.SUB_LOCATION].list());
  }, []);

  return (
    <MaterialLots
      {...props}
      subLocations={subLocations}
      onOrderMaterialLots={onOrderMaterialLots}
      inventoryMaterialLots={inventoryMaterialLots}
    />
  );
};

export default withRecordsListHandling(
  MaterialLotsContainer,
  Selectors.getMaterialLots,
  ['material-lot'],
);
