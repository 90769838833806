import React from 'react';
import PropTypes from 'prop-types';
import { Badge, Button, Col, Container, Row } from 'react-bootstrap';
import WorkstationTabs from 'rapidfab/components/assets/WorkstationTabs';
import Table from 'rapidfab/components/Tables/Table';
import { FEATURES, ROUTES } from 'rapidfab/constants';
import { extractUuid, getShortUUID } from 'rapidfab/utils/uuidUtils';
import { getRouteURI } from 'rapidfab/utils/uriUtils';
import { Link } from 'react-router-dom';
import { FormattedMessage } from 'rapidfab/i18n';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { getIsDebugModeEnabled, isFeatureEnabled } from 'rapidfab/selectors';
import { useSelector } from 'react-redux';
import { faPlus } from '@fortawesome/free-solid-svg-icons';
import BreadcrumbNav from '../BreadcrumbNav';
import DisabledIconWithTooltip from '../DisabledIconWithTooltip';
import DisabledFor from '../DisabledFor';

const AddiguruInstallationStatus = ({ status }) => {
  if (status === 'healthy') {
    return <Badge bg="success">Healthy</Badge>;
  }
  if (status === 'unreachable') {
    return <Badge bg="danger">Unreachable</Badge>;
  }
  if (status === 'authentication_error') {
    return <Badge bg="danger">Authentication Error</Badge>;
  }
  if (status === 'configuration_error') {
    return <Badge bg="danger">Configuration Error</Badge>;
  }
  if (status === 'degraded') {
    return <Badge bg="danger">Degraded</Badge>;
  }
  return <Badge bg="danger">Unknown</Badge>;
};

AddiguruInstallationStatus.propTypes = {
  status: PropTypes.string.isRequired,
};

const AddiguruInstallations = ({ data, isFetching, openAddiguruInstallationModal }) => {
  const isDebugModeEnabled = useSelector(getIsDebugModeEnabled);
  const isGroupQualificationsFeatureEnabled = useSelector(state =>
    isFeatureEnabled(state, FEATURES.GROUP_QUALIFICATIONS));

  const breadcrumb = ['assets', 'workstations', 'addiguruInstallations'];

  const columns = [
    {
      type: 'custom',
      uid: 'field.id',
      accessor: 'uuid',
      defaultMessage: 'ID',
      resource: 'addiguru-installation',
      Cell: ({ row: { original: data } }) => {
        const uuid = extractUuid(data.uri);
        return (
          <Link
            to={getRouteURI(ROUTES.ADDIGURU_INSTALLATIONS, null, { uuid }, true)}
          >
            {getShortUUID(uuid)}
          </Link>
        );
      },
    },

    {
      type: 'text',
      uid: 'field.name',
      accessor: 'name',
      defaultMessage: 'Name',
      isSortable: true,
    },
    {
      type: 'custom',
      uid: 'field.health',
      accessor: 'health',
      defaultMessage: 'Health',
      isSortable: true,
      Cell: ({ row: { original: data } }) => (<AddiguruInstallationStatus status={data.health} />),
    },
  ];

  return (

    <Container fluid>
      <BreadcrumbNav breadcrumbs={breadcrumb} />
      <Row>
        <Col xs={12} className="mb8">
          <WorkstationTabs activeKey="addiguru-installations" />
        </Col>
      </Row>
      <Row>
        <Col xs={12}>
          <div className="pull-right">
            <DisabledFor
              restrictedUser
              /*
              Disable for Non Managers only when `group-qualifications` feature is enabled.
              No limitations otherwise
              */
              alwaysEnabled={!isGroupQualificationsFeatureEnabled}
              disabledPrefix={
                isDebugModeEnabled ? (
                  <DisabledIconWithTooltip
                    id="limitedFunctionalityDebugMode"
                    defaultMessage="This functionality usage is limited. Contact your Bureau Manager if you have any questions. Debug Info: Locked due to configuration of User Groups and Group Qualifications. See docs for more details."
                    placement="bottom"
                  />
                )
                  : <DisabledIconWithTooltip placement="bottom" />
              }
            >
              {({ disabled }) => (
                <Button
                  variant="primary"
                  size="sm"
                  onClick={openAddiguruInstallationModal}
                  disabled={disabled}
                >
                  <FontAwesomeIcon icon={faPlus} />{' '}
                  <FormattedMessage id="record.add.addiguruInstallation" defaultMessage="Add Addiguru Installation" />
                </Button>
              )}
            </DisabledFor>
          </div>
        </Col>
      </Row>
      <hr />
      <Row>
        <Table
          tableID="addiguruInstallations"
          data={data}
          columns={columns}
          isFilteringEnabled
          withDefaultPagination
          initialSortedDesc={false}
          initialSortedColumn="name"
          isManualSoringEnabled={false}
          isFetching={isFetching}
        />
      </Row>
    </Container>
  );
};

AddiguruInstallations.propTypes = {
  data: PropTypes.arrayOf({}).isRequired,
  isFetching: PropTypes.bool.isRequired,
  openAddiguruInstallationModal: PropTypes.func.isRequired,
  row: PropTypes.shape({
    original: PropTypes.shape({
      uri: PropTypes.string.isRequired,
      health: PropTypes.string.isRequired,
    }).isRequired,
  }).isRequired,
};

export default AddiguruInstallations;
