import { createSelector } from 'reselect';
import * as baseStateSelectors from 'rapidfab/selectors/baseStateSelectors';
import { getStateResources } from 'rapidfab/selectors/helpers/base';
import _map from 'lodash/map';
import _keyBy from 'lodash/keyBy';

export const getPrinters = createSelector(
  [baseStateSelectors.getStatePrinters, getStateResources],
  (uuids, resources) => _map(uuids, uuid => resources[uuid]),
);

export const getPrintersByUri = createSelector(
  [getPrinters],
  printers => _keyBy(printers, 'uri'),
);
