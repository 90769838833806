import useFilteredUsers from 'rapidfab/hooks/useFilteredUsers';
import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import PropTypes from 'prop-types';

import Actions from 'rapidfab/actions';
import * as Selectors from 'rapidfab/selectors';

import Users from 'rapidfab/components/admin/Users';
import Loading from 'rapidfab/components/Loading';
import { API_RESOURCES, PAGINATION_IGNORE_DEFAULT_LIMIT } from 'rapidfab/constants';

const UsersContainer = props => {
  const { readOnly } = props;
  const usersSortedAlphabetically = useSelector(Selectors.getUsersGroupedByLetter);
  const roles = useSelector(Selectors.getRoles);
  const locationsByUri = useSelector(Selectors.getLocationsByUri);
  const isLoading = useSelector(state => state.ui.nautilus[API_RESOURCES.USERS].list.fetching);
  const rolesGroupedByUsername = useSelector(Selectors.getRolesGroupedByUsername);

  const dispatch = useDispatch();

  const fetchUsers = () => !readOnly &&
    dispatch(Actions.Api.nautilus[API_RESOURCES.USERS].list(
      {}, { limit: PAGINATION_IGNORE_DEFAULT_LIMIT },
    ));
  const fetchLocations = () => !readOnly &&
    dispatch(Actions.Api.nautilus[API_RESOURCES.LOCATION].list());

  useEffect(() => {
    fetchUsers();
    fetchLocations();
  }, []);

  const { filter, roleFilter, setCurrentUserPreview, setUserModalMode } = props;

  const filteredUsers = useFilteredUsers(usersSortedAlphabetically, filter, roleFilter, roles);

  if (isLoading) return <Loading />;

  return (
    <Users
      {...props}
      usersGrouped={filteredUsers}
      rolesGroupedByUsername={rolesGroupedByUsername}
      locationsByUri={locationsByUri}
      setCurrentUserPreview={setCurrentUserPreview}
      setUserModalMode={setUserModalMode}
    />
  );
};

UsersContainer.propTypes = {
  filter: PropTypes.string.isRequired,
  roleFilter: PropTypes.string,
  selectedUsersURIs: PropTypes.arrayOf(PropTypes.string),
  multiSelect: PropTypes.bool.isRequired,
  handleSelectionChange: PropTypes.func,
  readOnly: PropTypes.bool,
  setCurrentUserPreview: PropTypes.func.isRequired,
  setUserModalMode: PropTypes.func.isRequired,
};

UsersContainer.defaultProps = {
  selectedUsersURIs: [],
  handleSelectionChange: () => {},
  roleFilter: null,
  readOnly: false,
};

export default UsersContainer;
