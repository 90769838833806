import React from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { Button, Modal } from 'react-bootstrap';
import { MODEL_LIBRARY_TYPES, FEATURES, ROUTES } from 'rapidfab/constants';
import { FormattedMessage } from 'rapidfab/i18n';
import ModelLibrariesContainer from 'rapidfab/containers/organize/ModelLibrariesContainer';
import { isFeatureEnabled } from 'rapidfab/selectors';
import { Link } from 'react-router-dom';
import { getRouteURI } from 'rapidfab/utils/uriUtils';

const ModelLibraryModal = ({ onHide, handleSelect }) => {
  const isDDWEnabled = useSelector(state =>
    isFeatureEnabled(state, FEATURES.DIGITAL_DESIGN_WAREHOUSE),
  );
  return (
    <Modal size="lg" show onHide={() => onHide()} backdrop="static">
      <Modal.Header closeButton>
        <Modal.Title>
          {isDDWEnabled ? (
            <>
              <span>
                Please select a design from the <strong>Company Library</strong>:
              </span>
              <br />
              <span>
                Check out the entire Digital Design Warehouse{' '}
                <Link to={getRouteURI(ROUTES.DDW_MY_LIBRARY, {}, {}, true)}>here</Link>
              </span>
            </>
          ) : (
            <span>Please select a model:</span>
          )}
        </Modal.Title>

      </Modal.Header>
      <Modal.Body>
        <ModelLibrariesContainer
          showHeader
          showModelDropZone={false}
          handleSelect={handleSelect}
          typeFilter={MODEL_LIBRARY_TYPES.PRODUCT}
        />
      </Modal.Body>
      <Modal.Footer>
        <Button variant="primary" onClick={() => onHide('')}>
          <FormattedMessage id="close" defaultMessage="Close" />
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

ModelLibraryModal.propTypes = {
  onHide: PropTypes.func.isRequired,
  handleSelect: PropTypes.func.isRequired,
};

export default ModelLibraryModal;
