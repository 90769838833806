import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Actions from 'rapidfab/actions';
import isFetchingInitial from 'rapidfab/utils/isFetchingInitial';
import { API_RESOURCES } from 'rapidfab/constants';
import withRecordsListHandling from 'rapidfab/containers/hocs/withRecordsListHandling';
import { getToolingStocks } from 'rapidfab/selectors/toolingStock';
import { getToolingStockTypesByUri } from 'rapidfab/selectors/toolingStockType';
import { getLocationsByUri } from 'rapidfab/selectors/location';
import { isVendorServiceProvider } from 'rapidfab/selectors';
import ToolingStocks from 'rapidfab/components/inventory/ToolingStocks';
import PropTypes from 'prop-types';
import ToolingStockCreateContainer from './ToolingStockCreateContainer';

const ToolingStocksContainer = props => {
  const isVendorUser = useSelector(isVendorServiceProvider);
  const fetching = useSelector(
    state =>
      isFetchingInitial(
        state.ui.nautilus[API_RESOURCES.TOOLING_STOCK].list,
        state.ui.nautilus[API_RESOURCES.TOOLING_STOCK_TYPE].list,
      ) ||
      (!isVendorUser &&
        isFetchingInitial(state.ui.nautilus[API_RESOURCES.LOCATION].list)),
  );

  const locations = useSelector(state => getLocationsByUri(state));
  const stockTypes = useSelector(state => getToolingStockTypesByUri(state));
  const toolingStocks = useSelector(state => getToolingStocks(state));
  const dispatch = useDispatch();
  const [showCreateModal, setShowCreateModal] = useState(false);

  const selected = {
    fetching,
    locations,
    stockTypes,
    toolingStocks,
    isVendorUser,
  };

  const onInitialize = () => {
    dispatch(Actions.Api.nautilus[API_RESOURCES.TOOLING_STOCK_TYPE].list());
    if (!isVendorUser) {
      dispatch(Actions.Api.nautilus[API_RESOURCES.LOCATION].list());
    }
  };

  useEffect(() => {
    onInitialize();
  }, []);

  return (
    <>
      <ToolingStocks
        {...props}
        {...selected}
        showModal={() => setShowCreateModal(true)}
      />
      {showCreateModal && (
        <ToolingStockCreateContainer
          show={showCreateModal}
          hideModal={() => setShowCreateModal(false)}
        />
      )}
    </>
  );
};

ToolingStocksContainer.propTypes = {
  onSort: PropTypes.func.isRequired,
};

export default withRecordsListHandling(
  ToolingStocksContainer,
  getToolingStocks,
  [API_RESOURCES.TOOLING_STOCK],
  {
    defaultLimit: 50,
    searchBy: 'name',
    useLocationFiltering: true,
    multicolumnSearch: false,
    defaultSort: '',
    staticQueryParams: {},
  },
);
