import Constants from 'rapidfab/constants';

export function storeCurrency(selectedCurrency) {
  return {
    type: Constants.STORE_CURRENCY,
    selectedCurrency,
  };
}

export function setSelectedCurrency(selectedCurrency) {
  return dispatch => {
    dispatch(storeCurrency(selectedCurrency));
  };
}
