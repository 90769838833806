import { faCheckCircle, faCircleXmark, faExclamationCircle, faInfoCircle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import { Bounce, toast } from 'react-toastify';
import PinToast from './PinToast'; // Ensure this path is correct

// eslint-disable-next-line import/prefer-default-export
export const createToast = (type, message, config) => {
  const iconsByType = {
    error: faCircleXmark,
    success: faCheckCircle,
    info: faInfoCircle,
    warning: faExclamationCircle,
  };

  const commonProps = {
    position: config.position || 'top-right',
    autoClose: config.autoClose || (type === 'error' ? 10000 : 5000),
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
    theme: 'colored',
    icon: <FontAwesomeIcon className="font-size-20" icon={iconsByType[type]} />,
    transition: config.transition || Bounce,
  };

  if (config.pinnable) {
    const toastId = toast[type](props => (
      <PinToast
        {...props}
        autoClose={commonProps.autoClose}
        message={message}
        toastId={toastId}
      />
    ), commonProps);
  } else {
    toast[type](message, commonProps);
  }
};
