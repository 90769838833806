import _map from 'lodash/map';
import _filter from 'lodash/filter';
import _unionBy from 'lodash/unionBy';
import { CUSTOM_FIELD_TYPES } from '../constants';

/*
Method returns custom fields with initialized values based on custom field
specification (customFieldReferences) and already filled custom field values (customFieldValues).
*/
export default function getInitialCustomFieldValues(customFieldReferences, customFieldValues) {
  // It's needed to set default values to all checkbox type field
  const checkboxCustomFieldReferences = _filter(customFieldReferences, { type: CUSTOM_FIELD_TYPES.CHECKBOX });
  const checkboxCustomFieldValues = _map(
    checkboxCustomFieldReferences,
    fieldReference => ({ custom_field: fieldReference.uri, value: 'false' }),
  );

  // customFieldValues has more priority than checkboxCustomFieldValues
  return _unionBy(customFieldValues, checkboxCustomFieldValues, 'custom_field');
}
