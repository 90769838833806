import { faInfoCircle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import _capitalize from 'lodash/capitalize';
import PropTypes from 'prop-types';
import PRINTER_TYPE_BUILD_PLATE_TYPES from 'rapidfab/constants/printerTypeBuildPlates';
import { resetRcTooltipInnerStyle } from 'rapidfab/constants/styles';
import RCTooltip from 'rc-tooltip';
import React from 'react';
import {
  ButtonGroup,
  Card,
  Col,
  FormGroup,
  FormLabel,
  Row,
  ToggleButton,
} from 'react-bootstrap';
import { Field } from 'react-final-form';
import { FormattedMessage } from 'react-intl';

const PrinterTypeBuildPlateType = ({
  buildPlateType,
  renderBuildVolume,
  isBuildVolumeValueIncorrect,
  isDisabled,
  setActivePlateType,
}) => {
  const rectangularPlateForm = (
    <Row>
      <Col sm={4}>
        <FormGroup className="form-group" controlId="uxBuildVolumeX">
          <FormLabel>
            <FormattedMessage
              id="field.volumeX"
              defaultMessage="Volume X"
            />
            : *
          </FormLabel>
          <Field
            name="build_volume.x"
            type="number"
            validate={value => isBuildVolumeValueIncorrect(value)}
            render={props => renderBuildVolume(props)}
          />
        </FormGroup>
      </Col>
      <Col sm={4}>
        <FormGroup className="form-group" controlId="uxBuildVolumeY">
          <FormLabel>
            <FormattedMessage
              id="field.volumeY"
              defaultMessage="Volume Y"
            />
            : *
          </FormLabel>
          <Field
            name="build_volume.y"
            type="number"
            validate={value => isBuildVolumeValueIncorrect(value)}
            render={props => renderBuildVolume(props)}
          />
        </FormGroup>
      </Col>
      <Col sm={4}>
        <FormGroup className="form-group" controlId="uxBuildVolumeZ">
          <FormLabel>
            <FormattedMessage
              id="field.volumeZ"
              defaultMessage="Volume Z"
            />
            : *
          </FormLabel>
          <Field
            name="build_volume.z"
            type="number"
            validate={value => isBuildVolumeValueIncorrect(value)}
            render={props => renderBuildVolume(props)}
          />
        </FormGroup>
      </Col>
    </Row>
  );

  const circularPlateForm = (
    <Row>
      <Col sm={4}>
        <FormGroup className="form-group" controlId="uxDiameterMillimeters">
          <FormLabel>
            <FormattedMessage
              id="field.diameter"
              defaultMessage="Diameter"
            />
            : *
          </FormLabel>
          <Field
            name="build_volume.d"
            type="number"
            validate={value => isBuildVolumeValueIncorrect(value)}
            render={props => renderBuildVolume(props)}
          />
        </FormGroup>
      </Col>
      <Col sm={4}>
        <FormGroup className="form-group" controlId="uxBuildVolumeZ">
          <FormLabel>
            <FormattedMessage
              id="field.volumeZ"
              defaultMessage="Volume Z"
            />
            : *
          </FormLabel>
          <Field
            name="build_volume.z"
            type="number"
            validate={value => isBuildVolumeValueIncorrect(value)}
            render={props => renderBuildVolume(props)}
          />
        </FormGroup>
      </Col>
    </Row>
  );

  const DISPLAY_FORM_TYPES = {
    [PRINTER_TYPE_BUILD_PLATE_TYPES.RECTANGULAR]: rectangularPlateForm,
    [PRINTER_TYPE_BUILD_PLATE_TYPES.CIRCULAR]: circularPlateForm,
  };

  return (
    <Card className="custom-darken-modal--card mb15">
      <Card.Header className="pd-exp custom-darken-modal--card-header">
        <Row className="d-flex justify-content-between align-items-center">
          <Col lg={4}>
            <FormattedMessage
              id="buildPlate.setting"
              defaultMessage="Build Plate"
            />
          </Col>
          <Col className="p-0">
            <div className="d-flex align-items-center pull-right">
              <FormattedMessage
                id="buildPlateShape"
                defaultMessage="Build Plate Shape"
              />

              <RCTooltip
                placement="top"
                id="userGroupsTooltip"
                destroyTooltipOnHide
                overlayInnerStyle={resetRcTooltipInnerStyle}
                mouseLeaveDelay={0.4}
                overlay={(
                  <p className="mb0 darkTooltip">
                    <FormattedMessage
                      id="tooltip.printerTypeBuildPlate"
                      defaultMessage="Select Rectangular for traditional x, y, and z axis dimensions or Circular for printers with a round build plate. Please Note: When Circular is selected, the system will calculate the build area using the largest inscribed square, which may not utilize the entire plate surface and could result in less packing efficiency."
                    />
                  </p>
                )}
              >
                <FontAwesomeIcon className="spacer-right spacer-left" icon={faInfoCircle} />
              </RCTooltip>
              <ButtonGroup className="spacer-left">
                <ToggleButton
                  size="sm"
                  className="custom-darken-modal--toggle-button-sm"
                  type="button"
                  disabled={isDisabled}
                  variant={buildPlateType === PRINTER_TYPE_BUILD_PLATE_TYPES.RECTANGULAR ? 'primary' : 'outline-secondary'}
                  onClick={() => setActivePlateType(PRINTER_TYPE_BUILD_PLATE_TYPES.RECTANGULAR)}
                >
                  {_capitalize(PRINTER_TYPE_BUILD_PLATE_TYPES.RECTANGULAR)}
                </ToggleButton>
                <ToggleButton
                  size="sm"
                  className="custom-darken-modal--toggle-button-sm"
                  type="button"
                  disabled={isDisabled}
                  variant={buildPlateType === PRINTER_TYPE_BUILD_PLATE_TYPES.CIRCULAR ? 'primary' : 'outline-secondary'}
                  onClick={() => setActivePlateType(PRINTER_TYPE_BUILD_PLATE_TYPES.CIRCULAR)}
                >
                  {_capitalize(PRINTER_TYPE_BUILD_PLATE_TYPES.CIRCULAR)}
                </ToggleButton>
              </ButtonGroup>
            </div>
          </Col>
        </Row>
      </Card.Header>
      <Card.Body className="custom-darken-modal--card-body">
        {DISPLAY_FORM_TYPES[buildPlateType]}
        <p className="buildPlateVolumeMeasurementInfo">Measurements in millimeters</p>
      </Card.Body>
    </Card>
  );
};

PrinterTypeBuildPlateType.propTypes = {
  buildPlateType: PropTypes.string,
  isBuildVolumeValueIncorrect: PropTypes.bool.isRequired,
  renderBuildVolume: PropTypes.node.isRequired,
  isDisabled: PropTypes.bool.isRequired,
  setActivePlateType: PropTypes.func.isRequired,
};
PrinterTypeBuildPlateType.defaultProps = {
  buildPlateType: PRINTER_TYPE_BUILD_PLATE_TYPES.RECTANGULAR,
};

export default PrinterTypeBuildPlateType;
