import React from 'react';
import PropTypes from 'prop-types';
import {
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  ModalTitle,
  Button,
  FormGroup,
  FormControl,
  FormLabel,
} from 'react-bootstrap';
import Loading from 'rapidfab/components/Loading';
import SaveButtonTitle from 'rapidfab/components/SaveButtonTitle';
import { FormattedMessage } from 'rapidfab/i18n';
import { SHIPMENT_ADDRESS_TYPES } from 'rapidfab/constants';
import { SHIPMENT_ADDRESS_TYPES_MAPPING } from 'rapidfab/mappings';
import { finalFormInputTypes } from 'rapidfab/types';

import { Form, Field } from 'react-final-form';

const ShipmentAddressModal = ({
  onClose,
  addressType,
  onSubmit,
  isSaving,
  initialFormValues,
}) => (
  <Modal show onHide={onClose} backdrop="static">
    <ModalHeader closeButton>
      <ModalTitle>
        <FormattedMessage {...SHIPMENT_ADDRESS_TYPES_MAPPING[addressType]} />
      </ModalTitle>
    </ModalHeader>
    <Form
      onSubmit={onSubmit}
      initialValues={initialFormValues}
      render={({ handleSubmit }) => (
        <form onSubmit={handleSubmit}>
          <ModalBody>
            <FormGroup controlId="addressName">
              <FormLabel>
                <FormattedMessage id="field.name" defaultMessage="Name" />:
              </FormLabel>
              <Field
                name="name"
                type="text"
                render={props => (
                  <FormControl
                    {...props.input}
                  />
                )}
              />
            </FormGroup>
            <FormGroup controlId="addressAddress">
              <FormLabel>
                <FormattedMessage id="field.address" defaultMessage="Address" />:
              </FormLabel>
              <Field
                name="address"
                type="text"
                render={props => (
                  <FormControl
                    {...props.input}
                    as="textarea"
                  />
                )}
              />
            </FormGroup>
            <FormGroup controlId="addressPhone">
              <FormLabel>
                <FormattedMessage id="field.phone" defaultMessage="Phone" />:
              </FormLabel>
              <Field
                name="phone_number"
                type="text"
                render={props => (
                  <FormControl
                    {...props.input}
                  />
                )}
              />
            </FormGroup>
            <FormGroup controlId="addressEmail">
              <FormLabel>
                <FormattedMessage id="email" defaultMessage="Email" />:
              </FormLabel>
              <Field
                name="email"
                type="email"
                render={props => (
                  <FormControl
                    {...props.input}
                  />
                )}
              />
            </FormGroup>
          </ModalBody>
          <ModalFooter>
            <Button onClick={onClose}>
              <FormattedMessage id="button.cancel" defaultMessage="Cancel" />
            </Button>
            <Button variant="success" type="submit" disabled={isSaving}>
              {isSaving ? <Loading /> : <SaveButtonTitle />}
            </Button>
          </ModalFooter>
        </form>
      )}
    />
  </Modal>
);

ShipmentAddressModal.propTypes = {
  onClose: PropTypes.func.isRequired,
  addressType: PropTypes.oneOf(Object.values(SHIPMENT_ADDRESS_TYPES)).isRequired,
  initialFormValues: PropTypes.shape({
    name: PropTypes.string.isRequired,
    address: PropTypes.string.isRequired,
    phone_number: PropTypes.string.isRequired,
    email: PropTypes.string.isRequired,
  }).isRequired,
  onSubmit: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  isSaving: PropTypes.bool.isRequired,
  input: finalFormInputTypes.isRequired,
};

export default ShipmentAddressModal;
