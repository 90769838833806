import React from 'react';
import PropTypes from 'prop-types';
import { ROUTES } from 'rapidfab/constants';
import { getRouteURI } from 'rapidfab/utils/uriUtils';
import { Navbar, Nav, NavDropdown } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { FormattedMessage } from 'rapidfab/i18n';
import NavProfile from 'rapidfab/components/navbar/NavProfile';
import { Inventory } from 'rapidfab/components/navbar/Titles';
import {
  faUserSecret,
  faHammer,
  faBoxesStacked,
} from '@fortawesome/free-solid-svg-icons';

const NavLinksVendorServiceProvider = ({
  currentUser,
  locale,
  onChangeLocale,
  onLogout,
  session,
  isImpersonating,
  onImpersonateStop,
  isStopImpersonationLoading,
}) => (
  <Navbar.Collapse>
    <Nav>
      <NavDropdown eventKey={1} title={<Inventory />} id="uxNavInventory">
        <NavDropdown.Item
          eventKey={1.1}
          href={getRouteURI(ROUTES.VENDOR_TOOLING_STOCKS)}
        >
          <FontAwesomeIcon icon={faBoxesStacked} />{' '}
          <FormattedMessage
            id="record.tooling_stocks"
            defaultMessage="Tools"
          />
        </NavDropdown.Item>
        <NavDropdown.Item
          eventKey={1.2}
          href={getRouteURI(ROUTES.VENDOR_TOOLING_STOCK_TYPES)}
        >
          <FontAwesomeIcon icon={faHammer} />{' '}
          <FormattedMessage
            id="record.tooling_stock_types"
            defaultMessage="Tool Types"
          />
        </NavDropdown.Item>
      </NavDropdown>
      {isImpersonating && (
        <NavDropdown.Item eventKey={1.2} onClick={() => onImpersonateStop()}>
          <div>
            <FontAwesomeIcon icon={faUserSecret} />{' '}
            <FormattedMessage
              id="endImpersonation"
              defaultMessage="End Impersonation"
            />
          </div>
        </NavDropdown.Item>
      )}
    </Nav>
    <NavProfile
      currentUser={currentUser}
      locale={locale}
      onChangeLocale={onChangeLocale}
      onLogout={onLogout}
      session={session}
      isServiceProviderRole
      onImpersonateStop={onImpersonateStop}
      isStopImpersonationLoading={isStopImpersonationLoading}
    />
  </Navbar.Collapse>
);

NavLinksVendorServiceProvider.propTypes = {
  currentUser: PropTypes.shape({}).isRequired,
  locale: PropTypes.string.isRequired,
  onChangeLocale: PropTypes.func.isRequired,
  onLogout: PropTypes.func.isRequired,
  session: PropTypes.shape({}).isRequired,
  isImpersonating: PropTypes.bool.isRequired,
  onImpersonateStop: PropTypes.func.isRequired,
  isStopImpersonationLoading: PropTypes.bool.isRequired,
};

export default NavLinksVendorServiceProvider;
