import { getVendorServiceProviderFromToolingTypeByUri, isFeatureEnabled } from 'rapidfab/selectors';
import isFetchingInitial from 'rapidfab/utils/isFetchingInitial';
import React, { useEffect } from 'react';
import Actions from 'rapidfab/actions';
import Loading from 'rapidfab/components/Loading';
import * as Selectors from 'rapidfab/selectors';
import Alert from 'rapidfab/utils/alert';
import { API_RESOURCES, FEATURES } from 'rapidfab/constants';
import { useDispatch, useSelector } from 'react-redux';
import { extractUuid } from 'rapidfab/utils/uuidUtils';
import { FormattedMessage } from 'react-intl';
import { getToolingStockTypeFromUri } from 'rapidfab/selectors/toolingStockType';
import ToolingStockComponent from 'rapidfab/components/records/ToolingStock';

const ToolingStockContainer = props => {
  const uuid = useSelector(Selectors.getRouteUUID);
  const toolingStock = useSelector(state =>
    Selectors.getRouteUUIDResource(state, props),
  );
  const isVendorUser = useSelector(Selectors.isVendorServiceProvider);

  const vendorUserFetching = useSelector(state =>
    state.ui.nautilus[API_RESOURCES.SERVICE_PROVIDER].get.fetching);

  const isVendorServiceProviderFeatureEnabled = useSelector(state =>
    isFeatureEnabled(state, FEATURES.VENDOR_SERVICE_PROVIDER));

  const toolingStockType = useSelector(state => (
    toolingStock?.type
      ? getToolingStockTypeFromUri(state, toolingStock.type)
      : undefined
  ));

  const toolingTypeVendorUser = useSelector(state => (
    toolingStockType?.service_provider
      ? getVendorServiceProviderFromToolingTypeByUri(state, toolingStockType.service_provider)
      : undefined
  ));

  const locations = useSelector(Selectors.getLocationsByUri);
  const location = toolingStock?.location
    ? locations[toolingStock.location]
    : undefined;
  const subLocations = useSelector(Selectors.getSubLocationsByUri);
  const subLocation = toolingStock?.sub_location
    ? subLocations?.[toolingStock?.sub_location]
    : undefined;
  const initialLocationsFetching = useSelector(state =>
    isFetchingInitial(
      state.ui.nautilus[API_RESOURCES.LOCATION].list,
      state.ui.nautilus[API_RESOURCES.SUB_LOCATION].list,
    ),
  );

  const runs = useSelector(Selectors.getRunsByUri);
  const run = toolingStock?.run ? runs?.[toolingStock?.run] : undefined;
  const workstation = run ? {
    name: run.workstation_name,
    uri: run.workstation,
  } : undefined;

  const dispatch = useDispatch();

  const fetching = useSelector(
    state =>
      !toolingStock ||
      !toolingStockType ||
      state.ui.nautilus[API_RESOURCES.TOOLING_STOCK].get.fetching ||
      state.ui.nautilus[API_RESOURCES.TOOLING_STOCK_TYPE].get.fetching ||
      // we don't fetch locations for vendor users
      (!isVendorUser && initialLocationsFetching),
  );

  const onInitialize = async currentUUID => {
    if (!currentUUID) return;
    if (!isVendorUser) {
      dispatch(Actions.Api.nautilus[API_RESOURCES.LOCATION].list());
      dispatch(Actions.Api.nautilus[API_RESOURCES.SUB_LOCATION].list());
    }

    try {
      const toolingStockResponse = await dispatch(
        Actions.Api.nautilus[API_RESOURCES.TOOLING_STOCK].get(currentUUID),
      );
      const toolingStockData = toolingStockResponse.json;

      if (!toolingStockData) return;

      const typeUuid = extractUuid(toolingStockData.type);

      const toolingStockTypeResponse = await dispatch(
        Actions.Api.nautilus[API_RESOURCES.TOOLING_STOCK_TYPE].get(typeUuid),
      );
      // Don't fetch workstation or run for vendor users
      if (isVendorUser) return;

      const toolingStockTypeData = toolingStockTypeResponse.json;

      if (toolingStockTypeData?.service_provider && isVendorServiceProviderFeatureEnabled) {
        dispatch(
          Actions.Api.nautilus[API_RESOURCES.SERVICE_PROVIDER]
            .get(extractUuid(toolingStockTypeData.service_provider)),
        );
      }

      if (toolingStockData.run) {
        const runUuid = extractUuid(toolingStockData.run);
        dispatch(Actions.Api.nautilus[API_RESOURCES.RUN].get(runUuid));
      }
    } catch (error) {
      Alert.error(
        <FormattedMessage
          id="toaster.error.tooling-stock.unableToLoad"
          defaultMessage="Unable to load tool. {errorMsg}"
          values={{ errorMsg: error.message }}
        />,
      );
    }
  };

  const handleSubmitNotes = async payload => {
    await dispatch(
      Actions.Api.nautilus[API_RESOURCES.TOOLING_STOCK].put(uuid, payload),
    );
    Alert.success(
      <FormattedMessage
        id="toaster.toolingStock.updated"
        defaultMessage="Tool successfully updated."
      />,
    );
  };

  useEffect(() => {
    onInitialize(uuid);
  }, [uuid]);

  if (fetching) {
    return <Loading />;
  }

  return (
    <ToolingStockComponent
      {...props}
      toolingStock={toolingStock}
      toolingStockType={toolingStockType}
      location={location}
      run={run}
      workstation={workstation}
      subLocation={subLocation}
      vendorUserFetching={vendorUserFetching}
      toolingTypeVendorUser={toolingTypeVendorUser}
      handleSubmitNotes={handleSubmitNotes}
      isVendorUser={isVendorUser}
    />
  );
};

export default ToolingStockContainer;
