import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import PropTypes from 'prop-types';

import Actions from 'rapidfab/actions';
import * as Selectors from 'rapidfab/selectors';

import EditRestrictedLineItemOrderComponent from 'rapidfab/components/records/order/restricted/edit/EditRestrictedLineItemOrder';
import Loading from 'rapidfab/components/Loading';
import { API_RESOURCES } from 'rapidfab/constants';

const EditRestrictedOrder = () => {
  const bureau = useSelector(Selectors.getBureauUri);
  const order = useSelector(Selectors.getRouteUUIDResource);
  const routeUUID = useSelector(Selectors.getRouteUUID);
  const products = useSelector(state => Selectors.getProductsForOrder(state, order));
  const [lineItemsLength, setLineItemsLength] = useState(products?.length);

  useEffect(() => {
    setLineItemsLength(products.length);
  }, [products]);

  const dispatch = useDispatch();

  useEffect(() => {
    // Fetch order and related resources
    dispatch(Actions.Api.nautilus[API_RESOURCES.ORDER].get(routeUUID));

    // Fetch resource options for input selections
    dispatch(Actions.Api.nautilus[API_RESOURCES.MATERIAL].list({ bureau }));
    dispatch(Actions.Api.nautilus[API_RESOURCES.WORKFLOW].list());
  }, []);

  const loading = !order || !routeUUID;

  return (
    <div>
      {loading ? <Loading /> : <EditRestrictedLineItemOrderComponent lineItemsLength={lineItemsLength} />}
    </div>
  );
};

EditRestrictedOrder.propTypes = {
  route: PropTypes.shape({
    uuid: PropTypes.string.isRequired,
  }).isRequired,
};

export default EditRestrictedOrder;
