import React from 'react';
import PropTypes from 'prop-types';
import { Button, Image } from 'react-bootstrap';
import Loading from 'rapidfab/components/Loading';
import NetfabbLogo from 'rapidfab/images/netfabb_logo.png';

const NetfabbButton = ({ onClick, processing, children, disabled }) => (
  <Button
    className="block"
    variant="default"
    size="sm"
    disabled={processing || disabled}
    onClick={onClick}
  >
    <div className="d-inline-block" style={{ marginRight: '0.75em' }}>
      {!disabled && processing ? <Loading className="d-inline-block" /> : (
        <Image src={NetfabbLogo} alt="" style={{ height: '1em' }} />
      )}
    </div>
    {children}
  </Button>
);

NetfabbButton.defaultProps = {
  disabled: false,
};

NetfabbButton.propTypes = {
  onClick: PropTypes.func.isRequired,
  processing: PropTypes.bool.isRequired,
  children: PropTypes.element.isRequired,
  disabled: PropTypes.bool,
};

export default NetfabbButton;
