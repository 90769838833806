import { API_RESOURCES } from 'rapidfab/constants/resources';

export const EVENT_TYPES = {
  CREATED: 'created',
  UPDATED: 'updated',
  DELETED: 'deleted',
};

/**
 * List of API resources that support "Created" event from EventStream
 *
 * !!!ATTENTION!!! add new resources with caution
 * "created" event may brake lists of resources where some filters are made on the API level.
 * E.g. if list of orders is filtered by Completed Status
 * and new Pending order is created - it will be added to the list.
 */
export const CREATED_EVENT_RESOURCES_SUPPORTED = new Set([
  API_RESOURCES.COMMENT,
  API_RESOURCES.PRODUCT,
  API_RESOURCES.CASTOR_COSTING_CONFIG,
  API_RESOURCES.THREADS_MESSAGES,
]);

export const DELETED_EVENT_RESOURCES_SUPPORTED = new Set([
  API_RESOURCES.THREADS_MESSAGES,
]);
