import React from 'react';
import PropTypes from 'prop-types';
import withRecordsListHandling from 'rapidfab/containers/hocs/withRecordsListHandling';
import { getActiveAssignments, getCurrentUserRole } from 'rapidfab/selectors';
import {
  RELATED_TABLE_NAMES,
  STATUS_COLOR_CODE_TYPES,
} from 'rapidfab/constants';
import { Col, Row } from 'react-bootstrap';
import Pagination from 'rapidfab/components/RecordList/Pagination';
import StatusColorCode from 'rapidfab/components/StatusColorCode';
import MappedColumn from 'rapidfab/components/RecordList/MappedColumn';
import {
  COMMENT_ACTION_STATUSES_MAP,
  DOWNTIME_STATUS_MAP,
  LINE_ITEM_STATUS_MAP,
  ORDER_STATUS_MAP,
  PREP_TASK_RECORD_STATUSES_MAP,
  STOCK_STATUS_MAP,
} from 'rapidfab/mappings';
import RelatedResourceRoute from 'rapidfab/components/RelatedResourceRoute';
import { connect } from 'react-redux';

import Table from 'rapidfab/components/Tables/Table';

// Not using a constant mapping here, since map is not very transparent
// and quite customized for this certain page
const TYPES = {
  [RELATED_TABLE_NAMES.ORDER]: 'Order',
  [RELATED_TABLE_NAMES.COMMENT_ACTION]: 'Comment Assignment',
  [RELATED_TABLE_NAMES.PREP_TASK_RECORD]: 'Prep Task',
  [RELATED_TABLE_NAMES.STOCK]: 'Material Reorder',
  [RELATED_TABLE_NAMES.LINE_ITEM]: 'Line Item',
  [RELATED_TABLE_NAMES.DOWNTIME]: 'Downtime',
};

const RELATED_TABLE_NAME_TO_STATUS_COLOR_CODE_TYPE_MAP = {
  [RELATED_TABLE_NAMES.ORDER]: STATUS_COLOR_CODE_TYPES.ORDER_STATUS,
  [RELATED_TABLE_NAMES.PREP_TASK_RECORD]:
    STATUS_COLOR_CODE_TYPES.PREP_TASK_RECORD_STATUS,
  [RELATED_TABLE_NAMES.COMMENT_ACTION]:
    STATUS_COLOR_CODE_TYPES.COMMENT_ACTION_STATUS,
  [RELATED_TABLE_NAMES.STOCK]: STATUS_COLOR_CODE_TYPES.STOCK_STATUS,
  [RELATED_TABLE_NAMES.DOWNTIME]: STATUS_COLOR_CODE_TYPES.DOWNTIME_STATUS,
  [RELATED_TABLE_NAMES.LINE_ITEM]: STATUS_COLOR_CODE_TYPES.LINE_ITEM_STATUS,
};

const RELATED_TABLE_NAME_TO_STATUS_MAP = {
  [RELATED_TABLE_NAMES.ORDER]: ORDER_STATUS_MAP,
  [RELATED_TABLE_NAMES.PREP_TASK_RECORD]: PREP_TASK_RECORD_STATUSES_MAP,
  [RELATED_TABLE_NAMES.COMMENT_ACTION]: COMMENT_ACTION_STATUSES_MAP,
  [RELATED_TABLE_NAMES.STOCK]: STOCK_STATUS_MAP,
  [RELATED_TABLE_NAMES.DOWNTIME]: DOWNTIME_STATUS_MAP,
  [RELATED_TABLE_NAMES.LINE_ITEM]: LINE_ITEM_STATUS_MAP,
};

const MyActions = ({ data, ...componentProps }) => {
  const columns = [
    {
      type: 'custom',
      // id is not provided
      uid: 'title',
      accessor: 'name',
      defaultMessage: 'Title',
      isSortable: false,
      // eslint-disable-next-line no-shadow
      Cell: ({ row: { original: data } }) => (
        <RelatedResourceRoute
          relatedTableName={data.related_table_name}
          relatedUUID={data.related_uuid}
          title={data.name}
          currentUserRole={componentProps.currentUserRole}
        />
      ),
    },
    {
      type: 'custom',
      uid: 'field.type',
      accessor: 'related_table_name',
      defaultMessage: 'Type',
      isSortable: false,
      Cell: ({ value }) => <span>{TYPES[value] || value}</span>,
    },
    {
      type: 'custom',
      uid: 'field.status',
      accessor: 'related_object_status',
      defaultMessage: 'Status',
      isSortable: false,
      // eslint-disable-next-line no-shadow
      Cell: ({ row: { original: data } }) => {
        const statusColorCodeType =
          RELATED_TABLE_NAME_TO_STATUS_COLOR_CODE_TYPE_MAP[
            data.related_table_name
          ];
        const mapping =
          RELATED_TABLE_NAME_TO_STATUS_MAP[data.related_table_name];
        const status = data.related_object_status;
        return (
          <div>
            <StatusColorCode status={status} type={statusColorCodeType} />{' '}
            <span className="order-status-dot" />
            {mapping ? (
              <MappedColumn mapping={mapping} value={status} />
            ) : (
              // fallback to raw status in case mapping is not found
              status
            )}
          </div>
        );
      },
    },
    {
      type: 'age',
      // id is not provided
      uid: 'field.age',
      accessor: 'created',
      defaultMessage: 'Age',
      isSortable: true,
    },
  ];

  const { limit, listStore, offset, onPageChange, fetching, onSort } =
    componentProps;

  return (
    <>
      <Table
        data={data}
        columns={columns}
        isFetching={fetching}
        isFilteringEnabled={false}
        withDefaultPagination={false}
        isManualSoringEnabled
        isUpdatedColumnShown={false}
        manualSortingFunc={onSort}
        initialSortedColumn="created"
        initialSortedDesc
        PaginationComponent={(
          <Row>
            <Col>
              <Pagination
                limit={limit}
                listStore={listStore}
                offset={offset}
                onPageChange={onPageChange}
                size="md"
              />
            </Col>
          </Row>
        )}
      />
    </>
  );
};

MyActions.propTypes = {
  data: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  currentUserRole: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired,
  row: PropTypes.shape({
    id: PropTypes.string,
    name: PropTypes.string,
    original: PropTypes.arrayOf(
      PropTypes.shape({
        related_table_name: PropTypes.string,
        related_uuid: PropTypes.string,
        name: PropTypes.string,
        related_object_status: PropTypes.string,
      }),
    ),
  }).isRequired,
};

const mapStateToProps = state => {
  const currentUserRole = getCurrentUserRole(state);
  return { currentUserRole };
};

export default withRecordsListHandling(
  connect(mapStateToProps)(MyActions),
  getActiveAssignments,
  ['assignment'],
  {
    defaultLimit: 10,
    searchBy: null,
    defaultSort: '-created',
    useLocationFiltering: false,
    multicolumnSearch: false,
    staticQueryParams: {
      // Show only active Assignments
      is_active: true,
    },
  },
);
