import PropTypes from 'prop-types';
import React from 'react';
import { FormattedCost } from 'rapidfab/i18n';
import { connect } from 'react-redux';
import {
  getBureauDefaultCurrency,
  getCurrencyConversion,
  getSelectedCurrency,
} from 'rapidfab/selectors';

const FormattedLocalizedCost = ({ currency, value }) => (
  <FormattedCost currency={currency} value={value} />
);

FormattedLocalizedCost.propTypes = {
  value: PropTypes.number.isRequired,
  currency: PropTypes.string.isRequired,
};

const mapStateToProps = (state, props) => {
  const defaultCurrency = getBureauDefaultCurrency(state);
  const selectedCurrency = getSelectedCurrency(state);

  let costValue = props.value;
  let costCurrency = defaultCurrency;

  if (selectedCurrency && selectedCurrency !== defaultCurrency) {
    const selectedCurrencyConversion = getCurrencyConversion(state, selectedCurrency);

    costValue = selectedCurrencyConversion.value * props.value;
    costCurrency = selectedCurrency;
  }

  return {
    currency: costCurrency,
    value: costValue,
  };
};

export default connect(mapStateToProps)(FormattedLocalizedCost);
