import ordersDA from './orders/da';
import runsDA from './runs/runsDA';
import piecesDA from './pieces/piecesDA';
import serviceDA from './service/da.json';
import msnavDA from './msnav/da.json';

import ordersDE from './orders/de';
import runsDE from './runs/runsDE';
import piecesDE from './pieces/piecesDE';
import serviceDE from './service/de.json';
import msnavDE from './msnav/de.json';

import ordersEN from './orders/en';
import runsEN from './runs/runsEN';
import piecesEN from './pieces/piecesEN';
import serviceEN from './service/en.json';
import msnavEN from './msnav/en.json';

import ordersFR from './orders/fr';
import runsFR from './runs/runsFR';
import piecesFR from './pieces/piecesFR';
import serviceFR from './service/fr.json';
import msnavFR from './msnav/fr.json';

import ordersJA from './orders/ja';
import runsJA from './runs/runsJA';
import piecesJA from './pieces/piecesJA';
import serviceJA from './service/ja.json';
import msnavJA from './msnav/ja.json';

export const planDA = Object.assign(ordersDA, runsDA, piecesDA, serviceDA, msnavDA);

export const planDE = Object.assign(ordersDE, runsDE, piecesDE, serviceDE, msnavDE);

export const planEN = Object.assign(ordersEN, runsEN, piecesEN, serviceEN, msnavEN);

export const planFR = Object.assign(ordersFR, runsFR, piecesFR, serviceFR, msnavFR);

export const planJA = Object.assign(ordersJA, runsJA, piecesJA, serviceJA, msnavJA);
