import React from 'react';

const City = props => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    aria-hidden="true"
    data-icon="city"
    data-prefix="fas"
    viewBox="0 -64 640 640"
    {...props}
  >
    <path
      fill="currentColor"
      d="M640 240v240c0 17.67-14.33 32-32 32H32c-17.67 0-32-14.33-32-32V144c0-26.51 21.49-48 48-48h16V24.01C64 10.76 74.75.91 88 .91s24 9.84 24 23.1v72h64v-72c0-13.25 10.75-23.1 24-23.1s24 9.84 24 23.1v71.1h64v-47.1c0-26.51 21.49-48 48-48l96 .005c26.51 0 48 21.49 48 48v143.1h112C618.5 192 640 213.5 640 240zm-512-68c0-6.625-5.375-12-12-12H76c-6.625 0-12 5.375-12 12v40c0 6.625 5.375 12 12 12h40c6.625 0 12-5.375 12-12v-40zm0 96c0-6.625-5.375-12-12-12H76c-6.625 0-12 5.375-12 12v40c0 6.625 5.375 12 12 12h40c6.625 0 12-5.375 12-12v-40zm0 96c0-6.625-5.375-12-12-12H76c-6.625 0-12 5.375-12 12v40c0 6.625 5.375 12 12 12h40c6.625 0 12-5.375 12-12v-40zm128-192c0-6.625-5.375-12-12-12h-40c-6.625 0-12 5.375-12 12v40c0 6.625 5.375 12 12 12h40c6.625 0 12-5.375 12-12v-40zm0 96c0-6.625-5.375-12-12-12h-40c-6.6 0-12 5.4-12 12v40c0 6.625 5.375 12 12 12h40c6.625 0 12-5.375 12-12v-40zm0 96c0-6.625-5.375-12-12-12h-40c-6.625 0-12 5.38-12 12v40c0 6.625 5.375 12 12 12h40c6.625 0 12-5.375 12-12v-40zM416 76.01c0-6.625-5.375-12-12-12h-40c-6.625 0-12 5.375-12 12v40c0 6.625 5.375 12 12 12h40c6.625 0 12-5.375 12-12v-40zm0 95.99c0-6.625-5.375-12-12-12h-40c-6.625 0-12 5.38-12 12v40c0 6.625 5.375 12 12 12h40c6.625 0 12-5.375 12-12v-40zm0 96c0-6.625-5.375-12-12-12h-40c-6.625 0-12 5.38-12 12v40c0 6.625 5.375 12 12 12h40c6.625 0 12-5.375 12-12v-40zm160 0c0-6.625-5.375-12-12-12h-40c-6.625 0-12 5.375-12 12v40c0 6.625 5.375 12 12 12h40c6.625 0 12-5.375 12-12v-40zm0 96c0-6.625-5.375-12-12-12h-40c-6.625 0-12 5.375-12 12v40c0 6.625 5.375 12 12 12h40c6.625 0 12-5.375 12-12v-40z"
    />
  </svg>
);

export default City;
