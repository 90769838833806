import React, { useEffect } from 'react';
import DDWPaymentStatus from 'rapidfab/components/records/order/edit/DDWPaymentStatus';
import { useDispatch, useSelector } from 'react-redux';
import { handleInitializeShoppingCartData, getIsOrderCreatedThroughShoppingCart } from 'rapidfab/utils/shoppingCartUtils';
import * as Selectors from 'rapidfab/selectors';
import { API_RESOURCES, FEATURES } from 'rapidfab/constants';
import { extractUuid } from 'rapidfab/utils/uuidUtils';
import Actions from 'rapidfab/actions';
import PropTypes from 'prop-types';
import _find from 'lodash/find';
import * as baseStateSelectors from 'rapidfab/selectors/baseStateSelectors';
import Loading from 'rapidfab/components/Loading';
import Alert from 'rapidfab/utils/alert';

const DDWPaymentStatusContainer = ({
  order,
}) => {
  const dispatch = useDispatch();

  const shoppingCartForOrder = useSelector(state => Selectors.getShoppingCartForOrder(state, order?.uri));
  const shoppingCartItems = useSelector(state =>
    Selectors.getShoppingCartItemsForShoppingCartUri(state, shoppingCartForOrder?.uri));
  const modelsByUri = useSelector(Selectors.getModelsByUri);
  const materials = useSelector(Selectors.getMaterials);
  // The regular selector in `selectors/payment.js` was returning a `[null]` array.
  const payments = useSelector(state => baseStateSelectors.getStatePayments(state)?.resources);
  const paymentForThisOrder = _find(payments, { payment_for: order.uri });

  const shoppingCartUuid = extractUuid(shoppingCartForOrder?.uri);

  const isPaymentSystemFeatureEnabled = useSelector(state =>
    Selectors.isFeatureEnabled(state, FEATURES.PAYMENT_SYSTEM));
  const isRobozeDDWFeatureEnabled = useSelector(state =>
    Selectors.isFeatureEnabled(state, FEATURES.ROBOZE_DDW));
  const isDigitalDesignWarehouseFeatureEnabled = useSelector(state =>
    Selectors.isFeatureEnabled(state, FEATURES.DIGITAL_DESIGN_WAREHOUSE));

  const isSubmittingPayment = useSelector(state =>
    state.ui.nautilus[API_RESOURCES.PAYMENT].put.fetching
    || state.ui.nautilus[API_RESOURCES.PAYMENT].post.fetching);

  const onInitialize = async () => {
    // Initialise shopping cart related to this order.
    if ([isRobozeDDWFeatureEnabled, isDigitalDesignWarehouseFeatureEnabled].every(Boolean)) {
      await dispatch(Actions.Api.nautilus[API_RESOURCES.SHOPPING_CART].list({
        order: order.uri,
      }));
    }

    if (isPaymentSystemFeatureEnabled) {
    // Initialise payment related to this order.
      await dispatch(Actions.Api.nautilus[API_RESOURCES.PAYMENT].list({
        payment_for: order.uri,
      }));
    }
  };

  useEffect(() => {
    onInitialize();
  }, []);

  useEffect(() => {
    if (shoppingCartUuid
      && [isRobozeDDWFeatureEnabled, isDigitalDesignWarehouseFeatureEnabled].every(Boolean)) {
      handleInitializeShoppingCartData(dispatch, shoppingCartUuid);
    }
  }, [shoppingCartUuid]);

  const refreshPaymentStatus = () =>
  // Same as above, but with force: true flag.
    dispatch(Actions.Api.nautilus[API_RESOURCES.PAYMENT].list({
      payment_for: order.uri,
    }, {}, {}, {}, true));

  const handleUpdatePaymentStatus = async updatedStatus => {
    const payload = {
      status: updatedStatus,
    };

    await dispatch(Actions.Api.nautilus[API_RESOURCES.PAYMENT].put(extractUuid(paymentForThisOrder.uri), payload));

    refreshPaymentStatus();
    Alert.success('Status successfully updated.');
  };

  // Every item must have a `price_per` key.
  const orderIsCreatedThroughShoppingCart = getIsOrderCreatedThroughShoppingCart(shoppingCartItems);

  const initialFormValues = {
    status: paymentForThisOrder?.status,
  };

  return (payments ? (
    orderIsCreatedThroughShoppingCart
    && (
      <DDWPaymentStatus
        payment={paymentForThisOrder}
        materials={materials}
        shoppingCartItems={shoppingCartItems}
        modelLibrariesByUri={modelsByUri}
        handleUpdatePaymentStatus={handleUpdatePaymentStatus}
        isSubmittingPayment={isSubmittingPayment}
        initialFormValues={initialFormValues}
      />
    )
  ) : (
    <Loading />
  )
  );
};

export default DDWPaymentStatusContainer;

DDWPaymentStatusContainer.propTypes = {
  order: PropTypes.shape({
    uri: PropTypes.string,
  }).isRequired,
};
