import { isUuid } from 'rapidfab/utils/uuidUtils';
import React from 'react';
import PropTypes from 'prop-types';

import { FormattedMessage } from 'react-intl';

import ConfirmationModal from 'rapidfab/components/ConfirmationModal';
import { COLORS } from 'rapidfab/constants';
import { useModal } from 'rapidfab/hooks';
import {
  Button, Col,
  Form,
  FormControl,
  FormGroup,
  FormLabel,
  FormText, Row,
} from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faClose, faWarning } from '@fortawesome/free-solid-svg-icons';
import { DateInput } from 'rapidfab/components/forms/DateInput';
import { selectInputStyles } from 'rapidfab/constants/styles';
import Select from 'react-select';
import reactSelectUserFormatter from 'rapidfab/utils/reactSelectUserFormatter';
import getVisibleCustomFieldReferencesWithOptions from 'rapidfab/utils/getVisibleCustomFieldReferencesWithOptions';
import CustomFieldList from '../forms/CustomFieldList';

const DowntimeForm = ({
  selection,
  handleSelectionChange,
  description,
  downtime,
  finishDate,
  finishTime,
  handleInputChange,
  handleSubmit,
  assignedUser,
  users,
  handleAssignUser,
  isFinishValid,
  initialValues,
  startDate,
  startTime,
  isStartValid,
  pastTimeConfirmation,
  handlePastValue,
  customDowntimeFieldReferences,
  customFieldValues,
  handleCustomInputChange,
  customFieldsValue,
}) => {
  const formattedUsers = reactSelectUserFormatter(users);
  const [handleModal, RescheduleWarningConfirmationModal] = useModal();

  const visibleRunCustomFieldReferencesWithOptions = getVisibleCustomFieldReferencesWithOptions({
    fieldReferences: customDowntimeFieldReferences,
    fieldValues: customFieldValues,
  });

  const handleShowRescheduleWarningConfirmationModal = event => {
    event.preventDefault();

    handleModal({
      title: 'Maintenance Event',
      bodyText: 'Please note the Gantt chart will take around 15 minutes to reschedule runs around this maintenance event.',
      onConfirm: () => handleSubmit(event),
    });
  };

  return (
    <div>
      <RescheduleWarningConfirmationModal />

      <div className="d-flex align-items-center justify-content-between">
        <h2 className="wrap-text mt5">
          {downtime
            ? `Editing ${initialValues.description}`
            : <FormattedMessage id="printer.downtime.create" defaultMessage="Create New Downtime" />}
        </h2>
        {selection !== 'none' && (
          <FontAwesomeIcon
            icon={faClose}
            onClick={() => handleSelectionChange('none')}
            tabIndex={0}
            role="button"
            className="custom-darken-modal-button"
          />
        )}
      </div>

      <Form onSubmit={handleShowRescheduleWarningConfirmationModal}>
        <FormGroup controlId="uxDescription" className="form-group">
          <FormLabel>
            <FormattedMessage
              id="field.description"
              defaultMessage="Description"
            />
          </FormLabel>
          <FormControl
            name="description"
            onChange={handleInputChange}
            required
            as="textarea"
            type="text"
            value={description}
          />
        </FormGroup>
        <FormGroup controlId="uxCustomFields" className="form-group">
          <CustomFieldList
            customFieldReferences={visibleRunCustomFieldReferencesWithOptions}
            customFieldValues={Object.values(customFieldsValue)}
            variant="row"
            onChange={handleCustomInputChange}
          />
        </FormGroup>
        <FormGroup controlId="uxDowntimeOwner" className="form-group">
          <FormLabel>
            <FormattedMessage
              id="field.downtimeOwner"
              defaultMessage="Owner"
            />
          </FormLabel>
          <Select
            data-cy="downtimeOwner"
            options={formattedUsers}
            onChange={value => {
              handleAssignUser('owner', value);
            }}
            placeholder={(
              <FormattedMessage id="field.downtimeUserSelector" defaultMessage="Assign owner" />
            )}
            value={assignedUser}
            styles={selectInputStyles}
            isClearable
          />
        </FormGroup>
        <hr />
        <Row>
          <Col xs={12} sm={6}>
            <FormGroup
              controlId="uxStartDate"
              className="form-group"
            >
              <FormLabel>Start Date</FormLabel>
              <DateInput
                name="startDate"
                onChange={handleInputChange}
                required
                value={startDate}
              />
            </FormGroup>

            <FormGroup
              controlId="uxStartTime"
              className="form-group"
            >
              <FormLabel>Start Time</FormLabel>
              <FormControl
                name="startTime"
                onChange={handleInputChange}
                required
                type="time"
                value={startTime}
              />
              {
                pastTimeConfirmation && (
                  <ConfirmationModal
                    handleCancel={() => handlePastValue(false)}
                    handleConfirm={() => handlePastValue(true)}
                    message="Warning: you have selected a date in the past. Would you like to continue with this date?"
                  />
                )
              }
              {!isStartValid && (
                <FormText>
                  <FontAwesomeIcon className="spacer-left spacer-right" icon={faWarning} style={{ color: COLORS.ORANGE }} />
                  <FormattedMessage
                    id="validateStartTimeDowntimes"
                    defaultValue="Start Date is in the past"
                  />
                </FormText>
              )}
            </FormGroup>
          </Col>
          <Col xs={12} sm={6}>
            <FormGroup
              controlId="uxFinishDate"
              className="form-group"
              validationState={isFinishValid ? null : 'error'}
            >
              <FormLabel>End Date</FormLabel>
              <DateInput
                name="finishDate"
                onChange={handleInputChange}
                required
                value={finishDate}
              />
            </FormGroup>

            <FormGroup
              controlId="uxFinishTime"
              className="form-group"
              validationState={isFinishValid ? null : 'error'}
            >
              <FormLabel>End Time</FormLabel>
              <FormControl
                name="finishTime"
                onChange={handleInputChange}
                required
                type="time"
                value={finishTime}
              />
              {!isFinishValid && (
                <FormText>
                  <FormattedMessage
                    id="validateFinishTime"
                    defaultMessage="Must begin after the start time"
                  />
                </FormText>
              )}
            </FormGroup>
          </Col>
        </Row>

        <div className="d-flex align-items-center justify-content-end mt5">
          <Button block disabled={!isFinishValid} type="submit">
            {downtime ?
              <FormattedMessage id="printer.downtime.update" defaultMessage="Update Downtime" /> :
              <FormattedMessage id="printer.downtime.create" defaultMessage="Create New Downtime" />}
          </Button>

          {isUuid(selection) && (
            <Button
              block
              variant="danger"
              className="spacer-left"
              onClick={() => handleSelectionChange(`DELETE/${selection}`)}
            >
              Delete Downtime
            </Button>
          )}
        </div>
      </Form>
    </div>
  );
};

DowntimeForm.defaultProps = {
  downtime: null,
  initialValues: null,
};

DowntimeForm.propTypes = {
  description: PropTypes.string.isRequired,
  downtime: PropTypes.string,
  finishDate: PropTypes.string.isRequired,
  finishTime: PropTypes.string.isRequired,
  handleInputChange: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  initialValues: PropTypes.shape({
    description: PropTypes.string,
  }),
  isFinishValid: PropTypes.func.isRequired,
  isStartValid: PropTypes.func.isRequired,
  startDate: PropTypes.string.isRequired,
  startTime: PropTypes.string.isRequired,
  pastTimeConfirmation: PropTypes.oneOfType([
    PropTypes.bool,
    PropTypes.instanceOf(Object),
  ]).isRequired,
  handlePastValue: PropTypes.func.isRequired,
  assignedUser: PropTypes.shape({
    label: PropTypes.string.isRequired,
    value: PropTypes.string.isRequired,
  }).isRequired,
  users: PropTypes.arrayOf({}).isRequired,
  handleAssignUser: PropTypes.func.isRequired,
  customDowntimeFieldReferences: PropTypes.arrayOf({}).isRequired,
  customFieldValues: PropTypes.arrayOf({}).isRequired,
  handleCustomInputChange: PropTypes.func.isRequired,
  customFieldsValue: PropTypes.arrayOf({}).isRequired,
  selection: PropTypes.string.isRequired,
  handleSelectionChange: PropTypes.func.isRequired,
};

export default DowntimeForm;
