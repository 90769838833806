const merckNotesPlaceholder = '"the part must be made of X material". "need part to be solvent resistant", "clear material needed", "Part needs to be air tight", etc.';

export default ({
  isNormalUser = false,
  isSintaviaUser = false,
  isReadOnly = false,
  isDanfossUser = false,
  isMerckUser = false,
  isBoeingUser = false,
  is3MAquaUser = false,
  is3MUser = false,
  isOerlikonUser = false,
  workInstructionsExist = false,
  isCustomWorkflow = false,
  isRobozeBureauOrderFieldsUser = false,
}) => ({
  printable: {
    show: (isMerckUser || isBoeingUser) ? isNormalUser : true,
  },
  status: {
    editable: isNormalUser,
  },
  modelFileUnits: {
    show: isNormalUser || isSintaviaUser || isDanfossUser || is3MUser,
    required: isNormalUser || isSintaviaUser || isDanfossUser || is3MUser,
  },
  modelUserUnits: {
    show: isNormalUser || isSintaviaUser || isDanfossUser || is3MUser,
    required: isNormalUser || isSintaviaUser || isDanfossUser || is3MUser,
  },
  replaceModel: {
    show: !isReadOnly,
  },
  layerThickness: {
    show: (isNormalUser || isSintaviaUser) && !isOerlikonUser,
  },
  traveler: {
    show: isNormalUser || isSintaviaUser,
  },
  customerID: {
    show: isNormalUser && isBoeingUser,
    required: false,
    id: 'partNumber',
    defaultMessage: 'Part Number',
  },
  baseMaterial: {
    show: isNormalUser || isSintaviaUser || is3MAquaUser ||
      is3MUser || isReadOnly || (isBoeingUser && !isNormalUser),
    editable: isNormalUser || isSintaviaUser || is3MUser || is3MAquaUser,
  },
  supportMaterial: {
    show: (isNormalUser || isReadOnly) && !isSintaviaUser && !(isRobozeBureauOrderFieldsUser && !isNormalUser),
    editable: !isReadOnly,
  },
  workflow: {
    show: (isNormalUser || isSintaviaUser || isReadOnly || (isBoeingUser && isNormalUser))
      && !(isRobozeBureauOrderFieldsUser && !isNormalUser),
    editable: !isReadOnly && !workInstructionsExist && !isCustomWorkflow,
    required: true,
  },
  infillStrategy: {
    show: isNormalUser && !isSintaviaUser && !isBoeingUser,
  },
  supportStrategy: {
    show: isNormalUser && !isSintaviaUser && !isBoeingUser,
  },
  notes: {
    show: isNormalUser || isMerckUser || isBoeingUser || is3MUser
    || is3MAquaUser || isOerlikonUser,
    editable: !isReadOnly,
    required: false,
    placeholder: (isMerckUser && !isNormalUser) ? merckNotesPlaceholder : '',
  },
  designHours: {
    show: isNormalUser && !isBoeingUser,
  },
  materialUsed: {
    show: ((!isMerckUser && isReadOnly && !isNormalUser) || (is3MAquaUser && isNormalUser))
      && !(isRobozeBureauOrderFieldsUser && !isNormalUser),
  },
  bureauCost: {
    show: (!isMerckUser && !is3MAquaUser && isReadOnly && !isNormalUser)
      && !(isRobozeBureauOrderFieldsUser && !isNormalUser),
    id: 'estimates.bureauCost',
    defaultMessage: 'Bureau Cost',
  },
  discountByQuantity: {
    show: false,
    overlay: [
      { quantity: 5, percentage: 5 },
      { quantity: 10, percentage: 8 },
      { quantity: 50, percentage: 10 },
    ],
  },
});
