import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Tab, Tabs } from 'react-bootstrap';
import { FEATURES } from 'rapidfab/constants';
import * as Selectors from 'rapidfab/selectors';
import ProcessSteps from './ProcessSteps';
import PrepWorkflowSteps from './PrepWorkflowSteps';

const WORKFLOW_TABS = {
  PREP_WORKFLOW: 'prepWorkflow',
  PRODUCTION_WORKFLOW: 'productionWorkflow',
};

const AllWorkflowsSteps = ({ isPrepWorkflowFeatureEnabled, piece }) => {
  const [activeTab, setActiveTab] = useState(WORKFLOW_TABS.PRODUCTION_WORKFLOW);

  if (!piece) {
    return null;
  }

  if (!isPrepWorkflowFeatureEnabled) {
    // If prep workflow feature is disabled,
    // we have to render production workflow only (if non-null)
    if (!piece.workflow) {
      return null;
    }

    return (
      <ProcessSteps
        piece={piece}
        withHeader
      />
    );
  }

  return (
    <>
      <Tabs
        activeKey={activeTab}
        onSelect={tabName => setActiveTab(tabName)}
        id="workflowTabs"
      >
        <Tab eventKey={WORKFLOW_TABS.PREP_WORKFLOW} title="Prep Workflow" />
        {piece.workflow && <Tab eventKey={WORKFLOW_TABS.PRODUCTION_WORKFLOW} title="Production Workflow" />}
      </Tabs>
      {
        activeTab === WORKFLOW_TABS.PREP_WORKFLOW && (
          <PrepWorkflowSteps
            lineItemUri={piece.line_item}
          />
        )
      }
      {
        // May not be rendered if production workflow is empty (see tab) (
        activeTab === WORKFLOW_TABS.PRODUCTION_WORKFLOW && (
          <ProcessSteps
            piece={piece}
          />
        )
      }
    </>
  );
};

AllWorkflowsSteps.propTypes = {
  piece: PropTypes.shape({
    workflow: PropTypes.string,
    line_item: PropTypes.string,
  }).isRequired,

  isPrepWorkflowFeatureEnabled: PropTypes.bool.isRequired,
};

const mapStateToProps = state => {
  const isPrepWorkflowFeatureEnabled = Selectors.isFeatureEnabled(state, FEATURES.PREP_WORKFLOW);

  return {
    isPrepWorkflowFeatureEnabled,
  };
};

export default connect(mapStateToProps)(AllWorkflowsSteps);
