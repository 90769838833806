import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { CREATE_RUNS_STATUSES_MAP } from 'rapidfab/mappings';
import { getRouteURI } from 'rapidfab/utils/uriUtils';
import { extractUuid } from 'rapidfab/utils/uuidUtils';
import Loading from 'rapidfab/components/Loading';
import { CREATE_RUNS_STATUSES, ROUTES } from 'rapidfab/constants';
import { Card } from 'react-bootstrap';
import { Link, useNavigate } from 'react-router-dom';

import TableWithSearching from 'rapidfab/components/Tables/TableWithSearching';

const CreateRuns = ({
  data,
  onSort,
  fetching,
  printersByUri,
  autoRedirectToRun,
  createRunsItem,
  ...componentProps
}) => {
  const breadcrumbs = ['createRuns'];
  // For now, loader is shown only if we need to redirect user to the Printing Run page
  const showLoader = autoRedirectToRun;
  const navigate = useNavigate();

  const redirectToPrintingRunUri = createRunsItem?.primary_run;

  useEffect(
    () => {
      if (autoRedirectToRun && redirectToPrintingRunUri) {
        navigate(getRouteURI(ROUTES.RUN_EDIT, { uuid: extractUuid(redirectToPrintingRunUri) }, {}, true));
      }
    },
    // Printing Run may not yet be created, so waiting for EventStream to provided it
    [redirectToPrintingRunUri],
  );

  const columns = [
    {
      type: 'text',
      uid: 'field.id',
      accessor: 'uuid',
      defaultMessage: 'ID',
      short: true,
    },
    {
      type: 'translatable',
      uid: 'field.status',
      accessor: 'status',
      defaultMessage: 'Status',
      mapping: CREATE_RUNS_STATUSES_MAP,
      isSortable: true,
    },
    {
      type: 'custom',
      uid: 'field.runName',
      accessor: 'run_name',
      defaultMessage: 'Printing Run',
      isSortable: true,
      // eslint-disable-next-line no-shadow
      Cell: ({ row: { original: data } }) => {
        if (!data.primary_run) {
          return data.run_name;
        }
        return (
          <Link
            to={getRouteURI(ROUTES.RUN_EDIT, { uuid: extractUuid(data.primary_run) }, {}, true)}
          >
            {data.run_name}
          </Link>
        );
      },
    },
    {
      type: 'custom',
      uid: 'field.primary_workstation',
      accessor: 'primary_workstation',
      defaultMessage: 'Primary workstation',
      isSortable: true,
      Cell: ({ value }) => {
        const printer = printersByUri && printersByUri[value];
        return (
          <Link
            to={getRouteURI(ROUTES.PRINTER_EDIT, { uuid: extractUuid(value) }, {}, true)}
          >
            {printer ? printer.name : value}
          </Link>
        );
      },
    },
    {
      type: 'custom',
      uid: 'plan.runs',
      accessor: 'resulting_runs',
      defaultMessage: 'Runs',
      isSortable: false,
      Cell: ({ value }) => (
        <span>{value ? value.length : 0}</span>
      ),
    },
    {
      type: 'custom',
      uid: 'pieces',
      accessor: 'pieces',
      defaultMessage: 'Pieces',
      isSortable: false,
      Cell: ({ value }) => (
        <span>{value ? value.length : 0}</span>
      ),
    },
    {
      type: 'time',
      uid: 'created',
      accessor: 'created',
      defaultMessage: 'Created',
      isSortable: true,
    },
  ];

  if (autoRedirectToRun && createRunsItem && createRunsItem.status === CREATE_RUNS_STATUSES.ERROR) {
    // When we need to redirect user to Run page, but Run failed to create
    return (
      <Card>
        <div className="text-center">
          {createRunsItem.notes}
        </div>
      </Card>
    );
  }

  if (showLoader) {
    return <Loading />;
  }

  return (
    <TableWithSearching
      {...componentProps}
      data={data}
      columns={columns}
      withBreadcrumbs
      breadcrumbs={breadcrumbs}
      isFetching={fetching}
      isManualSoringEnabled
      manualSortingFunc={onSort}
      initialSortedDesc
      initialSortedColumn="updated"
    />
  );
};

CreateRuns.propTypes = {
  printersByUri: PropTypes.objectOf(PropTypes.shape({
    name: PropTypes.string.isRequired,
  })).isRequired,
  autoRedirectToRun: PropTypes.bool.isRequired,
  createRunsItem: PropTypes.shape({
    status: PropTypes.string,
    primary_run: PropTypes.string,
    notes: PropTypes.string,
  }),
  data: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  fetching: PropTypes.bool.isRequired,
  onSort: PropTypes.func.isRequired,
  row: PropTypes.shape({
    original: PropTypes.shape({
      uuid: PropTypes.string.isRequired,
      uri: PropTypes.string.isRequired,
      primary_run: PropTypes.shape({}),
      run_name: PropTypes.string,
    }),
  }).isRequired,
  value: PropTypes.string.isRequired,
};

CreateRuns.defaultProps = {
  createRunsItem: null,
};

export default CreateRuns;
