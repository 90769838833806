import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Badge, OverlayTrigger, Tooltip } from 'react-bootstrap';
import SmoothCollapse from 'react-smooth-collapse';
import { FormattedMessage } from 'rapidfab/i18n';
import {
  WORK_INSTRUCTION_BADGE_REPORT_COLOR_MAP,
  WORK_INSTRUCTION_REPORT_TYPE_ANSWERS_MAP,
  WORK_INSTRUCTION_REPORT_TYPE_MAP,
} from 'rapidfab/mappings';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import _get from 'lodash/get';
import _map from 'lodash/map';
import _find from 'lodash/find';
import _isEmpty from 'lodash/isEmpty';
import { getShortUUID } from 'rapidfab/utils/uuidUtils';
import {
  faCalendar,
  faCheckCircle,
  faChevronDown,
  faChevronUp,
  faComment,
  faEye,
  faUser,
  faXmarkCircle,
} from '@fortawesome/free-solid-svg-icons';
import dayjs from 'dayjs';
import RCTooltip from 'rc-tooltip';
import { truncateText } from 'rapidfab/utils/stringUtils';
import { WORK_INSTRUCTION_REPORT_TYPES } from 'rapidfab/constants';

const WorkInstructionReportBadge = ({ instruction, customColor, customCss, children }) => {
  const hasThreshold = !_isEmpty(instruction.threshold);
  const outsideThreshold = hasThreshold && instruction.outside_threshold;
  const badgeStatus = !hasThreshold ? 'general' : (outsideThreshold ? 'danger' : 'success');
  const badgeColor = customColor || WORK_INSTRUCTION_BADGE_REPORT_COLOR_MAP[badgeStatus];

  return (
    <Badge
      className={`badge-sm work-instruction-report-badge ${customCss || ''}`}
      bg="primary"
      ref={element =>
        element && element.style.setProperty('background-color', badgeColor,
          'important')}
    >
      {
        children || (
          <FormattedMessage
            {...WORK_INSTRUCTION_REPORT_TYPE_ANSWERS_MAP[instruction.report_type]}
          />
        )
      }
    </Badge>
  );
};

WorkInstructionReportBadge.defaultProps = {
  customColor: null,
  customCss: 'spacer-left',
  children: null,
};

WorkInstructionReportBadge.propTypes = {
  instruction: PropTypes.shape({
    threshold: PropTypes.shape({}),
    outside_threshold: PropTypes.bool,
    report_type: PropTypes.string,
    uuid: PropTypes.string,
  }).isRequired,
  customColor: PropTypes.string,
  customCss: PropTypes.oneOfType([PropTypes.oneOf([null]), PropTypes.string]),
  children: PropTypes.node,
};

const ChecklistsForProcessSteps = ({
  processStep,
  index,
  workChecklistLinkings,
  workstationsByUri,
  isDebugModeEnabled,
  workInstructionsReports,
  usersByUri,
  hideWorkInstructionBadge,
}) => {
  const [collapsed, setCollapsed] = useState(false);
  const { workstation, name, uri } = processStep;
  const checklist = _find(
    workChecklistLinkings,
    ({ process_step }) => process_step === uri,
  );
  const emptyWorkInstruction = {
    work_instructions: [],
  };
  const { work_checklist: workChecklist = emptyWorkInstruction } = checklist || {};
  const workstationName = workstation
    ? _get(workstationsByUri[workstation], 'name', '')
    : 'Any Workstation';

  const renderThresholdTooltipCondition = (hasThreshold, outsideThreshold, instruction) => {
    if (!hasThreshold) {
      return null;
    }

    return (
      <WorkInstructionReportBadge instruction={instruction}>
        <FontAwesomeIcon icon={outsideThreshold ? faXmarkCircle : faCheckCircle} className="spacer-right" />
        <span>Threshold</span>
      </WorkInstructionReportBadge>
    );
  };

  const renderWorkInstructionValue = (isBadgeValue, instruction, reportAnswer, type) => {
    const reportValueThresholds = {
      reportValue: 23,
      userName: 30,
    };

    if (isBadgeValue) {
      return <WorkInstructionReportBadge customCss={null} instruction={instruction} />;
    }

    if (reportAnswer.length >= reportValueThresholds[type]) {
      return (
        <OverlayTrigger
          placement="top"
          overlay={(
            <Tooltip className="custom-tooltip">
              <p>{reportAnswer}</p>
            </Tooltip>
          )}
        >
          <p className="m-a-0">{truncateText(reportAnswer, reportValueThresholds[type])}</p>
        </OverlayTrigger>
      );
    }

    return (
      <p className="m-a-0">{reportAnswer}</p>
    );
  };

  const renderWorkInstructionBadgeTooltip = (
    reportValue,
    reportAnswer,
    instruction,
    defaultReportValue,
    thresholdData,
  ) => {
    if (!reportValue) {
      return null;
    }

    const isBadgeValue = !!defaultReportValue;
    const user = usersByUri[reportValue.user]?.name || 'Unknown';
    const completedDate = dayjs(reportValue.completed).format('MMM D, YYYY h:mm A');
    const { hasThreshold, outsideThreshold } = thresholdData;

    return (
      <div className="work-instruction-report-tooltip">
        <div className="mb10 d-flex align-items-center justify-content-between">
          <div className="d-flex align-items-center">
            <div className="work-instruction-report-tooltip-item-icon">
              <FontAwesomeIcon icon={faComment} className="spacer-right" />
            </div>
            {renderWorkInstructionValue(isBadgeValue, instruction, reportAnswer, 'reportValue')}
          </div>
          <div className="work-instruction-report-tooltip-badge-wrapper">
            <WorkInstructionReportBadge
              instruction={instruction}
              customColor={WORK_INSTRUCTION_BADGE_REPORT_COLOR_MAP.success}
            >
              <FormattedMessage
                id="status.complete"
                defaultMessage="Complete"
              />
            </WorkInstructionReportBadge>
          </div>
        </div>
        <div className="mb10 d-flex align-items-center justify-content-between">
          <div className="d-flex align-items-center">
            <div className="work-instruction-report-tooltip-item-icon">
              <FontAwesomeIcon icon={faUser} className="spacer-right" />
            </div>
            {renderWorkInstructionValue(null, instruction, user, 'userName')}
          </div>
          <div className="work-instruction-report-tooltip-badge-wrapper">
            {
              renderThresholdTooltipCondition(hasThreshold, outsideThreshold, instruction)
            }
          </div>
        </div>
        <div className="d-flex align-items-center">
          <div className="d-flex align-items-center">
            <div className="work-instruction-report-tooltip-item-icon">
              <FontAwesomeIcon icon={faCalendar} className="spacer-right" />
            </div>
            {completedDate}
          </div>
        </div>
      </div>
    );
  };

  const renderWorkInstructionBadge = (reportValue, reportAnswer, instruction, defaultReportValue, thresholdData) => {
    if (instruction.report_type === WORK_INSTRUCTION_REPORT_TYPES.NO_ENTRY) {
      return null;
    }

    if (!reportValue) {
      return (
        <Badge className="badge-sm spacer-left" bg="warning">
          <FormattedMessage
            id="status.pending"
            defaultMessage="Pending"
          />
        </Badge>
      );
    }

    const { hasThreshold, outsideThreshold } = thresholdData;

    return (
      <RCTooltip
        placement="top"
        destroyTooltipOnHide
        overlayClassName="work-instruction-report-tooltip-hidden"
        mouseEnterDelay={0.1}
        mouseLeaveDelay={0.2}
        overlayInnerStyle={{ backgroundColor: 'transparent', padding: 0, border: 'none' }}
        zIndex={10000000}
        overlay={(
          renderWorkInstructionBadgeTooltip(
            reportValue,
            reportAnswer,
            instruction,
            defaultReportValue,
            { hasThreshold, outsideThreshold })
        )}
      >
        <span>
          <WorkInstructionReportBadge instruction={instruction}>
            <FontAwesomeIcon icon={faEye} className="spacer-right" />
            {
              defaultReportValue ? (
                <FormattedMessage
                  {...WORK_INSTRUCTION_REPORT_TYPE_ANSWERS_MAP[instruction.report_type]}
                />
              ) : (
                <span>{truncateText(reportAnswer, 20)}</span>
              )
            }
          </WorkInstructionReportBadge>
        </span>
      </RCTooltip>
    );
  };

  return (
    <div className="item-container" key={uri}>
      <div className="item-header">
        <div>
          <b className="wrap-text">
            {index + 1} {name}
            {isDebugModeEnabled && (
              <Badge className="spacer-left text-white" bg="info">
                {getShortUUID(uri)}
              </Badge>
            )}
          </b>
          {workChecklist.description
        && <div>{workChecklist.description}</div>}
        </div>
        <div className="item-header-right-content">
          <div className="item-header-right-content-labels">
            <b>{workstationName}</b>
            {workChecklist.additional_instruction_url
              && (
                <div>
                  <a
                    href={workChecklist.additional_instruction_url}
                  >
                    {workChecklist.additional_instruction_url}
                  </a>
                </div>
              )}
          </div>
          {workChecklist.work_instructions.length ? (
            <FontAwesomeIcon
              className="ml15"
              icon={collapsed ? faChevronDown : faChevronUp}
              onClick={() => setCollapsed(!collapsed)}
            />
          ) : null}
        </div>
      </div>
      <div>
        <SmoothCollapse expanded={!collapsed}>
          {_map(workChecklist.work_instructions, ((instruction, instructionIndex) => {
            const workInstructionReportByInstructionUUID = workInstructionsReports?.[instruction?.uuid] || null;
            const defaultReportValue = WORK_INSTRUCTION_REPORT_TYPE_ANSWERS_MAP[instruction.report_type];
            const hasThreshold = !_isEmpty(instruction.threshold);
            const outsideThreshold = hasThreshold && instruction.outside_threshold;
            let reportAnswer = workInstructionReportByInstructionUUID?.report;

            if (instruction.report_type === WORK_INSTRUCTION_REPORT_TYPES.RANGE && reportAnswer) {
              reportAnswer = JSON.parse(workInstructionReportByInstructionUUID.report).join(' - ');
            }

            return (
              <div key={instruction.uuid} className="item-work-instructions">
                <div>{index + 1}.{instructionIndex + 1} {instruction.description}</div>
                <div className="d-flex align-items-center">
                  <Badge className="badge-sm" bg="default">
                    <FormattedMessage
                      {...WORK_INSTRUCTION_REPORT_TYPE_MAP[instruction.report_type]}
                    />
                  </Badge>
                  {
                    hasThreshold && (
                      <Badge
                        variant="info"
                        className="badge-sm spacer-left"
                        bg="info"
                      >
                        <FormattedMessage id="threshold" defaultMessage="Threshold" />
                      </Badge>
                    )
                  }
                  {instruction.required &&
                    <Badge className="spacer-left badge-sm badge-danger" variant="danger">Required</Badge>}
                  {
                    !hideWorkInstructionBadge &&
                    renderWorkInstructionBadge(
                      workInstructionReportByInstructionUUID,
                      reportAnswer,
                      instruction,
                      defaultReportValue,
                      { hasThreshold, outsideThreshold },
                    )
                  }
                </div>
              </div>
            );
          }))}
        </SmoothCollapse>
      </div>
    </div>
  );
};

ChecklistsForProcessSteps.defaultProps = {
  hideWorkInstructionBadge: false,
};

ChecklistsForProcessSteps.propTypes = {
  processStep: PropTypes.shape({
    workstation: PropTypes.string,
    name: PropTypes.string,
    uri: PropTypes.string,
  }).isRequired,
  index: PropTypes.number.isRequired,
  workChecklistLinkings: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  workstationsByUri: PropTypes.shape({}).isRequired,
  isDebugModeEnabled: PropTypes.bool.isRequired,
  workInstructionsReports: PropTypes.shape({}).isRequired,
  usersByUri: PropTypes.shape({}).isRequired,
  hideWorkInstructionBadge: PropTypes.bool,
};

export default ChecklistsForProcessSteps;
