import Constants from 'rapidfab/constants';

function reducer(state = {}, action) {
  switch (action.type) {
    case Constants.STORE_SUBMITTED_ORDERS:
      return { ...state, orders: action.orders };
    default:
      return state;
  }
}

export default reducer;
