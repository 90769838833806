import React from 'react';

const Error = props => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 48 48"
    {...props}
  >
    <g fill="none" fillRule="evenodd" stroke="none" strokeWidth="1">
      <g fillRule="nonzero" transform="translate(-517 -860)">
        <g transform="translate(517 860)">
          <path
            fill="#000"
            d="M34 2L14 2 0 15.4166667 0 34.4555556 14 48 33.8666667 48 48 34.5833333 48 15.4166667z"
            opacity="0.15"
          />
          <path
            fill="#D92231"
            d="M34 0L14 0 0 13.4166667 0 32.4555556 14 46 33.8666667 46 48 32.5833333 48 13.4166667z"
          />
          <path
            fill="#ED3745"
            d="M34 0l14 13.543V15L34 1.457H14L0 15v-1.457L14 0h20z"
          />
          <path
            fill="#FFF"
            d="M34.4347826 30.375L31.6956522 33 24 25.625 16.3043478 33 13.5652174 30.375 21.2608696 23 13.5652174 15.625 16.3043478 13 24 20.375 31.6956522 13 34.4347826 15.625 26.7391304 23z"
          />
        </g>
      </g>
    </g>
  </svg>
);

export default Error;
