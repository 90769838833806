import {
  faBoxArchive,
  faBoxOpen,
  faEdit,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import PropTypes from 'prop-types';
import BureauBarcodeIcon from 'rapidfab/components/BureauBarcodeIcon';
import ConfirmationModal from 'rapidfab/components/ConfirmationModal';
import Loading from 'rapidfab/components/Loading';
import ResourceReadOnlyView from 'rapidfab/components/ResourceReadOnlyView/ResourceReadOnlyView';
import ResourceReadOnlyViewRow, {
  RESOURCE_READ_ONLY_VIEW_FIELD_TYPES,
} from 'rapidfab/components/ResourceReadOnlyView/ResourceReadOnlyViewRow';
import Tooltip from 'rapidfab/components/Tooltip';
import TruncatedItemsList from 'rapidfab/components/TruncatedItemsList';
import { COLORS, ROUTES } from 'rapidfab/constants';
import { FormattedDateTime, FormattedMessage } from 'rapidfab/i18n';
import {
  PERMANENT_CONTAINER_STATUS_MAP,
} from 'rapidfab/mappings';
import { getRouteURI } from 'rapidfab/utils/uriUtils';
import { extractUuid } from 'rapidfab/utils/uuidUtils';
import React from 'react';
import { Badge, Button, ButtonToolbar, Card, Col, Row } from 'react-bootstrap';

const PermanentContainerRecordComponent = ({
  permanentContainer,
  locationData,
  handleSetEditMode,
  materials,
  materialsFetching,
  onArchivePermanentContainer,
  isSubmitting,
  showArchiveConfirmation,
  handleAbortArchivePermanentContainer,
  batch,
  batchFetching,
}) => {
  const { location, subLocation } = locationData;

  const materialRestrictions = materials.filter(material =>
    permanentContainer.material_restrictions?.includes(material?.uri),
  ).map(material => material?.name) || [];

  const renderMaterialRestrictionsCustomValue = () => {
    if (materialsFetching) {
      return <Loading inline />;
    }

    return materialRestrictions.length ? <TruncatedItemsList data={materialRestrictions} /> : 'All';
  };

  const renderArchiveIcon = permanentContainer => {
    const isArchived = permanentContainer.archived;
    if (isSubmitting) {
      return <Loading inline />;
    }

    if (isArchived) {
      return (
        <Tooltip
          id="unarchiveIcon"
          placement="top"
          trigger={(<FontAwesomeIcon color={COLORS.WHITE} icon={faBoxOpen} />)}
        >
          <FormattedMessage
            id="button.unarchive"
            defaultMessage="Unarchive"
          />
        </Tooltip>
      );
    }

    return (
      <Tooltip
        id="unarchiveIcon"
        placement="top"
        trigger={(<FontAwesomeIcon color={COLORS.WHITE} icon={faBoxArchive} />)}
      >
        <FormattedMessage
          id="button.archive"
          defaultMessage="Archive"
        />
      </Tooltip>

    );
  };

  const renderQuantityCustomValue = (permanentContainer, batch) => {
    if (!permanentContainer) return 'N/A';
    if (batchFetching) {
      return <Loading inline />;
    }

    return (
      <>
        <span>{permanentContainer.quantity}</span>&nbsp;
        {batch && <span>{batch.units}</span> }
      </>
    );
  };

  return (
    <Card bg="dark" className="mb15">
      <Card.Header className="pd-exp accent d-flex align-items-center justify-content-between">
        <FormattedMessage
          id="field.permanentContainer"
          defaultMessage="Permanent Container Details"
        />
      </Card.Header>
      <div className="card-body-wrapper-accent">
        <Card.Body>
          <div className="d-flex align-items-center justify-content-between mb15">
            <a
              href={getRouteURI(ROUTES.PRINT_PERMANENT_CONTAINER_CONTAINERS_QR_CODES,
                { uuid: extractUuid(permanentContainer.uri) })}
              className="btn btn-info-outline"
              rel="noopener noreferrer"
            >

              <BureauBarcodeIcon
                className="spacer-right"
                qrCodeText="Print"
                barcodeText="Print"
              />
            </a>
            <ButtonToolbar className="d-flex align-items-center">
              {
                !permanentContainer.archived ? (
                  <Button
                    size="xs"
                    variant="primary"
                    className="mr10"
                    onClick={handleSetEditMode}
                  >
                    <FontAwesomeIcon icon={faEdit} />
                  </Button>
                ) : (
                  <Badge bg="default" className="badge-sm ms-2 spacer-right">
                    <FormattedMessage id="field.archived" defaultMessage="Archived" />
                  </Badge>
                )
              }

              <Button
                size="xs"
                variant={permanentContainer.archived ? 'primary' : 'warning'}
                onClick={() => onArchivePermanentContainer(permanentContainer)}
              >
                {renderArchiveIcon(permanentContainer)}
              </Button>
            </ButtonToolbar>
          </div>
          <Row>
            <Col xs={{ span: 10 }}>
              <ResourceReadOnlyView
                entity={permanentContainer}
              >
                <ResourceReadOnlyViewRow
                  name="name"
                  label="Name"
                />
                <ResourceReadOnlyViewRow
                  name="status"
                  type={RESOURCE_READ_ONLY_VIEW_FIELD_TYPES.CUSTOM}
                  customValue={
                    permanentContainer
                    && permanentContainer.status
                    && <FormattedMessage {...PERMANENT_CONTAINER_STATUS_MAP[permanentContainer.status]} />
                  }
                />
                <ResourceReadOnlyViewRow
                  name="quantity"
                  label="Quantity"
                  type={RESOURCE_READ_ONLY_VIEW_FIELD_TYPES.CUSTOM}
                  customValue={renderQuantityCustomValue(permanentContainer, batch)}
                />
                <ResourceReadOnlyViewRow
                  name="material_restrictions"
                  type="custom"
                  customValue={renderMaterialRestrictionsCustomValue()}
                  label="Allowed Materials"
                  className="wrap-text"
                />
                <ResourceReadOnlyViewRow
                  name="location"
                  label="Location"
                  type={RESOURCE_READ_ONLY_VIEW_FIELD_TYPES.CUSTOM}
                  customValue={location && location.name}
                />
                <ResourceReadOnlyViewRow
                  name="sub_location"
                  label="Sub-Location"
                  type={RESOURCE_READ_ONLY_VIEW_FIELD_TYPES.CUSTOM}
                  customValue={subLocation && subLocation.name}
                />
                <Col xs={{ span: 12 }} className="mt15">
                  <ResourceReadOnlyViewRow
                    name="updated"
                    type={RESOURCE_READ_ONLY_VIEW_FIELD_TYPES.CUSTOM}
                    customValue={permanentContainer?.updated ?
                      <FormattedDateTime value={permanentContainer?.updated} /> : 'N/A'}
                  />
                </Col>
              </ResourceReadOnlyView>
            </Col>
          </Row>
        </Card.Body>
      </div>

      {
        showArchiveConfirmation && (
          <ConfirmationModal
            swapButtons
            handleCancel={handleAbortArchivePermanentContainer}
            customConfirmVariant="warning"
            handleConfirm={() => onArchivePermanentContainer(permanentContainer)}
            confirmButtonContent={isSubmitting ?
              <Loading /> :
              <FormattedMessage id="button.archive" defaultMessage="Archive" />}
            message={<FormattedMessage id="toaster.permanentContainer.confirmArchive" defaultMessage="Are you sure you want to archive this permanent container?" />}
          />
        )
      }

    </Card>
  );
};

PermanentContainerRecordComponent.propTypes = {
  permanentContainer: PropTypes.shape({
    uri: PropTypes.string,
    name: PropTypes.string,
    archived: PropTypes.bool,
    status: PropTypes.string,
    quantity: PropTypes.number,
    current_batch: PropTypes.string,
    material_restrictions: PropTypes.oneOfType(
      [
        PropTypes.arrayOf(PropTypes.string),
        PropTypes.shape({
          name: PropTypes.string,
        }),
      ]),
    location: PropTypes.string,
    sub_location: PropTypes.string,
    updated: PropTypes.string,
  }).isRequired,
  locationData: PropTypes.shape({
    location: PropTypes.shape({
      name: PropTypes.string,
    }),
    subLocation: PropTypes.shape({
      name: PropTypes.string,
    }),
  }).isRequired,
  handleSetEditMode: PropTypes.func.isRequired,
  materials: PropTypes.arrayOf(PropTypes.shape({
    uri: PropTypes.string,
  })).isRequired,
  materialsFetching: PropTypes.bool.isRequired,
  onArchivePermanentContainer: PropTypes.func.isRequired,
  isSubmitting: PropTypes.bool.isRequired,
  showArchiveConfirmation: PropTypes.bool.isRequired,
  handleAbortArchivePermanentContainer: PropTypes.func.isRequired,
  batch: PropTypes.string.isRequired,
  batchFetching: PropTypes.bool.isRequired,
};

export default PermanentContainerRecordComponent;
