import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';

import { getUUIDResource, getMaterialContainersForLot, getRouteUUID } from 'rapidfab/selectors';
import Actions from 'rapidfab/actions';
import { ROUTES, PAGINATION_IGNORE_DEFAULT_LIMIT, API_RESOURCES } from 'rapidfab/constants';

import Loading from 'rapidfab/components/Loading';
import QrContainer from 'rapidfab/components/qr/Container';
import { Col, Container, Row } from 'react-bootstrap';
import BreadcrumbNav from 'rapidfab/components/BreadcrumbNav';
import { getShortUUID } from 'rapidfab/utils/uuidUtils';
import { getRouteURI } from 'rapidfab/utils/uriUtils';
import { useParams } from 'react-router-dom';

const LotContainersContainer = () => {
  const uuid = useSelector(state => getRouteUUID(state));
  const lot = useSelector(state => getUUIDResource(state, uuid));
  const isLoading = useSelector(state =>
    state.ui.nautilus[API_RESOURCES.MATERIAL_LOT].get.fetching
    || state.ui.nautilus[API_RESOURCES.MATERIAL_CONTAINER].list.fetching);
  const containers = useSelector(state => getMaterialContainersForLot(state, lot));
  const route = useParams();
  const dispatch = useDispatch();

  const onInitialize = currentUUID => {
    if (!currentUUID) {
      return Promise.resolve();
    }

    return dispatch(Actions.Api.nautilus[API_RESOURCES.MATERIAL_LOT].get(currentUUID, true)).then(lotResult => {
      const { uri: lotUri } = lotResult.json;
      return Promise.all([
        dispatch(Actions.Api.nautilus[API_RESOURCES.MATERIAL_CONTAINER].list(
          { material_lot: lotUri },
          { limit: PAGINATION_IGNORE_DEFAULT_LIMIT },
        )),
      ]);
    });
  };

  useEffect(() => {
    (async () => {
      await onInitialize(uuid);
      window.print();
    })();
  }, []);

  if (isLoading || !lot) {
    return (<Loading />);
  }

  const renderContainers = () => {
    if (route?.container && containers.length) {
      const currentContainer = containers.find(item => item.uuid === route?.container);

      return (
        <QrContainer
          lotUri={lot.uri}
          materialName={lot.material_name}
          container={currentContainer}
        />
      );
    }

    return containers.map(container => (
      <QrContainer
        lotUri={lot.uri}
        materialName={lot.material_name}
        container={container}
      />
    ));
  };

  return (
    <Container fluid>
      <Row>
        <Col lg={12}>
          <div className="hide-on-print">
            <BreadcrumbNav
              breadcrumbs={[
                'materialLots',
                {
                  message: `${lot.name} (${getShortUUID(lot.uuid)})`,
                  href: getRouteURI(ROUTES.MATERIAL_LOT, { uuid: lot.uuid }),
                },
                `Print ${lot.name} ${route?.container ? `Container ${getShortUUID(route?.container)}` : 'Containers QR Codes'}`,
              ]}
            />
          </div>
          {renderContainers()}
        </Col>
      </Row>
    </Container>
  );
};

LotContainersContainer.propTypes = {
  lot: PropTypes.shape({
    uuid: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    uri: PropTypes.string.isRequired,
    material_name: PropTypes.string.isRequired,
  }).isRequired,
};

export default LotContainersContainer;
