import { createSelector } from 'reselect';
import { getPredicate } from 'rapidfab/selectors/helpers/base';
import _filter from 'lodash/filter';
import { getWorkChecklistLinkings } from 'rapidfab/selectors/workChecklistLinking';

// eslint-disable-next-line import/prefer-default-export
export const getRelatedWorkChecklistLinking = createSelector(
  [getPredicate, getWorkChecklistLinkings],
  (relatedURI, workChecklistLinkings) =>
    _filter(workChecklistLinkings, ['related_uri', relatedURI]),
);
