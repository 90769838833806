import React from 'react';
import PropTypes from 'prop-types';
import ReactTextareaAutocomplete from '@webscopeio/react-textarea-autocomplete';
import Loading from 'rapidfab/components/Loading';
import _map from 'lodash/map';
import _compact from 'lodash/compact';
import _startsWith from 'lodash/startsWith';
import { Badge } from 'react-bootstrap';

const UserItem = ({ entity }) => (
  <div className="list-item">
    <div className="user-item__email">{entity.username}</div>
    <div className="user-item__name">{entity.name}</div>
  </div>
);

const LabelItem = ({ entity }) => (
  <div className="list-item">
    <Badge
      className="spacer-right badge-sm hawking-badge-secondary mb-1 text-wrap py-0 w-fit-content text-start"
      style={{ lineHeight: 1.7 }}
    >
      <span className="small-status-dot spacer-right" style={{ backgroundColor: entity.color }} />
      {entity.name}
    </Badge>
  </div>
);

const TextAreaAutocomplete = React.forwardRef((props, ref) => {
  const autocompleteTrigger = {
    '@': {
      dataProvider: async token => {
        const fetchedUsers = await props.fetchAllUsers();

        if (fetchedUsers) {
          return _compact(_map(fetchedUsers, datum => (
            _startsWith(datum?.username?.toLowerCase(), token.toLowerCase()) ? datum : null
          )));
        }

        return [];
      },
      component: UserItem,
      output: (item, trigger) => `**${trigger}${item.username}**`,
    },
  };

  if (props.isModelLibraryRoute) {
    autocompleteTrigger['#'] = {
      dataProvider: async token => {
        const existingLabels = props.labels ?
          _compact(_map(props.labels, datum => (_startsWith(datum?.name?.toLowerCase(), token.toLowerCase()) ?
            datum :
            null
          ))) :
          [];

        // If no labels match the token, add an option to create a new label
        let createNewLabel = null;
        if (existingLabels.length === 0) {
          createNewLabel = { name: `Create label: ${token}`, isNew: true };
        }

        // Return both the existing labels and (if applicable) the option to create a new label
        return createNewLabel ? [...existingLabels, createNewLabel] : existingLabels;
      },
      component: LabelItem,
      output: item => {
        if (item.isNew) {
          // If the label is new, create it
          const labelName = item.name.replace('Create label: ', '');
          return `[${labelName}](#${encodeURIComponent(labelName)})`;
        }
        // If the label already exists, output it with both the name and id + replace spaces with hyphen
        return `[${item.id}](#${item.name.replace(/\s/g, '-')})`;
      },
    };
  }

  return (
    <ReactTextareaAutocomplete
      {...props}
      loadingComponent={() => <span><Loading /></span>}
      trigger={autocompleteTrigger}
      className="textarea form-control"
      containerClassName="textarea-autocomplete-container"
      listClassName="textarea-autocomplete-list"
      itemClassName="textarea-autocomplete-item"
      dropdownClassName="textarea-autocomplete-dropdown"
      ref={ref}
    />
  );
});

TextAreaAutocomplete.propTypes = {
  className: PropTypes.string,
  suggestions: PropTypes.arrayOf(PropTypes.string).isRequired,
  fetchAllUsers: PropTypes.func.isRequired,
  labels: PropTypes.arrayOf(PropTypes.shape({})),
  isModelLibraryRoute: PropTypes.bool,
};

TextAreaAutocomplete.defaultProps = {
  className: '',
  labels: [],
  isModelLibraryRoute: false,
};

UserItem.propTypes = {
  entity: PropTypes.shape({
    username: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
  }).isRequired,
};

LabelItem.propTypes = {
  entity: PropTypes.shape({
    name: PropTypes.string.isRequired,
    color: PropTypes.string.isRequired,
  }).isRequired,
};

export default TextAreaAutocomplete;
