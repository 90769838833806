const getRouteParameters = () => {
  const uriParameters = {};
  const rawTrailingUri = window.location.hash.split('?')[1];
  if (rawTrailingUri) {
    const rawParameters = rawTrailingUri.split('&');
    rawParameters.forEach(item => {
      const key = item.split('=')[0];
      const value = item.split('=')[1];
      uriParameters[key] =
          /* Parse string boolean values to normal boolean */
          (value === 'true' || value === 'false') ?
            JSON.parse(value) :
            value;
    });
  }
  return uriParameters;
};

export default getRouteParameters;
