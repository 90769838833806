// hooks/useFavoriteUsers.js
import { IMPERSONATION_FAVORITE_USERS_LIST } from 'rapidfab/constants';
import { useState, useEffect } from 'react';

const useFavoriteUsers = initialFavorites => {
  const [favoriteUsers, setFavoriteUsers] = useState(initialFavorites);

  useEffect(() => {
    const storedFavorites = JSON.parse(localStorage.getItem(IMPERSONATION_FAVORITE_USERS_LIST)) || [];
    setFavoriteUsers(storedFavorites);
  }, []);

  useEffect(() => {
    localStorage.setItem(IMPERSONATION_FAVORITE_USERS_LIST, JSON.stringify(favoriteUsers));
  }, [favoriteUsers]);

  const addFavoriteUser = user => {
    setFavoriteUsers(previous => [...previous, user]);
  };

  const removeFavoriteUser = username => {
    setFavoriteUsers(previous => previous.filter(user => user.username !== username));
  };

  const renameFavoriteUserName = (userEmail, newUsername) => {
    setFavoriteUsers(previous => previous.map(user => {
      if (user.username === userEmail) {
        return { ...user, name: newUsername };
      }
      return user;
    }));
  };

  return { favoriteUsers, addFavoriteUser, removeFavoriteUser, renameFavoriteUserName };
};

export default useFavoriteUsers;
