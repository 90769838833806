import React from 'react';
import { WORK_INSTRUCTION_UNITS_MAP } from 'rapidfab/mappings';
import PropTypes from 'prop-types';

const FormattedWeight = ({ value, valueUnits }) => (
  <span>{value} {WORK_INSTRUCTION_UNITS_MAP[valueUnits]?.defaultMessage}</span>
);

export default FormattedWeight;

FormattedWeight.propTypes = {
  value: PropTypes.node.isRequired,
  valueUnits: PropTypes.string.isRequired,
};
