import { createSelector } from 'reselect';
import _groupBy from 'lodash/groupBy';
import {
  isPostProcessorEvent,
  isCADModelChangeEvent,
  isCADModelReplaceEvent,
  isManualNoteEvent,
  isPrintEvent,
  isWorkChecklistChanges,
  isWorkInstructionReportEvent,
  isInPreparationEvent,
} from 'rapidfab/utils/tracebilityReportEvents';
import { getNonRemanufacturedPrints, getPrints } from 'rapidfab/selectors/print';
import { getPredicate } from 'rapidfab/selectors/helpers/base';
import { extractUuid } from 'rapidfab/reducers/makeApiReducers';
import { getEvents } from 'rapidfab/selectors/event';
import { getOrderDocuments } from 'rapidfab/selectors/helpers/order';
import { getRunDocuments } from 'rapidfab/selectors/helpers/run';
import { getRuns, getRunsByUri } from 'rapidfab/selectors/run';
import { getComments } from 'rapidfab/selectors/comment';
import _filter from 'lodash/filter';
import _includes from 'lodash/includes';
import _find from 'lodash/find';
import _forEach from 'lodash/forEach';
import _map from 'lodash/map';
import _keyBy from 'lodash/keyBy';
import {
  RUN_OPERATIONS,
  COMMENT_RELATED_TABLE_NAMES,
  TRACEABILITY_FILTERS,
} from 'rapidfab/constants';
import { getLineItems } from 'rapidfab/selectors/lineItem';
import { getTraceabilityReports } from 'rapidfab/selectors/traceabilityReport';
import { getModels } from 'rapidfab/selectors/model';
import { getPrepWorkflowRecords } from 'rapidfab/selectors/prepWorkflowRecord';
import { getPrepTaskRecords } from 'rapidfab/selectors/prepTaskRecord';
import { getPrepWorkflows } from 'rapidfab/selectors/prepWorkflow';
import { getPieceActualsByPieceUri } from 'rapidfab/selectors/pieceActuals';

export const getNonRemanufacturedPrintsByPieceUri = createSelector(
  [getNonRemanufacturedPrints],
  prints => _groupBy(prints, 'piece'),
);

// !!!ATTENTION!!! For Piece we return all prints, including Remanufactured ones
export const getPrintsForPiece = createSelector(
  [getPredicate, getPrints],
  (piece, prints) => {
    if (!piece) {
      return [];
    }
    return _filter(prints, { piece: piece.uri });
  },
);

export const getPrepWorkflowRecordForPiece = createSelector(
  [getPredicate, getPrepWorkflowRecords],
  (piece, prepWorkflowRecords) => {
    if (!piece) {
      return [];
    }

    return _find(prepWorkflowRecords, prepWorkflowRecord => (
      prepWorkflowRecord.line_item === piece.line_item
      || (prepWorkflowRecord.order === piece.order && !prepWorkflowRecord.line_item)
    ));
  },
);

export const getPrepTaskRecordsForPiece = createSelector(
  [getPredicate, getPrepTaskRecords, getPrepWorkflowRecordForPiece],
  (piece, prepTaskRecords, prepWorkflowRecord) => {
    if (!piece || !prepWorkflowRecord) {
      return [];
    }
    // Order is not guaranteed
    return _filter(prepTaskRecords, { prep_workflow_record: prepWorkflowRecord.uri });
  },
);

// !!!ATTENTION!!! ALL prints used here (not just Non-Remanufactured ones)
// As a resut - all Runs returned, not only Runs for Non-Remanufactured prints
export const getRunsForPiece = createSelector(
  [getPredicate, getRuns, getPrintsForPiece],
  (piece, runs, printsForPiece) => {
    if (!piece) {
      return [];
    }
    const printsRunUris = _map(printsForPiece, 'run');
    return _filter(runs, run => _includes(printsRunUris, run.uri));
  },
);

export const getLineItemForPiece = createSelector(
  [getPredicate, getLineItems],
  (piece, lineItems) => {
    if (!piece) {
      return null;
    }
    return _find(lineItems, { uri: piece.line_item });
  },
);

export const getPrepWorkflowForPiece = createSelector(
  [getLineItemForPiece, getPrepWorkflows],
  (lineItem, prepWorkflows) => {
    if (!lineItem) {
      return null;
    }
    return _find(prepWorkflows, { uri: lineItem.prep_workflow });
  },
);

// !!!ATTENTION!!! ALL Runs used here, (not just Runs for Non-Remanufactured prints)
export const getEventRelatedResourcesForPieceByResourceUri = createSelector(
  [
    getPredicate,
    getRunsForPiece,
    getPrepWorkflowRecordForPiece,
    getPrepTaskRecordsForPiece,
    getPrepWorkflowForPiece,
  ],
  (
    piece,
    runs,
    prepWorkflowRecord,
    prepTaskRecords,
    prepWorkflow,
  ) => {
    if (!piece) {
      return {};
    }

    // TODO: This list might need to be extended further depending on product requirements
    const resources = [
      ...runs,
      ...prepTaskRecords,
      prepWorkflow,
      prepWorkflowRecord,
    ].filter(Boolean);

    return _keyBy(resources, 'uri');
  },
);

// !!!ATTENTION!!! ALL prints used here (not just Non-Remanufactured ones)
// !!!ATTENTION!!! ALL Runs used here, (not just Runs for Non-Remanufactured prints)
export const getEventsForPiece = createSelector(
  [
    getPredicate,
    getRunsForPiece,
    getEvents,
    getOrderDocuments,
    getRunDocuments,
    getPrintsForPiece,
    getPrepWorkflowRecordForPiece,
    getPrepTaskRecordsForPiece,
    getLineItemForPiece,
  ],
  (
    piece,
    runs,
    events,
    orderDocuments,
    runDocuments,
    prints,
    prepWorkflowRecord,
    prepTaskRecords,
    lineItem,
  ) => {
    if (!piece) {
      return [];
    }

    const printOrderDocumentUris = orderDocuments
      .filter(document => document.order === piece.order)
      .map(document => document.order);

    const pieceRunUris = _map(runs, 'uri');
    const printUris = _map(prints, 'uri');
    const prepTaskRecordUris = _map(prepTaskRecords, 'uri');

    const printRunUuids = new Set(pieceRunUris.map(printRunUri => extractUuid(printRunUri)));
    const printRunDocumentUris = runDocuments
      .filter(document => printRunUuids.has(document.related_uuid))
      .map(document => document.uri);

    const uris = new Set([
      piece.uri,
      piece.order,
      piece.line_item,
      lineItem && lineItem.prep_workflow,
      ...printOrderDocumentUris,
      ...pieceRunUris,
      ...printRunDocumentUris,
      ...printUris,
      ...prepTaskRecordUris,
      prepWorkflowRecord && prepWorkflowRecord.uri,
    ].filter(Boolean)); // remove null values

    return events.filter(event => uris.has(event.reference));
  },
);

export const getEventsForPieceSortedByCreated = createSelector(
  [getEventsForPiece],
  events =>
    events.sort((a, b) => {
      if (a.created > b.created) return 1;
      if (a.created < b.created) return -1;
      return 0;
    }),
);

// !!!ATTENTION!!! ALL Runs used here, (not just Runs for Non-Remanufactured prints)
// Anyway, since there is only one Printing Run per piece (even within Runs for ALL prints)
// - selector will still work as expected and return correct run
export const getPrintingRunForPiece = createSelector(
  [getPredicate, getRunsForPiece],
  (piece, runs) => _find(runs, { operation: RUN_OPERATIONS.PRINTING }),
);

export const getModelForPiece = createSelector(
  [getPredicate, getModels],
  (piece, models) => {
    if (!piece) {
      return null;
    }
    return _find(models, { uri: piece.model });
  },
);

export const getTraceabilityReportForPiece = createSelector(
  [getPredicate, getTraceabilityReports],
  (piece, reports) => {
    if (piece) {
      return _find(reports, { piece: piece.uri });
    }
    return null;
  },
);

export const getCommentsForPiece = createSelector(
  [getPredicate, getComments, getPrepTaskRecordsForPiece, getRunsForPiece],
  (piece, comments, prepTaskRecords, runs) => {
    if (piece && piece.order && piece.line_item) {
      const { order: orderUri } = piece;
      const orderUuid = extractUuid(orderUri);
      const prepTaskRecordUuids = _map(prepTaskRecords, ({ uri }) => extractUuid(uri));
      const runsUuids = _map(runs, ({ uri }) => extractUuid(uri));

      return _filter(comments, comment => {
        switch (comment.related_table_name) {
          case (COMMENT_RELATED_TABLE_NAMES.ORDER):
            return comment.related_uuid === orderUuid;
          case (COMMENT_RELATED_TABLE_NAMES.PREP_TASK_RECORD):
            return prepTaskRecordUuids.includes(comment.related_uuid);
          case (COMMENT_RELATED_TABLE_NAMES.RUN):
            return runsUuids.includes(comment.related_uuid);
          default: return false;
        }
      });
    }
    return [];
  },
);

export const getTraceabilityFilteredEvents = createSelector(
  [getEventsForPieceSortedByCreated, getRunsByUri],
  (events, runsByURI) => {
    const hiddenEvents = new Set(['bureau', 'model_permission']);
    const eventCreationTime = events.map(event => event.created).sort()[0];
    const basicEvents = events.filter(event => {
      const isVisibleEvent = !hiddenEvents.has(event.key);
      const isUpdateEvent = event.creation !== eventCreationTime;
      const isRealEvent = event.current_value !== event.previous_value;
      return isVisibleEvent && isUpdateEvent && isRealEvent;
    });

    let filteredEvents = {};
    _forEach(TRACEABILITY_FILTERS, ({ value }) => { filteredEvents[value] = []; });

    filteredEvents = {
      ...filteredEvents,
      ..._groupBy(
        basicEvents,
        event => {
          if (isInPreparationEvent(event)) {
            return 'preparation_events';
          }
          if (isManualNoteEvent(event)) {
            return 'manual_notes';
          }
          if (isPostProcessorEvent(event)) {
            return 'post_process_events';
          }
          if (isWorkChecklistChanges(event)) {
            return 'work_checklist_changes';
          }
          if (isCADModelReplaceEvent(event)) {
            return 'cad_model_replaced';
          }
          if (isCADModelChangeEvent(event)) {
            return 'cad_model_changes';
          }
          if (isWorkInstructionReportEvent(event)) {
            return 'work_instruction_report';
          }

          const eventRun = event.reference.includes('/run/') && runsByURI[event.reference];
          if (isPrintEvent(eventRun)) {
            return 'print_events';
          }
          return 'other';
        },
      ),
    };

    return filteredEvents;
  },
);

// Even though it is called `actuals` (with `s`) the return value is 1 object (or none)
export const getPieceActualsForPiece = createSelector(
  [getPredicate, getPieceActualsByPieceUri],
  (piece, pieceActualsByPieceUri) => piece && pieceActualsByPieceUri[piece.uri],
);
