import UploadFileModalProgressBarContainer from 'rapidfab/containers/modals/UploadFileModalProgressBarContainer';
import usePrevious from 'rapidfab/hooks';
import * as Selectors from 'rapidfab/selectors';
import React, { useEffect, useState } from 'react';
import * as Sentry from '@sentry/react';
import PropTypes from 'prop-types';
import { Navigate, useLocation, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import Actions from 'rapidfab/actions';
import { isFeatureEnabled } from 'rapidfab/utils/featureFlagUtils';
import _every from 'lodash/every';
import _forEach from 'lodash/forEach';
import { API_RESOURCES, FILE_LOADER_COLLAPSED_STATE, FILE_LOADER_HIDDEN_STATE, ROUTES } from 'rapidfab/constants';
import RouterContext from 'rapidfab/context/RouterContext';
import usePreventNavigate from 'rapidfab/hooks/usePreventNavigate';
import {
  getLineItemsForOrder,
  getProductsForOrder,
  getRouteUUIDResource,
  isCurrentUserRestricted,
  getFeatures,
} from 'rapidfab/selectors';
import { convertRouteFeaturesToObject } from 'rapidfab/routes';
import { FEATURES } from 'rapidfab/constants/features';
import { ROLE_TO_PERMISSION, ROUTER_PERMISSION } from 'rapidfab/constantsRouter';
import NotFound from '../components/404';

export default function PrivateRoute({
  session, component: Component, permission, features, decidingFeature, routeIsHiddenFromRestrictedUsers,
}) {
  const dispatch = useDispatch();
  const location = useLocation();
  const { uuid } = useParams();
  const [ordersViewMode, setOrdersViewMode] = useState(null);
  const [expandAllLineItems, setExpandAllLineItems] = useState(false);
  const [isObserving, setIsObserving] = useState(true);
  const isFileLoaderHidden = localStorage.getItem(FILE_LOADER_HIDDEN_STATE) || false;
  const isFileLoaderCollapsed = localStorage.getItem(FILE_LOADER_COLLAPSED_STATE) || false;
  const [shouldBlurBackground, setShouldBlurBackground] = useState(false);
  const fileLoader = useSelector(Selectors.getFileLoader);
  const isRestrictedUser = useSelector(isCurrentUserRestricted);
  const fetchingLineItems = useSelector(state => state.ui.nautilus[API_RESOURCES.LINE_ITEM].list.fetching);
  const currentUserFeatures = useSelector(getFeatures);
  let incompleteLineItems = [];
  let orderHash = 'records/order';
  const orderReviewRegEx = location.pathname.match(/\/([^/]+)\/?$/);

  useEffect(() => {
    dispatch(Actions.UI.clearUIErrors());
  }, [uuid]);

  let isReviewingOrder = false;
  if (orderReviewRegEx) {
    isReviewingOrder = orderReviewRegEx[1] === 'review' || orderReviewRegEx[1] === 'line-items';
  }

  if (isRestrictedUser) {
    orderHash = 'records/restricted/order';
  }
  const orderLocation = location.pathname.includes(orderHash);

  const order = useSelector(state => orderLocation && getRouteUUIDResource(state));
  const products = useSelector(state => (orderLocation && order ? getProductsForOrder(state, order) : null));
  const lineItems = useSelector(state =>
    (orderLocation && products?.length ? getLineItemsForOrder(state, order) : null));

  /*
   [sc-58650] UPD: Added another condition to wait until the /line-items endpoint is fetched.
   It is needed to prevent the cases where the order is still loading, we have products,
   but have no line-items yet as they are still fetching but the "lineItems?.length" will not
   work as it is 0, and we will have "ELSE" condition where all the products will be counted as
   "Incomplete Line items" and the Modal Window appears.
 */

  if (orderLocation && !fetchingLineItems) {
    if (lineItems?.length) {
      /* If we have lineItems in the Array -> we need to make sure that the same URIs are in the "product" Array,
            'cause if product.uri === lineItem.product, it means the line item is the full product.
            If not, it is an incomplete Line Item. For example: lineItems: [1], products: [2] ->
            in this case the object in the lineItems array has the same URi in the products array,
            and this item should not be counted as incomplete line item,
            the rest of the objects are incomplete line items. */

      incompleteLineItems =
        products.filter(currentProduct => !lineItems.some(lineItem => currentProduct.uri === lineItem.product));
    } else {
      /* If we have products but do not have line items ->
         it means all the products in the Array are incomplete line items. */
      incompleteLineItems = products && [...products];
    }

    if (!isObserving) {
      // If the area is not visible, and we are not observing -> clear notification and enable routing.
      incompleteLineItems = [];
    }
  }

  const deleteProduct = currentUUID =>
    dispatch(Actions.Api.nautilus[API_RESOURCES.PRODUCT].delete(currentUUID));

  const [
    leaveConfirmation,
    setLeaveConfirmation,
    setLinkToForward,
    setTriggerNextToRender,
    linkToForward] = usePreventNavigate(incompleteLineItems, isRestrictedUser, isReviewingOrder);

  const removeIncompleteItems = () => new Promise(resolve => {
    incompleteLineItems.forEach(designFile => {
      if (designFile) {
        deleteProduct(designFile?.uuid);
      }
    });
    resolve();
  });

  if (uuid) {
    dispatch(Actions.RouteUUID.setRouteUUID(uuid));
  } else {
    dispatch(Actions.RouteUUID.setRouteUUID(null));
  }

  useEffect(() => {
    if (location.pathname === ROUTES.ORDERS) {
      if (location.search.includes('?view_mode=chart')) {
        setOrdersViewMode('CHART');
      } else {
        setOrdersViewMode('LIST');
      }
    } else {
      setOrdersViewMode(null);
    }
  }, [location.search, location.pathname]);

  useEffect(() => {
    if (orderLocation) {
      const isExpandAllMode = location.search.includes('?view_mode=expand_all');
      if (isExpandAllMode) {
        setExpandAllLineItems(true);
      } else {
        setExpandAllLineItems(false);
      }
    } else {
      setExpandAllLineItems(null);
    }
  }, [orderLocation, location.search, location.pathname]);

  const previousQueue = usePrevious(fileLoader?.queue);
  useEffect(() => {
    if ((previousQueue && fileLoader) && (previousQueue.length !== fileLoader.queue?.length)) {
      localStorage.removeItem(FILE_LOADER_HIDDEN_STATE);
      localStorage.removeItem(FILE_LOADER_COLLAPSED_STATE);
    }
  }, [previousQueue, fileLoader?.queue]);

  const isAllowedByRole = (requiredPermission, userRole) => {
    /**
     * Validates route permission bitmask with user role
     * to check access for route.
     *
     * How bitmasks works:
     * https://abdulapopoola.com/2016/05/30/understanding-bit-masks/
     *
     * @param requiredPermission (int): bitmask of required permission
     * @param userRole (string or null): String of user role name, null if guest
     * @param hash (string): Hash to sent to sentry (when error occurs)
     */
    let userRoleBitmask = ROUTER_PERMISSION.GUEST;
    if (userRole) {
      userRoleBitmask = ROLE_TO_PERMISSION[userRole];
    }

    // eslint-disable-next-line no-bitwise
    const hasPermissions = requiredPermission & userRoleBitmask;
    if (userRoleBitmask !== ROUTER_PERMISSION.GUEST && !hasPermissions) {
      Sentry.captureMessage(
        `User requested route without permissions (route ${location.pathname}). Route bitmask: ${requiredPermission}, user bitmask: ${userRoleBitmask}`);
    }

    return hasPermissions;
  };

  const isAllowedByFeatures = (
    requiredFeatures,
    userFeatures,
    decidingFeatures,
  ) => {
    /**
     *
     * @param requiredFeatures {}
     *  an object with route.features config { FEATURE_NAME: bool }
     * @param userFeatures [] List of user feature objects
     * @param decidingFeatures an array of feature names
     * if one of the feature form the decidingFeatures list is true, the routing is allowed
     * @returns boolean if user is allowed to access route based on enabled features
     */
    let requiredFeaturesToObject;
    if (requiredFeatures) {
      requiredFeaturesToObject = convertRouteFeaturesToObject(requiredFeatures);
    }
    let isAllowed = false;
    if (decidingFeatures) {
      _forEach(decidingFeatures, featureName => {
        isAllowed = isFeatureEnabled(userFeatures, featureName) || isAllowed;
      });
    } else {
      isAllowed = _every(
        requiredFeaturesToObject,
        (isExpectedToBeEnabled, featureName) =>
          isFeatureEnabled(userFeatures, featureName) === isExpectedToBeEnabled,
      );
    }

    if (!isAllowed) {
      // Prepare user features for error message
      const featuresEntriesList =
        decidingFeatures || Object.keys(requiredFeaturesToObject);
      const featuresEntries = featuresEntriesList.map(featureName => [
        featureName,
        isFeatureEnabled(userFeatures, featureName),
      ]);
      const userFeaturesToCheck = Object.fromEntries(featuresEntries);
      const requiredFeatureList = decidingFeatures || requiredFeaturesToObject;

      Sentry.captureMessage(
        `User requested route without features on/off appropriately (route ${location.pathname}). Required features: ${JSON.stringify(
          requiredFeatureList,
        )}, user features: ${JSON.stringify(userFeaturesToCheck)}`,
      );
    }
    return isAllowed;
  };

  if (!isAllowedByRole(permission, session.role)) {
    return <Navigate to={ROUTES.LOGIN} />;
  }

  if (!isAllowedByFeatures(features, currentUserFeatures, decidingFeature)) {
    return <NotFound />;
  }

  if (isRestrictedUser && routeIsHiddenFromRestrictedUsers) {
    return <NotFound />;
  }

  const {
    queue,
    isModalView,
  } = fileLoader ?? {};

  const hideFileLoader = () => localStorage.setItem(FILE_LOADER_HIDDEN_STATE, 'true');
  const toggleCollapsed = () => (localStorage.getItem(FILE_LOADER_COLLAPSED_STATE)
    ? localStorage.removeItem(FILE_LOADER_COLLAPSED_STATE)
    : localStorage.setItem(FILE_LOADER_COLLAPSED_STATE, 'true'));

  return (
    <RouterContext.Provider
      value={{ ordersViewMode,
        incompleteLineItems,
        leaveConfirmation,
        setLeaveConfirmation,
        setTriggerNextToRender,
        linkToForward,
        setLinkToForward,
        removeIncompleteItems,
        setIsObserving,
        expandAllLineItems,
        shouldBlurBackground,
        setShouldBlurBackground,
        hideFileLoader,
        isFileLoaderCollapsed,
        toggleCollapsed }}
    >
      {shouldBlurBackground ? <div className="blurry-background" /> : null}
      {
        !!queue.length && !isFileLoaderHidden && (
          <UploadFileModalProgressBarContainer
            queue={queue}
            isModalView={isModalView}
          />
        )
      }
      <Component />
    </RouterContext.Provider>
  );
}

PrivateRoute.defaultProps = {
  features: null,
  decidingFeature: null,
  routeIsHiddenFromRestrictedUsers: false,
};

PrivateRoute.propTypes = {
  session: PropTypes.shape({
    fetching: PropTypes.bool,
    currentUser: PropTypes.shape({}),
    role: PropTypes.string,
  }).isRequired,
  component: PropTypes.oneOfType([PropTypes.node, PropTypes.elementType]).isRequired,
  permission: PropTypes.number.isRequired,
  features: PropTypes.oneOfType(
    [PropTypes.objectOf(PropTypes.bool), PropTypes.arrayOf(PropTypes.oneOf(Object.values(FEATURES)))],
  ),
  decidingFeature: PropTypes.arrayOf(PropTypes.shape({})),
  /**
   * When this is `true`, the given route will display a `404` to restricted users.
   */
  routeIsHiddenFromRestrictedUsers: PropTypes.bool,
};
