import _filter from 'lodash/filter';
import _keyBy from 'lodash/keyBy';
import _map from 'lodash/map';
import * as baseStateSelectors from 'rapidfab/selectors/baseStateSelectors';
import { getPredicate, getStateResources } from 'rapidfab/selectors/helpers/base';
import { createSelector } from 'reselect';

export const getScheduledRuns = createSelector(
  [baseStateSelectors.getStateScheduleRuns, getStateResources],
  (uuids, resources) => _map(uuids, uuid => resources[uuid]),
);

export const getScheduledRunsByRunUri = createSelector(
  [getPredicate, getScheduledRuns],
  (runUris, scheduledRuns) => {
    if (!runUris) {
      return _keyBy(scheduledRuns, 'run');
    }
    return _keyBy(_filter(scheduledRuns, sr => runUris.includes(sr.run)), 'run');
  },
);
