import { FormattedMessage } from 'rapidfab/i18n';
import React from 'react';
import PropTypes from 'prop-types';
import { Col, Card, Image, Row } from 'react-bootstrap';
import exampleSnapshot from 'rapidfab/images/example_snapshot.png';
import OrderEstimates from 'rapidfab/components/records/order/edit/OrderEstimates';

const printingCost = (printTime, printCostScaleFactor, runningCostPerHour) => {
  const printTimeHours = printTime / 3600;
  const printingCostScaled =
    (printTimeHours * runningCostPerHour * printCostScaleFactor) / 100;

  return printingCostScaled;
};

const bureauCost = (
  baseMaterialUsed,
  constantOverhead,
  materialCostScaleFactor,
  printTime,
  printCostScaleFactor,
  runningCostPerHour,
) => {
  const materialCost = 0.05;

  const printingCostScaled = printingCost(
    printTime,
    printCostScaleFactor,
    runningCostPerHour,
  );

  const baseMaterialCost = baseMaterialUsed * materialCost;
  const baseMaterialCostScaled =
    (baseMaterialCost * materialCostScaleFactor) / 100;

  const perPieceCost = constantOverhead;

  return printingCostScaled + baseMaterialCostScaled + perPieceCost;
};

const ExampleEstimates = ({
  estimates,
  model,
  runningCostPerHour,
  materialCostScaleFactor,
  printCostScaleFactor,
  constantOverhead,
  use100Infill,
  isCustomDarkCardStyle,
}) => (
  <Card
    bg={`${isCustomDarkCardStyle ? '' : 'dark'}`}
    className={`${isCustomDarkCardStyle ? 'mb15 custom-darken-modal--card' : ''}`}
    data-cy="estimates-table"
  >
    <Card.Header className={`${isCustomDarkCardStyle ? 'pd-exp custom-darken-modal--card-header' : 'pd-exp inverse'}`}>
      <FormattedMessage
        id="exampleEstimates"
        defaultMessage="Example Estimates"
      />
    </Card.Header>
    <div className={`${isCustomDarkCardStyle ? '' : 'card-body-wrapper'}`}>
      <Card.Body className={`${isCustomDarkCardStyle ? 'custom-darken-modal--card-body' : ''}`}>
        <Row>
          <Col
            xs={{ span: 8, offset: 2 }}
            lg={{ span: 4, offset: 4 }}
            className="mb15"
          >
            {/* <Thumbnail src={exampleSnapshot} /> */}
            <Image src={exampleSnapshot} thumbnail />
          </Col>
        </Row>
        <OrderEstimates
          totalPrintingCost={printingCost(
            estimates.printing_time,
            printCostScaleFactor,
            runningCostPerHour,
          )}
          totalMaterialCost={estimates.total_material_cost}
          totalModelsVolumeMm={model.volume_mm}
          bureauCustom={bureauCost(
            estimates.materials.base,
            constantOverhead,
            materialCostScaleFactor,
            estimates.printing_time,
            printCostScaleFactor,
            runningCostPerHour,
          )}
          postProcessing={estimates.post_processing_cost}
          printingTime={estimates.printing_time}
          support={estimates.materials.support}
          showShippingCost={false}
          use100Infill={use100Infill}
        />
      </Card.Body>
    </div>
  </Card>
);

ExampleEstimates.defaultProps = {
  estimates: {
    printing_time: 10000,
    total_material_cost: 5,
    post_processing_cost: 0,
    materials: {
      base: 100,
      support: 0,
    },
  },
  model: {
    volume_mm: 10000,
  },
  isCustomDarkCardStyle: false,
};

ExampleEstimates.propTypes = {
  estimates: PropTypes.shape({
    printing_time: PropTypes.number,
    total_material_cost: PropTypes.number,
    post_processing_cost: PropTypes.number,
    materials: PropTypes.shape({
      support: PropTypes.number,
      base: PropTypes.number,
    }),
  }),
  model: PropTypes.shape({
    volume_mm: PropTypes.number,
  }),
  runningCostPerHour: PropTypes.number.isRequired,
  printCostScaleFactor: PropTypes.number.isRequired,
  materialCostScaleFactor: PropTypes.number.isRequired,
  constantOverhead: PropTypes.number.isRequired,
  use100Infill: PropTypes.bool.isRequired,
  isCustomDarkCardStyle: PropTypes.bool,
};

export default ExampleEstimates;
