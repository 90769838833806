import MiniCards from 'rapidfab/components/records/order/edit/LineItemMiniCards';
import { pluralWord } from 'rapidfab/utils/stringUtils';
import React, { useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { Button, Card, Col, FormControl, FormLabel, Row } from 'react-bootstrap';
import { useSelector } from 'react-redux';
import { getLineItemsForOrder, getMaterialsByUri, getModelsByUri, getWorkflowsByUri } from 'rapidfab/selectors';
import { API_RESOURCES } from 'rapidfab/constants';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faChevronLeft,
  faChevronRight,
} from '@fortawesome/free-solid-svg-icons';
import _sortBy from 'lodash/sortBy';
import _chunk from 'lodash/chunk';
import dayjs from 'dayjs';
import { getLineItemWorkflowTypeObjectKey } from 'rapidfab/utils/lineItemUtils';

const LineItemCarouselView = ({ order, bg }) => {
  const lineItems = useSelector(state => getLineItemsForOrder(state, order));
  const [sortedValue, setSortValue] = useState('created');
  const [filterValue, setFilterValue] = useState('');
  const [pageOffSet, setPageOffset] = useState(0);
  const workflowsByUri = useSelector(getWorkflowsByUri);
  const materialsByUri = useSelector(getMaterialsByUri);
  const modelsByUri = useSelector(getModelsByUri);
  const isLoading = useSelector(state =>
    state.ui.nautilus[API_RESOURCES.MODEL].list.fetching || state.ui.nautilus[API_RESOURCES.WORKFLOW].list.fetching,
  );

  const NUMBER_OF_CARDS_TO_DISPLAY = 4;

  const handleNextPage = direction => {
    const increment = {
      right: 1,
      left: -1,
    };
    setPageOffset(previous => previous + increment[direction]);
  };

  const getFilterRegex = filter => {
    const isSpecialChar = /[ $()*+.?[\\\]^{|}]+$/;
    const isNumeric = /^\d+$/;
    const characters = [...filter].map(char => {
      if (isSpecialChar.test(char)) {
        return `\\${char}`;
      } if (isNumeric.test(char)) {
        return `[${char}]`;
      }
      return char;
    });
    const expanded = characters.join('');
    return new RegExp(`^.*${expanded}.*$`);
  };

  const filteredLineItems = useMemo(() => {
    const normalizedSearch = filterValue.normalize().toLowerCase();
    const searchRegex = getFilterRegex(normalizedSearch);

    if (!filterValue) return lineItems;

    return lineItems.filter(lineItem => {
      const { name, created, status, notes, custom_field_values, workflow } = lineItem;
      const workflowTypeKey = getLineItemWorkflowTypeObjectKey(lineItem);
      const { layer_thickness, materials } = lineItem[workflowTypeKey] || {};

      const matchesSearchByName = searchRegex.test(name.normalize().toLowerCase());

      const matchesStatus = searchRegex.test(status.normalize().toLowerCase());

      const matchesSearchByWorkflow =
        searchRegex.test(workflowsByUri[workflow].name.normalize().toLowerCase());

      const matchesSearchByBaseMaterial =
        !!materials && searchRegex.test(materialsByUri[materials.base]?.name.normalize().toLowerCase());

      const matchesSearchBySupportMaterial =
       !!materials && searchRegex.test(materialsByUri[materials.support]?.name.normalize().toLowerCase());

      const matchesSearchByCustomFieldValues =
        custom_field_values.some(({ value }) => searchRegex.test(value.normalize().toLowerCase()));

      const matchesSearchByNotes = !!notes && searchRegex.test(notes.normalize().toLowerCase());

      const matchesSearchByLayerThickness = !!layer_thickness && searchRegex.test(
        String(layer_thickness).normalize().toLowerCase(),
      );

      // // Format the created date as 'YYYY-MM' and match against the search term
      const matchesSearchByCreatedMonth = dayjs(created.normalize()).format('MM-YYYY').includes(normalizedSearch);

      // // Format the created date as 'YYYY-MM-DD' and match against the search term
      const matchesSearchByCreatedDate = dayjs(created.normalize()).format('MM-DD-YYYY').includes(normalizedSearch);

      const returnLineItem = matchesSearchByName
          || matchesSearchByLayerThickness
          || matchesSearchByCreatedMonth
          || matchesSearchByCreatedDate
          || matchesStatus
          || matchesSearchByNotes
          || matchesSearchByCustomFieldValues
          || matchesSearchByBaseMaterial
          || matchesSearchBySupportMaterial
          || matchesSearchByWorkflow;

      setPageOffset(0);
      return returnLineItem;
    });
  }, [filterValue, JSON.stringify(lineItems)]);

  const sortedLineItems = useMemo(() => {
    const sortedItems = _sortBy(filteredLineItems, [sortedValue]);
    return _chunk(sortedItems, NUMBER_OF_CARDS_TO_DISPLAY);
  }, [sortedValue, filteredLineItems]);

  const renderLineItemSwitchButton = (direction, pageOffSet) => (
    <Button
      className={`LineItemCardSwitchBtn ${direction === 'left' ? 'spacer-right' : ''}`}
      onClick={() => handleNextPage(direction)}
      disabled={(pageOffSet === 0 && direction === 'left') ||
        (pageOffSet === sortedLineItems.length - 1 && direction === 'right')}
    >
      {direction === 'left' ?
        <FontAwesomeIcon icon={faChevronLeft} /> :
        <FontAwesomeIcon icon={faChevronRight} />}
    </Button>
  );

  if (!lineItems.length) return null;

  return (
    <Card bg={bg} data-cy="line-item-carousel">
      <Card.Header className="pd-exp inverse">
        Line Item Quick View ({lineItems.length} Line {pluralWord('Item', lineItems)})
      </Card.Header>
      <div className="card-body-wrapper">
        <Card.Body>
          <Row className="mb10">
            <Col lg={8} className="m-b">
              <FormLabel>Search</FormLabel>
              <FormControl
                as="input"
                onChange={
                  ({ target }) => setFilterValue(target.value)
                }
              />
            </Col>
            <Col>
              <FormLabel>Sort By</FormLabel>
              <FormControl
                as="select"
                onChange={event => setSortValue(event.target.value)}
              >
                <option value="created">Created (Oldest to Newest)</option>
                <option value="name">Name</option>
                <option value="priority">Priority (Highest to Lowest)</option>
              </FormControl>
            </Col>
          </Row>
          <Row className="m-b">
            <div className="d-flex align-items-center justify-content-end mb10">
              {Math.floor(sortedLineItems?.length) > 1 && (
                <>
                  {renderLineItemSwitchButton('left', pageOffSet)}
                  {renderLineItemSwitchButton('right', pageOffSet)}
                </>
              )}
            </div>
            <Col>
              <Row>
                {sortedLineItems[pageOffSet]?.map(lineItem => {
                  const workflowTypeKey = getLineItemWorkflowTypeObjectKey(lineItem);
                  return (
                    <MiniCards
                      key={lineItem.uri}
                      lineItem={lineItem}
                      model={modelsByUri[lineItem[workflowTypeKey]?.model]}
                      workflow={workflowsByUri[lineItem.workflow]}
                      material={{
                        base: materialsByUri[lineItem[workflowTypeKey]?.materials.base],
                        support: materialsByUri[lineItem[workflowTypeKey]?.materials.support],
                      }}
                      isLoading={isLoading}
                    />
                  );
                })}
              </Row>
            </Col>
          </Row>

        </Card.Body>
      </div>
    </Card>
  );
};

LineItemCarouselView.defaultProps = {
  bg: 'dark',
};

LineItemCarouselView.propTypes = {
  bg: PropTypes.string,
  order: PropTypes.shape({}).isRequired,
};

export default LineItemCarouselView;
