import React, { useCallback, memo } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import Actions from 'rapidfab/actions';
import { API_RESOURCES } from 'rapidfab/constants';
import {
  getWorkflowsByUri,
  getPiecesForLineItem,
  getNonRemanufacturedPrintsByPieceUri,
  getPrintsByUri,
} from 'rapidfab/selectors';
import LineItemPieces from 'rapidfab/components/records/order/edit/LineItemPieces';
import PropTypes from 'prop-types';

const LineItemPiecesContainer = props => {
  const workflowsByUri = useSelector(getWorkflowsByUri);
  const pieces = useSelector(state => getPiecesForLineItem(state, props.lineItem));
  const printsByPieceUri = useSelector(getNonRemanufacturedPrintsByPieceUri);
  const printsByUri = useSelector(getPrintsByUri);
  const isFetching = useSelector(state => state.ui.nautilus[API_RESOURCES.PIECE].list.fetching
    || state.ui.nautilus[API_RESOURCES.PRINT].list.fetching);

  const selected = {
    isFetching,
    workflowsByUri,
    pieces,
    printsByUri,
    printsByPieceUri,
  };

  const dispatch = useDispatch();
  const onUpdate = useCallback(payload =>
    dispatch(Actions.Api.nautilus[API_RESOURCES.PIECE].put(payload.uuid, payload)),
  [dispatch]);

  return (
    <LineItemPieces
      {...props}
      {...selected}
      onUpdate={onUpdate}
    />
  );
};

LineItemPiecesContainer.propTypes = {
  lineItem: PropTypes.shape({}).isRequired,
};

export default memo(LineItemPiecesContainer);
