import { createSelector } from 'reselect';
import * as baseStateSelectors from 'rapidfab/selectors/baseStateSelectors';
import _map from 'lodash/map';
import { getStateResources, getPredicate } from 'rapidfab/selectors/helpers/base';
import _keyBy from 'lodash/keyBy';
import _filter from 'lodash/filter';

export const getPayments = createSelector(
  [baseStateSelectors.getStatePayments, getStateResources],
  (uuids, resources) => _map(uuids, uuid => resources[uuid]),
);

export const getPaymentsByUri = createSelector([getPayments], payments =>
  _keyBy(payments, 'uri'),
);

export const getPaymentsFilterableByOrder = createSelector(
  [getPredicate, baseStateSelectors.getStatePayments],
  (orderUri, payments) => (
    /* Using `.resources` because there seems to be a caching issue on the /payment
    endpoint. Regular selectors don't appear to be working. */
    _filter(payments?.resources, ({ payment_for: orderUri }))
  ));
