import React, { useCallback, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';

import Actions from 'rapidfab/actions';

import Loading from 'rapidfab/components/Loading';
import NewRestrictedOrderComponent from 'rapidfab/components/records/order/restricted/new/NewRestrictedOrder';
import { API_RESOURCES, FEATURES } from 'rapidfab/constants';
import { createOrReplaceArray } from 'rapidfab/utils/arrayUtils';
import getInitialCustomFieldValues from 'rapidfab/utils/getInitialCustomFieldValues';
import * as Selectors from 'rapidfab/selectors';
import userSort from 'rapidfab/utils/userSort';
import dayjs from 'dayjs';

const NewRestrictedOrder = props => {
  const fetching = useSelector(state => state.ui.nautilus[API_RESOURCES.SHIPPING].list.fetching
    || state.ui.nautilus[API_RESOURCES.USERS].list.fetching
    || state.ui.nautilus[API_RESOURCES.LOCATION].list.fetching);

  const dispatch = useDispatch();
  const onInitialize = () => {
    dispatch(Actions.Api.nautilus[API_RESOURCES.SHIPPING].list());
    dispatch(Actions.Api.nautilus[API_RESOURCES.USERS].list());
    dispatch(Actions.Api.nautilus[API_RESOURCES.LOCATION].list());
  };

  const customOrderFieldReferences = useSelector(Selectors.getCustomOrderFieldReferences) || [];

  const onCustomFieldChange = useCallback(([field, customField], state, { changeValue }) => {
    const customFieldValues = state.formState.values.custom_field_values;

    const customFieldValuesReplaced = createOrReplaceArray(
      customFieldValues,
      { custom_field: customField.customFieldReferenceUri },
      { value: customField.value },
    );

    changeValue(state, field, () => (customFieldValuesReplaced));
  }, []);

  const initCustomFieldValues = useCallback(([field], state, { changeValue }) => {
    const customFieldValues = state.formState.values.custom_field_values;

    const updatedCustomOrderFieldValues = getInitialCustomFieldValues(
      customOrderFieldReferences, customFieldValues,
    );
    changeValue(state, field, () => (updatedCustomOrderFieldValues));
  }, [customOrderFieldReferences]);

  const shippings = useSelector(Selectors.getShippingsAlphabetized);
  const users = useSelector(Selectors.getUsers).sort(userSort);
  const initialShipping = shippings[0] ? shippings[0].uri : null;
  const sessionResources = useSelector(Selectors.getSession);
  const locationsAvailable = useSelector(Selectors.getLocations);

  const orderBusinessSegmentFeatureEnabled = useSelector(state =>
    Selectors.isFeatureEnabled(state, FEATURES.ORDER_BUSINESS_SEGMENT));
  const isShipmentForOrderFeatureEnabled = useSelector(state =>
    Selectors.isFeatureEnabled(state, FEATURES.SHIPMENT_FOR_ORDER));
  const threeMOrderFieldsFeatureEnabled = useSelector(state =>
    Selectors.isFeatureEnabled(state, FEATURES.THREE_M_MAIN_BUREAU_ORDER_FIELDS));
  const beehiveOrderFieldsFeatureEnabled = useSelector(state =>
    Selectors.isFeatureEnabled(state, FEATURES.BEEHIVE3D_ORDER_FIELDS));
  const xerox3DPFieldsFeatureEnabled = useSelector(state =>
    Selectors.isFeatureEnabled(state, FEATURES.XEROX3DP_FIELDS));
  const isPOCUKOrderFieldsFeatureEnabled = useSelector(
    state => Selectors.isFeatureEnabled(state, FEATURES.POC_UK_ORDER_FIELDS),
  );
  const isEatonOrderFieldsEnabled = useSelector(state =>
    Selectors.isFeatureEnabled(state, FEATURES.EATON_ORDER_FIELDS));

  // Pre-populated customer fields by request of Danfoss [ch4166] and 3M [7667]
  const includeSessionFields = (
    orderBusinessSegmentFeatureEnabled || threeMOrderFieldsFeatureEnabled
  );
  const sessionUserName = includeSessionFields ? sessionResources.name : null;
  const sessionUserEmail = includeSessionFields ? sessionResources.username : null;

  const initialFormValues = {
    customer_name: sessionUserName,
    customer_email: sessionUserEmail,
    custom_field_values: [],
    shipping: {
      uri: initialShipping,
    },
    due_date: isPOCUKOrderFieldsFeatureEnabled ? dayjs().add(7, 'day').format('YYYY-MM-DD') : null,
  };

  const selected = {
    initialFormValues,
    shippings,
    users,
    customOrderFieldReferences,
    locationsAvailable,
    isUserRestricted: true,
    isOrderBusinessSegmentFeatureEnabled: orderBusinessSegmentFeatureEnabled,
    is3MOrderFieldsFeatureEnabled: threeMOrderFieldsFeatureEnabled,
    isBeehiveOrderFieldsFeatureEnabled: beehiveOrderFieldsFeatureEnabled,
    isXerox3DPFieldsFeatureEnabled: xerox3DPFieldsFeatureEnabled,
    isShipmentForOrderFeatureEnabled,
    initialShipping,
    isEatonOrderFieldsEnabled,
  };

  const dispatched = {
    onCustomFieldChange,
    initCustomFieldValues,
  };

  useEffect(() => onInitialize(), []);

  return (
    <div>
      {fetching ? (
        <Loading />
      ) : (
        <div>
          <NewRestrictedOrderComponent {...props} {...selected} {...dispatched} />
        </div>
      )}
    </div>
  );
};

NewRestrictedOrder.propTypes = {
  fetching: PropTypes.bool.isRequired,
  onInitialize: PropTypes.func.isRequired,
  uploadModel: PropTypes.shape({}).isRequired,
};

export default NewRestrictedOrder;
