import {
  createRunProgressError,
  createRunSuccess,
  createRunUpdateProcessing, createRunUpdateScheduling,
} from 'rapidfab/actions/createRunProgress';
import { CREATE_RUN_EVENT_STREAM_STATUSES, CREATE_RUN_PROGRESS_STATUSES } from 'rapidfab/constants';
import { getStateEventStreamCreateRunLatest } from 'rapidfab/selectors/baseStateSelectors';
import { extractUuid } from 'rapidfab/utils/uuidUtils';
import { useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';

const useTrackRunCreationStatus = createRunUri => {
  const latestCreateRunEvent = useSelector(getStateEventStreamCreateRunLatest);
  const schedulingTimeoutRef = useRef(null); // Store the timeout reference
  const dispatch = useDispatch();

  useEffect(() => {
    if (!createRunUri) return;

    const { payload, topic } = latestCreateRunEvent || {};
    const { status, uri, primary_run, finished, notes, unfitted_pieces } = payload || {};

    const isUpdatedEvent = topic === CREATE_RUN_EVENT_STREAM_STATUSES.CREATE_RUNS_UPDATED;
    const isRunUpdatedEvent = topic === CREATE_RUN_EVENT_STREAM_STATUSES.RUN_UPDATED;
    const isUriMatch = uri === createRunUri;

    const eventMatches = statusType => isUpdatedEvent && status === statusType && isUriMatch;

    if (eventMatches(CREATE_RUN_PROGRESS_STATUSES.PROCESSING)) {
      dispatch(createRunUpdateProcessing(extractUuid(createRunUri)));
    }

    if (eventMatches(CREATE_RUN_PROGRESS_STATUSES.COMPLETE) && primary_run) {
      // Set the "Scheduling" status for 2 seconds to simulate the scheduling process
      dispatch(createRunUpdateScheduling(extractUuid(createRunUri)));

      // Clear any existing timeout to avoid duplicates
      if (schedulingTimeoutRef.current) {
        clearTimeout(schedulingTimeoutRef.current);
      }

      // Set a new timeout to update the status to "complete"
      schedulingTimeoutRef.current = setTimeout(() => {
        const runDetails = { finished, primary_run, resulting_runs: payload.resulting_runs };
        dispatch(createRunSuccess(extractUuid(createRunUri), runDetails));
      }, 2000); // 2 seconds delay
    }

    if ((isRunUpdatedEvent && status === CREATE_RUN_PROGRESS_STATUSES.ERROR)
      || eventMatches(CREATE_RUN_PROGRESS_STATUSES.ERROR)) {
      const errorDetails = { finished, notes, unfittedPiecesLength: unfitted_pieces?.length };
      dispatch(createRunProgressError(extractUuid(createRunUri), errorDetails));
    }
  }, [latestCreateRunEvent, createRunUri, dispatch]);
};

export default useTrackRunCreationStatus;
