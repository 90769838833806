import React from 'react';
import PropTypes from 'prop-types';

import Loading from 'rapidfab/components/Loading';

import BureauError from 'rapidfab/components/bureauError';
import Tos from 'rapidfab/components/tos';

import 'react-s-alert/dist/s-alert-default.css';
import 'react-s-alert/dist/s-alert-css-effects/slide.css';
import { userResourceType } from 'rapidfab/types';

const SessionProvider = ({
  bureaus,
  children,
  currentUser,
  fetching,
  errors,
  onAcceptTerms,
  isHawkingUser,
}) => {
  if (!currentUser && !fetching) {
    // Looks like it's not logged in user (errors are 403 to api requests).

    // Children component will be rendered, router will check permissions and
    // navigate user to correct route (main page/login for unrestricted users)

    // We don't have redirect here as we don't have router object for this user
    return <div>{children}</div>;
  }

  if (currentUser && !fetching) {
    if (!currentUser.tos && !currentUser.impersonating) {
      return <Tos user={currentUser} onAcceptTerms={onAcceptTerms} isHawkingUser={isHawkingUser} />;
    } if (errors.length || bureaus.size === 0) {
      return <BureauError bureaus={bureaus} errors={errors} />;
    }
    return <div>{children}</div>;
  }

  return <Loading />;
};

SessionProvider.defaultProps = { currentUser: null };

SessionProvider.propTypes = {
  bureaus: PropTypes.shape({ size: PropTypes.number }).isRequired,
  currentUser: userResourceType,
  children: PropTypes.element.isRequired,
  fetching: PropTypes.bool.isRequired,
  errors: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  onAcceptTerms: PropTypes.func.isRequired,
  isHawkingUser: PropTypes.bool.isRequired,
};

export default SessionProvider;
