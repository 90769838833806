import React, { useState } from 'react';
import PropTypes from 'prop-types';
import LineItems from 'rapidfab/components/records/order/edit/LineItems';
import { designFileResourceType, lineItemType, modelType, productResourceType } from 'rapidfab/types';
import { DESIGN_FILE_TYPES, FEATURES } from 'rapidfab/constants';
import AddLineItem from 'rapidfab/components/records/order/edit/AddLineItem';
import { Button, Card } from 'react-bootstrap';
import CancelOrDeleteModal from 'rapidfab/components/CancelOrDeleteModal';
import { FormattedMessage } from 'react-intl';
import Alert from 'react-s-alert';
import { useSelector } from 'react-redux';
import { isFeatureEnabled } from 'rapidfab/selectors';

const Product = ({
  product,
  designFile,
  modelsForDesignFile,
  modelLibraryModel,
  lineItems,
  readOnlyLineItemsOrCallback,
  deleteProduct,
  highlightedItems,
  setHighlightedItems,
  expandMode,
  setExpandedItems,
  expandedItems,
  setExpandMode,
  scrollRef,
  productIndex,
}) => {
  const [isConfirmDeleteModalVisible, setIsConfirmDeleteModalVisible] = useState(false);
  const isPowderWorkflowFeatureEnabled = useSelector(state => isFeatureEnabled(state, FEATURES.POWDER_WORKFLOW));

  if (!product.uri) {
    // No need to show product right after POST
    // waiting for it to be fully loaded first
    return null;
  }

  if (lineItems.length) {
    // When Line Item(s) are already created - just render them
    return (
      <LineItems
        lineItems={lineItems.map(({ uri }) => uri)}
        productIndex={productIndex}
        readOnly={readOnlyLineItemsOrCallback}
        expandMode={expandMode}
        setExpandedItems={setExpandedItems}
        expandedItems={expandedItems}
        setExpandMode={setExpandMode}
        isPowderWorkflowFeatureEnabled={isPowderWorkflowFeatureEnabled}
        designFile={designFile}
      />
    );
  }

  let newLineItemForm;

  const isSingleMeshDesignFileWithModel =
    // Single-mesh design file exists
    designFile && designFile.type === DESIGN_FILE_TYPES.SINGLE_MESH
    // And model for design file exists
    && modelsForDesignFile.length;

  const isNoModelUpload = product.no_model_upload;

  const isSelectedFromModelLibrary = product.model_library && modelLibraryModel;

  if (
    isSingleMeshDesignFileWithModel
    || isNoModelUpload
    || isSelectedFromModelLibrary
  ) {
    const model =
      // In the current implementation `single mesh` design file has only 1 model
      modelsForDesignFile[0]
      || modelLibraryModel;

    // Show Line Item creation form for the design file model (or with empty model for no_model_upload)
    newLineItemForm = (
      <AddLineItem
        modelUri={model && model.uri}
        productUri={product.uri}
        highlightedItems={highlightedItems}
        setHighlightedItems={setHighlightedItems}
      />
    );
  }

  const onProductDelete = () =>
    deleteProduct()
      .then(() => {
        Alert.success(
          <FormattedMessage
            id="toaster.productDeletedSuccessfully"
            defaultMessage="Product deleted successfully."
          />,
        );
      });

  // TODO: Handle Error state of product properly (use product status once it is implemented)
  return (
    <Card bg="dark" border="secondary" className="mb15" data-cy="add-cad-container">
      <Card.Header className="pd-exp inverse">
        <div className="clearfix" ref={scrollRef}>
          <FormattedMessage
            id="record.cadfile.addDetails"
            defaultMessage="Add CAD File Details"
          />
          <Button
            variant="danger"
            size="sm"
            className="pull-right"
            type="button"
            onClick={() => setIsConfirmDeleteModalVisible(true)}
          >
            <FormattedMessage id="button.delete" defaultMessage="Delete" />
          </Button>
        </div>
      </Card.Header>
      <Card.Body>
        {
          newLineItemForm
        || (
          <p>
            Failed to create product properly. Try to re-load the page or remove the product and re-add it again
          </p>
        )
        }
        {isConfirmDeleteModalVisible && (
          <CancelOrDeleteModal
            modalType="delete"
            handleConfirm={onProductDelete}
            handleOpen={() => {
            // handleOpen is used only to close modal `handleOpen('')`
              setIsConfirmDeleteModalVisible(false);
            }}
          />
        )}
      </Card.Body>
    </Card>
  );
};

Product.defaultProps = {
  readOnlyLineItemsOrCallback: false,
  designFile: null,
  modelLibraryModel: null,
};

Product.propTypes = {
  product: productResourceType.isRequired,
  lineItems: PropTypes.arrayOf(lineItemType).isRequired,
  readOnlyLineItemsOrCallback: PropTypes.oneOfType([PropTypes.bool, PropTypes.func]),
  designFile: designFileResourceType,
  modelsForDesignFile: PropTypes.arrayOf(modelType).isRequired,
  modelLibraryModel: modelType,
  deleteProduct: PropTypes.func.isRequired,
  highlightedItems: PropTypes.oneOfType([
    PropTypes.bool,
    PropTypes.instanceOf(Array),
  ]).isRequired,
  setHighlightedItems: PropTypes.func.isRequired,
  expandMode: PropTypes.oneOfType([PropTypes.oneOf([null]), PropTypes.string]).isRequired,
  setExpandedItems: PropTypes.func.isRequired,
  setExpandMode: PropTypes.func.isRequired,
  expandedItems: PropTypes.arrayOf(PropTypes.number).isRequired,
  scrollRef: PropTypes.shape({ current: PropTypes.instanceOf(Element) }).isRequired,
  productIndex: PropTypes.number.isRequired,

};

export default Product;
