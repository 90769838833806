import React, { useContext, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { ListGroupItem, Row, Col, Modal, FormControl, FormGroup, ListGroup, Button, Form } from 'react-bootstrap';
import { FormattedMessage } from 'react-intl';
import _reduce from 'lodash/reduce';
import Loading from 'rapidfab/components/Loading';
import { DragDropContext } from 'react-beautiful-dnd';
import { ACCESS_INFO_ACTION_TYPES, PROCESS_STEP_SHIPPING_DIRECTION, RUN_OPERATIONS } from 'rapidfab/constants';
import DisabledByAccessInfoCheck from 'rapidfab/components/DisabledByAccessInfoCheck';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import GuidelineSuggestionContext from 'rapidfab/context/GuidelineSuggestionContext';
import SplitConfirmationModal from 'rapidfab/components/records/run/split_confirmation_modal/SplitConfirmationModal';
import { faPlus } from '@fortawesome/free-solid-svg-icons';
import ProcessTypes from './ProcessTypes';

// Removes outsource shipping process steps from steps list
// related to provided post processor outsourse process step index
const removeRelatedOutsourceShippingSteps = (processTypes, outsourceStepIndex) => {
  const updatedProcessTypes = [...processTypes];
  const nextStepIndex = outsourceStepIndex + 1;
  const nextStep = updatedProcessTypes[nextStepIndex];
  if (nextStep && nextStep.shippingDirection
    === PROCESS_STEP_SHIPPING_DIRECTION.SERVICE_PROVIDER_TO_BUREAU) {
    updatedProcessTypes.splice(nextStepIndex, 1);
  }

  const previousStepIndex = outsourceStepIndex - 1;
  const previousStep = updatedProcessTypes[previousStepIndex];
  if (previousStep && previousStep.shippingDirection
    === PROCESS_STEP_SHIPPING_DIRECTION.BUREAU_TO_SERVICE_PROVIDER) {
    updatedProcessTypes.splice(previousStepIndex, 1);
  }

  return updatedProcessTypes;
};

const reorder = (list, startIndex, endIndex, deleteCount = 1) => {
  const result = [...list];
  const removed = result.splice(startIndex, deleteCount);
  result.splice(endIndex, 0, ...removed);
  return result;
};

const WorkflowPickListModal = ({
  show,
  onClose,
  printerTypes,
  postProcessorTypes,
  shippingTypes,
  sending,
  fetching,
  workstationsGroupedByType,
  currentProcessStepPosition,
  setChangeWorkflowNotes,
  notes,
  showChangeWorkflowNotes,
  workstationsByUri,
  isGeneralMFGLanguageEnabled,
  currentWorkflowSteps,
  bureauUri,
  processTypesByUri,
  isEditingDisabled,
  isAllPrintsSelected,
  selectedPiecesList,
  isPowderWorkflow,
  selectedLineItemsData,
  ...props
}) => {
  const initialProcessTypes = [...printerTypes, ...postProcessorTypes, ...shippingTypes];
  const [allProcessTypes, setAllProcessTypes] = useState(initialProcessTypes);
  const [selectedProcessTypes, setSelectedProcessTypes] = useState([]);
  const [showSplitConfirmationModal, setShowSplitConfirmationModal] = useState(false);

  const guidelineSuggestionContext = useContext(GuidelineSuggestionContext);
  const { selectedGuidelineSuggestion } = guidelineSuggestionContext ?? {};
  const { suggestion } = selectedGuidelineSuggestion ?? {};

  const refreshProcessSteps = () => {
    /*
     * Will update state (selectedProcessTypes) with all up-to-date process steps
     * based on props (currentWorkflowSteps)
     */
    const newSelectedProcessTypes = _reduce(currentWorkflowSteps, (result, workflowStep) => {
      // printer/post-processor types from props contain only Available for Adding ones
      // (with printers/post-processors attached)
      // Using processTypesByUri here since Process Type might be added some time ago
      // and then become non-available (e.g. printer is removed)
      const currentProcessType = processTypesByUri[workflowStep.workstation_type_uri];
      if (currentProcessType) {
        result.push({
          ...currentProcessType,
          workstationUri: workflowStep.workstation,
          specificWorkstation: Boolean(workflowStep.workstation),
          shippingDirection: workflowStep.shipping_direction,
        });
      }

      return result;
    }, []);

    if (guidelineSuggestionContext && selectedGuidelineSuggestion.suggestion) {
      const suggestionProcessType = processTypesByUri[suggestion.process_step_template.workstation_type_uri];
      setSelectedProcessTypes([suggestionProcessType, ...newSelectedProcessTypes]);
      return;
    }

    setSelectedProcessTypes(newSelectedProcessTypes);
  };

  useEffect(() => {
    if (initialProcessTypes.length > 0) {
      setAllProcessTypes(initialProcessTypes);
    }
  }, [initialProcessTypes.length]);

  useEffect(() => {
    if (currentWorkflowSteps.length) {
      refreshProcessSteps();
    }
  }, [JSON.stringify(currentWorkflowSteps), JSON.stringify(processTypesByUri)]);

  useEffect(() => {
    if (guidelineSuggestionContext && selectedGuidelineSuggestion.suggestion) {
      const suggestionProcessType = processTypesByUri[suggestion.process_step_template.workstation_type_uri];
      setSelectedProcessTypes(previous => {
        // skip if previous already contains suggestion
        if (previous.some(step => step.id === suggestionProcessType.id)) {
          return previous;
        }
        return ([suggestionProcessType, ...previous]);
      });
    }
  }, []);

  const handleSelected = selectedProcessTypeId => {
    const selectedProcessType = allProcessTypes.find(
      step => step.id === selectedProcessTypeId,
    );

    const isShippingProcessType = shippingTypes.find(
      type => type.id === selectedProcessTypeId,
    );

    const isPrintingProcessType = printerTypes.find(
      type => type.id === selectedProcessTypeId,
    );

    // Due to a validation for adding the Printing Step only at the beginning of the list.
    if (isPrintingProcessType) {
      setSelectedProcessTypes(previous => ([selectedProcessType, ...previous]));
    } else {
      setSelectedProcessTypes(previous => ([
        ...previous,
        {
          ...selectedProcessType,
          // Shipping direction is `bureau_to_customer`
          // for all selected manually shipping steps for now
          shippingDirection:
            isShippingProcessType ? PROCESS_STEP_SHIPPING_DIRECTION.BUREAU_TO_CUSTOMER : null,
        },
      ]));
    }
  };

  const handleSelectedOutsourceShipping = (selectedProcessTypeId, index, shippingDirection) => {
    const selectedProcessType = allProcessTypes.find(
      step => step.id === selectedProcessTypeId,
    );

    const changedSelectedProcessSteps = [...selectedProcessTypes];

    // selectedProcessType is not found when `None` is selected from dropdown
    if (!selectedProcessType) {
      if (selectedProcessTypes[index] && selectedProcessTypes[index].shippingDirection === shippingDirection) {
        // Remove outsource shipping step when there was one previously and "None" selected now
        changedSelectedProcessSteps.splice(index, 1);
      }
    } else {
      const stepWithShippingDirection = {
        ...selectedProcessType,
        shippingDirection,
      };
      if (selectedProcessTypes[index] && selectedProcessTypes[index].shippingDirection === shippingDirection) {
        // Replace Outsource shipping step when there was one previously
        changedSelectedProcessSteps[index] = stepWithShippingDirection;
      } else {
        const indexToInsert =
          shippingDirection === PROCESS_STEP_SHIPPING_DIRECTION.BUREAU_TO_SERVICE_PROVIDER
            // For `to` shipping step - it needs to be inserted
            // into current Post Processor Outsource index (expected Shipping Index + 1)
            ? index + 1
            // For 'from' shipping step -
            // insert right after current Post Processor Outsource index (expected Shipping index)
            : index;
        // Add new Outsource shipping step when there was none previously
        changedSelectedProcessSteps.splice(indexToInsert, 0, stepWithShippingDirection);
      }
    }
    setSelectedProcessTypes(changedSelectedProcessSteps);
  };

  const onSubmit = event => {
    event.preventDefault();
    if (isAllPrintsSelected) {
      const convertedSteps = selectedProcessTypes.map(step => (
        {
          notes: 'optional',
          upload: 'optional',
          success: 'optional',
          tracking_id: 'hidden',
          workstation_type_uri: step.uri,
          // Set workstation URI only if checkbox is checked
          workstation: step.specificWorkstation ? step.workstationUri : null,
          shipping_direction: step.shippingDirection,
          bureau: bureauUri,
        }),
      );
      props.onSubmit(convertedSteps);
    } else {
      setShowSplitConfirmationModal(true);
    }
  };

  const handleSplitConfirmationSubmit = splitSchedules => {
    const convertedSteps = selectedProcessTypes.map(step => (
      {
        notes: 'optional',
        upload: 'optional',
        success: 'optional',
        tracking_id: 'hidden',
        workstation_type_uri: step.uri,
        // Set workstation URI only if checkbox is checked
        workstation: step.specificWorkstation ? step.workstationUri : null,
        shipping_direction: step.shippingDirection,
        bureau: bureauUri,
      }),
    );
    props.onSubmit(convertedSteps, splitSchedules);
  };

  const removeSelectedAt = index => {
    setSelectedProcessTypes(previous => (
      previous.filter(
        (processType, currentIndex) => {
          if (index === currentIndex) {
            // Exclude selected At
            return false;
          }

          if (currentIndex === (index - 1) && processType.shippingDirection
            === PROCESS_STEP_SHIPPING_DIRECTION.BUREAU_TO_SERVICE_PROVIDER
          ) {
            // Additionally remove previous step, if that is `to` shipping
            return false;
          }

          if (currentIndex === (index + 1) && processType.shippingDirection
            === PROCESS_STEP_SHIPPING_DIRECTION.SERVICE_PROVIDER_TO_BUREAU
          ) {
            // Additionally remove next step, if that is `from` shipping
            return false;
          }

          // Include all other steps
          return true;
        },
      )
    ));
  };

  const selectWorkstationCheckboxChanged = index => {
    let changedSelectedProcessTypes = [...selectedProcessTypes];
    const changedSelectedProcessType = changedSelectedProcessTypes[index];
    changedSelectedProcessType.specificWorkstation = !changedSelectedProcessType.specificWorkstation;

    // If Specific Workstation checkbox unchecked clear selected workstation
    // and related outsource shipping steps (if any)
    if (!changedSelectedProcessType.specificWorkstation) {
      // If previously selected workstation is an outsource one clear related shipping steps
      const selectedWorkstation = workstationsByUri[changedSelectedProcessType.workstationUri];
      const isOutsourceWorkstation = selectedWorkstation && selectedWorkstation.is_service;

      if (isOutsourceWorkstation) {
        changedSelectedProcessTypes = removeRelatedOutsourceShippingSteps(changedSelectedProcessTypes, index);
      }

      changedSelectedProcessType.workstationUri = null;
    }
    setSelectedProcessTypes(changedSelectedProcessTypes);
  };

  const selectWorkstation = (name, uri) => {
    // `name` is actually a `string` index from the SingleSelect
    const index = Number(name);
    let changedSelectedProcessTypes = [...selectedProcessTypes];
    const changedSelectedProcessType = changedSelectedProcessTypes[index];

    // If workstation URI is changed from Outsource
    // to some other one - clear related shipping steps
    if (changedSelectedProcessType.workstationUri) {
      // If previously selected workstation is an outsource one clear related shipping steps
      const selectedWorkstation = workstationsByUri[changedSelectedProcessType.workstationUri];
      const isOutsourceWorkstation = selectedWorkstation && selectedWorkstation.is_service;

      if (isOutsourceWorkstation) {
        changedSelectedProcessTypes = removeRelatedOutsourceShippingSteps(changedSelectedProcessTypes, index);
      }
    }

    changedSelectedProcessType.workstationUri = uri || null;
    setSelectedProcessTypes(changedSelectedProcessTypes);
  };

  const isWorkflowSubmitDisabled = sending || fetching || (showChangeWorkflowNotes && !notes) || isEditingDisabled;

  const sortByName = (a, b) => a.name.localeCompare(b.name);
  printerTypes.sort(sortByName);
  postProcessorTypes.sort(sortByName);
  shippingTypes.sort(sortByName);

  const onDragEnd = result => {
    if (!result.destination) {
      return;
    }
    const processType = selectedProcessTypes[result.source.index];
    const selectedWorkstation = workstationsByUri[processType.workstationUri];
    const isOutsourceWorkstation = selectedWorkstation && selectedWorkstation.is_service;
    const { index } = result.source;
    let startIndex = result.source.index;
    let endIndex = result.destination.index;
    let deleteCount = 1;
    if (isOutsourceWorkstation) {
      const previousStep = selectedProcessTypes[index - 1];
      const nextStep = selectedProcessTypes[index + 1];
      let toShippingStep = null;
      let fromShippingStep = null;
      if (
        previousStep && previousStep.shippingDirection === PROCESS_STEP_SHIPPING_DIRECTION.BUREAU_TO_SERVICE_PROVIDER
      ) {
        toShippingStep = previousStep;
      }

      if (nextStep && nextStep.shippingDirection === PROCESS_STEP_SHIPPING_DIRECTION.SERVICE_PROVIDER_TO_BUREAU) {
        fromShippingStep = nextStep;
      }

      if (toShippingStep && fromShippingStep) {
        deleteCount = 3;
        startIndex -= 1;
        if (result.source.index < result.destination.index) {
          endIndex -= 2;
        }
      } else if (toShippingStep) {
        deleteCount = 2;
        startIndex -= 1;
      } else if (fromShippingStep) {
        deleteCount = 2;
      }
    }

    const completedSelectedProcessTypes = selectedProcessTypes
      .filter((_, index) => currentProcessStepPosition >= (index + 1));
    const completedSelectedProcessTypesIndexes = completedSelectedProcessTypes
      .map((_, index) => index);
    if (completedSelectedProcessTypesIndexes.includes(result.destination.index)) {
      return;
    }

    const items = reorder(selectedProcessTypes, startIndex, endIndex, deleteCount);
    setSelectedProcessTypes(items);
  };

  const isPrinterSelected =
    selectedProcessTypes.find(processType =>
      processType.uri.includes('printer-type'),
    ) !== undefined;

  let processTypeGroups;
  if (isPowderWorkflow || (isPrinterSelected || printerTypes.length < 1)) {
    processTypeGroups = [
      {
        label: 'Post-Processor Types',
        operation: RUN_OPERATIONS.POST_PROCESSING,
        processTypes: postProcessorTypes,
      },
      {
        label: 'Shipping Types',
        operation: RUN_OPERATIONS.SHIPPING,
        processTypes: shippingTypes,
      },
    ];
  } else {
    processTypeGroups = [
      {
        label: isGeneralMFGLanguageEnabled ? 'Production Device Types' : 'Printer Types',
        operation: RUN_OPERATIONS.PRINTING,
        processTypes: printerTypes,
      },
    ];
  }

  return (
    <>
      <Modal
        show={show}
        onHide={onClose}
        size="lg"
        className="picklistModal"
        backdrop="static"
      >
        <Form onSubmit={onSubmit}>
          <Modal.Header closeButton className="text-left">
            <Modal.Title>Add Production Workflow Steps</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            {showChangeWorkflowNotes && (
              <Row>
                <Col xs={12} sm={12} lg={12}>
                  <div
                    className="well picklist-well"
                    style={{ boxShadow: 'none' }}
                  >
                    <FormGroup>
                      <p className="pickList-p">
                        Reason for Changing Production Workflow*
                      </p>
                      <FormControl
                        as="textarea"
                        style={{ height: 60 }}
                        placeholder="Notes"
                        type="text"
                        id="remanufactureNotes"
                        name="remanufactureNotes"
                        value={notes}
                        onChange={event => setChangeWorkflowNotes(event)}
                      />
                    </FormGroup>
                  </div>
                </Col>
              </Row>
            )}
            <Row>
              <Col xs={6} sm={6} lg={6}>
                <p className="pickList-p">Available Steps</p>
                {fetching ? (
                  <Loading />
                ) : (
                  <div className="well picklist-well" data-cy="workflow-step-picklist">
                    {processTypeGroups
                      .map(({
                        label,
                        operation,
                        processTypes,
                      }) => (
                        <ListGroup key={label}>
                          <p className="pickList-p">{label}</p>
                          {processTypes.map((processType, index) => (
                            <DisabledByAccessInfoCheck
                              key={processType.uri}
                              resourceUri={
                              // Prevent running checks for shipping, since it is not supported for now
                                operation !== RUN_OPERATIONS.SHIPPING &&
                              processType.uri
                              }
                              ignoreDisabledPrefixRendering
                              actionType={ACCESS_INFO_ACTION_TYPES.USE}
                              // Loading data in parent container
                              disableInit
                            >
                              {({
                                disabled,
                                disabledPrefix,
                              }) => (
                                <ListGroupItem
                                  className="picklist-group-item"
                                  id={processType.id}
                                  disabled={disabled}
                                  // eslint-disable-next-line react/no-array-index-key
                                  key={`${processType.id}+${index}`}
                                  onClick={() =>
                                    !disabled && handleSelected(processType.id)}
                                >
                                  <Row className="picklist-group-item-row">
                                    <Col xs={1}>
                                      {disabled ? (
                                        disabledPrefix
                                      ) : (
                                      // Duplicating disabledPrefix classes to make UI consistent
                                        <span className="mx-1">
                                          <FontAwesomeIcon icon={faPlus} />
                                        </span>
                                      )}
                                    </Col>
                                    <Col xs={11}>{processType.name}</Col>
                                  </Row>
                                </ListGroupItem>
                              )}
                            </DisabledByAccessInfoCheck>
                          ))}
                        </ListGroup>
                      ))}
                  </div>
                )}
              </Col>
              <Col xs={6} sm={6} lg={6}>
                <p className="pickList-p">Selected Steps</p>
                <Row>
                  <Col xs={{
                    span: 6,
                    offset: 6,
                  }}
                  >
                    <p>Specific Workstation</p>
                  </Col>
                </Row>
                {fetching ? (
                  <Loading />
                ) : (
                  <DragDropContext onDragEnd={onDragEnd}>
                    <div className="well picklist-well">

                      <ProcessTypes
                        selectedProcessTypes={selectedProcessTypes}
                        currentProcessStepPosition={currentProcessStepPosition}
                        workstationsByUri={workstationsByUri}
                        workstationsGroupedByType={workstationsGroupedByType}
                        handleSelectedOutsourceShipping={handleSelectedOutsourceShipping}
                        shippingTypes={shippingTypes}
                        selectWorkstationCheckboxChanged={selectWorkstationCheckboxChanged}
                        selectWorkstation={selectWorkstation}
                        removeSelectedAt={removeSelectedAt}
                      />
                    </div>
                  </DragDropContext>
                )}
              </Col>
            </Row>
          </Modal.Body>
          <Modal.Footer className="d-flex align-items-start">
            <Button onClick={onClose}>
              <FormattedMessage id="button.cancel" defaultMessage="Cancel" />
            </Button>
            <Button
              type="submit"
              variant="success"
              disabled={isWorkflowSubmitDisabled}
            >
              {sending ? (
                <Loading />
              ) : (
                <FormattedMessage id="button.save" defaultMessage="Save" />
              )}
            </Button>
          </Modal.Footer>
        </Form>
      </Modal>
      {
        showSplitConfirmationModal && (
          <SplitConfirmationModal
            selectedPiecesList={selectedPiecesList}
            selectedLineItemsData={selectedLineItemsData}
            handleClose={() => setShowSplitConfirmationModal(false)}
            callback={handleSplitConfirmationSubmit}
          />
        )
      }
    </>
  );
};

WorkflowPickListModal.defaultProps = {
  currentProcessStepPosition: null,
  fetching: false,
  sending: false,
  setChangeWorkflowNotes: () => {},
  notes: '',
  showChangeWorkflowNotes: false,
  isEditingDisabled: false,
  isAllPrintsSelected: true,
  selectedPiecesList: [],
  isPowderWorkflow: false,
  selectedLineItemsData: [],
};

WorkflowPickListModal.propTypes = {
  onClose: PropTypes.func.isRequired,
  currentProcessStepPosition: PropTypes.number,
  // currentWorkflowSteps requires list of process steps
  currentWorkflowSteps: PropTypes.arrayOf(PropTypes.shape({
    // URIs eg [/printer-type/, /shipping/, /post-processor-type/]
    uri: PropTypes.string.isRequired,
    workstation: PropTypes.string,
  })).isRequired,
  fetching: PropTypes.bool,
  printerTypes: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  postProcessorTypes: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  sending: PropTypes.bool,
  shippingTypes: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  show: PropTypes.bool.isRequired,
  onSubmit: PropTypes.func.isRequired,
  workstationsGroupedByType: PropTypes.shape({}).isRequired,
  workstationsByUri: PropTypes.objectOf(PropTypes.shape({
    name: PropTypes.string.isRequired,
    is_service: PropTypes.bool,
    shipping_direction: PropTypes.string,
  })).isRequired,
  setChangeWorkflowNotes: PropTypes.func,
  notes: PropTypes.string,
  showChangeWorkflowNotes: PropTypes.bool,
  processTypesByUri: PropTypes.objectOf(PropTypes.shape({})).isRequired,
  bureauUri: PropTypes.string.isRequired,
  isGeneralMFGLanguageEnabled: PropTypes.bool.isRequired,
  isEditingDisabled: PropTypes.bool,
  isAllPrintsSelected: PropTypes.bool,
  selectedPiecesList: PropTypes.arrayOf(PropTypes.shape({})),
  isPowderWorkflow: PropTypes.bool,
  selectedLineItemsData: PropTypes.arrayOf(PropTypes.shape({})),
};

export default WorkflowPickListModal;
