import { createSelector } from 'reselect';
import { getPredicate } from 'rapidfab/selectors/helpers/base';
import { getDocuments } from 'rapidfab/selectors/document';
import _filter from 'lodash/filter';

// eslint-disable-next-line import/prefer-default-export
export const getDocumentsForResourceUuid = createSelector(
  [getPredicate, getDocuments],
  (uuid, documents) => _filter(documents, { related_uuid: uuid, archived: null }),
);
