import React from 'react';
import PropTypes from 'prop-types';
import {
  ListGroupItem,
  Row,
  Col,
  Button,
  Tooltip,
  OverlayTrigger,
} from 'react-bootstrap';
import { getShortUUID } from 'rapidfab/utils/uuidUtils';
import Loading from 'rapidfab/components/Loading';
import { PROCESS_PROVIDER_STATUSES_MAP } from 'rapidfab/mappings';
import { FormattedMessage } from 'react-intl';
import { PROCESS_PROVIDER_STATUSES, ROUTES } from 'rapidfab/constants';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { getRouteURI } from 'rapidfab/utils/uriUtils';
import { Link } from 'react-router-dom';
import { faQuestionCircle } from '@fortawesome/free-solid-svg-icons';

const ProcessProvider = ({
  provider,
  processProvider,
  fetching,
  onRequest,
  onStatusChangeAction,
  isServiceProviderRole,
}) => {
  const showRequest = !isServiceProviderRole
    && (!processProvider || processProvider.status === PROCESS_PROVIDER_STATUSES.INACTIVE);
  const showDeactivate = !isServiceProviderRole
    && processProvider && processProvider.status === PROCESS_PROVIDER_STATUSES.ACTIVE;

  const showAccept = isServiceProviderRole
    && processProvider && processProvider.status === PROCESS_PROVIDER_STATUSES.PENDING;
  const showDeny = isServiceProviderRole
    && processProvider && processProvider.status === PROCESS_PROVIDER_STATUSES.PENDING;

  const showTooltip = isServiceProviderRole
    && processProvider && processProvider.status === PROCESS_PROVIDER_STATUSES.INACTIVE;

  const onRequestAction = () => {
    if (processProvider) {
      onStatusChangeAction(PROCESS_PROVIDER_STATUSES.PENDING);
    } else {
      onRequest(provider.uri);
    }
  };

  return (
    <ListGroupItem key={getShortUUID(provider.uuid)}>
      <Row>
        <Col xs={6} className="truncate-data">
          {
            isServiceProviderRole ?
              <span>{provider.source_bureau_name}</span>
              : (
                <Link to={getRouteURI(ROUTES.SERVICE_PROVIDER_EDIT, { uuid: provider.uuid }, {}, true)}>
                  {provider.name}
                </Link>
              )
          }
        </Col>
        { fetching && <Col xs={6} className="process-providers-item text-center"> <Loading /> </Col> }
        { !fetching && (
          <>
            <Col xs={3}>
              {(processProvider && processProvider.status)
                ? (
                  <FormattedMessage {...PROCESS_PROVIDER_STATUSES_MAP[processProvider.status]} />
                ) : (
                  <FormattedMessage id="notAvailable" defaultMessage="N/A" />
                )}
            </Col>
            <Col xs={3} className="process-providers-item">
              {showRequest && (
                <Button
                  block
                  variant="primary"
                  type="button"
                  onClick={onRequestAction}
                >
                  <span>Request</span>
                </Button>
              )}
              {showDeactivate && (
                <Button
                  block
                  variant="danger"
                  type="button"
                  onClick={() =>
                    onStatusChangeAction(PROCESS_PROVIDER_STATUSES.INACTIVE)}
                >
                  <span>Deactivate</span>
                </Button>
              )}
              {showAccept && (
                <Button
                  block
                  variant="primary"
                  type="button"
                  onClick={() =>
                    onStatusChangeAction(PROCESS_PROVIDER_STATUSES.ACTIVE)}
                >
                  <span>Accept</span>
                </Button>
              )}
              {showDeny && (
                <Button
                  block
                  variant="danger"
                  type="button"
                  onClick={() =>
                    onStatusChangeAction(PROCESS_PROVIDER_STATUSES.INACTIVE)}
                >
                  <span>Deny</span>
                </Button>
              )}
              {showTooltip && (
                <OverlayTrigger
                  placement="top"
                  overlay={(
                    <Tooltip>
                      Contact {provider.source_bureau_name} to activate this service
                    </Tooltip>
                  )}
                >
                  <FontAwesomeIcon icon={faQuestionCircle} />
                </OverlayTrigger>
              )}
            </Col>
          </>
        )}
      </Row>
    </ListGroupItem>
  );
};

ProcessProvider.propTypes = {
  provider: PropTypes.shape({
    source_bureau_name: PropTypes.string,
    name: PropTypes.string.isRequired,
    uuid: PropTypes.string,
    uri: PropTypes.string,
  }).isRequired,
  processProvider: PropTypes.shape({
    status: PropTypes.string,
    uuid: PropTypes.string,
  }),
  isServiceProviderRole: PropTypes.bool.isRequired,
  onRequest: PropTypes.func.isRequired,
  onStatusChangeAction: PropTypes.func.isRequired,
  fetching: PropTypes.bool.isRequired,
};

ProcessProvider.defaultProps = {
  processProvider: null,
};

export default ProcessProvider;
