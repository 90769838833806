import _find from 'lodash/find';
import RouterContext from 'rapidfab/context/RouterContext';
import { pluralWord } from 'rapidfab/utils/stringUtils';
import React, { useEffect, useState, useCallback, useMemo, useContext } from 'react';
import PropTypes from 'prop-types';
import UploadFileModalProgressBar from 'rapidfab/components/modals/UploadFileModal';
import { formatEstimatedFileLoaderUploadModelTime, updateFileLoaderUploadingProgress } from 'rapidfab/utils/fileUtils';

const UploadFileModalProgressBarContainer = ({ queue, isModalView }) => {
  const [uploadsState, setUploadsState] = useState([]);

  useEffect(() => {
    setUploadsState(currentUploadsState => queue.map(fileUpload => {
      const { id, file, percent } = fileUpload;
      const uploadState = _find(currentUploadsState, { id }) || {
        id,
        file,
        percent: 0,
        uploadStarted: false,
        startTime: null,
        remainingTime: null,
      };

      return {
        ...uploadState,
        percent,
        uploadStarted: percent > 0 || uploadState.uploadStarted,
        startTime: percent > 0 && !uploadState.startTime ? Date.now() : uploadState.startTime,
        remainingTime: uploadState.uploadStarted && uploadState.startTime
          ? updateFileLoaderUploadingProgress(uploadState.startTime, percent)
          : null,
      };
    }));
  }, [queue]);

  const handleBeforeUnload = useCallback(event => {
    if (queue.some(({ uploading }) => uploading)) {
      event.preventDefault();
      // eslint-disable-next-line no-param-reassign
      event.returnValue = '';
    }
  }, [queue]);

  useEffect(() => {
    window.addEventListener('beforeunload', handleBeforeUnload);
    return () => window.removeEventListener('beforeunload', handleBeforeUnload);
  }, [handleBeforeUnload]);

  const uploadingState = useMemo(() => uploadsState.map(upload => ({
    ...upload,
    formattedTime: formatEstimatedFileLoaderUploadModelTime(upload.remainingTime, upload.percent, upload.uploadStarted),
  })), [uploadsState]);

  const uploadingHeaderTitle = useMemo(() => {
    const uploadingFiles = queue.filter(({ percent }) => percent !== 100);
    return !uploadingFiles.length ?
      `${queue.length} ${pluralWord('upload', queue)} complete` :
      `Uploading ${uploadingFiles.length} ${pluralWord('file', uploadingFiles)}`;
  }, [queue]);

  const { hideFileLoader, isFileLoaderCollapsed, toggleCollapsed } = useContext(RouterContext);

  const allFilesUploaded = queue.every(({ percent }) => percent === 100);

  return (
    <UploadFileModalProgressBar
      uploadingState={uploadingState}
      isModalView={isModalView}
      uploadingHeaderTitle={uploadingHeaderTitle}
      hideFileLoader={hideFileLoader}
      toggleCollapsed={toggleCollapsed}
      isCollapsed={isFileLoaderCollapsed}
      allFilesUploaded={allFilesUploaded}
    />
  );
};

UploadFileModalProgressBarContainer.propTypes = {
  queue: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.string,
    file: PropTypes.shape({
      name: PropTypes.string,
    }),
    percent: PropTypes.number,
    uploading: PropTypes.bool,
  })).isRequired,
  isModalView: PropTypes.bool,
};

UploadFileModalProgressBarContainer.defaultProps = {
  isModalView: false,
};

export default UploadFileModalProgressBarContainer;
