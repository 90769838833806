import useFetchSingleResource from 'rapidfab/hooks/useFetchSingleResource';
import _invert from 'lodash/invert';
import { API_RESOURCES } from 'rapidfab/constants/resources';

const WrappedUseFetchData = (resource, configuration, args) => {
  const data = useFetchSingleResource({ resource, configuration, ...args });
  return data;
};

/**
 * Master useFetchMultipleResources hook for LIST and GET requests on resources.
 *
 *
 * @example
 * // In this example, we see multiple resources being fetched with minimal parameters being passed in.
 * const resourcesList = useFetchMultipleResources(
    [
      { resource: API_RESOURCES.PRINT},
      // As you can see, the only mandatory parameter is the resource to fetch, all others are optional.
      { resource: API_RESOURCES.LINE_ITEM, configuration: lineITemExampleURI, method: 'get' },
      { resource: API_RESOURCES.PRINTER, configuration: [{}, { limit: 20 }] },
      { resource: API_RESOURCES.WORKFLOW, configuration: [{}, { limit: 30 }], selector: Selectors.getWorkflowsByUuid },
    ];
  );
 *
 *
 * @example
 * // In this example, we see multiple resources being fetched with most/all parameters being passed in.
 * const resourcesList = useFetchMultipleResources(
 *      [
 *          {
 *              resource: API_RESOURCES.LINE_ITEM,
 *              selector: state => Selectors.myCoolSelector(state, myParameter),
 *              configuration: [{ uri: myQuery }, { limit: 10 }, {}, {}, true],
 *              shouldFetch: false,
 *              method: 'list',
 *          },
 *          {
 *              resource: API_RESOURCES.PRINTER,
 *              selector: state => Selectors.myCoolSelector(state, myParameter),
 *              configuration: [{ uri: myQuery }, { limit: 20 }, {}, {}, true],
 *              shouldFetch: myBoolean,
 *              method: 'list'
 *          },
 *          {
 *              resource: API_RESOURCES.WORKFLOW,
 *              selector: state => Selectors.myCoolSelector(state, myParameter),
 *              configuration: [{ uri: myQuery }, { limit: 30 }, {}, {}, true],
 *              shouldFetch: myBoolean,
 *              method: 'list'
 *          },
 *      ]
 * )
 *
 * @param {Object[]} resourceEndpointsConfig
 * @param {string} resourceEndpointsConfig.resource
 * @param {function(state:Object)} resourceEndpointsConfig.selector
 * @param {'list' | 'get'} resourceEndpointsConfig.method
 * @param {Object} resourceEndpointsConfig.configuration
 * @param {Object} resourceEndpointsConfig.configuration.filters
 * @param {Object} resourceEndpointsConfig.configuration.page
 * @param {Object} resourceEndpointsConfig.configuration.searchParams
 * @param {Object} resourceEndpointsConfig.configuration.queryParams
 * @param {bool} resourceEndpointsConfig.configuration.forced
 * @param {number} resourceEndpointsConfig.configuration.apiVersion
 * @param {Object} resourceEndpointsConfig.configuration.config
 *
 * @returns {FetchDataHookResult[]}
 */
const useFetchMultipleResources = resourceEndpointsConfig => {
  const resourcesDict = {};
  const getApiResourcesByValueKey = _invert(API_RESOURCES);

  resourceEndpointsConfig.forEach(({ resource, configuration, ...args }) => {
    resourcesDict[getApiResourcesByValueKey[resource]] = WrappedUseFetchData(resource, configuration, args);
  },
  );
  return resourcesDict;
};

export default useFetchMultipleResources;
