import { createSelector } from 'reselect';
import _map from 'lodash/map';
import _find from 'lodash/find';
import _filter from 'lodash/filter';
import _keyBy from 'lodash/keyBy';
import * as baseStateSelectors from 'rapidfab/selectors/baseStateSelectors';
import _isEmpty from 'lodash/isEmpty';
import { getStateResources, getPredicate } from 'rapidfab/selectors/helpers/base';

export const getShoppingCarts = createSelector(
  [baseStateSelectors.getStateShoppingCarts, getStateResources],
  (uuids, resources) => _map(uuids, uuid => resources[uuid]),
);

export const getShoppingCartsByUri = createSelector([getShoppingCarts], shoppingCarts =>
  _keyBy(shoppingCarts, 'uri'),
);

export const getShoppingCartItems = createSelector(
  [baseStateSelectors.getStateShoppingCartItems, getStateResources],
  (uuids, resources) => _map(uuids, uuid => resources[uuid]),
);

export const getShoppingCartsForUuid = createSelector(
  [getPredicate, getShoppingCarts],
  (uuid, shoppingCarts) => _find(shoppingCarts, ({ uuid })),
);

export const getShoppingCartForOrder = createSelector(
  [getPredicate, getShoppingCarts],
  (orderUri, shoppingCarts) => _find(shoppingCarts, ({ order: orderUri })),
);

export const getShoppingCartItemsForShoppingCartUri = createSelector(
  [getPredicate, getShoppingCartItems],
  (shoppingCartUri, shoppingCartItems) => _filter(shoppingCartItems, ({ shopping_cart: shoppingCartUri })),
);

export const getShoppingCartsFilterableByQuery = createSelector(
  [getPredicate, getShoppingCarts],
  (filterQuery, shoppingCarts) => {
    if (!_isEmpty(filterQuery)) {
      return _filter(shoppingCarts, filterQuery);
    }

    return shoppingCarts;
  },
);
