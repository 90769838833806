import { ListGroup } from 'react-bootstrap';
import React from 'react';
import PropTypes from 'prop-types';
import { Draggable, Droppable } from 'react-beautiful-dnd';
import { PROCESS_STEP_SHIPPING_DIRECTION } from 'rapidfab/constants';
import OutsourceShippingStep from './OutsourceShippingStep';
import ProcessType from './ProcessType';

const ProcessTypes = ({
  selectedProcessTypes,
  currentProcessStepPosition,
  workstationsByUri,
  workstationsGroupedByType,
  handleSelectedOutsourceShipping,
  shippingTypes,
  removeSelectedAt,
  selectWorkstationCheckboxChanged,
  selectWorkstation,
}) => (
  <Droppable droppableId="droppable">
    {provided => (
      <div {...provided.droppableProps} ref={provided.innerRef}>
        <ListGroup>
          {selectedProcessTypes.map((processType, index) => {
            const isOutsourceShipping = processType.shippingDirection
              && [
                PROCESS_STEP_SHIPPING_DIRECTION.SERVICE_PROVIDER_TO_BUREAU,
                PROCESS_STEP_SHIPPING_DIRECTION.BUREAU_TO_SERVICE_PROVIDER,
              ].includes(processType.shippingDirection);

            // Process step can't be edited when currentProcessStepPosition lower than index
            // Index starts from 0, but current step position from 1
            const processStepPosition = (index + 1);
            const isDisabled = currentProcessStepPosition && currentProcessStepPosition >= processStepPosition;

            const selectedWorkstation = workstationsByUri[processType.workstationUri];
            const isOutsourceWorkstation = selectedWorkstation && selectedWorkstation.is_service;

            let toShippingStep = null;
            let fromShippingStep = null;
            if (isOutsourceWorkstation) {
              const previousStep = selectedProcessTypes[index - 1];
              const nextStep = selectedProcessTypes[index + 1];
              if (previousStep && previousStep.shippingDirection
                === PROCESS_STEP_SHIPPING_DIRECTION.BUREAU_TO_SERVICE_PROVIDER) {
                toShippingStep = previousStep;
              }

              if (nextStep && nextStep.shippingDirection
                  === PROCESS_STEP_SHIPPING_DIRECTION.SERVICE_PROVIDER_TO_BUREAU) {
                fromShippingStep = nextStep;
              }
            }

            const draggableKey = `dragId${processType.id}${index}`;
            const draggableId = `dragId${processType.name}${index}`;
            if (isOutsourceShipping) {
              // Outsource shipping steps are rendered with the Outsource process step.
              const styleTop = processType.shippingDirection === PROCESS_STEP_SHIPPING_DIRECTION.BUREAU_TO_SERVICE_PROVIDER ? '80px' : '-80px';
              return (
                <Draggable key={draggableKey} draggableId={draggableId} index={index}>
                  {provided => (
                    <div
                      ref={provided.innerRef}
                      {...provided.draggableProps}
                      {...provided.dragHandleProps}
                      style={{
                        ...provided.draggableProps.style,
                        position: 'relative',
                        top: styleTop,
                      }}
                    />
                  )}
                </Draggable>
              );
            }

            return (
              <Draggable key={draggableKey} draggableId={draggableId} index={index} isDragDisabled={isDisabled}>
                {provided => (
                  <div ref={provided.innerRef} {...provided.draggableProps} {...provided.dragHandleProps}>
                    <>
                      {isOutsourceWorkstation && (
                        <OutsourceShippingStep
                          key={`outsource-shipping-process-step-${index - 1}`}
                          processStep={toShippingStep}
                          index={index - 1}
                          shippingDirection={PROCESS_STEP_SHIPPING_DIRECTION.BUREAU_TO_SERVICE_PROVIDER}
                          handleSelectedOutsourceShipping={handleSelectedOutsourceShipping}
                          shippingTypes={shippingTypes}
                        />
                      )}
                      <ProcessType
                        processType={processType}
                        index={index}
                        isDisabled={isDisabled}
                        removeSelectedAt={removeSelectedAt}
                        selectWorkstationCheckboxChanged={selectWorkstationCheckboxChanged}
                        selectWorkstation={selectWorkstation}
                        workstationsGroupedByType={workstationsGroupedByType}
                      />
                      {isOutsourceWorkstation && (
                        <OutsourceShippingStep
                          key={`outsource-shipping-process-step-${index + 1}`}
                          processStep={fromShippingStep}
                          index={index + 1}
                          shippingDirection={PROCESS_STEP_SHIPPING_DIRECTION.SERVICE_PROVIDER_TO_BUREAU}
                          handleSelectedOutsourceShipping={handleSelectedOutsourceShipping}
                          shippingTypes={shippingTypes}
                        />
                      )}
                    </>
                  </div>
                )}
              </Draggable>
            );
          },
          )}
        </ListGroup>
      </div>
    )}
  </Droppable>

);

ProcessTypes.defaultProps = {
  currentProcessStepPosition: null,
};

ProcessTypes.propTypes = {
  selectedProcessTypes: PropTypes.arrayOf(PropTypes.shape({
    shippingDirection: PropTypes.string,
  })).isRequired,
  currentProcessStepPosition: PropTypes.number,
  workstationsByUri: PropTypes.objectOf(PropTypes.shape({
    name: PropTypes.string.isRequired,
    is_service: PropTypes.bool,
    shipping_direction: PropTypes.string,
  })).isRequired,
  workstationsGroupedByType: PropTypes.shape({}).isRequired,
  handleSelectedOutsourceShipping: PropTypes.func.isRequired,
  removeSelectedAt: PropTypes.func.isRequired,
  selectWorkstationCheckboxChanged: PropTypes.func.isRequired,
  selectWorkstation: PropTypes.func.isRequired,
  shippingTypes: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
};

export default ProcessTypes;
