/* eslint-disable jsx-a11y/no-static-element-interactions */
import React from 'react';
import PropTypes from 'prop-types';

const styles = {
  defaultStep: {
    background: '#424858',
    color: '#fff',
    position: 'relative',
    display: 'inline-block',
    height: '40px',
    lineHeight: '40px',
    marginRight: '20px',
    padding: '0 20px 0 30px',
    marginBottom: '30px',
  },

  activeStep: {
    background: '#1CA8DD',
  },

  linkStep: {
    cursor: 'pointer',
  },

  rightTriangle: {
    content: '',
    position: 'absolute',
    bottom: 0,
    right: '-11px',
    width: '0',
    borderStyle: 'solid',
    borderWidth: '20px 0px 20px 11px',
    borderColor: 'transparent transparent transparent #424858',
  },

  rightTriangleActive: {
    borderColor: 'transparent transparent transparent #1CA8DD',
  },

  leftTriangle: {
    content: '',
    position: 'absolute',
    bottom: 0,
    left: '0px',
    width: '0',
    borderStyle: 'solid',
    borderWidth: '20px 0px 20px 11px',
    borderColor: 'transparent transparent transparent #252830',
  },
};

class StepperStep extends React.Component {
  constructor(props) {
    super(props);

    this.navigateTo = this.navigateTo.bind(this);
  }

  navigateTo() {
    const { step, link } = this.props;
    if (link && step.uri) {
      window.location = step.uri;
    }
  }

  render() {
    const { step, active, link } = this.props;
    let stepStyles = styles.defaultStep;
    let stepRightTriangleStyles = styles.rightTriangle;

    if (active) {
      stepStyles = { ...stepStyles, ...styles.activeStep };
      stepRightTriangleStyles = {

        ...stepRightTriangleStyles,
        ...styles.rightTriangleActive,
      };
    }

    if (link) {
      stepStyles = { ...stepStyles, ...styles.linkStep };
    }

    return (
      <div style={stepStyles} onClick={() => this.navigateTo()}>
        <span style={styles.leftTriangle} />
        {step.name}
        <span style={stepRightTriangleStyles} />
      </div>
    );
  }
}

StepperStep.defaultProps = {
  active: false,
  link: false,
};

StepperStep.propTypes = {
  step: PropTypes.shape({
    name: PropTypes.string,
    uri: PropTypes.string,
  }).isRequired,
  active: PropTypes.bool,
  link: PropTypes.bool,
};

export default StepperStep;
