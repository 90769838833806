import React from 'react';
import { faClose } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button, Modal } from 'react-bootstrap';
import { Form } from 'react-final-form';
import 'rapidfab/styles/componentStyles/custom-darken-modal.scss';
import { useDispatch } from 'react-redux';
import Actions from 'rapidfab/actions';
import { API_RESOURCES } from 'rapidfab/constants';
import PropTypes from 'prop-types';

const UnlinkAnalysisDialog = ({ analysisUuid, show, setShow }) => {
  const dispatch = useDispatch();

  const onSubmit = async () => {
    await dispatch(Actions.Api.nautilus[API_RESOURCES.ADDIGURU_ANALYSIS].put(analysisUuid, {
      run: null,
    }));
    await dispatch(Actions.Api.nautilus[API_RESOURCES.ADDIGURU_ANALYSIS].list({ run: null }));
    setShow(false);
  };

  return (
    <Modal
      bg="dark"
      show={show}
      backdrop="static"
      dialogClassName="custom-darken-modal"
    >
      <Form
        onSubmit={onSubmit}
        initialValues={{}}
        render={({ handleSubmit }) => (
          <form onSubmit={handleSubmit}>
            <Modal.Header>
              <Modal.Title className="w-100 d-flex align-items-center justify-content-between">
                <div className="d-flex align-items-center">
                  Unlink Addiguru Analysis from Run
                </div>
                <FontAwesomeIcon
                  icon={faClose}
                  onClick={() => setShow(false)}
                  tabIndex={0}
                  role="button"
                  className="custom-darken-modal-button"
                />
              </Modal.Title>
            </Modal.Header>
            <Modal.Body>
              Are you sure you want to unlink the Addiguru Analysis from this run?
            </Modal.Body>
            <Modal.Footer>
              <Button type="button" variant="primary" onClick={() => setShow(false)}>
                Cancel
              </Button>
              <Button type="submit" variant="danger">
                Unlink Analysis
              </Button>
            </Modal.Footer>
          </form>
        )}
      />
    </Modal>
  );
};

UnlinkAnalysisDialog.propTypes = {
  analysisUuid: PropTypes.string.isRequired,
  show: PropTypes.bool.isRequired,
  setShow: PropTypes.func.isRequired,
};

export default UnlinkAnalysisDialog;
