import React, { useEffect, useState } from 'react';
import { Button, Card, Col, Image, Row } from 'react-bootstrap';
import PropTypes from 'prop-types';
import Iframe from 'react-iframe';
import SmoothCollapse from 'react-smooth-collapse';
import NebumindModal from './NebumindModal';
import { nebumindAnalysisResourceType } from '../../../types';
import Loading from '../../Loading';

const IMPACT_LABELS = {
  LOW: 'Low',
  MEDIUM: 'Medium',
  HIGH: 'High',
};

const IMPACT_COLOR_MAPPINGS = {
  [IMPACT_LABELS.LOW]: '#75FF00',
  [IMPACT_LABELS.MEDIUM]: '#F5A301',
  [IMPACT_LABELS.HIGH]: '#F1120E',
};

const NebumindImpactLabel = ({ impact }) => {
  let impactLabel = IMPACT_LABELS.HIGH;

  if (impact === 0) {
    impactLabel = IMPACT_LABELS.LOW;
  } else if (impact < 50) {
    impactLabel = IMPACT_LABELS.MEDIUM;
  }
  return (
    <p
      style={{
        backgroundColor: IMPACT_COLOR_MAPPINGS[impactLabel],
        color: 'white',
        padding: '0 5px',
        borderRadius: 3,
        display: 'inline-block',
      }}
    >
      {impactLabel}
    </p>
  );
};

NebumindImpactLabel.propTypes = {
  impact: PropTypes.number.isRequired,
};

const Nebumind = ({ nebumind, isFetching }) => {
  const [showNebumindModal, setShowNebumindModal] = useState(false);
  const [collapsed, setCollapsed] = useState([]);

  useEffect(() => {
    if (nebumind?.indications) {
      setCollapsed(nebumind.indications.map(() => ({ isCollapsibleOpen: false })));
    }
  }, [nebumind]);

  const openNebumindModal = () => {
    setShowNebumindModal(true);
  };
  const onToggleCollapse = index => () => {
    if (collapsed.includes(index)) {
      setCollapsed(collapsed.filter(index_ => index_ !== index));
    } else {
      setCollapsed([...collapsed, index]);
    }
  };

  return (
    <div>
      <Card bg="dark" className="mb15">
        <Card.Header className="pd-exp inverse">
          Nebumind Summary
        </Card.Header>
        <div className="card-body-wrapper">
          <Card.Body className="pd-exp">
            {(isFetching || !nebumind) ? <Loading /> : (
              <Row>

                <Col md={6}>
                  <Iframe
                    width="100%"
                    height="400px"
                    title="3dpreview"
                    url={nebumind.visualization}
                  />
                </Col>

                <Col md={6}>
                  <div>
                    <h4>MPM Problematic Areas:</h4>
                    {
                      nebumind.indications.map((record, index) => {
                        const numericalValues = [
                          <p key="x"><b>X:</b> {record.x_min} - {record.x_max}</p>,
                          <p key="y"><b>Y:</b> {record.y_min} - {record.y_max}</p>,
                          <p key="z"><b>Z:</b> {record.z_min} - {record.z_max}</p>,
                          <p key="value"><b>Threshold:</b> {record.threshold}</p>,
                        ];

                        const isCollapsibleOpen = collapsed.includes(index);
                        const { nebumind_impact, threshold } = record;

                        /**
                         * REMOVE THIS AFTER DEMO!!!
                         */
                        let t_impact = nebumind_impact;
                        if (index === 0) {
                          t_impact = IMPACT_LABELS.HIGH;
                        }

                        return (
                          // eslint-disable-next-line react/no-array-index-key
                          <Card bg="dark mb15" key={index.toString()}>
                            <Card.Header className="pd-exp inverse">
                              <div
                                role="button"
                                tabIndex={0}
                                className="d-flex w-100 justify-content-between"
                              >
                                <div className="d-flex justify-content-between align-items-center">
                                  <p style={{ paddingRight: 10 }}><b>Threshold:</b> {threshold}</p>
                                  <p><b>Impact:</b> <NebumindImpactLabel impact={t_impact} /></p>
                                </div>
                                <Button
                                  onClick={onToggleCollapse(index)}
                                >
                                  {isCollapsibleOpen ? 'Hide Full Data' : 'Show Full Data'}
                                </Button>
                              </div>
                            </Card.Header>
                            {
                              <SmoothCollapse expanded={isCollapsibleOpen}>
                                <div className="card-body-wrapper">
                                  <Card.Body className="pd-exp">

                                    <>
                                      <Col md={6}>
                                        {/* Placeholder for graph ref */}
                                      </Col>
                                      <Col md={6}>
                                        {numericalValues}
                                        <b>Impact: <NebumindImpactLabel impact={nebumind_impact} /></b>
                                      </Col>
                                    </>

                                  </Card.Body>
                                </div>
                              </SmoothCollapse>
                            }

                          </Card>
                        );
                      })
                    }
                  </div>

                  <div style={{ float: 'left' }}>
                    <Button variant="primary" onClick={openNebumindModal}>
                      <div style={{ width: '130px' }}>
                        <Image
                          src="https://www.nebumind.com/wp-content/themes/fs-nebumind/assets/images/nebumind_logo.svg?v2"
                          alt="image of nebumind"
                        />
                      </div>
                    </Button>
                  </div>
                  <div style={{ float: 'right' }}>
                    <a href="https://beta.elementstech.io/report_nebumind.pdf" rel="noreferrer" target="_blank">
                      <Button variant="primary">
                        <div style={{ width: '130px' }}>
                          Download Report
                        </div>
                      </Button>
                    </a>
                  </div>

                </Col>

              </Row>
            )}

          </Card.Body>
        </div>

        {showNebumindModal && (
          <NebumindModal
            nebumindData={nebumind}
            onClose={() => {
              setShowNebumindModal(false);
            }}
          />
        )}
      </Card>
    </div>
  );
};

Nebumind.defaultProps = {
  nebumind: null,
};

Nebumind.propTypes = {
  nebumind: nebumindAnalysisResourceType,
  isFetching: PropTypes.bool.isRequired,
};

export default Nebumind;
