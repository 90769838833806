import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { Image } from 'react-bootstrap';
import FlowsLogo from 'rapidfab/images/flows-logo.svg';
import LoginImage1 from 'rapidfab/images/login-image-1.webp';
import LoginImage2 from 'rapidfab/images/login-image-2.webp';
import LoginImage3 from 'rapidfab/images/login-image-3.webp';
import LoginImage4 from 'rapidfab/images/login-image-4.webp';
import LoginImage5 from 'rapidfab/images/login-image-5.webp';
import LoginImage6 from 'rapidfab/images/login-image-6.webp';

const images = [LoginImage1, LoginImage2, LoginImage3, LoginImage4, LoginImage5, LoginImage6];

const LoginWrapper = ({
  mainText,
  subText,
  children,
}) => {
  const [currentImage, setCurrentImage] = useState(0);

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentImage(previousImage => (previousImage + 1) % images.length);
    }, 5000);

    return () => clearInterval(interval);
  }, []);

  return (
    <div className="authentise-login">
      <div className="authentise-login-left-block">
        <Image src={FlowsLogo} alt="Flows by Authentise" className="authentise-login-flows-logo" />
        <div className="authentise-login-form-container">
          <h1>{mainText}</h1>
          <p>{subText}</p>
          {children}
        </div>
      </div>
      <div className="authentise-login-right-block">
        <div
          className="authentise-login-right-block-image"
          style={{ backgroundImage: `url(${images[currentImage]})` }}
        />
        <div className="authentise-login-right-block-overlay" />
        <div className="authentise-login-right-block-text">
          <p className="authentise-login-right-block-text-main"><span className="authentise-login-right-block-text-main-bold">Authentise Flows</span> boosts manufacturing productivity with advanced, data-driven solutions.</p>
          <p className="authentise-login-right-block-text-secondary mb15">Flows combines machine data, shop floor apps, and third-party tools for a flexible and
            comprehensive workflow management system.
          </p>
          <p className="authentise-login-right-block-text-secondary mb0">Seamlessly manage both additive and non-additive manufacturing processes to achieve significant
            productivity gains and full traceability.
          </p>
        </div>
      </div>
    </div>
  );
};

LoginWrapper.propTypes = {
  mainText: PropTypes.string.isRequired,
  subText: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,
};

export default LoginWrapper;
