import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { Modal } from 'react-bootstrap';
import Loading from 'rapidfab/components/Loading';
import _map from 'lodash/map';
import ChecklistsForProcessSteps
  from 'rapidfab/components/modals/WorkflowChecklistsModal/ChecklistsForProcessSteps';
import SuggestionBlock from 'rapidfab/components/GuidelinesEngine/SuggestionBlock';
import {
  GUIDELINES_EXPRESSION_FIELD_TYPES,
} from 'rapidfab/constants';
import GuidelineSuggestionContext from 'rapidfab/context/GuidelineSuggestionContext';
import { getEndpointFromURI } from 'rapidfab/utils/uriUtils';

const WorkflowChecklistsModal = ({
  onClose,
  workflow,
  processSteps,
  workChecklistLinkings,
  fetching,
  workstationsByUri,
  children,
  onApplyGuidelineSuggestion,
  isGuidelineEngineFeatureEnabled,
  isDebugModeEnabled,
  workInstructionsReports,
  usersByUri,
  hideWorkInstructionBadge,
}) => {
  const guidelineSuggestionContext = useContext(GuidelineSuggestionContext);

  /* Render the following if there are no suggestions. */
  if (!guidelineSuggestionContext) {
    return (
      <Modal show onHide={onClose} style={{ width: '600px!important' }}>
        <Modal.Header closeButton>
          <Modal.Title>Production Workflow: {workflow?.name}</Modal.Title>
        </Modal.Header>
        {fetching
          ? <Modal.Body><Loading /></Modal.Body>
          : (
            <Modal.Body className="workflow-preview-modal">
              {_map(processSteps, (processStep, index) => (
                <ChecklistsForProcessSteps
                  processStep={processStep}
                  index={index}
                  workChecklistLinkings={workChecklistLinkings}
                  workstationsByUri={workstationsByUri}
                  workInstructionsReports={workInstructionsReports}
                  usersByUri={usersByUri}
                />
              ))}
              {children}
            </Modal.Body>
          )}
      </Modal>
    );
  }

  const { guidelineSuggestionsWithTemplateData } = guidelineSuggestionContext;

  const { onApplyWorkInstructionGuidelineSuggestion,
    onApplyProcessStepGuidelineSuggestion } = onApplyGuidelineSuggestion ?? {};

  const processStepSuggestions = guidelineSuggestionsWithTemplateData
    .filter(suggestion => (
      getEndpointFromURI(suggestion.source_guideline).endpointName === 'for-process-step'
    ));

  return (
    <Modal show onHide={onClose} style={{ width: '600px!important' }}>
      <Modal.Header closeButton>
        <Modal.Title>Production Workflow: {workflow?.name}</Modal.Title>
      </Modal.Header>
      {fetching
        ? <Modal.Body><Loading /></Modal.Body>
        : (
          <Modal.Body className="workflow-preview-modal">
            {processStepSuggestions.length ? (
              <SuggestionBlock
                existingProcessSteps={processSteps}
                suggestionType={GUIDELINES_EXPRESSION_FIELD_TYPES.PROCESS_STEP}
                suggestionData={processStepSuggestions}
                onEditSuggestionBeforeAdd={selectedGuidelineSuggestion =>
                  onApplyProcessStepGuidelineSuggestion(selectedGuidelineSuggestion, null)}
              />
            ) : null}
            {
              _map(processSteps, (processStep, index) => {
                const existingWorkInstructionsForProcessStep = workChecklistLinkings
                  .find(linking => linking.process_step === processStep.uri)?.work_checklist?.work_instructions;

                const workInstructionSuggestionsForProcessStep = guidelineSuggestionsWithTemplateData
                  .filter(suggestion => getEndpointFromURI(suggestion.source_guideline).endpointName === 'for-work-instruction' &&
                  suggestion.related_workstation_type_name === processStep.name);

                return ([
                  <ChecklistsForProcessSteps
                    isDebugModeEnabled={isDebugModeEnabled}
                    processStep={processStep}
                    index={index}
                    workChecklistLinkings={workChecklistLinkings}
                    workstationsByUri={workstationsByUri}
                    workInstructionsReports={workInstructionsReports}
                    usersByUri={usersByUri}
                    hideWorkInstructionBadge={hideWorkInstructionBadge}
                  />,
                  isGuidelineEngineFeatureEnabled && workInstructionSuggestionsForProcessStep.length ? (
                    <SuggestionBlock
                      existingWorkInstructions={existingWorkInstructionsForProcessStep}
                      suggestionType={GUIDELINES_EXPRESSION_FIELD_TYPES.WORK_INSTRUCTION}
                      suggestionData={workInstructionSuggestionsForProcessStep}
                      onEditSuggestionBeforeAdd={selectedGuidelineSuggestion => {
                        onApplyWorkInstructionGuidelineSuggestion(selectedGuidelineSuggestion, processStep);
                      }}
                    />
                  ) : null,
                ]);
              })
            }
            {children}
          </Modal.Body>
        )}
    </Modal>
  );
};

WorkflowChecklistsModal.defaultProps = {
  workflow: {},
  processSteps: [],
  workChecklistLinkings: [],
  children: null,
  workInstructionsReports: {},
  usersByUri: {},
  hideWorkInstructionBadge: false,
};

WorkflowChecklistsModal.propTypes = {
  workflow: PropTypes.shape({
    name: PropTypes.string,
  }),
  processSteps: PropTypes.arrayOf(PropTypes.shape({})),
  workChecklistLinkings: PropTypes.arrayOf(PropTypes.shape({})),
  onClose: PropTypes.func.isRequired,
  fetching: PropTypes.bool.isRequired,
  workstationsByUri: PropTypes.shape({}).isRequired,
  children: PropTypes.node,
  onApplyGuidelineSuggestion: PropTypes.func.isRequired,
  isGuidelineEngineFeatureEnabled: PropTypes.bool.isRequired,
  isDebugModeEnabled: PropTypes.bool.isRequired,
  workInstructionsReports: PropTypes.shape({}),
  usersByUri: PropTypes.shape({}),
  hideWorkInstructionBadge: PropTypes.bool,
};

export default WorkflowChecklistsModal;
