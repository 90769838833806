import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import Chart from 'rapidfab/components/chart';
import { ORDER_STATUS_COLOR_CODE_MAPPING } from '../../mappings';
import { ORDER_STATUS } from '../../constants/statuses';

const OrdersByStatusChart = ({ data }) => {
  const chartColors = [
    ORDER_STATUS_COLOR_CODE_MAPPING[ORDER_STATUS.CALCULATING_ESTIMATES],
    ORDER_STATUS_COLOR_CODE_MAPPING[ORDER_STATUS.CANCELLED],
    ORDER_STATUS_COLOR_CODE_MAPPING[ORDER_STATUS.CLONING],
    ORDER_STATUS_COLOR_CODE_MAPPING[ORDER_STATUS.CONFIRMED],
    ORDER_STATUS_COLOR_CODE_MAPPING[ORDER_STATUS.COMPLETE],
    ORDER_STATUS_COLOR_CODE_MAPPING[ORDER_STATUS.ERROR],
    ORDER_STATUS_COLOR_CODE_MAPPING[ORDER_STATUS.IN_PREPARATION],
    ORDER_STATUS_COLOR_CODE_MAPPING[ORDER_STATUS.NEW],
    ORDER_STATUS_COLOR_CODE_MAPPING[ORDER_STATUS.PENDING],
    ORDER_STATUS_COLOR_CODE_MAPPING[ORDER_STATUS.POST_PROCESSING],
    ORDER_STATUS_COLOR_CODE_MAPPING[ORDER_STATUS.PRINTING],
    ORDER_STATUS_COLOR_CODE_MAPPING[ORDER_STATUS.SHIPPING],
  ];

  const datasets = [
    {
      label: 'Status',
      backgroundColor: chartColors,
      data,
    },
  ];

  return (
    <Chart
      type="bar"
      data={{
        labels: [
          <FormattedMessage
            id="status.calculatingEstimates"
            defaultMessage="Calculating Estimates"
          />,
          <FormattedMessage id="status.cancelled" defaultMessage="Cancelled" />,
          <FormattedMessage
            id="status.cloning"
            defaultMessage="Cloning"
          />,
          <FormattedMessage id="status.confirmed" defaultMessage="Confirmed" />,
          <FormattedMessage id="status.complete" defaultMessage="Complete" />,
          <FormattedMessage id="status.error" defaultMessage="Error" />,
          <FormattedMessage id="status.inPreparation" defaultMessage="In Preparation" />,
          <FormattedMessage id="status.new" defaultMessage="New" />,
          <FormattedMessage id="status.pending" defaultMessage="Pending" />,
          <FormattedMessage id="status.postProcessing" defaultMessage="Post-Processing" />,
          <FormattedMessage id="status.printing" defaultMessage="Printing" />,
          <FormattedMessage id="status.shipping" defaultMessage="Shipping" />,
        ],
        datasets,
      }}
    />
  );
};

OrdersByStatusChart.propTypes = {
  data: PropTypes.arrayOf(PropTypes.number).isRequired,
};

export default OrdersByStatusChart;
