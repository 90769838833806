import FormGroupField from 'rapidfab/components/forms/FormGroupField';
import {
  TOOLING_STOCK_ACTION_TYPES,
  TOOLING_STOCK_ALLOWED_ACTIONS,
  TOOLING_STOCK_VENDOR_DISALLOWED_ACTIONS,
} from 'rapidfab/constants';
import { FormattedMessageMappingOption } from 'rapidfab/i18n';
import { TOOLING_STOCK_ACTION_TYPES_MAP } from 'rapidfab/mappings';
import React from 'react';
import PropTypes from 'prop-types';
import { Button, Row, Modal, FormGroup, FormLabel } from 'react-bootstrap';
import { FormControlSelect } from 'rapidfab/components/formTools';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { FormattedMessage } from 'react-intl';
import { Form, Field } from 'react-final-form';
import { finalFormInputTypes } from 'rapidfab/types';
import Loading from 'rapidfab/components/Loading';
import {
  faClose,
  faPlus,
  faTableList,
} from '@fortawesome/free-solid-svg-icons';

const ToolingStockActionModal = ({
  toolingStock,
  show,
  onSubmit,
  submitting,
  handleCloseModal,
  locations,
  subLocations,
  subLocationsFetching,
  onLocationChange,
  setLocationActionType,
  locationsFetching,
  isVendorUser,
}) => {
  const renderActionAdditionalFields = values => {
    const action = values.action_type;
    switch (action) {
      case TOOLING_STOCK_ACTION_TYPES.RECORD_TESTS:
        return (
          <Field
            name="test_results"
            type="text"
            required
            render={props => (
              <FormGroupField
                {...props.input}
                as="textarea"
                required
                name="test_results"
                controlId="uxTestResults"
                label="Test Results"
              />
            )}
          />
        );
      case TOOLING_STOCK_ACTION_TYPES.MAKE_READY_FOR_USE: // fallthrough
      case TOOLING_STOCK_ACTION_TYPES.RELOCATE:
        return locationsFetching ? (
          <Loading className="mb5 d-flex" />
        ) : (
          <>
            <FormGroup controlId="uxLocation" className="form-group">
              <FormLabel>
                <FormattedMessage
                  id="field.location"
                  defaultMessage="Location"
                />
                : *
              </FormLabel>
              <Field
                name="destination_location"
                type="select"
                required
                render={props => (
                  <FormControlSelect
                    {...props.input}
                    onChange={event => {
                      const uri = event.target.value;
                      onLocationChange(uri);
                      props.input.onChange(uri);
                    }}
                    required
                  >
                    <FormattedMessage
                      id="field.select"
                      defaultMessage="Select an option"
                    >
                      {text => (
                        <option value="" disabled>
                          {text}
                        </option>
                      )}
                    </FormattedMessage>
                    {locations.map(
                      location =>
                        location && (
                          <option key={location.uri} value={location.uri}>
                            {location.name}
                          </option>
                        ),
                    )}
                  </FormControlSelect>
                )}
              />
            </FormGroup>

            <FormGroup controlId="uxSubLocation" className="form-group">
              <FormLabel>
                <FormattedMessage
                  id="field.sub_location"
                  defaultMessage="Sub-Location"
                />
                :
                {subLocationsFetching && (
                  <Loading inline className="spacer-left" />
                )}
              </FormLabel>
              <Field
                name="destination_sub_location"
                type="select"
                disabled={!values.destination_location || subLocationsFetching}
                render={props => (
                  <FormControlSelect
                    {...props.input}
                    disabled={
                      !values.destination_location || subLocationsFetching
                    }
                  >
                    <FormattedMessage
                      id="field.select"
                      defaultMessage="Select an option"
                    >
                      {text => <option value="">{text}</option>}
                    </FormattedMessage>
                    {subLocations.map(
                      location =>
                        location && (
                          <option key={location.uri} value={location.uri}>
                            {location.name}
                          </option>
                        ),
                    )}
                  </FormControlSelect>
                )}
              />
            </FormGroup>
          </>
        );
      case TOOLING_STOCK_ACTION_TYPES.COMPLETE_REFURBISH:
        return (
          <Field
            name="test_data"
            type="text"
            required
            render={props => (
              <FormGroupField
                {...props.input}
                as="textarea"
                required
                name="test_data"
                controlId="uxTestData"
                label="Test Data"
              />
            )}
          />
        );
      default:
        return null;
    }
  };

  const getAvailableActions = () => {
    const available = TOOLING_STOCK_ALLOWED_ACTIONS[toolingStock?.status] ?? [];
    if (!isVendorUser) return available;
    // Remove disallowed vendor actions
    return available.filter(
      action => !TOOLING_STOCK_VENDOR_DISALLOWED_ACTIONS.includes(action),
    );
  };

  return (
    <Modal
      size="lg"
      show={show}
      onHide={handleCloseModal}
      scrollable
      backdrop="static"
      dialogClassName="custom-darken-modal"
    >
      <Form
        onSubmit={onSubmit}
        initialValues={{}}
        render={({ handleSubmit, values }) => (
          <form onSubmit={handleSubmit}>
            <Modal.Header>
              <Modal.Title className="w-100 d-flex align-items-center justify-content-between">
                <div className="d-flex align-items-center">
                  <p className="custom-darken-modal-title-text m-a-0">
                    <FontAwesomeIcon
                      className="spacer-right"
                      icon={faTableList}
                    />
                    <FormattedMessage
                      id="record.tooling_stock_action.create"
                      defaultMessage="Create Tool Action"
                    />
                  </p>
                </div>
                <FontAwesomeIcon
                  icon={faClose}
                  onClick={handleCloseModal}
                  tabIndex={0}
                  role="button"
                  className="custom-darken-modal-button"
                />
              </Modal.Title>
            </Modal.Header>
            <Modal.Body className="custom-darken-modal--body-scroll">
              <Row className="custom-darken-modal-custom-gutter">
                <FormGroup controlId="uxActionType" className="form-group">
                  <FormLabel>
                    <FormattedMessage
                      id="field.tooling_stock.action_type"
                      defaultMessage="Action Type"
                    />
                    : *
                  </FormLabel>
                  <Field
                    name="action_type"
                    render={props => (
                      <FormControlSelect
                        {...props.input}
                        onChange={event => {
                          const { value } = event.target;
                          props.input.onChange(value);
                          setLocationActionType(
                            value === TOOLING_STOCK_ACTION_TYPES.RELOCATE ||
                              value ===
                                TOOLING_STOCK_ACTION_TYPES.MAKE_READY_FOR_USE,
                          );
                        }}
                        required
                      >
                        <FormattedMessage
                          id="field.tooling_stock.selectAction"
                          defaultMessage="Select Action"
                        >
                          {text => <option value={null}>{text}</option>}
                        </FormattedMessage>
                        {getAvailableActions().map(toolingStockAction => (
                          <FormattedMessageMappingOption
                            key={toolingStockAction}
                            mapping={TOOLING_STOCK_ACTION_TYPES_MAP}
                            value={toolingStockAction}
                          />
                        ))}
                      </FormControlSelect>
                    )}
                  />
                </FormGroup>

                {renderActionAdditionalFields(values)}

                <Field
                  name="notes"
                  type="text"
                  render={props => (
                    <FormGroupField
                      {...props.input}
                      as="textarea"
                      name="notes"
                      controlId="uxNotes"
                      label={(
                        <FormattedMessage
                          id="field.notes"
                          defaultMessage="Notes"
                        />
                      )}
                    />
                  )}
                />
              </Row>
            </Modal.Body>
            <Modal.Footer>
              <Button type="submit" disabled={submitting} variant="success">
                {submitting ? (
                  <Loading />
                ) : (
                  <>
                    <FontAwesomeIcon icon={faPlus} className="spacer-right" />
                    <FormattedMessage
                      id="button.createResource"
                      defaultMessage="Create"
                    />
                  </>
                )}
              </Button>
            </Modal.Footer>
          </form>
        )}
      />
    </Modal>
  );
};

ToolingStockActionModal.propTypes = {
  toolingStock: PropTypes.shape({
    uri: PropTypes.string.isRequired,
    status: PropTypes.string.isRequired,
  }).isRequired,
  onSubmit: PropTypes.func.isRequired,
  input: finalFormInputTypes.isRequired,
  submitting: PropTypes.bool.isRequired,
  handleCloseModal: PropTypes.func.isRequired,
  show: PropTypes.bool.isRequired,
  locations: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  subLocations: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  onLocationChange: PropTypes.func.isRequired,
  subLocationsFetching: PropTypes.bool,
  setLocationActionType: PropTypes.func.isRequired,
  locationsFetching: PropTypes.bool.isRequired,
  isVendorUser: PropTypes.bool.isRequired,
};
ToolingStockActionModal.defaultProps = {
  subLocationsFetching: false,
};

export default ToolingStockActionModal;
