import { faArrowLeftLong, faArrowRightLong } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import PropTypes from 'prop-types';
import FormControlField from 'rapidfab/components/forms/FormControlField';
import Loading from 'rapidfab/components/Loading';
import { ROUTES } from 'rapidfab/constants';
import React from 'react';
import {
  Button,
} from 'react-bootstrap';
import { Form } from 'react-final-form';
import { FormattedMessage } from 'rapidfab/i18n';
import { Link } from 'react-router-dom';

const ForgotPasswordForm = ({
  resetConfirmationStateProps,
  onResetPasswordSubmitForm,
  isResetPasswordLoading,
}) => {
  const fields = {
    email: 'email',
  };

  const {
    resetPasswordConfirmationState,
    setResetPasswordConfirmationState,
  } = resetConfirmationStateProps;

  return (
    <>
      {resetPasswordConfirmationState.confirmationSent ? (
        <Button
          block
          size="sm"
          type="submit"
          className="authentise-login-submit-button"
          variant="outline-info"
          onClick={() =>
            setResetPasswordConfirmationState({ ...resetPasswordConfirmationState, confirmationSent: false })}
        >
          {isResetPasswordLoading
            ? <Loading inline />
            : (
              <>
                <FontAwesomeIcon icon={faArrowLeftLong} className="spacer-right" />
                <FormattedMessage id="back" defaultMessage="Back" />
              </>
            )}
        </Button>
      ) : (
        <Form onSubmit={onResetPasswordSubmitForm}>
          {({ handleSubmit }) => (
            <form onSubmit={handleSubmit}>
              <FormControlField
                name={fields.email}
                required
                type="email"
                className="mb25"
                placeholder="Email: *"
              />

              <div className="btn-group-vertical">

                <Button
                  block
                  size="sm"
                  type="submit"
                  variant="outline-warning"
                  className="authentise-login-submit-button"
                >
                  {isResetPasswordLoading
                    ? <Loading inline />
                    : (
                      <>
                        <FontAwesomeIcon icon={faArrowRightLong} className="spacer-right" />
                        <FormattedMessage id="button.submit" defaultMessage="Submit" />
                      </>
                    )}
                </Button>
              </div>
            </form>
          )}
        </Form>
      )}
      <div
        className="mt15 text-center"
      >
        <Link to={ROUTES.LOGIN}>
          <FormattedMessage id="field.backToSignIn" defaultMessage="Back to Sign In" />
        </Link>
      </div>
    </>
  );
};

ForgotPasswordForm.propTypes = {
  resetConfirmationStateProps: PropTypes.shape({
    resetPasswordConfirmationState: PropTypes.shape({
      confirmationSent: PropTypes.bool,
      displayEmail: PropTypes.string,
    }).isRequired,
    setResetPasswordConfirmationState: PropTypes.func.isRequired,
  }).isRequired,
  onResetPasswordSubmitForm: PropTypes.func.isRequired,
  isResetPasswordLoading: PropTypes.bool.isRequired,
};

export default ForgotPasswordForm;
