import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Actions from 'rapidfab/actions';
import {
  getLocationFilter,
  getOrders,
  getPrints,
  getProcessSteps,
  isFeatureEnabled,
} from 'rapidfab/selectors';
import Prints from 'rapidfab/components/plan/prints';
import { API_RESOURCES, FEATURES, LIST_BY_URIS_CHUNK_SIZE, PAGINATION_IGNORE_DEFAULT_LIMIT } from 'rapidfab/constants';
import _map from 'lodash/map';
import _chunk from 'lodash/chunk';
import _uniq from 'lodash/uniq';

const PrintsContainer = props => {
  const printApi = useSelector(state =>
    state.ui.nautilus[API_RESOURCES.PRINT].list);
  const orders = useSelector(getOrders);
  const allPrints = useSelector(getPrints);
  const processSteps = useSelector(getProcessSteps);
  const printProcessSteps = processSteps.filter(step =>
    step.workstation_type_uri.includes('printer-type'),
  );
  const printingStepPrints = allPrints.filter(print =>
    printProcessSteps.some(step => step.uri === print.process_step),
  );
  const locationFilter = useSelector(getLocationFilter);
  const filteredPrints = printingStepPrints.filter(print => {
    if (locationFilter) {
      return print.location === locationFilter.location;
    }
    return true;
  });

  const gridData = filteredPrints.map(print => {
    const printOrder = orders.find(order => order.uri === print.order);
    const result = { ...print };
    if (printOrder) {
      result.dueDate = printOrder.due_date;
      result.customerName = printOrder.customer_name;
    }
    return result;
  });

  const isOrderDueDateFeatureEnabled = useSelector(state => isFeatureEnabled(state, FEATURES.ORDER_DUE_DATE));

  const fetching = printApi.count === 0 || (printApi.count === 1 && printApi.fetching);

  const dispatch = useDispatch();

  const onInitialize = async () => {
    const printsResponse = await dispatch(Actions.Api.nautilus[API_RESOURCES.PRINT].list(
      {
        // Showing ALL prints on the page, including the ones,
        // that were Remanufactured into some other Prints
        work_needed: false,
      },
      { limit: PAGINATION_IGNORE_DEFAULT_LIMIT },
    ));

    const orderUris = _uniq(_map(printsResponse.json.resources, 'order'));
    _chunk(orderUris, LIST_BY_URIS_CHUNK_SIZE).forEach(orderUrisChunk => {
      dispatch(Actions.Api.nautilus[API_RESOURCES.ORDER].list(
        { uri: orderUrisChunk },
        {},
        {},
        {},
        true, // Force update
      ));
    });
    dispatch(Actions.Api.nautilus[API_RESOURCES.PROCESS_STEP].list());
    dispatch(Actions.Api.nautilus[API_RESOURCES.LOCATION].list());
  };

  const handleOnChange = location =>
    dispatch(Actions.LocationFilter.setLocation(location));

  useEffect(() => {
    (async () => {
      await onInitialize();
    })();
  }, []);

  return (
    <Prints
      {...props}
      fetching={fetching}
      gridData={gridData}
      handleOnChange={handleOnChange}
      isOrderDueDateFeatureEnabled={isOrderDueDateFeatureEnabled}
    />
  );
};

export default PrintsContainer;
