import _map from 'lodash/map';
import _has from 'lodash/has';
import { createSelector } from 'reselect';
import _concat from 'lodash/concat';
import _find from 'lodash/find';
import { getModelers } from 'rapidfab/selectors/modelers';
import { getPostProcessorTypes } from 'rapidfab/selectors/postProcessorType';
import { getPostProcessors } from 'rapidfab/selectors/postProcessor';
import { getPrinters } from 'rapidfab/selectors/printer';
import { getPrinterTypes } from 'rapidfab/selectors/printerType';

export const getMachinesForQueues = createSelector(
  [getPrinters, getPostProcessors, getModelers],
  (printers, postProcessors, modelers) => _map(_concat(printers, postProcessors), machine => {
    const modeler = _find(modelers, { uri: machine.modeler });
    return { ...machine, status: modeler && modeler.status };
  }),
);

export const getMachinesWithTypesForQueues = createSelector(
  [getMachinesForQueues,
    getPostProcessorTypes,
    getPrinterTypes],
  (machines, postProcessorTypes, printerTypes) =>
    _map(machines, machineMapItem => {
      const machine = machineMapItem;

      // If machine is a printer
      if (_has(machineMapItem, 'printer_type')) {
        machine.printer_type = _find(printerTypes, { uri: machineMapItem.printer_type });
      }

      // If machine is a post-processor
      if (_has(machineMapItem, 'post_processor_type')) {
        machine.post_processor_type = _find(postProcessorTypes, { uri: machineMapItem.post_processor_type });
      }

      return machine;
    }),
);
