import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Badge, Col, Row } from 'react-bootstrap';
import { FormattedMessage } from 'rapidfab/i18n';
import { expertConversionResourceType } from 'rapidfab/types';
import { EXPERT_CONVERSION_STATUSES_MAP } from 'rapidfab/mappings';
import { EXPERT_CONVERSION_STATUSES } from 'rapidfab/constants';
import { faUpload } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { fileNameHasPotentialNullByte } from 'rapidfab/utils/fileUtils';
import Alert from 'rapidfab/utils/alert';
import FileInput from 'rapidfab/components/records/order/edit/FileInput';

const ExpertConversion = ({
  expertConversion,
  onUploadModel,
  isConversionExpert,
}) => {
  const [isUploadingModel, setIsUploadingModel] = useState(false);

  const handleModelUpload = event => {
    const modelFile = event.target.files[0];

    if (fileNameHasPotentialNullByte(modelFile.name)) {
      Alert.error('The filename does not meet the requirements. Please rename the file and try again.');
      return;
    }
    setIsUploadingModel(true);
    onUploadModel(modelFile)
      .finally(() => {
        setIsUploadingModel(false);
      });
  };

  const expertConversionSubmitted = expertConversion?.status === EXPERT_CONVERSION_STATUSES.SUBMITTED;

  return (
    <Row>
      <Col xs={12}>
        {!!expertConversion && (
          <p>
            <b className="spacer-right">Conversion Status:</b>
            <Badge style={{ fontSize: 15 }} className="spacer-left hawking-badge-secondary">
              {
                EXPERT_CONVERSION_STATUSES_MAP[expertConversion.status]
                  ? <FormattedMessage {...EXPERT_CONVERSION_STATUSES_MAP[expertConversion.status]} />
                  : <FormattedMessage id="notAvailable" defaultMessage="N/A" />
              }
            </Badge>
          </p>
        )}
        <div>
          {isConversionExpert && expertConversionSubmitted && (
            <div className="mt15">
              <FileInput
                buttonStyle="btn-primary"
                customContainerStyles="mt-2 text-center m-l-0"
                controlLabelStyle={{ width: '100%' }}
                disabled={isUploadingModel}
                chooseFileLabel={(
                  <div className="d-flex justify-content-center align-items-center text-left">
                    <FontAwesomeIcon icon={faUpload} className="spacer-right" />
                    <span>{isUploadingModel ? 'Uploading...' : 'Upload'}</span>
                  </div>
                )}
                variant="primary"
                handleFileChange={handleModelUpload}
              />
            </div>
          )}
        </div>

      </Col>
      <Col xs={12}>
        {
          // Show notes, if there are any (Error messages will be added to notes)
          expertConversion && expertConversion.notes
        }
      </Col>

    </Row>
  );
};

ExpertConversion.propTypes = {
  onUploadModel: PropTypes.func.isRequired,
  expertConversion: expertConversionResourceType,
  isConversionExpert: PropTypes.bool.isRequired,
};

ExpertConversion.defaultProps = {
  expertConversion: null,
};

export default ExpertConversion;
