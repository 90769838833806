import SlicerFileErrorTooltip from 'rapidfab/components/SlicerFileErrorTooltip';
import { SLICER_CONFIGURATION_FILE_STATUSES } from 'rapidfab/constants';
import React, { useRef, useState } from 'react';
import { useDispatch } from 'react-redux';
import { Card, FormControl, FormGroup, Button, Row } from 'react-bootstrap';
import { FormattedMessage } from 'rapidfab/i18n';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faExclamationCircle, faTimes, faTriangleExclamation } from '@fortawesome/free-solid-svg-icons';
import Actions from 'rapidfab/actions';
import alert from 'rapidfab/utils/alert';
import PropTypes from 'prop-types';

const SlicerConfigurationFile = ({ slicerConfig }) => {
  const dispatch = useDispatch();
  const fileInputRef = useRef(null);
  const [newFile, setNewFile] = useState(null);
  const slicerFileNotUploaded = slicerConfig?.status === SLICER_CONFIGURATION_FILE_STATUSES.PENDING_FILE_UPLOAD;
  const slicerFileFailed = slicerConfig?.status === SLICER_CONFIGURATION_FILE_STATUSES.FAILED;

  const handleFileInputClick = () => {
    fileInputRef.current.click();
  };

  const handleFileChange = async event => {
    const file = event.target.files[0];
    setNewFile(file);
  };

  const handleUpdateNewConfigFile = async () => {
    const documentLocation = slicerConfig?.slicerConfigUploadLocation;
    await dispatch(Actions.UploadModel.upload(documentLocation, newFile, newFile?.type));
    setNewFile(null);
    alert.success(<FormattedMessage id="toaster.slicerConfig.updated" defaultMessage="Slicer file succesfully uploaded." />);
  };

  return (
    <Card bg="dark">
      <Card.Header className="pd-exp inverse">
        Silcer Configuration File
        <SlicerFileErrorTooltip
          conditionToShow={slicerFileNotUploaded}
          text={`The slicer's setup file might be broken, not yet uploaded, or still uploading.
                  Please refresh the page to check if it finishes uploading.
                  If you are still having trouble, you can replace the file by clicking the button below.`}
          icon={faTriangleExclamation}
          color="#ffd500"
        />
        <SlicerFileErrorTooltip
          conditionToShow={slicerFileFailed}
          text={`The upload of the slicer's setup file was unsuccessful.
          Please try to re-upload by selecting the button below, or opt for a different file.`}
          icon={faExclamationCircle}
          color="#ff0000"
        />

      </Card.Header>
      <Card.Body className="card-body-wrapper">
        <Row>
          <FormGroup className="form-group" controlId="slicer_config">

            <FormControl

              type="file"
              name="content"
              ref={fileInputRef}
              className="visually-hidden"
              onChange={handleFileChange}
            />

          </FormGroup>

          {newFile && (
            <div className="m-b-md">

              {newFile.name}
              <button
                className="spacer-left btn-xs btn-danger mr15"
                type="button"
                onClick={() => setNewFile(null)}
                tabIndex={0}
              >
                <FontAwesomeIcon icon={faTimes} />
              </button>

            </div>
          )}
          <Button onClick={handleFileInputClick} variant="primary" className="mb-1">Replace Slicer Configuration</Button>
          {newFile && (<Button onClick={handleUpdateNewConfigFile} variant="success" className="mb-1">Update Slicer Configuration</Button>)}
        </Row>
      </Card.Body>

    </Card>
  );
};

SlicerConfigurationFile.propTypes = {
  slicerConfig: PropTypes.shape({
    slicerConfigUploadLocation: PropTypes.string.isRequired,
    status: PropTypes.string,
  }).isRequired,
};

export default SlicerConfigurationFile;
