import { API_RESOURCES,
  THREADS_API_RESOURCES,
  GUIDELINE_API_RESOURCES,
  MATERIAL_TEST_API_RESOURCES } from 'rapidfab/constants';
import MakeApi from './makeApi';

export const RESOURCES = {
  nautilus: Object.values(API_RESOURCES),
  threads_api: Object.values(THREADS_API_RESOURCES),
};

// Historically all resources were cached ones, and only some - non-cached.
// Making it reverse now, so that all the newly created resources will be non-cached, unless needed.
// TODO: 1) Remove all legacy-added cached resources from this list,
//  once those are tested to work properly as non-cached resources
// TODO: 2) In case any new/existing resources needs to be a cached one
//  - explain it spcifically via comment, etc.
export const CACHED_RESOURCES = [
  API_RESOURCES.BUREAU_SETTINGS,
  API_RESOURCES.BUREAU_INTAKE_SETTINGS,
  API_RESOURCES.BUREAU,
  API_RESOURCES.BUREAU_BRANDING,
  API_RESOURCES.COMMENT,
  API_RESOURCES.CURRENCY_CONVERSION,
  API_RESOURCES.CUSTOM_FIELD,
  API_RESOURCES.DOWNTIME,
  API_RESOURCES.FEATURE,
  API_RESOURCES.GROUPS,
  API_RESOURCES.INFILL_STRATEGY,
  API_RESOURCES.LABEL,
  API_RESOURCES.LABOR,
  API_RESOURCES.LAYOUT,
  API_RESOURCES.LINE_ITEM_NETFABB,
  API_RESOURCES.LINE_ITEM,
  API_RESOURCES.LINE_ITEM_QUOTE,
  API_RESOURCES.LOCATION,
  API_RESOURCES.MANUFACTURER,
  API_RESOURCES.MATERIAL,
  API_RESOURCES.MATERIAL_BATCH,
  API_RESOURCES.MATERIAL_BATCH_ACTION,
  API_RESOURCES.MATERIAL_BATCH_TRACEABILITY_REPORT,
  API_RESOURCES.MATERIAL_CONTAINER,
  API_RESOURCES.MATERIAL_LOT,
  API_RESOURCES.MATERIAL_LOT_ACTION,
  API_RESOURCES.MATERIAL_PURCHASE_ORDER,
  API_RESOURCES.MEMBERSHIP_BUREAU,
  API_RESOURCES.MEMBERSHIPS,
  API_RESOURCES.MODEL_LIBRARY,
  API_RESOURCES.MODEL,
  API_RESOURCES.MODELER,
  API_RESOURCES.OAUTH_TOKEN_AUTODESK_FORGE,
  API_RESOURCES.ORDER_QUOTE,
  API_RESOURCES.ORDER_PACKING_SLIP,
  API_RESOURCES.ORDER,
  API_RESOURCES.PERMISSIONS,
  API_RESOURCES.POST_PROCESSOR_TYPE,
  API_RESOURCES.PREP_TASK,
  API_RESOURCES.PRINT,
  API_RESOURCES.PRINTER_TYPE,
  API_RESOURCES.PRINTER,
  API_RESOURCES.PROCESS_PROVIDER,
  API_RESOURCES.PROCESS_STEP,
  API_RESOURCES.PROSPER_PRINTER_MATERIAL,
  API_RESOURCES.PROSPER,
  API_RESOURCES.CUSTOM_GROUP,
  API_RESOURCES.ROLE,
  API_RESOURCES.RUN_TRANSFORMATION,
  API_RESOURCES.RUN_QUEUE,
  API_RESOURCES.RUN,
  API_RESOURCES.RUN_MATERIAL,
  API_RESOURCES.USER_SSO,
  API_RESOURCES.SERVICE_PROVIDER_V1_ORDER,
  API_RESOURCES.SERVICE_PROVIDER_V1_LINE_ITEM,
  API_RESOURCES.SERVICE_PROVIDER,
  API_RESOURCES.SERVICE_PROVIDER_JOB,
  API_RESOURCES.SESSIONS,
  API_RESOURCES.SHIPMENT,
  API_RESOURCES.SHIPPING,
  API_RESOURCES.SPECIMEN,
  API_RESOURCES.STOCK,
  API_RESOURCES.SUPPORT_STRATEGY,
  API_RESOURCES.TRACEABILITY_REPORT,
  API_RESOURCES.WORKFLOW,
  API_RESOURCES.WORKSTATION,
  API_RESOURCES.WORK_CHECKLISTS_FOR,
  API_RESOURCES.WORK_INSTRUCTION,
  API_RESOURCES.SAMPLE,
  API_RESOURCES.SHOPPING_CART,
  API_RESOURCES.SHOPPING_CART_ITEM,
  API_RESOURCES.PAYMENT,
  API_RESOURCES.MACHINE_JOB_FILE,
  API_RESOURCES.INTEGRATION,
  API_RESOURCES.MODELER_PRINTING_PRESET,
  API_RESOURCES.THREADS_MESSAGES,
  GUIDELINE_API_RESOURCES.NAMED_EXPRESSION,
  GUIDELINE_API_RESOURCES.FOR_WORK_INSTRUCTION,
  GUIDELINE_API_RESOURCES.FOR_PROCESS_STEP,
  GUIDELINE_API_RESOURCES.FOR_LINE_ITEM,
  GUIDELINE_API_RESOURCES.COMPARATOR,
  MATERIAL_TEST_API_RESOURCES.MATERIAL_TEST_OPERATION,
  MATERIAL_TEST_API_RESOURCES.MATERIAL_TEST_INSTRUCTION,
  MATERIAL_TEST_API_RESOURCES.MATERIAL_TEST_INSTRUCTION_REPORT,
  MATERIAL_TEST_API_RESOURCES.MATERIAL_TEST_OPERATION_LINKING,
  MATERIAL_TEST_API_RESOURCES.MATERIAL_TEST_OPERATION_WORK_CHECKLISTS_FOR_MATERIAL,
  MATERIAL_TEST_API_RESOURCES.MATERIAL_TEST_OPERATION_WORK_CHECKLISTS_FOR_PANEL,
  MATERIAL_TEST_API_RESOURCES.MATERIAL_TEST_UNIT,
  MATERIAL_TEST_API_RESOURCES.MATERIAL_TEST_PANEL,
  THREADS_API_RESOURCES.THREADS,
];

export default MakeApi(RESOURCES);
