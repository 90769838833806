import { createSelector } from 'reselect';
import _keyBy from 'lodash/keyBy';
import { getLineItemActualsForLineItem } from 'rapidfab/selectors/helpers/lineItem';

// eslint-disable-next-line import/prefer-default-export
export const getProcessStepActualsForLineItemByProcessStepUri = createSelector(
  [getLineItemActualsForLineItem],
  lineItemActuals => (
    lineItemActuals
    && lineItemActuals.per_step_details
    && _keyBy(lineItemActuals.per_step_details, 'process_step')
  ),
);
