import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import Actions from 'rapidfab/actions';
import {
  ALLOWED_MODEL_EXTENSIONS,
  API_RESOURCES,
  FEATURES,
  FILE_EXTENSIONS,
  MODEL_LAYER_THICKNESS_SETTINGS,
  MODEL_LIBRARY_TYPES,
  MODEL_TYPES,
  MODEL_UNITS,
  ROUTES,
} from 'rapidfab/constants';
import { Modal } from 'react-bootstrap';
import Loading from 'rapidfab/components/Loading';
import { extractUuid } from 'rapidfab/utils/uuidUtils';
import ModelLibraryCreateModal from 'rapidfab/components/organize/ModelLibraryCreateModal';
import { getBaseMaterialsByUri, getBureau, getBureauUri, isFeatureEnabled } from 'rapidfab/selectors';
import _pick from 'lodash/pick';
import _set from 'lodash/set';
import _without from 'lodash/without';

import { MODEL_LIBRARY_CREATE_CONTAINER } from 'rapidfab/constants/forms';
import { connect, useSelector } from 'react-redux';
import { routeURIContains } from 'rapidfab/utils/uriUtils';
import { replaceFormValuesIntoSubDict } from 'rapidfab/utils/formHelpers';

const productCreateFields = _without(MODEL_LIBRARY_CREATE_CONTAINER.FIELDS, 'file_unit');
const specimenCreateFields = _without(MODEL_LIBRARY_CREATE_CONTAINER.FIELDS, 'file_unit', 'layer_thickness');

const ModelLibraryCreateContainer = props => {
  const {
    bureauUri,
    onInitialize,
    fetching,
    isHawkingUser,
  } = props;

  const specimenLibraryEnabled = useSelector(state => isFeatureEnabled(state, FEATURES.SPECIMEN_LIBRARY));
  const isIntegrationCastorFeatureEnabled = useSelector(state => isFeatureEnabled(state, FEATURES.INTEGRATION_CASTOR));
  const isDigitalDesignWarehouseEnabled =
    useSelector(state => isFeatureEnabled(state, FEATURES.DIGITAL_DESIGN_WAREHOUSE));
  const isBothDDWAndIntegrationCastorEnabled = isDigitalDesignWarehouseEnabled && isIntegrationCastorFeatureEnabled;
  const isRobozeDDWFeatureEnabled = useSelector(state => isFeatureEnabled(state, FEATURES.ROBOZE_DDW));
  const isPOCUKOrderFieldsFeatureEnabled = useSelector(
    state => isFeatureEnabled(state, FEATURES.POC_UK_ORDER_FIELDS),
  );

  useEffect(() => {
    // only need to onInitialize if hawking users to populate materials
    if (isHawkingUser) {
      onInitialize(bureauUri);
    }
  }, []);

  if (fetching) {
    return <Modal show><Loading className="mt15 mb15" /></Modal>;
  }

  return (
    <ModelLibraryCreateModal
      {...props}
      specimenLibraryEnabled={specimenLibraryEnabled}
      isBothDDWAndIntegrationCastorEnabled={isBothDDWAndIntegrationCastorEnabled}
      isRobozeDDWFeatureEnabled={isRobozeDDWFeatureEnabled}
      isPOCUKOrderFieldsFeatureEnabled={isPOCUKOrderFieldsFeatureEnabled}
    />
  );
};

ModelLibraryCreateContainer.propTypes = {
  bureauUri: PropTypes.string.isRequired,
  onInitialize: PropTypes.func.isRequired,
  fetching: PropTypes.bool.isRequired,
  isHawkingUser: PropTypes.bool.isRequired,
  onSuccessSubmit: PropTypes.func,
  onClose: PropTypes.func.isRequired,
  modelFiles: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string.isRequired,
    }),
  ).isRequired,
  customLibraryName: PropTypes.string,
  ownerUri: PropTypes.string,
};

ModelLibraryCreateContainer.defaultProps = {
  onSuccessSubmit: null,
  customLibraryName: null,
  ownerUri: null,
};

const mapStateToProps = (state, { modelFiles }) => {
  const bureau = getBureau(state);
  const bureauUri = getBureauUri(state);
  const isHawkingUser = isFeatureEnabled(state, FEATURES.HAWKING_DEPLOYMENT);
  const baseMaterialsByUri = getBaseMaterialsByUri(state);
  const { fetching } = state.ui.nautilus[API_RESOURCES.MATERIAL].list;
  const [modelFile, ...otherModelFiles] = modelFiles;
  const fileNameWithoutExtension = modelFile.name.split('.')
    .slice(0, -1)
    .join('.');
  const initialFormValues = {
    name: fileNameWithoutExtension,
    type: MODEL_LIBRARY_TYPES.PRODUCT,
    layer_thickness: isHawkingUser
      ? MODEL_LAYER_THICKNESS_SETTINGS.HAWKING_DEFAULT
      : MODEL_LAYER_THICKNESS_SETTINGS.DEFAULT,
  };
  return {
    bureau,
    bureauUri,
    fetching,
    isHawkingUser,
    initialFormValues,
    baseMaterialsByUri,
    modelFile,
    otherModelFiles,
  };
};

const mapDispatchToProps = (dispatch, ownProps) => ({
  onInitialize: bureauUri => {
    dispatch(Actions.Api.nautilus[API_RESOURCES.MATERIAL].list({ bureau: bureauUri }));
  },
  onSubmitModelAndModelLibrary: async (formValues, modelFile) => {
    const {
      user,
      bureau,
      isDigitalDesignWarehouseFeatureEnabled,
      customLibraryName,
      ownerUri,
    } = ownProps;
    const modelFileNameParts = modelFile.name.split('.');
    const extension = modelFileNameParts.pop()
      .toLowerCase();
    const fileNameWithoutExtension = modelFileNameParts.join('.');

    const getModelLibraryOwner = () => {
      if (isDigitalDesignWarehouseFeatureEnabled) {
        // If we are uploading a model for the Custom Library
        if (customLibraryName) {
          return ownerUri;
        }
        /* If we are uploading the model to Company Library from DDW ->
           put bureau.uri so the order could be uploaded to this folder specifically. */
        if (routeURIContains(ROUTES.DDW_COMPANY_LIBRARY)) {
          return bureau.uri;
        }

        // Otherwise, put user.uri so the order could be uploaded to the My Library.
        return user.uri;
      }
      return bureau.uri;
    };

    const modelPayload = {
      // Hardcoding it to `.stl` (lowercase) always
      // (since STL is the only format for model files for now)
      name: `${fileNameWithoutExtension}.${FILE_EXTENSIONS.STL}`,
      file_unit: null,
      user_unit: null,
      type: MODEL_TYPES.STL,
    };

    // When file is of STL extension,
    // we need to specify file units and user units in modelPayload for /model endpoint

    // ELSE(ie. when the model file is `Native CAD` or `2D`(ZVERSE_CONVERSION_EXTENSIONS) Drawings)
    //  We may want to skip sending file_unit and user units.
    if ((ALLOWED_MODEL_EXTENSIONS).includes(extension)) {
      modelPayload.file_unit = formValues.file_unit;
      modelPayload.user_unit = MODEL_UNITS.MM;
    } else {
      // For any non-STL extension we need to provide conversion type and original name
      // TODO: Using extension as an original type. We might need to replace it with extension-to-type mapping
      modelPayload.conversion_original_type = extension;
      modelPayload.conversion_original_filename = modelFile.name;
    }

    const { headers } = await dispatch(Actions.Api.nautilus[API_RESOURCES.MODEL].post(modelPayload));
    const {
      location,
      uploadLocation,
    } = headers;

    await dispatch(Actions.Api.nautilus[API_RESOURCES.MODEL].get(extractUuid(location)));
    await dispatch(Actions.UploadModel.upload(uploadLocation, modelFile));

    const isProductType = formValues.type === MODEL_LIBRARY_TYPES.PRODUCT;

    const payload = _pick(formValues, isProductType
      ? Object.values(productCreateFields)
      : Object.values(specimenCreateFields));

    if (formValues.part_number) {
      _set(payload, 'default_customer_id', formValues.part_number);
    }
    if (formValues.desired_quantity) {
      _set(payload, 'desired_quantity', formValues.desired_quantity);
    }

    MODEL_LIBRARY_CREATE_CONTAINER.NULL_FIELDS.forEach(
      fieldName => {
        if (!payload[fieldName]) {
          payload[fieldName] = null;
        }
      },
    );

    payload.model = location;

    // Create new additive sub-dict and move additive keys there
    const payloadValues = replaceFormValuesIntoSubDict(
      payload,
      MODEL_LIBRARY_CREATE_CONTAINER.ADDITIVE,
      'additive',
    );

    const modelLibraryPostResponse =
      await dispatch(Actions.Api.nautilus[API_RESOURCES.MODEL_LIBRARY].post(payloadValues));
    /* 👇🏼 PUT request to change the owner has had to be made straight after making the POST
    as changing the `owner` key in the `payload` itself doesn't appear to change anything. */
    await dispatch(Actions.Api.nautilus[API_RESOURCES.MODEL_LIBRARY].put(
      extractUuid(modelLibraryPostResponse.headers.location),
      { owner: getModelLibraryOwner() },
    ));
    return modelLibraryPostResponse;
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(ModelLibraryCreateContainer);
