import Actions from 'rapidfab/actions';
import { API_RESOURCES, PAGINATION_IGNORE_DEFAULT_LIMIT } from 'rapidfab/constants';

// eslint-disable-next-line import/prefer-default-export
export const loadAdditionalRunResourcesByRunUris = (runUris, dispatch) => {
  if (runUris.length) {
    dispatch(
      Actions.Api.nautilus[API_RESOURCES.RUN].list(
        { uri: runUris },
        { limit: PAGINATION_IGNORE_DEFAULT_LIMIT },
        {},
        {},
        true,
      ));

    dispatch(Actions.Api.nautilus[API_RESOURCES.SCHEDULE_RUNS].list(
      { run: runUris },
      { limit: PAGINATION_IGNORE_DEFAULT_LIMIT },
      {},
      {},
      true,
    ));

    dispatch(Actions.Api.nautilus[API_RESOURCES.RUN_ACTUALS].list(
      { run: runUris },
      { limit: PAGINATION_IGNORE_DEFAULT_LIMIT },
      {},
      {},
      true,
    ));
  }
};
