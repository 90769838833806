import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import * as Selectors from 'rapidfab/selectors';
import Actions from 'rapidfab/actions';
import { extractUuid } from 'rapidfab/utils/uuidUtils';

import Loading from 'rapidfab/components/Loading';
import PrintComponent from 'rapidfab/components/records/print/print';
import { API_RESOURCES } from 'rapidfab/constants';

const PrintContainer = props => {
  const uuid = useSelector(Selectors.getRouteUUID);
  const print = useSelector(state => Selectors.getRouteUUIDResource(state));
  const orders = useSelector(state => Selectors.getOrders(state, props));
  const users = useSelector(Selectors.getUsers);
  const bureau = useSelector(Selectors.getBureau);
  const lineItem = useSelector(state =>
    (print ? Selectors.getUUIDResource(state, extractUuid(print.line_item)) : null));
  const piece = useSelector(state => (print ? Selectors.getUUIDResource(state, extractUuid(print.piece)) : null));

  const order = print ? orders.find(o => o.uri === print.order) : null;
  const model = useSelector(state => Selectors.getModelForPiece(state, piece));

  const fetching = useSelector(state =>
    state.ui.nautilus[API_RESOURCES.PRINT].get.fetching ||
    state.ui.nautilus[API_RESOURCES.ORDER].get.fetching ||
    state.ui.nautilus[API_RESOURCES.PIECE].get.fetching ||
    state.ui.nautilus[API_RESOURCES.LINE_ITEM].get.fetching ||
    state.ui.nautilus[API_RESOURCES.MODEL].get.fetching ||
    state.ui.nautilus[API_RESOURCES.USERS].list.fetching);

  const selected = {
    uuid,
    print,
    order,
    piece,
    lineItem,
    users,
    bureau,
    model,
    fetching,
  };

  const dispatch = useDispatch();

  const onInitialize = currentUUID => {
    const printRequest = dispatch(Actions.Api.nautilus[API_RESOURCES.PRINT].get(currentUUID));

    printRequest.then(printResponse => {
      const {
        piece: pieceUri,
        order: orderUri,
        run: runUri,
      } = printResponse.json;

      dispatch(Actions.Api.nautilus[API_RESOURCES.ORDER].get(extractUuid(orderUri)));
      dispatch(Actions.Api.nautilus[API_RESOURCES.PIECE].get(extractUuid(pieceUri))).then(pieceResponse => {
        const {
          model: modelUri,
          is_line_item_deleted: isLineItemDeleted,
          line_item: lineItemUri,
        } = pieceResponse.json;

        if (modelUri) {
          dispatch(
            Actions.Api.nautilus[API_RESOURCES.MODEL].get(
              extractUuid(modelUri),
            ),
          );
        }

        if (!isLineItemDeleted) {
          // We need to load line item data only if it is not deleted
          dispatch(Actions.Api.nautilus[API_RESOURCES.LINE_ITEM].get(extractUuid(lineItemUri), true));
        }

        if (!isLineItemDeleted && runUri) {
          dispatch(Actions.Api.nautilus[API_RESOURCES.RUN].get(extractUuid(runUri)));
        }
      });
    });
  };

  useEffect(() => onInitialize(uuid), []);

  const isLineItemDeleted = piece && piece.is_line_item_deleted;
  const loading =
      fetching || !print || !order || (!isLineItemDeleted && !lineItem) || !model;
  return (
    <div>
      {loading ? <Loading /> : <PrintComponent {...props} {...selected} />}
    </div>
  );
};

export default PrintContainer;
