import React, { useState } from 'react';
import PropTypes from 'prop-types';
import _startCase from 'lodash/startCase';
import { FormattedDateTime, FormattedMessage, FormattedDate } from 'rapidfab/i18n';
import FormattedLocalizedCost from 'rapidfab/components/FormattedLocalizedCost';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck, faCheckSquare, faCopy, faSquare } from '@fortawesome/free-solid-svg-icons';

export const RESOURCE_READ_ONLY_VIEW_FIELD_TYPES = {
  TEXT: 'text',
  BOOLEAN: 'bool',
  DATETIME: 'datetime',
  DATE: 'date',
  COST: 'cost',
  MAPPED: 'mapped',
  CUSTOM: 'custom',
  // TODO: Add more supported types
};

const ResourceReadOnlyViewRow = ({
  entity,
  name,
  type,
  label: customLabel,
  customValue,
  map,
  isCopiable = false,
  ...otherProps
}) => {
  const [copied, setCopied] = useState(false);

  const value = type === RESOURCE_READ_ONLY_VIEW_FIELD_TYPES.CUSTOM
    ? customValue
    : entity[name];
  const label = customLabel || _startCase(name);
  return (
    <div {...otherProps}>
      <b>{label}:</b>
      &nbsp;
      {
        !value
        && value !== 0
        && ![
          RESOURCE_READ_ONLY_VIEW_FIELD_TYPES.BOOLEAN,
        ].includes(type)
        && (<FormattedMessage id="notAvailable" defaultMessage="N/A" />)
      }

      {
        [
          RESOURCE_READ_ONLY_VIEW_FIELD_TYPES.TEXT,
          RESOURCE_READ_ONLY_VIEW_FIELD_TYPES.CUSTOM,
        ].includes(type)
        && value
      }

      {
        type === RESOURCE_READ_ONLY_VIEW_FIELD_TYPES.DATETIME
        && value
        && (<FormattedDateTime value={value} />)
      }

      {
        type === RESOURCE_READ_ONLY_VIEW_FIELD_TYPES.DATE
        && value
        && (<FormattedDate value={value} />)
      }

      {
        type === RESOURCE_READ_ONLY_VIEW_FIELD_TYPES.COST
        && value
        && (<FormattedLocalizedCost value={value} />)
      }

      {
        type === RESOURCE_READ_ONLY_VIEW_FIELD_TYPES.BOOLEAN
        && (<FontAwesomeIcon icon={value ? faCheckSquare : faSquare} />)
      }

      {
        type === RESOURCE_READ_ONLY_VIEW_FIELD_TYPES.MAPPED
        && map[value]
        && (<FormattedMessage {...map[value]} />)
      }

      {isCopiable && (
        copied ? (
          <FontAwesomeIcon
            role="button"
            icon={faCheck}
            onClick={() => setCopied(false)}
            className="spacer-left"
          />
        ) :
          (
            <FontAwesomeIcon
              role="button"
              onClick={() => {
                setCopied(true);
                navigator.clipboard.writeText(value);
              }}
              className="spacer-left"
              icon={faCopy}
            />
          )
      )}
    </div>
  );
};

ResourceReadOnlyViewRow.defaultProps = {
  name: null,
  entity: null,
  label: null,
  type: RESOURCE_READ_ONLY_VIEW_FIELD_TYPES.TEXT,
  customValue: null,
  map: null,
  isCopiable: false,
};

ResourceReadOnlyViewRow.propTypes = {
  entity: PropTypes.shape({}),
  name: PropTypes.string,
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  type: PropTypes.oneOf(Object.values(RESOURCE_READ_ONLY_VIEW_FIELD_TYPES)),
  customValue: PropTypes.node,
  // TODO: { id, default } message shape can not be used here
  //  since we will come up with `defineMessage()` result in Mappings someday
  map: PropTypes.shape({}),
  isCopiable: PropTypes.bool,
};

export default ResourceReadOnlyViewRow;
