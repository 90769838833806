import React from 'react';
import PropTypes from 'prop-types';
import { Container, Row, Col } from 'react-bootstrap';
import Sidebar from 'rapidfab/components/hawking/Sidebar';
import 'rapidfab/styles/hawking/main.scss';

const HawkingLayout = ({ children }) => (
  <Container fluid>
    <Row>
      <Col xs={2}>
        <Sidebar />
      </Col>
      <Col xs={10}>
        {children}
      </Col>
    </Row>
  </Container>
);

HawkingLayout.propTypes = {
  children: PropTypes.node.isRequired,
};

export default HawkingLayout;
