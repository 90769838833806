import React from 'react';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { FormattedMessage } from 'rapidfab/i18n';
import { faQuestionCircle } from '@fortawesome/free-solid-svg-icons';

const CostTooltip = () => (
  <OverlayTrigger
    placement="top"
    overlay={(
      <Tooltip>
        <FormattedMessage
          id="costTooltip"
          defaultMessage="This currency is based on your Bureau Default Currency. Please contact Authentise Support to change this default."
        />
      </Tooltip>
    )}
  >
    <FontAwesomeIcon className="spacer-left spacer-right" icon={faQuestionCircle} />
  </OverlayTrigger>
);

export default CostTooltip;
