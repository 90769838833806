import React from 'react';
import PropTypes from 'prop-types';
import {
  getEventReferenceResourceRoute,
  getEventReferenceResourceType,
} from 'rapidfab/utils/tracebilityReportEvents';
import { extractUuid } from 'rapidfab/reducers/makeApiReducers';
import { getRouteURI } from 'rapidfab/utils/uriUtils';
import { ROUTES } from 'rapidfab/constants';

const EventResourceLink = ({ url, name, relatedResource }) => {
  let route = null;
  let generatedLinkName = null;

  if (relatedResource) {
    route = getEventReferenceResourceRoute(relatedResource.uri, getRouteURI);
    generatedLinkName = relatedResource.name;
  }

  if (!route) {
    try {
      const resourceType = getEventReferenceResourceType(url);
      const uuid = extractUuid(url);
      const availableRoutes = Object.values(ROUTES);
      const fallbackRouteTemplate = `/records/${resourceType}/:uuid`;
      const fallbackRoute = `#/records/${resourceType}/${uuid}`;
      if (availableRoutes.includes(fallbackRouteTemplate)) {
      // As A fall-back - use previous implementation only if route exists
        route = fallbackRoute;
      }
      // UUID in uri isn't found
      // eslint-disable-next-line no-empty
    } catch {}
  }

  if (!generatedLinkName) {
    // Use full URL as link name as a fall-back
    generatedLinkName = url;
  }

  const routeName = name || generatedLinkName;

  return route
    ? <a href={route}>{routeName}</a>
    // Return plain text name when no route is available
    // (e.g. User entity, Prep Workflow Record etc.)
    // Each of those entities may need to be included into `getEventReferenceResourceRoute` function
    : routeName;
};

EventResourceLink.propTypes = {
  relatedResource: PropTypes.shape({
    name: PropTypes.string.isRequired,
    url: PropTypes.string.isRequired,
    uri: PropTypes.string,
  }),
  url: PropTypes.string.isRequired,
  name: PropTypes.string,
};

EventResourceLink.defaultProps = {
  relatedResource: null,
  name: null,
};

export default EventResourceLink;
