import React from 'react';

const Time = props => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 40 40"
    {...props}
  >
    <g fill="none" fillRule="evenodd" stroke="none" strokeWidth="1">
      <g fill="#000" transform="translate(-108 -2010)">
        <g transform="translate(108 2010)">
          <g>
            <path d="M20 0C8.954 0 0 8.954 0 20s8.954 20 20 20 20-8.954 20-20A20 20 0 0020 0zm7.1 29.72l-9.1-9.1V8h3v11.38l8.22 8.22-2.12 2.12z" />
          </g>
        </g>
      </g>
    </g>
  </svg>
);

export default Time;
