import React from 'react';
import PropTypes from 'prop-types';

function ColorChits({ color, completed, total, label }) {
  // getting the color state
  // For each  state classes are defined in main.less
  const getColorState = () => {
    // when 100% done (Green)
    if (completed === total) return 'success';

    //  when 1-99% done (Chosen Color / Default Orange In-Progess Color)
    if (total > completed && completed > 0) return color;

    // when 0% done (Grey)
    return 'default';
  };

  return (
    <div className="order-status-item">
      <div className={`order-status-number-new ${getColorState()}`}>
        <b>
          <span className="num">{`${completed}/${total}`}</span>
        </b>
      </div>
      {label}
    </div>
  );
}
ColorChits.defaultProps = {
  label: '',
  total: 0,
  completed: 0,
  color: 'orange',
};

ColorChits.propTypes = {
  label: PropTypes.node,
  total: PropTypes.number,
  completed: PropTypes.number,
  color: PropTypes.string,
};

export default ColorChits;
