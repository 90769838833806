import { OverlayTrigger, Tooltip as BSTooltip } from 'react-bootstrap';
import PropTypes from 'prop-types';
import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faQuestionCircle } from '@fortawesome/free-solid-svg-icons';

const Tooltip = ({ id, children, placement, trigger, hideTooltipText }) => (
  <OverlayTrigger
    placement={placement}
    overlay={hideTooltipText ? <></> : (
      <BSTooltip id={id}>
        {children}
      </BSTooltip>
    )}
  >
    {trigger}
  </OverlayTrigger>
);

Tooltip.propTypes = {
  id: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,
  trigger: PropTypes.node,
  placement: PropTypes.string,
  hideTooltipText: PropTypes.bool,
};

Tooltip.defaultProps = {
  placement: 'top',
  trigger: <FontAwesomeIcon icon={faQuestionCircle} className="spacer-left spacer-right" />,
  hideTooltipText: false,
};

export default Tooltip;
