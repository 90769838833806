import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { getUsersByUri } from 'rapidfab/selectors';

const EventUser = (({ user }) => {
  // There is no separate route for User entity exists
  // Leaving this as an empty link for now,
  // to prevent UI changes, until this issue is solved from product perspective
  const userRoute = '#';
  return user
    ? <a href={userRoute}> {user.name} </a>
    : null;
});

EventUser.propTypes = {
  // Eslint do not see usage of userUri inside of mapStateToProps
  // eslint-disable-next-line react/no-unused-prop-types
  userUri: PropTypes.string,
  user: PropTypes.shape({
    name: PropTypes.string.isRequired,
  }),
};

EventUser.defaultProps = {
  userUri: null,
  user: null,
};

const mapStateToProps = (state, ownProps) => {
  const { userUri } = ownProps;
  const usersByUri = getUsersByUri(state);
  return {
    user: userUri ? usersByUri[userUri] : null,
  };
};

export default connect(mapStateToProps)(EventUser);
