import _get from 'lodash/get';
import _map from 'lodash/map';
import _mapValues from 'lodash/mapValues';
import _isEmpty from 'lodash/isEmpty';
import _every from 'lodash/every';
import _keys from 'lodash/keys';
import _keyBy from 'lodash/keyBy';
import _filter from 'lodash/filter';
import _flatMap from 'lodash/flatMap';
import _uniq from 'lodash/uniq';

export default function getCustomFieldArrayOptions({
  fieldReference,
  fieldReferences,
  fieldValuesByUri,
}) {
  let { options } = fieldReference;

  // this field's options depend on the field_ids in this array
  const dependentOnFieldIds = _uniq(
    // get dependent_on keys to determine the array of dependentOnFields
    _flatMap(options, ({ meta }) => _keys(meta.dependent_on)),
  );

  // if this field is not dependent on anything, return the options
  if (_isEmpty(dependentOnFieldIds)) return options;

  const dependentOnFields = _filter(
    fieldReferences, ({ field_id }) => dependentOnFieldIds.includes(field_id),
  );
  const dependentOnFieldsByFieldId = _keyBy(dependentOnFields, 'field_id');
  const dependentOnFieldValuesByFieldId = _mapValues(
    dependentOnFieldsByFieldId,
    // use field reference's uri to get the field value
    ({ uri, default_value }) => _get(
      fieldValuesByUri,
      [uri, 'value'],
      default_value || '',
    ),
  );

  // find the options that have dependentOnFieldId in meta.dependent_on
  options = _filter(options, ({ meta: { dependent_on } }) =>
    !dependent_on || _every(_map(dependentOnFieldIds, fieldId =>
      dependent_on[fieldId].includes(dependentOnFieldValuesByFieldId[fieldId]),
    )),
  );

  return options;
}
