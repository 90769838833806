export default function isModelPrintable(model) {
  if (!model) return false;

  if (!model.analyses) return true;

  if (!model.analyses.manifold) return false;

  if (model.analyses.facets_reversed > 0) return false;

  // When manifold is true and facets_reversed is equal to 0, the model is printable.
  return true;
}
