import FormGroupField from 'rapidfab/components/forms/FormGroupField';
import React from 'react';
import PropTypes from 'prop-types';
import { Button, Row, Modal, FormGroup, FormLabel } from 'react-bootstrap';
import { FormControlSelect } from 'rapidfab/components/formTools';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { FormattedMessage } from 'react-intl';
import { Form, Field } from 'react-final-form';
import { finalFormInputTypes } from 'rapidfab/types';
import Loading from 'rapidfab/components/Loading';
import {
  faClose,
  faObjectGroup,
  faPlus,
} from '@fortawesome/free-solid-svg-icons';

const ToolingStockCreateModal = ({
  show,
  onSubmit,
  submitting,
  handleCloseModal,
  locations,
  subLocations,
  toolingStockTypes,
  subLocationsFetching,
  onLocationChange,
}) => (
  <Modal
    size="lg"
    show={show}
    onHide={handleCloseModal}
    scrollable
    backdrop="static"
    dialogClassName="custom-darken-modal"
  >
    <Form
      onSubmit={onSubmit}
      initialValues={{}}
      render={({ handleSubmit, values }) => (
        <form onSubmit={handleSubmit}>
          <Modal.Header>
            <Modal.Title className="w-100 d-flex align-items-center justify-content-between">
              <div className="d-flex align-items-center">
                <p className="custom-darken-modal-title-text m-a-0">
                  <FontAwesomeIcon
                    className="spacer-right"
                    icon={faObjectGroup}
                  />
                  <FormattedMessage
                    id="record.tooling_stock.create"
                    defaultMessage="Create Tool"
                  />
                </p>
              </div>
              <FontAwesomeIcon
                icon={faClose}
                onClick={handleCloseModal}
                tabIndex={0}
                role="button"
                className="custom-darken-modal-button"
              />
            </Modal.Title>
          </Modal.Header>
          <Modal.Body className="custom-darken-modal--body-scroll">
            <Row className="custom-darken-modal-custom-gutter">
              <Field
                name="name"
                type="text"
                render={props => (
                  <FormGroupField
                    {...props.input}
                    name="name"
                    controlId="uxName"
                    required
                    label={
                      <FormattedMessage id="field.name" defaultMessage="Name" />
                    }
                  />
                )}
              />

              <FormGroup controlId="uxToolingStockType" className="form-group">
                <FormLabel>
                  <FormattedMessage
                    id="field.tooling_stock_type"
                    defaultMessage="Tool Type"
                  />
                  : *
                </FormLabel>
                <Field
                  name="type"
                  type="select"
                  render={props => (
                    <FormControlSelect {...props.input} required>
                      <FormattedMessage
                        id="field.select"
                        defaultMessage="Select an option"
                      >
                        {text => (
                          <option value="" disabled>
                            {text}
                          </option>
                        )}
                      </FormattedMessage>
                      {toolingStockTypes.map(
                        type =>
                          type && (
                            <option key={type.uri} value={type.uri}>
                              {type.name}
                            </option>
                          ),
                      )}
                    </FormControlSelect>
                  )}
                />
              </FormGroup>

              <FormGroup controlId="uxLocation" className="form-group">
                <FormLabel>
                  <FormattedMessage
                    id="field.location"
                    defaultMessage="Location"
                  />
                  : *
                </FormLabel>
                <Field
                  name="location"
                  type="select"
                  required
                  render={props => (
                    <FormControlSelect
                      {...props.input}
                      onChange={event => {
                        const uri = event.target.value;
                        onLocationChange(uri);
                        props.input.onChange(uri);
                      }}
                      required
                    >
                      <FormattedMessage
                        id="field.select"
                        defaultMessage="Select an option"
                      >
                        {text => (
                          <option value="" disabled>
                            {text}
                          </option>
                        )}
                      </FormattedMessage>
                      {locations.map(
                        location =>
                          location && (
                            <option key={location.uri} value={location.uri}>
                              {location.name}
                            </option>
                          ),
                      )}
                    </FormControlSelect>
                  )}
                />
              </FormGroup>

              <FormGroup controlId="uxSubLocation" className="form-group">
                <FormLabel>
                  <FormattedMessage
                    id="field.sub_location"
                    defaultMessage="Sub-Location"
                  />
                  :
                  {subLocationsFetching && (
                    <Loading inline className="spacer-left" />
                  )}
                </FormLabel>
                <Field
                  name="sub_location"
                  type="select"
                  disabled={!values.location || subLocationsFetching}
                  render={props => (
                    <FormControlSelect {...props.input}>
                      <FormattedMessage
                        id="field.select"
                        defaultMessage="Select an option"
                      >
                        {text => (
                          <option value="" disabled>
                            {text}
                          </option>
                        )}
                      </FormattedMessage>
                      {subLocations.map(
                        location =>
                          location && (
                            <option key={location.uri} value={location.uri}>
                              {location.name}
                            </option>
                          ),
                      )}
                    </FormControlSelect>
                  )}
                />
              </FormGroup>

              <Field
                name="notes"
                type="text"
                render={props => (
                  <FormGroupField
                    {...props.input}
                    as="textarea"
                    name="notes"
                    controlId="uxNotes"
                    label={(
                      <FormattedMessage
                        id="field.notes"
                        defaultMessage="Notes"
                      />
                    )}
                  />
                )}
              />
            </Row>
          </Modal.Body>
          <Modal.Footer>
            <Button type="submit" disabled={submitting} variant="success">
              {submitting ? (
                <Loading />
              ) : (
                <>
                  <FontAwesomeIcon icon={faPlus} className="spacer-right" />
                  <FormattedMessage id="button.save" defaultMessage="Save" />
                </>
              )}
            </Button>
          </Modal.Footer>
        </form>
      )}
    />
  </Modal>
);

ToolingStockCreateModal.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  input: finalFormInputTypes.isRequired,
  submitting: PropTypes.bool.isRequired,
  handleCloseModal: PropTypes.func.isRequired,
  show: PropTypes.bool.isRequired,
  locations: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  subLocations: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  toolingStockTypes: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  onLocationChange: PropTypes.func.isRequired,
  subLocationsFetching: PropTypes.bool,
};
ToolingStockCreateModal.defaultProps = {
  subLocationsFetching: false,
};

export default ToolingStockCreateModal;
