import React from 'react';
import { Badge, Button, ListGroup, ListGroupItem } from 'react-bootstrap';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { API_RESOURCES } from 'rapidfab/constants';
import Actions from 'rapidfab/actions';
import { getAddiguruAnomaliesByAnalysis } from 'rapidfab/selectors/addiguru';

const AddiguruAnomalies = ({ analysis }) => {
  const anomalies = useSelector(state => getAddiguruAnomaliesByAnalysis(state, analysis.uri));
  const isAddressed = analysis.anomaly_status === 'addressed';
  const dispatch = useDispatch();

  const addressAnomalies = async () => {
    await dispatch(Actions.Api.nautilus[API_RESOURCES.ADDIGURU_ANALYSIS].put(analysis.uuid, {
      anomaly_status: isAddressed ? 'unaddressed' : 'addressed',
    }));
    await dispatch(Actions.Api.nautilus[API_RESOURCES.ADDIGURU_ANALYSIS].get(analysis.uuid));
  };

  const addressedAnomalies = anomalies.filter(anomaly => anomaly.status === 'addressed' && anomaly.is_anomaly).length;
  const addressedDefects = anomalies.filter(anomaly => anomaly.status === 'addressed' && anomaly.is_defect).length;
  return (
    <div className="d-flex flex-column gap-2">
      <div className="d-flex flex-row justify-content-between align-items-end">
        <h4><strong>Anomalies</strong></h4>

        <Button
          size="sm"
          variant="primary"
          onClick={addressAnomalies}
        >
          {isAddressed ? 'Unaddress All' : 'Address All'}
        </Button>
      </div>
      {!isAddressed && (
        <>
          <strong>
            New Anomalies
          </strong>
          <ListGroup>
            <ListGroupItem>
              <div className="pull-left d-flex flex-row gap-2">
                Anomalies
              </div>
              <div className="pull-right d-flex flex-row gap-2">
                <Badge bg="warning">{analysis.anomalies - addressedAnomalies}</Badge>
              </div>
            </ListGroupItem>
            <ListGroupItem>
              <div className="pull-left d-flex flex-row gap-2">
                Defects
              </div>
              <div className="pull-right d-flex flex-row gap-2">
                <Badge bg="danger">{analysis.defects - addressedDefects}</Badge>
              </div>
            </ListGroupItem>
          </ListGroup>
        </>
      )}

      {addressedAnomalies > 0 && (
        <>
          <strong>
            Addressed
          </strong>
          <ListGroup>
            <ListGroupItem>
              <div className="pull-left d-flex flex-row gap-2">
                Anomalies
              </div>
              <div className="pull-right d-flex flex-row gap-2">
                <Badge bg="success">{addressedAnomalies}</Badge>
              </div>
            </ListGroupItem>
            <ListGroupItem>
              <div className="pull-left d-flex flex-row gap-2">
                Defects
              </div>
              <div className="pull-right d-flex flex-row gap-2">
                <Badge bg="success">{addressedDefects}</Badge>
              </div>
            </ListGroupItem>
          </ListGroup>
        </>
      )}
    </div>
  );
};

AddiguruAnomalies.propTypes = {
  analysis: PropTypes.shape({
    anomalies: PropTypes.number,
    defects: PropTypes.number,
    total_anomalies: PropTypes.number,
    uuid: PropTypes.string,
    anomaly_status: PropTypes.string,
    uri: PropTypes.string,
  }).isRequired,
};

export default AddiguruAnomalies;
