import Constants from 'rapidfab/constants';
import { doGet } from 'rapidfab/api/makeApi';
import Alert from 'rapidfab/utils/alert';

export function fetchModel(downloadURL) {
  return {
    types: [
      'DOWNLOAD_MODEL_REQUEST',
      'DOWNLOAD_MODEL_SUCESS',
      'DOWNLOAD_MODEL_FAILURE',
    ],
    callApi: () => doGet(downloadURL),
  };
}

export function downloadContent(filename, contentURL) {
  const encodedFilename = encodeURIComponent(filename.normalize());
  const withFilename = `${contentURL}&filename=${encodedFilename}`;
  const errorMessage = 'There was an error when uploading the model. Please refresh the page and try again or ' +
    'contact support@authentise.com if the problem persists.';
  doGet(withFilename).then(response => {
    if (response.status !== 200) {
      Alert.error(errorMessage);
      return;
    }
    window.location = withFilename;
  }).catch(() => {
    Alert.error(errorMessage);
  });

  return {
    type: Constants.DOWNLOAD_MODEL_CONTENT,
    url: contentURL,
  };
}
