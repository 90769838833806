import cadfileEN from './cadfile/en.json';
import documentsEN from './documents/en.json';
import estimatesEN from './estimates/en.json';
import quoteEN from './quote/en.json';
import shipmentsEN from './shipments/en.json';
import summaryEN from './summary/en.json';

const recordsEN = Object.assign(cadfileEN, documentsEN, estimatesEN, quoteEN, shipmentsEN, summaryEN);

export default recordsEN;
