import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Draggable } from 'react-drag-and-drop';
import { extractUuid } from 'rapidfab/reducers/makeApiReducers';
import { FormattedLength } from 'rapidfab/i18n';
import { getRawAndFormattedFillPercentage } from 'rapidfab/utils/printBedFill';
import { getLineItemWorkflowTypeObjectKey } from 'rapidfab/utils/lineItemUtils';

export default class ActivePiece extends Component {
  constructor(props) {
    super(props);
    this.state = {};
    this.handleClick = this.handleClick.bind(this);
  }

  handleClick(event, piece) {
    const { setFirstItem, onShiftToggle } = this.props;
    if (event.shiftKey) {
      onShiftToggle(piece);
    } else {
      setFirstItem(piece);
    }
  }

  render() {
    const { piece, printer, selected } = this.props;
    const pieceLineItemWorkflowTypeKey = getLineItemWorkflowTypeObjectKey(piece.lineItem);
    const { formatted: formattedBedFillPercentage } = getRawAndFormattedFillPercentage(
      [piece.lineItem[pieceLineItemWorkflowTypeKey].model],
      printer && printer.printer_type,
    );

    return (
      <Draggable type="buildplatepiece" className={`${this.props.isDragAndDropHovering ? 'run-new-drag-n-drop-highlight' : ''}`} data={piece.uri}>
        <tr
          onClick={event => this.handleClick(event, piece)}
          className={selected ? 'selected-row ' : null}
          style={{ border: 'none' }}
        >
          <td>
            <a href={`#/records/order/${extractUuid(piece.order)}`}>
              {piece.order_name} ({piece.lineItem.name})
            </a>
          </td>
          <td>
            <FormattedLength
              length={piece.lineItem[pieceLineItemWorkflowTypeKey].model.size.x}
              roundPrecision={2}
              lengthUnits={piece.lineItem[pieceLineItemWorkflowTypeKey].model.file_unit}
            />
            {' × '}
            <FormattedLength
              length={piece.lineItem[pieceLineItemWorkflowTypeKey].model.size.y}
              roundPrecision={2}
              lengthUnits={piece.lineItem[pieceLineItemWorkflowTypeKey].model.file_unit}
            />
          </td>
          <td>{formattedBedFillPercentage}</td>
        </tr>
      </Draggable>
    );
  }
}

ActivePiece.propTypes = {
  piece: PropTypes.shape({
    uri: PropTypes.string.isRequired,
    order: PropTypes.string.isRequired,
    order_name: PropTypes.string.isRequired,
    lineItem: PropTypes.shape({
      name: PropTypes.string.isRequired,
      model: PropTypes.shape({
        size: PropTypes.shape({
          x: PropTypes.number.isRequired,
          y: PropTypes.number.isRequired,
        }).isRequired,
        user_unit: PropTypes.string.isRequired,
        file_unit: PropTypes.string.isRequired,
      }).isRequired,
    }).isRequired,
  }).isRequired,
  printer: PropTypes.shape({
    printer_type: PropTypes.string.isRequired,
  }).isRequired,
  selected: PropTypes.bool.isRequired,
  setFirstItem: PropTypes.func.isRequired,
  onShiftToggle: PropTypes.func.isRequired,
  isDragAndDropHovering: PropTypes.bool.isRequired,
};
