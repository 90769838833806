import React, { useCallback, useState } from 'react';
import { faAngleDown, faAngleUp, faExternalLink, faSave } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import _find from 'lodash/find';
import _reject from 'lodash/reject';
import _get from 'lodash/get';
import PropTypes from 'prop-types';
import Actions from 'rapidfab/actions';
import { API_RESOURCES, CF_BACKGROUND_COLOR_OPTIONS, PAGINATION_IGNORE_DEFAULT_LIMIT, ROUTES, USER_ROLES, WORKFLOW_TYPES, WORKFLOW_USAGE_STATES } from 'rapidfab/constants';
import { FormattedMessage, FormattedMessageMappingOption } from 'rapidfab/i18n';
import { LINE_ITEM_STATUS_MAP } from 'rapidfab/mappings';
import * as Selectors from 'rapidfab/selectors';
import { LINE_ITEM_STATUS_TRANSFORMATIONS } from 'rapidfab/transformations';
import { createOrReplaceArray } from 'rapidfab/utils/arrayUtils';
import getVisibleCustomFieldReferencesWithOptions from 'rapidfab/utils/getVisibleCustomFieldReferencesWithOptions';
import { getRouteURI } from 'rapidfab/utils/uriUtils';
import { extractUuid } from 'rapidfab/utils/uuidUtils';
import { Button, FormControl, InputGroup, Modal, Card } from 'react-bootstrap';
import { Field, Form } from 'react-final-form';
import { useDispatch, useSelector } from 'react-redux';
import Select from 'react-select';
import { selectInputStylesLightMode } from 'rapidfab/constants/styles';
import reactSelectUserFormatter from 'rapidfab/utils/reactSelectUserFormatter';
import userSort from 'rapidfab/utils/userSort';
import FormRow from '../FormRow';
import Loading from '../Loading';
import Tooltip from '../Tooltip';
import VisibleFor from '../VisibleFor';
import CustomFieldList from '../forms/CustomFieldList';
import SelectSingle from '../forms/SelectSingle';
import SelectSingleLazy from '../forms/SelectSingleLazy';

const LineItemBulkActionModal = ({ show,
  onHide,
  bulkActionMarkedLineItems,
  expandModeState: [, setExpandMode] }) => {
  const dispatch = useDispatch();

  const [workflowsFetchMoreState, setWorkflowsFetchMoreState] = useState({ offset: 0, count: 1 });
  const [customFieldValues, setCustomFieldValues] = useState([]);
  const [customFieldsViewExpanded, setCustomFieldsViewExpanded] = useState(false);
  const [fetchedUsers, setFetchedUsers] = useState(false);
  const [assignedUser, setAssignedUser] = useState(null);

  // Get the statuses from the first marked line item.
  const statusOptions = LINE_ITEM_STATUS_TRANSFORMATIONS[bulkActionMarkedLineItems[0]?.status];
  const {
    base: baseMaterials,
    support: supportMaterials,
  } = useSelector(Selectors.getBaseAndSupportMaterials);

  const isFetching = useSelector(state => state.ui.nautilus[API_RESOURCES.LINE_ITEM].put.fetching);
  const usersFetching = useSelector(state => state.ui.nautilus[API_RESOURCES.USERS].list.fetching);

  const users = useSelector(state => Selectors.getUsers(state).sort(userSort));
  const currentUserRole = useSelector(Selectors.getCurrentUserRole);
  const isManager = currentUserRole === USER_ROLES.MANAGER;

  const customLineItemFieldReferences = useSelector(Selectors.getCustomLineItemFieldReferences);
  const workflows = useSelector(state => Selectors.getAvailableWorkflowsForLineItem(
    state, bulkActionMarkedLineItems[0]));
  const nonSpecimenWorkflows = _reject(workflows, ['type', WORKFLOW_TYPES.SPECIMEN]);

  const visiblelineItemCustomFieldReferencesWithOptions = getVisibleCustomFieldReferencesWithOptions({
    fieldReferences: customLineItemFieldReferences,
    fieldValues: customFieldValues,
    fieldOverrides: [],
    parentFieldReferences: [],
    parentFieldValues: [],
  });

  const fetchAllUsers = useCallback(() => {
    if (fetchedUsers) return null;
    return dispatch(Actions.Api.nautilus[API_RESOURCES.USERS].list(
      (isManager ? { archived: false } : {}), { limit: PAGINATION_IGNORE_DEFAULT_LIMIT },
    ))
      .then(() => setFetchedUsers(true));
  }, [dispatch, fetchedUsers, isManager]);

  const onFetchMoreWorkflows = async () => {
    const limit = 100;
    const response = await dispatch(Actions.Api.nautilus[API_RESOURCES.WORKFLOW].list(
      { include_custom_workflows: true },
      { limit, offset: workflowsFetchMoreState.offset }, {}, { sort: 'name' }, true),
    );
    setWorkflowsFetchMoreState(previous => (
      { offset: previous.offset + limit, count: response?.json.meta?.count || 0 }
    ));
  };

  const onCustomFieldChange = (_, value) => {
    const customFieldValuesReplaced = createOrReplaceArray(
      customFieldValues,
      { custom_field: value.customFieldReferenceUri },
      { value: value.value },
    );

    setCustomFieldValues(customFieldValuesReplaced);
  };

  const onFormSubmit = async formValues => {
    const { materials,
      status,
      workflow,
      assigned_user } = formValues;

    const payload = {
      additive: {
        materials,
      },
      status,
      workflow,
      custom_field_values: customFieldValues,
      assigned_user,
    };

    // Remove undefined/unset keys from payload
    Object.keys(payload).forEach(key => payload[key] === undefined && delete payload[key]);

    await Promise.all(
      bulkActionMarkedLineItems.map(markedLineItem => (
        dispatch(Actions.Api.nautilus[API_RESOURCES.LINE_ITEM].put(extractUuid(markedLineItem.uri), payload))),
      ),
    );

    // Set the expand mode to collapsed, when the user re-expands line items, edited values will update.
    setExpandMode('collapsed');

    onHide();
  };

  const lightSmallBoxViewStyle = {
    background: 'white',
    border: '1px solid rgba(0, 0, 0, 0.2)',
    height: '100%',
  };

  return (
    <Form
      onSubmit={onFormSubmit}
    >
      {({ handleSubmit, values }) => {
        const baseMaterialColor = _find(baseMaterials, { uri: _get(values, 'materials.base') })?.color;
        const supportMaterialColor = _find(supportMaterials, { uri: _get(values, 'materials.support') })?.color;

        return (
          <form onSubmit={handleSubmit}>
            <Modal onHide={onHide} show={show}>
              <Modal.Header closeButton>Apply the Responses to the Selected Line Items</Modal.Header>
              <Modal.Body>
                <Field
                  id="status"
                  name="status"
                  render={({ input }) => (
                    <FormRow id="field.status" defaultMessage="Status">
                      {statusOptions && (
                        <FormControl
                          name="status"
                          as="select"
                          onChange={input.onChange}
                          {...input}
                          required
                        >
                          {statusOptions && ([
                            <option key="placeholder" value="">Choose...</option>,
                            ...statusOptions.map(statusOption => (
                              <FormattedMessageMappingOption
                                mapping={LINE_ITEM_STATUS_MAP}
                                value={statusOption}
                                key={statusOption}
                              />
                            ))]
                          )}
                        </FormControl>
                      )}
                    </FormRow>
                  )}
                />
                <Field
                  id="assignedUser"
                  name="assigned_user"
                  render={({ input }) => (
                    <FormRow id="field.lineitemOwner" defaultMessage="Owner">
                      <InputGroup className="w100">
                        <div className="d-flex align-items-center w-full">
                          <Select
                            data-cy="lineItem_owner"
                            name="lineItemOwner"
                            options={reactSelectUserFormatter(users)}
                            onChange={event => {
                              setAssignedUser(event);
                              input.onChange(event?.value || null);
                            }}
                            placeholder={(
                              <FormattedMessage id="field.downtimeUserSelector" defaultMessage="Assign owner" />
                            )}
                            isLoading={usersFetching}
                            onMenuOpen={fetchAllUsers}
                            value={assignedUser}
                            styles={selectInputStylesLightMode}
                            isClearable
                          />
                        </div>
                      </InputGroup>
                    </FormRow>
                  )}
                />
                <Field
                  id="base_material"
                  name="materials.base"
                  render={({ input }) => (
                    <FormRow
                      id="field.baseMaterial"
                      defaultMessage="Base Material"
                    >
                      <div className="d-flex align-items-center">
                        <InputGroup className="w100">
                          <div className="d-flex w-full">
                            <SelectSingle
                              name="baseMaterial"
                              data={baseMaterials}
                              handleOnChange={input.onChange}
                              required
                              imitateOnChangeEvent
                              lightMode
                              {...input}
                            />
                            {values?.materials?.base && (
                              <InputGroup.Text style={lightSmallBoxViewStyle}>
                                <div
                                  style={{
                                    margin: '0 auto',
                                    width: 20,
                                    height: 20,
                                    backgroundColor: baseMaterialColor,
                                  }}
                                />
                              </InputGroup.Text>
                            )}
                            {values?.materials?.base && (
                              <VisibleFor unrestricted>
                                <InputGroup.Text style={lightSmallBoxViewStyle}>
                                  <a
                                    target="_blank"
                                    href={getRouteURI(ROUTES.MATERIALS,
                                      null, { uuid: extractUuid(values?.base_material) }, true)}
                                    rel="noreferrer"
                                  >
                                    <FontAwesomeIcon icon={faExternalLink} />
                                  </a>
                                </InputGroup.Text>
                              </VisibleFor>
                            )}
                          </div>
                        </InputGroup>
                        <FormattedMessage
                          id="record.lineItem.locationFilterMessage"
                          defaultMessage="The Materials you can select from are those Materials which are available at the Order's Location. To see other Materials that may be available, please change the Order's Location. To see All Materials available in your bureau, set the Order Location to Any."
                        >
                          {text => (
                            <Tooltip id="locationFilteringMessage" placement="left">{text}</Tooltip>
                          )}
                        </FormattedMessage>
                      </div>
                    </FormRow>
                  )}
                />

                <Field
                  id="support_material"
                  name="materials.support"
                  render={({ input }) => (
                    <FormRow id="field.supportMaterial" defaultMessage="Support Material">
                      <InputGroup className="w100">
                        <div className="d-flex align-items-center w-full">
                          <SelectSingle
                            name="supportMaterial"
                            data={supportMaterials}
                            handleOnChange={input.onChange}
                            imitateOnChangeEvent
                            lightMode
                            {...input}
                          />
                          {values?.materials?.support && (
                            <InputGroup.Text style={lightSmallBoxViewStyle}>
                              <div
                                style={{
                                  margin: '0 auto',
                                  width: 20,
                                  height: 20,
                                  backgroundColor: supportMaterialColor,
                                }}
                              />
                            </InputGroup.Text>
                          )}
                          {values?.materials?.support && (
                            <VisibleFor unrestricted>
                              <InputGroup.Text style={lightSmallBoxViewStyle}>
                                <a
                                  target="_blank"
                                  href={getRouteURI(ROUTES.MATERIALS,
                                    null, { uuid: extractUuid(values?.support_material) }, true)}
                                  rel="noreferrer"
                                >
                                  <FontAwesomeIcon icon={faExternalLink} />
                                </a>
                              </InputGroup.Text>
                            </VisibleFor>
                          )}
                        </div>
                      </InputGroup>
                    </FormRow>
                  )}
                />

                <Field
                  id="workflow"
                  name="workflow"
                  render={({ input }) => (
                    <FormRow
                      id="workflow"
                      defaultMessage="Production Workflow"
                    >
                      <InputGroup className="w100">
                        <div className="d-flex align-items-center w-full">
                          <SelectSingleLazy
                            name="workflow"
                            dataTestId="lineItemWorkflow"
                            data={nonSpecimenWorkflows}
                            handleOnChange={input.onChange}
                            imitateOnChangeEvent
                            required
                            isOptionDisabledCallback={item => item.usage_state === WORKFLOW_USAGE_STATES.ARCHIVED}
                            onFetchMore={onFetchMoreWorkflows}
                            lightMode
                            {...input}
                          />
                          {values?.workflow && (
                            <VisibleFor unrestricted>
                              <InputGroup.Text style={lightSmallBoxViewStyle}>
                                <a
                                  target="_blank"
                                  href={
                                    getRouteURI(ROUTES.WORKFLOW_EDIT,
                                      { uuid: extractUuid(values?.workflow) })
                                  }
                                  rel="noreferrer"
                                >
                                  <FontAwesomeIcon icon={faExternalLink} />
                                </a>
                              </InputGroup.Text>
                            </VisibleFor>
                          )}
                          <FormattedMessage
                            id="workflowInstructionTooltip"
                            defaultMessage="Production Workflow can’t be changed once work instructions are created for this line item."
                          >
                            {text => (
                              <Tooltip id="workflowInstructionTooltip">{text}</Tooltip>
                            )}
                          </FormattedMessage>
                        </div>
                      </InputGroup>
                    </FormRow>
                  )}
                />

                <Card bg="light">
                  <Card.Header
                    role="button"
                    onClick={() => setCustomFieldsViewExpanded(previous => !previous)}
                    id="custom-fields-card-header"
                    className="d-flex align-items-center"
                  >
                    <FontAwesomeIcon
                      className="spacer-right"
                      icon={customFieldsViewExpanded ? faAngleUp : faAngleDown}
                      size="2x"
                    />
                    <FormattedMessage
                      id="custom_fields"
                      defaultMessage="Custom Fields"
                    />
                  </Card.Header>
                  {customFieldsViewExpanded && (
                    <Card.Body>
                      <CustomFieldList
                        bg={CF_BACKGROUND_COLOR_OPTIONS.LIGHT}
                        customFieldReferences={visiblelineItemCustomFieldReferencesWithOptions}
                        customFieldValues={customFieldValues}
                        onChange={onCustomFieldChange}
                        readOnly={false}
                      />
                    </Card.Body>
                  )}
                </Card>

              </Modal.Body>
              <Modal.Footer>
                <Button variant="danger" onClick={onHide}>Cancel</Button>
                <Button disabled={isFetching} onClick={handleSubmit}>
                  {isFetching ? <Loading /> : (
                    <span>
                      <FontAwesomeIcon icon={faSave} /> Save
                    </span>
                  )}
                </Button>
              </Modal.Footer>
            </Modal>
          </form>
        );
      }}
    </Form>
  );
};

export default LineItemBulkActionModal;

LineItemBulkActionModal.propTypes = {
  show: PropTypes.bool.isRequired,
  onHide: PropTypes.func.isRequired,
  onConfirm: PropTypes.func.isRequired,
  bulkActionMarkedLineItems: PropTypes.arrayOf(PropTypes.shape({
    status: PropTypes.string,
  })).isRequired,
  expandModeState: PropTypes.arrayOf(PropTypes.func).isRequired,
};
