import React from 'react';
import { Button, Modal } from 'react-bootstrap';
import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faClose } from '@fortawesome/free-solid-svg-icons';

const BureauNotificationDetailsModal = ({ show, onHide, title, bodyText }) => (
  <Modal
    show={show}
    onHide={onHide}
    size="md"
    backdrop="static"
    dialogClassName="custom-darken-modal"
  >
    <Modal.Header className="d-flex align-items-center justify-content-between">
      {title}
      <FontAwesomeIcon
        icon={faClose}
        onClick={onHide}
        tabIndex={0}
        role="button"
        className="custom-darken-modal-button"
      />
    </Modal.Header>
    <Modal.Body>
      {bodyText}
    </Modal.Body>
    <Modal.Footer>
      <Button variant="danger" onClick={onHide}>
        Close
      </Button>
    </Modal.Footer>
  </Modal>
);

export default BureauNotificationDetailsModal;

BureauNotificationDetailsModal.propTypes = {
  show: PropTypes.bool.isRequired,
  onHide: PropTypes.func.isRequired,
  title: PropTypes.string.isRequired,
  bodyText: PropTypes.string.isRequired,
};
