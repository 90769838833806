/* eslint-disable linebreak-style */
import { createSelector } from 'reselect';
import * as baseStateSelectors from 'rapidfab/selectors/baseStateSelectors';
import { getStateResources } from 'rapidfab/selectors/helpers/base';
import _map from 'lodash/map';
import _keyBy from 'lodash/keyBy';

export const getLabor = createSelector(
  [baseStateSelectors.getStateLabor, getStateResources],
  (uuids, resources) => _map(uuids, uuid => resources[uuid]),
);

export const getLaborByPostPocessorType = createSelector([getLabor], labor =>
  _keyBy(labor, 'post_processor_type'),
);

export const getLaborByPrinterType = createSelector([getLabor], labor =>
  _keyBy(labor, 'printer_type'),
);

export const getLaborByUri = createSelector([getLabor], labor =>
  _keyBy(labor, 'uri'),
);
export const getLaborByUuid = createSelector([getLabor], labor =>
  _keyBy(labor, 'uuid'),
);
