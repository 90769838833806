import { Button, Col, FormControl, FormGroup, FormLabel, Modal, Row } from 'react-bootstrap';
import { FormattedMessage } from 'react-intl';
import { Field, Form } from 'react-final-form';
import React from 'react';
import { SUB_LOCATIONS_FIELDS_MODAL, SUB_LOCATIONS_SENSOR_MODAL_FORM } from 'rapidfab/constants/forms';
import PropTypes from 'prop-types';
import Loading from 'rapidfab/components/Loading';
import _isNil from 'lodash/isNil';
import _sortBy from 'lodash/sortBy';
import _uniq from 'lodash/uniq';

const FREQUENCY_TIME_LIST = [5, 10, 15, 30];

const SubLocationsEditModal = (
  {
    onClose,
    handleSubmitForm,
    isAdding,
    subLocation,
    listOfSensorResources,
    isSensorFeatureEnabled = true,
    subLocationHasSensorRelatedResource,
  },
) => {
  const initialValues = {
    name: subLocation?.name,
    description: subLocation?.description,
    serial_number: subLocationHasSensorRelatedResource ? listOfSensorResources[0]?.serial_number : '',
    frequency: subLocationHasSensorRelatedResource ? listOfSensorResources[0]?.frequency : null,
    state: subLocationHasSensorRelatedResource ? listOfSensorResources[0]?.state : 'New',
  };
  const initialFormValues = {};

  Object
    .keys(initialValues)
    .filter(key => (
      SUB_LOCATIONS_FIELDS_MODAL.FIELDS.includes(key)) || SUB_LOCATIONS_SENSOR_MODAL_FORM.FIELDS.includes(key))
    .forEach(key => {
      initialFormValues[key] = initialValues[key];
    });

  return (
    <Modal show onHide={onClose} backdrop="static">
      <Modal.Header closeButton>
        <Modal.Title>
          <FormattedMessage id="record.subLocation.edit" defaultMessage="Edit Sub-Location" />
        </Modal.Title>
      </Modal.Header>
      <Form
        onSubmit={handleSubmitForm}
        initialValues={initialFormValues}
        render={({ handleSubmit, values }) => (
          <form onSubmit={handleSubmit}>
            <Modal.Body>
              <FormGroup className="form-group" controlId="subLocationName">
                <Row>
                  <Col xs={5}>
                    <FormLabel>
                      <FormattedMessage
                        id="field.name"
                        defaultMessage="Name"
                      />: *
                    </FormLabel>
                  </Col>
                  <Col xs={7}>
                    <Field
                      name="name"
                      initialValue={initialValues.name}
                      render={({ input }) => (
                        <FormControl
                          {...input}
                          onChange={event => input.onChange(event)}
                          required
                        />
                      )}
                    />
                  </Col>
                </Row>
              </FormGroup>

              <FormGroup className="form-group" controlId="subLocationDescription">
                <Row>
                  <Col xs={5}>
                    <FormLabel>
                      <FormattedMessage
                        id="field.description"
                        defaultMessage="Description"
                      />:
                    </FormLabel>
                  </Col>
                  <Col xs={7}>
                    <Field
                      name="description"
                      parse={value => (_isNil(value) ? null : value)}
                      initialValue={initialValues.description}
                      render={props => (
                        <FormControl {...props.input} as="textarea" disabled />
                      )}
                    />
                  </Col>
                </Row>
              </FormGroup>
              {isSensorFeatureEnabled && (
                <>
                  <FormGroup className="form-group" controlId="sensorSerialNumber">
                    <Row>
                      <Col xs={5}>
                        <FormLabel>
                          <FormattedMessage
                            id="field.serialNumber"
                            defaultMessage="Serial Number"
                          />:
                        </FormLabel>
                      </Col>
                      <Col xs={7}>
                        {listOfSensorResources.length === 1 ? (
                          <Field
                            name="serial_number"
                            initialValue={initialValues.serial_number}
                            render={props => (
                              <FormControl
                                {...props.input}
                                disabled={subLocationHasSensorRelatedResource}
                              />
                            )}
                          />
                        ) : (
                          <Field
                            name="serial_number"
                            initialValue={initialValues.serial_number}
                            parse={value => (_isNil(value) ? '' : value)}
                            render={props => (
                              <FormControl
                                {...props.input}
                                as="input"
                                value={props.input.value.toUpperCase()}
                              />
                            )}
                          />
                        )}
                      </Col>
                    </Row>
                  </FormGroup>
                  <FormGroup className="form-group" controlId="sensorFrequency">
                    <Row>
                      <Col xs={5}>
                        <FormLabel>
                          <FormattedMessage
                            id="field.sensorFrequency"
                            defaultMessage="Frequency"
                          />: {!!values.serial_number && '*'}
                        </FormLabel>
                      </Col>
                      <Col xs={7}>

                        <Field
                          name="frequency"
                          initialValue={initialValues.frequency}
                          render={props => (
                            subLocationHasSensorRelatedResource ? (

                            // Will be updated to only select form type when PUT request is included into BE code.
                            // For now itll be locked into place
                              <FormControl
                                {...props.input}
                                as="select"
                                required={!!values.serial_number}
                              >
                                {
                                  _sortBy(
                                    _uniq([listOfSensorResources[0]?.frequency, ...FREQUENCY_TIME_LIST]),
                                  ).map(
                                    currentFrequencyTime => (
                                      <option value={currentFrequencyTime} key={currentFrequencyTime}>
                                        {currentFrequencyTime} minutes
                                      </option>

                                    ))
                                }
                              </FormControl>

                            ) : (
                              <FormControl
                                {...props.input}
                                as="select"
                                required={!!values.serial_number}
                              >
                                <FormattedMessage id="field.choose" defaultMessage="Choose…">{text =>
                                  <option value="" disabled>{text}</option>}
                                </FormattedMessage>
                                {FREQUENCY_TIME_LIST.map(currentFrequencyTime => (
                                  <option key={currentFrequencyTime} value={currentFrequencyTime}>
                                    {currentFrequencyTime} minutes
                                  </option>
                                ))}

                              </FormControl>
                            )

                          )}
                        />

                      </Col>
                    </Row>
                  </FormGroup>
                  {subLocationHasSensorRelatedResource && (
                    <FormGroup className="form-group" controlId="sensorState">
                      <Row>
                        <Col xs={5}>
                          <FormLabel>
                            <FormattedMessage
                              id="field.sensorState"
                              defaultMessage="State"
                            />:
                          </FormLabel>
                        </Col>
                        <Col xs={7}>
                          <Field
                            name="state"
                            initialValue={initialValues.state}
                            render={props => (
                              <FormControl disabled {...props.input} />
                            )}
                          />
                        </Col>
                      </Row>
                    </FormGroup>
                  )}
                </>
              )}

            </Modal.Body>
            <Modal.Footer>
              <Button
                onClick={onClose}
                disabled={isAdding}
                variant="danger"
              >
                <FormattedMessage
                  id="button.close"
                  defaultMessage="Close"
                />
              </Button>
              <Button
                type="submit"
                disabled={isAdding}
                variant="success"
              >
                {isAdding && <Loading inline className="spacer-right" />}
                <FormattedMessage
                  id="button.save"
                  defaultMessage="Save"
                />
              </Button>
            </Modal.Footer>
          </form>
        )}
      />
    </Modal>
  );
};

SubLocationsEditModal.defaultProps = {
  input: {},
};

SubLocationsEditModal.propTypes = {
  onClose: PropTypes.func.isRequired,
  handleSubmitForm: PropTypes.func.isRequired,
  isAdding: PropTypes.bool.isRequired,
  input: PropTypes.shape({
    onChange: PropTypes.func,
    value: PropTypes.string,
  }),
  subLocationHasSensorRelatedResource: PropTypes.shape({}).isRequired,
  subLocation: PropTypes.shape({
    description: PropTypes.string,
    name: PropTypes.string,
  }).isRequired,
  listOfSensorResources: PropTypes.arrayOf({}).isRequired,
  isSensorFeatureEnabled: PropTypes.bool.isRequired,
};

export default SubLocationsEditModal;
