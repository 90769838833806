import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import { Button } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import Actions from 'rapidfab/actions';
import { getRouteUUIDResource } from 'rapidfab/selectors';

import { FormattedMessage } from 'rapidfab/i18n';
import UseNonMfgLanguageFeature from 'rapidfab/components/generalMfgLanguage/UseNonMfgLanguageFeature';
import { API_RESOURCES } from 'rapidfab/constants';
import { faSpinner } from '@fortawesome/free-solid-svg-icons';

const styles = { spacingBelow: { marginBottom: '2rem' } };

const RunRequeueButton = ({ handleRequeue, isSending, uri, manuallyDisabled }) => (
  // <ButtonGroup style={styles.spacingBelow} vertical block>
  <div className="btn-group-vertical w-full" style={styles.spacingBelow}>
    <Button variant="default" disabled={!uri || isSending || manuallyDisabled} onClick={() => handleRequeue(uri)}>
      {isSending ? (
        <FontAwesomeIcon icon={faSpinner} spin />
      ) : (
        <UseNonMfgLanguageFeature
          mfgLanguageComponent={(
            <FormattedMessage
              id="scheduleAsNextPrint"
              defaultMessage="Schedule As Next Print"
            />
          )}
          nonMfgLanguageComponent={(
            <FormattedMessage
              id="mfg.scheduleAsNextPrint.scheduleAsProductionRun"
              defaultMessage="Schedule As Production Run"
            />
          )}
        />
      )}
    </Button>
  </div>
  // </ButtonGroup>
);

RunRequeueButton.defaultProps = { uri: null, manuallyDisabled: false };

RunRequeueButton.propTypes = {
  handleRequeue: PropTypes.func.isRequired,
  isSending: PropTypes.bool.isRequired,
  uri: PropTypes.string,
  manuallyDisabled: PropTypes.bool,
};

const mapDispatchToProps = dispatch => ({
  handleRequeue: uri => {
    dispatch(Actions.Api.nautilus[API_RESOURCES.RUN_QUEUE].post({ run: uri })).then(() => {
      dispatch(Actions.Api.nautilus[API_RESOURCES.RUN].get(uri));
    });
  },
});

const mapStateToProps = state => {
  const run = getRouteUUIDResource(state);
  const isSending = state.ui.nautilus[API_RESOURCES.RUN_QUEUE].post.fetching;
  return { isSending, ...(run ? { uri: run.uri } : {}) };
};

export default connect(mapStateToProps, mapDispatchToProps)(RunRequeueButton);
