import {
  faArrowCircleRight,
  faGear, faSignOut,
  faUsers,
  faUsersCog,
  faUserSecret,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import PropTypes from 'prop-types';
import Feature from 'rapidfab/components/Feature';
import Loading from 'rapidfab/components/Loading';
import { ProfileAvatarFull } from 'rapidfab/components/navbar/ProfileAvatar';
import { FEATURES, ROUTES } from 'rapidfab/constants';
import { useImpersonationContext } from 'rapidfab/context/ImpersonationContext';
import { FormattedMessage } from 'rapidfab/i18n';
import { getRouteURI } from 'rapidfab/utils/uriUtils';
import React, { useEffect, useRef } from 'react';
import { Link } from 'react-router-dom';
import NonHawkingFeature from '../hawking/NonHawkingFeature';

const ProfileMenu = ({
  userName,
  userEmail,
  shouldShowProfile,
  shouldShowImpersonate,
  shouldShowImpersonateStop,
  isDebugModeEnabled,
  shouldShowAdmin,
  setShowDebugModeUserInformationModal,
  onImpersonateStop,
  isStopImpersonationLoading,
  isVisible,
  onClose,
  onLogout,
  avatarRef,
}) => {
  const menuRef = useRef(null);

  const {
    setIsImpersonationModalActive,
  } = useImpersonationContext();

  const handleClickOutside = event => {
    if (menuRef.current && !menuRef.current.contains(event.target) &&
      avatarRef.current && !avatarRef.current.contains(event.target)) {
      onClose(); // Only close if the click is outside both the menu and the avatar
    }
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    return () => document.removeEventListener('mousedown', handleClickOutside);
  }, [onClose, avatarRef]);

  const handleMenuClick = event => {
    const menuItem = event.target;
    // In case the button or link within menu is clicked => close the menu
    // Exception is "End Impersonation" button.
    // Or we clicked on the avatar full name data
    if (
      ((
        menuItem.closest('a, button, div[role="button"], path, svg') &&
          menuItem.tabIndex !== -1)
        || typeof menuItem.className !== 'string'
        || menuItem.className.includes('profile-avatar-full'))) {
      onClose();
    }
  };

  const getMenuRoute = route => getRouteURI(route, {}, {}, true);

  if (!isVisible) {
    return null;
  }
  return (
    <div ref={menuRef} className="profile-menu" onClick={handleMenuClick} role="button" tabIndex={-1}>
      <ProfileAvatarFull
        userName={userName}
        email={userEmail}
        shouldShowProfile={shouldShowProfile}
      />
      <hr className="profile-menu-separator" />

      <div className="profile-menu-items">

        {shouldShowImpersonate && !shouldShowImpersonateStop && (
          <div
            className="profile-menu-item"
            role="button"
            tabIndex={0}
            onClick={() => setIsImpersonationModalActive(true)}
          >
            <div className="profile-menu-item-icon">
              <FontAwesomeIcon icon={faUserSecret} />
            </div>
            <FormattedMessage
              id="impersonate"
              defaultMessage="Impersonate"
            />
          </div>
        )}

        {shouldShowAdmin && (
          <Feature featureNames={[FEATURES.STANLEY_X_DEPLOYMENT]} isInverted>
            <NonHawkingFeature>
              <Link
                to={getMenuRoute(ROUTES.ADMIN_SETTINGS)}
                className="profile-menu-item"
              >
                <div className="profile-menu-item-icon">
                  <FontAwesomeIcon icon={faGear} />
                </div>
                <FormattedMessage
                  id="settings"
                  defaultMessage="Settings"
                />
              </Link>
            </NonHawkingFeature>
          </Feature>
        )}

        {isDebugModeEnabled && (
          <div
            role="button"
            tabIndex={0}
            className="profile-menu-item"
            onClick={setShowDebugModeUserInformationModal}
          >
            <div className="profile-menu-item-icon">
              <FontAwesomeIcon icon={faUsersCog} />
            </div>
            <FormattedMessage
              id="bureau_data"
              defaultMessage="Bureau Data"
            />
          </div>
        )}

        {shouldShowImpersonateStop && (
          <div
            role="button"
            tabIndex={0}
            className="profile-menu-item"
            onClick={() => setIsImpersonationModalActive(true)}
          >
            <div className="profile-menu-item-icon">
              <FontAwesomeIcon icon={faArrowCircleRight} />
            </div>
            <FormattedMessage
              id="change_impersonation"
              defaultMessage="Change Impersonation"
            />
          </div>
        )}

        {shouldShowImpersonateStop && (
          <div
            role="button"
            tabIndex={-1}
            className="profile-menu-item profile-menu-item-end-impersonation"
            onClick={onImpersonateStop}
          >
            <div className="profile-menu-item-icon">
              {
                isStopImpersonationLoading
                  ? <Loading inline />
                  : <FontAwesomeIcon icon={faUserSecret} />
              }
            </div>
            <FormattedMessage
              id="endImpersonation"
              defaultMessage="End Impersonation"
            />
          </div>
        )}

        {shouldShowAdmin && (
          <Link
            to={getMenuRoute(ROUTES.ADMIN)}
            className="profile-menu-item"
          >
            <div className="profile-menu-item-icon">
              <FontAwesomeIcon icon={faUsers} />
            </div>
            <FormattedMessage id="admin" defaultMessage="Administration" />
          </Link>
        )}

        <div
          role="button"
          tabIndex={0}
          className="profile-menu-item-signout"
          onClick={onLogout}
        >
          <div className="profile-menu-item-icon">
            <FontAwesomeIcon icon={faSignOut} />
          </div>
          <FormattedMessage id="sign_out" defaultMessage="Sign Out" />
        </div>
      </div>
    </div>
  );
};

ProfileMenu.defaultProps = {
  shouldShowImpersonateStop: null,
};

ProfileMenu.propTypes = {
  userName: PropTypes.string.isRequired,
  userEmail: PropTypes.string.isRequired,
  shouldShowAdmin: PropTypes.bool.isRequired,
  shouldShowProfile: PropTypes.bool.isRequired,
  shouldShowImpersonate: PropTypes.bool.isRequired,
  shouldShowImpersonateStop: PropTypes.bool,
  isDebugModeEnabled: PropTypes.bool.isRequired,
  isVisible: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  setShowDebugModeUserInformationModal: PropTypes.func.isRequired,
  onImpersonateStop: PropTypes.func.isRequired,
  onLogout: PropTypes.func.isRequired,
  isStopImpersonationLoading: PropTypes.bool.isRequired,
  avatarRef: PropTypes.shape({ current: PropTypes.instanceOf(Element) }).isRequired,
};

export default ProfileMenu;
