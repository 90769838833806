import React from 'react';
import { Container } from 'react-bootstrap';
import Loading from 'rapidfab/components/Loading';
import TableWithSearching from 'rapidfab/components/Tables/TableWithSearching';
import BreadcrumbNav from 'rapidfab/components/BreadcrumbNav';
import PropTypes from 'prop-types';
import { SECURE_FILE_CHECKOUT_STATUSES } from 'rapidfab/constants';
import { SECURE_FILE_CHECKOUT_STATUS_COLOR_CODE_MAPPING, SECURE_FILE_CHECKOUT_STATUSES_MAP } from 'rapidfab/mappings';
import { secureFileCheckoutResourceType } from 'rapidfab/types';
import { FormattedMessage } from 'rapidfab/i18n';
import SaveButton from 'rapidfab/components/SaveButton';

const LabelItem = ({ entity }) => (
  <div className="list-item capitalize">
    <span
      className="small-status-dot spacer-right"
      style={{ backgroundColor: SECURE_FILE_CHECKOUT_STATUS_COLOR_CODE_MAPPING[entity.status] }}
    />
    {SECURE_FILE_CHECKOUT_STATUSES_MAP[entity.status]}
  </div>
);

LabelItem.propTypes = {
  entity: secureFileCheckoutResourceType.isRequired,
};

const SecureFileCheckouts = props => {
  const {
    isSaving,
    fetching,
    secureFileCheckouts,
    handleCheckIn,
  } = props;

  const columns = [
    {
      type: 'custom',
      uid: 'field.workstation',
      accessor: 'workstation',
      defaultMessage: 'Workstation',
      Cell: cellData => {
        const { row: { original: data } } = cellData;
        return <p>{data.common_name}</p>;
      },
    },
    {
      type: 'time',
      uid: 'field.updated',
      accessor: 'updated',
      defaultMessage: 'Updated',
    },
    {
      type: 'custom',
      uid: 'field.status',
      accessor: 'status',
      defaultMessage: 'Status',
      Cell: cellData => {
        const { row: { original: data } } = cellData;
        return <LabelItem entity={data} />;
      },
    },
    {
      type: 'custom',
      uid: 'field.checkIn',
      accessor: 'checkIn',
      defaultMessage: 'Check In',
      Cell: cellData => {
        const { row: { original: data } } = cellData;
        const isDisabled = data?.status === SECURE_FILE_CHECKOUT_STATUSES.COMPLETED;
        return (
          <div className="d-flex justify-content-center align-items-center">
            <SaveButton
              bsStyle={isDisabled ? 'secondary' : 'primary'}
              disabled={isDisabled}
              isSaving={isSaving}
              label="Check In"
              showSaveIcon={false}
              onClick={() => handleCheckIn(data.uri)}
            />
          </div>
        );
      },
    },
  ];

  return (
    <Container fluid>
      <BreadcrumbNav breadcrumbs={['admin', 'secureRunCheckouts']} />
      <div>
        <h1 className="font-size-36">
          <FormattedMessage
            id="secureRunCheckouts"
            defaultMessage="Secure Run Checkouts"
          />
        </h1>
      </div>
      {fetching ? (
        <Loading />
      ) : (
        <TableWithSearching
          {...props}
          isFetching={fetching}
          data={secureFileCheckouts}
          columns={columns}
          navbar={<span />}
          isFilteringEnabled={false}
          withDefaultPagination={false}
          isManualSoringEnabled
          isUpdatedColumnShown={false}
          initialSortedDesc
          showLocationsFilter={false}
        />
      )}
    </Container>
  );
};

SecureFileCheckouts.defaultProps = {
  row: null,
};

SecureFileCheckouts.propTypes = {
  row: PropTypes.shape({
    original: secureFileCheckoutResourceType.isRequired,
  }),
  isSaving: PropTypes.bool.isRequired,
  fetching: PropTypes.bool.isRequired,
  secureFileCheckouts: PropTypes.arrayOf(secureFileCheckoutResourceType).isRequired,
  handleCheckIn: PropTypes.func.isRequired,
};
export default SecureFileCheckouts;
