import PropTypes from 'prop-types';

export const valueType = PropTypes.oneOfType([
  PropTypes.number,
  PropTypes.string,
  PropTypes.bool,
  PropTypes.array,
]);

export const reduxFormFieldType = PropTypes.objectOf({
  onChange: PropTypes.func,
  name: PropTypes.string,
  value: valueType,
});

export const customFieldReferenceType = PropTypes.shape({
  field_id: PropTypes.string.isRequired,
  field_name: PropTypes.string.isRequired,
  options: PropTypes.arrayOf(PropTypes.shape({
    key: PropTypes.string.isRequired,
    value: PropTypes.string.isRequired,
  })),
});

export const orderFormType = PropTypes.shape({
  // eslint-disable-next-line react/forbid-prop-types
  custom_field_values: PropTypes.arrayOf(PropTypes.object),
  customer_name: reduxFormFieldType,
  customer_email: reduxFormFieldType,
  shipping: PropTypes.shape({
    name: reduxFormFieldType,
    address: reduxFormFieldType,
    tracking: reduxFormFieldType,
    uri: reduxFormFieldType,
  }),
  initial_comment: reduxFormFieldType,
  name: reduxFormFieldType,
  priority: reduxFormFieldType,
  order_owner: reduxFormFieldType,
  customer_po: reduxFormFieldType,
  quote_number: reduxFormFieldType,
  ip_sensitivity: reduxFormFieldType,
  location: reduxFormFieldType,
  order_type: reduxFormFieldType,
  due_date: reduxFormFieldType,
  region: reduxFormFieldType,
});

export const materialTypeFormType = PropTypes.shape({
  units: reduxFormFieldType,
  id: reduxFormFieldType,
  name: reduxFormFieldType,
  uuid: reduxFormFieldType,
  description: reduxFormFieldType,
  manufacturer: reduxFormFieldType,
  color: reduxFormFieldType,
  type: reduxFormFieldType,
  material_family: reduxFormFieldType,
  printer_types_selected: reduxFormFieldType,
  density: reduxFormFieldType,
  cost: reduxFormFieldType,
  manufacturing_process: reduxFormFieldType,
  third_party_fulfillment: reduxFormFieldType,
  external_datasheet_url: reduxFormFieldType,
});

export const materialLotFormType = PropTypes.shape({
  name: reduxFormFieldType,
  material: reduxFormFieldType,
  location: reduxFormFieldType,
  delivery_id: reduxFormFieldType,
  delivery_date: reduxFormFieldType,
  expiration_date: reduxFormFieldType,
  number_of_containers: reduxFormFieldType,
  size_of_container: reduxFormFieldType,
});

export const finalFormInputTypes = PropTypes.shape({
  input: PropTypes.shape({
    value: PropTypes.string,
    onChange: PropTypes.func,
  }).isRequired,
});
