import React from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import Icon, { ICON_NAMES } from 'rapidfab/icons';
import { FormattedMessage } from 'rapidfab/i18n';
import Tooltip from 'rapidfab/components/Tooltip';
import {
  getCastorCostingForModelLibrary,
  getCastorCostingConfigsByCastorCostingUri,
} from 'rapidfab/selectors';
import { CASTOR_COSTING_CONFIG_MANUFACTURABILITY_OPTIONS } from 'rapidfab/constants';

const CastorCostingIssue = ({ uri }) => {
  const castorCosting = useSelector(state =>
    getCastorCostingForModelLibrary(state, uri),
  );
  const castorCostingConfigsByCastorCostingUri = useSelector(state =>
    getCastorCostingConfigsByCastorCostingUri(state),
  );

  const configs = castorCostingConfigsByCastorCostingUri?.[castorCosting?.uri];
  if (!castorCosting || !configs) return null;
  const notPrintable = configs.some(
    config =>
      config.additive_manufacturable ===
      CASTOR_COSTING_CONFIG_MANUFACTURABILITY_OPTIONS.NOT_PRINTABLE ||
      !config.additive_manufacturable,
  );
  if (!notPrintable) return null;
  return (
    <FormattedMessage id="field.reviewRequired" defaultMessage="Review required">
      {text => (
        <Tooltip
          id="CastorCostingIssueTooltip"
          trigger={(
            <span>
              <Icon name={ICON_NAMES.WARNING} size="default" />
            </span>
          )}
        >
          {text}
        </Tooltip>
      )}
    </FormattedMessage>
  );
};

CastorCostingIssue.propTypes = {
  uri: PropTypes.string.isRequired,
};

export default CastorCostingIssue;
