import React, { useRef } from 'react';
import PhInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css';

const PhoneInput = ({ ...inputProps }) => {
  const ref = useRef();

  return (
    <PhInput
      excludeCountries={['ru']}
      containerClass="react-phone-input__container"
      specialLabel=""
      inputClass="react-phone-input"
      dropdownClass="react-phone-input__dropdown"
      placeholder="Enter phone number"
      inputProps={{
        ref,
      }}
      {...inputProps}
    />
  );
};
export default PhoneInput;
