import React, { useMemo, useState } from 'react';
import { Card, FormControl, FormGroup, FormLabel, Button, Row, Col, Tab, Tabs } from 'react-bootstrap';
import { useSelector } from 'react-redux';
import { getModelLibrariesByModelUri, getModelsByUri, getSlicedModels } from 'rapidfab/selectors';
import round from 'lodash/round';
import ModelLibraryModal from 'rapidfab/components/modals/ModelLibraryModal';
import ModelLibraryInput from 'rapidfab/components/records/order/edit/ModelLibraryInput';
import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faDownload } from '@fortawesome/free-solid-svg-icons';
import SLICED_FILE_STATUS from 'rapidfab/constants/slicedFileStatuses';

import Loading from './Loading';

const SlicedModelView = ({ models }) => (
  <div className="card-list mb10">
    {models.map(model =>
      (
        <div
          data-cy={model.type}
          className="card"
          key={model.uri}
        >
          <img className="card-img-top" src={model.snapshot_content} alt="" />
          <div className="card-body">
            <div className="d-flex justify-content-between">
              <h5 className="card-title mb0">
                {model.name}
              </h5>
              {model.content ? (
                <a href={model.content}>
                  <FontAwesomeIcon icon={faDownload} />
                </a>
              ) : <h5 className="mb0 text-danger">error</h5>}
            </div>
          </div>
        </div>
      ),
    )}
  </div>
);

SlicedModelView.propTypes = {
  models: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
};

const SlicerTest = ({ initiateSlicingTest, slicedTestModel, isFetching, setSlicedTestModel }) => {
  const [modelLibraryModalIsOpen, setModelLibraryModalIsOpen] = useState(false);
  const [selectedModalToTest, setSelectedModalToTest] = useState(null);

  const modelLibrariesByModelUri = useSelector(getModelLibrariesByModelUri);
  const modelsByUri = useSelector(getModelsByUri);
  const slicedModelsForSlicerConfig = useSelector(getSlicedModels);

  const currentModelData = modelsByUri[selectedModalToTest?.additive.model];

  const modelsUsedWithSlicerConfig = useMemo(() => slicedModelsForSlicerConfig.map(slicedModel => {
    if (modelLibrariesByModelUri[slicedModel?.model]) {
      return { ...modelLibrariesByModelUri[slicedModel.model], ...slicedModel };
    }
    return null;
  },
  ).filter(model => model !== null),
  [
    JSON.stringify(slicedModelsForSlicerConfig),
    JSON.stringify(modelLibrariesByModelUri),
  ]);

  const openModelLibraryModel = () => setModelLibraryModalIsOpen(true);

  const closeModelLibraryModel = () => setModelLibraryModalIsOpen(false);
  const handleSelect = model => {
    setSelectedModalToTest(model);
    closeModelLibraryModel();
  };

  const testSlicerConfig = async () => {
    await initiateSlicingTest({
      model: selectedModalToTest?.additive?.model,
    });
  };

  const removeTestModel = () => {
    setSelectedModalToTest(null);
    setSlicedTestModel(null);
  };

  const TABS = {
    NEW: 'new',
    ALL: 'all',
  };

  const [activeTab, setActiveTab] = useState(TABS.NEW);

  return (
    <Card bg="dark">
      <Card.Header className="pd-exp inverse">Slicer Test</Card.Header>
      <Card.Body className="card-body-wrapper">
        {!selectedModalToTest && (
          <Tabs
            activeKey={activeTab}
            onSelect={tabName => setActiveTab(tabName)}
            className="mt10 mb10"
          >
            <Tab eventKey={TABS.NEW} title="New Test" />
            <Tab eventKey={TABS.ALL} title="All Test" />
          </Tabs>
        )}
        { activeTab === TABS.NEW ? (
          <Row>
            {!selectedModalToTest ? (
              <>
                <ModelLibraryInput fullWidth onClick={openModelLibraryModel} className="w-100" />
                {/* Will be needed in next iteration */}
                {/* <Button variant="primary" className="mb-1">Upload Model From File Explorer</Button> */}
              </>
            ) : (
              <Row className="justify-content-end">
                <Col>
                  <div
                    data-cy={selectedModalToTest.type}
                    className="card"
                  >
                    <img className="card-img-top" src={selectedModalToTest.snapshot_content} alt={selectedModalToTest.name} />
                    <div className="card-body">
                      <h5 className="card-title">
                        {selectedModalToTest.name}
                      </h5>
                    </div>
                  </div>
                </Col>
                <Col>
                  <FormGroup className="mb-2">
                    <FormLabel>Model Dimensions:</FormLabel>
                    <Row>
                      <Col>
                        <FormLabel>X:</FormLabel>
                        <FormControl disabled readOnly value={round(currentModelData.size.x, 2)} />
                      </Col>
                      <Col>
                        <FormLabel>Y:</FormLabel>
                        <FormControl disabled readOnly value={round(currentModelData.size.y, 2)} />
                      </Col>
                      <Col>
                        <FormLabel>Z:</FormLabel>
                        <FormControl disabled readOnly value={round(currentModelData.size.z, 2)} />
                      </Col>
                    </Row>

                  </FormGroup>
                  <FormGroup className="mb-2">
                    <Row>
                      <Col><FormLabel>File Units:</FormLabel>
                        <FormControl disabled readOnly value={currentModelData.file_unit} />
                      </Col>
                    </Row>
                  </FormGroup>
                  <Button
                    className="w-100"
                    variant="danger"
                    onClick={removeTestModel}
                  >Remove Test Model
                  </Button>
                </Col>
                <Row className="justify-content-end">
                  <Col lg={6} className="d-flex justify-content-end">

                    {/* Render button here */}
                    <>
                      {(slicedTestModel && SLICED_FILE_STATUS.SUCCESS === slicedTestModel.status) && (
                        <Button
                          className="spacer-right"
                          variant="success"
                          href={slicedTestModel.content}
                        > Download
                        </Button>
                      )}
                      {(slicedTestModel && SLICED_FILE_STATUS.ERROR === slicedTestModel.status) && (
                        <Button
                          className="spacer-right"
                          variant="danger"
                        >
                          Error
                        </Button>
                      )}
                    </>

                    {!slicedTestModel ? (
                      <Button
                        variant="primary"
                        onClick={testSlicerConfig}
                      >Run Slicer Test
                      </Button>
                    ) : (
                      <Button
                        disabled
                        variant="primary"
                      >
                        {isFetching ? (
                          <span>
                            Slicer Running...<Loading />
                          </span>
                        ) : 'Test Complete' }

                      </Button>
                    )}
                  </Col>

                </Row>
              </Row>
            )}
            {
              modelLibraryModalIsOpen && (
                <ModelLibraryModal
                  onHide={closeModelLibraryModel}
                  handleSelect={handleSelect}
                />
              )
            }
          </Row>
        ) : <SlicedModelView models={modelsUsedWithSlicerConfig} />}
      </Card.Body>

    </Card>
  );
};

SlicerTest.propTypes = {
  initiateSlicingTest: PropTypes.func.isRequired,
  slicedTestModel: PropTypes.shape({
    content: PropTypes.string.isRequired,
    status: PropTypes.string.isRequired,
  }).isRequired,
  isFetching: PropTypes.bool.isRequired,
  setSlicedTestModel: PropTypes.func.isRequired,
};

export default SlicerTest;
