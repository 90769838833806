import React from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import Actions from 'rapidfab/actions';
import {
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  ModalTitle,
  Button,
} from 'react-bootstrap';
import { FormattedMessage } from 'rapidfab/i18n';
import { API_RESOURCES, FEATURES, PAGINATION_IGNORE_DEFAULT_LIMIT } from 'rapidfab/constants';
import { getLocations } from 'rapidfab/selectors';
import { reduxFormFieldType } from 'rapidfab/types';
import StockFields from 'rapidfab/components/records/StockFields';

import { Form } from 'react-final-form';
import * as Selectors from 'rapidfab/selectors';

const MaterialTypeStockModal = ({
  onClose,
  fields,
  material,
}) => {
  const locations = useSelector(getLocations);
  const initialFormValues = { track_quantity: true };
  const isMaterialManagementFeatureEnabled = useSelector(state =>
    Selectors.isFeatureEnabled(state, FEATURES.MATERIAL_MANAGEMENT),
  );

  const dispatch = useDispatch();

  const onSave = async (payload, materialUri) => {
    const validatedPayload = { ...payload };
    if (!payload.track_quantity) {
      delete validatedPayload.quantity;
    } else {
      validatedPayload.quantity = Number.parseFloat(payload.quantity);
    }
    validatedPayload.material = materialUri;
    await dispatch(Actions.Api.nautilus[API_RESOURCES.STOCK].post(validatedPayload));
    dispatch(Actions.Api.nautilus[API_RESOURCES.STOCK].list(
      { material: materialUri },
      { limit: PAGINATION_IGNORE_DEFAULT_LIMIT },
    ));
  };

  const onSubmit = formValues => {
    onSave(formValues, material.uri);
    onClose();
  };
  return (
    <Modal show onHide={onClose} backdrop="static">
      <ModalHeader closeButton>
        <ModalTitle>Create Stock</ModalTitle>
      </ModalHeader>
      <Form
        onSubmit={onSubmit}
        render={({ handleSubmit }) => (
          <form onSubmit={handleSubmit}>
            <ModalBody>
              <StockFields
                fields={fields}
                locations={locations}
                initialFormValues={initialFormValues}
                isReadOnly={isMaterialManagementFeatureEnabled}
                material={material}
              />
            </ModalBody>
            <ModalFooter>
              <Button
                variant="success"
                type="submit"
              >
                <FormattedMessage
                  id="button.save"
                  defaultMessage="Save"
                />
              </Button>
            </ModalFooter>
          </form>
        )}
      />
    </Modal>
  );
};

MaterialTypeStockModal.propTypes = {
  onClose: PropTypes.func.isRequired,
  fields: PropTypes.shape({
    location: reduxFormFieldType,
    status: reduxFormFieldType,
    quantity: reduxFormFieldType,
    track_quantity: reduxFormFieldType,
  }).isRequired,
  material: PropTypes.shape({
    uri: PropTypes.string,
  }).isRequired,
};

export default MaterialTypeStockModal;
