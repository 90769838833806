import PropTypes from 'prop-types';
import { FormControlSelect } from 'rapidfab/components/formTools';
import UnitsTooltip from 'rapidfab/components/UnitsTooltip';
import {
  BATCHING_BY_NUMBER_OF_PARTS,
  BATCHING_BY_SIZE_TYPE_XY,
  BATCHING_BY_SIZE_TYPE_XYZ,
  BATCHING_TYPES,
} from 'rapidfab/constants';
import { finalFormInputTypes } from 'rapidfab/types';
import React, { useEffect, useState } from 'react';
import {
  Card,
  Col, FormControl,
  FormGroup,
  FormLabel,
  Row,
} from 'react-bootstrap';
import { Field, FormSpy } from 'react-final-form';
import { FormattedMessage } from 'react-intl';

const BatchTypeModal = ({
  fields,
  setCurrentBatchingStrategy,
  isBatchingStrategyDisabled,
}) => {
  const [form, setForm] = useState({ values: fields });
  const [wrongUnit, setWrongUnit] = useState([]);
  const message = 'Warning. The value you have specified is very small (less than 50 millimeters). This may prevent runs from properly being created. Please review this value carefully.';

  useEffect(() => {
    if (setCurrentBatchingStrategy && form.values?.batching_strategy) {
      setCurrentBatchingStrategy(form.values.batching_strategy);
    }
  }, [form.values?.batching_strategy]);

  const onPageUnitsCheck = () => {
    const { build_volume, batching_strategy } = form.values;

    // eslint-disable-next-line no-mixed-operators
    if (form.values.build_volume && batching_strategy === 'by_size_xy' || batching_strategy === 'by_size_xyz') {
      const itemsToWarn = [];
      Object.entries(build_volume).map(([name, item]) => {
        if (item && Number.parseFloat(item.value) < 50) {
          const id = `uxBuildVolume${name.toUpperCase()}`;
          itemsToWarn.push(id);
        }
        return itemsToWarn;
      });

      return itemsToWarn;
    }
    return [];
  };

  useEffect(() => {
    const warnings = onPageUnitsCheck();
    if (warnings.length) {
      setWrongUnit(warnings);
    }
  }, []);

  const onBlur = (event, id) => {
    if (Number(event.target.value) < 50 && event.target.id === id) {
      setWrongUnit([...wrongUnit, event.target.id]);
    } else {
      setWrongUnit(wrongUnit.filter(index => index !== id));
    }
  };

  const renderBuildVolume = () => {
    if (((form.values.batching_strategy === BATCHING_BY_SIZE_TYPE_XY) ||
      (form.values.batching_strategy === BATCHING_BY_SIZE_TYPE_XYZ))) {
      return (
        <>
          <Card className="custom-darken-modal--card mb15">
            <Card.Header className="pd-exp custom-darken-modal--card-header">
              <Row className="d-flex justify-content-between align-items-center">
                <Col lg={4}>
                  <FormattedMessage
                    id="buildPlate.setting"
                    defaultMessage="Build Plate"
                  />
                </Col>
              </Row>
            </Card.Header>
            <Card.Body className="custom-darken-modal--card-body">
              <Row>
                <Col sm={form.values.batching_strategy === BATCHING_BY_SIZE_TYPE_XYZ ? 4 : 6}>
                  <FormGroup controlId="uxBuildVolumeX" className="form-group">
                    <FormLabel>
                      <FormattedMessage
                        id="field.volumeX"
                        defaultMessage="Volume X"
                      />: *
                      {
                        wrongUnit.includes('uxBuildVolumeX') &&
                        <UnitsTooltip id="batching_strategy.units" message={message} />
                      }
                    </FormLabel>
                    <Field
                      name="build_volume.x"
                      type="number"
                      initialValue={fields?.build_volume?.x}
                      render={props => (
                        <FormControl
                          {...props.input}
                          required
                          onBlur={event => props.input.onBlur(event, 'uxBuildVolumeX')}
                        />
                      )}
                    />
                  </FormGroup>
                </Col>
                <Col sm={form.values.batching_strategy === BATCHING_BY_SIZE_TYPE_XYZ ? 4 : 6}>
                  <FormGroup controlId="uxBuildVolumeY" className="form-group">
                    <FormLabel>
                      <FormattedMessage
                        id="field.volumeY"
                        defaultMessage="Volume Y"
                      />: *
                      {
                        wrongUnit.includes('uxBuildVolumeY') &&
                        <UnitsTooltip id="batching_strategy.units" message={message} />
                      }
                    </FormLabel>
                    <Field
                      name="build_volume.y"
                      type="number"
                      initialValue={fields?.build_volume?.y}
                      render={props => (
                        <FormControl
                          {...props.input}
                          required
                          onBlur={event => onBlur(event, 'uxBuildVolumeY')}
                        />
                      )}
                    />
                  </FormGroup>
                </Col>
                <Col sm={4}>
                  {
                    (form.values.batching_strategy === BATCHING_BY_SIZE_TYPE_XYZ) && (
                      <FormGroup controlId="uxBuildVolumeZ" className="form-group">
                        <FormLabel>
                          <FormattedMessage
                            id="field.volumeZ"
                            defaultMessage="Volume Z"
                          />: *
                          {
                            wrongUnit.includes('uxBuildVolumeZ') &&
                            <UnitsTooltip id="batching_strategy.units" message={message} />
                          }
                        </FormLabel>
                        <Field
                          type="number"
                          name="build_volume.z"
                          initialValue={fields?.build_volume?.z}
                          render={props => (
                            <FormControl
                              {...props.input}
                              required
                              onBlur={event => onBlur(event, 'uxBuildVolumeZ')}
                            />
                          )}
                        />
                      </FormGroup>
                    )
                  }
                </Col>
              </Row>
              <p className="buildPlateVolumeMeasurementInfo">Measurements in millimeters</p>
            </Card.Body>
          </Card>

          <FormGroup controlId="minPackingDistance" className="form-group">
            <FormLabel>
              <FormattedMessage
                id="field.min_packing_distance"
                defaultMessage="Min Packing Distance (mm)"
              />: *
            </FormLabel>
            <Field
              name="min_packing_distance"
              type="number"
              initialValue={fields?.min_packing_distance}
              render={props => (
                <FormControl
                  {...props.input}
                  required
                />
              )}
            />
          </FormGroup>
        </>
      );
    }

    return null;
  };

  return (
    <>
      <FormGroup>
        <FormLabel>
          <FormattedMessage
            id="batchingStrategy"
            defaultMessage="Batching Strategy"
          />: *
        </FormLabel>
        <Field
          name="batching_strategy"
          render={props => (
            <FormControlSelect
              disabled={isBatchingStrategyDisabled}
              required
              className="mb15"
              {...props.input}
            >
              {BATCHING_TYPES.map(batchingType => (
                <option key={batchingType.value} value={batchingType.value}>
                  {batchingType.label}
                </option>
              ))}
            </FormControlSelect>
          )}
        />
      </FormGroup>

      {renderBuildVolume()}

      {
        form.values.batching_strategy === BATCHING_BY_NUMBER_OF_PARTS && (
          <Row>
            <Col xs={12} md={12}>

              <FormGroup controlId="uxPartsNumber" className="form-group">
                <FormLabel>
                  <FormattedMessage
                    id="field.parts_number"
                    defaultMessage="Number of Parts"
                  />: *
                </FormLabel>
                <Field
                  name="number_of_parts"
                  type="number"
                  initialValue={fields?.number_of_parts}
                  render={props => (
                    <FormControl
                      {...props.input}
                      required
                    />
                  )}
                />
              </FormGroup>
            </Col>
          </Row>
        )
      }
      <FormSpy
        subscription={{ values: true }}
        onChange={values => setForm(values)}
      />
    </>
  );
};

BatchTypeModal.defaultProps = {
  isBatchingStrategyDisabled: false,
  setCurrentBatchingStrategy: null,
};

BatchTypeModal.propTypes = {
  fields: PropTypes.shape({
    batching_strategy: PropTypes.shape({
      value: PropTypes.string,
    }),
    build_volume: PropTypes.shape({
      x: PropTypes.string,
      y: PropTypes.string,
      z: PropTypes.string,
    }),
    min_packing_distance: PropTypes.shape({}),
    number_of_parts: PropTypes.shape({}),
  }).isRequired,
  input: finalFormInputTypes.isRequired,
  isBatchingStrategyDisabled: PropTypes.bool,
  setCurrentBatchingStrategy: PropTypes.func,
};

export default BatchTypeModal;
