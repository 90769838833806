import { faBox, faCube } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import _camelCase from 'lodash/camelCase';
import BreadcrumbNav from 'rapidfab/components/BreadcrumbNav';
import Feature from 'rapidfab/components/Feature';
import { FEATURES, ROUTES } from 'rapidfab/constants';
import BuildsLibrariesContainer from 'rapidfab/containers/organize/BuildsLibrariesContainer';
import ModelLibrariesContainer from 'rapidfab/containers/organize/ModelLibrariesContainer';
import {
  isFeatureEnabled,
} from 'rapidfab/selectors';
import { getRouteURI, routeURIContains } from 'rapidfab/utils/uriUtils';
import React from 'react';
import { Card, Col, Container, ListGroup, ListGroupItem, Row } from 'react-bootstrap';
import { FormattedMessage } from 'react-intl';
import { useSelector } from 'react-redux';

const getLocationBreadcrumb = location => {
  let breadcrumb = location.split('/').at(-1);
  breadcrumb = breadcrumb.includes('library') && breadcrumb.replace('library', 'libraries');
  breadcrumb = _camelCase(breadcrumb);
  return breadcrumb;
};

const Libraries = () => {
  const isHawkingDeploymentFeatureEnabled = useSelector(state => isFeatureEnabled(state, FEATURES.HAWKING_DEPLOYMENT));
  const isCertifiedBuildsFeatureEnabled = useSelector(state => isFeatureEnabled(state, FEATURES.CERTIFIED_BUILDS));

  return (
    <Container fluid>
      <BreadcrumbNav breadcrumbs={['organize', getLocationBreadcrumb(window.location.hash)]} />
      <Row>
        {isCertifiedBuildsFeatureEnabled && !isHawkingDeploymentFeatureEnabled && (
          <Col className="mb15" md={2}>
            <Card bg="dark">
              <Card.Body className="p-a-0">
                <ListGroup>
                  <ListGroupItem
                    onClick={() => { window.location = getRouteURI(ROUTES.MODEL_LIBRARY); }}
                    className={window.location.hash.includes(ROUTES.MODEL_LIBRARY) && 'clearfix active'}
                  >
                    <p>
                      <FormattedMessage
                        id="organize.modelLibrary"
                        defaultMessage="Model Library"
                      />
                      <FontAwesomeIcon className="spacer-left" icon={faCube} />
                    </p>
                  </ListGroupItem>
                  <Feature
                    featureName={FEATURES.CERTIFIED_BUILDS}
                  >
                    <ListGroupItem
                      onClick={() => { window.location = getRouteURI(ROUTES.BUILDS_LIBRARY); }}
                      className={window.location.hash.includes(ROUTES.BUILDS_LIBRARY) && 'clearfix active'}
                    >
                      <p>
                        <FormattedMessage
                          id="organize.buildsLibrary"
                          defaultMessage="Builds Library"
                        />
                        <FontAwesomeIcon className="spacer-left" icon={faBox} />
                      </p>
                    </ListGroupItem>
                  </Feature>
                </ListGroup>
              </Card.Body>
            </Card>
          </Col>
        )}
        <Col>
          {routeURIContains(ROUTES.MODEL_LIBRARY) && (
            <ModelLibrariesContainer />
          )}
          {routeURIContains(ROUTES.BUILDS_LIBRARY) && (
            <BuildsLibrariesContainer />
          )}
        </Col>
      </Row>
    </Container>
  );
};

export default Libraries;
