import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Button, Card, Row, Col, Form } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { FormattedMessage } from 'react-intl';
import { FormControlSelect } from 'rapidfab/components/formTools';
import Loading from 'rapidfab/components/Loading';
import {
  LINE_ITEM_STATUS,
  PREP_TASK_RECORD_STATUSES,
  USER_SETTINGS_KEYS,
  USER_SETTINGS_VALUES,
  PREP_WORKFLOW_RECORD_END_STATUSES,
  PREP_WORKFLOW_RECORD_SUCCESS_END_STATUSES,
} from 'rapidfab/constants';
import _find from 'lodash/find';
import _filter from 'lodash/filter';
import _map from 'lodash/map';
import _findLastIndex from 'lodash/findLastIndex';
import ConfirmationModal from 'rapidfab/components/ConfirmationModal';
import PrepTaskRecordModalContainer from 'rapidfab/containers/track/PrepTaskRecordModalContainer';
import LineItemPrepWorkflowCompleteModal from 'rapidfab/components/records/order/edit/LineItemPrepWorkflowCompleteModal';
import { extractUuid } from 'rapidfab/utils/uuidUtils';
import { faAngleDown, faAngleUp, faPencil } from '@fortawesome/free-solid-svg-icons';
import PrepWorkflowModalContainer from '../../../../containers/plan/PrepWorkflowModalContainer';
import PrepTasksProgress from './PrepTaskProgress';

const LineItemPrepWorkflowPanel = ({
  lineItem,
  prepWorkflows,
  prepWorkflowRecord,
  prepTaskRecordsForWorkflow,
  prepTasksByUri,
  onPrepWorkflowSubmit,
  isLoading,
  isLineItemSaving,
  isPrepWorkflowRecordCreating,
  onPrepWorkflowStart,
  onLineItemStatusUpdate,
  onUserSettingsUpdate,
  onLineItemPrepWorkflowsSync,
  userSettings,
  loadPrepTaskRecordsForPrepWorkflowRecord,
  orderLineItems,
}) => {
  const [isCollapsed, setIsCollapsed] = useState(false);
  const [prepWorkflowUri, setPrepWorkflowUri] = useState(
    lineItem.prep_workflow,
  );

  const [
    selectedPrepTaskRecordUUIDForModal,
    setSelectedPrepTaskRecordUUIDForModal,
  ] = useState(null);
  const [isCustomizeModalShown, setIsCustomizeModalShown] = useState(false);

  const [isLocked, setIsLocked] = useState(false);
  const [showStartConfirmation, setShowStartConfirmation] = useState(false);

  const [
    previousPrepWorkflowRecordStatus,
    setPreviousPrepWorkflowRecordStatus,
  ] = useState(prepWorkflowRecord && prepWorkflowRecord.status);
  const [showCompleteWorkflowModal, setShowCompleteWorkflowModal] =
    useState(false);

  const autoStatusSelection =
    userSettings &&
    userSettings[
      USER_SETTINGS_KEYS.LINE_ITEM_AUTO_STATUS_ON_PREP_WORKFLOW_COMPLETE
    ];

  // In case at least one Line Item with other Prep Workflow exists - prep workflows are not synced
  const lineItemsWithNonSyncedWorkflow = _filter(
    orderLineItems,
    orderLineItem => orderLineItem.prep_workflow !== lineItem.prep_workflow,
  );

  const handlePrepWorkflowStatusChange = () => {
    if (prepWorkflowRecord.status === previousPrepWorkflowRecordStatus) {
      // No need to proceed in case status is the same
      return;
    }

    // Save current value as previous for further usage in any case
    setPreviousPrepWorkflowRecordStatus(prepWorkflowRecord.status);

    // Check if status was changed to any of end-states (but cancelled status is ignored)
    if (
      !previousPrepWorkflowRecordStatus ||
      PREP_WORKFLOW_RECORD_SUCCESS_END_STATUSES.includes(
        previousPrepWorkflowRecordStatus,
      ) ||
      !PREP_WORKFLOW_RECORD_SUCCESS_END_STATUSES.includes(
        prepWorkflowRecord.status,
      )
    ) {
      // No need to proceed is status was changed to some other status except complete or skipped
      return;
    }

    if (autoStatusSelection) {
      // If user checked `always apply` and clicked `Confirm` button
      if (
        autoStatusSelection ===
        USER_SETTINGS_VALUES.LINE_ITEM_AUTO_STATUS_ON_PREP_WORKFLOW_COMPLETE
          .SET_CONFIRMED
      ) {
        onLineItemStatusUpdate(lineItem.uri, LINE_ITEM_STATUS.CONFIRMED);
      }
    } else {
      // Check if user has saved setting or open modal otherwise
      setShowCompleteWorkflowModal(true);
    }
  };

  useEffect(() => {
    // Record is locked, when it is created for order (not line item)
    if (prepWorkflowRecord) {
      setIsLocked(!prepWorkflowRecord.line_item);

      handlePrepWorkflowStatusChange();
    }
  }, [prepWorkflowRecord]);

  const prepWorkflow =
    prepWorkflowUri && _find(prepWorkflows, { uri: prepWorkflowUri });

  const isPrepWorkflowChanged = lineItem.prep_workflow !== prepWorkflowUri;

  const onPrepWorkflowUriChange = event => {
    const {
      target: { value },
    } = event;
    setPrepWorkflowUri(value || null);
  };

  const onPrepWorkflowUriSave = () => {
    onPrepWorkflowSubmit(lineItem.uri, prepWorkflowUri);
  };

  useEffect(() => {
    setPrepWorkflowUri(lineItem.prep_workflow);
  }, [lineItem.prep_workflow]);

  const onPrepTaskRecordClick = prepTaskRecordUUID => {
    setSelectedPrepTaskRecordUUIDForModal(prepTaskRecordUUID);
  };

  const onPrepWorkflowStartClick = async () => {
    setShowStartConfirmation(false);
    if (isLocked && lineItemsWithNonSyncedWorkflow.length) {
      const orderLineItemUrisWithOtherPrepWorkflow = _map(
        lineItemsWithNonSyncedWorkflow,
        'uri',
      );
      await onLineItemPrepWorkflowsSync(
        lineItem.prep_workflow,
        orderLineItemUrisWithOtherPrepWorkflow,
      );
    }
    onPrepWorkflowStart(lineItem, isLocked);
  };

  const saveStatusChangeSelection = (saveSelection, selection) => {
    if (!saveSelection) {
      return;
    }

    onUserSettingsUpdate({
      ...userSettings,
      [USER_SETTINGS_KEYS.LINE_ITEM_AUTO_STATUS_ON_PREP_WORKFLOW_COMPLETE]:
        selection,
    });
  };

  const handleLineItemConfirm = async saveSelection => {
    await onLineItemStatusUpdate(lineItem.uri, LINE_ITEM_STATUS.CONFIRMED);
    saveStatusChangeSelection(
      saveSelection,
      USER_SETTINGS_VALUES.LINE_ITEM_AUTO_STATUS_ON_PREP_WORKFLOW_COMPLETE
        .SET_CONFIRMED,
    );
    setShowCompleteWorkflowModal(false);
  };

  const handleLineItemPending = saveSelection => {
    saveStatusChangeSelection(
      saveSelection,
      USER_SETTINGS_VALUES.LINE_ITEM_AUTO_STATUS_ON_PREP_WORKFLOW_COMPLETE
        .LEAVE_PENDING,
    );
    setShowCompleteWorkflowModal(false);
  };

  const onPrepWorkflowModalClose = newPrepWorkflowURI => {
    setIsCustomizeModalShown(false);
    if (newPrepWorkflowURI) {
      // Prep Workflow is replaced
      setPrepWorkflowUri(newPrepWorkflowURI);
    }
    loadPrepTaskRecordsForPrepWorkflowRecord(
      prepWorkflow.uri,
      prepWorkflowRecord.uri,
    );
  };

  const isPrepWorkflowChangeAllowed = !!prepWorkflowRecord;

  // When there is a PrepWorkflow selected and saved.
  const showStartWorkflowActions = prepWorkflowUri && !isPrepWorkflowChanged;

  // Order (line item, then order) matters,
  // User is not able to create custom prep workflow
  // if prepWorkflowRecord is not exist
  const customizePrepWorkflowForUri =
    isPrepWorkflowChangeAllowed &&
    (prepWorkflowRecord.line_item || prepWorkflowRecord.order);

  const prepWorkflowName = prepWorkflow && prepWorkflow.name;

  const startConfirmationMessage =
    isLocked && lineItemsWithNonSyncedWorkflow.length
      ? `Doing this will result in the Prep Workflow for all Line Items in this Order to be set to ${prepWorkflowName}. This action cannot be undone.`
      : `You are going to Start ${prepWorkflowName} Prep Workflow. This action can not be undone.`;

  return (
    <>
      <Card bg="dark">
        <Card.Header className="pd-exp inverse">
          <>
            <span>
              Prep Workflow
              {prepWorkflow && <span>: {prepWorkflow.name}</span>}
            </span>
            <Button
              className="pull-right"
              size="xs"
              onClick={() => setIsCollapsed(previous => !previous)}
            >
              <FontAwesomeIcon icon={isCollapsed ? faAngleDown : faAngleUp} />
            </Button>
          </>
        </Card.Header>
        <div className="card-body-wrapper">
          <Card.Body>
            {isLoading && <Loading />}
            {!isCollapsed && !isLoading && (
              <div>
                <Row>
                  <Col md={3}>
                    <FormControlSelect
                      id="prepWorkflow"
                      name="prepWorkflow"
                      value={prepWorkflowUri}
                      onChange={onPrepWorkflowUriChange}
                      disabled={isPrepWorkflowChangeAllowed}
                    >
                      <FormattedMessage id="field.none" defaultMessage="None">
                        {text => <option value="">{text}</option>}
                      </FormattedMessage>
                      {prepWorkflows.map(prepWorkflowOption => (
                        <option
                          key={prepWorkflowOption.uri}
                          value={prepWorkflowOption.uri}
                        >
                          {prepWorkflowOption.name}
                        </option>
                      ))}
                    </FormControlSelect>
                  </Col>
                  <Col md={3}>
                    {isPrepWorkflowChanged && !prepWorkflowRecord && (
                      <Button
                        variant="success"
                        disabled={isLineItemSaving}
                        onClick={onPrepWorkflowUriSave}
                      >
                        {isLineItemSaving && (
                          <>
                            <Loading inline />{' '}
                          </>
                        )}
                        <FormattedMessage
                          id="button.save"
                          defaultMessage="Save"
                        />
                      </Button>
                    )}
                    {
                      // If record is created and not completed yet, we allow to change prep workflow
                      prepWorkflowRecord &&
                        !PREP_WORKFLOW_RECORD_END_STATUSES.includes(
                          prepWorkflowRecord.status,
                        ) && (
                        <Button
                          variant="primary"
                          className="ml15"
                          disabled={isPrepWorkflowChanged}
                          onClick={() => setIsCustomizeModalShown(true)}
                        >
                          <FontAwesomeIcon icon={faPencil} />{' '}
                          <FormattedMessage id="edit" defaultMessage="Edit" />
                        </Button>
                      )
                    }
                  </Col>
                </Row>
                <PrepTasksProgress
                  prepWorkflow={prepWorkflow}
                  prepTasksByUri={prepTasksByUri}
                  prepTaskRecords={prepTaskRecordsForWorkflow}
                  onPrepTaskRecordClick={onPrepTaskRecordClick}
                />
                <div className="mt15">
                  {showStartWorkflowActions && (
                    <>
                      {!prepWorkflowRecord && (
                        <Button
                          className="pull-right ml15 mb15"
                          type="button"
                          variant="success"
                          disabled={isPrepWorkflowRecordCreating || lineItem.status !== LINE_ITEM_STATUS.PENDING}
                          onClick={() => setShowStartConfirmation(true)}
                        >
                          {isPrepWorkflowRecordCreating && (
                            <>
                              <Loading inline />{' '}
                            </>
                          )}
                          Start
                        </Button>
                      )}
                      <Form.Check
                        className="pull-right mb15"
                        checked={isLocked}
                        disabled={
                          isPrepWorkflowRecordCreating || !!prepWorkflowRecord
                        }
                        onChange={event => setIsLocked(event.target.checked)}
                        type="checkbox"
                        label="Lock for all Prep Workflows in Order"
                      />
                    </>
                  )}
                </div>
              </div>
            )}
          </Card.Body>
        </div>
      </Card>
      {isCustomizeModalShown && (
        <PrepWorkflowModalContainer
          prepWorkflowUUID={extractUuid(prepWorkflowUri)}
          customizeForUri={customizePrepWorkflowForUri}
          onClose={onPrepWorkflowModalClose}
          // Allow to change only tasks for prep workflow in new status
          currentActivePrepTaskIndex={_findLastIndex(
            prepTaskRecordsForWorkflow,
            taskRecord => taskRecord.status !== PREP_TASK_RECORD_STATUSES.NEW,
          )}
          withDeleteConfirmation
        />
      )}
      {selectedPrepTaskRecordUUIDForModal && (
        <PrepTaskRecordModalContainer
          prepTaskRecordUUID={selectedPrepTaskRecordUUIDForModal}
          onClose={() => setSelectedPrepTaskRecordUUIDForModal(null)}
        />
      )}
      {showStartConfirmation && (
        <ConfirmationModal
          handleCancel={() => setShowStartConfirmation(false)}
          handleConfirm={onPrepWorkflowStartClick}
          message={startConfirmationMessage}
        />
      )}
      {showCompleteWorkflowModal && (
        <LineItemPrepWorkflowCompleteModal
          isSubmitting={isLineItemSaving}
          onLineItemConfirm={handleLineItemConfirm}
          onLineItemLeavePending={handleLineItemPending}
          onClose={() => setShowCompleteWorkflowModal(false)}
          prepWorkflow={prepWorkflow}
          lineItemName={lineItem.name}
        />
      )}
    </>
  );
};

LineItemPrepWorkflowPanel.propTypes = {
  lineItem: PropTypes.shape({
    uri: PropTypes.string.isRequired,
    prep_workflow: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    status: PropTypes.string.isRequired,
  }).isRequired,
  prepWorkflows: PropTypes.arrayOf(
    PropTypes.shape({
      uri: PropTypes.string.isRequired,
      name: PropTypes.string.isRequired,
    }),
  ).isRequired,
  prepWorkflowRecord: PropTypes.shape({
    uri: PropTypes.string,
    line_item: PropTypes.string,
    order: PropTypes.string,
    status: PropTypes.string,
  }),
  prepTaskRecordsForWorkflow: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  prepTasksByUri: PropTypes.objectOf(
    PropTypes.shape({
      uri: PropTypes.string.isRequired,
      name: PropTypes.string.isRequired,
    }),
  ).isRequired,
  isLoading: PropTypes.bool.isRequired,
  isLineItemSaving: PropTypes.bool.isRequired,
  isPrepWorkflowRecordCreating: PropTypes.bool.isRequired,
  onPrepWorkflowSubmit: PropTypes.func.isRequired,
  onPrepWorkflowStart: PropTypes.func.isRequired,
  onLineItemStatusUpdate: PropTypes.func.isRequired,
  onUserSettingsUpdate: PropTypes.func.isRequired,
  onLineItemPrepWorkflowsSync: PropTypes.func.isRequired,
  loadPrepTaskRecordsForPrepWorkflowRecord: PropTypes.func.isRequired,
  userSettings: PropTypes.shape({}),
  orderLineItems: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
};

LineItemPrepWorkflowPanel.defaultProps = {
  prepWorkflowRecord: null,
  userSettings: null,
};

export default LineItemPrepWorkflowPanel;
