import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Modal, FormGroup, FormLabel, FormControl, Button } from 'react-bootstrap';
import { FormattedMessage } from 'react-intl';
import SelectSingle from 'rapidfab/components/forms/SelectSingle';
import { WORKFLOW_TYPES } from 'rapidfab/constants';

class WorkflowSpecimenFormModal extends Component {
  constructor(props) {
    super(props);

    // The modal gets mounted and unmounted on open and close, thus we populate every time we open
    this.state = {
      specimen: {
        quantity: '',
        model_library: '',
        workflow: '',
      },
    };

    this.handleChange = this.handleChange.bind(this);
    this.onSubmit = this.onSubmit.bind(this);
  }

  async componentDidMount() {
    const { data } = this.props;

    // Get all possible specimens when the "Add New Specimen" modal is opened.
    await this.props.handleGetAllSpecimens();

    if (data) {
      // eslint-disable-next-line react/no-did-mount-set-state
      this.setState({
        specimen: data,
      });
    }
  }

  handleChange(event) {
    const { specimen } = this.state;
    const { name, value } = event.target;
    specimen[name] = value;
    this.setState({ specimen });
  }

  onSubmit(event) {
    event.preventDefault();
    this.props.submit(this.state.specimen);
  }

  render() {
    const {
      show,
      close,
      data,
      modelLibraries,
      specimenWorkflows,
      isEditing,
    } = this.props;
    const { specimen } = this.state;

    return (
      <Modal show={show} onHide={close} backdrop="static">
        <form onSubmit={this.onSubmit}>
          <Modal.Header closeButton>
            <Modal.Title>{isEditing ? 'Edit' : 'Add'} Production Workflow Specimen</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <FormGroup controlId="formControlsSelect">
              <FormLabel>
                <FormattedMessage
                  id="field.model"
                  defaultMessage="Model"
                />
              </FormLabel>
              <FormControl
                as="select"
                name="model_library"
                onChange={this.handleChange}
                value={specimen.model_library}
                required
              >
                <option value="" selected disabled>
                  Select from the model library
                </option>
                {Object.values(modelLibraries)
                  .filter(modelLibrary => modelLibrary.type === WORKFLOW_TYPES.SPECIMEN)
                  .map(filteredModelLibrary => (
                    <option value={filteredModelLibrary.uri} key={filteredModelLibrary.uri}>
                      {filteredModelLibrary.name}
                    </option>
                  ))}
              </FormControl>
            </FormGroup>

            <FormGroup controlId="formControlsInput">
              <FormLabel>
                <FormattedMessage
                  id="field.quantity"
                  defaultMessage="Quantity"
                />
              </FormLabel>
              <FormControl
                type="number"
                name="quantity"
                min="1"
                required
                value={specimen.quantity}
                onChange={this.handleChange}
              />
            </FormGroup>

            <FormGroup controlId="formControlsSelect">
              <FormLabel>
                <FormattedMessage
                  id="workflow"
                  defaultMessage="Production Workflow"
                />
              </FormLabel>
              <SelectSingle
                placeholder="Select Specimen Production Workflow"
                name="workflow"
                data={specimenWorkflows}
                value={specimen.workflow}
                handleOnChange={this.handleChange}
                imitateOnChangeEvent
                required
              />
            </FormGroup>

          </Modal.Body>
          <Modal.Footer>
            <Button onClick={close}>
              <FormattedMessage id="button.cancel" defaultMessage="Cancel" />
            </Button>
            <Button type="submit" variant="success">
              {data ? (
                <FormattedMessage id="button.save" defaultMessage="Save" />
              ) : (
                <FormattedMessage id="button.add" defaultMessage="Add" />
              )}
            </Button>
          </Modal.Footer>
        </form>
      </Modal>
    );
  }
}

WorkflowSpecimenFormModal.defaultProps = {
  modelLibraries: {},
  data: {},
};

WorkflowSpecimenFormModal.propTypes = {
  close: PropTypes.func.isRequired,
  data: PropTypes.shape({}),
  modelLibraries: PropTypes.shape({}),
  specimenWorkflows: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  show: PropTypes.bool.isRequired,
  submit: PropTypes.func.isRequired,
  isEditing: PropTypes.bool.isRequired,
  handleGetAllSpecimens: PropTypes.func.isRequired,
};

export default WorkflowSpecimenFormModal;
