import React from 'react';
import PropTypes from 'prop-types';
import ReactMarkdown, { renderers as defaultRenderers } from 'react-markdown';
import { Badge } from 'react-bootstrap';
import _keyBy from 'lodash/keyBy';
import { useLocation } from 'react-router-dom';
import { API_RESOURCES } from 'rapidfab/constants';

const remarkEmoji = require('remark-emoji');
const remarkBreaks = require('remark-breaks');

const renderLabel = (name, labelColor = '#e6e337') => (
  <Badge
    className="badge-sm hawking-badge-secondary text-wrap py-0 w-fit-content text-start"
    style={{ lineHeight: 1.7 }}
  >
    <span className="small-status-dot spacer-right" style={{ backgroundColor: labelColor }} />
    {name}
  </Badge>
);

const Markdown = ({ source, labels }) => {
  const location = useLocation();
  const isModelLibraryRoute = location.pathname.includes(API_RESOURCES.MODEL_LIBRARY);

  const customRenderers = {
    ...defaultRenderers,
    link: ({ href, children }) => {
      if (href.startsWith('#') && isModelLibraryRoute) {
        const labelId = typeof children === 'string' ? children : children[0].props.children;
        const labelName = href.split('#')[1].replace(/-/g, ' '); // replace hyphen back to space
        const labelsById = _keyBy(labels, 'id');
        const label = labelsById[labelId];

        return label
          ? renderLabel(label.name, label.color)
          : renderLabel(labelName);
      }
      // Render as a normal link
      return (
        <a href={href}>{children}</a>
      );
    },
  };

  return (
    <ReactMarkdown
      className="markdown-styling"
      source={source}
      labels={labels}
      disallowedTypes={['image', 'html']}
      plugins={[
        [remarkEmoji, {}],
        [remarkBreaks, {}],
      ]}
      renderers={customRenderers}
    />
  );
};

Markdown.defaultProps = {
  labels: [],
};

Markdown.propTypes = {
  source: PropTypes.string.isRequired,
  labels: PropTypes.arrayOf(PropTypes.shape({})),
};

export default Markdown;
