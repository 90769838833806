import { createSelector } from 'reselect';
import { getMaterials } from 'rapidfab/selectors/material';
import _filter from 'lodash/filter';
import _keyBy from 'lodash/keyBy';

export const getMaterialsAlphabetized = createSelector(
  [getMaterials],
  materials => materials.sort((a, b) => a.name.localeCompare(b.name)),
);

export const getBaseAndSupportMaterials = createSelector(
  [getMaterialsAlphabetized],
  materials => {
    const base = _filter(materials, { type: 'base' });
    const support = _filter(materials, { type: 'support' });
    return { base, support };
  },
);

export const getBaseMaterialsByUri = createSelector(
  [getBaseAndSupportMaterials],
  ({ base }) => _keyBy(base, 'uri'),
);

export const getSupportMaterialsByUri = createSelector(
  [getBaseAndSupportMaterials],
  ({ support }) => _keyBy(support, 'uri'),
);

export const getBaseMaterialsByLocationUri = createSelector(
  [getBaseAndSupportMaterials],
  ({ base: baseMaterials }) => {
    const baseMaterialsByLocationUri = {};
    baseMaterials.forEach(material => {
      const { stock_locations } = material;
      stock_locations.forEach(locationUri => {
        if (!baseMaterialsByLocationUri[locationUri]) {
          baseMaterialsByLocationUri[locationUri] = [];
        }
        baseMaterialsByLocationUri[locationUri].push(material);
      });
    });
    return baseMaterialsByLocationUri;
  },
);
