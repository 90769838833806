import React from 'react';
import PropTypes from 'prop-types';
import {
  Button,
  OverlayTrigger,
  Tooltip,
} from 'react-bootstrap';
import {
  PREP_TASK_RECORD_ACTIVE_STATUSES,
  PREP_TASK_RECORD_END_STATUSES,
} from 'rapidfab/constants';
import _find from 'lodash/find';
import _findLastIndex from 'lodash/findLastIndex';
import _isEmpty from 'lodash/isEmpty';
import splitTasksListIntoParts from 'rapidfab/utils/splitTasksListIntoParts';
import PrepTaskProgressItemContainer from 'rapidfab/containers/records/order/PrepTaskProgressItemContainer';

const NextPreviousCount = ({
  tasksInWorkflow,
  prepTasksByUri,
  countClassName,
  getPrepTaskRecord,
  onPrepTaskRecordClick,
}) => {
  const count = tasksInWorkflow.length;
  if (!count) return null;
  return (
    <OverlayTrigger
      placement="bottom"
      delayHide={2000}
      overlay={(
        <Tooltip>
          <div className="tooltip-content">
            {tasksInWorkflow.map(taskInWorkflow => {
              const { position, prep_task: prepTaskUri } = taskInWorkflow;
              const task = prepTasksByUri[prepTaskUri];
              const prepTaskRecord = getPrepTaskRecord(taskInWorkflow) || {};
              const isDisabled = _isEmpty(prepTaskRecord);
              return (
                <div key={position}>
                  <Button
                    className="p-a-0"
                    variant="link"
                    role="button"
                    tabIndex={position}
                    disabled={isDisabled}
                    onClick={
                      isDisabled ? null : () => onPrepTaskRecordClick(prepTaskRecord.uri)
                    }
                  >
                    {position + 1}. {task.shortname}
                  </Button>
                </div>
              );
            })}
          </div>
        </Tooltip>
      )}
    >
      <div className={countClassName}>{count}</div>
    </OverlayTrigger>
  );
};
NextPreviousCount.propTypes = {
  tasksInWorkflow: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  prepTasksByUri: PropTypes.shape({}).isRequired,
  countClassName: PropTypes.string.isRequired,
  getPrepTaskRecord: PropTypes.func.isRequired,
  onPrepTaskRecordClick: PropTypes.func.isRequired,
};

const PrepTasksProgress = ({
  prepWorkflow,
  prepTaskRecords,
  prepTasksByUri,
  onPrepTaskRecordClick,
}) => {
  if (!prepWorkflow || !prepWorkflow.tasks_in_workflow.length) {
    return null;
  }

  // Assuming there is only 1 task can be in active state
  // (Queued/In-Progress/On-Hold) at the same time, for now
  // When there are no `active` tasks - find latest `end` task (completed/skipped/cancelled)
  const activeStatuses = new Set([
    ...PREP_TASK_RECORD_ACTIVE_STATUSES,
    ...PREP_TASK_RECORD_END_STATUSES,
  ]);
  const currentCenterTaskIndex =
    _findLastIndex(prepTaskRecords, ({ status }) => activeStatuses.has(status));

  const {
    previous: previousTasksInWorkflow,
    middle: visibleTasksInWorkflow,
    next: nextTasksInWorkflow,
  } = splitTasksListIntoParts(prepWorkflow.tasks_in_workflow, currentCenterTaskIndex);

  const getPrepTaskRecord =
    taskInWorkflow => _find(prepTaskRecords, { prep_task_in_workflow: taskInWorkflow.uuid });

  return (
    <div className="stepper mt15">
      <NextPreviousCount
        tasksInWorkflow={previousTasksInWorkflow}
        prepTasksByUri={prepTasksByUri}
        countClassName="stepper-prev-count"
        getPrepTaskRecord={getPrepTaskRecord}
        onPrepTaskRecordClick={onPrepTaskRecordClick}
      />
      {visibleTasksInWorkflow.map(taskInWorkflow => {
        const { position, prep_task: prepTaskUri } = taskInWorkflow;
        return (
          <PrepTaskProgressItemContainer
            key={position}
            taskInWorkflow={taskInWorkflow}
            prepTaskRecord={getPrepTaskRecord(taskInWorkflow)}
            prepTask={prepTasksByUri[prepTaskUri]}
            onClick={onPrepTaskRecordClick}
          />
        );
      })}
      <NextPreviousCount
        tasksInWorkflow={nextTasksInWorkflow}
        prepTasksByUri={prepTasksByUri}
        countClassName="stepper-next-count"
        getPrepTaskRecord={getPrepTaskRecord}
        onPrepTaskRecordClick={onPrepTaskRecordClick}
      />
    </div>
  );
};

PrepTasksProgress.propTypes = {
  prepWorkflow: PropTypes.shape({
    tasks_in_workflow: PropTypes.arrayOf(PropTypes.shape({
      position: PropTypes.number,
      prep_task: PropTypes.string,
    })),
  }),
  prepTasksByUri: PropTypes.objectOf(PropTypes.shape({})).isRequired,
  onPrepTaskRecordClick: PropTypes.func.isRequired,
  prepTaskRecords: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
};

PrepTasksProgress.defaultProps = {
  prepWorkflow: null,
};

export default PrepTasksProgress;
