import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import _truncate from 'lodash/truncate';
import PropTypes from 'prop-types';
import { getRouteURI } from 'rapidfab/utils/uriUtils';
import React from 'react';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import { Link } from 'react-router-dom';

const TruncatedTitle = ({
  title,
  titleClassName,
  icon,
  iconClassName,
  inline,
  maxTextLength,
  tooltipPlacement = 'top',
  style,
  titleLinkToProps,
}) => {
  if (!title) return null;
  const [route, params = {}, options = {}, flag = false] = titleLinkToProps ?? [];

  if (titleLinkToProps && !route) {
    throw new Error('titleLinkToProps must have a route');
  }

  const renderTitleWithTooltip = () => {
    if (titleLinkToProps) {
      return (
        <Link to={getRouteURI(route, params, options, flag)}>
          {_truncate(title, { length: maxTextLength })}
        </Link>
      );
    }
    return (
      <span className={titleClassName}>
        {_truncate(title, { length: maxTextLength })}
      </span>
    );
  };

  const renderTitleWithoutTooltip = () => {
    if (titleLinkToProps) {
      return (
        <Link to={getRouteURI(route, params, options, flag)}>
          {title}
        </Link>
      );
    }
    return (
      <span className={titleClassName}>
        {title}
      </span>
    );
  };

  return (
    <p className={`mb0 ${inline ? 'd-inline-block' : ''}`} style={style}>
      {icon && <FontAwesomeIcon className={`spacer-right ${iconClassName}`} icon={icon} />}
      {title.length >= maxTextLength ? (
        <OverlayTrigger placement={tooltipPlacement} overlay={<Tooltip>{title}</Tooltip>}>
          {renderTitleWithTooltip()}
        </OverlayTrigger>
      ) : renderTitleWithoutTooltip()}
    </p>
  );
};

TruncatedTitle.propTypes = {
  title: PropTypes.string,
  icon: PropTypes.shape({}),
  iconClassName: PropTypes.string,
  maxTextLength: PropTypes.number.isRequired,
  tooltipPlacement: PropTypes.string,
  titleClassName: PropTypes.string,
  inline: PropTypes.bool,
  style: PropTypes.shape({}),
  titleLinkToProps: PropTypes.arrayOf([PropTypes.string, PropTypes.shape({}), PropTypes.bool]),
};

TruncatedTitle.defaultProps = {
  title: PropTypes.string,
  icon: null,
  titleClassName: '',
  iconClassName: '',
  tooltipPlacement: 'top',
  inline: false,
  style: {},
  titleLinkToProps: null,
};

export default TruncatedTitle;
