import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { extractUuid } from 'rapidfab/utils/uuidUtils';
import { FEATURES } from 'rapidfab/constants';
import { FormattedDate, FormattedLength } from 'rapidfab/i18n';
import { Draggable } from 'react-drag-and-drop';
import Feature from 'rapidfab/components/Feature';
import PriorityLabel from 'rapidfab/components/records/run/PriorityLabel';
import ModalThreeScene from 'rapidfab/components/ModalThreeScene';
import { runPieceResourceType } from 'rapidfab/types';
import { getLineItemWorkflowTypeObjectKey } from 'rapidfab/utils/lineItemUtils';
import { ExportControlWarningIcon } from 'rapidfab/components/ExportControl';

export default class RunPieceItem extends Component {
  constructor(props) {
    super(props);
    this.state = {};
    this.handleClick = this.handleClick.bind(this);
  }

  handleClick(event, piece) {
    const { setFirstItem, onShiftToggle } = this.props;
    if (event.shiftKey) {
      onShiftToggle(piece);
    } else {
      setFirstItem(piece);
    }
  }

  render() {
    // eslint-disable-next-line react/prop-types
    const { piece, selected, labelRelationshipForCurrentPiece } = this.props;
    const workflowTypeKey = getLineItemWorkflowTypeObjectKey(piece.lineItem);
    const { model } = piece.lineItem[workflowTypeKey];

    return (
      <Draggable type="buildplatepiece" data={piece.uri}>
        <tr
          onClick={event => this.handleClick(event, piece)}
          className={selected ? 'selected-row ' : null}
        >
          <td className="hidden">{piece.uuid}</td>
          <td className="render-column">
            <ModalThreeScene
              snapshot={model.snapshot_content}
              model={model.content}
              unit={model.user_unit}
              fileUnit={model.file_unit}
              size={model.size}
              volume={model.volume_mm}
              showMfgOrientationPanel={false}
            />
          </td>
          <td>
            <a href={`#/records/order/${extractUuid(piece.order)}`}>
              {`${piece.order_name} (${piece.name})`}
            </a>
            {labelRelationshipForCurrentPiece && (
              <ExportControlWarningIcon
                exportControlLabel={labelRelationshipForCurrentPiece.label}
              />
            )}
          </td>
          <td>
            <FormattedLength
              length={piece.lineItem[workflowTypeKey].model.size.x}
              roundPrecision={0}
              lengthUnits={piece.lineItem[workflowTypeKey].model.file_unit}
            />{' × '}
            <FormattedLength
              length={piece.lineItem[workflowTypeKey].model.size.y}
              roundPrecision={0}
              lengthUnits={piece.lineItem[workflowTypeKey].model.file_unit}
            />{' × '}
            <FormattedLength
              length={piece.lineItem[workflowTypeKey].model.size.z}
              roundPrecision={0}
              lengthUnits={piece.lineItem[workflowTypeKey].model.file_unit}
            />
          </td>
          <td>
            <a
              href={`#/inventory/materials?uuid=${extractUuid(piece.material)}`}
            >
              {piece.material_name}
            </a>
          </td>
          <td>{piece.layer_thickness}</td>
          <td>
            {piece.order_due_date && (
              <FormattedDate value={piece.order_due_date} />
            )}
          </td>
          <Feature featureName={FEATURES.PRIORITY}>
            <td>
              <PriorityLabel value={piece.priority} />
            </td>
          </Feature>
        </tr>
      </Draggable>
    );
  }
}

RunPieceItem.propTypes = {
  piece: runPieceResourceType.isRequired,
  selected: PropTypes.bool.isRequired,
  onShiftToggle: PropTypes.func.isRequired,
  setFirstItem: PropTypes.func.isRequired,
  labelRelationshipForCurrentPiece: PropTypes.shape({
    label: PropTypes.shape({}),
  }).isRequired,
};
