function truncateLargeNumber(number, keepStart = 2, keepEnd = 3) {
  // Convert the number to a string
  const numberString = number.toString();

  // If the number is too small to truncate, return as is
  if (numberString.length <= keepStart + keepEnd) {
    return numberString;
  }

  // Keep first 'keepStart' digits and last 'keepEnd' digits
  const start = numberString.slice(0, keepStart);
  const end = numberString.slice(-keepEnd);

  // Return the truncated version
  return `${start}...${end}`;
}

export default truncateLargeNumber;
