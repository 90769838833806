import _get from 'lodash/get';
import _filter from 'lodash/filter';
import _includes from 'lodash/includes';
import _map from 'lodash/map';
import { THREADS_API_RESOURCES } from 'rapidfab/constants';
import { createSelector } from 'reselect';
import { getPredicate } from 'rapidfab/selectors/helpers/base';

// eslint-disable-next-line import/prefer-default-export
export const getThreadsApiThreads = state =>
  _get(state.ui.threads_api[THREADS_API_RESOURCES.THREADS], 'list.json.results');

// Find all threads->references that reference the URI we pass in.
export const getThreadsApiRelatedThreadsForUriResource = createSelector(
  [getPredicate, getThreadsApiThreads],
  (providedUri, threads) => _filter(threads, thread => {
    const threadReferenceUrls = _map(thread.references, 'url');
    return _includes(threadReferenceUrls, providedUri);
  }),
);
