import _isEmpty from 'lodash/isEmpty';
import PropTypes from 'prop-types';
import React, { useCallback } from 'react';
import { Col, Container, Row } from 'react-bootstrap';

import _groupBy from 'lodash/groupBy';
import _map from 'lodash/map';
import BreadcrumbNav from 'rapidfab/components/BreadcrumbNav';
import Limit from 'rapidfab/components/RecordList/Limit';
import Pagination from 'rapidfab/components/RecordList/Pagination';
import Search from 'rapidfab/components/RecordList/Search';
import SubLocations from 'rapidfab/components/SubLocations';
import Table from 'rapidfab/components/Tables/Table';
import { extractUuid } from 'rapidfab/utils/uuidUtils';
import Locations from '../locations';
import TableInlineFilters from './TableInlineFilters';

const TableWithSearching = props => {
  const {
    tableID,
    data,
    limit,
    search,
    offset,
    navbar,
    navbarLeading,
    columns,
    onSearch,
    listStore,
    subheader,
    locations,
    breadcrumbs,
    inlineExtraFilters,
    onInlineExtraFilterChange,
    extraFilters,
    extraToggle,
    onPageChange,
    onLimitChange,
    withBreadcrumbs,
    showLocationsFilter,
    showSubLocationsFilter = false,
    isManualSoringEnabled,
    manualSortingFunc,
    initialSortedDesc,
    initialSortedColumn,
    isFetching,
    withoutDivider,
    isUpdatedColumnShown = true,
    isDebugModeEnabled = false,
    grouping,
    onSetPage,
    skipOffset,
    tableHeadComponent,
    customFilters,
    tableHeadColSpan = 10,
    paginationSinglePanOnly = false,
  } = props;
  const areLocationsShown = showLocationsFilter && locations?.length > 0;
  const showSubLocations = areLocationsShown && showSubLocationsFilter;

  const initialGroupData = { groupDisplayLabel: '', groupDisplayId: '' };

  const getTableComponent = (
    /** Data records to display. */
    data,
    /** Table rendering options. */
    { paginationComponent = null,
      isGrouped = false,
      /** Grouped data records. */
      groupData = initialGroupData },
  ) => (
    <Table
      tableID={tableID}
      data={data}
      skipOffset={skipOffset}
      columns={columns}
      isFetching={isFetching}
      isFilteringEnabled={false}
      withDefaultPagination={false}
      isUpdatedColumnShown={isUpdatedColumnShown}
      isManualSoringEnabled={isManualSoringEnabled}
      manualSortingFunc={manualSortingFunc}
      initialSortedDesc={initialSortedDesc}
      initialSortedColumn={initialSortedColumn}
      isDebugModeEnabled={isDebugModeEnabled}
      PaginationComponent={paginationComponent}
      isGrouped={isGrouped}
      groupData={groupData}
    />
  );

  const paginationComponent = (
    <Row>
      <Col>
        <Pagination
          limit={limit}
          listStore={listStore}
          offset={offset}
          onPageChange={onPageChange}
          onSetPage={onSetPage}
          singlePanOnly={paginationSinglePanOnly}
        />

      </Col>
      <Col lg={2}>
        <Limit
          limit={limit}
          onLimitChange={onLimitChange}
        />
      </Col>
    </Row>
  );

  const getTableGroupDisplayData = useCallback(groupingKey => {
    const fallback = { groupDisplayLabel: groupingKey };
    if (!grouping?.selected || !grouping?.groupDisplayLabelField || !grouping?.selected[groupingKey]) {
      return fallback;
    }
    const { selected, groupDisplayLabelField } = grouping;
    const groupDisplayLabel = selected[groupingKey][groupDisplayLabelField] || groupingKey;
    const groupDisplayUuid = extractUuid(selected[groupingKey]?.uri);
    return {
      groupDisplayLabel,
      groupDisplayUuid,
    };
  }, [grouping]);

  const renderTable = useCallback(() => {
    const groupedData = _groupBy(data, grouping?.value);
    // Return grouped UI if grouping is set.
    if (grouping) {
      return (
        <>
          {_map(Object.entries(groupedData), ([groupingKey, groupedResources]) => (
            <React.Fragment key={groupingKey}>
              {getTableComponent(
                groupedResources,
                { isGrouped: true, groupData: getTableGroupDisplayData(groupingKey) },
              )}
            </React.Fragment>
          ))}
          <Container className="m-t">
            {paginationComponent}
          </Container>
        </>
      );
    }

    return getTableComponent(data, { paginationComponent });
  }, [data, grouping, paginationComponent]);

  return (
    <Container fluid>
      {
        withBreadcrumbs && breadcrumbs && (
          <BreadcrumbNav breadcrumbs={breadcrumbs} />
        )
      }
      {subheader && (
        <Row>
          <Col xs={12} className="mb8">
            {subheader}
          </Col>
        </Row>
      )}

      <Row>
        <Col xs={showSubLocations ? 3 : 8}>
          {areLocationsShown ? (
            <Locations />
          ) : (
            <div />
          )}
        </Col>
        {
          showSubLocations && (
            <Col xs={5}>
              <SubLocations />
            </Col>
          )
        }
        {
          navbar && (
            <>
              {navbarLeading && (
                <Col xs={8}>
                  {navbarLeading}
                </Col>
              )}
              <Col xs={4}>
                {navbar}
              </Col>
            </>
          )
        }
      </Row>

      {!withoutDivider && <hr />}

      <Row>
        <Col xs={12}>
          <Row>
            <Col xs={{ span: 12 }} lg={{ span: tableHeadColSpan, offset: skipOffset ? 0 : 1 }}>
              <div className="d-flex w-full align-items-center justify-content-between">
                <Search
                  initialSearch={search}
                  onSearch={onSearch}
                />
                {extraToggle}
                {!_isEmpty(inlineExtraFilters) && (
                  <TableInlineFilters
                    filters={inlineExtraFilters}
                    values={customFilters}
                    onChange={onInlineExtraFilterChange}
                  />
                )}
              </div>
            </Col>
          </Row>
          {!_isEmpty(extraFilters) && (
            <Row className="mb8">
              <Col xs={{ span: 12 }} lg={{ span: 10, offset: skipOffset ? 0 : 1 }}>
                <div className="filters-label">Filters:</div>
                {extraFilters}
              </Col>
            </Row>
          )}
          <Row className="mb8">
            <Col xs={{ span: 12 }} lg={{ span: 10, offset: skipOffset ? 0 : 1 }}>
              {tableHeadComponent}
            </Col>
          </Row>
          {renderTable()}
        </Col>
      </Row>
    </Container>
  );
};

/* eslint-disable react/require-default-props */

TableWithSearching.defaultProps = {
  onSetPage: null,
  skipOffset: false,
  tableHeadComponent: null,
  tableHeadColSpan: 10,
  paginationSinglePanOnly: false,
};

TableWithSearching.propTypes = {
  tableID: PropTypes.string,
  data: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  columns: PropTypes.arrayOf(PropTypes.shape({
    type: PropTypes.string.isRequired,
    accessor: PropTypes.string.isRequired,
    uid: PropTypes.string.isRequired,
    uri: PropTypes.string,
    defaultMessage: PropTypes.string,
    route: PropTypes.string,
    short: PropTypes.bool,
    slug: PropTypes.string,
    role: PropTypes.string,
    func: PropTypes.func,
    equals: PropTypes.string,
    users: PropTypes.shape({}),
    params: PropTypes.shape({}),
    resource: PropTypes.string,
    resources: PropTypes.objectOf(PropTypes.shape({
      uri: PropTypes.string,
      name: PropTypes.string,
    })),
    customFieldReference: PropTypes.string,
    modelers: PropTypes.objectOf(PropTypes.shape({})),
    shipment: PropTypes.string,
    suffix: PropTypes.string,
    mapping: PropTypes.objectOf(PropTypes.shape({})),
    coloured: PropTypes.bool,
    colorScheme: PropTypes.string,
  })).isRequired,
  isFetching: PropTypes.bool,
  initialSortedColumn: PropTypes.string,
  initialSortedDesc: PropTypes.bool,
  isManualSoringEnabled: PropTypes.bool,
  listStore: PropTypes.shape({}).isRequired,
  filters: PropTypes.shape({
    location: PropTypes.string,
  }).isRequired,
  offset: PropTypes.number.isRequired,
  limit: PropTypes.number.isRequired,
  search: PropTypes.string,
  manualSortingFunc: PropTypes.func,
  onPageChange: PropTypes.func.isRequired,
  onLimitChange: PropTypes.func.isRequired,
  onSearch: PropTypes.func.isRequired,
  withoutDivider: PropTypes.bool,
  withBreadcrumbs: PropTypes.bool,
  showLocationsFilter: PropTypes.bool,
  extraFilters: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.shape({})),
    PropTypes.shape({}),
  ]),
  subheader: PropTypes.element,
  navbar: PropTypes.element,
  navbarLeading: PropTypes.element,
  breadcrumbs: PropTypes.arrayOf(PropTypes.string),
  locations: PropTypes.arrayOf(PropTypes.shape({})),
  isUpdatedColumnShown: PropTypes.bool,
  isDebugModeEnabled: PropTypes.bool,
  showSubLocationsFilter: PropTypes.bool,
  inlineExtraFilters: PropTypes.arrayOf(PropTypes.shape({})),
  onInlineExtraFilterChange: PropTypes.func.isRequired,
  grouping: PropTypes.shape({
    value: PropTypes.string,
    groupDisplayLabelField: PropTypes.string,
    selected: PropTypes.shape({}),
  }),
  onSetPage: PropTypes.func,
  skipOffset: PropTypes.bool,
  extraToggle: PropTypes.oneOfType([PropTypes.element, undefined]),
  /** Custom component optionally inserted below filter UI and above table. */
  tableHeadComponent: PropTypes.element,
  tableHeadColSpan: PropTypes.number,
  paginationSinglePanOnly: PropTypes.bool,
  customFilters: PropTypes.objectOf(PropTypes.shape({})).isRequired,
};

export default TableWithSearching;
