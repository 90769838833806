import React, { useState } from 'react';

import { Downtime } from 'rapidfab/components/downtime';
import { useSelector } from 'react-redux';
import { getCustomDowntimeFieldReferences, getCustomFieldsByUri } from 'rapidfab/selectors';

const DowntimeContainer = props => {
  const customDowntimeFieldReferences = useSelector(getCustomDowntimeFieldReferences);
  const customFieldsByUri = useSelector(getCustomFieldsByUri);
  const [selection, setSelection] = useState('none');

  const selected = {
    customDowntimeFieldReferences,
    customFieldsByUri,
  };

  const handleSelectionChange = value => setSelection(value);

  return (
    <Downtime
      {...props}
      {...selected}
      selection={selection}
      handleSelectionChange={handleSelectionChange}
    />
  );
};

export default DowntimeContainer;
