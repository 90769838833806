import React from 'react';
import PropTypes from 'prop-types';

import { Nav, NavItem, NavLink } from 'react-bootstrap';
import { FEATURES, ROUTES } from 'rapidfab/constants';
import { getRouteURI } from 'rapidfab/utils/uriUtils';
import Feature from 'rapidfab/components/Feature';

const FeedstocksTabs = ({ activeKey }) => (
  <Nav
    variant="tabs"
    activeKey={activeKey}
    // `undefined` is needed to allow `href` in NavItem-s
    onSelect={undefined}
    id="workstationTabs"
  >
    <Feature isInverted featureName={FEATURES.POWDER_WORKFLOW}>
      <NavItem>
        <NavLink eventKey="printers" href={getRouteURI(ROUTES.PRINTERS)}>
          Printers
        </NavLink>
      </NavItem>
    </Feature>
    <Feature featureName={FEATURES.MANUFACTURING_EXECUTION_SYSTEM}>
      <NavItem>
        <NavLink eventKey="post-processors" href={getRouteURI(ROUTES.POST_PROCESSORS)}>
          Post Processors
        </NavLink>
      </NavItem>
    </Feature>
    <Feature featureName={FEATURES.SERVICE_PROVIDER_AT_STEPS}>
      <NavItem>
        <NavLink eventKey="post-processor-services" href={getRouteURI(ROUTES.POST_PROCESSOR_SERVICES)}>
          Post Processor Services
        </NavLink>
      </NavItem>
    </Feature>
    <Feature featureName={FEATURES.ADDIGURU}>
      <NavItem>
        <NavLink eventKey="addiguru-installations" href={getRouteURI(ROUTES.ADDIGURU_INSTALLATIONS)}>
          Addiguru Installations
        </NavLink>
      </NavItem>
    </Feature>
  </Nav>
);

FeedstocksTabs.propTypes = {
  activeKey: PropTypes.oneOf(['printers', 'post-processors', 'post-processor-services', 'addiguru-installations']).isRequired,
};

export default FeedstocksTabs;
