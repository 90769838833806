import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import Actions from 'rapidfab/actions';
import * as Selectors from 'rapidfab/selectors';
import ReviewOrder from 'rapidfab/components/records/order/restricted/review/ReviewOrder';
import Loading from 'rapidfab/components/Loading';
import { loadOrderRelatedUsers } from 'rapidfab/dispatchers/order';
import { API_RESOURCES, FEATURES,
  DDW_PAYMENT_SYSTEM_TYPE,
  EVENT_STREAM_SELECTOR_RESOURCES } from 'rapidfab/constants';
import { handleInitializeShoppingCartData } from 'rapidfab/utils/shoppingCartUtils';
import { extractUuid } from 'rapidfab/utils/uuidUtils';
import Alert from 'rapidfab/utils/alert';
import { DDW_PAYMENT_STATUSES } from 'rapidfab/constants/statuses';

const ReviewRestrictedOrder = () => {
  const dispatch = useDispatch();
  const [isListeningForPaymentEvents, setIsListeningForPaymentEvents] = useState(false);

  const routeUUID = useSelector(Selectors.getRouteUUID);
  const bureau = useSelector(Selectors.getBureauUri);
  const order = useSelector(state => Selectors.getUUIDResource(state, routeUUID));
  const shoppingCart = useSelector(state => Selectors.getShoppingCartForOrder(state, order?.uri));
  const payment = useSelector(state => Selectors.getPaymentsFilterableByOrder(state, order?.uri))[0];

  const latestPaymentEvent = useSelector(state =>
    Selectors.getStateEventStreamEvents(state, EVENT_STREAM_SELECTOR_RESOURCES.PAYMENT));

  const isStripeIntegrationFeatureEnabled = useSelector(state =>
    Selectors.isFeatureEnabled(state, FEATURES.INTEGRATION_STRIPE));
  const isPaymentSystemFeatureEnabled = useSelector(state =>
    Selectors.isFeatureEnabled(state, FEATURES.PAYMENT_SYSTEM));
  const isDigitalDesignWarehouseFeatureEnabled = useSelector(state =>
    Selectors.isFeatureEnabled(state, FEATURES.DIGITAL_DESIGN_WAREHOUSE));
  const isRobozeDDWFeatureEnabled = useSelector(state =>
    Selectors.isFeatureEnabled(state, FEATURES.ROBOZE_DDW));
  const isRobozeBureauOrderFieldsFeatureEnabled = useSelector(state => Selectors.isFeatureEnabled(
    state,
    FEATURES.ROBOZE_GIGAFACTORY_BUREAU_ORDER_FIELDS,
  ));

  const shoppingCartItems = useSelector(state =>
    Selectors.getShoppingCartItemsForShoppingCartUri(state, shoppingCart?.uri));
  const materials = useSelector(Selectors.getMaterials);
  const modelLibrariesByUri = useSelector(Selectors.getModelLibrariesByUri);
  const modelsByUri = useSelector(Selectors.getModelsByUri);

  const isPaymentSubmitting = useSelector(state =>
    state.ui.nautilus[API_RESOURCES.PAYMENT].post.fetching
      || state.ui.nautilus[API_RESOURCES.PAYMENT].post.fetching
      || isListeningForPaymentEvents);

  const isPaymentLoading = useSelector(state =>
    state.ui.nautilus[API_RESOURCES.PAYMENT].list.fetching
    || state.ui.nautilus[API_RESOURCES.PAYMENT].get.fetching);

  // All selected resources for the cost breakdown table in `Review Order`
  const costBreakdownTableSelectedResources = {
    shoppingCartItems,
    materials,
    modelsByUri,
    modelLibrariesByUri,
  };

  const refreshPaymentApiData = async () => {
    dispatch(Actions.Api.nautilus[API_RESOURCES.PAYMENT].list({
      payment_for: order?.uri,
    }, {}, {}, {}, true));
  };

  const refreshPaymentAndShoppingCartApiData = async () => {
    await refreshPaymentApiData();
    dispatch(Actions.Api.nautilus[API_RESOURCES.SHOPPING_CART].list({
      order: order?.uri,
    }, {}, {}, {}, true));
  };

  const onInitialize = () => {
    // Fetch order and related resources
    dispatch(Actions.Api.nautilus[API_RESOURCES.ORDER].get(routeUUID)).then(response => {
      const currentOrder = response?.json;
      if (currentOrder) {
        loadOrderRelatedUsers(dispatch, currentOrder);

        if ([
          isDigitalDesignWarehouseFeatureEnabled,
          isRobozeDDWFeatureEnabled]
          .every(Boolean)) {
          // Get related shopping carts for this order
          dispatch(Actions.Api.nautilus[API_RESOURCES.SHOPPING_CART].list({
            order: currentOrder.uri,
          }));
        }

        if (isPaymentSystemFeatureEnabled) {
          // Get related payment for this order
          dispatch(Actions.Api.nautilus[API_RESOURCES.PAYMENT].list({
            payment_for: currentOrder.uri,
          }));
        }
      }
    });

    dispatch(Actions.Api.nautilus[API_RESOURCES.LOCATION].list());
    dispatch(Actions.Api.nautilus[API_RESOURCES.MATERIAL].list({ bureau }));
    dispatch(Actions.Api.nautilus[API_RESOURCES.USERS].list());
    dispatch(Actions.Api.nautilus[API_RESOURCES.WORKFLOW].list({ include_custom_workflows: true }));
  };

  const handlePaymentEvents = async () => {
    if (latestPaymentEvent?.payload.checkout_url) {
      // Redirect to stripe checkout url.
      window.open(latestPaymentEvent?.payload.checkout_url, '_blank').focus();

      // 5 seconds timeout; seems to ensure the status is refreshed properly.
      const timeoutDelay = 5000;

      setTimeout(() => {
        refreshPaymentApiData();
      }, timeoutDelay);
    } else if (latestPaymentEvent?.payload.status === 'complete') {
      refreshPaymentApiData();
      setIsListeningForPaymentEvents(false);
    }
  };

  const handleCreatePayment = async () => {
    // If a payment already exists and its status is complete.
    if (payment?.status === DDW_PAYMENT_STATUSES.COMPLETE) {
      Alert.error(`You have already completed your payment for ${extractUuid(payment.uri)}, please try refreshing this page.`);
      return;
    }

    if (!order || !order?.uri) {
      Alert.error("Couldn't create payment, order URI cannot be found.");
      return;
    }

    const payload = {
      payment_for: order?.uri,
      payment_system: isStripeIntegrationFeatureEnabled ?
        DDW_PAYMENT_SYSTEM_TYPE.STRIPE
        : DDW_PAYMENT_SYSTEM_TYPE.MANUAL,
    };

    await dispatch(Actions.Api.nautilus[API_RESOURCES.PAYMENT].post(payload));

    if (isStripeIntegrationFeatureEnabled) {
      setIsListeningForPaymentEvents(true);
    }

    refreshPaymentAndShoppingCartApiData();
  };

  useEffect(() => {
    if (isStripeIntegrationFeatureEnabled && isListeningForPaymentEvents) {
      handlePaymentEvents();
    }
  }, [latestPaymentEvent, isListeningForPaymentEvents]);

  useEffect(() => {
    onInitialize();
  }, []);

  useEffect(() => {
    if (shoppingCart
      && !isPaymentSubmitting
       && [isDigitalDesignWarehouseFeatureEnabled, isRobozeDDWFeatureEnabled].every(Boolean)) {
      handleInitializeShoppingCartData(dispatch, extractUuid(shoppingCart?.uri));
    }
  }, [shoppingCart, isPaymentSubmitting]);

  const loading = !order || !routeUUID;

  return (
    <div>
      {loading ? <Loading /> : (
        <ReviewOrder
          shoppingCart={shoppingCart}
          handleCreatePayment={handleCreatePayment}
          payment={payment}
          refreshPaymentApiData={refreshPaymentApiData}
          isPaymentSubmitting={isPaymentSubmitting}
          isPaymentLoading={isPaymentLoading}
          isStripeIntegrationFeatureEnabled={isStripeIntegrationFeatureEnabled}
          isRobozeBureauOrderFieldsFeatureEnabled={isRobozeBureauOrderFieldsFeatureEnabled}
          {...costBreakdownTableSelectedResources}
        />
      )}
    </div>
  );
};

ReviewRestrictedOrder.propTypes = {
  route: PropTypes.shape({
    uuid: PropTypes.string.isRequired,
  }).isRequired,
  order: PropTypes.shape({}).isRequired,
};

export default ReviewRestrictedOrder;
