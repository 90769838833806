import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { getUUIDResource } from '../../selectors';
import { API_RESOURCES } from '../../constants';
import Actions from '../../actions';
import Nebumind from '../../components/records/piece/Nebumind';

const NebumindContainer = ({ uuid }) => {
  const dispatch = useDispatch();
  const nebumind = useSelector(state => getUUIDResource(state, uuid));
  const isFetching = useSelector(state => state.ui.nautilus[API_RESOURCES.NEBUMIND_ANALYSIS].list.fetching);

  const getNebumind = uuid => {
    dispatch(Actions.Api.nautilus[API_RESOURCES.NEBUMIND_ANALYSIS].get(uuid));
  };

  useEffect(() => {
    if (!nebumind && uuid) {
      getNebumind(uuid);
    }
  }, [uuid, nebumind]);

  return (
    <Nebumind isFetching={isFetching} nebumind={nebumind} />
  );
};

NebumindContainer.propTypes = {
  uuid: PropTypes.string.isRequired,
};

export default NebumindContainer;
