import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Loading from 'rapidfab/components/Loading';
import AdminUserGroups from 'rapidfab/components/admin/AdminUserGroups';
import Actions from 'rapidfab/actions';
import isFetchingInitial from 'rapidfab/utils/isFetchingInitial';
import { getGroups, getCustomGroups } from 'rapidfab/selectors';
import { API_RESOURCES } from 'rapidfab/constants';

const AdminUserGroupsContainer = () => {
  const isLoading = useSelector(state => isFetchingInitial(state.ui.nautilus[API_RESOURCES.CUSTOM_GROUP].list));
  const groups = useSelector(getCustomGroups);
  const systemGroups = useSelector(getGroups);
  const groupsFetching = useSelector(state => state.ui.nautilus[API_RESOURCES.CUSTOM_GROUP].list.fetching);
  const systemGroupsFetching = useSelector(state => state.ui.nautilus[API_RESOURCES.GROUPS].list.fetching);

  const dispatch = useDispatch();

  const loadGroups = () => dispatch(Actions.Api.nautilus[API_RESOURCES.CUSTOM_GROUP].list({}, {}, {}, { sort: 'created' }));
  const loadSystemGroups = () => dispatch(Actions.Api.nautilus[API_RESOURCES.GROUPS].list({}, {}, {}, { sort: 'name' }));
  const getUsersByGroup = group => {
    dispatch(Actions.Api.nautilus[API_RESOURCES.USERS].clear());
    return dispatch(Actions.Api.nautilus[API_RESOURCES.USERS].list({ group }, {}, {}, { sort: 'name' }));
  };

  useEffect(() => {
    loadGroups();
    loadSystemGroups();
  }, []);

  const saveGroup = async (groupUUID, payload) => {
    if (groupUUID) {
      await dispatch(Actions.Api.nautilus[API_RESOURCES.CUSTOM_GROUP].put(groupUUID, payload));
      return dispatch(Actions.Api.nautilus[API_RESOURCES.CUSTOM_GROUP].get(groupUUID, true));
    }

    await dispatch(Actions.Api.nautilus[API_RESOURCES.CUSTOM_GROUP].post(payload));
    return dispatch(Actions.Api.nautilus[API_RESOURCES.CUSTOM_GROUP].list({}, {}, {}, { sort: 'created' }));
  };

  const saveGroupMembers = async (groupUUID, payload) => {
    await dispatch(Actions.Api.nautilus[API_RESOURCES.CUSTOM_GROUP].put(groupUUID, payload));
    return dispatch(Actions.Api.nautilus[API_RESOURCES.CUSTOM_GROUP].get(groupUUID, true));
  };

  const deleteGroup = async groupUUID => {
    await dispatch(Actions.Api.nautilus[API_RESOURCES.CUSTOM_GROUP].delete(groupUUID));
    return dispatch(Actions.Api.nautilus[API_RESOURCES.CUSTOM_GROUP].list({}, {}, {}, { sort: 'created' }));
  };

  if (isLoading) {
    return (<Loading />);
  }

  return isLoading ? <Loading /> : (
    <AdminUserGroups
      groups={groups}
      systemGroups={systemGroups}
      saveGroup={saveGroup}
      saveGroupMembers={saveGroupMembers}
      deleteGroup={deleteGroup}
      getUsersByGroup={getUsersByGroup}
      fetchingState={{ groupsFetching, systemGroupsFetching }}
    />
  );
};

export default AdminUserGroupsContainer;
