import _groupBy from 'lodash/groupBy';
import { createSelector } from 'reselect';
import * as baseStateSelectors from 'rapidfab/selectors/baseStateSelectors';
import { getStateResources, getPredicate } from 'rapidfab/selectors/helpers/base';
import _map from 'lodash/map';
import _keyBy from 'lodash/keyBy';
import _find from 'lodash/find';

export const getUsers = createSelector(
  [baseStateSelectors.getStateUsers, getStateResources],
  (uuids, resources) => _map(uuids, uuid => resources[uuid]),
);

export const getUsersByUsername = createSelector([getUsers], users =>
  _keyBy(users, 'username'),
);

export const getUsersByUri = createSelector([getUsers], users =>
  _keyBy(users, 'uri'),
);

export const getUserFindableByUri = createSelector(
  [getPredicate, getUsers],
  (uri, users) => _find(users, ({ uri })),
);

// Selector to get users and sort them alphabetically
export const getUsersSorted = createSelector(
  [getUsers],
  users => users.sort((a, b) => a.name.localeCompare(b.name)),
);

// Method to group users by the first letter of their name
export const getUsersGroupedByLetter = createSelector(
  [getUsersSorted],
  users => _groupBy(users, user => user.name.charAt(0).toUpperCase()),
);
