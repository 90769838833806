import React, { useState } from 'react';
import { Button, Col, FormControl, FormGroup, FormLabel, Modal, Row } from 'react-bootstrap';
import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faClose, faPlus, faSave } from '@fortawesome/free-solid-svg-icons';
import { FormattedMessage } from 'react-intl';
import _isEmpty from 'lodash/isEmpty';

const NewUnitSection = ({
  onClose,
  onSave,
}) => {
  const [newUnitFormValues,
    setNewUnitFormValues] = useState({
    name: null,
    symbol: null,
  });

  return (
    <Row style={{ marginTop: 25 }}>
      <h3>New Test Unit</h3>
      <Col xs={10}>
        <FormGroup>
          <FormLabel>
            <FormattedMessage
              id="field.name"
              defaultMessage="Name"
            />: *
          </FormLabel>
          <FormControl
            placeholder="Name"
            type="text"
            onChange={event =>
              setNewUnitFormValues({
                ...newUnitFormValues,
                name: event.target.value,
              })}
            required
          />
        </FormGroup>
        <FormGroup className="mt15">
          <FormLabel>
            Units Symbol: *
          </FormLabel>
          <FormControl
            placeholder="cm"
            type="text"
            onChange={event =>
              setNewUnitFormValues({
                ...newUnitFormValues,
                symbol: event.target.value,
              })}
            required
          />
        </FormGroup>
      </Col>
      <Col className="d-flex align-items-center justify-content-center" xs={2}>
        <FontAwesomeIcon
          type="submit"
          className="mr15"
          role="button"
          onClick={() => {
            if (!_isEmpty(newUnitFormValues.name)
              && !_isEmpty(newUnitFormValues.symbol)
            ) {
              onSave(newUnitFormValues);
            }
          }}
          icon={faSave}
        />
        <FontAwesomeIcon
          role="button"
          onClick={onClose}
          icon={faClose}
        />
      </Col>
    </Row>
  );
};

NewUnitSection.propTypes = {
  onClose: PropTypes.func.isRequired,
  onSave: PropTypes.func.isRequired,
};

const ManageMaterialTestUnitsModal = ({
  show,
  materialTestUnits,
  onClose,
  handleSaveMaterialTestUnits,
}) => {
  const [
    showAddNewUnitSection,
    setShowAddNewUnitSection,
  ] = useState(false);

  const [newUnits, setNewUnits] = useState([]);

  const handleSaveNewUnit = newUnitFormData => {
    setNewUnits(previous => [...previous, newUnitFormData]);
  };

  return (
    <Modal show={show}>
      <Modal.Header onHide={onClose} closeButton>
        <h2>
          <FormattedMessage
            id="materialTests.manageTestUnits"
            defaultMessage="Manage Test Units"
          />
        </h2>
      </Modal.Header>
      <Modal.Body>
        <FormGroup>
          <div className="d-flex justify-content-between">
            <FormLabel>
              Units: *
            </FormLabel>
          </div>
          <FormControl
            as="select"
            type="select"
            multiple
            onChange={() => {}}
            name="units"
            required
          >
            {materialTestUnits.map(unit => (
              <option key={unit.symbol} value={unit.uri}>
                {unit?.description} ({unit?.symbol})
              </option>
            ))}
            {newUnits.map(newlyAddedUnit => (
              <option key={newlyAddedUnit.symbol} value={newlyAddedUnit.name}>
                *{newlyAddedUnit.name} ({newlyAddedUnit.symbol})
              </option>
            ))}
          </FormControl>
        </FormGroup>
        <div className="w-100 mt15">
          {showAddNewUnitSection ? (
            <NewUnitSection
              onClose={() => setShowAddNewUnitSection(false)}
              onSave={newUnitFormValues => {
                handleSaveNewUnit(newUnitFormValues);
                setShowAddNewUnitSection(false);
              }}
            />
          ) :
            (
              <div className="pull-right">
                <Button onClick={() => setShowAddNewUnitSection(true)}>
                  <FormattedMessage
                    id="materialTests.addNewUnit"
                    defaultMessage="Add New Unit"
                  />
                  <FontAwesomeIcon
                    className="spacer-left"
                    icon={faPlus}
                  />
                </Button>
              </div>
            )}
        </div>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="danger" onClick={onClose}>
          <FormattedMessage
            id="button.cancel"
            defaultMessage="Cancel"
          />
        </Button>
        <Button
          variant="success"
          onClick={() => {
            onClose();
            handleSaveMaterialTestUnits(newUnits);
          }}
        >
          <FormattedMessage
            id="button.save"
            defaultMessage="Save"
          />
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default ManageMaterialTestUnitsModal;

ManageMaterialTestUnitsModal.propTypes = {
  onClose: PropTypes.func.isRequired,
  handleSaveMaterialTestUnits: PropTypes.func.isRequired,
  show: PropTypes.bool.isRequired,
  materialTestUnits: PropTypes.arrayOf(PropTypes.shape()).isRequired,
};
