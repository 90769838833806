import React from 'react';
import PropTypes from 'prop-types';
import {
  Button,
  ButtonToolbar,
  Col,
  Card,
  Container,
  Row,
  OverlayTrigger,
  Tooltip,
} from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { FormattedMessage } from 'react-intl';
import Error from 'rapidfab/components/error';
import Loading from 'rapidfab/components/Loading';
import Locations from 'rapidfab/components/locations';
import Orders from 'rapidfab/components/home/Orders';
import MyActions from 'rapidfab/components/home/MyActions';
import 'rapidfab/styles/componentStyles/home.scss';
import { ROUTES } from 'rapidfab/constants';
import { getRouteURI } from 'rapidfab/utils/uriUtils';
import UseNonMfgLanguageFeature from 'rapidfab/components/generalMfgLanguage/UseNonMfgLanguageFeature';
import DebugModeFeature from 'rapidfab/components/DebugModeFeature';
import { faFile, faList, faQuestionCircle } from '@fortawesome/free-solid-svg-icons';
import OrdersByStatusChart from './home/OrdersByStatusChart';

const Home = ({ fetchingRuns, apiErrors, data }) => (
  <Container fluid>
    <Row className="vertically-aligned">
      <Col xs={8}>
        <Locations />
      </Col>
      <Col xs={4}>
        <ButtonToolbar className="pull-right">
          <Button
            variant="primary"
            size="sm"
            href={getRouteURI(ROUTES.ORDER_NEW)}
          >
            <FontAwesomeIcon icon={faFile} className="spacer-right" />
            <FormattedMessage
              id="record.order.add"
              defaultMessage="Add Order"
            />
          </Button>
          <Button
            variant="primary"
            size="sm"
            href={getRouteURI(ROUTES.RUN_CREATE)}
            style={{ marginLeft: '10px' }}
          >
            <FontAwesomeIcon icon={faList} className="spacer-right" />
            <UseNonMfgLanguageFeature
              mfgLanguageComponent={(
                <FormattedMessage
                  id="record.run.add"
                  defaultMessage="Add Run"
                />
              )}
              nonMfgLanguageComponent={(
                <FormattedMessage
                  id="mfg.addRun.Schedule"
                  defaultMessage="Schedule"
                />
              )}
            />
          </Button>
        </ButtonToolbar>
      </Col>
    </Row>

    <hr />

    <Row>
      <Col xs={12}>
        <Error errors={apiErrors} />
      </Col>
    </Row>

    <Row>
      <Col md={6}>
        <Card bg="dark">
          <Card.Header className="pd-exp inverse">
            <DebugModeFeature>
              <FormattedMessage
                id="plan.orders"
                defaultMessage="Orders"
              />
            </DebugModeFeature>
          </Card.Header>
          <div className="card-body-wrapper" id="home-order-table" data-cy="home-order-table">
            <Orders />
          </div>
        </Card>
      </Col>
      <Col md={6}>
        <Card bg="dark">
          <Card.Header className="pd-exp inverse">
            <span>
              <FormattedMessage
                id="recentOrders"
                defaultMessage="Recent Orders"
              />
            </span>
            <OverlayTrigger
              placement="top"
              overlay={(
                <Tooltip>
                  <FormattedMessage
                    id="recentOrderStatuses"
                    defaultMessage="Order statuses for the last 60 days"
                  />
                </Tooltip>
              )}
            >
              <FontAwesomeIcon className="spacer-left spacer-right" icon={faQuestionCircle} />
            </OverlayTrigger>
          </Card.Header>
          <div className="card-body-wrapper">
            <Card.Body>
              {fetchingRuns ? (
                <Loading />
              ) : (
                <OrdersByStatusChart data={data.orderStatus} />
              )}
            </Card.Body>
          </div>
        </Card>
      </Col>
    </Row>
    <Row className="with-padding">
      <Col md={6}>
        <Card bg="dark" data-cy="my-actions">
          <Card.Header className="pd-exp inverse">
            <FormattedMessage
              id="myActions"
              defaultMessage="My Actions"
            />
          </Card.Header>
          <div className="card-body-wrapper">
            <MyActions />
          </div>
        </Card>
      </Col>
    </Row>
  </Container>
);

Home.propTypes = {
  data: PropTypes.shape({
    lastTenOrders: PropTypes.arrayOf(PropTypes.shape({})),
    orderStatus: PropTypes.arrayOf(PropTypes.number),
  }).isRequired,
  fetchingRuns: PropTypes.bool.isRequired,
  apiErrors: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
};

export default Home;
