import { match } from 'path-to-regexp';

export function extractUuid(uri) {
  /*
   * Looks like uuid is not validated, so any values are considered to be as uuid.
   *
   * TODO Maybe it makes sense to use getEndpointFromURI(uri).uuid,
   *  but please check for efficiency since method is used for a lot of places
   */

  const matchPattern = match(':protocol//:domain/:resource/:uuid/');
  const matchPatternWithSubResource = match(':protocol//:domain/:resource/:sub_resource/:uuid/');

  /**
   * If the URI contains sub routes, e.g:
   * api-url.com/resource-name/SUB-RESOURCE-NAME/.../
   */
  if (matchPatternWithSubResource(uri)) {
    const matchPattern = match(':protocol//:domain/:sub_resource/:resource/:uuid');
    const matchUri = matchPattern(uri);
    return matchUri.params.uuid;
  }

  const matchUri = matchPattern(uri);
  if (!matchUri || !matchUri.params.uuid) {
    return null;
  }

  return matchUri.params.uuid;
}

export function getShortUUID(originalUUID) {
  let activeUUID = originalUUID;

  // It's possibly can be uri, let's try to extract uuid from URI
  const extractedUUID = extractUuid(originalUUID);
  if (extractedUUID !== null) {
    activeUUID = extractedUUID;
  }

  return activeUUID?.toString().slice(0, 8);
}

export const isUuid = string =>
  /^[\da-f]{8}-[\da-f]{4}-[1-5][\da-f]{3}-[89ab][\da-f]{3}-[\da-f]{12}$/i.test(
    string,
  );
