import React from 'react';
import PropTypes from 'prop-types';
import { Table, Card, Row, Col } from 'react-bootstrap';
import _sumBy from 'lodash/sumBy';
import { stockSummaryResourceType, subLocationResourceType } from 'rapidfab/types';
import Loading from 'rapidfab/components/Loading';

const SubLocationTableHeader = () => (
  <thead>
    <tr>
      <th />
      <th>
        Lots - Inventory (On Order)
      </th>
      <th>
        Lots - Inventory (Available)
      </th>
      <th>
        Batches - Inventory (Available)
      </th>
    </tr>
  </thead>
);

const SubLocationTableBody = ({ subLocations, stockSummary, units, stockSummaryLoading }) => {
  const total = {
    materialLotQuantityOnOrder: _sumBy(stockSummary, 'material_lot_quantity_on_order'),
    materialLotQuantityAvailable: _sumBy(stockSummary, 'material_lot_quantity_available'),
    batchesQuantityAvailable: _sumBy(stockSummary, 'batches_quantity_available'),
  };

  const renderTableValue = (value, valueUnits) => {
    if (stockSummaryLoading) {
      return <Loading inline />;
    }

    if ((!value && value !== 0) && !stockSummaryLoading) {
      return 'N/A';
    }

    return `${value} ${valueUnits}`;
  };

  return (
    <tbody>
      {subLocations.map((subLocation => {
        const stockData = stockSummary.find(object => object.sub_location === subLocation.uri);
        return (
          <tr key={subLocation.uri}>
            <td>
              <strong>
                {subLocation.name}
              </strong>
            </td>
            <td>
              {renderTableValue(stockData?.material_lot_quantity_on_order, units)}
            </td>
            <td>
              {renderTableValue(stockData?.material_lot_quantity_available, units)}
            </td>
            <td>
              {renderTableValue(stockData?.batches_quantity_available, units)}
            </td>
          </tr>
        );
      }
      ))}
      <tr className="fw-bold">
        <td>Total</td>
        <td>
          {renderTableValue(total.materialLotQuantityOnOrder, units)}
        </td>
        <td>
          {renderTableValue(total.materialLotQuantityAvailable, units)}
        </td>
        <td>
          {renderTableValue(total.batchesQuantityAvailable, units)}
        </td>
      </tr>
    </tbody>
  );
};

SubLocationTableBody.propTypes = {
  subLocations: PropTypes.arrayOf(subLocationResourceType).isRequired,
  stockSummary: PropTypes.arrayOf(stockSummaryResourceType).isRequired,
  units: PropTypes.string.isRequired,
  stockSummaryLoading: PropTypes.bool.isRequired,
};

const MaterialStockSubLocationTable = ({ subLocations, stockSummary, units, stockSummaryLoading }) =>
  (
    <Card bg="dark">
      <Card.Header className=" inverse">Stock Overview</Card.Header>

      <Card.Body>
        <Row>
          <Col xs={{ span: 12 }}>
            <div className="d-flex justify-content-between mb15">
              <Table bordered size="sm" className="m-b-0">
                <SubLocationTableHeader />
                <SubLocationTableBody
                  subLocations={subLocations}
                  stockSummary={stockSummary}
                  units={units}
                  stockSummaryLoading={stockSummaryLoading}
                />
              </Table>
            </div>
          </Col>
        </Row>
      </Card.Body>

    </Card>
  );

MaterialStockSubLocationTable.propTypes = {
  subLocations: PropTypes.arrayOf(subLocationResourceType).isRequired,
  stockSummary: PropTypes.arrayOf(stockSummaryResourceType).isRequired,
  units: PropTypes.string.isRequired,
  stockSummaryLoading: PropTypes.bool.isRequired,
};

export default MaterialStockSubLocationTable;
