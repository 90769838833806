import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import * as Selectors from 'rapidfab/selectors';

import ProcessProvider from 'rapidfab/components/records/ProcessProvider';
import Actions from 'rapidfab/actions';
import { extractUuid } from 'rapidfab/utils/uuidUtils';
import Alert from 'react-s-alert';
import { API_RESOURCES } from 'rapidfab/constants';
import { FormattedMessage } from 'react-intl';

const ProcessProviderContainer = props => {
  const initialValues = props.processProvider || {};
  const isServiceProviderRole = useSelector(Selectors.isServiceProvider);
  const fetching = useSelector(state => state.ui.nautilus[API_RESOURCES.PROCESS_PROVIDER].put.fetching ||
    state.ui.nautilus[API_RESOURCES.PROCESS_PROVIDER].post.fetching ||
    state.ui.nautilus[API_RESOURCES.PROCESS_PROVIDER].get.fetching);

  const [isFetching, setIsFetching] = useState(false);

  useEffect(() => {
    if (!fetching && isFetching) {
      setIsFetching(fetching);
    }
  }, [fetching]);

  const dispatch = useDispatch();

  const onCreate = (serviceProviderUri, processorUri) => {
    const processProviderPayload = {
      service_provider: serviceProviderUri,
      post_processor: processorUri,
    };

    dispatch(Actions.Api.nautilus[API_RESOURCES.PROCESS_PROVIDER].post(processProviderPayload))
      .then(response =>
        dispatch(Actions.Api.nautilus[API_RESOURCES.PROCESS_PROVIDER].get(extractUuid(response.headers.location))));
  };

  const onUpdate = (processProviderUri, status) => {
    const processProviderUUID = extractUuid(processProviderUri);

    dispatch(Actions.Api.nautilus[API_RESOURCES.PROCESS_PROVIDER].put(processProviderUUID, { status })).then(() => {
      dispatch(Actions.Api.nautilus[API_RESOURCES.PROCESS_PROVIDER].get(processProviderUUID));

      Alert.success(<FormattedMessage
        id="toaster.processProvider.updated"
        defaultMessage="Successfully updated"
      />);
    });
  };

  const onStatusChangeAction = status => {
    const { processProvider } = props;
    setIsFetching(true);

    onUpdate(processProvider.uri, status);
  };

  const onRequest = providerURI => {
    const { processorUri } = props;
    setIsFetching(true);

    onCreate(providerURI, processorUri);
  };

  const {
    provider,
    processProvider,
  } = props;

  return (
    <ProcessProvider
      provider={provider}
      processProvider={processProvider}
      isServiceProviderRole={isServiceProviderRole}
      onRequest={onRequest}
      onStatusChangeAction={onStatusChangeAction}
      fetching={isFetching}
      initialValues={initialValues}
    />
  );
};

ProcessProviderContainer.propTypes = {
  processorUri: PropTypes.string.isRequired,
  provider: PropTypes.shape({}).isRequired,
  processProvider: PropTypes.shape({
    uri: PropTypes.string,
  }),
};

ProcessProviderContainer.defaultProps = {
  processProvider: null,
};

export default ProcessProviderContainer;
