import React from 'react';
import _map from 'lodash/map';
import { FormattedMessage } from 'react-intl';
import PropTypes from 'prop-types';

const NCSelectedPiece = ({ selectedPiecesList }) => (
  <>
    <div>
      <b> <FormattedMessage id="nc.selectedPieces" defaultMessage="Selected Pieces" /> </b>
    </div>
    <div className="nc-piece-list">
      { _map(
        selectedPiecesList,
        piece => <div className="nc-piece" key={piece.uri}> {piece.name} </div>,
      )}
    </div>
  </>
);

NCSelectedPiece.propTypes = {
  selectedPiecesList: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
};

export default NCSelectedPiece;
