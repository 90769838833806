import React from 'react';
import BuildsLibrariesGrid from 'rapidfab/components/organize/BuildsLibrariesGrid';
import { Accordion, Button, ButtonToolbar, Card, Col, Container, Dropdown, DropdownButton, Form, FormControl, FormLabel, Modal, Row } from 'react-bootstrap';
import Loading from 'rapidfab/components/Loading';
import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { FormattedDateTime } from 'rapidfab/i18n';
import _pick from 'lodash/pick';
import _values from 'lodash/values';
import _range from 'lodash/range';
import { faSpinner } from '@fortawesome/free-solid-svg-icons';

const AddCertifiedBuildIntoProductionConfirmationModal = ({
  buildLibrary,
  show,
  closeModal,
  piecesByUri,
  confirmModal,
  isSaving,
}) => {
  const { pieces: buildLibraryPieceUris } = buildLibrary ?? [];
  const pieces = _values(_pick(piecesByUri, buildLibraryPieceUris));

  return (
    <Modal show={show} onHide={closeModal} backdrop="static">
      <Modal.Header>Produce Certified Build - &lsquo;{buildLibrary?.name}&rsquo;</Modal.Header>
      <Modal.Body>
        <p>
          This will put an exact copy of this build plate into production.
          It will create a new Order and Line-Items as a Confirmed order.
          The build plate will automatically be scheduled.
        </p>
        <div className="spacer-top">
          <Card bg="light">
            <Card.Header>Pieces Included ({pieces?.length})</Card.Header>
            <Card.Body className="pd-exp inverse">
              {pieces ?
                pieces.map(piece => (
                  <Accordion className="bg-light pd-exp inverse" bg="light">
                    <Accordion.Header>
                      <p>{piece.name}</p>
                    </Accordion.Header>
                    <Accordion.Body>
                      <p><b>Order name:</b> {piece?.order_name}</p>
                      <p><b>Updated:</b> <FormattedDateTime value={piece?.updated} /></p>
                    </Accordion.Body>
                  </Accordion>
                ))
                :
                <FontAwesomeIcon icon={faSpinner} spin /> }
            </Card.Body>
          </Card>
        </div>
        <Form.Check
          className="my-2"
          disabled
          inline
          name="noModelUpload"
          checked
          onChange={() => {}}
          type="checkbox"
          label={(
            <p>Automatic Schedule</p>
          )}
        />
      </Modal.Body>
      <Modal.Footer>
        <Button variant="danger" onClick={closeModal}>
          Close
        </Button>
        {isSaving ? (
          <Loading />
        ) : (
          <Button
            disabled={isSaving}
            variant="success"
            onClick={() => confirmModal(buildLibrary)}
          >
            Continue
          </Button>
        )}
      </Modal.Footer>
    </Modal>
  );
};

const BuildsLibraries = ({
  buildsLibraries,
  handleRenameBuildLibrary,
  buildFilesByUri,
  handleDeleteBuildLibrary,
  isFetching,
  search,
  handleBuildLibrariesSearch,
  handleSelect,
  confirmationModal,
  piecesByUri,
  handleAddCertifiedBuildIntoProduction,
  pagination,
  isSavingScheduleBuildFileLibrary,
  pieces,
  ...props
}) => {
  const { confirmationModalState, setConfirmationModalState } = confirmationModal;

  const {
    pageLimitValues,
    pageLimit,
    activePage,
    totalPaginatedPages,
  } = pagination;

  return (
    <Container fluid>
      <AddCertifiedBuildIntoProductionConfirmationModal
        buildLibrary={confirmationModalState.buildLibrary}
        show={confirmationModalState.show}
        closeModal={() => setConfirmationModalState({
          ...confirmationModalState,
          show: false,
          buildLibrary: null,
        })}
        confirmModal={handleAddCertifiedBuildIntoProduction}
        piecesByUri={piecesByUri}
        pieces={pieces}
        isSaving={isSavingScheduleBuildFileLibrary}
      />
      <div>
        <hr />
        <Row style={{ marginBottom: '15px' }}>
          <div className="d-flex justify-content-between">
            <div className="d-flex" style={{ flexBasis: '100%' }}>
              <Col xs={{ span: 3 }} className="mr30">
                <FormLabel>Search</FormLabel>{' '}
                <FormControl
                  type="text"
                  value={search}
                  onChange={event => handleBuildLibrariesSearch(event.target.value)}
                />
              </Col>
            </div>
          </div>
        </Row>
      </div>
      {isFetching && (
        <Loading className="mb30" />
      )}
      <BuildsLibrariesGrid
        {...props}
        buildLibraries={buildsLibraries}
        handleRenameBuildLibrary={handleRenameBuildLibrary}
        buildFilesByUri={buildFilesByUri}
        piecesByUri={piecesByUri}
        handleDelete={handleDeleteBuildLibrary}
        handleSelect={handleSelect}
      />
      <div className="pagination-panel">
        <ButtonToolbar className="pull-left">
          <DropdownButton title={pageLimit} id="pageLimitSelector-PrintsTab">
            {pageLimitValues.map(value => (
              <Dropdown.Item
                active={value === pageLimit}
                key={value}
                eventKey={value}
                onClick={() => pagination.setPaginationState({
                  ...pagination,
                  pageLimit: value,
                })}
              >
                {value}
              </Dropdown.Item>
            ))}
          </DropdownButton>
        </ButtonToolbar>

        <ButtonToolbar className="pull-right">
          <Button
            disabled={activePage === 0 || /* fetching */ false}
            className="spacer-right"
            onClick={() => {
              pagination
                .setPaginationState({
                  ...pagination,
                  offset: (activePage - 1) * pageLimit,
                  activePage: activePage - 1,
                });
            }}
          >Prev
          </Button>
          <DropdownButton className="spacer-right" title={`Page ${activePage + 1}`}>
            {_range(0, totalPaginatedPages).map(value => (
              <Dropdown.Item
                active={value === pageLimit}
                key={value}
                eventKey={value}
                onClick={event => {
                  const offset = (Number(event.target.textContent) - 1) * pageLimit;
                  pagination.setPaginationState({
                    ...pagination,
                    activePage: value,
                    offset,
                  });
                }}
              >
                {value + 1}
              </Dropdown.Item>
            ))}
          </DropdownButton>
          <Button
            disabled={activePage === (totalPaginatedPages - 1) || /* fetching */ false}
            onClick={() => {
              pagination
                .setPaginationState({
                  ...pagination,
                  offset: (activePage + 1) * pageLimit,
                  activePage: activePage + 1,
                });
            }}
          >Next
          </Button>
        </ButtonToolbar>
      </div>
    </Container>

  );
};

export default BuildsLibraries;

BuildsLibraries.propTypes = {
  buildsLibraries: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  handleRenameBuildLibrary: PropTypes.func.isRequired,
  handleDeleteBuildLibrary: PropTypes.func.isRequired,
  isFetching: PropTypes.bool.isRequired,
  search: PropTypes.string.isRequired,
  handleBuildLibrariesSearch: PropTypes.func.isRequired,
  buildFilesByUri: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  handleSelect: PropTypes.func.isRequired,
  confirmationModalState: PropTypes.shape({
    show: PropTypes.bool,
    buildLibrary: PropTypes.shape({}),
  }).isRequired,
  setConfirmationModalState: PropTypes.func.isRequired,
  confirmationModal: PropTypes.shape({
    confirmationModalState: PropTypes.shape({
      buildLibrary: PropTypes.shape({}),
      show: PropTypes.bool,
    }),
    setConfirmationModalState: PropTypes.func.isRequired,
  }).isRequired,
  piecesByUri: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  handleAddCertifiedBuildIntoProduction: PropTypes.func.isRequired,
  pagination: PropTypes.shape({
    setPaginationState: PropTypes.func,
    pageLimitValues: PropTypes.arrayOf(PropTypes.number),
    pageLimit: PropTypes.number,
    offset: PropTypes.number,
    activePage: PropTypes.number,
    totalModelLibraries: PropTypes.number,
    totalPaginatedPages: PropTypes.number,
  }).isRequired,
  totalPaginatedPages: PropTypes.number.isRequired,
  isSavingScheduleBuildFileLibrary: PropTypes.bool.isRequired,
  pieces: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
};

AddCertifiedBuildIntoProductionConfirmationModal.propTypes = {
  buildLibrary: PropTypes.shape({
    name: PropTypes.string,
  }).isRequired,
  pieces: PropTypes.shape({}).isRequired,
  show: PropTypes.bool.isRequired,
  closeModal: PropTypes.func.isRequired,
  confirmModal: PropTypes.func.isRequired,
  isSaving: PropTypes.bool.isRequired,
  piecesByUri: PropTypes.shape({}).isRequired,
};
