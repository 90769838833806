import { createSelector } from 'reselect';
import { getPredicate } from 'rapidfab/selectors/helpers/base';
import { getLocationSettings } from 'rapidfab/selectors/locationSettings';
import _find from 'lodash/find';

// eslint-disable-next-line import/prefer-default-export
export const getLocationSettingsForLocation = createSelector(
  [getPredicate, getLocationSettings],
  (location, locationSettings) => {
    const { uri } = location ?? {};
    return _find(locationSettings, { location: uri });
  },
);
