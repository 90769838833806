import React from 'react';
import PropTypes from 'prop-types';
import { ESTIMATOR,
  FEATURES,
  MATERIAL_UNITS,
  MATERIAL_UNITS_MEASUREMENT_CORE } from 'rapidfab/constants';
import { useSelector } from 'react-redux';
import { hhmmss } from 'rapidfab/utils/timeUtils';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Feature from 'rapidfab/components/Feature';
import { Button, Col, ListGroup, ListGroupItem, Row } from 'react-bootstrap';
import {
  FormattedMessage,
  FormattedVolume,
} from 'rapidfab/i18n';
import { getUsersByUri } from 'rapidfab/selectors';
import FormattedLocalizedCost from 'rapidfab/components/FormattedLocalizedCost';
import _sumBy from 'lodash/sumBy';
import _isEmpty from 'lodash/isEmpty';
import { lineItemEstimatesResourceType } from 'rapidfab/types';
import UseNonMfgLanguageFeature from 'rapidfab/components/generalMfgLanguage/UseNonMfgLanguageFeature';
import { getLineItemEstimatesTypeObjectKey } from 'rapidfab/utils/lineItemUtils';
import FormattedWeight from 'rapidfab/components/FormattedWeight';
import _round from 'lodash/round';
import { convertMassToOtherUnit } from 'rapidfab/utils/mathUtils';
import { faSpinner } from '@fortawesome/free-solid-svg-icons';

const Estimates = ({
  estimates,
  additionalCosts,
  model,
  quantity,
  isRelevantEstimation,
  onEstimationEditClick,
  onEstimationViewClick,
  hideFinancial,
  lineItem,
  baseAndSupportMaterials,
  noEstimatesAvailable,
}) => {
  const users = useSelector(getUsersByUri);
  const editedBy = estimates.estimator === ESTIMATOR.USER ? users : null;

  const isProsperNonRelevantEstimation =
    estimates?.estimator === ESTIMATOR.PROSPER || !isRelevantEstimation;
  const isUserEstimation = estimates?.estimator === ESTIMATOR.USER;
  const totalAdditionalCostPerPiece = _sumBy(additionalCosts, 'cost_value');

  const workflowTypeEstimatesKey = getLineItemEstimatesTypeObjectKey(lineItem);

  // Volume is always in cm^3, density is always in g/cm^3
  const calculateMaterialMass = (volume, density, massUnit) => {
    // Calculate mass of material in g
    const mass = volume * density;

    // Convert mass to input unit
    return _round(convertMassToOtherUnit(mass, MATERIAL_UNITS.GRAM, massUnit), 3);
  };

  return (
    <ListGroup fill className="m-b-0">
      <ListGroupItem key="header">
        {isUserEstimation && editedBy && (
          <Row>
            <Col xs={12}>
              <small className="pull-right">
                Last edited by {editedBy.name}
              </small>
            </Col>
          </Row>
        )}
        <Row>
          <Col xs={5}>
            <b>
              <FormattedMessage
                id="field.name"
                defaultMessage="Name"
              />
            </b>
          </Col>
          <Col xs={3}>
            <b>
              <FormattedMessage
                id="perPiece"
                defaultMessage="Per Piece"
              />
            </b>
          </Col>
          <Col xs={3}>
            <b>
              <FormattedMessage
                id="total"
                defaultMessage="Total"
              />
            </b>
          </Col>
          <Col xs={1}>
            <div className="d-flex align-items-center pull-right">
              <Button data-cy="estimate-details-btn" className="me-1" size="xs" variant="default" onClick={onEstimationViewClick}>
                <FormattedMessage id="button.details" defaultMessage="Details" />
              </Button>
              <Feature featureName={FEATURES.USER_CAN_UPDATE_ESTIMATES}>
                <Button data-cy="estimate-edit-btn" size="xs" variant="default" onClick={onEstimationEditClick}>
                  <FormattedMessage id="edit" defaultMessage="Edit" />
                </Button>
              </Feature>
            </div>
          </Col>
        </Row>
      </ListGroupItem>

      {estimates && lineItem[workflowTypeEstimatesKey] && lineItem[workflowTypeEstimatesKey]?.printing_time !== null ? (
        <>
          {!hideFinancial && (
            <ListGroupItem>
              <Row>
                <Col xs={5}>
                  <UseNonMfgLanguageFeature
                    mfgLanguageComponent={(
                      <FormattedMessage
                        id="estimates.printingCost"
                        defaultMessage="Printing Cost"
                      />
                    )}
                    nonMfgLanguageComponent={(
                      <FormattedMessage
                        id="mfg.printingCost.primaryProductionCost"
                        defaultMessage="Primary Production Cost"
                      />
                    )}
                  />
                </Col>
                <Col xs={3}>
                  {lineItem[workflowTypeEstimatesKey]?.printing_cost === null ? (
                    <FormattedMessage id="notAvailable" defaultMessage="N/A" />
                  ) : (
                    <FormattedLocalizedCost
                      value={lineItem[workflowTypeEstimatesKey]?.printing_cost / quantity}
                    />
                  )}
                  {isProsperNonRelevantEstimation &&
                    <span className="asterisk">*</span>}
                </Col>
                <Col xs={3}>
                  {lineItem[workflowTypeEstimatesKey]?.printing_cost === null ? (
                    <FormattedMessage id="notAvailable" defaultMessage="N/A" />
                  ) : (
                    <FormattedLocalizedCost
                      value={lineItem[workflowTypeEstimatesKey]?.printing_cost}
                    />
                  )}
                  {isProsperNonRelevantEstimation &&
                    <span className="asterisk">*</span>}
                </Col>
              </Row>
            </ListGroupItem>
          )}

          <ListGroupItem>
            <Row>
              <Col xs={5}>
                <UseNonMfgLanguageFeature
                  mfgLanguageComponent={(
                    <FormattedMessage
                      id="printTime"
                      defaultMessage="Print Time"
                    />
                  )}
                  nonMfgLanguageComponent={(
                    <FormattedMessage
                      id="mfg.printTime.primaryProductionTime"
                      defaultMessage="Primary Production Time"
                    />
                  )}
                />
              </Col>
              <Col xs={3} style={{ fontStyle: isUserEstimation ? 'italic' : 'normal' }}>
                {lineItem[workflowTypeEstimatesKey]?.printing_time === null ? (
                  <FormattedMessage id="notAvailable" defaultMessage="N/A" />
                ) : (
                  <span>{hhmmss(lineItem[workflowTypeEstimatesKey]?.printing_time / quantity)}</span>
                )}
                {isProsperNonRelevantEstimation &&
                <span className="asterisk">*</span>}
              </Col>
              <Col xs={3} style={{ fontStyle: isUserEstimation ? 'italic' : 'normal' }}>
                {lineItem[workflowTypeEstimatesKey]?.printing_time === null ? (
                  <FormattedMessage id="notAvailable" defaultMessage="N/A" />
                ) : (
                  <span>{hhmmss(lineItem[workflowTypeEstimatesKey]?.printing_time)}</span>
                )}
                {isProsperNonRelevantEstimation &&
                <span className="asterisk">*</span>}
              </Col>
            </Row>
          </ListGroupItem>

          {/* --- Material weight --- */}
          {baseAndSupportMaterials.base?.density &&
          baseAndSupportMaterials.base.units_measurement_core === MATERIAL_UNITS_MEASUREMENT_CORE.WEIGHT && (
            <ListGroupItem>
              <Row>
                <Col xs={5}>
                  <FormattedMessage
                    id="materialWeight"
                    defaultMessage="Material Weight"
                  />
                </Col>
                <Col xs={3}>
                  <div>
                    {model?.volume_mm === null ? (
                      <FormattedMessage
                        id="notAvailable"
                        defaultMessage="N/A"
                      />
                    ) : (
                      <FormattedWeight
                        value={
                          calculateMaterialMass(
                            (model?.volume_mm / 1000),
                            baseAndSupportMaterials.base?.density,
                            baseAndSupportMaterials.base?.units,
                          )
                        }
                        valueUnits={baseAndSupportMaterials.base?.units}
                      />
                    )}
                  </div>
                  {isProsperNonRelevantEstimation &&
                  <span className="asterisk">*</span>}
                </Col>
                <Col xs={3}>
                  {model && (
                    <div>
                      {model?.volume_mm === null ? (
                        <FormattedMessage
                          id="notAvailable"
                          defaultMessage="N/A"
                        />
                      ) : (
                        <FormattedWeight
                          value={
                            calculateMaterialMass(
                              (model?.volume_mm / 1000),
                              baseAndSupportMaterials.base?.density,
                              baseAndSupportMaterials.base?.units,
                            ) * quantity
                          }
                          valueUnits={baseAndSupportMaterials.base?.units}
                        />
                      )}
                    </div>
                  )}
                  {isProsperNonRelevantEstimation &&
                  <span className="asterisk">*</span>}
                </Col>
              </Row>
            </ListGroupItem>
          )}
          {/* --- End of material weight --- */}

          <ListGroupItem>
            <Row>
              <Col xs={5}>
                <FormattedMessage
                  id="modelVolume"
                  defaultMessage="Model Volume"
                />
              </Col>
              <Col xs={3}>
                {model && (
                  <div>
                    {model.volume_mm === null ? (
                      <FormattedMessage
                        id="notAvailable"
                        defaultMessage="N/A"
                      />
                    ) : (
                      <FormattedVolume
                        value={model.volume_mm}
                        valueUnits={MATERIAL_UNITS.MM3}
                      />
                    )}
                  </div>
                )}
                {isProsperNonRelevantEstimation &&
                <span className="asterisk">*</span>}
              </Col>
              <Col xs={3}>
                {model && (
                  <div>
                    {model.volume_mm === null ? (
                      <FormattedMessage
                        id="notAvailable"
                        defaultMessage="N/A"
                      />
                    ) : (
                      <FormattedVolume
                        value={model.volume_mm * quantity}
                        valueUnits={MATERIAL_UNITS.MM3}
                      />
                    )}
                  </div>
                )}
                {isProsperNonRelevantEstimation &&
                <span className="asterisk">*</span>}
              </Col>
            </Row>
          </ListGroupItem>

          <ListGroupItem>
            <Row>
              <Col xs={5}>
                <FormattedMessage
                  id="estimates.supportUsed"
                  defaultMessage="Support Used"
                />
              </Col>
              <Col xs={3}>
                {lineItem[workflowTypeEstimatesKey]?.materials && (
                  <div>
                    {lineItem[workflowTypeEstimatesKey]?.materials.support === null ? (
                      <FormattedMessage
                        id="notAvailable"
                        defaultMessage="N/A"
                      />
                    ) : (
                      <FormattedVolume
                        value={lineItem[workflowTypeEstimatesKey]?.materials.support / quantity}
                        valueUnits={
                          // `line_item.estimates.materials.support` is always returned in cm3
                          MATERIAL_UNITS.CM3
                        }
                      />
                    )}
                  </div>
                )}
                {isProsperNonRelevantEstimation &&
                <span className="asterisk">*</span>}
              </Col>
              <Col xs={3}>
                {lineItem[workflowTypeEstimatesKey]?.materials && (
                  <div>
                    {lineItem[workflowTypeEstimatesKey]?.materials.support === null ? (
                      <FormattedMessage
                        id="notAvailable"
                        defaultMessage="N/A"
                      />
                    ) : (
                      <FormattedVolume
                        value={lineItem[workflowTypeEstimatesKey]?.materials.support}
                        valueUnits={
                          // `line_item.estimates.materials.support` is always returned in cm3
                          MATERIAL_UNITS.CM3
                        }
                      />
                    )}
                  </div>
                )}
                {isProsperNonRelevantEstimation &&
                <span className="asterisk">*</span>}
              </Col>
            </Row>
          </ListGroupItem>
          {!hideFinancial && (
            <>
              <ListGroupItem>
                <Row>
                  <Col xs={5}>
                    <FormattedMessage
                      id="estimates.postProcessingCost"
                      defaultMessage="Post-Processing Cost"
                    />
                  </Col>
                  <Col xs={3}>
                    {lineItem[workflowTypeEstimatesKey]?.post_processing_cost === null ? (
                      <FormattedMessage id="notAvailable" defaultMessage="N/A" />
                    ) : (
                      <FormattedLocalizedCost
                        value={lineItem[workflowTypeEstimatesKey]?.post_processing_cost / quantity}
                      />
                    )}
                    {isProsperNonRelevantEstimation &&
                    <span className="asterisk">*</span>}
                  </Col>
                  <Col xs={3}>
                    {lineItem[workflowTypeEstimatesKey]?.post_processing_cost === null ? (
                      <FormattedMessage id="notAvailable" defaultMessage="N/A" />
                    ) : (
                      <FormattedLocalizedCost
                        value={lineItem[workflowTypeEstimatesKey]?.post_processing_cost}
                      />
                    )}
                    {isProsperNonRelevantEstimation &&
                    <span className="asterisk">*</span>}
                  </Col>
                </Row>
              </ListGroupItem>

              {!_isEmpty(additionalCosts) && (
                <ListGroupItem data-cy="additional-cost">
                  <Row>
                    <Col xs={5}>
                      <FormattedMessage
                        id="record.lineItem.additionalCosts"
                        defaultMessage="Additional Costs"
                      />
                    </Col>
                    <Col xs={3} data-cy="per-piece-cost">
                      <FormattedLocalizedCost value={totalAdditionalCostPerPiece} />
                    </Col>
                    <Col xs={3} data-cy="total-cost">
                      <FormattedLocalizedCost value={totalAdditionalCostPerPiece * quantity} />
                    </Col>
                  </Row>
                </ListGroupItem>
              )}

              <ListGroupItem>
                <Row>
                  <Col xs={5}>
                    <FormattedMessage
                      id="materialCost"
                      defaultMessage="Material Cost"
                    />
                  </Col>
                  <Col xs={3}>
                    {lineItem[workflowTypeEstimatesKey]?.total_material_cost === null ? (
                      <FormattedMessage id="notAvailable" defaultMessage="N/A" />
                    ) : (
                      <FormattedLocalizedCost value={lineItem[workflowTypeEstimatesKey]?.total_material_cost /
                      quantity}
                      />
                    )}
                    {isProsperNonRelevantEstimation &&
                    <span className="asterisk">*</span>}
                  </Col>
                  <Col xs={3}>
                    {lineItem[workflowTypeEstimatesKey]?.total_material_cost === null ? (
                      <FormattedMessage id="notAvailable" defaultMessage="N/A" />
                    ) : (
                      <FormattedLocalizedCost value={lineItem[workflowTypeEstimatesKey]?.total_material_cost} />
                    )}
                    {isProsperNonRelevantEstimation &&
                    <span className="asterisk">*</span>}
                  </Col>
                </Row>
              </ListGroupItem>

              <ListGroupItem>
                <Row>
                  <Col xs={5}>
                    <FormattedMessage
                      id="totalCost"
                      defaultMessage="Total Cost"
                    />
                  </Col>
                  <Col xs={3} style={{ fontStyle: isUserEstimation ? 'italic' : 'normal' }}>
                    {estimates.total_cost ? (
                      <FormattedLocalizedCost
                        value={estimates.total_cost / quantity}
                      />
                    ) : (
                      <FormattedMessage id="notAvailable" defaultMessage="N/A" />
                    )}
                    {isProsperNonRelevantEstimation &&
                    <span className="asterisk">*</span>}
                  </Col>
                  <Col xs={3} style={{ fontStyle: isUserEstimation ? 'italic' : 'normal' }}>
                    {estimates.total_cost ? (
                      <FormattedLocalizedCost
                        value={estimates.total_cost}
                      />
                    ) : (
                      <FormattedMessage id="notAvailable" defaultMessage="N/A" />
                    )}
                    {isProsperNonRelevantEstimation &&
                    <span className="asterisk">*</span>}
                  </Col>
                </Row>
              </ListGroupItem>

              <Feature featureName={FEATURES.DESIGN_COST}>
                <ListGroupItem>
                  <Row>
                    <Col xs={5}>
                      <FormattedMessage
                        id="estimates.designCost"
                        defaultMessage="Design Cost"
                      />
                    </Col>
                    <Col xs={3}>
                      {estimates.design_cost ? (
                        <FormattedLocalizedCost
                          value={estimates.design_cost / quantity}
                        />
                      ) : (
                        <FormattedMessage id="notAvailable" defaultMessage="N/A" />
                      )}
                    </Col>
                    <Col xs={3}>
                      {estimates.design_cost ? (
                        <FormattedLocalizedCost
                          value={estimates.design_cost}
                        />
                      ) : (
                        <FormattedMessage id="notAvailable" defaultMessage="N/A" />
                      )}
                    </Col>
                  </Row>
                </ListGroupItem>
              </Feature>

              <ListGroupItem>
                <Row>
                  <Col xs={5}>
                    <FormattedMessage
                      id="bureauPrice"
                      defaultMessage="Bureau Price"
                    />
                  </Col>
                  <Col xs={3} style={{ fontStyle: isUserEstimation ? 'italic' : 'normal' }}>
                    {estimates.total_price ? (
                      <FormattedLocalizedCost
                        value={estimates.total_price / quantity}
                      />
                    ) : (
                      <FormattedMessage id="notAvailable" defaultMessage="N/A" />
                    )}
                  </Col>
                  <Col xs={3} style={{ fontStyle: isUserEstimation ? 'italic' : 'normal' }}>
                    {estimates.total_price ? (
                      <FormattedLocalizedCost
                        value={estimates.total_price}
                      />
                    ) : (
                      <FormattedMessage id="notAvailable" defaultMessage="N/A" />
                    )}
                  </Col>
                </Row>
              </ListGroupItem>
            </>
          )}
        </>
      ) : (noEstimatesAvailable ? (
        <div className="text-center mt15 mb15">
          <FormattedMessage
            id="status.noEstimates"
            defaultMessage="No estimates available"
          />
          …
        </div>
      ) : (
        <div className="text-center mt15 mb15">
          <FontAwesomeIcon icon={faSpinner} spin className="spacer-right" />
          <FormattedMessage
            id="status.calculating"
            defaultMessage="Calculating"
          />
          …
        </div>
      )
      )}
    </ListGroup>
  );
};

Estimates.defaultProps = {
  estimates: {
    estimator: null,
    edited_by: null,
    total_cost: null,
    design_cost: null,
    printing_time: null,
    printing_cost: null,
    total_material_cost: null,
    post_processing_cost: null,
    materials: {
      base: PropTypes.shape({
        units_measurement_core: PropTypes.string,
        units: PropTypes.string,
      }),
      support: null,
    },
    additional_costs: [],
  },
  model: {
    volume_mm: null,
  },
  quantity: 0,
  isRelevantEstimation: true,
  onEstimationEditClick: () => true,
  onEstimationViewClick: () => true,
  editedBy: null,
  additionalCosts: [],
  hideFinancial: false,
  noEstimatesAvailable: false,
};

Estimates.propTypes = {
  additionalCosts: PropTypes.arrayOf(PropTypes.shape({
    cost_name: PropTypes.string,
    cost_value: PropTypes.number,
  })),
  estimates: lineItemEstimatesResourceType,
  model: PropTypes.shape({
    volume_mm: PropTypes.number,
  }),
  quantity: PropTypes.number,
  isRelevantEstimation: PropTypes.bool,
  onEstimationEditClick: PropTypes.func,
  onEstimationViewClick: PropTypes.func,
  editedBy: PropTypes.shape({
    name: PropTypes.string.isRequired,
  }),
  hideFinancial: PropTypes.bool,
  lineItem: PropTypes.shape({}).isRequired,
  baseAndSupportMaterials: PropTypes.shape({
    base: PropTypes.shape({
      density: PropTypes.number,
      units_measurement_core: PropTypes.string,
      units: PropTypes.string,
    }),
    support: PropTypes.shape({}),
  }).isRequired,
  noEstimatesAvailable: PropTypes.bool,
};

export default Estimates;
