import React, { useRef, useState } from 'react';
import {
  ListGroupItem,
  Row,
  Col,
  FormGroup,
  FormControl,
  Button,
  FormLabel,
  InputGroup,
} from 'react-bootstrap';
import getServiceProviderFormOptions from 'rapidfab/utils/serviceProviderFormOptions';
import { getShortUUID } from 'rapidfab/utils/uuidUtils';
import _keys from 'lodash/keys';
import Loading from 'rapidfab/components/Loading';
import { FormattedMessage } from 'rapidfab/i18n';
import {
  CurrencySymbols,
  SERVICE_PROVIDER_JOB_STATUS,
} from 'rapidfab/constants';
import { SERVICE_PROVIDER_JOB_MAP, JOB_RATING_MAP } from 'rapidfab/mappings';
import onlyDecimal from 'rapidfab/reducers/onlyDecimal';

import { toISOStringWithoutTime } from 'rapidfab/utils/timeUtils';
import ServiceProviderJobRatingModal from 'rapidfab/components/records/order/edit/ServiceProviderJobRatingModal';

import { Form, Field } from 'react-final-form';
import PropTypes from 'prop-types';
import { FormControlDate } from 'rapidfab/components/formTools';
import { finalFormInputTypes } from 'rapidfab/types';
import {
  addTimeZoneToDateString,
  removeTimeZoneFromDateString,
} from 'rapidfab/utils/formHelpers';

const ServiceProviderJob = ({
  provider,
  job,
  fetching,
  onBidRequest,
  onSubmit,
  onUpdateRating,
  onStatusTransformation,
  lineItemShortUUID,
  isServiceProvider,
  initialFormValues,
  availableConversions,
}) => {
  const formRef = useRef();
  const isProviderJobExist = Boolean(job);

  const serviceProviderJobFormID = `${getShortUUID(
    provider.uuid,
  )}-${lineItemShortUUID}`;

  // Prevent selecting dates in the past
  const today = new Date();
  let minDueAt = toISOStringWithoutTime(today);

  if (job && job.due_at) {
    const dueAtDate = new Date(job.due_at);
    if (dueAtDate < today) {
      // We need to allow current value in the past, if any was set previously
      minDueAt = job.due_at;
    }
  }
  const [showRatingModal, setShowRatingModal] = useState(false);
  let updatedFields;
  return (
    <ListGroupItem className="bg-dark" key={serviceProviderJobFormID}>
      <Form
        onSubmit={values => onSubmit(values, updatedFields)}
        initialValues={initialFormValues}
        render={({ handleSubmit, form, values }) => {
          updatedFields = _keys(form.getState()?.dirtyFields);
          const serviceProviderJobOptions = getServiceProviderFormOptions({
            isBureauUser: !isServiceProvider,
            lastEditorRole: isProviderJobExist && job.last_editor_role,
            serviceProviderStatus: isProviderJobExist && job.status,
            target: isProviderJobExist && job.target,
            updatedFields,
          });

          return (
            <form ref={formRef} id={serviceProviderJobFormID} onSubmit={handleSubmit}>
              <Row>
                <Col xs={3} className="truncate-data">
                  {isServiceProvider ? (
                    <span>{provider.name}</span>
                  ) : (
                    <a
                      href={`#/records/service-provider/${
                        provider.uuid && provider.uuid
                      }`}
                    >
                      {provider.name}
                    </a>
                  )}
                </Col>
                <Col xs={3} className="order-providers-item">
                  <FormGroup controlId="uxName" className="mb0">
                    {serviceProviderJobOptions.cost.show ? (
                      <InputGroup className="align-items-center">
                        <InputGroup.Text
                          className="currency-addon"
                          disabled={!serviceProviderJobOptions.currency.editable}
                        >
                          <Field
                            type="select"
                            initialValue={initialFormValues?.currency}
                            name="currency"
                            render={props => (
                              <FormControl
                                as="select"
                                className="currency"
                                value={props.input.value}
                                onChange={props.input.onChange}
                                disabled={!serviceProviderJobOptions.currency.editable}
                              >
                                {availableConversions.map(conversion => (
                                  <>
                                    {/* eslint-disable react/no-danger */}
                                    <option
                                      value={conversion.currency}
                                      dangerouslySetInnerHTML={{
                                        __html: CurrencySymbols[conversion.currency],
                                      }}
                                    />
                                    {/* eslint-enable react/no-danger */}
                                  </>
                                ))}
                              </FormControl>
                            )}
                          />
                        </InputGroup.Text>
                        <Field
                          name="cost"
                          initialValue={initialFormValues?.cost}
                          render={props => (
                            <FormControl
                              className="cost"
                              name="lineItemCost"
                              type="number"
                              value={props.input.value}
                              onChange={event => {
                                // TODO: Replace with field-level `Normalizer`
                                //  once Redux-Form is updated to v6
                                if (event.target.value) {
                                  // eslint-disable-next-line no-param-reassign
                                  event.target.value = onlyDecimal(event.target.value);
                                }
                                return props.input.onChange(event);
                              }}
                              disabled={!serviceProviderJobOptions.cost.editable}
                            />
                          )}
                        />
                      </InputGroup>
                    ) : (
                      'N/A'
                    )}
                  </FormGroup>
                </Col>
                <Col xs={3} className="order-providers-item">
                  {serviceProviderJobOptions.dueDate.show ? (
                    <FormGroup controlId="uxName" className="mb0">
                      <Field
                        name="due_at"
                        initialValue={initialFormValues?.due_at}
                        parse={addTimeZoneToDateString}
                        format={removeTimeZoneFromDateString}
                        render={props => {
                          const field = { ...props.input, ...props.meta };
                          return (
                            <FormControlDate
                              {...field}
                              min={minDueAt}
                              defaultValue={field.value}
                              disabled={!serviceProviderJobOptions.dueDate.editable}
                            />
                          );
                        }}
                      />
                    </FormGroup>
                  ) : (
                    'N/A'
                  )}
                </Col>
                {fetching && (
                  <Col className="order-providers-item text-center">
                    {' '}
                    <Loading />{' '}
                  </Col>
                )}
                {!fetching && (
                  <Col xs={3} className="d-flex flex-direction-column">
                    {serviceProviderJobOptions.requestBidButton.show && (
                      <Button
                        block
                        className="mb15"
                        variant="primary"
                        type="button"
                        onClick={() => onBidRequest(provider.uri)}
                        disabled={serviceProviderJobOptions.requestBidButton.disabled}
                      >
                        <span>
                          {serviceProviderJobOptions.requestBidButton.message}
                        </span>
                      </Button>
                    )}
                    {serviceProviderJobOptions.currentStatusButton.show && (
                      <Button
                        block
                        className="mb15"
                        variant={serviceProviderJobOptions.currentStatusButton.style}
                        type="button"
                        // Currently, always disabled
                        disabled={
                          serviceProviderJobOptions.currentStatusButton.disabled
                        }
                      >
                        <span>
                          {serviceProviderJobOptions.currentStatusButton.message}
                        </span>
                      </Button>
                    )}
                    {serviceProviderJobOptions.saveBidDataButton.show && (
                      <Button
                        block
                        className="mb15"
                        variant="primary"
                        type="button"
                        onClick={() => {
                          if (formRef.current.reportValidity()) {
                            onStatusTransformation(
                              values,
                              serviceProviderJobOptions.saveBidDataButton.transformsTo,
                            );
                          }
                        }}
                        disabled={
                          serviceProviderJobOptions.saveBidDataButton.disabled
                        }
                      >
                        <span>
                          {serviceProviderJobOptions.saveBidDataButton.message}
                        </span>
                      </Button>
                    )}
                    {serviceProviderJobOptions.submitButton.show && (
                      <Button
                        block
                        className="mb15"
                        variant="primary"
                        type="submit"
                        disabled={serviceProviderJobOptions.submitButton.disabled}
                      >
                        <span>{serviceProviderJobOptions.submitButton.message}</span>
                      </Button>
                    )}
                    {serviceProviderJobOptions.offerJobButton.show && (
                      <Button
                        block
                        className="mb15"
                        variant="primary"
                        type="button"
                        onClick={() =>
                          onStatusTransformation(values, serviceProviderJobOptions.offerJobButton.transformsTo)}
                        disabled={serviceProviderJobOptions.offerJobButton.disabled}
                      >
                        <span>
                          {serviceProviderJobOptions.offerJobButton.message}
                        </span>
                      </Button>
                    )}
                    {serviceProviderJobOptions.completeButton.show && (
                      <Button
                        block
                        className="mb15"
                        variant="primary"
                        type="button"
                        onClick={() =>
                          onStatusTransformation(values, serviceProviderJobOptions.completeButton.transformsTo)}
                        disabled={serviceProviderJobOptions.completeButton.disabled}
                      >
                        <span>
                          {serviceProviderJobOptions.completeButton.message}
                        </span>
                      </Button>
                    )}
                    {serviceProviderJobOptions.cancelButton.show && (
                      <Button
                        block
                        className="mb15"
                        variant="danger"
                        type="button"
                        onClick={() =>
                          onStatusTransformation(values, serviceProviderJobOptions.cancelButton.transformsTo)}
                        disabled={serviceProviderJobOptions.cancelButton.disabled}
                      >
                        <span>{serviceProviderJobOptions.cancelButton.message}</span>
                      </Button>
                    )}
                  </Col>
                )}
              </Row>

              {(serviceProviderJobOptions.serviceProviderNotes.show ||
                serviceProviderJobOptions.status.show) && (
                <div className="order-providers-form">
                  <Row>
                    {serviceProviderJobOptions.serviceProviderNotes.show && (
                      <Col xs={6} sm={6}>
                        <FormGroup controlId="uxNotes">
                          <FormLabel>Notes:</FormLabel>
                          <Field
                            name="provider_notes"
                            initialValue={initialFormValues?.provider_notes}
                            render={props => (
                              <FormControl
                                name="notes"
                                type="text"
                                as="textarea"
                                required
                                value={props.input.value}
                                onChange={props.input.onChange}
                                disabled={!serviceProviderJobOptions.serviceProviderNotes.editable}
                              />
                            )}
                          />
                        </FormGroup>
                      </Col>
                    )}

                    {serviceProviderJobOptions.rating.show && (
                      <Col
                        xs={6}
                        sm={6}
                        className="d-flex justify-content-between mt15"
                      >
                        <div className="d-flex flex-direction-column">
                          <b>Part Rating:</b>
                          {job.job_rating ? (
                            <FormattedMessage {...JOB_RATING_MAP[job.job_rating]} />
                          ) : (
                            '-'
                          )}
                        </div>
                        <Button onClick={() => setShowRatingModal(true)}>
                          Rate Job
                        </Button>
                      </Col>
                    )}

                    {serviceProviderJobOptions.status.show && (
                      <Col xs={6} sm={6}>
                        <FormLabel>Status:</FormLabel>
                        <Field
                          name="status"
                          type="select"
                          initialValue={initialFormValues?.status}
                          render={props => (
                            <FormControl
                              as="select"
                              type="select"
                              value={props.input.value}
                              onChange={props.input.onChange}
                            >
                              {
                                serviceProviderJobOptions
                                  .serviceProviderJobStatusTransformations[job.status].map(status => (
                                    <option key={status} value={status}>
                                      {SERVICE_PROVIDER_JOB_MAP[status].defaultMessage}
                                    </option>
                                  ))
                              }
                            </FormControl>
                          )}
                        />
                      </Col>
                    )}
                  </Row>
                </div>
              )}
            </form>
          );
        }}
      />

      <ServiceProviderJobRatingModal
        initialValue={isProviderJobExist ? job.job_rating : null}
        show={showRatingModal}
        onClose={() => setShowRatingModal(false)}
        onUpdateRating={onUpdateRating}
      />
    </ListGroupItem>
  );
};

ServiceProviderJob.propTypes = {
  provider: PropTypes.shape({
    uuid: PropTypes.string,
    uri: PropTypes.string,
    name: PropTypes.string,
  }).isRequired,
  input: finalFormInputTypes.isRequired,
  job: PropTypes.shape({
    job_rating: PropTypes.number,
    last_editor_role: PropTypes.string,
    status: PropTypes.oneOf(Object.values(SERVICE_PROVIDER_JOB_STATUS)),
    target: PropTypes.string,
    due_at: PropTypes.string,
  }),
  isServiceProvider: PropTypes.bool.isRequired,
  onBidRequest: PropTypes.func.isRequired,
  onStatusTransformation: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  onUpdateRating: PropTypes.func.isRequired,
  lineItemShortUUID: PropTypes.string.isRequired,
  fetching: PropTypes.bool.isRequired,
  meta: PropTypes.shape({}).isRequired,
  initialFormValues: PropTypes.shape({
    provider_notes: PropTypes.string,
    cost: PropTypes.number,
    duration: PropTypes.number,
    job_rating: PropTypes.number,
    status: PropTypes.oneOf(Object.values(SERVICE_PROVIDER_JOB_STATUS)),
    due_at: PropTypes.string,
    currency: PropTypes.string,
  }).isRequired,
  availableConversions: PropTypes.arrayOf(PropTypes.shape({
    currency: PropTypes.string.isRequired,
  })).isRequired,
};

ServiceProviderJob.defaultProps = {
  job: null,
};

export default ServiceProviderJob;
