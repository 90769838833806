/* eslint-disable import/prefer-default-export */
import { useSelector } from 'react-redux';
import { getBureauDefaultCurrency } from 'rapidfab/selectors/currencySelectors';
import { useCallback } from 'react';
import dayjs from 'dayjs';

export const DATE_LOCALES = {
  US: 'en-EN',
  ISO: 'ISO',
};

export const DATE_FORMATS = {
  [DATE_LOCALES.US]: 'MM/DD/YYYY',
  [DATE_LOCALES.ISO]: 'YYYY/MM/DD',
};

export const DATE_TIME_FORMATS = {
  [DATE_LOCALES.US]: 'MM/DD/YYYY, h:mm A',
  [DATE_LOCALES.ISO]: 'YYYY/MM/DD, HH:mm',
};

/**
 * Returns the current user's date locale based on their bureau's default currency.
 * If the bureau's currency is not found or is loading, ISO is returned instead.
 * Also returns the relevant date and time formats for current locale
 * @returns locale: 'US' or 'ISO'
 * @returns dateFormat: 'MM DD YYYY' or 'YYYY MM DD'
 * @returns dateTimeFormat: 'MM DD YYYY, HH:mm' or 'YYYY MM DD, HH:mm'
 * @returns formattedDate: Function returning formatted string of supplied date using dayjs
 * @returns formattedDateTime: Ditto above, for datetime.
 */
export const useDateLocale = tz => {
  const defaultCurrency = useSelector(getBureauDefaultCurrency);
  const locale = defaultCurrency === 'USD' ? DATE_LOCALES.US : DATE_LOCALES.ISO;
  const formattedDate = useCallback(date => {
    if (tz) {
      return dayjs(date).tz(tz).format(DATE_FORMATS[locale]);
    }
    return dayjs(date).format(DATE_FORMATS[locale]);
  }, [locale, tz]);

  const formattedDateTime = useCallback(date => {
    if (tz) {
      return dayjs(date).tz(tz).format(DATE_TIME_FORMATS[locale]);
    }
    return dayjs(date).format(DATE_TIME_FORMATS[locale]);
  }, [locale, tz]);

  return {
    locale,
    dateFormat: DATE_FORMATS[locale],
    dateTimeFormat: DATE_TIME_FORMATS[locale],
    formattedDate,
    formattedDateTime,
  };
};
