import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Feature from 'rapidfab/components/Feature';
import EditEstimationTimeModal from 'rapidfab/components/modals/EditEstimationTimeModal';
import { Button, Card, OverlayTrigger, Table, Tooltip } from 'react-bootstrap';
import { useSelector } from 'react-redux';

import { faQuestionCircle, faInfoCircle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import dayjs from 'dayjs';
import _get from 'lodash/get';
import _round from 'lodash/round';
import _groupBy from 'lodash/groupBy';
import LastUpdated from 'rapidfab/components/LastUpdated';
import UseNonMfgLanguageFeature from 'rapidfab/components/generalMfgLanguage/UseNonMfgLanguageFeature';
import EditRunActualsModalContainer from 'rapidfab/components/records/run/EditRunActualsModalContainer';
import Loading from 'rapidfab/components/Loading';
import { FEATURES, MATERIAL_UNITS, RUN_OPERATIONS, RUN_STATUSES } from 'rapidfab/constants';
import {
  FORMATTED_DURATION_TYPES,
  FormattedDateTime,
  FormattedVolume,
  FormattedMessage,
  FormattedOptionalDuration,
} from 'rapidfab/i18n';
import { getRouteUUIDResource, getRunActualsForRun } from 'rapidfab/selectors';
import { convertMassToOtherUnit } from 'rapidfab/utils/mathUtils';
import TimeFormattedHHMMSSWithTooltip from 'rapidfab/components/TimeFormattedHHMMSSWithTooltip';
import FormattedWeight from 'rapidfab/components/FormattedWeight';
import EditRunEstimationWeightModal from 'rapidfab/components/modals/EditRunEstimationWeightModal';

const RunData = ({
  estimates,
  estimatesPrintTime,
  handleEstimatesInputChange,
  showEstimationTimeEditModal,
  toggleEstimationModal,
  isEstimationsSubmitting,
  onEstimatesSubmit,
  canEditActuals,
  toggleActualsModal,
  showActualsEditModal,
  isUserManagedPrinterType,
  runMaterialData,
  materialsForRunMaterials,
  disabled,
  onEstMaterialWeightSubmit,
}) => {
  const {
    runMaterial,
    runMaterialFetching,
  } = runMaterialData;

  const run = useSelector(getRouteUUIDResource);
  const materials = _groupBy(runMaterial?.materials, 'type');
  const runActuals = useSelector(state => getRunActualsForRun(state, run));
  const [showEstMaterialWeightModal, setShowEstMaterialWeightModal] = useState(false);
  const startTime =
    runActuals && runActuals.start_in_progress_time && dayjs(runActuals.start_in_progress_time);
  const endTime =
    runActuals && runActuals.end_in_progress_time && dayjs(runActuals.end_in_progress_time);
  const time = (startTime && endTime) ? endTime.diff(startTime, 'seconds') : null;
  const isRunComplete = run?.status === RUN_STATUSES.COMPLETE;

  const actuals = {
    base: _get(runActuals?.additive, 'base_material_used'),
    end: _get(runActuals, 'end_in_progress_time'),
    time,
    start: _get(runActuals, 'start_in_progress_time'),
    support: _get(runActuals?.additive, 'support_material_used'),
    uri: _get(runActuals, 'uri'),
  };
  const operation = run?.operation;

  const userManagedPrinterTooltip = (
    <OverlayTrigger
      placement="top"
      overlay={(
        <Tooltip>
          <p>Printer Types using a User Managed packing type must have their schedule estimates entered manually.</p>
        </Tooltip>
      )}
    >
      <FontAwesomeIcon className="spacer-left spacer-right" icon={faQuestionCircle} />
    </OverlayTrigger>
  );

  const renderMaterialsTooltip = materials => {
    const tooltipText = materials.length > 1 ?
      'This run contains multiple material types' :
      'This run contains a single material type';
    return (
      runMaterialFetching ?
        <Loading inline className="spacer-left" /> :
        (
          <OverlayTrigger
            placement="top"
            overlay={(
              <Tooltip>
                <ul>
                  <p className="text-left">
                    <strong>{tooltipText}</strong>
                  </p>
                  {
                    materials.map(material => {
                      const name = material.material;
                      if (name) {
                        return (
                          <li className="text-left" key={name}>
                            {name}&nbsp;
                          </li>
                        );
                      }
                      return null;
                    })
                  }
                </ul>
              </Tooltip>
            )}
          >
            <FontAwesomeIcon
              className="spacer-left"
              icon={faInfoCircle}
            />
          </OverlayTrigger>
        )
    );
  };

  // Volume is always in cm^3, density is always in g/cm^3
  const calculateMaterialMass = (volume, density, massUnit) => {
    // Calculate mass of material in g
    const mass = volume * density;

    // Convert mass to input unit
    return _round(convertMassToOtherUnit(mass, MATERIAL_UNITS.GRAM, massUnit), 3);
  };
  const estMaterialWeight = runMaterial?.materials[0]
  && materialsForRunMaterials[runMaterial.materials[0].uri]?.density ? calculateMaterialMass(
      runMaterial.materials[0].quantity_estimate,
      materialsForRunMaterials[runMaterial.materials[0].uri].density,
      materialsForRunMaterials[runMaterial.materials[0].uri].units,
    ) : null;
  const actualMaterialWeight = runMaterial?.materials[0]
  && materialsForRunMaterials[runMaterial.materials[0].uri]?.density ? calculateMaterialMass(
      runMaterial.materials[0].quantity_actual,
      materialsForRunMaterials[runMaterial.materials[0].uri].density,
      materialsForRunMaterials[runMaterial.materials[0].uri].units,
    ) : null;

  const showEditEstMaterialWeightButton = new Set(
    runMaterial?.materials.map(material => material.uri) || [],
  ).size === 1;

  const { units, density } = materialsForRunMaterials[runMaterial?.materials[0]?.uri] || {};

  return (
    <>
      <Card bg="dark" className="mb15">
        <div className="card-body-wrapper">
          <Feature featureName={FEATURES.USER_CAN_UPDATE_ESTIMATES}>
            <EditEstimationTimeModal
              show={showEstimationTimeEditModal}
              printTime={estimatesPrintTime}
              onChange={handleEstimatesInputChange}
              submit={onEstimatesSubmit}
              onHide={toggleEstimationModal}
              loading={isEstimationsSubmitting}
              modelName="run"
            />
          </Feature>
          {/* <Feature featureName={FEATURES.ROBOZE_DDW}> */}
          <EditRunEstimationWeightModal
            initialValues={{ weight: estMaterialWeight }}
            show={showEstMaterialWeightModal}
            onSubmit={onEstMaterialWeightSubmit}
            onHide={() => setShowEstMaterialWeightModal(false)}
          />
          {/* </Feature> */}

          <EditRunActualsModalContainer
            show={showActualsEditModal}
            onHide={toggleActualsModal}
            actuals={actuals}
            materialWeight={actualMaterialWeight}
            units={units}
            density={density}
            runUri={_get(run, 'uri')}
          />

          <Table bordered size="sm" className="m-b-0">
            <thead>
              <tr>
                <th />
                <th>
                  <FormattedMessage id="estimates" defaultMessage="Estimates" />
                  {isUserManagedPrinterType && userManagedPrinterTooltip}
                </th>
                <th>
                  <div className="d-flex justify-content-between">
                    <FormattedMessage id="actuals" defaultMessage="Actuals" />
                    {canEditActuals && (
                      <Button size="xs" onClick={toggleActualsModal} disabled={disabled}>
                        <FormattedMessage id="button.edit" defaultMessage="Edit" />
                      </Button>
                    )}
                  </div>
                </th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>
                  <strong>
                    <FormattedMessage id="start" defaultMessage="Start" />
                  </strong>
                </td>
                <td>
                  {
                    estimates.start && (
                      <FormattedDateTime value={estimates.start} />
                    )
                  }
                </td>
                <td>
                  {actuals.start && (
                    <FormattedDateTime value={actuals.start} />
                  )}
                </td>
              </tr>
              <tr>
                <td>
                  <strong>
                    <FormattedMessage id="end" defaultMessage="End" />
                  </strong>
                </td>
                <td>
                  {
                    estimates.end && (
                      <FormattedDateTime value={estimates.end} />
                    )
                  }
                </td>
                <td>
                  {actuals.end && (
                    <FormattedDateTime value={actuals.end} />
                  )}
                </td>
              </tr>
              {operation === RUN_OPERATIONS.PRINTING && (
                <tr>
                  <td>
                    <strong>
                      <UseNonMfgLanguageFeature
                        mfgLanguageComponent={<FormattedMessage id="printTime" defaultMessage="Print Time" />}
                        nonMfgLanguageComponent={(
                          <FormattedMessage
                            id="mfg.printTime.productionTime"
                            defaultMessage="Production Time"
                          />
                        )}
                      />
                    </strong>
                  </td>
                  <td>
                    {
                      (estimates?.time?.run_duration) && (
                        <TimeFormattedHHMMSSWithTooltip timeInSeconds={estimates?.time?.run_duration}>
                          <span>
                            <FormattedOptionalDuration
                              intervalFormat={FORMATTED_DURATION_TYPES.HH_MM_SS}
                              value={estimates?.time?.run_duration}
                            />
                          </span>
                        </TimeFormattedHHMMSSWithTooltip>
                      )
                    }
                    {' '}
                    <Feature featureName={FEATURES.USER_CAN_UPDATE_ESTIMATES}>
                      <Button size="xs" onClick={toggleEstimationModal} disabled={disabled}>
                        <FormattedMessage id="button.edit" defaultMessage="Edit" />
                      </Button>
                    </Feature>
                  </td>
                  <td>
                    {
                      actuals.time && (
                        <TimeFormattedHHMMSSWithTooltip
                          timeInSeconds={actuals.time}
                        >
                          <span>
                            <FormattedOptionalDuration
                              intervalFormat={FORMATTED_DURATION_TYPES.HH_MM_SS}
                              value={actuals.time}
                            />
                          </span>
                        </TimeFormattedHHMMSSWithTooltip>

                      )

                    }
                  </td>
                </tr>
              )}
              {runMaterial?.materials[0] && materialsForRunMaterials[runMaterial.materials[0].uri]?.density && (
                <tr>
                  <td>
                    <strong>
                      <FormattedMessage
                        id="materialWeight"
                        defaultMessage="Material Weight"
                      />
                    </strong>
                  </td>
                  <td>
                    {/* `Quantity_estimate` means volume */}

                    <FormattedWeight
                      value={estMaterialWeight}
                      valueUnits={materialsForRunMaterials[runMaterial.materials[0].uri].units}
                    />
                    {' '}
                    {showEditEstMaterialWeightButton && (
                      <Button size="xs" onClick={() => setShowEstMaterialWeightModal(true)}>
                        <FormattedMessage id="button.edit" defaultMessage="Edit" />
                      </Button>
                    )}
                  </td>
                  <td>
                    <FormattedWeight
                      value={actualMaterialWeight}
                      valueUnits={materialsForRunMaterials[runMaterial.materials[0].uri].units}
                    />
                  </td>
                </tr>
              )}
              {operation === RUN_OPERATIONS.POST_PROCESSING && (
                <tr>
                  <td>
                    <strong>
                      <FormattedMessage
                        id="postProcessingTime"
                        defaultMessage="Post-Processing Time"
                      />
                    </strong>
                  </td>
                  <td>
                    {estimates?.time?.run_duration && (
                      <TimeFormattedHHMMSSWithTooltip
                        timeInSeconds={estimates?.time?.run_duration}
                      >

                        <span>
                          <FormattedOptionalDuration
                            intervalFormat={FORMATTED_DURATION_TYPES.HH_MM_SS}
                            value={estimates.time.run_duration}
                          />
                        </span>
                      </TimeFormattedHHMMSSWithTooltip>
                    )}
                  </td>
                  <td>
                    {actuals?.time && (
                      <TimeFormattedHHMMSSWithTooltip
                        timeInSeconds={actuals?.time}
                      >

                        <span>
                          <FormattedOptionalDuration
                            intervalFormat={FORMATTED_DURATION_TYPES.HH_MM_SS}
                            value={actuals.time}
                          />
                        </span>
                      </TimeFormattedHHMMSSWithTooltip>
                    )}
                  </td>
                </tr>
              )}
              {operation === RUN_OPERATIONS.SHIPPING && (
                <tr>
                  <td>
                    <strong>
                      <FormattedMessage
                        id="shippingTime"
                        defaultMessage="Shipping Time"
                      />
                    </strong>
                  </td>
                  <td>
                    {
                      estimates?.time?.run_duration && (
                        <TimeFormattedHHMMSSWithTooltip
                          timeInSeconds={estimates?.time?.run_duration}
                        >
                          <span>
                            <FormattedOptionalDuration
                              intervalFormat={FORMATTED_DURATION_TYPES.HH_MM_SS}
                              value={estimates.time.run_duration}
                            />
                          </span>
                        </TimeFormattedHHMMSSWithTooltip>
                      )
                    }
                  </td>
                  <td>
                    {actuals?.time && (
                      <TimeFormattedHHMMSSWithTooltip
                        timeInSeconds={actuals?.time}
                      >
                        <span>
                          <FormattedOptionalDuration
                            intervalFormat={FORMATTED_DURATION_TYPES.HH_MM_SS}
                            value={actuals.time}
                          />
                        </span>
                      </TimeFormattedHHMMSSWithTooltip>

                    )}
                  </td>
                </tr>
              )}
              {operation === RUN_OPERATIONS.PRINTING && (
                <>
                  <tr>
                    <td>
                      <strong>
                        <FormattedMessage
                          id="baseUsed"
                          defaultMessage="Base Used"
                        />
                      </strong>
                    </td>
                    <td>
                      {
                        estimates && estimates.baseMaterial && (
                          <FormattedVolume
                            value={estimates.baseMaterial}
                            valueUnits={
                              // `run.materials_support is always returned in cm3
                              MATERIAL_UNITS.CM3
                            }
                          />
                        )
                      }
                      {
                        materials && materials?.base &&
                    renderMaterialsTooltip(materials.base, 'quantity_estimate')
                      }
                    </td>
                    <td>
                      {isRunComplete && actuals.base && (
                        <FormattedVolume
                          value={actuals.base}
                          // `run-actuals.support_material_used` is always returned in cm3
                          valueUnits={MATERIAL_UNITS.CM3}
                        />
                      )}
                      {
                        materials && materials?.base &&
                    renderMaterialsTooltip(materials.base, 'quantity_actual')
                      }
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <strong>
                        <FormattedMessage
                          id="supportUsed"
                          defaultMessage="Support Used"
                        />
                      </strong>
                    </td>
                    <td>
                      {
                        estimates && estimates.supportMaterial && (
                          <FormattedVolume
                            value={estimates.supportMaterial}
                            valueUnits={
                              // `run.materials_support is always returned in cm3
                              MATERIAL_UNITS.CM3
                            }
                          />
                        )
                      }
                      {
                        materials && materials?.support &&
                    renderMaterialsTooltip(materials.support, 'quantity_estimate')
                      }
                    </td>
                    <td>
                      {isRunComplete && actuals.support && (
                        <FormattedVolume
                          value={actuals.support}
                          // `run-actuals.support_material_used` is always returned in cm3
                          valueUnits={MATERIAL_UNITS.CM3}
                        />
                      )}
                      {
                        materials && materials?.support &&
                    renderMaterialsTooltip(materials.support, 'quantity_actual')
                      }
                    </td>
                  </tr>
                </>
              )}
            </tbody>
          </Table>
        </div>
      </Card>
      <div className="d-flex"> Actuals&nbsp;<LastUpdated resource={runActuals} capitalized={false} /></div>
    </>
  );
};

RunData.defaultProps = {
  estimates: {
    end: null,
    start: null,
    materials: {
      base: null,
      support: null,
    },
    time: {
      post_processing: null,
      print: null,
      shipping: null,
    },
  },
  showEstimationTimeEditModal: false,
  isEstimationsSubmitting: false,
  onEstimatesSubmit: () => true,
  handleEstimatesInputChange: () => true,
  toggleEstimationModal: () => true,
  estimatesPrintTime: null,
  canEditActuals: false,
  toggleActualsModal: () => true,
  showActualsEditModal: false,
  runActuals: {},
  isUserManagedPrinterType: false,
  runMaterialData: {},
};

RunData.propTypes = {
  estimates: PropTypes.shape({
    end: PropTypes.string,
    start: PropTypes.string,
    baseMaterial: PropTypes.number,
    supportMaterial: PropTypes.number,
    time: PropTypes.shape({
      run_duration: PropTypes.number,
      pre_run_duration: PropTypes.number,
      post_run_duration: PropTypes.number,
    }),
  }),
  onEstimatesSubmit: PropTypes.func,
  handleEstimatesInputChange: PropTypes.func,
  isEstimationsSubmitting: PropTypes.bool,
  showEstimationTimeEditModal: PropTypes.bool,
  toggleEstimationModal: PropTypes.func,
  estimatesPrintTime: PropTypes.string,
  canEditActuals: PropTypes.bool,
  toggleActualsModal: PropTypes.func,
  showActualsEditModal: PropTypes.bool,
  runActuals: PropTypes.shape({}),
  isUserManagedPrinterType: PropTypes.bool,
  runMaterialData: PropTypes.shape({
    runMaterial: PropTypes.shape({
      materials: PropTypes.arrayOf(PropTypes.shape({
        quantity_estimate: PropTypes.number,
        quantity_actual: PropTypes.number,
        density: PropTypes.number,
        uri: PropTypes.string,
      })),
    }),
    runMaterialFetching: PropTypes.bool,
  }),
  materialsForRunMaterials: PropTypes.shape({}).isRequired,
  disabled: PropTypes.bool.isRequired,
  onEstMaterialWeightSubmit: PropTypes.func.isRequired,
};

export default RunData;
