import React from 'react';

const ArrowSubMenu = props => (
  <svg xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" viewBox="0 0 24 24" {...props}>
    <path
      fill="none"
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={2}
      d="m10 16l4-4l-4-4"
    />
  </svg>
);

export default ArrowSubMenu;
