import { createSelector } from 'reselect';
import _map from 'lodash/map';
import _filter from 'lodash/filter';
import { getLabels } from 'rapidfab/selectors/legacy-selectors';
import _find from 'lodash/find';
import { getPredicate, getStateResources } from './helpers/base';
import { getStateLabelRelationships } from './baseStateSelectors';

// eslint-disable-next-line import/prefer-default-export
export const getLabelRelationships = createSelector(
  [getStateLabelRelationships, getStateResources],
  (uuids, resources) => _map(uuids, uuid => resources[uuid]),
);

export const getLabelRelationshipsForPieceUris = createSelector(
  [getPredicate, getLabelRelationships, getLabels],
  (pieceUris, labelRelationships, labels) => {
    const labelRelationshipResources = _filter(labelRelationships, labelRelationshipItem =>
      pieceUris.includes(labelRelationshipItem.target_uri));

    const result = labelRelationshipResources.map(labelRelationshipItem => ({
      ...labelRelationshipItem,
      label: _find(labels, { uri: labelRelationshipItem.label }),
    }))
      .filter(({ label }) => label?.name !== 'No Export Control');

    return result;
  },
);
