import { FILE_EXTENSIONS } from 'rapidfab/constants';
import Alert from 'rapidfab/utils/alert';

export const getFileExtension = file => {
  try {
    return file.name.toLowerCase().split('.').pop();
  } catch {
    return null;
  }
};

// Default method for onDropRejected, with a `next` callback fn.
export const onDropzoneFileRejected = (filesErrors, nextCallback = null) => {
  filesErrors.forEach(({ errors }) => {
    errors.forEach(({ message }) => {
      Alert.error(message);
    });
  });

  if (nextCallback) nextCallback();
};

export const getStringFileExtension = filename => {
  const dotIndex = filename.lastIndexOf('.');
  return dotIndex !== -1 ? filename.slice(dotIndex + 1) : '';
};

export function readableFileSize(size) {
  const units = ['B', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];
  let index = 0;
  while (size >= 1024) {
    // eslint-disable-next-line no-param-reassign
    size /= 1024;
    index += 1;
  }
  return `${size.toFixed(1)} ${units[index]}`;
}

export function updateFileLoaderUploadingProgress(startTime, newPercentage) {
  if (newPercentage <= 0 || newPercentage >= 100) return null;

  const currentTime = Date.now();
  const elapsedTime = (currentTime - startTime) / 1000;
  const estimatedTotalTime = elapsedTime / (newPercentage / 100);
  const remainingTime = estimatedTotalTime - elapsedTime;

  return Math.max(0, remainingTime);
}

// Determine the correct singular or plural form for "second" and "minute" or "hour"
const formatTimeUnit = (value, unit) => {
  const pr = new Intl.PluralRules('en-US', { type: 'cardinal' });
  const pluralForm = pr.select(value);
  const units = {
    second: { one: 'second', other: 'seconds' },
    minute: { one: 'minute', other: 'minutes' },
    hour: { one: 'hour', other: 'hours' },
  };
  const unitDisplay = units[unit][pluralForm];
  return `~${value} ${unitDisplay} left`;
};

export function formatEstimatedFileLoaderUploadModelTime(seconds, percentage) {
  if (percentage === 100) return 'Upload complete';
  if (!seconds || percentage === 0) return 'Estimating time...';

  if (seconds < 60) {
    return formatTimeUnit(Math.round(seconds), 'second');
  }
  const minutes = Math.round(seconds / 60);
  return formatTimeUnit(minutes, 'minute');
}

/**
 * Truncates a file name to a specified maximum length, ensuring that the file extension is preserved.
 * If the file name exceeds the maximum length, it is truncated and an ellipsis (...) is inserted in the middle.
 * The function aims to balance the truncated parts on both sides of the ellipsis.
 *
 * @param {string} fileName - The original file name to be truncated.
 * @param {number} maxLength - The maximum allowed length of the file name after truncation.
 * @returns {string} The truncated file name with its extension preserved. If the original file name
 *                   is shorter than or equal to the maximum length, it is returned unchanged.
 *
 * @example
 * // Truncates a file name to 10 characters, preserving the file extension
 * truncateFileName('verylongfilename.txt', 10);
 * // Expected output: 'ver...txt'
 *
 * @example
 * // Returns the original file name if it's within the maxLength limit
 * truncateFileName('short.txt', 10);
 * // Expected output: 'short.txt'
 */
export function truncateFileName(fileName = '', maxLength) {
  if (fileName.length <= maxLength) {
    return fileName;
  }

  const fileExtension = fileName.split('.').pop();
  const baseName = fileName.slice(0, -fileExtension.length - 1); // Remove extension for calculation
  const maxBaseLength = maxLength - fileExtension.length - 3; // Subtract 3 for the ellipsis

  // Calculate the number of characters to show from the start and the end
  const startChars = Math.floor(maxBaseLength / 2);
  const endChars = Math.ceil(maxBaseLength / 2);

  // Truncate and ensure there's no leading space after the ellipsis
  const truncatedBaseName = `${baseName.slice(0, startChars)}...${baseName.slice(-endChars)}`.replace(' ...', '...');

  return `${truncatedBaseName}.${fileExtension}`;
}

export function isStpOrStepFileConversionType(model) {
  // Check if the model is defined and not null
  if (!model) return false;

  // Check if the model has the conversion_original_type field, it's not null,
  // and its value is either "stp" or "step"
  return [FILE_EXTENSIONS.STP, FILE_EXTENSIONS.STEP].includes(model.conversion_original_type);
}

export function fileNameHasPotentialNullByte(fileName) {
  // Regex to check for the pattern that might be interpreted as a null byte
  // Plus a check for actual null byte presence
  if (/\\00/.test(fileName) || fileName.includes('\u0000')) {
    // eslint-disable-next-line no-console
    console.error(`Invalid file name: ${fileName}.`);
    return true; // Indicate that this file has a problematic pattern
  }

  return false; // No problematic pattern found
}

export function handleCheckSelectedFiles(files) {
  const validFiles = [];
  const invalidFiles = [];

  files.forEach(file => {
    if (fileNameHasPotentialNullByte(file.name)) {
      invalidFiles.push(file.name);
    } else {
      validFiles.push(file);
    }
  });

  // Inform the user about the invalid files using template literals for better readability
  if (invalidFiles.length > 0) {
    Alert.error(`${invalidFiles.join(', ')} cannot be uploaded due to incorrect file name. Please rename the file(s) and try again.`);
  }

  return validFiles;
}
