import React from 'react';
import PropTypes from 'prop-types';

export const textColor = {
  Warning: { color: '#e4d836' },
  Danger: { color: '#e64759' },
  Success: { color: '#1bc98e' },
  Default: { color: '#ffffff' },
};

const TextIndicator = ({ title, valueText, valueSubtext, color }) => (
  <div className="text-indicator">
    {title && <h4 className="text-center">{title}</h4>}
    <div className="text-indicator__value-wrapper">
      {valueText && <div className="text-indicator__valuetext" style={{ color }}>{valueText}</div>}
      <div className="text-indicator__subtext">{valueSubtext}</div>
    </div>
  </div>
);

TextIndicator.defaultProps = {
  title: '',
  valueText: '',
  valueSubtext: '',
  color: '#FFF',
};

TextIndicator.propTypes = {
  title: PropTypes.string,
  valueText: PropTypes.string,
  valueSubtext: PropTypes.string,
  color: PropTypes.string,
};

export default TextIndicator;
