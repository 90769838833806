import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import dayjs from 'dayjs';

import {
  getDowntimesForMachine,
  getRouteUUID,
  getRouteUUIDResource,
  getRunEstimateForRun,
  getRunEstimatesByRunUri,
  getRunRescheduleQueue,
} from 'rapidfab/selectors';
import { extractUuid } from 'rapidfab/utils/uuidUtils';
import Actions from 'rapidfab/actions';

import Loading from 'rapidfab/components/Loading';
import RunRecordSchedule from 'rapidfab/components/records/run/RunRecordSchedule';
import { API_RESOURCES, LIST_BY_URIS_CHUNK_SIZE, PAGINATION_IGNORE_DEFAULT_LIMIT, ROUTES } from 'rapidfab/constants';
import _chunk from 'lodash/chunk';
import { useNavigate } from 'react-router-dom';
import { getRouteURI } from 'rapidfab/utils/uriUtils';

function handleBack(uri) {
  window.location.hash = `#/records/run/${extractUuid(uri)}`;
}

const RunRecordScheduleContainer = props => {
  const uuid = useSelector(getRouteUUID);
  const run = useSelector(getRouteUUIDResource);
  const [printer, setPrinter] = useState();
  const runEstimates = useSelector(state => getRunEstimateForRun(state, run));
  const runEstimatesKeyedByRunUri = useSelector(getRunEstimatesByRunUri);
  const [nonPrintingEstimates, setEstimates] = useState([]);
  const [showDowntimeWarning, setShowDowntimeWarning] = useState(false);
  const estimateQueue = useSelector(getRunRescheduleQueue);
  const downtimes = useSelector(state => getDowntimesForMachine(state, printer));
  const navigate = useNavigate();

  const complete = {
    // run.start / run.end is for Manually Scheduled case, otherwise use estimates
    currentStart: runEstimates && runEstimates.estimates.start,
    currentFinish: runEstimates && runEstimates.estimates.end,
    id: run && run.id,
    operation: run && run.operation,
    estimateQueue: nonPrintingEstimates.length ? nonPrintingEstimates : estimateQueue,
    uri: run && run.uri,
  };

  const selected = {
    runEstimatesKeyedByRunUri,
    uuid,
    ...(run
      ? complete
      : null),
  };

  const [startDate, setStartDate] = useState(() => dayjs()
    .add(1, 'd').format('YYYY-MM-DD'));
  const [startTime, setStartTime] = useState(() => dayjs()
    .minute(0).add(1, 'h').format('HH:mm'));
  const [finishDate, setFinishDate] = useState(() => dayjs()
    .add(1, 'd').format('YYYY-MM-DD'));
  const [finishTime, setFinishTime] = useState(() => dayjs()
    .minute(0).add(2, 'h').format('HH:mm'));
  const [isSubmitting, setIsSubmitting] = useState(false);

  const dispatch = useDispatch();

  useEffect(() => {
    // Fetch run estimates
    dispatch(Actions.Api.nautilus[API_RESOURCES.RUN].get(uuid)).then(runResponse => {
      const { printer, uri, workstation_name } = runResponse?.json ?? {};
      const printerUuid = extractUuid(printer);
      setPrinter(printer);
      if (printerUuid) {
        dispatch(Actions.Api.nautilus[API_RESOURCES.PRINTER].get(printerUuid)).then(printerResponse => {
          if (printerResponse.json?.queue?.length) {
            _chunk(printerResponse.json.queue, LIST_BY_URIS_CHUNK_SIZE).forEach(runsUrisChunk => {
              // schedule_runs is without pagination, so page limit is not used
              dispatch(Actions.Api.nautilus[API_RESOURCES.SCHEDULE_RUNS].list(
                { run: runsUrisChunk },
              ));
            });
          }
        });
        dispatch(Actions.Api.nautilus[API_RESOURCES.RUN].list({ printer }, {
          limit: PAGINATION_IGNORE_DEFAULT_LIMIT,
        }));
        dispatch(Actions.Api.nautilus[API_RESOURCES.DOWNTIME].list({ printer }));
      } else {
        dispatch(Actions.Api.nautilus[API_RESOURCES.RUN].list({ workstation_name }))
          .then(workstationResponse => {
            if (workstationResponse?.json?.resources?.length) {
              const runsUris = workstationResponse.json.resources.map(run => run.uri);
              dispatch(Actions.Api.nautilus[API_RESOURCES.SCHEDULE_RUNS].list(
                { run: runsUris },
              ))
                .then(scheduleRunsResponse => {
                  setEstimates(scheduleRunsResponse.json.resources);
                });
            }
          });
      }

      dispatch(Actions.Api.nautilus[API_RESOURCES.SCHEDULE_RUNS].list({ run: uri }));
    });
  }, [uuid]);

  const handleInputChange = event => {
    const { value, name } = event.target;
    switch (name) {
      case 'startDate':
        setStartDate(value);
        break;
      case 'startTime':
        setStartTime(value);
        break;
      case 'finishDate':
        setFinishDate(value);
        break;
      case 'finishTime':
        setFinishTime(value);
        break;
      default:
        break;
    }
  };

  const getStart = () => dayjs(`${startDate} ${startTime}`);

  const getFinish = () => dayjs(`${finishDate} ${finishTime}`);

  const isStartValid = () => getStart().isAfter(dayjs());

  const isFinishValid = () => {
    const start = getStart();
    const finish = getFinish();
    return finish.isAfter(dayjs()) && finish.isAfter(start);
  };

  // Check if any downtimes intersect with scheduled run
  const isDuringDowntime = () => downtimes.some(downtime =>
    (getStart().isSameOrBefore(downtime.finish) && getFinish().isSameOrAfter(downtime.start)
    ),
  );

  const handleSubmit = async event => {
    event.preventDefault();
    // If there is downtime and the run conflicts with it, show the user the warning modal
    // Note that it's assumed if the warning is being shown and the user is submitting, they're confirming the dialog
    // So continue with submitting the rescheduled run
    if (!showDowntimeWarning && isDuringDowntime()) {
      setShowDowntimeWarning(true);
      return;
    }
    setShowDowntimeWarning(false);

    setIsSubmitting(true);
    const { uri } = complete;
    const payload = {
      estimates: {
        start: getStart().toISOString(),
        end: getFinish().toISOString(),
      },
    };
    const estimates = runEstimatesKeyedByRunUri[uri];
    await dispatch(
      Actions.Api.nautilus[API_RESOURCES.SCHEDULE_RUNS].put(estimates?.uuid, payload),
    );
    navigate(getRouteURI(ROUTES.RUN_EDIT, { uuid: extractUuid(uri) }, null, true));
    setIsSubmitting(false);
  };

  return !complete.uri ? (
    <Loading />
  ) : (
    <RunRecordSchedule
      {...props}
      {...selected}
      operation={run.operation}
      startDate={startDate}
      startTime={startTime}
      finishDate={finishDate}
      finishTime={finishTime}
      submitting={isSubmitting}
      handleBack={handleBack}
      handleSubmit={handleSubmit}
      handleInputChange={handleInputChange}
      isStartValid={isStartValid()}
      isFinishValid={isFinishValid()}
      showDowntimeWarning={showDowntimeWarning}
      hideDowntimeWarning={() => setShowDowntimeWarning(false)}
    />
  );
};

export default RunRecordScheduleContainer;
