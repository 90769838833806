import { MACHINE_TYPES } from 'rapidfab/constants/machineTypes';

const findSlicerMachineType = (slicer, machines, cbs) => {
  const { printerTypeByURI, printersByURI, jeniCluster } = machines;
  const additionalPropertiesFromCallbacks = cbs.map(callback => callback());

  const { printer, printer_type, jeni_cluster } = slicer;
  let machineType;

  // Slicer Configs will only have 1 of the following values.
  // If Slicer is attached to printer_type then the other two will be null.
  // If for some reason all of them are null then the machineType is set to none

  if (printer) machineType = MACHINE_TYPES.PRINTER;
  if (printer_type) machineType = MACHINE_TYPES.PRINTER_TYPE;
  if (jeni_cluster) machineType = MACHINE_TYPES.JENI_CLUSTER;
  if (!jeni_cluster && !printer && !printer_type) machineType = 'none';

  const connectedJeniCluster = jeniCluster?.find(currentJeniCluster => currentJeniCluster.uri === slicer[machineType]);

  const machine = printersByURI[slicer[machineType]]?.name || printerTypeByURI[slicer[machineType]]?.name || connectedJeniCluster?.name || 'none';
  return Object.assign({ ...slicer, machine, machineType }, ...additionalPropertiesFromCallbacks);
};

const machineFieldAddedToSlicerConfig = (slicerConfigs, machines, ...cbs) => {
// cbs are optional arguements of call back functions for adding additional properties
// example addUploadLocationToSlicer in slicerConfigurationcontainer
// wants to header locations to the slicer config so it is added as a 3rd parameter

  if (!slicerConfigs || !machines) return null;
  const isSlicerConfigOfTypeList = Array.isArray(slicerConfigs);
  let slicerWithMachines;

  if (isSlicerConfigOfTypeList) {
    slicerWithMachines = slicerConfigs.map(config => findSlicerMachineType(config, machines, cbs));
  } else {
    slicerWithMachines = findSlicerMachineType(slicerConfigs, machines, cbs);
  }
  return slicerWithMachines;
};

export default machineFieldAddedToSlicerConfig;
