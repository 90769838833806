import React from 'react';
import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import _map from 'lodash/map';
import { FormattedMessage } from 'react-intl';
import SaveButtonTitle from 'rapidfab/components/SaveButtonTitle';
import WorkInstruction from 'rapidfab/components/records/WorkInstruction';
import { extractUuid } from 'rapidfab/utils/uuidUtils';
import { finalFormInputTypes } from 'rapidfab/types';
import LastUpdated from 'rapidfab/components/LastUpdated';
import { Button, ButtonToolbar, Card, Col, FormControl, FormGroup, FormLabel, Row, Table } from 'react-bootstrap';
import { Form, Field, FormSpy } from 'react-final-form';
import { isURLRegex } from 'rapidfab/utils/uriUtils';
import AdditionalURLErrorMessage from 'rapidfab/components/AdditionalURLErrorMessage';
import _isNil from 'lodash/isNil';
import { faPlus } from '@fortawesome/free-solid-svg-icons';

const styles = {
  flexRow: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  positionHeader: {
    width: '5%',
  },
  stepHeader: {
    width: '85%',
    paddingLeft: '27px',
    textAlign: 'left',
  },
  optionsHeader: {
    width: '5%',
  },
};

const WorkChecklist = ({
  isSaving,
  workChecklistLinking,
  onWorkInstructionDelete,
  onWorkInstructionCreate,
  onWorkChecklistSave,
  onWorkInstructionEdit,
  onWorkInstructionReorder,
  onWorkInstructionToggle,
  currentEditableInstruction,
  wrapPanel,
  customHandlers,
  readOnly,
  initialFormValues,
  additionalInstructionUrlError,
  onChange,
  isStepComplete,
  isPowderWorkflow,
  isPOCUKOrderFieldsFeatureEnabled,
  isCustomDarkCardStyle,
}) => {
  const uuid = extractUuid(initialFormValues?.uri);

  const Content = (
    <Form
      // Submitting form by onClick to pass form's state
      onSubmit={() => {}}
      initialValues={initialFormValues}
      mutators={{
        onWorkInstructionEdit,
        onWorkInstructionCreate,
        onWorkInstructionDelete,
        onWorkInstructionToggle,
        onWorkInstructionReorder,
      }}
      render={({ handleSubmit, form, values }) => {
        window.form = form;

        return (
          <form onSubmit={handleSubmit} data-cy="workchecklist-container">
            <FormGroup controlId={`description-${uuid}`} className="form-group">
              <FormLabel>
                <FormattedMessage
                  id="field.description"
                  defaultMessage="Description"
                />:
              </FormLabel>
              <Field
                name="description"
                type="text"
                render={props => (
                  <FormControl
                    data-cy="workchecklist-description"
                    {...props.input}
                    disabled={readOnly || isStepComplete}
                  />
                )}
              />
            </FormGroup>

            <FormGroup controlId={`additional_instruction_url-${uuid}`} className="form-group">
              <FormLabel>
                <FormattedMessage
                  id="form.additionalInstructions"
                  defaultMessage="Additional Instructions Link"
                />:
              </FormLabel>
              <Field
                name="additional_instruction_url"
                type="url"
                validate={value => !isURLRegex(value)}
                parse={value => (_isNil(value) ? null : value)}
                render={fieldProps => (
                  <>
                    <FormControl
                      data-cy="workchecklist-additonal-instructions"
                      {...fieldProps.input}
                      disabled={readOnly || isStepComplete}
                      className={additionalInstructionUrlError && 'highlightWrongUri'}
                      placeholder="http://www.example.com"
                      isInvalid={(fieldProps.meta.touched && fieldProps.meta.invalid && fieldProps.input.value !== '')}
                    />

                    <AdditionalURLErrorMessage {...fieldProps} />
                  </>
                )}
              />
            </FormGroup>

            {values?.work_instructions?.length > 0 && (
              <Table responsive>
                <thead className="work-instructions-table">
                  <tr>
                    <th style={styles.positionHeader}>Position</th>
                    <th style={styles.stepHeader}>Step</th>
                    <th style={styles.optionsHeader}>{readOnly ? 'View' : 'Edit'}</th>
                    <th style={styles.optionsHeader}>Delete</th>
                  </tr>
                </thead>
                <tbody className="work-instructions-table">
                  {_map(values.work_instructions,
                    (workInstruction, index) => (
                      <>
                        <WorkInstruction
                          instruction={workInstruction}
                          key={index}
                          readOnly={readOnly}
                          quantity={values.work_instructions?.length}
                          onDelete={form.mutators.onWorkInstructionDelete}
                          onToggle={form.mutators.onWorkInstructionToggle}
                          onChange={form.mutators.onWorkInstructionEdit}
                          onReorder={form.mutators.onWorkInstructionReorder}
                          isEditable={
                            workInstruction.position === currentEditableInstruction
                          }
                          isStepComplete={isStepComplete}
                          isPowderWorkflow={isPowderWorkflow}
                          isPOCUKOrderFieldsFeatureEnabled={isPOCUKOrderFieldsFeatureEnabled}
                        />
                      </>
                    ))}
                </tbody>
              </Table>
            )}

            {wrapPanel ? <hr /> : values?.work_instructions?.length === 0 && <br />}

            <div className="clearfix">
              <ButtonToolbar className="pull-right">
                {!customHandlers && (
                  <Button
                    variant="success"
                    className="pull-right mr15"
                    onClick={event => {
                      event.preventDefault();
                      onWorkChecklistSave(values);
                    }}
                    disabled={isSaving || readOnly}
                  >
                    <SaveButtonTitle resourceName="Checklist" />
                  </Button>
                )}
                <Button
                  variant="primary"
                  className="pull-right"
                  disabled={readOnly || isStepComplete}
                  onClick={() => form.mutators.onWorkInstructionCreate('work_instructions')}
                >
                  <FontAwesomeIcon icon={faPlus} className="spacer-right" />
                  <FormattedMessage
                    id="button.addStep"
                    defaultMessage="Add Step"
                  />
                </Button>
              </ButtonToolbar>
            </div>

            {!customHandlers && (
              <div>
                <hr />
                <LastUpdated
                  resource={workChecklistLinking && workChecklistLinking.work_checklist}
                />
              </div>
            )}
            <FormSpy
              subscription={{ values: true }}
              onChange={({ values }) => onChange(values)}
            />
          </form>
        );
      }}
    />
  );

  if (wrapPanel) {
    return (
      <Row>
        <Col xs={12}>
          <Card
            bg={`${isCustomDarkCardStyle ? '' : 'dark'}`}
            className={`mb15 ${isCustomDarkCardStyle ? 'custom-darken-modal--card' : ''}`}
            data-cy="work-checklist-table"
            style={isCustomDarkCardStyle ? {} : { marginTop: '26px' }}
          >
            <Card.Header
              className={`${isCustomDarkCardStyle ? 'pd-exp custom-darken-modal--card-header' : 'pd-exp inverse'}`}
            >
              Work Checklist
            </Card.Header>
            <div className={`${isCustomDarkCardStyle ? '' : 'card-body-wrapper'}`}>
              <Card.Body className={`${isCustomDarkCardStyle ? 'custom-darken-modal--card-body' : ''}`}>
                {Content}
              </Card.Body>
            </div>
          </Card>
        </Col>
      </Row>
    );
  }

  return (
    <Row>
      <Col xs={12}>
        {Content}
      </Col>
    </Row>
  );
};

WorkChecklist.defaultProps = {
  customHandlers: false,
  wrapPanel: false,
  currentEditableInstruction: null,
  workChecklistLinking: {},
  readOnly: false,
  additionalInstructionUrlError: false,
  onChange: () => true,
  isCustomDarkCardStyle: false,
};

WorkChecklist.propTypes = {
  isSaving: PropTypes.bool.isRequired,
  customHandlers: PropTypes.bool,
  wrapPanel: PropTypes.bool,
  onWorkChecklistSave: PropTypes.func.isRequired,
  onWorkInstructionCreate: PropTypes.func.isRequired,
  onWorkInstructionDelete: PropTypes.func.isRequired,
  onWorkInstructionToggle: PropTypes.func.isRequired,
  onWorkInstructionEdit: PropTypes.func.isRequired,
  onWorkInstructionReorder: PropTypes.func.isRequired,
  currentEditableInstruction: PropTypes.number,
  workChecklistLinking: PropTypes.shape({
    work_checklist: PropTypes.shape({
      updated: PropTypes.string,
      updated_by: PropTypes.string,
      version: PropTypes.number,
    }),
  }),
  readOnly: PropTypes.bool,
  additionalInstructionUrlError: PropTypes.bool,
  input: finalFormInputTypes.isRequired,
  initialFormValues: PropTypes.shape({
    description: PropTypes.string,
    name: PropTypes.string,
    work_instructions: PropTypes.arrayOf(PropTypes.shape({})),
    uri: PropTypes.string,
    additional_instruction_url: PropTypes.string,
  }).isRequired,
  onChange: PropTypes.func,
  isStepComplete: PropTypes.bool.isRequired,
  isPowderWorkflow: PropTypes.bool.isRequired,
  isPOCUKOrderFieldsFeatureEnabled: PropTypes.bool.isRequired,
  isCustomDarkCardStyle: PropTypes.bool,
};

export default WorkChecklist;
