import { createSelector } from 'reselect';
import * as baseStateSelectors from 'rapidfab/selectors/baseStateSelectors';
import { getStateResources } from 'rapidfab/selectors/helpers/base';
import _map from 'lodash/map';
import _keyBy from 'lodash/keyBy';
import _concat from 'lodash/concat';

export const getGuidelinesForWorkInstruction = createSelector(
  [baseStateSelectors.getStateGuidelinesForWorkInstruction, getStateResources],
  (uuids, resources) => _map(uuids, uuid => resources[uuid]),
);

export const getGuidelinesForWorkInstructionByUri = createSelector([getGuidelinesForWorkInstruction], guidelines =>
  _keyBy(guidelines, 'uri'),
);

export const getGuidelinesForProcessStep = createSelector(
  [baseStateSelectors.getStateGuidelinesForProcessStep, getStateResources],
  (uuids, resources) => _map(uuids, uuid => resources[uuid]),
);

export const getGuidelinesForProcessStepByUri = createSelector([getGuidelinesForProcessStep], guidelines =>
  _keyBy(guidelines, 'uri'),
);

export const getGuidelines = createSelector(
  [
    baseStateSelectors.getStateGuidelinesForWorkInstruction,
    baseStateSelectors.getStateGuidelinesForProcessStep,
    getStateResources,
  ],
  (workInstructionUuids, processStepUuids, resources) =>
    _map(_concat(workInstructionUuids, processStepUuids), uuid => resources[uuid]),
);

export const getGuidelinesByUri = createSelector([getGuidelines], guidelines =>
  _keyBy(guidelines, 'uri'),
);

export const getGuidelinesByUuid = createSelector([getGuidelines], guidelines =>
  _keyBy(guidelines, 'uuid'),
);
