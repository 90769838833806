import Tooltip from 'rapidfab/components/Tooltip';
import React, { useEffect, useRef, useState } from 'react';
import PropTypes, { shape } from 'prop-types';
import {
  Button,
  Col,
  FormLabel,
  FormControl,
  FormGroup,
  Card,
  Row,
} from 'react-bootstrap';
import { FormattedMessage, FormattedMessageMappingOption } from 'rapidfab/i18n';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Feature from 'rapidfab/components/Feature';
import Loading from 'rapidfab/components/Loading';
import { MATERIAL_REASON_CODE_MAP } from 'rapidfab/mappings';
import {
  MATERIAL_REASON_CODE_OPTIONS,
  FEATURES,
  MATERIAL_CONTAINER_NUMBER_FIELD_STEP,
} from 'rapidfab/constants';
import _values from 'lodash/values';
import { faInfoCircle, faPencil, faSave } from '@fortawesome/free-solid-svg-icons';

const BatchEditableQuantity = ({ batch, onSaveQuantityChange, isSaving }) => {
  const formRef = useRef();
  const [isEditMode, setEditMode] = useState(false);
  const [quantity, setQuantity] = useState(batch.quantity);
  const [notes, setNotes] = useState('');
  const [reasonCode, setReasonCode] = useState('');

  useEffect(() => {
    setQuantity(batch.quantity);
  }, [batch.quantity]);

  const onEnableEditMode = () => {
    setEditMode(true);
  };

  const onCancelEditMode = () => {
    setQuantity(batch.quantity);
    setNotes('');
    setReasonCode('');
    setEditMode(false);
  };

  const changeQuantity = event => {
    const { value } = event.target;
    setQuantity(value !== '' ? Number(value) : null);
  };

  const onNotesChange = event => {
    const { value } = event.target;
    setNotes(value);
  };

  const onReasonCodeChange = event => {
    const { value } = event.target;
    setReasonCode(value);
  };

  const onSubmit = event => {
    event.preventDefault();
    if (!formRef.current.reportValidity()) {
      // No need to proceed in case form is not valid
      return;
    }

    onSaveQuantityChange({ quantity, notes, reasonCode });
  };

  return (
    <Card bg="dark" className="mb15">
      <Card.Header className="pd-exp inverse">
        <>
          <span>Material in Batch</span>
          <div className="pull-right">
            {!isEditMode && (
              <Button
                variant="primary"
                size="xs"
                style={{ marginRight: '1rem' }}
                onClick={onEnableEditMode}
              >
                <FontAwesomeIcon icon={faPencil} />{' '}
                <FormattedMessage id="edit" defaultMessage="Edit" />
              </Button>
            )}
            {isEditMode && (
              <>
                <Button
                  variant="default"
                  size="xs"
                  style={{ marginRight: '1rem' }}
                  onClick={onCancelEditMode}
                  disabled={isSaving}
                >
                  <FormattedMessage
                    id="button.cancel"
                    defaultMessage="Cancel"
                  />
                </Button>
                <Button
                  variant="success"
                  size="xs"
                  style={{ marginRight: '1rem' }}
                  onClick={onSubmit}
                  disabled={isSaving}
                >
                  {isSaving ? <Loading inline /> : <FontAwesomeIcon icon={faSave} />}{' '}
                  <FormattedMessage id="button.save" defaultMessage="Save" />
                </Button>
              </>
            )}
          </div>
        </>
      </Card.Header>
      <div className="card-body-wrapper">
        <Card.Body>
          <Row>
            <Col xs={12}>
              <div className="container">
                {isEditMode ? (
                  <form
                    ref={formRef}
                    id="editable-containers-form"
                    onSubmit={onSubmit}
                  >
                    <FormGroup controlId="quantity">
                      <FormLabel>
                        <FormattedMessage
                          id="materialBatch.updateQuantity"
                          defaultMessage="Update Batch Quantity"
                        />
                        :
                      </FormLabel>
                      <FormControl
                        name="quantity"
                        type="number"
                        value={quantity}
                        min="0"
                        onChange={changeQuantity}
                        disabled={isSaving}
                        required
                        step={MATERIAL_CONTAINER_NUMBER_FIELD_STEP}
                      />
                    </FormGroup>
                    <FormGroup controlId="notes">
                      <FormLabel>
                        <FormattedMessage
                          id="quantityEditReason"
                          defaultMessage="Quantity Edit Reason"
                        />
                        :
                      </FormLabel>
                      <FormControl
                        value={notes}
                        onChange={onNotesChange}
                        required
                        disabled={isSaving}
                      />
                    </FormGroup>
                    <Feature featureName={FEATURES.RICOH_REASON_CODE}>
                      <FormGroup controlId="reason-code">
                        <FormLabel>
                          <FormattedMessage
                            id="reasonCode"
                            defaultMessage="Reason Code"
                          />
                          : *
                        </FormLabel>
                        <FormControl
                          as="select"
                          value={reasonCode}
                          onChange={onReasonCodeChange}
                          required
                        >
                          <FormattedMessage id="field.none" defaultMessage="None">
                            {text => <option value="">{text}</option>}
                          </FormattedMessage>
                          {_values(MATERIAL_REASON_CODE_OPTIONS).map(
                            lotReasonOption => (
                              <FormattedMessageMappingOption
                                key={lotReasonOption}
                                value={lotReasonOption}
                                mapping={MATERIAL_REASON_CODE_MAP}
                              />
                            ),
                          )}
                        </FormControl>
                      </FormGroup>
                    </Feature>
                  </form>
                ) : (
                  <p className="mb0">
                    <Tooltip
                      id="materialIsNotInContainers"
                      placement="top"
                      trigger={(<FontAwesomeIcon icon={faInfoCircle} className="spacer-right" />)}
                    >
                      <span>
                        Please use the &quot;Containerise Material&quot; button in the Batch Details
                        section above to containerise the batch
                      </span>
                    </Tooltip>
                    Your material is not currently in a known container.
                  </p>
                )}
              </div>
            </Col>
          </Row>
        </Card.Body>
      </div>
    </Card>
  );
};

BatchEditableQuantity.defaultProps = {
  isSaving: false,
};

BatchEditableQuantity.propTypes = {
  batch: PropTypes.shape({
    uuid: PropTypes.string,
    quantity: PropTypes.number.isRequired,
    units: PropTypes.string.isRequired,
    materials: PropTypes.arrayOf(shape({})).isRequired,
  }).isRequired,
  onSaveQuantityChange: PropTypes.func.isRequired,
  isSaving: PropTypes.bool,
};

export default BatchEditableQuantity;
