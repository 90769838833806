import React from 'react';
import PropTypes from 'prop-types';
import { Card } from 'react-bootstrap';

const ResourceReadOnlyView = ({
  entity,
  placeholder,
  withPanelWrapper,
  title,
  children,
}) => {
  let content = placeholder;
  if (entity) {
    content = React.Children.map(children, child => {
      if (React.isValidElement(child)) {
        // Patching `entity` into each child component
        return React.cloneElement(child, { entity });
      }
      return child;
    });
  }

  if (!withPanelWrapper) {
    return (
      <>
        {title && (<h4>{title}</h4>)}
        {content}
      </>
    );
  }

  return (
    <Card bg="dark" className="mb15">
      <Card.Header className="pd-exp inverse">{title}</Card.Header>
      <div className="card-body-wrapper">
        <Card.Body>{content}</Card.Body>
      </div>
    </Card>
  );
};

ResourceReadOnlyView.defaultProps = {
  entity: null,
  title: null,
  placeholder: 'N/A',
  withPanelWrapper: false,
  children: null,
};

ResourceReadOnlyView.propTypes = {
  entity: PropTypes.shape({}),
  placeholder: PropTypes.node,
  title: PropTypes.node,
  children: PropTypes.node,
  withPanelWrapper: PropTypes.bool,
};

export default ResourceReadOnlyView;
