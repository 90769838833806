import React from 'react';
import PropTypes from 'prop-types';
import AuthentiseLogo from 'rapidfab/images/authentise-logo-192.png';

const QRSticker = ({ children }) => (
  <div className="sticker">
    { children }
    <footer>
      <p className="only-printable">
        Powered by <img className="logo" src={AuthentiseLogo} alt="Authentise Logo" /> Authentise
      </p>
    </footer>
    <hr className="non-printable" />
  </div>
);

QRSticker.propTypes = {
  children: PropTypes.node.isRequired,
};

export default QRSticker;
