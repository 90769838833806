import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Alert from 'react-s-alert';
import WorkChecklist from 'rapidfab/components/records/WorkChecklist';
import validateWorkInstructionPayload, { validateWorkInstruction } from 'rapidfab/utils/validateWorkInstructionPayload';
import * as Selectors from 'rapidfab/selectors';
import { API_RESOURCES, FEATURES, WORK_INSTRUCTION_REPORT_TYPES } from 'rapidfab/constants';
import _filter from 'lodash/filter';
import _maxBy from 'lodash/maxBy';
import _find from 'lodash/find';
import _findIndex from 'lodash/findIndex';
import _map from 'lodash/map';
import _isEqual from 'lodash/isEqual';
import Actions from 'rapidfab/actions';
import { createOrReplaceArray } from 'rapidfab/utils/arrayUtils';
import Loading from 'rapidfab/components/Loading';
import { getEndpointFromURI, isURI } from 'rapidfab/utils/uriUtils';
import { extractUuid } from 'rapidfab/utils/uuidUtils';
import cleanChecklistLinking from 'rapidfab/utils/cleanChecklistLinking';
import { reduxFormFieldType, workChecklistLinkingResourceType } from 'rapidfab/types';
import { connect } from 'react-redux';
import GuidelineSuggestionContext from 'rapidfab/context/GuidelineSuggestionContext';
import { FormattedMessage } from 'react-intl';

class WorkChecklistContainer extends Component {
  static updatePositions(workInstructions) {
    /*
    Update position for all work instructions
    First work instruction will be with 1 position
    Second with 2, and next ...
    */
    return _map(workInstructions, (instruction, index) => ({ ...instruction, position: index + 1 }));
  }

  static isValidWorkInstruction(workInstruction) {
    /*
      Validates descriptions from all work instructions
      Adds alert on validation error and returns false
     */

    if (workInstruction) {
      try {
        validateWorkInstruction(workInstruction);
      } catch (error) {
        Alert.error(error.message);
        return false;
      }
    }

    return true;
  }

  // This method is used only on Workstation Type page, so relatedUri = workstep
  static cleanPayload(payload, relatedURI) {
    return cleanChecklistLinking(
      payload,
      relatedURI,
    );
  }

  constructor(props) {
    super(props);

    this.state = {
      editableWorkInstructionPosition: null,
      // We allow to update only one work instruction at one time.
      // We store index in editableWorkInstructionPosition variable
      // If it's null == nothing is edited at the moment
      additionalInstructionUrlError: false,
    };

    this.onWorkChecklistSave = this.onWorkChecklistSave.bind(this);

    this.onWorkInstructionCreate = this.onWorkInstructionCreate.bind(this);
    this.onWorkInstructionDelete = this.onWorkInstructionDelete.bind(this);
    this.onWorkInstructionEdit = this.onWorkInstructionEdit.bind(this);
    this.onWorkInstructionToggle = this.onWorkInstructionToggle.bind(this);
    this.onWorkInstructionReorder = this.onWorkInstructionReorder.bind(this);
    this.handleAdditionalInstructionError = this.handleAdditionalInstructionError.bind(this);
  }

  componentDidMount() {
    const { workstep, initialFormValues, processStepURI } = this.props;

    this.props.onInitialize(workstep);

    if (this.context) {
      const { selectedGuidelineSuggestion } = this.context;

      if (selectedGuidelineSuggestion && selectedGuidelineSuggestion.processStep.uri === processStepURI) {
        this.setState({
          editableWorkInstructionPosition: initialFormValues.work_instructions.length,
        });
      }
    }
  }

  componentDidUpdate(prevProps, prevState) {
    const { workstep, values, customHandlers } = this.props;
    if (prevProps.workstep !== workstep) {
      this.props.onInitialize(workstep);
    }

    if (!_isEqual(values, prevProps.values) && customHandlers) {
      this.props.onChange(values);
    }

    if (!values?.additional_instruction_url?.length && prevState.additionalInstructionUrlError) {
      this.handleAdditionalInstructionError(false);
    }
  }

  handleAdditionalInstructionError(state) {
    this.setState({ additionalInstructionUrlError: state });
  }

  onWorkInstructionDelete([field, position], state, { changeValue }) {
    const { editableWorkInstructionPosition } = this.state;
    // const workInstructions = this.props.fields.work_instructions;
    const workInstructions = state.formState.values.work_instructions;
    // const workInstructionsValues = workInstructions.value;
    let workInstructionsReplaced = _filter(
      workInstructions,
      instruction => instruction.position !== position,
    );
    workInstructionsReplaced = WorkChecklistContainer.updatePositions(workInstructionsReplaced);

    if (position === editableWorkInstructionPosition) {
      // User can delete work instruction on editable mode
      // We need to cleanup value with current position
      this.setState({ editableWorkInstructionPosition: null });
    }
    changeValue(state, field, () => workInstructionsReplaced);
    // workInstructions.onUpdate(workInstructionsReplaced);
  }

  onWorkInstructionCreate([field], state, { changeValue }) {
    const workInstructions = state?.lastFormState?.values?.work_instructions;
    const currentMaxInstructionPosition = workInstructions && _maxBy(workInstructions, 'position');
    const workInstructionNewPosition =
      currentMaxInstructionPosition ? currentMaxInstructionPosition.position + 1 : 0;

    const workInstruction = {
      additional_instruction_url: '',
      description: '',
      report_type: WORK_INSTRUCTION_REPORT_TYPES.TEXT,
      position: workInstructionNewPosition,
      required: false,
      report_units: null,
      threshold: null,
      threshold_type: null,
      threshold_notification: null,
      threshold_action: null,
      choices: null,
    };

    this.setState({ editableWorkInstructionPosition: workInstructionNewPosition });

    changeValue(state, field, () => (workInstructions
      ? [...workInstructions, workInstruction]
      : [workInstruction]));
    this.onWorkInstructionToggle(['work_instructions', workInstructionNewPosition]);
  }

  // eslint-disable-next-line no-unused-vars
  onWorkInstructionToggle([field, position], state) {
    const workInstructions = state?.formState?.values?.work_instructions;
    const { editableWorkInstructionPosition } = this.state;

    let newPosition = position;

    if (editableWorkInstructionPosition !== null) {
      const workInstruction = _find(workInstructions, { position });
      const isValidWorkInstruction = WorkChecklistContainer.isValidWorkInstruction(workInstruction);
      if (!isValidWorkInstruction) return false;
    }

    if (position === editableWorkInstructionPosition) {
      // If it's already active position, let's set as inactive
      this.setState({ editableWorkInstructionPosition: null });
      newPosition = null;
    }

    this.setState({ editableWorkInstructionPosition: newPosition });
    return true;
  }

  onWorkInstructionEdit([targetName, instruction], state, { changeValue }) {
    const workInstructionsValues = state?.formState?.values?.work_instructions;

    const updatedFields = { [targetName]: instruction.value };

    if (targetName === 'report_type') {
      if (instruction.value === WORK_INSTRUCTION_REPORT_TYPES.NO_ENTRY) {
        // Unset this field as required as nothing is expected here
        updatedFields.required = false;
      }

      if (
        ![WORK_INSTRUCTION_REPORT_TYPES.NUMBER, WORK_INSTRUCTION_REPORT_TYPES.RANGE]
          .includes(instruction.value)
      ) {
        // Unset unit field when work instruction is not Number of Range
        updatedFields.report_units = null;
      }

      if (
        ![WORK_INSTRUCTION_REPORT_TYPES.TEXT, WORK_INSTRUCTION_REPORT_TYPES.NUMBER]
          .includes(instruction.value)
      ) {
        // Unset thresholds fields when work instruction is not Text or Number
        updatedFields.threshold = null;
        updatedFields.threshold_type = null;
        updatedFields.threshold_action = null;
        updatedFields.threshold_notification = null;
      }
    }

    // Clear threshold fields when threshold type is changed
    if (targetName === 'threshold_type') {
      updatedFields.threshold = null;
    }

    const workInstructionsReplaced = createOrReplaceArray(
      workInstructionsValues,
      { position: instruction.position },
      updatedFields,
    );
    changeValue(state, 'work_instructions', () => (workInstructionsReplaced));
  }

  onWorkInstructionReorder([field, order], state, { changeValue }) {
    const { editableWorkInstructionPosition } = this.state;
    // const workInstructions = this.props.fields.work_instructions;
    const workInstructions = state.formState.values.work_instructions;
    let workInstructionsValues = [...workInstructions];

    const workInstructionIndex = _findIndex(workInstructionsValues, ['position', order.position]);
    if (workInstructionIndex === undefined) {
      // It shouldn't be, but let's handle this case as well
      throw new Error('Work Instruction Index is not found, but it should be');
    }

    if (workInstructionIndex === 0 && order.direction === 'up') {
      // Can't move upper first instruction
      return;
    }

    if (workInstructionIndex === (workInstructionsValues.length - 1) && order.direction === 'down') {
      // Can't mode down last instruction
      return;
    }

    if (editableWorkInstructionPosition !== null) {
      // If current Work Instruction is editable and invalid, then reject this swapping
      const editableWorkInstruction = _find(
        workInstructionsValues,
        { position: editableWorkInstructionPosition },
      );
      const isValidWorkInstruction = WorkChecklistContainer.isValidWorkInstruction(
        editableWorkInstruction,
      );
      if (!isValidWorkInstruction) return;
    }

    const swapWithIndex = order.direction === 'up' ? workInstructionIndex - 1 : workInstructionIndex + 1;

    // Swap instructions
    [workInstructionsValues[workInstructionIndex], workInstructionsValues[swapWithIndex]] =
      [workInstructionsValues[swapWithIndex], workInstructionsValues[workInstructionIndex]];

    workInstructionsValues = WorkChecklistContainer.updatePositions(workInstructionsValues);

    changeValue(state, field, () => (workInstructionsValues));
    // workInstructions.onUpdate(workInstructionsValues);
    this.setState({ editableWorkInstructionPosition: null });
  }

  onWorkInstructionReorderPrevious(position, direction) {
    const { editableWorkInstructionPosition } = this.state;
    const workInstructions = this.props.fields.work_instructions;
    let workInstructionsValues = [...workInstructions.value];

    const workInstructionIndex = _findIndex(workInstructionsValues, ['position', position]);
    if (workInstructionIndex === undefined) {
      // It shouldn't be, but let's handle this case as well
      throw new Error('Work Instruction Index is not found, but it should be');
    }

    if (workInstructionIndex === 0 && direction === 'up') {
      // Can't move upper first instruction
      return;
    }

    if (workInstructionIndex === (workInstructionsValues.length - 1) && direction === 'down') {
      // Can't mode down last instruction
      return;
    }

    if (editableWorkInstructionPosition !== null) {
      // If current Work Instruction is editable and invalid, then reject this swapping
      const editableWorkInstruction = _find(
        workInstructionsValues,
        { position: editableWorkInstructionPosition },
      );
      const isValidWorkInstruction = WorkChecklistContainer.isValidWorkInstruction(
        editableWorkInstruction,
      );
      if (!isValidWorkInstruction) return;
    }

    const swapWithIndex = direction === 'up' ? workInstructionIndex - 1 : workInstructionIndex + 1;

    // Swap instructions
    [workInstructionsValues[workInstructionIndex], workInstructionsValues[swapWithIndex]] =
      [workInstructionsValues[swapWithIndex], workInstructionsValues[workInstructionIndex]];

    workInstructionsValues = WorkChecklistContainer.updatePositions(workInstructionsValues);

    workInstructions.onUpdate(workInstructionsValues);
    this.setState({ editableWorkInstructionPosition: null });
  }

  onWorkChecklistSave(values) {
    const { workstep, workChecklistLinking, workstepResource } = this.props;
    const { additionalInstructionUrlError } = this.state;
    const relatedUri = workChecklistLinking.related_uri || workstep;
    const relatedObject = getEndpointFromURI(relatedUri);
    if (!values.work_instructions || !values.work_instructions.length) {
      Alert.warning(
        <FormattedMessage
          id="toaster.warning.workChecklist.addAtLeastOneStep"
          defaultMessage="Please add at least one Step first."
        />);
      return false;
    }

    if (
      values?.additional_instruction_url?.length &&
      !this.props.readOnly &&
      !isURI(values.additional_instruction_url)) {
      Alert.warning(
        <FormattedMessage
          id="toaster.warning.workChecklist.invalidAdditionalInstructionsLink"
          defaultMessage="Please type a valid Additional Instructions Link."
        />);
      this.handleAdditionalInstructionError(true);
      return false;
    }

    const workChecklistDefaults = {
      name: workstepResource ? `${workstepResource.name} Checklist` : 'Checklist',
    };

    let payload = [
      {
        position: workChecklistLinking.position,
        process_step: workChecklistLinking.process_step,
        related_uri: relatedUri,
        work_checklist: {
          ...values,
          ...workChecklistDefaults,
        },
        uri: workChecklistLinking.uri,
      },
    ];

    if (additionalInstructionUrlError) {
      this.handleAdditionalInstructionError(false);
    }

    try {
      validateWorkInstructionPayload(payload);
    } catch (error) {
      Alert.error(error.message);
      return false;
    }

    payload = WorkChecklistContainer.cleanPayload(payload, relatedUri);
    this.props.onFormSubmit(relatedObject.endpointName, relatedObject.uuid, payload, workstep);
    return true;
  }

  render() {
    const {
      fields, users, isFetching,
      isSaving, workChecklistLinking, wrapPanel,
      customHandlers,
      readOnly,
    } = this.props;
    const { editableWorkInstructionPosition } = this.state;

    if (isFetching) {
      return (
        <Loading />
      );
    }

    return (
      <WorkChecklist
        currentEditableInstruction={editableWorkInstructionPosition}
        customHandlers={customHandlers}
        fields={fields}
        isSaving={isSaving}
        readOnly={readOnly}
        wrapPanel={wrapPanel}
        additionalInstructionUrlError={this.state.additionalInstructionUrlError}

        onWorkInstructionCreate={this.onWorkInstructionCreate}
        onWorkInstructionDelete={this.onWorkInstructionDelete}
        onWorkInstructionToggle={this.onWorkInstructionToggle}
        onWorkInstructionEdit={this.onWorkInstructionEdit}
        onWorkInstructionReorder={this.onWorkInstructionReorder}
        onWorkChecklistSave={this.onWorkChecklistSave}

        users={users}
        workChecklistLinking={workChecklistLinking}
        initialFormValues={this.props.initialFormValues}
        onChange={this.props.onChange}
        isStepComplete={this.props.isStepComplete}
        isPowderWorkflow={this.props.isPowderWorkflow}
        isPOCUKOrderFieldsFeatureEnabled={this.props.isPOCUKOrderFieldsFeatureEnabled}
        isCustomDarkCardStyle={this.props.isCustomDarkCardStyle}
      />
    );
  }
}

WorkChecklistContainer.contextType = GuidelineSuggestionContext;

WorkChecklistContainer.defaultProps = {
  isFetching: false,
  isSaving: false,
  customHandlers: false,
  initialValues: {},
  onChange: () => true,
  wrapPanel: false,
  workChecklistLinking: {},
  workstepResource: null,
  workstep: null,
  readOnly: false,
  isCustomDarkCardStyle: false,
};

WorkChecklistContainer.propTypes = {
  customHandlers: PropTypes.bool,
  isFetching: PropTypes.bool,
  isSaving: PropTypes.bool,
  initialValues: PropTypes.shape({
    work_instructions: PropTypes.shape({}),
  }),
  initialFormValues: PropTypes.shape({
    work_instructions: PropTypes.arrayOf(PropTypes.shape({})),
    initial_related_uri: PropTypes.shape({}),
  }).isRequired,
  fields: PropTypes.shape({
    work_instructions: reduxFormFieldType,
  }).isRequired,
  onFormSubmit: PropTypes.func.isRequired,
  onInitialize: PropTypes.func.isRequired,
  onChange: PropTypes.func,
  users: PropTypes.shape({}).isRequired,
  values: PropTypes.shape({
    uri: PropTypes.string,
    work_instructions: PropTypes.arrayOf(PropTypes.string),
    additional_instruction_url: PropTypes.string,
  }).isRequired,
  workChecklistLinking: workChecklistLinkingResourceType,
  workstep: PropTypes.string,
  workstepResource: PropTypes.shape({
    name: PropTypes.string,
    uri: PropTypes.string,
  }),
  wrapPanel: PropTypes.bool,
  readOnly: PropTypes.bool,
  processStepURI: PropTypes.string.isRequired,
  isStepComplete: PropTypes.bool.isRequired,
  isPowderWorkflow: PropTypes.bool.isRequired,
  isPOCUKOrderFieldsFeatureEnabled: PropTypes.bool.isRequired,
  isCustomDarkCardStyle: PropTypes.bool,
};

function mapDispatchToProps(dispatch) {
  return {
    onInitialize: workstepURI => {
      if (workstepURI) {
        dispatch(Actions.Api.nautilus[API_RESOURCES.WORK_CHECKLIST_LINKING].list({
          related_uri: workstepURI,
        }));
      }
    },
    onFormSubmit: (endpointName, uuid, payload, checklistLinkingRelatedUri) => {
      const checklistWorkstep = `${endpointName}/${uuid}`;
      dispatch(Actions.Api.nautilus[API_RESOURCES.WORK_CHECKLISTS_FOR].put(checklistWorkstep, payload)).then(() => {
        Alert.success(
          <FormattedMessage
            id="toaster.workChecklistsFor.updated"
            defaultMessage="Checklist successfully updated"
          />);
        // Last Updated (user/date) info is set on the backend, and there is no
        // event-stream event for checklists. So LIST request is required right
        // after PUT
        dispatch(Actions.Api.nautilus[API_RESOURCES.WORK_CHECKLIST_LINKING]
          .list({ related_uri: checklistLinkingRelatedUri }));
      });
    },
  };
}

function mapStateToProps(state, props) {
  const { workstep } = props;

  const users = Selectors.getUsersByUri(state);
  const isPowderWorkflow = Selectors.isFeatureEnabled(state, FEATURES.POWDER_WORKFLOW);
  const isPOCUKOrderFieldsFeatureEnabled = Selectors.isFeatureEnabled(state, FEATURES.POC_UK_ORDER_FIELDS);

  let workChecklistLinking = {};
  let initialFormValues = null;
  let workstepResource = null;

  if (workstep) {
    // Workstep is provided on Workstation Type page only.
    const workChecklistLinkings = Selectors.getRelatedWorkChecklistLinking(state, workstep);
    workChecklistLinking = workChecklistLinkings && workChecklistLinkings[0];

    initialFormValues = workChecklistLinking && workChecklistLinking.work_checklist;
    workstepResource = Selectors.getUUIDResource(state, extractUuid(workstep));
  }

  initialFormValues = initialFormValues
    || props.initialValues
    || {};

  return {
    bureau: Selectors.getBureauUri(state),
    isFetching: state.ui.nautilus[API_RESOURCES.WORK_CHECKLIST_LINKING].list.fetching,
    isSaving: state.ui.nautilus[API_RESOURCES.WORK_CHECKLISTS_FOR].put.fetching,
    workChecklistLinking,
    workstepResource,
    users,
    initialFormValues,
    isPowderWorkflow,
    isPOCUKOrderFieldsFeatureEnabled,
  };
}

const areStatePropsEqual = (next, previous) => _isEqual(JSON.stringify(previous), JSON.stringify(next));

export default connect(mapStateToProps, mapDispatchToProps, null, { areStatePropsEqual })(WorkChecklistContainer);
