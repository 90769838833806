import _keyBy from 'lodash/keyBy';
import _map from 'lodash/map';
import { CUSTOM_FIELD_TYPES } from 'rapidfab/constants';
import truncateFieldPositionBasedOnVisibilityOptions from './truncateFieldPositionBasedOnVisibilityOptions';
import getCustomFieldArrayOptions from './getCustomFieldArrayOptions';

const getVisibleCustomFieldReferencesWithOptions = ({
  fieldReferences,
  fieldValues,
  fieldOverrides = {},
  parentFieldReferences = [],
  parentFieldValues = [],
}) => {
  const fieldValuesByUri = _keyBy(fieldValues, 'custom_field');

  const parentFieldReferencesByUri = _keyBy(parentFieldReferences, 'uri');
  const parentFieldValuesByUri = _keyBy(
    parentFieldValues,
    value =>
      parentFieldReferencesByUri[value.custom_field]?.field_id,
  );

  const fieldReferencesWithOverrideData = _map(fieldReferences, field => {
    if (!fieldOverrides || !fieldOverrides[field.field_id]) {
      return field;
    }
    return {
      ...field,
      // Add details from overrides to custom fields config (if any)
      ...fieldOverrides[field.field_id],
    };
  });

  let visibleFieldReferences = truncateFieldPositionBasedOnVisibilityOptions(
    fieldReferencesWithOverrideData,
    fieldValuesByUri,
    parentFieldValuesByUri,
  );

  visibleFieldReferences = _map(visibleFieldReferences, fieldReference => {
    if (fieldReference.type === CUSTOM_FIELD_TYPES.ARRAY) {
      const options = getCustomFieldArrayOptions({
        fieldReference,
        fieldReferences,
        fieldValuesByUri,
      });
      return { ...fieldReference, options };
    }
    return fieldReference;
  });

  return visibleFieldReferences;
};

export default getVisibleCustomFieldReferencesWithOptions;
