import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect, useSelector } from 'react-redux';
import {
  FormLabel,
  OverlayTrigger,
  Tooltip,
  FormControl,
  InputGroup,
} from 'react-bootstrap';
import { FormattedMessage } from 'rapidfab/i18n';
import FormRow from 'rapidfab/components/FormRow';
import {
  FormControlTextCareful,
  FormControlCost, FormControlTextAreaWithCustomHandlers, FormControlCheckboxWithCustomHandlers,
} from 'rapidfab/components/formTools';
import {
  CUSTOM_FIELD_TYPES,
  CUSTOM_FIELD_RIGHTS,
  CUSTOM_FIELD_FORMATS,
  FEATURES,
  CF_BACKGROUND_COLOR_OPTIONS,
} from 'rapidfab/constants';
import { getBureauDefaultCurrency } from 'rapidfab/selectors';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import _isEmpty from 'lodash/isEmpty';
import * as Selectors from 'rapidfab/selectors';
import { customFieldReferenceType } from 'rapidfab/types';
import { isURLRegex } from 'rapidfab/utils/uriUtils';
import { faExternalLink, faQuestionCircle } from '@fortawesome/free-solid-svg-icons';
import { DateInput } from './DateInput';

const ResourceLink = ({ href }) => {
  const attributes = {};

  if (href.startsWith('http')) {
    attributes.target = '_blank';
    attributes.rel = 'noopener noreferrer';
  }

  return (
    <a href={href} {...attributes}>
      Go <FontAwesomeIcon icon={faExternalLink} />
    </a>
  );
};

ResourceLink.propTypes = { href: PropTypes.string.isRequired };

const FormRowWrapper = ({ fieldName, isRequired, children, labelSuffix }) => (
  <FormRow
    defaultMessage={fieldName}
    isRequired={isRequired}
    labelSuffix={labelSuffix}
  >
    {children}
  </FormRow>
);

FormRowWrapper.defaultProps = {
  labelSuffix: null,
};

FormRowWrapper.propTypes = {
  fieldName: PropTypes.string.isRequired,
  isRequired: PropTypes.bool.isRequired,
  children: PropTypes.node.isRequired,
  labelSuffix: PropTypes.node,
};

const CustomFieldComponent = ({
  reference,
  value,
  onChange,
  variant,
  currency,
  readOnly,
  isCurrentUserRestricted,
  bg,
}) => {
  // TEMPORARY, move this out of this file
  const isDanfossUser = useSelector(state => Selectors.isFeatureEnabled(state, FEATURES.ORDER_BUSINESS_SEGMENT));
  // End of temporary

  const components = {
    form: FormRowWrapper,
    row: 'div',
  };
  const ComponentWrapper = components[variant];

  const tooltip = reference.meta && reference.meta.tooltip && (
    <OverlayTrigger
      placement="bottom"
      overlay={(
        <Tooltip id={`${reference.field_name}-tooltip`}>
          {reference.meta.tooltip}
        </Tooltip>
      )}
    >
      <FontAwesomeIcon icon={faQuestionCircle} />
    </OverlayTrigger>
  );

  const placeholder = (reference.meta && reference.meta.placeholder) || '';

  const lightBg = bg === CF_BACKGROUND_COLOR_OPTIONS.LIGHT;
  const canWrite = reference.rights.includes(CUSTOM_FIELD_RIGHTS.WRITE) && !readOnly;

  useEffect(() => {
    if (
      reference.type === CUSTOM_FIELD_TYPES.ARRAY
      && value
    ) {
      const availableValues = reference.options?.map(option => option.value);
      // When list of options was changed (e.g. based on dependent_on meta)
      // and current value is not listed in the options
      // - clear current value
      if (!availableValues?.includes(value)) {
        // Only non-nullable values are suitable for default value
        const newValue = reference.default_value || '';

        onChange('custom_field_values', {
          customFieldReferenceUri: reference.uri,
          value: newValue,
        });
      }
    }
  }, [reference.type, value, reference.options]);

  const handleChange = event => (onChange('custom_field_values', {
    customFieldReferenceUri: reference.uri,
    value: event.target.value,
  }));

  const handleChangeCheckbox = () => {
    const checkboxValue = value === 'false' ? 'true' : 'false';
    return (onChange('custom_field_values', {
      customFieldReferenceUri: reference.uri,
      value: String(checkboxValue),
    }));
  };

  if (reference.is_visible_for_restricted === false && isCurrentUserRestricted) {
    return null;
  }

  // TEMPORARY, need FE+BE implementation to determine bureaus CF is visible for
  if (reference.field_name === 'Service Provider' && !isDanfossUser) {
    return null;
  }
  // End of temporary

  return (
    <ComponentWrapper
      fieldName={reference.field_name}
      isRequired={reference.required}
      labelSuffix={tooltip}
    >
      {
        variant === CustomFieldComponent.variants.row && (
          <FormLabel>
            {reference.field_name}:{reference.required && ' *'}
            {tooltip && ` ${tooltip}`}
          </FormLabel>
        )
      }
      {
        reference.type === CUSTOM_FIELD_TYPES.TEXT && (
          <InputGroup className="w100">
            <div className="d-flex w-full">
              <FormControlTextCareful
                disabled={!canWrite}
                required={reference.required}
                value={value}
                onChange={handleChange}
              />
              {isURLRegex(value) && (
                <InputGroup.Text style={{ background: lightBg ? 'inherit' : 'auto',
                  border: lightBg ? '1px solid rgba(0, 0, 0, 0.125)' : 'auto' }}
                >
                  <ResourceLink href={value} />
                </InputGroup.Text>
              )}
            </div>
          </InputGroup>
        )
      }
      {
        reference.type === CUSTOM_FIELD_TYPES.URL && (
          <>
            <div className={`d-flex custom_field_url_wrapper ${isURLRegex(value) ? 'valid' : 'invalid'}`}>
              <FormControlTextCareful
                disabled={!canWrite}
                required={reference.required}
                value={value}
                onChange={handleChange}
                placeholder={placeholder}
                invalid={!isURLRegex(value)}
              />
              {
                isURLRegex(value) && (
                  <InputGroup.Text size="sm">
                    <ResourceLink
                      href={value}
                    />
                  </InputGroup.Text>
                )
              }
            </div>
            {
              value && !isURLRegex(value) && (
                <div className="custom_field_url_warning text-start text-danger center">
                  <FormattedMessage
                    id="customField.incorrectURLFormat"
                    defaultMessage="Please use http://www.example.com format above"
                  />
                </div>
              )
            }
          </>

        )
      }
      {
        reference.type === CUSTOM_FIELD_TYPES.TEXTAREA && (
          <FormControlTextAreaWithCustomHandlers
            disabled={!canWrite}
            id={reference.uri}
            required={reference.required}
            value={value}
            onChange={handleChange}
            placeholder={placeholder}
          />
        )
      }
      {
        reference.type === CUSTOM_FIELD_TYPES.FLOAT
        && reference.format !== CUSTOM_FIELD_FORMATS.CURRENCY && (
          <FormControlTextCareful
            disabled={!canWrite}
            required={reference.required}
            value={value}
            onChange={handleChange}
            type="number"
            placeholder={placeholder}
          />
        )
      }
      {
        reference.type === CUSTOM_FIELD_TYPES.FLOAT
        && reference.format === CUSTOM_FIELD_FORMATS.CURRENCY && (
          <FormControlCost
            name={reference.field_name}
            disabled={!canWrite}
            required={reference.required}
            value={value}
            onChange={handleChange}
            currency={currency}
            placeholder={placeholder}
          />
        )
      }
      {
        reference.type === CUSTOM_FIELD_TYPES.INTEGER && (
          <FormControlTextCareful
            disabled={!canWrite}
            required={reference.required}
            value={value}
            onChange={handleChange}
            type="number"
            pattern="^\d?$"
            placeholder={placeholder}
          />
        )
      }
      {
        reference.type === CUSTOM_FIELD_TYPES.CHECKBOX && (
          <FormControlCheckboxWithCustomHandlers
            disabled={!canWrite}
            value={value}
            required={reference.required}
            onChange={handleChangeCheckbox}
          />
        )
      }
      {
        reference.type === CUSTOM_FIELD_TYPES.DATE && (
          <DateInput
            disabled={!canWrite}
            required={reference.required}
            value={value}
            onChange={handleChange}
          />
        )
      }
      {
        reference.type === CUSTOM_FIELD_TYPES.ARRAY && (
          <FormControl
            disabled={!canWrite || _isEmpty(reference.options)}
            as="select"
            required={reference.required}
            value={value}
            onChange={handleChange}
            placeholder={placeholder}
          >
            {reference.required ? (
              <FormattedMessage id="field.choose" defaultMessage="Choose…">{text =>
                <option value="" disabled>{text}</option>}
              </FormattedMessage>
            ) : (
              <FormattedMessage id="field.none" defaultMessage="None">{text =>
                <option value="">{text}</option>}
              </FormattedMessage>
            )}

            {reference.options?.map(field => (
              <option key={field.value} value={field.value} disabled={field.disabled}>
                {field.key}
              </option>
            ))}
          </FormControl>
        )
      }
    </ComponentWrapper>
  );
};

CustomFieldComponent.defaultProps = {
  value: '',
  readOnly: false,
};

CustomFieldComponent.variants = {
  form: 'form',
  row: 'row',
};

CustomFieldComponent.propTypes = {
  reference: customFieldReferenceType.isRequired,
  value: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  variant: PropTypes.oneOf(Object.values(CustomFieldComponent.variants)).isRequired,
  currency: PropTypes.string.isRequired,
  readOnly: PropTypes.bool,
  isCurrentUserRestricted: PropTypes.bool.isRequired,
  bg: PropTypes.string.isRequired,
};

const mapStateToProps = state => ({
  isCurrentUserRestricted: Selectors.isCurrentUserRestricted(state),
  currency: getBureauDefaultCurrency(state),
});

export default connect(mapStateToProps)(CustomFieldComponent);
