import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Config from 'rapidfab/config';

import {
  Container,
  Row,
  Col,
  Button,
} from 'react-bootstrap';
import TosLogoContainer from 'rapidfab/containers/TosLogoContainer';
import { FormattedMessage } from 'react-intl';
import { TOS_XEROX_URL } from 'rapidfab/constants';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSpinner, faThumbsUp } from '@fortawesome/free-solid-svg-icons';

const TosLink = ({ isHawkingUser }) => (
  <a href={isHawkingUser ? TOS_XEROX_URL : Config.TOS_URL} target="_blank" rel="noopener noreferrer">
    <FormattedMessage id="tos" defaultMessage="Terms of Service" />
  </a>
);

class Tos extends Component {
  constructor(props) {
    super(props);

    this.state = {
      fetching: false,
    };

    this.handleAgree = this.handleAgree.bind(this);
  }

  handleAgree(event) {
    event.preventDefault();
    if (this.state.fetching) return;

    this.props.onAcceptTerms(this.props.user);

    this.setState({
      fetching: true,
    });
  }

  render() {
    const { fetching } = this.state;

    return (
      <Container>
        <Row>
          <Col xs={12}>
            <div className="jumbotron" style={{ textAlign: 'center' }}>
              <TosLogoContainer />
              <h2 style={{ marginTop: '40px' }}>
                <FormattedMessage id="tos" defaultMessage="Terms of Service" />
              </h2>
              <p>
                <FormattedMessage
                  id="tosDescription"
                  defaultMessage="To use this software you must agree to the {tosLink}."
                  values={{ tosLink: <TosLink isHawkingUser={this.props.isHawkingUser} /> }}
                />
              </p>
              <Button
                size="sm"
                variant="primary"
                onClick={this.handleAgree}
                style={{ marginTop: '20px' }}
                disabled={fetching}
              >
                <FontAwesomeIcon icon={fetching ? faSpinner : faThumbsUp} spin={fetching} />{' '}
                <FormattedMessage id="button.agree" defaultMessage="Agree" />
              </Button>
            </div>
          </Col>
        </Row>
      </Container>
    );
  }
}

Tos.propTypes = {
  onAcceptTerms: PropTypes.func.isRequired,
  user: PropTypes.shape({}).isRequired,
  isHawkingUser: PropTypes.bool.isRequired,
};

TosLink.propTypes = {
  isHawkingUser: PropTypes.bool.isRequired,
};

export default Tos;
