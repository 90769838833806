import Constants from 'rapidfab/constants';

export function storeSubmittedOrders(orders) {
  return {
    type: Constants.STORE_SUBMITTED_ORDERS,
    orders,
  };
}

export function setSubmittedOrders(orders) {
  return dispatch => {
    dispatch(storeSubmittedOrders(orders));
  };
}
