import LocationModalContainer from 'rapidfab/containers/modals/LocationModalContainer';
import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import Actions from 'rapidfab/actions';
import * as Selectors from 'rapidfab/selectors';

import LocationsComponent from 'rapidfab/components/organize/locations';
import { API_RESOURCES } from 'rapidfab/constants';
import { useSearchParams } from 'react-router-dom';

const LocationsContainer = props => {
  const [searchParams] = useSearchParams();
  const uuid = searchParams.get('uuid');
  const usersByUri = useSelector(Selectors.getUsersByUri);
  const locations = useSelector(Selectors.getLocations);
  const subLocations = useSelector(Selectors.getSubLocations);
  const bureauGroup = useSelector(Selectors.getBureauGroup);

  const fetching = useSelector(state =>
    state.ui.nautilus[API_RESOURCES.LOCATION].list.fetching
    || state.ui.nautilus[API_RESOURCES.USERS].list.fetching
     || state.ui.nautilus[API_RESOURCES.SUB_LOCATION].list.fetching,
  );

  const [isCreateLocationModalShown, setIsCreateLocationModalShown] = useState(false);

  const dispatch = useDispatch();
  const onInitialize = bureausGroup => {
    dispatch(Actions.Api.nautilus[API_RESOURCES.USERS].list({ group: bureausGroup }));
    dispatch(Actions.Api.nautilus[API_RESOURCES.LOCATION].clear());
    dispatch(Actions.Api.nautilus[API_RESOURCES.LOCATION].list());
    dispatch(Actions.Api.nautilus[API_RESOURCES.SUB_LOCATION].list());
  };

  useEffect(() => onInitialize(bureauGroup), []);

  return (
    <>
      <LocationsComponent
        {...props}
        usersByUri={usersByUri}
        fetching={fetching}
        locations={locations}
        subLocations={subLocations}
        setIsCreateLocationModalShown={setIsCreateLocationModalShown}
      />
      {(isCreateLocationModalShown || uuid) && (
        <LocationModalContainer
          isVisible={isCreateLocationModalShown}
          hideModal={() => setIsCreateLocationModalShown(false)}
        />
      )}
    </>
  );
};

export default LocationsContainer;
