import _find from 'lodash/find';
import Feature from 'rapidfab/components/Feature';
import FormGroupField from 'rapidfab/components/forms/FormGroupField';
import Documents from 'rapidfab/components/records/Documents';
import { resetRcTooltipInnerStyle, selectInputStyles } from 'rapidfab/constants/styles';
import RCTooltip from 'rc-tooltip';
import React from 'react';
import PropTypes from 'prop-types';
import { Button, Col, Row, Badge, Modal, FormLabel, FormGroup } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { FormattedMessage } from 'react-intl';
import { getShortUUID } from 'rapidfab/utils/uuidUtils';
import { DOCUMENT_RELATED_TABLE_NAMES, FEATURES } from 'rapidfab/constants';
import { Form, Field } from 'react-final-form';
import { finalFormInputTypes } from 'rapidfab/types';
import Loading from 'rapidfab/components/Loading';
import {
  faArchive,
  faClose,
  faFloppyDisk,
  faObjectGroup,
  faPlus,
} from '@fortawesome/free-solid-svg-icons';
import Select from 'react-select';

const ToolingStockTypeModal = ({
  uuid,
  isVisible,
  fetching,
  onArchive,
  onUnarchive,
  toolingStockType,
  onSubmit,
  initialFormValues,
  submitting,
  handleCloseModal,
  unarchiving,
  formattedVendorUsers,
  isVendorUser,
}) => (
  <Modal
    size="lg"
    show={isVisible || uuid || initialFormValues?.uuid}
    onHide={handleCloseModal}
    scrollable
    backdrop="static"
    dialogClassName="custom-darken-modal"
  >
    {fetching ? (
      <Loading className="p-a-md" />
    ) : (
      <Form
        onSubmit={onSubmit}
        initialValues={initialFormValues}
        render={({ handleSubmit, values }) => (
          <form onSubmit={handleSubmit}>
            <Modal.Header>
              <Modal.Title className="w-100 d-flex align-items-center justify-content-between">
                <div className="d-flex align-items-center">
                  <FontAwesomeIcon
                    className="spacer-right"
                    icon={faObjectGroup}
                  />

                  <p className="custom-darken-modal-title-text m-a-0">
                    {initialFormValues?.uuid && initialFormValues?.name ? (
                      initialFormValues.name.length >= 50 ? (
                        <div className="d-flex align-items-center">
                          <RCTooltip
                            placement="bottom"
                            id="toolingStockTypeNameTooltip"
                            destroyTooltipOnHide
                            overlayInnerStyle={resetRcTooltipInnerStyle}
                            mouseLeaveDelay={0.4}
                            overlay={(
                              <p className="mb0 darkTooltip">
                                {initialFormValues.name}
                              </p>
                            )}
                          >
                            <div className="d-flex align-items-center">
                              <p className="mb0 truncate capitalize custom-darken-modal-title-text-title">
                                {initialFormValues.name}
                              </p>
                              ({getShortUUID(initialFormValues.uuid)})
                            </div>
                          </RCTooltip>
                        </div>
                      ) : (
                        <span>
                          {initialFormValues.name}
                          &nbsp;({getShortUUID(initialFormValues.uuid)})
                        </span>
                      )
                    ) : (
                      <FormattedMessage
                        id="record.tooling_stock_type.create"
                        defaultMessage="Create Tool Type"
                      />
                    )}
                    {toolingStockType?.archived && (
                      <Badge bg="default" className="spacer-left badge-sm">
                        <FormattedMessage
                          id="field.archived"
                          defaultMessage="Archived"
                        />
                      </Badge>
                    )}
                  </p>
                </div>
                <FontAwesomeIcon
                  icon={faClose}
                  onClick={handleCloseModal}
                  tabIndex={0}
                  role="button"
                  className="custom-darken-modal-button"
                />
              </Modal.Title>
            </Modal.Header>
            <Modal.Body className="custom-darken-modal--body-scroll">
              <Row className="custom-darken-modal-custom-gutter">
                <Col lg={uuid || initialFormValues?.uuid ? 6 : 12} xs={12}>
                  <Field
                    name="name"
                    type="text"
                    render={props => (
                      <FormGroupField
                        {...props.input}
                        name="name"
                        controlId="uxName"
                        required
                        disabled={isVendorUser}
                        label={(
                          <FormattedMessage
                            id="field.name"
                            defaultMessage="Name"
                          />
                        )}
                      />
                    )}
                  />

                  <Field
                    name="description"
                    type="text"
                    render={props => (
                      <FormGroupField
                        {...props.input}
                        as="textarea"
                        name="description"
                        controlId="uxDescription"
                        disabled={isVendorUser}
                        label={(
                          <FormattedMessage
                            id="field.description"
                            defaultMessage="Description"
                          />
                        )}
                      />
                    )}
                  />

                  {!isVendorUser && (
                    <Feature featureName={FEATURES.VENDOR_SERVICE_PROVIDER}>
                      <FormGroup className="form-group" controlId="uxServiceProvider">
                        <FormLabel>
                          <FormattedMessage
                            id="field.vendor"
                            defaultMessage="Vendor"
                          />
                          :
                        </FormLabel>
                        <Field
                          name="service_provider"
                          render={({ input }) => {
                            const selectedOption = _find(formattedVendorUsers,
                              { value: input.value }) || null;

                            return (
                              <Select
                                options={formattedVendorUsers}
                                onChange={option =>
                                  input.onChange(option?.value || null)}
                                placeholder="Select Vendor"
                                value={selectedOption}
                                styles={selectInputStyles}
                                components={{ LoadingIndicator: () => (<Loading inline className="spacer-right" />) }}
                                isClearable
                                isLoading={fetching}
                              />
                            );
                          }}
                        />
                      </FormGroup>
                    </Feature>
                  )}

                  <hr className="custom-darken-modal-separator" />

                  <Row>
                    <h4 className="printerTypeBufferTitle">
                      Refurbish Details
                    </h4>
                    <Col sm={5}>
                      <Field
                        name="refurbish_after"
                        type="number"
                        render={props => (
                          <FormGroupField
                            {...props.input}
                            name="refurbish_after"
                            required
                            disabled={isVendorUser}
                            controlId="uxRefurbishAfter"
                            min="1"
                            label={(
                              <FormattedMessage
                                id="field.tooling_stock_refurbish_after"
                                defaultMessage="Refurbish After"
                              />
                            )}
                          />
                        )}
                      />
                    </Col>
                  </Row>

                  <Field
                    name="refurbish_required_info"
                    type="text"
                    render={props => (
                      <FormGroupField
                        {...props.input}
                        as="textarea"
                        required
                        disabled={isVendorUser}
                        name="refurbish_required_info"
                        controlId="uxRefurbishedNotes"
                        label={(
                          <FormattedMessage
                            id="field.notes"
                            defaultMessage="Notes"
                          />
                        )}
                      />
                    )}
                  />
                </Col>
                {uuid || initialFormValues?.uuid ? (
                  <Col lg={6} xs={12}>
                    <Documents
                      relatedTable={DOCUMENT_RELATED_TABLE_NAMES.TOOLING_STOCK_TYPE}
                      relatedUUID={uuid}
                      isCustomDarkCardStyle
                      readOnly={isVendorUser}
                    />
                  </Col>
                ) : null}
              </Row>
            </Modal.Body>
            {!isVendorUser && (
              <Modal.Footer>
                {initialFormValues?.id && (
                  <Button
                    disabled={submitting || unarchiving}
                    variant="warning"
                    onClick={() =>
                      (toolingStockType?.archived
                        ? onUnarchive(values)
                        : onArchive(initialFormValues?.uuid)
                      )}
                  >
                    {unarchiving ? (
                      <Loading />
                    ) : (
                      <>
                        <FontAwesomeIcon
                          icon={faArchive}
                          className="spacer-right"
                        />
                        <FormattedMessage
                          id={
                            toolingStockType?.archived
                              ? 'button.unarchive'
                              : 'button.archive'
                          }
                          defaultMessage={
                            toolingStockType?.archived ? 'Unarchive' : 'Archive'
                          }
                        />
                      </>
                    )}
                  </Button>
                )}
                <Button
                  type="submit"
                  disabled={submitting || unarchiving}
                  variant="success"
                >
                  {submitting ? (
                    <Loading />
                  ) : (
                    <>
                      <FontAwesomeIcon
                        icon={initialFormValues?.id ? faFloppyDisk : faPlus}
                        className="spacer-right"
                      />
                      <FormattedMessage
                        id={
                          initialFormValues?.id
                            ? 'button.save'
                            : 'button.createResource'
                        }
                        defaultMessage={initialFormValues?.id ? 'Save' : 'Create'}
                      />
                    </>
                  )}
                </Button>
              </Modal.Footer>
            )}
          </form>
        )}
      />
    )}
  </Modal>
);

ToolingStockTypeModal.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  onArchive: PropTypes.func.isRequired,
  onUnarchive: PropTypes.func.isRequired,
  uuid: PropTypes.string,
  input: finalFormInputTypes.isRequired,
  initialFormValues: PropTypes.shape({
    name: PropTypes.string,
    description: PropTypes.string,
    refurbish_after: PropTypes.number,
    refurbish_required_info: PropTypes.string,
    archived: PropTypes.string,
    uuid: PropTypes.string,
    id: PropTypes.string,
  }).isRequired,
  toolingStockType: PropTypes.shape({
    archived: PropTypes.bool,
    uri: PropTypes.string,
  }).isRequired,
  submitting: PropTypes.bool.isRequired,
  handleCloseModal: PropTypes.func.isRequired,
  unarchiving: PropTypes.bool,
  isVisible: PropTypes.bool.isRequired,
  fetching: PropTypes.bool,
  formattedVendorUsers: PropTypes.arrayOf(PropTypes.shape({})),
  isVendorUser: PropTypes.bool.isRequired,
};

ToolingStockTypeModal.defaultProps = {
  uuid: '',
  fetching: false,
  unarchiving: false,
  formattedVendorUsers: [],
};

export default ToolingStockTypeModal;
