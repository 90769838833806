import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'rapidfab/i18n';
import { Modal, FormGroup, Form, Button, Row, Col } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { JOB_RATING_MAP } from 'rapidfab/mappings';
import { faSpinner } from '@fortawesome/free-solid-svg-icons';

const ServiceProviderJobRatingModal = ({
  show,
  onClose,
  onUpdateRating,
  initialValue,
}) => {
  const [value, setValue] = useState(initialValue);
  const [submitting, setSubmitting] = useState(false);
  const onChange = event => setValue(event.target.value);
  const numberValue = value && Number(value);

  return (
    <Modal show={show} onHide={onClose}>
      <Modal.Header closeButton>
        <Modal.Title>Part Quality Rating</Modal.Title>
        <Modal.Body>
          <form
            onSubmit={event => {
              setSubmitting(true);
              event.preventDefault();
              onUpdateRating(numberValue)
                .then(() => {
                  onClose();
                })
                .finally(() => {
                  setSubmitting(false);
                });
            }}
          >
            <FormGroup
              controlId="job_rating"
              className="d-flex justify-content-center align-items-center mt15 mb15"
            >
              <div style={{ maxWidth: '80px' }} className="mr15">
                <FormattedMessage
                  id="rating.partQualityPrompt"
                  defaultMessage="How would you rate your part's quality?"
                />
              </div>
              {Object.keys(JOB_RATING_MAP).map(index => (
                <Form.Check
                  key={index}
                  name="radioGroup"
                  inline
                  value={index}
                  checked={numberValue === Number(index)}
                  onChange={onChange}
                  type="radio"
                  label={(
                    <FormattedMessage {...JOB_RATING_MAP[index]} />
                  )}
                />
              ))}
            </FormGroup>
            <Row>
              <Col className="pull-right">
                <Button
                  variant="danger"
                  onClick={onClose}
                  disabled={submitting}
                >
                  Cancel
                </Button>
                <Button
                  variant="success"
                  className="ml15"
                  type="submit"
                  disabled={submitting || !numberValue}
                >
                  {submitting ? <FontAwesomeIcon icon={faSpinner} spin /> : 'Submit'}
                </Button>
              </Col>
            </Row>
          </form>
        </Modal.Body>
      </Modal.Header>
    </Modal>
  );
};
ServiceProviderJobRatingModal.defaultProps = {
  initialValue: null,
};
ServiceProviderJobRatingModal.propTypes = {
  show: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  onUpdateRating: PropTypes.func.isRequired,
  initialValue: PropTypes.number,
};

export default ServiceProviderJobRatingModal;
