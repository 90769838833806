import Actions from 'rapidfab/actions';
import LoginWrapper from 'rapidfab/components/LoginWrapper';
import ResetPasswordForm from 'rapidfab/components/ResetPasswordForm';
import { API_RESOURCES, ROUTES } from 'rapidfab/constants';
import { FormattedMessage } from 'rapidfab/i18n';
import Alert from 'rapidfab/utils/alert-new';
import { getRouteURI } from 'rapidfab/utils/uriUtils';
import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';

const ResetPasswordContainer = () => {
  const { token } = useParams();
  const [isLoading, setIsLoading] = useState(false);

  const dispatch = useDispatch();

  const handleResetPassword = payload =>
    dispatch(Actions.Api.nautilus[API_RESOURCES.PASSWORD_RESETS].put(token, payload));

  const onSubmitResetForm = formValues => {
    setIsLoading(true);
    handleResetPassword(formValues)
      .then(() => {
        Alert.success(<FormattedMessage id="toaster.passwordReset" defaultMessage="Your password has been reset." />);
        window.location.hash = getRouteURI(ROUTES.LOGIN);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  return (
    <LoginWrapper
      mainText="Reset Password"
      subText="Please enter your new password below to reset your account password."
    >
      <ResetPasswordForm
        onSubmitResetForm={onSubmitResetForm}
        isResettingPasswordLoading={isLoading}
      />
    </LoginWrapper>
  );
};

export default ResetPasswordContainer;
