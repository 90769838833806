import { useMemo } from 'react';
import _groupBy from 'lodash/groupBy';

// Custom hook to handle filtering and grouping
function useFilteredUsers(usersGroupedByLetter, filter = '', roleFilter = '', roles) {
  const filterRegex = useMemo(() => {
    const safeFilter = filter.replace(/[$()*+.?[\\\]^{|}]/g, '\\$&');
    return new RegExp(safeFilter, 'i');
  }, [filter]);

  const filteredUsers = useMemo(() => {
    const allUsers = Object.values(usersGroupedByLetter).flat();

    return allUsers.filter(user => {
      const matchesName = user.name.search(filterRegex) !== -1;
      const matchesEmail = user.username.toLowerCase().includes(filter.toLowerCase());
      const matchesRole = roleFilter
        ? roles.some(role => role.username === user.username &&
          (role.role === roleFilter || role.location === roleFilter))
        : true;

      return (matchesName || matchesEmail) && matchesRole;
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [usersGroupedByLetter, filterRegex, roleFilter, roles]);

  const usersGroupedPostFilter = useMemo(() =>
    _groupBy(filteredUsers, user => user.name[0]?.toUpperCase() || 'N/A'), [filteredUsers]);

  return usersGroupedPostFilter;
}

export default useFilteredUsers;
