import _filter from 'lodash/filter';
import _sortBy from 'lodash/sortBy';
import { createSelector } from 'reselect';
import * as baseStateSelectors from 'rapidfab/selectors/baseStateSelectors';
import { getStateResources } from 'rapidfab/selectors/helpers/base';
import _map from 'lodash/map';
import _keyBy from 'lodash/keyBy';
import { getPostProcessors } from 'rapidfab/selectors/postProcessor';
import _groupBy from 'lodash/groupBy';
import _countBy from 'lodash/countBy';

export const getPostProcessorTypes = createSelector(
  [baseStateSelectors.getStatePostProcessorTypes, getStateResources],
  (uuids, resources) => _map(uuids, uuid => resources[uuid]),
);

export const getSortedPostProcessorTypesByName = createSelector(
  [getPostProcessorTypes],
  postProcessorTypes =>
    _sortBy(
      _filter(postProcessorTypes, 'name'), // Make sure the Post Processor Type has the "name"
      postProcessorType => postProcessorType.name.toLowerCase(),
    ),
);

export const getPostProcessorTypesByUri = createSelector(
  [getPostProcessorTypes],
  postProcessorTypes => _keyBy(postProcessorTypes, 'uri'),
);

export const getPostProcessorsGroupedByPostProcessorType = createSelector(
  [getPostProcessors],
  postProcessors => _groupBy(postProcessors, 'post_processor_type'),
);

export const getAvailablePostProcessorTypes = createSelector(
  [getPostProcessorTypes, getPostProcessors],
  (postProcessorTypes, postProcessors) => {
    const typeCount = _countBy(postProcessors, 'post_processor_type');
    return postProcessorTypes.filter(({ uri }) => typeCount[uri] > 0);
  },
);
