import languages from 'rapidfab/i18n';

/* eslint-disable no-console */
export const loadState = () => {
  try {
    const serializedState = localStorage.getItem('state');
    if (serializedState === null) {
      return {};
    }

    const state = JSON.parse(serializedState);

    // Restore the messages to the i18n object.
    if (languages[state.i18n.locale]) {
      state.i18n.messages = languages[state.i18n.locale];
    }

    return state;
  } catch (error) {
    console.error(error);
  }

  return {};
};

export const saveState = state => {
  try {
    const {
      i18n,
      units,
      preferences,
      dynamicCurrency,
      locationFilter,
      traceabilityReportFilters,
      submittedOrders,
      admin,
    } = state;
    const { locale } = i18n;

    // Do not store the messages in localStorage.
    // Otherwise, new strings will not work until the language is changed.
    const serializedState = JSON.stringify({
      i18n: {
        locale,
      },
      dynamicCurrency,
      preferences,
      units,
      locationFilter,
      traceabilityReportFilters,
      submittedOrders,
      admin,
    });

    localStorage.setItem('state', serializedState);
  } catch (error) {
    console.error(error);
  }
};
