import BureauBarcodeIcon from 'rapidfab/components/BureauBarcodeIcon';
import { DebugModeBadge } from 'rapidfab/components/DebugMode/DebugModeComponents';
import React, { useEffect, useMemo, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { extractUuid, getShortUUID } from 'rapidfab/utils/uuidUtils';
import Feature from 'rapidfab/components/Feature';
import SaveButton from 'rapidfab/components/SaveButton';
import { MATERIAL_CONTAINER_STATUS_MAP, MATERIAL_REASON_CODE_MAP } from 'rapidfab/mappings';
import { Button, Form, Col, FormLabel, FormControl, FormGroup, Card, Row } from 'react-bootstrap';
import { FormattedMessage, FormattedMessageMappingOption } from 'rapidfab/i18n';
import NewContainersModal from 'rapidfab/components/records/material_management/NewContainersModal';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import _map from 'lodash/map';
import _orderBy from 'lodash/orderBy';
import _clone from 'lodash/clone';
import _isEqual from 'lodash/isEqual';
import _values from 'lodash/values';
import {
  MATERIAL_REASON_CODE_OPTIONS,
  FEATURES,
  MATERIAL_CONTAINER_NUMBER_FIELD_STEP, MATERIAL_CONTAINER_TYPES, ROUTES,
} from 'rapidfab/constants';
import { Link } from 'react-router-dom';
import { getRouteURI } from 'rapidfab/utils/uriUtils';
import Table from 'rapidfab/components/Tables/Table';
import _find from 'lodash/find';
import { faArrowUpRightFromSquare, faPencil, faPlus, faTrash } from '@fortawesome/free-solid-svg-icons';
import FormattedNumberByLocales from 'rapidfab/components/FormattedNumberByLocales';

const getSortedContainersWithIsDeletedInitialized = containers => _orderBy(
  _map(
    containers,
    container => ({
      ..._clone(container),
      isDeleted: false,
    }),
  ),
  'created',
  'desc',
);

const ContainersEditableGrid = ({
  containers: initialContainers,
  panelHeading,
  addContainersAllowed,
  deleteContainersAllowed,
  showAddedToBatchColumn,
  showReasonInput,
  onSaveContainers,
  isSaving,
  isContainerEditable,
  isOnOrderLot,
  uuid,
  route,
  units,
  isDebugModeEnabled,
}) => {
  const formRef = useRef();
  const [isEditMode, setEditMode] = useState(false);
  const [isCreateContainersModalOpen, setIsCreateContainersModalOpen] = useState(false);
  const [containers, setContainers] = useState(
    getSortedContainersWithIsDeletedInitialized(initialContainers),
  );
  const [previousInitialContainers, setPreviousInitialContainers] = useState(initialContainers);
  const [notes, setNotes] = useState('');
  const [reasonCode, setReasonCode] = useState('');

  useEffect(() => {
    if (!_isEqual(previousInitialContainers, initialContainers)) {
      setPreviousInitialContainers(initialContainers);
      setContainers(getSortedContainersWithIsDeletedInitialized(initialContainers));
    }
  }, [initialContainers]);

  const isContainerNewOrEditable = container =>
    !container.uuid || isContainerEditable(container);

  const onEnableEditMode = () => {
    setEditMode(true);
  };

  const onCancelEditMode = () => {
    setContainers(
      getSortedContainersWithIsDeletedInitialized(initialContainers),
    );
    setNotes('');
    setReasonCode('');
    setEditMode(false);
  };

  const onAddNewContainers = newContainers => {
    setContainers(previousContainers => [
      ...newContainers,
      ...previousContainers,
    ]);
  };

  const changeIsContainerDeleted = uuid => {
    if (!uuid) {
      // In case container is not saved into DB yet - just delete it
      setContainers(previous => previous.filter(c => !!c.uuid));
      return;
    }

    const changedContainers = _clone(containers);
    const currentContainer = _find(changedContainers, { uuid });
    currentContainer.isDeleted = !currentContainer.isDeleted;
    setContainers(changedContainers);
  };

  const changeContainerQuantity = (index, value) => {
    setContainers(previousContainers => {
      const changedContainers = _clone(previousContainers);
      const containerAtIndex = changedContainers[index];
      const newQuantityOfContainerAtIndex = value !== '' ? Number(value) : null;
      changedContainers.splice(index, 1, { ...containerAtIndex, quantity: newQuantityOfContainerAtIndex });
      return changedContainers;
    });
  };

  const onNotesChange = event => {
    const { value } = event.target;
    setNotes(value);
  };

  const onReasonCodeChange = event => {
    const { value } = event.target;
    setReasonCode(value);
  };

  const onSubmit = event => {
    event.preventDefault();
    if (!formRef.current.reportValidity()) {
      // No need to proceed in case form is not valid
      return;
    }

    // Ignoring any new containers that have zero quantity,
    // and all non-editable ones
    const filteredContainers = containers.filter(
      container =>
        isContainerNewOrEditable(container)
        && (container.uuid || container.quantity),
    );

    const containersToDelete = filteredContainers
      .filter(container => container.isDeleted)
      .map(({ uri }) => ({ uri }));

    const containersToCreate = filteredContainers
      .filter(container => !container.uuid)
      .map(({ quantity }) => ({ quantity }));

    const containersToUpdate = filteredContainers
      .filter(container => container.uuid && !container.isDeleted)
      .map(({ uri, quantity }) => ({ uri, quantity }));

    onSaveContainers(
      {
        create: containersToCreate,
        update: containersToUpdate,
        delete: containersToDelete,
      },
      reasonCode,
      notes,
    );
  };

  const isEditModeAllowed =
    // Edit Mode is allowed when Add containers option is allowed
    addContainersAllowed
    // Or when there are editable containers available
    || containers.some(container => isContainerEditable(container));

  const columns = useMemo(() => [
    {
      type: 'custom',
      uid: 'field.id',
      accessor: 'uuid',
      defaultMessage: 'ID',
      Cell: ({ value }) => (
        <div className="d-flex">
          {value && getShortUUID(value)}
          <Link
            to={getRouteURI(route, { uuid, container: value }, {}, true)}
            className="pull-right"
          >
            <BureauBarcodeIcon className="spacer-right spacer-left" />
          </Link>
        </div>
      ),
    },
    {
      type: 'translatable',
      uid: 'field.status',
      accessor: 'status',
      defaultMessage: 'Status',
      mapping: MATERIAL_CONTAINER_STATUS_MAP,
    },
    isDebugModeEnabled && (
      {
        type: 'custom',
        uid: 'field.disposable',
        accessor: 'disposable',
        defaultMessage: 'Type',
        Header: () => ([
          <DebugModeBadge />,
          <span>Type</span>,
        ]),
        Cell: ({ row: { original }, value }) => (
          <div>{!value ? (
            <>
              <span>{MATERIAL_CONTAINER_TYPES.PERMANENT_CONTAINER}</span>
              <Link
                to={getRouteURI(
                  ROUTES.PERMANENT_CONTAINER,
                  { uuid: extractUuid(original?.uri) },
                  {}, true)}
              >
                <FontAwesomeIcon className="spacer-left" icon={faArrowUpRightFromSquare} />
              </Link>

            </>
          ) : MATERIAL_CONTAINER_TYPES.CONTAINER}
          </div>
        ),
      }
    ),
    showAddedToBatchColumn && (
      {
        type: 'bool',
        uid: 'materialBatch.in',
        accessor: 'current_batch',
        defaultMessage: 'In Batch',
      }
    ),
    {
      type: 'custom',
      uid: 'field.quantity',
      accessor: 'quantity',
      defaultMessage: 'Quantity',
      Cell: ({ row: { original, index }, value }) => (
        (
          isEditMode && isContainerNewOrEditable(original)
        )
          ? (
            <FormControl
              name={`quantity-${original.id}`}
              type="number"
              value={value}
              min="0"
              onChange={
                event =>
                  changeContainerQuantity(index, event.target.value)
              }
              disabled={isSaving || original.isDeleted}
              step={MATERIAL_CONTAINER_NUMBER_FIELD_STEP}
            />
          )
          : (
            <div className="d-flex flex-direction-row">
              <FormattedNumberByLocales number={value} />
              <span className="spacer-left">{units}</span>
            </div>
          )
      ),
    },
    {
      type: 'time',
      uid: 'created',
      accessor: 'created',
      defaultMessage: 'Created',
    },
    isEditMode && deleteContainersAllowed && (
      {
        type: 'custom',
        // id's not provided
        uid: 'isDeleted',
        accessor: 'isDeleted',
        defaultMessage: 'Deleted',
        Header: () => (
          <FontAwesomeIcon icon={faTrash} />
        ),
        Cell: ({ row: { original }, value }) => (
          <Form.Check
            checked={value}
            onChange={() => changeIsContainerDeleted(original.uuid)}
            disabled={
              !isContainerNewOrEditable(original)
              || isSaving
            }
            type="checkbox"
          />
        ),
      }
    ),
  ], [isEditMode]);

  if (isOnOrderLot) {
    return (
      <Card>
        <Card.Header>Material Lot Containers</Card.Header>
        <Card.Body>
          <FormattedMessage
            id="materialLot.receiveToAddContainers"
            defaultMessage="Receive the lot to add containers"
          />
        </Card.Body>
      </Card>
    );
  }

  return (
    <>
      <Card bg="dark">
        <Card.Header className="pd-exp inverse">
          <>
            {panelHeading}
            <div className="pull-right">
              {(!isEditMode && isEditModeAllowed) && (
                <Button
                  variant="primary"
                  size="xs"
                  onClick={onEnableEditMode}
                >
                  <FontAwesomeIcon icon={faPencil} />{' '}
                  <FormattedMessage id="edit" defaultMessage="Edit" />
                </Button>
              )}
              {isEditMode && (
                <>
                  <Button
                    variant="default"
                    size="xs"
                    className="spacer-right"
                    onClick={onCancelEditMode}
                    disabled={isSaving}
                  >
                    <FormattedMessage id="button.cancel" defaultMessage="Cancel" />
                  </Button>
                  {addContainersAllowed && (
                    <Button
                      className="spacer-right"
                      variant="primary"
                      size="xs"
                      onClick={() => setIsCreateContainersModalOpen(true)}
                      disabled={isSaving}
                    >
                      <FontAwesomeIcon icon={faPlus} />{' '}
                      <FormattedMessage id="container.add" defaultMessage="Add Containers" />
                    </Button>
                  )}
                  <SaveButton
                    size="xs"
                    onClick={onSubmit}
                    isSaving={isSaving}
                  />
                </>
              )}
            </div>
          </>
        </Card.Header>
        <div className="card-body-wrapper">
          <Card.Body>
            <form ref={formRef} id="editable-containers-form" onSubmit={onSubmit}>
              {(showReasonInput || addContainersAllowed) && isEditMode && (
                <Row>
                  <Col xs={12} className="mb15">
                    {showReasonInput && (
                      <FormGroup controlId="notes">
                        <FormLabel>
                          <FormattedMessage
                            id="quantityEditReason"
                            defaultMessage="Quantity Edit Reason"
                          />:
                        </FormLabel>
                        <FormControl
                          value={notes}
                          onChange={onNotesChange}
                          disabled={isSaving}
                          required
                        />
                      </FormGroup>
                    )}
                    <Feature featureName={FEATURES.RICOH_REASON_CODE}>
                      <FormGroup controlId="reason-code">
                        <FormLabel>
                          <FormattedMessage
                            id="reasonCode"
                            defaultMessage="Reason Code"
                          />: *
                        </FormLabel>
                        <FormControl
                          as="select"
                          value={reasonCode}
                          onChange={onReasonCodeChange}
                          required
                        >
                          <FormattedMessage id="field.none" defaultMessage="None">{text =>
                            <option value="">{text}</option>}
                          </FormattedMessage>
                          {_values(MATERIAL_REASON_CODE_OPTIONS).map(lotReasonOption => (
                            <FormattedMessageMappingOption
                              key={lotReasonOption}
                              value={lotReasonOption}
                              mapping={MATERIAL_REASON_CODE_MAP}
                            />
                          ))}
                        </FormControl>
                      </FormGroup>
                    </Feature>
                  </Col>
                </Row>
              )}
              <Row>
                <Col xs={12}>
                  <Table data={containers} columns={columns} isUpdatedColumnShown={false} autoResetPage={false} />
                </Col>
              </Row>
            </form>
          </Card.Body>
        </div>
      </Card>
      {isCreateContainersModalOpen && (
        <NewContainersModal
          onClose={() => setIsCreateContainersModalOpen(false)}
          onAddNewContainers={onAddNewContainers}
          materialUnitOfMeasure={units}
          existingAmountOfContainer={containers.length}
        />
      )}
    </>
  );
};

ContainersEditableGrid.defaultProps = {
  containers: [],
  isSaving: false,
  isOnOrderLot: false,
};

ContainersEditableGrid.propTypes = {
  containers: PropTypes.arrayOf(PropTypes.shape({
    uuid: PropTypes.string,
    status: PropTypes.string,
    quantity: PropTypes.number,
    created: PropTypes.string,
  })),
  panelHeading: PropTypes.node.isRequired,
  deleteContainersAllowed: PropTypes.bool.isRequired,
  addContainersAllowed: PropTypes.bool.isRequired,
  showAddedToBatchColumn: PropTypes.bool.isRequired,
  showReasonInput: PropTypes.bool.isRequired,
  onSaveContainers: PropTypes.func.isRequired,
  isSaving: PropTypes.bool,
  isContainerEditable: PropTypes.func.isRequired,
  isOnOrderLot: PropTypes.bool,
  uuid: PropTypes.string.isRequired,
  route: PropTypes.string.isRequired,
  row: PropTypes.shape({
    original: PropTypes.shape({
      uuid: PropTypes.string.isRequired,
      id: PropTypes.string.isRequired,
      uri: PropTypes.string.isRequired,
      isDeleted: PropTypes.bool.isRequired,
    }),
    index: PropTypes.number.isRequired,
  }).isRequired,
  // eslint-disable-next-line react/no-unused-prop-types
  value: PropTypes.string.isRequired,
  units: PropTypes.string.isRequired,
  isDebugModeEnabled: PropTypes.bool.isRequired,
};

export default ContainersEditableGrid;
