import { faMinusCircle, faPlusCircle, faSave } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import _compact from 'lodash/compact';
import _find from 'lodash/find';
import _isEmpty from 'lodash/isEmpty';
import _kebabCase from 'lodash/kebabCase';
import _map from 'lodash/map';
import _omit from 'lodash/omit';
import _uniqueId from 'lodash/uniqueId';
import PropTypes from 'prop-types';
import Actions from 'rapidfab/actions';
import Loading from 'rapidfab/components/Loading';
import { API_RESOURCES } from 'rapidfab/constants/resources';
import Alert from 'rapidfab/utils/alert';
import { extractUuid } from 'rapidfab/utils/uuidUtils';
import React, { useEffect, useState } from 'react';
import { Card, FormCheck, FormControl, ListGroup, ListGroupItem, Row, Col, Form as BSForm } from 'react-bootstrap';
import { Field, Form } from 'react-final-form';
import { FormattedMessage } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';

const CustomMaterialTestInstructionComponent = ({
  material,
  title,
  parentTest,
  materialTestOperationLinkings,
  materialTestInstructionsByUri,
  materialTestUnits,
  materialTestUnitsByUri,
  isCustomDarkCardStyle,
}) => {
  const isSaving = useSelector(state =>
    state.ui.nautilus[API_RESOURCES.MATERIAL_TEST_INSTRUCTION].post.fetching
    || state.ui.nautilus[API_RESOURCES.MATERIAL_TEST_INSTRUCTION].put.fetching
    || state.ui.nautilus[API_RESOURCES.MATERIAL_TEST_INSTRUCTION].delete.fetching
    || state.ui.nautilus[API_RESOURCES.MATERIAL_TEST_OPERATION_LINKING].list.fetching,
  );
  const [customInstructions, setCustomInstructions] = useState({ 1: '' });
  const [deletedInstructionsURIs, setDeletedInstructionsURIs] = useState([]);
  const { uri: materialUri, bureau: bureauUri } = material ?? {};

  const dispatch = useDispatch();

  const setFocusOnField = fieldNumber => {
    setTimeout(() => {
      const selector = `[data-instruction-number="instruction_field-${fieldNumber}"]`;
      const element = document.querySelector(selector);
      element.focus();
    }, 100);
  };

  const initializeCustomFormValues = () => {
    const instructions = {};

    const operationLinkingForMaterialTestOperation = _find(materialTestOperationLinkings,
      { related_uri: material?.uri, material_test_operation: parentTest?.material_test_operation });

    const existingCustomInstructions =
      _compact(_map(operationLinkingForMaterialTestOperation?.material_test_instructions,
        customInstructionURI => materialTestInstructionsByUri[customInstructionURI]));

    if (operationLinkingForMaterialTestOperation) {
      existingCustomInstructions.forEach(currentInstruction => {
        instructions[currentInstruction?.position] =
          { name: currentInstruction?.name,
            uri: currentInstruction?.uri || '',
            unit: currentInstruction?.unit };
      });
      setCustomInstructions(instructions);
    }
  };

  useEffect(() => {
    if (!_isEmpty(materialTestInstructionsByUri) && !isSaving) {
      initializeCustomFormValues();
    }
  }, [materialTestInstructionsByUri]);

  const handleDeletedCustomInstructions = async () => {
    const promises = [];

    if (deletedInstructionsURIs.length) {
      deletedInstructionsURIs.forEach(uri => {
        if (uri) {
          promises.push(dispatch(Actions.Api.nautilus[API_RESOURCES.MATERIAL_TEST_INSTRUCTION]
            .delete(extractUuid(uri))));
        }
      });
    }
    await Promise.all(promises);
    setDeletedInstructionsURIs([]);
  };

  const customInstructionsSave = async () => {
    if (_isEmpty(parentTest)) {
      return;
    }

    const { material_test_operation, material_work_checklist } = parentTest;

    try {
      const promises = [];
      Object.entries(customInstructions).forEach(async item => {
        const [customInstructionId, customInstruction] = item;
        const { name, unit, uri } = customInstruction;
        if (name) {
          const payload = {
            material_test_operation,
            material_work_checklist,
            description: '',
            name,
            position: +customInstructionId,
            unit,
          };
          if (materialTestInstructionsByUri[uri]) {
            promises.push(dispatch(Actions.Api.nautilus[API_RESOURCES.MATERIAL_TEST_INSTRUCTION]
              .put(extractUuid(uri), payload)));
          } else {
            promises.push(dispatch(Actions.Api.nautilus[API_RESOURCES.MATERIAL_TEST_INSTRUCTION].post(payload)));
          }
        }
        // When new instructions are created and sent with the post request we need to dispatch them into redux state
        // POST request has the uri in the headers so we use those to make a list request
        const responseToAllInstructionRequest = await Promise.all(promises);
        const newInstructionPromises = responseToAllInstructionRequest
          .filter(instruction => instruction.headers.location)
          .map(({ headers }) => dispatch(Actions.Api.nautilus[API_RESOURCES.MATERIAL_TEST_INSTRUCTION]
            .list({ uri: headers.location })));

        await Promise.all(newInstructionPromises);

        // We then need to update out redux state for the linking operations so we list them by related uris
        await dispatch(Actions.Api.nautilus[API_RESOURCES.MATERIAL_TEST_OPERATION_LINKING].list(
          { related_uri: [materialUri, bureauUri].filter(Boolean) },
        ));
      });

      handleDeletedCustomInstructions();

      Alert.success('Custom instructions has been saved');
    } catch (error) {
      Alert.error(error);
    }
  };

  return (
    <Form
      initialValues={customInstructions}
      onSubmit={customInstructionsSave}
      render={({ handleSubmit }) => (
        <Card
          bg={`${isCustomDarkCardStyle ? '' : 'dark'}`}
          className={`mb15 ${isCustomDarkCardStyle ? 'custom-darken-modal--card' : ''}`}
        >
          <Card.Header className={`${isCustomDarkCardStyle ? 'pd-exp custom-darken-modal--card-header' : 'pd-exp inverse'}`}>
            <div className="d-flex align-items-center justify-content-between w-100">
              <span>
                {title}
              </span>
              {!_isEmpty(parentTest) && (
                isSaving ? <Loading /> : (
                  <FontAwesomeIcon
                    type="submit"
                    role="button"
                    icon={faSave}
                    onClick={!_isEmpty(customInstructions) ?
                      handleSubmit :
                      () => Alert.error('Please add instructions for the selected test')}
                    size="md"
                  />
                )
              )}
            </div>
          </Card.Header>
          <Card.Body className={`${isCustomDarkCardStyle ? 'custom-darken-modal--card-body' : ''}`}>
            {
              !_isEmpty(parentTest) ?
                Object.entries(customInstructions).map(([customInstructionId]) => (
                  <Field
                    name={`instruction_field${customInstructions.length}`}
                    render={({ input }) => (
                      <div className="d-flex align-items-center mb-2">
                        <FormControl
                          data-instruction-number={`instruction_field-${customInstructionId}`}
                          name="instructionName"
                          type="text"
                          placeholder={customInstructions[customInstructionId]?.name || 'Instruction Field'}
                          value={customInstructions[customInstructionId]?.name || ''}
                          onChange={event => {
                            input.onChange(event);
                            setCustomInstructions(previous =>
                              ({ ...previous,
                                [customInstructionId]: {
                                  ...customInstructions[customInstructionId],
                                  name: event.target?.value,
                                } }
                              ));
                          }}
                          disabled={isSaving || _isEmpty(parentTest)}
                        />
                        <FormControl
                          name="unit"
                          type="select"
                          as="select"
                          placeholder="Units"
                          className="spacer-left"
                          value={customInstructions[customInstructionId].unit}
                          onChange={event => {
                            input.onChange(event);
                            setCustomInstructions(previous =>
                              ({ ...previous,
                                [customInstructionId]: {
                                  ...customInstructions[customInstructionId],
                                  unit: event.target.value,
                                } }
                              ));
                          }}
                          disabled={isSaving || _isEmpty(parentTest)}
                        >
                          <option key={null} value={null}>
                            Unit
                          </option>
                          {!_isEmpty(materialTestUnits) && _map(materialTestUnits, unit => (
                            materialTestUnitsByUri[unit] ? (
                              <option
                                key={_uniqueId(_kebabCase(materialTestUnitsByUri[unit].description))}
                                value={unit}
                              >
                                {materialTestUnitsByUri[unit].description} ({materialTestUnitsByUri[unit].symbol})
                              </option>
                            ) : null))}
                        </FormControl>
                        <div className="d-flex align-items-center">

                          {!_isEmpty(parentTest) && ([
                            <>
                              <FontAwesomeIcon
                                role="button"
                                size="lg"
                                className="spacer-left"
                                icon={faPlusCircle}
                                onClick={() => {
                                  const customInstructionLength = Object.entries(customInstructions).length;
                                  setCustomInstructions(previous => ({ ...previous, [customInstructionLength + 1]: '' }));
                                  setFocusOnField(customInstructionLength + 1);
                                }}
                              /><FontAwesomeIcon
                                role="button"
                                size="lg"
                                className="spacer-left"
                                icon={faMinusCircle}
                                onClick={() => {
                                  if (Object.keys(customInstructions).length > 1) {
                                    setDeletedInstructionsURIs(previous =>
                                      [...previous, customInstructions[customInstructionId].uri]);
                                    setCustomInstructions(() => {
                                      const updatedList = _omit(customInstructions, customInstructionId);
                                      const newList = {};
                                      // resets the positions back in order when deleting an instruction
                                      Object.values(updatedList).forEach((value, index) => { newList[index] = value; });
                                      return newList;
                                    },
                                    );
                                  } else {
                                    Alert.error(<FormattedMessage
                                      id="toaster.custom.instruction.delete.error"
                                      defaultMessage="You need at least one instruction to use this Material Operation"
                                    />);
                                  }
                                }}
                              />
                            </>,
                          ])}
                        </div>
                      </div>
                    )}
                  />
                )) :
                (
                  <p>
                    Please save this material before creating custom instructions.
                  </p>
                )
            }
          </Card.Body>
        </Card>
      )}
    />
  );
};

CustomMaterialTestInstructionComponent.propTypes = {
  title: PropTypes.string.isRequired,
  parentTest: PropTypes.shape({
    material_test_operation: PropTypes.shape({}).isRequired,
    material_work_checklist: PropTypes.string.isRequired,
  }).isRequired,
  materialTestUnits: PropTypes.arrayOf(PropTypes.string).isRequired,
  materialTestInstructionsByUri: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  materialTestOperationLinkings: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  materialTestUnitsByUri: PropTypes.shape(PropTypes.shape({})).isRequired,
  material: PropTypes.shape({ uri: PropTypes.string.isRequired, bureau: PropTypes.string.isRequired }).isRequired,
  isCustomDarkCardStyle: PropTypes.bool,
};

CustomMaterialTestInstructionComponent.defaultProps = {
  isCustomDarkCardStyle: false,
};

const MaterialTypeTestPanel = ({
  materialTestOperationLinkings,
  materialTestOperations,
  isMaterialTestSelected,
  setIsMaterialTestSelected,
  selectedMaterialTestOperations,
  setSelectedMaterialTestOperations,
  materialTestInstructionsByUri,
  materialTestUnitsByUri,
  material,
  isCustomDarkCardStyle,
}) => {
  const [unitSelectionForm, setUnitSelectionForm] = useState({});
  const dispatch = useDispatch();
  const handleMaterialLevelUnitsUpdate = async uri => {
    const payload = { unit: unitSelectionForm[uri] };
    try {
      await dispatch(
        Actions.Api.nautilus[API_RESOURCES.MATERIAL_TEST_INSTRUCTION].put(extractUuid(uri), payload),
      );
      Alert.success(<FormattedMessage id="materialTest.Instruction.Unit.Update" defaultMessage="Units have been updated successfully" />);
    } catch (error) {
      throw new Error(error);
    }
  };
  const selectedMaterialTestOperationLinkingUris = _map(selectedMaterialTestOperations, 'uri');
  return (
    <Card
      bg={`${isCustomDarkCardStyle ? '' : 'dark'}`}
      className={`m-b ${isCustomDarkCardStyle ? 'custom-darken-modal--card' : ''}`}
    >
      <Card.Header
        className={`${isCustomDarkCardStyle ? 'pd-exp custom-darken-modal--card-header' : 'pd-exp accent'}`}
      >
        <div className="d-flex align-items-center justify-content-between">
          <FormattedMessage id="materialTests" defaultMessage="Material Tests" />
          <div className="d-flex align-items-center">
            <FontAwesomeIcon
              icon={isMaterialTestSelected ? faMinusCircle : faPlusCircle}
              className="pointer"
              size="lg"
              onClick={() =>
                setIsMaterialTestSelected(previous => !previous)}
            />
          </div>
        </div>
      </Card.Header>
      {
        isMaterialTestSelected && (
          <div className={`${isCustomDarkCardStyle ? '' : 'card-body-wrapper-accent'}`}>
            <Card.Body className={`${isCustomDarkCardStyle ? 'custom-darken-modal--card-body' : ''}`}>
              <Row>
                <p>Select one or more material tests that can be applied to the following material type.</p>
              </Row>
              <ListGroup>
                {
                  _map(materialTestOperationLinkings.filter(linking => linking.related_uri.includes('bureau')), operationLinking => {
                    const materialTestOperation =
                    _find(materialTestOperations, { uri: operationLinking.material_test_operation });
                    const materialLevelTestOperationLinking = materialTestOperationLinkings.find(
                      ({ material_test_operation, related_uri }) => (

                        material_test_operation === operationLinking.material_test_operation &&
                          related_uri === material?.uri
                      ),
                    );
                    const isMaterialTestCustomTest = materialTestOperation?.name.trim() === 'Chemical Composition';
                    const materialLevelInstruction =
                      materialTestInstructionsByUri[
                        materialLevelTestOperationLinking?.material_test_instructions[0]];
                    const shouldDisplayUnitSelector = !!(materialLevelInstruction?.unit && !isMaterialTestCustomTest);
                    return (
                      <ListGroupItem>
                        <FormCheck
                          checked={selectedMaterialTestOperationLinkingUris.includes(operationLinking.uri)}
                          onChange={event => {
                            if (event.target.checked) {
                              setSelectedMaterialTestOperations(previous => [...previous, operationLinking]);
                            } else {
                              const filteredMaterialTestOperations = [...selectedMaterialTestOperations]
                                .filter(item => (
                                  item.uri !== operationLinking.uri
                                ));
                              setSelectedMaterialTestOperations(filteredMaterialTestOperations);
                            }
                          }}
                          className="pull-right"
                        />
                        <p>{materialTestOperation?.name}</p>
                        {
                          (!materialLevelTestOperationLinking &&
                        selectedMaterialTestOperationLinkingUris.includes(operationLinking.uri) &&
                        !isMaterialTestCustomTest) &&
                        (
                          <p><em>Please save material type before setting a default unit for material operation</em></p>
                        )

                        }
                        {
                          materialLevelTestOperationLinking &&
                        selectedMaterialTestOperationLinkingUris.includes(operationLinking.uri) &&
                        // this displays when there are both and operation linking and it is selected in the UI
                        (

                          shouldDisplayUnitSelector && (
                            // this is an indicator to determine if the unit select field should be
                            // be displayed or not. This is needed to hide this form for custom
                            // operations like chemical composition
                            <Row>
                              <Col xs={10} lg={11}>
                                <BSForm.Control
                                  size="lg"
                                  as="select"
                                  value={
                                    unitSelectionForm[materialLevelInstruction?.uri] ||
                                      materialLevelInstruction?.unit
                                  }
                                  onChange={event => {
                                    const selectedUnitUri = event.target.value;
                                    setUnitSelectionForm(
                                      previous => (
                                        { ...previous, [materialLevelInstruction.uri]: selectedUnitUri }));
                                  }}
                                >
                                  {materialTestOperation?.unit_options?.map(unit => (
                                    <option
                                      key={materialTestUnitsByUri[unit]?.uri}
                                      value={materialTestUnitsByUri[unit]?.uri}
                                    >

                                      {`${materialTestUnitsByUri[unit]?.description} (${materialTestUnitsByUri[unit]?.symbol})`}
                                    </option>
                                  ))}
                                </BSForm.Control>
                              </Col>
                              <Col className="align-self-center justify-self-end d-flex justify-content-end">
                                <FontAwesomeIcon
                                  size="lg"
                                  icon={faSave}
                                  className="pointer"
                                  onClick={() => handleMaterialLevelUnitsUpdate(materialLevelInstruction.uri)}
                                />
                              </Col>
                            </Row>
                          )
                        )
                        }
                        {isMaterialTestCustomTest &&
                          _map(selectedMaterialTestOperations, 'uri').includes(operationLinking.uri) &&
                          (
                            <CustomMaterialTestInstructionComponent
                              materialTestUnits={materialTestOperation.unit_options}
                              materialTestUnitsByUri={materialTestUnitsByUri}
                              title="Chemicals Used"
                              isCustomDarkCardStyle={isCustomDarkCardStyle}
                              parentTest={materialLevelTestOperationLinking}
                              materialTestOperationLinkings={materialTestOperationLinkings}
                              material={material}
                              materialTestInstructionsByUri={materialTestInstructionsByUri}
                            />
                          )}
                      </ListGroupItem>
                    );
                  })
                }
              </ListGroup>
            </Card.Body>
          </div>
        )
      }
    </Card>
  );
};

export default MaterialTypeTestPanel;

MaterialTypeTestPanel.propTypes = {
  materialTestOperationLinkings: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  materialTestOperations: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  isMaterialTestSelected: PropTypes.bool.isRequired,
  setIsMaterialTestSelected: PropTypes.func.isRequired,
  selectedMaterialTestOperations: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  setSelectedMaterialTestOperations: PropTypes.func.isRequired,
  material: PropTypes.shape({
    uri: PropTypes.string,
    bureau: PropTypes.string,
  }).isRequired,
  materialTestInstructionsByUri: PropTypes.shape({}).isRequired,
  materialTestUnitsByUri: PropTypes.shape({}).isRequired,
  isCustomDarkCardStyle: PropTypes.bool.isRequired,
};
