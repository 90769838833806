import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

const BreakpointRenderer = ({ children, breakpoint }) => {
  const [width, setWidth] = useState(window.innerWidth);

  useEffect(() => {
    const handleResize = () => setWidth(window.innerWidth);
    window.addEventListener('resize', handleResize);

    // Clean up the event listener on component unmount
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  return (
    width > breakpoint && (<>{ children }</>)
  );
};
BreakpointRenderer.propTypes = {
  children: PropTypes.node.isRequired,
  breakpoint: PropTypes.number.isRequired,
};

export default BreakpointRenderer;
