import { DebugModeBadge, DebugModeExpandCollapseButton, DebugModeCopyButton } from 'rapidfab/components/DebugMode/DebugModeComponents';
import React, { useState } from 'react';
import { Card, ListGroup, ListGroupItem } from 'react-bootstrap';
import PropTypes from 'prop-types';
import _isEmpty from 'lodash/isEmpty';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faExternalLink } from '@fortawesome/free-solid-svg-icons';
import { Link } from 'react-router-dom';
import { getRouteURI } from 'rapidfab/utils/uriUtils';
import { ROUTES } from 'rapidfab/constants';
import { extractUuid } from 'rapidfab/utils/uuidUtils';

const DebugModeSupportedPrinters = ({
  style,
  printerData,
  onFetchPrinters,
}) => {
  const [expanded, setExpanded] = useState(false);

  return (
    <div>
      <Card style={style}>
        <Card.Header>
          <DebugModeBadge />
          Supported Printers
          <DebugModeExpandCollapseButton
            className="pull-right"
            expanded={expanded}
            onClick={() => {
              setExpanded(previous => !previous);

              if (_isEmpty(printerData)) {
                onFetchPrinters();
              }
            }}
          />
        </Card.Header>
        {expanded && (
          <Card.Body style={{ maxHeight: 200, overflowY: 'scroll' }}>
            <ListGroup>
              {!_isEmpty(printerData) && printerData.map(printer => (
                <ListGroupItem>
                  <Link to={getRouteURI(ROUTES.PRINTER_EDIT, { uuid: extractUuid(printer.uri) })}>
                    {printer.name}
                    {' '}
                    <FontAwesomeIcon className="spacer-left" icon={faExternalLink} />
                  </Link>
                  <DebugModeCopyButton
                    resource={printer}
                    resourceKey="uri"
                    className="pull-right"
                  />
                </ListGroupItem>
              ))}
            </ListGroup>
          </Card.Body>
        )}
      </Card>
    </div>
  );
};

export default DebugModeSupportedPrinters;

DebugModeSupportedPrinters.propTypes = {
  style: PropTypes.shape({}).isRequired,
  printerData: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  onFetchPrinters: PropTypes.func.isRequired,
};
