import _assign from 'lodash/assign';
import _keyBy from 'lodash/keyBy';
import _map from 'lodash/map';
import _reduce from 'lodash/reduce';
import { getEndpointFromURI } from 'rapidfab/utils/uriUtils';

export function extractUuid(uri) {
  return getEndpointFromURI(uri).uuid;
}

export function hydrateRecord(record) {
  if (!record || !record.uri) return record;
  const { uuid } = getEndpointFromURI(record.uri);
  return {
    ...record,
    uuid,
    id: uuid.slice(-6),
  };
}

export function createReducer(initialState, handlers) {
  return function reducer(state = initialState, action) {
    if (Object.prototype.hasOwnProperty.call(handlers, action.type)) {
      return handlers[action.type](state, action);
    }
    return state;
  };
}

const getTypePrefix = (host, resource) => `${host}_${resource}`.toUpperCase();

const resourceRequestHandler = state => ({
  ...state,
  uxFetching: true,
  uxErrors: [],
});

const resourceFailureHandler = (state, action) => {
  let uxErrors = [];
  if (action.error) {
    uxErrors = [{ code: 'API Error', title: action.error.message }];
  } else {
    uxErrors = action.json.errors;
  }
  return { ...state,
    uxFetching: false,
    uxErrors };
};

const processResources = (state, resources) => {
  const records = _map(resources, hydrateRecord);
  return {

    ...state,
    uxFetching: false,
    uxErrors: [],
    ..._keyBy(records, 'uuid'),
  };
};

const processResource = (state, resource) => processResources(state, [resource]);

function makeGet(host, resource) {
  const typePrefix = getTypePrefix(host, resource);
  return {
    [`${typePrefix}_GET_REQUEST`]: resourceRequestHandler,
    [`${typePrefix}_GET_SUCCESS`](state, action) {
      return processResource(state, action.json);
    },
    [`${typePrefix}_GET_FAILURE`]: resourceFailureHandler,
  };
}

function makeList(host, resource) {
  const typePrefix = getTypePrefix(host, resource);
  return {
    [`${typePrefix}_LIST_REQUEST`]: resourceRequestHandler,
    [`${typePrefix}_LIST_SUCCESS`](state, action) {
      return processResources(state, action.json.resources);
    },
    [`${typePrefix}_LIST_FAILURE`]: resourceFailureHandler,
  };
}

function makePut(host, resource) {
  const typePrefix = getTypePrefix(host, resource);
  return {
    [`${typePrefix}_PUT_REQUEST`]: resourceRequestHandler,
    [`${typePrefix}_PUT_SUCCESS`](state, action) {
      return processResource(state, action.payload);
    },
    [`${typePrefix}_PUT_FAILURE`]: resourceFailureHandler,
  };
}

function makeClone(host, resource) {
  const typePrefix = getTypePrefix(host, resource);
  return {
    [`${typePrefix}_CLONE_REQUEST`]: resourceRequestHandler,
    [`${typePrefix}_CLONE_SUCCESS`](state, action) {
      return processResource(state, action.payload);
    },
    [`${typePrefix}_CLONE_FAILURE`]: resourceFailureHandler,
  };
}

function makePost(host, resource) {
  const typePrefix = getTypePrefix(host, resource);
  return {
    [`${typePrefix}_POST_REQUEST`]: resourceRequestHandler,
    [`${typePrefix}_POST_SUCCESS`](state, action) {
      const record = hydrateRecord(
        Object.assign(action.payload, {
          uri: action.headers.location,
          uploadUri: action.headers['X-Upload-Location'],
        }),
      );
      return { ...state,
        [record.uuid]: record,
        uxFetching: false,
        uxErrors: [] };
    },
    [`${typePrefix}_POST_FAILURE`]: resourceFailureHandler,
  };
}

function makeDelete(host, resource) {
  const typePrefix = getTypePrefix(host, resource);
  return {
    [`${typePrefix}_DELETE_REQUEST`]: resourceRequestHandler,
    [`${typePrefix}_DELETE_SUCCESS`](state, action) {
      const newState = { ...state,
        uxFetching: false,
        uxErrors: [] };
      delete newState[action.uuid];
      return newState;
    },
    [`${typePrefix}_DELETE_FAILURE`]: resourceFailureHandler,
  };
}

export function makeApiReducers(resources) {
  const initialState = {
    uxFetching: false,
    uxErrors: [],
  };
  return _reduce(
    resources,
    (result, resourceNames, host) => {
      const newResult = result;
      resourceNames.forEach(resource => {
        newResult[resource] = createReducer(
          initialState,
          _assign(
            makeClone(host, resource),
            makeGet(host, resource),
            makeList(host, resource),
            makePost(host, resource),
            makePut(host, resource),
            makeDelete(host, resource),
          ),
        );
      });
      return newResult;
    },
    {},
  );
}
