import React, { Component } from 'react';
import { Gauge } from 'gaugeJS';
import _merge from 'lodash/merge';
import PropTypes from 'prop-types';

export default class GaugeChart extends Component {
  constructor(props) {
    super(props);

    this.canvasRef = React.createRef();
    this.gaugeValueRef = React.createRef();
  }

  componentDidMount() {
    const options = _merge({
      angle: 0,
      lineWidth: 0.7,
      pointer: {
        length: 0.5,
        strokeWidth: 0.035,
        color: '#000000',
      },

      colorStart: '#1ca8dd', // Colors
      colorStop: '#1ca8dd', // just experiment with them

      staticLabels: {
        font: '10px sans-serif', // Specifies font
        labels: [0, 100], // Print labels at these values
        color: '#fff', // Optional: Label text color
        fractionDigits: 0, // Optional: Numerical precision. 0=round off.
      },

      highDpiSupport: true,
    },
    this.props.options,
    );

    const target = this.canvasRef.current;
    const gauge = new Gauge(target);
    gauge.setOptions(options);
    gauge.minValue = this.props.minValue || 0;
    gauge.maxValue = this.props.maxValue || 100;
    gauge.setTextField(this.gaugeValueRef.current);
    gauge.set(this.props.value || 0);

    /* eslint-disable react/no-did-mount-set-state */
    this.setState({ gauge });
    /* eslint-enable react/no-did-mount-set-state */
  }

  componentDidUpdate() {
    const { gauge } = this.state;
    gauge.set(this.props.value);
    gauge.update();
  }

  render() {
    return (
      <div>
        {this.props.gaugeTitle && <h4 className="text-center">{this.props.gaugeTitle}, <span ref={this.gaugeValueRef} />%</h4>}

        <canvas ref={this.canvasRef} className="gauge" id={this.props.id} />
      </div>
    );
  }
}

GaugeChart.defaultProps = {
  options: {},
  gaugeTitle: null,
};

GaugeChart.propTypes = {
  options: PropTypes.shape({}),
  maxValue: PropTypes.number.isRequired,
  minValue: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
  id: PropTypes.string.isRequired,
  gaugeTitle: PropTypes.string,
};
