import React from 'react';
import PropTypes from 'prop-types';
import { RotatingLines } from 'react-loader-spinner';

const sizeMapping = {
  xs: 12,
  sm: 18,
  lg: 36,
  '2x': 36,
  '3x': 48,
  '4x': 60,
  '5x': 72,
};

const Loading = ({ size, className, inline, style, iconStyle, color = '#8B8B8E' }) => {
  const styles = {
    ...style,
  };
  if (inline) {
    styles.display = 'inline-block';
  }

  const iconSize = sizeMapping[size] || sizeMapping.sm;

  return (
    <div style={styles} className={`text-center ${className}`}>
      <RotatingLines
        strokeColor={color}
        strokeWidth="5"
        animationDuration="0.75"
        width={iconSize}
        visible
        {...iconStyle}
      />
    </div>
  );
};

Loading.defaultProps = {
  size: null,
  className: null,
  inline: false,
  style: {},
  iconStyle: {},
  color: '#8B8B8E',
};

Loading.propTypes = {
  size: PropTypes.string,
  className: PropTypes.string,
  inline: PropTypes.bool,
  style: PropTypes.shape({}),
  iconStyle: PropTypes.shape({}),
  color: PropTypes.string,
};

export default Loading;
