export const defaultHeaderFormats = {
  year: {
    long: 'YYYY',
    mediumLong: 'YYYY',
    medium: 'YYYY',
    short: 'YY',
  },
  month: {
    long: 'MMMM YYYY',
    mediumLong: 'MMMM',
    medium: 'MMMM',
    short: 'MM/YY',
  },
  week: {
    long: 'w',
    mediumLong: 'w',
    medium: 'w',
    short: 'w',
  },
  day: {
    long: 'dddd',
    mediumLong: 'dddd',
    medium: 'dd D',
    short: 'D',
  },
  hour: {
    long: 'HH:00',
    mediumLong: 'HH:00',
    medium: 'HH:00',
    short: 'HH',
  },
  minute: {
    long: 'HH:mm',
    mediumLong: 'HH:mm',
    medium: 'HH:mm',
    short: 'mm',
  },
  second: {
    long: 's',
    mediumLong: 's',
    medium: 's',
    short: 's',
  },
};

export default function formatGanttDateHeaderLabel(
  [timeStart],
  unit,
  labelWidth,
  formatOptions = defaultHeaderFormats,
) {
  let format;
  if (labelWidth >= 150) {
    format = formatOptions[unit].long;
  } else if (labelWidth >= 100) {
    format = formatOptions[unit].mediumLong;
  } else if (labelWidth >= 50) {
    format = formatOptions[unit].medium;
  } else {
    format = formatOptions[unit].short;
  }
  return timeStart.format(format);
}
