import { createSelector } from 'reselect';
import { getPredicate } from 'rapidfab/selectors/helpers/base';
import _filter from 'lodash/filter';
import { getLineItems } from 'rapidfab/selectors/lineItem';
import { getDesignFiles } from 'rapidfab/selectors/designFile';

export const getLineItemsForProduct = createSelector(
  [getPredicate, getLineItems],
  (product, lineItems) => {
    if (!product) {
      return [];
    }
    return _filter(lineItems, { product: product.uri });
  },
);

export const getDesignFilesForProduct = createSelector(
  [getPredicate, getDesignFiles],
  (product, designFiles) => {
    if (!product) {
      return [];
    }
    return _filter(designFiles, { product: product.uri });
  },
);
