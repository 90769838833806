import React from 'react';
import { Route, Routes } from 'react-router-dom';
import PropTypes from 'prop-types';
import { ROUTER_PERMISSION } from '../constantsRouter';
import { FEATURES } from '../constants';
import PrivateRoute from './PrivateRoute';
import QueuesContainer from '../containers/track/QueuesContainer';
import PrepTaskRecordsContainer from '../containers/plan/PrepTaskRecordsContainer';
import NotFound from '../components/404';
import WIPMatrixContainer from '../containers/track/WIPMatrixContainer';

// eslint-disable-next-line react/prop-types
const TrackRoutes = ({ session }) => (
  <Routes>
    <Route
      path="/queues"
      element={(
        <PrivateRoute
          session={session}
          component={QueuesContainer}
          permission={ROUTER_PERMISSION.BUREAU_ONLY}
          isRestrictedRoute
          features={{
            [FEATURES.HAWKING_DEPLOYMENT]: false,
          }}
        />
      )}
    />
    <Route
      path="/tasks"
      element={(
        <PrivateRoute
          session={session}
          component={PrepTaskRecordsContainer}
          permission={ROUTER_PERMISSION.BUREAU_ONLY}
          features={[FEATURES.PREP_WORKFLOW]}
        />
      )}
    />
    <Route
      path="/wip-matrix"
      element={(
        <PrivateRoute
          session={session}
          component={WIPMatrixContainer}
          permission={ROUTER_PERMISSION.BUREAU_ONLY}
          features={[FEATURES.WIP_MATRIX]}
        />
      )}
    />
    <Route
      path="*"
      element={<NotFound />}
    />
  </Routes>
);

TrackRoutes.propTypes = {
  session: PropTypes.shape({}).isRequired,
};

export default TrackRoutes;
