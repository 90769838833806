import React from 'react';
import PropTypes from 'prop-types';
import { STATUS_COLOR_CODE_TYPES, COLORS } from 'rapidfab/constants';
import { STATUS_COLOR_CODE_TYPE_TO_COLOR_MAP } from 'rapidfab/mappings';

const DEFAULT_COLOR_MAP_FOR_TYPE = {
  [STATUS_COLOR_CODE_TYPES.BOEING_FIELD_STATUS]: COLORS.RED,
  [STATUS_COLOR_CODE_TYPES.BOEING_FINANCE_REVIEW_STATUS]: COLORS.RED,
  [STATUS_COLOR_CODE_TYPES.YES_NO]: COLORS.RED,
};

const StatusColorCode = ({ status, type, sizeClassName }) => {
  const statusColor = STATUS_COLOR_CODE_TYPE_TO_COLOR_MAP[type]
    // Use color mapping by color code type
    ? STATUS_COLOR_CODE_TYPE_TO_COLOR_MAP[type][status]
    // Or default mapping otherwise
    : DEFAULT_COLOR_MAP_FOR_TYPE[type];

  // StatusColor might be empty if no mapping for status nor default mapping is found
  return (
    <span
      style={{ backgroundColor: statusColor }}
      className={`${sizeClassName}-status-dot`}
    />
  );
};

StatusColorCode.defaultProps = { status: '', type: STATUS_COLOR_CODE_TYPES.ORDER_STATUS, sizeClassName: 'small' };
StatusColorCode.propTypes = {
  status: PropTypes.string,
  type: PropTypes.oneOf(Object.values(STATUS_COLOR_CODE_TYPES)),
  sizeClassName: PropTypes.string,
};

export default StatusColorCode;
