import Constants from 'rapidfab/constants';

export function storeUserSettings(userSettings) {
  return {
    type: Constants.STORE_PREFERENCES_USER_SETTINGS,
    userSettings,
  };
}

export function setUserSettings(userSettings) {
  return dispatch => {
    dispatch(storeUserSettings(userSettings));
  };
}
