import Alert from 'react-s-alert';

export default {
  error: (message, config = {}) => {
    Alert.error(message, {
      timeout: 10000,
      ...config,
    });
  },
  success: (message, config = {}) => {
    Alert.success(message, {
      timeout: 4000,
      ...config,
    });
  },
  warning: (message, config = {}) => {
    Alert.warning(message, {
      timeout: 7000,
      ...config,
    });
  },
};
