import React from 'react';
import PropTypes from 'prop-types';
import Dropzone from 'react-dropzone';
import Alert from 'rapidfab/utils/alert';
import { NATIVE_CAD_CONVERSION_EXTENSIONS } from 'rapidfab/constants';
import getValidExtensionsToUploadModel from 'rapidfab/utils/getValidExtensionsToUploadModel';
import isEmpty from 'lodash/isEmpty';

const ModelInputDropTarget = ({
  onSelectFiles,
  children,
  isZverseConversionSupported,
  isNativeCadConversionSupported,
  isExpertConversionFeatureEnabled,
}) => {
  const getFileExtension = file => {
    try {
      return file.name.toLowerCase().split('.').pop();
    } catch {
      return null;
    }
  };

  const validExtensions = getValidExtensionsToUploadModel(
    isNativeCadConversionSupported, isZverseConversionSupported, isExpertConversionFeatureEnabled,
  );

  const onDropValidate = file => {
    const fileExtension = getFileExtension(file);

    if (fileExtension && !validExtensions.includes(fileExtension)) {
      let message = `File must be one of extension: ${validExtensions.join(', ')}`;
      if (!isNativeCadConversionSupported && NATIVE_CAD_CONVERSION_EXTENSIONS.includes(fileExtension)) {
        // When a Native CAD file is selected, but it is not allowed - ask to contact Authentise to enable it
        message = `We don't support ${fileExtension} format. Contact Authentise Support to include more file formats.`;
      }
      // Dropzone expects object to be returned in case of error
      // (the result is used in onDropRejected)
      return { customRejectionMessage: message };
    }
    // Dropzone expects null when everything is fine
    return null;
  };

  const onDropAccepted = files =>
    onSelectFiles(files);

  /**
   * See Dropzone docs for more details
   * @param filesErrors {Object[]}
   * @param filesErrors[].errors {Object[]}
   * @param filesErrors[].errors[].message {string}
   * @param filesErrors[].file {Object}
   */
  const onDropRejected = filesErrors => {
    // dropzone sends out its own file errors so we...
    // 1) loop through all rejected files
    // 2) capture are custom message from validator and alert that message instead of the default one
    // 3) if for some reason it doesnt exist well show the auto generated one from dropzone.
    filesErrors.forEach(({ errors }) => {
      const [dropZoneErrorMessage, customMessage] = errors;
      Alert.error(
        !isEmpty(customMessage.customRejectionMessage) ?
          customMessage.customRejectionMessage :
          dropZoneErrorMessage.message,
      );
    });
  };

  return (
    <Dropzone
      onDropAccepted={onDropAccepted}
      validator={onDropValidate}
      onDropRejected={onDropRejected}
      accept={validExtensions.map(extension => `.${extension}`)}
      multiple
    >
      {({ getRootProps, getInputProps }) => (
        <div {...getRootProps({ role: 'button' })}>
          <input {...getInputProps()} />
          {children}
        </div>
      )}
    </Dropzone>
  );
};

ModelInputDropTarget.propTypes = {
  onSelectFiles: PropTypes.func.isRequired,
  children: PropTypes.node.isRequired,
  isZverseConversionSupported: PropTypes.bool,
  isNativeCadConversionSupported: PropTypes.bool,
  isExpertConversionFeatureEnabled: PropTypes.bool,
};

ModelInputDropTarget.defaultProps = {
  isZverseConversionSupported: false,
  isNativeCadConversionSupported: false,
  isExpertConversionFeatureEnabled: false,
};

export default ModelInputDropTarget;
