import React from 'react';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { FormattedMessage } from 'rapidfab/i18n';
import PropTypes from 'prop-types';
import { faExclamationCircle } from '@fortawesome/free-solid-svg-icons';

const UnitsTooltip = ({ id, message }) => (
  <OverlayTrigger
    placement="top"
    overlay={(
      <Tooltip>
        <FormattedMessage
          id={id}
          defaultMessage={message}
        />
      </Tooltip>
    )}
  >
    <FontAwesomeIcon className="spacer-left spacer-right" icon={faExclamationCircle} />
  </OverlayTrigger>
);

export default UnitsTooltip;

UnitsTooltip.propTypes = {
  id: PropTypes.string.isRequired,
  message: PropTypes.string.isRequired,
};
