import { faArrowRightLong } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import PropTypes from 'prop-types';
import FormControlField from 'rapidfab/components/forms/FormControlField';
import Loading from 'rapidfab/components/Loading';
import React from 'react';
import { Button } from 'react-bootstrap';
import { Form } from 'react-final-form';
import { FormattedMessage } from 'rapidfab/i18n';
import { ROUTES } from 'rapidfab/constants';
import { Link } from 'react-router-dom';

const ResetPasswordForm = ({
  onSubmitResetForm,
  isResettingPasswordLoading,
}) => {
  const fields = {
    password: 'password',
    confirmPassword: 'confirm_password',
  };

  return (
    <>
      <Form onSubmit={onSubmitResetForm}>
        {({ handleSubmit }) => (
          <form onSubmit={handleSubmit}>

            <FormControlField
              name={fields.password}
              required
              type="password"
              className="mb25"
              placeholder="Password: *"
            />

            <FormControlField
              name={fields.confirmPassword}
              required
              type="password"
              className="mb25"
              placeholder="Confirm Password: *"
            />

            <div className="btn-group-vertical">
              <Button
                block
                size="sm"
                type="submit"
                variant="outline-warning"
                className="authentise-login-submit-button"
              >
                {isResettingPasswordLoading
                  ? <Loading inline />
                  : (
                    <>
                      <FontAwesomeIcon icon={faArrowRightLong} className="spacer-right" />
                      <FormattedMessage id="button.reset" defaultMessage="Reset" />
                    </>
                  )}
              </Button>
            </div>
          </form>
        )}
      </Form>
      <div
        className="mt15 text-center"
      >
        <Link to={ROUTES.LOGIN}>
          <FormattedMessage id="field.backToSignIn" defaultMessage="Back to Sign In" />
        </Link>
      </div>
    </>
  );
};

ResetPasswordForm.propTypes = {
  onSubmitResetForm: PropTypes.func.isRequired,
  isResettingPasswordLoading: PropTypes.bool.isRequired,
};

export default ResetPasswordForm;
