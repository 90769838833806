import { createSelector } from 'reselect';

import { getStateResources } from 'rapidfab/selectors/helpers/base';
import * as baseStateSelectors from 'rapidfab/selectors/baseStateSelectors';

// eslint-disable-next-line import/prefer-default-export
export const getRunTransformations = createSelector(
  [baseStateSelectors.getStateRunTransformations, getStateResources],
  (uuids, resources) => uuids.map(uuid => resources[uuid]),
);
