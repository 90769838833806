import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import FormattedLocalizedCost from 'rapidfab/components/FormattedLocalizedCost';
import { getLineItemsByUri } from 'rapidfab/selectors';
import { Table } from 'react-bootstrap';
import { useSelector } from 'react-redux';

const ProductionChangeCostsTable = ({ selectedPrints = [], selectedLineItems = [] }) => {
  const lineItemsByUri = useSelector(getLineItemsByUri);
  const noneSelected = !selectedPrints.length && !selectedLineItems.length;

  const costToPrint = useMemo(() => {
    const lineItems = selectedPrints.length ?
      selectedPrints.map(print => lineItemsByUri[print.line_item]) : selectedLineItems;
    // eslint-disable-next-line unicorn/prevent-abbreviations, unicorn/no-array-reduce
    return lineItems.reduce((acc, cur) => {
      const estimate = cur.estimates_additive;
      const total = estimate.total_material_cost + estimate.printing_cost;
      return acc + (total / cur.piece_count);
    }, 0);
  }, [selectedPrints, selectedLineItems, lineItemsByUri]);

  const costToProcess = useMemo(() => {
    const lineItems = selectedPrints.length ?
      selectedPrints.map(print => lineItemsByUri[print.line_item]) : selectedLineItems;
    // eslint-disable-next-line unicorn/prevent-abbreviations, unicorn/no-array-reduce
    return lineItems.reduce((acc, cur) => {
      const estimate = cur.estimates_additive;
      return acc + (estimate.post_processing_cost / cur.piece_count);
    }, 0);
  }, [selectedPrints, selectedLineItems, lineItemsByUri]);

  return (
    <Table bordered className="mt-1 mb-0">
      <thead>
        <tr>
          <th>
            <strong>Cost to Print</strong>
          </th>
          <th>
            <strong>Cost to Process</strong>
          </th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td style={{ textAlign: 'center' }}>
            {noneSelected ? '-' : <FormattedLocalizedCost value={costToPrint} />}
          </td>
          <td style={{ textAlign: 'center' }}>
            {noneSelected ? '-' : <FormattedLocalizedCost value={costToProcess} />}
          </td>
        </tr>
      </tbody>
    </Table>
  );
};

ProductionChangeCostsTable.propTypes = {
  selectedLineItems: PropTypes.shape({}).isRequired,
  selectedPrints: PropTypes.shape({}).isRequired,
};

export default ProductionChangeCostsTable;
