import Constants from 'rapidfab/constants';

function reducer(state = {}, action) {
  switch (action.type) {
    case Constants.STORE_LOCATION:
      return { ...state, location: action.location };
    case Constants.STORE_SUB_LOCATION:
      return { ...state, subLocation: action.subLocation };
    default:
      return state;
  }
}

export default reducer;
