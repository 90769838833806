import React, { useState, useReducer, useEffect } from 'react';
import Guideline from 'rapidfab/components/admin/Guideline';
import { useSelector, useDispatch } from 'react-redux';
import * as Selectors from 'rapidfab/selectors';
import {
  API_RESOURCES,
  GUIDELINES_EXPRESSION_EMPTY,
  GUIDELINE_API_RESOURCES,
  ROUTES,
  GUIDELINES_ENGINE_ACTION_TYPES,
  PAGINATION_IGNORE_DEFAULT_LIMIT,
} from 'rapidfab/constants';
import Actions from 'rapidfab/actions';
import PropTypes from 'prop-types';
import _keyBy from 'lodash/keyBy';
import GuidelineContext from 'rapidfab/context/GuidelineContext';
import Alert from 'rapidfab/utils/alert';
import { getRouteURI, getEndpointFromURI, isURI } from 'rapidfab/utils/uriUtils';
import _clone from 'lodash/clone';
import _isEmpty from 'lodash/isEmpty';
import { extractUuid } from 'rapidfab/utils/uuidUtils';
import Loading from 'rapidfab/components/Loading';
import { FormattedMessage } from 'react-intl';

const GuidelineContainer = () => {
  const dispatch = useDispatch();

  const uuid = useSelector(Selectors.getRouteUUID);
  const bureauUri = useSelector(Selectors.getBureauUri);
  const printerTypes = useSelector(Selectors.getPrinterTypes);
  const printersTypesByUri = useSelector(Selectors.getPrinterTypesByUri);
  const postProcessorTypes = useSelector(Selectors.getPostProcessorTypesByUri);
  const postProcessorTypesByUri = useSelector(Selectors.getPostProcessorTypesByUri);
  const shippingTypes = useSelector(Selectors.getShippings);
  const shippingTypesByUri = useSelector(Selectors.getShippingsByUri);
  const namedExpressions = useSelector(Selectors.getNamedExpressions);
  const processSteps = useSelector(Selectors.getProcessSteps);
  const processStepsByUri = useSelector(Selectors.getProcessStepsByUri);
  const processStepsByProcessTypeUri = useSelector(Selectors.getProcessStepsByProcessTypeUri);

  const [guidelinesByUuid, setGuidelinesByUuid] = useState({});
  const dataForGuideline = guidelinesByUuid[uuid];

  const [isInitializingGuidelineData, setIsInitializingGuidelineData] = useState(false);

  const saving = useSelector(state => state.ui.nautilus[API_RESOURCES.FOR_WORK_INSTRUCTION].post.fetching ||
    state.ui.nautilus[API_RESOURCES.FOR_PROCESS_STEP].post.fetching ||
    state.ui.nautilus[API_RESOURCES.PROCESS_STEP].post.fetching ||
    state.ui.nautilus[API_RESOURCES.WORK_INSTRUCTION].post.fetching);

  const fetchingExistingGuidelineData = useSelector(
    state => state.ui.nautilus[GUIDELINE_API_RESOURCES.EXPRESSION].get.fetching ||
      state.ui.nautilus[GUIDELINE_API_RESOURCES.NAMED_EXPRESSION].get.fetching ||
      state.ui.nautilus[API_RESOURCES.PROCESS_STEP].get.fetching ||
      state.ui.nautilus[API_RESOURCES.WORK_INSTRUCTION].get.fetching ||
      _isEmpty(processStepsByUri) ||
      (uuid && !guidelinesByUuid[uuid]) ||
      isInitializingGuidelineData,
  );

  const onInitializeWorkflowStepResourceItems = () => {
    dispatch(Actions.Api.nautilus[API_RESOURCES.PROCESS_STEP].list({}, {}, {}, {}, false));
    dispatch(Actions.Api.nautilus[API_RESOURCES.WORK_INSTRUCTION].list({}, {}, {}, {}, false));
  };

  useEffect(() => {
    onInitializeWorkflowStepResourceItems();
  }, []);

  const onInitializeNamedExpressions = () => {
    dispatch(Actions.Api.nautilus[GUIDELINE_API_RESOURCES.NAMED_EXPRESSION].list());
  };

  useEffect(() => {
    onInitializeNamedExpressions();
  }, []);

  const emptyGuidelineExpression = { ...GUIDELINES_EXPRESSION_EMPTY, uuid: Math.random().toString(36).slice(2) };

  const guidelineExpressionsInitialState = {
    expressions: [emptyGuidelineExpression],
    join: 'AND',
  };

  const guidelineExpressionsReducer = (state, action) => {
    let updatedExpressions = [];

    switch (action.type) {
      case 'GUIDELINE_EXPRESSIONS_SET':
        return { ...state, expressions: action.payload };
      case 'GUIDELINE_EXPRESSIONS_ADD_NEW':
        updatedExpressions = [...state.expressions, emptyGuidelineExpression];
        return { ...state, expressions: updatedExpressions };
      case 'GUIDELINE_EXPRESSIONS_DELETE_AT':
        updatedExpressions = state.expressions.filter(expression => expression.uuid !== action.uuid);
        return { ...state, expressions: updatedExpressions };
      case 'GUIDELINE_EXPRESSIONS_FIELD_TYPE_CHANGE':
        updatedExpressions = state.expressions.map(expression => {
          if (action.uuid === expression.uuid) {
            return { ...expression, field_type: action.payload, comparator: null, value: null };
          }
          return expression;
        });
        return { ...state, expressions: updatedExpressions };
      case 'GUIDELINE_EXPRESSIONS_FIELD_CHANGE':
        updatedExpressions = state.expressions.map(expression => {
          if (action.uuid === expression.uuid) {
            return { ...expression, field: action.payload };
          }
          return expression;
        });
        return { ...state, expressions: updatedExpressions };
      case 'GUIDELINE_EXPRESSIONS_COMPARATOR_CHANGE':
        updatedExpressions = state.expressions.map(expression => {
          if (action.uuid === expression.uuid) {
            return { ...expression, comparator: action.payload, value: null };
          }
          return expression;
        });
        return { ...state, expressions: updatedExpressions };
      case 'GUIDELINE_EXPRESSIONS_VALUE_CHANGE':
        updatedExpressions = state.expressions.map(expression => {
          if (action.uuid === expression.uuid) {
            return { ...expression, value: action.payload };
          }
          return expression;
        });
        return { ...state, expressions: updatedExpressions };
      case 'GUIDELINE_EXPRESSIONS_JOIN_CHANGE':
        return { ...state, join: action.payload };
      // 👇🏼 'add work instruction' 👇🏼 'add workflow step'?
      case 'GUIDELINE_EXPRESSIONS_ACTION_TYPE_CHANGE':
        updatedExpressions = state.expressions
          .map(expression => ({ ...expression, action: { ...expression.action, type: action.payload } }));
        return { ...state, action: { ...state.action, type: action.payload }, expressions: updatedExpressions };
      case 'GUIDELINE_EXPRESSIONS_ACTION_LOCATION_CHANGE':
        updatedExpressions = state.expressions
          .map(expression => ({ ...expression,
            action: { ...expression.action, target_process_step: action.payload } }));
        return { ...state,
          action: {
            ...state.action,
            target_process_step: action.payload,
          },
          expressions: updatedExpressions };
      case 'GUIDELINE_EXPRESSIONS_ACTION_DATA_CHANGE':
        updatedExpressions = state.expressions
          .map(expression => ({ ...expression, action: { ...expression.action, data: action.payload } }));
        return { ...state, action: { ...state.action, data: action.payload }, expressions: updatedExpressions };
      case 'GUIDELINE_EXPRESSIONS_ACTION_DELETE':
        updatedExpressions = state.expressions.map(expression => {
          if (action.uuid === expression.uuid) {
            return ({ ...expression, action: { action, type: null, location: null, data: null } });
          }
          return expression;
        });
        return { ...state, expressions: updatedExpressions };
      case 'GUIDELINE_EXPRESSIONS_CHANGE':
        return { ...state, ...action.payload };
      default:
        return state;
    }
  };

  const [
    guidelineExpressions,
    dispatchGuidelineExpressionsAction,
  ] = useReducer(
    guidelineExpressionsReducer,
    guidelineExpressionsInitialState,
  );

  const guidelineExpressionActions = guidelineExpressions.expressions.map(expression => expression.action);
  const guidelineExpressionValues = guidelineExpressions.expressions.map(expression => expression.label);

  const [selectedActionType, setSelectedActionType] = useState(null);
  const [addWorkInstructionModalState, setAddWorkInstructionModalState] = useState({
    show: false,
    id: null,
  });
  const [summary, setSummary] = useState('');

  useEffect(() => {
    let newSummary = '';

    const targetProcessStep = guidelineExpressions.action?.target_process_step;

    const workflowStepSelected = guidelineExpressions.action?.type ===
    GUIDELINES_ENGINE_ACTION_TYPES.ADD_WORKFLOW_STEP;

    const workInstructionSelected = guidelineExpressions.action?.type ===
    GUIDELINES_ENGINE_ACTION_TYPES.ADD_WORK_INSTRUCTION;

    const ifBlockExpressionsSummary = guidelineExpressions.expressions.map((expression, index) => {
      const isLastEntry = guidelineExpressions.expressions.length > 1 &&
      (index + 1) !== guidelineExpressions.expressions.length;

      const string = [
        `IF ${expression.field?.label} on ${expression.field_type?.label} `,
        `${expression.comparator?.label} ${expression.value?.label} `,
        `${isLastEntry ? guidelineExpressions?.join : ''} `,
      ].join('');

      return string;
    });

    const thenBlockSummary = [
      'THEN Authentise will suggest a ',
      `${(workflowStepSelected && 'Workflow Step') || (workInstructionSelected && 'Work Instruction')} `,
      `${workflowStepSelected ? 'of type' : 'under'} ${processStepsByProcessTypeUri[targetProcessStep]?.name}.`,
    ].join('');

    newSummary = `${ifBlockExpressionsSummary} ${thenBlockSummary}`;
    setSummary(newSummary);
  }, [guidelineExpressions]);

  const getGuidelineExpressionByUuid = uuid => guidelineExpressions.expressions
    .find(expression => expression.uuid === uuid);

  const getNamedExpressionBySelectedFieldName = uuid => {
    const result = namedExpressions?.find(
      expression => expression.type === getGuidelineExpressionByUuid(uuid)?.field_type.value &&
    expression.uri === getGuidelineExpressionByUuid(uuid)?.field.value);
    return result;
  };

  const listUniqueFieldTypes = Object.values(_keyBy(namedExpressions, 'type'));

  const listExpressionFieldNamesBySelectedFieldType = uuid => {
    const result = namedExpressions?.filter(
      expression => expression.type === getGuidelineExpressionByUuid(uuid)?.field_type.value);
    return result;
  };

  const listComparators = uuid => {
    const result = getNamedExpressionBySelectedFieldName(uuid)?.comparators;
    return result;
  };

  // eslint-disable-next-line consistent-return
  const listValues = async expressionUuid => {
    const dropdownData = getNamedExpressionBySelectedFieldName(expressionUuid)?.named_expression_options;
    if (dropdownData && dropdownData.length) {
      const result = await Promise.all(dropdownData.map(async item => {
        const splitUri = item.options_uri.split('/');
        const endpointName = splitUri.at(-2);
        const filters = {};
        if (endpointName === API_RESOURCES.MATERIAL) {
          filters.bureau = bureauUri;
        }
        const response = await dispatch(Actions.Api.nautilus[endpointName].list(filters));
        const { resources } = await response.json ?? {};

        return {
          resource: endpointName,
          display_field_key: item.display_key,
          resources,
        };
      }));
      return result;
    }
  };

  // Logic to format expression.value.{{ 'uri' | 'label' }}.
  // 👇🏼 temporarily omitting, as this may be completely redundant
  // const transformExpressionValueForFieldType = expression => {
  //   const fieldLabel = expression.field.label.toUpperCase().trim();
  //   if (fieldLabel.includes('Name'.toUpperCase())
  //   || fieldLabel.includes('Cost'.toUpperCase())
  //   || fieldLabel.includes('By'.toUpperCase())) {
  //     return expression.value.label;
  //   }
  //   return expression.value.uri;
  // };
  // 👆🏼 end of omission.

  const onGuidelineSave = async formValues => {
    const { expressions } = guidelineExpressions;

    if (!formValues.name || !formValues.description) {
      Alert.error(
        <FormattedMessage
          id="toaster.error.name.mustProvideNameAndDescription"
          defaultMessage="You must provide a name and description."
        />);
      return;
    }

    /* 1. For each expression, post to `/guideline/expression` with the
    following payload */
    const savedExpressions = await Promise.all(expressions.map(async expression => {
      const expressionPayload = {
        named_expression: expression.field.value,
        comparator: expression.comparator.value,
        value: expression.value.label,
        bureau: bureauUri,
      };
      return dispatch(Actions.Api.nautilus[API_RESOURCES.EXPRESSION].post(expressionPayload))
        .then(response => response.headers.location);
    }));

    if (!guidelineExpressions?.action?.type) {
      Alert.error(
        <FormattedMessage
          id="toaster.error.name.mustProvideAction"
          defaultMessage="You must provide an action."
        />);
      return;
    }

    if (guidelineExpressions.action.type === GUIDELINES_ENGINE_ACTION_TYPES.ADD_WORK_INSTRUCTION) {
      /* 2.a POST to `/work-instruction` with work instruction data to get
        work_instruction_template in the payload in part 3 */
      const workInstructionPayload = _clone(guidelineExpressions.action.data);
      if (!workInstructionPayload.additional_instruction_url ||
        workInstructionPayload.additional_instruction_url.length === 0) {
        workInstructionPayload.additional_instruction_url = null;
      }

      dispatch(Actions.Api.nautilus[API_RESOURCES.WORK_INSTRUCTION].post(workInstructionPayload))
        .then(response => {
          const forWorkInstructionPayload = {
            related_workstation_uri: guidelineExpressions.action.target_process_step,
            work_instruction_template: response.headers.location,
            guideline_expressions: savedExpressions,
            join_operator: guidelineExpressions.join.toLowerCase(),
            name: formValues.name,
            description: formValues.description,
            summary,
          };

          if (response.headers.location) {
            dispatch(Actions.Api.nautilus[API_RESOURCES.FOR_WORK_INSTRUCTION].post(forWorkInstructionPayload))
              .catch(error => Alert.error(error))
              .then(() => Alert.success(
                <FormattedMessage
                  id="toaster.guidelinesEngine.successfullySaved"
                  defaultMessage="Guideline successfully saved."
                />,
              ))
              .then(() => { window.location.hash = getRouteURI(ROUTES.GUIDELINES_ENGINE); });
          }
        });
    } else if (guidelineExpressions.action.type === GUIDELINES_ENGINE_ACTION_TYPES.ADD_WORKFLOW_STEP) {
      const { notes, workstation_type_uri, success, tracking_id, upload } = guidelineExpressions.action.data;

      const processStepToCreatePayload = {
        bureau: bureauUri,
        notes,
        workstation_type_uri,
        success,
        tracking_id,
        upload,
      };

      dispatch(Actions.Api.nautilus[API_RESOURCES.PROCESS_STEP].post(processStepToCreatePayload))
        .then(response => {
          if (response.headers.location && savedExpressions.length) {
            const forProcessStepPayload = {
              process_step_template: response.headers.location,
              guideline_expressions: savedExpressions,
              join_operator: guidelineExpressions.join.toLowerCase(),
              name: formValues.name,
              description: formValues.description,
              summary,
            };

            dispatch(Actions.Api.nautilus[API_RESOURCES.FOR_PROCESS_STEP].post(forProcessStepPayload))
              .catch(error => Alert.error(error))
              .then(() => Alert.success(
                <FormattedMessage
                  id="toaster.guidelinesEngine.successfullySaved"
                  defaultMessage="Guideline successfully saved."
                />,
              ))
              .then(() => { window.location.hash = getRouteURI(ROUTES.GUIDELINES_ENGINE); });
          }
        });
    }
  };

  // Retrieves saved form values for an existing guideline record and fills in its data.
  const onInitializeGuidelineData = async () => {
    if (dataForGuideline) {
      // Manual state change on top of the redux state-based 'isInitializing' checks.
      setIsInitializingGuidelineData(true);

      const workInstruction = await dispatch(Actions.Api.nautilus[API_RESOURCES.WORK_INSTRUCTION]
        .get(extractUuid(dataForGuideline.work_instruction_template)));
      const processStep = await dispatch(Actions.Api.nautilus[API_RESOURCES.PROCESS_STEP]
        .get(extractUuid(dataForGuideline.process_step_template)));

      const expressionResponses = await Promise.all(dataForGuideline.guideline_expressions.map(async expression => {
        const expressionResponse = await dispatch(Actions.Api.nautilus[GUIDELINE_API_RESOURCES.EXPRESSION]
          .get(extractUuid(expression), true));

        let endpointData = null;

        if (isURI(expressionResponse.json?.value)) {
          endpointData = getEndpointFromURI(expressionResponse.json.value);
        }

        const named_expression = await dispatch(Actions.Api.nautilus[GUIDELINE_API_RESOURCES.NAMED_EXPRESSION]
          .get(extractUuid(expressionResponse.json.named_expression), true));
        const comparator = await dispatch(Actions.Api.nautilus[GUIDELINE_API_RESOURCES.COMPARATOR]
          .get(extractUuid(expressionResponse.json.comparator), true));
        const { value: rawValue } = await expressionResponse.json;

        let valueResource = null;

        if (endpointData && endpointData?.endpointName && endpointData?.uuid) {
          valueResource = await dispatch(Actions.Api.nautilus[endpointData.endpointName]
            .get(endpointData.uuid, true));
        }

        const { uri } = await expressionResponse.json;

        return { named_expression: named_expression.json,
          comparator: comparator.json,
          valueObj: {
            label: valueResource?.json?.name || rawValue,
            uri: valueResource?.json?.uri || rawValue,
          },
          uri };
      }));

      // eslint-disable-next-line no-param-reassign
      dataForGuideline.guideline_expressions = expressionResponses;

      // New expressions that will be used to set the state to the fetched initial values.
      const updatedFetchedExpressionState = expressionResponses.map(expression => (
        {
          action: {
            data: workInstruction?.json,
            target_process_step: dataForGuideline.related_workstation_uri,
            type: null,
          },
          comparator: {
            label: expression.comparator?.name,
            value: expression.comparator?.name,
          },
          field: {
            label: expression?.named_expression?.name,
            value: expression?.named_expression?.uri,
          },
          field_type: {
            label: expression?.named_expression?.type,
            value: expression?.named_expression?.type,
          },
          value: expression.valueObj,
          uuid: Math.random().toString(36).slice(2),
        }
      ));

      // Set all expression values to fetched initial form values.
      dispatchGuidelineExpressionsAction({
        type: 'GUIDELINE_EXPRESSIONS_SET',
        payload: updatedFetchedExpressionState,
      });

      // Set the join operator to fetched initial form values.
      dispatchGuidelineExpressionsAction({
        type: 'GUIDELINE_EXPRESSIONS_JOIN_CHANGE',
        payload: dataForGuideline?.join_operator.toUpperCase(),
      });

      // End of manual onInitializing state set.
      setIsInitializingGuidelineData(false);

      const { endpointName } = getEndpointFromURI(dataForGuideline.uri);

      // Set the workflow steps/work instructions to fetched initial form values.
      if (endpointName === 'for-work-instruction') {
        setSelectedActionType('add_work_instruction');
        dispatchGuidelineExpressionsAction({
          type: 'GUIDELINE_EXPRESSIONS_ACTION_TYPE_CHANGE',
          payload: 'add_work_instruction',
        });
        dispatchGuidelineExpressionsAction({
          type: 'GUIDELINE_EXPRESSIONS_ACTION_LOCATION_CHANGE',
          payload: dataForGuideline.related_workstation_uri,
        });
        dispatchGuidelineExpressionsAction({
          type: 'GUIDELINE_EXPRESSIONS_ACTION_DATA_CHANGE',
          payload: workInstruction?.json,
        });
      } else if (endpointName === 'for-process-step') {
        setSelectedActionType('add_workflow_step');
        dispatchGuidelineExpressionsAction({
          type: 'GUIDELINE_EXPRESSIONS_ACTION_TYPE_CHANGE',
          payload: 'add_workflow_step',
        });
        dispatchGuidelineExpressionsAction({
          type: 'GUIDELINE_EXPRESSIONS_ACTION_DATA_CHANGE',
          payload: processStep?.json,
        });
        dispatchGuidelineExpressionsAction({
          type: 'GUIDELINE_EXPRESSIONS_ACTION_LOCATION_CHANGE',
          payload: processStep?.json?.workstation_type_uri,
        });
      } else {
        /* ... */
      }
    }
  };

  const onInitializeGuidelines = async () => {
    const workInstructionGuidelinesResponse = await dispatch(Actions.Api.nautilus[API_RESOURCES.FOR_WORK_INSTRUCTION]
      .list({}, { limit: PAGINATION_IGNORE_DEFAULT_LIMIT }, {}, {}, true));
    const processStepGuidelinesResponse = await dispatch(Actions.Api.nautilus[API_RESOURCES.FOR_PROCESS_STEP]
      .list({}, { limit: PAGINATION_IGNORE_DEFAULT_LIMIT }, {}, {}, true));

    const workInstructionGuidelines = workInstructionGuidelinesResponse.json?.resources;
    const processStepGuidelines = processStepGuidelinesResponse.json?.resources;

    const updatedGuidelinesByUuid = {
      ..._keyBy(workInstructionGuidelines, guideline => extractUuid(guideline.uri)),
      ..._keyBy(processStepGuidelines, guideline => extractUuid(guideline.uri)),
    };

    setGuidelinesByUuid(updatedGuidelinesByUuid);
  };

  useEffect(() => {
    onInitializeGuidelines();
  }, []);

  useEffect(() => {
    onInitializeGuidelineData();
  }, [dataForGuideline]);

  return (
    <GuidelineContext.Provider
      value={{
        uuid,
        summary,
        uniqueFieldTypes: listUniqueFieldTypes,
        listExpressionFieldNamesBySelectedFieldType,
        getNamedExpressionBySelectedFieldName,
        dispatchGuidelineExpressionsAction,
        guidelineExpressions,
        guidelineExpressionActions,
        listComparators,
        listValues,
        printerTypes,
        printersTypesByUri,
        postProcessorTypes,
        postProcessorTypesByUri,
        shippingTypes,
        shippingTypesByUri,
        processSteps,
        processStepsByUri,
        processStepsByProcessTypeUri,
        guidelineExpressionValues,
        selectedActionType: { selectedActionType, setSelectedActionType },
        addWorkInstructionModalState: { addWorkInstructionModalState, setAddWorkInstructionModalState },
        onGuidelineSave,
        saving,
        dataForGuideline,
      }}
    >
      {fetchingExistingGuidelineData ? <Loading /> : <Guideline />}
      {/* 👇🏼 Regular props passed in through the <Context.Provider> above */}
    </GuidelineContext.Provider>
  );
};

GuidelineContainer.propTypes = {
  printerTypes: PropTypes.shape({}).isRequired,
  postProcessorTypes: PropTypes.shape({}).isRequired,
  shippingTypes: PropTypes.shape({}).isRequired,
};

export default GuidelineContainer;
