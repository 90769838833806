import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { Badge, ListGroup, ListGroupItem, OverlayTrigger, Tooltip } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { getAddiguruAnalysesByPrinter } from 'rapidfab/selectors/addiguru';
import Actions from 'rapidfab/actions';
import { API_RESOURCES, ROUTES } from 'rapidfab/constants';
import { Link } from 'react-router-dom';
import { getRouteURI } from 'rapidfab/utils/uriUtils';
import { extractUuid } from 'rapidfab/utils/uuidUtils';
import AddiguruAnalysisStatus from './section/AddiguruAnalysisStatus';

const AddiguruPrinterAnalyses = ({ printerUri }) => {
  const dispatch = useDispatch();
  const analyses = useSelector(state => getAddiguruAnalysesByPrinter(state, printerUri));

  useEffect(() => {
    dispatch(Actions.Api.nautilus[API_RESOURCES.ADDIGURU_ANALYSIS].list({ printer: printerUri }));
  }, [printerUri]);

  return (
    <div>
      <h3>Analyses Linked to Printer</h3>
      {analyses.length === 0 && <span><em>No Analyses linked to this printer</em></span>}
      <ListGroup style={{ maxHeight: '300px', overflow: 'auto' }}>
        {analyses.map(analysis => (
          <ListGroupItem
            key={analysis.id}
            className="justify-content-between d-flex"
          >
            <div className="pull-left d-flex flex-row gap-2">
              <Link to={getRouteURI(ROUTES.RUN_EDIT, { uuid: extractUuid(analysis.run) }, { section: 'addiguru' }, true)}>
                {analysis.addiguru_id}
              </Link>
              <AddiguruAnalysisStatus status={analysis.status} />
            </div>
            <div className="pull-right d-flex flex-row gap-2">
              <OverlayTrigger
                placement="top"
                overlay={(
                  <Tooltip id="anomaliesTooltip">
                    Anomalies
                  </Tooltip>
                )}
              >
                <Badge bg="warning">{analysis.anomalies}</Badge>
              </OverlayTrigger>
              <OverlayTrigger
                placement="top"
                overlay={(
                  <Tooltip id="defectsTooltip">
                    Defects
                  </Tooltip>
                )}
              >
                <Badge bg="danger">{analysis.defects}</Badge>
              </OverlayTrigger>
            </div>
          </ListGroupItem>
        ))}
      </ListGroup>
    </div>
  );
};

AddiguruPrinterAnalyses.propTypes = {
  printerUri: PropTypes.string.isRequired,
};

export default AddiguruPrinterAnalyses;
