import React, { useCallback, useState } from 'react';
import ModalComponent from 'rapidfab/components/modals/UseModalHookComponent';

/**
 *
 * @description Creates simple modals through a common resuable modal component.
 *
 * @example
 * // Call the hook
 * const [openMyModal, MyModal] = useModal();
 * // Using all parameters
 * const [openMyModal, MyModal, closeMyModal?] = useModal(CustomModal?);
 *
 * // Modal component defined, by default it will not display.
 * <MyModal id="myModalId" />
 *
 * // Whatever modal trigger (in this case, a button), calls the openModalCallback function.
 * <button onClick={() => openMyModal({
 *   title: "Modal Title",
 *   bodyText: "I'm a modal!",
 *   onConfirm: () => throw new Error("I'm not implemented yet!")
 * })}>
 *   Click Me!
 * </button>
 *
 * @example
 * // Using the third item the hook returns, (handleHideModal method)
 * const [openMyModal, MyModal, closeMyModal] = useModal();
 *
 * // ...
 * <button onClick={closeMyModal}>Close!</button>
 *
 * @returns {Array<Function, React.ReactElement, Function?>}
 */
export const useModal = CustomModal => {
  const [modalState, setModalState] = useState({
    modalOpen: false,
  });

  const handleHideModal = useCallback(() => setModalState({ ...modalState, modalOpen: false }), []);

  const modalFunction = useCallback(({ ...componentProps }) => (
    CustomModal ? (
      <CustomModal
        show={modalState.modalOpen}
        onHide={handleHideModal}
        onConfirm={(data, ...rest) => {
          handleHideModal();
          modalState.onConfirm(data, ...rest);
        }}
        {...modalState}
        {...componentProps}
      />
    )
      :
      (
        <ModalComponent
          isModalOpen={modalState.modalOpen}
          {...modalState}
          onHide={handleHideModal}
          onConfirm={(data, ...rest) => {
            handleHideModal();
            modalState.onConfirm(data, ...rest);
          }}
        />
      )
  ), [modalState]);

  /**
   * 1. handleModal (function): Master function for modal.
   * 2. ModalComponent (element): Is the modal component to show (UseModalHookComponent.jsx).
   * 3. handleHideModal (function): Optional function to close the modal.
   */

  return [
    restProps => setModalState({ modalOpen: !modalState.modalOpen, ...restProps }),
    modalFunction,
    handleHideModal,
  ];
};

export default useModal;
