import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/free-solid-svg-icons';

const DeleteButtonTitle = ({ resourceName }) => (
  <span>
    <FontAwesomeIcon icon={faTimes} />
    {' '}
    <FormattedMessage id="button.delete" defaultMessage="Delete" />
    {' '}
    {resourceName}
  </span>
);

DeleteButtonTitle.defaultProps = { resourceName: null };
DeleteButtonTitle.propTypes = { resourceName: PropTypes.string };

export default DeleteButtonTitle;
