import React from 'react';
import { useSelector } from 'react-redux';

import * as Selectors from 'rapidfab/selectors';

import ModelLibrariesByFiltersContainer from 'rapidfab/containers/ModelLibrariesByFiltersContainer';

const HawkingMyLibraryContainer = props => {
  const user = useSelector(Selectors.getSession);
  const myModelLibraries = useSelector(Selectors.getMyModelLibraries);

  return (
    <ModelLibrariesByFiltersContainer
      {...props}
      ownerUri={user.uri}
      modelLibraries={myModelLibraries}
    />
  );
};

export default HawkingMyLibraryContainer;
