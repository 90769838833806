import React from 'react';
import { PrepWorkflows } from 'rapidfab/components/organize';
import withRecordsListHandling from 'rapidfab/containers/hocs/withRecordsListHandling';
import { getNonCustomPrepWorkflows, getIsDebugModeEnabled } from 'rapidfab/selectors';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';

const PrepWorkflowsContainer = ({ queryParams, ...restProps }) => {
  const isDebugModeEnabled = useSelector(getIsDebugModeEnabled);
  return (
    <PrepWorkflows
      initialPrepWorkflowUUID={queryParams?.uuid}
      isDebugModeEnabled={isDebugModeEnabled}
      {...restProps}
    />
  );
};

PrepWorkflowsContainer.propTypes = {
  queryParams: PropTypes.shape({
    uuid: PropTypes.string,
  }).isRequired,
};

export default withRecordsListHandling(
  PrepWorkflowsContainer,
  getNonCustomPrepWorkflows,
  ['prep-workflow'],
);
