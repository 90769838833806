import React from 'react';
import {
  FormLabel,
  FormControl,
  FormGroup,
  Modal,
  Button,
} from 'react-bootstrap';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';

const ContactUsModal = ({ onClose, currentUser, show, onSubmit }) => (
  <Modal
    show={show}
    onHide={() => {
      onClose();
    }}
  >
    <form
      onSubmit={event => {
        event.preventDefault();
        onSubmit({
          support_request_payload:
            document.querySelector('#messageInput')?.value,
        });
      }}
    >
      <Modal.Header closeButton>
        <Modal.Title>Contact Us</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <FormGroup className="form-group" controlId="name">
          <FormLabel>
            <FormattedMessage id="contactUsField.name" defaultMessage="Name" />
          </FormLabel>
          <FormControl
            id="nameInput"
            required
            type="text"
            defaultValue={currentUser.name}
            disabled
          />
        </FormGroup>
        <FormGroup className="form-group" controlId="emailAddress">
          <FormLabel>
            <FormattedMessage
              id="contactUsField.email"
              defaultMessage="Email"
            />
          </FormLabel>
          <FormControl
            id="emailInput"
            type="text"
            defaultValue={currentUser?.username}
            disabled
          />
        </FormGroup>
        <FormGroup className="form-group" controlId="message">
          <FormLabel>
            <FormattedMessage
              id="contactUsField.message"
              defaultMessage="Message"
            />
          </FormLabel>
          <FormControl
            style={{ height: 100 }}
            as="textarea"
            id="messageInput"
            placeholder="Your message will be sent to the support team. A member of the support team will contact you within 2 business days."
          />
        </FormGroup>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="default" onClick={() => onClose()}>
          Cancel
        </Button>
        <Button type="submit" variant="primary">
          Submit
        </Button>
      </Modal.Footer>
    </form>
  </Modal>
);

ContactUsModal.propTypes = {
  onClose: PropTypes.func.isRequired,
  show: PropTypes.bool.isRequired,
  onSubmit: PropTypes.func.isRequired,
  currentUser: PropTypes.shape({
    // Name is empty for Service Provider users
    // https://app.clubhouse.io/authentise/story/28430
    name: PropTypes.string,
    username: PropTypes.string.isRequired,
  }).isRequired,
};

export default ContactUsModal;
