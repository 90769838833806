import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { ButtonToolbar, SplitButton, Dropdown, Button, Modal } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { FormattedMessage } from 'react-intl';
import { reduxFormFieldType } from 'rapidfab/types';
import { faBan } from '@fortawesome/free-solid-svg-icons';

export default class ServiceProviderDropdownButton extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showModal: false,
    };

    this.handleShow = this.handleShow.bind(this);
    this.handleHide = this.handleHide.bind(this);
    this.handleOnDelete = this.handleOnDelete.bind(this);
  }

  handleShow() {
    this.setState({ showModal: true });
  }

  handleHide() {
    this.setState({ showModal: false });
  }

  handleOnDelete() {
    const { fields, onDelete } = this.props;

    this.setState({ showModal: false }, () => {
      onDelete(fields.uuid);
    });
  }

  render() {
    const { fields } = this.props;

    return (
      <ButtonToolbar className="pull-right">
        {fields.uuid ? (
          <SplitButton
            id="uxSaveDropdown"
            type="submit"
            variant="success"
            size="sm"
            title="Save"
            pullRight
          >
            <Dropdown.Item
              eventKey={1}
              disabled={!fields.id}
              onClick={this.handleShow}
            >
              <FontAwesomeIcon icon={faBan} />{' '}
              <FormattedMessage id="button.delete" defaultMessage="Delete" />
            </Dropdown.Item>
          </SplitButton>
        ) : (
          <Button type="submit" variant="success" size="sm">
            Save & email Authentise Intro Material
          </Button>
        )}

        <Modal show={this.state.showModal}>
          <Modal.Header closeButton>
            <Modal.Title>Remove Service Provider</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            Are you sure you want to delete current Service Provider?
          </Modal.Body>
          <Modal.Footer>
            <Button variant="danger" onClick={this.handleOnDelete}>
              Delete
            </Button>
            <Button variant="primary" onClick={this.handleHide}>
              Back
            </Button>
          </Modal.Footer>
        </Modal>
      </ButtonToolbar>
    );
  }
}

ServiceProviderDropdownButton.propTypes = {
  fields: PropTypes.objectOf(reduxFormFieldType).isRequired,
  onDelete: PropTypes.func.isRequired,
};
