import React, { useEffect } from 'react';
import { getRouteUUID, getUUIDResource } from 'rapidfab/selectors';
import { API_RESOURCES } from 'rapidfab/constants';
import { useDispatch, useSelector } from 'react-redux';
import Actions from 'rapidfab/actions';
import Capture from 'rapidfab/components/records/capture';
import Loading from 'rapidfab/components/Loading';
import { extractUuid } from 'rapidfab/utils/uuidUtils';

const CaptureContainer = () => {
  const uuid = useSelector(getRouteUUID);
  const capture = useSelector(state => getUUIDResource(state, uuid));
  const captureType = useSelector(state => (capture
  && capture.capture_type ? getUUIDResource(state, extractUuid(capture.capture_type)) : null));
  const isLoading = useSelector(state => state.ui.nautilus[API_RESOURCES.CAPTURE].get.fetching);

  const dispatch = useDispatch();

  const onInitialize = currentUUID => {
    dispatch(Actions.Api.nautilus[API_RESOURCES.CAPTURE].get(currentUUID)).then(captureResponse => {
      const { capture_type: currentCaptureType, user } = captureResponse.json;
      dispatch(Actions.Api.nautilus[API_RESOURCES.USERS].get(extractUuid(user)));
      // Using LIST + filter[uri] since GET is not supported here.
      dispatch(Actions.Api.nautilus[API_RESOURCES.CAPTURE_TYPE].list({ uri: currentCaptureType }));
    });
  };

  const user = useSelector(state => (capture?.user ? getUUIDResource(state, extractUuid(capture.user)) : null));

  useEffect(() => {
    onInitialize(uuid);
  }, []);

  if (isLoading || !capture) {
    return <Loading />;
  }

  return (
    <Capture
      capture={capture}
      captureType={captureType}
      user={user}
    />
  );
};

export default CaptureContainer;
