import Alert from 'rapidfab/utils/alert';
import { fileNameHasPotentialNullByte, readableFileSize } from 'rapidfab/utils/fileUtils';
import React, { useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import {
  Modal,
  Row,
  Col,
  Card,
  Button,
  SplitButton,
  Dropdown,
  Image,
} from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import CastorCostingContainer from 'rapidfab/containers/hawking/CastorCostingContainer';
import EditModalHeader from 'rapidfab/components/EditModalHeader';
import ModalThreeScene from 'rapidfab/components/ModalThreeScene';
import FileInput from 'rapidfab/components/records/order/edit/FileInput';
import Loading from 'rapidfab/components/Loading';
import Feature from 'rapidfab/components/Feature';
import Documents from 'rapidfab/components/records/Documents';
import ModelLibraryDetailsPanel from 'rapidfab/components/records/model_library/ModelLibraryDetailsPanel';
import ProductSpecimenDetailPanel from 'rapidfab/components/records/model_library/ProductSpecimenDetailPanel';
import OwnerPanel from 'rapidfab/components/records/model_library/owner/OwnerPanel';
import {
  DOCUMENT_RELATED_TABLE_NAMES,
  FEATURES,
  MODEL_STATUSES,
  ZVERSE_CONVERSION_EXTENSIONS,
  MODEL_CONVERSION_STATUSES,
  COMMENT_RELATED_TABLE_NAMES,
  MODEL_UNITS,
  MAX_FILE_SIZE,
  EXPERT_CONVERSION_EXTENSIONS,
} from 'rapidfab/constants';
import { modelType, modelLibraryType } from 'rapidfab/types';
import ZverseContainer from 'rapidfab/containers/hawking/ZverseContainer';
import ResourceReadOnlyViewRow from 'rapidfab/components/ResourceReadOnlyView/ResourceReadOnlyViewRow';
import ResourceReadOnlyView from 'rapidfab/components/ResourceReadOnlyView/ResourceReadOnlyView';
import Config from 'rapidfab/config';
import _find from 'lodash/find';
import _isEmpty from 'lodash/isEmpty';
import getInitialCustomFieldValues from 'rapidfab/utils/getInitialCustomFieldValues';
import { createOrReplaceArray } from 'rapidfab/utils/arrayUtils';
import { connect } from 'react-redux';
import * as Selectors from 'rapidfab/selectors';
import ConfirmationModal from 'rapidfab/components/ConfirmationModal';
import { FormattedMessage } from 'rapidfab/i18n';
import { faDownload, faExternalLink, faShoppingCart, faUpload } from '@fortawesome/free-solid-svg-icons';
import CommentsContainer from 'rapidfab/containers/CommentsContainer';
import ThreadsLoginModal from 'rapidfab/components/modals/ThreadsLoginModal';
import ManageCarouselModal from 'rapidfab/components/Carousel/ManageCarouselModal';
import Carousel from 'rapidfab/components/Carousel/Carousel';
import FsLightbox from 'fslightbox-react';
import { convertLengthToOtherUnit as convertLength } from 'rapidfab/utils/mathUtils';
import ExpertConversionContainer from 'rapidfab/containers/hawking/ExpertConversionContainer';
import COMMENT_RESOURCE_TYPES from 'rapidfab/constants/CommentResourceType';

const hawkingModalThreeSceneWrapperStyles = isHawkingUser => (
  isHawkingUser && {
    marginTop: 16,
  }
);

const DownloadableModelFileRow = ({
  label,
  name,
  handleModelDownload,
  truncateNameMaxCars,
}) => (
  <p>
    <b className="spacer-right">{label}:</b>
    <Button
      className="p-a-0"
      variant="link"
      onClick={handleModelDownload}
    >
      {
        truncateNameMaxCars &&
        truncateNameMaxCars > 0
        && truncateNameMaxCars < 128 && name.length > truncateNameMaxCars ?
          <span>{name.slice(0, truncateNameMaxCars)}...</span>
          :
          <span>{name}</span>
      }
      <FontAwesomeIcon icon={faDownload} className="spacer-left" />
    </Button>
  </p>
);

DownloadableModelFileRow.defaultProps = {
  truncateNameMaxCars: null,
};

DownloadableModelFileRow.propTypes = {
  label: PropTypes.node.isRequired,
  name: PropTypes.string.isRequired,
  handleModelDownload: PropTypes.func.isRequired,
  truncateNameMaxCars: PropTypes.number,
};

const ModelLibrary = ({
  handleClose,
  handleModelContentDownload,
  handleZverseSnapshotContentDownload,
  labelsByUri,
  model,
  modelLibrary,
  onDelete,
  onUpdate,
  onUpdateModel,
  specimens,
  uuid,
  isCADToSTLConversionFeatureEnabled,
  customModelLibraryFieldReferences,
  isHawkingUser,
  isDigitalDesignWarehouseFeatureEnabled,
  isThreadsIntegrationFeatureEnabled,
  handleModelOriginalContentDownload,
  isShowing,
  workflows,
  onFetchMoreWorkflows,
  setAddToCartModalVisible,
  isRestrictedUser,
  isStanleyXUser,
  shippings,
  handleSigninToThreads,
  threadsLoginModalState,
  isThreadsApiFetching,
  threadsApiRelatedThreads,
  cookies,
  carousel,
  isCarouselFetching,
  handleAddDocumentToCarousel,
  currentUser,
  isIntegrationCastorFeatureEnabled,
  handleSaveShoppingCart,
  handleUploadCarouselImage,
  handleRefreshCarouselSnapshots,
  uploadingCarouselImages,
  userBelongsToCustomGroup,
  isRobozeBureauOrderFieldsFeatureEnabled,
  isExpertConversionFeatureEnabled,
}) => {
  const onSaveName = name => onUpdate({
    name,
    uuid,
  });
  const [replacingModel, setReplacingModel] = useState(null);
  const [isUploadingReplacingModel, setIsUploadingReplacingModel] = useState(false);
  const [customFieldValues, setCustomFieldValues] = useState(modelLibrary.custom_field_values);
  const [showDeleteConfirmationModal, setShowDeleteConfirmationModal] = useState(false);
  const [showManageCarouselModal, setShowManageCarouselModal] = useState(false);
  const [focusedCarouselImage, setFocusedCarouselImage] = useState(carousel?.snapshots[0]);
  const [showImageLightbox, setShowImageLightbox] = useState(false);
  const [maxFileSizeWarning, setMaxFileSizeWarning] = useState(null);

  const [showThreadsLoginModal, setShowThreadsLoginModal] = threadsLoginModalState;
  const handleFileChange = event => {
    const modelFile = event.target.files[0];

    if (fileNameHasPotentialNullByte(modelFile.name)) {
      Alert.error('The filename does not meet the requirements. Please rename the file and try again.');
      return;
    }

    setReplacingModel(modelFile);
  };

  const handleUploadReplacingModel = async () => {
    if (replacingModel?.size > MAX_FILE_SIZE && !maxFileSizeWarning) {
      const maxFileSize = readableFileSize(MAX_FILE_SIZE);
      const fileSize = readableFileSize(replacingModel.size);
      setMaxFileSizeWarning(
        `Allowed max file ${maxFileSize} size exceeded (received file size is ${fileSize}). Would you like to upload the file anyway?`,
      );
      return;
    }
    setMaxFileSizeWarning(null);
    setIsUploadingReplacingModel(true);
    try {
      await onUpdateModel(replacingModel, uuid);
      setReplacingModel(null);
      setIsUploadingReplacingModel(false);
    } catch {
      setIsUploadingReplacingModel(false);
    }
  };

  const robozeAndCastorFeaturesEnabled = isRobozeBureauOrderFieldsFeatureEnabled && isIntegrationCastorFeatureEnabled;

  const findCustomFieldReference = fieldId => {
    if (!fieldId) return null;
    if (!isHawkingUser) return null;

    const currentReference = _find(
      customModelLibraryFieldReferences,
      { field_id: fieldId },
    );

    if (!currentReference) {
      return null;
    }

    /* We will render the Part Number custom field in case its position is null,
       and it will be rendered into "Product Details section". If it is not "null"
       - we will not render it. See: [sc-40166] */

    if (currentReference.field_id === 'part_number' && currentReference.position !== null) return null;

    return currentReference;
  };
  const initCustomFieldValues = () => {
    const updatedCustomLineItemFieldValues = getInitialCustomFieldValues(
      customModelLibraryFieldReferences, customFieldValues,
    );

    setCustomFieldValues(updatedCustomLineItemFieldValues);
  };
  const onCustomFieldChange = (_, value) => {
    const customFieldValuesReplaced = createOrReplaceArray(
      customFieldValues,
      { custom_field: value.customFieldReferenceUri },
      { value: value.value },
    );

    setCustomFieldValues(customFieldValuesReplaced);
  };

  const onCloseModals = () => {
    setShowDeleteConfirmationModal(false);
  };

  // shows delete confirmation modal
  const handleDelete = () => {
    setShowDeleteConfirmationModal(true);
  };

  // deletes the product
  const deleteProduct = () => {
    onDelete(uuid);
    onCloseModals();
  };

  useEffect(() => {
    if (customModelLibraryFieldReferences.length) {
      initCustomFieldValues();
    }
  }, []);

  // Model can be in Not Uploaded state, when there is a PDF file attached, but no STL yet
  // TODO: Other Non-stl formats will be covered via this condition later as well
  const isModelUploaded = model
    && model.status !== MODEL_STATUSES.NOT_UPLOADED
    && model.status !== MODEL_STATUSES.CONVERTING;

  // No need to replace models which are not yet uploaded
  // (e.g. PDF models with no STL yet)
  /* const isReplaceAllowed = isModelUploaded; */

  // Expert conversion panel is needed for PDF/PNG/TIFF/TIF files only, and when there is no STL file yet
  const isExpertConversionPanelAllowed = isExpertConversionFeatureEnabled && model
    && EXPERT_CONVERSION_EXTENSIONS.includes(model.conversion_original_type)
    && !isModelUploaded;

  // Zverse panel is needed for PDF/PNG/TIFF/TIF files only, and when there is no STL file yet
  const isZversePanelAllowed = !isExpertConversionFeatureEnabled && model
    && ZVERSE_CONVERSION_EXTENSIONS.includes(model.conversion_original_type)
    && !isModelUploaded;

  /* The Zverse file extensions should be either "png, pdf, tif, tiff", in order to make it sure,
     we need to have these conditions:
      1. The conversion_original_type should be one of those file extensions mentioned above.
      2. The model should not have the "content" as it is only for the 3D models.
      3. It should definitely contain the "snapshot_value" string leads to the snapshot image.
      4. The original conversion status should be "complete-without-upload.
      If all conditions are true -> we will show the 2D Image and no preview enabled (for 3D models only)" */
  const isZverseFileUploaded = !isExpertConversionFeatureEnabled
    && ZVERSE_CONVERSION_EXTENSIONS.includes(model?.conversion_original_type)
    && !model.content
    && model.snapshot_content
    && model.conversion_status === MODEL_CONVERSION_STATUSES.COMPLETE_WITHOUT_UPLOAD;

  // Castor Costing panel is supported only if there is a model file uploaded (STL)
  // (No need to show it for PDF without STL)
  const isCastorCostingPanelAllowed = isModelUploaded || isZverseFileUploaded;

  /* This determines whether the edit (pencil) buttons on the panels should be visible. */
  const isModelDataEditable = useMemo(() =>
    /* User is restricted as is on the `/company-library` */
    /* ⚠️ Company Library if-clause temporarily commented-out below until backend change is
    implemented to allow restricted users to delete from `My Library` ⚠️ */
    (!isRestrictedUser || userBelongsToCustomGroup) ||
      (modelLibrary.owner === currentUser.uri),

  [
    isRestrictedUser,
    currentUser.uri,
    modelLibrary?.owner,
    userBelongsToCustomGroup]);

  const handleRedirectToNewThreadDiscussion = () => {
    const newThreadUrl = new URL(`${Config.THREADS_URL}/new-thread`);
    newThreadUrl.pathname = 'new-thread';
    newThreadUrl.searchParams.set('name', modelLibrary.name);
    newThreadUrl.searchParams.set('reference_uri', modelLibrary.uri);
    window.open(newThreadUrl, '_blank').focus();
  };

  const isWorkflowsAndShippingsNotEmpty = workflows.length && shippings.length;

  const isModalSnapshotSelected = focusedCarouselImage?.source === model?.uri ||
    !carousel?.snapshots.length;

  useEffect(() => {
    setFocusedCarouselImage(carousel?.snapshots[0]);
  }, [carousel?.snapshots]);

  if (showManageCarouselModal) {
    return (
      <ManageCarouselModal
        carousel={carousel}
        isCarouselFetching={isCarouselFetching}
        onHide={() => setShowManageCarouselModal(false)}
        handleUploadCarouselImage={handleUploadCarouselImage}
        handleRefreshCarouselSnapshots={handleRefreshCarouselSnapshots}
      />
    );
  }

  if (showThreadsLoginModal) {
    return (
      <ThreadsLoginModal
        onSignin={handleSigninToThreads}
        onClose={() => setShowThreadsLoginModal(false)}
        isThreadsApiFetching={isThreadsApiFetching}
      />
    );
  }

  return ([
    <FsLightbox
      toggler={showImageLightbox}
      sources={[focusedCarouselImage?.content]}
    />,
    !maxFileSizeWarning ? (
      <Modal
        size={`${isHawkingUser ? 'lg' : 'xl'}`}
        show={isShowing}
        onHide={handleClose}
        backdrop="static"
        scrollable
      >
        <Modal.Header style={{ position: 'relative' }}>
          <Modal.Title className="w-100">
            <EditModalHeader
              name={modelLibrary.name}
              partNumber={modelLibrary.default_customer_id}
              onSaveName={onSaveName}
              onClose={handleClose}
              onDelete={handleDelete}
              isHawkingUser={isHawkingUser}
              isModelDataEditable={isModelDataEditable}
            />
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Row>
            <Col lg={8} xs={12}>
              <Card bg="light" className="mb15">
                <Card.Body className="pd-exp">
                  {model && (
                    <ResourceReadOnlyView entity={model}>
                      {(model.content || isZverseFileUploaded) && (
                        <div className="d-flex row mb8">
                          <Col className="d-flex justify-content-center mb15" lg={{ span: 8 }}>
                            <div
                              className={`render-thumbnail ${isHawkingUser ? 'mb15' : 'd-flex justify-content-center mb5'}`}
                              style={{
                                ...hawkingModalThreeSceneWrapperStyles(isHawkingUser),
                              }}
                            >
                              {isModalSnapshotSelected ? (
                                <ModalThreeScene
                                  className="paddingless"
                                  isHawkingUser={isHawkingUser}
                                  snapshot={model.snapshot_content}
                                  model={model.content}
                                  unit={model.user_unit}
                                  fileUnit={model.file_unit}
                                  volume={model.volume_mm}
                                  size={model.size}
                                  isZverseFileUploaded={isZverseFileUploaded}
                                  showMfgOrientationPanel={false}
                                />
                              ) : (
                                carousel?.snapshots.length > 0 && (
                                  <Image
                                    role="button"
                                    onClick={() => setShowImageLightbox(previous => !previous)}
                                    className="img-thumbnail"
                                    src={focusedCarouselImage?.content}
                                  />
                                )
                              )}
                            </div>
                          </Col>
                          {/* {isZverseFileUploaded && ( */}
                          <Col
                            lg={{ span: 4 }}
                            className={`d-flex flex-direction-column justify-content-start ${isHawkingUser ? 'mt15' : ''}`}
                            style={{ padding: '0px 8px' }}
                          >
                            <Card bg="light" className="panel-light">
                              <div className="card-body-wrapper">
                                <Card.Body className="pd-exp inverse file-details">
                                  <DownloadableModelFileRow
                                    truncateNameMaxCars={18}
                                    label={isCADToSTLConversionFeatureEnabled ? 'Model File' : 'Product File'}
                                    name={model.name ?? ''}
                                    handleModelDownload={
                                    /* (handleModelContentDownload and handleModelOriginalContentDownload)
                                     * these functions appends the "filename" in the `content` url.
                                     * This is needed to download the file with stl extension instead
                                     * of document.
                                     */
                                      () => (model.zverse_conversion ? handleZverseSnapshotContentDownload(model) :
                                        ((!model.conversion_status || model.conversion_original_filename)
                                          ? handleModelOriginalContentDownload(model)
                                          : handleModelContentDownload(model)))
                                    }
                                  />
                                  {!!isModelDataEditable && (
                                    <div className="mt15 mb10">
                                      <b>Replace with 3D Model:</b>
                                      {!replacingModel ? (
                                        <FileInput
                                          buttonStyle="btn-primary"
                                          customContainerStyles="mt-2 text-center m-l-0"
                                          controlLabelStyle={{ width: '100%' }}
                                          disabled={isUploadingReplacingModel}
                                          chooseFileLabel={(
                                            <div className="d-flex justify-content-center align-items-center text-left">
                                              <FontAwesomeIcon icon={faUpload} className="spacer-right" />
                                              <span>{isUploadingReplacingModel ? 'Uploading...' : 'Replace'}</span>
                                            </div>
                                          )}
                                          variant="primary"
                                          handleFileChange={handleFileChange}
                                        />
                                      )
                                        : (
                                          <Button
                                            className="pull-right my-2 w-full"
                                            variant="primary"
                                            disabled={!replacingModel}
                                            onClick={handleUploadReplacingModel}
                                          >
                                            {isUploadingReplacingModel
                                              ? <Loading />
                                              : (
                                                <span>Confirm Upload</span>
                                              )}
                                          </Button>
                                        )}
                                    </div>
                                  )}
                                  <p><strong>Model Units:</strong></p>
                                  <p> {model.file_unit}</p>
                                  <p><strong>Model Volume:</strong></p>
                                  <p> {(model.volume_mm)?.toFixed(2) ?? 'N/A'} {MODEL_UNITS.MM}^3</p>
                                  <p><strong>Dimensions:</strong></p>
                                  {
                                    Object.values(model.size).every(value => value === null) ?
                                      <p>N/A</p>
                                      : (
                                        <p>
                                          {
                                            [model.size.x, model.size.y, model.size.z]
                                              .map(dimension => (dimension ? convertLength(dimension, model.file_unit, MODEL_UNITS.MM)?.toFixed(2) : 'N/A'))
                                              .join(' x ')
                                          } {MODEL_UNITS.MM}
                                        </p>
                                      )
                                  }
                                </Card.Body>
                              </div>
                            </Card>
                          </Col>
                          {/* )} */}
                        </div>
                      )}
                      {/* {model.conversion_status && (
                    <ResourceReadOnlyViewRow
                      className="model-library-model-info-row"
                      name="conversion_status"
                      type={RESOURCE_READ_ONLY_VIEW_FIELD_TYPES.MAPPED}
                      map={MODEL_CONVERSION_STATUSES_MAP}
                    />
                  )} */}
                      {model.conversion_notes && (
                        <ResourceReadOnlyViewRow
                          className="model-library-model-info-row"
                          name="conversion_notes"
                        />
                      )}
                      {model.conversion_original_content && (
                        <DownloadableModelFileRow
                          label={isCADToSTLConversionFeatureEnabled ? 'Original Model File' : 'Original Product File'}
                          name={model.conversion_original_filename ?? model.name ?? ''}
                          handleModelDownload={
                            () => handleModelContentDownload(model)
                          }
                        />
                      )}
                    </ResourceReadOnlyView>
                  )}

                  {carousel?.snapshots.length > 0 && (
                    <Carousel
                      carousel={carousel}
                      onManageCarouselClick={() => setShowManageCarouselModal(true)}
                      focusedCarouselImageState={[focusedCarouselImage, setFocusedCarouselImage]}
                      handleUploadCarouselImage={handleUploadCarouselImage}
                      isCarouselFetching={isCarouselFetching}
                      uploadingCarouselImages={uploadingCarouselImages}
                    />
                  )}

                  {isZversePanelAllowed && (
                    <Feature featureName={FEATURES.INTEGRATION_ZVERSE}>
                      <Row>
                        <Col xs={12}>
                          <ZverseContainer
                            modelUri={model.uri}
                          />
                        </Col>
                      </Row>
                    </Feature>
                  )}

                  {isExpertConversionPanelAllowed && (
                    <Feature featureName={FEATURES.DESIGN_CONVERSION_BY_EXPERT}>
                      <Row>
                        <Col xs={12}>
                          <ExpertConversionContainer
                            modelUri={model.uri}
                          />
                        </Col>
                      </Row>
                    </Feature>
                  )}
                </Card.Body>
              </Card>
            </Col>
            <Col lg={4} xs={12}>
              <ModelLibraryDetailsPanel
                modelLibrary={modelLibrary}
                labelsByUri={labelsByUri}
                onUpdate={onUpdate}
                findCustomFieldReference={findCustomFieldReference}
                onCustomFieldChange={onCustomFieldChange}
                customFieldValues={customFieldValues}
                isModelDataEditable={isModelDataEditable}
              />
              <ProductSpecimenDetailPanel
                specimens={specimens}
                modelLibrary={modelLibrary}
                onUpdate={onUpdate}
                findCustomFieldReference={findCustomFieldReference}
                onCustomFieldChange={onCustomFieldChange}
                customFieldValues={customFieldValues}
                customModelLibraryFieldReferences={customModelLibraryFieldReferences}
                onFetchMoreWorkflows={onFetchMoreWorkflows}
                isModelDataEditable={isModelDataEditable}
                isRestrictedUser={isRestrictedUser}
              />
              <OwnerPanel
                modelLibrary={modelLibrary}
                onUpdate={onUpdate}
                isDigitalDesignWarehouseFeatureEnabled={isDigitalDesignWarehouseFeatureEnabled}
              />
            </Col>
          </Row>

          <Row className={isThreadsIntegrationFeatureEnabled ? '' : 'm-t'}>
            <Col md={8} xs={12}>
              {!isThreadsIntegrationFeatureEnabled && (
                <CommentsContainer
                  bgInverse
                  resourceTableName={COMMENT_RELATED_TABLE_NAMES.MODEL_LIBRARY}
                  resourceUUID={uuid}
                  modelLibrary={modelLibrary}
                  resourceType={COMMENT_RESOURCE_TYPES.MODEL_LIBRARY}
                />
              )}
            </Col>
            <Col md={4} xs={12}>
              <Documents
                isInverse
                panelTitle="Documents"
                relatedTable={DOCUMENT_RELATED_TABLE_NAMES.MODEL_LIBRARY}
                relatedUUID={uuid}
                carousel={carousel}
                handleAddDocumentToCarousel={handleAddDocumentToCarousel}
                uploadButtonIconOnly
              />
            </Col>
          </Row>

          {isCastorCostingPanelAllowed && (
            <Feature featureName={FEATURES.INTEGRATION_CASTOR}>
              <Row>
                <Col xs={12}>
                  <CastorCostingContainer
                    modelLibrary={modelLibrary}
                    model={model}
                    modelUri={model && model.uri}
                    isZverseFileUploaded={isZverseFileUploaded}
                    isIntegrationCastorFeatureEnabled={isIntegrationCastorFeatureEnabled}
                    setAddToCartModalVisible={setAddToCartModalVisible}
                    handleSaveShoppingCart={handleSaveShoppingCart}
                    isRobozeBureauOrderFieldsFeatureEnabled={isRobozeBureauOrderFieldsFeatureEnabled}
                  />
                </Col>
              </Row>
            </Feature>
          )}

        </Modal.Body>

        <Modal.Footer className="stanley-x-order_btn-container">
          <Feature featureName={FEATURES.THREADS_INTEGRATION}>
            {threadsApiRelatedThreads.length === 0 ? (
              <Button
                bg="warning"
                variant="warning"
                onClick={_isEmpty(cookies.get('threads_bearer_token')) ? () => setShowThreadsLoginModal(true) : handleRedirectToNewThreadDiscussion}
              >
                <FormattedMessage
                  id="discussInNewThread"
                  defaultMessage="Discuss in New Thread"
                />
                <FontAwesomeIcon className="spacer-left" icon={faExternalLink} />
              </Button>
            ) : (
              <SplitButton
                bg="warning"
                variant="warning"
                onClick={() => window.open(`${Config.THREADS_URL}/thread/${threadsApiRelatedThreads[0].id}`, '_blank').focus()}
                title="Continue Discussion"
              >
                {threadsApiRelatedThreads.map(thread => (
                  <Dropdown.Item
                    onClick={() => window.open(`${Config.THREADS_URL}/thread/${thread.id}`, '_blank').focus()}
                  >
                    Continue Discussion in &ldquo;{thread.name}&rdquo;
                    <FontAwesomeIcon
                      className="spacer-left"
                      icon={faExternalLink}
                    />
                  </Dropdown.Item>
                ))}
              </SplitButton>
            )}
          </Feature>
          <div className="stanley-x-order_btn-container">
            {isDigitalDesignWarehouseFeatureEnabled && !robozeAndCastorFeaturesEnabled && (
              <div>
                {isWorkflowsAndShippingsNotEmpty ? (
                  <Button
                    className={isStanleyXUser ? 'stanley-x-order_now-btn spacer-right' : 'spacer-right'}
                    onClick={() => setAddToCartModalVisible(true)}
                  >
                    <FontAwesomeIcon className="spacer-right" icon={faShoppingCart} />
                    <FormattedMessage
                      id="modelLibrary.shoppingCart.addToCart"
                      defaultMessage="Add to Cart"
                    />
                  </Button>
                ) : (
                  <Row>
                    <Col lg={10} className="d-flex align-items-end">
                      <p>
                        <em>* Please create at least one shipping partner and workflow to begin adding designs to cart
                        </em>
                      </p>
                    </Col>
                    <Col>

                      <Button
                        className={isStanleyXUser ? 'stanley-x-order_now-btn spacer-right' : 'spacer-right'}
                        onClick={() => setAddToCartModalVisible(true)}
                        disabled
                      >
                        <FontAwesomeIcon className="spacer-right" icon={faShoppingCart} />
                        <FormattedMessage
                          id="modelLibrary.shoppingCart.addToCart"
                          defaultMessage="Add to Cart"
                        />
                      </Button>
                    </Col>
                  </Row>
                )}
              </div>
            )}
          </div>
        </Modal.Footer>
        {showDeleteConfirmationModal && (
          <ConfirmationModal
            handleCancel={onCloseModals}
            handleConfirm={deleteProduct}
            message={(
              <FormattedMessage
                id="message.entityDeletePrompt"
                defaultMessage="Are you sure you want to delete this {entityType}?"
                values={{ entityType: 'Product' }}
              />
            )}
            title={<FormattedMessage id="deleteProduct" defaultMessage="Delete Product" />}
            confirmButtonContent={<FormattedMessage id="button.delete" defaultMessage="Delete" />}
            isHawkingUser={isHawkingUser}
          />
        )}
      </Modal>
    ) : (
      <ConfirmationModal
        handleCancel={() => setMaxFileSizeWarning(null)}
        handleConfirm={handleUploadReplacingModel}
        confirmButtonContent="Yes"
        message={maxFileSizeWarning}
      />
    ),
  ]);
};

ModelLibrary.defaultProps = {
  model: null,
  isCADToSTLConversionFeatureEnabled: false,
  customModelLibraryFieldReferences: [],
  handleModelOriginalContentDownload: () => null,
  onFetchMoreWorkflows: () => null,
  userBelongsToCustomGroup: false,
};

ModelLibrary.propTypes = {
  handleClose: PropTypes.func.isRequired,
  handleModelContentDownload: PropTypes.func.isRequired,
  handleModelOriginalContentDownload: PropTypes.func,
  handleZverseSnapshotContentDownload: PropTypes.func.isRequired,
  labelsByUri: PropTypes.objectOf(PropTypes.shape({
    name: PropTypes.string,
    color: PropTypes.string,
  })).isRequired,
  model: modelType,
  modelLibrary: modelLibraryType.isRequired,
  onDelete: PropTypes.func.isRequired,
  onUpdate: PropTypes.func.isRequired,
  onUpdateModel: PropTypes.func.isRequired,
  specimens: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  uuid: PropTypes.string.isRequired,
  isCADToSTLConversionFeatureEnabled: PropTypes.bool,
  customModelLibraryFieldReferences: PropTypes.instanceOf(Array),
  isHawkingUser: PropTypes.bool.isRequired,
  isShowing: PropTypes.bool.isRequired,
  onFetchMoreWorkflows: PropTypes.func,
  isRestrictedUser: PropTypes.bool.isRequired,
  isStanleyXUser: PropTypes.bool.isRequired,
  isDigitalDesignWarehouseFeatureEnabled: PropTypes.bool.isRequired,
  workflows: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  shippings: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  setAddToCartModalVisible: PropTypes.func.isRequired,
  isThreadsIntegrationFeatureEnabled: PropTypes.bool.isRequired,
  handleSigninToThreads: PropTypes.func.isRequired,
  threadsLoginModalState: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  isThreadsApiFetching: PropTypes.bool.isRequired,
  threadsApiRelatedThreads: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.number,
  })).isRequired,
  cookies: PropTypes.shape({
    get: PropTypes.func,
  }).isRequired,
  carousel: PropTypes.shape({
    content: PropTypes.string,
    snapshots: PropTypes.arrayOf(PropTypes.shape({
      content: PropTypes.string,
    })).isRequired,
  }).isRequired,
  isCarouselFetching: PropTypes.bool.isRequired,
  handleAddDocumentToCarousel: PropTypes.func.isRequired,
  currentUser: PropTypes.shape({
    uri: PropTypes.string.isRequired,
  }).isRequired,
  isIntegrationCastorFeatureEnabled: PropTypes.bool.isRequired,
  handleSaveShoppingCart: PropTypes.func.isRequired,
  handleUploadCarouselImage: PropTypes.func.isRequired,
  handleRefreshCarouselSnapshots: PropTypes.func.isRequired,
  uploadingCarouselImages: PropTypes.bool.isRequired,
  userBelongsToCustomGroup: PropTypes.bool,
  isRobozeBureauOrderFieldsFeatureEnabled: PropTypes.bool.isRequired,
  isExpertConversionFeatureEnabled: PropTypes.bool.isRequired,
};

const mapStateToProps = state => ({
  customModelLibraryFieldReferences: Selectors.getCustomModelLibraryFieldReferences(state),
  isHawkingUser: Selectors.isFeatureEnabled(state, FEATURES.HAWKING_DEPLOYMENT),
  isExpertConversionFeatureEnabled: Selectors.isFeatureEnabled(state, FEATURES.DESIGN_CONVERSION_BY_EXPERT),
  currentUser: Selectors.getSession(state),
});

export default connect(mapStateToProps)(ModelLibrary);
