import React from 'react';
import PropTypes from 'prop-types';
import { Badge } from 'react-bootstrap';
import { FormattedMessage } from 'rapidfab/i18n';
import { FEATURES, TEXT_COLOR_CONTRAST, RUN_OPERATIONS } from 'rapidfab/constants';
import {
  RUN_OPERATION_MAP,
  RUN_STATUS_COLOR_MAP,
  SECURE_FILE_CHECKOUT_STATUS_COLOR_CODE_MAPPING,
  SECURE_FILE_CHECKOUT_STATUSES_MAP,
} from 'rapidfab/mappings';
import _startCase from 'lodash/startCase';
import UseNonMfgLanguageFeature from 'rapidfab/components/generalMfgLanguage/UseNonMfgLanguageFeature';
import { ExportControlGeneralWarning } from 'rapidfab/components/ExportControl';
import Feature from 'rapidfab/components/Feature';
import AddiguruAnomalyCountPill from 'rapidfab/components/addiguru/section/AddiguruAnomalyCountPill';

const RunRecordHeader = ({
  uri,
  name,
  printCount,
  exportControlled,
  status,
  operation,
  lastSecureFileCheckoutStatus,
  estimatesPrintTime,
}) => {
  const labelBgColor = RUN_STATUS_COLOR_MAP[status];
  const textColor = TEXT_COLOR_CONTRAST[labelBgColor];
  const estimationText = `Estimated Run Time: ${estimatesPrintTime || '-'} / `;

  const secureFileCheckoutBgColor = SECURE_FILE_CHECKOUT_STATUS_COLOR_CODE_MAPPING[lastSecureFileCheckoutStatus];
  const secureFileCheckoutTextColor = TEXT_COLOR_CONTRAST[secureFileCheckoutBgColor];

  return (
    <div className="page-header">
      <h3>
        <div className="page-header-flex">
          <div className="page-header-flex-title">
            <div>
              {operation && (
                <UseNonMfgLanguageFeature
                  mfgLanguageComponent={(
                    <FormattedMessage
                      id={RUN_OPERATION_MAP[operation].id}
                      defaultMessage={RUN_OPERATION_MAP[operation].defaultMessage}
                    />
                  )}
                  nonMfgLanguageComponent={(
                    <FormattedMessage
                      id="mfg.printing.schedule"
                      defaultMessage="Schedule"
                    />
                  )}
                />
              )} Run:<b className="spacer-left wrap-text">{name || '-'}</b>
              {exportControlled && (
                <ExportControlGeneralWarning
                  badgeClassName="ml15"
                >
                  <FormattedMessage
                    id="message.exportControlWarning"
                    defaultMessage="One or more pieces in this run are export controlled. Please use necessary caution."
                  />
                </ExportControlGeneralWarning>
              )}
            </div>
            <h5>
              ({`${estimationText}Pieces Count: ${printCount || '-'}`})
            </h5>
          </div>
          <div className="d-flex spacer-top">
            {operation === RUN_OPERATIONS.PRINTING && (
              <Feature featureName={FEATURES.ADDIGURU}>
                <AddiguruAnomalyCountPill runUri={uri} />
              </Feature>
            )}
            <Feature featureName={FEATURES.SECURE_FILE_CHECKOUT}>
              {operation === RUN_OPERATIONS.PRINTING && (
                <Badge
                  style={{ color: secureFileCheckoutTextColor }}
                  className="spacer-right"
                  ref={element => element && element.style.setProperty(
                    'background-color', secureFileCheckoutBgColor, 'important',
                  )}
                >
                  {SECURE_FILE_CHECKOUT_STATUSES_MAP[lastSecureFileCheckoutStatus]}
                </Badge>
              )}
            </Feature>
            <Badge
              style={{ color: textColor }}
              ref={element => element && element.style.setProperty(
                'background-color', labelBgColor, 'important',
              )}
            >
              {_startCase(status)}
            </Badge>
          </div>
        </div>
      </h3>
    </div>
  );
};

RunRecordHeader.defaultProps = {
  name: null,
  status: null,
  printCount: null,
  operation: null,
  estimations: {},
  exportControlled: false,
  lastSecureFileCheckoutStatus: null,
};

RunRecordHeader.propTypes = {
  uri: PropTypes.string.isRequired,
  operation: PropTypes.string,
  name: PropTypes.string,
  status: PropTypes.string,
  printCount: PropTypes.number,
  estimations: PropTypes.shape({
    start: PropTypes.string,
    end: PropTypes.string,
  }),
  exportControlled: PropTypes.bool,
  lastSecureFileCheckoutStatus: PropTypes.string,
  estimatesPrintTime: PropTypes.string.isRequired,
};

export default RunRecordHeader;
