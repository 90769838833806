import React from 'react';
import PropTypes from 'prop-types';

import { FormControl, FormGroup, InputGroup, Badge } from 'react-bootstrap';

import { FormattedMessage } from 'rapidfab/i18n';

const roles = ['manager', 'global-user', 'restricted'];

const styles = {
  spacingVertical: { padding: '2rem 0' },
  spacingBottom: { marginBottom: '0.5rem' },
};

const UserRoles = ({
  handleToggle,
  isSessionManager,
  userRoles,
  locations,
  isSelfRegistrationAllowedFeatureEnabled,
  isRoleSaving,
}) => {
  const rolesToShow = [...roles];
  if (isSelfRegistrationAllowedFeatureEnabled) {
    rolesToShow.push('self-registered-guest');
  }
  return (
    <FormGroup style={styles.spacingVertical}>
      <div style={styles.spacingBottom}>
        <Badge>
          <FormattedMessage id="roles" defaultMessage="Roles" />
        </Badge>
      </div>
      {rolesToShow.map(role => (
        <InputGroup key={role.uri} style={styles.spacingBottom}>
          <InputGroup.Text>
            <input
              checked={userRoles.includes(role)}
              disabled={!isSessionManager || isRoleSaving}
              name={role}
              onChange={handleToggle}
              type="checkbox"
            />
          </InputGroup.Text>
          <FormControl type="text" value={role} disabled />
        </InputGroup>
      ))}
      {locations.map(location => (
        <InputGroup key={location.uri} style={styles.spacingBottom}>
          <InputGroup.Text>
            <input
              checked={userRoles.includes(location.uri)}
              disabled={!isSessionManager || isRoleSaving}
              data-location={location.uri}
              name="location-user"
              onChange={handleToggle}
              type="checkbox"
            />
          </InputGroup.Text>
          <FormControl
            type="text"
            value={`Location: ${location.name}`}
            disabled
          />
        </InputGroup>
      ))}
    </FormGroup>
  );
};

UserRoles.propTypes = {
  handleToggle: PropTypes.func.isRequired,
  isSessionManager: PropTypes.bool.isRequired,
  locations: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  userRoles: PropTypes.arrayOf(PropTypes.string).isRequired,
  isSelfRegistrationAllowedFeatureEnabled: PropTypes.bool.isRequired,
  isRoleSaving: PropTypes.bool.isRequired,
};

export default UserRoles;
