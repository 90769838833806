export const EXPORT_CONTROL_LABEL_NAMES = {
  UK_THIRD_PARTY: 'UK Third Party',
  US_THIRD_PARTY: 'US Third Party',
  NO_EXPORT_CONTROL: 'No Export Control',
};

export const EXPORT_CONTROL_PIECE_WARNINGS = {
  [EXPORT_CONTROL_LABEL_NAMES.UK_THIRD_PARTY]: 'This piece is marked as being export controlled specifically for third parties from the UK.',
  [EXPORT_CONTROL_LABEL_NAMES.US_THIRD_PARTY]: 'This piece is marked as being export controlled specifically for third parties from the US.',
};

export const EXPORT_CONTROL_LINE_ITEM_WARNINGS = {
  [EXPORT_CONTROL_LABEL_NAMES.UK_THIRD_PARTY]: 'At least one piece in this line item is export controlled specifically for third parties from the UK.',
  [EXPORT_CONTROL_LABEL_NAMES.US_THIRD_PARTY]: 'At least one piece in this line item is export controlled specifically for third parties from the US.',
};
