import { faCaretRight, faCheck, faClose, faFile } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import _every from 'lodash/every';
import _isEmpty from 'lodash/isEmpty';
import PropTypes from 'prop-types';
import Actions from 'rapidfab/actions';
import FormRow from 'rapidfab/components/FormRow';
import Loading from 'rapidfab/components/Loading';
import FileInput from 'rapidfab/components/records/order/edit/FileInput';
import { ALLOWED_DOCUMENT_EXTENSIONS, API_RESOURCES, COLORS, FILE_EXTENSIONS, PRINTER_FORM_COMMAND_TYPES, RUN_STATUSES } from 'rapidfab/constants';
import { selectInputStyles } from 'rapidfab/constants/styles';
import { useFetchMultipleResources } from 'rapidfab/hooks';
import { FormattedMessage } from 'rapidfab/i18n';
import * as Selectors from 'rapidfab/selectors';
import { getRouteUUIDResource } from 'rapidfab/selectors';
import Alert from 'rapidfab/utils/alert';
import { getFileExtension } from 'rapidfab/utils/fileUtils';
import { extractUuid } from 'rapidfab/utils/uuidUtils';
import React, { useMemo, useState } from 'react';
import { Button, Card, ListGroup, ListGroupItem } from 'react-bootstrap';
import { Form } from 'react-final-form';
import { useDispatch, useSelector } from 'react-redux';
import Select from 'react-select';

/** Renders custom select-option layout for modeler printing presets. */
const formatModelerPrintingPresetOption = ({ name, description }) => (
  <div>
    <p>{name}</p>
    <small>{description}</small>
  </div>
);

const ChecklistItem = ({ text, isChecked = false }) => (
  <ListGroupItem>
    {isChecked ? <FontAwesomeIcon icon={faCheck} color={COLORS.GREEN} className="spacer-right" /> : <FontAwesomeIcon icon={faClose} color={COLORS.RED} className="spacer-right" />}
    {text}
  </ListGroupItem>
);

ChecklistItem.propTypes = {
  text: PropTypes.string.isRequired,
  isChecked: PropTypes.bool.isRequired,
};

/**
 * Slic3r integration; press to print card UI view
 */
const PressToPrintView = ({
  printerData,
}) => {
  const dispatch = useDispatch();

  const run = useSelector(getRouteUUIDResource);
  const { MODELER_PRINTING_PRESET: { responseData: modelerPrintingPresetResponseData } } = useFetchMultipleResources([
    { resource: API_RESOURCES.BUILD_FILE, configuration: [{ run: run?.uri }], method: 'list' },
    { resource: API_RESOURCES.MODELER_PRINTING_PRESET, configuration: [{ modeler: printerData?.modeler }], method: 'list' },
  ]);
  const modelerPrintingPresets = modelerPrintingPresetResponseData?.resources;

  const isUploading = useSelector(state => state.ui.nautilus[API_RESOURCES.BUILD_FILE].post.fetching
    || state.uploadModel.uploading,
  );
  const isDeletingMachineJobFile =
     useSelector(state => state.ui.nautilus[API_RESOURCES.BUILD_FILE].delete.fetching);
  const isSendingCommand =
    useSelector(state => state.ui.nautilus[API_RESOURCES.MODELER_COMMAND].post.fetching);
  const machineJobFile = useSelector(state => Selectors.getPressToPrintMachineJobFile(state, run?.uri))[0];

  const [stagedMachineJobFile, setStagedMachineJobFile] = useState(null);
  const [presetSelectionViewUnlocked, setPresetSelectionViewEnabled] = useState(false);
  const [selectedModelerPrintingPreset, setSelectedModelerPrintingPreset] = useState('');

  const onChangeMachineJobFile = async file => {
    if (!run) return;

    setStagedMachineJobFile(file);

    const payload = {
      name: file?.name,
      run: run.uri,
      format: getFileExtension(file),
    };

    const fileExtension = getFileExtension(file);

    // If file extension of uploaded file is `.zip`, user is required to select a preset before button is unlocked.
    if (fileExtension === FILE_EXTENSIONS.ZIP) {
      setPresetSelectionViewEnabled(true);
    }

    if (machineJobFile.length) {
      // Replace existing uploaded file if it already exists.
      try {
        await dispatch(Actions.UploadModel.upload(machineJobFile?.content, file));
        Alert.success(<FormattedMessage
          id="toaster.jobFile.success.updated"
          defaultMessage="Machine job file has been successfully updated."
        />);
      } catch (error) {
        Alert.error(error);
      }
    } else {
      // Upload new build file.
      try {
        const machineJobFileResponse = await dispatch(Actions.Api.nautilus[API_RESOURCES.BUILD_FILE].post(payload));
        const { uploadLocation } = machineJobFileResponse?.headers;
        await dispatch(Actions.UploadModel.upload(uploadLocation, file));

        Alert.success(<FormattedMessage
          id="toaster.jobFile.success.created"
          defaultMessage="Machine job file has been successfully created."
        />);
      } catch (error) {
        Alert.error(error);
      }
    }
  };

  const onSubmit = async () => {
    if (!run) return;

    const payload = {
      'build-file': machineJobFile?.uri, // TODO: on backend: `build-file` -> `build_file` (underscored);
      run: run.uri,
      modeler: printerData?.modeler,
      type: PRINTER_FORM_COMMAND_TYPES.START_JOB,
      printing_preset: selectedModelerPrintingPreset,
    };

    await dispatch(Actions.Api.nautilus[API_RESOURCES.MODELER_COMMAND].post(payload));
    Alert.success('Start print job command sent.');
  };

  const onDeletePrintJobFile = async () => {
    setStagedMachineJobFile(null);

    if (machineJobFile) {
      await dispatch(Actions.Api.nautilus[API_RESOURCES.BUILD_FILE]
        .delete(extractUuid(machineJobFile?.uri)));
    }
  };

  const isPrinterConnectedToModeler = !!printerData?.modeler;
  const isRunReadyToGo = [RUN_STATUSES.QUEUED_READY, RUN_STATUSES.PAUSED, RUN_STATUSES.CANCELLED].includes(run?.status);
  const isSendCommandButtonDisabled = useMemo(() => {
    let machineJobFileChecked = false;
    // If file extension of uploaded file is `.zip`, user is required to select a printing preset.
    if (machineJobFile?.format === FILE_EXTENSIONS.ZIP) {
      setPresetSelectionViewEnabled(true);
      if (!_isEmpty(selectedModelerPrintingPreset)) {
        machineJobFileChecked = true;
      } else {
        // User hasn't selected a printing preset, disable send command button.
        machineJobFileChecked = false;
      }
    } else {
      // If file extension of uploaded file is not `.zip`
      machineJobFileChecked = true;
      setPresetSelectionViewEnabled(false);
    }

    return _every([machineJobFileChecked, isPrinterConnectedToModeler, isRunReadyToGo], Boolean);
  }, [selectedModelerPrintingPreset, machineJobFile, isPrinterConnectedToModeler, isRunReadyToGo]);

  return (
    <Form
      onSubmit={onSubmit}
    >
      {({ handleSubmit }) => (
        <Card bg="dark" id="press-to-print-view" className="m-b">
          <Card.Header className="pd-exp inverse">
            Press to Print
          </Card.Header>
          <div className="card-body-wrapper">

            <Card.Body>

              <FormRow
                defaultMessage="File"
              >
                <div id="machine-job-file-upload-container" className="d-flex justify-content-between align-items-center">
                  <div>
                    {machineJobFile || stagedMachineJobFile ? (
                      <a
                        href={machineJobFile?.content}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <FontAwesomeIcon icon={faFile} className="spacer-right" />
                        {machineJobFile ? (machineJobFile?.name || 'Machine Job File') : (stagedMachineJobFile?.name || '')}
                      </a>
                    ) : (
                      <i>No file uploaded</i>
                    )}
                    <div className="spacer-bottom">
                      <small>Print job file</small>
                    </div>
                  </div>
                  <div id="upload-container-buttons" className="d-flex align-items-start">
                    {(machineJobFile || stagedMachineJobFile) && (
                      <div className="p-relative m-l-0 mt15">
                        <Button
                          disabled={isDeletingMachineJobFile}
                          variant="danger"
                          className="spacer-right"
                          onClick={onDeletePrintJobFile}
                        >
                          Delete
                        </Button>
                      </div>
                    )}
                    {isUploading ? (
                      <Loading />
                    ) : (
                      <FileInput
                        defaultStyle={false}
                        fileType={FileInput.fileTypes.document}
                        handleFileChange={event => onChangeMachineJobFile(event.target.files[0])}
                        chooseFileLabel="Choose File"
                        acceptedExtensions={[FILE_EXTENSIONS.ZIP, ...ALLOWED_DOCUMENT_EXTENSIONS]}
                        disabled={isUploading}
                      />
                    )}
                  </div>
                </div>
              </FormRow>

              {presetSelectionViewUnlocked && (
                <FormRow
                  defaultMessage="Preset"
                >
                  <Select
                    name="modeler-printing-preset"
                    styles={selectInputStyles}
                    options={modelerPrintingPresets}
                    placeholder="Select a modeler printing preset"
                    formatOptionLabel={formatModelerPrintingPresetOption}
                    getOptionLabel={option => option.name}
                    getOptionValue={option => option.name}
                    onChange={data => setSelectedModelerPrintingPreset(data.name)}
                    disabled={!isPrinterConnectedToModeler}
                    required
                  />
                </FormRow>
              )}

              <hr />

              <FormRow
                defaultMessage="Print"
              >
                <small className="mb15">Print checklist</small>
                <ListGroup id="checklist-container">
                  <ChecklistItem text="Machine job is attached to run" isChecked={!!machineJobFile} />
                  <ChecklistItem text="Printer is connected to a modeler" isChecked={isPrinterConnectedToModeler} />
                  <ChecklistItem text="Run is ready to go" isChecked={isRunReadyToGo} />
                </ListGroup>

              </FormRow>

            </Card.Body>
            <Card.Footer>
              <Button
                disabled={!isSendCommandButtonDisabled || isSendingCommand || isUploading}
                variant="success"
                className="pull-right m-b"
                onClick={handleSubmit}
              >
                <FontAwesomeIcon className="spacer-right" icon={faCaretRight} />
                Start Print Job
              </Button>
            </Card.Footer>
          </div>
        </Card>
      )}
    </Form>
  );
};

export default PressToPrintView;

PressToPrintView.propTypes = {
  printerData: PropTypes.shape({
    modeler: PropTypes.string,
    uri: PropTypes.string,
  }).isRequired,
};
