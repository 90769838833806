function makeConstants(constants) {
  const result = {};
  constants.forEach(constant => {
    result[constant] = constant;
  });
  return result;
}

const Constants = [
  'DOWNLOAD_MODEL_CONTENT',
  'DOWNLOAD_MODEL_REQUEST',
  'DOWNLOAD_MODEL_SUCCESS',
  'DOWNLOAD_MODEL_FAILURE',
  'HASH_CHANGE',
  'LOCALE_CHANGE',
  'EVENT_STREAM_MESSAGE',
  'RESOURCE_ACCESS_INFO_SUCCESS',
  'RESOURCE_CLONE_REQUEST',
  'RESOURCE_CLONE_SUCCESS',
  'RESOURCE_CLONE_FAILURE',
  'RESOURCE_POST_REQUEST',
  'RESOURCE_POST_REQUEST',
  'RESOURCE_POST_SUCCESS',
  'RESOURCE_POST_FAILURE',
  'RESOURCE_REPLACE_REQUEST',
  'RESOURCE_REPLACE_SUCCESS',
  'RESOURCE_REPLACE_FAILURE',
  'RESOURCE_PUT_REQUEST',
  'RESOURCE_PUT_SUCCESS',
  'RESOURCE_PUT_FAILURE',
  'RESOURCE_LIST_REQUEST',
  'RESOURCE_LIST_SUCCESS',
  'RESOURCE_LIST_FAILURE',
  'RESOURCE_GET_REQUEST',
  'RESOURCE_GET_SUCCESS',
  'RESOURCE_GET_FAILURE',
  'RESOURCE_DELETE_REQUEST',
  'RESOURCE_DELETE_SUCCESS',
  'RESOURCE_DELETE_FAILURE',
  'RESOURCE_MANUAL_REMOVE',
  'RESOURCE_MANUAL_CLEAR',
  'RESOURCE_REQUEST_SUPPRESSED',
  'SET_REQUESTED_FILE_LOADER_DATA',
  'UPLOAD_MODEL_STORE_PAYLOAD',
  'UPLOAD_MODEL_CLEAR',
  'UPLOAD_MODEL_PROGRESS',
  'UPLOAD_MODEL_REQUEST',
  'UPLOAD_MODEL_FAILURE',
  'UPLOAD_MODEL_SUCCESS',
  'UPLOAD_MODEL_ADD_ERROR',
  'STORE_LOCATION',
  'STORE_SUB_LOCATION',
  'STORE_TRACEABILITY_REPORT_FILTERS',
  'STORE_SUBMITTED_ORDERS',
  'SET_PAGE',
  'SET_ROUTE_UUID',
  'CLEAR_UI_STATE',
  'CLEAR_UI_ERRORS',
  'UNITS_CHANGE_METRIC',
  'UNITS_CHANGE_IMPERIAL',
  'STORE_CURRENCY',
  'STORE_PREFERENCES_USER_SETTINGS',
  'MODEL_ROTATION',
  'CHANGE_IMPERSONATION_MODAL',
  'STORE_EVENTSTREAM_DISCONNECTED',
  'SET_SHOW_DEBUG_MODE_USER_INFO',
  'FILE_LOADER_REQUEST',
  'FILE_LOADER_PROGRESS',
  'FILE_LOADER_SUCCESS',
  'FILE_LOADER_CLEAR',
  'FILE_LOADER_ADD_ERROR',
  'FILE_LOADER_SET_MODAL_STATE',
  'CREATE_RUN_PROGRESS_START',
  'CREATE_RUN_PROGRESS_RESTART',
  'CREATE_RUN_PROGRESS_PROCESSING',
  'CREATE_RUN_PROGRESS_FINISHING',
  'CREATE_RUN_PROGRESS_SUCCESS',
  'CREATE_RUN_PROGRESS_ADD_ERROR',
  'CREATE_RUN_PROGRESS_CLEAR',
];

// eslint-disable-next-line import/prefer-default-export
export const reduxConstantActions = makeConstants(Constants);
