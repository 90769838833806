import { Container } from 'react-bootstrap';
import PropTypes from 'prop-types';
import React from 'react';
import Order from 'rapidfab/components/service_providers/Order';
import LineItem from 'rapidfab/components/service_providers/LineItem';
import { SERVICE_PROVIDER_JOB_END_STATUSES } from 'rapidfab/constants';
import MappedColumn from 'rapidfab/components/RecordList/MappedColumn';
import { SERVICE_PROVIDER_JOB_MAP } from 'rapidfab/mappings';

const Job = ({ job, order, lineItem, serviceProvider, onModelDownload }) => {
  if (SERVICE_PROVIDER_JOB_END_STATUSES.includes(job.status)) {
    return (
      <Container fluid>
        <div className="jumbotron">
          <h2>You don&apos;t have access to this order anymore</h2>
          <p>
            This Job is in&#8196;
            <MappedColumn
              mapping={SERVICE_PROVIDER_JOB_MAP}
              value={job.status}
            />
            &#8239; status, you no longer have access to view this order.
          </p>
        </div>
      </Container>
    );
  }

  return (
    <Container fluid>
      { order && <Order data={order} /> }
      { lineItem && (
        <LineItem
          lineItem={lineItem}
          job={job}
          serviceProvider={serviceProvider}
          onModelDownload={onModelDownload}
        />
      ) }
    </Container>
  );
};

Job.defaultProps = {
  lineItem: null,
  order: null,
};

Job.propTypes = {
  job: PropTypes.shape({
    status: PropTypes.string,
  }).isRequired,
  serviceProvider: PropTypes.shape({}).isRequired,
  lineItem: PropTypes.shape({}),
  onModelDownload: PropTypes.func.isRequired,
  order: PropTypes.shape({}),
};

export default Job;
