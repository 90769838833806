import _isEmpty from 'lodash/isEmpty';
import ConfirmationModal from 'rapidfab/components/ConfirmationModal';
import PrinterTypeModalContainer from 'rapidfab/containers/modals/PrinterTypeModalContainer';
import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import Actions from 'rapidfab/actions';
import * as Selectors from 'rapidfab/selectors';

import PrinterForm from 'rapidfab/components/records/PrinterForm';
import { extractUuid, getShortUUID } from 'rapidfab/utils/uuidUtils';
import { API_RESOURCES, FEATURES, PAGINATION_IGNORE_DEFAULT_LIMIT, ROUTES } from 'rapidfab/constants';
import { isFeatureEnabled } from 'rapidfab/utils/featureFlagUtils';
import { getRouteURI } from 'rapidfab/utils/uriUtils';
import Alert from 'rapidfab/utils/alert';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import { getSubLocations } from 'rapidfab/selectors';
import { Link, useSearchParams } from 'react-router-dom';

function redirect() {
  window.location.hash = getRouteURI(ROUTES.PRINTERS);
}

function redirectToPrinter(uri) {
  window.location.hash = getRouteURI(ROUTES.PRINTER_EDIT, { uuid: extractUuid(uri) });
}

const PrinterFormContainer = props => {
  const uuid = useSelector(Selectors.getRouteUUID);
  const printer = useSelector(state =>
    ((props.route && props.route.uuid)
      ? Selectors.getRouteUUIDResource(state)
      : null));
  const locations = useSelector(Selectors.getLocationOptions);
  const subLocations = useSelector(getSubLocations);
  const printerTypes = useSelector(Selectors.getSortedPrinterTypesByName);
  const features = useSelector(Selectors.getFeatures);
  const modelersByUris = useSelector(Selectors.getModelersByUri);
  const modelerTypesByUri = useSelector(Selectors.getModelerTypesByUri);
  const subLocationsFetching = useSelector(state => state.ui.nautilus[API_RESOURCES.SUB_LOCATION].list.fetching);
  const printerTypeOnTheFlySubmitting = useSelector(state =>
    state.ui.nautilus[API_RESOURCES.PRINTER_TYPE].post.fetching);
  const printerTypeOnTheFlyFetching = useSelector(state =>
    state.ui.nautilus[API_RESOURCES.PRINTER_TYPE].list.fetching);

  const modeler = useSelector(state => ((printer
    && printer.modeler) ? Selectors.getUUIDResource(state, extractUuid(printer.modeler)) : null));

  const loadedBatch = useSelector(state => Selectors.getLoadedMaterialBatchForPrinter(state, printer));
  const loadedBatchLot =
    useSelector(state =>
      (loadedBatch ? Selectors.getUUIDResource(state, extractUuid(loadedBatch.material_lot)) : null));

  const submitting = useSelector(state => state.ui.nautilus[API_RESOURCES.PRINTER].put.fetching);
  const [searchParams] = useSearchParams();
  const [printerTypeModalVisible, setPrinterTypeModalVisible] = useState(false);

  // Modeler type for this printer.
  const modelerTypeUri = modelersByUris[printer?.modeler]?.type;

  // Modeler data for this printer.
  const transformedModelerData = {
    ...modelersByUris[printer?.modeler],
    type: modelerTypeUri ? modelerTypesByUri[modelerTypeUri] : {},
  };

  if (modelerTypeUri) transformedModelerData.type = modelerTypesByUri[modelerTypeUri];

  const [name, setName] = useState(() => (printer ? printer.name : ''));
  const [printerType, setPrinterType] = useState(() => (printer
    ? printer.printer_type
    : (printerTypes.length ? printerTypes[0].uri : '')));
  const [loading, setLoading] = useState(() => !printer);
  const [location, setLocation] = useState(() => (printer
    ? printer.location
    : (locations.length ? locations[0].uri : '')));
  const [subLocation, setSubLocation] = useState(printer?.sub_location || '');
  const [newPrinterTypeName, setNewPrinterTypeName] = useState(null);
  const [printerTypeOnTheFlyUri, setPrinterTypeOnTheFlyUri] = useState(null);
  const [showWarningRelocationModal, setShowWarningRelocationModal] = useState(false);

  const selected = {
    uuid,
    locations,
    printerTypes,
    features,
    loadedBatch,
    loadedBatchLot,
    modeler,
    printer,
    submitting,
    modelerData: transformedModelerData,
    subLocationsFetching,
    newPrinterTypeName,
    printerTypeOnTheFlySubmitting,
    printerTypeOnTheFlyFetching,
    setPrinterTypeModalVisible,
  };

  const dispatch = useDispatch();

  const onInitialize = (currentUUID, currentFeatures) => {
    const promises = [
      dispatch(Actions.Api.nautilus[API_RESOURCES.LOCATION].list()),
      dispatch(Actions.Api.nautilus[API_RESOURCES.MODELER_TYPE].list()),
      dispatch(Actions.Api.nautilus[API_RESOURCES.PRINTER_TYPE].list({}, { limit: PAGINATION_IGNORE_DEFAULT_LIMIT })),
    ];

    if (currentUUID) {
      promises.push(
        dispatch(Actions.Api.nautilus[API_RESOURCES.PRINTER].get(currentUUID))
          .then(response => {
            const {
              uri,
              modeler: currentModeler,
            } = response.json;
            const isMaterialManagementEnabled = isFeatureEnabled(
              currentFeatures,
              FEATURES.MATERIAL_MANAGEMENT,
            );

            if (currentModeler) {
              dispatch(Actions.Api.nautilus[API_RESOURCES.MODELER].get(extractUuid(currentModeler)));
            }

            if (isMaterialManagementEnabled) {
              dispatch(Actions.Api.nautilus[API_RESOURCES.MATERIAL_BATCH].list({ at_machine: uri }))
                .then(batchResponse => {
                  const { resources } = batchResponse.json;
                  const lotUri = resources[0] && resources[0].material_lot;
                  if (lotUri) {
                    dispatch(Actions.Api.nautilus[API_RESOURCES.MATERIAL_LOT].get(extractUuid(lotUri)));
                  }
                });
            }
          }),
      );
    }
    return Promise.all(promises);
  };
  const onDelete = currentUUID =>
    dispatch(Actions.Api.nautilus[API_RESOURCES.PRINTER].delete(currentUUID));

  const loadSubLocations = async locationUri => {
    dispatch(Actions.Api.nautilus[API_RESOURCES.SUB_LOCATION].clear('list'));
    dispatch(Actions.Api.nautilus[API_RESOURCES.SUB_LOCATION].list({ location: locationUri }));
  };

  const onSubmit = (currentUUID, payload) => (
    currentUUID
      ? dispatch(Actions.Api.nautilus[API_RESOURCES.PRINTER].put(currentUUID, payload))
        .then(() => {
          // Last Updated (user/date) info is set on the backend, and there is no
          // event-stream event for workflows. So GET request is required right
          // after PUT
          dispatch(Actions.Api.nautilus[API_RESOURCES.PRINTER].get(currentUUID));
        })
      : dispatch(Actions.Api.nautilus[API_RESOURCES.PRINTER].post(payload))
  );
  const onModelerSubmit = (currentUUID, modelerUri) => Promise.all([
    // modelerUri can be empty in case user deleted a modeler
    dispatch(Actions.Api.nautilus[API_RESOURCES.PRINTER].put(currentUUID, { modeler: modelerUri })),
    // Loading modeler only if uri is set
    modelerUri && dispatch(Actions.Api.nautilus[API_RESOURCES.MODELER].get(modelerUri)),
  ]);

  useEffect(() => {
    (async () => {
      await onInitialize(uuid, features);
      setLoading(false);
    })();
  }, [uuid]);

  useEffect(() => {
    if (location) {
      loadSubLocations(location);
    }
  }, [location]);

  useEffect(() => {
    const queryPrinterTypeUri = searchParams.get('printerType');
    if (printerTypeModalVisible) return;
    if (printer) {
      setName(printer.name);
      setPrinterType(printerTypeOnTheFlyUri || printer.printer_type || queryPrinterTypeUri);
      setLocation(printer.location);
    } else {
      setPrinterType(printerTypeOnTheFlyUri || queryPrinterTypeUri || printerTypes[0]?.uri);
      setLocation(locations[0]?.uri);
    }
  }, [printer, JSON.stringify(locations), JSON.stringify(printerTypes)]);

  useEffect(() => {
    const printerSubLocationUri = printer ?
      subLocations.find(subLocation => subLocation.uri === printer.sub_location)?.uri : null;
    setSubLocation(printerSubLocationUri || subLocations[0]?.uri || '');
  }, [printer, JSON.stringify(subLocations)]);

  const handleDelete = () => {
    onDelete(uuid)
      .then(() => Alert.success(
        <FormattedMessage
          id="toaster.printer.deleted"
          defaultMessage="Printer {uuid} successfully deleted."
          values={{ uuid }}
        />,
      ))
      .finally(redirect);
  };

  const handleInputChange = event => {
    // eslint-disable-next-line no-shadow
    const {
      value,
      name,
    } = event.target;
    switch (name) {
      case 'name':
        setName(value);
        break;
      case 'location':
        setLocation(value);
        break;
      case 'sub_location':
        setSubLocation(value);
        break;
      default:
        break;
    }
  };

  const handleChangePrinterType = value => setPrinterType(value);

  const handleSubmit = event => {
    event.preventDefault();

    const payload = {
      name,
      printer_type: printerType,
      location,
      sub_location: subLocation,
    };

    if (!_isEmpty(loadedBatch)) {
      return setShowWarningRelocationModal(true);
    }

    return onSubmit(uuid, payload)
      .then(printerResponse => {
        if (uuid) {
          Alert.success(<FormattedMessage
            id="toaster.printer.updated"
            defaultMessage="Printer successfully updated"
          />);
        } else {
          const { uri } = printerResponse.payload;
          redirectToPrinter(uri);
          Alert.success(<FormattedMessage
            id="toaster.printer.created"
            defaultMessage="Printer successfully created"
          />);
        }
      });
  };

  const setModelerUri = modelerUri => {
    if (printer && printer.modeler === modelerUri) {
      // Modeler is already set
      return Promise.resolve();
    }
    return onModelerSubmit(uuid, modelerUri);
  };

  return (
    <>
      <PrinterForm
        name={name}
        location={location}
        subLocation={subLocation}
        subLocations={subLocations}
        loading={loading}
        printerType={printerType}
        {...props}
        {...selected}
        handleDelete={handleDelete}
        handleInputChange={handleInputChange}
        handleChangePrinterType={handleChangePrinterType}
        setNewPrinterTypeName={setNewPrinterTypeName}
        handleSubmit={handleSubmit}
        setModelerUri={setModelerUri}
      />
      {(newPrinterTypeName || printerTypeModalVisible) && (
        <PrinterTypeModalContainer
          isVisible={newPrinterTypeName || printerTypeModalVisible}
          externalUUID={printerType && extractUuid(printerType)}
          createRecordOnTheFlyProps={printerTypeModalVisible ?
            null :
            {
              name: newPrinterTypeName,
              confirmOnTheFlyCreation: setPrinterTypeOnTheFlyUri,
            }}
          hideModal={printerTypeModalVisible ?
            () => setPrinterTypeModalVisible(false) :
            () => setNewPrinterTypeName(null)}
        />
      )}
      {showWarningRelocationModal && (
        <ConfirmationModal
          handleConfirm={() => setShowWarningRelocationModal(false)}
          confirmButtonContent="Close"
          customConfirmVariant="primary"
          message={(
            <span>
              This printer has a material batch{' '}
              <Link
                to={getRouteURI(
                  ROUTES.MATERIAL_BATCH,
                  { uuid: loadedBatch.uuid },
                  {}, true)}
              >
                {getShortUUID(loadedBatch.uuid)}
              </Link>{' '}
              loaded. Please unload the material batch before relocating the printer.
            </span>
          )}
        />
      )}
    </>
  );
};

PrinterFormContainer.propTypes = {
  route: PropTypes.shape({
    uuid: PropTypes.string,
  }).isRequired,
};

export default PrinterFormContainer;
