import { Col, Row } from 'react-bootstrap';
import React from 'react';
import { ORDER_STATUS_MAP } from 'rapidfab/mappings';
import DataRow from 'rapidfab/components/records/order/DataRow';
import { orderType } from 'rapidfab/types';

const Order = ({ data }) => (
  <div className="jumbotron">
    <h2>Order {data.name}</h2>
    <hr />
    <Row>
      <Col xs={12}>
        <DataRow id="field.name" defaultMessage="Name">
          {data.name || '-'}
        </DataRow>
        <DataRow id="field.bureau" defaultMessage="Bureau">
          {data.bureau.name || '-'}
        </DataRow>
        <DataRow id="field.status" defaultMessage="Status">
          {(ORDER_STATUS_MAP[data.status] &&
            ORDER_STATUS_MAP[data.status].defaultMessage) ||
            '-'}
        </DataRow>
        <DataRow id="field.owner" defaultMessage="Owner">
          {data.owner.name ? `${data.owner.name} (${data.owner.email})` : '-'}
        </DataRow>
        <DataRow id="field.shippingAddress" defaultMessage="Shipping Address">
          {data.shipping.address || '-'}
        </DataRow>
        <DataRow id="field.shippingName" defaultMessage="Shipping Name">
          {data.shipping.name || '-'}
        </DataRow>
        <DataRow id="field.trackingNumber" defaultMessage="Shipping/Tracking #">
          {data.shipping.tracking || '-'}
        </DataRow>
      </Col>
    </Row>
  </div>
);

Order.propTypes = {
  data: orderType.isRequired,
};

export default Order;
