import Constants from 'rapidfab/constants';

/**
 * Sets state on debug mode view to display user info.
 */
export const debugModeUserInfo = payload => ({
  type: Constants.SET_SHOW_DEBUG_MODE_USER_INFO,
  payload,
});

export const setShowDebugModeUserInfo = value => dispatch => {
  dispatch(debugModeUserInfo(value));
};

export default debugModeUserInfo;
