import React from 'react';
import { OverlayTrigger, Popover, Button } from 'react-bootstrap';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import PropTypes from 'prop-types';
import Alert from 'rapidfab/utils/alert';

import { useSelector } from 'react-redux';
import * as Selectors from 'rapidfab/selectors';
import { useIntl } from 'react-intl';

const DebugModeFeature = ({ children, curl }) => {
  const intl = useIntl();
  const isDebugModeEnabled = useSelector(Selectors.getIsDebugModeEnabled);
  const link = children?.props?.href || children?.props?.to;
  const URL = curl || (link
    ? `${window.location.origin}${link}`
    : window.location.href);
  const shortened = `${URL.slice(0, 30)}...`;

  return isDebugModeEnabled
    ? (
      <div className="d-flex align-items-center justify-content-between">
        {children}
        <OverlayTrigger
          id="debug-mode-overlay"
          trigger="click"
          placement="bottom"
          rootClose
          overlay={(
            <Popover id="debug-mode-popover">
              <Popover.Header as="h3">Copy CURL</Popover.Header>
              <Popover.Body>
                <CopyToClipboard
                  text={URL}
                  onCopy={() => {
                    Alert.success(intl.formatMessage(
                      { id: 'toaster.permalinkCopied', defaultMessage: 'Permalink is copied!' },
                    ));
                  }}
                >
                  <span style={{ cursor: 'pointer' }}>{shortened}</span>
                </CopyToClipboard>
              </Popover.Body>
            </Popover>
          )}
        >
          <Button
            className="p-a-0"
            type="button"
            size="sm"
            variant="link"
          >
            (g)
          </Button>
        </OverlayTrigger>
      </div>
    )
    : children;
};

DebugModeFeature.propTypes = {
  children: PropTypes.node.isRequired,
  curl: PropTypes.string,
};

DebugModeFeature.defaultProps = {
  curl: null,
};

export default DebugModeFeature;
