import Constants from 'rapidfab/constants';

const initialState = {
  rotation: { theta_x: null, theta_y: null, theta_z: null },
};

function reducer(state = initialState, action) {
  switch (action.type) {
    case Constants.MODEL_ROTATION:
      return { ...state, [action.uuid]: { ...action.rotation } };
    default:
      return state;
  }
}

export default reducer;
