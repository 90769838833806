import Actions from 'rapidfab/actions';
import ForgotPasswordForm from 'rapidfab/components/ForgotPasswordForm';
import LoginWrapper from 'rapidfab/components/LoginWrapper';
import { API_RESOURCES } from 'rapidfab/constants';
import Alert from 'rapidfab/utils/alert-new';
import { censorEmail } from 'rapidfab/utils/stringUtils';
import React, { useState } from 'react';
import { useDispatch } from 'react-redux';

const ForgotPasswordContainer = () => {
  const [isLoading, setIsLoading] = useState(false);

  const dispatch = useDispatch();

  const onResetEmailSubmit = email => dispatch(Actions.Api.nautilus[API_RESOURCES.PASSWORD_RESETS]
    .post({ user: email }));

  const [resetPasswordConfirmationState, setResetPasswordConfirmationState] = useState({
    confirmationSent: false,
    displayEmail: null,
  });

  const onResetPasswordSubmitForm = formValues => {
    const { email } = formValues;
    setIsLoading(true);
    onResetEmailSubmit(email)
      .then(() => {
        Alert.success('We sent an email to you with instructions to reset your password.');
      })
      .catch(error => {
        setIsLoading(false);
        setResetPasswordConfirmationState({ ...resetPasswordConfirmationState, confirmationSent: false });
        Alert.error(error);
      })
      .finally(() => {
        setIsLoading(false);
        setResetPasswordConfirmationState({ confirmationSent: true, displayEmail: censorEmail(email) });
      });
  };

  return (
    <LoginWrapper
      mainText={resetPasswordConfirmationState.confirmationSent
        ? 'Check your email'
        : 'Forgot Password'}
      subText={resetPasswordConfirmationState.confirmationSent
        ? `A password reset link has been sent to ${resetPasswordConfirmationState.displayEmail}.`
        : "Please type your email address below, and we'll send you a link to reset your password."}
    >
      <ForgotPasswordForm
        resetConfirmationStateProps={{
          resetPasswordConfirmationState,
          setResetPasswordConfirmationState,
        }}
        onResetPasswordSubmitForm={onResetPasswordSubmitForm}
        isResetPasswordLoading={isLoading}
      />
    </LoginWrapper>
  );
};

export default ForgotPasswordContainer;
