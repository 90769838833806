import React, { memo } from 'react';
import PropTypes from 'prop-types';
import { Col, ListGroup, ListGroupItem, OverlayTrigger, Card, Row, Tooltip } from 'react-bootstrap';

import {
  FormattedMessage,
  FormattedVolume,
} from 'rapidfab/i18n';

import { hhmmss } from 'rapidfab/utils/timeUtils';
import FormattedLocalizedCost from 'rapidfab/components/FormattedLocalizedCost';
import { MATERIAL_UNITS } from 'rapidfab/constants';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { convertVolumeToOtherUnit } from 'rapidfab/utils/mathUtils';
import UseNonMfgLanguageFeature from 'rapidfab/components/generalMfgLanguage/UseNonMfgLanguageFeature';
import { faQuestionCircle } from '@fortawesome/free-solid-svg-icons';

const OrderEstimates = ({
  totalPrintingCost,
  totalMaterialCost,
  totalModelsVolumeMm,
  bureauCustom,
  postProcessing,
  printingTime,
  shippingAmount,
  support,
  use100Infill,
  hideFinancial,
}) => {
  // Overlay callback is needed since tooltip has no access to intl

  const overlayCallback = tootltipContent => (
    <OverlayTrigger
      placement="top"
      overlay={(
        <Tooltip id="automaticOrderingTooltip">
          {tootltipContent}
        </Tooltip>
      )}
    >
      <FontAwesomeIcon icon={faQuestionCircle} className="spacer-left" />
    </OverlayTrigger>
  );

  return (
    <>
      <Card bg="dark" border="info" className="mb15">
        <ListGroup fill>
          <ListGroupItem key="header">
            <b>
              <FormattedMessage
                id="processingEstimate"
                defaultMessage="Processing Estimate"
              />
            </b>
          </ListGroupItem>

          <ListGroupItem>
            <Row>
              <Col xs={8}>
                <UseNonMfgLanguageFeature
                  mfgLanguageComponent={(
                    <FormattedMessage
                      id="printTime"
                      defaultMessage="Print Time"
                    />
                  )}
                  nonMfgLanguageComponent={(
                    <FormattedMessage
                      id="mfg.printTime.primaryProductionTime"
                      defaultMessage="Primary Production Time"
                    />
                  )}
                />
              </Col>
              <Col xs={4}>
                {printingTime === null ? (
                  <FormattedMessage id="notAvailable" defaultMessage="N/A" />
                ) : (
                  <span>{hhmmss(printingTime)}</span>
                )}
              </Col>
            </Row>
          </ListGroupItem>

          <ListGroupItem>
            <Row>
              <Col xs={8}>
                <FormattedMessage
                  id="materialUsed"
                  defaultMessage="Material Used"
                />
                <FormattedMessage
                  id={
                    use100Infill
                      ? 'materialUsed100InfillTooltip'
                      : 'materialUsedCustomInfillTooltip'
                  }
                  defaultMessage={
                    use100Infill
                      ? 'Material used is estimated based on 100% infill. The model\'s total volume is {formattedVolume}.'
                      : 'Material used is estimated based on proprietary infill estimation algorithm (generally less than 100% infill). The model\'s total volume is {formattedVolume}.'
                  }
                  values={{
                  // TODO: looks like html tags are not supported here,
                  // so no way to use <FormattedVolume> component here
                    formattedVolume:
                    `${convertVolumeToOtherUnit(totalModelsVolumeMm, MATERIAL_UNITS.MM3, MATERIAL_UNITS.CM3)} cm³`,
                  }}
                >
                  {text => overlayCallback(text)}
                </FormattedMessage>
              </Col>
              <Col xs={4}>
                {totalModelsVolumeMm ? (
                  <FormattedVolume
                    value={totalModelsVolumeMm}
                    valueUnits={MATERIAL_UNITS.MM3}
                  />
                ) : (
                  <FormattedMessage id="notAvailable" defaultMessage="N/A" />
                )}
              </Col>
            </Row>
          </ListGroupItem>

          <ListGroupItem>
            <Row>
              <Col xs={8}>
                <FormattedMessage
                  id="supportUsed"
                  defaultMessage="Support Used"
                />
              </Col>
              <Col xs={4}>
                {support === null ? (
                  <FormattedMessage id="notAvailable" defaultMessage="N/A" />
                ) : (
                  <FormattedVolume
                    value={support}
                    valueUnits={
                    // `order.estimates.materials.support` is in cm3
                    // anyway, looks like this property is not used at least from 2017
                      MATERIAL_UNITS.CM3
                    }
                  />
                )}
              </Col>
            </Row>
          </ListGroupItem>
        </ListGroup>
      </Card>
      {!hideFinancial && (
        <Card bg="dark" border="info" className="mb15">
          <ListGroup fill>
            <ListGroupItem key="header">
              <b>
                <FormattedMessage
                  id="costEstimate"
                  defaultMessage="Cost Estimate"
                />
              </b>
            </ListGroupItem>
            <ListGroupItem>
              <Row>
                <Col xs={8}>
                  <UseNonMfgLanguageFeature
                    mfgLanguageComponent={(
                      <FormattedMessage
                        id="estimates.printingCost"
                        defaultMessage="Printing Cost"
                      />
                    )}
                    nonMfgLanguageComponent={(
                      <FormattedMessage
                        id="mfg.printingCost.primaryProductionCost"
                        defaultMessage="Primary Production Cost"
                      />
                    )}
                  />
                </Col>
                <Col xs={4}>
                  {totalPrintingCost === null ? (
                    <FormattedMessage id="notAvailable" defaultMessage="N/A" />
                  ) : (
                    <FormattedLocalizedCost
                      value={totalPrintingCost}
                    />
                  )}
                </Col>
              </Row>
            </ListGroupItem>
            <ListGroupItem>
              <Row>
                <Col xs={8}>
                  <FormattedMessage
                    id="estimates.postProcessingCost"
                    defaultMessage="Post-Processing Cost"
                  />
                </Col>
                <Col xs={4}>
                  {postProcessing === null ? (
                    <FormattedMessage id="notAvailable" defaultMessage="N/A" />
                  ) : (
                    <FormattedLocalizedCost value={postProcessing} />
                  )}
                </Col>
              </Row>
            </ListGroupItem>

            <ListGroupItem>
              <Row>
                <Col xs={8}>
                  <FormattedMessage
                    id="estimates.shippingCost"
                    defaultMessage="Shipping Cost"
                  />
                </Col>
                <Col xs={4}>
                  {shippingAmount === null ? (
                    <FormattedMessage id="notAvailable" defaultMessage="N/A" />
                  ) : (
                    <FormattedLocalizedCost value={shippingAmount} />
                  )}
                </Col>
              </Row>
            </ListGroupItem>

            <ListGroupItem>
              <Row>
                <Col xs={8}>
                  <FormattedMessage
                    id="materialCost"
                    defaultMessage="Material Cost"
                  />
                </Col>
                <Col xs={4}>
                  {totalMaterialCost === null ? (
                    <FormattedMessage id="notAvailable" defaultMessage="N/A" />
                  ) : (
                    <FormattedLocalizedCost value={totalMaterialCost} />
                  )}
                </Col>
              </Row>
            </ListGroupItem>
            <ListGroupItem>
              <Row>
                <Col xs={8}>
                  <FormattedMessage
                    id="totalCost"
                    defaultMessage="Total Cost"
                  />
                </Col>
                <Col xs={4}>
                  {bureauCustom ? (
                    <FormattedLocalizedCost value={shippingAmount ? bureauCustom + shippingAmount : bureauCustom} />
                  ) : (
                    <FormattedMessage id="notAvailable" defaultMessage="N/A" />
                  )}
                </Col>
              </Row>
            </ListGroupItem>
          </ListGroup>
        </Card>
      )}
    </>
  );
};

OrderEstimates.defaultProps = {
  totalPrintingCost: null,
  totalMaterialCost: null,
  totalModelsVolumeMm: null,
  bureauCustom: null,
  postProcessing: null,
  printingTime: null,
  shippingAmount: null,
  support: null,
};

OrderEstimates.propTypes = {
  totalPrintingCost: PropTypes.number,
  totalMaterialCost: PropTypes.number,
  totalModelsVolumeMm: PropTypes.number,
  bureauCustom: PropTypes.number,
  postProcessing: PropTypes.number,
  printingTime: PropTypes.number,
  shippingAmount: PropTypes.number,
  support: PropTypes.number,
  use100Infill: PropTypes.bool.isRequired,
  hideFinancial: PropTypes.bool.isRequired,
};

export default memo(OrderEstimates);
