import React from 'react';

const AdditionalURLErrorMessage = fieldProps => {
  const { meta, input } = fieldProps;
  return (
    (meta.touched && meta.invalid && input.value !== '') && <div className="text-start text-danger center">Please use http://www.example.com format above</div>
  );
};

export default AdditionalURLErrorMessage;
