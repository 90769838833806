import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import * as ReactIntl from 'react-intl';
import daMessages from 'rapidfab/i18n/danish';
import deMessages from 'rapidfab/i18n/deutsch';
import enUSMessages from 'rapidfab/i18n/english';
import jaMessages from 'rapidfab/i18n/japanese';
import frMessages from 'rapidfab/i18n/french';
import { MODEL_UNITS, MATERIAL_UNITS, UNITS } from 'rapidfab/constants';
import { convertVolumeToOtherUnit, convertLengthToOtherUnit } from 'rapidfab/utils/mathUtils';
import { useDateLocale } from 'rapidfab/hooks/useDateLocale';
import _round from 'lodash/round';
import { hhmmss } from 'rapidfab/utils/timeUtils';
import dayjs from 'dayjs';

export const { FormattedTime } = ReactIntl;
/**
 * @param {Object} props
 * @param {string} props.value - Value to format
 * @param {string} props.template - Format of date string
 * @param {string} props.tz - Timezone to display the date in
 *
 * NOTE: By default, the date is displayed in UTC. This contrasts FormattedDateTime.
 */
export const FormattedDate = ({ value, template, tz = 'UTC' }) => {
  const { formattedDate } = useDateLocale(tz);
  if (!value) return 'N/A';
  return template ? dayjs(value).tz(tz).format(template) : formattedDate(value);
};

export const { FormattedMessage } = ReactIntl;
export const { FormattedNumber } = ReactIntl;

export const FormattedMessageMappingOption = ({ mapping, value }) => {
  if (value in mapping) {
    const mappedData = mapping[value];

    return (
      <FormattedMessage id={mappedData.id} defaultMessage={mappedData.defaultMessage}>
        {text =>
          <option key={value} value={value}>{text}</option>}
      </FormattedMessage>
    );
  }

  return <option key={value} value={value}>{value}</option>;
};

FormattedMessageMappingOption.propTypes = {
  mapping: PropTypes.objectOf(PropTypes.shape({
    id: PropTypes.string.isRequired,
    defaultMessage: PropTypes.string.isRequired,
  })).isRequired,
  value: PropTypes.string.isRequired,
};

/**
 * @param {Object} props
 * @param {string} props.value - Value to format
 * @param {string} props.template - Format of date string
 * @param {string} props.tz - Timezone to display the date in
 *
 * NOTE: By default, the date is displayed in users locale TZ. This contrasts FormattedDate.
 */
export const FormattedDateTime = ({ value, template, tz }) => {
  const { formattedDateTime } = useDateLocale(tz);
  if (!value) return 'N/A';
  return template ? dayjs(value).tz(tz).format(template) : formattedDateTime(value);
};

FormattedDateTime.propTypes = {
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  local: PropTypes.bool,
};

export const FORMATTED_DURATION_TYPES = {
  HOURS: 'hours',
  DAYS: 'days',
  NO_FORMATTING: 'none',
  HH_MM_SS: 'hh:mm:ss',
};

export const FormattedOptionalDuration = ({ value, intervalFormat }) => {
  if (!value) {
    return <FormattedMessage id="notAvailable" defaultMessage="N/A" />;
  }

  switch (intervalFormat) {
    case FORMATTED_DURATION_TYPES.HOURS:
      return Math.round(dayjs.duration(value, 'seconds').asHours());
    case FORMATTED_DURATION_TYPES.DAYS:
      return Math.round(dayjs.duration(value, 'seconds').asDays());
    case FORMATTED_DURATION_TYPES.HH_MM_SS:
      return hhmmss(value);
    default:
      return value;
  }
};

FormattedOptionalDuration.propTypes = {
  // Value is in seconds
  value: PropTypes.number,
  intervalFormat: PropTypes.string.isRequired,
};

FormattedOptionalDuration.defaultProps = {
  value: null,
};

export const FormattedLength = connect(({ units }) => ({ units }))(
  ({ length, lengthUnits, roundPrecision, units }) => (
    <span>
      <FormattedNumber
        value={
          _round(
            convertLengthToOtherUnit(
              length,
              lengthUnits,
              (
                units === UNITS.metric
                  ? MODEL_UNITS.MM
                  : MODEL_UNITS.INCHES
              ),
            ),
            roundPrecision,
          )
        }
      />
      {' '}
      {units === UNITS.metric ? 'mm' : 'in'}
    </span>
  ),
);

FormattedLength.propTypes = {
  length: PropTypes.number.isRequired,
  lengthUnits: PropTypes.oneOf(Object.values(MODEL_UNITS)).isRequired,
  roundPrecision: PropTypes.number,
};

FormattedLength.defaultProps = {
  roundPrecision: 0,
};

export const FormattedVolume = connect(({ units }) => ({ units }))(
  ({ value, valueUnits, units }) => (
    <span>
      <FormattedNumber
        value={
          convertVolumeToOtherUnit(
            value,
            valueUnits,
            (
              units === UNITS.metric
                ? MATERIAL_UNITS.CM3
                : MATERIAL_UNITS.INCH3
            ),
          )
        }
      />
      {' '}
      {units === UNITS.metric ? 'cm' : 'in'}
      <sup>3</sup>
    </span>
  ),
);

FormattedVolume.propTypes = {
  value: PropTypes.number.isRequired,
  valueUnits: PropTypes.string.isRequired,
};

export const FormattedDuration = ({ value }) => (
  <span>
    <FormattedNumber value={value} /> <abbr title="Seconds">s</abbr>
  </span>
);

FormattedDuration.propTypes = {
  value: PropTypes.number.isRequired,
};

export const FormattedCost = ({ currency, value }) => (
  // eslint-disable-next-line react/style-prop-object
  <FormattedNumber style="currency" currency={currency} value={value} />
);

FormattedCost.propTypes = {
  value: PropTypes.number.isRequired,
  currency: PropTypes.string.isRequired,
};

export const FormattedCostRange = ({ currency, values }) => {
  if (!values) return 'N/A';

  const { start, end } = values;

  return (

    <div>
      <span>
        <FormattedNumber
          // eslint-disable-next-line react/style-prop-object
          style="currency"
          currency={currency}
          value={start}
          maximumFractionDigits={0}
        />
      </span>-
      <span>
        <FormattedNumber
          currency={currency}
          value={end}
          maximumFractionDigits={0}
        />
      </span>
    </div>

  );
};

FormattedCostRange.propTypes = {
  values: PropTypes.instanceOf(Object).isRequired,
  currency: PropTypes.string.isRequired,
};

export default {
  da: daMessages,
  de: deMessages,
  'en-US': enUSMessages,
  ja: jaMessages,
  fr: frMessages,
};
