import dayjs from 'dayjs';
// import utc from 'dayjs/plugin/utc';
import { createSelector } from 'reselect';
import _sortBy from 'lodash/sortBy';
import _map from 'lodash/map';
import _keyBy from 'lodash/keyBy';
import * as baseStateSelectors from './baseStateSelectors';
import { getStateResources } from './helpers/base';

// eslint-disable-next-line import/prefer-default-export
export const getOrderQuotes = createSelector(
  [baseStateSelectors.getStateOrderQuotes, getStateResources],
  (uuids, resources) => _sortBy(
    uuids.map(uuid => resources[uuid]), orderQuote => dayjs(orderQuote.created).local(),
  ),
);

export const getLineItemWorkstepEstimates = createSelector(
  [baseStateSelectors.getStateLineItemWorkstepEstimate, getStateResources],
  (uuids, resources) => _map(uuids, uuid => resources[uuid]),
);

export const getLineItemWorkstepCostEstimates = createSelector(
  [baseStateSelectors.getStateLineItemWorkstepCostEstimate, getStateResources],
  (uuids, resources) => _map(uuids, uuid => resources[uuid]),
);

export const getLineItemWorkstepEstimatesByUri = createSelector([getLineItemWorkstepEstimates], workstep =>
  _keyBy(workstep, 'process_step'),
);
