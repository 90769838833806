import Constants, { CREATE_RUN_EVENT_STREAM_STATUSES, EVENT_STREAM_SELECTOR_RESOURCES } from 'rapidfab/constants';

const initialState = {
  disconnected: false,
};

function reducer(state = initialState, action) {
  switch (action.type) {
    case Constants.EVENT_STREAM_MESSAGE:
      if (action.topic.includes(EVENT_STREAM_SELECTOR_RESOURCES.MSNAV)) {
        return { ...state, [EVENT_STREAM_SELECTOR_RESOURCES.MSNAV]: action };
      }

      if (action.topic.includes(EVENT_STREAM_SELECTOR_RESOURCES.BUILD_FILE)) {
        return { ...state, build_file: action };
      }

      if (action.topic.includes(CREATE_RUN_EVENT_STREAM_STATUSES.RUN) ||
        action.topic.includes(CREATE_RUN_EVENT_STREAM_STATUSES.CREATE_RUNS)) {
        return { ...state, create_run_updates: action };
      }

      if (action.topic.includes(EVENT_STREAM_SELECTOR_RESOURCES.PAYMENT)) {
        return { ...state, [EVENT_STREAM_SELECTOR_RESOURCES.PAYMENT]: action };
      }
      if (action.topic.includes(EVENT_STREAM_SELECTOR_RESOURCES.SLICER)) {
        return { ...state, [EVENT_STREAM_SELECTOR_RESOURCES.SLICER]: action };
      }

      return state;
    case Constants.STORE_EVENTSTREAM_DISCONNECTED:
      return { ...state, disconnected: action.payload };
    default:
      return state;
  }
}

export default reducer;
