import { faBan, faMinusCircle, faPlusCircle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import _capitalize from 'lodash/capitalize';
import _findIndex from 'lodash/findIndex';
import _isEmpty from 'lodash/isEmpty';
import _map from 'lodash/map';
import _uniqueId from 'lodash/uniqueId';
import PropTypes from 'prop-types';
import BreadcrumbNav from 'rapidfab/components/BreadcrumbNav';
import Loading from 'rapidfab/components/Loading';
import JeniClusterCommandView from 'rapidfab/components/records/JeniClusterCommandView';
import WorkflowContainer from 'rapidfab/containers/records/WorkflowContainer';
import { FormattedMessage } from 'rapidfab/i18n';
import Alert from 'rapidfab/utils/alert';
import { extractUuid, getShortUUID } from 'rapidfab/utils/uuidUtils';
import React, { forwardRef, useEffect, useImperativeHandle, useRef, useState } from 'react';
import { Badge, ButtonToolbar, Card, Col, Container, Dropdown, FormControl, FormGroup, FormLabel, Row, SplitButton } from 'react-bootstrap';
import { Field, Form } from 'react-final-form';

const JeniClusterPostProcessorTypes = ({
  stateProps: [
    pptState,
    setPptState,
    pptStateValidationErrors,
  ],
  postProcessorTypes,
}) => {
  const handleAddNewPptStateItem = () => {
    if (pptState.length >= 5) {
      Alert.error('You can only add a maximum of 5 post processor types.');
    } else {
      const newPostProcessorStateItem = {
        id: _uniqueId('ppt_'),
        postProcessorType: null,
        count: 0,
      };

      setPptState([...pptState, newPostProcessorStateItem]);
    }
  };

  const handleRemovePptStateItem = idToRemove => {
    if (pptState.length <= 1) {
      // Users shouldn't be able to get to this stage anyway, since the button will be disabled.
      Alert.error('You cannot remove the last post processor type.');
    } else {
      setPptState(
        [...pptState].filter(pptStateItem => pptStateItem.id !== idToRemove));
    }
  };

  const handleUpdatePptField = (event, fieldKey, id) => {
    const clonedPptState = [...pptState];
    const updatedStateItemIndex = _findIndex(clonedPptState, { id });

    const payload = {
      ...clonedPptState[updatedStateItemIndex],
      [fieldKey]: event.target.value,
    };

    clonedPptState[updatedStateItemIndex] = payload;

    setPptState(clonedPptState);
  };

  return (
    <Card bg="dark">
      <Card.Header className="pd-exp inverse">
        Post Processor Types
      </Card.Header>
      <div className="card-body-wrapper">
        <Card.Body>
          {pptState.map(pptStateItem => (
            <Row>
              <Col xs={2} style={{ alignSelf: 'center' }}>
                <div className="d-flex justify-content-start">
                  <FontAwesomeIcon onClick={handleAddNewPptStateItem} role="button" size="lg" icon={faPlusCircle} />
                  {pptState.length > 1 && (
                    <FontAwesomeIcon
                      onClick={() => handleRemovePptStateItem(pptStateItem.id)}
                      className="spacer-left"
                      role="button"
                      size="lg"
                      icon={faMinusCircle}
                      disabled={pptState.length <= 1}
                    />
                  )}
                </div>
              </Col>
              <Col xs={6}>
                <FormGroup className="form-group" controlId={`uxPostProcessorType-${pptStateItem.id}`}>
                  <FormLabel>
                    Post Processor Type:
                  </FormLabel>
                  <FormControl
                    name="postProcessorType"
                    onChange={event => handleUpdatePptField(event, 'postProcessorType', pptStateItem.id)}
                    required
                    value={pptStateItem.postProcessorType || ''}
                    as="select"
                  >
                    {[
                      <option style={{ fontStyle: 'italic' }} key="placeholder" value="">
                        No post processor type selected
                      </option>,
                      ..._map(postProcessorTypes, pptItem => (
                        <option
                          id={extractUuid(pptItem.uri)}
                          value={pptItem.uri}
                        >
                          {pptItem.name}
                        </option>
                      ))]}
                  </FormControl>
                </FormGroup>
              </Col>
              <Col xs={4}>
                <FormGroup className="form-group" controlId={`uxPostProcessorTypeCount-${pptStateItem.id}`}>
                  <FormLabel>
                    Count:
                  </FormLabel>
                  <FormControl
                    name="count"
                    onChange={event => handleUpdatePptField(event, 'count', pptStateItem.id)}
                    required
                    value={pptStateItem.count}
                    min={1}
                    max={99}
                    type="number"
                  />
                </FormGroup>
              </Col>
              {pptStateValidationErrors?.[pptStateItem.id] &&
              <span className="mt-1 text-danger">{pptStateValidationErrors[pptStateItem.id]}</span>}
            </Row>
          ),
          )}
        </Card.Body>
      </div>

    </Card>
  );
};

JeniClusterPostProcessorTypes.propTypes = {
  stateProps: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  postProcessorTypes: PropTypes.arrayOf(PropTypes.shape({
    uri: PropTypes.string,
  })).isRequired,
};

/**
 * @description JENI Cluster single record
 */
const JeniCluster = forwardRef(({
  jeniCluster,
  printerTypes,
  postProcessorTypes,
  initialFormValues,
  locations,
  onSubmitJeniCluster,
  handleDeleteJeniCluster,
  isJeniClusterFetching,
  isJeniClusterSubmitting,
  isJeniRelatedWorkflowFetching,
  onIntegratedWorkflowSave,
  workflow,
}, forwardedRef) => {
  const isEditingExistingResource = !_isEmpty(jeniCluster);

  const initialPptState = [
    {
      id: _uniqueId('ppt_'),
      postProcessorType: null,
      count: 0,
    },
  ];

  const [pptState, setPptState] = useState(initialPptState);
  const [pptStateValidationErrors, setPptStateValidationErrors] = useState(null);

  const integratedWorkflowComponentRef = useRef(null);

  /**
   * @description Sets the state for post-processor-types form values if reading an existing resource.
   */
  const handleSetInitialPptState = () => {
    // Only set the initial state if reading an existing resource.
    if (jeniCluster) {
      const updatedState = _map(
        initialFormValues?.postProcessorTypes,
        item => ({
          id: _uniqueId('ppt_'),
          postProcessorType: item.uri,
          count: item.count,
        }));

      setPptState(updatedState);
    }
  };

  useEffect(() => {
    handleSetInitialPptState();
  }, [initialFormValues.postProcessorTypes]);

  const combineValidators = (...validators) => value =>
    // eslint-disable-next-line unicorn/no-array-reduce, unicorn/no-useless-undefined
    validators.reduce((error, validator) => error || validator(value), undefined);

  const fieldRequired = value => (value ? undefined : 'This field is required.');
  const numericValidation = value => (value <= 99 && value >= 1 ? undefined : 'Value must be between 1-99.');
  const ipAddressValidation = value => {
    if (!value) {
      return 'This field is required.';
    }

    // Regular expression to match IP address with an optional port number
    const ipAddressRegex = /^(\d{1,3}\.){3}\d{1,3}(:\d+)?$/;

    if (!ipAddressRegex.test(value)) {
      return 'Invalid IP address format';
    }

    // eslint-disable-next-line unicorn/no-useless-undefined
    return undefined;
  };

  const validateSelectedPostProcessorTypes = () => {
    setPptStateValidationErrors(null);

    return pptState.every(field => {
      if (!field.postProcessorType) {
        setPptStateValidationErrors(previous => ({ ...previous, [field.id]: 'This field is required.' }));
        return false;
      }

      if (!field.count || field.count > 99 || field.count < 1) {
        setPptStateValidationErrors(previous => ({ ...previous, [field.id]: 'Value must be between 1-99.' }));
        return false;
      }

      return true;
    });
  };

  const onSubmit = async formValues => {
    if (validateSelectedPostProcessorTypes()) {
      onSubmitJeniCluster(formValues, pptState);
    }
  };

  useImperativeHandle(forwardedRef, () => ({
    onIntegratedWorkflowSave() {
      integratedWorkflowComponentRef.current?.onSaveHandler();
    },
    getWorkflowState() {
      return integratedWorkflowComponentRef.current?.getWorkflowState();
    },
  }));

  if (isJeniClusterFetching) {
    return <Loading />;
  }

  return (
    <Container fluid className="m-b">
      <Form
        initialValues={initialFormValues}
        onSubmit={onSubmit}
        render={({ handleSubmit }) => (
          <>
            <BreadcrumbNav
              breadcrumbs={['inventory', 'jeniClusters', (isEditingExistingResource && jeniCluster?.uri) ?
                `${jeniCluster?.name} (${getShortUUID(extractUuid(jeniCluster?.uri))})` : 'New']}
            />

            <div className="clearfix">
              <h1>
                <FormattedMessage
                  id="jeniCluster"
                  defaultMessage="JENI Cluster"
                />
              </h1>
              <ButtonToolbar className="pull-right">
                <SplitButton
                  id="uxSaveDropdown"
                  type="submit"
                  variant="success"
                  size="sm"
                  title={isJeniClusterSubmitting ? <Loading inline /> : 'Save'}
                  pullRight
                  disabled={isJeniClusterFetching}
                  onClick={event => {
                    event.preventDefault();
                    handleSubmit(event);
                  }}
                >
                  {isEditingExistingResource && (
                    <Dropdown.Item
                      eventKey={1}
                      onClick={handleDeleteJeniCluster}
                    >
                      <FontAwesomeIcon icon={faBan} />{' '}
                      <FormattedMessage id="button.delete" defaultMessage="Delete" />
                    </Dropdown.Item>
                  )}
                </SplitButton>
              </ButtonToolbar>
            </div>

            <hr />

            <Row>
              <Col xs={12} sm={6}>
                <Card bg="dark">
                  <Card.Header className="pd-exp inverse">
                    <FormattedMessage
                      id="field.summary"
                      defaultMessage="Summary"
                    />
                  </Card.Header>
                  <div className="card-body-wrapper">
                    <Card.Body>

                      <Field
                        name="name"
                        validate={fieldRequired}
                        render={({ input: { onChange, value }, meta }) => (
                          <FormGroup className="form-group" controlId="uxName">
                            <FormLabel>
                              <FormattedMessage
                                id="field.name"
                                defaultMessage="Name"
                              />
                            </FormLabel>
                            <FormControl
                              name="name"
                              onChange={onChange}
                              required
                              type="text"
                              value={value}
                            />
                            {meta.error && meta.touched && <span className="mt-1 text-danger">{meta.error}</span>}
                          </FormGroup>
                        )}
                      />

                      <Row id="printerTypeAndCount">
                        <Col xs={7}>

                          <Field
                            name="printerType.uri"
                            validate={fieldRequired}
                            render={({ input: { onChange, value }, meta }) => (
                              <FormGroup className="form-group" controlId="uxName">
                                <FormLabel>
                                  <FormattedMessage
                                    id="field.printerType"
                                    defaultMessage="Printer Type"
                                  />
                                </FormLabel>
                                <FormControl
                                  name="printerType.uri"
                                  onChange={onChange}
                                  required
                                  value={value}
                                  as="select"
                                >
                                  {[
                                    <option style={{ fontStyle: 'italic' }} key="placeholder" value="">
                                      No printer selected
                                    </option>,
                                    ..._map(printerTypes, printerTypeItem => (
                                      <option
                                        id={extractUuid(printerTypeItem?.uri)}
                                        value={printerTypeItem?.uri}
                                      >
                                        {printerTypeItem?.name}
                                      </option>
                                    ))]}
                                </FormControl>
                                {meta.error && meta.touched && <span className="mt-1 text-danger">{meta.error}</span>}
                              </FormGroup>
                            )}
                          />

                        </Col>

                        <Col xs={5}>

                          <Field
                            name="printerType.count"
                            validate={combineValidators(fieldRequired, numericValidation)}
                            render={({ input: { onChange, value }, meta }) => (
                              <FormGroup className="form-group" controlId="uxPrinterTypeCount">
                                <FormLabel>
                                  Count:
                                </FormLabel>
                                <FormControl
                                  name="printerType.count"
                                  onChange={onChange}
                                  defaultValue={1}
                                  value={value}
                                  max={99}
                                  min={1}
                                  type="number"
                                  required
                                />
                                {meta.error && meta.touched && <span className="mt-1 text-danger">{meta.error}</span>}
                              </FormGroup>
                            )}
                          />
                        </Col>
                      </Row>

                      <JeniClusterPostProcessorTypes
                        initialFormValues={initialFormValues?.postProcessorTypes}
                        postProcessorTypes={postProcessorTypes}
                        stateProps={[pptState,
                          setPptState,
                          pptStateValidationErrors,
                          setPptStateValidationErrors]}
                      />

                      <Field
                        name="location"
                        validate={fieldRequired}
                        render={({ input: { onChange, value }, meta }) => (
                          <FormGroup className="form-group m-t" controlId="uxLocation">
                            <FormLabel>
                              Location:
                            </FormLabel>
                            <FormControl
                              name="location"
                              onChange={onChange}
                              required
                              value={value}
                              as="select"
                            >
                              {[
                                <option style={{ fontStyle: 'italic' }} key="placeholder" value="">
                                  No location selected
                                </option>,
                                ..._map(locations, locationItem => (
                                  <option id={extractUuid(locationItem.uri)} value={locationItem.uri}>
                                    {locationItem.name}
                                  </option>
                                )),
                              ]}
                            </FormControl>
                            {meta.error && meta.touched && <span className="mt-1 text-danger">{meta.error}</span>}
                          </FormGroup>
                        )}
                      />

                      <Field
                        name="ipAddress"
                        validate={combineValidators(fieldRequired, ipAddressValidation)}
                        render={({ input: { onChange, value }, meta }) => (
                          <FormGroup className="form-group m-t" controlId="uxIpAddress">
                            <FormLabel>
                              IP Address:
                            </FormLabel>
                            <FormControl
                              name="ipAddress"
                              onChange={onChange}
                              placeholder="127.0.0.1"
                              value={value}
                              required
                              maxLength={19}
                              type="text"
                            />
                            {meta.error && meta.touched && <span className="mt-1 text-danger">{meta.error}</span>}
                          </FormGroup>
                        )}
                      />

                      <FormGroup className="form-group m-t" controlId="uxStatus">
                        <FormLabel>
                          Status:
                        </FormLabel>
                        <Badge className="spacer-left" bg="info">
                          {isEditingExistingResource ? _capitalize(jeniCluster?.status) : 'New'}
                        </Badge>
                      </FormGroup>

                    </Card.Body>
                  </div>
                </Card>
              </Col>
              <Col xs={12} sm={6}>
                {/* Command view UI is only visible when viewing an existing JENI-cluster resource. */}
                {jeniCluster && (
                  <JeniClusterCommandView
                    jeniCluster={jeniCluster}
                  />
                )}

                <hr />

                {
                  isJeniRelatedWorkflowFetching ?
                    <Loading /> : (
                      <WorkflowContainer
                        workflow={workflow}
                        ref={integratedWorkflowComponentRef}
                        onOverrideSave={onIntegratedWorkflowSave}
                        hideHeaderUI
                        lgCol={12}
                        smCol={12}
                      />
                    )
                }
              </Col>
            </Row>
          </>
        )}
      />
    </Container>
  );
});

JeniCluster.propTypes = {
  name: PropTypes.string.isRequired,
  jeniCluster: PropTypes.string.isRequired,
  printerTypes: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  postProcessorTypes: PropTypes.arrayOf(PropTypes.shape({
    uri: PropTypes.string,
  })).isRequired,
  initialFormValues: PropTypes.shape({
    postProcessorTypes: PropTypes.arrayOf(PropTypes.shape({})),
  }).isRequired,
  onSubmitJeniCluster: PropTypes.func.isRequired,
  locations: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  handleDeleteJeniCluster: PropTypes.func.isRequired,
  isJeniClusterFetching: PropTypes.bool.isRequired,
  onIntegratedWorkflowSave: PropTypes.func.isRequired,
  workflow: PropTypes.shape({}).isRequired,
  isJeniClusterSubmitting: PropTypes.bool.isRequired,
  isJeniRelatedWorkflowFetching: PropTypes.bool.isRequired,
};

export default JeniCluster;
