import React, { useEffect, useMemo } from 'react';
import { faArrowLeftLong, faFloppyDisk, faPencil, faPlus, faTrash } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { FormattedMessage } from 'rapidfab/i18n';
import { Button, Col, ListGroupItem, OverlayTrigger, Row, Tooltip } from 'react-bootstrap';
import FormattedLocalizedCost from 'rapidfab/components/FormattedLocalizedCost';
import { useFieldArray, useForm } from 'react-hook-form';
import PropTypes from 'prop-types';

const convertPrice = value => new Intl.NumberFormat('en-US', {
  style: 'currency',
  currency: 'USD',
}).format(value);

export const WorkstepTable = ({ saveQuote, tableData, editMode, setEditMode, workstepUri }) => {
  const { control, handleSubmit, reset, watch, register } = useForm({
    defaultValues: { charges: useMemo(() => tableData, [tableData]) },
  });
  const { fields, append, remove } = useFieldArray({ control, name: 'charges' });

  const onSubmit = data => {
    saveQuote(data);
    setEditMode(false);
  };

  const toggleEditMode = event => {
    event.preventDefault();
    setEditMode(previous => {
      if (previous) reset();
      return !previous;
    });
  };

  const addAdditionalCharge = event => {
    event.preventDefault();
    append({
      key: 'additional',
      name: `Additional Charge ${fields.filter(row => row.key === 'additional').length + 1}`,
      perUnit: 10,
      count: 2,
      unit: 'Unit',
    });
  };

  useEffect(() => reset({ charges: tableData }), [workstepUri]);
  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      {fields.map((field, index) => {
        const fieldName = `charges.${index}.count`;
        return (
          <ListGroupItem key={field.id} className="resource-list-item-card-item">
            <Row>
              <Col xs={4} className="wrap-text">
                {field.name}
              </Col>
              <Col xs={editMode ? 1 : 2}>
                {field.unit}
              </Col>
              <Col xs={2}>
                <OverlayTrigger
                  placement="top"
                  overlay={(
                    <Tooltip>
                      {convertPrice(field.perUnit)}
                    </Tooltip>
                  )}
                >
                  <div className="quoteProcessStepModalInputTextPrice">
                    <FormattedLocalizedCost value={field.perUnit} />
                  </div>
                </OverlayTrigger>
              </Col>
              <Col xs={2}>
                {editMode ? (
                  <input
                    className="line-item-quote-modal-input"
                    type="number"
                    step="0.01"
                    key={field.id}
                    {...register(fieldName)}
                    required
                  />
                ) :
                  watch(fieldName)}
              </Col>
              <Col xs={2} className="wrap-text">
                <OverlayTrigger
                  placement="top"
                  overlay={(
                    <Tooltip>
                      {convertPrice(watch(fieldName) * field.perUnit)}
                    </Tooltip>
                  )}
                >
                  <div className="quoteProcessStepModalInputTextPrice">
                    <FormattedLocalizedCost value={watch(fieldName) * field.perUnit} />
                  </div>
                </OverlayTrigger>
              </Col>
              {editMode && (
                <Col xs={1}>
                  {field.key === 'additional' && (
                    <FontAwesomeIcon
                      role="button"
                      icon={faTrash}
                      onClick={() => remove(index)}
                    />
                  )}
                </Col>
              )}
            </Row>
          </ListGroupItem>
        );
      })}
      {editMode ? (
        <div className="mt-1 d-flex flex-row justify-content-between align-items-center mb15">
          <Button
            variant="outline-info"
            className="btn-info-dark"
            size="sm"
            onClick={addAdditionalCharge}
          >
            <FontAwesomeIcon icon={faPlus} className="spacer-right" />
            <FormattedMessage
              id="button.addAdditionalCharge"
              defaultMessage="Add Additional Charge"
            />
          </Button>

          <div>
            <Button onClick={toggleEditMode} type="button" size="sm" variant="outline-light" className="spacer-right">
              <FontAwesomeIcon icon={faArrowLeftLong} className="spacer-right" />
              <FormattedMessage id="back" defaultMessage="Back" />
            </Button>
            <Button size="sm" variant="outline-info" type="submit">
              <FontAwesomeIcon icon={faFloppyDisk} className="spacer-right" />
              <FormattedMessage id="button.save" defaultMessage="Save" />
            </Button>
          </div>
        </div>
      ) : (
        <div className="mt-1 d-flex align-items-center justify-content-end gap-2">
          <Button size="sm" variant="outline-info" type="button" onClick={toggleEditMode}>
            <FontAwesomeIcon icon={faPencil} className="spacer-right" />
            <FormattedMessage id="button.edit" defaultMessage="Edit" />
          </Button>
        </div>
      )}
    </form>
  );
};

WorkstepTable.propTypes = {
  saveQuote: PropTypes.func.isRequired,
  tableData: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  editMode: PropTypes.bool.isRequired,
  setEditMode: PropTypes.func.isRequired,
  workstepUri: PropTypes.string.isRequired,
};

export const WorkstepTableHeader = ({ editMode }) => (
  <ListGroupItem className="resource-list-item-card-header">
    <Row>
      <Col xs={4}>
        <b>
          <FormattedMessage
            id="line_item.quote_price_factor"
            defaultMessage="Price Factor"
          />
        </b>
      </Col>
      <Col xs={editMode ? 1 : 2}>
        <b>
          Unit
        </b>
      </Col>
      <Col xs={2}>
        <b>
          $/#
        </b>
      </Col>
      <Col xs={2}>
        <b>
          #
        </b>
      </Col>
      <Col xs={2}>
        <b>
          <FormattedMessage
            id="line_item.quote_price_factor.price"
            defaultMessage="Price"
          />
        </b>
      </Col>
      <Col xs={1} />
    </Row>
  </ListGroupItem>
);

WorkstepTableHeader.propTypes = {
  editMode: PropTypes.bool.isRequired,
};
