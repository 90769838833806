import { createSelector } from 'reselect';
import * as baseStateSelectors from 'rapidfab/selectors/baseStateSelectors';
import { getPredicate, getStateResources } from 'rapidfab/selectors/helpers/base';
import _map from 'lodash/map';
import _keyBy from 'lodash/keyBy';
import _find from 'lodash/find';
import _filter from 'lodash/filter';

export const getSecureCheckoutDirectories = createSelector(
  [baseStateSelectors.getStateSecureCheckoutDirectories, getStateResources],
  (uuids, resources) => _map(uuids, uuid => resources[uuid]),
);
export const getAvailableSecureCheckoutDirectories = createSelector(
  [getSecureCheckoutDirectories],
  secureCheckoutDirectories => _filter(secureCheckoutDirectories, resource => resource.available),
);

export const getSecureCheckoutDirectoriesByUri = createSelector(
  [getSecureCheckoutDirectories], secureCheckoutDirectories =>
    _keyBy(secureCheckoutDirectories, 'uri'),
);

export const getSecureCheckoutDirectoryByUri = createSelector(
  [getSecureCheckoutDirectories, getPredicate],
  (secureCheckoutDirectories, uri) => _find(secureCheckoutDirectories, { uri }),
);
