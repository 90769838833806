import React from 'react';
import { faChevronDown, faChevronUp, faTriangleExclamation } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import _findKey from 'lodash/findKey';
import PropTypes from 'prop-types';
import { resetRcTooltipInnerStyle } from 'rapidfab/constants/styles';
import { useModalGroupedSection } from 'rapidfab/context/ModalGroupedSectionProvider';
import RCTooltip from 'rc-tooltip';
import { Card, FormGroup } from 'react-bootstrap';

const ModalGroupedSection = ({
  isSectionVisible,
  sectionTitle,
  children,
}) => {
  const { sectionsWithMissingFields, groupedSectionTitles, handleSetSectionVisibilityState } = useModalGroupedSection();

  const sectionKey = _findKey(groupedSectionTitles, value => value === sectionTitle);
  const sectionWithMissingFields = sectionsWithMissingFields[sectionKey];
  const sectionHasMissingRequiredFields = sectionWithMissingFields?.length;

  return (
    <FormGroup
      className="form-group"
    >
      <Card className="custom-darken-modal--card" style={sectionHasMissingRequiredFields ? { boxShadow: '0 0 6px 3px rgb(144 99 42)' } : {}}>
        <Card.Header
          role="button"
          onClick={() => handleSetSectionVisibilityState(sectionKey)}
          className="pd-exp d-flex align-items-center justify-content-between custom-darken-modal--card-header cursor-pointer"
        >
          <div>
            <span>{sectionTitle}</span>
            {
              !!sectionHasMissingRequiredFields && (
                <RCTooltip
                  placement="top"
                  id="printerTypeRequiredFieldsTooltip"
                  destroyTooltipOnHide
                  overlayInnerStyle={resetRcTooltipInnerStyle}
                  mouseLeaveDelay={0.4}
                  overlay={(
                    <div className="darkTooltip">
                      <p>
                        Section <strong>{sectionTitle}</strong> has missing required fields:
                      </p>
                      {sectionWithMissingFields.map(field => (
                        <ul key={field}>
                          <li>{field}</li>
                        </ul>
                      ))}
                      <p className="mb0">Please fill all the required fields before submitting the form.</p>
                    </div>
                  )}
                >
                  <FontAwesomeIcon icon={faTriangleExclamation} className="spacer-left" color="#ffd500" />
                </RCTooltip>
              )
            }
          </div>
          <FontAwesomeIcon
            icon={isSectionVisible ? faChevronUp : faChevronDown}
            className="spacer-left"
          />

        </Card.Header>
        {
          isSectionVisible && (
            <Card.Body className="custom-darken-modal--card-body">
              {children}
            </Card.Body>
          )
        }
      </Card>
    </FormGroup>
  );
};

ModalGroupedSection.propTypes = {
  isSectionVisible: PropTypes.bool.isRequired,
  sectionTitle: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,
};

export default ModalGroupedSection;
