import React from 'react';
import { ROUTES, STATUS_COLOR_CODE_TYPES } from 'rapidfab/constants';
import { SERVICE_PROVIDER_JOB_MAP } from 'rapidfab/mappings';

import TableWithSearching from 'rapidfab/components/Tables/TableWithSearching';
import PropTypes from 'prop-types';

const columns = [
  {
    type: 'uuid',
    uid: 'field.id',
    accessor: 'uuid',
    defaultMessage: 'ID',
    resource: 'service-provider-job',
    route: ROUTES.SERVICE_PROVIDER_JOB,
  },
  {
    type: 'time',
    uid: 'field.bid_requested_date',
    accessor: 'created',
    defaultMessage: 'Bid Requested Date',
    isSortable: true,
  },
  {
    type: 'text',
    uid: 'field.requesting_bureau_name',
    accessor: 'requesting_bureau_name',
    defaultMessage: 'Requesting Bureau',
    isSortable: true,
  },
  {
    type: 'text',
    uid: 'orderName',
    accessor: 'order_name',
    defaultMessage: 'Order Name',
    isSortable: true,
  },
  {
    type: 'custom',
    uid: 'jobType',
    accessor: 'process_step',
    defaultMessage: 'Job Type',
    Cell: ({ value }) => (
      !value ? 'Full Part' : 'Process Step'
    ),
  },
  {
    type: 'translatable',
    uid: 'field.status',
    accessor: 'status',
    defaultMessage: 'Status',
    mapping: SERVICE_PROVIDER_JOB_MAP,
    coloured: true,
    colorScheme: STATUS_COLOR_CODE_TYPES.SERVICE_PROVIDER_JOB,
    isSortable: true,
  },
];

const JobsGrid = ({ data, onSort, fetching, ...componentProps }) => (
  <>
    <TableWithSearching
      {...componentProps}
      data={data}
      columns={columns}
      isFetching={fetching}
      isManualSoringEnabled
      manualSortingFunc={onSort}
      initialSortedDesc
      initialSortedColumn="updated"
    />
  </>
);

JobsGrid.propTypes = {
  data: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  fetching: PropTypes.bool.isRequired,
  onSort: PropTypes.func.isRequired,
};

export default JobsGrid;
