import React, { useEffect } from 'react';
import DDWBuyerShoppingCart from 'rapidfab/components/danfossDDW/DDWBuyerShoppingCart';
import { useDispatch, useSelector } from 'react-redux';
import * as Selectors from 'rapidfab/selectors';
import _isEmpty from 'lodash/isEmpty';
import Loading from 'rapidfab/components/Loading';
import _find from 'lodash/find';
import { API_RESOURCES, FEATURES } from 'rapidfab/constants';
import { handleInitializeShoppingCartData } from 'rapidfab/utils/shoppingCartUtils';
import Actions from 'rapidfab/actions';

const DDWBuyerShoppingCartContainer = () => {
  const dispatch = useDispatch();

  const shoppingCartUuid = window.location.hash.split('/').slice(-1)[0];
  const shoppingCart = useSelector(state => Selectors.getShoppingCartsForUuid(state, shoppingCartUuid));
  const shoppingCartItems = useSelector(state =>
    Selectors.getShoppingCartItemsForShoppingCartUri(state, shoppingCart?.uri));
  const modelsByUri = useSelector(Selectors.getModelsByUri);
  const modelLibrariesByUri = useSelector(Selectors.getModelLibrariesByUri);
  const materials = useSelector(Selectors.getMaterials);
  const materialsByUri = useSelector(Selectors.getMaterialsByUri);
  const shoppingCartOwner = useSelector(state => Selectors.getUserFindableByUri(state, shoppingCart?.owner));
  const orders = useSelector(Selectors.getOrders);
  const orderForBuyerShoppingCart = _find(orders, { uri: shoppingCart?.order });

  const isRobozeDDWFeatureEnabled = useSelector(state =>
    Selectors.isFeatureEnabled(state, FEATURES.ROBOZE_DDW));
  const isDigitalDesignWarehouseFeatureEnabled = useSelector(state =>
    Selectors.isFeatureEnabled(state, FEATURES.DIGITAL_DESIGN_WAREHOUSE));

  const isFetchingBuyerShoppingCart = useSelector(state =>
    state.ui.nautilus[API_RESOURCES.SHOPPING_CART].list.fetching
    || state.ui.nautilus[API_RESOURCES.SHOPPING_CART_ITEM].list.fetching);

  const isFetchingModelRelatedData = useSelector(state =>
    state.ui.nautilus[API_RESOURCES.MODEL_LIBRARY].list.fetching ||
    state.ui.nautilus[API_RESOURCES.MODEL].list.fetching ||
    state.ui.nautilus[API_RESOURCES.MATERIAL].list.fetching);

  const isFetchingUsers = useSelector(state => state.ui.nautilus[API_RESOURCES.USERS].list.fetching);

  const onInitialize = async () => {
    if ([isRobozeDDWFeatureEnabled, isDigitalDesignWarehouseFeatureEnabled].every(Boolean)) {
      handleInitializeShoppingCartData(dispatch, shoppingCartUuid);
    }
  };

  const getOrderForShoppingCart = async () => {
    dispatch(Actions.Api.nautilus[API_RESOURCES.ORDER].get(shoppingCart.order));
  };

  useEffect(() => {
    onInitialize();
  }, [shoppingCartUuid]);

  useEffect(() => {
    if ([isRobozeDDWFeatureEnabled, isDigitalDesignWarehouseFeatureEnabled].every(Boolean)
      && shoppingCart
       && shoppingCart?.order) {
      getOrderForShoppingCart();
    }
  }, [shoppingCart]);

  return (
    _isEmpty(shoppingCart) || isFetchingBuyerShoppingCart ?
      <Loading />
      : (
        <DDWBuyerShoppingCart
          materials={materials}
          materialsByUri={materialsByUri}
          shoppingCart={shoppingCart}
          shoppingCartItems={shoppingCartItems}
          modelsByUri={modelsByUri}
          modelLibrariesByUri={modelLibrariesByUri}
          shoppingCartOwner={shoppingCartOwner}
          orderForBuyerShoppingCart={orderForBuyerShoppingCart}
          fetchingAdditionalData={{
            isFetchingModelRelatedData,
            isFetchingUsers,
          }}
        />
      )
  );
};

export default DDWBuyerShoppingCartContainer;
