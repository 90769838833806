import React from 'react';
import { FormattedMessage } from 'rapidfab/i18n';
import PropTypes from 'prop-types';

const ShoppingCartItemDataRow = ({
  titleId,
  titleDefaultMessage,
  value,
}) => (
  <tr>
    <td>
      <b>
        <FormattedMessage
          id={titleId}
          defaultMessage={titleDefaultMessage}
        />
        :
      </b>
    </td>
    <td>
      {value}
    </td>
  </tr>
);

ShoppingCartItemDataRow.propTypes = {
  titleId: PropTypes.string.isRequired,
  titleDefaultMessage: PropTypes.string.isRequired,
  value: PropTypes.node.isRequired,
};

export default ShoppingCartItemDataRow;
