import {
  SERVICE_PROVIDER_JOB_STATUS,
  LINE_ITEM_SERVICE_PROVIDER_ROLE,
  SERVICE_PROVIDER_JOB_TARGET,
} from 'rapidfab/constants';
import { SERVICE_PROVIDER_JOB_MAP } from 'rapidfab/mappings';

export default ({
  isBureauUser = false,
  lastEditorRole = null,
  serviceProviderStatus = null, // it's LineItemServiceProvider.status, not SP status
  updatedFields = [],
  target = null, // job.target field, "line_item" or "process_step"
}) => {
  const isProcessStepTargetJob = target === SERVICE_PROVIDER_JOB_TARGET.PROCESS_STEP;
  const isLineItemTargetJob = target === SERVICE_PROVIDER_JOB_TARGET.LINE_ITEM;

  const cancelledStatuses = [
    SERVICE_PROVIDER_JOB_STATUS.DECLINED,
    SERVICE_PROVIDER_JOB_STATUS.CANCELLED,
  ];

  const completedStatuses = [
    SERVICE_PROVIDER_JOB_STATUS.COMPLETED,
    ...cancelledStatuses,
  ];

  const disabledProgressStatuses = new Set([
    SERVICE_PROVIDER_JOB_STATUS.BID_NEGOTIATED,
    isProcessStepTargetJob && SERVICE_PROVIDER_JOB_STATUS.SHIPPING_TO,
    SERVICE_PROVIDER_JOB_STATUS.IN_PROGRESS,
    SERVICE_PROVIDER_JOB_STATUS.SHIPPING_FROM,
    ...completedStatuses,
  ].filter(Boolean)); // Remove unrelated (null) statuses

  const bureauSubmitUpdateStatuses = isProcessStepTargetJob
    ? [isProcessStepTargetJob && SERVICE_PROVIDER_JOB_STATUS.BID_NEGOTIATED]
    : []; // For full line item job - Bureau do not submit status updates

  const serviceProviderSubmitUpdateStatuses = new Set([
    isLineItemTargetJob && SERVICE_PROVIDER_JOB_STATUS.BID_NEGOTIATED,
    isProcessStepTargetJob && SERVICE_PROVIDER_JOB_STATUS.SHIPPING_TO,
    SERVICE_PROVIDER_JOB_STATUS.IN_PROGRESS,
  ].filter(Boolean)); // Remove unrelated (null) statuses

  const bureauCanCancelBidStatuses = [
    SERVICE_PROVIDER_JOB_STATUS.BID_REQUESTED,
    SERVICE_PROVIDER_JOB_STATUS.BID_NEGOTIATED,
  ];

  const bureauCanSeeProposedValues = new Set([
    SERVICE_PROVIDER_JOB_STATUS.BID_REQUESTED,
    SERVICE_PROVIDER_JOB_STATUS.BID_UPDATED,
    SERVICE_PROVIDER_JOB_STATUS.BID_NEGOTIATED,
    isProcessStepTargetJob && SERVICE_PROVIDER_JOB_STATUS.SHIPPING_TO,
    SERVICE_PROVIDER_JOB_STATUS.IN_PROGRESS,
    SERVICE_PROVIDER_JOB_STATUS.SHIPPING_FROM,
    ...completedStatuses,
  ].filter(Boolean)); // Remove unrelated (null) statuses

  const userCanProposeStatusChanges = new Set([
    SERVICE_PROVIDER_JOB_STATUS.BID_REQUESTED,
    SERVICE_PROVIDER_JOB_STATUS.BID_UPDATED,
  ]);

  const serviceProviderReadonlyStatuses = [
    isProcessStepTargetJob && SERVICE_PROVIDER_JOB_STATUS.BID_NEGOTIATED,
    SERVICE_PROVIDER_JOB_STATUS.SHIPPING_FROM,
    ...completedStatuses,
  ].filter(Boolean); // Remove unrelated (null) statuses

  const serviceProviderJobStatusTransformations = {
    [SERVICE_PROVIDER_JOB_STATUS.BID_NEGOTIATED]: [
      SERVICE_PROVIDER_JOB_STATUS.BID_NEGOTIATED,
      isProcessStepTargetJob && SERVICE_PROVIDER_JOB_STATUS.SHIPPING_TO,
      isLineItemTargetJob && SERVICE_PROVIDER_JOB_STATUS.IN_PROGRESS,
      isLineItemTargetJob && SERVICE_PROVIDER_JOB_STATUS.SHIPPING_FROM,
    ].filter(Boolean), // Remove unrelated (null) statuses
    [SERVICE_PROVIDER_JOB_STATUS.IN_PROGRESS]: [
      SERVICE_PROVIDER_JOB_STATUS.IN_PROGRESS,
      SERVICE_PROVIDER_JOB_STATUS.SHIPPING_FROM,
    ],
  };
  if (isProcessStepTargetJob) {
    serviceProviderJobStatusTransformations[SERVICE_PROVIDER_JOB_STATUS.SHIPPING_TO] = [
      SERVICE_PROVIDER_JOB_STATUS.SHIPPING_TO,
      SERVICE_PROVIDER_JOB_STATUS.IN_PROGRESS,
      SERVICE_PROVIDER_JOB_STATUS.SHIPPING_FROM,
    ];
  }

  return ({
    requestBidButton: {
      show:
      isBureauUser &&
      (
        !serviceProviderStatus ||
        serviceProviderStatus === SERVICE_PROVIDER_JOB_STATUS.BID_REQUESTED
      ),
      message: serviceProviderStatus === SERVICE_PROVIDER_JOB_STATUS.BID_REQUESTED ? 'Bid Requested' : 'Request Bid',
      disabled: serviceProviderStatus === SERVICE_PROVIDER_JOB_STATUS.BID_REQUESTED,
    },
    cancelButton: {
      show:
      isBureauUser ?
        bureauCanCancelBidStatuses.includes(serviceProviderStatus)
        :
        userCanProposeStatusChanges.has(serviceProviderStatus),
      message:
      isBureauUser ?
        ((serviceProviderStatus === SERVICE_PROVIDER_JOB_STATUS.BID_REQUESTED && 'Cancel Bid') || 'Cancel Offer') :
        'Decline',
      disabled: false,
      transformsTo: isBureauUser ?
        SERVICE_PROVIDER_JOB_STATUS.CANCELLED
        :
        SERVICE_PROVIDER_JOB_STATUS.DECLINED,
    },
    saveBidDataButton: {
      show:
      !isBureauUser &&
      userCanProposeStatusChanges.has(serviceProviderStatus),
      message: 'Save Bid Data',
      transformsTo: SERVICE_PROVIDER_JOB_STATUS.BID_UPDATED,
      disabled: false,
    },
    currentStatusButton: {
      show:
      isBureauUser
        ?
        disabledProgressStatuses.has(serviceProviderStatus)
        :
        serviceProviderReadonlyStatuses.includes(serviceProviderStatus),
      message: serviceProviderStatus &&
      SERVICE_PROVIDER_JOB_MAP[serviceProviderStatus].defaultMessage,
      style: cancelledStatuses.includes(serviceProviderStatus) ? 'danger' : 'primary',
      disabled: true,
    },
    submitButton: {
      show:
    isBureauUser
      ?
      (
        // Submit button is used when Bureau user can propose changes to bid
        userCanProposeStatusChanges.has(serviceProviderStatus)
        // Or change negotiated bid to Shipping To status
        || bureauSubmitUpdateStatuses.includes(serviceProviderStatus)
      )
      :
      serviceProviderSubmitUpdateStatuses.has(serviceProviderStatus),
      message:
      (isBureauUser && serviceProviderStatus === SERVICE_PROVIDER_JOB_STATUS.BID_UPDATED && 'Make a counteroffer') ||
      'Submit Update',
      disabled: (updatedFields.length === 0),
    },
    completeButton: {
      show: isBureauUser && serviceProviderStatus === SERVICE_PROVIDER_JOB_STATUS.SHIPPING_FROM,
      message: 'Complete',
      disabled: false,
      transformsTo: SERVICE_PROVIDER_JOB_STATUS.COMPLETED,
    },
    offerJobButton: {
      show: isBureauUser && serviceProviderStatus === SERVICE_PROVIDER_JOB_STATUS.BID_UPDATED,
      message: 'Offer Job',
      disabled: (
        lastEditorRole === LINE_ITEM_SERVICE_PROVIDER_ROLE.BUREAU_USER ||
      updatedFields.length > 0
      ),
      transformsTo: SERVICE_PROVIDER_JOB_STATUS.BID_NEGOTIATED,
    },
    currency: {
      show:
      !isBureauUser ||
      (isBureauUser && bureauCanSeeProposedValues.has(serviceProviderStatus)),
      editable: (
        userCanProposeStatusChanges.has(serviceProviderStatus)
      ),
    },
    cost: {
      show:
      !isBureauUser ||
      (isBureauUser && bureauCanSeeProposedValues.has(serviceProviderStatus)),
      editable: (
        userCanProposeStatusChanges.has(serviceProviderStatus)
      ),
    },
    dueDate: {
      show:
      !isBureauUser ||
      (isBureauUser && bureauCanSeeProposedValues.has(serviceProviderStatus)),
      editable: (
        userCanProposeStatusChanges.has(serviceProviderStatus)
      ),
    },
    serviceProviderNotes: {
      show:
      isBureauUser ?
        bureauCanSeeProposedValues.has(serviceProviderStatus)
        :
        disabledProgressStatuses.has(serviceProviderStatus),
      editable:
      !isBureauUser &&
      serviceProviderSubmitUpdateStatuses.has(serviceProviderStatus),
    },
    rating: {
      show: isBureauUser && serviceProviderStatus === SERVICE_PROVIDER_JOB_STATUS.COMPLETED,
      editable: isBureauUser && serviceProviderStatus === SERVICE_PROVIDER_JOB_STATUS.COMPLETED,
    },
    status: {
      show: isBureauUser ?
        bureauSubmitUpdateStatuses.includes(serviceProviderStatus)
        :
        serviceProviderSubmitUpdateStatuses.has(serviceProviderStatus),
      editable: true,
    },
    serviceProviderJobStatusTransformations,
  });
};
