import isArray from 'lodash/isArray';

const reactSelectUserFormatter = users => {
  // Function used to map a user record to be compatible for React-Select component
  if (!users) {
    return null;
  }
  if (!isArray(users)) {
    return { label: users.name, value: users.uri };
  }
  return users.map(({ name, uri }) => ({ label: name, value: uri }));
};

export default reactSelectUserFormatter;
