import React from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import { Button, FormGroup } from 'react-bootstrap';
import { FEATURES } from 'rapidfab/constants';
import Feature from 'rapidfab/components/Feature';
import { isFeatureEnabled } from 'rapidfab/selectors';

const ModelLibraryInput = ({ onClick, fullWidth }) => {
  const isDDWEnabled = useSelector(state =>
    isFeatureEnabled(state, FEATURES.DIGITAL_DESIGN_WAREHOUSE),
  );
  return (
    <Feature featureName={FEATURES.MODEL_LIBRARY}>
      <FormGroup
        style={{ marginLeft: 0, marginRight: '15px', position: 'relative' }}
      >
        <Button className={`m-b-sm ${fullWidth ? 'w-100' : ''}`} variant="default" onClick={onClick}>
          {isDDWEnabled ? (
            <FormattedMessage
              id="chooseModelFromLibraryDDW"
              defaultMessage="Add design from Digital Warehouse"
            />
          ) : (
            <FormattedMessage
              id="chooseModelFromLibrary"
              defaultMessage="Choose model from library"
            />
          )}
        </Button>
      </FormGroup>
    </Feature>
  );
};

ModelLibraryInput.defaultProps = {
  fullWidth: false,
};

ModelLibraryInput.propTypes = {
  onClick: PropTypes.func.isRequired,
  fullWidth: PropTypes.bool,
};

export default ModelLibraryInput;
