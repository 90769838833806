import React from 'react';
import PropTypes from 'prop-types';
import { Row, Col, FormLabel } from 'react-bootstrap';
import { FormattedMessage } from 'rapidfab/i18n';

const DataRow = ({ id, defaultMessage, children, isRequired }) => (
  <Row>
    <Col xs={3}>
      <FormLabel>
        <FormattedMessage id={id} defaultMessage={defaultMessage} />
        :
        { isRequired && ' *' }
      </FormLabel>
    </Col>
    <Col xs={9}>{children}</Col>
  </Row>
);

DataRow.defaultProps = {
  isRequired: false,
};

DataRow.propTypes = {
  id: PropTypes.string.isRequired,
  defaultMessage: PropTypes.string.isRequired,
  children: PropTypes.element.isRequired,
  isRequired: PropTypes.bool,
};

export default DataRow;
