import { faArrowsSpin, faArrowUpWideShort, faCubes, faObjectGroup } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import _truncate from 'lodash/truncate';
import PropTypes from 'prop-types';
import Loading from 'rapidfab/components/Loading';
import BatchPowderQualityStatusBadge
  from 'rapidfab/components/records/material_management/BatchPowderQualityStatusBadge';
import StatusColorCode from 'rapidfab/components/StatusColorCode';
import Tooltip from 'rapidfab/components/Tooltip';
import { MATERIAL_BATCH_POWDER_QUALITY_STATUSES, ROUTES, STATUS_COLOR_CODE_TYPES } from 'rapidfab/constants';
import { FormattedMessage } from 'rapidfab/i18n';
import { MATERIAL_BATCH_STATUS_MAP } from 'rapidfab/mappings';
import { getRouteURI } from 'rapidfab/utils/uriUtils';
import { extractUuid, getShortUUID } from 'rapidfab/utils/uuidUtils';
import React from 'react';
import { Link } from 'react-router-dom';

const MaterialBatchCardDetails = ({ batch, batchFetching }) => {
  if (batchFetching) {
    return <Loading />;
  }

  if (!batch) return null;

  return (
    <div className="material-batch-card-details">
      <div className="material-batch-card-details-header">
        <p className="m-0">Material Batch</p>
        <div className="material-batch-card-details-header-status">
          <div className="spacer-right">
            <StatusColorCode status={batch.status} type={STATUS_COLOR_CODE_TYPES.BATCH_STATUS} /> <span
              className="order-status-dot"
            />
            <FormattedMessage
              id={MATERIAL_BATCH_STATUS_MAP[batch.status].id}
              defaultMessage={MATERIAL_BATCH_STATUS_MAP[batch.status].defaultMessage}
            />
          </div>
          <BatchPowderQualityStatusBadge
            qualityStatus={batch.powder_quality}
          />
        </div>
      </div>
      <div className="material-batch-card-details-body">
        <div className="d-flex align-items-center justify-content-between mb15">
          <div>
            <FontAwesomeIcon className="spacer-right" icon={faCubes} />
            <Link
              to={getRouteURI(
                ROUTES.MATERIAL_BATCH,
                { uuid: batch.uuid },
                {}, true)}
            >
              {getShortUUID(batch.uuid)}
            </Link>
          </div>
          <div>
            <FontAwesomeIcon className="spacer-right" icon={faObjectGroup} />
            <Link
              to={getRouteURI(
                ROUTES.MATERIALS,
                null,
                { uuid: extractUuid(batch.materials[0].uri) },
                true)}
            >
              {
                batch.materials[0].name.length > 25 ? (
                  <Tooltip
                    id="accessDisallowedReason"
                    placement="top"
                    trigger={(<span>{_truncate(batch.materials[0].name, { length: 25 })}</span>)}
                  >
                    {batch.materials[0].name}
                  </Tooltip>
                ) : (
                  batch.materials[0].name
                )
              }
            </Link>
          </div>
        </div>
        <div className="d-flex align-items-center justify-content-between">
          <div>
            <FontAwesomeIcon className="spacer-right" icon={faArrowUpWideShort} />
            {batch.quantity} {batch.units}
          </div>
          <div>
            <FontAwesomeIcon className="spacer-right" icon={faArrowsSpin} />
            {batch.usage_cycles}
          </div>
        </div>
      </div>
    </div>
  );
};

MaterialBatchCardDetails.propTypes = {
  batch: PropTypes.shape({
    status: PropTypes.string,
    uuid: PropTypes.string,
    units: PropTypes.string,
    quantity: PropTypes.number,
    usage_cycles: PropTypes.number,
    materials: PropTypes.arrayOf(PropTypes.shape({
      uri: PropTypes.string,
      name: PropTypes.string,
    })),
    powder_quality: PropTypes.oneOf(Object.values(MATERIAL_BATCH_POWDER_QUALITY_STATUSES)),
  }),
  batchFetching: PropTypes.bool.isRequired,
};

MaterialBatchCardDetails.defaultProps = {
  batch: null,
};

export default MaterialBatchCardDetails;
