import { faPlus } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { DebugModeBadge } from 'rapidfab/components/DebugMode/DebugModeComponents';
import Loading from 'rapidfab/components/Loading';
import OrganizeTemplatesTabs from 'rapidfab/components/organize/OrganizeTemplatesTabs';
import TableWithSearching from 'rapidfab/components/Tables/TableWithSearching';
import { ROUTES } from 'rapidfab/constants';
import { getRouteURI } from 'rapidfab/utils/uriUtils';
import { getShortUUID } from 'rapidfab/utils/uuidUtils';
import React from 'react';
import { Button } from 'react-bootstrap';
import { FormattedMessage } from 'react-intl';
import PropTypes from 'prop-types';
import { Link, useLocation } from 'react-router-dom';

const NavbarLinks = (
  <div className="pull-right d-flex">
    <Button
      variant="primary"
      size="sm"
      href={getRouteURI(ROUTES.ORDER_TEMPLATES_NEW)}
      className="pull-right spacer-right"
    >
      <FontAwesomeIcon icon={faPlus} />{' '}
      <FormattedMessage
        id="record.orderTemplates.add"
        defaultMessage="Add Order Template"
      />
    </Button>
  </div>
);

const OrderTemplates = props => {
  const { fetching,
    orderTemplates,
    isOrderOwnerColumnEnabled,
    isDebugModeEnabled } = props;
  const location = useLocation();
  const isTemplatesMainRoute = location.pathname === ROUTES.TEMPLATES;
  const breadcrumbs = ['organize', 'templates'];

  if (!isTemplatesMainRoute) {
    breadcrumbs.push('orderTemplates');
  }

  const columns = [
    isDebugModeEnabled && ({
      debugMode: true,
      type: 'custom',
      uid: 'field.id',
      accessor: 'uuid',
      defaultMessage: 'ID',
      resource: 'order',
      Header: () => ([
        <DebugModeBadge />,
        <span>ID</span>,
      ]),
      Cell: ({ row: { original: data } }) => (
        <Link
          to={getRouteURI(ROUTES.ORDER_TEMPLATES_EDIT, { uuid: data.uuid }, null, true)}
        >
          {getShortUUID(data.uri)}
        </Link>
      ),
    }),
    {
      type: 'custom',
      uid: 'field.templateName',
      accessor: 'template_name',
      defaultMessage: 'Template Name',
      isSortable: true,
      Cell: ({ row: { original: data } }) => (
        <Link
          to={getRouteURI(ROUTES.ORDER_TEMPLATES_EDIT, { uuid: data.uuid }, null, true)}
        >
          {data.template_name}
        </Link>
      ),
    },
    {
      type: 'text',
      uid: 'orderName',
      accessor: 'name',
      defaultMessage: 'Order Name',
      resource: 'order',
      uri: 'uri',
      isSortable: true,
    },
    isOrderOwnerColumnEnabled && (
      {
        type: 'text',
        uid: 'field.owner',
        accessor: 'order_owner_name',
        defaultMessage: 'Owner',
        isSortable: true,
      }
    ),
    {
      type: 'text',
      uid: 'field.customer_name',
      accessor: 'customer_name',
      defaultMessage: 'Customer Name',
      isSortable: true,
    },
    {
      type: 'text',
      uid: 'field.shippingName',
      defaultMessage: 'Shipping Name',
      accessor: 'shipping.name',
      isSortable: true,
    },
    {
      type: 'time',
      uid: 'created',
      accessor: 'created',
      defaultMessage: 'Created',
      isSortable: true,
    },
  ];

  return (
    <>
      {fetching ? (
        <Loading />
      ) : (
        <TableWithSearching
          {...props}
          isFetching={fetching}
          withBreadcrumbs
          breadcrumbs={breadcrumbs}
          navbar={NavbarLinks}
          data={orderTemplates}
          columns={columns}
          withDefaultPagination
          showLocationsFilter={false}
          isFilteringEnabled={false}
          isUpdatedColumnShown={false}
          initialSortedDesc
          subheader={<OrganizeTemplatesTabs activeKey="order-templates" />}

        />
      )}
    </>
  );
};

OrderTemplates.propTypes = {
  fetching: PropTypes.bool.isRequired,
  orderTemplates: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  isOrderOwnerColumnEnabled: PropTypes.bool.isRequired,
  row: PropTypes.shape({
    original: PropTypes.shape({
      uuid: PropTypes.string,
      uri: PropTypes.string,
      template_name: PropTypes.string,
    }),
  }).isRequired,
  isDebugModeEnabled: PropTypes.bool.isRequired,
};

export default OrderTemplates;
