import React from 'react';
import { getServiceProviderJobs } from 'rapidfab/selectors';
import JobsGrid from 'rapidfab/components/service_providers/JobsGrid';
import withRecordsListHandling from 'rapidfab/containers/hocs/withRecordsListHandling';

const JobListContainer = componentProps => (
  <JobsGrid
    {...componentProps}
  />
);

export default withRecordsListHandling(
  JobListContainer,
  getServiceProviderJobs,
  ['service-provider-job'],
);
