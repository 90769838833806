/** @typedef {import("rapidfab/types/tools").ToolType} ToolType */
import _map from 'lodash/map';
import _keyBy from 'lodash/keyBy';
import _find from 'lodash/find';
import * as baseStateSelectors from 'rapidfab/selectors/baseStateSelectors';
import {
  getStateResources,
  getPredicate,
} from 'rapidfab/selectors/helpers/base';
import { createSelector } from 'reselect';

export const getToolingStockTypes = createSelector(
  [baseStateSelectors.getStateToolingStockTypes, getStateResources],
  /** @returns {(ToolType & {uuid: string})[]} */
  (uuids, resources) => _map(uuids, uuid => resources[uuid]),
);

export const getToolingStockTypesByUri = createSelector(
  [getToolingStockTypes],
  toolingStockTypes => _keyBy(toolingStockTypes, 'uri'),
);

export const getToolingStockTypeFromUri = createSelector(
  [getPredicate, getToolingStockTypes],
  (stockTypeUri, types) => _find(types, ({ uri }) => uri === stockTypeUri),
);
