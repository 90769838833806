import _sumBy from 'lodash/sumBy';
import _reduce from 'lodash/reduce';
import Actions from 'rapidfab/actions';
import { API_RESOURCES } from 'rapidfab/constants';
import _isEmpty from 'lodash/isEmpty';

/**
 * @description Calculates total for shopping cart.
 *
 * @param {ShoppingCartItem[]} pricedShoppingCartItems
 * @param {number[]} additionalCosts
 * @returns {number}
*/
export const getShoppingCartItemsTotalPrice = (
  pricedShoppingCartItems,
  additionalCosts = [0],
) => {
  const shoppingCartItemsTotal = _sumBy(pricedShoppingCartItems, shoppingCartItem => (
    shoppingCartItem.price_per * shoppingCartItem.quantity
  ));

  const additionalCostsTotal = _reduce(additionalCosts, (total, currentCostValue) => (
    total + currentCostValue
  ));

  return shoppingCartItemsTotal + additionalCostsTotal;
};

/**
 * @description Initialize data for shopping carts, materials, model libraries, models, users.
 *
 * @param {function} dispatch
 * @param {string} shoppingCartUuid
 */
export const handleInitializeShoppingCartData = async (dispatch, shoppingCartUuid) => {
  const shoppingCartResponse =
  await dispatch(Actions.Api.nautilus[API_RESOURCES.SHOPPING_CART].get(shoppingCartUuid));
  const shoppingCart = shoppingCartResponse?.json;

  if (!_isEmpty(shoppingCart)) {
    await dispatch(Actions.Api.nautilus[API_RESOURCES.SHOPPING_CART_ITEM].list({
      shopping_cart: shoppingCart.uri,
    }));

    await dispatch(Actions.Api.nautilus[API_RESOURCES.MATERIAL].list());
    await dispatch(Actions.Api.nautilus[API_RESOURCES.MODEL_LIBRARY].list());
    await dispatch(Actions.Api.nautilus[API_RESOURCES.MODEL].list());

    await dispatch(Actions.Api.nautilus[API_RESOURCES.USERS].list({
      uri: shoppingCart.owner,
    }));
  }
};

/**
 * @description Returns whether the order was created via shopping cart.
 *
 * For now, this check
 * is done by checking whether every shopping-cart-item in the shopping-cart
 * contains the key `price_per`.
 *
 * @param {ShoppingCartItem[]} shoppingCartItems all shopping cart items.
 * @returns {boolean}
 */
export const getIsOrderCreatedThroughShoppingCart = shoppingCartItems =>
  shoppingCartItems.every(shoppingCartItem => !!shoppingCartItem.price_per);

/**
 * @description Gets material object for `/shopping-cart-item` resource.
 *
 * @param {ShoppingCartItem} shoppingCartItem
 * @param {ModelLibrary} relatedModelLibrary
 * @param {Material[]} materials
 * @returns {Material}
*/
export const getCartItemMaterial = (shoppingCartItem, relatedModelLibrary, materials) => (
  shoppingCartItem?.material_override ?
    materials.find(({ base_template }) =>
      base_template === shoppingCartItem?.material_override)
    :
    materials.find(material => material.uri === relatedModelLibrary?.additive.base_material)
);
