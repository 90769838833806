import _isEmpty from 'lodash/isEmpty';
import Constants, { CREATE_RUN_PROGRESS_STATUSES } from 'rapidfab/constants';

const initialRunQueueState = {
  queue: [],
};

function createRunProgressReducer(state = initialRunQueueState, action) {
  switch (action.type) {
    case Constants.CREATE_RUN_PROGRESS_START: {
      const { id, createRunUri } = action;
      // Add new run progress to the queue
      const createNewRun = {
        id,
        createRunUri,
        status: CREATE_RUN_PROGRESS_STATUSES.PENDING,
        error: {},
        completeDetails: {},
      };
      return {
        ...state,
        queue: [...state.queue, createNewRun],
      };
    }
    case Constants.CREATE_RUN_PROGRESS_RESTART: {
      const { queue } = state;
      const { oldId, newId, newCreateRunUri } = action;

      return {
        ...state,
        queue: queue.map(run =>
          (run.id === oldId
            ? {
              ...run,
              id: newId,
              createRunUri: newCreateRunUri,
              error: {},
              status: CREATE_RUN_PROGRESS_STATUSES.PENDING,
            }
            : run),
        ),
      };
    }
    case Constants.CREATE_RUN_PROGRESS_PROCESSING: {
      const { queue } = state;
      const { id } = action;

      return {
        ...state,
        queue: queue.map(run =>
          (run.id === id
            ? { ...run, status: CREATE_RUN_PROGRESS_STATUSES.PROCESSING }
            : run),
        ),
      };
    }
    case Constants.CREATE_RUN_PROGRESS_FINISHING: {
      const { queue } = state;
      const { id } = action;

      return {
        ...state,
        queue: queue.map(run =>
          (run.id === id
            ? { ...run, status: CREATE_RUN_PROGRESS_STATUSES.FINISHING }
            : run),
        ),
      };
    }
    case Constants.CREATE_RUN_PROGRESS_SUCCESS: {
      const { queue } = state;
      const { id, runDetails: { finished, primary_run, resulting_runs } } = action;

      return {
        ...state,
        queue: queue.map(run =>
          (run.id === id
            ? { ...run,
              status: CREATE_RUN_PROGRESS_STATUSES.COMPLETE,
              error: {},
              completeDetails: {
                dateFinished: finished,
                mainRun: primary_run,
                runsCreated: resulting_runs,
              } }
            : run),
        ),
      };
    }
    case Constants.CREATE_RUN_PROGRESS_CLEAR: {
      // Clear the entire run creation queue
      return initialRunQueueState;
    }
    case Constants.CREATE_RUN_PROGRESS_ADD_ERROR: {
      const { queue } = state;
      const { id, errorDetails: { finished, notes, unfittedPiecesLength } } = action;

      return {
        ...state,
        queue: queue.map(run =>
          (run.id === id && _isEmpty(run.error)
            ? { ...run,
              status: CREATE_RUN_PROGRESS_STATUSES.ERROR,
              error: {
                id,
                message: notes,
                dateFinished: finished,
                unfittedPiecesLength,
              } }
            : run),
        ),
      };
    }
    default:
      return state;
  }
}

export default createRunProgressReducer;
