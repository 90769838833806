import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import PropTypes from 'prop-types';
import React from 'react';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';

const SlicerFileErrorTooltip = ({ conditionToShow, text, icon, color }) => {
  if (conditionToShow) {
    return (
      <OverlayTrigger
        placement="top"
        overlay={(
          <Tooltip>
            {text}
          </Tooltip>
        )}
      >
        <FontAwesomeIcon icon={icon} className="spacer-left" color={color} />
      </OverlayTrigger>
    );
  }

  return null;
};

SlicerFileErrorTooltip.propTypes = {
  conditionToShow: PropTypes.bool.isRequired,
  text: PropTypes.string.isRequired,
  icon: PropTypes.shape({}).isRequired,
  color: PropTypes.string.isRequired,
};

export default SlicerFileErrorTooltip;
