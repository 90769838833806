import React, { useContext } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import PropTypes from 'prop-types';
import GuidelineContext from 'rapidfab/context/GuidelineContext';
import { Badge } from 'react-bootstrap';
import {
  GUIDELINES_ENGINE_ACTION_TYPES,
  GUIDELINES_ENGINE_FIELD_TYPES_MAPPING,
} from 'rapidfab/constants';
import _capitalize from 'lodash/capitalize';
import { faCircle } from '@fortawesome/free-solid-svg-icons';

export const ColorCode = ({ color, className }) => (
  <FontAwesomeIcon
    className={className}
    icon={faCircle}
    style={{ color }}
  />
);

const GuidelineSummaryBlock = () => {
  const {
    guidelineExpressions,
    processStepsByProcessTypeUri,
  } = useContext(GuidelineContext);

  const workflowStepSelected = guidelineExpressions.action?.type ===
    GUIDELINES_ENGINE_ACTION_TYPES.ADD_WORKFLOW_STEP;

  const workInstructionSelected = guidelineExpressions.action?.type ===
  GUIDELINES_ENGINE_ACTION_TYPES.ADD_WORK_INSTRUCTION;

  const ifBlockExpressions = guidelineExpressions.expressions.map((expression, index) =>
    (
      <p style={{ fontFamily: 'monospace' }}>
        IF <Badge bg="success" className="badge-sm">{expression.field?.label}</Badge> on
        {' '}<Badge bg="primary" className="badge-sm">{GUIDELINES_ENGINE_FIELD_TYPES_MAPPING[expression.field_type?.value]}</Badge>
        {' '}<Badge bg="info" className="badge-sm">{_capitalize(expression.comparator?.label)}</Badge>
        {' '}<Badge bg="white" className="badge-sm">{expression.value?.label}</Badge>
        {guidelineExpressions.expressions.length > 1 && (index + 1) !== guidelineExpressions.expressions.length &&
          <> {guidelineExpressions.join ?? <Badge className="badge-sm" bg="danger"><i>empty join</i></Badge>} </>}
      </p>
    ),
  );

  return ([
    ifBlockExpressions,
    <p style={{ fontFamily: 'monospace' }}>
      THEN suggest a
      {' '}
      <Badge bg="warning" className="badge-sm">
        {(workflowStepSelected && 'Workflow Step') ||
            (workInstructionSelected && 'Work Instruction')}
      </Badge>
      {' '}{workflowStepSelected ? 'of type ' : 'under '}
      <Badge bg="danger" className="badge-sm">
        {processStepsByProcessTypeUri[guidelineExpressions.action?.target_process_step]?.name}
      </Badge>
    </p>,
  ]);
};

export default GuidelineSummaryBlock;

ColorCode.propTypes = {
  color: PropTypes.string.isRequired,
  className: PropTypes.shape({}).isRequired,
};
