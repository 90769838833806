import { MACHINE_TYPE } from 'rapidfab/constants';
import React from 'react';
import PropTypes from 'prop-types';

import { Button, Col, Row } from 'react-bootstrap';

import { isUuid } from 'rapidfab/utils/uuidUtils';

import { FormattedMessage } from 'rapidfab/i18n';
import DowntimeDeleteContainer from 'rapidfab/containers/downtime/DowntimeDeleteContainer';
import DowntimeFormContainer from 'rapidfab/containers/downtime/DowntimeFormContainer';
import DowntimesContainer from 'rapidfab/containers/downtime/DowntimesContainer';

const styles = {
  border: {
    border: '1px solid rgb(67, 72, 87)',
    borderRadius: '4px',
    padding: '10px 15px',
  },
  buttonRow: {
    display: 'flex',
    flexDirection: 'row-reverse',
    margin: '1rem 0 2rem 0',
  },
  spacingTop: {
    marginTop: '1rem',
  },
};

const Downtime = ({
  handleSelectionChange,
  machineType,
  machineUri,
  selection,
  customDowntimeFieldReferences,
  customFieldsByUri,
}) => {
  const renderScheduledDowntimes = () => (
    <Col xs={12} sm={machineType === MACHINE_TYPE.PRINTER ? 12 : 6}>
      <DowntimesContainer
        handleSelectionChange={handleSelectionChange}
        machineUri={machineUri}
        customFieldsByUri={customFieldsByUri}
      />
    </Col>
  );

  const renderDowntimeForm = () => (
    <Col xs={12} sm={machineType === MACHINE_TYPE.PRINTER ? 12 : 6}>
      <div style={selection !== 'none' ? styles.border : null}>
        {selection === 'add' && (
          <DowntimeFormContainer
            selection={selection}
            handleSelectionChange={handleSelectionChange}
            machineType={machineType}
            machineUri={machineUri}
            customDowntimeFieldReferences={customDowntimeFieldReferences}
            customFieldsByUri={customFieldsByUri}
          />
        )}
        {isUuid(selection) && (
          <DowntimeFormContainer
            selection={selection}
            downtime={selection}
            handleSelectionChange={handleSelectionChange}
            machineType={machineType}
            machineUri={machineUri}
            customDowntimeFieldReferences={customDowntimeFieldReferences}
            customFieldsByUri={customFieldsByUri}
          />
        )}
        {selection.split('/')[0] === 'DELETE' && (
          <DowntimeDeleteContainer
            handleSelectionChange={handleSelectionChange}
            uuid={selection.split('/')[1]}
          />
        )}
      </div>
    </Col>
  );

  const renderDowntimeLayout = () => {
    if (machineType === MACHINE_TYPE.PRINTER) {
      return (
        <>
          <Row className="mb15">{renderDowntimeForm()}</Row>
          <Row>{renderScheduledDowntimes()}</Row>
        </>

      );
    }
    return (
      <Row>
        {renderScheduledDowntimes()}
        {renderDowntimeForm()}
      </Row>
    );
  };

  return (
    <Row>
      <Col xs={12} className="mb15 mt15">
        <Button
          className="pull-right btn-default"
          onClick={() => handleSelectionChange('add')}
        >
          <FormattedMessage
            id="addNewDowntime"
            defaultMessage="Add New Downtime"
          />
        </Button>
      </Col>
      {renderDowntimeLayout()}
    </Row>
  );
};

Downtime.propTypes = {
  handleSelectionChange: PropTypes.func.isRequired,
  machineType: PropTypes.oneOf(['post-processor', 'printer']).isRequired,
  machineUri: PropTypes.string.isRequired,
  selection: PropTypes.string.isRequired,
  customDowntimeFieldReferences: PropTypes.arrayOf({}).isRequired,
  customFieldsByUri: PropTypes.shape({}).isRequired,
};

export default Downtime;
