import React from 'react';
import { Row, Col, Button } from 'react-bootstrap';

const SentryTest = () => (
  <Row>
    <Col md={{ span: 10, offset: 1 }}>
      <span className="spacer-right">Send Sentry Alert:</span>
      <Button
        size="sm"
        variant="warning"
        onClick={() => {
          throw new Error('SENTRY INTEGRATION TEST');
        }}
      >
        Send
      </Button>
    </Col>
  </Row>
);

export default SentryTest;
