import PermanentContainerNewContainer from 'rapidfab/containers/inventory/PermanentContainerNewContainer';
import PermanentContainerRecordContainer from 'rapidfab/containers/inventory/PermanentContainerRecordContainer';
import PermanentContainersListContainer from 'rapidfab/containers/inventory/PermanentContainersListContainer';
import InventoryMaterials from 'rapidfab/containers/inventory/materials';
import ToolingStockTypesListContainer from 'rapidfab/containers/inventory/ToolingStockTypesListContainer';
import ToolingStocksListContainer from 'rapidfab/containers/inventory/ToolingStocksListContainer';
import PermanentContainerContainers from 'rapidfab/containers/qr/PermanentContainerContainers';
import React from 'react';
import { Route, Routes } from 'react-router-dom';
import InventoryStocks from 'rapidfab/containers/inventory/stocks';
import PropTypes from 'prop-types';
import JeniClustersContainer from 'rapidfab/containers/inventory/JeniClustersContainer';
import { FEATURES } from '../constants';
import { ROUTER_PERMISSION } from '../constantsRouter';
import PrivateRoute from './PrivateRoute';
import MaterialBatchesContainer from '../containers/inventory/MaterialBatchesContainer';
import MaterialLotsContainer from '../containers/inventory/MaterialLotsContainer';
import MaterialBatchContainer from '../containers/records/MaterialBatchContainer';
import QrBatchContainersContainer from '../containers/qr/BatchContainersContainer';
import MaterialBatchGenealogyContainer from '../containers/records/MaterialBatchGenealogyContainer';
import MaterialLotContainer from '../containers/records/MaterialLotContainer';
import MaterialLotNewContainer from '../containers/records/MaterialLotNewContainer';
import QrLotContainersContainer from '../containers/qr/LotContainersContainer';
import NotFound from '../components/404';

const InventoryRoutes = ({ session }) => (
  <Routes>
    <Route
      path="/stocks"
      element={(
        <PrivateRoute
          session={session}
          component={InventoryStocks}
          permission={ROUTER_PERMISSION.BUREAU_ONLY}
          isRestrictedRoute
          features={[FEATURES.MANUFACTURING_EXECUTION_SYSTEM]}
        />
      )}
    />
    <Route
      path="/tools"
      element={(
        <PrivateRoute
          session={session}
          component={ToolingStocksListContainer}
          permission={ROUTER_PERMISSION.BUREAU_ONLY}
          isRestrictedRoute
          features={[FEATURES.TOOLING_STOCK]}
        />
      )}
    />
    <Route
      path="/tool-types"
      element={(
        <PrivateRoute
          session={session}
          component={ToolingStockTypesListContainer}
          permission={ROUTER_PERMISSION.BUREAU_ONLY}
          features={[FEATURES.TOOLING_STOCK]}
        />
      )}
    />
    <Route
      path="/batches"
      element={(
        <PrivateRoute
          session={session}
          component={MaterialBatchesContainer}
          permission={ROUTER_PERMISSION.BUREAU_ONLY}
          features={[FEATURES.MATERIAL_MANAGEMENT]}
        />
      )}
    />
    <Route
      path="/materials"
      element={(
        <PrivateRoute
          session={session}
          component={InventoryMaterials}
          isRestrictedRoute
          permission={ROUTER_PERMISSION.BUREAU_ONLY}
          features={
            [FEATURES.MANUFACTURING_EXECUTION_SYSTEM]
          }
        />
      )}
    />
    <Route
      path="/permanent-containers"
      element={(
        <PrivateRoute
          session={session}
          component={PermanentContainersListContainer}
          permission={ROUTER_PERMISSION.BUREAU_ONLY}
          features={[FEATURES.MATERIAL_MANAGEMENT]}
        />
      )}
    />
    <Route
      path="/permanent-container/:uuid"
      element={(
        <PrivateRoute
          session={session}
          component={PermanentContainerRecordContainer}
          permission={ROUTER_PERMISSION.BUREAU_ONLY}
          features={[FEATURES.MATERIAL_MANAGEMENT]}
        />
      )}
    />
    <Route
      path="/permanent-container/:uuid/qr"
      element={(
        <PrivateRoute
          session={session}
          hideLayout
          component={PermanentContainerContainers}
          permission={ROUTER_PERMISSION.BUREAU_ONLY}
          features={[FEATURES.MATERIAL_MANAGEMENT]}
        />
      )}
    />
    <Route
      path="/permanent-container"
      element={(
        <PrivateRoute
          session={session}
          component={PermanentContainerNewContainer}
          permission={ROUTER_PERMISSION.BUREAU_ONLY}
          features={[FEATURES.MATERIAL_MANAGEMENT]}
        />
      )}
    />
    <Route
      path="/jeni-clusters"
      element={(
        <PrivateRoute
          session={session}
          component={JeniClustersContainer}
          permission={ROUTER_PERMISSION.BUREAU_ONLY}
          features={[FEATURES.JENI_CLUSTER]}
        />
      )}
    />
    <Route
      path="/batch/:uuid"
      element={(
        <PrivateRoute
          session={session}
          component={MaterialBatchContainer}
          permission={ROUTER_PERMISSION.BUREAU_ONLY}
          features={[FEATURES.MATERIAL_MANAGEMENT]}
        />
      )}
    />
    <Route
      path="/batch/:uuid/qr"
      element={(
        <PrivateRoute
          session={session}
          hideLayout
          component={QrBatchContainersContainer}
          permission={ROUTER_PERMISSION.BUREAU_ONLY}
          features={[FEATURES.MATERIAL_MANAGEMENT]}
        />
      )}
    />
    <Route
      path="/batch/:uuid/qr/:container"
      element={(
        <PrivateRoute
          session={session}
          hideLayout
          component={QrBatchContainersContainer}
          permission={ROUTER_PERMISSION.BUREAU_ONLY}
          features={[FEATURES.MATERIAL_MANAGEMENT]}
        />
      )}
    />
    <Route
      path="/batch/:uuid/genealogy"
      element={(
        <PrivateRoute
          session={session}
          component={MaterialBatchGenealogyContainer}
          permission={ROUTER_PERMISSION.BUREAU_ONLY}
          features={[FEATURES.MATERIAL_MANAGEMENT]}
        />
      )}
    />
    <Route
      path="/lots"
      element={(
        <PrivateRoute
          session={session}
          component={MaterialLotsContainer}
          permission={ROUTER_PERMISSION.BUREAU_ONLY}
          features={[FEATURES.MATERIAL_MANAGEMENT]}
        />
      )}
    />
    <Route
      path="/lot/:uuid"
      element={(
        <PrivateRoute
          session={session}
          component={MaterialLotContainer}
          permission={ROUTER_PERMISSION.BUREAU_ONLY}
          features={[FEATURES.MATERIAL_MANAGEMENT]}
        />
      )}
    />
    <Route
      path="/lot"
      element={(
        <PrivateRoute
          session={session}
          component={MaterialLotNewContainer}
          permission={ROUTER_PERMISSION.BUREAU_ONLY}
          features={[FEATURES.MATERIAL_MANAGEMENT]}
        />
      )}
    />
    <Route
      path="/lot/:uuid/qr"
      element={(
        <PrivateRoute
          session={session}
          hideLayout
          component={QrLotContainersContainer}
          permission={ROUTER_PERMISSION.BUREAU_ONLY}
          features={[FEATURES.MATERIAL_MANAGEMENT]}
        />
      )}
    />
    <Route
      path="/lot/:uuid/qr/:container"
      element={(
        <PrivateRoute
          session={session}
          hideLayout
          component={QrLotContainersContainer}
          permission={ROUTER_PERMISSION.BUREAU_ONLY}
          features={[FEATURES.MATERIAL_MANAGEMENT]}
        />
      )}
    />
    <Route
      path="*"
      element={<NotFound />}
    />
  </Routes>
);

InventoryRoutes.propTypes = {
  session: PropTypes.shape({}).isRequired,
};

export default InventoryRoutes;
