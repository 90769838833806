import { doGet } from 'rapidfab/api/makeApi';
import SlicerConfigurations from 'rapidfab/components/inventory/SlicerConfigurations';
import { API_RESOURCES, MIME_TYPES, ROUTES } from 'rapidfab/constants';
import RouterContext from 'rapidfab/context/RouterContext';
import { useFetchMultipleResources } from 'rapidfab/hooks';
import Alert from 'rapidfab/utils/alert';
import React, { useMemo, useState, useContext } from 'react';
import Actions from 'rapidfab/actions';
import { useDispatch, useSelector } from 'react-redux';
import { getIsDebugModeEnabled, getPrinterTypesByUri, getPrintersByUri } from 'rapidfab/selectors';
import _omit from 'lodash/omit';
import { useNavigate } from 'react-router-dom';
import { extractUuid } from 'rapidfab/utils/uuidUtils';
import { getRouteURI } from 'rapidfab/utils/uriUtils';
import { getSlicerConfig } from 'rapidfab/selectors/slicerConfig';
import Config from 'rapidfab/config';
import { FormattedMessage } from 'rapidfab/i18n';
import machineFieldAddedToSlicerConfig from 'rapidfab/selectors/helpers/addMachineToSlicerConfig';
import withRecordsListHandling from '../hocs/withRecordsListHandling';

const SlicerConfigurationsContainer = props => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedFile, setSelectedFile] = useState(null);
  const [isModelUploadLoading, setIsModelUploadLoading] = useState(false);
  const [allSlicerConfigs, setAllSlicerConfigs] = useState([]);
  const [allSlicerConfigsFetched, setAllSlicerConfigsFetched] = useState(false);

  const isDebugModeEnabled = useSelector(getIsDebugModeEnabled);
  const printersByURI = useSelector(getPrintersByUri);
  const printerTypeByURI = useSelector(getPrinterTypesByUri);
  const slicerConfigs = useSelector(getSlicerConfig);
  const slicerConfigSubmitting = useSelector(state =>
    state.ui.nautilus[API_RESOURCES.SLICER_CONFIGURATION_RAW].post.fetching);
  const slicerConfigIsFetching = useSelector(state =>
    slicerConfigSubmitting
    || state.ui.nautilus[API_RESOURCES.SLICER_CONFIGURATION_RAW].list.fetching);

  const { PRINTER, PRINTER_TYPE } = useFetchMultipleResources([
    { resource: API_RESOURCES.PRINTER },
    { resource: API_RESOURCES.PRINTER_TYPE },
  ]);

  const { data: printer, isLoading: printerResourceLoading } = PRINTER;
  const { data: printerType, isLoading: printerTypeResourceLoading } = PRINTER_TYPE;

  const { shouldBlurBackground, setShouldBlurBackground } = useContext(RouterContext);

  const isFileSizeMoreThan5MB = selectedFile?.size > 5_000_000;

  const handleSlicerFormSubmit = formData => {
    const payload = { ...formData };

    const cleansedPayloadForSlicer = _omit(payload, ['machine_type', 'content']);
    setShouldBlurBackground(true);
    dispatch(Actions.Api.nautilus[API_RESOURCES.SLICER_CONFIGURATION_RAW]
      .post(cleansedPayloadForSlicer)).then(response => {
      const { headers } = response;
      const slicerConfigUri = headers.location;
      setIsModelUploadLoading(true);
      if (isFileSizeMoreThan5MB) {
        setIsModalOpen(false);
      }
      dispatch(Actions.FileLoader.setFileLoaderModalState());
      dispatch(Actions.UploadModel.upload(
        headers.uploadLocation,
        selectedFile,
        MIME_TYPES.OCTET_STREAM,
        isFileSizeMoreThan5MB)).then(() => {
        Alert.success(<FormattedMessage id="toaster.slicerConfig.added" defaultMessage="Slicer file succesfully created." />);
        setIsModelUploadLoading(false);
        setShouldBlurBackground(false);
        setIsModalOpen(false);
        setAllSlicerConfigsFetched(false);
        navigate(getRouteURI(ROUTES.SLICER_CONFIGURATIONS_EDIT, { uuid: extractUuid(slicerConfigUri) }, {}, true));
      });
    });
  };

  const machineFieldAddedToSlicerConfigsData =
  machineFieldAddedToSlicerConfig(slicerConfigs, { printerTypeByURI, printersByURI });

  const isFetching = slicerConfigIsFetching
  || printerResourceLoading
  || printerTypeResourceLoading;

  const handleGetAllSlicerConfigs = async () => {
    const slicerUrl = `${Config.HOST.NAUTILUS}/${API_RESOURCES.SLICER_CONFIGURATION_RAW}/`;
    try {
      const configResponse = await doGet(slicerUrl);
      const configData = await configResponse.json();
      setAllSlicerConfigs(configData?.resources);
      setAllSlicerConfigsFetched(true);
    } catch (error) {
      Alert.error(error.message);
    }
  };

  const printersWithNoSlicerConfigAttached = useMemo(() => {
    // Extract URIs of printers that have a slicer config
    const printerURIsWithSlicerConfig = new Set(allSlicerConfigs.map(config => config.printer)
      .filter(Boolean));

    // Filter printers that do not have a slicer config attached
    return printer.filter(printer => !printerURIsWithSlicerConfig.has(printer.uri));
  }, [printer, allSlicerConfigs]);

  const printerTypesWithNoSlicerConfigAttached = useMemo(() => {
    // Extract URIs of printer types that have a slicer config
    const printerTypeURIsWithSlicerConfig = new Set(allSlicerConfigs
      .map(config => config.printer_type)
      .filter(Boolean));

    // Filter printer types that do not have a slicer config attached
    return printerType.filter(printerType => !printerTypeURIsWithSlicerConfig.has(printerType.uri));
  }, [printerType, allSlicerConfigs]);

  const selected = {
    fileSelected: {
      fileValue: selectedFile,
      setFile: setSelectedFile,
    },
    machineResources: {
      printer_type: printerTypesWithNoSlicerConfigAttached,
      printer: printersWithNoSlicerConfigAttached,
    },
    isDebugModeEnabled,
    isFetching,
    isSubmitting: slicerConfigSubmitting,
    isModelUploadLoading,
    modal: {
      isModalOpen,
      handleClosingModal: () => setIsModalOpen(false),
      handleOpeningModal: () => setIsModalOpen(true),
    },
    shouldBlurBackground,
  };

  return (
    <SlicerConfigurations
      {...props}
      {...selected}
      data={machineFieldAddedToSlicerConfigsData}
      fetching={isFetching}
      handleSlicerFormSubmit={handleSlicerFormSubmit}
      getAllSlicerConfigs={handleGetAllSlicerConfigs}
      allSlicerConfigsFetched={allSlicerConfigsFetched}
    />
  );
};
export default withRecordsListHandling(
  SlicerConfigurationsContainer,
  getSlicerConfig,
  [API_RESOURCES.SLICER_CONFIGURATION_RAW],
  {
    defaultLimit: 10,
    searchBy: null,
    multicolumnSearch: true,
    defaultSort: '-updated',
  },
);
