import _map from 'lodash/map';
import _pick from 'lodash/pick';
import { WORK_INSTRUCTION_REPORT_TYPES } from 'rapidfab/constants';

export default function cleanChecklistLinking(
  workChecklists,
  relatedUri,
) {
  return _map(workChecklists, dirtyChecklist => {
    const linkingFields = [
      'position',
      'process_step',
      'work_checklist',
    ];

    const checklistFields = [
      'description',
      'work_instructions',
      'name',
      'uri',
      'additional_instruction_url',
    ];

    const workInstructionFields = [
      'position',
      'description',
      'uuid',
      'additional_instruction_url',
      'report_type',
      'required',
      'report_units',
      'threshold',
      'threshold_type',
      'threshold_notification',
      'threshold_action',
      'choices',
    ];

    const checklistLinking = _pick(dirtyChecklist, linkingFields);

    if (dirtyChecklist.related_uri === relatedUri) {
      // We need to leave uri only if linking is already created for current resource
      // This way backend will know that provided linking is an existing one
      // (and no need to create new)
      checklistLinking.uri = dirtyChecklist.uri;
    }

    checklistLinking.work_checklist = _pick(dirtyChecklist.work_checklist, checklistFields);

    if (checklistLinking.work_checklist.work_instructions.length === 0) {
      // If there isn't any work instructions
      // then it's good to delete this work checklist entirely
      // Changes work instruction to false value,
      // which will be removed by .filter(Boolean)
      return false;
    }

    // If url is not provided, then change value to null field
    // Needed to pass nautilus URI-field validation
    if (checklistLinking.work_checklist.additional_instruction_url === '') {
      checklistLinking.work_checklist.additional_instruction_url = null;
    }

    // Remove unnecessary fields from work instructions as well
    checklistLinking.work_checklist.work_instructions = _map(
      checklistLinking.work_checklist.work_instructions,
      workInstruction => {
        const cleanWorkInstruction = _pick(workInstruction, workInstructionFields);

        // If url is not provided, then change value to null field
        // Needed to pass nautilus URI-field validation
        if (cleanWorkInstruction.additional_instruction_url === '') {
          cleanWorkInstruction.additional_instruction_url = null;
        }

        if (
          ![WORK_INSTRUCTION_REPORT_TYPES.NUMBER, WORK_INSTRUCTION_REPORT_TYPES.RANGE]
            .includes(cleanWorkInstruction.report_type)
        ) {
          // Unset unit field when work instruction is not Number of Range
          cleanWorkInstruction.report_units = null;
        }

        if (
          ![WORK_INSTRUCTION_REPORT_TYPES.TEXT, WORK_INSTRUCTION_REPORT_TYPES.NUMBER]
            .includes(cleanWorkInstruction.report_type)
        ) {
          // Unset thresholds fields when work instruction is not Text or Number
          cleanWorkInstruction.threshold = null;
          cleanWorkInstruction.threshold_notification = null;
          cleanWorkInstruction.threshold_type = null;
          cleanWorkInstruction.threshold_action = null;
        }

        if (
          !cleanWorkInstruction.threshold
          || (
            !cleanWorkInstruction.threshold.value
            && !cleanWorkInstruction.threshold.lower_bound
            && !cleanWorkInstruction.threshold.upper_bound
            && !cleanWorkInstruction.threshold.deviation
          )
        ) {
          // If no value, deviation or lower/upper bound is provided
          // - no need to save any of the thresholds fields
          cleanWorkInstruction.threshold = null;
          cleanWorkInstruction.threshold_notification = null;
          cleanWorkInstruction.threshold_type = null;
          cleanWorkInstruction.threshold_action = null;
        }

        return cleanWorkInstruction;
      },
    );

    return checklistLinking;
  }).filter(Boolean);
}
