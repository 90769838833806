import React from 'react';

const Home = props => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 48 48" {...props}>
    <path
      d="m24 4.99999996-18.00000004 18.00000004v17c0 1.6568543 1.34314576 3 3 3h12.00000004v-12h6v12h12c1.6568543 0 3-1.3431457 3-3v-17z"
      fillRule="evenodd"
    />
  </svg>
);

export default Home;
