import dayjs from 'dayjs';
import PropTypes from 'prop-types';
import Actions from 'rapidfab/actions';
import React, { useEffect, useState } from 'react';

import DowntimeForm from 'rapidfab/components/downtime/DowntimeForm';
import Constants, { API_RESOURCES } from 'rapidfab/constants';
import { useDispatch, useSelector } from 'react-redux';
import { getUsers } from 'rapidfab/selectors';
import _isEmpty from 'lodash/isEmpty';
import _filter from 'lodash/filter';

const DowntimeFormContainer = ({
  downtime,
  machineType,
  machineUri,
  handleSelectionChange,
  customDowntimeFieldReferences,
  customFieldsByUri,
  selection,
}) => {
  const futureTime = dayjs().minute(0).add(2, 'h').format('HH:mm');
  const futureDaysAmount = futureTime === '00:00' ? 2 : 1;
  const futureDate = dayjs().add(futureDaysAmount, 'd').format('YYYY-MM-DD');
  const downtimeResources = useSelector(state => (downtime ? state.resources[downtime] : null));
  const users = useSelector(getUsers);
  const userAssignedToMachineDowntime = users.find(user => user?.uri === downtimeResources?.assigned_user);

  const userAssignedObject = userAssignedToMachineDowntime
    ? { value: userAssignedToMachineDowntime.uri, label: userAssignedToMachineDowntime?.name }
    : null;

  const initialValues = downtimeResources ? {
    description: downtimeResources.description,
    assignedUser: userAssignedObject,
    startDate: dayjs(downtimeResources.start).format(
      'YYYY-MM-DD',
    ),
    startTime: dayjs(downtimeResources.start).format(
      'HH:ss',
    ),
    finishTime: dayjs(downtimeResources.finish).format(
      'HH:ss',
    ),
    finishDate: dayjs(downtimeResources.finish).format(
      'YYYY-MM-DD',
    ),
    customFields: downtimeResources.custom_field_values,
  } : null;

  const [description, setDescription] = useState(() =>
    (initialValues?.description
      ? initialValues.description
      : ''),
  );

  const [assignedUser, setAssignedUser] = useState(initialValues?.assignedUser || null);
  const [startDate, setStartDate] = useState(() =>
    (initialValues?.startDate
      ? initialValues.startDate
      : dayjs()
        .add(1, 'd')
        .format('YYYY-MM-DD')),
  );
  const [startTime, setStartTime] = useState(() =>
    (initialValues?.startTime
      ? initialValues.startTime
      : dayjs()
        .minute(0)
        .add(1, 'h')
        .format('HH:mm')),
  );

  const [finishTime, setFinishTime] = useState(() =>
    (initialValues?.finishTime
      ? initialValues.finishTime
      : futureTime
    ));

  const [finishDate, setFinishDate] = useState(() =>
    (initialValues?.finishDate
      ? initialValues.finishDate
      : futureDate
    ));

  const [pastTimeConfirmation, setPastTimeConfirmation] = useState(false);

  const buildCustomFieldsObjectByUri = customFields => {
    if (_isEmpty(customFields)) {
      return {};
    }
    const result = {};
    customFields.forEach(({ custom_field, value }) => {
      result[custom_field] = { custom_field, value };
    });

    return result;
  };

  const [customFieldsValue, setCustomFieldsValue] = useState(buildCustomFieldsObjectByUri(initialValues?.customFields));

  useEffect(() => {
    if (!initialValues) {
      return;
    }
    setDescription(initialValues.description);
    setStartDate(initialValues.startDate);
    setStartTime(initialValues.startTime);
    setFinishTime(initialValues.finishTime);
    setFinishDate(initialValues.finishDate);
    setAssignedUser(initialValues.assignedUser);
    setCustomFieldsValue(buildCustomFieldsObjectByUri(initialValues.customFields));
  }, [downtime]);

  const handleUpdateDateState = (currentName, currentValue) => {
    switch (currentName) {
      case 'description':
        setDescription(currentValue);
        break;
      case 'owner':
        setAssignedUser(currentValue);
        break;
      case 'startDate':
        setStartDate(currentValue);
        break;
      case 'startTime':
        setStartTime(currentValue);
        break;
      case 'finishTime':
        setFinishTime(currentValue);
        break;
      case 'finishDate':
        setFinishDate(currentValue);
        break;
      case 'custom_field_values':
        setCustomFieldsValue(previous => (
          { ...previous, [currentValue.uri]: { custom_field: currentValue.uri, value: currentValue.value } }));
        break;
      default:
        break;
    }
  };

  const handleAssignUser = (name, value) => {
    handleUpdateDateState(name, value);
  };

  const handleInputChange = event => {
    const { value, name } = event.target;

    // to make sure we are checking the current "start"(date or time) only.
    if (name === 'startDate' || name === 'startTime') {
      const startValueDay = name === 'startDate' ? value : startDate;
      const startValueTime = name === 'startTime' ? value : startTime;
      // to check if the startDate (time of day) is in the past.
      if (!dayjs(`${startValueDay} ${startValueTime}`).isAfter(dayjs())) {
        // to save the current value to be changed, so we could access it in handlePastValue().
        setPastTimeConfirmation({ [name]: value });
      } else {
        handleUpdateDateState(name, value);
      }
    } else {
      handleUpdateDateState(name, value);
    }
  };

  const handleCustomInputChange = (customEventName, { value, customFieldReferenceUri }) => {
    const { type, uri } = customFieldsByUri[customFieldReferenceUri];
    handleUpdateDateState(customEventName, { type, uri, value });
  };
  const dispatch = useDispatch();

  // const fetchAllUsers = async () => {
  //   await dispatch(Actions.Api.nautilus[API_RESOURCES.USERS].list());
  // };

  // useEffect(() => {
  //   fetchAllUsers();
  // }, []);

  const handlePastValue = isConfirmed => {
    // saved value from handleInputChange() if the date(day or time) was in the past.

    if (isConfirmed) {
      const dateKey = Object.keys(pastTimeConfirmation)[0];
      const dateValue = Object.values(pastTimeConfirmation)[0];
      handleUpdateDateState(dateKey, dateValue);
      setPastTimeConfirmation(false);
    } else {
      setPastTimeConfirmation(false);
    }
  };

  const handleSubmit = async event => {
    event.preventDefault();

    const payload = {
      description,
      [machineType]: machineUri,
      finish: dayjs(`${finishDate} ${finishTime}`).toISOString(),
      start: dayjs(`${startDate} ${startTime}`).toISOString(),
    };

    payload.assigned_user = assignedUser ? assignedUser.value : null;

    if (!_isEmpty(customFieldsValue)) {
      payload.custom_field_values = Object.values(customFieldsValue);
    } else {
      // use default values from custom forms data
      const customFieldsWithDefaultValues = _filter(customDowntimeFieldReferences, 'default_value');
      payload.custom_field_values = customFieldsWithDefaultValues.map(
        ({ default_value, uri }) => ({ custom_field: uri, value: default_value }));
    }

    const response = await (initialValues
      ? dispatch(Actions.Api.nautilus[API_RESOURCES.DOWNTIME].put(downtime, payload))
      : dispatch(Actions.Api.nautilus[API_RESOURCES.DOWNTIME].post(payload)));
    if (
      response.type === Constants.RESOURCE_POST_SUCCESS ||
      response.type === Constants.RESOURCE_PUT_SUCCESS
    ) {
      handleSelectionChange('none');
    }
  };

  const getStart = () => dayjs(`${startDate} ${startTime}`);
  const isStartValid = () => dayjs(getStart()).isAfter(dayjs());

  const getFinish = () => dayjs(`${finishDate} ${finishTime}`);
  const isFinishValid = () => getFinish().isAfter(getStart());

  return (
    <DowntimeForm
      initialValues={initialValues}
      downtime={downtime}
      selection={selection}
      handleSelectionChange={handleSelectionChange}
      startTime={startTime}
      startDate={startDate}
      finishTime={finishTime}
      finishDate={finishDate}
      isStartValid={isStartValid}
      isFinishValid={isFinishValid}
      description={description}
      handleSubmit={handleSubmit}
      handleInputChange={handleInputChange}
      handlePastValue={handlePastValue}
      pastTimeConfirmation={pastTimeConfirmation}
      users={users}
      handleAssignUser={handleAssignUser}
      assignedUser={assignedUser}
      customDowntimeFieldReferences={customDowntimeFieldReferences}
      customFieldsValue={customFieldsValue}
      handleCustomInputChange={handleCustomInputChange}
    />
  );
};

DowntimeFormContainer.defaultProps = {
  downtime: null,
};

DowntimeFormContainer.propTypes = {
  downtime: PropTypes.string,
  handleSelectionChange: PropTypes.func.isRequired,
  machineType: PropTypes.oneOf(['post_processor', 'printer']).isRequired,
  machineUri: PropTypes.string.isRequired,
  customDowntimeFieldReferences: PropTypes.arrayOf({}).isRequired,
  customFieldsByUri: PropTypes.shape({}).isRequired,
  selection: PropTypes.string.isRequired,
};

export default DowntimeFormContainer;
