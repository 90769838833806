import Constants from 'rapidfab/constants';

export function storeLocation(location) {
  return {
    type: Constants.STORE_LOCATION,
    location,
  };
}

export function setLocation(location) {
  return dispatch => {
    dispatch(storeLocation(location));
  };
}

export function storeSubLocation(subLocation) {
  return {
    type: Constants.STORE_SUB_LOCATION,
    subLocation,
  };
}

export function setSubLocation(subLocation) {
  return dispatch => {
    dispatch(storeSubLocation(subLocation));
  };
}
