import React from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { isFeatureEnabled } from 'rapidfab/selectors';
import { FEATURES } from 'rapidfab/constants';

import ModelInputDropTarget from 'rapidfab/components/ModelInputDropTarget';

const ModelLibraryInputDropTargetContainer = ({
  onSelectFiles,
  children,
}) => {
  const isZverseConversionFeatureEnabled = useSelector(state =>
    isFeatureEnabled(state, FEATURES.INTEGRATION_ZVERSE));
  const isExpertConversionFeatureEnabled = useSelector(state =>
    isFeatureEnabled(state, FEATURES.DESIGN_CONVERSION_BY_EXPERT));
  const isNativeCadConversionToStlFeatureEnabled = useSelector(state =>
    isFeatureEnabled(state, FEATURES.NATIVE_CAD_TO_STL_CONVERSION));

  return (
    <ModelInputDropTarget
      onSelectFiles={onSelectFiles}
      isZverseConversionSupported={isZverseConversionFeatureEnabled}
      isNativeCadConversionSupported={isNativeCadConversionToStlFeatureEnabled}
      isExpertConversionFeatureEnabled={isExpertConversionFeatureEnabled}
    >
      {children}
    </ModelInputDropTarget>
  );
};

ModelLibraryInputDropTargetContainer.propTypes = {
  onSelectFiles: PropTypes.func.isRequired,
  children: PropTypes.node,
};

ModelLibraryInputDropTargetContainer.defaultProps = {
  children: null,
};

export default ModelLibraryInputDropTargetContainer;
