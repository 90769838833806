import React from 'react';
import { useSelector, useDispatch } from 'react-redux';

import Actions from 'rapidfab/actions';
import * as Selectors from 'rapidfab/selectors';

import DowntimeDelete from 'rapidfab/components/downtime/DowntimeDelete';
import { API_RESOURCES } from 'rapidfab/constants';
import PropTypes from 'prop-types';

const DowntimeDeleteContainer = ({ uuid, handleSelectionChange }) => {
  const printer = useSelector(state => Selectors.getUUIDResource(state, uuid));
  const hasDelete = !!printer;
  const description = printer && printer.description;

  const handleBack = () => {
    handleSelectionChange(uuid);
  };

  const dispatch = useDispatch();

  const handleDelete = async () => {
    const response = await dispatch(Actions.Api.nautilus[API_RESOURCES.DOWNTIME].delete(uuid));
    if (response.type === 'RESOURCE_DELETE_SUCCESS') {
      handleSelectionChange('none');
    }
  };

  return (
    hasDelete && (
      <DowntimeDelete
        description={description}
        handleBack={handleBack}
        handleDelete={handleDelete}
      />
    )
  );
};

DowntimeDeleteContainer.propTypes = {
  handleSelectionChange: PropTypes.func.isRequired,
  uuid: PropTypes.string.isRequired,
};

export default DowntimeDeleteContainer;
