import React from 'react';
import PropTypes from 'prop-types';
import MaskedInput from 'react-text-mask';

import { Button, FormLabel, FormGroup, Modal } from 'react-bootstrap';
import { FormattedMessage } from 'rapidfab/i18n';
import SaveButton from '../SaveButton';

const PrimaryRunDurationModal = ({
  show,
  submit,
  primaryRunDuration,
  loading,
  onHide,
}) => {
  const [inputDuration, setInputDuration] = React.useState(primaryRunDuration);
  React.useEffect(() => {
    setInputDuration(primaryRunDuration);
  }, [primaryRunDuration]);

  const printTimeParts = primaryRunDuration.split(':');
  const maskLength = Math.max(printTimeParts[0].length, 2);
  const hourMask = Array.from({ length: maskLength }).fill(/\d/);
  const minuteMask = Array.from({ length: maskLength }).fill(/\d/);

  const printTimeMask = [...hourMask, ':', ...minuteMask];

  const onSubmit = event => {
    event.preventDefault();
    const formData = new FormData(event.target);
    const values = {};
    [...formData.entries()].forEach(([key, value]) => {
      values[key] = `${value}:00`;
    });
    submit(values);
  };
  const onHideModal = () => {
    setInputDuration(primaryRunDuration);
    onHide();
  };
  return (
    <Modal show={show} onHide={onHideModal} backdrop="static">
      <form onSubmit={onSubmit}>
        <Modal.Header closeButton>
          <FormattedMessage
            id="record.run.setPrimaryRunDuration"
            defaultMessage="Set Primary Run Duration: {mask}"
            values={{ mask: '(HH:MM)' }}
          />
        </Modal.Header>
        <Modal.Body>
          <FormGroup>
            <FormLabel for="time">
              <FormattedMessage
                id="field.duration"
                defaultMessage="Duration"
              />
              :
            </FormLabel>
            <MaskedInput
              id="time"
              name="time"
              className="form-control"
              mask={printTimeMask}
              placeholder="hh:mm"
              value={inputDuration}
              onChange={event => setInputDuration(event.target.value)}
            />
          </FormGroup>
        </Modal.Body>
        <Modal.Footer>
          <Button onClick={onHideModal}>
            <FormattedMessage id="button.cancel" defaultMessage="Cancel" />
          </Button>
          <SaveButton
            showSaveIcon
            isSaving={loading}
            label={<FormattedMessage id="button.submit" defaultMessage="Submit" />}
            variant="success"
          />
        </Modal.Footer>
      </form>
    </Modal>
  );
};

PrimaryRunDurationModal.defaultProps = {
  loading: false,
  show: false,
  submit: () => true,
  onHide: () => true,
};

PrimaryRunDurationModal.propTypes = {
  show: PropTypes.bool,
  primaryRunDuration: PropTypes.string.isRequired,
  onHide: PropTypes.func,
  submit: PropTypes.func,
  loading: PropTypes.bool,
};

export default PrimaryRunDurationModal;
