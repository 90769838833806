import { FormattedMessage } from 'rapidfab/i18n';
import React from 'react';
import Table from 'rapidfab/components/Tables/Table';
import PropTypes from 'prop-types';
import { TOOLING_STOCK_ACTION_TYPES_MAP } from 'rapidfab/mappings';
import { Row, Col, ButtonToolbar, DropdownButton, Dropdown, Card, Button } from 'react-bootstrap';
import _range from 'lodash/range';

const ToolingStockActions = ({
  fetching,
  actions,
  usersByUri,
  showActionModal,
  vendorUserName,
  isVendorUser,
  pagination,
}) => {
  const columns = [
    {
      type: 'translatable',
      uid: 'field.action',
      accessor: 'action_type',
      defaultMessage: 'Action',
      mapping: TOOLING_STOCK_ACTION_TYPES_MAP,
    },
    ...(isVendorUser
      ? []
      : [
        {
          type: 'custom',
          uid: 'field.user',
          accessor: 'user',
          defaultMessage: 'Performed By',
          // TODO: Hack, we don't have access to the VSP user since they're
          // in a different bureau. Until we receive the `service_provider` field on the action
          // from the BE, we have to assume a missing user is the service provider. This will
          // break if a tool is reassigned between multiple VSPs.
          Cell: ({ row: { original: action } }) => (
            <>{usersByUri[action.user]?.name ?? vendorUserName}</>
          ),
        },
      ]),
    {
      type: 'text',
      uid: 'field.notes',
      accessor: 'notes',
      defaultMessage: 'Notes',
    },
    {
      type: 'time',
      uid: 'field.date',
      accessor: 'created',
      defaultMessage: 'Date',
    },
  ];

  const {
    activePage,
    setPage,
    pageLimit,
    totalItems,
    nextPage,
    prevPage,
    totalPaginatedPages,
  } = pagination;

  const renderHeaderView = () => (
    <Row>
      <Col xs={{ span: 12 }} lg={{ span: 10, offset: 1 }} className="d-flex justify-content-end">
        <div className="pagination-panel">
          <ButtonToolbar className="pull-right">
            <Button
              disabled={activePage === 0 || fetching}
              className="spacer-right"
              onClick={prevPage}
            >Prev
            </Button>
            <DropdownButton className="spacer-right" title={`Page ${activePage + 1}`}>
              {_range(0, totalPaginatedPages).map(value => (
                <Dropdown.Item
                  active={value === pageLimit}
                  key={value}
                  eventKey={value}
                  onClick={() => setPage(value)}
                >
                  {value + 1}
                </Dropdown.Item>
              ))}
            </DropdownButton>
            <Button
              disabled={((
                activePage === (totalPaginatedPages - 1) ||
                  // No need to show next button if there are no more printers to show
                  totalItems === 0
              )) || fetching}
              onClick={nextPage}
            >Next
            </Button>
          </ButtonToolbar>
        </div>
      </Col>
    </Row>
  );

  return (
    <Card bg="dark" className="mb15">
      <Card.Header className="pd-exp inverse">
        <FormattedMessage
          id="field.tooling_stock_actions"
          defaultMessage="Tool Actions"
        />
        <Button className="pull-right" onClick={showActionModal}>
          <FormattedMessage
            id="field.tooling_stock.addAction"
            defaultMessage="Add Action"
          />
        </Button>
      </Card.Header>
      <div className="card-body-wrapper">
        <Card.Body>
          {renderHeaderView()}
          <Table
            data={actions}
            columns={columns}
            isFilteringEnabled={false}
            withDefaultPagination={false}
            isUpdatedColumnShown={false}
            initialSortedDesc
            initialSortedColumn="created"
            resetDefaultSortingState
            isFetching={fetching}
          />
        </Card.Body>
      </div>
    </Card>
  );
};

ToolingStockActions.propTypes = {
  actions: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  usersByUri: PropTypes.shape({}).isRequired,
  fetching: PropTypes.bool.isRequired,
  showActionModal: PropTypes.func.isRequired,
  vendorUserName: PropTypes.string.isRequired,
  isVendorUser: PropTypes.bool.isRequired,
  pagination: PropTypes.shape({
    activePage: PropTypes.number.isRequired,
    setPage: PropTypes.func.isRequired,
    pageLimit: PropTypes.number.isRequired,
    totalItems: PropTypes.number.isRequired,
    nextPage: PropTypes.func.isRequired,
    prevPage: PropTypes.func.isRequired,
    totalPaginatedPages: PropTypes.number.isRequired,
  }).isRequired,

  row: PropTypes.shape({
    original: PropTypes.shape({
      user: PropTypes.string,
    }),
  }).isRequired,
};

export default ToolingStockActions;
