import _map from 'lodash/map';
import * as baseStateSelectors from 'rapidfab/selectors/baseStateSelectors';
import { getPredicate, getStateResources } from 'rapidfab/selectors/helpers/base';
import { createSelector } from 'reselect';
import _filter from 'lodash/filter';

export const getThreadsMessages = createSelector(
  [baseStateSelectors.getStateThreadsMessages, getStateResources],
  (uuids, resources) =>
    _map(uuids, uuid => resources[uuid]),
);

export const getThreadsMessagesForResource = createSelector(
  [getPredicate, getThreadsMessages],
  (resourceUuid, messages) => {
    if (!resourceUuid) {
      return [];
    }
    return _filter(messages, { record_uuid: resourceUuid });
  },
);

export const getThreadsMessagesOptimistic = createSelector(
  [getPredicate, getThreadsMessages, (state, optimisticRecords) => optimisticRecords],
  (resourceUuid, threadsMessages, optimisticRecords) => {
    if (!resourceUuid) {
      return [];
    }
    return _filter([...threadsMessages, ...optimisticRecords], { record_uuid: resourceUuid });
  },
);
