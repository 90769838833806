import React from 'react';
import PropTypes from 'prop-types';
import Joyride, { ACTIONS } from 'react-joyride';
import { COLORS } from 'rapidfab/constants';

const joyrideStyles = {
  primaryColor: COLORS.PURPLE,
  width: 500,
};

const Tour = ({ steps, hide, onClose, ...props }) => {
  if (hide) return null;

  const handleJoyrideCallback = ({ action }) => {
    if (action === ACTIONS.CLOSE) onClose();
  };

  return (
    <Joyride
      callback={handleJoyrideCallback}
      steps={steps}
      styles={{ options: joyrideStyles }}
      {...props}
    />
  );
};

Tour.defaultProps = {
  onClose: () => {},
};

Tour.propTypes = {
  steps: PropTypes.arrayOf(PropTypes.shape({
    target: PropTypes.string,
    content: PropTypes.string,
  })).isRequired,
  hide: PropTypes.bool.isRequired,
  onClose: PropTypes.func,
};

export default Tour;
