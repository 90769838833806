import { createSelector } from 'reselect';
import * as baseStateSelectors from 'rapidfab/selectors/baseStateSelectors';
import { getPredicate, getStateResources } from 'rapidfab/selectors/helpers/base';
import _map from 'lodash/map';
import _keyBy from 'lodash/keyBy';
import _filter from 'lodash/filter';
import _orderBy from 'lodash/orderBy';

export const getSecureFileCheckouts = createSelector(
  [baseStateSelectors.getStateSecureFileCheckouts, getStateResources],
  (uuids, resources) => _map(uuids, uuid => resources[uuid]),
);

export const getSecureFileCheckoutsByUri = createSelector(
  [getSecureFileCheckouts], secureFileCheckouts =>
    _keyBy(secureFileCheckouts, 'uri'),
);

export const getSecureFileCheckoutsForRun = createSelector(
  [getSecureFileCheckouts, getPredicate],
  (secureFileCheckouts, runUuid) => {
    if (!runUuid) {
      return [];
    }

    return _filter(secureFileCheckouts, { resource_uuid: runUuid, related_table_name: 'run' });
  },
);
export const getLastSecureFileCheckoutForRun = createSelector(
  [getSecureFileCheckouts, getPredicate],
  (secureFileCheckouts, runUuid) => {
    if (!runUuid) {
      return [];
    }
    const filteredResources = _filter(secureFileCheckouts, { resource_uuid: runUuid, related_table_name: 'run' });
    const orderedResources = _orderBy(filteredResources, 'updated', 'desc');
    return orderedResources[0];
  },
);
