import { ProfileAvatar } from 'rapidfab/components/navbar/ProfileAvatar';
import ProfileMenu from 'rapidfab/components/navbar/ProfileMenu';
import YBugFeedbackButton from 'rapidfab/components/YBugButton';
import React, { useCallback, useRef, useState } from 'react';
import { faEnvelope, faQuestionCircle, faBell } from '@fortawesome/free-regular-svg-icons';
import { faCheck } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import _get from 'lodash/get';
import PropTypes from 'prop-types';
import Actions from 'rapidfab/actions';
import Flag from 'rapidfab/components/flag';
import NonHawkingFeature from 'rapidfab/components/hawking/NonHawkingFeature';
import CurrencyMenu from 'rapidfab/components/navbar/CurrencyMenu';
import UnitsMenu from 'rapidfab/components/navbar/UnitsMenu';
import { FormattedMessage } from 'rapidfab/i18n';
import Permissions from 'rapidfab/permissions';
import * as Selectors from 'rapidfab/selectors';
import {
  Nav,
  NavDropdown,
} from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { API_RESOURCES, COLORS } from 'rapidfab/constants';
import useBureauNotifications from 'rapidfab/hooks/useBureauNotifications';
import Alert from '../../utils/alert';
import ContactUsModal from './ContactUsModal';
import BureauNotifications from './BureauNotifications';

const LanguageFlagMap = {
  da: 'dk',
  de: 'de',
  'en-US': 'us-uk',
  ja: 'jp',
  fr: 'fr',
};

const BureauNotificationsNavBarIcon = ({ hasRecentNotifications, markBureauNotificationsAsRead, setShowDrawer }) => {
  const handleIconClick = () => {
    if (hasRecentNotifications) {
      markBureauNotificationsAsRead();
    }
    setShowDrawer(true);
  };

  return (
    <li id="bureau-notifications-li" role="presentation" className="mr15 ml15">
      {hasRecentNotifications && (
        <span style={{
          display: 'block',
          position: 'relative',
          top: 20,
          left: 10,
          width: 7,
          height: 7,
          backgroundColor: COLORS.RED,
          borderRadius: '50%',
        }}
        />
      )}
      <FontAwesomeIcon
        id="bureau-notifications-icon-btn"
        role="button"
        icon={faBell}
        title="Bureau Notifications"
        onClick={handleIconClick}
      />
    </li>
  );
};

BureauNotificationsNavBarIcon.propTypes = {
  hasRecentNotifications: PropTypes.bool.isRequired,
  markBureauNotificationsAsRead: PropTypes.func.isRequired,
  setShowDrawer: PropTypes.func.isRequired,
};

const NavProfile = ({
  currentUser,
  onChangeLocale,
  onLogout,
  onImpersonateStop,
  isStopImpersonationLoading,
  locale,
  session,
  isServiceProviderRole,
  isHawkingUser,
  showLanguage,
  showUnits,
}) => {
  const flag = LanguageFlagMap[locale];
  const shouldShowImpersonateStop = _get(session, 'currentUser.impersonating');

  const shouldShowAdmin = Permissions.has(
    'nautilus',
    'administrate.group',
    session,
  );

  const shouldShowImpersonate = Permissions.has(
    'nautilus',
    'impersonation',
    session,
  );

  const shouldShowProfile = !isServiceProviderRole;
  const shouldShowLanguage = showLanguage && !isServiceProviderRole;
  const shouldShowCurrency = !isServiceProviderRole;
  const shouldShowUnits = showUnits && !isServiceProviderRole;
  const userName = isServiceProviderRole ? currentUser.username : currentUser.name;

  const [shouldShowProfileMenu, setShouldShowProfileMenu] = useState(false);
  const [showContactUsModal, setShowContactUsModal] = useState(false);
  const [showBureauNotificationsDrawer, setShowBureauNotificationsDrawer] = useState(false);

  const bureauSettings = useSelector(Selectors.getBureauSettings);
  const isDebugModeEnabled = useSelector(Selectors.getIsDebugModeEnabled);
  const avatarRef = useRef(null);

  const { bureauNotifications, hasRecentNotifications, markBureauNotificationsAsRead } = useBureauNotifications();
  const dispatch = useDispatch();

  const documentationPage = session => {
    const XEROX_DOCUMENTATION_PAGE = 'http://docs.elem.business.xerox.com/';
    const AUTHENTISE_DOCUMENTATION_PAGE = 'https://authentise.zendesk.com/hc/en-us';

    // If the user has more than one bureau assigned, bureauSettings will not be populated
    // as it binds to the first bureau in the list.
    // The case of having more than one bureau is not typical.
    if (bureauSettings?.custom_helpdesk_uri) {
      return bureauSettings.custom_helpdesk_uri;
    }

    if (isHawkingUser) return XEROX_DOCUMENTATION_PAGE;
    if (session.isStanleyXUser) return AUTHENTISE_DOCUMENTATION_PAGE;
    if (session.isDanfossUser) return AUTHENTISE_DOCUMENTATION_PAGE;

    return AUTHENTISE_DOCUMENTATION_PAGE;
  };

  const fetchSupportRequestPayload = async (support_request_payload, close) => {
    try {
      await dispatch(
        Actions.Api.nautilus[API_RESOURCES.CONTACT_US].post(
          { support_request_payload },
        ),
      );
      Alert.success(
        <FormattedMessage
          id="toaster.supportReview"
          defaultMessage="Thanks! The Elem Support Team will review your issue and respond as quickly as possible."
        />,
      );
      close();
    } catch {
      Alert.error(<FormattedMessage
        id="toaster.error.supportReview.messageNotSent"
        defaultMessage="Message couldn't be sent."
      />);
    }
  };

  const setShowDebugModeUserInformationModal = () =>
    dispatch(Actions.DebugModeUserInfo.setShowDebugModeUserInfo(true));

  const toggleShowProfileMenu = useCallback(
    () => {
      setShouldShowProfileMenu(previous => !previous);
    },
    [],
  );

  return (
    <>
      <ContactUsModal
        currentUser={currentUser}
        show={showContactUsModal}
        onClose={() => {
          setShowContactUsModal(false);
        }}
        onSubmit={payload => {
          if (payload.support_request_payload.length !== 0) {
            return fetchSupportRequestPayload(payload.support_request_payload, () => setShowContactUsModal(false));
          }
          return Alert.error(<FormattedMessage
            id="toaster.error.supportReview.messageCannotBeEmpty"
            defaultMessage="Message cannot be empty."
          />);
        }}
      />
      <Nav className="flexed-pull-right">
        <BureauNotifications
          bureauNotifications={bureauNotifications}
          show={showBureauNotificationsDrawer}
          onClose={() => setShowBureauNotificationsDrawer(false)}
        />
        <NonHawkingFeature>
          {shouldShowLanguage && (
            <NavDropdown
              // eventKey={2}
              title={<Flag type={flag} />}
              id="uxNavLocale"
              aria-label="Choose locale"
            >
              <NavDropdown.Item
                eventKey={2.1}
                onClick={() => onChangeLocale(locale, 'en-US')}
              >
                <FontAwesomeIcon
                  icon={faCheck}
                  style={{ visibility: flag === 'us-uk' ? null : 'hidden' }}
                />{' '}
                <Flag type="us-uk" /> English
              </NavDropdown.Item>
              <NavDropdown.Item
                eventKey={2.2}
                onClick={() => onChangeLocale(locale, 'de')}
              >
                <FontAwesomeIcon
                  icon={faCheck}
                  style={{ visibility: flag === 'de' ? null : 'hidden' }}
                />{' '}
                <Flag type="de" /> German
              </NavDropdown.Item>
              <NavDropdown.Item
                eventKey={2.3}
                onClick={() => onChangeLocale(locale, 'da')}
              >
                <FontAwesomeIcon
                  icon={faCheck}
                  style={{ visibility: flag === 'dk' ? null : 'hidden' }}
                />{' '}
                <Flag type="dk" /> Danish
              </NavDropdown.Item>
              <NavDropdown.Item
                eventKey={2.4}
                onClick={() => onChangeLocale(locale, 'ja')}
              >
                <FontAwesomeIcon
                  icon={faCheck}
                  style={{ visibility: flag === 'jp' ? null : 'hidden' }}
                />{' '}
                <Flag type="jp" /> 日本語
              </NavDropdown.Item>
              <NavDropdown.Item
                eventKey={2.5}
                onClick={() => onChangeLocale(locale, 'fr')}
              >
                <FontAwesomeIcon
                  icon={faCheck}
                  style={{ visibility: flag === 'fr' ? null : 'hidden' }}
                />{' '}
                <Flag type="fr" /> French
              </NavDropdown.Item>
            </NavDropdown>
          )}
          {shouldShowCurrency && <CurrencyMenu />}
          {shouldShowUnits && <UnitsMenu />}
        </NonHawkingFeature>
        {/* NavItem component not used here because href is not working in react-bootstrap for NavItem */}
        {isHawkingUser ? (
          <li style={{ cursor: 'pointer' }} onClick={() => setShowContactUsModal(true)} role="presentation" className="ml15 mr30">
            {/* Redundant href below to omit the eslint error */}
            <a href={() => setShowContactUsModal(true)}>
              <FontAwesomeIcon icon={faEnvelope} style={{ color: '#cfd2da' }} title="Help" />
            </a>
          </li>
        ) : null}
        <li role="presentation" className="mr15 ml15">
          <a
            href={documentationPage(session)}
            target="_blank"
            rel="noreferrer"
          >
            <FontAwesomeIcon icon={faQuestionCircle} title="Help" />
          </a>
        </li>

        <YBugFeedbackButton />

        <BureauNotificationsNavBarIcon
          hasRecentNotifications={hasRecentNotifications}
          markBureauNotificationsAsRead={markBureauNotificationsAsRead}
          setShowDrawer={setShowBureauNotificationsDrawer}
        />

        <ProfileAvatar
          userName={userName}
          toggleMenu={toggleShowProfileMenu}
          isActive={shouldShowProfileMenu}
          avatarRef={avatarRef}
        />

        <ProfileMenu
          shouldShowProfile={shouldShowProfile}
          shouldShowAdmin={shouldShowAdmin}
          shouldShowImpersonate={shouldShowImpersonate}
          shouldShowImpersonateStop={shouldShowImpersonateStop}
          isDebugModeEnabled={isDebugModeEnabled}
          onImpersonateStop={onImpersonateStop}
          isStopImpersonationLoading={isStopImpersonationLoading}
          setShowDebugModeUserInformationModal={setShowDebugModeUserInformationModal}
          userName={userName}
          userEmail={currentUser.username}
          isVisible={shouldShowProfileMenu}
          onClose={() => setShouldShowProfileMenu(false)}
          onLogout={onLogout}
          avatarRef={avatarRef}
        />

      </Nav>
    </>
  );
};

NavProfile.propTypes = {
  currentUser: PropTypes.shape({
    // Name is empty for Service Provider users
    // https://app.clubhouse.io/authentise/story/28430
    name: PropTypes.string,
    username: PropTypes.string.isRequired,
  }).isRequired,
  locale: PropTypes.string.isRequired,
  onChangeLocale: PropTypes.func.isRequired,
  onLogout: PropTypes.func.isRequired,
  onImpersonateStop: PropTypes.func.isRequired,
  session: PropTypes.shape({
    isStanleyXUser: PropTypes.bool,
    isDanfossUser: PropTypes.bool,
  }).isRequired,
  isServiceProviderRole: PropTypes.bool,
  isHawkingUser: PropTypes.bool,
  showLanguage: PropTypes.bool,
  showUnits: PropTypes.bool,
  isStopImpersonationLoading: PropTypes.bool.isRequired,
};

NavProfile.defaultProps = {
  isServiceProviderRole: false,
  isHawkingUser: false,
  showLanguage: true,
  showUnits: true,
};

export default NavProfile;
