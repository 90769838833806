import { createSelector } from 'reselect';
import * as baseStateSelectors from 'rapidfab/selectors/baseStateSelectors';
import { getStateResources } from 'rapidfab/selectors/helpers/base';

// eslint-disable-next-line import/prefer-default-export
export const getRunEstimates = createSelector(
  [baseStateSelectors.getStateRunEstimates, getStateResources],
  (uuids, resources) => uuids
    .map(uuid => resources[uuid])
    // Filter runs to return ones with estimates only
    // (prevent returning empty runs until event from nautilus received with full info)
    .filter(Boolean),
);
