import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Form, FormControl, OverlayTrigger, Tooltip } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import _map from 'lodash/map';
import _isNil from 'lodash/isNil';
import dayjs from 'dayjs';

import { FEATURES } from 'rapidfab/constants';
import { FormattedDateTime, FormattedMessage, FormattedMessageMappingOption } from 'rapidfab/i18n';
import {
  IP_SENSITIVITY_MAP,
  ORDER_STATUS_MAP,
} from 'rapidfab/mappings';
import { FormControlSelect, FormControlTextCareful } from 'rapidfab/components/formTools';

import Feature from 'rapidfab/components/Feature';
import VisibleFor from 'rapidfab/components/VisibleFor';
import FormRow from 'rapidfab/components/FormRow';
import UserOption from 'rapidfab/components/UserOption';
import CustomFieldList from 'rapidfab/components/forms/CustomFieldList';
import SelectSingleLazy from 'rapidfab/components/forms/SelectSingleLazy';
import OrderPrioritySelector from 'rapidfab/components/records/order/OrderPrioritySelector';
import WholeOrderShipmentCheckbox from 'rapidfab/components/records/order/WholeOrderShipmentCheckbox';
import getOrderFormOptions, { ORDER_FORM_TYPES } from 'rapidfab/utils/orderFormOptions';
import getorderCustomFieldReferencesOverrides from 'rapidfab/utils/orderCustomFieldReferencesOverrides';
import nl2br from 'rapidfab/utils/nl2br';
import getVisibleCustomFieldReferencesWithOptions from 'rapidfab/utils/getVisibleCustomFieldReferencesWithOptions';

import { Field } from 'react-final-form';
import { faQuestionCircle } from '@fortawesome/free-solid-svg-icons';
import { DateInput } from 'rapidfab/components/forms/DateInput';

class EditOrderForm extends Component {
  componentDidMount() {
    if (this.props.customOrderFieldReferences.length) {
      this.props.initCustomFieldValues('custom_field_values');
    }
    this.props.handleGetOrderOwner();
  }

  componentDidUpdate(prevProps) {
    const { customOrderFieldReferences } = this.props;
    if (
      prevProps.customOrderFieldReferences.length !== customOrderFieldReferences.length
    ) {
      this.props.initCustomFieldValues('custom_field_values');
    }
  }

  render() {
    const {
      formValues,
      initialValues,
      created,
      isCreatorGuest,
      creatorName,
      shippings,
      users,
      locationsAvailable,
      isOrderBusinessSegmentFeatureEnabled,
      inputsDisabled,
      customOrderFieldReferences,
      isRestricted,
      isBoeingOrderFieldsFeatureEnabled,
      is3MOrderFieldsFeatureEnabled,
      isBeehiveOrderFieldsFeatureEnabled,
      order,
      hasLineItemsInProductionStage,
      isEatonOrderFieldsEnabled,
      isOrderTemplate,
    } = this.props;

    const isNormalUser = !isRestricted;
    const isDanfossUser = isOrderBusinessSegmentFeatureEnabled;
    const isBoeingUser = isBoeingOrderFieldsFeatureEnabled;
    const is3MUser = is3MOrderFieldsFeatureEnabled;
    const isBeehiveUser = isBeehiveOrderFieldsFeatureEnabled;
    const isEatonUser = isEatonOrderFieldsEnabled;

    const options = getOrderFormOptions({
      isNormalUser,
      isDanfossUser,
      isBoeingUser,
      is3MUser,
      isBeehiveUser,
      isEatonUser,
    }, ORDER_FORM_TYPES.EDIT);

    const orderCustomFieldReferencesOverrides = getorderCustomFieldReferencesOverrides({
      isNormalUser,
      isDanfossUser,
      isBoeingUser,
      is3MUser,
    });

    const visibleOrderCustomFieldReferencesWithOptions = getVisibleCustomFieldReferencesWithOptions({
      fieldReferences: customOrderFieldReferences,
      fieldValues: formValues?.custom_field_values,
      fieldOverrides: orderCustomFieldReferencesOverrides,
    });

    const customerFields = (
      <span>
        {options.customerEmail.show && (
          <FormRow
            id="field.customer_email"
            defaultMessage="Customer Email"
            isRequired={options.customerEmail.required}
          >
            <Field
              name="customer_email"
              parse={value => (_isNil(value) ? null : value)}
              render={props => {
                const field = { ...props.input, ...props.meta };
                return (
                  <FormControlTextCareful
                    {...field}
                    disabled={isOrderTemplate}
                    inputRef={props.ref}
                    required={options.customerEmail.required}
                  />
                );
              }}
            />
          </FormRow>
        )}

        {options.customerName.show && (
          <FormRow
            id={options.customerName.id}
            defaultMessage={options.customerName.defaultMessage}
            isRequired={options.customerName.required}
          >
            <Field
              name="customer_name"
              parse={value => (_isNil(value) ? null : value)}
              render={props => {
                const field = { ...props.input, ...props.meta };
                return (
                  <FormControlTextCareful
                    {...field}
                    disabled={isOrderTemplate}
                    inputRef={props.ref}
                    required={options.customerName.required}
                  />
                );
              }}
            />
          </FormRow>
        )}
      </span>
    );

    return (
      <div>
        <FormRow id="orderName" defaultMessage="Order Name" isRequired>
          <Field
            name="name"
            type="text"
            render={props => (
              <FormControl
                {...props.input}
                disabled={inputsDisabled || isOrderTemplate}
                required
              />
            )}
          />
        </FormRow>

        {options.priority.show && (
          <Feature featureName={FEATURES.PRIORITY}>
            <Field
              name="priority"
              parse={value => Number(value)}
              render={props => (
                <OrderPrioritySelector
                  {...props.input}
                  disabled={inputsDisabled || isOrderTemplate}
                />
              )}
            />
          </Feature>
        )}

        <FormRow id="field.status" defaultMessage="Status">
          <Form.Text disabled={inputsDisabled || isOrderTemplate}>
            {initialValues.status && (
              <FormattedMessage
                id={ORDER_STATUS_MAP[initialValues.status].id}
                defaultMessage={ORDER_STATUS_MAP[initialValues.status].message}
              />
            )}
          </Form.Text>
        </FormRow>

        {options.orderOwner.show && (
          <Feature featureName={FEATURES.ORDER_ORDER_OWNER}>
            <FormRow id="field.owner" defaultMessage="Owner" isRequired={isOrderBusinessSegmentFeatureEnabled}>
              <Field
                name="order_owner"
                initialValue={initialValues?.order_owner}
                render={props => (
                  <SelectSingleLazy
                    name={props.input.name}
                    dataTestId="order_owner"
                    data={users}
                    nullable
                    labelKey="username"
                    disabled={isOrderTemplate}
                    value={props.input.value}
                    handleOnChange={props.input.onChange}
                    imitateOnChangeEvent
                    required={isOrderBusinessSegmentFeatureEnabled}
                    renderLabelCallback={UserOption.generateLabel}
                    onFetchMore={this.props.fetchedUsers ? null : this.props.fetchAllUsers}
                  />
                )}
              />
            </FormRow>
          </Feature>
        )}

        {options.shippingName.show && (
          <FormRow
            id={options.shippingName.id}
            defaultMessage={options.shippingName.defaultMessage}
            isRequired={options.shippingName.required}
          >
            <Field
              name="shipping.name"
              parse={value => (_isNil(value) ? null : value)}
              render={props => {
                const field = { ...props.input, ...props.meta };
                return (
                  <FormControlTextCareful
                    {...field}
                    disabled={isOrderTemplate}
                    inputRef={props.ref}
                    required={options.shippingName.required}
                  />
                );
              }}
            />
          </FormRow>
        )}

        <FormRow
          id={options.shippingAddress.id}
          defaultMessage={options.shippingAddress.defaultMessage}
          isRequired={options.shippingAddress.required}
        >
          <Field
            name="shipping.address"
            parse={value => (_isNil(value) ? null : value)}
            render={props => (
              <FormControl
                as="textarea"
                required={options.shippingAddress.required}
                className="resize-vertical"
                {...props.input}
                disabled={inputsDisabled || isOrderTemplate}
              />
            )}
          />
        </FormRow>

        {options.trackingNumber.show && (
          <FormRow
            id="field.trackingNumber"
            defaultMessage="Shipping/Tracking #"
            isRequired={options.trackingNumber.required}
          >
            <Field
              name="shipping.tracking"
              parse={value => (_isNil(value) ? null : value)}
              render={props => {
                const field = { ...props.input, ...props.meta };
                return (
                  <FormControlTextCareful
                    {...field}
                    disabled={isOrderTemplate}
                    inputRef={props.ref}
                    required={options.trackingNumber.required}
                  />
                );
              }}
            />
          </FormRow>
        )}

        {options.shippingType.show && (
          <FormRow
            id="field.shippingType"
            defaultMessage="Shipping Type"
            isRequired={options.shippingType.required}
          >
            <Field
              name="shipping.uri"
              render={props => (
                <FormControlSelect
                  {...props.input}
                  required={options.shippingType.required}
                  disabled={hasLineItemsInProductionStage || isOrderTemplate}
                >
                  {_map(shippings, shipping => (
                    <option key={shipping.uri} value={shipping.uri}>
                      {shipping.name}
                    </option>
                  ))}
                </FormControlSelect>
              )}
            />
          </FormRow>
        )}

        <Field
          name="shipping_grouping"
          render={props => (
            <VisibleFor unrestricted>
              {initialValues?.shipping?.uri && (
                <Feature featureName={FEATURES.SHIPMENT_FOR_ORDER}>
                  <WholeOrderShipmentCheckbox
                    field={props.input}
                    disabled={hasLineItemsInProductionStage || isOrderTemplate}
                  />
                </Feature>
              )}
            </VisibleFor>
          )}
        />

        {options.created.show && (
          <FormRow id="created" defaultMessage="Created">
            <Form.Text>
              {created ? (
                <span>
                  <FormattedDateTime value={created} />
                  <span>
                    {' '}
                    {dayjs()
                      .tz(dayjs.tz.guess())
                      .format('z')
                      // remove "z" (zulu time) letter at the end of the date
                      .replace('z', '')}{' '}
                  </span>
                </span>
              ) : (
                <em>
                  <FormattedMessage id="notAvailable" defaultMessage="N/A" />
                </em>
              )}
            </Form.Text>
          </FormRow>
        )}

        {options.creator.show && (
          <FormRow id="field.creator" defaultMessage="Created By">
            <Form.Text>
              {creatorName ? (
                <>
                  {isCreatorGuest && (
                    <Feature featureName={FEATURES.SELF_REGISTRATION_ALLOWED}>
                      <>
                        <span className="label label-info">Guest</span>
                        &nbsp;
                      </>
                    </Feature>
                  )}
                  <span>
                    {creatorName}
                  </span>
                </>
              ) : (
                <em>
                  <FormattedMessage id="notAvailable" defaultMessage="N/A" />
                </em>
              )}
            </Form.Text>
          </FormRow>
        )}

        {customerFields}

        <Feature featureNames={[FEATURES.ORDER_LOCATION, FEATURES.ORDER_BUSINESS_SEGMENT]}>
          {options.location.show && (
            <FormRow
              id="field.location"
              defaultMessage="Location"
            >
              <Field
                name="location"
                render={props => (
                  <FormControlSelect
                    {...props.input}
                    disabled={isOrderTemplate}
                  >
                    <FormattedMessage id="field.any" defaultMessage="Any">{text =>
                      <option value="">{text}</option>}
                    </FormattedMessage>
                    {locationsAvailable.map(location => location && (
                      <option key={location.uri} value={location.uri}>
                        {location.name}
                      </option>
                    ))}
                  </FormControlSelect>
                )}
              />
            </FormRow>
          )}
        </Feature>

        <Feature featureName={FEATURES.IP_SENSITIVITY}>
          <FormRow
            id="field.ipSensitivity"
            defaultMessage="IP Sensitivity"
            isRequired={options.ipSensitivity.required}
          >
            <Field
              name="ip_sensitivity"
              parse={value => value}
              render={props => (
                <FormControlSelect
                  {...props.input}
                  required={options.ipSensitivity.required}
                  disabled={inputsDisabled || isOrderTemplate}
                >
                  {options.ipSensitivity.required ? (
                    <FormattedMessage id="field.choose" defaultMessage="Choose…">{text =>
                      <option value="" disabled>{text}</option>}
                    </FormattedMessage>
                  ) : (
                    <FormattedMessage id="field.none" defaultMessage="None">{text =>
                      <option value="none">{text}</option>}
                    </FormattedMessage>
                  )}
                  {Object.keys(IP_SENSITIVITY_MAP).map(ip => (
                    <FormattedMessageMappingOption
                      key={ip}
                      mapping={IP_SENSITIVITY_MAP}
                      value={ip}
                    />
                  ))}
                </FormControlSelect>
              )}
            />
          </FormRow>
        </Feature>

        {order.notes && (
          <FormRow id="field.notes" defaultMessage="Notes">
            <div className="d-flex align-items-center">
              <div>
                {nl2br(order.notes)}
              </div>
              <OverlayTrigger
                placement="bottom"
                overlay={(
                  <Tooltip>
                    <FormattedMessage
                      id="orderNotesTooltip"
                      defaultMessage="Please leave a comment below instead. Notes are for system notifications."
                    />
                  </Tooltip>
                )}
              >
                <FontAwesomeIcon icon={faQuestionCircle} className="d-flex ml30" />
              </OverlayTrigger>
            </div>
          </FormRow>
        )}

        <Feature featureName={FEATURES.ORDER_DUE_DATE}>
          {options.dueDate.show && (
            <FormRow id="field.dueDate" defaultMessage="Due Date" isRequired={options.dueDate.required}>
              <Field
                name="due_date"
                render={props => (
                  <DateInput
                    {...props.input}
                    disabled={inputsDisabled || isOrderTemplate}
                    required={options.dueDate.required}
                    min={options.dueDate.min}
                  />
                )}
              />
            </FormRow>
          )}
        </Feature>

        <CustomFieldList
          customFieldReferences={visibleOrderCustomFieldReferencesWithOptions}
          customFieldValues={formValues?.custom_field_values || []}
          onChange={this.props.onCustomFieldChange}
          readOnly={isOrderTemplate}
        />
      </div>
    );
  }
}

EditOrderForm.defaultProps = {
  creatorName: null,
  inputsDisabled: false,
  isCreatorGuest: false,
};

EditOrderForm.propTypes = {
  created: PropTypes.string.isRequired,
  isCreatorGuest: PropTypes.bool,
  creatorName: PropTypes.string,
  isRestricted: PropTypes.bool.isRequired,
  isOrderBusinessSegmentFeatureEnabled: PropTypes.bool.isRequired,
  isBoeingOrderFieldsFeatureEnabled: PropTypes.bool.isRequired,
  is3MOrderFieldsFeatureEnabled: PropTypes.bool.isRequired,
  isBeehiveOrderFieldsFeatureEnabled: PropTypes.bool.isRequired,
  formValues: PropTypes.shape({
    custom_field_values: PropTypes.arrayOf(PropTypes.shape({})),
  }).isRequired,
  initCustomFieldValues: PropTypes.func.isRequired,
  initialValues: PropTypes.shape({
    custom_field_values: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
    customer_email: PropTypes.string.isRequired,
    customer_name: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    priority: PropTypes.string.isRequired,
    status: PropTypes.string.isRequired,
    order_owner: PropTypes.string.isRequired,
    shipping: PropTypes.shape({
      name: PropTypes.string.isRequired,
      address: PropTypes.string.isRequired,
      tracking: PropTypes.string.isRequired,
      uri: PropTypes.string.isRequired,
    }),
    customer_po: PropTypes.string.isRequired,
    quote_number: PropTypes.string.isRequired,
    location: PropTypes.string.isRequired,
    ip_sensitivity: PropTypes.string.isRequired,
    order_type: PropTypes.string.isRequired,
    region: PropTypes.string.isRequired,
    due_date: PropTypes.string.isRequired,
    shipping_grouping: PropTypes.string.isRequired,
  }).isRequired,
  shippings: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  users: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  locationsAvailable: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  onCustomFieldChange: PropTypes.func.isRequired,
  inputsDisabled: PropTypes.bool,
  order: PropTypes.shape({
    notes: PropTypes.string,
  }).isRequired,
  customOrderFieldReferences: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  hasLineItemsInProductionStage: PropTypes.bool.isRequired,
  fetchedUsers: PropTypes.bool.isRequired,
  isEatonOrderFieldsEnabled: PropTypes.bool.isRequired,
  fetchAllUsers: PropTypes.func.isRequired,
  handleGetOrderOwner: PropTypes.func.isRequired,
  isOrderTemplate: PropTypes.bool.isRequired,
};

export default EditOrderForm;
