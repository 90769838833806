import Constants, { API_RESOURCES } from 'rapidfab/constants';
import _filter from 'lodash/filter';
import _map from 'lodash/map';
import PropTypes from 'prop-types';
import Actions from 'rapidfab/actions';
import Alert from 'rapidfab/utils/alert';
import { pluralWord } from 'rapidfab/utils/stringUtils';
import { extractUuid } from 'rapidfab/utils/uuidUtils';
import React, { createContext, useState } from 'react';
import { useDispatch } from 'react-redux';

export const BulkLineItemActionContext = createContext();

const BulkLineItemActionContextProvider = ({ children, passedValues, ...restContextProps }) => {
  const dispatch = useDispatch();

  const { openLineItemBulkActionModal } = restContextProps;

  const [bulkActionMarkedLineItems, setBulkActionMarkedLineItems] = useState([]);

  const isBulkActionLineItemMarked = lineItemUri =>
    bulkActionMarkedLineItems.some(item => item.uri === lineItemUri);

  const handleMarkLineItemForBulkAction = lineItem => {
    if (isBulkActionLineItemMarked(lineItem.uri)) {
      setBulkActionMarkedLineItems(_filter(bulkActionMarkedLineItems,
        markedLineItem => markedLineItem.uri !== lineItem.uri));
    } else {
      setBulkActionMarkedLineItems(previous => ([...previous, lineItem]));
    }
  };

  const handleMarkAllLineItemsForBulkAction = lineItems => {
    if (bulkActionMarkedLineItems.length === lineItems.length) {
      setBulkActionMarkedLineItems([]);
    } else {
      setBulkActionMarkedLineItems(lineItems);
    }
  };

  const handleDeleteMarkedLineItems = async lineItems => {
    const updatedBulkActionMarkedLineItems = _filter([...bulkActionMarkedLineItems],
      deletedLineItem => lineItems.every(lineItem => lineItem.uri !== deletedLineItem.uri));
    setBulkActionMarkedLineItems(updatedBulkActionMarkedLineItems);

    const markedLineItemUuids = _map(lineItems, lineItem => extractUuid(lineItem.uri));
    const productUuids = _map(lineItems, lineItem => extractUuid(lineItem.product));
    const productUris = _map(lineItems, 'product');
    const response = await dispatch(Actions.Api.nautilus[API_RESOURCES.BULK_DELETE_PRODUCT].post(
      productUris,
    ));

    if (response.type === Constants.RESOURCE_POST_SUCCESS) {
      // Use `.remove()` to optimistically convey frontend changes to the UI.
      await dispatch(Actions.Api.nautilus[API_RESOURCES.LINE_ITEM].remove(markedLineItemUuids));
      await dispatch(Actions.Api.nautilus[API_RESOURCES.PRODUCT].remove(productUuids));
      Alert.success(`Successfully deleted ${lineItems.length} ${pluralWord('Line Item', lineItems)}.`);
    }
  };

  return (
    <BulkLineItemActionContext.Provider value={{
      bulkActionMarkedLineItems,
      setBulkActionMarkedLineItems,
      isBulkActionLineItemMarked,
      handleMarkLineItemForBulkAction,
      openLineItemBulkActionModal,
      handleMarkAllLineItemsForBulkAction,
      handleDeleteMarkedLineItems,
      ...passedValues,
    }}
    >
      {children}
    </BulkLineItemActionContext.Provider>
  );
};

export default BulkLineItemActionContextProvider;

BulkLineItemActionContextProvider.propTypes = {
  children: PropTypes.node.isRequired,
  passedValues: PropTypes.node.isRequired,
};
