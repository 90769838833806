import _find from 'lodash/find';
import _sumBy from 'lodash/sumBy';
import { API_RESOURCES, ORDER_QUOTE_DISCOUNT_TYPES } from 'rapidfab/constants';

export const transformWorkstepQuoteDetailsForProcessStep =
  (processStep, lineItemQuote, lineItemUri, workstepCostEstimates) => {
    /* This function merges the cost-estimate data into the line-item-quote data
    at keys `overhead_cost_per_piece_in_run` and `overhead_cost_per_run` */
    const workstepQuoteDetailsForProcessStep = _find(
      lineItemQuote.work_steps_quote_details,
      { process_step: processStep.uri });
    const workstepCostEstimateForProcessStep = _find(
      workstepCostEstimates,
      { process_step: processStep.uri, line_item: lineItemUri },
    );

    if (workstepQuoteDetailsForProcessStep && workstepCostEstimateForProcessStep) {
      return {
        ...workstepQuoteDetailsForProcessStep,
        overhead_cost_per_piece_in_run:
          workstepCostEstimateForProcessStep.overhead_cost_per_piece_in_run,
        overhead_cost_per_run:
          workstepCostEstimateForProcessStep.overhead_cost_per_run,
      };
    }

    return {
      ...workstepQuoteDetailsForProcessStep,
      overhead_cost_per_piece_in_run:
        workstepQuoteDetailsForProcessStep.overhead_cost_per_piece_in_run,
      overhead_cost_per_run:
        workstepQuoteDetailsForProcessStep.overhead_cost_per_run,
    };
  };

export const isProcessStepTypeEqualTo = (currentProcessType, type) =>
  currentProcessType?.uri?.includes(type);

export const getWorkstationTimePricePerUnit = currentProcessType => {
  if (isProcessStepTypeEqualTo(currentProcessType, API_RESOURCES.POST_PROCESSOR_TYPE)) {
    return currentProcessType?.cost * 60;
  } if (isProcessStepTypeEqualTo(currentProcessType, API_RESOURCES.PRINTER_TYPE)) {
    return currentProcessType?.running_cost_per_hour;
  } if (isProcessStepTypeEqualTo(currentProcessType, API_RESOURCES.SHIPPING)) {
    return currentProcessType?.cost * 60;
  }
  return null;
};

export const convertFromPriceToCount = (price, pricePerCount) =>
  Number((price / pricePerCount).toFixed(2));

export const workStepPricePerLineItem = (step, pieceCount) => (step.in_price ? (
  step.consumable_price_per +
  step.labor_price_per +
  step.machine_price_per +
  (step.overhead_cost_per_piece_in_run * step.number_of_runs * pieceCount) +
  (step.overhead_cost_per_run * step.number_of_runs) +
  step.workstation_price_per +
  _sumBy(step.additional_charges, charge => (charge.price_per_unit * charge.unit_count))
) : 0);

export const calculateTotalQuote = (
  quotes,
  shippingCost,
  markup,
  discount,
  discountType,
  bureauCustomPrice,
) => {
  // eslint-disable-next-line unicorn/no-array-reduce
  const totalsByLineItemUri = quotes.reduce((accumulator, current) => {
    // eslint-disable-next-line unicorn/no-array-reduce
    const total = current.total_price_per;
    accumulator[current.line_item] = total;
    return accumulator;
  }, {});

  const subtotal = Object.values(totalsByLineItemUri)
    // eslint-disable-next-line unicorn/no-array-reduce
    .reduce((accumulator, current) => accumulator + current, shippingCost);

  // Calculate discount
  let discountAmount = 0;
  if (discountType === ORDER_QUOTE_DISCOUNT_TYPES.PERCENTAGE && bureauCustomPrice !== null) {
    discountAmount = (bureauCustomPrice * discount) / 100;
  } else if (discountType === ORDER_QUOTE_DISCOUNT_TYPES.AMOUNT) {
    discountAmount = discount;
  }

  const markupAmount = (subtotal - discountAmount) * (markup / 100);

  const total = subtotal + markupAmount - discountAmount;

  return {
    total,
    subtotal,
    markupAmount,
    discountAmount,
    totalsByLineItemUri,
  };
};
