import React, { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { API_RESOURCES } from 'rapidfab/constants';
import Actions from 'rapidfab/actions';
import PrepTaskProgressItem from 'rapidfab/components/records/order/edit/PrepTaskProgressItem';

const PrepTaskProgressItemContainer = props => {
  const dispatch = useDispatch();
  const onStatusChange = useCallback((prepTaskRecordUuid, status) =>
    dispatch(Actions.Api.nautilus[API_RESOURCES.PREP_TASK_RECORD]
      .put(prepTaskRecordUuid, { status })),
  [dispatch]);

  return (
    <PrepTaskProgressItem {...props} onStatusChange={onStatusChange} />
  );
};

export default PrepTaskProgressItemContainer;
