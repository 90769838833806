import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { getUUIDResource, getRouteUUID } from 'rapidfab/selectors';
import Actions from 'rapidfab/actions';
import Loading from 'rapidfab/components/Loading';
import { getShortUUID } from 'rapidfab/utils/uuidUtils';
import { Col, Container, Row } from 'react-bootstrap';
import BreadcrumbNav from 'rapidfab/components/BreadcrumbNav';
import { getRouteURI } from 'rapidfab/utils/uriUtils';
import { API_RESOURCES, ROUTES } from 'rapidfab/constants';
import QRSticker from 'rapidfab/components/qr/_wrapper';
import BureauBarcodeOutput from 'rapidfab/components/BureauBarcodeOutput';

const ToolingStockQRContainer = () => {
  const dispatch = useDispatch();
  const uuid = useSelector(state => getRouteUUID(state));
  const toolingStock = useSelector(state => getUUIDResource(state, uuid));
  const isLoading = useSelector(
    state =>
      !toolingStock ||
      state.ui.nautilus[API_RESOURCES.TOOLING_STOCK].get.fetching,
  );

  const onInitialize = async uuid => {
    await dispatch(Actions.Api.nautilus[API_RESOURCES.TOOLING_STOCK].get(uuid));
  };

  useEffect(() => {
    if (uuid) {
      onInitialize(uuid).then(() => window.print());
    }
  }, [uuid]);

  return (
    <Container fluid>
      <Row>
        <Col lg={12}>
          {isLoading ? (
            <Loading />
          ) : (
            <>
              <div className="hide-on-print">
                <BreadcrumbNav
                  breadcrumbs={[
                    'toolingStocks',
                    {
                      message: `${toolingStock.name} (${getShortUUID(toolingStock.uuid)})`,
                      href: getRouteURI(ROUTES.TOOLING_STOCKS, {
                        uuid: toolingStock.uuid,
                      }),
                    },
                    `Print ${toolingStock.name} QR Code`,
                  ]}
                />
              </div>

              <QRSticker>
                <div>Tool: {toolingStock.name || 'N/A'}</div>
                <BureauBarcodeOutput url={toolingStock.uri} />
              </QRSticker>
            </>
          )}
        </Col>
      </Row>
    </Container>
  );
};

ToolingStockQRContainer.propTypes = {
  toolingStock: PropTypes.shape({
    name: PropTypes.string,
    uuid: PropTypes.string,
    uri: PropTypes.string,
  }).isRequired,
};

export default ToolingStockQRContainer;
