// eslint-disable-next-line import/prefer-default-export
export const KEY_USERS_FOR_IMPERSONATION = [
  {
    name: 'andre+danfoss',
    username: 'andre+danfoss@authentise.com',
    uri: 'https://data.dev-auth2.com/users/e622b921-8162-4fe9-86ef-b6af507bece2/',
  },
  {
    name: 'andre+xerox+hawking',
    username: 'andre+xerox+hawking@authentise.com',
    uri: 'https://data.dev-auth2.com/users/8d077df4-52a9-4f3c-9f38-ad986ac0de61/',
  },
  {
    name: 'showroom',
    username: 'showroom@authentise.com',
    uri: 'https://data.dev-auth2.com/users/310b9cee-7156-4bfa-8567-7de377042f3b/',
  },
  {
    name: 'andre+beehive3d',
    username: 'andre+beehive3d@authentise.com',
    uri: 'https://data.dev-auth2.com/users/3290bda4-175c-47b8-86af-d01d05139bfc/',
  },
  {
    name: 'andre+stanley+x',
    username: 'andre+stanley+x@authentise.com',
    uri: 'https://data.dev-auth2.com/users/f96b1422-5baf-47af-b053-6f99b78d5148/',
  },
  {
    name: 'andre+nebumind',
    username: 'andre+nebumind@authentise.com',
    uri: 'https://data.dev-auth2.com/users/4de60e11-17c3-4204-b63f-f1e24bbdc7c7/',
  },
];
