import PropTypes from 'prop-types';
import ManageUser from 'rapidfab/components/admin/ManageUser';
import React, { useEffect, useState } from 'react';
import {
  OverlayTrigger,
  Tooltip,
} from 'react-bootstrap';

import { faQuestionCircle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { differenceBy } from 'lodash/array';
import _forEach from 'lodash/forEach';
import _isEqual from 'lodash/isEqual';
import _map from 'lodash/map';
import Actions from 'rapidfab/actions';
import { API_RESOURCES, USER_HIDE_INFO_TYPES, USER_ROLES, USER_ROLES_ITEMS } from 'rapidfab/constants';
import {
  getBureauSettings,
  getCurrentUserRoleMax,
  getLocations,
  getLocationsByUri,
  getSelectedUserLocations,
  getSelectedUserRoleMax,
  getSelectedUserRoles,
  isSessionManager,
} from 'rapidfab/selectors';
import Alert from 'rapidfab/utils/alert';
import { extractUuid } from 'rapidfab/utils/uuidUtils';
import { FormattedMessage } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';

const CURRENT_USER_MANAGER = 'CURRENT_USER_MANAGER';
const SELECTED_USER_MANAGER = 'SELECTED_USER_MANAGER';

const ManageUserContainer = ({
  user,
  csrfToken,
}) => {
  const currentUserRole = useSelector(getCurrentUserRoleMax);
  const selectedUserRoleMax = useSelector(state => user => getSelectedUserRoleMax(state, user));
  const hideFinancialState = selectedUserRoleMax?.hide_info === USER_HIDE_INFO_TYPES.FINANCIAL;
  const getSelectedUserLocation = useSelector(state => getSelectedUserLocations(state, user));
  const locationsBy = useSelector(getLocationsByUri);
  const locations = useSelector(getLocations);
  const isCurrentSessionManager = useSelector(isSessionManager);
  const userRoles = useSelector(state => getSelectedUserRoles(state, user));
  const isRoleSaving = useSelector(state =>
    state.ui.nautilus[API_RESOURCES.ROLE].post.fetching ||
    state.ui.nautilus[API_RESOURCES.ROLE].delete.fetching ||
    state.ui.nautilus[API_RESOURCES.ROLE].put.fetching);

  const [isHideFinancials, setIsHideFinancials] = useState(hideFinancialState);
  const hasGlobalRole = userRoles.find(role => role.role === USER_ROLES.GLOBAL_USER);
  const [confirmGlobal, setConfirmGlobal] = useState(false);
  const [removingGlobal, setRemovingGlobal] = useState(false);
  const [roleFetchState, setRoleFetchState] = useState({
    role: null,
    isFetching: false,
  });

  const [showQrLogonCodeModal, setShowQrLogonCodeModal] = useState(false);
  const [showQrLogonCodeWarningDisclaimerModal, setShowQrLogonCodeWarningDisclaimerModal] = useState(false);
  const [qrCodeBase64Data, setQrCodeBase64Data] = useState(null);
  const [isLoadingQRCodeData, setIsLoadingQRCodeData] = useState(false);

  const transformedLocations = getSelectedUserLocation.map(currentLocation => {
    if (locationsBy[currentLocation.location]) {
      return locationsBy[currentLocation.location];
    }
    return [];
  });
  const selectedUserName = selectedUserRoleMax?.username;
  const selectedUserRole = selectedUserRoleMax?.role;
  const currentUserName = currentUserRole?.username;
  const [dataLocations, setDataLocations] = useState(transformedLocations);
  const [selectedUser, setSelectedUser] = useState(selectedUserName);
  const isCurrentUserManager = currentUserRole?.role === USER_ROLES.MANAGER;
  const isCurrentAndSelectedUserManager = (currentUserRole?.role && selectedUserRole === USER_ROLES.MANAGER) &&
    (currentUserName === selectedUserName);
  const rolesToShow = [...USER_ROLES_ITEMS];
  const updatedUserRoles = new Set(userRoles.map(role => role.role));
  const conditionsRendering =
    (isCurrentAndSelectedUserManager && CURRENT_USER_MANAGER) ||
    (selectedUserRole === USER_ROLES.MANAGER && SELECTED_USER_MANAGER);
  const disableConditions =
    isCurrentAndSelectedUserManager
    || !isCurrentSessionManager
    || isRoleSaving;
  const bureauSettings = useSelector(getBureauSettings);
  const isContactlessLoginEnabled = bureauSettings?.contactless_logon_enabled;

  const dispatch = useDispatch();

  const createRole = payload => dispatch(Actions.Api.nautilus[API_RESOURCES.ROLE].post(payload));
  const deleteRole = uuid => dispatch(Actions.Api.nautilus[API_RESOURCES.ROLE].delete(uuid));
  const dispatchHideFinancials = (uuid, payload) =>
    dispatch(Actions.Api.nautilus[API_RESOURCES.ROLE].put(uuid, payload));
  const getContactlessLogonQRCode = async uuid => {
    const user = await dispatch(Actions.Api.nautilus[API_RESOURCES.USERS]
      .get(uuid, {}, { get_contactless_logon: true }));
    const { qr_logon_code: qrLogonCode } = user.json;
    return qrLogonCode;
  };

  useEffect(() => {
    if (selectedUserName) {
      setSelectedUser(selectedUserName);
    }
  }, [selectedUserName]);

  useEffect(() => {
    if (selectedUserName !== selectedUser) {
      // to clear current data locations state on switch every user
      if (transformedLocations.length) {
        setDataLocations(transformedLocations);
      } else {
        setDataLocations([]);
      }
    }
  }, [selectedUserName]);

  // TRANSFORM CURRENT LOCATION ROLES TO LOCATIONS OBJECTS
  const transformLocationsByAddress = selectedLocations => {
    const result = [];
    const valueToFilter = selectedLocations || locations;
    _forEach(getSelectedUserLocation, item => {
      valueToFilter.forEach(dataItem => {
        if (dataItem.uri === item.location) {
          result.push(dataItem);
        }
      });
    });

    return result;
  };

  // TRANSFORM FROM LOCATION OBJECT TO LOCATION ROLE
  const getTransformedLocationIntoRole = property =>
    getSelectedUserLocation.find(locationRole => locationRole.location === property.uri);

  // DELETE USER ROLE FROM CHECKBOX:
  const removeCurrentSelectedRole = async (rolesList, currentRole) => {
    const currentSelected = rolesList.find(role => role.role === currentRole);

    if (currentSelected) {
      // we have some role selected, and we need to uncheck it
      return new Promise(resolve => {
        deleteRole(currentSelected.uuid);
        resolve();
      });
    }
    return null;
  };

  // TOGGLE SET LOCATION TO THE USER (ADD OR REMOVE)
  const onLocationsChange = async selectedLocations => {
    if (_isEqual(selectedLocations, dataLocations) && !confirmGlobal) return;
    setDataLocations(selectedLocations);

    if (selectedLocations.length === locations.length && !hasGlobalRole && !confirmGlobal) {
      // we've decided to add all locations, so we should transform role to "Global User"
      setConfirmGlobal(true);
    } else {
      const currentSelectedLocationsByAddresses = transformLocationsByAddress(selectedLocations);
      const currentSetLocationsByAddresses = transformLocationsByAddress();
      const uniqueItemsValue = confirmGlobal ? locations : selectedLocations;
      // find the objects which we do not have in the current locations list
      const uniqueItemsToAdd = differenceBy(uniqueItemsValue, currentSelectedLocationsByAddresses, 'uri');
      // find the objects which we have in the current location list but do not have in the list of selected locations
      const itemsToRemove = currentSetLocationsByAddresses
        .filter(locationSet => !selectedLocations.includes(locationSet));

      if (uniqueItemsToAdd.length) {
        try {
          if (uniqueItemsToAdd.length > 1) {
            // we have multiple location roles to add
            const roleCreationPromises = _map(uniqueItemsToAdd, async uniqueItem => (
              createRole({
                username: user.username,
                role: USER_ROLES.LOCATION_USER,
                location: uniqueItem.uri,
                csrf_token: csrfToken,
              })
            ));
            await Promise.all(roleCreationPromises);
          } else {
            await createRole({
              username: user.username,
              role: USER_ROLES.LOCATION_USER,
              location: uniqueItemsToAdd[0].uri,
              csrf_token: csrfToken,
            });
          }
        } finally {
          if (confirmGlobal) {
            setDataLocations(locations);
          }
        }
      }

      if (itemsToRemove.length) {
        if (itemsToRemove.length > 1) {
          // we have multiple location roles to remove
          _forEach(itemsToRemove, async removeItem => {
            await deleteRole(getTransformedLocationIntoRole(removeItem)?.uuid);
          });
        } else {
          await deleteRole(getTransformedLocationIntoRole(itemsToRemove[0])?.uuid);
        }
      }
    }
    setSelectedUser(selectedUserName);
  };

  // CONFIRM SETTING UP THE GLOBAL USER ROLE FROM THE CHECKBOX + ADD ALL LOCATIONS
  const handleConfirmGlobalUserRole = async () => {
    const rolePayload = {
      username: user.username,
      role: USER_ROLES.GLOBAL_USER,
      csrf_token: csrfToken,
    };

    if (!hasGlobalRole) {
      await createRole(rolePayload);
    }
    setConfirmGlobal(false);

    setRoleFetchState({ role: USER_ROLES.GLOBAL_USER, isFetching: true });
    await onLocationsChange(dataLocations);
    setRoleFetchState({ role: USER_ROLES.GLOBAL_USER, isFetching: false });
  };

  // DELETE GLOBAL USER ROLE FROM THE CHECKBOX + REMOVE ALL LOCATIONS
  const handleRemoveGlobalUserRole = async () => {
    await deleteRole(hasGlobalRole?.uuid);
    setRemovingGlobal(false); // This will close the modal.

    setRoleFetchState({ role: USER_ROLES.GLOBAL_USER, isFetching: true });
    const roleDeletionPromises = _map(dataLocations, async locationSelected => (
      deleteRole(getTransformedLocationIntoRole(locationSelected)?.uuid)
    ));
    await Promise.all(roleDeletionPromises);
    setRoleFetchState({ role: USER_ROLES.GLOBAL_USER, isFetching: false });

    setDataLocations([]);
  };

  // TOGGLE BACK TO THE PREVIOUS LOCATIONS SET AND DO NOT SET THE GLOBAL ROLE
  const handleDenySwitchingGlobalUserRole = () => {
    setConfirmGlobal(false);
    setDataLocations(transformLocationsByAddress());
  };

  // TOGGLE USER ROLES FROM CHECKBOX:
  const handleToggleRole = async (event, roleName) => {
    const { name, checked } = event.target;
    if (checked) {
      if (name === USER_ROLES.GLOBAL_USER) {
        // we should warn user if we are checking or unchecking this role and manipulate with locations
        return setConfirmGlobal(true);
      }

      const payload = {
        username: user.username,
        role: name,
        csrf_token: csrfToken,
      };
      try {
        await Promise.resolve(createRole(payload));
      } catch {
        Alert.warning(
          <FormattedMessage
            id="toaster.warning.role.doesNotWork"
            defaultMessage="Sorry, for now setting up the role {roleName} does not work."
            values={{ roleName }}
          />,
        );
        throw new Error(`Sorry, for now setting up the role ${roleName} does not work.`);
      }
    } else {
      if (name === USER_ROLES.GLOBAL_USER && getSelectedUserLocation.length) {
        // we should warn user if he would like to remove all locations in the list as well as remove global user
        return setRemovingGlobal(true);
      }

      await removeCurrentSelectedRole(userRoles, name);
    }
    return null;
  };

  // TOGGLE FINANCIALS STATE FOR 1 ROLE:
  const toggleHideFinancials = () => {
    if (selectedUserRole === USER_ROLES.MANAGER) return;
    setIsHideFinancials(currentState => !currentState);

    if (userRoles.length > 1) {
      // we have multiple user roles and need to set multiple requests
      _forEach(userRoles, currentRole => {
        dispatchHideFinancials(currentRole?.uuid, {
          hide_info: isHideFinancials ? null : USER_HIDE_INFO_TYPES.FINANCIAL,
        });
      });
    } else {
      // we have only one role selected and need to set it
      dispatchHideFinancials(selectedUserRoleMax?.uuid, {
        hide_info: isHideFinancials ? null : USER_HIDE_INFO_TYPES.FINANCIAL,
      });
    }
  };

  // RENDER TOOLTIP TEXT CONDITIONS
  const renderTooltipConditions = (renderFor, type, style = null, position = 'left') => {
    const handlers = {
      [CURRENT_USER_MANAGER]: `${renderFor} cannot be changed if your own role is Manager.`,
      [SELECTED_USER_MANAGER]: `${renderFor} cannot be changed if selected user role is Manager.`,
    };

    if (!handlers[type]) return null;

    return (
      <div style={style}>
        <OverlayTrigger
          placement={position}
          overlay={(
            <Tooltip id={renderFor.toLowerCase().trim()}>
              {handlers[type]}
            </Tooltip>
          )}
        >
          <FontAwesomeIcon
            icon={faQuestionCircle}
            className={renderFor === 'Hide Financials' ? 'spacer-left' : ''}
          />
        </OverlayTrigger>
      </div>
    );
  };

  const handleGetContactlessLogonQRCode = async () => {
    setIsLoadingQRCodeData(true);
    const qrCodeBase64Data = await getContactlessLogonQRCode(extractUuid(user.uri))
      .catch(error => {
        setIsLoadingQRCodeData(false);
        return Alert.error(error);
      });
    if (qrCodeBase64Data) {
      setQrCodeBase64Data(qrCodeBase64Data);
      setShowQrLogonCodeModal(true);
      return setIsLoadingQRCodeData(false);
    }
    return setIsLoadingQRCodeData(false);
  };

  const onUserConfirmGetContactlessLogonQRCode = shouldUserProceed => {
    setShowQrLogonCodeWarningDisclaimerModal(false);
    if (shouldUserProceed) {
      handleGetContactlessLogonQRCode();
    }
  };

  // Grouped Props
  const locationProps = {
    locations,
    disableConditions,
    onLocationsChange,
    dataLocations,
  };

  const qrProps = {
    isContactlessLoginEnabled,
    showQrLogonCodeWarningDisclaimerModal,
    onUserConfirmGetContactlessLogonQRCode,
    showQrLogonCodeModal,
    setShowQrLogonCodeModal,
    qrCodeBase64Data,
    isLoadingQRCodeData,
    setShowQrLogonCodeWarningDisclaimerModal,
  };

  const userProps = {
    user,
    rolesToShow,
    roleFetchState,
    updatedUserRoles,
    isCurrentUserManager,
    selectedUserName,
    currentUserName,
    handleToggleRole,
    isRoleSaving,
    userRoles,
    selectedUserRole,
  };

  const globalRoleProps = {
    confirmGlobal,
    removingGlobal,
    setRemovingGlobal,
    handleConfirmGlobalUserRole,
    handleRemoveGlobalUserRole,
    handleDenySwitchingGlobalUserRole,
  };

  const tooltipProps = {
    renderTooltipConditions,
    conditionsRendering,
  };

  const financialStateProps = {
    hideFinancialState,
    toggleHideFinancials,
  };

  return (
    <ManageUser
      userProps={userProps}
      roleProps={globalRoleProps}
      locationProps={locationProps}
      qrProps={qrProps}
      tooltipProps={tooltipProps}
      financialStateProps={financialStateProps}
    />
  );
};

ManageUserContainer.propTypes = {
  user: PropTypes.shape({
    username: PropTypes.string.isRequired,
    tos: PropTypes.bool.isRequired,
    uri: PropTypes.string.isRequired,
    json: PropTypes.shape({
      qr_logon_code: PropTypes.string,
    }),
  }).isRequired,
  csrfToken: PropTypes.string.isRequired,
};

export default ManageUserContainer;
