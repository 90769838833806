import React from 'react';
import { Button, FormControl, FormGroup, FormLabel, Modal } from 'react-bootstrap';
import { Field, Form } from 'react-final-form';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import _isEmpty from 'lodash/isEmpty';
import Loading from 'rapidfab/components/Loading';
import { FormControlSelect } from 'rapidfab/components/formTools';

const AddMaterialTestOperationModal = ({
  isSaving,
  materialTestUnits,
  onClose,
  handleSaveMaterialTestOperation,
  handleUpdateMaterialTestOperation,
  initialFormValues,
  uuid,
}) => (
  <Modal show onHide={onClose}>
    <Form
      initialValues={initialFormValues}
      validate={formValues => {
        const errors = {};
        if (_isEmpty(formValues.name)) errors.name = 'Please enter a test name.';
        if (_isEmpty(formValues.unit)) errors.unit = 'Please select a test unit.';
        return errors;
      }}
      onSubmit={
        formValues => (
          uuid ? handleUpdateMaterialTestOperation(formValues) : handleSaveMaterialTestOperation(formValues))
      }
      render={({ handleSubmit, values }) => (
        <>
          <Modal.Header closeButton>
            <h2>
              {initialFormValues ?
                initialFormValues?.name : (
                  <FormattedMessage
                    id="addMaterialTest"
                    defaultMessage="Add Material Test"
                  />
                )}
            </h2>
          </Modal.Header>
          <Modal.Body>
            <form onSubmit={handleSubmit}>
              <Field
                name="name"
                render={({ input, meta }) => (
                  <FormGroup>
                    <FormLabel>
                      <FormattedMessage
                        id="field.name"
                        defaultMessage="Name"
                      />: *
                    </FormLabel>
                    <FormControl
                      placeholder="Name"
                      value={input.value}
                      type="text"
                      onChange={input.onChange}
                      required
                    />
                    {meta.error && <span className="mt-2 text-danger">{meta.error}</span>}
                  </FormGroup>
                )}
              />
              <Field
                name="description"
                render={({ input }) => (
                  <FormGroup
                    className="mt15"
                  >
                    <FormLabel>
                      <FormattedMessage
                        id="field.description"
                        defaultMessage="Description"
                      />:
                    </FormLabel>
                    <FormControl
                      placeholder="Description"
                      value={input.value}
                      as="textarea"
                      type="textarea"
                      maxLength={250}
                      onChange={input.onChange}
                      required
                    />
                  </FormGroup>
                )}
              />
              <Field
                name="unit"
                type="select"
                render={({ input, meta }) => (
                  <FormGroup
                    className="mt15"
                  >
                    <div className="d-flex justify-content-between">
                      <FormLabel>
                        <FormattedMessage
                          id="field.units"
                          defaultMessage="Units"
                        />: *
                      </FormLabel>
                      {/* 👇🏼 Not including in MVP */}
                      {/* <Button
                        variant="primary"
                        size="xs"
                        className="mb5"
                        onClick={handleSegueToManageTestUnitsModal}
                      >
                        <FormattedMessage
                          id="materialTests.manageUnits"
                          defaultMessage="Manage Units"
                        />
                        <FontAwesomeIcon
                          className="spacer-left"
                          icon={faExternalLink}
                        />
                      </Button> */}
                      {/* 👆🏼 Not including in MVP */}
                    </div>
                    <FormControlSelect
                      as="select"
                      type="select"
                      defaultValue={values.unit_options}
                      multiple
                      onChange={input.onChange}
                      name="unit"
                      required
                    >
                      {materialTestUnits.map(unit => (
                        <option key={unit.symbol} value={unit.uri}>
                          {unit?.description} ({unit?.symbol})
                        </option>
                      ))}
                    </FormControlSelect>
                    {meta.error && <span className="mt-2 text-danger">{meta.error}</span>}
                  </FormGroup>
                )}
              />
            </form>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="danger" onClick={onClose}>
              <FormattedMessage
                id="button.close"
                defaultMessage="Close"
              />
            </Button>
            <Button
              variant="primary"
              onClick={handleSubmit}
              disabled={isSaving}
            >
              {isSaving ?
                <Loading /> : (
                  <FormattedMessage
                    id="button.save"
                    defaultMessage="Save"
                  />
                )}
            </Button>
          </Modal.Footer>
        </>
      )}
    />
  </Modal>
);

export default AddMaterialTestOperationModal;

AddMaterialTestOperationModal.propTypes = {
  isSaving: PropTypes.bool.isRequired,
  materialTestUnits: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  onClose: PropTypes.func.isRequired,
  handleSaveMaterialTestOperation: PropTypes.func.isRequired,
  initialFormValues: PropTypes.shape({
    name: PropTypes.string,
  }).isRequired,
  handleUpdateMaterialTestOperation: PropTypes.func.isRequired,
  uuid: PropTypes.string.isRequired,
};
