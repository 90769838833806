import ConfirmationModal from 'rapidfab/components/ConfirmationModal';
import FormGroupField from 'rapidfab/components/forms/FormGroupField';
import { DROPDOWN_MODAL_TYPES } from 'rapidfab/constants';
import React from 'react';
import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { FormattedMessage } from 'react-intl';
import {
  Alert,
  ButtonToolbar,
  Dropdown,
  SplitButton,
} from 'react-bootstrap';

import CancelOrDeleteModal from 'rapidfab/components/CancelOrDeleteModal';
import SaveButtonTitle from 'rapidfab/components/SaveButtonTitle';
import Loading from 'rapidfab/components/Loading';
import { faBan, faInfoCircle, faSave, faTrash } from '@fortawesome/free-solid-svg-icons';
import { faClone } from '@fortawesome/free-regular-svg-icons';

const SaveDropdownButton = ({
  isCancelled,
  cancellable,
  handleConfirm,
  handleOpen,
  onSubmit,
  modalType,
  resourceName,
  disabled,
  submitting,
  showDuplicate,
  showSaveAsTemplate,
  setOrderTemplateName,
  orderTemplateName,
  handleCancelTemplateModal,
  isFormDirty,
}) => (
  <ButtonToolbar className={['clearfix', 'order-summary-btn']}>
    <div className="pull-right">
      <span>
        <SplitButton
          id="actions"
          type="submit"
          variant="success"
          size="sm"
          menuRole="menu"
          title={disabled ? <Loading /> : <SaveButtonTitle resourceName={resourceName} />}
          onClick={onSubmit}
          disabled={disabled}
        >
          {showDuplicate && (
            <Dropdown.Item eventKey="2" onClick={() => handleOpen(DROPDOWN_MODAL_TYPES.DUPLICATE)}>
              <FontAwesomeIcon icon={faClone} />
              {' '}
              <FormattedMessage id="button.duplicate" defaultMessage="Duplicate" />
            </Dropdown.Item>
          )}
          {showSaveAsTemplate && (
            <Dropdown.Item eventKey="2" onClick={() => handleOpen(DROPDOWN_MODAL_TYPES.SAVE_AS_TEMPLATE)}>
              <FontAwesomeIcon icon={faClone} />
              {' '}
              <FormattedMessage id="button.saveTemplate" defaultMessage="Save Template" />
            </Dropdown.Item>
          )}
          {cancellable && !isCancelled && (
            <Dropdown.Item eventKey="0" onClick={() => handleOpen(DROPDOWN_MODAL_TYPES.CANCEL)}>
              <FontAwesomeIcon icon={faBan} />
              {' '}
              <FormattedMessage
                id="button.cancelResource"
                defaultMessage="Cancel {resourceName}"
                values={{ resourceName }}
              />
            </Dropdown.Item>
          )}
          <Dropdown.Item eventKey="1" onClick={() => handleOpen(DROPDOWN_MODAL_TYPES.DELETE)}>
            <FontAwesomeIcon icon={faTrash} />
            {' '}
            <FormattedMessage id="button.delete" defaultMessage="Delete" />
          </Dropdown.Item>

        </SplitButton>
      </span>
    </div>
    {(modalType && modalType !== DROPDOWN_MODAL_TYPES.SAVE_AS_TEMPLATE) && (
      <CancelOrDeleteModal
        modalType={modalType}
        handleConfirm={handleConfirm}
        handleOpen={handleOpen}
      />
    )}
    {modalType === DROPDOWN_MODAL_TYPES.SAVE_AS_TEMPLATE && (
      <ConfirmationModal
        handleCancel={handleCancelTemplateModal}
        handleConfirm={handleConfirm}
        isDarkenCustom
        confirmButtonDisabled={submitting}
        title={(
          <>
            <FontAwesomeIcon icon={faClone} className="spacer-right" />
            <FormattedMessage id="button.saveTemplate" defaultMessage="Save Template" />
          </>
        )}
        content={(
          <>
            <p className="font-weight-200">Please type a name for the Order Template. Please be aware that this
              differs from the Order Name.
            </p>
            <FormGroupField
              type="text"
              name="template_name"
              controlId="uxTemplateName"
              value={orderTemplateName}
              onChange={event => setOrderTemplateName(event.target.value)}
              required
              label="Template Name"
            />
            {isFormDirty && (
              <Alert variant="info" className="custom-info-alert">
                <FontAwesomeIcon icon={faInfoCircle} size="2x" className="me-2" />
                <div>
                  <div className="flex-grow-1 font-weight-200 font-size-13">
                    <strong>Please note:</strong> Certain fields on the Order Form have been modified.
                    <p className="mb0">The template will be created using the updated fields.</p>
                  </div>
                </div>
              </Alert>

            )}
          </>
        )}
        customConfirmVariant="success"
        customCancelVariant="default"
        confirmButtonContent={(
          <>
            {submitting ? <Loading inline className="spacer-right" /> : <FontAwesomeIcon icon={faSave} className="spacer-right" />}
            <FormattedMessage id="button.save" defaultMessage="Save" />
          </>
        )}
        cancelButtonContent={
          <FormattedMessage id="button.cancel" defaultMessage="Cancel" />
        }
        swapButtons
      />
    )}
  </ButtonToolbar>
);

SaveDropdownButton.defaultProps = {
  onSubmit: null,
  resourceName: null,
  disabled: false,
  showDuplicate: false,
  showSaveAsTemplate: false,
  submitting: false,
  handleCancelTemplateModal: null,
  setOrderTemplateName: null,
  orderTemplateName: null,
  isFormDirty: false,
};

SaveDropdownButton.propTypes = {
  isCancelled: PropTypes.bool.isRequired,
  cancellable: PropTypes.bool.isRequired,
  handleConfirm: PropTypes.func.isRequired,
  handleOpen: PropTypes.func.isRequired,
  onSubmit: PropTypes.func,
  disabled: PropTypes.bool,
  modalType: PropTypes.string.isRequired,
  resourceName: PropTypes.string,
  showDuplicate: PropTypes.bool,
  showSaveAsTemplate: PropTypes.bool,
  submitting: PropTypes.bool,
  handleCancelTemplateModal: PropTypes.func,
  setOrderTemplateName: PropTypes.func,
  orderTemplateName: PropTypes.string,
  isFormDirty: PropTypes.bool,
};

const SaveDropdownButtonContainer = ({
  onCancel,
  onDelete,
  onDuplicate,
  onSaveAsTemplate,
  actionModalProps,
  ...props
}) => {
  const {
    handleCancelTemplateModal,
    orderTemplateName,
    setOrderTemplateName,
    modalType,
    setModalType,
  } = actionModalProps ?? {};

  const saveAsTemplate = async () => {
    await onSaveAsTemplate(orderTemplateName);
  };

  const handleConfirm = () => {
    const actionMap = {
      [DROPDOWN_MODAL_TYPES.DUPLICATE]: onDuplicate,
      [DROPDOWN_MODAL_TYPES.SAVE_AS_TEMPLATE]: saveAsTemplate,
      [DROPDOWN_MODAL_TYPES.CANCEL]: onCancel,
      [DROPDOWN_MODAL_TYPES.DELETE]: onDelete,
    };

    const action = actionMap[modalType];
    return action && action();
  };

  const handleOpen = type => {
    setModalType(type);
  };

  return (
    <SaveDropdownButton
      {...props}
      handleCancelTemplateModal={handleCancelTemplateModal}
      orderTemplateName={orderTemplateName}
      setOrderTemplateName={setOrderTemplateName}
      modalType={modalType}
      cancellable={!!onCancel}
      handleConfirm={handleConfirm}
      handleOpen={handleOpen}
      showDuplicate={!!onDuplicate}
      showSaveAsTemplate={!!onSaveAsTemplate}
    />
  );
};

SaveDropdownButtonContainer.defaultProps = {
  onCancel: null,
  onSubmit: null,
  onDuplicate: null,
  onSaveAsTemplate: null,
  resourceName: null,
  disabled: false,
  submitting: false,
};

SaveDropdownButtonContainer.propTypes = {
  onCancel: PropTypes.func,
  onDelete: PropTypes.func.isRequired,
  onDuplicate: PropTypes.func,
  onSaveAsTemplate: PropTypes.func,
  onSubmit: PropTypes.func,
  resourceName: PropTypes.string,
  disabled: PropTypes.bool,
  submitting: PropTypes.bool,
  isFormDirty: PropTypes.bool.isRequired,
  actionModalProps: PropTypes.shape({
    handleCancelTemplateModal: PropTypes.func,
    orderTemplateName: PropTypes.string,
    setOrderTemplateName: PropTypes.func,
    modalType: PropTypes.string.isRequired,
    setModalType: PropTypes.func.isRequired,
  }).isRequired,
};

export default SaveDropdownButtonContainer;
