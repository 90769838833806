import React from 'react';
import { useSelector } from 'react-redux';
import { Container } from 'react-bootstrap';

import BreadcrumbNav from 'rapidfab/components/BreadcrumbNav';
import Stepper from 'rapidfab/components/stepper/Stepper';
import OrderSummary from 'rapidfab/components/records/order/restricted/edit/OrderSummary';
import { RESTRICTED_USER_ORDER_STEPS, RESTRICTED_USER_ORDER_STEP_ORDER_DETAILS, FEATURES } from 'rapidfab/constants';
import { prepareStepperURI } from 'rapidfab/utils/uriUtils';
import { extractUuid } from 'rapidfab/reducers/makeApiReducers';
import { getShortUUID } from 'rapidfab/utils/uuidUtils';
import { getRouteUUIDResource, isFeatureEnabled } from 'rapidfab/selectors';

const EditRestrictedOrder = () => {
  const order = useSelector(getRouteUUIDResource);
  const id = order && order.id ? `${order.name} (${order.customer_id || getShortUUID(order.uuid)})` : '';
  const breadcrumbs = ['orders', id];
  const orderUuid = extractUuid(order.uri);

  const isDigitalDesignWarehouseFeatureEnabled = useSelector(
    state => isFeatureEnabled(state, FEATURES.DIGITAL_DESIGN_WAREHOUSE),
  );
  const isRobozeBureauOrderFieldsFeatureEnabled = useSelector(state => isFeatureEnabled(
    state,
    FEATURES.ROBOZE_GIGAFACTORY_BUREAU_ORDER_FIELDS,
  ));
  const isIntegrationCastorFeatureEnabled = useSelector(state => isFeatureEnabled(state, FEATURES.INTEGRATION_CASTOR));

  const robozeDigitalAndCastorFeaturesEnabled =
    isDigitalDesignWarehouseFeatureEnabled &&
    isIntegrationCastorFeatureEnabled &&
    isRobozeBureauOrderFieldsFeatureEnabled;

  return (
    <Container fluid>
      <BreadcrumbNav breadcrumbs={breadcrumbs} />
      <Stepper
        steps={prepareStepperURI(RESTRICTED_USER_ORDER_STEPS, { uuid: orderUuid })}
        activeStep={RESTRICTED_USER_ORDER_STEP_ORDER_DETAILS}
      />
      <OrderSummary
        orderUri={order.uri}
        robozeDigitalAndCastorFeaturesEnabled={robozeDigitalAndCastorFeaturesEnabled}
      />
    </Container>
  );
};

export default EditRestrictedOrder;
