import { faLocationDot } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import PropTypes from 'prop-types';
import { rcTooltipInnerStyleList } from 'rapidfab/constants/styles';
import { truncateText } from 'rapidfab/utils/stringUtils';
import RCTooltip from 'rc-tooltip';
import React from 'react';
import { Button } from 'react-bootstrap';

const LocationUserRoleBadgeIcon = ({ locationRoles, locationsByUri, handleViewUser }) => {
  // Return null if there are no location roles to display
  if (!locationRoles.length) return null;

  // Tooltip overlay content, displaying inline and separated by commas
  const renderTooltipContent = () => {
    const visibleRoles = locationRoles.slice(0, 10); // Show only the first 10 for the tooltip

    return (
      <>
        {visibleRoles.map((role, index) => {
          const location = locationsByUri[role.location];
          if (!location) return null; // Skip if no location found

          return (
            <span key={role.id} className="font-weight-400">
              <FontAwesomeIcon icon={faLocationDot} className="spacer-right" />
              {truncateText(location.name, 45)}
              {index !== visibleRoles.length - 1 && ', '}
            </span>
          );
        })}
        {locationRoles.length > 10 && (
          <Button variant="link" className="users-list-locations-button" onClick={handleViewUser}>
            ...and {locationRoles.length - 10} more
          </Button>
        )}
      </>
    );
  };

  return (
    <RCTooltip
      placement="top"
      overlayClassName="users-list-locations-tooltip"
      id="locationRolesTooltip"
      destroyTooltipOnHide
      overlayInnerStyle={rcTooltipInnerStyleList}
      mouseLeaveDelay={0.4}
      overlay={<div>{renderTooltipContent()}</div>}
    >
      <div className="users-list-locations">
        <span className="users-list-locations-badge">{locationRoles.length}</span>
        <FontAwesomeIcon icon={faLocationDot} fixedWidth className="users-list-locations-icon" />
      </div>
    </RCTooltip>
  );
};

LocationUserRoleBadgeIcon.propTypes = {
  locationRoles: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  locationsByUri: PropTypes.shape({}).isRequired,
  handleViewUser: PropTypes.func.isRequired,
};

export default LocationUserRoleBadgeIcon;
