import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import Actions from 'rapidfab/actions';
import { EVENT_VALUE_MAPPING } from 'rapidfab/utils/tracebilityReportEvents';
import { extractUuid } from 'rapidfab/reducers/makeApiReducers';
import { FormattedMessage } from 'rapidfab/i18n';
import { getUUIDResource } from 'rapidfab/selectors';
import { isUuid } from 'rapidfab/utils/uuidUtils';
import * as Sentry from '@sentry/react';
import CONFIG from 'rapidfab/config';
import { isURIRegex } from 'rapidfab/utils/uriUtils';
import EventResourceLink from 'rapidfab/components/records/piece/event/EventResourceLink';
import { Button } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faImage } from '@fortawesome/free-solid-svg-icons';

const EventValue = ({ value, resource, handleDownload }) => {
  if (!value) {
    return <FormattedMessage id="field.none" defaultMessage="None" />;
  }

  if (typeof value === 'boolean') {
    return value ? 'True' : 'False';
  }

  if (!resource && isURIRegex(value)) {
    return <EventResourceLink url={value} />;
  }

  if (resource && resource.name) {
    const { uri, name, snapshot_content: snapshotContent } = resource;
    if (!snapshotContent) {
      return <EventResourceLink url={uri} relatedResource={resource} />;
    }

    return (
      <span>
        <Button
          className="p-a-0"
          variant="link"
          role="button"
          tabIndex={0}
          onClick={() => handleDownload(value)}
        >
          {name}
        </Button>{' '}
        <a href={snapshotContent} target="_blank" rel="noopener noreferrer">
          <FontAwesomeIcon icon={faImage} />
        </a>
      </span>
    );
  }

  return (
    <span>
      {EVENT_VALUE_MAPPING[value]
        ? EVENT_VALUE_MAPPING[value].defaultMessage
        : value}
    </span>
  );
};

EventValue.propTypes = {
  value: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
    PropTypes.bool,
  ]).isRequired,
  resource: PropTypes.shape({}),
  handleDownload: PropTypes.func.isRequired,
};

EventValue.defaultProps = {
  resource: null,
};

const mapStateToProps = (state, ownProps) => {
  const { value } = ownProps;
  let resource = null;
  try {
    resource = getUUIDResource(state, extractUuid(value));
  } catch {
    // In case extractUuid throws an error - no need to load resource
  }
  return {
    resource,
  };
};

const mapDispatchToProps = dispatch => ({
  handleDownload: uri => {
    let modelUri = uri;

    // This extra check may need to be removed at some point
    // when all the existing events are fixed and proper documentation/tests added
    if (isUuid(modelUri)) {
      Sentry.captureMessage(
        `${uri} UUID is provided as event value in Traceability Report instead or URI.`,
      );
      modelUri = `${CONFIG.HOST.NAUTILUS}/model/${modelUri}`;
    }

    dispatch(Actions.DownloadModel.fetchModel(modelUri)).then(response => {
      dispatch(
        Actions.DownloadModel.downloadContent(
          response.json.name,
          response.json.content,
        ),
      );
    });
  },
});

EventValue.propTypes = {
  resource: PropTypes.shape({
    uri: PropTypes.string,
    name: PropTypes.string,
    snapshot_content: PropTypes.string,
  }),
};

export default connect(mapStateToProps, mapDispatchToProps)(EventValue);
