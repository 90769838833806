import _isUndefined from 'lodash/isUndefined';
import _isEmpty from 'lodash/isEmpty';
import _omit from 'lodash/omit';
import PermanentContainers from 'rapidfab/components/inventory/PermanentContainers';
import { FormattedMessage } from 'rapidfab/i18n';
import { PERMANENT_CONTAINER_STATUS_MAP } from 'rapidfab/mappings';
import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import Actions from 'rapidfab/actions';
import {
  getBatchesByUri,
  getBatchesUrisFromPermanentContainers, getMaterialRestrictionUrisForPermanentContainers,
  getPermanentContainers,
} from 'rapidfab/selectors';

import {
  API_RESOURCES,
} from 'rapidfab/constants';

import { Form, FormLabel } from 'react-bootstrap';
import withRecordsListHandling from 'rapidfab/containers/hocs/withRecordsListHandling';

import { useDispatch, useSelector } from 'react-redux';
import _mapValues from 'lodash/mapValues';
import * as Selectors from 'rapidfab/selectors';
import SelectMultiple from 'rapidfab/components/forms/SelectMultiple';

const PermanentContainersListContainer = ({ filters, onFilterUpdate, ...restProps }) => {
  const fetching = useSelector(state =>
    state.ui.nautilus[API_RESOURCES.LOCATION].list.fetching ||
    state.ui.nautilus[API_RESOURCES.MATERIAL_BATCH].list.fetching ||
    state.ui.nautilus[API_RESOURCES.MATERIAL_CONTAINER].list.fetching ||
    state.ui.nautilus[API_RESOURCES.MATERIAL].list.fetching);

  const bureau = useSelector(state => Selectors.getBureauUri(state));
  const subLocations = useSelector(Selectors.getSubLocations);
  const isDebugModeEnabled = useSelector(Selectors.getIsDebugModeEnabled);
  const materials = useSelector(Selectors.getMaterials);
  const locations = useSelector(state => Selectors.getLocationsByUri(state));
  const permanentContainerBatches = useSelector(getBatchesUrisFromPermanentContainers);
  const materialRestrictionsForPermanentContainer = useSelector(getMaterialRestrictionUrisForPermanentContainers);
  const batchesByUri = useSelector(getBatchesByUri);

  const initialStatusFilterValuesDict = { new: 'New', empty: 'empty', in_use: 'In Use' };
  const initialFilterValuesDictMap = {};

  Object.keys(initialStatusFilterValuesDict).forEach(key => {
    initialFilterValuesDictMap[key] = { id: `status.${key}`, defaultMessage: initialStatusFilterValuesDict[key] };
  });

  const onCheckboxChange = event => {
    const { checked } = event.target;
    let changedFilters = { ...filters };

    if (!checked) {
      changedFilters = _omit(changedFilters, 'archived');
    } else {
      changedFilters.archived = false;
    }

    onFilterUpdate(changedFilters);
  };

  const onPermanentContainerFilterStatusChange = selectedStatuses => {
    if (_isEmpty(selectedStatuses)) {
      onFilterUpdate(_omit(filters, 'status'));
      return;
    }
    const changedFilters = { ...filters };
    changedFilters.status = selectedStatuses.map(statusItem => statusItem.status);
    onFilterUpdate(changedFilters);
  };

  const { archived } = filters;

  const availableStatuses = _mapValues(PERMANENT_CONTAINER_STATUS_MAP, (statusOption, status) => ({
    label: statusOption.defaultMessage,
    status,
  }));

  const {
    status: statuses = [],
  } = filters;

  const selectedStatuses = statuses.map(status => availableStatuses[status]);

  const dispatch = useDispatch();

  const onInitialize = () => {
    dispatch(Actions.Api.nautilus[API_RESOURCES.LOCATION].list());
    dispatch(Actions.Api.nautilus[API_RESOURCES.SUB_LOCATION].list());
  };

  const loadMaterialResources = () => {
    if (permanentContainerBatches.length) {
      dispatch(Actions.Api.nautilus[API_RESOURCES.MATERIAL_BATCH].list(
        { uri: permanentContainerBatches },
        {},
        {},
        {},
        true));
    }

    if (materialRestrictionsForPermanentContainer.length) {
      dispatch(Actions.Api.nautilus[API_RESOURCES.MATERIAL].list(
        { uri: materialRestrictionsForPermanentContainer },
        {},
        {},
        {},
        true));
    }
  };

  useEffect(() => {
    onInitialize();
  }, [bureau]);

  useEffect(() => {
    loadMaterialResources();
  }, [JSON.stringify(permanentContainerBatches), JSON.stringify(materialRestrictionsForPermanentContainer)]);

  return (
    <PermanentContainers
      {...restProps}
      fetching={fetching}
      batches={batchesByUri}
      materials={materials}
      subLocations={subLocations}
      locations={locations}
      isDebugModeEnabled={isDebugModeEnabled}
      extraFilters={[
        <div key="ordersExtraFilters" className="form-inline">
          <div className="form-group mr15 ">
            <FormLabel htmlFor="statusFilter">
              <FormattedMessage
                id="field.status"
                defaultMessage="Status"
              />
            </FormLabel>
            <div className="spacer-left form-control inline-picky-wrapper">
              <SelectMultiple
                title="Status"
                data={Object.values(availableStatuses)}
                labelKey="label"
                valueKey="status"
                selectedData={selectedStatuses}
                handleOnClose={onPermanentContainerFilterStatusChange}
              />
            </div>
            <div className="form-group ml15">
              <Form.Check
                name="archived"
                checked={!archived && !_isUndefined(archived)}
                onChange={onCheckboxChange}
                type="checkbox"
                label="Hide Archived"
              />
            </div>

          </div>
        </div>,
      ]}
    />
  );
};

PermanentContainersListContainer.propTypes = {
  onFilterUpdate: PropTypes.func.isRequired,
  filters: PropTypes.shape({
    status: PropTypes.arrayOf(PropTypes.string),
    archived: PropTypes.bool,
  }).isRequired,
};

export default withRecordsListHandling(
  PermanentContainersListContainer,
  getPermanentContainers,
  ['material-container'],
  {
    defaultLimit: 50,
    staticQueryParams: {
      // Show only disposable containers
      disposable: false,
      archived: false,
    },
    defaultSort: '-created',
    useLocationFiltering: true,
    multicolumnSearch: true,
  },
);
