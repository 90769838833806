export default function isIgnoredError(error) {
  if (error.code === 'unauthorized-no-session') {
    // Skip toaster for this error.
    // This error state is handled by router (user is redirected to login page)
    return true;
  }

  if (error.resource === 'user_sso') {
    // Expected to have USER_SSO errors on login
    // if integration is not enabled.
    // It's handled by login form component
    return true;
  }

  return false;
}
