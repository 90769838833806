import React from 'react';

const Chevron = props => (
  <svg
    viewBox="0 -1 24 44"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M2 2L20 20L2 40"
      stroke="white"
      strokeWidth="2.75"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default Chevron;
