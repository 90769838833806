import { createSelector } from 'reselect';
import * as baseStateSelectors from 'rapidfab/selectors/baseStateSelectors';
import { getStateResources, getPredicate } from 'rapidfab/selectors/helpers/base';
import _map from 'lodash/map';
import _keyBy from 'lodash/keyBy';
import _find from 'lodash/find';

export const getCarousels = createSelector(
  [baseStateSelectors.getStateCarousels, getStateResources],
  (uuids, resources) => _map(uuids, uuid => resources[uuid]),
);

export const getCarouselsByUri = createSelector([getCarousels], modelLibraries =>
  _keyBy(modelLibraries, 'uri'),
);

export const getCarouselForModelLibrary = createSelector(
  [getPredicate, getCarousels],
  (modelLibrary, carousels) => {
    if (!modelLibrary) {
      return [];
    }

    return _find(carousels, ({ parent: modelLibrary.uri }));
  },
);
