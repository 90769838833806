import { createSelector } from 'reselect';
import { getStateResources } from 'rapidfab/selectors/helpers/base';
import _map from 'lodash/map';
import _groupBy from 'lodash/groupBy';
import * as baseStateSelectors from 'rapidfab/selectors/baseStateSelectors';

export const getCastorCostingConfigs = createSelector(
  [baseStateSelectors.getStateCastorCostingConfigs, getStateResources],
  (uuids, resources) => _map(uuids, uuid => resources[uuid]),
);

export const getCastorCostingConfigsByCastorCostingUri = createSelector(
  [getCastorCostingConfigs],
  castorCostingConfigs => _groupBy(castorCostingConfigs, 'castor_costing'),
);
