import _map from 'lodash/map';
import * as baseStateSelectors from 'rapidfab/selectors/baseStateSelectors';
import { getStateResources } from 'rapidfab/selectors/helpers/base';
import { createSelector } from 'reselect';

/**
 * Returns readonly resources used on the runs list page. Joined from two (`Run, Schedule`) tables.
 */
// eslint-disable-next-line import/prefer-default-export
export const getRunWithSchedules = createSelector(
  [baseStateSelectors.getStateRunWithSchedules, getStateResources],
  (uuids, resources) => _map(uuids, uuid => resources[uuid]),
);
