/**
 * @typedef{import("rapidfab/types/tools").ToolLink} ToolLink
 * @typedef{import("rapidfab/types/api").APIResource<ToolLink>} ToolLinkResponse
 */
import Actions from 'rapidfab/actions';
import { API_RESOURCES } from 'rapidfab/constants';
import { getRunToolLinks } from 'rapidfab/selectors/toolLinks';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getToolingStockTypesByUri } from 'rapidfab/selectors/toolingStockType';
import { getToolingStocksByUri } from 'rapidfab/selectors/toolingStock';

/**
 * @param {string} uri
 */
const useToolLinks = uri => {
  const toolLinks = useSelector(state => getRunToolLinks(state, uri));
  const tools = useSelector(getToolingStocksByUri);
  const toolTypes = useSelector(getToolingStockTypesByUri);

  /** @type {boolean} */
  const isFetchingLinks = useSelector(
    state => state.ui.nautilus[API_RESOURCES.TOOLING_LINK].list.fetching,
  );
  /** @type {boolean} */
  const isFetchingTools = useSelector(
    state =>
      toolLinks?.length &&
      (state.ui.nautilus[API_RESOURCES.TOOLING_STOCK_TYPE].list.fetching ||
        state.ui.nautilus[API_RESOURCES.TOOLING_STOCK].list.fetching),
  );
  const dispatch = useDispatch();

  useEffect(() => {
    if (!uri) return;
    const init = async () => {
      /** @type{ToolLinkResponse} */
      const linksResponse = (
        await dispatch(
          Actions.Api.nautilus[API_RESOURCES.TOOLING_LINK].list({ run: uri }),
        )
      ).json;

      if (!linksResponse || !linksResponse.resources.length) return;

      const tools = linksResponse.resources.map(({ tool }) => tool);
      const types = linksResponse.resources.map(({ type }) => type);

      dispatch(
        Actions.Api.nautilus[API_RESOURCES.TOOLING_STOCK].list({ uri: tools }),
      );
      dispatch(
        Actions.Api.nautilus[API_RESOURCES.TOOLING_STOCK_TYPE].list({
          uri: types,
        }),
      );
    };
    init();
  }, [uri, dispatch]);

  const isFetching = isFetchingLinks || isFetchingTools;
  const data = isFetching
    ? undefined
    : {
      tools,
      toolLinks,
      toolTypes,
    };

  return { isFetching, data };
};

export default useToolLinks;
