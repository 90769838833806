import _find from 'lodash/find';
import _map from 'lodash/map';
import _values from 'lodash/values';
import Actions from 'rapidfab/actions';
import SelectMultiple from 'rapidfab/components/forms/SelectMultiple';
import { FormattedMessage } from 'rapidfab/i18n';
import { getLabelsByUri } from 'rapidfab/selectors';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import _difference from 'lodash/difference';

import * as Selectors from 'rapidfab/selectors';
import { API_RESOURCES, FEATURES, WORKFLOW_USAGE_STATES } from 'rapidfab/constants';

import { Form, FormLabel } from 'react-bootstrap';
import { ProductionWorkflows } from 'rapidfab/components/organize';
import withRecordsListHandling from 'rapidfab/containers/hocs/withRecordsListHandling';

const { ARCHIVED, ...NON_ARCHIVED_WORKFLOW_USAGE_STATES } = WORKFLOW_USAGE_STATES;
const nonArchivedWorkflowUsageStatesList = Object.values(NON_ARCHIVED_WORKFLOW_USAGE_STATES);

const ProductionWorkflowsContainer = props => {
  const specimenWorkflowFeatureEnabled = useSelector(state =>
    Selectors.isFeatureEnabled(state, FEATURES.SPECIMEN_LIBRARY));
  const isDebugModeEnabled = useSelector(Selectors.getIsDebugModeEnabled);
  const labelsByUri = useSelector(getLabelsByUri);
  const isGeneralMFGLanguageEnabled = useSelector(
    state => Selectors.isFeatureEnabled(state, FEATURES.GENERAL_MFG_LANGUAGE));

  const dispatch = useDispatch();

  const onCheckboxChange = event => {
    const { checked } = event.target;
    const { onFilterUpdate, filters } = props;
    const { usage_state, ...changedFilters } = filters;

    if (checked) {
      changedFilters.usage_state = nonArchivedWorkflowUsageStatesList;
    }
    onFilterUpdate(changedFilters);
  };

  const { labels: filterLabels } = props.filters;

  const availablelabels = _map(_values(labelsByUri), label => ({
    label: label.name,
    value: label.uri,
  }));

  const selectedLabels = _map(filterLabels, uri =>
    _find(availablelabels, { value: uri }));

  const onLabelChange = selectedLabels => {
    const { onFilterUpdate, filters } = props;
    const changedFilters = { ...filters };

    if (selectedLabels.length) {
      changedFilters.labels = _map(selectedLabels, 'value');
    } else {
      delete changedFilters.labels;
    }
    onFilterUpdate(changedFilters);
  };

  const { usage_state: usageState } = props.filters;

  const onInitialize = () => {
    dispatch(Actions.Api.nautilus[API_RESOURCES.LABEL].list());
  };

  useEffect(() => {
    onInitialize();
  }, []);

  return (
    <ProductionWorkflows
      {...props}
      isDebugModeEnabled={isDebugModeEnabled}
      specimenWorkflowFeatureEnabled={specimenWorkflowFeatureEnabled}
      isGeneralMFGLanguageEnabled={isGeneralMFGLanguageEnabled}
      labelsByUri={labelsByUri}
      extraFilters={[
        <div key="ordersExtraFilters" className="form-inline">
          <div className="form-group mr15 ">
            <FormLabel htmlFor="statusFilter">
              <FormattedMessage
                id="field.label"
                defaultMessage="Label"
              />
            </FormLabel>
            <div className="spacer-left form-control inline-picky-wrapper">
              <SelectMultiple
                title="Label"
                className="run-status__selector"
                data={Object.values(availablelabels)}
                labelKey="label"
                valueKey="value"
                selectedData={selectedLabels}
                handleOnClose={onLabelChange}
              />
            </div>
            <div className="form-group ml15">
              <Form.Check
                name="hideArchived"
                checked={!_difference(nonArchivedWorkflowUsageStatesList, usageState).length}
                onChange={onCheckboxChange}
                type="checkbox"
                label="Hide Archived"
              />
            </div>
          </div>
        </div>,
      ]}
    />
  );
};

ProductionWorkflowsContainer.propTypes = {
  onFilterUpdate: PropTypes.func.isRequired,
  filters: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
};

export default withRecordsListHandling(
  ProductionWorkflowsContainer,
  Selectors.getNonCustomWorkflows,
  ['workflow'],
  {
    staticQueryParams: {
      integrated: false,
    },
  },
);
