import Table from 'rapidfab/components/Tables/Table';
import React from 'react';
import PropTypes from 'prop-types';

const PostProcessorsTable = ({ fetching, postProcessors, columns, renderHeaderView, isDebugModeEnabled }) => (
  <>
    {renderHeaderView()}

    <Table
      isDebugModeEnabled={isDebugModeEnabled}
      tableID="post-processors"
      data={postProcessors}
      columns={columns}
      isFilteringEnabled={false}
      withDefaultPagination={false}
      initialSortedDesc={false}
      initialSortedColumn="name"
      isManualSoringEnabled={false}
      isFetching={fetching}
    />
  </>
);

PostProcessorsTable.propTypes = {
  fetching: PropTypes.bool.isRequired,
  postProcessors: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  columns: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  renderHeaderView: PropTypes.func.isRequired,
  isDebugModeEnabled: PropTypes.bool.isRequired,
};

export default PostProcessorsTable;
