import { createSelector } from 'reselect';
import { getStateToolingStockActions } from 'rapidfab/selectors/baseStateSelectors';
import {
  getStateResources,
  getPredicate,
} from 'rapidfab/selectors/helpers/base';
import _map from 'lodash/map';
import _find from 'lodash/find';
import _filter from 'lodash/filter';

export const getToolingStockActions = createSelector(
  [getStateToolingStockActions, getStateResources],
  (uuids, resources) => _map(uuids, uuid => resources[uuid]),
);

export const getToolingStockActionsByToolingStock = createSelector(
  [getPredicate, getToolingStockActions],
  (stockUri, actions) =>
    _filter(actions, ({ tooling_stock }) => tooling_stock === stockUri),
);

export const getToolingStockActionFromUri = createSelector(
  [getPredicate, getToolingStockActions],
  (stockUri, actions) => _find(actions, ({ uri }) => uri === stockUri),
);
