import cadfileJA from './cadfile/ja.json';
import documentsJA from './documents/ja.json';
import estimatesJA from './estimates/ja.json';
import quoteJA from './quote/ja.json';
import shipmentsJA from './shipments/ja.json';
import summaryJA from './summary/ja.json';

const recordsJA = Object.assign(cadfileJA, documentsJA, estimatesJA, quoteJA, shipmentsJA, summaryJA);

export default recordsJA;
