import { doGet, filtersToQuery } from 'rapidfab/api/makeApi';
import { ANALYTICS_REPORTS_URL } from 'rapidfab/constants';

import Config from 'rapidfab/config';

export default function fetchReport(reportType, startTime, endTime) {
  const hostRoot = Config.HOST.NAUTILUS;
  const filters = {
    start_time: startTime,
    end_time: endTime,
    template: reportType,
    file_format: 'csv',
  };
  const downloadURL = `${hostRoot}/${ANALYTICS_REPORTS_URL}/?${filtersToQuery(filters)}`;
  return {
    type: 'DOWNLOAD_ANALYTICS_REPORT',
    callApi: () => doGet(downloadURL),
  };
}
