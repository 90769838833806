import React, { useEffect, useState } from 'react';
import AddiguruInstallations from 'rapidfab/components/assets/AddiguruInstallations';
import Actions from 'rapidfab/actions';
import { API_RESOURCES, ROUTES } from 'rapidfab/constants';
import { useDispatch, useSelector } from 'react-redux';
import { getAddiguruInstallations } from 'rapidfab/selectors/addiguru';
import { useNavigate, useSearchParams } from 'react-router-dom';
import InstallationModal from 'rapidfab/components/addiguru/installationModal';
import { getRouteURI } from 'rapidfab/utils/uriUtils';

const AddiguruInstallationsContainer = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();
  const [isAddiguruInstallationModalShown, setIsAddiguruInstallationModalShown] = useState(false);

  const addiguruInstallations = useSelector(getAddiguruInstallations);
  const isFetching = useSelector(state =>
    state.ui.nautilus[API_RESOURCES.ADDIGURU_INSTALLATION].list.fetching,
  );

  const onInitialize = () => {
    dispatch(Actions.Api.nautilus[API_RESOURCES.ADDIGURU_INSTALLATION].list());
  };

  useEffect(() => {
    onInitialize();
  }, []);

  const handlecCloseModal = () => {
    setIsAddiguruInstallationModalShown(false);
    searchParams.delete('uuid');
    setSearchParams(searchParams);
    navigate(getRouteURI(ROUTES.ADDIGURU_INSTALLATIONS, null, null, true), { replace: true });
  };

  return (
    <>
      <AddiguruInstallations
        data={addiguruInstallations}
        isFetching={isFetching}
        openAddiguruInstallationModal={() => setIsAddiguruInstallationModalShown(true)}
      />

      <InstallationModal
        closeAddiguruInstallationModal={handlecCloseModal}
        isModalOpen={isAddiguruInstallationModalShown}
      />
    </>

  );
};

AddiguruInstallationsContainer.propTypes = {};

export default AddiguruInstallationsContainer;
