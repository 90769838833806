import { createSelector } from 'reselect';
import { getPredicate } from 'rapidfab/selectors/helpers/base';
import { getComments } from 'rapidfab/selectors/comment';
import _filter from 'lodash/filter';

// eslint-disable-next-line import/prefer-default-export
export const getCommentsForResource = createSelector(
  [getPredicate, getComments],
  (resource, comments) => {
    if (!resource) {
      return [];
    }
    return _filter(comments, { related_uuid: resource.uuid });
  },
);
