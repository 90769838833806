import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import Actions from 'rapidfab/actions';
import { API_RESOURCES, PAGINATION_IGNORE_DEFAULT_LIMIT } from 'rapidfab/constants';
import Loading from 'rapidfab/components/Loading';
import {
  getServiceProviders,
  getProcessProvidersByServiceProviderUriForPostProcessor,
  isServiceProvider,
} from 'rapidfab/selectors';
import ProcessProviders from 'rapidfab/components/records/ProcessProviders';

const PostProcessorProvidersContainer = props => {
  const loading = useSelector(state =>
    state.ui.nautilus[API_RESOURCES.PROCESS_PROVIDER].list.fetching
    || state.ui.nautilus[API_RESOURCES.SERVICE_PROVIDER].list.fetching);
  const serviceProviders = useSelector(getServiceProviders);
  const processProvidersByServiceProviderUri =
    useSelector(state => getProcessProvidersByServiceProviderUriForPostProcessor(state, props.processorService.uri));
  const isServiceProviderRole = useSelector(isServiceProvider);

  const dispatch = useDispatch();

  useEffect(() => {
    const { processorService } = props;
    dispatch(Actions.Api.nautilus['service-provider'].list(
      {},
      { limit: PAGINATION_IGNORE_DEFAULT_LIMIT },
      {},
      {},
      true,
    ));
    dispatch(Actions.Api.nautilus['process-provider'].list(
      { post_processor: processorService.uri },
      { limit: PAGINATION_IGNORE_DEFAULT_LIMIT },
    ));
  }, []);

  const { processorService } = props;

  if (loading) {
    return (<Loading />);
  }

  return (
    <ProcessProviders
      serviceProviders={serviceProviders}
      processorUri={processorService.uri}
      processProvidersByServiceProviderUri={processProvidersByServiceProviderUri}
      isServiceProviderRole={isServiceProviderRole}
    />
  );
};

PostProcessorProvidersContainer.defaultProps = {};

PostProcessorProvidersContainer.propTypes = {
  processorService: PropTypes.shape({
    uri: PropTypes.string,
    name: PropTypes.string,
    location: PropTypes.string,
    post_processor_type: PropTypes.string,
    duration: PropTypes.number,
  }).isRequired,
};

export default PostProcessorProvidersContainer;
