import _sortBy from 'lodash/sortBy';
import { createSelector } from 'reselect';
import * as baseStateSelectors from 'rapidfab/selectors/baseStateSelectors';
import { getPredicate, getStateResources } from 'rapidfab/selectors/helpers/base';
import _map from 'lodash/map';
import _filter from 'lodash/filter';
import _keyBy from 'lodash/keyBy';

export const getPrinterTypes = createSelector(
  [baseStateSelectors.getStatePrinterTypes, getStateResources],
  (uuids, resources) => {
    const printerTypes = _map(uuids, uuid => resources[uuid]);
    return _filter(printerTypes, { is_template: false });
  },
);

export const getPrinterTypesByUri = createSelector(
  [getPrinterTypes],
  printerTypes => _keyBy(printerTypes, 'uri'),
);

export const getPrinterTypesForLineItem = createSelector(
  [getPredicate, getPrinterTypes],
  (lineItem, printerTypes) => {
    if (!lineItem) {
      return [];
    }

    return printerTypes.filter(printerType =>
      printerType.materials.includes(lineItem?.additive?.materials.base) ||
      printerType.materials.includes(lineItem?.additive?.materials.support));
  },
);

export const getSortedPrinterTypesByName = createSelector(
  [getPrinterTypes],
  printerTypes =>
    _sortBy(printerTypes, printerType => printerType.name.toLowerCase()),
);
