import {
  faExclamationTriangle,
  faFloppyDisk,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import isEqual from 'lodash/isEqual';
import PropTypes from 'prop-types';
import CustomMultiSelect from 'rapidfab/components/forms/CustomMultiSelect';
import { FormControlSelect } from 'rapidfab/components/formTools';
import Loading from 'rapidfab/components/Loading';
import { pluralWord } from 'rapidfab/utils/stringUtils';
import React from 'react';
import {
  Button,
  ButtonToolbar,
  Card,
  FormControl, FormGroup, FormLabel, OverlayTrigger,
  Row, Tooltip,
} from 'react-bootstrap';
import { Field, Form } from 'react-final-form';
import { FormattedMessage } from 'react-intl';

const PermanentContainerRecordForm = ({
  onSubmit,
  isSubmitting,
  initialFormValues,
  loadSubLocations,
  onSelectAllMaterials,
  onDeselectAllMaterials,
  locationData,
  materialData,
}) => {
  const {
    locations,
    subLocations,
  } = locationData;

  const {
    materials,
  } = materialData;

  return (
    <Form
      onSubmit={onSubmit}
      initialValues={initialFormValues}
      initialValuesEqual={isEqual}
      mutators={{
        onSelectAllMaterials,
        onDeselectAllMaterials,
      }}
      render={({ handleSubmit, form, values }) => {
        const materialRestrictionsLength = values?.material_restrictions?.length;
        const dynamicAllowedMaterialsLabel = materialRestrictionsLength
          ? `${materialRestrictionsLength} ${pluralWord('Material', values.material_restrictions)} Selected`
          : 'All Materials Allowed';
        return (
          <form onSubmit={handleSubmit}>
            <Row>
              <Card className="mb15" bg="dark">
                <Card.Header className="pd-exp accent d-flex align-items-center justify-content-between">
                  <FormattedMessage
                    id="field.permanentContainer"
                    defaultMessage="Permanent Container Details"
                  />
                  <ButtonToolbar className="pull-right">
                    <Button
                      type="submit"
                      disabled={isSubmitting}
                      size="xs"
                      variant="primary"
                      onClick={() => {}}
                    >
                      {
                        isSubmitting ?
                          <Loading /> :
                          <FontAwesomeIcon icon={faFloppyDisk} />
                      }
                    </Button>
                  </ButtonToolbar>
                </Card.Header>
                <div className="card-body-wrapper-accent">
                  <Card.Body>
                    <FormGroup className="form-group" controlId="uxName">
                      <FormLabel>
                        <FormattedMessage id="field.name" defaultMessage="Name" />: *
                      </FormLabel>
                      <Field
                        name="name"
                        type="text"
                        render={props => (
                          <FormControl
                            {...props.input}
                            required
                          />
                        )}
                      />
                    </FormGroup>
                    <FormGroup className="form-group" controlId="uxFamily">
                      {/* Commented until we might require it back, hidden at this iteration */}
                      {/* <Button */}
                      {/*  className="spacer-left btn-xs" */}
                      {/*  data-cy="select-all-materials-button" */}
                      {/*  variant={isAllMaterialsButtonSelected ? 'link' : 'info'} */}
                      {/*  onClick={() => { */}
                      {/*    setIsAllMaterialsButtonSelected(!isAllMaterialsButtonSelected); */}
                      {/*    if (isAllMaterialsButtonSelected) form.mutators.onDeselectAllMaterials(); */}
                      {/*    else form.mutators.onSelectAllMaterials(); */}
                      {/*  }} */}
                      {/* > */}
                      {/*  {isAllMaterialsButtonSelected ? 'Deselect all materials' : 'Select all materials'} */}
                      {/* </Button> */}

                      <Field
                        name="material_restrictions"
                        render={({ input }) => (
                          <CustomMultiSelect
                            value={input.value}
                            onChange={input.onChange}
                            isCardMode
                            formLabel={(
                              <FormLabel
                                className="mb10"
                                style={!materialRestrictionsLength ? { color: '#1ac98e' } : {}}
                              >
                                {dynamicAllowedMaterialsLabel}
                              </FormLabel>
                            )}
                            title={(
                              <FormattedMessage
                                id="material_restrictions"
                                defaultMessage="Allowed Materials"
                              />
                            )}
                            options={materials}
                            placeholder="Select materials"
                          />
                        )}
                      />
                    </FormGroup>
                    <FormGroup className="form-group" controlId="uxLocation">
                      <FormLabel>
                        <FormattedMessage
                          id="field.location"
                          defaultMessage="Location"
                        />
                        : *
                      </FormLabel>
                      {
                        initialFormValues?.uri && (
                          <OverlayTrigger
                            placement="top"
                            overlay={(
                              <Tooltip>
                                <b>Relocation of the Loaded Permanent Container is available within the QR App.</b>
                              </Tooltip>
                            )}
                          >
                            <FontAwesomeIcon
                              icon={faExclamationTriangle}
                              color="#FF9903"
                              className="spacer-left spacer-right has-text-warning"
                            />
                          </OverlayTrigger>
                        )
                      }

                      <Field
                        name="location"
                        render={props =>
                          (
                            <FormControlSelect
                              {...props.input}
                              id="uxLocation"
                              disabled={!!initialFormValues?.uri}
                              onChange={event => {
                                form.change('sub_location', '');
                                const newLocationUri = event.target.value;
                                loadSubLocations(newLocationUri);
                                props.input.onChange(event);
                              }}
                              required
                            >
                              <option key="placeholder" value="" selected disabled>
                                Select a Location
                              </option>
                              {locations.map(location => (
                                <option key={location.uri} value={location.uri}>
                                  {location.name}
                                </option>
                              ))}
                            </FormControlSelect>
                          )}
                      />
                    </FormGroup>
                    <FormGroup className="form-group" controlId="uxSubLocation">
                      <FormLabel>
                        <FormattedMessage
                          id="field.subLocation"
                          defaultMessage="Sub-Location"
                        />
                        : *
                      </FormLabel>
                      <Field
                        name="sub_location"
                        render={props => (
                          <FormControlSelect
                            {...props.input}
                            id="uxSubLocation"
                            disabled={!values.location || !!initialFormValues?.uri}
                            required
                          >
                            <option key="placeholder" value="" selected disabled>
                              {!values.location ? 'Select a Location' : 'Select a Sub-Location'}
                            </option>
                            {subLocations.map(subLocation => (
                              <option key={subLocation.uri} value={subLocation.uri}>
                                {subLocation.name}
                              </option>
                            ))}
                          </FormControlSelect>
                        )}
                      />
                    </FormGroup>
                  </Card.Body>
                </div>
              </Card>
            </Row>
          </form>
        );
      }}
    />
  );
};

PermanentContainerRecordForm.propTypes = {
  initialFormValues: PropTypes.shape({
    name: PropTypes.string,
    uri: PropTypes.string,
  }).isRequired,
  locationData: PropTypes.shape({
    locations: PropTypes.arrayOf(PropTypes.shape({})),
    subLocations: PropTypes.arrayOf(PropTypes.shape({})),
  }).isRequired,
  materialData: PropTypes.shape({
    isAllMaterialsButtonSelected: PropTypes.bool,
    setIsAllMaterialsButtonSelected: PropTypes.func,
    materials: PropTypes.arrayOf(PropTypes.shape({
      name: PropTypes.string,
    })),
  }).isRequired,
  onSubmit: PropTypes.func.isRequired,
  isSubmitting: PropTypes.bool.isRequired,
  loadSubLocations: PropTypes.func.isRequired,
  onSelectAllMaterials: PropTypes.func.isRequired,
  onDeselectAllMaterials: PropTypes.func.isRequired,
  input: PropTypes.shape({
    onChange: PropTypes.func,
  }).isRequired,
};

export default PermanentContainerRecordForm;
