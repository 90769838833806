import { createSelector } from 'reselect';
import { getPredicate } from 'rapidfab/selectors/helpers/base';
import { getCastorCostings } from 'rapidfab/selectors/castorCosting';
import _find from 'lodash/find';

// eslint-disable-next-line import/prefer-default-export
export const getCastorCostingForModelLibrary = createSelector(
  [getPredicate, getCastorCostings],
  (modelLibraryUri, castorCostings) => (
    _find(castorCostings, { 'model-library': modelLibraryUri })
  ),
);
