import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Alert, Button, Container } from 'react-bootstrap';
import 'rapidfab/styles/hawking/main.scss';
import { ROUTES } from 'rapidfab/constants';
import { getRouteURI } from 'rapidfab/utils/uriUtils';
import TableWithSearching from 'rapidfab/components/Tables/TableWithSearching';
import BreadcrumbNav from 'rapidfab/components/BreadcrumbNav';
import { FormattedMessage } from 'rapidfab/i18n';
import CancelOrDeleteModal from 'rapidfab/components/CancelOrDeleteModal';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus, faTrash } from '@fortawesome/free-solid-svg-icons';

const GuidelinesEngine = componentProps => {
  const {
    onSort,
    onDelete,
    data,
  } = componentProps;

  const [confirmDeleteModalState, setConfirmDeleteModalState] = useState({
    show: false,
    data: null,
  });

  const columns = [
    {
      type: 'custom',
      // id's not provided
      uid: 'field.name',
      accessor: 'name',
      defaultMessage: 'Name',
      resource: 'rule',
      Cell: ({ row: { original: data } }) => (
        <Link
          to={getRouteURI(ROUTES.GUIDELINES_ENGINE_EDIT, { uuid: data.uuid }, {}, true)}
        >
          <p>{data.name}</p>
        </Link>
      ),
    },
    {
      type: 'text',
      uid: 'field.description',
      accessor: 'description',
      defaultMessage: 'Description',
    },
    {
      type: 'custom',
      uid: 'field.summary',
      accessor: 'summary',
      defaultMessage: 'Summary',
      Cell: ({ value }) => {
        // This check is temporary, next sprint the BE date will be improved and we may remove this.
        const summary = typeof value === 'string'
          && (value.includes('Due Date')
          && value.includes('00:00:00.000'))
          ? value.replace('00:00:00.000', '')
          : value;

        return (
          <span>{summary}</span>
        );
      },
    },
    {
      type: 'bool',
      uid: 'field.disabled',
      accessor: 'disabled',
      defaultMessage: 'Disabled',
    },
    {
      type: 'custom',
      uid: 'field.actions',
      accessor: 'actions',
      defaultMessage: 'Actions',
      Cell: ({ row: { original: data } }) => (
        <div className="d-flex justify-content-center">
          {/* 👇🏼 Omitted for v1.0 */}
          {/* <Button
            href={getRouteURI(ROUTES.GUIDELINES_ENGINE_EDIT, { uuid: data.uuid }, {}, false)}
            size="xs"
            className="spacer-right"
          >
            <FontAwesomeIcon icon="pencil" />
          </Button> */}
          <Button
            onClick={() => setConfirmDeleteModalState({
              show: true,
              data,
            })}
            variant="danger"
            size="xs"
            className="spacer-right"
          >
            <FontAwesomeIcon icon={faTrash} />
          </Button>
        </div>
      ),
    },
  ];

  return (
    <>
      {confirmDeleteModalState.show && (
        <CancelOrDeleteModal
          modalType="delete"
          handleConfirm={() => {
            if (!confirmDeleteModalState.data?.uuid) {
              Alert.error(
                <FormattedMessage
                  id="toaster.error.guidelinesEngine.UUIDNotFound"
                  defaultMessage="UUID for this resource couldn't be found."
                />);
              return;
            }
            onDelete(confirmDeleteModalState.data);
          }}
          handleOpen={() => {
            // handleOpen is used only to close modal `handleOpen('')`
            setConfirmDeleteModalState({ show: false, data: null });
          }}
        />
      )}
      <Container fluid>
        <BreadcrumbNav breadcrumbs={['admin', 'guidelinesEngine']} />
        <h1 className="font-size-36 pull-left">
          <FormattedMessage
            id="guidelinesEngine"
            defaultMessage="Guidelines Engine"
          />
        </h1>
        <TableWithSearching
          isUpdatedColumnShown={false}
          {...componentProps}
          data={data}
          columns={columns}
          showLocationsFilter={false}
          navbar={(
            <Button
              variant="primary"
              size="sm"
              href={getRouteURI(ROUTES.GUIDELINES_ENGINE_NEW)}
              className="pull-right"
            >
              <FontAwesomeIcon icon={faPlus} />{' '}
              Create Guideline
            </Button>
          )}
          isManualSoringEnabled
          manualSortingFunc={onSort}
          initialSortedDesc
        />
      </Container>
    </>
  );
};

GuidelinesEngine.propTypes = {
  onDelete: PropTypes.func.isRequired,
  row: PropTypes.shape({
    original: PropTypes.shape({
      name: PropTypes.string,
      uuid: PropTypes.string,
    }),
  }).isRequired,
  value: PropTypes.string.isRequired,
};

export default GuidelinesEngine;
