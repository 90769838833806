import { useEffect, useState } from 'react';

/**
 * @description Return a boolean true/false if the page is currently reloading (refreshing).
 *
 * @example
 * const isPageReloading = useIsPageReloading();
 * console.info(isPageReloading)
 * // Output: true/false
 *
 * @returns {boolean}
 */
// eslint-disable-next-line import/prefer-default-export
export const useIsPageReloading = () => {
  const [isPageReloading, setIsPageReloading] = useState(false);
  useEffect(() => {
    window.onbeforeunload = () => setIsPageReloading(true);
    return () => {
      window.onbeforeunload = null;
      setIsPageReloading(false);
    };
  }, []);
  return isPageReloading;
};
