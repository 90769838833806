import { handleCheckSelectedFiles } from 'rapidfab/utils/fileUtils';
import React, { useEffect, useState, useMemo } from 'react';
import PropTypes from 'prop-types';
import {
  Col,
  Container,
  Row,
  FormLabel,
  FormControl,
  ButtonToolbar,
  Button,
  DropdownButton,
  Dropdown,
  Badge,
} from 'react-bootstrap';
import { useIntl, FormattedMessage } from 'react-intl';

import {
  API_RESOURCES, FEATURES,
  MODEL_LIBRARY_SORT,
  MODEL_LIBRARY_TYPES,
  ROUTES, VIEW_MODE_OPTIONS,
} from 'rapidfab/constants';
import { MODEL_LIBRARY_TYPE_MAP, MODEL_STATUS_MAP } from 'rapidfab/mappings';
import DebugModeDataPanel from 'rapidfab/components/DebugMode/DebugModeDataPanel';

import Loading from 'rapidfab/components/Loading';
import ModelLibrariesGrid from 'rapidfab/components/organize/ModelLibrariesGrid';
import ModelLibrariesList from 'rapidfab/components/organize/ModelLibrariesList';
import ModelLibraryInputDropTargetContainer from 'rapidfab/containers/ModelLibraryInputDropTargetContainer';
import ModelLibraryCreateContainer from 'rapidfab/containers/organize/ModelLibraryCreateContainer';
import NonHawkingFeature from 'rapidfab/components/hawking/NonHawkingFeature';
import { materialTypeResourceType, modelType } from 'rapidfab/types';
import { getRouteURI, getEndpointFromURI } from 'rapidfab/utils/uriUtils';
import { extractUuid } from 'rapidfab/utils/uuidUtils';
import * as Sentry from '@sentry/react';
import _mapValues from 'lodash/mapValues';
import _isEmpty from 'lodash/isEmpty';
import _forEach from 'lodash/forEach';
import _pickBy from 'lodash/pickBy';
import _map from 'lodash/map';
import Alert from 'rapidfab/utils/alert';
import _range from 'lodash/range';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronDown, faChevronUp, faList, faPlus, faShoppingCart, faThLarge, faUpload } from '@fortawesome/free-solid-svg-icons';
import Feature from 'rapidfab/components/Feature';
import ReactSelect, { components } from 'react-select';
import { selectInputStyles } from 'rapidfab/constants/styles';
import { Picky } from 'react-picky';

const ModelLibraries = ({
  labelsByUri,
  modelLibraries,
  fetching,
  search,
  showHeader,
  handleSearchChange,
  typeFilter,
  labelFilter,
  handleTypeFilterChange,
  handleLabelFilterChange,
  materialsByUri,
  handleSelect,
  showModelDropZone,
  modelsByUri,
  ownerUri,
  isHawkingDeploymentFeatureEnabled,
  isStanleyXDeploymentFeatureEnabled,
  isDigitalDesignWarehouseFeatureEnabled,
  pagination,
  handleSortModelLibraries,
  sortValue,
  toggleAscDesc,
  ascDescValue,
  isRestrictedUser,
  bureau,
  user,
  usersByUri,
  viewCartModalState,
  itemsForCurrentShoppingCart,
  onInitializeModelLibraries,
  shoppingCarts,
  customLibraryName,
  isDebugModeEnabled,
}) => {
  const intl = useIntl();
  const [filesToUpload, setFilesToUpload] = useState([]);
  const [viewMode, setViewMode] = useState(VIEW_MODE_OPTIONS.CARDS);
  const isCardsView = viewMode === VIEW_MODE_OPTIONS.CARDS;
  const [setShowViewCartModal] = viewCartModalState;

  // Block of code related to Toastrs on each Model status change event
  // It is now needed for Model Library pages only.
  // TODO: Consider moving somewhere globally once there is a need.
  const modelLibraryModelUris = _map(modelLibraries, 'additive.model');
  const modelStatusesByUri = _mapValues(
    // We only care about models for the currently shown Model Libraries
    _pickBy(modelsByUri, (model, uri) => modelLibraryModelUris.includes(uri)),
    'status',
  );
  const [previousModelStatusesByUri, setPreviousModelStatusesByUri] = useState(modelStatusesByUri);
  useEffect(
    () => {
      _forEach(modelsByUri, ({ uri, name, status }) => {
        // When any model status is changed (it was already set previously) - show toastr
        if (previousModelStatusesByUri[uri] && previousModelStatusesByUri[uri] !== status) {
          // Show a message `BlaBla.stl model is Processing/Processed` when model status changes
          Alert.success(intl.formatMessage(
            { id: 'modelStatusChangedMessage', defaultMessage: '{modelName} model is {newStatus}' },
            { modelName: name, newStatus: intl.formatMessage(MODEL_STATUS_MAP[status]) },
          ));
        }
      });
      setPreviousModelStatusesByUri(modelStatusesByUri);
    },
    [JSON.stringify(modelStatusesByUri)],
  );

  const switchToNextUploadFile = () => {
    // Remove first file to trigger modal for the next one (if there are any left)
    setFilesToUpload(filesToUpload.splice(1));
  };

  // When modal is closed/canceled or saved switch to next file
  const onModelLibraryCreateModalClose = switchToNextUploadFile;

  const ModelLibrariesComponent = isCardsView ? ModelLibrariesGrid : ModelLibrariesList;

  const locationIs = routes => {
    if (Array.isArray(routes)) {
      return routes.some(route => window.location.hash.includes(route));
    }
    return window.location.hash.includes(routes);
  };

  const redirectToEditPage = ({ uri: modelLibraryUri }) => {
    const ownerEntity = ownerUri && getEndpointFromURI(ownerUri).endpointName;

    if (isStanleyXDeploymentFeatureEnabled) {
      if (isRestrictedUser) {
        if (ownerEntity === API_RESOURCES.USERS) {
          window.location.hash =
            getRouteURI(ROUTES.DDW_RESTRICTED_MY_LIBRARY, {}, { uuid: extractUuid(modelLibraryUri) });
          return;
        } if (ownerEntity === API_RESOURCES.BUREAU) {
          window.location.hash =
            getRouteURI(ROUTES.DDW_RESTRICTED_COMPANY_LIBRARY, {}, { uuid: extractUuid(modelLibraryUri) });
          return;
        } if (!ownerUri) {
          window.location.hash =
            getRouteURI(ROUTES.DDW_RESTRICTED_ADMINISTRATOR_LIBRARY, {}, { uuid: extractUuid(modelLibraryUri) });
          return;
        }
      } else {
        if (ownerEntity === API_RESOURCES.USERS) {
          window.location.hash =
            getRouteURI(ROUTES.DDW_MY_LIBRARY, {}, { uuid: extractUuid(modelLibraryUri) });
          return;
        } if (ownerEntity === API_RESOURCES.BUREAU) {
          window.location.hash =
            getRouteURI(ROUTES.DDW_COMPANY_LIBRARY, {}, { uuid: extractUuid(modelLibraryUri) });
          return;
        } if (!ownerUri) {
          window.location.hash =
            getRouteURI(ROUTES.DDW_ALL_DESIGNS, {}, { uuid: extractUuid(modelLibraryUri) });
          return;
        }
      }
    }

    if (isDigitalDesignWarehouseFeatureEnabled) {
      if (isRestrictedUser) {
        /* Is a DDW user but IS restricted. */
        if (ownerEntity === API_RESOURCES.USERS) {
          window.location.hash =
            getRouteURI(ROUTES.DDW_RESTRICTED_MY_LIBRARY, {}, { uuid: extractUuid(modelLibraryUri) });
          return;
        } if (ownerEntity === API_RESOURCES.BUREAU) {
          window.location.hash =
            getRouteURI(ROUTES.DDW_RESTRICTED_COMPANY_LIBRARY, {}, { uuid: extractUuid(modelLibraryUri) });
          return;
        } if (ownerEntity === API_RESOURCES.CUSTOM_GROUP || ownerEntity === API_RESOURCES.GROUPS) {
          // customLibraryName will be already formatted to /digital-design-warehouse/restricted/<name>
          window.location.hash =
            getRouteURI(customLibraryName, {}, { uuid: extractUuid(modelLibraryUri) });
          return;
        } if (!ownerUri) {
          window.location.hash =
            getRouteURI(ROUTES.DDW_RESTRICTED_ADMINISTRATOR_LIBRARY, {}, { uuid: extractUuid(modelLibraryUri) });
          return;
        }
      } else {
        /* Is a DDW user but is NOT restricted. */
        if (ownerEntity === API_RESOURCES.USERS) {
          window.location.hash =
            getRouteURI(ROUTES.DDW_MY_LIBRARY, {}, { uuid: extractUuid(modelLibraryUri) });
          return;
        } if (ownerEntity === API_RESOURCES.BUREAU) {
          window.location.hash =
            getRouteURI(ROUTES.DDW_COMPANY_LIBRARY, {}, { uuid: extractUuid(modelLibraryUri) });
          return;
        } if (ownerEntity === API_RESOURCES.CUSTOM_GROUP || ownerEntity === API_RESOURCES.GROUPS) {
          // customLibraryName will be already formatted to /digital-design-warehouse/<name>
          window.location.hash =
            getRouteURI(customLibraryName, {}, { uuid: extractUuid(modelLibraryUri) });
          return;
        } if (!ownerUri) {
          window.location.hash =
            getRouteURI(ROUTES.DDW_ALL_DESIGNS, {}, { uuid: extractUuid(modelLibraryUri) });
          return;
        }
      }
      // else {
      //   /* Is a DDW user and IS restricted. */
      //   window.location.hash =
      //   getRouteURI(ROUTES.HAWKING_COMPANY_LIBRARY, {}, { uuid: extractUuid(modelLibraryUri) });
      //   return;
      // }
    }

    if (!isHawkingDeploymentFeatureEnabled && !isDigitalDesignWarehouseFeatureEnabled) {
      // For non-Hawking users we only have `model-library` page
      window.location.hash =
        getRouteURI(ROUTES.MODEL_LIBRARY, {}, { uuid: extractUuid(modelLibraryUri) });
      return;
    }

    if (!ownerUri) {
      // When Owner Uri is not set - user is on generic Manager Library page
      window.location.hash =
        getRouteURI(ROUTES.HAWKING_ADMINISTRATOR_LIBRARY, {}, { uuid: extractUuid(modelLibraryUri) });
      return;
    }

    if (ownerEntity === API_RESOURCES.USERS) {
      // When Owner URI is a User uri - current page is My Library
      window.location.hash =
        getRouteURI(ROUTES.HAWKING_MY_LIBRARY, {}, { uuid: extractUuid(modelLibraryUri) });
      return;
    }

    if (ownerEntity === API_RESOURCES.BUREAU) {
      // When Owner URI is a Bureau uri - current page is Company Library
      window.location.hash =
        getRouteURI(ROUTES.HAWKING_COMPANY_LIBRARY, {}, { uuid: extractUuid(modelLibraryUri) });
      return;
    }

    Sentry.captureMessage(`Unsupported Owner Uri type: ${ownerEntity} passed into Model Libraries page. Owner Uri: ${ownerUri}`);
  };

  const onModelLibraryCreateModalSuccessSubmit = (modelLibrary, appliedToAll) => {
    if (appliedToAll) {
      redirectToEditPage(modelLibrary);
      setFilesToUpload([]);
      return;
    }

    // Prevent redirect when there are files left to be saved
    if (filesToUpload.length === 1) {
      redirectToEditPage(modelLibrary);
    }
    switchToNextUploadFile();
  };

  const uploadModelText = isHawkingDeploymentFeatureEnabled ? 'Upload Product' : 'Upload Model';

  const handleSelectFiles = files => {
    const validFiles = handleCheckSelectedFiles(files);

    setFilesToUpload(validFiles);
  };
  /* Handle the conditional rendering of the upload button (top right),
  there may be more as we choose to add bureaus in the future. */
  const handleUploadButtonConditionalRender = useMemo(() => {
    if ((locationIs(ROUTES.HAWKING_COMPANY_LIBRARY) && isRestrictedUser) || locationIs(ROUTES.DDW_ALL_DESIGNS)) {
      /* Guard if user is in company library and is restricted. */
      return false;
    }
    return (
      <ModelLibraryInputDropTargetContainer onSelectFiles={handleSelectFiles}>
        <Button variant="primary" type="button" className="pull-right btn-upload w-full text-nowrap">
          <FontAwesomeIcon icon={faPlus} className="spacer-right" />
          {uploadModelText}
        </Button>
      </ModelLibraryInputDropTargetContainer>
    );
  }, []);

  /* Handle the conditional rendering of the drop upload zone (bottom),
  there may be more as we choose to add bureaus in the future. */
  const handleDropZoneConditionalRender = useMemo(() => {
    if (locationIs(ROUTES.HAWKING_COMPANY_LIBRARY) && isRestrictedUser) {
      /* Guard if user is in company library and is restricted. */
      return false;
    }
    return (
      <Col xs={12}>
        <div className="jumbotron mt15">
          <ModelLibraryInputDropTargetContainer onSelectFiles={setFilesToUpload}>
            <h1 className="text-center">{uploadModelText}</h1>
            <div className="text-center">
              <FontAwesomeIcon icon={faUpload} size="5x" />
            </div>
            <p className="text-center mt15">
              Drag a file here or click to browse
            </p>
          </ModelLibraryInputDropTargetContainer>
        </div>
      </Col>
    );
  }, []);

  const {
    pageLimitValues,
    pageLimit,
    activePage,
    totalPaginatedPages,
  } = pagination;

  const handleShowViewShoppingCartModal = async () => {
    await onInitializeModelLibraries();
    setShowViewCartModal(true);
  };

  const debugModeData = {
    name: 'Model Search',
    current_search_value: search,
    search_fields: 'Name, Updated, Created, Notes, Layer_Thickness, Workflow, Custom_fields, Base Material and Support Material',
    model_libraries_to_display: modelLibraries,
    number_of_models_displayed: `${modelLibraries.length} ${modelLibraries.length > 1 ? 'models' : 'model'} displayed`,
  };

  return (
    <Container fluid>
      {
        showHeader && (
          <div>
            {!locationIs(ROUTES.ORDER_NEW) && (
              <>
                <Row>
                  <Col xs={12}>
                    <div className="d-flex align-items-end justify-content-end">
                      <Col xs={{ span: 2 }} className="mr30">
                        <Feature featureName="experiment-alpha">
                          <ReactSelect
                            styles={selectInputStyles}
                            options={MODEL_LIBRARY_SORT}
                            onChange={change => {
                              toggleAscDesc(previous => !previous);
                              handleSortModelLibraries(change.value);
                            }}
                            value={MODEL_LIBRARY_SORT.find(item => item.value === sortValue)}
                            components={{
                              Option: props =>
                                (
                                  <components.Option {...props}>
                                    {/* eslint-disable-next-line react/prop-types */}
                                    {props.value !== '' && props.value === sortValue &&
                                    <FontAwesomeIcon icon={ascDescValue ? faChevronUp : faChevronDown} />}{' '}
                                    {/* eslint-disable-next-line react/prop-types */}
                                    {props.children}
                                  </components.Option>
                                ),
                            }}
                          />
                        </Feature>
                        <Feature featureName="experiment-alpha" isInverted>
                          <Picky
                            options={MODEL_LIBRARY_SORT}
                            labelKey="label"
                            valueKey="value"
                            keepOpen={false}
                            value={MODEL_LIBRARY_SORT[MODEL_LIBRARY_SORT.findIndex(item => item.value === sortValue)]}
                            onChange={handleSortModelLibraries}
                            renderList={({
                              items,
                              selectValue,
                            }) => items.map(item => {
                              let chevron = null;
                              if (item.value && item.value === sortValue) {
                                if (ascDescValue) {
                                  chevron = <FontAwesomeIcon icon={faChevronUp} />;
                                } else {
                                  chevron = <FontAwesomeIcon icon={faChevronDown} />;
                                }
                              }
                              return (
                                // eslint-disable-next-line jsx-a11y/no-noninteractive-element-interactions
                                <li
                                  key={item.value}
                                  onClick={() => {
                                    toggleAscDesc(previous => !previous);
                                    selectValue(item.value);
                                  }}
                                >
                                  {chevron} {item.label}
                                </li>
                              );
                            })}
                          />
                        </Feature>
                      </Col>
                      <ButtonToolbar className="mr15 pull-right toolbar">
                        <Button
                          variant="default"
                          text="dark"
                          className={viewMode === VIEW_MODE_OPTIONS.CARDS ? 'btn-active' : ''}
                          onClick={() => setViewMode(VIEW_MODE_OPTIONS.CARDS)}
                        >
                          <FontAwesomeIcon icon={faThLarge} size="lg" />
                        </Button>
                        <Button
                          text="dark"
                          variant="default"
                          className={viewMode === VIEW_MODE_OPTIONS.LIST ? 'btn-active' : ''}
                          active={viewMode === VIEW_MODE_OPTIONS.LIST}
                          onClick={() => setViewMode(VIEW_MODE_OPTIONS.LIST)}
                        >
                          <FontAwesomeIcon icon={faList} size="lg" />
                        </Button>
                      </ButtonToolbar>
                    </div>
                  </Col>
                </Row>
                <hr />
              </>
            )}
            {isDebugModeEnabled && <DebugModeDataPanel data={debugModeData} style={{ marginBottom: 10 }} />}
            <Row style={{ marginBottom: '15px' }}>
              <div className="d-flex justify-content-between">
                <div className="d-flex" style={{ flexBasis: '100%' }}>
                  <Col xs={{ span: 3 }} className="mr30">
                    <FormLabel>Search</FormLabel>{' '}
                    <FormControl
                      data-cy="search-filter"
                      type="text"
                      value={search}
                      onChange={event => handleSearchChange(event.target.value)}
                    />
                  </Col>

                  {locationIs(ROUTES.MODEL_LIBRARY) && (
                    <NonHawkingFeature>
                      <Col xs={{ span: 3 }} className="mr30">
                        <FormLabel>Type</FormLabel>{' '}
                        <FormControl
                          data-cy="type-filter"
                          as="select"
                          value={typeFilter}
                          onChange={event => handleTypeFilterChange(event.target.value)}
                        >
                          <option value="">Any</option>
                          {Object.values(MODEL_LIBRARY_TYPES).map(type => (
                            <FormattedMessage
                              key={MODEL_LIBRARY_TYPE_MAP[type].id}
                              id={MODEL_LIBRARY_TYPE_MAP[type].id}
                              defaultMessage={MODEL_LIBRARY_TYPE_MAP[type].defaultMessage}
                            >
                              {text => (
                                <option value={type}>{text}</option>
                              )}
                            </FormattedMessage>
                          ))}
                        </FormControl>
                      </Col>
                    </NonHawkingFeature>
                  )}
                  {locationIs([
                    ROUTES.MODEL_LIBRARY,
                    ROUTES.HAWKING_COMPANY_LIBRARY,
                    ROUTES.HAWKING_MY_LIBRARY,
                    ROUTES.HAWKING_ADMINISTRATOR_LIBRARY,
                    ROUTES.ORDER_NEW,
                  ]) && (
                    <Col xs={{ span: 3 }} className="mr30">
                      <FormLabel>Label</FormLabel>{' '}
                      <FormControl
                        data-cy="label-filter"
                        as="select"
                        value={labelFilter}
                        onChange={event => handleLabelFilterChange(event.target.value)}
                      >
                        <option value="">Any</option>
                        {Object.values(labelsByUri).filter(({ items }) => items.length > 0).map(label => (
                          <option key={label.uri} value={label.uri}>{label.name}</option>
                        ))}
                      </FormControl>
                    </Col>
                  )}
                </div>
                {!locationIs(ROUTES.ORDER_NEW) && (
                  <div className="d-flex align-items-center">
                    {handleUploadButtonConditionalRender}
                    <>
                      {itemsForCurrentShoppingCart.length > 0 && (
                        <Badge
                          className="badge badge-sm position-relative d-flex align-items-center justify-content-center text-white"
                          bg="danger"
                          style={{
                            left: 120,
                            top: -17,
                            height: 20,
                            width: 20,
                            borderRadius: 20,
                          }}
                        >
                          {itemsForCurrentShoppingCart.length}
                        </Badge>
                      )}
                      <Feature featureName={FEATURES.DIGITAL_DESIGN_WAREHOUSE}>
                        <Button
                          onClick={handleShowViewShoppingCartModal}
                          variant="primary"
                          type="button"
                          className="spacer-left pull-right btn-upload w-full text-nowrap"
                          disabled={_isEmpty(shoppingCarts)}
                        >
                          <FontAwesomeIcon icon={faShoppingCart} className="spacer-right" />
                          <FormattedMessage
                            id="modelLibrary.shoppingCart.viewCart"
                            defaultMessage="View Cart"
                          />
                        </Button>
                      </Feature>
                    </>
                  </div>
                )}
              </div>
            </Row>
          </div>
        )
      }

      <Row>

        <Col>
          {fetching && (
            <Loading className="mb30" />
          )}
          <ModelLibrariesComponent
            modelLibraries={modelLibraries}
            labelsByUri={labelsByUri}
            materialsByUri={materialsByUri}
            handleSelect={handleSelect || redirectToEditPage}
            modelsByUri={modelsByUri}
            isHawkingDeploymentFeatureEnabled={isHawkingDeploymentFeatureEnabled}
            ascDescValue={ascDescValue}
            sortValue={sortValue}
            bureau={bureau}
            usersByUri={usersByUri}
          />
        </Col>
        <div className="pagination-panel">
          <ButtonToolbar className="pull-left">
            <DropdownButton title={pageLimit} id="pageLimitSelector-PrintsTab">
              {pageLimitValues.map(value => (
                <Dropdown.Item
                  active={value === pageLimit}
                  key={value}
                  eventKey={value}
                  onClick={() => pagination.setPaginationState({
                    ...pagination,
                    pageLimit: value,
                  })}
                >
                  {value}
                </Dropdown.Item>
              ))}
            </DropdownButton>
          </ButtonToolbar>

          <ButtonToolbar className="pull-right">
            <Button
              disabled={activePage === 0 || fetching}
              className="spacer-right"
              onClick={() => {
                pagination
                  .setPaginationState({
                    ...pagination,
                    offset: (activePage - 1) * pageLimit,
                    activePage: activePage - 1,
                  });
              }}
            >Prev
            </Button>
            <DropdownButton className="spacer-right" title={`Page ${activePage + 1}`}>
              {_range(0, totalPaginatedPages).map(value => (
                <Dropdown.Item
                  active={value === pageLimit}
                  key={value}
                  eventKey={value}
                  onClick={event => {
                    const offset = (Number(event.target.textContent) - 1) * pageLimit;
                    pagination.setPaginationState({
                      ...pagination,
                      activePage: value,
                      offset,
                    });
                  }}
                >
                  {value + 1}
                </Dropdown.Item>
              ))}
            </DropdownButton>
            <Button
              disabled={activePage === (totalPaginatedPages - 1) || fetching}
              onClick={() => {
                pagination
                  .setPaginationState({
                    ...pagination,
                    offset: (activePage + 1) * pageLimit,
                    activePage: activePage + 1,
                  });
              }}
            >Next
            </Button>
          </ButtonToolbar>
        </div>

        {/* DropZone component */}
        {showModelDropZone &&
          handleDropZoneConditionalRender}

      </Row>

      {(filesToUpload.length > 0) && (
        <ModelLibraryCreateContainer
          onClose={onModelLibraryCreateModalClose}
          modelFiles={filesToUpload}
          onSuccessSubmit={onModelLibraryCreateModalSuccessSubmit}
          user={user}
          isDigitalDesignWarehouseFeatureEnabled={isDigitalDesignWarehouseFeatureEnabled}
          bureau={bureau}
          customLibraryName={customLibraryName}
          ownerUri={ownerUri}
        />
      )}
    </Container>
  );
};

ModelLibraries.propTypes = {
  fetching: PropTypes.bool.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  modelLibraries: PropTypes.arrayOf(PropTypes.object).isRequired,
  labelsByUri: PropTypes.shape({}).isRequired,
  showHeader: PropTypes.bool.isRequired,
  search: PropTypes.string,
  handleSearchChange: PropTypes.func,
  typeFilter: PropTypes.string,
  labelFilter: PropTypes.string,
  handleTypeFilterChange: PropTypes.func,
  handleLabelFilterChange: PropTypes.func,
  materialsByUri: PropTypes.objectOf(materialTypeResourceType).isRequired,
  handleSelect: PropTypes.func,
  showModelDropZone: PropTypes.bool,
  modelsByUri: PropTypes.objectOf(modelType).isRequired,
  ownerUri: PropTypes.string,
  isHawkingDeploymentFeatureEnabled: PropTypes.bool.isRequired,
  isStanleyXDeploymentFeatureEnabled: PropTypes.bool.isRequired,
  pagination: PropTypes.shape({
    setPaginationState: PropTypes.func,
    pageLimitValues: PropTypes.arrayOf(PropTypes.number),
    pageLimit: PropTypes.number,
    offset: PropTypes.number,
    activePage: PropTypes.number,
    totalModelLibraries: PropTypes.number,
    totalPaginatedPages: PropTypes.number,
  }).isRequired,
  totalPaginatedPages: PropTypes.number.isRequired,
  handleSortModelLibraries: PropTypes.func.isRequired,
  toggleAscDesc: PropTypes.func.isRequired,
  ascDescValue: PropTypes.bool.isRequired,
  isDebugModeEnabled: PropTypes.bool.isRequired,
  sortValue: PropTypes.string.isRequired,
  isDigitalDesignWarehouseFeatureEnabled: PropTypes.bool.isRequired,
  isRestrictedUser: PropTypes.bool.isRequired,
  bureau: PropTypes.shape({}).isRequired,
  user: PropTypes.shape({}).isRequired,
  usersByUri: PropTypes.shape({}).isRequired,
  viewCartModalState: PropTypes.arrayOf(PropTypes.node).isRequired,
  itemsForCurrentShoppingCart: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  onInitializeModelLibraries: PropTypes.func.isRequired,
  shoppingCarts: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  customLibraryName: PropTypes.string,
};

ModelLibraries.defaultProps = {
  search: '',
  handleSearchChange: false,
  typeFilter: '',
  labelFilter: '',
  handleTypeFilterChange: false,
  handleLabelFilterChange: false,
  showModelDropZone: true,
  handleSelect: null,
  ownerUri: null,
  customLibraryName: null,
};

export default ModelLibraries;
