import React from 'react';
import PropTypes from 'prop-types';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { ListGroup, ListGroupItem, Card, Row, Col } from 'react-bootstrap';
import Tooltip from 'rc-tooltip';

import 'rc-tooltip/assets/bootstrap_white.css';

import { extractUuid } from 'rapidfab/utils/uuidUtils';

import { FormattedDateTime } from 'rapidfab/i18n';
import Loading from 'rapidfab/components/Loading';
import { faClock } from '@fortawesome/free-regular-svg-icons';
import BREAKPOINTS from 'rapidfab/constants/deviceSizes';
import { faInfoCircle } from '@fortawesome/free-solid-svg-icons';
import _isEmpty from 'lodash/isEmpty';
import AssignedUserBadge from '../AssignedUserBadge';

const styles = {
  listRow: {
    alignItems: 'center',
    display: 'flex',
    justifyContent: 'flex-start',
  },
  spacingRight: {
    marginRight: '2rem',
  },
  timeRow: {
    display: 'flex',
    justifyContent: 'space-between',
    width: '100%',
  },
};

const Downtimes = ({ downtimes, handleSelectionChange, loading, customFieldsByUri }) => (
  <Card bg="dark">
    <Card.Header className="pd-exp inverse">
      {loading ? (
        <Loading />
      ) : (
        `${downtimes.length === 0 ? 'No' : ''} Scheduled Downtime`
      )}
    </Card.Header>
    <ListGroup fill>
      {downtimes.map(downtime => (
        <ListGroupItem
          onClick={() => handleSelectionChange(extractUuid(downtime.uri))}
          key={downtime.uri}
          className="justify-content-between d-flex"
        >
          <div style={styles.listRow}>
            <FontAwesomeIcon style={styles.spacingRight} icon={faClock} />
            <div className="wrap-text">
              <span>{downtime.description}</span>
              <div className="with-max-content">
                <span style={styles.timeRow}>
                  <span>Start:</span> &nbsp;<FormattedDateTime value={downtime.start} />
                </span>
                <span style={styles.timeRow}>
                  <span>Finish:</span> &nbsp;<FormattedDateTime value={downtime.finish} />
                </span>
              </div>
            </div>
          </div>
          <div className="d-flex flex-column justify-content-between align-items-center">
            {!_isEmpty(downtime.custom_field_values) && (
              <Tooltip
                placement="top"
                destroyTooltipOnHide
                mouseEnterDelay={0}
                mouseLeaveDelay={0.1}
                overlayInnerStyle={{ width: '40rem', wordBreak: 'break-word' }}
                overlay={(
                  <>
                    {
                      downtime.custom_field_values.map(({ custom_field, value }) => (
                        <Row className="mb5">
                          <Col lg={6}>
                            <b>{customFieldsByUri[custom_field]?.field_name}</b>:
                          </Col>
                          <Col className="align-self-end">
                            {value}
                          </Col>
                        </Row>
                      ))
                    }
                  </>
                )}
              >
                <FontAwesomeIcon icon={faInfoCircle} className="spacer-right" />
              </Tooltip>
            )}
            <AssignedUserBadge assignedUser={downtime.assigned_user} assignedTo="Scheduled Downtime" breakpoint={BREAKPOINTS.XL_DESKTOP} onlyToolTip />
          </div>

        </ListGroupItem>
      ))}
    </ListGroup>
  </Card>
);

Downtimes.propTypes = {
  downtimes: PropTypes.arrayOf(
    PropTypes.shape({
      description: PropTypes.string,
      finish: PropTypes.string,
      start: PropTypes.string,
    }),
  ).isRequired,
  handleSelectionChange: PropTypes.func.isRequired,
  loading: PropTypes.bool.isRequired,
  customFieldsByUri: PropTypes.shape({}).isRequired,
};

export default Downtimes;
