import React from 'react';
import SidebarLink from 'rapidfab/components/SidebarLink';
import { ROUTES, DDW_PAYMENT_STATUSES } from 'rapidfab/constants';
import { getRouteURI } from 'rapidfab/utils/uriUtils';
import _map from 'lodash/map';
import { Badge, Card, Col, Row } from 'react-bootstrap';
import { FormControlSelect } from 'rapidfab/components/formTools';
import { extractUuid } from 'rapidfab/utils/uuidUtils';
import _filter from 'lodash/filter';
import _isEmpty from 'lodash/isEmpty';
import _capitalize from 'lodash/capitalize';
import _reject from 'lodash/reject';

const ManageShoppingCartsSidebarView = ({
  shoppingCarts,
  handleFilterShoppingCarts,
}) => {
  const shoppingCartsWithOrders = _filter(shoppingCarts, shoppingCartItem => !_isEmpty(shoppingCartItem.order));

  /* Omitting 'complete', as there is `complete` value in `payment_status` that corresponds to it.
    Leaving the field null is not robust IMO. */
  const paymentStatusStrings = _reject(Object.values(DDW_PAYMENT_STATUSES),
    statusString =>
      statusString === DDW_PAYMENT_STATUSES.COMPLETE);

  return ([
    <p className="ddwModelLibraryLabel">Manage Shopping Carts</p>,
    <Card bg="dark">
      <Card.Body>
        <Row id="filterShoppingCartStatusWrapper" className="d-flex align-items-center">
          <Col xs={4}>
            <b>Status:</b>
          </Col>
          <Col xs={8}>
            <FormControlSelect
              className="spacer-top"
              id="shoppingCartFilters.status"
              defaultValue={DDW_PAYMENT_STATUSES.READY}
              onChange={event => handleFilterShoppingCarts(event)}
            >
              <option id="shoppingCartStatus.all" value="all">
                All
              </option>
              {_map(
                paymentStatusStrings,
                statusString => (
                  <option id={`shoppingCartStatus.${statusString}`} value={statusString}>
                    {_capitalize(statusString)}
                  </option>
                ))}
            </FormControlSelect>
          </Col>
        </Row>
        <ul className="mt15" id="managerManageShoppingCartsView">
          {_map(shoppingCartsWithOrders, shoppingCartItem => (
            <SidebarLink
              uri={getRouteURI(ROUTES.DDW_BUYER_SHOPPING_CART, { uuid: extractUuid(shoppingCartItem.uri) })}
              iconName="shopping-cart"
              label={shoppingCartItem.name}
              trailingContent={shoppingCartItem.payment_status === DDW_PAYMENT_STATUSES.READY && (
                <Badge
                  bg="warning"
                  className="badge badge-sm"
                >
                  Ready
                </Badge>
              )}
            />
          ))}
        </ul>
      </Card.Body>
    </Card>,
  ]);
};

export default ManageShoppingCartsSidebarView;
