import { Col, ListGroupItem, Row } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import PropTypes from 'prop-types';
import { PROCESS_STEP_SHIPPING_DIRECTION } from 'rapidfab/constants';
import SelectSingle from 'rapidfab/components/forms/SelectSingle';
import { faLevelDown, faLevelUp } from '@fortawesome/free-solid-svg-icons';

const OutsourceShippingStep = ({
  processStep, index, shippingDirection, shippingTypes, handleSelectedOutsourceShipping,
}) => {
  const processStepId = processStep?.id;
  const processStepKey = `${processStepId}+${index}`;
  return (
    <ListGroupItem key={processStepKey} className="picklist-group-item" id={processStepId}>
      <Row className="picklist-group-item-row">
        <Col xs={1}>
          {
            shippingDirection === PROCESS_STEP_SHIPPING_DIRECTION.BUREAU_TO_SERVICE_PROVIDER &&
            (<FontAwesomeIcon icon={faLevelDown} />)
          }
          {
            shippingDirection === PROCESS_STEP_SHIPPING_DIRECTION.SERVICE_PROVIDER_TO_BUREAU &&
            (<FontAwesomeIcon icon={faLevelUp} />)
          }
        </Col>
        <Col xs={9}>
          <SelectSingle
            name={String(index)}
            data={shippingTypes}
            valueKey="id"
            value={processStepId}
            handleOnChange={(name, value) => handleSelectedOutsourceShipping(value, index, shippingDirection)}
          />
        </Col>
      </Row>
    </ListGroupItem>
  );
};

OutsourceShippingStep.defaultProps = {
  processStep: null,
};

OutsourceShippingStep.propTypes = {
  processStep: PropTypes.shape({
    id: PropTypes.string,
    shippingDirection: PropTypes.string,
  }),
  shippingDirection: PropTypes.string.isRequired,
  shippingTypes: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  index: PropTypes.number.isRequired,
  handleSelectedOutsourceShipping: PropTypes.func.isRequired,
};

export default OutsourceShippingStep;
