// import { createSelector } from 'reselect';
import { getBureau } from 'rapidfab/selectors/bureau';

export const getIsLoading = state => state.ui.nautilus.users.list.fetching;
export const getIsFetching = state => state.ui.nautilus.downtime.list.fetching;
export const getIsSubmitting = state => state.ui.nautilus.bureau.put.fetching;

export const getBureauUUID = state => {
  const bureau = getBureau(state);
  return bureau.uuid;
};

export const getBureauName = state => {
  const bureau = getBureau(state);
  return bureau.name;
};

export const getBureauGroup = state => {
  const bureau = getBureau(state);
  return bureau.group;
};

export const getBureauBanner = state => {
  const bureau = getBureau(state);
  return bureau.order_banner;
};

export const getBureauLink = state => {
  const banner = getBureauBanner(state);
  return banner.link;
};

export const getBureauMessage = state => {
  const banner = getBureauBanner(state);
  return banner.message;
};
