import React from 'react';
import { Button, Card, ListGroup, Offcanvas } from 'react-bootstrap';
import PropTypes from 'prop-types';
import { useModal } from 'rapidfab/hooks';
import _map from 'lodash/map';
import dayjs from 'dayjs';
import Logo from 'rapidfab/images/flows-icon.png';
import _truncate from 'lodash/truncate';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faClose } from '@fortawesome/free-solid-svg-icons';
import { ProfileAvatar } from './ProfileAvatar';
import BureauNotificationDetailsModal from '../modals/BureauNotificationDetailsModal';

const BureauNotificationListItem = ({ notification, onClick }) => (
  <Card style={{ borderRadius: 12 }} className="bureau-notification-list-item rounded-4 mb-2">
    <Card.Body style={{ borderRadius: 12, padding: 15 }}>
      <div id="bureau-notification-list-item-header" className="d-flex w-full justify-content-between align-items-center mb-1">
        <h4>{notification.author_name}</h4>
        <h5>{dayjs(notification.created).fromNow()}</h5>
      </div>
      <div id="bureau-notification-list-item-body" className="d-flex w-full align-items-start">
        <ProfileAvatar imgSource={Logo} style={{ width: 50 }} className="m-r-sm f d-flex align-items-center justify-content-center" userName="Authentise" />
        <h5 className="w-full">{notification.text.length >= 150 ? ([
          _truncate(notification.text, { length: 150 }),
          <Button variant="link" size="xs" onClick={onClick}>
            <b>view more</b>
          </Button>,
        ]) : notification.text}
        </h5>
      </div>

    </Card.Body>
  </Card>
);

BureauNotificationListItem.propTypes = {
  notification: PropTypes.shape({
    uri: PropTypes.string,
    created: PropTypes.string,
    bureau: PropTypes.string,
    text: PropTypes.string,
    author_name: PropTypes.string,
  }).isRequired,
  onClick: PropTypes.func.isRequired,
};

const BureauNotifications = ({ bureauNotifications, show = false, onClose }) => {
  const [openBureauNotificationDetailsModal, WrappedBureauNotificationDetailsModal] = useModal(
    BureauNotificationDetailsModal,
  );
  return (
    <>
      <WrappedBureauNotificationDetailsModal />
      <Offcanvas
        show={show}
        className="bureau-notification pd-exp"
        bg="dark"
        variant="dark"
        onHide={onClose}
        placement="end"
      >
        <Offcanvas.Header>
          <Offcanvas.Title className="d-flex justify-content-between align-items-start w-full">
            <h2>Notifications</h2>
            <FontAwesomeIcon
              icon={faClose}
              onClick={onClose}
              tabIndex={0}
              role="button"
              className="custom-darken-modal-button"
            />
          </Offcanvas.Title>
        </Offcanvas.Header>
        {bureauNotifications.length > 0 ? (
          <Offcanvas.Body>
            <ListGroup>
              {_map(bureauNotifications, notification => (
                <BureauNotificationListItem
                  key={notification.uri}
                  onClick={() => openBureauNotificationDetailsModal({
                    title: 'Bureau Notification',
                    bodyText: notification.text,
                  })}
                  notification={notification}
                />
              ))}
            </ListGroup>
          </Offcanvas.Body>
        ) : (
          <Offcanvas.Body className="d-flex flex-col justify-content-center align-items-center">
            <span>No notifications yet.</span>
          </Offcanvas.Body>
        )}
      </Offcanvas>
    </>
  );
};

export default BureauNotifications;

BureauNotifications.defaultProps = {
  show: false,
};

BureauNotifications.propTypes = {
  show: PropTypes.bool,
  onClose: PropTypes.func.isRequired,
  bureauNotifications: PropTypes.arrayOf(PropTypes.shape({
    uri: PropTypes.string,
    created: PropTypes.string,
    bureau: PropTypes.string,
    text: PropTypes.string,
    author_name: PropTypes.string,
  })).isRequired,
};
