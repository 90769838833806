import { faObjectGroup } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import _isArray from 'lodash/isArray';
import _map from 'lodash/map';
import PropTypes from 'prop-types';
import { selectMultiInputStyles } from 'rapidfab/constants/styles';
import React, { useState, useEffect, useRef, useMemo } from 'react';
import { Button, Card } from 'react-bootstrap';
import Select, { components } from 'react-select';

const ValueContainer = ({
  itemsToShow,
  value,
  showAllOptions,
  isDropdownMenuOpen,
  toggleShowAll,
  children,
  ...props
}) => {
  const visibleCount = showAllOptions ? value.length : itemsToShow;
  const hiddenCount = value.length - visibleCount;
  const message = hiddenCount > 0 && !isDropdownMenuOpen ? `...and ${hiddenCount} more` : null;

  return (
    <components.ValueContainer {...props}>
      {children}
      {message && (
        <span role="button" tabIndex={0} onClick={toggleShowAll} className="customMultiSelectMorePlaceholder">
          {message}
        </span>
      )}
    </components.ValueContainer>
  );
};

ValueContainer.propTypes = {
  itemsToShow: PropTypes.number.isRequired,
  value: PropTypes.arrayOf(PropTypes.string).isRequired,
  showAllOptions: PropTypes.bool.isRequired,
  isDropdownMenuOpen: PropTypes.bool.isRequired,
  toggleShowAll: PropTypes.func.isRequired,
  children: PropTypes.node.isRequired,
};

const MultiValue = props => {
  const { icon, customIcon, data } = props;
  return (
    <components.MultiValue {...props}>
      <div>
        {icon && !customIcon && (
          <FontAwesomeIcon icon={icon} className="spacer-right" />
        )}
        {customIcon || null}
        {data.label}
      </div>
    </components.MultiValue>
  );
};

MultiValue.propTypes = {
  data: PropTypes.shape({
    label: PropTypes.string,
  }).isRequired,
  icon: PropTypes.shape({}),
  customIcon: PropTypes.node,
};

MultiValue.defaultProps = {
  icon: null,
  customIcon: null,
};

const CustomMultiSelect = ({
  value,
  onChange,
  title,
  options,
  placeholder,
  formLabel,
  itemsToShow = 10,
  isCardMode = false,
  customCardCss,
  customCardBg,
  customCardBorder,
  customCardHeaderCss,
  customCardBodyCss,
  required,
  defaultNullableValue = [],
  icon,
  customIcon,
}) => {
  const [isDropdownMenuOpen, setIsDropdownMenuOpen] = useState(false);
  const [showAllOptions, setShowAllOptions] = useState(false);
  const selectRef = useRef(null);

  // Format the regular useSelector data to the formatted options for the Select component
  const formattedOptions = useMemo(() =>
    options.map(manufacturer => ({
      label: manufacturer.name,
      value: manufacturer.uri,
    })).filter(Boolean), [options]);

  // Convert the array of URIs to the array of option objects
  const valueOptions = _isArray(value)
    ? value.map(uri => formattedOptions.find(option => option.value === uri)).filter(Boolean)
    : [];

  const handleChange = (selectedOptions, actionMeta) => {
    if (actionMeta.action === 'remove-value' || actionMeta.action === 'pop-value') {
      // Update with URI values after a value is removed
      const nextValue = valueOptions.filter(option => option !== actionMeta.removedValue).map(option => option.value);
      onChange(nextValue);
    } else {
      // Update with all selected URIs
      onChange(selectedOptions.length ? _map(selectedOptions, 'value') : defaultNullableValue);
    }
  };

  const handleDropdownOpen = () => {
    setIsDropdownMenuOpen(true);
  };

  const handleDropdownClose = () => {
    setIsDropdownMenuOpen(false);
    setShowAllOptions(false);
  };

  const handleClickOutsideMenu = event => {
    if (selectRef.current && !selectRef.current.contains(event.target) && isDropdownMenuOpen) {
      handleDropdownClose(); // Close only if the dropdown is open
    }
  };

  const toggleShowAll = event => {
    event.stopPropagation();
    setShowAllOptions(!showAllOptions);
  };

  // useEffect to add event listener for clicking outside the dropdown menu to close it
  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutsideMenu);
    return () => {
      document.removeEventListener('mousedown', handleClickOutsideMenu);
    };
  }, [isDropdownMenuOpen]);

  const visibleOptions = showAllOptions || isDropdownMenuOpen
    ? valueOptions
    : valueOptions.slice(0, itemsToShow);

  const pureSelectInput = (
    <div ref={selectRef}>
      <Select
        isMulti
        required={required}
        options={formattedOptions}
        onChange={handleChange}
        placeholder={placeholder}
        onMenuOpen={handleDropdownOpen}
        onMenuClose={handleDropdownClose}
        value={visibleOptions}
        styles={selectMultiInputStyles}
        closeMenuOnSelect={false}
        components={{
          itemsToShow,
          value,
          showAllOptions,
          isDropdownMenuOpen,
          toggleShowAll,
          ValueContainer: props => (
            <ValueContainer
              value={value}
              showAllOptions={showAllOptions}
              isDropdownMenuOpen={isDropdownMenuOpen}
              toggleShowAll={toggleShowAll}
              itemsToShow={itemsToShow}
              {...props}
            />
          ),
          MultiValue: props =>
            <MultiValue icon={icon} customIcon={customIcon} {...props} />,
        }}
        menuIsOpen={isDropdownMenuOpen}
      />
    </div>
  );

  return isCardMode ? (
    <Card className={customCardCss} bg={customCardBg || 'secondary'} border={customCardBorder || 'secondary'}>
      <Card.Header className={`pd-exp text-white d-flex justify-content-between align-items-center ${customCardHeaderCss}`}>
        <div className="customMultiSelectCardTitle">
          {title}
        </div>
        {value.length > itemsToShow && !isDropdownMenuOpen && (
          <Button
            type="button"
            className={`spacer-left btn-xs ${!showAllOptions ? 'customMultiSelectExpandAllBtn' : 'customMultiSelectCollapseBtn'}`}
            data-cy="show-hide-all-multi-select"
            onClick={toggleShowAll}
          >
            {showAllOptions ? 'Collapse' : 'Expand All'}
          </Button>
        )}
      </Card.Header>
      <Card.Body className={`${customCardBodyCss || 'bg-dark'}`}>
        {formLabel}
        {pureSelectInput}
      </Card.Body>
    </Card>
  ) : (
    <>
      {formLabel}
      {pureSelectInput}
    </>
  );
};

CustomMultiSelect.propTypes = {
  value: PropTypes.arrayOf(PropTypes.string).isRequired,
  onChange: PropTypes.func.isRequired,
  options: PropTypes.arrayOf(PropTypes.shape({
    value: PropTypes.string,
    label: PropTypes.string,
  })).isRequired,
  data: PropTypes.shape({
    label: PropTypes.string,
  }),
  placeholder: PropTypes.string,
  itemsToShow: PropTypes.number,
  formLabel: PropTypes.node,
  isCardMode: PropTypes.bool,
  icon: PropTypes.shape({}),
  title: PropTypes.oneOfType([PropTypes.string, PropTypes.node, null]),
  customCardCss: PropTypes.string,
  customCardBg: PropTypes.string,
  customCardBorder: PropTypes.string,
  customCardHeaderCss: PropTypes.string,
  customCardBodyCss: PropTypes.string,
  required: PropTypes.bool,
  defaultNullableValue: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.string), null]),
  customIcon: PropTypes.node,
};

CustomMultiSelect.defaultProps = {
  data: {},
  placeholder: '',
  itemsToShow: 10,
  formLabel: null,
  isCardMode: false,
  icon: faObjectGroup,
  title: null,
  customCardCss: '',
  customCardBg: '',
  customCardBorder: '',
  customCardHeaderCss: '',
  customCardBodyCss: '',
  required: false,
  defaultNullableValue: [],
  customIcon: null,
};

export default CustomMultiSelect;
