import React from 'react';
import PropTypes from 'prop-types';
import _isNil from 'lodash/isNil';
import { Button, Modal } from 'react-bootstrap';
import { Form } from 'react-final-form';
import { FormattedMessage } from 'rapidfab/i18n';
import FormGroupField from 'rapidfab/components/forms/FormGroupField';
import SaveButton from 'rapidfab/components/SaveButton';

const EditRunEstimationWeightModal = ({
  show,
  onHide,
  onSubmit,
  initialValues,
}) => (
  <Modal show={show} onHide={onHide} backdrop="static">
    <Modal.Header closeButton>
      <FormattedMessage
        id="materialWeight"
        defaultMessage="Material Weight"
      />
    </Modal.Header>
    <Form
      validate={formValues => {
        const errors = {};
        if (_isNil(formValues.weight)) errors.weight = 'Please enter weight.';
        if (formValues.weight < 0) errors.weight = 'The value cannot be less than 0.';
        return errors;
      }}
      onSubmit={async values => {
        await onSubmit(values);
        onHide();
      }}
      initialValues={initialValues}
    >
      {({ handleSubmit }) => (
        <form onSubmit={handleSubmit}>
          <Modal.Body>
            <FormGroupField
              name="weight"
              label={<FormattedMessage id="materialWeight" defaultMessage="Material Weight" />}
              type="number"
              required
            />
          </Modal.Body>
          <Modal.Footer>
            <Button onClick={onHide}>
              <FormattedMessage id="button.cancel" defaultMessage="Cancel" />
            </Button>
            <SaveButton
              showSaveIcon
              label={<FormattedMessage id="button.submit" defaultMessage="Submit" />}
              variant="success"
              type="submit"
            />
          </Modal.Footer>
        </form>
      )}
    </Form>
  </Modal>
);

EditRunEstimationWeightModal.propTypes = {
  show: PropTypes.bool.isRequired,
  onHide: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  initialValues: PropTypes.shape({
    weight: PropTypes.oneOfType([PropTypes.number, null]),
  }).isRequired,
};

export default EditRunEstimationWeightModal;
