import _sortBy from 'lodash/sortBy';
import { createSelector } from 'reselect';
import * as baseStateSelectors from 'rapidfab/selectors/baseStateSelectors';
import { getStateResources } from 'rapidfab/selectors/helpers/base';
import _map from 'lodash/map';
import _filter from 'lodash/filter';
import _keyBy from 'lodash/keyBy';

export const getMaterials = createSelector(
  [baseStateSelectors.getStateMaterials, getStateResources],
  (uuids, resources) => {
    const materials = _map(uuids, uuid => resources[uuid]);
    return _filter(materials, { is_template: false });
  },
);

export const getMaterialsByUri = createSelector([getMaterials], materials =>
  _keyBy(materials, 'uri'),
);

export const getSortedMaterialsByName = createSelector(
  [getMaterials],
  materials =>
    _sortBy(
      _filter(materials, 'name'), // Make sure the Material has the "name"
      material => material.name.toLowerCase(),
    ),
);
