import BureauBarcodeIcon from 'rapidfab/components/BureauBarcodeIcon';
import FeedstocksTabs from 'rapidfab/components/inventory/FeedstocksTabs';
import React from 'react';
import PropTypes from 'prop-types';
import { MATERIAL_BATCH_POWDER_QUALITY_STATUS_MAP, MATERIAL_BATCH_STATUS_MAP } from 'rapidfab/mappings';
import { Link } from 'react-router-dom';
import { ROUTES } from 'rapidfab/constants';

import TableWithSearching from 'rapidfab/components/Tables/TableWithSearching';

import { extractUuid } from 'rapidfab/utils/uuidUtils';
import { getRouteURI } from 'rapidfab/utils/uriUtils';

import { getNonEmptyMaterialContainersForBatch } from 'rapidfab/selectors/';

import { useSelector } from 'react-redux';

const MaterialBatches = ({
  data,
  fetching,
  onSort,
  subLocations,
  ...componentProps
}) => {
  const breadcrumbs = ['inventory', 'materialStocks', 'materialBatches'];

  const columns = [
    {
      type: 'uuid',
      uid: 'field.id',
      accessor: 'uuid',
      defaultMessage: 'ID',
      resource: 'material-batch',
      route: ROUTES.MATERIAL_BATCH,
    },
    {
      type: 'text',
      uid: 'field.material',
      accessor: 'materials',
      defaultMessage: 'Material',
      isSortable: true,
    },
    {
      type: 'custom',
      uid: 'field.location',
      accessor: 'location_name',
      defaultMessage: 'Location',
      isSortable: true,
      Cell: ({ row: { original } }) => {
        const location = componentProps.locations.find(location => location.uri === original.location);
        const subLocation = subLocations.find(subLocation => subLocation.uri === original.sub_location);
        return <span>{location?.name} {subLocation && `(${subLocation?.name})`}</span>;
      },
    },
    {
      type: 'suffixed',
      uid: 'field.quantity',
      accessor: 'quantity',
      defaultMessage: 'Quantity',
      suffix: 'units',
      isSortable: true,
    },
    {
      type: 'integer',
      uid: 'field.usage_cycles',
      accessor: 'usage_cycles',
      defaultMessage: 'Usage Cycles',
      isSortable: true,
    },
    {
      type: 'custom',
      uid: 'field.containers',
      accessor: 'uri',
      defaultMessage: 'Containers',
      isSortable: true,
      Cell: ({ row: { original } }) => {
        const containers = useSelector(state => getNonEmptyMaterialContainersForBatch(state, original));
        if (!containers?.length) {
          return <div className="text-right">None</div>;
        }
        return (
          <Link
            className="pull-right"
            to={getRouteURI(ROUTES.PRINT_BATCH_CONTAINERS_QR_CODES, { uuid: extractUuid(original.uri) }, {}, true)}
          >
            <BureauBarcodeIcon className="spacer-right" />
            {containers.length} {`${containers.length === 1 ? 'Container' : 'Containers'}`}
          </Link>
        );
      },
    },
    {
      type: 'translatable',
      uid: 'field.status',
      accessor: 'status',
      defaultMessage: 'Status',
      mapping: MATERIAL_BATCH_STATUS_MAP,
      isSortable: true,
    },
    {
      type: 'translatable',
      uid: 'field.powder_quality',
      accessor: 'powder_quality',
      defaultMessage: 'Powder Quality',
      mapping: MATERIAL_BATCH_POWDER_QUALITY_STATUS_MAP,
      isSortable: true,
    },
    {
      type: 'bool',
      uid: 'is_initial_batch',
      accessor: 'is_initial_batch',
      defaultMessage: 'Initial Batch',
      isSortable: true,
    },
  ];

  return (
    <TableWithSearching
      {...componentProps}
      breadcrumbs={breadcrumbs}
      isFetching={fetching}
      subheader={<FeedstocksTabs activeKey="material-batches" />}
      data={data}
      columns={columns}
      isManualSoringEnabled
      manualSortingFunc={onSort}
      initialSortedDesc
      initialSortedColumn="updated"
      withBreadcrumbs
      showSubLocationsFilter
    />
  );
};

MaterialBatches.defaultProps = {
  sort: '',
  search: '',
};

MaterialBatches.propTypes = {
  onPageChange: PropTypes.func.isRequired,
  onLimitChange: PropTypes.func.isRequired,
  onSearch: PropTypes.func.isRequired,
  onFilterUpdate: PropTypes.func.isRequired,
  onSort: PropTypes.func.isRequired,
  sort: PropTypes.string,
  search: PropTypes.string,
  recordListItems: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  filters: PropTypes.shape({}).isRequired,
  recordListStore: PropTypes.shape({}).isRequired,
  offset: PropTypes.number.isRequired,
  limit: PropTypes.number.isRequired,
  data: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  fetching: PropTypes.bool.isRequired,
  row: PropTypes.shape({
    original: PropTypes.shape({
      uuid: PropTypes.string.isRequired,
      uri: PropTypes.string.isRequired,
      location: PropTypes.string.isRequired,
      sub_location: PropTypes.string.isRequired,
    }),
  }).isRequired,
  locations: PropTypes.arrayOf(PropTypes.shape({
    uri: PropTypes.string,
  })).isRequired,
  subLocations: PropTypes.arrayOf(
    PropTypes.shape({
      uri: PropTypes.string,
    })).isRequired,
};

export default MaterialBatches;
