import React from 'react';
import Actions from 'rapidfab/actions';
import { API_RESOURCES, FEATURES } from 'rapidfab/constants';
import { getCustomFieldsByUri, isFeatureEnabled } from 'rapidfab/selectors';
import { extractUuid } from 'rapidfab/utils/uuidUtils';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { CUSTOM_FIELDS_MODAL } from 'rapidfab/constants/forms';
import _get from 'lodash/get';
import _set from 'lodash/set';
import CustomFieldAddModal from 'rapidfab/components/admin/CustomFieldAddModal';
import CustomFieldEditModal from 'rapidfab/components/admin/CustomFieldEditModal';

const CustomFieldModalContainer = props => {
  const { uri } = props;
  const customFieldsByUri = useSelector(getCustomFieldsByUri);
  const customField = customFieldsByUri[uri];
  const initialValues = customField ? {
    field_name: customField.field_name,
    default_value: customField.default_value,
    required: customField.required,
    meta: customField.meta,
    enabled: customField.enabled,
    is_visible_for_restricted: customField.is_visible_for_restricted,
    options: customField.options,
    position: customField.position,
    description: customField.description,
  } : {};
  const isHawkingUser = useSelector(state => isFeatureEnabled(state, FEATURES.HAWKING_DEPLOYMENT));

  const initialFormValues = {};
  Object
    .keys(initialValues)
    .filter(key => CUSTOM_FIELDS_MODAL.FIELDS.includes(key))
    .forEach(key => {
      initialFormValues[key] = initialValues[key];
    });

  const selected = {
    initialValues: initialFormValues,
    isHawkingUser,
    customFieldsByUri,
  };

  const dispatch = useDispatch();

  const onCreateCustomField = payload => dispatch(Actions.Api.nautilus[API_RESOURCES.CUSTOM_FIELD].post(payload));
  const onUpdateCustomField = (uri, payload) => {
    CUSTOM_FIELDS_MODAL.FLOAT_FIELDS.forEach(
      fieldName => {
        const field = _get(payload, fieldName);
        if (field) {
          _set(payload, fieldName, Number.parseFloat(field));
        }
      },
    );

    return dispatch(Actions.Api.nautilus[API_RESOURCES.CUSTOM_FIELD].put(extractUuid(uri), payload));
  };

  const dispatched = {
    onCreateCustomField,
    onUpdateCustomField,
  };

  if (props.uri) {
    return <CustomFieldEditModal {...props} {...selected} {...dispatched} />;
  }
  return <CustomFieldAddModal {...props} {...selected} {...dispatched} />;
};

CustomFieldModalContainer.propTypes = {
  uri: PropTypes.string.isRequired,
};

export default CustomFieldModalContainer;
