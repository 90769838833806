import React from 'react';
import PropTypes from 'prop-types';
import { Container, Row, Col } from 'react-bootstrap';
import StanleyXSidebar from './StanleyXSidebar';
import 'rapidfab/styles/stanley/main.scss';

const StanleyXLayout = ({ children }) => (
  <Container fluid>
    <Row>
      <Col xs={2}>
        <StanleyXSidebar />
      </Col>
      <Col xs={10}>
        {children}
      </Col>
    </Row>
  </Container>
);

StanleyXLayout.propTypes = {
  children: PropTypes.node.isRequired,
};

export default StanleyXLayout;
