import React from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import 'rapidfab/styles/componentStyles/modelLibraryCard.scss';
import { labelResourceType, modelLibraryType, modelType } from 'rapidfab/types';
import TableCellLabels from 'rapidfab/components/TableCellLabels';
import { isFeatureEnabled } from 'rapidfab/selectors';
import { FEATURES } from 'rapidfab/constants';
import CastorCostingIssue from './CastorCostingIssue';

const ModelLibrariesGrid = ({ modelLibraries, modelsByUri, labelsByUri, handleSelect }) => {
  const isDigitalDesignWarehouseFeatureEnabled = useSelector(
    state => isFeatureEnabled(state, FEATURES.DIGITAL_DESIGN_WAREHOUSE),
  );
  const isRobozeBureauOrderFieldsFeatureEnabled = useSelector(state => isFeatureEnabled(
    state,
    FEATURES.ROBOZE_GIGAFACTORY_BUREAU_ORDER_FIELDS,
  ));
  const isIntegrationCastorFeatureEnabled = useSelector(state => isFeatureEnabled(state, FEATURES.INTEGRATION_CASTOR));

  return (
    <div className="card-list mb30">
      {modelLibraries.map(modelLibrary => {
        const model = modelsByUri[modelLibrary.additive.model];
        const modelSnapshotContent = model && model.snapshot_content;
        const snapshotContent = modelLibrary.snapshot_content || modelSnapshotContent;
        return (
          <div
            data-cy={modelLibrary.type}
            className="card"
            key={modelLibrary.uri}
            role="button"
            tabIndex={0}
            onClick={() => handleSelect(modelLibrary)}
          >
            <img className="card-img-top" src={snapshotContent} alt="" />
            <div className="card-body">
              <h5 className="card-title">{modelLibrary.name}
                {
                  isDigitalDesignWarehouseFeatureEnabled &&
                  isIntegrationCastorFeatureEnabled &&
                  isRobozeBureauOrderFieldsFeatureEnabled &&
                  <>{' '}<CastorCostingIssue uri={modelLibrary.uri} /></>
                }
              </h5>
              {
                modelLibrary && modelLibrary.labels && modelLibrary.labels.length > 0 && (
                  <div className="spacer-top">
                    <TableCellLabels
                      labelUris={modelLibrary.labels}
                      labelsByUri={labelsByUri}
                    />
                  </div>
                )
              }
            </div>
          </div>
        );
      })}
    </div>
  );
};

ModelLibrariesGrid.defaultProps = {
  handleSelect: null,
};

ModelLibrariesGrid.propTypes = {
  modelLibraries: PropTypes.arrayOf(modelLibraryType).isRequired,
  labelsByUri: PropTypes.objectOf(labelResourceType).isRequired,
  handleSelect: PropTypes.func,
  modelsByUri: PropTypes.objectOf(modelType).isRequired,
};

export default ModelLibrariesGrid;
