import PropTypes from 'prop-types';
import QRCode from 'qrcode.react';
import { BUREAU_BARCODE_FORMAT } from 'rapidfab/constants';
import { getBureauBarcodeFormat } from 'rapidfab/selectors';
import { getQRAppUri, encodedBarcodeUrl } from 'rapidfab/utils/uriUtils';
import React from 'react';
import Barcode from 'react-barcode';
import { useSelector } from 'react-redux';

const BureauBarcodeOutput = ({ url, customOutputUrl, allowClickingLink }) => {
  const bureauBarcodeFormat = useSelector(getBureauBarcodeFormat);
  const isBarcode = bureauBarcodeFormat === BUREAU_BARCODE_FORMAT.BARCODE;

  if (!url) {
    return <svg width="250" height="250" />;
  }

  return (
    <div
      className={
        isBarcode ? BUREAU_BARCODE_FORMAT.BARCODE : BUREAU_BARCODE_FORMAT.QR
      }
    >
      {allowClickingLink ? (
        <a
          href={customOutputUrl || getQRAppUri(url)}
          target="_blank"
          rel="noopener noreferrer"
        >
          {isBarcode ? (
            <Barcode width={1.2} value={encodedBarcodeUrl(url)} />
          ) : (
            <QRCode
              value={customOutputUrl || getQRAppUri(url)}
              renderAs="svg"
            />
          )}
        </a>
      ) : (isBarcode ? (
        <Barcode width={1.2} value={encodedBarcodeUrl(url)} />
      ) : (
        <QRCode value={customOutputUrl || getQRAppUri(url)} renderAs="svg" />
      ))}
    </div>
  );
};

BureauBarcodeOutput.propTypes = {
  url: PropTypes.string.isRequired,
  customOutputUrl: PropTypes.string,
  allowClickingLink: PropTypes.bool,
};

BureauBarcodeOutput.defaultProps = {
  customOutputUrl: '',
  allowClickingLink: true,
};

export default BureauBarcodeOutput;
