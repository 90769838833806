import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Row, Col } from 'react-bootstrap';
import { FormattedMessage } from 'react-intl';
import Alert from 'rapidfab/utils/alert';
import NetfabbButton from 'rapidfab/components/NetfabbButton';
import { getNetfabbLineItems } from 'rapidfab/selectors';
import Actions from 'rapidfab/actions';
import { extractUuid } from 'rapidfab/reducers/makeApiReducers';
import { API_RESOURCES } from 'rapidfab/constants';

const NetfabbImportExport = ({
  onExportClick,
  onImportClick,
  // lineItemUri,
  uploading,
  downloading,
  canImport,
  canExport,
}) => (
  <Row className="mt15">
    <Col md={6}>
      <NetfabbButton
        onClick={onExportClick}
        processing={uploading}
        canExport={!canExport}
      >
        <FormattedMessage
          id="record.cadfile.netfabbexport"
          defaultMessage="Export to Netfabb"
        />
      </NetfabbButton>
    </Col>
    <Col md={6}>
      <NetfabbButton
        onClick={onImportClick}
        processing={downloading}
        disabled={!canImport}
      >
        <FormattedMessage
          id="record.cadfile.netfabbimport"
          defaultMessage="Import from Netfabb"
        />
      </NetfabbButton>
    </Col>
  </Row>
);

NetfabbImportExport.propTypes = {
  onExportClick: PropTypes.func.isRequired,
  onImportClick: PropTypes.func.isRequired,
  // lineItemUri: PropTypes.string.isRequired,
  uploading: PropTypes.bool.isRequired,
  downloading: PropTypes.bool.isRequired,
  canImport: PropTypes.bool.isRequired,
  canExport: PropTypes.bool.isRequired,
};

const mapStateToProps = (state, ownProps) => {
  const { lineItemUri } = ownProps;
  const netfabbLineItems = getNetfabbLineItems(state);
  const filteredNetfabbLineItems = netfabbLineItems.find(item => item.line_item === lineItemUri);
  const netfabbLineItem = filteredNetfabbLineItems || {};

  const posting = state.ui.nautilus[API_RESOURCES.LINE_ITEM_NETFABB].post.fetching;
  const { fetching } = state.ui.nautilus[API_RESOURCES.LINE_ITEM_NETFABB].get;
  const uploadInProgress = netfabbLineItem.last_action === 'upload' && netfabbLineItem.last_action_status === 'in_progress';
  const downloadInProgress = netfabbLineItem.last_action === 'download' && netfabbLineItem.last_action_status === 'in_progress';

  return {
    uploading: posting || fetching || uploadInProgress,
    downloading: posting || fetching || downloadInProgress,
    canExport: !downloadInProgress,
    canImport: Boolean(netfabbLineItem.forge_project_id),
  };
};

const mapDispatchToProps = (dispatch, ownProps) => ({
  onExportClick: () => {
    const { lineItemUri } = ownProps;

    dispatch(Actions.Api.nautilus[API_RESOURCES.LINE_ITEM_NETFABB].post({
      line_item: lineItemUri,
      action: 'upload',
    })).then(response => {
      if (!response) {
        Alert.error(
          <FormattedMessage
            id="toaster.error.lineItemNetfabb.exportingCadFile"
            defaultMessage="Error exporting CAD file to Netfabb."
          />);
        return;
      }

      const { location } = response.headers;
      const lineItemNetfabbUuid = extractUuid(location);
      dispatch(Actions.Api.nautilus[API_RESOURCES.LINE_ITEM_NETFABB].get(lineItemNetfabbUuid)).then(({ json }) => {
        if (json.last_action_status === 'error') {
          Alert.error(
            <FormattedMessage
              id="toaster.error.lineItemNetfabb.exportingCadFile"
              defaultMessage="Error exporting CAD file to Netfabb."
            />);
          return;
        }

        const url = `netfabb://cmd/loadfromforge/project=${json.forge_project_id}&folder=${json.forge_folder_id}&item=${json.netfabb_project_id}`;
        const element = (
          <div>
            Successfully exported CAD file to Netfabb. <a href={url}>Open in Nefabb.</a>
          </div>
        );

        Alert.success(element, {
          timeout: 'none',
        });
      });
    });
  },
  onImportClick: () => {
    const { lineItemUri } = ownProps;

    dispatch(Actions.Api.nautilus[API_RESOURCES.LINE_ITEM_NETFABB].post({
      line_item: lineItemUri,
      action: 'download',
    })).then(response => {
      if (!response) {
        Alert.error(
          <FormattedMessage
            id="toaster.error.lineItemNetfabb.importingFromNetfabb"
            defaultMessage="Error importing CAD file from Netfabb."
          />);
        return;
      }

      const { location } = response.headers;
      const lineItemNetfabbUuid = extractUuid(location);
      dispatch(Actions.Api.nautilus[API_RESOURCES.LINE_ITEM_NETFABB].get(lineItemNetfabbUuid)).then(({ json }) => {
        if (json.last_action_status === 'error') {
          Alert.error(
            <FormattedMessage
              id="toaster.error.lineItemNetfabb.exportingCadFile"
              defaultMessage="Error exporting CAD file to Netfabb."
            />);
          return;
        }

        Alert.success(
          <FormattedMessage
            id="toaster.importedCADFileFromNetfab"
            defaultMessage="Successfully imported CAD file from Netfabb."
          />,
        );
      });
    });
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(NetfabbImportExport);
