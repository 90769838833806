import {
  faArrowRight,
  faClose,
  faScrewdriverWrench,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import PropTypes from 'prop-types';
import BuildPlateLineItemPiecesSummary from 'rapidfab/components/records/run/BuildPlateLineItemPiecesSummary';
import BuildPlateLineItemsModeIncompatiblePiecesContainer
  from 'rapidfab/containers/records/run/BuildPlateLineItemsModeIncompatiblePiecesContainer';
import { getLineItemWorkflowTypeObjectKey } from 'rapidfab/utils/lineItemUtils';
import React, { useState } from 'react';
import { Button, Modal } from 'react-bootstrap';
import { FormattedMessage } from 'react-intl';

const BuildPlateLineItemProductionChangeModal = ({
  show,
  modelsByUri,
  workflowsByUri,
  onClose,
  orders,
  productionChangeTitle,
  fetchMorePieces,
  productionChangeProps,
  lineItemsGeneralProps,
  reworkedPiecesProps,
}) => {
  const { onActionProductionChange } = productionChangeProps;
  const {
    selectedLineItems,
    lineItemSummaryData,
    selectedLineItemPiecesCount,
    setSelectedLineItemPiecesCount,
  } = lineItemsGeneralProps;

  const {
    reworkedPieces,
    setReworkedPieces,
    setReworkedPiecesFetchMoreState,
    lineItemForReworkedPieces,
    setLineItemForReworkedPieces,
    fetchReworkedPiecesForLineItem,
  } = reworkedPiecesProps;

  const [incompatiblePiecesModalVisible, setIncompatiblePiecesModalVisible] = useState(false);

  const isNextButtonDisabled =
    Object.values(selectedLineItemPiecesCount).some(selectedPiecesCount => !selectedPiecesCount);

  const toggleIncompatiblePiecesModal = async lineItemUri => {
    setIncompatiblePiecesModalVisible(previous => !previous);
    setLineItemForReworkedPieces(null);
    setReworkedPieces([]);
    setReworkedPiecesFetchMoreState({ offset: 0, count: 1 });
    if (!incompatiblePiecesModalVisible) {
      setLineItemForReworkedPieces(lineItemUri);
      await fetchReworkedPiecesForLineItem(lineItemUri);
    }
  };

  return (
    <Modal
      size="lg"
      show={show}
      onHide={onClose}
      backdrop="static"
      className="quoteProcessStepModalShow"
      dialogClassName="line-item-quote-modal"
    >
      <Modal.Header>
        <Modal.Title className="w-100 d-flex align-items-center justify-content-between">
          <div className="d-flex align-items-center">
            <FontAwesomeIcon icon={faScrewdriverWrench} />
            <p className="custom-darken-modal-title-text mt0">
              {productionChangeTitle}
            </p>
          </div>
          <FontAwesomeIcon
            icon={faClose}
            onClick={onClose}
            tabIndex={0}
            role="button"
            className="custom-darken-modal-button"
          />
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="mb20 font-size-18 font-weight-200">Please specify the number of pieces for which you would like to adjust the production process for the Line Item.</div>
        {selectedLineItems.map(lineItem => (
          <BuildPlateLineItemPiecesSummary
            key={lineItem.uri}
            lineItem={lineItem}
            ordersByUri={orders}
            piecesCount={selectedLineItemPiecesCount[lineItem.uri]}
            lineItemSummaryData={lineItemSummaryData}
            model={modelsByUri[lineItem[getLineItemWorkflowTypeObjectKey(lineItem)]?.model]}
            workflow={workflowsByUri[lineItem.workflow]}
            setSelectedLineItemPiecesCount={setSelectedLineItemPiecesCount}
            toggleIncompatiblePiecesModal={toggleIncompatiblePiecesModal}
          />
        ))}
      </Modal.Body>
      <Modal.Footer>
        <div className="d-flex align-items-center">
          <Button onClick={onClose} size="sm" variant="outline-light" className="spacer-right">
            <FormattedMessage id="button.cancel" defaultMessage="Cancel" />
          </Button>
          <div className="d-flex align-items-center justify-content-end">
            <Button size="sm" variant="primary" disabled={isNextButtonDisabled} onClick={onActionProductionChange}>
              <FontAwesomeIcon icon={faArrowRight} className="spacer-right" />
              <FormattedMessage id="button.next" defaultMessage="Next" />
            </Button>
          </div>
        </div>
      </Modal.Footer>

      {incompatiblePiecesModalVisible && (
        <BuildPlateLineItemsModeIncompatiblePiecesContainer
          isVisible={incompatiblePiecesModalVisible}
          onClose={toggleIncompatiblePiecesModal}
          reworkedPieces={reworkedPieces}
          fetchMorePieces={fetchMorePieces}
          lineItemUri={lineItemForReworkedPieces}
        />
      )}
    </Modal>
  );
};

BuildPlateLineItemProductionChangeModal.propTypes = {
  show: PropTypes.bool.isRequired,
  modelsByUri: PropTypes.objectOf(PropTypes.shape({})).isRequired,
  workflowsByUri: PropTypes.objectOf(PropTypes.shape({})).isRequired,
  onClose: PropTypes.func.isRequired,
  productionChangeTitle: PropTypes.string.isRequired,
  fetchMorePieces: PropTypes.func.isRequired,
  productionChangeProps: PropTypes.shape({
    onActionProductionChange: PropTypes.func,
  }).isRequired,
  lineItemsGeneralProps: PropTypes.shape({
    selectedLineItems: PropTypes.arrayOf(PropTypes.shape({})),
    lineItemSummaryData: PropTypes.objectOf(PropTypes.shape({})),
    selectedLineItemPiecesCount: PropTypes.objectOf(PropTypes.number),
    setSelectedLineItemPiecesCount: PropTypes.func,
  }).isRequired,
  reworkedPiecesProps: PropTypes.shape({
    reworkedPieces: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
    setReworkedPieces: PropTypes.func,
    setReworkedPiecesFetchMoreState: PropTypes.func,
    lineItemForReworkedPieces: PropTypes.string,
    setLineItemForReworkedPieces: PropTypes.func,
    fetchReworkedPiecesForLineItem: PropTypes.func,
  }).isRequired,
  orders: PropTypes.objectOf(PropTypes.shape({})).isRequired,
};

export default BuildPlateLineItemProductionChangeModal;
