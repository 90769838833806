import Constants from 'rapidfab/constants';

const initialState = {
  show: false,
};

/**
 * Reducer for debug mode view to display user information.
 */
function reducer(state = initialState, action) {
  switch (action.type) {
    case Constants.SET_SHOW_DEBUG_MODE_USER_INFO:
      return { ...state, show: action.payload };
    default:
      return state;
  }
}

export default reducer;
