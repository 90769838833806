import _capitalize from 'lodash/capitalize';
import _map from 'lodash/map';
import _words from 'lodash/words';
import { PRIORITIES } from 'rapidfab/constants';
import { PRIORITIES_MAP } from 'rapidfab/mappings';

export const truncateText = (text, truncationAmount, ellipses = true) => {
  let result = text.slice(0, truncationAmount);

  // Remove trailing spaces
  result = result.trimEnd();

  if (ellipses && text.length > truncationAmount) {
    result += '...';
  }

  return result;
};

/**
 * @description Determines whether a word should be pluralised
 * ---
 * @example ```
 * const lineItems = ['item1', 'item2', 'item3']
 * pluralWord('Line Item', lineItems) // Returns 'Line Items' as lineItems length is > 1
 * ```
 * @example ```
 * const orders = ['order1']
 * pluralWord('Order', orders) // Returns 'Order' as orders length is == 1
 * ```
 * ---
 * @param {string} baseWordForm Base word, e.g. 'Line Item'
 * @param {array || number} array Resource(s) that will determine whether plural or singular
 * @param {string} character Character to append to word if plural, default is 's'.
 * @param {boolean} lengthInsteadOfArray If true, use the array length instead of the array itself.
 * @returns {string}
 */
// eslint-disable-next-line unicorn/prevent-abbreviations
export const pluralWord = (
  baseWordForm,
  array,
  character = 's',
  lengthInsteadOfArray = false,
) => {
  if (!lengthInsteadOfArray && !array) return baseWordForm;

  const pluralRules = new Intl.PluralRules();
  const length = lengthInsteadOfArray ? array : array.length;

  if (pluralRules.select(length) !== 'one') {
    return `${baseWordForm}${character}`;
  }

  return baseWordForm;
};

/**
 * Attempts to generate a random partially intelligable word (that is not jumbled characters)
 * based on a set length.
 */
export const generateRandomWord = (length = 8) => {
  const vowels = 'aeiou';
  const consonants = 'bcdfghjklmnpqrstvwxyz';
  let randomWord = '';
  let useConsonant = Math.random() < 0.5;

  randomWord += useConsonant ? consonants[Math.floor(Math.random() * consonants.length)].toUpperCase() :
    vowels[Math.floor(Math.random() * vowels.length)].toUpperCase();

  for (let index = 1; index < length; index++) {
    if (useConsonant) {
      const randomIndex = Math.floor(Math.random() * consonants.length);
      randomWord += consonants[randomIndex];
    } else {
      const randomIndex = Math.floor(Math.random() * vowels.length);
      randomWord += vowels[randomIndex];
    }
    useConsonant = !useConsonant;
  }
  return randomWord;
};

/**
 * Generates initials for use in a user avatar from a full name.
 * If the name consists of multiple parts, such as a first name and last name,
 * the function returns the first letter of the first and last parts. If the name
 * is a single word, it returns the first letter of that word. All initials are
 * returned in uppercase.
 *
 * @param {string} fullName - The full name of the user from which to generate avatar initials.
 * @returns {string} The initials for the avatar. For names with multiple parts,
 *                   the initials consist of the first letters of the first and last part.
 *                   For single-part names, the initial is the first letter of the name.
 *                   All returned initials are in uppercase.
 */

export const formatProfileAvatarName = fullName => {
  if (!fullName) return '';
  let name = fullName;
  // Check if the name contains an '@', indicating it's an email
  if (name.includes('@')) {
    // Extract the part of the email before the '@'
    const emailNamePart = name.split('@')[0];
    // Replace dots with spaces to treat each part separated by dot as a different name part
    name = emailNamePart.replace(/\./g, ' ');
  }

  const names = name.trim().split(/\s+/); // Split by one or more spaces
  if (names.length === 1) {
    // If there's only one part to the name, use the first character of this part
    return names[0][0].toUpperCase();
  }
  // Use the first character of the first and last parts, both converted to uppercase
  return `${names[0][0]}${names[names.length - 1][0]}`.toUpperCase();
};

/**
 * Truncates an email address to a specified maximum length, preserving the domain part
 * and as much as possible of the local part at the beginning and end. Inserts an ellipsis
 * ("...") in the truncated local part to indicate omission.
 *
 * @param {string} email The email address to be truncated.
 * @param {number} maxLength The maximum allowed length of the truncated email. If the
 * original email's length is less than or equal to this value, the email is returned unchanged.
 * @returns {string} The truncated email if the original email exceeds the maxLength, otherwise
 * returns the original email.
 *
 * @example
 * // returns 'test-u...l+name@authentise.com'
 * truncateEmail('test-user+long-email+name@authentise.com', 30);
 */
export const truncateEmail = (email, maxLength) => {
  if (!email.includes('@') || !maxLength || email.length <= maxLength) return email;
  const [local, domain] = email.split('@');

  const availableLengthForLocal = maxLength - domain.length - 4; // 3 for "...", 1 for "@"
  const startEllipsisAfter = Math.floor(availableLengthForLocal / 2);
  const endEllipsisBefore = local.length - (availableLengthForLocal - startEllipsisAfter);

  return `${local.slice(0, startEllipsisAfter)}...${local.slice(endEllipsisBefore)}@${domain}`;
};

export const capitalizeWords = string => _map(_words(string), _capitalize).join(' ');

/**
 * Truncates an email address by replacing a portion of the local part (before the @) with asterisks.
 * Specifically, it replaces 75% of the characters in the local part with asterisks,
 * keeping the first character visible.
 *
 * @param {string} email - The email address to be truncated.
 * @returns {string} The truncated email address with the local part partially replaced by asterisks.
 *
 * @example
 * // returns 'j****@example.com'
 * censorEmail('john@example.com');
 */

export const censorEmail = email => {
  const prefix = email.split('@')[0];
  const suffix = email.split('@')[1];
  const replacingCharsCount = Math.floor(prefix.length * 0.75);
  return `${prefix[0] + '*'.repeat(replacingCharsCount)}@${suffix}`;
};

/**
 * Safely parses JSON and returns whether item is valid JSON or not.
 */
export const safeJSONParse = jsonString => {
  try {
    return [JSON.parse(jsonString), true];
  } catch {
    return [null, false];
  }
};

/**
 * Gets string priority label based on given numeric level.
 * @param {number} value
 */
export const getPriorityLabel = value => {
  if (value >= PRIORITIES.CRITICAL) return PRIORITIES_MAP[PRIORITIES.CRITICAL].defaultMessage;
  if (value >= PRIORITIES.HIGH) return PRIORITIES_MAP[PRIORITIES.HIGH].defaultMessage;
  if (value >= PRIORITIES.NORMAL) return PRIORITIES_MAP[PRIORITIES.NORMAL].defaultMessage;
  return PRIORITIES_MAP[PRIORITIES.LOW].defaultMessage;
};

export const isEmail = string => {
  if (!string) return false;
  const emailRegex = /^[\w.\\-]+@([\w-]+\.)+[\w-]{2,}$/gm;
  return emailRegex.test(string);
};
