import Tooltip from 'rapidfab/components/Tooltip';
import RouterContext from 'rapidfab/context/RouterContext';
import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import _isEqual from 'lodash/isEqual';
import {
  Button,
  Col,
  ListGroup,
  ListGroupItem,
  Card,
  Row,
} from 'react-bootstrap';
import Actions from 'rapidfab/actions';
import { getDocumentsForResourceUuid, getUploadModel, getUsersByUri, isFeatureEnabled } from 'rapidfab/selectors';
import { extractUuid } from 'rapidfab/utils/uuidUtils';
import { API_RESOURCES, DOCUMENT_RELATED_TABLE_NAMES, FEATURES, MIME_TYPES } from 'rapidfab/constants';
import Loading from 'rapidfab/components/Loading';
import Alert from 'rapidfab/utils/alert';
import FileInput from 'rapidfab/components/records/order/edit/FileInput';
import { FormattedMessage } from 'rapidfab/i18n';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import _map from 'lodash/map';
import { uniq } from 'lodash/array';
import { faInfoCircle, faUpload } from '@fortawesome/free-solid-svg-icons';
import DocumentVersionsModal from './DocumentVersionsModal';
import DocumentItem from './DocumentItem';

class Documents extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      upload: null,
      uploadLocation: null,

      isUploading: false,
      activeDocumentModalUri: null,
    };

    this.onChange = this.onChange.bind(this);
    this.onDelete = this.onDelete.bind(this);
    this.uploadDocument = this.uploadDocument.bind(this);
    this.initialize = this.initialize.bind(this);
    this.fetchUsers = this.fetchUsers.bind(this);
    this.setActiveDocumentModalUri = this.setActiveDocumentModalUri.bind(this);
  }

  componentDidMount() {
    if (!this.props.skipInitialize) {
      this.initialize();
    }
  }

  componentDidUpdate(prevProps) {
    const { upload, uploadLocation, isUploading } = this.state;
    const { uploadModel, relatedUUID } = this.props;

    if (prevProps.relatedUUID && relatedUUID !== prevProps.relatedUUID) {
      this.initialize();
    }

    if (
      uploadLocation &&
      uploadModel.uploadLocation === uploadLocation &&
      !_isEqual(uploadModel, prevProps.uploadModel) && uploadModel.uploading !== isUploading
    ) {
      const updatedFields = { isUploading: uploadModel.uploading };

      if (!uploadModel.uploading) {
        Alert.success(
          <FormattedMessage
            id="toaster.fileUploadSuccessfullyUpdated"
            defaultMessage="File {uploadName} successfully uploaded"
            values={{ uploadName: upload.name }}
          />);
        updatedFields.upload = null;
        updatedFields.uploadLocation = null;
      }
      // eslint-disable-next-line react/no-did-update-set-state
      this.setState(updatedFields);
    }
  }

  onChange(event) {
    const upload = event.target.files[0];
    this.setState({
      upload,
    });
  }

  async onDelete(document) {
    const { dispatch, handleAddDocumentToCarousel } = this.props;
    if (handleAddDocumentToCarousel) {
      handleAddDocumentToCarousel(document);
    }

    await dispatch(Actions.Api.nautilus[API_RESOURCES.DOCUMENT].delete(extractUuid(document.uri)));
  }

  setActiveDocumentModalUri(activeDocumentModalUri) {
    this.setState({ activeDocumentModalUri });
  }

  initialize() {
    const { relatedTable, relatedUUID } = this.props;
    this.props.dispatch(
      Actions.Api.nautilus[API_RESOURCES.DOCUMENT].list({
        related_table_name: relatedTable,
        related_uuid: relatedUUID,
      }, {}, {}, {}, true),
    ).then(response => {
      const { resources } = response.json;
      this.fetchUsers(resources);
    });
  }

  fetchUsers(users) {
    const userUris = uniq(_map(users, 'user'));

    if (userUris.length) {
      this.props.dispatch(Actions.Api.nautilus[API_RESOURCES.USERS].list({ uri: userUris }));
    }
  }

  async uploadDocument() {
    const { dispatch, relatedUUID, relatedTable, usersByUri } = this.props;
    const { upload } = this.state;
    const { name } = upload;

    const runDocumentPostResponse = await dispatch(
      Actions.Api.nautilus[API_RESOURCES.DOCUMENT].post({
        name,
        related_uuid: relatedUUID,
        related_table_name: relatedTable,
      }),
    );

    const uuid = extractUuid(runDocumentPostResponse.headers.location);
    const { uploadLocation } = runDocumentPostResponse.headers;
    this.setState({ uploadLocation });

    const isFileSizeMoreThan15MB = upload?.size > 15_000_000;
    await dispatch(Actions.UploadModel.upload(
      uploadLocation,
      upload,
      upload.type || MIME_TYPES.OCTET_STREAM,
      isFileSizeMoreThan15MB));
    const documentResponse = await dispatch(
      Actions.Api.nautilus[API_RESOURCES.DOCUMENT].get(uuid, true),
    );
    const shouldFetchNewUsers = !usersByUri[documentResponse?.json?.user];

    if (shouldFetchNewUsers) {
      await dispatch(Actions.Api.nautilus[API_RESOURCES.USERS]
        .list({ uri: documentResponse?.json?.user }));
    }
  }

  render() {
    const { onChange, onDelete, uploadDocument, setActiveDocumentModalUri } = this;
    const { upload, isUploading, activeDocumentModalUri } = this.state;
    const {
      documents,
      panelTitle = 'Additional Documents',
      panelStyle,
      usersByUri,
      uploadButtonIconOnly,
      isHawkingUser,
      isInverse,
      carousel,
      isCarouselFetching,
      documentFetchingData,
      handleAddDocumentToCarousel,
      isCustomDarkCardStyle,
      readOnly,
    } = this.props;

    const uploadPrompt = uploadButtonIconOnly
      ? <FontAwesomeIcon icon={faUpload} />
      : <FormattedMessage id="button.upload" defaultMessage="Upload" />;

    const getCardBgStyle = () => {
      if (isInverse) {
        return 'light';
      }
      if (isCustomDarkCardStyle) {
        return '';
      }
      return 'dark';
    };

    const getCardHeaderClassNames = () => {
      if (isInverse) {
        return 'pd-exp inverse';
      }

      if (isCustomDarkCardStyle) {
        return 'pd-exp custom-darken-modal--card-header';
      }
      return 'pd-exp accent';
    };

    const getCardWrapperClassNames = () => {
      if (isInverse) {
        return 'card-body-wrapper';
      }

      if (isCustomDarkCardStyle) {
        return '';
      }

      return 'card-body-wrapper-accent';
    };

    return (
      <Card
        data-cy="model-documents"
        bg={getCardBgStyle()}
        className={`m-b ${isCustomDarkCardStyle ? 'custom-darken-modal--card' : ''}`}
      >

        <Card.Header className={getCardHeaderClassNames()}>{panelTitle}</Card.Header>
        <div className={getCardWrapperClassNames()}>
          <Card.Body className={`${isCustomDarkCardStyle ? 'custom-darken-modal--card-body' : ''}`}>
            {isCarouselFetching ? <Loading /> : (
              <ListGroup fill>
                {documents.map(document => {
                  const isQuarantined = document.virus_scan === 'quarantined';
                  return (
                    <ListGroupItem key={document.uri} style={isQuarantined ? { border: '1px dashed #e74759' } : {}}>
                      <DocumentItem
                        carousel={carousel}
                        isCarouselFetching={isCarouselFetching}
                        document={document}
                        documentFetchingData={documentFetchingData}
                        onDelete={onDelete}
                        key={document.uri}
                        openVersionModal={() =>
                          setActiveDocumentModalUri(document.uri)}
                        usersByUri={usersByUri}
                        isHawkingUser={isHawkingUser}
                        handleAddDocumentToCarousel={handleAddDocumentToCarousel}
                      />
                    </ListGroupItem>
                  );
                })}
              </ListGroup>
            )}

            <Row>
              <Col sm={{ span: 9 }} className="d-flex align-items-baseline">
                <FileInput
                  fileType={FileInput.fileTypes.document}
                  handleFileChange={onChange}
                  name={upload && upload.name}
                  disabled={readOnly || isUploading}
                />
                {isUploading && (
                  <Tooltip
                    id="fileLoaderBusy"
                    placement="top"
                    trigger={(<FontAwesomeIcon icon={faInfoCircle} className="spacer-left" />)}
                  >
                    <span>A file upload operation is in progress.
                      Please wait for the completion of this process before initiating a new file upload.
                    </span>
                  </Tooltip>
                )}
              </Col>
              <Col sm={{ span: 3 }}>
                <Button
                  variant={panelStyle}
                  block
                  className="pull-right mt15"
                  disabled={readOnly || !upload || isUploading}
                  onClick={uploadDocument}
                >
                  {isUploading ? <Loading /> : uploadPrompt}
                </Button>
              </Col>
            </Row>

            {activeDocumentModalUri && (
              <DocumentVersionsModal
                uri={activeDocumentModalUri}
                handleClose={() => setActiveDocumentModalUri(null)}
                setActiveDocumentModalUri={setActiveDocumentModalUri}
              />
            )}
          </Card.Body>
        </div>
      </Card>
    );
  }
}

Documents.defaultProps = {
  panelTitle: 'Additional Documents',
  panelStyle: 'primary',
  uploadButtonIconOnly: false,
  isCustomDarkCardStyle: false,
  readOnly: false,
};

Documents.propTypes = {
  dispatch: PropTypes.func.isRequired,
  documents: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  uploadModel: PropTypes.shape({
    uploadLocation: PropTypes.string,
    uploading: PropTypes.bool,
  }).isRequired,
  relatedTable: PropTypes.oneOf(Object.values(DOCUMENT_RELATED_TABLE_NAMES)).isRequired,
  relatedUUID: PropTypes.string.isRequired,
  panelStyle: PropTypes.oneOf(['primary', 'default']),
  panelTitle: PropTypes.string,
  usersByUri: PropTypes.objectOf(PropTypes.shape({
    name: PropTypes.string,
    username: PropTypes.string,
  })).isRequired,
  uploadButtonIconOnly: PropTypes.bool,
  isHawkingUser: PropTypes.bool.isRequired,
  handleAddDocumentToCarousel: PropTypes.func.isRequired,
  carousel: PropTypes.shape({}).isRequired,
  isCarouselFetching: PropTypes.bool.isRequired,
  documentFetchingData: PropTypes.shape({}).isRequired,
  isCustomDarkCardStyle: PropTypes.bool,
  readOnly: PropTypes.bool,
};

const mapStateToProps = (state, ownProps) => {
  const uploadModel = getUploadModel(state);
  const uploadPercentage = uploadModel ? Number.parseInt(uploadModel.percent, 10) : 0;
  return ({
    usersByUri: getUsersByUri(state),
    documents: getDocumentsForResourceUuid(state, ownProps.relatedUUID),
    isHawkingUser: isFeatureEnabled(state, FEATURES.HAWKING_DEPLOYMENT),
    isCarouselFetching: state.ui.nautilus[API_RESOURCES.CAROUSEL].put.fetching,
    documentFetchingData: state.ui.nautilus[API_RESOURCES.DOCUMENT],
    uploadModel,
    uploadPercentage,
  });
};

Documents.defaultProps = {
  skipInitialize: false,
};

Documents.contextType = RouterContext;

Documents.propTypes = {
  isInverse: PropTypes.bool.isRequired,
  skipInitialize: PropTypes.bool,
};

export default connect(mapStateToProps)(Documents);
