import React from 'react';
import PropTypes from 'prop-types';
import _map from 'lodash/map';
import _isEmpty from 'lodash/isEmpty';
import _uniq from 'lodash/uniq';
import { FEATURES, MATERIAL_MODES, MATERIAL_UNITS, PRIORITIES } from 'rapidfab/constants';
import {
  Badge,
  Button,
  Col,
  Form as BSForm,
  FormControl,
  FormGroup,
  Modal,
  OverlayTrigger,
  Row,
  Tooltip,
} from 'react-bootstrap';
import { Form } from 'react-final-form';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  FORMATTED_DURATION_TYPES,
  FormattedDateTime,
  FormattedMessage,
  FormattedOptionalDuration,
} from 'rapidfab/i18n';
import Feature from 'rapidfab/components/Feature';
import { faExclamationCircle, faPencil } from '@fortawesome/free-solid-svg-icons';
import { ExportControlGeneralWarning } from 'rapidfab/components/ExportControl';
import { hhmmss } from 'rapidfab/utils/timeUtils';
import TimeFormattedHHMMSSWithTooltip from 'rapidfab/components/TimeFormattedHHMMSSWithTooltip';
import PrimaryRunDurationModal from 'rapidfab/components/modals/PrimaryRunDurationModal';
import SaveButton from 'rapidfab/components/SaveButton';
import PriorityLabel from 'rapidfab/components/records/run/PriorityLabel';
import FormGroupField from 'rapidfab/components/forms/FormGroupField';
import _isNil from 'lodash/isNil';
import FormattedWeight from 'rapidfab/components/FormattedWeight';

const ActivePiecesMetaData = ({
  pieces,
  onChangeRunName,
  name,
  priority,
  onToggleOverrideBuildPlateChecks,
  isBuildPlateExceeded,
  overrideBuildPlateChecks,
  formattedFillPercentage,
  startDate,
  dueDate,
  primaryRunDuration,
  onChangePrintTime,
  estWeight,
  onChangeEstWeight,
  printer,
  labelRelationships,
  isGeneralMFGLanguageEnabled,
  isLineItemsMode,
  exportControlledPiecesForLineItem,
}) => {
  const [showPrintTimeModal, setShowPrintTimeModal] = React.useState(false);
  const [showEstWeightModal, setShowEstWeightModal] = React.useState(false);
  const materialNames = _uniq(_map(pieces, 'material_name'));
  const materialMessage = materialNames.length === 1 ? materialNames[0] : 'Multiple';
  const isMoreThanOneMaterial = materialNames.length > 1;

  const layerThicknesses = _uniq(_map(pieces, 'layer_thickness'));
  const layerThicknessMessage = layerThicknesses.length === 1 ? layerThicknesses[0] : 'Multiple';
  const isMoreThanOneLayerThickness = layerThicknesses.length > 1;

  const renderExportControlWarning = () => {
    const hasExportControlWarning = (isLineItemsMode && !_isEmpty(exportControlledPiecesForLineItem)) ||
      (!isLineItemsMode && !_isEmpty(labelRelationships));

    if (!hasExportControlWarning) {
      return null;
    }

    return (
      <div className="mb8">
        <ExportControlGeneralWarning>
          {isLineItemsMode
            ? `${exportControlledPiecesForLineItem.length} pieces are export controlled. Please use necessary caution.`
            : `${labelRelationships.length} piece(s) are export controlled. Please use necessary caution.`}
        </ExportControlGeneralWarning>
      </div>
    );
  };

  return (
    <>
      <PrimaryRunDurationModal
        show={showPrintTimeModal}
        primaryRunDuration={hhmmss(primaryRunDuration, true)}
        onHide={() => setShowPrintTimeModal(false)}
        submit={values => {
          onChangePrintTime(values.time);
          setShowPrintTimeModal(false);
        }}
      />
      <Modal show={showEstWeightModal} onHide={() => setShowEstWeightModal(false)} backdrop="static">
        <Modal.Header closeButton>
          <FormattedMessage
            id="record.run.estWeight"
            defaultMessage="Est. Weight"
          />
        </Modal.Header>
        <Form
          validate={formValues => {
            const errors = {};
            if (_isNil(formValues.estWeight)) errors.estWeight = 'Please enter weight.';
            if (formValues.estWeight < 0) errors.estWeight = 'The value cannot be less than 0.';
            return errors;
          }}
          onSubmit={values => {
            onChangeEstWeight(values.estWeight);
            setShowEstWeightModal(false);
          }}
          initialValues={{ estWeight }}
        >
          {({ handleSubmit }) => (
            <form onSubmit={handleSubmit}>
              <Modal.Body>
                <FormGroupField
                  name="estWeight"
                  label={<FormattedMessage id="record.run.estWeight" defaultMessage="Est. Weight" />}
                  type="number"
                  required
                />
              </Modal.Body>
              <Modal.Footer>
                <Button onClick={() => setShowEstWeightModal(false)}>
                  <FormattedMessage id="button.cancel" defaultMessage="Cancel" />
                </Button>
                <SaveButton
                  showSaveIcon
                  label={<FormattedMessage id="button.submit" defaultMessage="Submit" />}
                  variant="success"
                  type="submit"
                  onClick={() => handleSubmit()}
                />
              </Modal.Footer>
            </form>
          )}
        </Form>
      </Modal>
      <Row className="mb8 d-flex align-items-center">
        <Col xs={6}>
          <div>Run Name</div>
          <FormGroup size="small">
            <FormControl
              type="text"
              value={name}
              onChange={onChangeRunName}
            />
          </FormGroup>
        </Col>
        <Col xs={6}>
          <div>
            <b className="spacer-right">
              <FormattedMessage id="record.run.piecesInPrimaryRun" defaultMessage="Pieces in Primary Run" />:
            </b>
            {pieces.length}
          </div>
        </Col>
      </Row>
      <Row>
        <Col xs={6}>
          <Feature featureName={FEATURES.PRIORITY}>
            <div className="mb8 d-flex">
              <b className="spacer-right">
                <FormattedMessage id="field.priority" defaultMessage="Priority" />:
              </b>
              {
                _isEmpty(pieces)
                  ? <FormattedMessage id="notAvailable" defaultMessage="N/A" />
                  : <PriorityLabel labelOnly value={priority} labelClassName="spacer-left" />
              }
            </div>
          </Feature>
          <div className="mb8 d-flex align-items-center">
            <b className="spacer-right">
              <FormattedMessage id="field.material" defaultMessage="Material" />:
            </b>
            {
              _isEmpty(materialNames)
                ? <FormattedMessage id="notAvailable" defaultMessage="N/A" />
                : materialMessage
            }
            {
              (isMoreThanOneMaterial && printer?.printer_type?.material_mode !== MATERIAL_MODES.MULTI_MATERIAL) && (
                <OverlayTrigger
                  placement="right"
                  overlay={(
                    <Tooltip>
                      <FormattedMessage
                        id="record.run.multiMaterialWarn"
                        defaultMessage="This printer may not support multiple materials"
                      />
                    </Tooltip>
                  )}
                >
                  <FontAwesomeIcon icon={faExclamationCircle} className="spacer-left" />
                </OverlayTrigger>
              )
            }

          </div>

          {renderExportControlWarning()}

          {printer?.printer_type?.material_mode === MATERIAL_MODES.MULTI_MATERIAL && (
            <div className="mb8">
              <b className="spacer-right">
                <Badge className="badge badge-sm" pill text="light" bg="primary">
                  <FormattedMessage
                    className=""
                    id="record.run.materialMode"
                    defaultMessage="This Printer supports Multiple Materials."
                  />
                </Badge>
              </b>

            </div>
          )}
          <div className="mb8 d-flex align-items-center">
            <b className="spacer-right">
              <FormattedMessage id="field.layer_thickness" defaultMessage="Layer Thickness" />:
            </b>
            {
              _isEmpty(layerThicknesses)
                ? <FormattedMessage id="notAvailable" defaultMessage="N/A" />
                : layerThicknessMessage
            }
            {
              isMoreThanOneLayerThickness && (
                <OverlayTrigger
                  placement="right"
                  overlay={(
                    <Tooltip>
                      <FormattedMessage
                        id="record.run.multiLayerThicknessWarn"
                        defaultMessage="This printer may not support multiple layer thicknesses"
                      />
                    </Tooltip>
                  )}
                >
                  <FontAwesomeIcon icon={faExclamationCircle} className="spacer-left" />
                </OverlayTrigger>
              )
            }
          </div>

        </Col>

        <Col xs={6}>
          <div className="mb8">
            <b className="spacer-right">
              <FormattedMessage
                id="record.run.estStartDate"
                defaultMessage="Est. Start Date"
              />:
            </b>
            {
              (pieces.length > 0)
                /* TODO: replace currentTime() */
                ? <FormattedDateTime value={startDate} />
                : <FormattedMessage id="notAvailable" defaultMessage="N/A" />
            }
          </div>
          <div className="mb8">
            <b className="spacer-right">
              <FormattedMessage
                id="record.run.estDueDate"
                defaultMessage="Est. Due Date"
              />:
            </b>
            {
              (pieces.length > 0)
                ? <FormattedDateTime value={dueDate} />
                : <FormattedMessage id="notAvailable" defaultMessage="N/A" />
            }
          </div>
          <div className="mb8">
            <b className="spacer-right">
              <FormattedMessage id="record.run.estDuration" defaultMessage="Est. Duration" />:
            </b>
            {
              (pieces.length > 0)
                ? (
                  <>
                    <TimeFormattedHHMMSSWithTooltip
                      timeInSeconds={primaryRunDuration}
                    >
                      <span>
                        <FormattedOptionalDuration
                          value={primaryRunDuration}
                          intervalFormat={FORMATTED_DURATION_TYPES.HH_MM_SS}
                        />

                      </span>
                    </TimeFormattedHHMMSSWithTooltip>
                    <Feature featureName={FEATURES.USER_CAN_UPDATE_ESTIMATES}>
                      <Button className="spacer-left" size="xs" onClick={() => setShowPrintTimeModal(true)}>
                        <FontAwesomeIcon size="sm" icon={faPencil} />
                      </Button>
                    </Feature>
                  </>
                )
                : <FormattedMessage id="notAvailable" defaultMessage="N/A" />
            }
          </div>
          {estWeight !== null && (
            <div className="mb8">
              <b className="spacer-right">
                <FormattedMessage id="record.run.estWeight" defaultMessage="Est. Weight" />:
              </b>
              {
                (pieces.length > 0)
                  ? (
                    <>
                      <FormattedWeight
                        value={estWeight}
                        valueUnits={MATERIAL_UNITS.KG}
                      />
                      <Button
                        className="spacer-left"
                        size="xs"
                        onClick={() => setShowEstWeightModal(true)}
                      >
                        <FontAwesomeIcon size="sm" icon={faPencil} />
                      </Button>
                    </>
                  )
                  : <FormattedMessage id="notAvailable" defaultMessage="N/A" />
              }
            </div>
          )}
          <div className="mb8 d-flex flex-direction-column">
            <div>
              <b className="spacer-right">
                <FormattedMessage id="record.run.estBuildFill" defaultMessage="Est. Build Fill" />:
              </b>
              {
                formattedFillPercentage || <FormattedMessage id="notAvailable" defaultMessage="N/A" />
              }
              {isBuildPlateExceeded && <FontAwesomeIcon icon={faExclamationCircle} className="spacer-left" />}
            </div>
            <BSForm.Check
              className="spacer-top"
              name="build-fill-warning"
              checked={overrideBuildPlateChecks}
              onChange={onToggleOverrideBuildPlateChecks}
              type="checkbox"
              label={
                isBuildPlateExceeded ? (
                  <FormattedMessage
                    id="record.run.buildFillCheckboxMsg"
                    defaultMessage="Build fill may exceed {machineType}'s size limits. Check this box to create this run anyways."
                    values={{ machineType: isGeneralMFGLanguageEnabled ? 'production device' : 'Select a Printer' }}

                  />
                ) : (
                  <FormattedMessage
                    id="record.run.overrideChecks"
                    defaultMessage="Override {machineType} size checks"
                    values={{ machineType: isGeneralMFGLanguageEnabled ? 'production device' : 'Select a Printer' }}
                  />
                )
              }
            />

          </div>
        </Col>
      </Row>
    </>
  );
};

ActivePiecesMetaData.defaultProps = {
  onChangeRunName: () => true,
  onChangePrintTime: () => true,
  onChangeEstWeight: () => true,
  priority: PRIORITIES.NORMAL,
  formattedFillPercentage: '',
  estWeight: null,
};

ActivePiecesMetaData.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  pieces: PropTypes.arrayOf(PropTypes.object).isRequired,
  priority: PropTypes.number,
  name: PropTypes.string.isRequired,
  onChangeRunName: PropTypes.func,
  isBuildPlateExceeded: PropTypes.bool.isRequired,
  overrideBuildPlateChecks: PropTypes.bool.isRequired,
  onToggleOverrideBuildPlateChecks: PropTypes.func.isRequired,
  formattedFillPercentage: PropTypes.string,
  startDate: PropTypes.string.isRequired,
  dueDate: PropTypes.string.isRequired,
  primaryRunDuration: PropTypes.number.isRequired,
  onChangePrintTime: PropTypes.func,
  estWeight: PropTypes.number,
  onChangeEstWeight: PropTypes.func,
  printer: PropTypes.shape({ printer_type: { material_mode: PropTypes.string } }).isRequired,
  labelRelationships: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  isGeneralMFGLanguageEnabled: PropTypes.bool.isRequired,
  isLineItemsMode: PropTypes.bool.isRequired,
  exportControlledPiecesForLineItem: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
};

export default ActivePiecesMetaData;
