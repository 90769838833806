/* eslint-disable no-param-reassign, unicorn/no-array-reduce */
import _assign from 'lodash/assign';
import _map from 'lodash/map';
import _union from 'lodash/union';
import _without from 'lodash/without';
import _isArray from 'lodash/isArray';
import Constants, {
  API_RESOURCES,
  CREATED_EVENT_RESOURCES_SUPPORTED,
  DELETED_EVENT_RESOURCES_SUPPORTED,
  EVENT_TYPES,
} from 'rapidfab/constants';
import { RESOURCES } from 'rapidfab/api';
import { extractUuid } from 'rapidfab/utils/uuidUtils';

export const initialState = Object.keys(RESOURCES).reduce(
  (hosts, host) =>
    ({ ...hosts,
      [host]: RESOURCES[host].reduce(
        (resources, resource) =>
          ({ ...resources, [resource]: [] }),
        {},
      ) }),
  {},
);

function reduceMethod(state, action) {
  const { type, uuid, json, headers } = action;

  switch (type) {
    case Constants.EVENT_STREAM_MESSAGE: {
      const { payload } = action;
      // !!!ATTENTION!!! please be aware that sometimes we have same resource
      // returned via different URIs with the same UUID.
      // E.g. ".../line-item/UUID" and "/service-provider/v1/line-item/"
      // Even though, current logic should not be affected - it may become and edge-case some day.
      return _union(state, [extractUuid(payload.uri)]);
    }
    case Constants.RESOURCE_POST_SUCCESS: {
      // Some resources are not uuid-like and we not need to add them to the store
      const locationUUID = extractUuid(headers.location);
      if (!locationUUID) {
        return state;
      }

      return _union(state, [locationUUID]);
    }
    case Constants.RESOURCE_LIST_SUCCESS:
      return _union(
        state,
        _map(json.resources, record => extractUuid(record.uri)),
      );
    case Constants.RESOURCE_GET_SUCCESS:
      // The OAuth token endpoint doesn't have a uri, so fall back to the uuid
      return _union(state, [extractUuid(json.uri) || json.uuid || `${action.api.resource}:${uuid}`]);
    case Constants.RESOURCE_DELETE_SUCCESS:
    case Constants.RESOURCE_MANUAL_REMOVE:
      return _isArray(uuid) ? _without(state, ...uuid) : _without(state, uuid);
    case Constants.RESOURCE_MANUAL_CLEAR:
      return [];
    default:
      return state;
  }
}

function reduceResource(state, action) {
  const resourceType = action.resourceType || action.api.resource;
  return _assign({}, state, {
    [action.api.resource]: reduceMethod(state[resourceType], action),
  });
}

function parseEventTopic(action) {
  const eventTopicParts = action.topic ? action.topic.split('.') : [];
  // E.g. topic="nautilus.comment.create"
  return {
    host: eventTopicParts[0],
    eventType: eventTopicParts[2],
    resource: eventTopicParts[1],
  };
}

function isCreatedEventSupportedForResource(resource) {
  return CREATED_EVENT_RESOURCES_SUPPORTED.has(resource);
}

function isDeletedEventSupportedForResource(resource) {
  return DELETED_EVENT_RESOURCES_SUPPORTED.has(resource);
}

function reducer(state = initialState, action) {
  switch (action.type) {
    case Constants.EVENT_STREAM_MESSAGE: {
      const { host, eventType, resource } = parseEventTopic(action);
      if (
        // "updated" event must be ignored since partial data
        // can be received (and it might break application)
        (eventType === EVENT_TYPES.CREATED
        && isCreatedEventSupportedForResource(resource))
      || (eventType === EVENT_TYPES.UPDATED
        && resource === API_RESOURCES.CASTOR_COSTING)
      ) {
        return _assign({}, state, {
          [host]: _assign({}, state[host], {
            [resource]: reduceMethod(state[host][resource], action),
          }),
        });
      }
      if (eventType === EVENT_TYPES.DELETED && isDeletedEventSupportedForResource(resource)) {
        const { payload } = action;
        return _assign({}, state, {
          [host]: _assign({}, state[host], {
            [resource]: _without(state[host][resource], extractUuid(payload.uri)),
          }),
        });
      }
      return state;
    }
    case Constants.RESOURCE_POST_SUCCESS:
    case Constants.RESOURCE_PUT_SUCCESS:
    case Constants.RESOURCE_LIST_SUCCESS:
    case Constants.RESOURCE_GET_SUCCESS:
    case Constants.RESOURCE_DELETE_SUCCESS:
    case Constants.RESOURCE_MANUAL_REMOVE:
    case Constants.RESOURCE_MANUAL_CLEAR:
      return _assign({}, state, {
        [action.api.host]: reduceResource(state[action.api.host], action),
      });
    default:
      return state;
  }
}

export default reducer;
