/**
 *
 * A list of common errors to catch and handle in a more friendly way
 * and less intrusive. Please see ticket `sc-55599`
 */

// eslint-disable-next-line import/prefer-default-export
export const COMMON_ERRORS = {
  CANNOT_READ_PROPERTIES_OF_UNDEFINED: 'TypeError: Cannot read properties of undefined',
};
