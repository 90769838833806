import { createSelector } from 'reselect';
import * as baseStateSelectors from 'rapidfab/selectors/baseStateSelectors';
import { getStateResources } from 'rapidfab/selectors/helpers/base';
import _map from 'lodash/map';
import _find from 'lodash/find';
import { getBureau } from './bureau';
import { getDocuments } from './document';

/**
 * Selector for the styles found at the `/bureau-branding` endpoint.
 */
const getBureauBrandings = createSelector(
  [baseStateSelectors.getStateBureauBrandings, getStateResources],
  (uuids, resources) => _map(uuids, uuid => resources[uuid]),
);

// eslint-disable-next-line import/prefer-default-export
export const getBureauBranding = createSelector(
  [getBureau, getBureauBrandings],
  (currentBureau, bureauBrandings) =>
    _find(bureauBrandings, { bureau: currentBureau?.uri }),
);

export const getBureauBrandingLogoDocument = createSelector(
  [getBureauBranding, getDocuments],
  (bureauBranding, documents) => {
    if (!bureauBranding?.logo) {
      return null;
    }
    return _find(documents, { uri: bureauBranding.logo });
  },
);
