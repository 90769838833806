import PrinterTypeModalContainer from 'rapidfab/containers/modals/PrinterTypeModalContainer';
import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import Actions from 'rapidfab/actions';
import isFetchingInitial from 'rapidfab/utils/isFetchingInitial';
import * as Selectors from 'rapidfab/selectors';

import PrinterTypesComponent from 'rapidfab/components/assets/printerTypes';
import { isFeatureEnabled } from 'rapidfab/selectors';
import { API_RESOURCES, FEATURES, PAGINATION_IGNORE_DEFAULT_LIMIT } from 'rapidfab/constants';
import { useSearchParams } from 'react-router-dom';

const PrinterTypesContainer = props => {
  const [searchParams] = useSearchParams();
  const uuid = searchParams.get('uuid');
  const fetching = useSelector(state => isFetchingInitial(
    state.ui.nautilus[API_RESOURCES.MANUFACTURER].list,
    state.ui.nautilus[API_RESOURCES.PRINTER_TYPE].list,
  ));
  const isTableDataFetching = useSelector(state =>
    state.ui.nautilus[API_RESOURCES.MANUFACTURER].list.fetching ||
    state.ui.nautilus[API_RESOURCES.PRINTER_TYPE].list.fetching,
  );
  const manufacturers = useSelector(Selectors.getManufacturers);
  const printerTypes = useSelector(Selectors.getPrinterTypes);
  const isGroupQualificationsFeatureEnabled =
    useSelector(state => isFeatureEnabled(state, FEATURES.GROUP_QUALIFICATIONS));

  const dispatch = useDispatch();

  const [isCreatePrinterTypeModalShown, setIsCreatePrinterTypeModalShown] = useState(false);

  const onInitialize = () => {
    const apiParams = [
      {}, // filters
      { limit: PAGINATION_IGNORE_DEFAULT_LIMIT }, // page
      {}, // searchParams
      {}, // queryParams
      true, // forced
    ];

    dispatch(Actions.Api.nautilus[API_RESOURCES.MANUFACTURER].list(...apiParams));
    dispatch(Actions.Api.nautilus[API_RESOURCES.PRINTER_TYPE].list(...apiParams));
  };

  useEffect(() => onInitialize(), []);

  return (
    <>
      <PrinterTypesComponent
        {...props}
        isTableDataFetching={isTableDataFetching}
        fetching={fetching}
        manufacturers={manufacturers}
        printerTypes={printerTypes}
        isGroupQualificationsFeatureEnabled={isGroupQualificationsFeatureEnabled}
        setCreatePrinterTypeModal={setIsCreatePrinterTypeModalShown}
      />
      {(isCreatePrinterTypeModalShown || uuid) && (
        <PrinterTypeModalContainer
          isVisible={isCreatePrinterTypeModalShown}
          hideModal={() => setIsCreatePrinterTypeModalShown(false)}
        />
      )}
    </>

  );
};

export default PrinterTypesContainer;
