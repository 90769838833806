/* eslint-disable import/prefer-default-export */
// intersection observer HOC
//
// This is a higher order component that wraps a component and provides it with
// an `isIntersecting` prop that is true when the component is in the viewport.
//
// The component is wrapped in a div that has a ref attached to it. The ref is
// used to determine if the component is in the viewport.

import React, { useEffect, useRef, useState, useMemo } from 'react';
import PropTypes from 'prop-types';

export const useElementOnScreen = options => {
  const containerRef = useRef(null);
  const [isVisible, setIsVisible] = useState(false);

  const callbackFunction = entries => {
    const [entry] = entries;
    setIsVisible(entry.isIntersecting);
  };

  useEffect(() => {
    const observer = new IntersectionObserver(callbackFunction, options);
    if (containerRef.current) {
      observer.observe(containerRef.current);
    }
    return () => {
      if (containerRef.current) {
        observer.unobserve(containerRef.current);
      }
    };
  },
  [containerRef, options]);

  return [containerRef, isVisible];
};

export function withIntersectionObserver(WrappedComponent, options) {
  return function WithIntersectionObserver(props) {
    const [containerRef, isVisible] = useElementOnScreen(options);
    return (
      <div ref={containerRef}>
        <WrappedComponent {...props} isIntersecting={isVisible} />
      </div>
    );
  };
}

/**
 * IntersectionObserver High Order Component
 */
export const withIntersectionObserverHOC = (WrappedComponent, options) => {
  const WithIntersectionObserver = props => {
    const [containerRef, isVisible] = useElementOnScreen(options);
    return (
      <div ref={containerRef}>
        <WrappedComponent {...props} isIntersecting={isVisible} />
      </div>
    );
  };

  return WithIntersectionObserver;
};

// Render if visible
// This component is used to render a component only when it is visible in the viewport.
// It uses the IntersectionObserver API to determine if the component is visible.
// TODO: Implement React.OffScreen when React 19 is released
export const RenderIfVisible = ({
  initialVisible,
  defaultHeight,
  visibleOffset,
  stayRendered,
  root,
  rootElement,
  rootElementClass,
  placeholderElement,
  placeholderElementClass,
  children,
  getVisibleStatus,
  keepState,
}) => {
  const [isVisible, setIsVisible] = useState(initialVisible);
  const wasVisible = useRef(false);
  const placeholderHeight = useRef(defaultHeight);
  const intersectionRef = useRef(null);

  const wasIntersected = useRef(false);

  useEffect(() => {
    if (intersectionRef.current) {
      const localRef = intersectionRef.current;
      const observer = new IntersectionObserver(
        entries => {
          if (entries[0].isIntersecting) {
            wasIntersected.current = true;
          }

          // Before switching off `isVisible`, set the height of the placeholder
          if (!entries[0].isIntersecting) {
            placeholderHeight.current = localRef?.offsetHeight; // TODO check
          }
          if (typeof window !== 'undefined' && window.requestIdleCallback) {
            window.requestIdleCallback(
              () => {
                setIsVisible((initialVisible && !wasIntersected.current) ? true : entries[0].isIntersecting);
                if (getVisibleStatus) {
                  getVisibleStatus((initialVisible && !wasIntersected.current) ? true : entries[0].isIntersecting);
                }
              },
              {
                timeout: 600, // TODO check the number
              },
            );
          } else {
            setIsVisible(entries[0].isIntersecting);
            if (getVisibleStatus) {
              getVisibleStatus(entries[0].isIntersecting);
            }
          }
        },
        { root, rootMargin: `${visibleOffset}px 0px ${visibleOffset}px 0px` },
      );

      observer.observe(localRef);
      return () => {
        if (localRef) {
          observer.unobserve(localRef);
        }
      };
    }
    return () => {};
  }, []);

  useEffect(() => {
    if (isVisible) {
      wasVisible.current = true;
    }
  }, [isVisible]);

  const placeholderStyle = { height: placeholderHeight.current };

  const rootClasses = useMemo(
    () => `renderIfVisible ${rootElementClass}`,
    [rootElementClass],
  );
  const placeholderClasses = useMemo(
    () => `renderIfVisible-placeholder ${placeholderElementClass}`,
    [placeholderElementClass],
  );

  // A solution to keep the state of the component
  // Not as efficient as unmounting the component
  // but still preventing unresponsiveness
  if (keepState) {
    const shouldDisplayChildren = isVisible || (stayRendered && wasVisible.current);
    return (
      <div ref={intersectionRef}>
        <div className={`${shouldDisplayChildren ? '' : 'hidden'}`}>
          {children}
        </div>
        <div className={`${shouldDisplayChildren ? 'hidden' : ''}`} style={placeholderStyle} />
      </div>
    );
  }

  // eslint-disable-next-line react/no-children-prop
  return React.createElement(rootElement, {
    children: (isVisible || (stayRendered && wasVisible.current)) ? (
      <>
        {children}
      </>
    ) : (
      React.createElement(placeholderElement, {
        className: placeholderClasses,
        style: placeholderStyle,
      })
    ),
    ref: intersectionRef,
    className: rootClasses,
  });
};

RenderIfVisible.propTypes = {
  initialVisible: PropTypes.bool,
  defaultHeight: PropTypes.number,
  visibleOffset: PropTypes.number,
  stayRendered: PropTypes.bool,
  root: PropTypes.shape({}),
  rootElement: PropTypes.string,
  rootElementClass: PropTypes.string,
  placeholderElement: PropTypes.node,
  placeholderElementClass: PropTypes.string,
  children: PropTypes.node,
  getVisibleStatus: PropTypes.func,
  keepState: PropTypes.bool,
};

RenderIfVisible.defaultProps = {
  initialVisible: false,
  defaultHeight: 300,
  visibleOffset: 1,
  stayRendered: false,
  root: null,
  rootElement: 'div',
  rootElementClass: '',
  placeholderElement: 'div',
  placeholderElementClass: '',
  children: null,
  getVisibleStatus: null,
  keepState: false,
};
