import { faClock } from '@fortawesome/free-regular-svg-icons';
import { faCalendarCheck } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { resetRcTooltipInnerStyle } from 'rapidfab/constants/styles';
import RCTooltip from 'rc-tooltip';
import React from 'react';
import PropTypes from 'prop-types';

const LinearProgressBar = ({ active, percentage = 0, notStartedText, progressData }) => {
  const { elapsedTime = null, remainingTime = null, completionTime = null } = progressData;

  const percentageData = percentage > 0 || active ? `${percentage}%` : notStartedText;

  const renderEstimationTimeTooltip = () => {
    // Return percentage text without tooltip if no time data is available
    if (!elapsedTime && !remainingTime && !completionTime) {
      return <span className="LinearProgressBarPercentage">{percentageData}</span>;
    }

    const renderRemainingTime = () => (remainingTime ? `(${remainingTime})` : null);

    return (
      <RCTooltip
        placement="bottom"
        id="estimationTimeTooltip"
        destroyTooltipOnHide
        overlayInnerStyle={resetRcTooltipInnerStyle}
        mouseLeaveDelay={0.4}
        overlay={(
          <div className="darkTooltip">
            {elapsedTime && (
              <p className="mb0">
                <span className="font-weight-400">Elapsed Time:</span> <FontAwesomeIcon icon={faClock} /> {elapsedTime}
              </p>
            )}
            {active && completionTime && (
              <p className="mb0">
                <span className="font-weight-400">Estimated Completion:</span> <FontAwesomeIcon icon={faCalendarCheck} /> {completionTime}
                {' '}{renderRemainingTime()}
              </p>
            )}
            {!active && percentage === 100 && completionTime && (
              <p className="mb0">
                <span className="font-weight-400">Completion Date:</span> <FontAwesomeIcon icon={faCalendarCheck} /> {completionTime}
              </p>
            )}
          </div>
        )}
      >
        <span className="LinearProgressBarPercentage cursor-default">{percentageData}</span>
      </RCTooltip>
    );
  };

  return (
    <div className="LinearProgressBar">
      <div className="LinearProgressBarLine" style={{ width: `${percentage}%` }} />
      {renderEstimationTimeTooltip()}
    </div>
  );
};

LinearProgressBar.defaultProps = {
  notStartedText: 'Not Started',
  active: false,
  progressData: {},
};

LinearProgressBar.propTypes = {
  percentage: PropTypes.number.isRequired,
  notStartedText: PropTypes.string,
  active: PropTypes.bool,
  progressData: PropTypes.shape({
    elapsedTime: PropTypes.string,
    remainingTime: PropTypes.string,
    completionTime: PropTypes.string,
  }),
};

export default LinearProgressBar;
