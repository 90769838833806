import { useState, useEffect } from 'react';
import Actions from 'rapidfab/actions';
import _map from 'lodash/map';
import { useDispatch, useSelector } from 'react-redux';
import Constants from 'rapidfab/constants';
import { getStateResources } from 'rapidfab/selectors/helpers/base';
import { createSelector } from 'reselect';
import _values from 'lodash/values';

const FETCH_METHODS = {
  LIST: 'list',
  GET: 'get',
};

/**
 * Master useFetchSingleResource hook for LIST and GET requests on single resources.
 *
 * @example
 * // Fetch a single resource using the only mandatory parameter, which is 'resource' (all others are optional).
 *
 * const { data, responseData, error, isLoading } = useFetchSingleResource({
 *   resource: API_RESOURCES.LINE_ITEM,
 * })
 *
 *
 * @example
 * // Fetch a single resource using all available parameters.
 *
 * const { data, responseData, error, isLoading } = useFetchSingleResource({
 *  resource: API_RESOURCES.LINE_ITEM,
 *  selector: state => Selectors.myCoolSelector(state, myParameter),
 *  configuration: [{ uri: myQuery }, { limit: 10 }, {}, {}, true],
 *  shouldFetch: myBoolean,
 *  method: 'list'
 * })
 *
 *
 * @param {Object} paramObject
 * @param {string} paramObject.resource
 * @param {'list' | 'get'} paramObject.method
 * @param {Object} paramObject.configuration
 * @param {Object} paramObject.configuration.filters
 * @param {Object} paramObject.configuration.page
 * @param {Object} paramObject.configuration.searchParams
 * @param {Object} paramObject.configuration.queryParams
 * @param {bool} paramObject.configuration.forced
 * @param {number} paramObject.configuration.apiVersion
 * @param {Object} paramObject.configuration.config
 * @param {function(state:Object)} paramObject.selector
 *
 * @returns {FetchDataHookResult}
 */
function useFetchSingleResource({
  // The resource we are trying to fetch, e.g. `API_RESOURCES.LINE_ITEM`
  resource,

  /* (optional) A custom selector which will change what is returned at `data`,
  e.g. `state => getMyResourceForLineItems(state, lineItem)`, if this is left blank it will select all resources
  available. */
  selector = null,

  /* (optional) Configuration (as specified above). For the API list request, e.g. `[{ uri: '...' }, { limit: 10 }]`
  For API get requests pass in a UUID. */
  configuration = [],

  // (optional) Fetch method to be used, can be either 'list' or 'get'. 'list' by default.
  method = FETCH_METHODS.LIST,

  // (optional) Boolean to determine whether fetch should occur.
  shouldFetch = true,

  // (optional) Boolean to determine whether fetch should clear redux store of old state.
  clearOnNewRequest = false,
}) {
  const dispatch = useDispatch();
  const [responseData, setResponseData] = useState(null);
  const [responseHeaders, setResponseHeaders] = useState(null);

  const defaultSelector = createSelector(
    [state => state.api.nautilus[resource],
      getStateResources],
    (uuids, resources) => _map(uuids, uuid => resources[uuid]),
  );

  const data = useSelector(selector || defaultSelector);

  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);

  // Request should stall until all filter Object.values are present.
  const allFilterValuesPresent = !!_values(configuration[0]).every(value => value !== undefined);

  const dispatchHttpRequest = async () => {
    if (clearOnNewRequest) {
      await dispatch(Actions.Api.nautilus[resource].clear());
    }
    try {
      let response = null;

      if (method === FETCH_METHODS.LIST) {
        response = await dispatch(Actions.Api.nautilus[resource].list(...configuration));
      } else if (method === FETCH_METHODS.GET) {
        response = await dispatch(Actions.Api.nautilus[resource].get(configuration));
      }

      if (
        [Constants.RESOURCE_LIST_SUCCESS, Constants.RESOURCE_GET_SUCCESS, Constants.RESOURCE_REQUEST_SUPPRESSED]
          .includes(response.type)
      ) {
        setResponseData(response?.json);
        setResponseHeaders(response?.headers);
      } else {
        throw new Error('Something went wrong during performing this network request, please see the following response:', response);
      }
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error(`[useFetchSingleResource] [/${resource}]:`, error);
      setError(error);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (!allFilterValuesPresent) {
      // eslint-disable-next-line no-console
      console.warn(`[useFetchSingleResource] [/${resource}]:`,
        'Halting request; refetching when all filter values are present.', configuration[0]);
    }

    if (shouldFetch && allFilterValuesPresent) {
      dispatchHttpRequest();
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [resource, allFilterValuesPresent]);

  return {
    data,
    responseData,
    error,
    isLoading,
    responseHeaders,
  };
}

export default useFetchSingleResource;
