import OrderTemplates from 'rapidfab/components/organize/OrderTemplates';
import { getOrderTemplates, isFeatureEnabled } from 'rapidfab/selectors';
import * as Selectors from 'rapidfab/selectors';
import React from 'react';
import { useSelector } from 'react-redux';
import isFetchingInitial from 'rapidfab/utils/isFetchingInitial';
import { API_RESOURCES, FEATURES } from 'rapidfab/constants';
import withRecordsListHandling from 'rapidfab/containers/hocs/withRecordsListHandling';

const OrderTemplatesContainer = props => {
  const fetching = useSelector(state =>
    isFetchingInitial(state.ui.nautilus[API_RESOURCES.ORDER].list),
  );
  const orderTemplates = useSelector(Selectors.getOrderTemplates);
  const isDebugModeEnabled = useSelector(Selectors.getIsDebugModeEnabled);
  const isOrderOwnerColumnEnabled = useSelector(state =>
    isFeatureEnabled(state, FEATURES.ORDER_ORDER_OWNER));

  const selected = {
    fetching,
    orderTemplates,
    isOrderOwnerColumnEnabled,
    isDebugModeEnabled,
  };

  return <OrderTemplates {...props} {...selected} />;
};

export default withRecordsListHandling(
  OrderTemplatesContainer,
  getOrderTemplates,
  [API_RESOURCES.ORDER],
  {
    defaultLimit: 50,
    multicolumnSearch: true,
    defaultSort: '-created',
    staticQueryParams: {
      // Show only template Orders
      is_template: true,
    },
  },
);
