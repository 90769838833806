import { createSelector } from 'reselect';
import * as baseStateSelectors from 'rapidfab/selectors/baseStateSelectors';
import { getStateResources } from 'rapidfab/selectors/helpers/base';
import _map from 'lodash/map';
import _keyBy from 'lodash/keyBy';

const STATIC_LIBRARIES = new Set(['My Library', 'Company Library', 'All Designs']);
export const getLibraries = createSelector(
  [baseStateSelectors.getStateLibrary, getStateResources],
  (uuids, resources) => _map(uuids, uuid => resources[uuid])
    // As "My Library" and "Company Library" are static, we don't want to show them in the list
    .filter(resource => !STATIC_LIBRARIES.has(resource.name)),
);

export const getLibrariesByUri = createSelector([getLibraries], libraries =>
  _keyBy(libraries, 'uri'),
);
