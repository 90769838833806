import React, { useEffect, useState } from 'react';
import {
  Button,
  Col,
  FormControl,
  FormGroup,
  FormLabel,
  InputGroup,
  ListGroup,
  Modal,
  OverlayTrigger,
  Row, Tooltip,
} from 'react-bootstrap';
import _keyBy from 'lodash/keyBy';
import { FormattedMessage } from 'react-intl';
import FileInput from 'rapidfab/components/records/order/edit/FileInput';
import { Field, Form } from 'react-final-form';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCaretDown, faEdit, faInfoCircle, faPlus, faQuestionCircle, faSave, faTimes } from '@fortawesome/free-solid-svg-icons';
import { useDispatch, useSelector } from 'react-redux';
import {
  API_RESOURCES,
  PAGINATION_IGNORE_DEFAULT_LIMIT,
  DOCUMENT_RELATED_TABLE_NAMES,
} from 'rapidfab/constants';
import _isEmpty from 'lodash/isEmpty';
import _map from 'lodash/map';
import _pick from 'lodash/pick';
import _filter from 'lodash/filter';
import _sortBy from 'lodash/sortBy';
import _flatMap from 'lodash/flatMap';
import Actions from 'rapidfab/actions';
import { extractUuid } from 'rapidfab/utils/uuidUtils';
import Loading from 'rapidfab/components/Loading';
import PropTypes from 'prop-types';
import Alert from 'rapidfab/utils/alert';
import { FormControlSelect } from 'rapidfab/components/formTools';
import _upperFirst from 'lodash/upperFirst';
import { DateInput } from 'rapidfab/components/forms/DateInput';

const CustomPanelLevelInstructionsView = ({
  materialTestUnitsByUri,
  selectedTestOperation,
  materialTestOperationLinkings,
  checklistForSelectedOperationLinking,
  onClose,
  savingState: [
    saving,
    setSaving,
  ],
  stateSetters: [
    setInstructionsForTestPanel,
    setIsCreatingPanelLevelInstruction,
    setCustomInstructionsForCurrentOperation,
  ],
  instructionsForSelectedOperationLinking,
}) => {
  const dispatch = useDispatch();

  const [customInstructions] = useState({
    0: '',
  });

  const handleSaveCustomInstructions = async formValues => {
    setIsCreatingPanelLevelInstruction(false);
    const customInstructionPayload = {
      material_test_operation: selectedTestOperation.uri,
      material_work_checklist: checklistForSelectedOperationLinking,
      description: '',
      name: formValues.name,
      position: instructionsForSelectedOperationLinking.length + 1,
      unit: formValues.unit,
    };

    setSaving(true);
    const instructionResponse = await dispatch(Actions.Api.nautilus[API_RESOURCES.MATERIAL_TEST_INSTRUCTION].post(
      customInstructionPayload,
    ));
    const instructionLocationUri = instructionResponse.headers.location;
    const newInstructionPayload = {
      bureau: null,
      material_test_operation: selectedTestOperation.uri,
      material_work_checklist: checklistForSelectedOperationLinking,
      name: formValues.name,
      description: '',
      position: instructionsForSelectedOperationLinking.length + 1,
      uri: instructionLocationUri,
      unit: formValues.unit,
    };

    /* Fetch the new linkings as the `material_test_instructions` key would have updated */
    await dispatch(Actions.Api.nautilus[API_RESOURCES.MATERIAL_TEST_OPERATION_LINKING].list({
      uri: materialTestOperationLinkings.map(item => item.uri),
    }, {}, {}, {}, true));

    setInstructionsForTestPanel(previous =>
      [...previous, newInstructionPayload]);
    setCustomInstructionsForCurrentOperation(previous =>
      [...previous, newInstructionPayload]);
    setSaving(false);
  };

  return (
    <Form
      onSubmit={formValues => handleSaveCustomInstructions(formValues)}
      render={({ handleSubmit }) => (
        <div className="mt15">
          <div className="d-flex align-items-center justify-content-between w-full">
            <h3>Custom Instructions</h3>
            <div className="d-flex align-items-center">
              <Button
                onClick={onClose}
                variant="danger"
              >
                <FormattedMessage
                  id="button.cancel"
                  defaultMessage="Cancel"
                />
              </Button>
              <Button
                className="spacer-left"
                onClick={handleSubmit}
              >
                Save Instruction
                <FontAwesomeIcon
                  className="spacer-left"
                  size="sm"
                  role="button"
                  icon={faSave}
                  disabled={saving}
                />
              </Button>
            </div>
          </div>
          {
            _map(customInstructions, customInstruction => (
              <>
                <FormGroup>
                  <FormLabel>
                    {customInstruction?.name}
                  </FormLabel>
                  <div className="d-flex align-items-center">
                    <Field
                      name="name"
                      render={({ input }) => (
                        <FormControl
                          name="name"
                          placeholder="Custom Instruction Name"
                          type="text"
                          onChange={input.onChange}
                        />
                      )}
                    />
                    <Field
                      className="spacer-left"
                      name="unit"
                      render={({ input }) => (

                        // list of units here
                        <FormControl
                          className="spacer-left"
                          name="unit"
                          type="select"
                          as="select"
                          onChange={input.onChange}
                        >
                          <option key={null} value={null}>
                            Unit
                          </option>
                          {
                            selectedTestOperation.unit_options.map(unit => (
                              <option key={unit} value={unit}>
                                {materialTestUnitsByUri[unit]?.description} ({materialTestUnitsByUri[unit]?.symbol})
                              </option>
                            ))
                          }
                        </FormControl>
                      )}
                    />
                  </div>
                </FormGroup>
              </>
            ))
          }
        </div>
      )}
    />
  );
};

CustomPanelLevelInstructionsView.propTypes = {
  selectedTestOperation: PropTypes.shape({
    uri: PropTypes.string,
    unit_options: PropTypes.arrayOf(PropTypes.string),
  }).isRequired,
  checklistForSelectedOperationLinking: PropTypes.string.isRequired,
  onClose: PropTypes.func.isRequired,
  savingState: PropTypes.shape({
    saving: PropTypes.bool.isRequired,
    setSaving: PropTypes.func.isRequired,
  }).isRequired,
  instructionsForSelectedOperationLinking: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  materialTestOperationLinkings: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  stateSetters: PropTypes.arrayOf(PropTypes.shape({
    setInstructionsForTestPanel: PropTypes.func,
    setIsCreatingPanelLevelInstruction: PropTypes.func,
    setCustomInstructionsForCurrentOperation: PropTypes.func,
  })).isRequired,
  materialTestUnitsByUri: PropTypes.shape({}).isRequired,
};

const MaterialTestListGroupItem = ({
  onClick,
  children,
  selected,
}) => (
  // eslint-disable-next-line jsx-a11y/no-static-element-interactions
  <div
    className={`material-test-list-group-item ${selected ? 'selected' : ''}`}
    onClick={onClick}
  >
    {children}
  </div>
);

MaterialTestListGroupItem.propTypes = {
  onClick: PropTypes.func.isRequired,
  children: PropTypes.node.isRequired,
  selected: PropTypes.bool.isRequired,
};

const MaterialAddTestPanelModal = ({
  samples,
  materialTestPanel,
  onClose,
  filteredSamples,
  materialTestOperations,
  materialTestOperationLinkings,
  materialTestUnitsByUri,
  onInitializeMaterialTest,
  setTestPanelCurrentlyEdited,
}) => {
  /* Sort operation linkings before render */
  const sortedMaterialTestOperationLinkings = _sortBy(
    materialTestOperationLinkings,
    'material_test_operation');

  const [isTestPanelHeaderEditable, setIsTestPanelHeaderEditable] = useState(false);
  const [editableUnits, setEditableUnits] = useState({});
  const [selectedOperationLinking, setSelectedOperationLinking] = useState(sortedMaterialTestOperationLinkings[0]);
  const [isCreatingPanelLevelInstruction, setIsCreatingPanelLevelInstruction] = useState(false);

  const [instructionsForCurrentOperation,
    setInstructionsForCurrentOperation] = useState([]);
  const [customInstructionsForCurrentOperation,
    setCustomInstructionsForCurrentOperation] = useState([]);

  const [loading, setLoading] = useState(false);
  const [saving, setSaving] = useState(false);
  const isFetchingMaterialPanel = useSelector(state =>
    state.ui.nautilus[API_RESOURCES.MATERIAL_TEST_PANEL].get.fetching ||
    state.ui.nautilus[API_RESOURCES.MATERIAL_TEST_INSTRUCTION].get.fetching);
  const isFetchingCustomInstructions = useSelector(state =>
    state.ui.nautilus[API_RESOURCES.MATERIAL_TEST_INSTRUCTION].post.fetching ||
    state.ui.nautilus[API_RESOURCES.MATERIAL_TEST_OPERATION_WORK_CHECKLISTS_FOR_PANEL].put.fetching);

  const materialTestOperationsByUri = _keyBy(materialTestOperations, 'uri');
  const selectedTestOperation =
    materialTestOperationsByUri[selectedOperationLinking?.material_test_operation];
  const selectedTestOperationName =
    materialTestOperationsByUri[selectedOperationLinking?.material_test_operation]?.name;
  const dispatch = useDispatch();

  const [initialFormValues, setInitialFormValues] = useState({});
  const [instructionsForTestPanel, setInstructionsForTestPanel] = useState([]);
  const [initialInstructionReportFormValues, setInitialInstructionReportFormValues] = useState([]);
  const initialInstructionReportFormValuesByInstructionUri =
    _keyBy(initialInstructionReportFormValues, 'material_test_instruction');
  const [instructionReportFormState, setInstructionReportFormState] = useState({});

  const isTestPanelLevel = selectedOperationLinking.related_uri === materialTestPanel?.uri;

  const initializeInstructions = (panelLevelOperationLinkings = null) => {
    setLoading(true);
    const instructionUris = panelLevelOperationLinkings?.length ?
      _flatMap(panelLevelOperationLinkings, 'material_test_instructions') :
      _flatMap(materialTestOperationLinkings, 'material_test_instructions');
    dispatch(Actions.Api.nautilus[API_RESOURCES.MATERIAL_TEST_INSTRUCTION].list({
      uri: instructionUris,
    }, { limit: PAGINATION_IGNORE_DEFAULT_LIMIT }, {}, {}, true))
      .then(testInstructionResponse => {
        const allInstructionsForTestPanel = testInstructionResponse.json?.resources;
        /* This will set the input fields to be displayed. (not values) */
        setInstructionsForTestPanel(allInstructionsForTestPanel);
      })
      .finally(() => setLoading(false));
  };

  const onAddTestPanelModalHeaderUpdate = payload => {
    const { panelName, startDate, completionDate } = payload;

    if (startDate && completionDate && (startDate > completionDate)) {
      Alert.error(
        <FormattedMessage
          id="toaster.error.materialTests.completionDateBeforeStartDate"
          defaultMessage="Completion date can't be before the start date"
        />,
      );
      return;
    }
    setLoading(true);

    dispatch(Actions.Api.nautilus[API_RESOURCES.MATERIAL_TEST_PANEL].put(
      extractUuid(materialTestPanel?.uri),
      {
        name: panelName,
        start_date: startDate || null,
        completion_date: completionDate || null,
      },
    )).then(() => {
      setIsTestPanelHeaderEditable(false);
      setLoading(false);
    })
      .catch(error => { Alert.error(error); })
      .finally(() => { setLoading(false); });
  };

  const initializeInstructionReports = async () => {
    if (materialTestPanel?.uri) {
      setLoading(true);
      /* Get uploaded documents */
      const documentsResponse = await dispatch(Actions.Api.nautilus[API_RESOURCES.DOCUMENT]
        .list({
          related_table_name: DOCUMENT_RELATED_TABLE_NAMES.MATERIAL_TEST_INSTRUCTION_REPORT,
        }));

      const allDocuments = documentsResponse.json?.resources;

      /* Get reports */
      dispatch(Actions.Api.nautilus[API_RESOURCES.MATERIAL_TEST_INSTRUCTION_REPORT].clear());
      const testReportDataResponse = await dispatch(Actions.Api.nautilus[API_RESOURCES.MATERIAL_TEST_INSTRUCTION_REPORT]
        .list({ material_test_panel: materialTestPanel?.uri },
          { limit: PAGINATION_IGNORE_DEFAULT_LIMIT }, {}, {}, true));

      const existingReports = testReportDataResponse.json?.resources;
      const existingReportsByTestInstruction = _keyBy(existingReports, 'material_test_instruction');

      /* Add the `uploadedTestDocument` key to each instruction-report; this is where
      the uploaded documents are stored in state */
      existingReports.forEach(report => {
        const uploadedTestDocumentForInstructionReport =
          _filter(allDocuments, { related_uuid: extractUuid(report.uri) });
        const newestTestDocument =
          _sortBy(uploadedTestDocumentForInstructionReport, 'created').reverse()[0];
        if (uploadedTestDocumentForInstructionReport) {
          // eslint-disable-next-line no-param-reassign
          report.uploadedTestDocument = newestTestDocument;
          // eslint-disable-next-line no-param-reassign
          report.containsTestDocument = true;
        }
      });

      setInstructionReportFormState(existingReportsByTestInstruction);
      setInitialInstructionReportFormValues(existingReports);
      setLoading(false);
    }
  };

  useEffect(() => {
    const fetchExistingOperationLinkings = async () => {
      if (materialTestPanel?.uri) {
        const operationlinkingResponse =
          await dispatch(Actions.Api.nautilus[API_RESOURCES.MATERIAL_TEST_OPERATION_LINKING].list({
            related_uri: materialTestPanel?.uri,
          }, {}, {}, {}, true));
        const panelLevelOperationLinkings = operationlinkingResponse.json?.resources;
        if (!_isEmpty(panelLevelOperationLinkings)) {
          onInitializeMaterialTest(materialTestPanel?.uri);
        } else {
          onInitializeMaterialTest();
        }
        initializeInstructions(panelLevelOperationLinkings);
        initializeInstructionReports();
      }
    };
    fetchExistingOperationLinkings();
  }, []);
  useEffect(() => {
    if (materialTestOperationLinkings.length) {
      setSelectedOperationLinking(
        previous => materialTestOperationLinkings.find(item => item.uri === previous.uri)
          || materialTestOperationLinkings[0],
      );
    }
  }, [materialTestOperationLinkings]);
  useEffect(() => {
    if (isCreatingPanelLevelInstruction && !isTestPanelLevel) {
      onInitializeMaterialTest(materialTestPanel?.uri);
      // initializeInstructions();
      initializeInstructionReports();
    }
  }, [isCreatingPanelLevelInstruction]);
  useEffect(() => {
    /* We want to get all the reports for this entire test panel, if it does exist. */
    initializeInstructionReports();
  }, [materialTestPanel]);

  const createNewInstructionReportPayload = (instructionFieldUri, fieldData) => (
    { material_test_instruction: instructionFieldUri,
      material_test_panel: materialTestPanel?.uri,
      notes: fieldData.notes || '',
      value: Number.parseFloat(fieldData.value),
      // This checks to see if the user selected and alterantive unit in the form
      // and if they did, it should be added to the payload
      // if not, the original units selected by default will
      // automatically be use
      ...(fieldData.unit) && { unit: fieldData.unit } });

  const saveInstructionReport = async instructionFields => {
    setSaving(true);

    let instructionReportFormStateWithUpdatedUris = {
      ...instructionReportFormState,
    };

    /* Reset the store of custom fields */
    setCustomInstructionsForCurrentOperation([]);
    const saveInstructionReportPromises = Object.entries(instructionFields)
      .map(([instructionFieldUri, fieldData]) => {
        const newInstructionReportPayload = createNewInstructionReportPayload(instructionFieldUri, fieldData);
        /* Check if values are already there, if so we should PUT instead of POST. */
        if (!_isEmpty(initialInstructionReportFormValuesByInstructionUri[instructionFieldUri])) {
          return dispatch(Actions.Api.nautilus[API_RESOURCES.MATERIAL_TEST_INSTRUCTION_REPORT]
            .put(extractUuid(
              instructionReportFormState[instructionFieldUri]?.uri,
            ), {
              notes: fieldData.notes,
              value: Number.parseFloat(fieldData.value),
              // on PUT request, if the user changed to unit in the UI
              // and it isnt the same as the initial unit
              // then we want to use the newly selected unit and update that instuction report
              // to reflect that the result value is in a differen unit
              unit: fieldData.unit !== initialInstructionReportFormValuesByInstructionUri[instructionFieldUri].unit
                ? fieldData.unit : initialInstructionReportFormValuesByInstructionUri[instructionFieldUri].unit,
            }));
        }

        /* No values are here, so let's create a new instruction-report for each instruction */
        return dispatch(Actions.Api.nautilus[API_RESOURCES.MATERIAL_TEST_INSTRUCTION_REPORT]
          .post(newInstructionReportPayload))
          .then(reportResponse => {
            const instructionReportLocation = reportResponse.headers.location;
            /* Set the URI of the location (for newly-created panels) */
            instructionReportFormStateWithUpdatedUris = ({
              ...instructionReportFormState,
              [instructionFieldUri]: {
                ...instructionReportFormState[instructionFieldUri],
                uri: instructionReportLocation,
              },
            });
            dispatch(Actions.Api.nautilus[API_RESOURCES.MATERIAL_TEST_INSTRUCTION_REPORT]
              .get(instructionReportLocation));
          })
          .catch(error => Alert.error(error));
      });

    await Promise.all(saveInstructionReportPromises);

    /* Find instruction fields where there are test documents */
    const instructionFieldsWithTestDocuments = _filter(
      instructionReportFormStateWithUpdatedUris,
      field => field.containsTestDocument &&
        field.uploadedTestDocument);

    await dispatch(Actions.Api.nautilus[API_RESOURCES.MATERIAL_TEST_OPERATION_LINKING].list({
      uri: materialTestOperationLinkings.map(item => item.uri),
    }, {}, {}, {}, true));

    const uploadedTestDocumentPromises = instructionFieldsWithTestDocuments.map(instructionField => {
      const documentPayload = {
        name: instructionField.uploadedTestDocument?.name,
        related_uuid: extractUuid(instructionField.uri),
        related_table_name: DOCUMENT_RELATED_TABLE_NAMES.MATERIAL_TEST_INSTRUCTION_REPORT,
      };
      return dispatch(Actions.Api.nautilus[API_RESOURCES.DOCUMENT]
        .post(documentPayload))
        .then(documentUploadResult => {
          const uploadDocument = documentUploadResult.headers.location;
          const uploadDocumentLocation = documentUploadResult.headers.uploadLocation;
          dispatch(Actions.Api.nautilus[API_RESOURCES.DOCUMENT].get(extractUuid(uploadDocument)));
          dispatch(Actions.UploadModel.upload(uploadDocumentLocation, instructionField.uploadedTestDocument));
        });
    });

    await Promise.all(uploadedTestDocumentPromises);

    initializeInstructions();
    initializeInstructionReports();
    setSaving(false);
  };

  const createTestPanel = (sample, panelName, startDate, endDate) => {
    setInitialFormValues({});
    dispatch(Actions.Api.nautilus[API_RESOURCES.MATERIAL_TEST_PANEL].post(
      {
        /*
          If there is only one available sample exists, we will use the [0] in the list,
          otherwise -> we will use the sample which was selected in the input.
        */
        sample: filteredSamples.length === 1 ? filteredSamples[0].uri : sample,
        name: panelName,
        start_date: startDate,
        completion_date: endDate,
      },
    )).then(panelResponse => {
      const locationUri = panelResponse.headers.location;
      dispatch(Actions.Api.nautilus[API_RESOURCES.MATERIAL_TEST_PANEL].get(locationUri))
        .then(fetchedPanelResponse => {
          const fetchedPanel = fetchedPanelResponse?.json;
          setTestPanelCurrentlyEdited(fetchedPanel?.uri);
          setInitialFormValues({
            // Once we add the Panel, it should have the Sample as it is a required field.
            sample: samples.find(({ uri }) => uri === fetchedPanel.sample)?.name,
            panelName: fetchedPanel?.name,
            // Dates are not required, so added a fallback to empty string.
            startDate: fetchedPanel.start_date?.slice(0, 10) || '',
            completionDate: fetchedPanel.completion_date?.slice(0, 10) || '',
          });

          return fetchedPanel;
        })
        .then(fetchedPanel => {
          initializeInstructions();
          initializeInstructionReports(fetchedPanel);
        });
    });
  };

  useEffect(() => {
    /* Check there IS a test panel? (we should be in edit mode). */
    if (materialTestPanel && materialTestPanel?.uri) {
      /* Set the values of an existing testPanel if it already exists. */
      setInitialFormValues(
        {
          sample: samples.find(({ uri }) => uri === materialTestPanel?.sample)?.name,
          panelName: materialTestPanel.name,
          // Some dates can be null below, added a fallback to empty string.
          startDate: materialTestPanel.start_date?.slice(0, 10) || '',
          completionDate: materialTestPanel.completion_date?.slice(0, 10) || '',
        },
      );
    }
  }, [instructionsForTestPanel]);

  const getInstructionReportUrisForCurrentOperation = instructionUris => {
    const result = instructionsForTestPanel
      .filter(instruction => instructionUris.includes(instruction?.uri));
    return _map(result, 'uri');
  };

  useEffect(
    () => {
      if (selectedOperationLinking) {
        setInstructionsForCurrentOperation([
          ..._filter(instructionsForTestPanel,
            ({ material_test_operation: selectedOperationLinking.material_test_operation })),
        ]);
      }
    },
    [selectedOperationLinking,
      instructionsForTestPanel,
      customInstructionsForCurrentOperation],
  );

  const renderSampleOutput = input => {
    if (isFetchingMaterialPanel) {
      return <Loading />;
    }

    // Show the sample which was already selected for the panel.
    if (initialFormValues?.sample) {
      return <p>{initialFormValues?.sample}</p>;
    }

    /*
      If there are (more than one) available samples (means they are bot associated to any panel) ->
      we will show them in the list of options to select.
    */
    if (filteredSamples.length > 1) {
      return (
        <FormControlSelect
          required
          className="form-group"
          value={input.value}
          onChange={input.onChange}
          {...input}
        >
          <option value="">None</option>
          {filteredSamples.map(({ uri, name, uuid }) => (
            <option value={uri} key={uuid}>{_upperFirst(name)}</option>
          ))}
        </FormControlSelect>
      );
    }
    /*
      In this case we will return the first available sample in the list,
      as if there are no available samples at all -> we will disable the Modal Window.
    */
    return <p>{_upperFirst(filteredSamples[0]?.name)}</p>;
  };

  const createPanelLevelWorkChecklists = () => {
    if (selectedOperationLinking.related_uri !== (materialTestPanel?.uri)) {
      const operationPayloads = materialTestOperations.map(operation => ({
        material_test_operation: operation?.uri,
      }));
      /* Creating the PANEL LEVEL (lowest level scope) work-checklists as the custom instructions
      are changed to be different the MATERIAL LEVEL schema. */
      return dispatch(Actions.Api.nautilus[API_RESOURCES.MATERIAL_TEST_OPERATION_WORK_CHECKLISTS_FOR_PANEL]
        .put(extractUuid(materialTestPanel?.uri), operationPayloads));
    }
    return new Promise(resolve => resolve());
  };

  /* Switch scope material-level -> panel-level */
  const handleSwitchToPanelLevelScope = async () => {
    /* Create the panel-level checklists */
    await createPanelLevelWorkChecklists();

    /* Fetch the panel-level operation-linkings straight after */
    const operationlinkingResponse =
      await dispatch(Actions.Api.nautilus[API_RESOURCES.MATERIAL_TEST_OPERATION_LINKING].list({
        related_uri: materialTestPanel?.uri,
      }, {}, {}, {}, true));
    const panelLevelOperationLinkings = operationlinkingResponse.json?.resources;
    initializeInstructions(panelLevelOperationLinkings);

    /* Simple boolean to display the custom instruction creation view */
    setIsCreatingPanelLevelInstruction(true);
  };

  const isPanelCreationStage = _isEmpty(initialFormValues);

  const handleDownloadFile = instructionFieldUri => {
    const testDocument = instructionReportFormState[instructionFieldUri]?.uploadedTestDocument;
    window.location.href = testDocument?.upload_location;
  };

  const convertDate = date => {
    if (!date) {
      return 'MM/DD/YYYY';
    }
    const [year, month, day] = date.split('-');
    return `${month}/${day}/${year}`;
  };

  return (
    <Form
      onSubmit={() => saveInstructionReport(
        instructionReportFormState,
      )}
      initialValues={initialFormValues}
      render={({ handleSubmit, values }) => {
        /* Bool to determine whether user has selected sample */
        const shouldSelectSampleFirst = filteredSamples.length !== 1 && !values?.sample;

        return (
          <Modal size="lg" show onHide={onClose}>
            <Modal.Header closeButton>
              <FormattedMessage
                id="materialTests.testPanel.addTestPanel"
                defaultMessage="Add Test Panel"
              />
            </Modal.Header>
            <Modal.Body className="max-h-10">
              <Row>
                <Row
                  className={`d-flex align-items-end pe-0 ${isPanelCreationStage ? 'mb-3' : 'mb-1'}`}
                >
                  {
                    isPanelCreationStage && filteredSamples.length !== 1 && (
                      <h4>
                        <FormattedMessage
                          id="materialTests.testPanel.selectSample"
                          defaultMessage="Please select the sample"
                        />
                      </h4>
                    )
                  }
                  <Col xs={6}>
                    <b>
                      <FormattedMessage
                        id="materialTests.sample"
                        defaultMessage="Sample"
                      />: *
                    </b>
                    <Field
                      value={values.sample}
                      name="sample"
                      render={({ input }) => renderSampleOutput(input)}
                    />
                  </Col>
                </Row>
                <Row
                  className="d-flex align-items-end pe-0"
                >
                  {isPanelCreationStage ?
                    (shouldSelectSampleFirst ? (
                      <div className="d-flex align-items-baseline">
                        <h4>
                          <FormattedMessage
                            id="materialTests.testPanel.fillInPanelDetails"
                            defaultMessage="Please fill in the Panel details"
                          />
                        </h4>
                        <OverlayTrigger
                          placement="bottom"
                          overlay={(
                            <Tooltip id="showSampleTip">
                              <p>Please select sample first</p>
                            </Tooltip>
                          )}
                        >
                          <FontAwesomeIcon icon={faQuestionCircle} className="spacer-left" />
                        </OverlayTrigger>
                      </div>
                    ) : (
                      <h4>
                        <FormattedMessage
                          id="materialTests.testPanel.fillInPanelDetails"
                          defaultMessage="Please fill in the Panel details"
                        />
                      </h4>
                    ))
                    : null}
                  <Col xs={materialTestPanel ? 5 : 3}>
                    <b>
                      <FormattedMessage
                        id="field.name"
                        defaultMessage="Name"
                      />: *
                    </b>
                    <Field
                      value={values.panelName}
                      name="panelName"
                      render={({ input }) => (

                        isTestPanelHeaderEditable || isPanelCreationStage ? (
                          <FormControl
                            disabled={shouldSelectSampleFirst}
                            name="panelName"
                            type="input"
                            value={input.value}
                            onChange={input.onChange}
                          />
                        ) : <div>{input.value}</div>
                      )}
                    />
                  </Col>
                  <Col xs={3}>
                    <b>Start Date</b>
                    <Field
                      value={values.startDate}
                      name="startDate"
                      render={({ input }) => (
                        isTestPanelHeaderEditable || isPanelCreationStage ? (

                          <DateInput
                            disabled={shouldSelectSampleFirst}
                            name="startDate"
                            value={input.value}
                            onChange={input.onChange}
                          />
                        ) : (
                          <div>{convertDate(input.value)}</div>
                        )
                      )}
                    />
                  </Col>
                  <Col xs={3}>
                    <b>Completion Date</b>
                    <Field
                      value={values.completionDate}
                      name="completionDate"
                      render={({ input }) => (
                        isTestPanelHeaderEditable || isPanelCreationStage ? (
                          <DateInput
                            disabled={shouldSelectSampleFirst}
                            name="completionDate"
                            value={input.value}
                            onChange={input.onChange}
                          />
                        ) : <div>{convertDate(input.value)}</div>
                      )}

                    />
                  </Col>
                  {!isPanelCreationStage && (
                    isTestPanelHeaderEditable ? (
                      loading ? (
                        <Col xs={1} className="mb5">
                          <Loading />
                        </Col>
                      ) : (
                        <Col xs={1} className="mb5">
                          <Button
                            size="xs"
                            className="mr15 btn-default"
                            onClick={() => {
                              setIsTestPanelHeaderEditable(false);
                            }}
                          >
                            <FontAwesomeIcon icon={faTimes} />
                          </Button>
                          <Button
                            size="xs"
                            className="btn-primary"
                            onClick={() => {
                              onAddTestPanelModalHeaderUpdate(values);
                            }}
                          >
                            <FontAwesomeIcon icon={faSave} />
                          </Button>
                        </Col>
                      )

                    ) : (
                      <Col xs={1} className="d-flex justify-content-end">
                        <Button
                          size="xs"
                          className="btn-default"
                          onClick={
                            () => setIsTestPanelHeaderEditable(true)
                          }
                        >
                          <FontAwesomeIcon icon={faEdit} />
                        </Button>
                      </Col>
                    )
                  )}

                </Row>
              </Row>
              {
                !isPanelCreationStage && (
                  <Form
                    onSubmit={() => saveInstructionReport(
                      _pick(instructionReportFormState,
                        getInstructionReportUrisForCurrentOperation(
                          selectedOperationLinking.material_test_instructions,
                        )),
                    )}
                    render={({
                      handleSubmit: handleInstructionReportFormSubmit,
                    }) => (
                      <Row className="m-t">
                        <Col xs={3}>
                          <ListGroup
                            className="mt15"
                            style={{
                              height: '100%',
                              maxHeight: 400,
                              overflowY: 'auto',
                            }}
                          >
                            <h2>
                              <FormattedMessage
                                id="tests"
                                defaultMessage="Tests"
                              />
                            </h2>
                            {_map(sortedMaterialTestOperationLinkings, operationLinking => {
                              /* Extract name of `operation` into its own variable */
                              const operationName =
                                materialTestOperationsByUri[operationLinking?.material_test_operation]?.name;

                              return (
                                <MaterialTestListGroupItem
                                  selected={operationLinking?.uri === selectedOperationLinking?.uri}
                                  onClick={() => setSelectedOperationLinking(operationLinking)}
                                >
                                  {operationName || <Loading />}
                                </MaterialTestListGroupItem>
                              );
                            },
                            )}
                          </ListGroup>
                        </Col>
                        <Col
                          xs={9}
                          style={{
                            maxHeight: '400px',
                            overflowY: 'auto',
                          }}
                        >
                          <Col className="mt15">
                            <div
                              className="d-flex w-full align-items-center justify-content-between"
                            >
                              <h2>
                                &quot;{selectedTestOperationName}&quot; Test Results
                              </h2>
                              <Button
                                disabled={saving}
                                variant="success"
                                size="sm"
                                onClick={handleInstructionReportFormSubmit}
                              >
                                <FontAwesomeIcon
                                  icon={faSave}
                                />
                              </Button>
                            </div>
                            {(loading || saving) ? <Loading /> :
                              instructionsForCurrentOperation
                                .map((instructionField, index) => {
                                  const updateState = (key, newValue) => {
                                    setInstructionReportFormState({
                                      ...instructionReportFormState,
                                      [instructionField?.uri]: {
                                        ...instructionReportFormState[instructionField?.uri],
                                        [key]: newValue,
                                      },
                                    });
                                  };

                                  const shouldShowDownloadButton =
                                    !_isEmpty(
                                      instructionReportFormState[instructionField.uri]?.uploadedTestDocument,
                                    );

                                  return (
                                    <>
                                      <FormGroup className="mt15">
                                        <FormLabel>
                                          <p>{instructionField.name}</p>
                                        </FormLabel>
                                        <InputGroup>
                                          <Field
                                            name="value"
                                            render={({ input }) => (
                                              <FormControl
                                                name="value"
                                                type="number"
                                                value={instructionReportFormState[instructionField?.uri]?.value ?? ''}
                                                onChange={event => {
                                                  input.onChange(event);
                                                  updateState(
                                                    'value',
                                                    event.target.value);
                                                }}
                                              />
                                            )}
                                          />

                                          {/* unit selector for updating */}

                                          {/* selector */}
                                          {
                                            editableUnits[instructionField.name] ? (
                                              <select
                                                defaultValue={materialTestUnitsByUri[
                                                  instructionReportFormState[instructionField?.uri]?.unit]?.uri ||
                                             materialTestUnitsByUri[instructionField?.unit]?.uri}
                                                required
                                                onChange={event => {
                                                  setEditableUnits(previous => (
                                                    { ...previous, [instructionField?.name]: false }
                                                  ));
                                                  updateState(
                                                    'unit',
                                                    event.target.value);
                                                }}
                                              >
                                                <option value=""> Select an unit</option>
                                                {
                                                  selectedTestOperation.unit_options
                                                    .map(currentUnit => (
                                                      <option
                                                        value={currentUnit}
                                                      >
                                                        {materialTestUnitsByUri[currentUnit]?.symbol}
                                                      </option>
                                                    ))
                                                }

                                              </select>
                                            ) : (

                                              <InputGroup.Text
                                                className="pointer"
                                                onClick={() =>
                                                  setEditableUnits(previous => (
                                                    { ...previous, [instructionField?.name]: true }
                                                  ),
                                                  )}
                                              >
                                                { (
                                                  materialTestUnitsByUri[
                                                    instructionReportFormState[instructionField?.uri]?.unit]?.symbol ||
                                                 materialTestUnitsByUri[instructionField?.unit]?.symbol
                                                ) ?? '%'}
                                                <FontAwesomeIcon className="spacer-left" icon={faCaretDown} />
                                              </InputGroup.Text>

                                            )
                                          }

                                          {/* selector */}
                                          <OverlayTrigger
                                            placement="top"
                                            overlay={(
                                              <Tooltip>
                                                <p>
                                                  <FormattedMessage
                                                    id="multiple.test.units"
                                                    defaultMessage="This operation contains multiple test units. If test results differ from current units, please click unit to update to a more appropriate unit"
                                                  />
                                                </p>
                                              </Tooltip>
                                            )}
                                          >
                                            <FontAwesomeIcon size="lg" className="ml15 align-self-center" icon={faInfoCircle} />
                                          </OverlayTrigger>
                                          {/* unit selector for updating */}
                                        </InputGroup>
                                      </FormGroup>
                                      {index === instructionsForCurrentOperation.length - 1 && ([
                                        !isCreatingPanelLevelInstruction && (
                                          <div className="d-flex w-100 justify-content-end align-items-center">
                                            <Button
                                              className="mt15"
                                              onClick={handleSwitchToPanelLevelScope}
                                              disabled={isFetchingCustomInstructions}
                                            >
                                              <FormattedMessage
                                                id="materialTests.testPanel.addCustomInstruction"
                                                defaultMessage="Add Custom Instruction"
                                              />
                                              <FontAwesomeIcon
                                                className="spacer-left"
                                                icon={faPlus}
                                                role="button"
                                              />
                                            </Button>
                                          </div>
                                        ),

                                        isCreatingPanelLevelInstruction && ([
                                          <hr />,
                                          <CustomPanelLevelInstructionsView
                                            materialTestUnitsByUri={materialTestUnitsByUri}
                                            selectedTestOperation={selectedTestOperation}
                                            materialTestOperationLinkings={materialTestOperationLinkings}
                                            checklistForSelectedOperationLinking={selectedOperationLinking
                                              .material_work_checklist}
                                            onClose={() => setIsCreatingPanelLevelInstruction(false)}
                                            savingState={[
                                              saving,
                                              setSaving,
                                            ]}
                                            instructionsForSelectedOperationLinking={selectedOperationLinking
                                              .material_test_instructions}
                                            stateSetters={[
                                              setInstructionsForTestPanel,
                                              setIsCreatingPanelLevelInstruction,
                                              setCustomInstructionsForCurrentOperation,
                                            ]}
                                          />,
                                          <hr />,
                                        ]),
                                      ])}
                                      {index === instructionsForCurrentOperation.length - 1 && (
                                        <FormGroup className="mt15">
                                          <FormLabel>
                                            <FormattedMessage
                                              id="field.notes"
                                              defaultMessage="Notes"
                                            />
                                          </FormLabel>
                                          <Field
                                            name="notes"
                                            render={({ input }) => (
                                              <FormControl
                                                name="notes"
                                                as="textarea"
                                                type="textarea"
                                                value={instructionReportFormState[instructionField?.uri]?.notes ?? ''}
                                                onChange={event => {
                                                  input.onChange(event);
                                                  updateState(
                                                    'notes',
                                                    event.target.value,
                                                  );
                                                }}
                                              />
                                            )}
                                          />
                                        </FormGroup>
                                      )}
                                      {index === instructionsForCurrentOperation.length - 1 && (
                                        <FormGroup className="mt15">
                                          <FormLabel>
                                            <FormattedMessage
                                              id="materialTests.testPanel.testDocument"
                                              defaultMessage="Test Document"
                                            />:
                                          </FormLabel>
                                          <div className="d-flex align-items-start">
                                            {shouldShowDownloadButton && (
                                              <Button
                                                className="mt15 spacer-right"
                                                bg="primary"
                                                onClick={() =>
                                                  handleDownloadFile(instructionField.uri)}
                                              >
                                                <FormattedMessage
                                                  id="button.download"
                                                  defaultMessage="Download"
                                                />
                                              </Button>
                                            )}
                                            <FileInput
                                              fileType={FileInput.fileTypes.document}
                                              handleFileChange={event => setInstructionReportFormState({
                                                ...instructionReportFormState,
                                                [instructionField.uri]: {
                                                  ...instructionReportFormState[instructionField.uri],
                                                  uploadedTestDocument: event.target.files[0],
                                                  containsTestDocument: true,
                                                },
                                              })}
                                              name={
                                                instructionReportFormState[
                                                  instructionField.uri
                                                ]?.uploadedTestDocument?.name
                                              }
                                              chooseFileLabel="Choose File"
                                            />
                                          </div>
                                        </FormGroup>
                                      )}
                                    </>
                                  );
                                })}
                          </Col>
                        </Col>
                      </Row>
                    )}
                  />
                )
              }
            </Modal.Body>
            <Modal.Footer>
              <Button
                onClick={onClose}
                variant="danger"
              >
                <FormattedMessage
                  id="button.close"
                  defaultMessage="Close"
                />
              </Button>
              <Button
                disabled={saving}
                onClick={event => (
                  isPanelCreationStage
                    ? createTestPanel(values.sample, values.panelName, values.startDate, values.completionDate)
                    : handleSubmit(event)
                )}
                variant={isPanelCreationStage ? 'primary' : 'success'}
              >
                <div className="d-flex align-items-center">
                  {(isFetchingMaterialPanel || saving) && <Loading className="spacer-right" />}
                  {
                    isPanelCreationStage ?
                      (
                        <FormattedMessage
                          id="button.add"
                          defaultMessage="Add"
                        />
                      ) : (
                        <FormattedMessage
                          id="button.saveall"
                          defaultMessage="Save All"
                        />
                      )
                  }
                </div>
              </Button>
            </Modal.Footer>
          </Modal>
        );
      }}
    />
  );
};
export default MaterialAddTestPanelModal;

MaterialAddTestPanelModal.propTypes = {
  materialTestPanel: PropTypes.shape({
    material_test_reports: PropTypes.arrayOf(PropTypes.shape()).isRequired,
    name: PropTypes.string,
    start_date: PropTypes.string,
    completion_date: PropTypes.string,
    uri: PropTypes.string,
    sample: PropTypes.shape({}).isRequired,
    uuid: PropTypes.string.isRequired,
  }).isRequired,
  onClose: PropTypes.func.isRequired,
  materialTestInstructions: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  materialTestOperations: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  materialTestUnitsByUri: PropTypes.shape({}).isRequired,
  materialTestOperationLinkings: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  samples: PropTypes.arrayOf(PropTypes.shape({
    uri: PropTypes.string,
  })).isRequired,
  materialTestUnits: PropTypes.shape({}).isRequired,
  onInitializeMaterialTest: PropTypes.func.isRequired,
  filteredSamples: PropTypes.arrayOf(PropTypes.shape({
    uri: PropTypes.string,
    uuid: PropTypes.string,
    name: PropTypes.string,
  })).isRequired,
  setNewInstructionReportFieldsForCurrentOperation: PropTypes.func.isRequired,
  setInstructionFieldsForTestOperations: PropTypes.func.isRequired,
  setTestPanelCurrentlyEdited: PropTypes.func.isRequired,
};
