import _isEmpty from 'lodash/isEmpty';
import User from 'rapidfab/components/admin/User';
import { FormattedMessage } from 'react-intl';
import React from 'react';
import PropTypes from 'prop-types';
import { ListGroup } from 'react-bootstrap';

const Users = ({
  usersGrouped,
  rolesGroupedByUsername,
  selectedUsersURIs,
  multiSelect,
  readOnly,
  locationsByUri,
  setCurrentUserPreview,
  setUserModalMode,
  handleSelectionChange,
  currentUserPreview,
}) => (
  <>
    <ListGroup>
      {!_isEmpty(usersGrouped) ? Object.entries(usersGrouped).map(([key, users]) => (
        <div className="users-list">
          <div className="users-list-header">
            <p>{key}</p>
          </div>
          {users.map(user => {
            const isUserSelected = multiSelect
              ? selectedUsersURIs.includes(user.uri)
              : currentUserPreview?.uri === user.uri;
            return (
              <User
                key={user.uri}
                user={user}
                roles={rolesGroupedByUsername[user.username]}
                handleSelectionChange={handleSelectionChange}
                multiSelect={!readOnly && multiSelect}
                isUserSelected={isUserSelected}
                locationsByUri={locationsByUri}
                setCurrentUserPreview={setCurrentUserPreview}
                setUserModalMode={setUserModalMode}
              />
            );
          })}
        </div>

      )) : (
        <div className="users-list-empty">
          <p className="mb0">
            <FormattedMessage id="users.notFound" defaultMessage="Users not found" />
          </p>
        </div>
      )}
    </ListGroup>
  </>

);

Users.propTypes = {
  handleSelectionChange: PropTypes.func,
  setCurrentUserPreview: PropTypes.func.isRequired,
  setUserModalMode: PropTypes.func.isRequired,
  usersGrouped: PropTypes.shape({}).isRequired,
  locationsByUri: PropTypes.shape({}).isRequired,
  currentUserPreview: PropTypes.shape({
    name: PropTypes.string,
    username: PropTypes.string,
    uuid: PropTypes.string,
    uri: PropTypes.string,
  }),
  user: PropTypes.shape({
    name: PropTypes.string,
    username: PropTypes.string,
    uuid: PropTypes.string.isRequired,
    uri: PropTypes.string.isRequired,
  }).isRequired,
  rolesGroupedByUsername: PropTypes.shape({}).isRequired,
  selectedUsersURIs: PropTypes.arrayOf(PropTypes.string),
  multiSelect: PropTypes.bool.isRequired,
  readOnly: PropTypes.bool.isRequired,
};

Users.defaultProps = {
  handleSelectionChange: () => {},
  selectedUsersURIs: [],
  currentUserPreview: {},
};

export default Users;
