import _find from 'lodash/find';
import _some from 'lodash/some';
import ConfirmationModal from 'rapidfab/components/ConfirmationModal';
import CustomMultiSelect from 'rapidfab/components/forms/CustomMultiSelect';
import ModalGroupedSection from 'rapidfab/components/modals/ModalGroupedSection';
import PrinterTypeBuildPlateType from 'rapidfab/components/records/PrinterTypeBuildPlate';
import { resetRcTooltipInnerStyle, selectInputStyles } from 'rapidfab/constants/styles';
import { ModalGroupedSectionProvider } from 'rapidfab/context/ModalGroupedSectionProvider';
import PrinterTypeIcon from 'rapidfab/icons/printer-type-icon';
import { getRouteURI, isURI } from 'rapidfab/utils/uriUtils';
import { extractUuid, getShortUUID } from 'rapidfab/utils/uuidUtils';
import RCTooltip from 'rc-tooltip';
import React from 'react';
import PropTypes from 'prop-types';
import {
  Row,
  Col,
  FormGroup,
  FormLabel,
  FormControl,
  InputGroup,
  Badge,
  Card,
  Form as BSForm, Button, Modal,
} from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { FormattedMessage } from 'react-intl';
import {
  ACCESS_INFO_ACTION_TYPES, BUILD_VOLUME_XYZ_MAXIMUM_VALUE,
  FEATURES,
  MANUFACTURING_PROCESSES, REACT_SELECT_ACTIONS, ROUTES,
} from 'rapidfab/constants';
import { BUILD_PACKER_TYPES_MAP } from 'rapidfab/mappings';
import ExampleEstimates from 'rapidfab/components/ExampleEstimates';
import Feature from 'rapidfab/components/Feature';
import WorkChecklistContainer from 'rapidfab/containers/records/WorkChecklistContainer';
import { FormattedMessageMappingOption } from 'rapidfab/i18n';
import { FormControlCost, FormControlSelect, identity } from 'rapidfab/components/formTools';
import { finalFormInputTypes, printerTypeResourceType } from 'rapidfab/types';
import DisabledByAccessInfoCheck from 'rapidfab/components/DisabledByAccessInfoCheck';
import UseNonMfgLanguageFeature from 'rapidfab/components/generalMfgLanguage/UseNonMfgLanguageFeature';
import Loading from 'rapidfab/components/Loading';
import { Form, Field } from 'react-final-form';
import LaborAvailableSchedule from 'rapidfab/components/records/LaborAvailableSchedule';
import LaborDuration from 'rapidfab/components/LaborDuration';
import { isInHoursMinutesFormat } from 'rapidfab/utils/timeUtils';
import {
  faClose,
  faExclamationCircle, faExternalLink,
  faFloppyDisk,
  faInfoCircle,
  faPlus, faQuestionCircle, faTrash,
} from '@fortawesome/free-solid-svg-icons';
import { BUILD_PACKER_TYPES } from 'rapidfab/constants/resources';
import { Link } from 'react-router-dom';
import CreatableSelect from 'react-select/creatable';
import IntegratedWorkstationCheck from '../IntegratedWorkstationCheck';

import 'rapidfab/styles/componentStyles/custom-darken-modal.scss';

const PrinterTypeModal = ({
  uuid,
  isVisible,
  onDelete,
  onFormSubmit,
  manufacturers,
  customGroups,
  materials,
  materialsByUri,
  infillStrategies,
  supportStrategies,
  prosperPrinterMaterials,
  prospers,
  fromTemplate,
  defaultCurrency,
  use100Infill,
  printerType,
  initialFormValues,
  isGroupQualificationsFeatureEnabled,
  submitting,
  deleting,
  handleChangeLaborTime,
  handleSetLaborNonStopTime,
  laborScheduleTime,
  laborNonStopEnabled,
  workScheduleEnabled,
  handleTriggerWorkSchedule,
  handleSetWorkday,
  workDays,
  durationState: {
    isDurationInvalid,
    setIsDurationInvalid,
  },
  UTC_TimeZone,
  handleUTC_TimezoneSetting,
  handleMaterialModeSetting,
  multiMaterialIsActive,
  isPack3dPremiumFeatureEnabled,
  handleSubmitManufacturer,
  isManufacturerFetching,
  activePlateType,
  setActivePlateType,
  handleSetSectionVisibilityState,
  groupedSectionsVisibilityState,
  handleCloseModal,
  fetching,
  someFeaturesAdvancedSectionEnabled,
  sectionsWithMissingFields,
}) => {
  const [confirmDelete, setConfirmDelete] = React.useState(false);

  const printerTypeNameTooltipLength = 50;

  const groupedSectionTitles = {
    general: 'General',
    buildPacking: 'Build / Packing',
    costPricing: 'Cost / Pricing',
    advanced: 'Advanced Settings',
  };

  const formattedManufacturers = manufacturers
    .filter(Boolean)
    .map(manufacturer => ({
      label: manufacturer.name,
      value: manufacturer.uri,
    }));

  const handleManufacturersChange = async (selectedOption, actionMeta, onChange) => {
    // The user would like to create the new manufacturer "on the fly"
    if (actionMeta.action === REACT_SELECT_ACTIONS.CREATE_OPTION) {
      // Send API request to create the new manufacturer with the "name" field
      const manufacturerUri = await handleSubmitManufacturer(selectedOption.label);
      if (manufacturerUri) {
        // Set the new manufacturer URI as the value of the select input
        return onChange(manufacturerUri);
      }
    }
    const newValue = selectedOption ? selectedOption.value : '';
    return onChange(newValue);
  };

  const handleConfirmDeletePrinterType = () => {
    setConfirmDelete(false);
    return onDelete(initialFormValues?.uuid);
  };

  return (
    <Modal
      size="lg"
      show={isVisible || uuid || initialFormValues?.uuid}
      onHide={handleCloseModal}
      scrollable
      backdrop="static"
      dialogClassName="custom-darken-modal"
    >
      {
        fetching ? <Loading className="p-a-md" /> : (
          <Form
            onSubmit={onFormSubmit}
            initialValues={initialFormValues}
            mutators={{
              toggleEnableLaborEnhancedWorkflow: (_, state, { changeValue }) => {
                const { labor_enhanced_workflow_enabled: initialValue } = state.formState.values;
                changeValue(
                  state,
                  'labor_enhanced_workflow_enabled',
                  () => !initialValue,
                );
                if (!initialValue === false) {
                  setIsDurationInvalid(false);
                  changeValue(state, 'duration', () => '00:00');
                }
              },
              toggleIntegratedWorkStation: (_, state, { changeValue }) => {
                const { integrated: initialValue } = state.formState.values;
                changeValue(
                  state,
                  'integrated',
                  () => !initialValue,
                );
              },
              onChangeValue: ([field, value], state, { changeValue }) => {
                setIsDurationInvalid(false);
                if (!isInHoursMinutesFormat(value)) {
                  setIsDurationInvalid(true);
                }

                changeValue(
                  state,
                  field,
                  () => value,
                );
              },
            }}
            render={({ handleSubmit, form, values }) => {
              let constantOverhead = 0;
              if (values.constant_overhead) {
                constantOverhead += Number(values.constant_overhead);
              }
              if (values.overhead_per_line_item) {
                constantOverhead += Number(values.overhead_per_line_item);
              }
              const manufacturingProcessMismatchMaterialUris = values.materials
                ? values.materials.filter(materialUri => {
                  const material = materialsByUri[materialUri];
                  return (
                    values.manufacturing_process &&
                    material &&
                    material.manufacturing_process &&
                    material.manufacturing_process !== values.manufacturing_process
                  );
                })
                : [];

              const showManufacturingProcessMismatchTooltip =
                manufacturingProcessMismatchMaterialUris.length > 0;
              const manufacturingProcessMismatchMaterialNames =
                manufacturingProcessMismatchMaterialUris.map(
                  materialUri =>
                    materialsByUri[materialUri] && materialsByUri[materialUri].name,
                );

              const isBuildVolumeValueIncorrect = value => value &&
                (Number(value) > (BUILD_VOLUME_XYZ_MAXIMUM_VALUE - 2));

              const renderBuildVolume = props => (
                <>
                  <FormControl
                    {...props.input}
                    required
                    disabled={fromTemplate}
                  />
                  {
                    props.meta.invalid && (
                      <FormLabel className="text-danger mt-1">
                        <FormattedMessage
                          id="buildVolumeMaximumValue"
                          defaultMessage="Maximum value is 999, 998 mm"
                        />
                      </FormLabel>
                    )
                  }
                </>
              );

              return (
                <form onSubmit={handleSubmit}>
                  <Modal.Header>
                    <Modal.Title className="w-100 d-flex align-items-center justify-content-between">
                      <div className="d-flex align-items-center">
                        <PrinterTypeIcon className="custom-navbar-icon-white" />

                        <p className="custom-darken-modal-title-text">
                          {initialFormValues?.uuid && initialFormValues?.name ?
                            (
                              initialFormValues.name.length >= printerTypeNameTooltipLength ?
                                (
                                  <div className="d-flex align-items-center">
                                    <RCTooltip
                                      placement="bottom"
                                      id="printerTypeNameTooltip"
                                      destroyTooltipOnHide
                                      overlayInnerStyle={resetRcTooltipInnerStyle}
                                      mouseLeaveDelay={0.4}
                                      overlay={(
                                        <p className="mb0 darkTooltip">
                                          {initialFormValues.name}
                                        </p>
                                      )}
                                    >
                                      <div className="d-flex align-items-center">
                                        <p className="mb0 truncate custom-darken-modal-title-text-title">
                                          {initialFormValues.name}
                                        </p>
                                        ({getShortUUID(initialFormValues.uuid)})
                                      </div>
                                    </RCTooltip>
                                  </div>
                                ) : (
                                  <span>{initialFormValues.name}
                                    &nbsp;({getShortUUID(initialFormValues.uuid)})
                                  </span>
                                )
                            ) : 'Create Printer Type'}
                        </p>
                      </div>
                      <FontAwesomeIcon
                        icon={faClose}
                        onClick={handleCloseModal}
                        tabIndex={0}
                        role="button"
                        className="custom-darken-modal-button"
                      />
                    </Modal.Title>
                  </Modal.Header>
                  <Modal.Body className="custom-darken-modal--body-scroll">
                    <Row className="custom-darken-modal-custom-gutter">

                      <ModalGroupedSectionProvider
                        sectionsWithMissingFields={sectionsWithMissingFields}
                        groupedSectionTitles={groupedSectionTitles}
                        handleSetSectionVisibilityState={handleSetSectionVisibilityState}
                      >
                        <ModalGroupedSection
                          isSectionVisible={groupedSectionsVisibilityState.general}
                          sectionTitle={groupedSectionTitles.general}
                        >
                          <Row>
                            <Col lg={6} xs={12}>
                              <FormGroup className="form-group" controlId="uxName">
                                <FormLabel>
                                  <FormattedMessage id="field.name" defaultMessage="Name" />: *
                                </FormLabel>
                                <Field
                                  name="name"
                                  type="text"
                                  render={props => (
                                    <FormControl
                                      {...props.input}
                                      required
                                      disabled={fromTemplate}
                                    />
                                  )}
                                />
                              </FormGroup>

                              <FormGroup className="form-group" controlId="uxDescription">
                                <FormLabel>
                                  <FormattedMessage
                                    id="field.description"
                                    defaultMessage="Description"
                                  />: *
                                </FormLabel>
                                <Field
                                  name="description"
                                  parse={identity}
                                  render={props => (
                                    <FormControl
                                      {...props.input}
                                      as="textarea"
                                      required
                                    />
                                  )}
                                />
                              </FormGroup>

                              <FormGroup className="form-group" controlId="uxManufacturer">
                                <FormLabel>
                                  <FormattedMessage
                                    id="field.manufacturer"
                                    defaultMessage="Manufacturer"
                                  />
                                  : *
                                  {values?.manufacturer && isURI(values.manufacturer) && (
                                    <Link
                                      target="_blank"
                                      className="spacer-left"
                                      rel="noopener noreferrer"
                                      to={getRouteURI(ROUTES.MANUFACTURER_EDIT,
                                        { uuid: extractUuid(values.manufacturer) }, null, true)}
                                    >
                                      <FontAwesomeIcon icon={faExternalLink} />
                                    </Link>
                                  )}
                                </FormLabel>
                                <Field
                                  name="manufacturer"
                                  render={props => {
                                    const selectedOption = _find(formattedManufacturers,
                                      { value: props.input.value }) || null;
                                    return (
                                      <CreatableSelect
                                        {...props.input}
                                        styles={selectInputStyles}
                                        isLoading={isManufacturerFetching}
                                        placeholder={(
                                          <FormattedMessage
                                            id="field.selectManufacturer"
                                            defaultMessage="Select Manufacturer"
                                          />
                                        )}
                                        isDisabled={isManufacturerFetching}
                                        components={{ LoadingIndicator: () => (<Loading inline className="spacer-right" />) }}
                                        value={selectedOption}
                                        options={formattedManufacturers}
                                        required
                                        onChange={(value, actionMeta) =>
                                          handleManufacturersChange(value, actionMeta, props.input.onChange)}
                                        isClearable
                                      />
                                    );
                                  }}
                                />
                              </FormGroup>

                              <FormGroup className="form-group">
                                <FormLabel>
                                  <FormattedMessage
                                    id="field.manufacturing_process"
                                    defaultMessage="Manufacturing Process"
                                  />
                                  :
                                </FormLabel>
                                <Field
                                  type="select"
                                  name="manufacturing_process"
                                  render={props => (
                                    <FormControlSelect
                                      {...props.input}
                                    >
                                      <option value="">None</option>
                                      {MANUFACTURING_PROCESSES.map(process => (
                                        <option value={process} key={process}>
                                          {process}
                                        </option>
                                      ))}
                                    </FormControlSelect>
                                  )}
                                />
                              </FormGroup>

                              <FormGroup className="form-group" controlId="uxUserGroups">
                                <FormLabel>
                                  <FormattedMessage id="userGroups" defaultMessage="User Groups" />:

                                  <RCTooltip
                                    placement="top"
                                    id="userGroupsTooltip"
                                    destroyTooltipOnHide
                                    overlayInnerStyle={resetRcTooltipInnerStyle}
                                    mouseLeaveDelay={0.4}
                                    overlay={(
                                      <p className="mb0 darkTooltip">
                                        <UseNonMfgLanguageFeature
                                          mfgLanguageComponent={(
                                            <FormattedMessage
                                              id="printerTypeEditGroupHelperText"
                                              defaultMessage="Assigning a Printer Type to a User Group will result in the associated Printers, Runs and Workflows will only be editable by users within the User Group"
                                            />
                                          )}
                                          nonMfgLanguageComponent={(
                                            <FormattedMessage
                                              id="mfg.printer.printerTypeEditGroupHelperText"
                                              defaultMessage="Assigning a Production Device Type to a User Group will result in the associated Production Devices, Runs and Workflows will only be editable by users within the User Group"
                                            />
                                          )}
                                        />
                                      </p>
                                    )}
                                  >
                                    <FontAwesomeIcon icon={faInfoCircle} className="spacer-left" />
                                  </RCTooltip>
                                </FormLabel>
                                <Field
                                  name="edit_group"
                                  render={props => (
                                    <FormControlSelect
                                      {...props.input}
                                      disabled={!isGroupQualificationsFeatureEnabled}
                                    >
                                      <FormattedMessage id="all" defaultMessage="All">{text =>
                                        <option value="">{text}</option>}
                                      </FormattedMessage>
                                      {customGroups.map(customGroup => (
                                        <option
                                          value={customGroup.uri}
                                          key={customGroup.uuid}
                                        >
                                          {customGroup.name}
                                        </option>
                                      ))}
                                    </FormControlSelect>
                                  )}
                                />
                              </FormGroup>
                            </Col>
                            <Col lg={6} xs={12}>
                              <FormGroup className="form-group" controlId="uxMaterials">
                                <Field
                                  name="materials"
                                  render={({ input }) => (
                                    <CustomMultiSelect
                                      value={input.value}
                                      onChange={input.onChange}
                                      isCardMode
                                      required
                                      defaultNullableValue={null}
                                      customCardCss="custom-darken-modal--card"
                                      customCardHeaderCss="custom-darken-modal--card-header"
                                      customCardBodyCss="custom-darken-modal--card-body"
                                      customCardBg=""
                                      customCardBorder=""
                                      formLabel={(
                                        <FormLabel className="d-flex align-items-center justify-content-between">
                                          <div className="d-flex align-items-center">
                                            <FormattedMessage id="materials" defaultMessage="Materials" />:
                                            *
                                            {showManufacturingProcessMismatchTooltip && (
                                              <RCTooltip
                                                placement="top"
                                                id="userGroupsTooltip"
                                                destroyTooltipOnHide
                                                overlayInnerStyle={resetRcTooltipInnerStyle}
                                                mouseLeaveDelay={0.4}
                                                overlay={(
                                                  <p className="mb0 darkTooltip">
                                                    {`${manufacturingProcessMismatchMaterialNames.join(
                                                      ', ',
                                                    )} have Manufacturing Processes that don't match this printer type`}
                                                  </p>
                                                )}
                                              >
                                                <FontAwesomeIcon className="spacer-left" icon={faExclamationCircle} />
                                              </RCTooltip>
                                            )}

                                          </div>

                                          <div className="d-flex align-items-center">
                                            <FormattedMessage
                                              id="enableMultiMaterial"
                                              defaultMessage="Enable Multiple Materials"
                                            />

                                            <RCTooltip
                                              placement="top"
                                              id="userGroupsTooltip"
                                              destroyTooltipOnHide
                                              overlayInnerStyle={resetRcTooltipInnerStyle}
                                              mouseLeaveDelay={0.4}
                                              overlay={(
                                                <p className="mb0 darkTooltip">
                                                  Enable to allow Runs on this machine to contain
                                                  Pieces with varying Base Materials. <br />
                                                  When Disabled (default), all Pieces within a Run must have
                                                  the same Base Material.
                                                </p>
                                              )}
                                            >
                                              <FontAwesomeIcon className="spacer-left" icon={faInfoCircle} />
                                            </RCTooltip>

                                            <BSForm.Check
                                              type="switch"
                                              id="custom-switch"
                                              checked={multiMaterialIsActive}
                                              onChange={handleMaterialModeSetting}
                                              className="ml15 mr0"
                                            />
                                          </div>
                                        </FormLabel>
                                      )}
                                      title={(
                                        <FormattedMessage
                                          id="material.settings"
                                          defaultMessage="Material Settings"
                                        />
                                      )}
                                      options={materials}
                                      placeholder="Select materials"
                                    />
                                  )}
                                />
                              </FormGroup>
                              {values?.uri && (
                                <WorkChecklistContainer
                                  workstep={initialFormValues?.uri || values?.uri}
                                  wrapPanel
                                  isCustomDarkCardStyle
                                />
                              )}
                            </Col>
                          </Row>
                        </ModalGroupedSection>

                        <ModalGroupedSection
                          isSectionVisible={groupedSectionsVisibilityState.buildPacking}
                          sectionTitle={groupedSectionTitles.buildPacking}
                        >
                          <Row>
                            <Col lg={6} xs={12}>
                              <FormGroup className="form-group" controlId="uxPackerType">
                                <FormLabel>
                                  <FormattedMessage
                                    id="field.buildTypes"
                                    defaultMessage="Build Packer Type"
                                  />
                                  : *
                                </FormLabel>
                                <Field
                                  name="build_packer_type"
                                  render={props => (
                                    <FormControlSelect
                                      {...props.input}
                                      required
                                    >
                                      {Object.keys(BUILD_PACKER_TYPES_MAP).map(packerType => {
                                        if (packerType === BUILD_PACKER_TYPES.PACK3D_PREMIUM
                                          && !isPack3dPremiumFeatureEnabled) {
                                          return null;
                                        }

                                        return (
                                          <FormattedMessageMappingOption
                                            mapping={BUILD_PACKER_TYPES_MAP}
                                            value={packerType}
                                          />
                                        );
                                      })}
                                    </FormControlSelect>
                                  )}
                                />
                              </FormGroup>
                              <FormGroup className="form-group" controlId="uxMinPackingDistance">
                                <FormLabel>
                                  <FormattedMessage
                                    id="field.min_packing_distance"
                                    defaultMessage="Min Packing Distance (mm)"
                                  />
                                  : *
                                </FormLabel>
                                <InputGroup>
                                  <Field
                                    name="min_packing_distance"
                                    type="number"
                                    render={props => (
                                      <FormControl
                                        {...props.input}
                                        min={0}
                                        max={100}
                                        step="any"
                                        required
                                      />
                                    )}
                                  />
                                  <InputGroup.Text>mm</InputGroup.Text>
                                </InputGroup>
                              </FormGroup>
                              <hr className="custom-darken-modal-separator" />

                              <Row>
                                <h4 className="printerTypeBufferTitle">Buffer (in seconds)</h4>
                                <Col sm={5}>
                                  <FormGroup className="form-group" controlId="uxTimeBeforePrint">
                                    <FormLabel>
                                      <FormattedMessage
                                        id="field.time_before_print"
                                        defaultMessage="Pre-Run Buffer"
                                      />
                                      : *
                                    </FormLabel>
                                    <Field
                                      name="time_before_print"
                                      type="number"
                                      render={props => (
                                        <FormControl
                                          {...props.input}
                                          required
                                        />
                                      )}
                                    />
                                  </FormGroup>
                                </Col>

                                <Col sm={5}>
                                  <FormGroup className="form-group" controlId="uxTimeAfterPrint">
                                    <FormLabel>
                                      <FormattedMessage
                                        id="field.time_after_print"
                                        defaultMessage="Post-Run Buffer"
                                      />
                                      : *
                                    </FormLabel>
                                    <Field
                                      name="time_after_print"
                                      type="number"
                                      render={props => (
                                        <FormControl
                                          {...props.input}
                                          required
                                        />
                                      )}
                                    />
                                  </FormGroup>
                                </Col>
                              </Row>

                              <Feature featureName={FEATURES.EXTERNAL_PRODUCTION_ESTIMATE} isInverted>
                                {infillStrategies.length > 0 && (
                                  <FormGroup className="form-group" controlId="uxInfillStrategies">
                                    <FormLabel>
                                      <FormattedMessage
                                        id="field.infill_strategies"
                                        defaultMessage="Infill Strategies"
                                      />
                                      : *
                                    </FormLabel>
                                    <Field
                                      name="infill_strategies"
                                      render={props => (
                                        <FormControlSelect
                                          {...props.input}
                                          multiple
                                          required
                                        >
                                          {infillStrategies.map(infillStrategy => (
                                            <option
                                              key={infillStrategy.uri}
                                              value={infillStrategy.uri}
                                            >
                                              {infillStrategy.name.toString()}
                                            </option>
                                          ))}
                                        </FormControlSelect>
                                      )}
                                    />
                                  </FormGroup>
                                )}
                              </Feature>

                              <Feature featureName={FEATURES.EXTERNAL_PRODUCTION_ESTIMATE} isInverted>
                                {supportStrategies.length > 0 && (
                                  <FormGroup className="form-group" controlId="uxSupportStrategies">
                                    <FormLabel>
                                      <FormattedMessage
                                        id="field.support_strategies"
                                        defaultMessage="Support Strategies"
                                      />
                                      : *
                                    </FormLabel>
                                    <Field
                                      name="support_strategies"
                                      render={props => (
                                        <FormControlSelect
                                          {...props.input}
                                          multiple
                                          required
                                        >
                                          {supportStrategies.map(supportStrategy => (
                                            <option
                                              key={supportStrategy.uri}
                                              value={supportStrategy.uri}
                                            >
                                              {`${supportStrategy.name}`}
                                            </option>
                                          ))}
                                        </FormControlSelect>
                                      )}
                                    />
                                  </FormGroup>
                                )}
                              </Feature>
                            </Col>

                            <Col lg={6} xs={12}>
                              <PrinterTypeBuildPlateType
                                renderBuildVolume={renderBuildVolume}
                                isBuildVolumeValueIncorrect={isBuildVolumeValueIncorrect}
                                isDisabled={!!initialFormValues?.uuid}
                                buildPlateType={activePlateType}
                                setActivePlateType={setActivePlateType}
                              />
                            </Col>
                          </Row>
                        </ModalGroupedSection>

                        <ModalGroupedSection
                          isSectionVisible={groupedSectionsVisibilityState.costPricing}
                          sectionTitle={groupedSectionTitles.costPricing}
                        >
                          <Row>
                            <Col lg={6} xs={12}>
                              <FormGroup className="form-group">
                                <FormLabel>
                                  <UseNonMfgLanguageFeature
                                    mfgLanguageComponent={(
                                      <FormattedMessage
                                        id="printerCostPerHour"
                                        defaultMessage="Printer Cost Per Hour"
                                      />
                                    )}
                                    nonMfgLanguageComponent={(
                                      <FormattedMessage
                                        id="mfg.printerCostPerHour.productionDeviceCostPerHour"
                                        defaultMessage="Production Device Cost Per Hour"
                                      />
                                    )}
                                  />
                                  : *
                                  <RCTooltip
                                    placement="top"
                                    id="userGroupsTooltip"
                                    destroyTooltipOnHide
                                    overlayInnerStyle={resetRcTooltipInnerStyle}
                                    mouseLeaveDelay={0.4}
                                    overlay={(
                                      <p className="mb0 darkTooltip">
                                        <FormattedMessage
                                          id="costTooltip"
                                          defaultMessage="This currency is based on your Bureau Default Currency. Please contact Authentise Support to change this default."
                                        />
                                      </p>
                                    )}
                                  >
                                    <FontAwesomeIcon className="spacer-left spacer-right" icon={faQuestionCircle} />
                                  </RCTooltip>

                                </FormLabel>
                                <FormControlCost
                                  type="number"
                                  name="running_cost_per_hour"
                                  required
                                  initialValue={initialFormValues?.running_cost_per_hour}
                                  currency={defaultCurrency}
                                />
                              </FormGroup>

                              <hr className="custom-darken-modal-separator" />

                              <Row>
                                <h4 className="printerTypeBufferTitle">
                                  Custom Bureau Cost

                                  <RCTooltip
                                    placement="right"
                                    destroyTooltipOnHide
                                    overlayInnerStyle={resetRcTooltipInnerStyle}
                                    mouseLeaveDelay={0.4}
                                    overlay={(
                                      <div className="darkTooltip">
                                        <UseNonMfgLanguageFeature
                                          mfgLanguageComponent={
                                            <Badge className="mb5">* (Print Time * Printer Running Cost)</Badge>
                                          }
                                          nonMfgLanguageComponent={(
                                            <Badge className="mb5">
                                              * (Production Device Time * Production Device
                                              Running Cost)
                                            </Badge>
                                          )}
                                        />
                                        <Col xs={6}>
                                          <Badge className="mb5">* (Material Used * Cost of Material)</Badge>
                                        </Col>
                                        <Col xs={6}>
                                          <Badge>= Custom Bureau Cost</Badge>
                                        </Col>
                                      </div>
                                    )}
                                  >
                                    <FontAwesomeIcon icon={faInfoCircle} className="spacer-left" />
                                  </RCTooltip>

                                </h4>
                                <Col lg={6} xs={12}>
                                  <FormGroup className="form-group">
                                    <FormLabel>
                                      <UseNonMfgLanguageFeature
                                        mfgLanguageComponent={(
                                          <FormattedMessage
                                            id="printerCostScaleFactor"
                                            defaultMessage="Printer Cost Scale Factor"
                                          />
                                        )}
                                        nonMfgLanguageComponent={(
                                          <FormattedMessage
                                            id="mfg.printerCostScaleFactor.productionDeviceCostScaleFactor"
                                            defaultMessage="Production Device Cost Scale Factor"
                                          />
                                        )}
                                      />
                                      :
                                    </FormLabel>
                                    <InputGroup>
                                      <Field
                                        name="print_cost_scale_factor"
                                        type="number"
                                        render={props => (
                                          <FormControl
                                            {...props.input}
                                          />
                                        )}
                                      />
                                      <InputGroup.Text>%</InputGroup.Text>
                                    </InputGroup>
                                  </FormGroup>
                                  <FormGroup className="form-group">
                                    <FormLabel>
                                      <FormattedMessage
                                        id="overheadCostPerPiece"
                                        defaultMessage="Overhead Cost Per Piece"
                                      />
                                      :
                                    </FormLabel>
                                    <FormControlCost
                                      type="number"
                                      name="constant_overhead"
                                      initialValue={initialFormValues?.constant_overhead}
                                      currency={defaultCurrency}
                                    />
                                  </FormGroup>
                                  {prospers.length > 0 &&
                                    _some(prosperPrinterMaterials, { printer_name: values.name }) && (
                                    <Badge bg="success">
                                      <FormattedMessage id="prosperIsActive" defaultMessage="Linked to Prosper 3D Estimation" />
                                    </Badge>
                                  )}
                                </Col>

                                <Col lg={6} xs={12}>
                                  <FormGroup className="form-group">
                                    <FormLabel>
                                      <FormattedMessage
                                        id="materialCostScaleFactor"
                                        defaultMessage="Material Cost Scale Factor"
                                      />
                                      :
                                    </FormLabel>
                                    <InputGroup>
                                      <Field
                                        name="material_cost_scale_factor"
                                        type="number"
                                        render={props => (
                                          <FormControl
                                            {...props.input}
                                          />
                                        )}
                                      />
                                      <InputGroup.Text>%</InputGroup.Text>
                                    </InputGroup>
                                  </FormGroup>

                                  <FormGroup className="form-group">
                                    <FormLabel>
                                      <FormattedMessage
                                        id="setupCost"
                                        defaultMessage="Setup Cost"
                                      />
                                      :
                                      <RCTooltip
                                        placement="right"
                                        destroyTooltipOnHide
                                        overlayInnerStyle={resetRcTooltipInnerStyle}
                                        mouseLeaveDelay={0.4}
                                        overlay={(
                                          <p className="mb0 darkTooltip">Overhead Cost Per Line-Item</p>
                                        )}
                                      >
                                        <FontAwesomeIcon icon={faInfoCircle} className="spacer-left" />
                                      </RCTooltip>
                                    </FormLabel>
                                    <FormControlCost
                                      type="number"
                                      name="overhead_per_line_item"
                                      initialValue={initialFormValues?.overhead_per_line_item}
                                      currency={defaultCurrency}
                                    />
                                  </FormGroup>
                                </Col>
                              </Row>

                            </Col>

                            <Col lg={6} xs={12}>
                              <ExampleEstimates
                                runningCostPerHour={values.running_cost_per_hour}
                                printCostScaleFactor={values.print_cost_scale_factor}
                                materialCostScaleFactor={values.material_cost_scale_factor}
                                constantOverhead={constantOverhead}
                                use100Infill={use100Infill}
                                isCustomDarkCardStyle
                              />
                            </Col>
                          </Row>
                        </ModalGroupedSection>

                        {someFeaturesAdvancedSectionEnabled && (
                          <ModalGroupedSection
                            isSectionVisible={groupedSectionsVisibilityState.advanced}
                            sectionTitle={groupedSectionTitles.advanced}
                          >
                            <Row>
                              <Col lg={6} xs={12}>
                                <Feature featureName={FEATURES.WORK_SCHEDULE}>
                                  <LaborDuration
                                    typesEnabled={false}
                                    toggleLaborWorkWorkField={form.mutators.toggleEnableLaborEnhancedWorkflow}
                                    isLaborWorkstationEnabled={values.labor_enhanced_workflow_enabled}
                                    isCustomDarkCardStyle
                                  >
                                    <FormGroup className="form-group" controlId="uxLaborDuration">
                                      <Row>
                                        <Col>
                                          <FormLabel>Default Labor Duration:</FormLabel>
                                          <RCTooltip
                                            placement="right"
                                            destroyTooltipOnHide
                                            overlayInnerStyle={resetRcTooltipInnerStyle}
                                            mouseLeaveDelay={0.4}
                                            overlay={(
                                              <p className="mb0 darkTooltip">Labor to Start is the default for printer types.</p>
                                            )}
                                          >
                                            <FontAwesomeIcon className="spacer-left" icon={faInfoCircle} />
                                          </RCTooltip>
                                          <InputGroup>
                                            <Field
                                              name="duration"
                                              type="text"
                                              validate={value => (!isInHoursMinutesFormat(value))}
                                              render={fieldProps =>
                                                (
                                                  <FormControl
                                                    {...fieldProps.input}
                                                    required
                                                    maxLength={6}
                                                    onChange={event =>
                                                      form.mutators.onChangeValue('duration', event.target.value)}
                                                    disabled={!values.labor_enhanced_workflow_enabled}
                                                  />
                                                )}
                                            />

                                            <InputGroup.Text>
                                              <FormattedMessage
                                                id="field.workstation-duration"
                                                defaultMessage="HH:MM"
                                              />

                                            </InputGroup.Text>

                                          </InputGroup>
                                        </Col>

                                      </Row>
                                    </FormGroup>
                                    {isDurationInvalid && (
                                      <div className="text-start text-danger">Please use HH:MM format above.</div>
                                    )}
                                  </LaborDuration>
                                </Feature>
                                <Feature featureName={FEATURES.WORK_SCHEDULE}>
                                  <LaborAvailableSchedule
                                    handleChange={handleChangeLaborTime}
                                    handleSetNonStopTime={handleSetLaborNonStopTime}
                                    scheduleTime={laborScheduleTime}
                                    nonStopEnabled={laborNonStopEnabled}
                                    workSchedule={workScheduleEnabled}
                                    setWorkSchedule={handleTriggerWorkSchedule}
                                    handleSetWorkday={handleSetWorkday}
                                    workDays={workDays}
                                    UTC_TimeZone={UTC_TimeZone}
                                    setUTC_Timezone={handleUTC_TimezoneSetting}
                                    isCustomDarkCardStyle
                                  />
                                </Feature>

                              </Col>
                              <Col lg={6} xs={12}>
                                <Feature featureName={FEATURES.JENI_CLUSTER}>
                                  <Card className="custom-darken-modal--card mb15">
                                    <Card.Header className="pd-exp custom-darken-modal--card-header">
                                      Integration Settings
                                    </Card.Header>
                                    <Card.Body className="custom-darken-modal--card-body">
                                      <IntegratedWorkstationCheck
                                        toggleIntegratedWorkStation={form.mutators.toggleIntegratedWorkStation}
                                        isIntegratedWorkstationEnabled={values.integrated}
                                      />
                                    </Card.Body>
                                  </Card>
                                </Feature>
                              </Col>
                            </Row>
                          </ModalGroupedSection>
                        )}

                      </ModalGroupedSectionProvider>

                    </Row>
                  </Modal.Body>
                  <Modal.Footer>
                    <DisabledByAccessInfoCheck
                      resourceUri={printerType && printerType.uri}
                      actionType={ACCESS_INFO_ACTION_TYPES.EDIT}
                      tooltipPlacement="bottom"
                      renderDisabledPrefix
                    >
                      {({ disabled }) => (
                        <>
                          {
                            initialFormValues?.id && (
                              <Button
                                disabled={disabled || submitting || deleting}
                                variant="danger"
                                onClick={() => setConfirmDelete(true)}
                              >
                                {deleting ? <Loading /> : (
                                  <>
                                    <FontAwesomeIcon icon={faTrash} className="spacer-right" />
                                    <FormattedMessage id="button.delete" defaultMessage="Delete" />
                                  </>
                                )}
                              </Button>
                            )
                          }
                          <Button type="submit" disabled={disabled || submitting} variant="success">
                            {submitting ? <Loading /> : (
                              <>
                                <FontAwesomeIcon icon={initialFormValues?.id ? faFloppyDisk : faPlus} className="spacer-right" />
                                <FormattedMessage
                                  id={initialFormValues?.id ? 'button.save' : 'button.createResource'}
                                  defaultMessage={initialFormValues?.id ? 'Save' : 'Create'}
                                />
                              </>
                            )}
                          </Button>
                        </>
                      )}
                    </DisabledByAccessInfoCheck>
                  </Modal.Footer>
                </form>
              );
            }}
          />
        )
      }
      {confirmDelete && (
        <ConfirmationModal
          handleCancel={() => setConfirmDelete(false)}
          handleConfirm={handleConfirmDeletePrinterType}
          message={(
            <FormattedMessage
              id="message.entityDeletePrompt"
              defaultMessage="Are you sure you want to delete this {entityType}?"
              values={{ entityType: 'Printer Type' }}
            />
          )}
          confirmButtonContent={<FormattedMessage id="button.delete" defaultMessage="Delete" />}
        />
      )}
    </Modal>
  );
};

PrinterTypeModal.propTypes = {
  onFormSubmit: PropTypes.func.isRequired,
  customGroups: PropTypes.arrayOf(PropTypes.shape({})),
  materials: PropTypes.arrayOf(PropTypes.shape({})),
  materialsByUri: PropTypes.objectOf(
    PropTypes.shape({
      name: PropTypes.string,
      manufacturing_process: PropTypes.string,
      uri: PropTypes.string,
    }),
  ),
  input: finalFormInputTypes.isRequired,
  printerType: printerTypeResourceType,
  infillStrategies: PropTypes.arrayOf(PropTypes.shape({})),
  supportStrategies: PropTypes.arrayOf(PropTypes.shape({})),
  prosperPrinterMaterials: PropTypes.arrayOf(PropTypes.shape({})),
  prospers: PropTypes.arrayOf(PropTypes.shape({})),
  manufacturers: PropTypes.arrayOf(PropTypes.shape({})),
  onDelete: PropTypes.func.isRequired,
  fromTemplate: PropTypes.bool,
  defaultCurrency: PropTypes.string.isRequired,
  use100Infill: PropTypes.bool.isRequired,
  initialFormValues: PropTypes.shape({
    name: PropTypes.string,
    uri: PropTypes.string,
    integrated: PropTypes.bool,
    manufacturer: PropTypes.string,
    build_packer_type: PropTypes.string,
    material: PropTypes.string,
    infill_strategy: PropTypes.string,
    support_strategy: PropTypes.string,
    running_cost_per_hour: PropTypes.number,
    print_cost_scale_factor: PropTypes.number,
    material_cost_scale_factor: PropTypes.number,
    constant_overhead: PropTypes.number,
    overhead_per_line_item: PropTypes.number,
    edit_group: PropTypes.string,
    manufacturing_process: PropTypes.string,
    support_strategies: PropTypes.arrayOf(PropTypes.string),
    infill_strategies: PropTypes.arrayOf(PropTypes.string),
    prosper_printer_materials: PropTypes.arrayOf(PropTypes.string),
    min_packing_distance: PropTypes.number,
    build_packing_distance: PropTypes.number,
    materials: PropTypes.arrayOf(PropTypes.string),
    time_after_print: PropTypes.number,
    time_before_print: PropTypes.number,
    build_volume: PropTypes.shape({
      x: PropTypes.number,
      y: PropTypes.number,
      z: PropTypes.number,
    }),
    description: PropTypes.string,
    id: PropTypes.string,
    uuid: PropTypes.string,
    flow_time: PropTypes.number,
    flow_time_queued: PropTypes.number,
  }).isRequired,
  isGroupQualificationsFeatureEnabled: PropTypes.bool.isRequired,
  submitting: PropTypes.bool.isRequired,
  handleChangeLaborTime: PropTypes.func.isRequired,
  handleSetLaborNonStopTime: PropTypes.func.isRequired,
  laborScheduleTime: PropTypes.shape({}).isRequired,
  laborNonStopEnabled: PropTypes.bool.isRequired,
  workScheduleEnabled: PropTypes.bool.isRequired,
  handleTriggerWorkSchedule: PropTypes.func.isRequired,
  isDurationInvalid: PropTypes.shape({
    flow_time: PropTypes.bool,
    flow_time_queued: PropTypes.bool,
  }).isRequired,
  onChangeDurationValue: PropTypes.shape({}).isRequired,
  handleSetWorkday: PropTypes.func.isRequired,
  workDays: PropTypes.arrayOf(PropTypes.string).isRequired,
  durationState: PropTypes.shape({
    isDurationInvalid: PropTypes.bool,
    setIsDurationInvalid: PropTypes.func,
  }).isRequired,
  handleUTC_TimezoneSetting: PropTypes.func.isRequired,
  UTC_TimeZone: PropTypes.number.isRequired,
  meta: PropTypes.shape({
    invalid: PropTypes.bool,
  }).isRequired,
  handleMaterialModeSetting: PropTypes.func.isRequired,
  multiMaterialIsActive: PropTypes.bool.isRequired,
  isPack3dPremiumFeatureEnabled: PropTypes.bool.isRequired,
  handleSubmitManufacturer: PropTypes.func,
  isManufacturerFetching: PropTypes.bool,
  isVisible: PropTypes.bool.isRequired,
  activePlateType: PropTypes.string.isRequired,
  setActivePlateType: PropTypes.func.isRequired,
  handleSetSectionVisibilityState: PropTypes.func.isRequired,
  groupedSectionsVisibilityState: PropTypes.shape({
    general: PropTypes.bool,
    buildPacking: PropTypes.bool,
    costPricing: PropTypes.bool,
    advanced: PropTypes.bool,
  }).isRequired,
  handleCloseModal: PropTypes.func.isRequired,
  uuid: PropTypes.string,
  fetching: PropTypes.bool,
  deleting: PropTypes.bool,
  someFeaturesAdvancedSectionEnabled: PropTypes.bool.isRequired,
  sectionsWithMissingFields: PropTypes.shape({}),
};

PrinterTypeModal.defaultProps = {
  materials: [],
  customGroups: [],
  materialsByUri: {},
  manufacturers: [],
  infillStrategies: [],
  supportStrategies: [],
  prosperPrinterMaterials: [],
  prospers: [],
  fromTemplate: false,
  printerType: null,
  handleSubmitManufacturer: null,
  isManufacturerFetching: false,
  uuid: null,
  fetching: false,
  deleting: false,
  sectionsWithMissingFields: {},
};

export default PrinterTypeModal;
