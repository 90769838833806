import React from 'react';
import PropTypes from 'prop-types';
import Actions from 'rapidfab/actions';
import { extractUuid } from 'rapidfab/reducers/makeApiReducers';
import * as Selectors from 'rapidfab/selectors';
import Alert from 'rapidfab/utils/alert';
import { getRouteURI, prepareStepperURI } from 'rapidfab/utils/uriUtils';
import { Form as BSForm, Button, ButtonToolbar, Card, Container } from 'react-bootstrap';

import BreadcrumbNav from 'rapidfab/components/BreadcrumbNav';
import Loading from 'rapidfab/components/Loading';
import SaveButtonTitle from 'rapidfab/components/SaveButtonTitle';
import Stepper from 'rapidfab/components/stepper/Stepper';
import {
  API_RESOURCES,
  COMMENT_RELATED_TABLE_NAMES,
  FEATURES,
  ORDER_SHIPPING_GROUPING_OPTIONS,
  RESTRICTED_USER_ORDER_STEP_ORDER_DETAILS,
  RESTRICTED_USER_ORDER_STEPS,
  ROUTES,
} from 'rapidfab/constants';
import NewRestrictedOrderFormContainer from 'rapidfab/containers/records/order/restricted/NewRestrictedOrderFormContainer';
import { FormattedMessage } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';

import { isFeatureEnabled } from 'rapidfab/selectors';
import { Form } from 'react-final-form';

const SaveOrderButton = ({ submitting }) => (
  <Button
    disabled={submitting}
    type="submit"
    value="submit"
    variant="success"
    size="sm"
  >
    {submitting ? <Loading /> : <SaveButtonTitle resourceName="Order" />}
  </Button>
);

SaveOrderButton.propTypes = { submitting: PropTypes.bool.isRequired };

const NewRestrictedOrderComponent = ({
  onSubmit,
  submitting,
  bannerMessage,
  bannerLink,
  initCustomFieldValues,
  onCustomFieldChange,
  initialFormValues,
  ...props
}) => {
  const breadcrumbs = ['orders', 'New Order'];
  return (
    <Container fluid>
      <BreadcrumbNav breadcrumbs={breadcrumbs} />

      <Stepper
        steps={prepareStepperURI(RESTRICTED_USER_ORDER_STEPS)}
        activeStep={RESTRICTED_USER_ORDER_STEP_ORDER_DETAILS}
      />

      <Form
        onSubmit={onSubmit}
        mutators={{
          initCustomFieldValues,
          onCustomFieldChange,
        }}
        initialValues={initialFormValues}
        render={({ handleSubmit, form, values }) => (
          <BSForm horizontal onSubmit={handleSubmit}>
            {bannerMessage && (
              <div className="jumbotron">
                <p>{bannerMessage}</p>
                <Button href={bannerLink}>
                  <FormattedMessage
                    id="moreInformation"
                    defaultMessage="More Information"
                  />
                </Button>
              </div>
            )}

            <Card className="m-b-sm" bg="dark">
              <Card.Header className="pd-exp inverse">Order</Card.Header>
              <div className="card-body-wrapper">
                <Card.Body>
                  <NewRestrictedOrderFormContainer
                    {...props}
                    initialFormValues={initialFormValues}
                    formValues={values}
                    initCustomFieldValues={form.mutators.initCustomFieldValues}
                    onCustomFieldChange={form.mutators.onCustomFieldChange}
                  />
                </Card.Body>
              </div>
            </Card>
            <ButtonToolbar className="clearfix" style={{ marginBottom: '3rem' }}>
              <div className="flexed-pull-right">
                <Button type="submit" variant="light" disabled={submitting}>
                  {submitting ? <Loading /> : 'Next step: Upload CAD Files'}
                </Button>
              </div>
            </ButtonToolbar>
          </BSForm>
        )}
      />
    </Container>
  );
};

NewRestrictedOrderComponent.defaultProps = {
  bannerMessage: null,
  bannerLink: null,
};

NewRestrictedOrderComponent.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  submitting: PropTypes.bool.isRequired,
  bannerMessage: PropTypes.string,
  bannerLink: PropTypes.string,
  initCustomFieldValues: PropTypes.func.isRequired,
  onCustomFieldChange: PropTypes.func.isRequired,
  initialFormValues: PropTypes.shape({}).isRequired,
};

const NewRestrictedOrder = props => {
  const { initialShipping,
    isOrderBusinessSegmentFeatureEnabled,
    isShipmentForOrderFeatureEnabled } = props;
  const bureau = useSelector(Selectors.getBureau);
  const submitting = useSelector(state =>
    state.ui.nautilus[API_RESOURCES.MODEL].post.fetching || state.ui.nautilus[API_RESOURCES.ORDER].post.fetching);
  const submittedOrders = useSelector(Selectors.getSubmittedOrders);
  const isAmazonUser = useSelector(state => isFeatureEnabled(state, FEATURES.AMAZON_ORDER_FIELDS));

  const selected = {
    bureau,
    submitting,
    submittedOrders,
  };

  const dispatch = useDispatch();

  const saveSubmittedOrders = orders => dispatch(Actions.SubmittedOrders.setSubmittedOrders(orders));

  const onSubmit = payload => {
    const orderPayload = {
      application_type: payload.application_type,
      bureau: bureau.uri,
      custom_field_values: payload.custom_field_values,
      due_date: payload.due_date
        ? new Date(payload.due_date).toISOString()
        : null,
      customer_email: payload.customer_email,
      customer_name: payload.customer_name,
      customer_po: payload.customer_po,
      ip_sensitivity: payload.ip_sensitivity,
      location: payload.location,
      name: payload.name,
      order_owner: payload.order_owner,
      order_type: payload.order_type,
      region: payload.region,
      shipping: {
        name: payload.shipping?.name,
        address: payload.shipping?.address,
        tracking: payload.shipping?.tracking,
        uri: payload.shipping?.uri
          ? payload.shipping?.uri
          : initialShipping,
      },
    };

    // The 'Whole Order Shipment' checkbox should be selected by default for Danfoss or Amazon users
    if (isShipmentForOrderFeatureEnabled || isOrderBusinessSegmentFeatureEnabled || isAmazonUser) {
      orderPayload.shipping_grouping = ORDER_SHIPPING_GROUPING_OPTIONS.BY_ORDER;
    }

    const initialComment = payload.initial_comment;

    Object.keys(orderPayload).forEach(key => {
      if (orderPayload[key] == null || orderPayload[key] === 'none') {
        delete orderPayload[key];
      }
    });

    dispatch(Actions.Api.nautilus[API_RESOURCES.ORDER].post(orderPayload))
      .then(async response => {
        const orderUuid = extractUuid(response.payload.uri);

        if (initialComment) {
          try {
            await dispatch(Actions.Api.nautilus[API_RESOURCES.COMMENT].post({
              related_table_name: COMMENT_RELATED_TABLE_NAMES.ORDER,
              related_uuid: orderUuid,
              text: initialComment,
            }));
          } catch {
            Alert.error(
              <FormattedMessage
                id="toaster.error.comment.initialComment"
                defaultMessage="Something went wrong with submitting the initial comment. Please add comment manually."
              />,
            );
          }
        }

        try {
          const localStorageOrderLimit = 25;
          submittedOrders.unshift(orderPayload);
          saveSubmittedOrders(submittedOrders.slice(0, localStorageOrderLimit));
        } catch {
          // empty
        }

        const orderEditURI = getRouteURI(ROUTES.ORDER_RESTRICTED_EDIT, { uuid: orderUuid });

        // Push URI with order edit to history, and then navigate to line item edit page
        window.history.pushState(null, '', `/${orderEditURI}`);
        window.location.hash = getRouteURI(ROUTES.EDIT_RESTRICTED_LINE_ITEM_ORDER, { uuid: orderUuid });
      })
      .catch(error => {
        console.error(error);
        Alert.error(`Unable to submit the order. ${error}`);
      });
  };

  const htmlBannerObject = { __html: bureau.order_banner.html_message };
  // This html is sanitized by the server
  // eslint-disable-next-line react/no-danger
  const htmlBannerMessageRender = <div dangerouslySetInnerHTML={htmlBannerObject} />;

  return (
    <NewRestrictedOrderComponent
      {...props}
      {...selected}
      onSubmit={onSubmit}
      bannerMessage={bureau.order_banner.html_message ?
        htmlBannerMessageRender :
        bureau.order_banner.message}
      bannerLink={bureau.order_banner.link}
    />
  );
};

export default NewRestrictedOrder;

NewRestrictedOrder.defaultProps = {
  initialShipping: null,
};

NewRestrictedOrder.propTypes = {
  initialShipping: PropTypes.string,
  isOrderBusinessSegmentFeatureEnabled: PropTypes.bool.isRequired,
  isShipmentForOrderFeatureEnabled: PropTypes.bool.isRequired,
};
