import Actions from 'rapidfab/actions';
import { API_RESOURCES } from 'rapidfab/constants';
import _map from 'lodash/map';

// eslint-disable-next-line import/prefer-default-export
export const loadExportControlData = async (dispatch, resourceUris) => {
  const labelRelationshipsResponse = await dispatch(Actions.Api.nautilus[API_RESOURCES.LABEL_RELATIONSHIP].list({
    target_uri: resourceUris,
  }));

  const labelRelationships = labelRelationshipsResponse.json?.resources;

  if (labelRelationships.length) {
    const labelUris = _map(labelRelationships, 'label');

    await dispatch(Actions.Api.nautilus[API_RESOURCES.LABEL].list({
      uri: labelUris,
    }));
  }
};
