import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { UNITS } from 'rapidfab/constants';
import { FormattedMessage } from 'rapidfab/i18n';
import { NavDropdown } from 'react-bootstrap';
import Actions from 'rapidfab/actions';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck } from '@fortawesome/free-solid-svg-icons';

const UnitsMenu = ({
  units,
  setMetric,
  setImperial,
}) => (
  <NavDropdown id="units menu" title="Units">
    <NavDropdown.Item onClick={setMetric}>
      <FontAwesomeIcon
        icon={faCheck}
        style={{ visibility: units === UNITS.metric ? null : 'hidden' }}
        className="spacer-right"
      />
      <FormattedMessage id="metric" defaultMessage="Metric" />
    </NavDropdown.Item>
    <NavDropdown.Item onClick={setImperial}>
      <FontAwesomeIcon
        icon={faCheck}
        style={{ visibility: units === UNITS.imperial ? null : 'hidden' }}
        className="spacer-right"
      />
      <FormattedMessage id="imperial" defaultMessage="Imperial" />
    </NavDropdown.Item>
  </NavDropdown>
);
UnitsMenu.propTypes = {
  units: PropTypes.string.isRequired,
  setMetric: PropTypes.func.isRequired,
  setImperial: PropTypes.func.isRequired,
};

const mapStateToProps = ({ units }) => ({ units });

const mapDispatchToProps = dispatch => ({
  setImperial: () => dispatch(Actions.Units.setImperial),
  setMetric: () => dispatch(Actions.Units.setMetric),
});

export default connect(mapStateToProps, mapDispatchToProps)(UnitsMenu);
