import React, { useState } from 'react';
import PropTypes from 'prop-types';

import { FormattedMessage } from 'react-intl';

import {
  Button,
  Modal,
} from 'react-bootstrap';
import Loading from 'rapidfab/components/Loading';

const CancelOrDeleteModal = ({
  modalType,
  handleConfirm,
  handleOpen,
  priorityBackdrop,
}) => {
  const [isConfirming, setIsConfirming] = useState(false);

  let message = '';
  let buttonText = '';

  switch (modalType) {
    case 'cancel':
      message = (
        <FormattedMessage
          id="message.cancelPrompt"
          defaultMessage="Are you sure you want to cancel?"
        />
      );
      buttonText = <FormattedMessage id="button.cancel" defaultMessage="Cancel" />;
      break;

    case 'delete':
      message = (
        <FormattedMessage
          id="message.deletePrompt"
          defaultMessage="Are you sure you want to delete?"
        />
      );
      buttonText = <FormattedMessage id="button.delete" defaultMessage="Delete" />;
      break;

    case 'deleteQuoteStep':
      message = (
        <FormattedMessage
          id="message.deleteQuoteStepPrompt"
          defaultMessage="This will delete custom costs and revert to baseline costs."
        />
      );
      buttonText = <FormattedMessage id="button.deleteQuoteStep" defaultMessage="Delete" />;
      break;

    case 'deleteQuoteStepAll':
      message = (
        <FormattedMessage
          id="message.deleteQuoteStepPromptAll"
          defaultMessage="Are you sure? This will delete all additional charges."
        />
      );
      buttonText = <FormattedMessage id="button.deleteQuoteStep" defaultMessage="Delete" />;
      break;

    case 'confirmClose':
      message = (
        <FormattedMessage
          id="message.confirmClose"
          defaultMessage="Warning: Any unsaved changes made will be lost. Please ensure you have clicked save before closing the modal."
        />
      );
      buttonText = <FormattedMessage id="close" defaultMessage="Close" />;
      break;

    case 'confirmRunTransformationLost':
      message = (
        <FormattedMessage
          id="message.confirmRunTransformationLost"
          defaultMessage="Warning: Any unsaved changes to the Run Production Change will be lost. Please complete the Run Production Change before switching the modes."
        />
      );
      buttonText = <FormattedMessage id="button.switchAnyway" defaultMessage="Switch Anyway" />;
      break;

    case 'duplicate':
      message = (
        <FormattedMessage
          id="message.duplicatePrompt"
          defaultMessage="Are you sure you want to duplicate?"
        />
      );
      buttonText = <FormattedMessage id="button.duplicate" defaultMessage="Duplicate" />;
      break;

    default:
  }

  const closeModal = () => {
    handleOpen('');
  };

  const onConfirm = () => {
    setIsConfirming(true);
    const confirmPromise = handleConfirm();
    if (confirmPromise) {
      confirmPromise.then(() => {
        closeModal();
      }).finally(() => {
        setIsConfirming(false);
      });
    } else {
      setIsConfirming(false);
      closeModal();
    }
  };

  const priorityBackdropStyle = priorityBackdrop ? 'priorityBackdrop' : '';

  return (
    <Modal show={modalType} onHide={closeModal} backdrop="static" backdropClassName={priorityBackdropStyle}>
      <Modal.Body>
        {message}
      </Modal.Body>
      <Modal.Footer>
        <Button variant="danger" onClick={onConfirm} disabled={isConfirming}>
          {isConfirming && (<Loading inline className="spacer-right" />)}
          {buttonText}
        </Button>
        <Button variant="primary" onClick={closeModal} disabled={isConfirming}>
          <FormattedMessage id="back" defaultMessage="Back" />
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

CancelOrDeleteModal.defaultProps = {
  priorityBackdrop: false,
};

CancelOrDeleteModal.propTypes = {
  modalType: PropTypes.oneOf(['cancel', 'delete', 'duplicate']).isRequired,
  handleConfirm: PropTypes.func.isRequired,
  handleOpen: PropTypes.func.isRequired,
  priorityBackdrop: PropTypes.bool,
};

export default CancelOrDeleteModal;
