import React from 'react';

const AssetsTypesIcon = props => (
  <svg
    width="17.5"
    height="17"
    viewBox="0 0 19 19"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className="custom-navbar-icon"
    {...props}
  >
    <g clipPath="url(#clip0_437_1117)" className="custom-navbar-icon">
      <path
        d="M15.2688 7.33189L12.25 6.22905V2.92356C12.25 2.46658 11.9594 2.05834 11.5188 1.89688L8.39375 0.754426C8.14063 0.659983 7.85938 0.659983 7.60313 0.754426L4.47813 1.89688C4.0375 2.05834 3.74688 2.46658 3.74688 2.92356V6.22905L0.728125 7.33189C0.290625 7.49336 0 7.9016 0 8.35858V11.7128C0 12.1271 0.240625 12.508 0.621875 12.6938L3.74688 14.2171C4.0625 14.3724 4.4375 14.3724 4.75313 14.2171L8 12.6329L11.2469 14.2171C11.5625 14.3724 11.9375 14.3724 12.2531 14.2171L15.3781 12.6938C15.7594 12.508 16 12.1271 16 11.7128V8.35858C16 7.9016 15.7094 7.49336 15.2688 7.33189ZM11.1875 6.25342L8.53125 7.22527V5.14753L11.1875 4.02031V6.25342ZM4.8125 2.88091L8 1.71713L11.1875 2.88091V2.89919L8 4.16045L4.8125 2.89919V2.88091ZM7.4375 11.7494L4.78125 13.0441V10.6343L7.4375 9.45228V11.7494ZM7.4375 8.33725L4.25 9.59852L1.0625 8.33725V8.31897L4.25 7.15519L7.4375 8.31897V8.33725ZM14.9375 11.7494L12.2812 13.0441V10.6343L14.9375 9.45228V11.7494ZM14.9375 8.33725L11.75 9.59852L8.5625 8.33725V8.31897L11.75 7.15519L14.9375 8.31897V8.33725Z"
        className="custom-navbar-icon"
      />
      <path
        d="M17.9361 3.25764L17.4875 2.99711C17.5328 2.75141 17.5328 2.49936 17.4875 2.25366L17.9361 1.99313C17.9877 1.96348 18.0109 1.90206 17.9941 1.84487C17.8772 1.46785 17.6781 1.12683 17.418 0.84301C17.378 0.79959 17.3127 0.788999 17.2622 0.818652L16.8136 1.07918C16.6251 0.916085 16.4082 0.790058 16.1734 0.707453V0.187461C16.1734 0.128154 16.1323 0.0762612 16.0744 0.0635527C15.6879 -0.0232891 15.292 -0.0190529 14.9245 0.0635527C14.8666 0.0762612 14.8255 0.128154 14.8255 0.187461V0.708512C14.5917 0.792176 14.3748 0.918203 14.1852 1.08024L13.7377 0.819711C13.6861 0.790058 13.6219 0.79959 13.5819 0.84407C13.3218 1.12683 13.1227 1.46785 13.0059 1.84593C12.988 1.90312 13.0122 1.96454 13.0638 1.99419L13.5124 2.25472C13.4671 2.50042 13.4671 2.75247 13.5124 2.99817L13.0638 3.25869C13.0122 3.28835 12.989 3.34977 13.0059 3.40696C13.1227 3.78398 13.3218 4.12499 13.5819 4.40882C13.6219 4.45224 13.6872 4.46283 13.7377 4.43318L14.1863 4.17265C14.3748 4.33574 14.5917 4.46177 14.8265 4.54438V5.06543C14.8265 5.12473 14.8676 5.17663 14.9255 5.18933C15.312 5.27618 15.7079 5.27194 16.0754 5.18933C16.1334 5.17663 16.1744 5.12473 16.1744 5.06543V4.54438C16.4082 4.46071 16.6251 4.33468 16.8147 4.17265L17.2633 4.43318C17.3148 4.46283 17.3791 4.4533 17.4191 4.40882C17.6792 4.12605 17.8782 3.78504 17.9951 3.40696C18.0109 3.34871 17.9877 3.28729 17.9361 3.25764ZM15.4994 3.47262C15.035 3.47262 14.657 3.09242 14.657 2.62538C14.657 2.15835 15.035 1.77815 15.4994 1.77815C15.9638 1.77815 16.3419 2.15835 16.3419 2.62538C16.3419 3.09242 15.9638 3.47262 15.4994 3.47262Z"
        className="custom-navbar-icon"
      />
    </g>
    <defs>
      <clipPath id="clip0_437_1117">
        <rect width="18" height="15" fill="white" />
      </clipPath>
    </defs>
  </svg>

);

export default AssetsTypesIcon;
