import _capitalize from 'lodash/capitalize';
import SlicerFileErrorTooltip from 'rapidfab/components/SlicerFileErrorTooltip';
import { SLICER_CONFIGURATION_FILE_STATUSES } from 'rapidfab/constants';
import { FormattedMessage } from 'rapidfab/i18n';
import React from 'react';
import { Button, Container } from 'react-bootstrap';
import PropTypes from 'prop-types';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faExclamationCircle, faPlus, faTriangleExclamation } from '@fortawesome/free-solid-svg-icons';
import TableWithSearching from '../Tables/TableWithSearching';
import CreateSlicerConfigurationModal from '../modals/CreateSlicerConfigurationModal';

const SlicerConfigurations = props => {
  const {
    data,
    fetching,
    handleSlicerFormSubmit,
    fileSelected,
    machineResources,
    isDebugModeEnabled,
    isFetching,
    isSubmitting,
    modal: { isModalOpen, handleClosingModal, handleOpeningModal },
    shouldBlurBackground,
    getAllSlicerConfigs,
    allSlicerConfigsFetched,
    isModelUploadLoading,
  } = props;

  const columns = [
    {
      type: 'record',
      uid: 'field.name',
      accessor: 'name',
      defaultMessage: 'Config Name',
      uri: 'uri',
      isSortable: true,
      resource: 'slicer',
    },
    {
      type: 'custom',
      uid: 'field.engine',
      accessor: 'engine',
      defaultMessage: 'Slicer Program',
      resource: 'engine',
      isSortable: true,
      Cell: ({ row: { original } }) => {
        const slicerFileNotUploaded = original?.status === SLICER_CONFIGURATION_FILE_STATUSES.PENDING_FILE_UPLOAD;
        const slicerFileFailed = original?.status === SLICER_CONFIGURATION_FILE_STATUSES.FAILED;
        return (
          <>
            <span>{_capitalize(original?.engine)}</span>
            <SlicerFileErrorTooltip
              conditionToShow={slicerFileNotUploaded}
              text={`The slicer's setup file might be broken, not yet uploaded, or still uploading.
                  Please refresh the page to check if it finishes uploading.
                  If you are still having trouble, you can replace the file by clicking the button below.`}
              icon={faTriangleExclamation}
              color="#ffd500"
            />
            <SlicerFileErrorTooltip
              conditionToShow={slicerFileFailed}
              text={`The upload of the slicer's setup file was unsuccessful.
          Please try to re-upload by selecting the button below, or opt for a different file.`}
              icon={faExclamationCircle}
              color="#ff0000"
            />
          </>
        );
      },
    },
    {
      type: 'custom',
      uid: 'field.targetMachine',
      accessor: 'machine',
      defaultMessage: 'Target Machine / Machine Type ',
      isSortable: true,
      Cell: ({ row: { original } }) => {
        const { machine, machineType } = original;
        return (
          <div>
            {machine} <i>({machineType})</i>
          </div>
        );
      },
    },
    isDebugModeEnabled && {
      type: 'uuid',
      uid: 'field.id',
      accessor: 'uuid',
      defaultMessage: 'ID',
      resource: 'slicer',
      isSortable: true,
      debugMode: true,
    },

  ];

  const NavbarLinks = (
    <Button
      className="pull-right"
      size="sm"
      variant="primary"
      onClick={handleOpeningModal}
    >
      <FontAwesomeIcon icon={faPlus} />{' '}
      <FormattedMessage
        id="record.slicer.configs"
        defaultMessage="Add Slicer Configuration"
      />
    </Button>
  );

  return (
    <Container fluid>
      <TableWithSearching
        {...props}
        isFetching={!shouldBlurBackground && fetching}
        withBreadcrumbs
        breadcrumbs={[
          'organize', 'slicerConfiguration',
        ]}
        navbar={NavbarLinks}
        data={data}
        columns={columns}
        isFilteringEnabled={false}
        withDefaultPagination={false}
        isManualSortingEnabled
        tableID="SlicerConfigurations"
        isUpdatedColumnShown={false}
        initialSortedDesc
      />
      <CreateSlicerConfigurationModal
        isOpen={isModalOpen}
        onClose={handleClosingModal}
        handleSlicerFormSubmit={handleSlicerFormSubmit}
        fileSelected={fileSelected}
        machineResources={machineResources}
        isFetching={isFetching}
        isSubmitting={isSubmitting || isModelUploadLoading}
        getAllSlicerConfigs={getAllSlicerConfigs}
        allSlicerConfigsFetched={allSlicerConfigsFetched}
      />
    </Container>
  );
};

SlicerConfigurations.propTypes = {
  data: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  fetching: PropTypes.bool.isRequired,
  row: PropTypes.shape({
    original: PropTypes.shape({
      uuid: PropTypes.string.isRequired,
      uri: PropTypes.string.isRequired,
      name: PropTypes.string.isRequired,
      archived: PropTypes.string,
      engine: PropTypes.string.isRequired,
      status: PropTypes.string.isRequired,
      machine: PropTypes.shape({}).isRequired,
      machineType: PropTypes.shape({}).isRequired,
    }),
  }).isRequired,
  handleSlicerFormSubmit: PropTypes.func.isRequired,
  fileSelected: PropTypes.shape({
    fileValue: PropTypes.shape({
      size: PropTypes.number,
    }),
  }).isRequired,
  machineResources: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  isDebugModeEnabled: PropTypes.bool.isRequired,
  isFetching: PropTypes.bool.isRequired,
  modal: PropTypes.shape({
    isModalOpen: PropTypes.bool.isRequired,
    handleClosingModal: PropTypes.func.isRequired,
    handleOpeningModal: PropTypes.func.isRequired,
  }).isRequired,
  isSubmitting: PropTypes.bool.isRequired,
  shouldBlurBackground: PropTypes.bool.isRequired,
  percentage: PropTypes.number.isRequired,
  formattedTime: PropTypes.string.isRequired,
  getAllSlicerConfigs: PropTypes.func.isRequired,
  allSlicerConfigsFetched: PropTypes.bool.isRequired,
  isFileSizeMoreThan5MB: PropTypes.bool.isRequired,
  isModelUploadLoading: PropTypes.bool.isRequired,
};

export default SlicerConfigurations;
