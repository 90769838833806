import React, { useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import PropTypes from 'prop-types';

import LineItemPrepWorkflowPanel from 'rapidfab/components/records/order/edit/LineItemPrepWorkflowPanel';
import {
  getPrepTaskRecordsForPrepWorkflowRecordOrderedByPosition,
  getPrepTasksByUri,
  getPrepWorkflowRecordForLineItem,
  getAvailablePrepWorkflowsForLineItem,
  getUUIDResource,
  getLineItemsForOrder,
} from 'rapidfab/selectors';
import { extractUuid } from 'rapidfab/utils/uuidUtils';
import Actions from 'rapidfab/actions';
import { getPreferencesUserSettings } from 'rapidfab/selectors/preferences';
import { API_RESOURCES, PAGINATION_IGNORE_DEFAULT_LIMIT } from 'rapidfab/constants';

const LineItemPrepWorkflowPanelContainer = props => {
  const { lineItemUri } = props;
  const lineItemUuid = extractUuid(lineItemUri);
  const lineItem = useSelector(state => getUUIDResource(state, lineItemUuid));
  const order = useSelector(state => (lineItem ? getUUIDResource(state, extractUuid(lineItem.order)) : null));
  const orderLineItems = useSelector(state => getLineItemsForOrder(state, order));
  const prepWorkflows = useSelector(state => getAvailablePrepWorkflowsForLineItem(state, lineItem));
  const prepWorkflowRecord = useSelector(state => getPrepWorkflowRecordForLineItem(state, lineItem));
  const prepTaskRecordsForWorkflow =
    useSelector(state => getPrepTaskRecordsForPrepWorkflowRecordOrderedByPosition(state, prepWorkflowRecord));
  const prepTasksByUri = useSelector(getPrepTasksByUri);
  const isLineItemSaving = useSelector(state => state.ui.nautilus[API_RESOURCES.LINE_ITEM].put.fetching);
  const isPrepWorkflowRecordCreating = useSelector(state =>
    state.ui.nautilus[API_RESOURCES.PREP_WORKFLOW_RECORD].post.fetching
    // For `locked` case, line items updated first, which is also a part of record creation process
    || state.ui.nautilus[API_RESOURCES.LINE_ITEM].put.fetching);
  const isLoading = useSelector(state => state.ui.nautilus[API_RESOURCES.PREP_WORKFLOW].list.fetching
    || state.ui.nautilus[API_RESOURCES.PREP_WORKFLOW].get.fetching
    || state.ui.nautilus[API_RESOURCES.PREP_TASK].list.fetching
    || state.ui.nautilus[API_RESOURCES.PREP_WORKFLOW_RECORD].list.fetching
    || state.ui.nautilus[API_RESOURCES.PREP_WORKFLOW_RECORD].get.fetching
    || state.ui.nautilus[API_RESOURCES.PREP_TASK_RECORD].list.fetching);

  const userSettings = useSelector(getPreferencesUserSettings);
  const selected = {
    isLoading,
    isLineItemSaving,
    prepTaskRecordsForWorkflow,
    isPrepWorkflowRecordCreating,
    lineItem,
    prepWorkflows,
    prepWorkflowRecord,
    prepTasksByUri,
    userSettings,
    orderLineItems,
  };

  const dispatch = useDispatch();

  const onPrepWorkflowSubmit = async (currentLineItemUri, prepWorkflowUri) => {
    const payload = {
      prep_workflow: prepWorkflowUri,
    };
    await dispatch(Actions.Api.nautilus[API_RESOURCES.LINE_ITEM].put(extractUuid(currentLineItemUri), payload));
    return dispatch(Actions.Api.nautilus[API_RESOURCES.LINE_ITEM].get(extractUuid(currentLineItemUri)));
  };

  const onLineItemPrepWorkflowsSync = (prepWorkflowUri, lineItemUris) =>
    Promise.all(lineItemUris.map(currentLineItemUri =>
      dispatch(Actions.Api.nautilus[API_RESOURCES.LINE_ITEM]
        .put(extractUuid(currentLineItemUri), { prep_workflow: prepWorkflowUri })),
    ));
  const onPrepWorkflowStart = (currentLineItemUri, isLocked) => {
    const payload = {
      prep_workflow: currentLineItemUri.prep_workflow,
      order: currentLineItemUri.order,
    };
    if (!isLocked) {
      payload.line_item = currentLineItemUri.uri;
    }

    return dispatch(Actions.Api.nautilus[API_RESOURCES.PREP_WORKFLOW_RECORD]
      .post(payload)).then(prepWorkflowResponse => {
      const createdPrepWorkflowRecordUri = prepWorkflowResponse.headers.location;
      dispatch(Actions.Api.nautilus[API_RESOURCES.PREP_WORKFLOW_RECORD]
        .get(extractUuid(createdPrepWorkflowRecordUri)))
        .then(() => {
          dispatch(Actions.Api.nautilus[API_RESOURCES.PREP_TASK_RECORD].list(
            { prep_workflow_record: createdPrepWorkflowRecordUri },
            { limit: PAGINATION_IGNORE_DEFAULT_LIMIT },
          ));
        });
    });
  };

  const onLineItemStatusUpdate = useCallback((currentLineItemUri, status) =>
    dispatch(Actions.Api.nautilus[API_RESOURCES.LINE_ITEM].put(
      extractUuid(currentLineItemUri),
      { status },
    )), [dispatch]);
  const onUserSettingsUpdate = useCallback(updatedUserSettings =>
    dispatch(Actions.Preferences.setUserSettings(updatedUserSettings)), [dispatch]);
  const loadPrepTaskRecordsForPrepWorkflowRecord = useCallback((prepWorkflowUri, prepWorkflowRecordUri) => {
    dispatch(Actions.Api.nautilus[API_RESOURCES.PREP_WORKFLOW].get(extractUuid(prepWorkflowUri)));
    dispatch(Actions.Api.nautilus[API_RESOURCES.PREP_TASK_RECORD].list(
      { prep_workflow_record: prepWorkflowRecordUri },
      { limit: PAGINATION_IGNORE_DEFAULT_LIMIT },
    ));
  }, [dispatch]);

  return (
    <LineItemPrepWorkflowPanel
      {...props}
      {...selected}
      onLineItemPrepWorkflowsSync={onLineItemPrepWorkflowsSync}
      onPrepWorkflowStart={onPrepWorkflowStart}
      onPrepWorkflowSubmit={onPrepWorkflowSubmit}
      onLineItemStatusUpdate={onLineItemStatusUpdate}
      onUserSettingsUpdate={onUserSettingsUpdate}
      loadPrepTaskRecordsForPrepWorkflowRecord={loadPrepTaskRecordsForPrepWorkflowRecord}
    />
  );
};

LineItemPrepWorkflowPanelContainer.propTypes = {
  lineItemUri: PropTypes.string.isRequired,
};

export default LineItemPrepWorkflowPanelContainer;
