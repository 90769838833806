import _compact from 'lodash/compact';
import _isEmpty from 'lodash/isEmpty';
import _map from 'lodash/map';
import { NEW_ORDER_FORM_CONTAINER } from 'rapidfab/constants/forms';
import { toISOStringWithoutTime } from 'rapidfab/utils/timeUtils';
import React, { useEffect, useMemo, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import Actions from 'rapidfab/actions';
import isFetchingInitial from 'rapidfab/utils/isFetchingInitial';
import * as Selectors from 'rapidfab/selectors';

import Loading from 'rapidfab/components/Loading';
import NewOrder from 'rapidfab/components/records/order/new/NewOrder';
import { getRouteURI } from 'rapidfab/utils/uriUtils';
import { ROUTES, PAGINATION_IGNORE_DEFAULT_LIMIT, API_RESOURCES } from 'rapidfab/constants';
import { useNavigate } from 'react-router-dom';

const NewOrderContainer = props => {
  const bureau = useSelector(Selectors.getBureau);
  const fetching = useSelector(state => isFetchingInitial(
    state.ui.nautilus[API_RESOURCES.MATERIAL].list,
    state.ui.nautilus[API_RESOURCES.SERVICE_PROVIDER].list,
  ));
  const orderTemplatesFetching = useSelector(state =>
    state.ui.nautilus[API_RESOURCES.ORDER].list.fetching);
  const shippings = useSelector(Selectors.getShippingsAlphabetized);
  const locations = useSelector(Selectors.getLocations);
  const orderTemplates = useSelector(Selectors.getOrderTemplatesSortedAlphabetically);

  const formattedOrderTemplates = orderTemplates.filter(Boolean)
    .map(orderTemplate => ({
      label: orderTemplate.template_name,
      value: orderTemplate.uri,
    }));

  const isRestrictedUser = useSelector(Selectors.isCurrentUserRestricted);

  const [orderTemplateUri, setOrderTemplateUri] = useState(null);
  const [initialOrderFields, setInitialOrderFields] = useState(null);
  const [orderTemplatesUsersFetched, setOrderTemplatesUsersFetched] = useState(false);

  const orderTemplatesUsers = useMemo(() =>
    _compact(_map(orderTemplates, template => template.order_owner).filter(Boolean)), [orderTemplates]);

  const selected = {
    shippings,
    locations,
    orderTemplatesFetching,
    orderTemplateUri,
    formattedOrderTemplates,
  };

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const onInitialize = currentBureau => {
    const { uri } = currentBureau;
    dispatch(Actions.Api.nautilus[API_RESOURCES.ORDER].list(
      { is_template: true },
      { limit: PAGINATION_IGNORE_DEFAULT_LIMIT },
      {},
      {},
      true,
    ));
    dispatch(Actions.Api.nautilus[API_RESOURCES.MATERIAL].list({ bureau: uri }));
    dispatch(Actions.Api.nautilus[API_RESOURCES.SERVICE_PROVIDER]
      .list({}, { limit: PAGINATION_IGNORE_DEFAULT_LIMIT }));
    dispatch(Actions.Api.nautilus[API_RESOURCES.SHIPPING].list());
    dispatch(Actions.Api.nautilus[API_RESOURCES.LOCATION].list());
  };

  const fetchOrderTemplatesUsers = () => {
    if (!_isEmpty(orderTemplatesUsers) && !orderTemplatesUsersFetched) {
      dispatch(Actions.Api.nautilus[API_RESOURCES.USERS].list(
        { uri: orderTemplatesUsers.join(',') },
        {},
        {},
        {},
        true,
      ));
      setOrderTemplatesUsersFetched(true);
    }
  };

  useEffect(() => onInitialize(bureau), []);
  useEffect(() => fetchOrderTemplatesUsers(), [orderTemplatesUsers, orderTemplatesUsersFetched]);

  const handleSetOrderTemplate = (uri, form) => {
    setOrderTemplateUri(uri);
    const selectedTemplate = orderTemplates.find(order => order.uri === uri);

    // Extract only required fields from the selected template
    const filteredTemplateValues = {};
    NEW_ORDER_FORM_CONTAINER.FIELDS.forEach(field => {
      if (selectedTemplate[field] !== undefined) {
        filteredTemplateValues[field] = selectedTemplate[field];
      }
    });

    // convert ISO date to yyyy-mm-dd for html input
    if (filteredTemplateValues.due_date) {
      const date = new Date(filteredTemplateValues.due_date);
      filteredTemplateValues.due_date = toISOStringWithoutTime(date);
    }

    // Restart the form with filtered initial values
    form.restart(filteredTemplateValues);
  };

  const handleClearForm = form => {
    setOrderTemplateUri(null);
    form.restart(initialOrderFields);
  };

  const dispatched = {
    handleSetOrderTemplate,
    handleClearForm,
    setInitialOrderFields,
  };

  // For restricted user it's another endpoint and another form
  if (!fetching && isRestrictedUser) {
    navigate(getRouteURI(ROUTES.ORDER_RESTRICTED_NEW, {}, {}, true));
  }

  return (
    <div>
      {fetching ? (
        <Loading />
      ) : (
        <div>
          <NewOrder
            {...props}
            {...selected}
            {...dispatched}
          />
        </div>
      )}
    </div>
  );
};

export default NewOrderContainer;
