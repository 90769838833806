import { createSelector } from 'reselect';
import { getPredicate } from 'rapidfab/selectors/helpers/base';
import _find from 'lodash/find';
import * as baseStateSelectors from 'rapidfab/selectors/baseStateSelectors';
import _filter from 'lodash/filter';

export const getWorkChecklistLinkingsForResource = createSelector(
  [getPredicate, baseStateSelectors.getStateUIWorkChecklistLinkingsGroups],
  (relatedURI, workChecklistLinkingsGroups) => {
    const linkingData = _find(workChecklistLinkingsGroups, ['related_uri', relatedURI]);
    return linkingData
      ? linkingData.work_checklist_linkings
      : [];
  },
);

export const getRelatedWorkChecklistLinkingsForResource = createSelector(
  [getPredicate, getWorkChecklistLinkingsForResource],
  (relatedURI, workChecklistLinkingsForResource) =>
    _filter(workChecklistLinkingsForResource, { related_uri: relatedURI }),
);
