import React from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';

import * as Selectors from 'rapidfab/selectors';

import ModelLibrariesByFiltersContainer from 'rapidfab/containers/ModelLibrariesByFiltersContainer';

const ModelLibrariesContainer = ({ handleSelect, ...restProps }) => {
  const modelLibraries = useSelector(Selectors.getModelLibraries);
  const user = useSelector(Selectors.getSession);

  return (
    <ModelLibrariesByFiltersContainer
      handleSelect={handleSelect}
      modelLibraries={modelLibraries}
      user={user}
      {...restProps}
    />
  );
};

ModelLibrariesContainer.defaultProps = {
  handleSelect: null,
};

ModelLibrariesContainer.propTypes = {
  handleSelect: PropTypes.func,
};

export default ModelLibrariesContainer;
