import React from 'react';
import { faCheck, faClose } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import PropTypes from 'prop-types';
import { API_RESOURCES, COLORS } from 'rapidfab/constants';
import { useFetchMultipleResources } from 'rapidfab/hooks';
import * as Selectors from 'rapidfab/selectors';
import { Card, Modal, Table } from 'react-bootstrap';
import { useSelector } from 'react-redux';
import ResourceReadOnlyView from '../ResourceReadOnlyView/ResourceReadOnlyView';
import ResourceReadOnlyViewRow from '../ResourceReadOnlyView/ResourceReadOnlyViewRow';

const InfoSection = ({ children, title }) => (
  <Card className="m-b">
    <Card.Header className="pd-exp inverse">
      {title}
    </Card.Header>
    <div className="card-body-wrapper">
      <Card.Body
        style={{ maxHeight: 400 }}
        className="pd-exp inverse overflow-auto"
      >
        <div className="d-flex flex-direction-column">
          {children}
        </div>
      </Card.Body>
    </div>
  </Card>
);

InfoSection.propTypes = {
  children: PropTypes.node.isRequired,
  title: PropTypes.string.isRequired,
};

const DebugModeUserInfoModal = ({
  onHide,
}) => {
  const bureau = useSelector(Selectors.getBureau);
  const features = useSelector(Selectors.getFeatures);
  const user = useSelector(Selectors.getSession);
  const bureauSettings = useSelector(Selectors.getBureauSettings);

  const { USER_SSO: userSSOData, INTEGRATION: integrationData } = useFetchMultipleResources([
    { resource: API_RESOURCES.USER_SSO, configuration: user?.username, method: 'get' },
    { resource: API_RESOURCES.INTEGRATION },
  ]);

  return (
    <Modal show>
      <Modal.Header onHide={onHide} closeButton>Bureau Data</Modal.Header>
      <Modal.Body>

        <InfoSection title="User Information">
          <ResourceReadOnlyView entity={user}>
            <ResourceReadOnlyViewRow isCopiable name="name" />
            <ResourceReadOnlyViewRow isCopiable name="username" label="Email" />
            <ResourceReadOnlyViewRow isCopiable name="uri" label="User URI" />
          </ResourceReadOnlyView>

          <ResourceReadOnlyView entity={bureau}>
            <ResourceReadOnlyViewRow isCopiable name="uri" label="Bureau URI" />
          </ResourceReadOnlyView>
        </InfoSection>

        <InfoSection
          title="Sign-In"
        >
          <ResourceReadOnlyView entity={bureauSettings}>
            <ResourceReadOnlyViewRow name="autologout_after" label="Log out automatically after (in minutes)" />
            <ResourceReadOnlyViewRow name="contactless_logon_timeout" label="User Login via QR Timeout" />
            <p><b>Is SSO User:</b>&nbsp;
              {userSSOData.responseData ? 'Yes' : 'No'}
            </p>
          </ResourceReadOnlyView>
        </InfoSection>

        {integrationData.responseData?.resources && (
          <InfoSection
            title="Integrations"
          >
            <Table
              id="integrations-table"
              className="pd-exp inverse"
            >
              <thead>
                <tr>
                  <th className="text-left">Name</th>
                  <th className="text-left">Enabled</th>
                </tr>
              </thead>
              <tbody>
                {integrationData.responseData?.resources.map(integration => {
                  const { name, enabled } = integration;
                  return (
                    <tr>
                      <td style={{ fontFamily: 'monospace' }}>{name}</td>
                      <td>{enabled ?
                        <FontAwesomeIcon icon={faCheck} color={COLORS.GREEN} /> :
                        <FontAwesomeIcon icon={faClose} color={COLORS.RED} /> }
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </Table>
          </InfoSection>
        )}

        <Card className="mt15" bg="dark">
          <Card.Header className="pd-exp inverse">Feature Flags</Card.Header>
          <div className="card-body-wrapper">
            <Card.Body
              className="pd-exp inverse overflow-auto"
              style={{ maxHeight: 400 }}
            >
              <Table
                id="feature-flags-table"
                className="pd-exp inverse"
              >
                <thead>
                  <tr>
                    <th className="text-left">Name</th>
                    <th className="text-left">Enabled</th>
                  </tr>
                </thead>
                <tbody>
                  {features.map(feature => (
                    <tr>
                      <td style={{ fontFamily: 'monospace' }}>{feature.name}</td>
                      <td>{feature.enabled ?
                        <FontAwesomeIcon icon={faCheck} color={COLORS.GREEN} /> :
                        <FontAwesomeIcon icon={faClose} color={COLORS.RED} /> }
                      </td>
                    </tr>
                  ),
                  )}
                </tbody>
              </Table>
            </Card.Body>
          </div>
        </Card>
      </Modal.Body>
    </Modal>
  );
};

export default DebugModeUserInfoModal;

DebugModeUserInfoModal.propTypes = {
  onHide: PropTypes.func.isRequired,
};
