import Constants from 'rapidfab/constants';

export function fileUploadProgress(id, percent) {
  return {
    type: Constants.FILE_LOADER_PROGRESS,
    id,
    percent,
  };
}

export function fileUploadSuccess(id) {
  return {
    type: Constants.FILE_LOADER_SUCCESS,
    id,
  };
}

export function fileUploadRequest(id, file) {
  return {
    type: Constants.FILE_LOADER_REQUEST,
    id,
    file,
  };
}

export function setFileLoaderModalState() {
  return {
    type: Constants.FILE_LOADER_SET_MODAL_STATE,
  };
}

export function clearFileLoaderState() {
  return {
    type: Constants.FILE_LOADER_CLEAR,
  };
}

export function addFileUploadError(errors) {
  return {
    type: Constants.FILE_LOADER_ADD_ERROR,
    errors,
  };
}
