import { Button, Col, FormControl, FormGroup, FormLabel, Modal, Row } from 'react-bootstrap';
import { FormattedMessage } from 'react-intl';
import { Field, Form } from 'react-final-form';
import React from 'react';
import { SUB_LOCATIONS_FIELDS_MODAL } from 'rapidfab/constants/forms';
import PropTypes from 'prop-types';
import Loading from 'rapidfab/components/Loading';
import _isNil from 'lodash/isNil';

const SubLocationsAddModal = ({ onClose, onSubmit, isAdding }) => {
  const initialValues = {
    name: null,
    description: null,
  };
  const initialFormValues = {};

  Object
    .keys(initialValues)
    .filter(key => SUB_LOCATIONS_FIELDS_MODAL.FIELDS.includes(key))
    .forEach(key => {
      initialFormValues[key] = initialValues[key];
    });

  return (
    <Modal show onHide={onClose} backdrop="static">
      <Modal.Header closeButton>
        <Modal.Title>
          <FormattedMessage id="record.subLocation.add" defaultMessage="Add Sub-Location" />
        </Modal.Title>
      </Modal.Header>
      <Form
        onSubmit={onSubmit}
        initialValues={initialValues}
        render={({ handleSubmit }) => (
          <form onSubmit={handleSubmit}>
            <Modal.Body>
              <FormGroup className="form-group" controlId="subLocationName">
                <Row>
                  <Col xs={5}>
                    <FormLabel>
                      <FormattedMessage
                        id="field.name"
                        defaultMessage="Name"
                      />: *
                    </FormLabel>
                  </Col>
                  <Col xs={7}>
                    <Field
                      name="name"
                      initialValue={initialValues.name}
                      render={props => (
                        <FormControl required {...props.input} />
                      )}
                    />
                  </Col>
                </Row>
              </FormGroup>

              <FormGroup className="form-group" controlId="subLocationDescription">
                <Row>
                  <Col xs={5}>
                    <FormLabel>
                      <FormattedMessage
                        id="field.description"
                        defaultMessage="Description"
                      />:
                    </FormLabel>
                  </Col>
                  <Col xs={7}>
                    <Field
                      name="description"
                      parse={value => (_isNil(value) ? null : value)}
                      initialValue={initialValues.description}
                      render={props => (
                        <FormControl as="textarea" {...props.input} />
                      )}
                    />
                  </Col>
                </Row>
              </FormGroup>

            </Modal.Body>
            <Modal.Footer>
              <Button
                onClick={onClose}
                disabled={isAdding}
                variant="danger"
              >
                <FormattedMessage
                  id="button.close"
                  defaultMessage="Close"
                />
              </Button>
              <Button
                type="submit"
                disabled={isAdding}
                variant="primary"
              >
                {isAdding && <Loading inline className="spacer-right" />}
                <FormattedMessage
                  id="button.add"
                  defaultMessage="Add"
                />
              </Button>
            </Modal.Footer>
          </form>
        )}
      />
    </Modal>
  );
};

SubLocationsAddModal.defaultProps = {
  input: {},
};

SubLocationsAddModal.propTypes = {
  onClose: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  isAdding: PropTypes.bool.isRequired,
  input: PropTypes.shape({}),
};

export default SubLocationsAddModal;
