import React from 'react';
import PropTypes from 'prop-types';
import { FormControl } from 'react-bootstrap';

const AVAILABLE_LIMITS = [10, 25, 50, 100];

const Limit = ({
  limit,
  onLimitChange,
  availableLimits,
}) => {
  const onLimitSelect = newLimit => {
    onLimitChange(newLimit);
  };

  return (
    <FormControl
      as="select"
      value={limit}
      onChange={event => onLimitSelect(event.target.value)}
    >
      {availableLimits.map(limitValue =>
        <option key={limitValue} value={limitValue}>{limitValue} per page</option>,
      )}
    </FormControl>
  );
};

Limit.defaultProps = {
  availableLimits: AVAILABLE_LIMITS,
};

Limit.propTypes = {
  limit: PropTypes.number.isRequired,
  onLimitChange: PropTypes.func.isRequired,
  availableLimits: PropTypes.arrayOf(PropTypes.number),
};

export default Limit;
