export const PRINT_TYPES = {
  PRODUCT: 'product',
  SPECIMEN: 'specimen',
  WASTE: 'waste',
  TOOLING: 'tooling',
};

export const MODEL_TYPES = {
  STL: 'stl',
};

export const DESIGN_FILE_TYPES = {
  SINGLE_MESH: 'single-mesh',
  PRODUCT: 'product',
};

export const MODAL_TEMPLATES = {
  CANCEL_OR_DELETE: 'cancel-or-delete',
  YES_OR_NO: 'yes-or-no',
  UPLOAD_FILE: 'upload-file',
  TEXT_ENTRY: 'text-entry',
};

export const DDW_PAYMENT_SYSTEM_TYPE = {
  MANUAL: 'manual',
  STRIPE: 'stripe',
};

export const IDENTITY_PROVIDER_TYPE = {
  SAML: 'SAML',
  LDAPS: 'LDAPS',
};
