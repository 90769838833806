import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { getAddiguruAnalysisLinkedToRun } from 'rapidfab/selectors/addiguru';
import { Badge, ListGroup, ListGroupItem, OverlayTrigger, Tooltip } from 'react-bootstrap';
import Actions from 'rapidfab/actions';
import { API_RESOURCES, ROUTES } from 'rapidfab/constants';
import { Link } from 'react-router-dom';
import { getRouteURI } from 'rapidfab/utils/uriUtils';
import { extractUuid } from 'rapidfab/utils/uuidUtils';

const PieceAddiguruLink = ({ runUri }) => {
  const addiguruAnalysis = useSelector(state => getAddiguruAnalysisLinkedToRun(state, runUri));
  const dispatch = useDispatch();

  // Fetch analysis
  useEffect(() => {
    dispatch(Actions.Api.nautilus[API_RESOURCES.ADDIGURU_ANALYSIS].list({ run: runUri }));
  }, [runUri]);

  if (!addiguruAnalysis) return null;

  return (
    <ListGroup className="mb-2">
      <ListGroupItem>
        <span>Addiguru Analysis:</span>
        <div className="pull-right d-flex flex-row gap-2">
          <OverlayTrigger
            placement="top"
            overlay={(
              <Tooltip id="anomaliesTooltip">
                Anomalies
              </Tooltip>
            )}
          >
            <Badge bg="warning">{addiguruAnalysis.anomalies}</Badge>
          </OverlayTrigger>

          <OverlayTrigger
            placement="top"
            overlay={(
              <Tooltip id="defectsTooltip">
                Defects
              </Tooltip>
            )}
          >
            <Badge bg="danger">{addiguruAnalysis.defects}</Badge>
          </OverlayTrigger>
          <Link to={getRouteURI(ROUTES.RUN_EDIT, { uuid: extractUuid(runUri) }, { section: 'addiguru' }, true)}>Details</Link>

        </div>
      </ListGroupItem>
    </ListGroup>
  );
};

PieceAddiguruLink.propTypes = {
  runUri: PropTypes.string.isRequired,
};

export default PieceAddiguruLink;
