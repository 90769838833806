import { faBan } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import PropTypes from 'prop-types';
import BreadcrumbNav from 'rapidfab/components/BreadcrumbNav';
import ConfirmationModal from 'rapidfab/components/ConfirmationModal';
import FormRow from 'rapidfab/components/FormRow';
import Loading from 'rapidfab/components/Loading';
import SaveButtonTitle from 'rapidfab/components/SaveButtonTitle';
import NewOrderFormContainer from 'rapidfab/containers/records/order/NewOrderFormContainer';
import { getShortUUID } from 'rapidfab/utils/uuidUtils';
import React from 'react';
import {
  Col,
  Row,
  Container,
  SplitButton,
  Dropdown,
  ButtonToolbar,
  FormControl,
} from 'react-bootstrap';
import { Field, Form } from 'react-final-form';
import { FormattedMessage } from 'react-intl';

const OrderTemplate = ({
  initialFormValues,
  customOrderFieldReferences,
  onSave,
  onDelete,
  order,
  onCustomFieldChange,
  initCustomFieldValues,
  isOrderSubmitting,
  confirmDeleteTemplate,
  setConfirmDeleteTemplate,
  templateDeleting,
}) => (
  <Container fluid>
    <BreadcrumbNav
      breadcrumbs={[
        'organize',
        'templates',
        'orderTemplates',
        initialFormValues?.uuid
          ? `${initialFormValues?.template_name} (${getShortUUID(initialFormValues?.uuid)})`
          : 'New',
      ]}
    />
    <Form
      initialValues={initialFormValues}
      mutators={{
        initCustomFieldValues,
        onCustomFieldChange,
      }}
      onSubmit={onSave}
      render={({ handleSubmit, form, values }) => (
        <form id="order_template" onSubmit={handleSubmit}>
          <div className="clearfix">
            <ButtonToolbar className="pull-right">
              <SplitButton
                form="order_template"
                id="uxSaveDropdown"
                type="submit"
                variant="success"
                size="sm"
                disabled={isOrderSubmitting}
                title={isOrderSubmitting ? <Loading /> : <SaveButtonTitle />}
                pullRight
              >
                <Dropdown.Item
                  eventKey={1}
                  onClick={() => setConfirmDeleteTemplate(true)}
                  disabled={!order?.uri}
                >
                  <FontAwesomeIcon icon={faBan} />{' '}
                  <FormattedMessage
                    id="button.delete"
                    defaultMessage="Delete"
                  />
                </Dropdown.Item>
              </SplitButton>
            </ButtonToolbar>
          </div>

          <hr />

          <Row className="m-b p-a">
            <Col xs={12} md={12}>
              <FormRow
                id="field.templateName"
                className="mb20"
                defaultMessage="Template Name"
                isRequired
                data-cy="order-form-template-name-text-field"
              >
                <Field
                  name="template_name"
                  initialValue={initialFormValues?.template_name}
                  render={props => (
                    <FormControl
                      className="mb20"
                      value={props.input.value}
                      onChange={props.input.onChange}
                      type="text"
                      required
                    />
                  )}
                />
              </FormRow>
              <NewOrderFormContainer
                formValues={values}
                initialFormValues={initialFormValues}
                initCustomFieldValues={form.mutators.initCustomFieldValues}
                onCustomFieldChange={form.mutators.onCustomFieldChange}
                customOrderFieldReferences={customOrderFieldReferences}
              />
            </Col>
          </Row>
        </form>
      )}
    />

    {confirmDeleteTemplate && (
      <ConfirmationModal
        handleCancel={() => setConfirmDeleteTemplate(false)}
        handleConfirm={() => onDelete(order.uuid)}
        swapButtons
        customConfirmVariant="primary"
        customCancelVariant="default"
        confirmButtonContent={(
          <>
            {templateDeleting && <Loading className="spacer-right" inline />}
            <FormattedMessage id="button.confirm" defaultMessage="Confirm" />
          </>
        )}
        message="Are you sure you want to delete this template?"
      />
    )}

  </Container>
);

OrderTemplate.propTypes = {
  initialFormValues: PropTypes.shape({
    uuid: PropTypes.string,
    template_name: PropTypes.string,
  }).isRequired,
  onSave: PropTypes.func.isRequired,
  customOrderFieldReferences: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  initCustomFieldValues: PropTypes.func.isRequired,
  onCustomFieldChange: PropTypes.func.isRequired,
  input: PropTypes.shape({
    value: PropTypes.string,
    onChange: PropTypes.func,
  }).isRequired,
  order: PropTypes.shape({
    uri: PropTypes.string,
    uuid: PropTypes.string,
  }).isRequired,
  isOrderSubmitting: PropTypes.bool.isRequired,
  confirmDeleteTemplate: PropTypes.bool.isRequired,
  setConfirmDeleteTemplate: PropTypes.func.isRequired,
  onDelete: PropTypes.func.isRequired,
  templateDeleting: PropTypes.bool.isRequired,

};

export default OrderTemplate;
