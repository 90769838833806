import React from 'react';
import PropTypes from 'prop-types';
import 'rapidfab/styles/componentStyles/modelLibraryCard.scss';
import { modelLibraryType } from 'rapidfab/types';
import { Badge } from 'react-bootstrap';
import _pick from 'lodash/pick';
import _keys from 'lodash/keys';
import _values from 'lodash/values';

const BuildsLibrariesGrid = ({
  buildLibraries,
  buildFilesByUri,
  handleSelect,
  piecesByUri,
  isDebugModeEnabled,
}) => (
  <div className="card-list mb30">
    {buildLibraries.map(buildLibrary => {
      const buildFile = buildFilesByUri[buildLibrary.build_file_template];
      const pieces = _pick(piecesByUri, buildLibrary.pieces);
      return (
        <div
          className="card border border-warning"
          key={buildLibrary.uri}
          role="button"
          tabIndex={0}
        >
          { /* eslint-disable-next-line jsx-a11y/interactive-supports-focus */ }
          <div role="button" onClick={() => handleSelect(buildLibrary)}>
            <img
              className="card-img-top"
              src={buildFile?.snapshot_content}
              alt=""
            />
          </div>
          <div className="card-body">
            <div className="d-flex justify-content-between align-items-center w-full">
              <h5 className="card-title">{buildLibrary.name}</h5>
            </div>
            {isDebugModeEnabled && _keys(pieces)?.length === 0 && <Badge bg="warning">No pieces</Badge>}
            {isDebugModeEnabled && _keys(pieces)?.length > 0 && _values(pieces).every(({ status }) => status === 'cancelled')
              && <Badge className="spacer-left" bg="danger">Cancelled</Badge>}
          </div>
        </div>
      );
    })}
  </div>
);

BuildsLibrariesGrid.propTypes = {
  buildLibraries: PropTypes.arrayOf(modelLibraryType).isRequired,
  buildFilesByUri: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  handleSelect: PropTypes.func.isRequired,
  piecesByUri: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  isDebugModeEnabled: PropTypes.bool.isRequired,
};

export default BuildsLibrariesGrid;
