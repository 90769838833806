import { API_RESOURCES, PAGINATION_IGNORE_DEFAULT_LIMIT } from 'rapidfab/constants';
import Actions from 'rapidfab/actions';

/**
 * @description Get fetched material-batch-sensor-data
 * @param {Function} dispatch
 * @param {string} materialBatchUri
 * @param {number} limit
 * @param {number} offset
 * @param {boolean} clearCacheOnChange
 * @returns {object[]}
 */
// eslint-disable-next-line import/prefer-default-export
export const handleInitializeMaterialBatchSensorData = (
  dispatch,
  materialBatchUri,
  limit = 24,
  offset = 0,
  clearCacheOnChange = false,
) => {
  const sanitizeLimitValue = limit => {
    // Limit can't be negative
    if (limit < 0) {
      return 24;
    }

    if (limit > PAGINATION_IGNORE_DEFAULT_LIMIT) {
      return PAGINATION_IGNORE_DEFAULT_LIMIT;
    }

    return limit;
  };

  if (limit && clearCacheOnChange) {
    dispatch(Actions.Api.nautilus[API_RESOURCES.MATERIAL_BATCH_SENSOR_DATA].clear());
  }

  return dispatch(Actions.Api.nautilus[API_RESOURCES.MATERIAL_BATCH_SENSOR_DATA]
    .list({ material_batch: materialBatchUri }, { limit: sanitizeLimitValue(limit), offset }));
};
