import React from 'react';

const Warning = props => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 -2.5 40 40"
    {...props}
  >
    <g fill="none" fillRule="evenodd" stroke="none" strokeWidth="1">
      <g fillRule="nonzero" transform="translate(-521 -789)">
        <g transform="translate(521.218 789.018)">
          <path
            fill="#FD9726"
            d="M39.182 30.482l-16.8-29c-.8-1.4-2.7-1.9-4.1-1.1-.5.3-.8.6-1.1 1.1l-16.8 29c-.8 1.4-.3 3.3 1.1 4.1.5.3 1 .4 1.5.4h33.6c1.7 0 3-1.4 3-3 0-.5-.1-1-.4-1.5z"
          />
          <path
            fill="#000"
            d="M21.282 25.982v3h-3v-3h3zm0-13v10h-3v-10h3z"
          />
        </g>
      </g>
    </g>
  </svg>
);

export default Warning;
