const utcOffSet = {
  'UTC-12': -12,
  'UTC-11': -11,
  'UTC-10': -10,
  'UTC-9:30': -9.5,
  'UTC-9': -9,
  'UTC-8': -8,
  'UTC-7': -7,
  'UTC-6': -6,
  'UTC-5': -5,
  'UTC-4': -4,
  'UTC-3:30': -3.5,
  'UTC-3': -3,
  'UTC-2': -2,
  'UTC-1': -1,
  'UTC+0': 0,
  'UTC+1': 1,
  'UTC+2': 2,
  'UTC+3': 3,
  'UTC+3:30': 3.5,
  'UTC+4': 4,
  'UTC+4:30': 4.5,
  'UTC+5': 5,
  'UTC+5:30': 5.5,
  'UTC+5:45': 5.75,
  'UTC+6': 6,
  'UTC+6:30': 6.5,
  'UTC+7': 7,
  'UTC+8': 8,
  'UTC+8:45': 8.75,
  'UTC+9': 9,
  'UTC+9:30': 9.5,
  'UTC+10': 10,
  'UTC+10:30': 10.5,
  'UTC+11': 11,
  'UTC+12': 12,
  'UTC+13': 13,
  'UTC+13:45': 13.75,
  'UTC+14': 14,
};

export default utcOffSet;
