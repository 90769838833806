import React from 'react';
import PropTypes from 'prop-types';
import LineItemPowderWorkflow from 'rapidfab/components/records/order/edit/LineItemPowderWorkflow';
import { designFileResourceType } from 'rapidfab/types';
import LineItem from './LineItem';

const LineItems = ({
  lineItems,
  readOnly,
  expandMode,
  setExpandedItems,
  expandedItems,
  setExpandMode,
  productIndex,
  isPowderWorkflowFeatureEnabled,
  designFile,
}) => (
  <div>
    {lineItems.map(lineItem => (
      isPowderWorkflowFeatureEnabled ? (
        <LineItemPowderWorkflow
          key={lineItem}
          formKey={lineItem}
          uri={lineItem}
          readOnly={readOnly}
          expandMode={expandMode}
          setExpandedItems={setExpandedItems}
          expandedItems={expandedItems}
          setExpandMode={setExpandMode}
          productIndex={productIndex}
        />
      ) : (
        <LineItem
          key={lineItem}
          formKey={lineItem}
          uri={lineItem}
          readOnly={readOnly}
          expandMode={expandMode}
          setExpandedItems={setExpandedItems}
          expandedItems={expandedItems}
          setExpandMode={setExpandMode}
          productIndex={productIndex}
          designFile={designFile}
        />
      )
    ),
    )}
  </div>
);

LineItems.defaultProps = {
  readOnly: false,
};

LineItems.propTypes = {
  lineItems: PropTypes.arrayOf(PropTypes.string).isRequired,
  readOnly: PropTypes.oneOfType([PropTypes.bool, PropTypes.func]),
  expandMode: PropTypes.oneOfType([PropTypes.oneOf([null]), PropTypes.string]).isRequired,
  setExpandedItems: PropTypes.func.isRequired,
  setExpandMode: PropTypes.func.isRequired,
  expandedItems: PropTypes.arrayOf(PropTypes.number).isRequired,
  productIndex: PropTypes.number.isRequired,
  isPowderWorkflowFeatureEnabled: PropTypes.bool.isRequired,
  designFile: designFileResourceType.isRequired,
};

export default LineItems;
