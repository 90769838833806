import _isEmpty from 'lodash/isEmpty';
import AddUser from 'rapidfab/components/admin/AddUser';
import { FormattedMessage } from 'rapidfab/i18n';
import Alert from 'rapidfab/utils/alert-new';
import React, { useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import PropTypes from 'prop-types';

import Actions from 'rapidfab/actions';
import * as Selectors from 'rapidfab/selectors';
import { API_RESOURCES } from 'rapidfab/constants';

const CreateNewUserContainer = ({
  user,
  csrfToken,
  handleCloseModal,
  handleNewUserInputs,
  userInputsProps,
  setUserModalMode,
  isRestrictedUser,
  setDeleteUserWarning,
}) => {
  const roles = useSelector(Selectors.getRoles);
  const bureauUri = useSelector(Selectors.getBureauUri);

  const fetching = useSelector(state =>
    state.ui.nautilus[API_RESOURCES.USERS].post.fetching ||
    state.ui.nautilus[API_RESOURCES.USERS].put.fetching);

  const isEditing = !_isEmpty(user);

  const dispatch = useDispatch();

  const handleEndEditMode = () => {
    setUserModalMode('view');
  };

  const createUser = async payload => {
    const { email } = payload;
    try {
      await dispatch(Actions.Api.nautilus[API_RESOURCES.USERS].post(payload));
      dispatch(Actions.Api.nautilus[API_RESOURCES.USERS].list());
      dispatch(Actions.Api.nautilus[API_RESOURCES.ROLE].list());
      Alert.success(<FormattedMessage id="user.created" defaultMessage="User has successfully been created" />);
      handleCloseModal();
    } catch {
      dispatch(Actions.UI.clearUIErrors());
      const userHasRoles = roles.some(({ username: nick }) => nick === email.toLowerCase());
      if (userHasRoles) {
        return;
      }
      const rolePayload = {
        username: email.toLowerCase(),
        role: 'restricted',
        csrf_token: csrfToken,
      };
      try {
        await dispatch(Actions.Api.nautilus[API_RESOURCES.ROLE].post(rolePayload));
        dispatch(Actions.Api.nautilus[API_RESOURCES.USERS].list());
        dispatch(Actions.Api.nautilus[API_RESOURCES.ROLE].list());
        Alert.success(<FormattedMessage id="user.created" defaultMessage="User has successfully been created" />);
        handleCloseModal();
      } catch (error) {
        console.error(error);
      }
      dispatch(Actions.UI.clearUIErrors());
    }
  };

  const updateUser = async payload => {
    try {
      await dispatch(Actions.Api.nautilus[API_RESOURCES.USERS].put(user.uuid, payload));
      Alert.success(<FormattedMessage id="user.updated" defaultMessage="User has been updated" />);
      handleEndEditMode();
    } catch (error) {
      console.info(error);
    }
  };

  const { name, email } = userInputsProps;

  const onFormSubmit = useCallback(event => {
    event.preventDefault();
    const updatePayload = { email: email.toLowerCase(), name };
    const createPayload = { ...updatePayload, bureau: bureauUri, login: false };

    return isEditing && user ? updateUser(updatePayload) : createUser(createPayload);
  }, [bureauUri, email, isEditing, name, user]);

  return (
    <AddUser
      onFormSubmit={onFormSubmit}
      fetching={fetching}
      isEditing={isEditing}
      handleCloseModal={handleCloseModal}
      handleNewUserInputs={handleNewUserInputs}
      userInputsProps={userInputsProps}
      setUserModalMode={setUserModalMode}
      isRestrictedUser={isRestrictedUser}
      setDeleteUserWarning={setDeleteUserWarning}
    />
  );
};

CreateNewUserContainer.defaultProps = { user: {} };

CreateNewUserContainer.propTypes = {
  user: PropTypes.shape({
    name: PropTypes.string,
    emails: PropTypes.arrayOf(PropTypes.string),
    username: PropTypes.string,
    uuid: PropTypes.string,
  }),
  csrfToken: PropTypes.string.isRequired,
  handleCloseModal: PropTypes.func.isRequired,
  handleNewUserInputs: PropTypes.func.isRequired,
  userInputsProps: PropTypes.shape({
    name: PropTypes.string,
    email: PropTypes.string,
  }).isRequired,
  setUserModalMode: PropTypes.func.isRequired,
  isRestrictedUser: PropTypes.bool.isRequired,
  setDeleteUserWarning: PropTypes.func.isRequired,
};

export default CreateNewUserContainer;
