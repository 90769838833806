import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import Actions from 'rapidfab/actions';
import {
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalTitle,
  ModalFooter,
  FormControl,
  FormGroup,
  FormLabel,
} from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { FormattedMessage } from 'rapidfab/i18n';
import { getMaterialPurchaseOrdersForMaterial } from 'rapidfab/selectors';
import { API_RESOURCES, PAGINATION_IGNORE_DEFAULT_LIMIT } from 'rapidfab/constants';
import { materialTypeResourceType } from 'rapidfab/types';
import { extractUuid } from 'rapidfab/utils/uuidUtils';
import { faLink, faPlus } from '@fortawesome/free-solid-svg-icons';

const MaterialLotLinkPOModal = ({
  onClose,
  onOpenCreateModal,
  onLinkPo,
  onInitialize,
  material,
  materialPurchaseOrders,
  lotUri,
}) => {
  const [selectedPoUri, setSelectedPoUri] = useState(null);

  useEffect(() => {
    onInitialize(material.uri);
  }, []);

  const onSaveLinkPo = () => onLinkPo(lotUri, selectedPoUri)
    .then(() => onClose());

  const isSubmitButtonDisabled = !selectedPoUri;

  return (
    <Modal show onHide={onClose} backdrop="static">
      <ModalHeader closeButton>
        <ModalTitle>
          <FormattedMessage
            id="materialLot.linkToPo"
            defaultMessage="Link to PO"
          />
        </ModalTitle>
      </ModalHeader>
      <ModalBody>
        <div className="mb15">
          <b><FormattedMessage
            id="field.material"
            defaultMessage="Material"
          />:
          </b>{' '}
          {material.name}
        </div>
        <FormGroup>
          <FormLabel>
            Existing PO Number:
          </FormLabel>
          <FormControl
            as="select"
            value={selectedPoUri}
            onChange={event => setSelectedPoUri(event.target.value)}
          >
            <option value={null}>None</option>
            {materialPurchaseOrders.map(({ external_id, planned_full_quantity, delivered_quantity, units, uri }) => (
              <option
                value={uri}
                key={uri}
              >
                {external_id} ({delivered_quantity} / {planned_full_quantity} {units} delivered)
              </option>
            ))}
          </FormControl>
        </FormGroup>
      </ModalBody>
      <ModalFooter>
        <Button
          variant="success"
          onClick={onOpenCreateModal}
        >
          <FontAwesomeIcon icon={faPlus} />{' '}
          <FormattedMessage
            id="materialLot.createNewPo"
            defaultMessage="Create New PO"
          />
        </Button>
        <Button
          variant="primary"
          className="ml15"
          onClick={onSaveLinkPo}
          disabled={isSubmitButtonDisabled}
        >
          <FontAwesomeIcon icon={faLink} />{' '}
          <FormattedMessage
            id="materialLot.useThisPo"
            defaultMessage="Use This PO"
          />
        </Button>
      </ModalFooter>
    </Modal>
  );
};

MaterialLotLinkPOModal.defaultProps = {
  materialPurchaseOrders: [],
};

MaterialLotLinkPOModal.propTypes = {
  lotUri: PropTypes.string.isRequired,
  onClose: PropTypes.func.isRequired,
  onInitialize: PropTypes.func.isRequired,
  onLinkPo: PropTypes.func.isRequired,
  onOpenCreateModal: PropTypes.func.isRequired,
  material: materialTypeResourceType.isRequired,
  materialPurchaseOrders: PropTypes.arrayOf(PropTypes.shape({})),
};

const mapStateToProps = (state, props) => {
  const materialPurchaseOrders = getMaterialPurchaseOrdersForMaterial(state, props.material);
  return {
    materialPurchaseOrders,
  };
};

const mapDispatchToProps = dispatch => ({
  onInitialize: async materialUri => {
    await dispatch(
      Actions.Api.nautilus[API_RESOURCES.MATERIAL_PURCHASE_ORDER].list(
        { material: materialUri },
        { limit: PAGINATION_IGNORE_DEFAULT_LIMIT },
      ),
    );
  },
  onLinkPo: (lotUri, materialPoUri) => dispatch(
    Actions.Api.nautilus[API_RESOURCES.MATERIAL_LOT].put(
      extractUuid(lotUri), { material_purchase_order: materialPoUri },
    ),
  ),
});

export default connect(mapStateToProps, mapDispatchToProps)(MaterialLotLinkPOModal);
