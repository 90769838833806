import dayjs from 'dayjs';
import { BATCHING_BY_NUMBER_OF_PARTS, RUN_STATUSES, RUN_UNLOCKED_MAX_PRINTS } from 'rapidfab/constants';

export function isLockedRunType(run) {
  return run.batch_type === BATCHING_BY_NUMBER_OF_PARTS;
}

export function getRunFill(run) {
  if (isLockedRunType(run) && run.prints.length < RUN_UNLOCKED_MAX_PRINTS) {
    return run.prints.length * (100 / RUN_UNLOCKED_MAX_PRINTS); // 5 prints per run
  }

  return null;
}

export default function getRunName(run) {
  const { pieces_locked, name } = run;
  if (isLockedRunType(run)) {
    if (!pieces_locked) {
      const percentFill = getRunFill(run);
      if (percentFill) {
        return `${name} (🔓${percentFill}%)`;
      }
    } else {
      return `${name} 🔒`;
    }
  }
  return name;
}

export const isRunActive = run => {
  if (!run) return false;
  return ((run.status === RUN_STATUSES.IN_PROGRESS) ||
    (run.status === RUN_STATUSES.PAUSED));
};

/*
This method is needed to find the Current Scheduled Run by the earliest estimates.start date,
also find the "next" scheduled run which is the "latest run" which should have the latest
estimates.end date.
*/

export const findScheduledRunsForResource = (resource, runs, runEstimatesKeyedByRunUri) => {
  if (!resource?.uri) {
    return { earliestRun: null, latestRunDate: null };
  }

  // Get all runs for the current resource
  const resourceRuns = runs[resource.uri];

  if (resourceRuns) {
    // Find all the scheduled runs for the current resource
    const scheduledRuns = resourceRuns
      .map(run => {
        // Get run estimates (scheduled_runs API)
        const runEstimate = runEstimatesKeyedByRunUri[run.uri];

        if (runEstimate) {
          // If we have estimates, return the run, its start and end dates in day.js format
          const startDate = dayjs(runEstimate.estimates?.start);
          const endDate = dayjs(runEstimate.estimates?.end);

          return { run, startDate, endDate };
        }
        return null;
      })
      /* Filter array if we have no estimates or the startDate is invalid
         in order to skip the rest of the logic */
      .filter(scheduledRun => scheduledRun &&
        (scheduledRun.startDate.isValid() || scheduledRun.endDate.isValid()));

    /* If we have some scheduled runs, find the earliest and latest runs
     they should not be the same runs as if the resource has only 1 run. */

    if (scheduledRuns.length) {
      const earliestRun = scheduledRuns
        // .slice() - to have a new copy of the array without modifying the original array.
        .slice()
        /* Using sort to find the earliest run by .diff()
           between the start dates and getting the first element. */
        .sort((a, b) => a.startDate.diff(b.startDate))[0].run;

      // If we have only 1 run or the earliest run is the same as the latest run -> we should skip.
      const latestRun = scheduledRuns.length === 1 ? null :
        scheduledRuns.slice().sort((a, b) => b.endDate.diff(a.endDate))[0].run;

      const latestRunDate = latestRun?.uri ?
        scheduledRuns.find(scheduledRun => scheduledRun.run.uri === latestRun.uri)?.endDate :
        null;

      const endDate = latestRunDate && latestRunDate.isValid() ? latestRunDate : null;

      return { earliestRun, latestRunDate: endDate };
    }
  }

  // Return null if we have no scheduled runs for the current resource.

  return { earliestRun: null, latestRunDate: null };
};

export const getRunProgressDetails = (run, runEstimatesKeyedByRunUri, runActuals) => {
  if (!run) {
    // If there's no run data available, return default values indicating no progress.
    return {
      percentage: 0,
      elapsedTime: null,
      remainingTime: null,
      completionTime: null,
    };
  }

  // Retrieve the estimated and actual data for this run.
  const runEstimate = runEstimatesKeyedByRunUri[run.uri];
  const actuals = runActuals[run.uri];
  const actualsStartDate = dayjs(actuals?.start_in_progress_time); // Start time of the actual run.

  // Check if the run has started and if there’s an estimated end time and valid start time.
  if ((runEstimate && actualsStartDate.isValid()) && isRunActive(run)) {
    // Retrieve the total estimated duration of the run in seconds and convert it to milliseconds.
    const runDurationSeconds = runEstimate.estimates?.time?.run_duration;
    const runDurationMs = runDurationSeconds * 1000; // Convert seconds to milliseconds.

    // Calculate the fixed completion time based on the actual start time and fixed estimated duration.
    const fixedCompletionTime = actualsStartDate.valueOf() + runDurationMs;

    // Get the current time in milliseconds to calculate progress.
    const current = Date.now();

    // Calculate the elapsed time by subtracting the start time from the current time.
    const elapsedTime = current - actualsStartDate; // Elapsed time in ms.

    // Calculate the completion percentage based on elapsed time relative to the fixed duration.
    let percentage = (elapsedTime / runDurationMs) * 100;

    // Ensure the percentage is between 0 and 100.
    if (percentage < 0) {
      percentage = 0;
    } else if (percentage > 100) {
      percentage = 100;
    } else {
      percentage = Math.round(percentage); // Round to the nearest whole number.
    }

    // Calculate the remaining time by checking the difference between fixed completion time and current time.
    const remainingTime = fixedCompletionTime > current
      ? fixedCompletionTime - current // Time left if we're not past the estimated completion.
      : 0; // No remaining time if we're past the estimated completion.

    // Convert the elapsed time to a human-readable format (e.g., "2h 15m").
    const formattedElapsedTime = dayjs.duration(elapsedTime).format('H[h] m[m]');

    // Convert remaining time to a human-readable format based on hours and minutes.
    const remainingDuration = dayjs.duration(remainingTime); // Convert remaining time in ms to duration.

    // Calculate hours including any extra days in terms of hours.
    const hoursLeft = remainingDuration.hours() + (remainingDuration.days() * 24);
    const minutesLeft = remainingDuration.minutes();

    // Format remaining time based on hours and minutes left.
    let formattedRemainingTime = '';

    if (hoursLeft > 0) {
      // If there are hours left, show hours and minutes.
      formattedRemainingTime = `About ${hoursLeft} hour${hoursLeft > 1 ? 's' : ''} and ${minutesLeft} minute${minutesLeft > 1 ? 's' : ''} left`;
    } else if (minutesLeft > 0) {
      // If there are no hours left but minutes remain, show only minutes.
      formattedRemainingTime = `About ${minutesLeft} minute${minutesLeft > 1 ? 's' : ''} left`;
    } else if (percentage === 100) {
      // If progress is at 100%, no time remaining should be shown.
      formattedRemainingTime = '';
    } else {
      // If there’s less than a minute left, indicate minimal remaining time.
      formattedRemainingTime = 'About less than a minute left';
    }

    // Format the fixed completion time as a readable date and time (e.g., "09:00 AM, 11/07").
    const formattedCompletionTime = dayjs(fixedCompletionTime).format('hh:mm A, MM/DD');

    // Return the calculated progress details: percentage complete, elapsed time, remaining time, and completion time.
    return {
      percentage,
      elapsedTime: formattedElapsedTime,
      remainingTime: formattedRemainingTime,
      completionTime: formattedCompletionTime,
    };
  }

  // If the run isn't active or estimates are missing, return default values.
  return {
    percentage: 0,
    elapsedTime: null,
    remainingTime: null,
    completionTime: null,
  };
};

export const findCurrentRunStartDate = (run, runEstimatesKeyedByRunUri) => {
  if (!run) {
    return null;
  }

  const runEstimate = runEstimatesKeyedByRunUri[run.uri];

  if (runEstimate) {
    return runEstimate.estimates?.start;
  }

  return null;
};
