import React, { useEffect } from 'react';
import DanfossDDWLayout from 'rapidfab/components/danfossDDW/DanfossDDWView';
import * as Selectors from 'rapidfab/selectors';
import { useDispatch, useSelector } from 'react-redux';
import { ROUTES, FEATURES, API_RESOURCES } from 'rapidfab/constants';
import Feature from 'rapidfab/components/Feature';
import Actions from 'rapidfab/actions';
import Alert from 'react-s-alert';
import { getSession } from 'rapidfab/selectors';

const DanfossDDWViewContainer = props => {
  const isRestrictedUser = useSelector(Selectors.isCurrentUserRestricted);
  const isStanleyXUser = useSelector(state => Selectors.isFeatureEnabled(state, FEATURES.STANLEY_X_DEPLOYMENT));
  const libraries = useSelector(state => Selectors.getLibraries(state));
  const customGroups = useSelector(state => Selectors.getCustomGroups(state));
  const systemGroups = useSelector(state => Selectors.getGroups(state));
  const groupsFetching = useSelector(state =>
    state.ui.nautilus[API_RESOURCES.GROUPS].list.fetching ||
    state.ui.nautilus[API_RESOURCES.CUSTOM_GROUP].list.fetching);
  const librariesFetching = useSelector(state => state.ui.nautilus[API_RESOURCES.LIBRARY].list.fetching);
  const [createLibraryModalShown, createAddLibraryModalShown] = React.useState(false);
  const addLibraryFetching = useSelector(state =>
    state.ui.nautilus[API_RESOURCES.LIBRARY].post.fetching);

  const user = useSelector(getSession);

  const groups = [...customGroups, ...systemGroups].filter(({ uri }) => {
    /* If Custom Libraries are already using the "Custom Group" or "System Group" as the owner,
       we should skip them in the "Select Input" so the user could not create the same library
       with the same owner (group)
     */
    const librariesUris = libraries.map(({ owner }) => owner);
    return !librariesUris.includes(uri);
  });

  /* Frontend hack to hide Custom groups which the current user is not the member of,
     it is required until the BE is not fixed for the "Permissions" to show data for
     the restricted users.
   */
  const filteredLibraries = isRestrictedUser ? libraries.filter(({ owner }) => {
    // Find the only (custom groups owner) libraries which the current user is the member of
    const customGroupsToShow = customGroups.filter(({ members }) => members.includes(user.uri)) || [];

    // If library has owner is "Custom Group"
    if (owner.includes(API_RESOURCES.CUSTOM_GROUP)) {
      return customGroupsToShow.find(({ uri }) => uri === owner);
    }

    // Return the rest of the libraries of other owners
    return owner;
  }) : libraries;

  const selected = {
    addLibraryFetching,
    createLibraryModalShown,
    createAddLibraryModalShown,
    groups,
    libraries: filteredLibraries,
    groupsFetching,
    librariesFetching,
  };

  const dispatch = useDispatch();

  const digitalDesignWarehouseHash = `#${ROUTES.DIGITAL_DESIGN_WAREHOUSE}`;

  if (window.location.hash === digitalDesignWarehouseHash ||
    window.location.hash === `${digitalDesignWarehouseHash}/`) {
    if (isRestrictedUser) {
      window.location.hash = '#/digital-design-warehouse/home';
    } else {
      window.location.hash = `${digitalDesignWarehouseHash}/my-library`;
    }
  }

  useEffect(() => {
    // Temporarily added until the "Restricted Users Hack" is removed.
    dispatch(Actions.Api.nautilus[API_RESOURCES.CUSTOM_GROUP].list());
    dispatch(Actions.Api.nautilus[API_RESOURCES.GROUPS].list());

    dispatch(Actions.Api.nautilus[API_RESOURCES.LIBRARY].list());
    dispatch(Actions.Api.nautilus[API_RESOURCES.SHOPPING_CART].list({}, {}, {}, {}, true));
  }, []);

  const showCreateLibraryModal = () => {
    /* Commented for now to implement the "Restricted Users Hack".
       Will be returned back once "Hack" is not needed, see filteredLibraries
       method above for more details.
     */
    // dispatch(Actions.Api.nautilus[API_RESOURCES.CUSTOM_GROUP].list());
    // dispatch(Actions.Api.nautilus[API_RESOURCES.GROUPS].list());
    createAddLibraryModalShown(true);
  };
  const onCloseCreateLibraryModal = () => createAddLibraryModalShown(false);

  const handleCreateLibrary = values => {
    const { owner } = values;
    // <- Hardcoded name for the current MVP, will be removed later ->
    const selectedGroup = groups.find(({ uri }) => uri === owner);
    const customLibraryName = `${selectedGroup.name} Library`;
    // <- Hardcoded name for the current MVP, will be removed later ->

    const payload = {
      owner,
      name: customLibraryName,
      // Hidden for the current MVP, will be required in the future
      // name: values.name,
    };

    dispatch(Actions.Api.nautilus[API_RESOURCES.LIBRARY].post(payload)).then(() => {
      Alert.success('Library was successfully created.');
      onCloseCreateLibraryModal();
    });
  };

  const dispatched = {
    handleCreateLibrary,
    onClose: onCloseCreateLibraryModal,
    onShow: showCreateLibraryModal,
  };

  return (
    /* 👇🏼 This is a temporary measure, routes aren't being blocked off properly. */
    <Feature featureName={isStanleyXUser ? FEATURES.STANLEY_X_DEPLOYMENT : FEATURES.DIGITAL_DESIGN_WAREHOUSE}>
      <DanfossDDWLayout
        {...props}
        {...selected}
        {...dispatched}
        restricted={isRestrictedUser}
      />
    </Feature>
  );
};

export default DanfossDDWViewContainer;
