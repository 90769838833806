import { extractUuid } from 'rapidfab/reducers/makeApiReducers';
import { API_RESOURCES, EVENT_REFERENCE_TYPES, ROUTES } from 'rapidfab/constants';
import {
  ORDER_REGION_MAP,
  ORDER_STATUS_MAP,
  ORDER_TYPE_EOS_MAP,
  ORDER_TYPE_FR_MAP,
  RUN_STATUS_MAP,
} from 'rapidfab/mappings';
import { getEndpointFromURI } from 'rapidfab/utils/uriUtils';
import _indexOf from 'lodash/indexOf';

// !!!ATTENTION!!! Use with caution. Legacy code.
// Some tests might be required before extending this list
export const EVENT_VALUE_MAPPING = {
  ...ORDER_STATUS_MAP,
  ...RUN_STATUS_MAP,
  ...ORDER_REGION_MAP,
  ...ORDER_TYPE_EOS_MAP,
  ...ORDER_TYPE_FR_MAP,
};

export const EVENT_REFERENCE_TYPE_MAPPING = {
  [EVENT_REFERENCE_TYPES.LINE_ITEM]: 'CAD File',
  [EVENT_REFERENCE_TYPES.ORDER]: 'Order',
  [EVENT_REFERENCE_TYPES.PRINT]: 'Print',
  [EVENT_REFERENCE_TYPES.RUN]: 'Run',
  [EVENT_REFERENCE_TYPES.DOCUMENT]: 'Document',
  [EVENT_REFERENCE_TYPES.PREP_WORKFLOW]: 'Prep Workflow',
  [EVENT_REFERENCE_TYPES.PREP_WORKFLOW_RECORD]: 'Prep Workflow Record',
  [EVENT_REFERENCE_TYPES.PREP_TASK_RECORD]: 'Prep Task Record',
};

export const EVENT_KEYS = {
  PREP_WORKFLOW: {
    LEVEL: 'level',
  },
  MODEL_UUID: 'model_uuid',
  NOTES: 'notes',
};

export function getEventReferenceResourceType(resourceUri) {
  return getEndpointFromURI(resourceUri).endpointName;
}

const isLineItemEvent = event => {
  const resourceType = getEventReferenceResourceType(event.reference);
  return resourceType === API_RESOURCES.LINE_ITEM;
};

// getRouteURICallback is required here as a param to resolve circular dependencies
// Current file is used in piece helpers selector
// so importing getRouteUri here directly breaks selectors
export function getEventReferenceResourceRoute(resourceUri, getRouteURICallback) {
  let href = null;

  const resourceType = getEventReferenceResourceType(resourceUri);
  const resourceUUID = extractUuid(resourceUri);
  switch (resourceType) {
    case EVENT_REFERENCE_TYPES.PREP_TASK_RECORD:
      href = getRouteURICallback(ROUTES.PREP_TASK_RECORDS, {}, { task: resourceUUID });
      break;
    case EVENT_REFERENCE_TYPES.PREP_WORKFLOW:
      // TODO: Implement handling of uuid on list page
      href = getRouteURICallback(ROUTES.PREP_WORKFLOW_LIST, {}, { uuid: resourceUUID });
      break;
    default:
      break;
  }
  return href;
}

// CAD Model Changed event is a Line-Item event only for now
export function isCADModelChangeEvent(event) {
  // Notes are in isManualNoteEvent, model_uuid is in isCADModelReplaceEvent
  const notInterestedKeys = [EVENT_KEYS.NOTES, EVENT_KEYS.MODEL_UUID];
  return isLineItemEvent(event) && !notInterestedKeys.includes(event.key);
}

// CAD Model Replaced event is a Line-Item event only for now
export function isCADModelReplaceEvent(event) {
  return isLineItemEvent(event) && event.key === EVENT_KEYS.MODEL_UUID;
}

export function isWorkChecklistChanges(event) {
  return event.key.includes('Work Checklist');
}

export function isManualNoteEvent(event) {
  return event.key === EVENT_KEYS.NOTES;
}

export function isWorkInstructionReportEvent(event) {
  const workStepEventRegex = /((required|optional) work step.*done with)|(report for (required|optional) work step).*changed/gim;
  return event.key.search(workStepEventRegex) !== -1;
}

export function isInPreparationEvent(event) {
  if (event.reference.includes('/prep-workflow-record/')) {
    return true;
  }

  if (event.reference.includes('/prep-task-record/')) {
    return true;
  }

  if (event.reference.includes('/prep-workflow/') && event.key === 'tasks_in_workflow') {
    return true;
  }

  return false;
}

export function isPrintEvent(run) {
  return (run && run.printer && !run.post_processor);
}

export function isPostProcessorEvent(run) {
  return (run && run.post_processor && !run.printer);
}

export function isPlainHeadingKeyEvent(event) {
  // For Work Checklist Changes and Work Instruction Report events
  // key contains full human-readable text
  return isWorkChecklistChanges(event) || isWorkInstructionReportEvent(event);
}

export function getCustomEventHeading(event, model) {
  const resourceType = getEventReferenceResourceType(event.reference);

  if (isCADModelReplaceEvent(event)) {
    // reversed replaced_models since most recent is first
    const replacedModelsUUIDs = model?.replaced_models?.map(uri => extractUuid(uri)) || [];
    const orderedReplacedModelsArray = replacedModelsUUIDs.slice().reverse();
    // index of the previous event's placement in orderedReplacedModelsArray
    const indexOfPreviousValue = _indexOf(orderedReplacedModelsArray, event.previous_value);
    // add 2 because indexOf current value is indexOfPreviousValue + 1
    const versionNumber = indexOfPreviousValue + 2;
    return `CAD Model File ${(versionNumber === 1) ? 'Set' : 'Changed'} - Version ${versionNumber}`;
  }

  if (
    resourceType === EVENT_REFERENCE_TYPES.PREP_WORKFLOW_RECORD
    && event.key === EVENT_KEYS.PREP_WORKFLOW.LEVEL
  ) {
    return 'Prep Workflow Started';
  }

  return null;
}

export function getCustomEventContent(event) {
  const resourceType = getEventReferenceResourceType(event.reference);

  if (
    resourceType === EVENT_REFERENCE_TYPES.PREP_WORKFLOW_RECORD
    && event.key === EVENT_KEYS.PREP_WORKFLOW.LEVEL
  ) {
    return `Lock for all Prep Workflows in order is ${event.current_value === 'order' ? 'Enabled' : 'Disabled'}`;
  }

  return null;
}
