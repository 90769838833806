import { formatQueueBufferDuration } from 'rapidfab/utils/timeUtils';
import { createSelector } from 'reselect';
import getRunName from 'rapidfab/utils/getRunName';
import dayjs from 'dayjs';
import { COLORS, RUN_STATUSES } from 'rapidfab/constants';
import { getRunEstimatesByRunUri } from 'rapidfab/selectors/helpers/run';
import { getDowntimes } from 'rapidfab/selectors/downtimes';
import { RUN_STATUS_COLOR_MAP } from 'rapidfab/mappings';
import { getRunsForLocation } from 'rapidfab/selectors/helpers/home';
import { getRunActualsByRunUri } from 'rapidfab/selectors/runActuals';

// eslint-disable-next-line import/prefer-default-export
export const getQueueEvents = createSelector(
  [getRunsForLocation, getDowntimes, getRunEstimatesByRunUri, getRunActualsByRunUri],
  (runs, downtimes, runEstimatesByRunUri, runActualsByRunUri) => [
    // TODO Replace to something more readable on next change
    // eslint-disable-next-line unicorn/no-array-reduce
    ...runs.reduce(
      (events, run) => {
        const runEstimate = runEstimatesByRunUri[run.uri];
        const runActuals = runActualsByRunUri[run.uri];
        const start =
          (runActuals && runActuals.start_in_progress_time)
          // Manually Schedule FE functions sets `start` (and it has priority over Run Estimates)
          || run?.start
          || (runEstimate && runEstimate.estimates?.start);
        const end =
          (runActuals && runActuals.end_in_progress_time)
          // Manually Schedule FE functions sets `finish` (and it has priority over Run Estimates.e)
          || run?.finish
          || (runEstimate && runEstimate.estimates?.end);

        let runColor = RUN_STATUS_COLOR_MAP[run.status];
        const runBorderColor = run.pieces_locked ? COLORS.ORANGE : RUN_STATUS_COLOR_MAP[run.status];

        if (!start || !end) {
          // No need to proceed, since there is no start and end date. Nothing to add to list
          return events;
        }

        const result = [
          ...events,
          {
            id: run.uri,
            resourceId: run.printer || run.post_processor,
            title: getRunName(run),
            url: `#/records/run/${run.uuid}`,
            start: (runEstimate && runEstimate.estimates.time.pre_run_duration)
              // Start time includes `pre_run_duration`. Exclude it from the run duration
              ? dayjs(start).add(runEstimate.estimates.time.pre_run_duration, 'second')
              : dayjs(start),
            end: (runEstimate && runEstimate.estimates.time.post_run_duration)
              // End time includes `post_run_duration`. Exclude it from the run duration
              ? dayjs(end).subtract(runEstimate.estimates.time.post_run_duration, 'second')
              : dayjs(end),
            backgroundColor: runColor,
            borderColor: runBorderColor,
          },
        ];
        if (runEstimate) {
          // eslint-disable-next-line camelcase
          if (run.pieces_locked && (runEstimate.estimates.time.pre_run_duration > 0 ||
              runEstimate.estimates.time.post_run_duration > 0)) {
            runColor = COLORS.PURPLE;
          }
          const buffersAllowedStatuses = new Set([
            RUN_STATUSES.QUEUED,
            RUN_STATUSES.QUEUED_READY,
            RUN_STATUSES.IN_PROGRESS,
          ]);

          if (runEstimate.estimates.time.pre_run_duration > 0 && buffersAllowedStatuses.has(run.status)) {
            const bufferStart = formatQueueBufferDuration(runEstimate.estimates.time.pre_run_duration);
            result.push(
              {
                id: `${run.uri}buffer${Math.random() * runEstimate.estimates.time.pre_run_duration}`,
                resourceId: run.printer || run.post_processor,
                tooltip: `Pre-Run Buffer -  ${bufferStart}`,
                title: '',
                url: `#/records/run/${run.uuid}`,
                start: dayjs(start),
                // Pre-printing end time is run start + pre-printing
                end: dayjs(start).add(runEstimate.estimates.time.pre_run_duration, 'second'),
                backgroundColor: COLORS.PURPLE,
                borderColor: COLORS.PURPLE,
              },
            );
          }
          if (runEstimate.estimates.time.post_run_duration > 0 && buffersAllowedStatuses.has(run.status)) {
            const bufferEnd = formatQueueBufferDuration(runEstimate.estimates.time.post_run_duration);
            result.push(
              {
                id: `${run.uri}buffer${Math.random() * runEstimate.estimates.time.post_run_duration}`,
                resourceId: run.printer || run.post_processor,
                tooltip: `Post-Run Buffer - ${bufferEnd}`,
                title: '',
                url: `#/records/run/${run.uuid}`,
                // Post-printing start time is run end - post-printing
                start: dayjs(end).subtract(runEstimate.estimates.time.post_run_duration, 'second'),
                end: dayjs(end),
                backgroundColor: COLORS.PURPLE,
                borderColor: COLORS.PURPLE,
              },
            );
          }
        }
        return result;
      },
      [],
    ),
    ...downtimes.map(downtime => ({
      id: downtime.uri,
      resourceId:
        downtime.printer || downtime.post_processor || downtime.shipping,
      title: downtime.description,
      start: dayjs(downtime.start),
      end: dayjs(downtime.finish),
      backgroundColor: '#428092',
      borderColor: '#428092',
    })),
  ],
);
