import React, { useEffect } from 'react';
import withRecordsListHandling from 'rapidfab/containers/hocs/withRecordsListHandling';
import * as Selectors from 'rapidfab/selectors';
import { useDispatch, useSelector } from 'react-redux';
import Actions from 'rapidfab/actions';
import { API_RESOURCES } from 'rapidfab/constants';
import SecureFileCheckouts from 'rapidfab/components/admin/SecureFileCheckouts';
import { FormattedMessage } from 'react-intl';
import Alert from 'rapidfab/utils/alert';

const SecureFileCheckoutsContainer = props => {
  const dispatch = useDispatch();

  const isSaving = useSelector(state =>
    state.ui.nautilus[API_RESOURCES.SECURE_FILE_CHECKOUT].post.fetching,
  );

  const secureFileCheckouts = useSelector(Selectors.getSecureFileCheckouts);
  const secureFileCheckoutsByUri = useSelector(Selectors.getSecureFileCheckoutsByUri);

  const handleCheckIn = async uri => {
    const secureFileCheckout = secureFileCheckoutsByUri[uri];
    if (!secureFileCheckout) return;

    const payload = {
      uri,
      secure_checkout_directory: secureFileCheckout.secure_checkout_directory,
      related_table_name: secureFileCheckout.related_table_name,
      resource_uuid: secureFileCheckout.resource_uuid,
      force_check_in: true,
    };
    try {
      await dispatch(Actions.Api.nautilus[API_RESOURCES.SECURE_FILE_CHECKOUT].post(payload, { skipSanitize: true }));
      Alert.success(
        <FormattedMessage
          id="toaster.secureFileCheckout.checkInStarted"
          defaultMessage="Secure file check in has been successfully started."
        />,
      );
    } catch (error) {
      Alert.error(error);
    }
  };

  const selected = {
    isSaving,
    secureFileCheckouts,
  };

  const dispatched = { handleCheckIn };

  const onInitialize = () => {
    dispatch(Actions.Api.nautilus[API_RESOURCES.SECURE_FILE_CHECKOUT].list());
  };

  useEffect(() => onInitialize(), []);

  return (
    <SecureFileCheckouts
      {...props}
      {...selected}
      {...dispatched}
    />
  );
};

export default withRecordsListHandling(
  SecureFileCheckoutsContainer,
  Selectors.getSecureFileCheckouts,
  [API_RESOURCES.SECURE_FILE_CHECKOUT],
  {
    defaultSort: null,
    searchBy: 'common_name',
    useLocationFiltering: false,
  },
);
