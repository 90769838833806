import { createSelector } from 'reselect';
import * as baseStateSelectors from 'rapidfab/selectors/baseStateSelectors';

// eslint-disable-next-line import/prefer-default-export
export const getSession = createSelector(
  [baseStateSelectors.getStateUISession],
  session => {
    if (!session) {
      return null;
    }

    return session;
  },
);
