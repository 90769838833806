import { routeURIContains } from 'rapidfab/utils/uriUtils';
import CustomLibraryContainer from 'rapidfab/containers/hawking/CustomLibraryContainer';
import React from 'react';
import PropTypes from 'prop-types';
import NotFound from 'rapidfab/components/404';

const DanfossDDWCustomLibrary = ({ customLibrary }) => {
  if (customLibrary && routeURIContains(customLibrary.name)) {
    return (
      <CustomLibraryContainer
        ownerUri={customLibrary.owner}
        customLibraryName={customLibrary.name}
      />
    );
  }
  return <NotFound />;
};

DanfossDDWCustomLibrary.propTypes = {
  customLibrary: PropTypes.shape({
    name: PropTypes.string,
    owner: PropTypes.string,
  }).isRequired,
};

export default DanfossDDWCustomLibrary;
