/**
 * @param features: []|{}
 *   Route.features can be an array of feature names to be enabled
 *   or an object with key-value
 *     where key is feature name
 *     and value is boolean whether the feature needs to be enabled or disabled
 *
 * @returns {} Key-value pair of feature name and boolean
 */
// eslint-disable-next-line import/prefer-default-export
export const convertRouteFeaturesToObject = features => {
  if (!Array.isArray(features)) {
    return features;
  }
  const featuresEntries = features.map(
    // For array-like config - all provided features need to be enabled
    featureName => [featureName, true],
  );
  return Object.fromEntries(featuresEntries);
};
