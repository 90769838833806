import _find from 'lodash/find';
import { SERVICE_PROVIDER_TYPE } from 'rapidfab/constants';
import { createSelector } from 'reselect';
import { getStateServiceProviders } from 'rapidfab/selectors/baseStateSelectors';
import { getPredicate, getStateResources } from 'rapidfab/selectors/helpers/base';
import _map from 'lodash/map';
import _keyBy from 'lodash/keyBy';
import _filter from 'lodash/filter';

export const getAllServiceProviders = createSelector(
  [getStateServiceProviders, getStateResources],
  (uuids, resources) => _map(uuids, uuid => resources[uuid]),
);

export const getServiceProviders = createSelector(
  [getAllServiceProviders],
  serviceProviders => _filter(serviceProviders, { service_provider_type: SERVICE_PROVIDER_TYPE.WORK }),
);

export const getVendorServiceProviders = createSelector(
  [getAllServiceProviders],
  serviceProviders => _filter(serviceProviders, { service_provider_type: SERVICE_PROVIDER_TYPE.VENDOR }),
);

export const getVendorServiceProvidersByUri = createSelector(
  [getVendorServiceProviders],
  vendorServiceProviders => _keyBy(vendorServiceProviders, 'uri'),
);

export const getVendorServiceProviderFromToolingTypeByUri = createSelector(
  [getPredicate, getVendorServiceProviders],
  (serviceProviderUri, vendorServiceProviders) =>
    _find(vendorServiceProviders, ({ uri }) => uri === serviceProviderUri),
);

export const getServiceProvidersByUri = createSelector(
  [getServiceProviders],
  serviceProviders => _keyBy(serviceProviders, 'uri'),
);
