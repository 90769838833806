// eslint-disable-next-line import/prefer-default-export

const renderOptionBackgroundColor = (isSelected, isFocused) => {
  if (isSelected) {
    return '#19a8dd';
  }
  if (isFocused) {
    return '#495057';
  }
  return '#343a40';
};
const renderOptionBackgroundColorLightMode = (isSelected, isFocused) => {
  if (isSelected) {
    return '#19a8dd';
  }
  if (isFocused) {
    return '#adb5bd';
  }
  return '#fff';
};

// eslint-disable-next-line import/prefer-default-export
export const selectInputStyles = {
  input: provided => ({
    ...provided,
    color: '#fff',
    '& input': {
      color: 'inherit',
    },
    fontWeight: 300,
  }),
  container: provided => ({
    ...provided,
    width: '100%',
  }),
  control: (provided, { isFocused, isDisabled }) => ({
    ...provided,
    backgroundColor: isDisabled ? '#353843' : '#434857',
    borderColor: '#434857',
    borderRadius: '4px',
    minHeight: '35px',
    maxHeight: '35px',
    cursor: isDisabled ? 'not-allowed' : 'pointer',
    '&:hover': !isDisabled && { borderColor: '#495057' },
    boxShadow: isFocused && !isDisabled ? '0 0 0 0.25rem rgba(51, 122, 183, 0.25)' : 'none',
    color: isDisabled ? '#adb5bd' : '#fff',
    overflow: 'hidden',
  }),
  singleValue: provided => ({
    ...provided,
    color: '#fff',
    fontWeight: 300,

  }),
  placeholder: provided => ({
    ...provided,
    color: '#fff',
    fontWeight: 300,
  }),
  menu: provided => ({
    ...provided,
    backgroundColor: '#343a40',
    color: '#fff',
    zIndex: 999,
    fontWeight: 300,
  }),
  option: (provided, state) => ({
    ...provided,
    backgroundColor: renderOptionBackgroundColor(state.isSelected, state.isFocused),
    color: state.isSelected ? '#fff' : provided.color,
    fontWeight: 300,
  }),
  dropdownIndicator: provided => ({
    ...provided,
    padding: '6px',
    color: '#c9c9c9',
    '&:hover': {
      color: '#fff',
    },
  }),
  clearIndicator: provided => ({
    ...provided,
    padding: '6px',
    color: '#c9c9c9',
    '&:hover': {
      color: '#fff',
    },
  }),
};
export const selectInputStylesLightMode = {
  input: provided => ({
    ...provided,
    color: '#343a40',
    '& input': {
      color: 'inherit',
    },
  }),
  container: provided => ({
    ...provided,
    width: '100%',
  }),
  control: (provided, { isFocused, isDisabled }) => ({
    ...provided,
    backgroundColor: isDisabled ? '#fff' : '#fff',
    borderColor: 'rgba(0, 0, 0, 0.2)',
    borderRadius: '4px',
    minHeight: '35px',
    maxHeight: '35px',
    cursor: isDisabled ? 'not-allowed' : 'pointer',
    // '&:hover': !isDisabled && { borderColor: '#495057' },
    boxShadow: isFocused && !isDisabled ? '0 0 0 0.25rem rgba(51, 122, 183, 0.25)' : 'none',
    color: isDisabled ? '#adb5bd' : '#fff',
    overflow: 'hidden',
  }),
  singleValue: provided => ({
    ...provided,
    color: '#343a40',
  }),
  placeholder: provided => ({
    ...provided,
    color: '#343a40',
  }),
  menu: provided => ({
    ...provided,
    backgroundColor: '#fff',
    color: '#343a40',
    zIndex: 999,
  }),
  option: (provided, state) => ({
    ...provided,
    backgroundColor: renderOptionBackgroundColorLightMode(state.isSelected, state.isFocused),
    color: state.isSelected ? '#fff' : provided.color,
  }),
  dropdownIndicator: provided => ({
    ...provided,
    padding: '6px',
    color: '#495057',
    '&:hover': {
      color: '#fff',
    },
  }),
  clearIndicator: provided => ({
    ...provided,
    padding: '6px',
    color: '#c9c9c9',
    '&:hover': {
      color: '#fff',
    },
  }),
};

export const selectMultiInputStyles = {
  ...selectInputStyles,
  control: (provided, { isFocused, isDisabled }) => ({
    ...provided,
    backgroundColor: isDisabled ? '#353843' : '#434857',
    borderColor: '#434857',
    borderRadius: '4px',
    minHeight: '35px',
    cursor: isDisabled ? 'not-allowed' : 'pointer',
    '&:hover': !isDisabled && { borderColor: '#495057' },
    boxShadow: isFocused && !isDisabled ? '0 0 0 0.25rem rgba(51, 122, 183, 0.25)' : 'none',
    color: isDisabled ? '#adb5bd' : '#fff',
  }),
  multiValue: provided => ({
    ...provided,
    backgroundColor: '#6a7179',
    borderRadius: '4px',
    padding: '2px',
    fontWeight: '400',
    color: '#fff',
  }),
  multiValueLabel: provided => ({
    ...provided,
    color: '#fff', // Change this to the desired color for MultiValueGeneric
  }),
};

export const resetRcTooltipInnerStyle = {
  border: 'none',
  backgroundColor: '#30343E',
  padding: 0,
};

export const rcTooltipInnerStyleList = {
  border: 'none',
  backgroundColor: '#30343E',
  color: '#fff',
  fontSize: '14px',
  borderRadius: '4px',
  boxShadow: '0 2px 4px rgba(0,0,0,0.15)',
  maxWidth: '450px',
};

export const draftJsEditorStyles = {
  editor: {
    minHeight: 150,
  },
};
