import PostProcessorNavbarLinks from 'rapidfab/components/assets/PostProcessorNavbarLinks';
import WorkstationTabs from 'rapidfab/components/assets/WorkstationTabs';
import TableWithSearching from 'rapidfab/components/Tables/TableWithSearching';
import { postProcessorTypeResourceType } from 'rapidfab/types';
import React from 'react';
import PropTypes from 'prop-types';

const PostProcessorsServicesTable = ({
  data,
  fetching,
  locations,
  locationsByUri,
  postProcessorTypes,
  isServices,
  isServiceProviderRole,
  isGroupQualificationsFeatureEnabled,
  subLocationsByUri,
  columns,
  isDebugModeEnabled,
  ...componentProps
}) => {
  const breadcrumbs = ['assets', 'workstations', isServices ? 'postProcessorServices' : 'postProcessors'];

  return (
    <TableWithSearching
      {...componentProps}
      locations={locations}
      showSubLocationsFilter
      breadcrumbs={breadcrumbs}
      navbar={(
        <PostProcessorNavbarLinks
          isServices={isServices}
          isDebugModeEnabled={isDebugModeEnabled}
          isGroupQualificationsFeatureEnabled={
            isGroupQualificationsFeatureEnabled
          }
        />
      )}
      subheader={<WorkstationTabs activeKey={`${isServices ? 'post-processor-services' : 'post-processors'}`} />}
      tableID="postProcessors"
      isFetching={fetching}
      data={data}
      columns={columns}
      withDefaultPagination
      isFilteringEnabled
      initialSortedDesc={false}
      initialSortedColumn="name"
      isManualSoringEnabled={false}
      withBreadcrumbs
    />
  );
};

PostProcessorsServicesTable.propTypes = {
  locations: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  locationsByUri: PropTypes.objectOf(PropTypes.shape({})),
  postProcessorTypes: PropTypes.objectOf(postProcessorTypeResourceType)
    .isRequired,
  isServices: PropTypes.bool,
  isServiceProviderRole: PropTypes.bool,
  isGroupQualificationsFeatureEnabled: PropTypes.bool.isRequired,
  data: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  fetching: PropTypes.bool.isRequired,
  subLocationsByUri: PropTypes.objectOf(PropTypes.shape({})).isRequired,
  columns: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  isDebugModeEnabled: PropTypes.bool.isRequired,
};

PostProcessorsServicesTable.defaultProps = {
  isServices: false,
  // This prop is Required when isServices=true
  isServiceProviderRole: false,
  // This prop is Required when isServices=false
  locationsByUri: {},
};

export default PostProcessorsServicesTable;
