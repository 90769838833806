import React, { useState } from 'react';
import { ROUTES } from 'rapidfab/constants';
import PropTypes from 'prop-types';
import SmoothCollapse from 'react-smooth-collapse';
import { Button, Col, ListGroup, ListGroupItem, Row } from 'react-bootstrap';
import { FormattedMessage } from 'rapidfab/i18n';
import { extractUuid } from 'rapidfab/utils/uuidUtils';
import { getRouteURI } from 'rapidfab/utils/uriUtils';
import { Link } from 'react-router-dom';
import Loading from 'rapidfab/components/Loading';
import { faChevronDown, faChevronRight } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import useToolLinks from './hook';

/**
 * @component
 * @param {Object} props
 * @param {string} props.uri
 */
const PieceToolLinks = ({ uri }) => {
  const { data, isFetching } = useToolLinks(uri);
  const [collapsed, setCollapsed] = useState(true);

  return (
    <ListGroup className="mb-2">
      <ListGroupItem>
        <div className="clearfix">
          <div className="pull-left">
            <Button
              size="xs"
              variant="outline"
              onClick={() => {
                setCollapsed(!collapsed);
              }}
            >
              <FontAwesomeIcon
                icon={collapsed ? faChevronRight : faChevronDown}
              />
            </Button>
            &nbsp;
            <span>
              <FormattedMessage
                id="inventory.toolLinks"
                defaultMessage="Linked Tools"
              />
              :
            </span>
          </div>
          <span className="pull-right mx-2">
            {data?.toolLinks.length ? data.toolLinks.length : 'None'}
          </span>
        </div>
      </ListGroupItem>
      <SmoothCollapse expanded={!collapsed}>
        <ListGroupItem>
          <Row>
            <Col xs={6}>
              <b>
                <FormattedMessage
                  id="inventory.toolingStock"
                  defaultMessage="Tool"
                />
              </b>
            </Col>
            <Col xs={6}>
              <b>
                <FormattedMessage
                  id="field.tooling_stock_type"
                  defaultMessage="Tool Type"
                />
              </b>
            </Col>
          </Row>
        </ListGroupItem>

        {isFetching ? (
          <Loading />
        ) : (data?.toolLinks?.length ? (
          data?.toolLinks.map(({ uri: link, tool, type }) => (
            <ListGroupItem key={link}>
              <Row>
                <Col xs={6}>
                  <Link
                    to={getRouteURI(
                      ROUTES.TOOLING_STOCK_DETAIL,
                      { uuid: extractUuid(tool) },
                      {},
                      true,
                    )}
                    replace
                  >
                    {data?.tools[tool]?.name ?? 'Unknown Tool'}
                  </Link>
                </Col>
                <Col xs={6}>
                  {data?.toolTypes[type]?.name ?? 'Unknown Tool Type'}
                </Col>
              </Row>
            </ListGroupItem>
          ))
        ) : (
          <ListGroupItem className="text-center">
            No Tools linked to this run
          </ListGroupItem>
        ))}
      </SmoothCollapse>
    </ListGroup>
  );
};

PieceToolLinks.propTypes = { uri: PropTypes.string.isRequired };

export default PieceToolLinks;
