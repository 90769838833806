import { createSelector } from 'reselect';
import * as baseStateSelectors from 'rapidfab/selectors/baseStateSelectors';
import { getStateResources } from 'rapidfab/selectors/helpers/base';
import _map from 'lodash/map';
import _filter from 'lodash/filter';
import _keyBy from 'lodash/keyBy';
import { getPermissions } from 'rapidfab/selectors/permissions';

export const getLocations = createSelector(
  [baseStateSelectors.getStateLocations, getStateResources],
  (uuids, resources) => _map(uuids, uuid => resources[uuid]),
);

export const getLocationsByUri = createSelector([getLocations], locations =>
  _keyBy(locations, 'uri'),
);

export const getPermissionLocationUris = createSelector(
  [getPermissions],
  permissions => _map(_filter(permissions, { right: 'printer.all' }), permission => permission.object),
);
