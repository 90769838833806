import Loading from 'rapidfab/components/Loading';
import { pluralWord } from 'rapidfab/utils/stringUtils';
import React from 'react';
import { getShortUUID, extractUuid } from 'rapidfab/utils/uuidUtils';
import { getRouteURI } from 'rapidfab/utils/uriUtils';
import { PERMANENT_CONTAINER_ACTION_TYPES, ROUTES } from 'rapidfab/constants';
import PropTypes from 'prop-types';
import _find from 'lodash/find';
import { Link } from 'react-router-dom';

const PermanentContainerMetadataColumn = ({
  rowData,
  locations,
  subLocations,
  materialLotDetailsMap,
  materialLotsFetching,
}) => {
  const { action_type: actionType } = rowData;

  switch (actionType) {
    case (PERMANENT_CONTAINER_ACTION_TYPES.RELOCATE): {
      const location = _find(locations, { uri: rowData?.metadata.destination_location });
      const subLocation = _find(subLocations, { uri: rowData?.metadata.destination_sub_location });

      return (
        <>
          <p>
            Location:{' '}
            <i>
              <Link
                to={getRouteURI(ROUTES.LOCATIONS, null, { uuid: extractUuid(location?.uri) }, true)}
              >
                {location?.name} ({getShortUUID(location?.uri)})
              </Link>
            </i>
          </p>
          {' '}
          <p>
            Sub Location:{' '}
            <i>{subLocation?.name} ({getShortUUID(subLocation?.uri)})</i>
          </p>
        </>
      );
    }
    case PERMANENT_CONTAINER_ACTION_TYPES.TOP_OFF:
    case PERMANENT_CONTAINER_ACTION_TYPES.LOAD_MATERIAL:
      if (!rowData?.materials?.material_names || !rowData?.materials?.material_lots) {
        return null;
      }
      if (materialLotsFetching) {
        return <Loading inline />;
      }
      return (
        <>
          <div>
            <p className="mb5">{pluralWord('Material', rowData.materials.material_names.length)}:</p>
            <i>
              {rowData.materials.material_names.map((materialName, index) => {
                const lotUri = rowData.materials.material_lots[index];
                const lotDetails = materialLotDetailsMap.get(lotUri);
                const units = lotDetails?.units || '';
                return (
                  <p key={materialName}>
                    {materialName} ({units})
                  </p>
                );
              })}
            </i>
          </div>
        </>
      );
    case (PERMANENT_CONTAINER_ACTION_TYPES.UNLOAD_MATERIAL): {
      const notes = rowData?.notes;

      if (notes) {
        return (
          <div>
            <p className="mb5">
              Notes:
            </p>
            <i>{notes}</i>
          </div>
        );
      }

      if (rowData.metadata?.split_off_batch) {
        return (
          <span>Transferred material to split off batch{' '}
            <a href={getRouteURI(ROUTES.MATERIAL_BATCH, { uuid: rowData.metadata.split_off_batch })}>
              {getShortUUID(rowData.metadata.split_off_batch)}
            </a>
          </span>
        );
      }
      return null;
    }
    default:
      return '';
  }
};

export default PermanentContainerMetadataColumn;

PermanentContainerMetadataColumn.propTypes = {
  rowData: PropTypes.shape({
    notes: PropTypes.string,
    action_type: PropTypes.string,
    materials: PropTypes.shape({
      material_names: PropTypes.arrayOf(PropTypes.string),
      material_lots: PropTypes.arrayOf(PropTypes.string),
    }),
    quantity: PropTypes.number,
    metadata: PropTypes.shape({
      resulting_batch: PropTypes.shape({}),
      split_off_batch: PropTypes.shape({}),
      destination_location: PropTypes.string,
      destination_sub_location: PropTypes.string,
    }),
  }).isRequired,
  materialLotDetailsMap: PropTypes.instanceOf(Map).isRequired,
  locations: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  subLocations: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  materialLotsFetching: PropTypes.bool.isRequired,
};
