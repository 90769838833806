import React from 'react';
import Loading from 'rapidfab/components/Loading';
import { Button, Modal } from 'react-bootstrap';
import { Field, Form } from 'react-final-form';
import { FormattedMessage } from 'react-intl';
import FormRow from 'rapidfab/components/FormRow';
import PropTypes from 'prop-types';
import { FormControlSelect } from 'rapidfab/components/formTools';

const CreateLibraryModal = ({
  show,
  onClose,
  handleCreateLibrary,
  addLibraryFetching,
  groups,
  groupsFetching,
}) => {
  const validate = values => {
    const errors = {};
    // Hidden for the current MVP, will be required in the future
    // if (!values.name) {
    //   errors.name = 'Name is required';
    // }
    if (!values.owner) {
      errors.owner = 'Group is required';
    }
    return errors;
  };

  const renderGroupsInput = input => {
    if (!groups.length && !groupsFetching) {
      return <p className="text-danger m-0">No groups found, please create a new first.</p>;
    }

    return groupsFetching ?
      <Loading inline /> : (
        <FormControlSelect
          {...input}
        >
          <option value="">Select group</option>
          {groups.map(group => (
            <option key={group.uri} value={group.uri}>
              {group.name}
            </option>
          ))}
        </FormControlSelect>
      );
  };

  return (
    <Modal show={show}>
      <Form
        onSubmit={formValues => handleCreateLibrary(formValues)}
        validate={validate}
        render={({
          handleSubmit,
        }) => (
          <>
            <Modal.Header onHide={onClose} closeButton>
              <FormattedMessage
                id="modelLibrary.createLibrary"
                defaultMessage="Create Library"
              />
            </Modal.Header>
            <Modal.Body>
              {/* There is no need to show "Name" in MVP, hidden for the future purposes */}
              {/* <Field */}
              {/*   name="name" */}
              {/*   initialValue="" */}
              {/* > */}
              {/*   {({ input, meta }) => ( */}
              {/*     <FormRow */}
              {/*       className="d-flex align-items-center form-group" */}
              {/*       id="field.name" */}
              {/*       defaultMessage="Name" */}
              {/*     > */}
              {/*       <FormControl */}
              {/*         {...input} */}
              {/*         required */}
              {/*         type="text" */}
              {/*         placeholder="Library name" */}
              {/*       /> */}
              {/*       {meta.error && meta.touched && ( */}
              {/*         <span className="mt-1 text-danger">{meta.error}</span> */}
              {/*       )} */}
              {/*     </FormRow> */}
              {/*   )} */}
              {/* </Field> */}
              <Field
                name="owner"
                initialValue=""
              >
                {({ input, meta }) => (
                  <FormRow
                    className="d-flex align-items-center form-group"
                    id="modelLibrary.ownedByGroup"
                    defaultMessage="Owned By Group"
                  >
                    {renderGroupsInput(input)}
                    {meta.error && meta.touched && (
                      <span className="mt-1 text-danger">{meta.error}</span>
                    )}
                  </FormRow>
                )}
              </Field>
            </Modal.Body>
            <Modal.Footer>
              <Button
                bg="danger"
                variant="danger"
                onClick={onClose}
              >
                <FormattedMessage
                  id="button.cancel"
                  defaultMessage="Cancel"
                />
              </Button>
              <Button
                variant="primary"
                bg="primary"
                disabled={!groups.length || groupsFetching}
                onClick={handleSubmit}
              >
                {addLibraryFetching ?
                  <Loading /> : (
                    <FormattedMessage
                      id="button.add"
                      defaultMessage="Add"
                    />
                  )}
              </Button>
            </Modal.Footer>
          </>
        )}
      />
    </Modal>
  );
};

CreateLibraryModal.propTypes = {
  show: PropTypes.bool.isRequired,
  addLibraryFetching: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  handleCreateLibrary: PropTypes.func.isRequired,
  groups: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  groupsFetching: PropTypes.bool.isRequired,
};

export default CreateLibraryModal;
