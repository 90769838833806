import React from 'react';
import PropTypes from 'prop-types';
import { FormControl } from 'react-bootstrap';

import { Field as ReactFinalField } from 'react-final-form';

const FormControlField = ({ name, required, ...otherProps }) => (
  <ReactFinalField name={name}>
    {({ input }) => (
      <FormControl {...input} required={required} {...otherProps} />
    )}
  </ReactFinalField>
);

FormControlField.propTypes = {
  name: PropTypes.string.isRequired,
  required: PropTypes.bool,
};

FormControlField.defaultProps = {
  required: false,
};

export default FormControlField;
