import React from 'react';
import PropTypes from 'prop-types';

import { Nav, NavItem, NavLink } from 'react-bootstrap';
import { ROUTES } from 'rapidfab/constants';
import { getRouteURI } from 'rapidfab/utils/uriUtils';

const OrganizeTemplatesTabs = ({ activeKey }) => (
  <Nav
    variant="tabs"
    activeKey={activeKey}
    // `undefined` is needed to allow `href` in NavItem-s
    onSelect={undefined}
    id="organizeTemplatesTabs"
  >
    <NavItem>
      <NavLink eventKey="order-templates" href={getRouteURI(ROUTES.TEMPLATES)}>
        Orders
      </NavLink>
    </NavItem>
  </Nav>
);

OrganizeTemplatesTabs.propTypes = {
  // Line Items in the feature
  activeKey: PropTypes.oneOf(['order-templates']).isRequired,
};

export default OrganizeTemplatesTabs;
