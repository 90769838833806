import PropTypes from 'prop-types';
import {
  MODELER_STATUSES,
  MATERIAL_BATCH_CUSTOM_STATUSES,
  MATERIAL_BATCH_STATUSES,
  MATERIAL_LOT_STATUSES,
  ZVERSE_CONVERSION_STATUSES,
  MODEL_CONVERSION_STATUSES,
  Currencies,
  LINE_ITEM_STATUS,
  ORDER_STATUS,
  RUN_STATUSES,
  PIECE_STATUSES,
  BUILD_FILE_STATUSES,
  PREP_TASK_RECORD_STATUSES,
  CASTOR_COSTING_STATUSES,
  DESIGN_FILE_TYPES,
  CUSTOM_FIELD_RIGHTS,
  CUSTOM_FIELD_FORMATS,
  CUSTOM_FIELD_TYPES,
  EXTERNAL_PRINT_ANALYSIS_VENDOR,
  SECURE_FILE_CHECKOUT_STATUSES,
  EXPERT_CONVERSION_STATUSES,
} from 'rapidfab/constants';

export const materialBatchResourceType = PropTypes.shape({
  at_machine: PropTypes.string,
  containers: PropTypes.arrayOf(PropTypes.string),
  cost: PropTypes.number,
  created: PropTypes.string,
  custom_status: PropTypes.oneOf(Object.values(MATERIAL_BATCH_CUSTOM_STATUSES)),
  full_quantity: PropTypes.number,
  is_initial_batch: PropTypes.bool,
  location: PropTypes.string,
  location_name: PropTypes.string,
  material: PropTypes.string,
  material_in_containers: PropTypes.bool,
  material_lot: PropTypes.string,
  material_name: PropTypes.string,
  notes: PropTypes.string,
  quantity: PropTypes.number,
  quantity_available_for_actions: PropTypes.number,
  reserved_quantity: PropTypes.number,
  initial_batch: PropTypes.string,
  source_material_batches: PropTypes.arrayOf(PropTypes.string),
  status: PropTypes.oneOf(Object.values(MATERIAL_BATCH_STATUSES)),
  units: PropTypes.string,
  updated: PropTypes.string,
  uri: PropTypes.string,
  uuid: PropTypes.string,
  usage_cycles: PropTypes.number,
});

export const materialLotResourceType = PropTypes.shape({
  containers_available_for_initial_batches: PropTypes.arrayOf(PropTypes.string),
  cost: PropTypes.number,
  delivery_date: PropTypes.string,
  delivery_id: PropTypes.string,
  expiration_date: PropTypes.string,
  full_quantity: PropTypes.number,
  initial_batches: PropTypes.arrayOf(PropTypes.string),
  location: PropTypes.string,
  location_name: PropTypes.string,
  material: PropTypes.string,
  material_name: PropTypes.string,
  name: PropTypes.string,
  notes: PropTypes.string,
  number_of_containers: PropTypes.number,
  quantity: PropTypes.number,
  status: PropTypes.oneOf(Object.values(MATERIAL_LOT_STATUSES)),
  units: PropTypes.string,
  updated: PropTypes.string,
  uri: PropTypes.string,
  usage_cycles_limit: PropTypes.number,
});

export const materialTypeResourceType = PropTypes.shape({
  uri: PropTypes.string,
  name: PropTypes.string,
  type: PropTypes.string,
  units: PropTypes.string,
  cost: PropTypes.number,
});

export const locationResourceType = PropTypes.shape({
  address: PropTypes.string,
  bureau: PropTypes.string,
  contact: PropTypes.string,
  group: PropTypes.string,
  name: PropTypes.string,
  phone: PropTypes.string,
  uri: PropTypes.string,
  countries_served: PropTypes.arrayOf(PropTypes.string),
});

export const modelerResourceType = PropTypes.shape({
  connected: PropTypes.bool,
  cassettes: PropTypes.arrayOf(PropTypes.string),
  current_build: PropTypes.string,
  description: PropTypes.string,
  error: PropTypes.string,
  ip_address: PropTypes.string,
  username: PropTypes.string,
  password: PropTypes.string,
  latest_upload: PropTypes.string,
  name: PropTypes.string,
  serial_number: PropTypes.string,
  status: PropTypes.oneOf(Object.values(MODELER_STATUSES)),
  type: PropTypes.string,
  updated: PropTypes.string,
  uri: PropTypes.string,
});

export const printerResourceType = PropTypes.shape({
  description: PropTypes.string,
  location: PropTypes.string,
  modeler: PropTypes.string,
  name: PropTypes.string,
  printer_type: PropTypes.string,
  queue: PropTypes.arrayOf(PropTypes.string),
  uri: PropTypes.string,
});

export const printerTypeResourceType = PropTypes.shape({
  base_template: PropTypes.string,
  bureau: PropTypes.string,
  build_volume: PropTypes.shape({
    x: PropTypes.number,
    y: PropTypes.number,
    z: PropTypes.number,
  }),
  build_packer_type: PropTypes.string,
  min_packing_distance: PropTypes.number,
  description: PropTypes.string,
  is_template: PropTypes.bool,
  time_before_print: PropTypes.number,
  time_after_print: PropTypes.number,
  manufacturer: PropTypes.string,
  materials: PropTypes.arrayOf(PropTypes.string),
  overhead_per_line_item: PropTypes.number,
  support_strategies: PropTypes.arrayOf(PropTypes.string),
  infill_strategies: PropTypes.arrayOf(PropTypes.string),
  name: PropTypes.string,
  running_cost_per_hour: PropTypes.number,
  print_cost_scale_factor: PropTypes.number,
  material_cost_scale_factor: PropTypes.number,
  constant_overhead: PropTypes.number,
  type: PropTypes.string,
  uri: PropTypes.string,
});

export const materialPurchaseOrderType = PropTypes.shape({
  created: PropTypes.string,
  delivered_quantity: PropTypes.number,
  external_id: PropTypes.string,
  id: PropTypes.string,
  material: PropTypes.string,
  material_name: PropTypes.string,
  planned_full_quantity: PropTypes.number,
  units: PropTypes.string,
  uri: PropTypes.string,
  updated: PropTypes.string,
  uuid: PropTypes.string,
});

export const materialStockType = PropTypes.shape({
  uri: PropTypes.string,
  uuid: PropTypes.string,
  stock_max: PropTypes.number,
  stock_min: PropTypes.number,
  material: PropTypes.string,
  location: PropTypes.string,
  quantity: PropTypes.number,
  on_order_quantity: PropTypes.number,
});

export const locationSettingsResourceType = PropTypes.shape({
  location: PropTypes.string,
  uri: PropTypes.string,
  notification_order_created: PropTypes.bool,
  notification_line_items_confirmed: PropTypes.bool,
  notification_order_started_printing: PropTypes.bool,
  notification_order_scheduled: PropTypes.bool,
  notification_print_error: PropTypes.bool,
  notification_order_completed: PropTypes.bool,
});

export const lineItemEstimatesResourceType = PropTypes.shape({
  estimator: PropTypes.string,
  edited_by: PropTypes.string,
  total_cost: PropTypes.number,
  design_cost: PropTypes.number,
  printing_time: PropTypes.number,
  total_material_cost: PropTypes.number,
  post_processing_cost: PropTypes.number,
  printing_cost: PropTypes.number,
  total_price: PropTypes.number,
  materials: PropTypes.shape({
    support: PropTypes.number,
    base: PropTypes.number,
  }),
  additional_costs: PropTypes.arrayOf(PropTypes.shape({
    cost_name: PropTypes.string,
    cost_value: PropTypes.number,
  })),
});

export const lineItemType = PropTypes.shape({
  autorun: PropTypes.bool.isRequired,
  materials: PropTypes.shape({
    base: PropTypes.string.isRequired,
  }).isRequired,
  layer_thickness: PropTypes.number.isRequired,
  no_model_upload: PropTypes.bool.isRequired,
  custom_field_values: PropTypes.arrayOf(PropTypes.shape({})),
  notes: PropTypes.string.isRequired,
  discount_by_quantity: PropTypes.number,
  name: PropTypes.string.isRequired,
  customer_id: PropTypes.string,
  quantity: PropTypes.number.isRequired,
  workflow: PropTypes.string,
  estimates: lineItemEstimatesResourceType,
  model: PropTypes.string,
  status: PropTypes.oneOf(Object.values(LINE_ITEM_STATUS)),
  additional_costs: PropTypes.arrayOf(PropTypes.shape({
    cost_name: PropTypes.string,
    cost_value: PropTypes.number,
  })),
  product: PropTypes.string,
});

export const lineItemPerStepActualsType = {
  per_piece_average: PropTypes.shape({
    total_cost: PropTypes.number,
    duration: PropTypes.number,
    material_cost: PropTypes.number,
    running_cost: PropTypes.number,
    model_volume: PropTypes.number,
  }),
  total: PropTypes.shape({
    total_cost: PropTypes.number,
    duration: PropTypes.number,
    material_cost: PropTypes.number,
    running_cost: PropTypes.number,
    model_volume: PropTypes.number,
  }),
};

export const lineItemActualsType = {
  uri: PropTypes.string,
  has_custom_actuals: PropTypes.bool,
  per_step_details: PropTypes.arrayOf(lineItemPerStepActualsType),
};

export const lineItemDefaultValues = {
  discount_by_quantity: null,
  customer_id: null,
};

export const orderType = PropTypes.shape({
  uri: PropTypes.string,
  quote_required: PropTypes.bool,
  custom_field_values: PropTypes.arrayOf(PropTypes.shape({})),
  uuid: PropTypes.string,
  name: PropTypes.string,
  status: PropTypes.oneOf(Object.values(ORDER_STATUS)),
  due_date: PropTypes.string,
  priority: PropTypes.number,
  location: PropTypes.string,
  shipping: PropTypes.shape({
    uri: PropTypes.string,
  }),
});

export const runType = PropTypes.shape({
  operation: PropTypes.string,
  uuid: PropTypes.string,
  name: PropTypes.string,
  id: PropTypes.string,
  status: PropTypes.oneOf(Object.values(RUN_STATUSES)),
  prints: PropTypes.arrayOf(PropTypes.string),
  materials_base: PropTypes.number,
  materials_support: PropTypes.number,
});

export const pieceType = PropTypes.shape({
  uri: PropTypes.string,
  workflow: PropTypes.string,
  current_step_position: PropTypes.number,
  status: PropTypes.oneOf(Object.values(PIECE_STATUSES)),
  id: PropTypes.string,
  order: PropTypes.string,
  uuid: PropTypes.string,
  current_print: PropTypes.string,
  copy: PropTypes.number,
  external_print_analysis: PropTypes.shape({
    type: PropTypes.oneOf(Object.values(EXTERNAL_PRINT_ANALYSIS_VENDOR)).isRequired,
    uuid: PropTypes.string.isRequired,
  }),
});

export const modelType = PropTypes.shape({
  snapshot_content: PropTypes.string,
  size: PropTypes.shape({
    z: PropTypes.number,
  }),
  file_unit: PropTypes.string,
  content: PropTypes.string,
  name: PropTypes.string,
  updated: PropTypes.string,
  percent: PropTypes.string,
  volume_mm: PropTypes.number,
  replaced_models: PropTypes.arrayOf(PropTypes.string),
  conversion_original_content: PropTypes.string,
  conversion_original_filename: PropTypes.string,
  conversion_status: PropTypes.oneOf(Object.values(MODEL_CONVERSION_STATUSES)),
  conversion_notes: PropTypes.string,
  zverse_conversion: PropTypes.string,
  expert_conversion: PropTypes.string,
  zverse_conversion_status: PropTypes.oneOf(Object.values(ZVERSE_CONVERSION_STATUSES)),
  'upload-location': PropTypes.string,
  user_uri: PropTypes.string,
});

export const commentResourceType = PropTypes.shape({
  uuid: PropTypes.string,
  related_table_name: PropTypes.string,
  related_uuid: PropTypes.string,
});

export const pieceActualsType = {
  uri: PropTypes.string,
  per_step_details: PropTypes.arrayOf(PropTypes.shape({
    process_step: PropTypes.string,
    total_cost: PropTypes.number,
    duration: PropTypes.number,
    material_cost: PropTypes.number,
    running_cost: PropTypes.number,
    model_volume: PropTypes.number,
  })),
};

export const runResourceType = PropTypes.shape({
  uri: PropTypes.string,
  operation: PropTypes.string,
  export_controlled: PropTypes.bool,
  post_processor_type: PropTypes.string,
  printer_type: PropTypes.string,
  pieces_locked: PropTypes.bool,
});

export const buildFileResourceType = PropTypes.shape({
  uri: PropTypes.string,
  run: PropTypes.string,
  content: PropTypes.string,
  status: PropTypes.oneOf(Object.values(BUILD_FILE_STATUSES)),
});

export const documentType = PropTypes.shape({
  created: PropTypes.string,
  name: PropTypes.string,
  content: PropTypes.string,
  uri: PropTypes.string,
  user: PropTypes.string,
  previous_documents: PropTypes.arrayOf(PropTypes.string),
});

export const customGroupResourceType = PropTypes.shape({
  uri: PropTypes.string,
  name: PropTypes.string,
});

export const productResourceType = PropTypes.shape({
  uri: PropTypes.string,
});

export const runPieceResourceType = PropTypes.shape({
  lineItem: PropTypes.shape({
    model: PropTypes.shape({
      size: PropTypes.shape({
        x: PropTypes.number,
        y: PropTypes.number,
        z: PropTypes.number,
      }).isRequired,
      user_unit: PropTypes.string,
      snapshot_content: PropTypes.string,
      content: PropTypes.string,
      file_unit: PropTypes.string,
      volume_mm: PropTypes.number,
    }).isRequired,
    snapshot_content: PropTypes.string,
    content: PropTypes.string.isRequired,
    file_unit: PropTypes.string.isRequired,
    volume_mm: PropTypes.number.isRequired,
  }).isRequired,
  uri: PropTypes.string,
  uuid: PropTypes.string,
  name: PropTypes.string,
  order: PropTypes.string,
  order_name: PropTypes.string,
  order_due_date: PropTypes.string,
  material: PropTypes.string,
  material_name: PropTypes.string,
  layer_thickness: PropTypes.number,
  priority: PropTypes.number,
});

export const lineItemResourceType = PropTypes.shape({
  model: PropTypes.shape({
    size: PropTypes.shape({
      x: PropTypes.number,
      y: PropTypes.number,
      z: PropTypes.number,
    }).isRequired,
    user_unit: PropTypes.string,
    snapshot_content: PropTypes.string,
    content: PropTypes.string,
    file_unit: PropTypes.string,
    volume_mm: PropTypes.number,
  }).isRequired,
  snapshot_content: PropTypes.string,
  content: PropTypes.string.isRequired,
  file_unit: PropTypes.string.isRequired,
  volume_mm: PropTypes.number.isRequired,
  uri: PropTypes.string,
  uuid: PropTypes.string,
  name: PropTypes.string,
  order: PropTypes.string,
  order_name: PropTypes.string,
  order_due_date: PropTypes.string,
  materials: PropTypes.arrayOf(PropTypes.shape({})),
  layer_thickness: PropTypes.number,
  priority: PropTypes.number,
});

export const designFileResourceType = PropTypes.shape({
  uri: PropTypes.string,
  model: PropTypes.string,
  type: PropTypes.oneOf(Object.values(DESIGN_FILE_TYPES)),
});

export const modelLibraryType = PropTypes.shape({
  name: PropTypes.string,
  model: PropTypes.string,
  type: PropTypes.string,
  owner: PropTypes.string,
  snapshot_content: PropTypes.string,
});

export const workflowType = PropTypes.shape({
  name: PropTypes.string,
  uri: PropTypes.string,
});

export const prepTaskRecordType = PropTypes.shape({
  status: PropTypes.oneOf(Object.values(PREP_TASK_RECORD_STATUSES)),
  uuid: PropTypes.string,
  actuals: PropTypes.shape({
    in_progress_time: PropTypes.number,
    on_hold_time: PropTypes.number,
  }),
  start_dwell_time: PropTypes.string,
  end_time: PropTypes.string,
  uri: PropTypes.string,
});

export const prepTaskType = PropTypes.shape({
  name: PropTypes.string,
  shortname: PropTypes.string,
  description: PropTypes.string,
  user_estimated_dwell_time: PropTypes.number,
  user_estimated_work_time: PropTypes.number,
});

export const shipmentAddressType = PropTypes.shape({
  name: PropTypes.string,
  address: PropTypes.string,
  phone_number: PropTypes.string,
  email: PropTypes.string,
});

export const shipmentResourceType = PropTypes.shape({
  name: PropTypes.string,
  uri: PropTypes.string,
  order: PropTypes.string,
  run: PropTypes.string,
  recipient_address: shipmentAddressType,
  billing_address: shipmentAddressType,
  origin_address: shipmentAddressType,
  estimated_delivery_date: PropTypes.string,
  estimated_shipment_date: PropTypes.string,
});

export const shippingResourceType = PropTypes.shape({
  name: PropTypes.string,
  uri: PropTypes.string,
});

export const prepWorkflowResourceType = PropTypes.shape({
  tasks_in_workflow: PropTypes.arrayOf(PropTypes.shape({})),
});

export const postProcessorTypeResourceType = PropTypes.shape({
  uri: PropTypes.string,
  updated_by: PropTypes.string,
  updated: PropTypes.string,
});

export const workInstructionResourceType = PropTypes.shape({
  report_type: PropTypes.string,
  choices: PropTypes.arrayOf(PropTypes.string),
  position: PropTypes.number,
  description: PropTypes.string,
  additional_instruction_url: PropTypes.string,
  threshold: PropTypes.shape({
    value: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.number,
    ]),
    deviation: PropTypes.number,
    lower_bound: PropTypes.number,
    upper_bound: PropTypes.number,
  }),
  threshold_type: PropTypes.string,
  threshold_action: PropTypes.string,
  threshold_notification: PropTypes.string,
});

export const workChecklistLinkingResourceType = PropTypes.shape({
  related_uri: PropTypes.string,
  position: PropTypes.number,
  process_step: PropTypes.string,
  uri: PropTypes.string,
});

export const downtimeResourceType = PropTypes.shape({
  description: PropTypes.string,
  printer: PropTypes.string,
  post_processor: PropTypes.string,
});

export const labelResourceType = PropTypes.shape({
  uri: PropTypes.string,
  name: PropTypes.string,
  color: PropTypes.string,
});

export const castorCostingResourceType = PropTypes.shape({
  uri: PropTypes.string,
  'model-library': PropTypes.string,
  status: PropTypes.oneOf(Object.values(CASTOR_COSTING_STATUSES)),
  currency: PropTypes.string,
  castor_new_config_url: PropTypes.string,
});

export const castorCostingConfigResourceType = PropTypes.shape({
  uri: PropTypes.string,
  castor_costing: PropTypes.string,
  configuration_name: PropTypes.string,
  configuration_material_name: PropTypes.string,
  configuration_quantity: PropTypes.number,
  configuration_years_of_demand: PropTypes.number,
  additive_target_printer: PropTypes.string,
  additive_material_name: PropTypes.string,
  additive_lead_time_days: PropTypes.number,
  additive_total_cost: PropTypes.number,
  traditional_method_name: PropTypes.string,
  traditional_lead_time_days: PropTypes.number,
  traditional_total_cost: PropTypes.number,
  configuration_ui_url: PropTypes.string,
  additive_target_printer_company: PropTypes.string,
});

export const bureauResourceType = PropTypes.shape({
  name: PropTypes.string,
  uri: PropTypes.string,
});

export const userResourceType = PropTypes.shape({
  name: PropTypes.string,
  uri: PropTypes.string,
  tos: PropTypes.bool,
  impersonating: PropTypes.string,
});

export const zverseConversionResourceType = PropTypes.shape({
  uri: PropTypes.string,
  status: PropTypes.oneOf(Object.values(ZVERSE_CONVERSION_STATUSES)),
  currency: PropTypes.oneOf(Currencies),
  cost: PropTypes.number,
});

export const expertConversionResourceType = PropTypes.shape({
  uri: PropTypes.string,
  status: PropTypes.oneOf(Object.values(EXPERT_CONVERSION_STATUSES)),
});

export const customFieldResourceType = PropTypes.shape({
  uri: PropTypes.string.isRequired,
  field_id: PropTypes.string.isRequired,
  field_name: PropTypes.string.isRequired,
  position: PropTypes.number.isRequired,
  related_table_name: PropTypes.string.isRequired,
  format: PropTypes.oneOf(Object.values(CUSTOM_FIELD_FORMATS)),
  type: PropTypes.oneOf(Object.values(CUSTOM_FIELD_TYPES)).isRequired,
  meta: PropTypes.shape({
    unit: PropTypes.string,
    tooltip: PropTypes.string,
    placeholder: PropTypes.string,
  }),
  rights: PropTypes.arrayOf(PropTypes.oneOf(Object.values(CUSTOM_FIELD_RIGHTS))),
  required: PropTypes.bool.isRequired,
  is_smart: PropTypes.bool.isRequired,
  enabled: PropTypes.bool.isRequired,
  default_value: PropTypes.string,
  options: PropTypes.arrayOf(PropTypes.shape({
    key: PropTypes.string.isRequired,
    value: PropTypes.string.isRequired,
    disabled: PropTypes.bool.isRequired,
    meta: PropTypes.shape({}).isRequired,
  })),
  description: PropTypes.string,
  use_count: PropTypes.number.isRequired,
});

export const nebumindAnalysisResourceType = PropTypes.shape({
  uri: PropTypes.string.isRequired,
  created: PropTypes.string.isRequired,
  updated: PropTypes.string.isRequired,
  status: PropTypes.string.isRequired,
  currency: PropTypes.string.isRequired,
  notes: PropTypes.string.isRequired,
  nebumind_user_name: PropTypes.string.isRequired,
  nebumind_user_password: PropTypes.string.isRequired,
  piece: PropTypes.string.isRequired,
  visualization: PropTypes.string.isRequired,
  indications: PropTypes.arrayOf(PropTypes.shape({
    x_min: PropTypes.number.isRequired,
    x_max: PropTypes.number.isRequired,
    y_min: PropTypes.number.isRequired,
    y_max: PropTypes.number.isRequired,
    z_min: PropTypes.number.isRequired,
    z_max: PropTypes.number.isRequired,
    nebumind_impact: PropTypes.number.isRequired,
    threshold: PropTypes.number.isRequired,
    visualization: PropTypes.string.isRequired,
  })),
});

export const buildResourceType = PropTypes.shape({
  created: PropTypes.string.isRequired,
  updated: PropTypes.string.isRequired,
  current_layer: PropTypes.number,
  layers: PropTypes.number,
  end_time: PropTypes.string,
  materials: PropTypes.arrayOf(PropTypes.shape({
    material_name: PropTypes.string,
    material_consumed: PropTypes.string,
  })),
  modeler: PropTypes.string.isRequired,
  name: PropTypes.string,
  status: PropTypes.string.isRequired,
  start_time: PropTypes.string.isRequired,
  uri: PropTypes.string.isRequired,
  estimated_time: PropTypes.number.isRequired,
  run: PropTypes.string,
});

export const subLocationResourceType = PropTypes.shape({
  uri: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  description: PropTypes.string,
  location: PropTypes.string.isRequired,
  is_default: PropTypes.bool.isRequired,
  archived: PropTypes.string,
});
export const stockSummaryResourceType = PropTypes.shape({
  sub_location: PropTypes.string.isRequired,
  stock: PropTypes.string.isRequired,
  material_lot_quantity_on_order: PropTypes.number.isRequired,
  material_lot_quantity_available: PropTypes.number.isRequired,
  batches_quantity_available: PropTypes.number.isRequired,
});
export const secureFileCheckoutResourceType = PropTypes.shape({
  uri: PropTypes.string.isRequired,
  created: PropTypes.string.isRequired,
  updated: PropTypes.string.isRequired,
  status: PropTypes.oneOf(Object.values(SECURE_FILE_CHECKOUT_STATUSES)).isRequired,
  user: PropTypes.string,
  secure_checkout_directory: PropTypes.string.isRequired,
  related_table_name: PropTypes.string.isRequired,
  resource_uuid: PropTypes.string.isRequired,
  common_name: PropTypes.string.isRequired,
  context: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
});
export const secureCheckoutDirectoryResourceType = PropTypes.shape({
  uri: PropTypes.string.isRequired,
  common_name: PropTypes.string.isRequired,
  directory_name: PropTypes.string.isRequired,
  available: PropTypes.bool.isRequired,
});
