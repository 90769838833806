import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import Actions from 'rapidfab/actions';
import isFetchingInitial from 'rapidfab/utils/isFetchingInitial';
import * as Selectors from 'rapidfab/selectors';

import ShippingsComponent from 'rapidfab/components/organize/shipping';
import { API_RESOURCES } from 'rapidfab/constants';

const ShippingsContainer = props => {
  const locations = useSelector(Selectors.getLocations);
  const shippings = useSelector(Selectors.getShippings);
  const users = useSelector(Selectors.getUsers);
  const fetching = useSelector(state => isFetchingInitial(state.ui.nautilus[API_RESOURCES.SHIPPING].list));

  const dispatch = useDispatch();
  useEffect(() => {
    (async () => dispatch(Actions.Api.nautilus[API_RESOURCES.SHIPPING].list()))();
  }, []);

  return (
    <ShippingsComponent
      {...props}
      users={users}
      locations={locations}
      shippings={shippings}
      fetching={fetching}
    />
  );
};

export default ShippingsContainer;
