import React, { Component } from 'react';
import PropTypes from 'prop-types';

/**
 * The same as default input,
 * but callback (onSubmit) will be called after onblur or after clicking to "Enter" button
 */
class InputSubmit extends Component {
  constructor(props) {
    super(props);

    this.state = {
      value: this.props.value,
    };

    this.handleOnChange = this.handleOnChange.bind(this);
    this.handleOnSubmit = this.handleOnSubmit.bind(this);
    this.handleKeyPress = this.handleKeyPress.bind(this);
  }

  componentDidUpdate(prevProps) {
    const { value } = this.props;
    if (value !== prevProps.value && value !== this.state.value) {
      // eslint-disable-next-line react/no-did-update-set-state
      this.setState({ value: this.props.value });
    }
  }

  handleOnChange(event) {
    const { value } = event.target;
    this.setState({ value });
  }

  handleOnSubmit() {
    const { value } = this.state;
    this.props.onSubmit(value);
  }

  handleKeyPress(event) {
    if (event.key === 'Enter') {
      this.handleOnSubmit();
    }
  }

  render() {
    const { value } = this.state;
    const { placeholder, disabled } = this.props;
    return (
      <input
        type="text"
        className="form-control"
        placeholder={placeholder}
        value={value}
        onChange={this.handleOnChange}
        onBlur={this.handleOnSubmit}
        onKeyPress={this.handleKeyPress}
        disabled={disabled}
      />
    );
  }
}

InputSubmit.defaultProps = {
  disabled: false,
};

InputSubmit.propTypes = {
  placeholder: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired,
  onSubmit: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
};

export default InputSubmit;
