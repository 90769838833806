import React from 'react';
import PropTypes from 'prop-types';

const HalfCircleProgressBar = ({ percentage = 0 }) => (
  <div className="HalfCircleProgressBar">
    <div className="HalfCircleProgressBarOverflow">
      <div
        className="HalfCircleProgressBarLine"
        style={{ transform: `rotate(${45 + (percentage * 1.8)}deg)` }}
      />
    </div>
    <span className="HalfCircleProgressBarPercentage"><span>{percentage}</span> %</span>
  </div>
);

HalfCircleProgressBar.propTypes = {
  percentage: PropTypes.number.isRequired,
};
export default HalfCircleProgressBar;
