import _filter from 'lodash/filter';
import { getPostProcessors, getPostProcessorsByUri } from 'rapidfab/selectors/postProcessor';
import { getPrinters, getPrintersByUri } from 'rapidfab/selectors/printer';
import { getShippings, getShippingsByUri } from 'rapidfab/selectors/shipping';
import { createSelector } from 'reselect';
import { getPredicate } from './base';

export const getWorkstations = createSelector(
  [getPrinters, getPostProcessors, getShippings],
  (printers, postProcessors, shippings) =>
    [...printers, ...postProcessors, ...shippings],
);

/** Returns workstations filtered by a WS-type URI. */
export const getWorkstationsByWorkstationType = createSelector(
  [getPredicate, getWorkstations],
  (workstationTypeUri, workstations) => (
    _filter(workstations, ws =>
      ws.post_processor_type === workstationTypeUri ||
      ws.printer_type === workstationTypeUri ||
      ws.shipping === workstationTypeUri)
  ),
);

export const getWorkstationsByUri = createSelector(
  [getPrintersByUri, getPostProcessorsByUri, getShippingsByUri],
  (printersByUri, postProcessorsByUri, shippingsByUri) => ({
    ...printersByUri,
    ...postProcessorsByUri,
    ...shippingsByUri,
  }),
);
