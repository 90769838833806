import { createSelector } from 'reselect';
import { getStateResources } from 'rapidfab/selectors/helpers/base';
import _map from 'lodash/map';
import * as baseStateSelectors from 'rapidfab/selectors/baseStateSelectors';
import _filter from 'lodash/filter';
import { PRINT_STATUSES } from 'rapidfab/constants';
import _keyBy from 'lodash/keyBy';

// !!!ATTENTION!!! this selector returns ALL prints
// including Remanufactured ones (if there are any in the store)
// Use this selector with CAUTION.
export const getPrints = createSelector(
  [baseStateSelectors.getStatePrints, getStateResources],
  (uuids, resources) => _map(uuids, uuid => resources[uuid]),
);

// The ones that are returned from the API via work_needed=true (default LIST /prints behavior)
// In other words: exclude Prints which were Remanufactured to some other Prints
// Actually, if print is not remanufactured, this field may not be returned in API.
// So, you might not see this field in API response at all.
// !!!IMPORTANT!!! in most cases, this selector should be used as the main one,
// unless you need prints which were Remanufactured to some other ones
export const getNonRemanufacturedPrints = createSelector(
  [getPrints],
  prints => _filter(prints, print => !print.remanufactured_to),
);

// !!!ATTENTION!!! ALL prints used here (not just Non-Remanufactured ones)
// Anyway, since there is only one Printing Print per Piece (even within ALL prints)
// - selector will still work as expected and return correct run
export const getPrintingPrintsCreated = createSelector(
  [getPrints],
  prints => _filter(prints, { process_step_position: 1, status: PRINT_STATUSES.CREATED }),
);

export const getPrintsByUri = createSelector(
  [getPrints],
  prints => _keyBy(prints, 'uri'),
);
