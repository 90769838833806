import React, { useContext, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import {
  getFeatures,
  getProductsForOrderSortedByCreatedDesc,
  getUUIDResource,
  isCurrentUserRestricted,
  getLineItemsForOrder,
} from 'rapidfab/selectors';

import Actions from 'rapidfab/actions';
import { API_RESOURCES, FEATURES, PAGINATION_IGNORE_DEFAULT_LIMIT } from 'rapidfab/constants';
import Loading from 'rapidfab/components/Loading';
import ProductContainer from 'rapidfab/containers/ProductContainer';
import { extractUuid } from 'rapidfab/utils/uuidUtils';
import { loadLineItemsWithRelatedDataForOrder } from 'rapidfab/dispatchers/order';
import { loadDesignFilesWithModels } from 'rapidfab/dispatchers/designFile';
import { loadModelLibrariesWithModels } from 'rapidfab/dispatchers/modelLibrary';
import _compact from 'lodash/compact';
import _map from 'lodash/map';
import { FormattedMessage } from 'react-intl';
import RouterContext from 'rapidfab/context/RouterContext';
import { useNavigate } from 'react-router-dom';
import useScrollToComponent from 'rapidfab/hooks/useScrollToComponent';
import ConfirmationModal from 'rapidfab/components/ConfirmationModal';
import * as Selectors from 'rapidfab/selectors';

const OrderProductsContainer = ({
  readOnlyLineItemsOrCallback,
  orderUri,
  expandMode,
  setExpandedItems,
  expandedItems,
  setExpandMode,
  handleExpandAllLineItems,
}) => {
  const [isInitialLoading, setIsInitialLoading] = useState(true);
  const [highlightedItems, setHighlightedItems] = useState(false);
  const [initialFetched, setInitialFetched] = useState(false);
  const { expandAllLineItems } = useContext(RouterContext);

  const dispatch = useDispatch();

  const order = useSelector(state => (orderUri ? getUUIDResource(state, extractUuid(orderUri)) : null));
  const products = useSelector(state => getProductsForOrderSortedByCreatedDesc(state, order));
  const isRestrictedUser = useSelector(isCurrentUserRestricted);
  const features = useSelector(getFeatures);
  const lineItems = useSelector(state => getLineItemsForOrder(state, order));
  const isPowderWorkflowFeatureEnabled = useSelector(state =>
    Selectors.isFeatureEnabled(state, FEATURES.POWDER_WORKFLOW));

  const anyProductDetailsFetching = useSelector(state => state.ui.nautilus[API_RESOURCES.DESIGN_FILE].list.fetching
    || state.ui.nautilus[API_RESOURCES.LINE_ITEM].list.fetching
    || state.ui.nautilus[API_RESOURCES.MODEL].list.fetching
    || state.ui.nautilus[API_RESOURCES.MODEL_LIBRARY].list.fetching,
  );

  const { incompleteLineItems,
    setLeaveConfirmation,
    setTriggerNextToRender,
    linkToForward,
    removeIncompleteItems,
    setLinkToForward,
    leaveConfirmation } = useContext(RouterContext);

  const [scrollRef, setScrollToIncomplete] = useScrollToComponent();

  const selected = {
    features,
    products,
    isRestrictedUser,
    scrollRef,
  };

  const navigate = useNavigate();

  useEffect(() => {
    if (expandAllLineItems && initialFetched) {
      handleExpandAllLineItems();
    }
  }, [expandAllLineItems, initialFetched]);

  const handleCancelLeavingPage = () => {
    setHighlightedItems(incompleteLineItems);
    setLeaveConfirmation(false);
    setScrollToIncomplete(true);
  };

  const handleConfirmLeavingPage = () => {
    setHighlightedItems(false);
    setLeaveConfirmation(false);
    setTriggerNextToRender(true);
    navigate(linkToForward.replace('#', ''));
    removeIncompleteItems().then(() => {
      setLinkToForward(null);
      setTriggerNextToRender(false);
    });
  };

  const loadProducts = () => {
    dispatch(Actions.Api.nautilus[API_RESOURCES.PRODUCT]
      .list({ order: orderUri }, { limit: PAGINATION_IGNORE_DEFAULT_LIMIT }, {}, {}, true));
  };

  const loadProductsRelatedData = (products, isRestrictedUser, features) => {
    if (!products.length) {
      return Promise.all([]);
    }
    const promises = [];
    const productUris = _map(products, 'uri');
    if (productUris.length) {
      promises.push(loadDesignFilesWithModels(dispatch, { product: productUris }));
    }

    return Promise.all([
      ...promises,
      loadLineItemsWithRelatedDataForOrder(
        dispatch,
        orderUri,
        isRestrictedUser,
        features,
        products,
      ),
    ]);
  };

  // Get Model Library models based on Line Items data.
  const loadModelLibrariesRelatedData = lineItems => {
    if (!lineItems.length) {
      return Promise.all([]);
    }
    const promises = [];
    const lineitemModelsUris = _compact(_map(lineItems, 'additive.model'));

    if (lineitemModelsUris.length && !isPowderWorkflowFeatureEnabled) {
      promises.push(loadModelLibrariesWithModels(dispatch, { 'additive.model': lineitemModelsUris }));
    }
    const modelLibraryUris = _compact(_map(products, 'model_library'));
    if (modelLibraryUris.length && !isPowderWorkflowFeatureEnabled) {
      promises.push(loadModelLibrariesWithModels(dispatch, { uri: modelLibraryUris }));
    }

    return Promise.all([
      ...promises,
    ]);
  };

  useEffect(() => {
    loadProducts();
  }, []);

  const productUris = products.map(({ uri }) => uri);
  const highlightIncompleteProduct = uri => highlightedItems.find(highlighted => highlighted.uri === uri);

  useEffect(() => {
    loadProductsRelatedData(
      products,
      isRestrictedUser,
      features,
    ).finally(() => {
      if (isInitialLoading) {
        setIsInitialLoading(false);
      }
    });
  },
  // JSON.stringify prevents useEffect to run on any re-render with `array` passed as a dependency
  // https://github.com/facebook/react/issues/14476#issuecomment-471199055
  [JSON.stringify(productUris)]);

  useEffect(() => {
    loadModelLibrariesRelatedData(lineItems);
  },
  [JSON.stringify(lineItems)]);

  if (isInitialLoading) {
    return <Loading className="m-b" />;
  }

  return ([
    anyProductDetailsFetching && <Loading className="m-b" />,
    _map(products, ({ uri }, index) => (
      <div key={uri} style={(highlightedItems && highlightIncompleteProduct(uri)) ? { boxShadow: 'rgb(42 144 108) 0px 0px 6px 8px' } : null}>
        <ProductContainer
          {...selected}
          key={uri}
          productUri={uri}
          readOnlyLineItemsOrCallback={readOnlyLineItemsOrCallback}
          highlightedItems={highlightedItems}
          setHighlightedItems={setHighlightedItems}
          expandMode={expandMode}
          setExpandedItems={setExpandedItems}
          expandedItems={expandedItems}
          setExpandMode={setExpandMode}
          initialFetchedState={{ initialFetched, setInitialFetched }}
          productIndex={index + 1}
        />
      </div>
    )),
    leaveConfirmation &&
    (
      <ConfirmationModal
        handleCancel={handleCancelLeavingPage}
        handleConfirm={handleConfirmLeavingPage}
        confirmButtonContent={<FormattedMessage id="button.delete" defaultMessage="Delete" />}
        message="Warning: It looks like you have unsaved line item changes. If you leave this page, these line items will be deleted.
        Would you like to proceed and delete?"
      />
    ),
  ]);
};

OrderProductsContainer.propTypes = {
  orderUri: PropTypes.string.isRequired,
  readOnlyLineItemsOrCallback: PropTypes.oneOfType([PropTypes.bool, PropTypes.func]),
  expandMode: PropTypes.oneOfType([PropTypes.oneOf([null]), PropTypes.string]).isRequired,
  setExpandedItems: PropTypes.func.isRequired,
  expandedItems: PropTypes.arrayOf(PropTypes.number).isRequired,
  setExpandMode: PropTypes.func.isRequired,
  handleExpandAllLineItems: PropTypes.func,
};

OrderProductsContainer.defaultProps = {
  readOnlyLineItemsOrCallback: false,
  handleExpandAllLineItems: () => {},
};

export default OrderProductsContainer;
