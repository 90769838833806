import FilterItem from 'rapidfab/components/FilterItem';
import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';

import Actions from 'rapidfab/actions';
import isFetchingInitial from 'rapidfab/utils/isFetchingInitial';
import * as Selectors from 'rapidfab/selectors';

import StocksComponent from 'rapidfab/components/inventory/stocks';
import { getMaterials, isFeatureEnabled } from 'rapidfab/selectors';
import { API_RESOURCES, FEATURES } from 'rapidfab/constants';

import withRecordsListHandling from 'rapidfab/containers/hocs/withRecordsListHandling';
import { getStocks } from 'rapidfab/selectors/stock';

const StocksContainer = props => {
  const bureau = useSelector(state => Selectors.getBureauUri(state));
  const fetching = useSelector(state => isFetchingInitial(
    state.ui.nautilus[API_RESOURCES.MATERIAL].list,
    state.ui.nautilus[API_RESOURCES.LOCATION].list,
    state.ui.nautilus[API_RESOURCES.STOCK].list,
  ));
  const locations = useSelector(state => Selectors.getLocationsByUri(state));
  const materials = useSelector(state => Selectors.getMaterialsByUri(state));
  const allMaterials = useSelector(getMaterials);
  const stocks = useSelector(state => Selectors.getStocks(state));
  const isMaterialManagementFeatureEnabled =
    useSelector(state => isFeatureEnabled(state, FEATURES.MATERIAL_MANAGEMENT));
  const dispatch = useDispatch();
  const {
    material,
  } = props.filters;

  const selected = {
    bureau,
    fetching,
    locations,
    materials,
    stocks,
    isMaterialManagementFeatureEnabled,
  };

  const onInitialize = () => {
    dispatch(Actions.Api.nautilus[API_RESOURCES.MATERIAL].list({ bureau }));
    dispatch(Actions.Api.nautilus[API_RESOURCES.LOCATION].list());
  };

  useEffect(() => {
    onInitialize();
  }, [bureau]);

  const onMaterialTypeFilterChange = ({ target: { value } }) => {
    const { onFilterUpdate, filters } = props;

    const { material, ...otherFilters } = filters;

    const changedFilters = value
      ? { ...otherFilters, material: value }
      : { ...otherFilters };

    onFilterUpdate(changedFilters);
  };

  return (
    <StocksComponent
      {...props}
      {...selected}
      extraFilters={[
        <div key="ordersExtraFilters" className="form-inline" style={{ lineHeight: '40px' }}>
          <FilterItem
            name="material"
            value={material}
            onChange={onMaterialTypeFilterChange}
            label={(<FormattedMessage id="field.materialType" defaultMessage="Material Type" />)}
          >
            {allMaterials.map(material => (
              <option
                key={material.uri}
                value={material.uri}
              >
                {material.name}
              </option>
            ))}
          </FilterItem>
        </div>,
      ]}
    />
  );
};

StocksContainer.propTypes = {
  bureau: PropTypes.string.isRequired,
  onInitialize: PropTypes.func.isRequired,
  filters: PropTypes.shape({
    material: PropTypes.string,
  }).isRequired,
  onFilterUpdate: PropTypes.func.isRequired,
};

export default withRecordsListHandling(
  StocksContainer,
  getStocks,
  ['stock'],
  {
    defaultLimit: 50,
    searchBy: null,
    defaultSort: '-material',
    useLocationFiltering: true,
    multicolumnSearch: true,
  },
);
