import BureauBarcodeIcon from 'rapidfab/components/BureauBarcodeIcon';
import React from 'react';
import PropTypes from 'prop-types';
import { PIECE_STATUS_MAP } from 'rapidfab/mappings';
import { orderType } from 'rapidfab/types';
import Table from 'rapidfab/components/Tables/Table';
import { Link } from 'react-router-dom';
import { getRouteURI } from 'rapidfab/utils/uriUtils';
import { FEATURES, ROUTES } from 'rapidfab/constants';
import { getShortUUID, extractUuid } from 'rapidfab/utils/uuidUtils';
import Config from 'rapidfab/config';
import Feature from 'rapidfab/components/Feature';

const Pieces = ({ ordersByUri,
  runs,
  pieces,
  isFetching,
  onSort,
  renderHeaderView,
  paginationComponent: PaginationComponent,
  ...componentProps }) => {
  const breadcrumbs = ['plan', 'orders', 'pieces'];

  const columns = [
    {
      type: 'custom',
      uid: 'field.id',
      accessor: 'uuid',
      defaultMessage: 'ID',
      resource: 'piece',
      Cell: ({ value }) => {
        const piece = pieces.find(({ uuid }) => uuid === value);
        return (
          <div className="d-flex">
            <Link
              to={getRouteURI(ROUTES.PIECE_EDIT, { uuid: value }, {}, true)}
              className="pull-right"
            >
              {value && getShortUUID(value)}
            </Link>
            {
              piece.current_print ? (
                <Feature featureName={FEATURES.QR_PRINTS_TRAVELER}>
                  <a
                    href={`${Config.HOST.QR}/traveler/print/${
                      extractUuid(piece.current_print)
                    }`}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <BureauBarcodeIcon className="spacer-right spacer-left" />
                  </a>
                </Feature>
              ) : null
            }
          </div>
        );
      },
    },
    {
      type: 'translatable',
      uid: 'field.status',
      accessor: 'status',
      defaultMessage: 'Status',
      mapping: PIECE_STATUS_MAP,
      isSortable: true,
    },
    {
      type: 'record',
      uid: 'field.name',
      accessor: 'name',
      defaultMessage: 'Name',
      resource: 'piece',
      uri: 'uri',
      isSortable: true,
    },
    {
      type: 'resource',
      uid: 'field.order',
      accessor: 'order',
      defaultMessage: 'Order',
      resource: 'order',
      resources: ordersByUri,
      isSortable: true,
    },
    {
      type: 'custom',
      uid: 'currentStep',
      accessor: 'current_step_position',
      defaultMessage: 'Current Step',
      isSortable: true,
      Cell: ({ value, row }) => {
        const print = row.original.current_print;
        const run = runs.find(run => run.prints.includes(print));

        if (run) {
          return (
            <Link
              to={getRouteURI(ROUTES.RUN_EDIT, { uuid: extractUuid(run.uri) }, {}, true)}
            >
              {value} - {run.workstation_name}
            </Link>
          );
        }
        return value;
      },
    },
  ];

  return (
    <>
      {renderHeaderView()}
      <Table
        {...componentProps}
        breadcrumbs={breadcrumbs}
        data={pieces}
        columns={columns}
        withBreadcrumbs
        isFilteringEnabled={false}
        withDefaultPagination={false}
        isFetching={isFetching}
        isManualSoringEnabled
        initialSortedDesc
        initialSortedColumn="updated"
        manualSortingFunc={onSort}
        PaginationComponent={(<PaginationComponent />)}
      />
    </>
  );
};

Pieces.propTypes = {
  ordersByUri: PropTypes.objectOf(orderType).isRequired,
  pieces: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  isFetching: PropTypes.bool.isRequired,
  onSort: PropTypes.func.isRequired,
  value: PropTypes.string.isRequired,
  row: PropTypes.shape({
    original: PropTypes.shape({
      uuid: PropTypes.string.isRequired,
      uri: PropTypes.string.isRequired,
      current_print: PropTypes.string.isRequired,
    }),
  }).isRequired,
  runs: PropTypes.arrayOf(PropTypes.shape({
    uri: PropTypes.string.isRequired,
    workstation_name: PropTypes.string.isRequired,
    prints: PropTypes.arrayOf(PropTypes.string),
  })).isRequired,
  renderHeaderView: PropTypes.func.isRequired,
  paginationComponent: PropTypes.func.isRequired,
};

export default Pieces;
