import React from 'react';
import PropTypes from 'prop-types';
import CustomFieldComponent from 'rapidfab/components/forms/CustomFieldComponent';
import _keyBy from 'lodash/keyBy';
import _get from 'lodash/get';
import _isNull from 'lodash/isNull';

const CustomFieldList = ({
  customFieldReferences,
  customFieldValues,
  onChange,
  variant,
  readOnly,
  bg,
}) => {
  const customFieldValuesByURI = _keyBy(customFieldValues, 'custom_field');

  return (
    <div>
      {
        customFieldReferences.map(customFieldReference => {
          if (_isNull(customFieldReference.position)) return null;

          // Only non-nullable values are suitable for default value
          const customFieldDefinitionDefaultValue = customFieldReference.default_value || '';

          const customFieldValue = _get(
            customFieldValuesByURI,
            [customFieldReference.uri, 'value'],
            customFieldDefinitionDefaultValue, // Default value if field value does not exist yet
          );

          return (
            <CustomFieldComponent
              reference={customFieldReference}
              value={customFieldValue}
              onChange={onChange}
              key={customFieldReference.uri}
              variant={variant}
              readOnly={readOnly}
              bg={bg}
            />
          );
        },
        )
      }
    </div>
  );
};

CustomFieldList.variants = CustomFieldComponent.variants;

CustomFieldList.defaultProps = {
  variant: CustomFieldList.variants.form,
  readOnly: false,
};

CustomFieldList.propTypes = {
  customFieldReferences: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  customFieldValues: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  onChange: PropTypes.func.isRequired,
  variant: PropTypes.oneOf(Object.values(CustomFieldList.variants)),
  readOnly: PropTypes.bool,
  bg: PropTypes.string.isRequired,
};

export default CustomFieldList;
