import _clone from 'lodash/clone';
import PropTypes from 'prop-types';
import ToolingStockActionModal from 'rapidfab/components/inventory/ToolingStockActionModal';
import React, { useEffect, useState } from 'react';
import Actions from 'rapidfab/actions';
import _get from 'lodash/get';
import _set from 'lodash/set';
import { API_RESOURCES } from 'rapidfab/constants';
import Alert from 'rapidfab/utils/alert';
import { useDispatch, useSelector } from 'react-redux';
import * as Selectors from 'rapidfab/selectors';
import {
  TOOLING_STOCK_ACTION_FORM,
  TOOLING_STOCK_ACTION_METADATA_FORM,
} from 'rapidfab/constants/forms';
import { FormattedMessage } from 'react-intl';
import { extractUuid } from 'rapidfab/utils/uuidUtils';

const ToolingStockActionModalContainer = props => {
  const locations = useSelector(Selectors.getLocations);
  const [location, setLocation] = useState();
  // Selected location in form to fetch sub locations
  const isVendorUser = useSelector(Selectors.isVendorServiceProvider);
  const subLocations = useSelector(state =>
    Selectors.getSubLocationsForLocation(state, location),
  );
  const locationsFetching = useSelector(
    state =>
      state.ui.nautilus[API_RESOURCES.LOCATION].list.fetching ||
      state.ui.nautilus[API_RESOURCES.SUB_LOCATION].list.fetching,
  );

  const [isLocationTypeSelected, setIsLocationTypeSelected] = useState(false);
  const [locationsFetched, setLocationsFetched] = useState(false);
  const [submitting, setSubmitting] = useState(false);

  const selected = {
    locations,
    subLocations,
    submitting,
    locationsFetching,
  };

  const dispatch = useDispatch();

  const fetchLocations = () => {
    dispatch(Actions.Api.nautilus[API_RESOURCES.LOCATION].list());
    dispatch(Actions.Api.nautilus[API_RESOURCES.SUB_LOCATION].list());
    setLocationsFetched(true);
  };

  useEffect(() => {
    if (isLocationTypeSelected && !locationsFetched && !isVendorUser) {
      fetchLocations();
    }
  }, [isLocationTypeSelected, locationsFetched]);

  const onSubmit = async formValues => {
    setSubmitting(true);

    const clonedValues = _clone(formValues);
    const actionType = clonedValues.action_type;

    // Always include 'action_type' in the payload
    const requiredFields = ['action_type'];
    if (clonedValues.notes) {
      requiredFields.push('notes');
    }

    // Get the fields relevant to the selected action_type

    const payload = { tooling_stock: props.toolingStock.uri };

    TOOLING_STOCK_ACTION_FORM.FIELDS.forEach(fieldName => {
      const field = _get(clonedValues, fieldName);
      if (field) {
        _set(payload, fieldName, field);
      }
    });

    TOOLING_STOCK_ACTION_FORM.NULL_FIELDS.forEach(fieldName => {
      const field = _get(clonedValues, fieldName);
      if (field === '' || field === undefined) {
        _set(payload, fieldName, null);
      }
    });

    const metadata = {};
    const metadataFields =
      TOOLING_STOCK_ACTION_METADATA_FORM.FIELDS_BY_ACTION[actionType];
    const metadataNullableFields =
      TOOLING_STOCK_ACTION_METADATA_FORM.NULL_FIELDS_BY_ACTION[actionType];

    metadataFields?.forEach(fieldName => {
      const field = _get(clonedValues, fieldName);
      if (field) {
        _set(metadata, fieldName, field);
      }
    });

    metadataNullableFields?.forEach(fieldName => {
      const field = _get(clonedValues, fieldName);
      if (field === '' || field === undefined) {
        _set(metadata, fieldName, null);
      }
    });

    payload.metadata = metadata;

    try {
      // TODO Make sure to pass the Tooling Stock UUID to properly send the PUT request for actions
      await dispatch(
        Actions.Api.nautilus[API_RESOURCES.TOOLING_STOCK_ACTION].post({
          ...payload,
        }),
      );
      dispatch(
        Actions.Api.nautilus[API_RESOURCES.TOOLING_STOCK].get(
          extractUuid(props.toolingStock.uri),
        ),
      );

      Alert.success(
        <FormattedMessage
          id="toaster.tooling_stock_action.created"
          defaultMessage="Tool Action successfully created."
        />,
      );
      setLocation(null);
      props.hideModal();
    } finally {
      setSubmitting(false);
    }
  };

  return (
    <ToolingStockActionModal
      {...props}
      {...selected}
      onSubmit={onSubmit}
      show={props.show}
      handleCloseModal={props.hideModal}
      onLocationChange={newLocation => setLocation(newLocation)}
      setLocationActionType={setIsLocationTypeSelected}
      isVendorUser={isVendorUser}
    />
  );
};

ToolingStockActionModalContainer.propTypes = {
  show: PropTypes.bool.isRequired,
  hideModal: PropTypes.func.isRequired,
  toolingStock: PropTypes.shape({
    uri: PropTypes.string.isRequired,
    status: PropTypes.string.isRequired,
  }).isRequired,
};

export default ToolingStockActionModalContainer;
