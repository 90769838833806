import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Col, ListGroup, ListGroupItem, OverlayTrigger, Row, Tooltip } from 'react-bootstrap';
import { FormattedDuration, FormattedMessage, FormattedVolume } from 'rapidfab/i18n';
import FormattedLocalizedCost from 'rapidfab/components/FormattedLocalizedCost';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { lineItemActualsType, lineItemPerStepActualsType } from 'rapidfab/types';
import _some from 'lodash/some';
import { MATERIAL_UNITS } from 'rapidfab/constants';
import { faChevronDown, faChevronRight, faQuestionCircle } from '@fortawesome/free-solid-svg-icons';

const ActualsRow = ({ processStep, actuals, hideFinancial }) => {
  const [collapsed, setCollapsed] = useState(true);

  const isPrintingStep = processStep.step_position === 1;

  const actualsTotal = actuals && actuals.total;
  const actualsPerPiece = actuals && actuals.per_piece_average;

  const collapsedFields = ['duration', 'running_cost', 'material_cost'];
  if (isPrintingStep) {
    // Model Volume is used on Printing step only
    collapsedFields.push('model_volume');
  }

  const isCollapsible = _some(collapsedFields, fieldName => (
    (actualsTotal && actualsTotal[fieldName])
    || (actualsPerPiece && actualsPerPiece[fieldName])
  ));

  const totalDuration = actualsTotal && actualsTotal.duration;
  const perPieceDuration = actualsPerPiece && actualsPerPiece.duration;
  const isDurationSet = totalDuration || perPieceDuration;

  const totalModelVolume = actualsTotal && actualsTotal.model_volume;
  const perPieceModelVolume = actualsPerPiece && actualsPerPiece.model_volume;
  // Model Volume is displayed on the Printing Step only
  const isModelVolumeSet = isPrintingStep && (totalModelVolume || perPieceModelVolume);

  const totalRunningCost = actualsTotal && actualsTotal.running_cost;
  const perPieceRunningCost = actualsPerPiece && actualsPerPiece.running_cost;
  const isRunningCostSet = totalRunningCost || perPieceRunningCost;

  const totalMaterialCost = actualsTotal && actualsTotal.material_cost;
  const perPieceMaterialCost = actualsPerPiece && actualsPerPiece.material_cost;
  const isMaterialCostSet = totalMaterialCost || perPieceMaterialCost;

  const totalCost = actualsTotal && actualsTotal.total_cost;
  const perPieceCost = actualsPerPiece && actualsPerPiece.total_cost;

  return (
    <ListGroupItem>
      <Row>
        <Col xs={4}>
          {isCollapsible && (
            <FontAwesomeIcon
              className="p-r"
              icon={collapsed ? faChevronRight : faChevronDown}
              onClick={() => setCollapsed(!collapsed)}
            />
          )}
          {processStep.name}
        </Col>
        {!hideFinancial && (
          <>
            <Col xs={4}>
              {
                perPieceCost
                  ? <FormattedLocalizedCost value={perPieceCost} />
                  : <FormattedMessage id="notAvailable" defaultMessage="N/A" />
              }
            </Col>
            <Col xs={4}>
              {
                totalCost
                  ? <FormattedLocalizedCost value={totalCost} />
                  : <FormattedMessage id="notAvailable" defaultMessage="N/A" />
              }
            </Col>
          </>
        )}
      </Row>
      {!collapsed && (
        <div className="small">
          {isDurationSet && (
            <Row>
              <Col xs={4}>
                <span className="pull-right">
                  <FormattedMessage id="runningTime" defaultMessage="Running Time" />
                </span>
              </Col>
              <Col xs={4}>
                {
                  perPieceDuration
                    ? <FormattedDuration value={perPieceDuration} />
                    : <FormattedMessage id="notAvailable" defaultMessage="N/A" />
                }
              </Col>
              <Col xs={4}>
                {
                  totalDuration
                    ? <FormattedDuration value={totalDuration} />
                    : <FormattedMessage id="notAvailable" defaultMessage="N/A" />
                }
              </Col>
            </Row>
          )}
          {isModelVolumeSet && (
            <Row>
              <Col xs={4}>
                <span className="pull-right">
                  <FormattedMessage id="modelVolume" defaultMessage="Model Volume" />
                </span>
              </Col>
              <Col xs={4}>
                {
                  perPieceModelVolume
                    ? <FormattedVolume value={perPieceModelVolume} valueUnits={MATERIAL_UNITS.MM3} />
                    : <FormattedMessage id="notAvailable" defaultMessage="N/A" />
                }
              </Col>
              <Col xs={4}>
                {
                  totalModelVolume
                    ? <FormattedVolume value={totalModelVolume} valueUnits={MATERIAL_UNITS.MM3} />
                    : <FormattedMessage id="notAvailable" defaultMessage="N/A" />
                }
              </Col>
            </Row>
          )}
          {isRunningCostSet && !hideFinancial && (
            <Row>
              <Col xs={4}>
                <span className="pull-right">
                  <FormattedMessage id="runningCost" defaultMessage="Running Cost" />
                </span>
              </Col>
              <Col xs={4}>
                {
                  perPieceRunningCost
                    ? <FormattedLocalizedCost value={perPieceRunningCost} />
                    : <FormattedMessage id="notAvailable" defaultMessage="N/A" />
                }
              </Col>
              <Col xs={4}>
                {
                  totalRunningCost
                    ? <FormattedLocalizedCost value={totalRunningCost} />
                    : <FormattedMessage id="notAvailable" defaultMessage="N/A" />
                }
              </Col>
            </Row>
          )}
          {isMaterialCostSet && !hideFinancial && (
            <Row>
              <Col xs={4}>
                <span className="pull-right">
                  <FormattedMessage id="materialCost" defaultMessage="Material Cost" />
                </span>
              </Col>
              <Col xs={4}>
                {
                  perPieceMaterialCost
                    ? <FormattedLocalizedCost value={perPieceMaterialCost} />
                    : <FormattedMessage id="notAvailable" defaultMessage="N/A" />
                }
              </Col>
              <Col xs={4}>
                {
                  totalMaterialCost
                    ? <FormattedLocalizedCost value={totalMaterialCost} />
                    : <FormattedMessage id="notAvailable" defaultMessage="N/A" />
                }
              </Col>
            </Row>
          )}
        </div>
      )}
    </ListGroupItem>
  );
};

ActualsRow.propTypes = {
  processStep: PropTypes.shape({
    name: PropTypes.string.isRequired,
    step_position: PropTypes.number,
  }).isRequired,
  actuals: lineItemPerStepActualsType,
  hideFinancial: PropTypes.bool,
};

ActualsRow.defaultProps = {
  actuals: null,
  hideFinancial: false,
};

const Actuals = ({ processSteps, actualsByProcessStepUri, lineItemActuals, hideFinancial }) => (
  <>
    <ListGroup fill>
      <ListGroupItem key="header">
        <Row>
          <Col xs={4}>
            <b>
              <FormattedMessage id="field.name" defaultMessage="Name" />
            </b>
          </Col>
          <Col xs={4}>
            <b>
              <FormattedMessage id="perPiece" defaultMessage="Per Piece" />
            </b>
          </Col>
          <Col xs={4}>
            <b>
              <FormattedMessage id="total" defaultMessage="Total" />
            </b>
            {lineItemActuals && lineItemActuals.has_custom_actuals && (
              <OverlayTrigger
                placement="top"
                overlay={(
                  <Tooltip>
                    <FormattedMessage
                      id="actualsTooltip"
                      defaultMessage="Some Pieces were given custom workflows. Their costs are omitted from the Line Item, please view the Piece(s) for specific details."
                    />
                  </Tooltip>
                )}
              >
                <FontAwesomeIcon className="pull-right" icon={faQuestionCircle} />
              </OverlayTrigger>
            )}
          </Col>
        </Row>
      </ListGroupItem>

      {processSteps.map(processStep => {
        const actuals = actualsByProcessStepUri[processStep.uri];
        return (<ActualsRow processStep={processStep} actuals={actuals} hideFinancial={hideFinancial} />);
      })}
    </ListGroup>
  </>
);

Actuals.propTypes = {
  actualsByProcessStepUri: PropTypes.objectOf(
    PropTypes.shape({}),
  ).isRequired,
  lineItemActuals: lineItemActualsType,
  processSteps: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  hideFinancial: PropTypes.bool,
};

Actuals.defaultProps = {
  lineItemActuals: null,
  hideFinancial: false,
};

export default Actuals;
