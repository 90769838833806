import { createSelector } from 'reselect';
import { getStateResources } from 'rapidfab/selectors/helpers/base';
import _map from 'lodash/map';
import * as baseStateSelectors from 'rapidfab/selectors/baseStateSelectors';

// If you're using this selector for line-item, please make sure
// that you might have prep workflow record for order level (order=uri, line_item=null),
// and this prep workflow record is related to line item
// eslint-disable-next-line import/prefer-default-export
export const getPrepWorkflowRecords = createSelector(
  [baseStateSelectors.getStatePrepWorkflowRecords, getStateResources],
  (uuids, resources) => _map(uuids, uuid => resources[uuid]),
);
