import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import {
  ORDER_CUSTOMER_GO_MAP,
  ORDER_PLANNING_STATUS_MAP,
  ORDER_BUILD_FILE_STATUS_MAP,
} from 'rapidfab/mappings';
import {
  FEATURES,
  BOEING_ORDER_CUSTOM_FIELDS,
  STATUS_COLOR_CODE_TYPES,
} from 'rapidfab/constants';
import {
  isFeatureEnabled,
  isCurrentUserRestricted,
  getCustomOrderFieldReferences,
} from 'rapidfab/selectors';
import { createOrReplaceArray } from 'rapidfab/utils/arrayUtils';
import {
  Col,
  FormLabel,
  FormGroup,
  Card,
  FormControl,
} from 'react-bootstrap';
import { FormattedMessage, FormattedMessageMappingOption } from 'rapidfab/i18n';
import StatusColorCode from 'rapidfab/components/StatusColorCode';
import getOrderFormOptions, {
  ORDER_FORM_TYPES,
} from 'rapidfab/utils/orderFormOptions';
import { finalFormInputTypes } from 'rapidfab/types';
import _find from 'lodash/find';
import _get from 'lodash/get';
import _keyBy from 'lodash/keyBy';

import { Field } from 'react-final-form';
import { DateInput } from 'rapidfab/components/forms/DateInput';

const FormRow = ({
  id,
  defaultMessage,
  children,
  includeStatusDot,
  colorKey,
  statusColorType,
}) => (
  <FormGroup className="d-flex align-items-center">
    <Col xs={includeStatusDot ? 3 : 4}>
      <FormLabel className="control-label--text-left">
        <FormattedMessage id={id} defaultMessage={defaultMessage} />
      </FormLabel>
    </Col>
    {includeStatusDot && (
      <Col xs={1}>
        <StatusColorCode
          sizeClassName="medium"
          type={statusColorType}
          status={colorKey}
        />
      </Col>
    )}
    <Col xs={8}>{children}</Col>
  </FormGroup>
);
FormRow.defaultProps = {
  includeStatusDot: true,
  colorKey: '',
  statusColorType: STATUS_COLOR_CODE_TYPES.BOEING_FIELD_STATUS,
};
FormRow.propTypes = {
  id: PropTypes.string.isRequired,
  colorKey: PropTypes.string,
  defaultMessage: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,
  includeStatusDot: PropTypes.bool,
  statusColorType: PropTypes.string,
};

const BAMProcessingStatus = ({
  isNormalUser,
  isDanfossUser,
  isBoeingUser,
  is3MUser,
  customOrderFieldReferences,
  initialValues,
}) => {
  const options = getOrderFormOptions({
    isNormalUser,
    isDanfossUser,
    isBoeingUser,
    is3MUser,
  }, ORDER_FORM_TYPES.EDIT);

  const financeReviewReference = _find(
    customOrderFieldReferences,
    { field_id: BOEING_ORDER_CUSTOM_FIELDS.FINANCE_REVIEW },
  );

  const customFieldValuesByURI = _keyBy(initialValues.custom_field_values, 'custom_field');
  const financeReviewValue = _get(
    customFieldValuesByURI,
    [financeReviewReference.uri, 'value'],
    financeReviewReference.defaultValue || '',
  );

  return (
    <Card bg="dark" className="mb15">
      <Card.Header className="pd-exp inverse">Processing Status</Card.Header>
      <div className="card-body-wrapper">
        <Card.Body>
          <FormRow
            id="field.dueDate"
            defaultMessage="Due Date"
            includeStatusDot={false}
          >
            <Field
              name="due_date"
              type="date"
              initialValue={initialValues?.due_date}
              render={props => (
                <DateInput {...props.input} />
              )}
            />
          </FormRow>
          <FormRow
            id="fields.customer_go"
            defaultMessage="Customer Go"
            statusColorType={STATUS_COLOR_CODE_TYPES.YES_NO}
            isRequired={options.customer_go.required}
            colorKey={initialValues?.customer_go}
          >
            <Field
              name="customer_go"
              initialValue={initialValues?.customer_go}
              render={props => (
                <FormControl
                  {...props.input}
                  as="select"
                  required={options.customer_go.required}
                >
                  {options.customer_go.required ? (
                    <FormattedMessage id="field.choose" defaultMessage="Choose…">
                      {text => (
                        <option value="" disabled>
                          {text}
                        </option>
                      )}
                    </FormattedMessage>
                  ) : (
                    <FormattedMessage id="field.none" defaultMessage="None">
                      {text => <option value="none">{text}</option>}
                    </FormattedMessage>
                  )}
                  {Object.keys(ORDER_CUSTOMER_GO_MAP).map(value => (
                    <FormattedMessageMappingOption
                      key={value}
                      mapping={ORDER_CUSTOMER_GO_MAP}
                      value={value}
                    />
                  ))}
                </FormControl>
              )}
            />
          </FormRow>
          <FormRow
            id="fields.planning_status"
            defaultMessage="Planning Status"
            isRequired={options.planning_status.required}
            colorKey={initialValues?.planning_status}
          >
            <Field
              name="planning_status"
              initialValue={initialValues?.planning_status}
              render={props => (
                <FormControl
                  {...props.input}
                  as="select"
                  required={options.planning_status.required}
                >
                  {options.planning_status.required ? (
                    <FormattedMessage id="field.choose" defaultMessage="Choose…">
                      {text => (
                        <option value="" disabled>
                          {text}
                        </option>
                      )}
                    </FormattedMessage>
                  ) : (
                    <FormattedMessage id="field.none" defaultMessage="None">
                      {text => <option value="none">{text}</option>}
                    </FormattedMessage>
                  )}
                  {Object.keys(ORDER_PLANNING_STATUS_MAP).map(status => (
                    <FormattedMessageMappingOption
                      key={status}
                      mapping={ORDER_PLANNING_STATUS_MAP}
                      value={status}
                    />
                  ))}
                </FormControl>
              )}
            />
          </FormRow>
          <FormRow
            id="fields.build_file_status"
            defaultMessage="Build File Status"
            isRequired={options.build_file_status.required}
            colorKey={initialValues?.build_file_status}
          >
            <Field
              name="build_file_status"
              initialValue={initialValues?.build_file_status}
              render={props => (
                <FormControl
                  {...props.input}
                  as="select"
                  required={options.build_file_status.required}
                >
                  {options.build_file_status.required ? (
                    <FormattedMessage id="field.choose" defaultMessage="Choose…">
                      {text => (
                        <option value="" disabled>
                          {text}
                        </option>
                      )}
                    </FormattedMessage>
                  ) : (
                    <FormattedMessage id="field.none" defaultMessage="None">
                      {text => <option value="none">{text}</option>}
                    </FormattedMessage>
                  )}
                  {Object.keys(ORDER_BUILD_FILE_STATUS_MAP).map(status => (
                    <FormattedMessageMappingOption
                      key={status}
                      mapping={ORDER_BUILD_FILE_STATUS_MAP}
                      value={status}
                    />
                  ))}
                </FormControl>
              )}
            />
          </FormRow>
          <FormRow
            id="field.financeReview"
            defaultMessage="Finance Review"
            isRequired={financeReviewReference.required}
            enabled={financeReviewReference.enabled}
            colorKey={financeReviewValue}
            statusColorType={STATUS_COLOR_CODE_TYPES.BOEING_FINANCE_REVIEW_STATUS}
          >
            <Field
              name="custom_field_values"
              initialValue={initialValues?.custom_field_values}
              render={props => (
                <FormControl
                  as="select"
                  required={financeReviewReference.required}
                  value={financeReviewValue}
                  onChange={event => {
                    const customFieldValuesReplaced = createOrReplaceArray(
                      props.input.value,
                      { custom_field: financeReviewReference.uri },
                      { value: event.target.value },
                    );
                    props.input.onChange(customFieldValuesReplaced);
                  }}
                >
                  {financeReviewReference.required && (
                    <FormattedMessage id="field.choose" defaultMessage="Choose…">
                      {text => (
                        <option value="" disabled>
                          {text}
                        </option>
                      )}
                    </FormattedMessage>
                  )}
                  {financeReviewReference.options.map(field => (
                    <option key={field.value} value={field.value}>
                      {field.key}
                    </option>
                  ))}
                </FormControl>
              )}
            />
          </FormRow>
        </Card.Body>
      </div>
    </Card>
  );
};
BAMProcessingStatus.propTypes = {
  initialValues: PropTypes.shape({
    custom_field_values: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
    due_date: PropTypes.string.isRequired,
    customer_go: PropTypes.string.isRequired,
    planning_status: PropTypes.string.isRequired,
    build_file_status: PropTypes.string.isRequired,
  }).isRequired,
  input: finalFormInputTypes.isRequired,
  isNormalUser: PropTypes.bool.isRequired,
  isDanfossUser: PropTypes.bool.isRequired,
  isBoeingUser: PropTypes.bool.isRequired,
  is3MUser: PropTypes.bool.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  customOrderFieldReferences: PropTypes.arrayOf(PropTypes.object).isRequired,
};

const mapStateToProps = state => ({
  customOrderFieldReferences: getCustomOrderFieldReferences(state),
  isNormalUser: !isCurrentUserRestricted(state),
  isBoeingUser: isFeatureEnabled(state, FEATURES.BOEING_ORDER_FIELDS),
  isDanfossUser: isFeatureEnabled(state, FEATURES.ORDER_BUSINESS_SEGMENT),
  is3MUser: isFeatureEnabled(state, FEATURES.THREE_M_MAIN_BUREAU_ORDER_FIELDS),
});

export default connect(mapStateToProps)(BAMProcessingStatus);
