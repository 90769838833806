import PrepWorkflowsContainer from 'rapidfab/containers/plan/PrepWorkflowsContainer';
import ProductionWorkflowsContainer from 'rapidfab/containers/plan/ProductionWorkflowsContainer';
import React from 'react';
import { Route, Routes } from 'react-router-dom';
import PropTypes from 'prop-types';
import { ROUTER_PERMISSION } from '../constantsRouter';
import PrivateRoute from './PrivateRoute';
import { FEATURES } from '../constants';
import Orders from '../containers/plan/orders';
import Runs from '../containers/plan/runs';
import PiecesContainer from '../containers/plan/PiecesContainer';
import ServiceProviderJobsContainer from '../containers/plan/ServiceProviderJobsContainer';
import CreateRunsContainer from '../containers/plan/CreateRunsContainer';
import Prints from '../containers/plan/prints';
import NotFound from '../components/404';
import MSNavImportsContainer from '../containers/plan/MSNavImportsContainer';
import Shipments from '../containers/plan/Shipments';

const PlanRoutes = ({ session }) => (
  <Routes>
    <Route
      path="/orders"
      element={(
        <PrivateRoute
          session={session}
          component={Orders}
          permission={ROUTER_PERMISSION.BUREAU_ONLY}
          features={[FEATURES.MANUFACTURING_EXECUTION_SYSTEM]}
        />
      )}
    />
    <Route
      path="/runs"
      element={(
        <PrivateRoute
          session={session}
          component={Runs}
          permission={ROUTER_PERMISSION.BUREAU_ONLY}
          isRestrictedRoute
          features={{
            [FEATURES.HAWKING_DEPLOYMENT]: false,
          }}
        />
      )}
    />
    <Route
      path="/pieces"
      element={(
        <PrivateRoute
          session={session}
          component={PiecesContainer}
          permission={ROUTER_PERMISSION.BUREAU_ONLY}
          features={{
            [FEATURES.HAWKING_DEPLOYMENT]: false,
          }}
        />
      )}
    />
    <Route
      path="/shipments"
      element={(
        <PrivateRoute
          session={session}
          component={Shipments}
          isRestrictedRoute
          permission={ROUTER_PERMISSION.BUREAU_ONLY}
          decidingFeatures={[
            FEATURES.SHIPMENT_FOR_ORDER,
            FEATURES.SERVICE_PROVIDER_AT_STEPS,
          ]}
          features={
            [FEATURES.MANUFACTURING_EXECUTION_SYSTEM]
          }
        />
      )}
    />
    <Route
      path="/service-provider-jobs"
      element={(
        <PrivateRoute
          session={session}
          component={ServiceProviderJobsContainer}
          permission={ROUTER_PERMISSION.LOGGED_IN_ONLY}
          features={[FEATURES.SERVICE_PROVIDERS]}
        />
      )}
    />
    <Route
      path="/create-runs"
      element={(
        <PrivateRoute
          session={session}
          component={CreateRunsContainer}
          permission={ROUTER_PERMISSION.BUREAU_ONLY}
          features={{
            [FEATURES.HAWKING_DEPLOYMENT]: false,
          }}
        />
      )}
    />
    <Route
      path="/prints"
      element={(
        <PrivateRoute
          session={session}
          component={Prints}
          permission={ROUTER_PERMISSION.BUREAU_ONLY}
          features={{
            [FEATURES.HAWKING_DEPLOYMENT]: false,
          }}
        />
      )}
    />
    <Route
      path="/workflows"
      element={(
        <PrivateRoute
          session={session}
          component={ProductionWorkflowsContainer}
          isRestrictedRoute
          permission={ROUTER_PERMISSION.BUREAU_ONLY}
          features={
            [FEATURES.MANUFACTURING_EXECUTION_SYSTEM]
          }
        />
      )}
    />
    <Route
      path="/prep-workflows"
      element={(
        <PrivateRoute
          session={session}
          component={PrepWorkflowsContainer}
          permission={ROUTER_PERMISSION.BUREAU_ONLY}
          features={
            [FEATURES.PREP_WORKFLOW]
          }
        />
      )}
    />
    <Route
      path="/msnav-imports"
      element={(
        <PrivateRoute
          session={session}
          component={MSNavImportsContainer}
          permission={ROUTER_PERMISSION.BUREAU_ONLY}
          features={[FEATURES.MSNAV_IMPORTER]}
        />
      )}
    />
    <Route
      path="*"
      element={<NotFound />}
    />
  </Routes>
);

PlanRoutes.propTypes = {
  session: PropTypes.shape({}).isRequired,
};

export default PlanRoutes;
