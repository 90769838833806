import { faPlus, faSave, faSpinner, faTrash, faWarning } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import _filter from 'lodash/filter';
import _sortBy from 'lodash/sortBy';
import _map from 'lodash/map';
import PropTypes from 'prop-types';
import BreadcrumbNav from 'rapidfab/components/BreadcrumbNav';
import {
  API_RESOURCES, LINE_ITEM_STATUS,
  MODAL_TEMPLATES, ORDER_STATUS,
  ORDER_UNLOCKED_STATUSES,
  ZIP_FILE_STATUSES,
} from 'rapidfab/constants';
import AddProductContainer from 'rapidfab/containers/AddProductContainer';
import OrderProductsContainer from 'rapidfab/containers/OrderProductsContainer';
import OrderSummaryContainer from 'rapidfab/containers/records/order/OrderSummaryContainer';
import { BulkLineItemActionContext } from 'rapidfab/context/BulkLineItemActionContext';
import { QuoteProcessStepsModalContextProvider } from 'rapidfab/context/QuoteProcessStepsModalContext';
import { useModal } from 'rapidfab/hooks';
import { FormattedMessage } from 'rapidfab/i18n';
import * as Selectors from 'rapidfab/selectors';
import { pluralWord } from 'rapidfab/utils/stringUtils';
import { extractUuid, getShortUUID } from 'rapidfab/utils/uuidUtils';
import React, { memo, useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { Button, Container, Form as BsForm } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import Actions from 'rapidfab/actions';
import Alert from 'rapidfab/utils/alert';
import { useNavigate } from 'react-router-dom';
import ConfirmationModal from 'rapidfab/components/ConfirmationModal';

const EditOrder = ({
  handleLineItemBulkActionModal,
  expandModeState: [expandMode, setExpandMode],
  orderQuoteFFEnabled,
  workstepQuoteDetailsFFEnabled,
}) => {
  const navigate = useNavigate();
  const [linkToForward, setLinkToForward] = useState(null);
  const [leaveConfirmation, setLeaveConfirmation] = useState(false);

  const order = useSelector(Selectors.getRouteUUIDResource) || {};
  const breadcrumbOrderLabel = order?.id ? `${order.name} (${order.customer_id || getShortUUID(order.uuid)})` : '';
  const isOrderUnlocked = ORDER_UNLOCKED_STATUSES.includes(order.status);
  const products = useSelector(state => Selectors.getProductsForOrder(state, order));
  const lineItems = useSelector(state => Selectors.getLineItemsForOrder(state, order));
  const dispatch = useDispatch();
  const [isLoadingZip, setIsLoadingZip] = useState(false);
  const orderZipFiles = useSelector(state => Selectors.getZipFilesForOrder(state, order));

  const isOrderTemplate = useMemo(() => order?.is_template, [order]);

  const lineItemsReferencedByProduct = useMemo(() => _filter(lineItems, lineItem => (
    products.some(product => product.uri === lineItem.product)
  )), [lineItems]);

  const someLineItemsHasErrorStatus = useMemo(() =>
    lineItems.some(lineItem => lineItem.status === LINE_ITEM_STATUS.ERROR), [lineItems]);

  const [lineItemsLength, setLineItemsLength] = useState(lineItems.length);

  const [expandedItems, setExpandedItems] = useState([]);

  const {
    bulkActionMarkedLineItems,
    handleMarkAllLineItemsForBulkAction,
    handleDeleteMarkedLineItems,
  } = useContext(BulkLineItemActionContext);
  const [handleDeleteBulkLineItemsWarningModal, DeleteBulkLineItemsWarningModal] = useModal();

  const orderZipFileContent = useMemo(() => {
    const selectedLineItemUris = JSON.stringify(
      _sortBy(_map(bulkActionMarkedLineItems, lineItem => extractUuid(lineItem.uri))),
    );
    return orderZipFiles.find(
      zipFile => zipFile.status === ZIP_FILE_STATUSES.COMPLETED &&
        JSON.stringify(_sortBy(zipFile.manifest.line_items || [])) === selectedLineItemUris,
    )?.content;
  }, [orderZipFiles, bulkActionMarkedLineItems]);

  useEffect(() => {
    if (lineItemsLength > 0 && expandedItems.length === lineItemsLength) {
      setExpandMode('expanded');
    }
  }, [expandedItems.length, lineItemsLength]);

  const handleExpandAllLineItems = useCallback(() => {
    setExpandMode(previous => {
      if (!previous || previous === 'collapsed') {
        // eslint-disable-next-line unicorn/new-for-builtins
        setExpandedItems([...Array(lineItemsLength)
          .keys()]);

        return 'expanded';
      }

      if (lineItemsLength > 0 && expandedItems.length === lineItemsLength) {
        setExpandedItems([]);
      }

      return 'collapsed';
    });
  }, [lineItemsLength, expandedItems]);

  useEffect(() => {
    setLineItemsLength(lineItems.length);
  }, [lineItems.length]);

  useEffect(() => {
    if (isLoadingZip && orderZipFileContent) {
      window.location = orderZipFileContent;
    }
    setIsLoadingZip(false);
  }, [orderZipFileContent]);

  const zipFilePreventNavigateListener = event => {
    if (event.target.getAttribute('href')) {
      /* Menu navigation is also <a> and has "href",
         we should not show any modals while just clicking on the navigation item. */
      if (event.target.id.includes('uxNav') || event.target.classList.contains('nav-link')) return;
      event.preventDefault();
      setLinkToForward(event.target.hash);
      setLeaveConfirmation(true);
    }
  };

  useEffect(() => {
    if (isLoadingZip) {
      window.addEventListener('click', zipFilePreventNavigateListener);
    } else {
      window.removeEventListener('click', zipFilePreventNavigateListener);
    }
    return () => {
      window.removeEventListener('click', zipFilePreventNavigateListener);
    };
  }, [isLoadingZip]);

  const onDownloadZIP = async () => {
    setIsLoadingZip(true);
    const lineItemUris = bulkActionMarkedLineItems.map(item => item.uri);
    const payload = {
      related_uuid: order.uuid,
      related_table_name: 'order',
      line_items: lineItemUris,
    };
    try {
      const response = await dispatch(Actions.Api.nautilus[API_RESOURCES.ZIPFILE_REQUEST].post(payload));
      const zipFileUri = response.headers.location;
      dispatch(Actions.Api.nautilus[API_RESOURCES.ZIPFILE].get(extractUuid(zipFileUri)));
      Alert.success(
        <FormattedMessage
          id="toaster.zipfile.created"
          defaultMessage="ZIP file created successfully."
        />,
      );
    } catch {
      setIsLoadingZip(false);
    }
  };

  const handleCancelLeavingPage = () => {
    setLeaveConfirmation(false);
  };

  const handleConfirmLeavingPage = () => {
    setLeaveConfirmation(false);
    navigate(linkToForward.replace('#', ''));
    setLinkToForward(null);
  };

  return (
    <Container className="p-b" fluid>
      <DeleteBulkLineItemsWarningModal id="delete-bulk-line-items-warning-modal" />
      <BreadcrumbNav breadcrumbs={['plan', 'orders', breadcrumbOrderLabel]} />
      <QuoteProcessStepsModalContextProvider
        orderQuoteFFEnabled={orderQuoteFFEnabled}
        workstepQuoteDetailsFFEnabled={workstepQuoteDetailsFFEnabled}
      >
        <OrderSummaryContainer isOrderTemplate={isOrderTemplate} />
        {!isOrderTemplate && (
          <>
            <div
              id="line-item-utils-container"
              className="d-flex justify-content-between mr15 w-100 p-b"
            >
              <div id="line-item-utils-leading" className="d-flex align-items-center">
                <BsForm.Check
                  id="bulk-action-select-all-checkbox"
                  className="ml15 mr15"
                  name="bulk-action-select-all-checkbox"
                  checked={lineItemsReferencedByProduct.length > 0 &&
                    bulkActionMarkedLineItems.length === lineItemsReferencedByProduct.length}
                  onChange={() => handleMarkAllLineItemsForBulkAction(lineItemsReferencedByProduct)}
                  type="checkbox"
                />
                <Button
                  variant="primary"
                  onClick={handleLineItemBulkActionModal}
                  disabled={bulkActionMarkedLineItems.length < 1}
                >
                  <FontAwesomeIcon icon={faPlus} className="spacer-right" />

                  Apply to {bulkActionMarkedLineItems.length} {pluralWord('Line Item', bulkActionMarkedLineItems)}
                </Button>
                <Button
                  className="spacer-left"
                  variant="danger"
                  onClick={() => handleDeleteBulkLineItemsWarningModal({
                    size: 'sm',
                    title: 'Warning',
                    headerIcon: <FontAwesomeIcon className="spacer-right" icon={faWarning} />,
                    bodyText: `You are about to delete ${bulkActionMarkedLineItems.length} ${pluralWord('Line Item', bulkActionMarkedLineItems)}.`,
                    modalTemplate: MODAL_TEMPLATES.CANCEL_OR_DELETE,
                    onConfirm: () => handleDeleteMarkedLineItems(bulkActionMarkedLineItems),
                  })}
                  disabled={bulkActionMarkedLineItems.length < 1}
                >
                  <FontAwesomeIcon icon={faTrash} className="spacer-right" />
                  Delete {bulkActionMarkedLineItems.length} {pluralWord('Line Item', bulkActionMarkedLineItems)}

                </Button>
                {bulkActionMarkedLineItems.length > 0 && (
                  <>
                    {isLoadingZip && (
                      <Button variant="primary" className="spacer-left">
                        <FontAwesomeIcon icon={faSpinner} spin />
                      </Button>
                    )}
                    {!isLoadingZip && !orderZipFileContent && (
                      <Button
                        className="spacer-left"
                        variant="primary"
                        onClick={onDownloadZIP}
                      >
                        <FontAwesomeIcon icon={faSave} className="spacer-right" />
                        Save Collection of Models

                      </Button>
                    )}
                    {!isLoadingZip && orderZipFileContent && (
                      // eslint-disable-next-line react/no-unknown-property
                      <a href={orderZipFileContent} download>
                        <Button variant="success" className="spacer-left">
                          <FontAwesomeIcon icon={faSave} className="spacer-right" />
                          Save Collection of Models
                        </Button>
                      </a>
                    )}
                  </>
                )}
              </div>
              {
                lineItemsLength > 1 && (
                  <Button variant="info" onClick={handleExpandAllLineItems}>
                    {expandMode === 'collapsed' || !expandMode || expandedItems.length !== lineItemsLength ? (
                      <FormattedMessage id="button.expandAll" defaultMessage="Expand All" />
                    ) : (
                      <FormattedMessage id="button.collapseAll" defaultMessage="Collapse All" />
                    )}
                  </Button>
                )
              }
            </div>
            <OrderProductsContainer
              orderUri={order.uri}
              expandMode={expandMode}
              expandedItems={expandedItems}
              setExpandedItems={setExpandedItems}
              setExpandMode={setExpandMode}
              handleExpandAllLineItems={handleExpandAllLineItems}
            />
          </>
        )}
      </QuoteProcessStepsModalContextProvider>
      {!isOrderTemplate && (
        !isOrderUnlocked ? (
          <div className="jumbotron">
            <p>
              {
                order.status === ORDER_STATUS.ERROR && someLineItemsHasErrorStatus
                  ? 'Additional line items cannot be added due to an error. Kindly remove the errored line item(s), re-upload them, and try again.'
                  : 'Additional line items cannot be added to a confirmed order. Please create a new order to add additional line items.'
              }
            </p>
          </div>
        ) : (
          <AddProductContainer orderUri={order.uri} lineItemsLength={lineItemsLength} />
        ))}
      {leaveConfirmation && !isOrderTemplate && (
        <ConfirmationModal
          handleCancel={handleCancelLeavingPage}
          handleConfirm={handleConfirmLeavingPage}
          message="Warning: Leaving this page may interrupt the zip file download process. Would you like to continue?"
        />
      )}
    </Container>
  );
};

export default memo(EditOrder);

EditOrder.propTypes = {
  handleLineItemBulkActionModal: PropTypes.func.isRequired,
  expandModeState: PropTypes.arrayOf(PropTypes.func).isRequired,
  orderQuoteFFEnabled: PropTypes.bool.isRequired,
  workstepQuoteDetailsFFEnabled: PropTypes.bool.isRequired,
};
