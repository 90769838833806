import React, { useState } from 'react';
import { faCheck, faChevronDown, faChevronUp, faCopy } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import _capitalize from 'lodash/capitalize';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'rapidfab/i18n';
import { Alert, Badge, Button } from 'react-bootstrap';

export const DebugModeBadge = () => (
  <Badge className="badge badge-sm spacer-right" bg="warning">Debug Mode</Badge>
);

export const DebugModeExpandCollapseButton = ({
  onClick,
  expanded,
  className,
}) => (
  <Button
    size="xs"
    className={className}
    onClick={onClick}
  >
    {expanded ? ([
      <FormattedMessage
        id="button.close"
        defaultMessage="Close"
      />,
      <FontAwesomeIcon className="spacer-left" icon={faChevronUp} />,
    ]) : ([
      <FormattedMessage
        id="button.open"
        defaultMessage="Open"
      />,
      <FontAwesomeIcon className="spacer-left" icon={faChevronDown} />,
    ])}
  </Button>
);

DebugModeExpandCollapseButton.defaultProps = {
  className: null,
};

DebugModeExpandCollapseButton.propTypes = {
  onClick: PropTypes.func.isRequired,
  expanded: PropTypes.bool.isRequired,
  className: PropTypes.string,
};

export const DebugModeCopyButton = ({
  resource,
  resourceKey,
  className,
}) => {
  const [copied, setCopied] = useState(false);

  const handleCopyResourceKey = () => {
    setCopied(true);

    try {
      navigator.clipboard.writeText(resource[resourceKey]);
    } catch (error) {
      Alert.error(error);
    }
  };

  return (
    <Button
      size="xs"
      className={className}
      onClick={handleCopyResourceKey}
    >
      {copied ? 'Copied' : 'Copy'} {!copied && _capitalize(resourceKey)}
      <FontAwesomeIcon className="spacer-left" icon={copied ? faCheck : faCopy} />
    </Button>
  );
};

DebugModeCopyButton.defaultProps = {
  className: null,
};

DebugModeCopyButton.propTypes = {
  resource: PropTypes.shape({}).isRequired,
  resourceKey: PropTypes.string.isRequired,
  className: PropTypes.string,
};
