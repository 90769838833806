import React from 'react';
import PropTypes from 'prop-types';
import { finalFormInputTypes } from 'rapidfab/types';
import Loading from 'rapidfab/components/Loading';
import {
  Button,
  FormLabel,
  FormGroup,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  ModalTitle,
} from 'react-bootstrap';
import { FormattedMessage } from 'react-intl';

import { Form, Field } from 'react-final-form';
import { FormControlSelect } from 'rapidfab/components/formTools';
import HISTORY_COLLECTION_FREQUENCY_TYPES from 'rapidfab/constants/historyCollectionFrequencyTypes';
import FormGroupField from 'rapidfab/components/forms/FormGroupField';
import SaveButton from 'rapidfab/components/SaveButton';

const ModelerModal = ({
  onClose,
  isSubmitting,
  onFormSubmit,
  isLoading,
  modeler,
  modelerTypes,
  initialFormValues,
}) => {
  if (isLoading) {
    return (
      <Modal show backdrop="static">
        <ModalBody>
          <Loading />
        </ModalBody>
      </Modal>
    );
  }

  return (
    <Modal size="lg" show onHide={onClose} backdrop="static">
      <ModalHeader closeButton>
        <ModalTitle>{modeler ? modeler.name : 'Add Printer Link'}</ModalTitle>
      </ModalHeader>
      <ModalBody>
        <Form
          onSubmit={onFormSubmit}
          initialValues={initialFormValues}
          render={({ handleSubmit }) => (
            <form id="modeler" onSubmit={handleSubmit}>
              <FormGroupField
                name="name"
                controlId="modelerName"
                required
                label={(
                  <FormattedMessage
                    id="field.name"
                    defaultMessage="Name"
                  />
                )}
              />
              <FormGroupField
                name="ip_address"
                controlId="modelerIpAddress"
                label={(
                  <FormattedMessage
                    id="field.ipAddress"
                    defaultMessage="IP Address"
                  />
                )}
              />
              <FormGroupField
                name="serial_number"
                controlId="modelerSerialNumber"
                label={(
                  <FormattedMessage
                    id="field.serialNumber"
                    defaultMessage="Serial Number"
                  />
                )}
              />
              <FormGroupField
                name="username"
                controlId="modelerUsername"
                label={(
                  <FormattedMessage id="field.username" defaultMessage="Username" />
                )}
              />
              <FormGroupField
                name="password"
                type="password"
                controlId="modelerPassword"
                label={(
                  <FormattedMessage id="field.password" defaultMessage="Password" />
                )}
                placeholder={modeler && modeler.password ? '*****' : ''}
              />

              <FormGroup controlId="modelerType" className="form-group">
                <FormLabel>
                  <FormattedMessage
                    id="field.printerLinkType"
                    defaultMessage="Printer Link Type"
                  />
                  :
                </FormLabel>
                <Field
                  name="type"
                  render={props => (
                    <FormControlSelect
                      {...props.input}
                    >
                      {modelerTypes.map(modelerType => (
                        <option key={modelerType.uri} value={modelerType.uri}>
                          {modelerType.name}
                        </option>
                      ))}
                    </FormControlSelect>
                  )}
                />
              </FormGroup>

              <FormGroup controlId="modelerFrequency" className="modeler-frequency-dropdown">
                <FormLabel>
                  <FormattedMessage
                    id="field.historyCollectionFrequency"
                    defaultMessage="History Collection Frequency"
                  />
                  :
                </FormLabel>
                <Field
                  name="history_collection_frequency"
                  render={props => (
                    <FormControlSelect
                      {...props.input}
                    >
                      {
                        Object.keys(HISTORY_COLLECTION_FREQUENCY_TYPES).map(type => (
                          <option key={type} value={HISTORY_COLLECTION_FREQUENCY_TYPES[type]}>
                            {HISTORY_COLLECTION_FREQUENCY_TYPES[type]}
                          </option>
                        ))
                      }
                    </FormControlSelect>
                  )}
                />
              </FormGroup>
            </form>
          )}
        />
      </ModalBody>
      <ModalFooter>
        <Button className="pull-right" onClick={onClose}>
          <FormattedMessage id="button.cancel" defaultMessage="Cancel" />
        </Button>
        <SaveButton
          showSaveIcon={false}
          isSaving={isSubmitting || isLoading}
          label={<FormattedMessage id="button.save" defaultMessage="Save" />}
          bsStyle="success"
          className="pull-right ml5"
          form="modeler"
        />
      </ModalFooter>
    </Modal>
  );
};

ModelerModal.defaultProps = {
  isSubmitting: false,
  isLoading: false,
  modeler: null,
};

ModelerModal.propTypes = {
  onFormSubmit: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
  isSubmitting: PropTypes.bool,
  isLoading: PropTypes.bool,
  input: finalFormInputTypes.isRequired,
  initialFormValues: PropTypes.shape({
    name: PropTypes.string.isRequired,
    ip_address: PropTypes.string.isRequired,
    serial_number: PropTypes.string.isRequired,
    username: PropTypes.string.isRequired,
    password: PropTypes.string.isRequired,
    type: PropTypes.string.isRequired,
  }).isRequired,
  modeler: PropTypes.shape({
    name: PropTypes.string,
    password: PropTypes.string,
  }),
  modelerTypes: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
};

export default ModelerModal;
