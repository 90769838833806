import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import Actions from 'rapidfab/actions';
import LineItemServiceProviders from 'rapidfab/components/records/order/edit/LineItemServiceProviders';
import Loading from 'rapidfab/components/Loading';
import * as Selectors from 'rapidfab/selectors';
import { extractUuid } from 'rapidfab/utils/uuidUtils';
import _isEqual from 'lodash/isEqual';
import { API_RESOURCES } from 'rapidfab/constants';

class ServiceProviderJobListContainer extends Component {
  componentDidMount() {
    this.loadOutsourceProcessProviders();
  }

  componentDidUpdate(prevProps) {
    const { outsourceProcessSteps } = this.props;

    // When outsource process steps list is changed - load processProviders
    // for the new outsource process steps (if any)
    if (!_isEqual(prevProps.outsourceProcessSteps, outsourceProcessSteps)) {
      this.loadOutsourceProcessProviders();
    }
  }

  loadOutsourceProcessProviders() {
    const { outsourceProcessSteps, dispatch } = this.props;
    const outsourceProcessStepPostProcessorUris = outsourceProcessSteps.map(
      outsourceProcessStep => outsourceProcessStep.workstation,
    ).filter(outsourceProcessStepPostProcessorUri => !!outsourceProcessStepPostProcessorUri);
    if (outsourceProcessStepPostProcessorUris.length) {
      dispatch(Actions.Api.nautilus[API_RESOURCES.PROCESS_PROVIDER]
        .list({ post_processor: outsourceProcessStepPostProcessorUris }));
    }
  }

  render() {
    const {
      serviceProviders,
      serviceProvidersByOutsourceProcessStepUri,
      lineItemJobsByServiceProviderUri,
      processStepJobsByServiceProviderUri,
      lineItem,
      fetching,
      outsourceProcessSteps,
    } = this.props;

    if (fetching) {
      return <Loading />;
    }

    return (
      <LineItemServiceProviders
        lineItem={lineItem}
        serviceProviders={serviceProviders}
        serviceProvidersByOutsourceProcessStepUri={serviceProvidersByOutsourceProcessStepUri}
        lineItemJobsByServiceProviderUri={lineItemJobsByServiceProviderUri}
        processStepJobsByServiceProviderUri={processStepJobsByServiceProviderUri}
        outsourceProcessSteps={outsourceProcessSteps}
      />
    );
  }
}

ServiceProviderJobListContainer.propTypes = {
  fetching: PropTypes.bool.isRequired,
  dispatch: PropTypes.func.isRequired,
  lineItem: PropTypes.string.isRequired,
  serviceProviders: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  serviceProvidersByOutsourceProcessStepUri: PropTypes.objectOf(PropTypes.shape({})).isRequired,
  lineItemJobsByServiceProviderUri: PropTypes.objectOf(PropTypes.shape({})).isRequired,
  processStepJobsByServiceProviderUri: PropTypes.objectOf(PropTypes.shape({})).isRequired,
  outsourceProcessSteps: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
};

const mapStateToProps = (state, ownProps) => {
  const lineItem = ownProps.lineItem
    && Selectors.getUUIDResource(state, extractUuid(ownProps.lineItem));
  const workflowUri = lineItem && lineItem.workflow;
  return {
    lineItemJobsByServiceProviderUri:
      Selectors.getJobsByServiceProviderUriForLineItem(state, ownProps.lineItem),
    processStepJobsByServiceProviderUri:
      Selectors.getOutsourceJobsByServiceProviderUriForLineItem(state, ownProps.lineItem),
    serviceProviders: Selectors.getServiceProviders(state),
    serviceProvidersByOutsourceProcessStepUri:
      Selectors.getServiceProvidersByOutsourceProcessStepUriForWorkflow(state, workflowUri),
    outsourceProcessSteps: Selectors.getOutsourceProcessStepsForWorkflow(state, workflowUri),
    fetching: state.ui.nautilus[API_RESOURCES.SERVICE_PROVIDER_JOB].list.fetching ||
      state.ui.nautilus[API_RESOURCES.SERVICE_PROVIDER].list.fetching,
  };
};

export default connect(mapStateToProps)(ServiceProviderJobListContainer);
