import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import {
  EVENT_KEYS,
  getCustomEventContent,
  getEventReferenceResourceType,
} from 'rapidfab/utils/tracebilityReportEvents';
import DocumentDownloadLink from 'rapidfab/components/records/piece/event/DocumentDownloadLink';
import EventValue from 'rapidfab/components/records/piece/event/EventValue';
import { useDispatch } from 'react-redux';
import Actions from 'rapidfab/actions';
import { API_RESOURCES } from 'rapidfab/constants';
import Loading from 'rapidfab/components/Loading';

const EventContent = ({ event }) => {
  // In case there is a custom content - render it instead of default variant
  const [isLoading, setIsLoading] = useState(false);
  const dispatch = useDispatch();
  const customContent = getCustomEventContent(event);

  useEffect(() => {
    const promises = [];
    if (event.key === EVENT_KEYS.MODEL_UUID) {
      if (event.previous_value && event.previous_value !== 'None') {
        promises.push(dispatch(Actions.Api.nautilus[API_RESOURCES.MODEL].get(event.previous_value)));
      }
      if (event.current_value && event.current_value !== 'None') {
        promises.push(dispatch(Actions.Api.nautilus[API_RESOURCES.MODEL].get(event.current_value)));
      }
    }
    setIsLoading(true);
    Promise.all(promises).finally(() => setIsLoading(false));
  }, [event.current_value, event.previous_value, dispatch]);

  if (customContent) {
    return customContent;
  }
  if (isLoading) return <Loading />;

  const resourceType = getEventReferenceResourceType(event.reference);
  const previousEventExists = (event.previous_value && event.previous_value !== 'None') || false;

  if (event.key === 'tool') {
    return (
      <span>
        <b>
          <EventValue value={previousEventExists ? event.previous_value : event.current_value} />
        </b>
        {previousEventExists ? ' (completed use)' : ' (put into use)'}
      </span>
    );
  }

  return (
    <>
      {
        previousEventExists && (
          <span>
            <span>from </span>
            <b><EventValue value={event.previous_value} /></b>
          </span>
        )
      }
      <span> to </span>
      <b><EventValue value={event.current_value} /></b>
      {(resourceType === 'document') && (
        <DocumentDownloadLink uri={event.reference} />
      )}
    </>
  );
};

EventContent.propTypes = {
  event: PropTypes.shape({
    previous_value: PropTypes.number,
    current_value: PropTypes.number,
    reference: PropTypes.string,
    key: PropTypes.string,
  }).isRequired,
};

export default EventContent;
