import cadfileFR from './cadfile/fr.json';
import documentsFR from './documents/fr.json';
import estimatesFR from './estimates/fr.json';
import quoteFR from './quote/fr.json';
import shipmentsFR from './shipments/fr.json';
import summaryFR from './summary/fr.json';

const recordsFR = Object.assign(cadfileFR, documentsFR, estimatesFR, quoteFR, shipmentsFR, summaryFR);

export default recordsFR;
