import React, { useState, useEffect, useCallback } from 'react';
import PropTypes from 'prop-types';
import { Button } from 'react-bootstrap';
import { toast } from 'react-toastify';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faThumbtack, faArrowRotateLeft } from '@fortawesome/free-solid-svg-icons';

const PinToast = ({ autoClose, message, toastId }) => {
  const [isPinned, setIsPinned] = useState(false);

  useEffect(() => {
    toast.update(toastId, {
      autoClose: isPinned ? false : autoClose,
      closeOnClick: !isPinned,
      draggable: !isPinned,
    });
  }, [isPinned, toastId]);

  const handlePin = useCallback(() => {
    setIsPinned(previous => !previous);
  }, []);

  return (
    <div role="button" tabIndex={0} className="toast-content" onClick={event => event.stopPropagation()}>
      <div>{message}</div>
      <div className="toast-actions">
        <Button variant="link" className="toast-action-button" onClick={handlePin}>
          <FontAwesomeIcon icon={isPinned ? faArrowRotateLeft : faThumbtack} color="white" className="spacer-right" />
          {isPinned ? 'Unpin Toast' : 'Pin Toast'}
        </Button>
      </div>
    </div>
  );
};

PinToast.propTypes = {
  message: PropTypes.string.isRequired,
  toastId: PropTypes.string.isRequired,
  autoClose: PropTypes.number.isRequired,
};

export default PinToast;
