import { DDW_PAYMENT_STATUSES } from 'rapidfab/constants';

/**
 * @description Gets appropriate background colours and messages to display based on payment status.
 *
 * @typedef StatusData
 * @property {string} bg
 * @property {string} message
 *
 * @param {object} payment
 * @returns {StatusData}
 */
// eslint-disable-next-line import/prefer-default-export
export const getPaymentStatusData = payment => {
  if (payment && payment?.status === DDW_PAYMENT_STATUSES.READY) {
    return {
      bg: 'warning',
      message: 'Ready',
    };
  }

  if (payment && payment?.status === DDW_PAYMENT_STATUSES.COMPLETE) {
    return {
      bg: 'success',
      message: 'Complete',
    };
  }

  return {
    bg: 'secondary',
    message: 'No Payment Requested',
  };
};
