import React from 'react';
import PropTypes from 'prop-types';
import { FEATURES } from 'rapidfab/constants';
import Feature from 'rapidfab/components/Feature';

// can use this component when
// Non-Additive text is needed to be used in place of an additive text,
// for a particular buearu using feature flag.
const UseNonMfgLanguageFeature = ({
  mfgLanguageComponent,
  nonMfgLanguageComponent,
}) => (
  <>
    <Feature featureName={FEATURES.GENERAL_MFG_LANGUAGE} isInverted>
      {mfgLanguageComponent}
    </Feature>
    <Feature featureName={FEATURES.GENERAL_MFG_LANGUAGE}>
      {nonMfgLanguageComponent}
    </Feature>
  </>
);

UseNonMfgLanguageFeature.propTypes = {
  mfgLanguageComponent: PropTypes.node.isRequired,
  nonMfgLanguageComponent: PropTypes.node.isRequired,

};

export default UseNonMfgLanguageFeature;
