import _map from 'lodash/map';
import PropTypes from 'prop-types';
import { selectInputStyles } from 'rapidfab/constants/styles';
import React from 'react';
import { FormLabel, OverlayTrigger } from 'react-bootstrap';
import Select from 'react-select';

/**
 * Inline mapped filters list component for TableWithSearching.jsx.
 */
const TableInlineFilters = ({ onChange, filters, values }) => (
  <div className="form-inline" style={{ lineHeight: '40px' }}>
    {
      _map(filters, filterItem => {
        const renderField = () => (
          <Select
            name={filterItem.fieldId}
            value={values[filterItem.fieldId]}
            defaultValue={filterItem.defaultValue}
            styles={selectInputStyles}
            options={filterItem.options}
            onChange={value => onChange(filterItem.fieldId, value)}
            isDisabled={filterItem.disabled}
            disabled={filterItem.disabled}
          />
        );

        return (
          <div key={filterItem.fieldId} className="form-group mr15">
            <FormLabel htmlFor={filterItem.fieldId}>
              {filterItem.label}:
            </FormLabel>
            <div className="spacer-left form-control inline-picky-wrapper">
              {
                filterItem.disabled ?
                  (
                    <OverlayTrigger
                      overlay={filterItem.disabledTooltip}
                    >
                      <div>
                        {renderField()}
                      </div>
                    </OverlayTrigger>
                  ) : (
                    renderField()
                  )
              }
            </div>
          </div>
        );
      })
    }
  </div>
);

export default TableInlineFilters;

TableInlineFilters.propTypes = {
  onChange: PropTypes.func.isRequired,
  filters: PropTypes.arrayOf(PropTypes.shape({
    fieldId: PropTypes.string.isRequired,
    options: PropTypes.arrayOf(PropTypes.shape({
      value: PropTypes.string,
      label: PropTypes.string.isRequired,
    })).isRequired,
  })).isRequired,
  values: PropTypes.objectOf(PropTypes.shape({})).isRequired,
};
