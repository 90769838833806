import ConfirmationModal from 'rapidfab/components/ConfirmationModal';
import CustomMultiSelect from 'rapidfab/components/forms/CustomMultiSelect';
import FormGroupField from 'rapidfab/components/forms/FormGroupField';
import UseNonMfgLanguageFeature from 'rapidfab/components/generalMfgLanguage/UseNonMfgLanguageFeature';
import ModalGroupedSection from 'rapidfab/components/modals/ModalGroupedSection';
import BatchTypeModal from 'rapidfab/components/records/BatchTypeModal';
import { resetRcTooltipInnerStyle, selectInputStyles } from 'rapidfab/constants/styles';
import { ModalGroupedSectionProvider } from 'rapidfab/context/ModalGroupedSectionProvider';
import PrinterTypeIcon from 'rapidfab/icons/printer-type-icon';
import { getRouteURI, isURI } from 'rapidfab/utils/uriUtils';
import RCTooltip from 'rc-tooltip';
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import {
  Button,
  Col,
  FormLabel,
  FormControl,
  FormGroup,
  Card,
  Row,
  SplitButton,
  Badge, Dropdown,
  InputGroup,
  Form as ReactBootStrapForm, Modal, ButtonToolbar,
} from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { FormattedMessage } from 'react-intl';

import CostTooltip from 'rapidfab/components/CostTooltip';
import { extractUuid, getShortUUID } from 'rapidfab/utils/uuidUtils';
import WorkChecklistContainer from 'rapidfab/containers/records/WorkChecklistContainer';
import LaborDuration from 'rapidfab/components/LaborDuration';
import { FormControlCost, FormControlSelect } from 'rapidfab/components/formTools';
import {
  ACCESS_INFO_ACTION_TYPES,
  FEATURES, LABOR_TYPE,
  REACT_SELECT_ACTIONS, ROUTES,
} from 'rapidfab/constants';
import DisabledByAccessInfoCheck from 'rapidfab/components/DisabledByAccessInfoCheck';

import { Form, Field } from 'react-final-form';
import { REVIEW_TYPES } from 'rapidfab/constants/reviewTypes';
import { finalFormInputTypes } from 'rapidfab/types';
import Loading from 'rapidfab/components/Loading';
import { isInHoursMinutesFormat } from 'rapidfab/utils/timeUtils';
import LaborAvailableSchedule from 'rapidfab/components/records/LaborAvailableSchedule';
import Feature from 'rapidfab/components/Feature';
import usePrevious from 'rapidfab/hooks';
import {
  faClose, faExternalLink, faFloppyDisk, faInfoCircle, faPlus,
  faQuestionCircle, faTrash,
} from '@fortawesome/free-solid-svg-icons';
import { Link } from 'react-router-dom';
import CreatableSelect from 'react-select/creatable';
import IntegratedWorkstationCheck from '../IntegratedWorkstationCheck';

const PostProcessorTypeModal = ({
  uuid,
  isVisible,
  fetching,
  deleting,
  onDelete,
  onArchive,
  onUnarchive,
  onSubmit,
  manufacturers,
  customGroups,
  materials,
  defaultCurrency,
  postProcessorType,
  isGroupQualificationsFeatureEnabled,
  initialFormValues,
  isEditing,
  onSelectAllMaterials,
  onDeselectAllMaterials,
  submitting,
  laborWorkstationEnable,
  isLaborWorkflowEnabled,
  handleChangeLaborTime,
  handleSetLaborNonStopTime,
  laborScheduleTime,
  laborNonStopEnabled,
  workScheduleEnabled,
  handleTriggerWorkSchedule,
  handleUTC_TimezoneSetting,
  handleSetWorkday,
  workDays,
  UTC_TimeZone,
  handleSubmitManufacturer,
  isManufacturerFetching,
  sectionsWithMissingFields,
  handleCloseModal,
  groupedSectionsVisibilityState,
  handleSetSectionVisibilityState,
  setCurrentBatchingStrategy,
  unarchiving,
}) => {
  const postProcessorTypeNameTooltipLength = 50;

  const groupedSectionTitles = {
    general: 'General',
    costDuration: 'Cost / Duration',
    advanced: 'Advanced Settings',
  };

  const [isAllMaterialsButtonSelected, setIsAllMaterialsButtonSelected] =
    useState(initialFormValues?.materials?.length === materials?.length);
  const [confirmDelete, setConfirmDelete] = React.useState(false);
  const [isDurationInvalid, setIsDurationInvalid] = useState(false);
  const [laborDurationSelected, setLaborDurationSelected] = useState(
    initialFormValues.laborType === LABOR_TYPE.LABOR_TO_RUN);
  const [, setLaborToStart] = useState(initialFormValues.laborType === 'labor_to_start');
  const [currentDurationValue, setCurrentDurationValue] = useState(initialFormValues.duration);
  const [currentLaborDurationValue, setCurrentLaborDurationValue] = useState(initialFormValues.workstation_duration);
  const [isLaborDurationInvalid, setIsLaborDurationInvalid] = useState(false);
  const [isLaborDurationDisabled, setIsLaborDurationDisabled] = useState(false);

  const formattedManufacturers = manufacturers.map(manufacturer => ({
    label: manufacturer.name,
    value: manufacturer.uri,
  })).filter(Boolean);

  const previousDuration = usePrevious(initialFormValues.workstation_duration);

  /* There are cases when initialFormValues.workstation_duration is undefined or 00:00
     as the value has not refreshed on time, and we need to track the changes in the value
     in order to update the input field and show the new data. */

  useEffect(() => {
    if (initialFormValues.workstation_duration !== previousDuration) {
      setCurrentLaborDurationValue(initialFormValues.workstation_duration);
    }
  }, [initialFormValues.workstation_duration, previousDuration]);

  const handleManufacturersChange = async (selectedOption, actionMeta, onChange) => {
    // The user would like to create the new manufacturer "on the fly"
    if (actionMeta.action === REACT_SELECT_ACTIONS.CREATE_OPTION) {
      // Send API request to create the new manufacturer with the "name" field
      const manufacturerUri = await handleSubmitManufacturer(selectedOption.label);
      if (manufacturerUri) {
        // Set the new manufacturer URI as the value of the select input
        return onChange(manufacturerUri);
      }
    }
    const newValue = selectedOption ? selectedOption.value : '';
    return onChange(newValue);
  };

  const handleConfirmDeletePostProcessorType = () => {
    setConfirmDelete(false);
    return onDelete(initialFormValues?.uuid);
  };

  return (
    <Modal
      size="lg"
      show={isVisible || uuid || initialFormValues?.uuid}
      onHide={handleCloseModal}
      scrollable
      backdrop="static"
      dialogClassName="custom-darken-modal"
    >
      {
        fetching ? <Loading className="p-a-md" /> : (
          <Form
            onSubmit={onSubmit}
            initialValues={initialFormValues.in_progress_max ? initialFormValues :
              { ...initialFormValues, in_progress_max: 1 }}
            mutators={{
              onSelectAllMaterials,
              onDeselectAllMaterials,
              onChangeLaborType: ([field, value], state, { changeValue }) => {
                changeValue(state, field, () => value);
              },
              toggleIntegratedWorkStation: (_, state, { changeValue }) => {
                const { integrated: initialValue } = state.formState.values;
                changeValue(
                  state,
                  'integrated',
                  () => !initialValue,
                );
              },
            }}
            render={({ handleSubmit, form, values }) => (
              <form onSubmit={handleSubmit}>
                <Modal.Header>
                  <Modal.Title className="w-100 d-flex align-items-center justify-content-between">
                    <div className="d-flex align-items-center">
                      <PrinterTypeIcon className="custom-navbar-icon-white" />

                      <p className="custom-darken-modal-title-text">
                        {initialFormValues?.uuid && initialFormValues?.name ?
                          (
                            initialFormValues.name.length >= postProcessorTypeNameTooltipLength ?
                              (
                                <div className="d-flex align-items-center">
                                  <RCTooltip
                                    placement="bottom"
                                    id="printerTypeNameTooltip"
                                    destroyTooltipOnHide
                                    overlayInnerStyle={resetRcTooltipInnerStyle}
                                    mouseLeaveDelay={0.4}
                                    overlay={(
                                      <p className="mb0 darkTooltip">
                                        {initialFormValues.name}
                                      </p>
                                    )}
                                  >
                                    <div className="d-flex align-items-center">
                                      <p className="mb0 truncate custom-darken-modal-title-text-title">
                                        {initialFormValues.name}
                                      </p>
                                      ({getShortUUID(initialFormValues.uuid)})
                                    </div>
                                  </RCTooltip>
                                </div>
                              ) : (
                                <span>{initialFormValues.name}
                                  &nbsp;({getShortUUID(initialFormValues.uuid)})
                                </span>
                              )
                          ) : (
                            <FormattedMessage
                              id="createPostProcessorType"
                              defaultMessage="Create Post Processor Type"
                            />
                          )}
                        {postProcessorType?.archived && (
                          <Badge bg="default" className="spacer-left badge-sm">
                            <FormattedMessage id="field.archived" defaultMessage="Archived" />
                          </Badge>
                        )}
                      </p>
                    </div>
                    <FontAwesomeIcon
                      icon={faClose}
                      onClick={handleCloseModal}
                      tabIndex={0}
                      role="button"
                      className="custom-darken-modal-button"
                    />
                  </Modal.Title>
                </Modal.Header>
                <Modal.Body className="custom-darken-modal--body-scroll">
                  <Row className="custom-darken-modal-custom-gutter">

                    <ModalGroupedSectionProvider
                      sectionsWithMissingFields={sectionsWithMissingFields}
                      groupedSectionTitles={groupedSectionTitles}
                      handleSetSectionVisibilityState={handleSetSectionVisibilityState}
                    >
                      <ModalGroupedSection
                        isSectionVisible={groupedSectionsVisibilityState.general}
                        sectionTitle={groupedSectionTitles.general}
                      >
                        <Row>
                          <Col lg={6} xs={12}>
                            <Field
                              name="name"
                              type="text"
                              render={props => (
                                <FormGroupField
                                  {...props.input}
                                  name="name"
                                  controlId="uxName"
                                  required
                                  label={(
                                    <FormattedMessage
                                      id="field.name"
                                      defaultMessage="Name"
                                    />
                                  )}
                                />
                              )}
                            />

                            <Field
                              name="description"
                              type="text"
                              render={props => (
                                <FormGroupField
                                  {...props.input}
                                  as="textarea"
                                  name="description"
                                  controlId="uxDescription"
                                  required
                                  label={(
                                    <FormattedMessage
                                      id="field.description"
                                      defaultMessage="Description"
                                    />
                                  )}
                                />
                              )}
                            />

                            <FormGroup className="form-group" controlId="uxManufacturer">
                              <FormLabel>
                                <FormattedMessage
                                  id="field.manufacturer"
                                  defaultMessage="Manufacturer"
                                />: *

                                {isURI(values?.manufacturer) && (
                                  <Link
                                    target="_blank"
                                    className="spacer-left"
                                    rel="noopener noreferrer"
                                    to={getRouteURI(ROUTES.MANUFACTURER_EDIT,
                                      { uuid: extractUuid(values.manufacturer) },
                                      null,
                                      true)}
                                  >
                                    <FontAwesomeIcon icon={faExternalLink} />
                                  </Link>
                                )}

                              </FormLabel>
                              <Field
                                name="manufacturer"
                                render={props => {
                                  const selectedOption = formattedManufacturers.find(option =>
                                    option.value === props.input.value) || null;
                                  return (
                                    <CreatableSelect
                                      {...props.input}
                                      styles={selectInputStyles}
                                      isLoading={isManufacturerFetching}
                                      placeholder={(
                                        <FormattedMessage
                                          id="field.selectManufacturer"
                                          defaultMessage="Select Manufacturer"
                                        />
                                      )}
                                      isDisabled={isManufacturerFetching}
                                      components={{ LoadingIndicator: () => (<Loading inline className="spacer-right" />) }}
                                      value={selectedOption}
                                      options={formattedManufacturers}
                                      required
                                      onChange={(value, actionMeta) =>
                                        handleManufacturersChange(value, actionMeta, props.input.onChange)}
                                      isClearable
                                    />
                                  );
                                }}
                              />
                            </FormGroup>

                            <BatchTypeModal
                              fields={initialFormValues}
                              setCurrentBatchingStrategy={setCurrentBatchingStrategy}
                            />

                            <FormGroup className="form-group" controlId="uxCapacity">
                              <FormLabel>
                                <FormattedMessage
                                  id="field.Capacity"
                                  defaultMessage="Max Capacity"
                                />: *
                                {' '}

                                <RCTooltip
                                  placement="top"
                                  id="maxCapacity"
                                  destroyTooltipOnHide
                                  overlayInnerStyle={resetRcTooltipInnerStyle}
                                  mouseLeaveDelay={0.4}
                                  overlay={(
                                    <p className="mb0 darkTooltip">
                                      <FormattedMessage
                                        id="maxCapacityTooltip"
                                        defaultMessage="Maximum parallel runs allowed on this workstation type."
                                      />
                                    </p>
                                  )}
                                >
                                  <FontAwesomeIcon className="spacer-left spacer-right" icon={faQuestionCircle} />
                                </RCTooltip>
                              </FormLabel>
                              <Field
                                name="in_progress_max"
                                type="number"
                                render={props => (
                                  <FormControl
                                    {...props.input}
                                    required
                                    min="1"
                                    max="32767"
                                    inputmode="numeric"
                                    data-cy="form-max-capacity-input-field"
                                  />
                                )}
                              />
                            </FormGroup>

                            <FormGroup className="form-group" controlId="uxUserGroups">
                              <FormLabel>
                                <FormattedMessage id="userGroups" defaultMessage="User Groups" />:

                                <RCTooltip
                                  placement="top"
                                  id="userGroupsTooltip"
                                  destroyTooltipOnHide
                                  overlayInnerStyle={resetRcTooltipInnerStyle}
                                  mouseLeaveDelay={0.4}
                                  overlay={(
                                    <p className="mb0 darkTooltip">
                                      <UseNonMfgLanguageFeature
                                        mfgLanguageComponent={(
                                          <FormattedMessage
                                            id="printerTypeEditGroupHelperText"
                                            defaultMessage="Assigning a Printer Type to a User Group will result in the associated Printers, Runs and Workflows will only be editable by users within the User Group"
                                          />
                                        )}
                                        nonMfgLanguageComponent={(
                                          <FormattedMessage
                                            id="mfg.printer.printerTypeEditGroupHelperText"
                                            defaultMessage="Assigning a Production Device Type to a User Group will result in the associated Production Devices, Runs and Workflows will only be editable by users within the User Group"
                                          />
                                        )}
                                      />
                                    </p>
                                  )}
                                >
                                  <FontAwesomeIcon icon={faInfoCircle} className="spacer-left" />
                                </RCTooltip>
                              </FormLabel>
                              <Field
                                name="edit_group"
                                render={props => (
                                  <FormControlSelect
                                    {...props.input}
                                    className="mb15"
                                    disabled={!isGroupQualificationsFeatureEnabled}
                                  >
                                    <FormattedMessage id="all" defaultMessage="All">{text =>
                                      <option value="">{text}</option>}
                                    </FormattedMessage>
                                    {customGroups.map(customGroup => (
                                      <option
                                        value={customGroup.uri}
                                        key={customGroup.uuid}
                                      >
                                        {customGroup.name}
                                      </option>
                                    ))}
                                  </FormControlSelect>
                                )}
                              />
                            </FormGroup>

                          </Col>
                          <Col lg={6} xs={12}>

                            <FormGroup className="form-group" controlId="uxMaterials">
                              <Field
                                name="materials"
                                render={({ input }) => (
                                  <CustomMultiSelect
                                    value={input.value}
                                    onChange={input.onChange}
                                    isCardMode
                                    required
                                    disabled={isAllMaterialsButtonSelected}
                                    defaultNullableValue={null}
                                    customCardCss="custom-darken-modal--card"
                                    customCardHeaderCss="custom-darken-modal--card-header"
                                    customCardBodyCss="custom-darken-modal--card-body"
                                    customCardBg=""
                                    customCardBorder=""
                                    formLabel={(
                                      <FormLabel className="d-flex align-items-center justify-content-between">
                                        <div className="d-flex align-items-center">
                                          <FormattedMessage id="materials" defaultMessage="Materials" />:
                                          *
                                        </div>

                                        <div className="d-flex align-items-center">
                                          <RCTooltip
                                            placement="top"
                                            id="userGroupsTooltip"
                                            destroyTooltipOnHide
                                            overlayInnerStyle={resetRcTooltipInnerStyle}
                                            mouseLeaveDelay={0.4}
                                            overlay={(
                                              <p className="mb0 darkTooltip">
                                                This selection only applies to all the current materials
                                                &nbsp;<b>on this list</b>,
                                                any new materials added in the future will need to be continuously
                                                selected manually.
                                              </p>
                                            )}
                                          >
                                            <FontAwesomeIcon className="spacer-right" icon={faQuestionCircle} />
                                          </RCTooltip>
                                          <Button
                                            className="spacer-left btn-xs"
                                            data-cy="select-all-materials-button"
                                            variant={isAllMaterialsButtonSelected ? 'link' : 'info'}
                                            onClick={() => {
                                              setIsAllMaterialsButtonSelected(!isAllMaterialsButtonSelected);
                                              if (isAllMaterialsButtonSelected) form.mutators.onDeselectAllMaterials();
                                              else form.mutators.onSelectAllMaterials();
                                            }}
                                          >
                                            {!isAllMaterialsButtonSelected ? (
                                              <FormattedMessage
                                                id="field.selectAllMaterials"
                                                defaultMessage="Select all materials"
                                              />
                                            ) : (
                                              <FormattedMessage
                                                id="field.deselectAllMaterials"
                                                defaultMessage="Deselect all materials"
                                              />
                                            )}
                                          </Button>
                                        </div>
                                      </FormLabel>
                                    )}
                                    title={(
                                      <FormattedMessage
                                        id="material.settings"
                                        defaultMessage="Material Settings"
                                      />
                                    )}
                                    options={materials}
                                    placeholder="Select materials"
                                  />
                                )}
                              />
                            </FormGroup>

                            {
                              postProcessorType && (
                                <WorkChecklistContainer
                                  workstep={postProcessorType.uri}
                                  wrapPanel
                                  isCustomDarkCardStyle
                                />
                              )
                            }

                          </Col>
                        </Row>
                      </ModalGroupedSection>

                      <ModalGroupedSection
                        isSectionVisible={groupedSectionsVisibilityState.costDuration}
                        sectionTitle={groupedSectionTitles.costDuration}
                      >
                        <Row>
                          <Col lg={6} xs={12}>

                            <FormGroup className="form-group" controlId="uxDuration">
                              <FormLabel>Workstation Default Duration: *
                                <RCTooltip
                                  placement="top"
                                  id="workstationDuration"
                                  destroyTooltipOnHide
                                  overlayInnerStyle={resetRcTooltipInnerStyle}
                                  mouseLeaveDelay={0.4}
                                  overlay={(
                                    <p className="mb0 darkTooltip">
                                      <FormattedMessage
                                        id="WorkstationDurationTooltip"
                                        defaultMessage="This is how long the Workstation runs for"
                                      />
                                    </p>
                                  )}
                                >
                                  <FontAwesomeIcon icon={faQuestionCircle} className="spacer-left" />
                                </RCTooltip>
                              </FormLabel>

                              <InputGroup>
                                <Field
                                  name="duration"
                                  type="text"
                                  validate={value => !isInHoursMinutesFormat(value)}
                                  initialValue={initialFormValues.duration}
                                  render={fieldProps => {
                                    setIsDurationInvalid(fieldProps.meta.touched && fieldProps.meta.invalid);
                                    return (
                                      <>
                                        <FormControl
                                          {...fieldProps.input}
                                          onChange={event => {
                                            setCurrentDurationValue(event.target.value);
                                            fieldProps.input.onChange(event.target.value);
                                            if (laborDurationSelected) {
                                              setCurrentLaborDurationValue(event.target.value);
                                            }
                                          }}
                                          required
                                          isInvalid={(fieldProps.meta.touched && fieldProps.meta.invalid && fieldProps.input.value !== '')}
                                        />
                                      </>
                                    );
                                  }}
                                />

                                <InputGroup.Text>
                                  <FormattedMessage
                                    id="field.workstation-duration"
                                    defaultMessage="HH:MM"
                                  />
                                </InputGroup.Text>

                              </InputGroup>
                              {isDurationInvalid && (
                                <span className="text-start text-danger">Please use HH:MM format above</span>)}
                            </FormGroup>
                            <FormGroup className="form-group" controlId="uxCost">
                              <FormLabel>
                                <FormattedMessage
                                  id="field.costPerMinute"
                                  defaultMessage="Cost Per Minute"
                                />: *
                                {' '}
                                <CostTooltip />
                              </FormLabel>
                              <FormControlCost
                                name="cost"
                                initialValue={initialFormValues?.cost}
                                type="number"
                                required
                                currency={defaultCurrency}
                              />
                            </FormGroup>
                          </Col>

                          <Col lg={6} xs={12}>

                            <Card className="custom-darken-modal--card mb15">
                              <Card.Header className="pd-exp custom-darken-modal--card-header">
                                Custom Bureau Cost
                              </Card.Header>
                              <Card.Body className="custom-darken-modal--card-body">
                                <Row>
                                  <Col sm={6}>
                                    <FormGroup className="form-group">
                                      <FormLabel>
                                        <FormattedMessage
                                          id="overheadCostPerPiece"
                                          defaultMessage="Overhead Cost Per Piece"
                                        />:
                                      </FormLabel>
                                      <Field
                                        name="overhead_cost_per_piece_in_run"
                                        type="number"
                                        render={props => (
                                          <FormControlCost
                                            {...props.input}
                                            name="overhead_cost_per_piece_in_run"
                                            initialValue={initialFormValues?.overhead_cost_per_piece_in_run || 0}
                                            type="number"
                                            currency={defaultCurrency}
                                          />
                                        )}
                                      />
                                    </FormGroup>
                                  </Col>
                                  <Col sm={6}>
                                    <FormGroup>
                                      <FormLabel>
                                        <FormattedMessage
                                          id="overheadCostPerRun"
                                          defaultMessage="Overhead Cost Per Run"
                                        />:
                                      </FormLabel>
                                      <Field
                                        name="overhead_cost_per_run"
                                        type="text"
                                        render={props => (
                                          <FormControlCost
                                            {...props.input}
                                            name="overhead_cost_per_run"
                                            initialValue={initialFormValues?.overhead_cost_per_run || 0}
                                            type="number"
                                            currency={defaultCurrency}
                                          />
                                        )}
                                      />
                                    </FormGroup>
                                  </Col>
                                </Row>
                              </Card.Body>
                            </Card>

                          </Col>
                        </Row>
                      </ModalGroupedSection>

                      <ModalGroupedSection
                        isSectionVisible={groupedSectionsVisibilityState.advanced}
                        sectionTitle={groupedSectionTitles.advanced}
                      >
                        <Row>
                          <Feature featureName={FEATURES.WORK_SCHEDULE}>
                            <Col lg={6} xs={12}>
                              <LaborDuration
                                toggleLaborWorkWorkField={laborWorkstationEnable}
                                isLaborWorkstationEnabled={isLaborWorkflowEnabled}
                                isCustomDarkCardStyle
                              >
                                <FormGroup className="form-group" controlId="uxlabor">

                                  <FormLabel>
                                    <FormattedMessage
                                      id="field.labor"
                                      defaultMessage="Type of Labor Needed for post processor type"
                                    />
                                  </FormLabel>
                                  <Field
                                    name="laborType"
                                    type="radio"
                                    value="labor_to_run"
                                    checked={
                                      initialFormValues.laborType === LABOR_TYPE.LABOR_TO_RUN
                                      && laborDurationSelected
                                    }
                                  >
                                    {props => (

                                      <ReactBootStrapForm.Check
                                        name={props.input.name}
                                        value={props.input.value}
                                        checked={(
                                          initialFormValues.laborType === LABOR_TYPE.LABOR_TO_RUN
                                            && laborDurationSelected) ||
                                          values.laborType === LABOR_TYPE.LABOR_TO_RUN}
                                        onChange={() => form.mutators.onChangeLaborType('laborType', LABOR_TYPE.LABOR_TO_RUN)}
                                        onClick={() => {
                                          setLaborDurationSelected(true);
                                          setLaborToStart(false);
                                          setIsLaborDurationDisabled(true);
                                          setCurrentLaborDurationValue(currentDurationValue);
                                        }}
                                        type="radio"
                                        label="Continuous Labor (person is working full workstation duration)"
                                        disabled={!isLaborWorkflowEnabled}
                                      />

                                    )}
                                  </Field>

                                  <Field
                                    name="laborType"
                                    type="radio"
                                    value="labor_to_start"
                                    disabled={!isLaborWorkflowEnabled}
                                    checked={initialFormValues.laborType === 'labor_to_start' && !laborDurationSelected}
                                  >
                                    {props => (
                                      <ReactBootStrapForm.Check
                                        checked={(initialFormValues.laborType === 'labor_to_start' && !laborDurationSelected) ||
                                          values.laborType === 'labor_to_start'}
                                        name={props.input.name}
                                        value={props.input.value}
                                        onChange={() => form.mutators.onChangeLaborType('laborType', 'labor_to_start')}
                                        onClick={() => {
                                          setLaborDurationSelected(false);
                                          setIsLaborDurationDisabled(false);
                                          setLaborToStart(true);
                                        }}
                                        type="radio"
                                        label="Labor to Start (person is working less time than workstation duration, at start of work)"
                                        disabled={!isLaborWorkflowEnabled}
                                      />

                                    )}
                                  </Field>
                                </FormGroup>

                                {!laborDurationSelected && (
                                  <FormGroup className="form-group" controlId="uxLaborDuration">
                                    <Row>
                                      <Col>
                                        <FormLabel>Default Labor Duration:</FormLabel>
                                        <InputGroup>
                                          <Field
                                            name="workstation_duration"
                                            type="text"
                                            initialValue={initialFormValues.workstation_duration || '00:00'}
                                            validate={value => (!isInHoursMinutesFormat(value))}

                                            render={fieldProps => {
                                              setIsLaborDurationInvalid(
                                                laborDurationSelected
                                                && currentLaborDurationValue !== currentDurationValue);
                                              return (
                                                <>
                                                  <FormControl
                                                    {...fieldProps.input}
                                                    value={currentLaborDurationValue}
                                                    required
                                                    onChange={event => {
                                                      setCurrentLaborDurationValue(event.target.value);
                                                      fieldProps.input.onChange(event.target.value);
                                                    }}
                                                    isInvalid={(fieldProps.meta.touched
                                                      && fieldProps.meta.invalid) || isLaborDurationInvalid}
                                                    disabled={!isLaborWorkflowEnabled || isLaborDurationDisabled}
                                                  />

                                                </>
                                              );
                                            }}
                                          />

                                          <InputGroup.Text>
                                            <FormattedMessage
                                              id="field.workstation-duration"
                                              defaultMessage="HH:MM"
                                            />

                                          </InputGroup.Text>

                                        </InputGroup>
                                      </Col>

                                    </Row>
                                    {(laborDurationSelected && isLaborDurationInvalid) && (
                                      <div className="text-start text-danger">
                                        Continuous labor is selected, please select labor to
                                        start or make sure default labor duration time matches default
                                        workstation duration time
                                      </div>
                                    )}
                                  </FormGroup>
                                )}
                              </LaborDuration>

                              <LaborAvailableSchedule
                                handleChange={handleChangeLaborTime}
                                handleSetNonStopTime={handleSetLaborNonStopTime}
                                scheduleTime={laborScheduleTime}
                                nonStopEnabled={laborNonStopEnabled}
                                workSchedule={workScheduleEnabled}
                                setWorkSchedule={handleTriggerWorkSchedule}
                                setUTC_Timezone={handleUTC_TimezoneSetting}
                                handleSetWorkday={handleSetWorkday}
                                workDays={workDays}
                                UTC_TimeZone={UTC_TimeZone}
                                isCustomDarkCardStyle
                              />
                            </Col>
                          </Feature>

                          <Col lg={6} xs={12}>
                            <Card className="custom-darken-modal--card mb15">
                              <Card.Header className="pd-exp custom-darken-modal--card-header">
                                Additional Settings
                              </Card.Header>
                              <Card.Body className="custom-darken-modal--card-body">
                                <Feature featureName={FEATURES.JENI_CLUSTER}>
                                  <IntegratedWorkstationCheck
                                    toggleIntegratedWorkStation={form.mutators.toggleIntegratedWorkStation}
                                    isIntegratedWorkstationEnabled={values.integrated}
                                    machineType="Post-Processor-Type"
                                  />
                                </Feature>
                                <FormGroup controlId="uxCustomGroup">
                                  <FormLabel>Review Type: *</FormLabel>
                                  <Field
                                    name="special_type"
                                    type="select"
                                    render={props => (
                                      <FormControlSelect
                                        {...props.input}
                                        disabled={isEditing}
                                        className="mb15"
                                      >
                                        <FormattedMessage id="postProcessorTypeOption.None" defaultMessage="None">{text =>
                                          <option value={null}>{text}</option>}
                                        </FormattedMessage>
                                        <FormattedMessage
                                          id="postProcessorTypeOption.NonConformanceReview"
                                          defaultMessage="Non-Conformance Review"
                                        >
                                          {text => <option value={REVIEW_TYPES.NON_CONFORMANCE_REVIEW}>{text}</option>}
                                        </FormattedMessage>
                                      </FormControlSelect>
                                    )}
                                  />
                                </FormGroup>
                                <p>
                                  <FormattedMessage
                                    id="postProcessorTypeEditAdvancedSettingsHelperText"
                                    defaultMessage="Indicates if this Post Processor is for special Reviews. Select the type of review which is applies."
                                  />
                                  <a href="/to-be-continued"> More Information</a>
                                </p>
                              </Card.Body>
                            </Card>
                          </Col>
                        </Row>
                      </ModalGroupedSection>

                    </ModalGroupedSectionProvider>

                  </Row>
                </Modal.Body>
                <Modal.Footer>

                  <DisabledByAccessInfoCheck
                    resourceUri={postProcessorType?.uri}
                    actionType={ACCESS_INFO_ACTION_TYPES.EDIT}
                    renderDisabledPrefix
                    tooltipPlacement="bottom"
                  >
                    {({ disabled }) => (
                      <ButtonToolbar className="pull-right">
                        {initialFormValues?.id && (
                          <SplitButton
                            id="uxActionDropdown"
                            type="button"
                            variant="warning"
                            size="sm"
                            className="bg-warning"
                            title={deleting || unarchiving ? <Loading /> : (
                              <FormattedMessage
                                id={postProcessorType?.archived ? 'button.unarchive' : 'button.archive'}
                                defaultMessage={postProcessorType?.archived ? 'Unarchive' : 'Archive'}
                              />
                            )}
                            onClick={() => (postProcessorType?.archived ?
                              onUnarchive(values) :
                              onArchive(initialFormValues?.uuid))}
                            disabled={disabled || submitting || deleting || unarchiving}
                          >
                            <Dropdown.Item
                              variant="danger"
                              eventKey={1}
                              onClick={() => setConfirmDelete(true)}
                              disabled={!initialFormValues?.id}
                            >
                              {deleting ? <Loading /> : (
                                <>
                                  <FontAwesomeIcon icon={faTrash} className="spacer-right" />
                                  <FormattedMessage id="button.delete" defaultMessage="Delete" />
                                </>
                              )}
                            </Dropdown.Item>
                          </SplitButton>
                        )}
                        <Button type="submit" disabled={disabled || submitting || unarchiving} variant="success" className="spacer-left">
                          {(submitting && !unarchiving) ? <Loading /> : (
                            <>
                              <FontAwesomeIcon icon={initialFormValues?.id ? faFloppyDisk : faPlus} className="spacer-right" />
                              <FormattedMessage
                                id={initialFormValues?.id ? 'button.save' : 'button.createResource'}
                                defaultMessage={initialFormValues?.id ? 'Save' : 'Create'}
                              />
                            </>
                          )}
                        </Button>
                      </ButtonToolbar>
                    )}
                  </DisabledByAccessInfoCheck>
                </Modal.Footer>
              </form>
            )}
          />
        )
      }
      {confirmDelete && (
        <ConfirmationModal
          handleCancel={() => setConfirmDelete(false)}
          handleConfirm={handleConfirmDeletePostProcessorType}
          message={(
            <FormattedMessage
              id="message.entityDeletePrompt"
              defaultMessage="Are you sure you want to delete this {entityType}?"
              values={{ entityType: 'Post Processor Type' }}
            />
          )}
          confirmButtonContent={<FormattedMessage id="button.delete" defaultMessage="Delete" />}
        />
      )}
    </Modal>
  );
};

PostProcessorTypeModal.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  onDelete: PropTypes.func.isRequired,
  onArchive: PropTypes.func.isRequired,
  onUnarchive: PropTypes.func.isRequired,
  manufacturers: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  customGroups: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  materials: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  defaultCurrency: PropTypes.string.isRequired,
  postProcessorType: PropTypes.shape({
    uri: PropTypes.string,
    archived: PropTypes.string,
  }),
  uuid: PropTypes.string,
  input: finalFormInputTypes.isRequired,
  initialFormValues: PropTypes.shape({
    name: PropTypes.string,
    description: PropTypes.string,
    manufacturer: PropTypes.string,
    materials: PropTypes.arrayOf(PropTypes.string),
    edit_group: PropTypes.string,
    special_type: PropTypes.string,
    uuid: PropTypes.string,
    id: PropTypes.string,
    duration: PropTypes.string,
    cost: PropTypes.string,
    in_progress_max: PropTypes.number,
    workstation_duration: PropTypes.string,
    laborType: PropTypes.string,
    overhead_cost_per_run: PropTypes.number,
    overhead_cost_per_piece_in_run: PropTypes.number,
  }).isRequired,
  isGroupQualificationsFeatureEnabled: PropTypes.bool.isRequired,
  isEditing: PropTypes.bool.isRequired,
  onSelectAllMaterials: PropTypes.func.isRequired,
  onDeselectAllMaterials: PropTypes.func.isRequired,
  submitting: PropTypes.bool.isRequired,
  laborWorkstationEnable: PropTypes.func.isRequired,
  isLaborWorkflowEnabled: PropTypes.bool.isRequired,
  handleChangeLaborTime: PropTypes.func.isRequired,
  handleSetLaborNonStopTime: PropTypes.func.isRequired,
  laborScheduleTime: PropTypes.shape({}).isRequired,
  laborNonStopEnabled: PropTypes.bool.isRequired,
  workScheduleEnabled: PropTypes.bool.isRequired,
  handleTriggerWorkSchedule: PropTypes.func.isRequired,
  handleUTC_TimezoneSetting: PropTypes.func.isRequired,
  isWorkScheduleFeatureEnabled: PropTypes.bool.isRequired,
  handleSetWorkday: PropTypes.func.isRequired,
  workDays: PropTypes.arrayOf(PropTypes.string).isRequired,
  UTC_TimeZone: PropTypes.number.isRequired,
  handleSubmitManufacturer: PropTypes.func.isRequired,
  isManufacturerFetching: PropTypes.bool.isRequired,
  sectionsWithMissingFields: PropTypes.shape({}),
  handleCloseModal: PropTypes.func.isRequired,
  groupedSectionsVisibilityState: PropTypes.shape({
    general: PropTypes.bool,
    costDuration: PropTypes.bool,
    advanced: PropTypes.bool,
  }).isRequired,
  handleSetSectionVisibilityState: PropTypes.func.isRequired,
  setCurrentBatchingStrategy: PropTypes.func.isRequired,
  unarchiving: PropTypes.bool,
  isVisible: PropTypes.bool.isRequired,
  fetching: PropTypes.bool,
  deleting: PropTypes.bool,
};

PostProcessorTypeModal.defaultProps = {
  uuid: '',
  fetching: false,
  deleting: false,
  unarchiving: false,
  postProcessorType: null,
  sectionsWithMissingFields: {},
};

export default PostProcessorTypeModal;
