import { getRouteUUID } from 'rapidfab/selectors';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Actions from 'rapidfab/actions';
import Loading from 'rapidfab/components/Loading';
import { getRouteURI } from 'rapidfab/utils/uriUtils';
import NotFound from 'rapidfab/components/404';
import { extractUuid } from 'rapidfab/utils/uuidUtils';
import { API_RESOURCES, RELATED_TABLE_NAMES, ROUTES } from 'rapidfab/constants';
import { useNavigate, useSearchParams } from 'react-router-dom';

const MyActionsContainer = () => {
  const [searchParams] = useSearchParams();
  const actionType = searchParams.get('action_type');
  const uuid = useSelector(getRouteUUID);
  const loading = useSelector(state => state.ui.nautilus[API_RESOURCES.LINE_ITEM].get.fetching
    || state.ui.nautilus[API_RESOURCES.DOWNTIME].get.fetching,
  );

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const downtimeNavigate = resource => {
    // checks what type of machine downtime is assigned to
    // and redirects to that resource
    if (resource?.printer) {
      navigate(getRouteURI(ROUTES.PRINTER_EDIT,
        { uuid: extractUuid(resource?.printer) }, {}, true));
      return;
    }
    if (resource?.post_processor) {
      navigate(getRouteURI(ROUTES.POST_PROCESSOR_EDIT,
        { uuid: extractUuid(resource?.post_processor) }, {}, true));
    }
  };

  const redirectToResource = resource => {
    // determines what resource we will be redirecting a user to based on the related table
    switch (actionType) {
      case RELATED_TABLE_NAMES.LINE_ITEM:
        navigate(getRouteURI(ROUTES.ORDER_EDIT, { uuid: extractUuid(resource?.order) }, {}, true));
        break;
      case RELATED_TABLE_NAMES.DOWNTIME:
        downtimeNavigate(resource);
        break;
      default:
        break;
    }
  };

  const onInitialize = resourceUUID => {
    // page is loaded it looks at the action type in url params and decides
    // what resource to fetch
    switch (actionType) {
      case RELATED_TABLE_NAMES.LINE_ITEM:
        dispatch(
          Actions.Api.nautilus[API_RESOURCES.LINE_ITEM]
            .get(resourceUUID)).then(({ json }) => {
          redirectToResource(json);
        });
        break;
      case RELATED_TABLE_NAMES.DOWNTIME:
        dispatch(
          Actions.Api.nautilus[API_RESOURCES.DOWNTIME]
            .get(resourceUUID)).then(({ json }) => {
          redirectToResource(json);
        });
        break;
      default:
        break;
    }
  };

  useEffect(() => {
    onInitialize(uuid);
  }, []);

  if (!loading) {
    return <NotFound />;
  }
  // This page is only used for redirect at the moment, so show loading always
  return (
    <Loading />
  );
};

export default MyActionsContainer;
