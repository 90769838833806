import React, { useState } from 'react';
import { Button, FormControl, Modal } from 'react-bootstrap';
import { Field, Form } from 'react-final-form';
import FormRow from 'rapidfab/components/FormRow';
import PropTypes from 'prop-types';
import Loading from 'rapidfab/components/Loading';

const ThreadsLoginModal = ({
  onSignin,
  onClose,
  isThreadsApiFetching,
}) => {
  const [isSigninUnsuccessful, setIsSigninUnsuccessful] = useState(false);
  const onUnsuccessfulSignin = () => setIsSigninUnsuccessful(true);

  return (
    <Modal show>
      <Form
        onSubmit={formValues => onSignin(formValues, onUnsuccessfulSignin)}
        render={({ handleSubmit }) => (
          <>
            <Modal.Header onHide={onClose} closeButton>
              Login to Threads
            </Modal.Header>
            <Modal.Body>
              <Field
                name="email"
                render={({ input }) => (
                  <FormRow
                    id="email"
                    defaultMessage="Email"
                  >
                    <FormControl
                      type="email"
                      onChange={input.onChange}
                      placeholder="user@email.com"
                    />
                  </FormRow>
                )}
              />
              <Field
                name="password"
                render={({ input }) => (
                  <FormRow
                    id="field.password"
                    defaultMessage="Password"
                  >
                    <FormControl
                      type="password"
                      onChange={input.onChange}
                    />
                  </FormRow>
                )}
              />
              {isSigninUnsuccessful &&
              <span className="m-t text-danger">Something went wrong, please check your login details and try again.</span>}
            </Modal.Body>
            <Modal.Footer>
              <Button
                type="submit"
                onClick={handleSubmit}
                disabled={isThreadsApiFetching}
              >
                {isThreadsApiFetching ? <Loading /> : 'Login'}
              </Button>
            </Modal.Footer>
          </>
        )}
      />
    </Modal>
  );
};

export default ThreadsLoginModal;

ThreadsLoginModal.propTypes = {
  onSignin: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
  isThreadsApiFetching: PropTypes.bool.isRequired,
};
