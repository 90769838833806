/* eslint-disable linebreak-style */
import React from 'react';
import { Card, Row, Col } from 'react-bootstrap';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faMinusCircle, faPlusCircle } from '@fortawesome/free-solid-svg-icons';

const LaborDuration = ({
  children,
  toggleLaborWorkWorkField,
  isLaborWorkstationEnabled,
  typesEnabled = true,
  isCustomDarkCardStyle,
}) => (

  <Card bg="dark" className={`mb15 ${isCustomDarkCardStyle ? 'custom-darken-modal--card' : ''}`} data-cy="LaborDuration-container">
    <Card.Header className={`${isCustomDarkCardStyle ? 'pd-exp custom-darken-modal--card-header' : 'pd-exp inverse'}`}>
      <Row className="d-flex justify-content-between align-items-center">
        <Col xs={isCustomDarkCardStyle ? 5 : 7}>
          <FormattedMessage
            id="laborNeedsForScheduling"
            defaultMessage="Labor Needs for Scheduling"
          />:
        </Col>
        <Col>
          <div className="d-flex align-items-center pull-right">
            <FormattedMessage
              id="enableLaborEnhancedWorkflow"
              defaultMessage="Enable Labor Enhanced Workflow"
            />
            <FontAwesomeIcon
              className={`ml15 pointer ${isCustomDarkCardStyle ? 'text-white' : 'removeLaborData'}`}
              icon={isLaborWorkstationEnabled ? faMinusCircle : faPlusCircle}
              size="lg"
              onClick={toggleLaborWorkWorkField}
            />
          </div>
        </Col>
      </Row>
    </Card.Header>
    {isLaborWorkstationEnabled && (
      <div className={`${isCustomDarkCardStyle ? '' : 'card-body-wrapper'}`}>
        <Card.Body className={`${isCustomDarkCardStyle ? 'custom-darken-modal--card-body' : ''}`}>
          {typesEnabled && (
            <Card.Title>Specify type of manual labor needed for post processor type</Card.Title>
          )}
          {children}
        </Card.Body>
      </div>
    )}
  </Card>
);

LaborDuration.defaultProps = {
  typesEnabled: true,
  isCustomDarkCardStyle: false,
};

LaborDuration.propTypes = {
  children: PropTypes.node.isRequired,
  typesEnabled: PropTypes.bool,
  toggleLaborWorkWorkField: PropTypes.func.isRequired,
  isLaborWorkstationEnabled: PropTypes.bool.isRequired,
  isCustomDarkCardStyle: PropTypes.bool,
};

export default LaborDuration;
