import PostProcessorTypeModalContainer from 'rapidfab/containers/modals/PostProcessorTypeModalContainer';
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Form } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import _isNull from 'lodash/isNull';

import Actions from 'rapidfab/actions';
import PostProcessorTypesComponent from 'rapidfab/components/assets/postProcessorTypes';
import * as Selectors from 'rapidfab/selectors';
import { isFeatureEnabled } from 'rapidfab/selectors';
import { API_RESOURCES, FEATURES } from 'rapidfab/constants';
import withRecordsListHandling from 'rapidfab/containers/hocs';
import { useSearchParams } from 'react-router-dom';

const PostProcessorTypesContainer = props => {
  const [searchParams] = useSearchParams();
  const uuid = searchParams.get('uuid');
  const bureau = useSelector(Selectors.getBureauUri);
  const fetching = useSelector(state =>
    state.ui.nautilus[API_RESOURCES.POST_PROCESSOR_TYPE].list.fetching ||
    state.ui.nautilus[API_RESOURCES.MANUFACTURER].list.fetching ||
    state.ui.nautilus[API_RESOURCES.MATERIAL].list.fetching,
  );
  const manufacturers = useSelector(Selectors.getManufacturers);
  const materials = useSelector(Selectors.getMaterials);
  const postProcessorTypes = useSelector(Selectors.getPostProcessorTypes);
  const isGroupQualificationsFeatureEnabled = useSelector(state =>
    isFeatureEnabled(state, FEATURES.GROUP_QUALIFICATIONS),
  );

  const [isCreatePostProcessorTypeModalShown, setIsCreatePostProcessorTypeModalShown] = useState(false);

  const dispatch = useDispatch();

  const onInitialize = currentBureau => {
    dispatch(Actions.Api.nautilus[API_RESOURCES.MANUFACTURER].list());
    dispatch(Actions.Api.nautilus[API_RESOURCES.MATERIAL].list({ bureau: currentBureau }));
    dispatch(Actions.Api.nautilus[API_RESOURCES.POST_PROCESSOR_TYPE].list());
  };

  useEffect(() => onInitialize(bureau), []);

  const onCheckboxChange = event => {
    const { checked } = event.target;
    const { onFilterUpdate, filters } = props;
    const { archived, ...changedFilters } = filters;

    if (checked) {
      changedFilters.archived = null;
    }
    onFilterUpdate(changedFilters);
  };

  const { archived } = props.filters;

  return (
    <>
      <PostProcessorTypesComponent
        {...props}
        isFetching={fetching}
        uuid={uuid}
        materials={materials}
        manufacturers={manufacturers}
        postProcessorTypes={postProcessorTypes}
        openCreateModal={() => setIsCreatePostProcessorTypeModalShown(true)}
        isGroupQualificationsFeatureEnabled={isGroupQualificationsFeatureEnabled}
        extraFilters={[
          <Form.Check
            name="archived"
            checked={_isNull(archived)}
            onChange={onCheckboxChange}
            type="checkbox"
            label="Hide Archived"
          />,
        ]}
      />
      {(isCreatePostProcessorTypeModalShown || uuid) && (
        <PostProcessorTypeModalContainer
          isVisible={isCreatePostProcessorTypeModalShown}
          hideModal={() => setIsCreatePostProcessorTypeModalShown(false)}
        />
      )}
    </>
  );
};

PostProcessorTypesContainer.propTypes = {
  onFilterUpdate: PropTypes.func.isRequired,
  filters: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
};

export default withRecordsListHandling(
  PostProcessorTypesContainer,
  Selectors.getPostProcessorTypes,
  ['post-processor-type'],
  {
    defaultSort: '-name',
    useLocationFiltering: false,
  },
);
