import React from 'react';
import PropTypes from 'prop-types';
import { Button } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { FormattedMessage } from 'rapidfab/i18n';
import Loading from 'rapidfab/components/Loading';
import { faEdit, faSave, faTimes } from '@fortawesome/free-solid-svg-icons';

const OwnerPanelHeader = ({
  isManager,
  isHawkingUser,
  isSaving,
  isEditingMode,
  setIsEditingMode,
  isStanleyXUser,
  isDigitalDesignWarehouseFeatureEnabled,
}) => {
  if (isManager && (isHawkingUser || isStanleyXUser || isDigitalDesignWarehouseFeatureEnabled)) {
    return (
      <div className="d-flex justify-content-between align-items-center">
        <FormattedMessage
          id="field.owner"
          defaultMessage="Owner"
        />
        {isSaving && <Loading />}
        {isEditingMode && !isSaving && (
          <div>
            <Button size="xs" className="mr15 btn-default" onClick={() => setIsEditingMode(false)}>
              <FontAwesomeIcon icon={faTimes} />
            </Button>
            <Button size="xs" className="btn-default" type="submit">
              <FontAwesomeIcon icon={faSave} />
            </Button>
          </div>
        )}
        {!isEditingMode && !isSaving && (
          <Button size="xs" className="btn-default" onClick={() => setIsEditingMode(true)}>
            <FontAwesomeIcon icon={faEdit} />
          </Button>
        )}
      </div>
    );
  }

  return <FormattedMessage id="field.owner" defaultMessage="Owner" />;
};
OwnerPanelHeader.propTypes = {
  setIsEditingMode: PropTypes.func.isRequired,
  isManager: PropTypes.bool.isRequired,
  isSaving: PropTypes.bool.isRequired,
  isEditingMode: PropTypes.bool.isRequired,
  isHawkingUser: PropTypes.bool.isRequired,
  isStanleyXUser: PropTypes.bool.isRequired,
  isDigitalDesignWarehouseFeatureEnabled: PropTypes.bool.isRequired,
};

export default OwnerPanelHeader;
