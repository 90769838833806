import React, { useState } from 'react';
import PropTypes from 'prop-types';
import {
  Button,
  OverlayTrigger,
  Card,
  Table,
  Image,
  Tooltip,
} from 'react-bootstrap';
// eslint-disable-next-line import/no-cycle
import AnatomicalModelAssemblyModalContainer from 'rapidfab/containers/AnatomicalModel/AnatomicalModelAssemblyModalContainer';
import { extractUuid } from 'rapidfab/utils/uuidUtils';
import _upperFirst from 'lodash/upperFirst';
import ConfirmationModal from 'rapidfab/components/ConfirmationModal';
import { FormattedMessage } from 'react-intl';
import Alert from 'rapidfab/utils/alert';
import Loading from 'rapidfab/components/Loading';
import AnatomicalModelPartModalContainer from 'rapidfab/containers/AnatomicalModel/AnatomicalModelPartModalContainer';
import { PART_STATUSES } from 'rapidfab/constants';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faExclamationCircle, faPencil, faPlus, faTrash } from '@fortawesome/free-solid-svg-icons';

const MODALS = {
  ADD_EDIT_ASSEMBLY_MODAL: 'add-edit-assembly',
  DELETE_ASSEMBLY_MODAL: 'delete-assembly',
  ADD_EDIT_PART_MODAL: 'add-edit-part',
  DELETE_PART_MODAL: 'delete-part',
};

const AssemblyRow = ({
  assembly,
  parts,
  openPartModal,
  openAssemblyModal,
  deletingAssemblyUri,
  deletingPartUri,
}) => {
  const partRowsCount = parts.length || 1;

  const isAssemblyDeleting = !!deletingAssemblyUri;
  const isPartDeleting = !!deletingPartUri;

  const assemblyRow = partColumns => (
    <tr key={`${assembly.uuid}`}>
      <td rowSpan={partRowsCount}>{assembly.name}</td>
      {partColumns}
      <td rowSpan={partRowsCount}>
        {assembly.assembly_type ? _upperFirst(assembly.assembly_type) : 'N/A'}
      </td>
      <td rowSpan={partRowsCount}>
        <Button
          variant="success"
          className="spacer-right"
          disabled={isAssemblyDeleting}
          onClick={() =>
            openPartModal(null, assembly.uri, MODALS.ADD_EDIT_PART_MODAL)}
        >
          <FontAwesomeIcon icon={faPlus} />
        </Button>
        <Button
          variant="primary"
          className="spacer-right"
          disabled={isAssemblyDeleting}
          onClick={() =>
            openAssemblyModal(assembly.uri, MODALS.ADD_EDIT_ASSEMBLY_MODAL)}
        >
          <FontAwesomeIcon icon={faPencil} />
        </Button>
        <Button
          variant="danger"
          disabled={isAssemblyDeleting}
          onClick={() =>
            openAssemblyModal(assembly.uri, MODALS.DELETE_ASSEMBLY_MODAL)}
        >
          {deletingAssemblyUri === assembly.uri && <Loading inline />}{' '}
          <FontAwesomeIcon icon={faTrash} />
        </Button>
      </td>
    </tr>
  );

  if (!parts.length) {
    const emptyParts = (
      <>
        <td />
        <td />
      </>
    );
    return assemblyRow(emptyParts);
  }

  return parts.map((part, index) => {
    const partColumns = (
      <>
        <td>
          {part.name}
          <div className="pull-right">
            <Button
              variant="primary"
              className="spacer-right"
              disabled={isPartDeleting}
              onClick={() =>
                openPartModal(
                  part.uri,
                  assembly.uri,
                  MODALS.ADD_EDIT_PART_MODAL,
                )}
            >
              <FontAwesomeIcon icon={faPencil} />
            </Button>
            <Button
              variant="danger"
              disabled={isPartDeleting}
              onClick={() =>
                openPartModal(part.uri, assembly.uri, MODALS.DELETE_PART_MODAL)}
            >
              {deletingPartUri === part.uri && <Loading inline />}{' '}
              <FontAwesomeIcon icon={faTrash} />
            </Button>
          </div>
        </td>
        <td>
          {part.status === PART_STATUSES.PROCESSING && <Loading inline />}
          {part.status === PART_STATUSES.ERROR && (
            <OverlayTrigger
              placement="top"
              overlay={
                <Tooltip>{part.notes || 'Failed to process STL'}</Tooltip>
              }
            >
              <FontAwesomeIcon icon={faExclamationCircle} />
            </OverlayTrigger>
          )}
          {part.snapshot_location && (
            // <Thumbnail className="part_image" src={part.snapshot_location} />
            <Image className="part_image" src={part.snapshot_location} thumbnail />
          )}
        </td>
      </>
    );
    if (index !== 0) {
      return <tr key={`${part.uuid}`}>{partColumns}</tr>;
    }
    return assemblyRow(partColumns);
  });
};

AssemblyRow.propTypes = {
  assembly: PropTypes.shape({
    uuid: PropTypes.string.isRequired,
    uri: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    assembly_type: PropTypes.string,
  }).isRequired,
  parts: PropTypes.arrayOf(PropTypes.shape({})),
  openPartModal: PropTypes.func.isRequired,
  openAssemblyModal: PropTypes.func.isRequired,
  deletingAssemblyUri: PropTypes.string,
  deletingPartUri: PropTypes.string,
};

AssemblyRow.defaultProps = {
  // One element is required to render assembly with no parts
  parts: [],
  deletingAssemblyUri: null,
};

const AnatomicalModelAssemblies = ({
  assemblies,
  anatomicalModelUri,
  partsByAssemblyUri,
  onDeleteAssembly,
  onDeletePart,
}) => {
  const [shownModal, setShownModal] = useState(null);

  const [deletingAssemblyUri, setDeletingAssemblyUri] = useState(null);
  const [currentAssemblyUri, setCurrentAssemblyUri] = useState(null);

  const [deletingPartUri, setDeletingPartUri] = useState(null);
  const [currentPartUri, setCurrentPartUri] = useState(null);

  const openModal = (assemblyUri, modal) => {
    setCurrentAssemblyUri(assemblyUri);
    setShownModal(modal);
  };

  const openAssemblyModal = (assemblyUri, modal) => {
    if (!anatomicalModelUri) {
      Alert.warning(
        <FormattedMessage
          id="toaster.warning.saveAnatomicalModel"
          defaultMessage="Please, save Anatomical Model first."
        />);
      return;
    }
    openModal(assemblyUri, modal);
  };

  const openPartModal = (partUri, assemblyUri, modal) => {
    setCurrentPartUri(partUri);
    openModal(assemblyUri, modal);
  };

  const closeModal = () => {
    setShownModal(null);
    setCurrentAssemblyUri(null);
    setCurrentPartUri(null);
  };

  const closeAssemblyModal = assemblyUri => {
    // When new assembly was created
    const showPartModal = !currentAssemblyUri && assemblyUri;
    closeModal();
    if (showPartModal) {
      openPartModal(null, assemblyUri, MODALS.ADD_EDIT_PART_MODAL);
    }
  };

  const deleteAssembly = async () => {
    setDeletingAssemblyUri(currentAssemblyUri);
    closeModal();
    onDeleteAssembly(currentAssemblyUri).finally(() => {
      setDeletingAssemblyUri(null);
    });
  };

  const deletePart = async () => {
    setDeletingPartUri(currentPartUri);
    closeModal();
    onDeletePart(currentPartUri).finally(() => {
      setDeletingPartUri(null);
    });
  };

  return (
    <Card bg="dark">
      <Card.Header className="pd-exp inverse">
        <>
          <span>Assemblies</span>
          <div className="pull-right">
            <Button
              variant="info"
              size="xs"
              onClick={() =>
                openAssemblyModal(null, MODALS.ADD_EDIT_ASSEMBLY_MODAL)}
            >
              Add Assembly
            </Button>
          </div>
        </>
      </Card.Header>
      <Card.Body>
        <Table bordered>
          <thead>
            <tr>
              <th>Assebly Name</th>
              <th>Part (STL)</th>
              <th>STL Thumbnail</th>
              <th>Assembly Type</th>
              <th>Edit Assembly</th>
            </tr>
          </thead>
          <tbody>
            {assemblies.map(assembly => (
              <AssemblyRow
                key={assembly.uri}
                assembly={assembly}
                parts={partsByAssemblyUri[assembly.uri]}
                openPartModal={openPartModal}
                openAssemblyModal={openAssemblyModal}
                deletingAssemblyUri={deletingAssemblyUri}
                deletingPartUri={deletingPartUri}
              />
            ))}
          </tbody>
        </Table>
      </Card.Body>
      {shownModal === MODALS.ADD_EDIT_ASSEMBLY_MODAL && (
        <AnatomicalModelAssemblyModalContainer
          onClose={closeAssemblyModal}
          uuid={currentAssemblyUri && extractUuid(currentAssemblyUri)}
          anatomicalModelUri={anatomicalModelUri}
        />
      )}
      {shownModal === MODALS.ADD_EDIT_PART_MODAL && (
        <AnatomicalModelPartModalContainer
          onClose={closeModal}
          uuid={currentPartUri && extractUuid(currentPartUri)}
          assemblyUri={currentAssemblyUri}
        />
      )}
      {shownModal === MODALS.DELETE_ASSEMBLY_MODAL && (
        <ConfirmationModal
          handleCancel={closeModal}
          handleConfirm={deleteAssembly}
          confirmButtonContent={
            <FormattedMessage id="button.delete" defaultMessage="Delete" />
          }
          message="Remove this Assembly?"
        />
      )}
      {shownModal === MODALS.DELETE_PART_MODAL && (
        <ConfirmationModal
          handleCancel={closeModal}
          handleConfirm={deletePart}
          confirmButtonContent={
            <FormattedMessage id="button.delete" defaultMessage="Delete" />
          }
          message="Remove this Part?"
        />
      )}
    </Card>
  );
};

AnatomicalModelAssemblies.propTypes = {
  assemblies: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  anatomicalModelUri: PropTypes.string.isRequired,
  partsByAssemblyUri: PropTypes.objectOf(PropTypes.arrayOf(PropTypes.shape({})))
    .isRequired,
  onDeleteAssembly: PropTypes.func.isRequired,
  onDeletePart: PropTypes.func.isRequired,
};

export default AnatomicalModelAssemblies;
