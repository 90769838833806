import React from 'react';
import PropTypes from 'prop-types';

const UserOption = ({ user }) => (
  <option key={user.uuid} value={user.uri}>
    {UserOption.generateLabel(user)}
  </option>
);

UserOption.propTypes = {
  user: PropTypes.shape({
    uuid: PropTypes.string,
    uri: PropTypes.string,
  }).isRequired,
};

UserOption.generateLabel = user => {
  if (user.name) {
    return `${user.name} (${user.username})`;
  }
  return user.username;
};

export default UserOption;
