import React, { useState } from 'react';
import PropTypes from 'prop-types';

import { Button, FormLabel, FormControl, FormGroup, Modal, Form } from 'react-bootstrap';
import { FormattedMessage } from 'rapidfab/i18n';
import { ROUTES, REMANUFACTURE_REASONS, RUN_TRANSFORM_TYPES } from 'rapidfab/constants';
import { getRouteURI } from 'rapidfab/utils/uriUtils';
import { getShortUUID, extractUuid } from 'rapidfab/utils/uuidUtils';
import Loading from 'rapidfab/components/Loading';
import { RUN_TRANSFORM_TYPES_FOR_REWORK_MODAL_MAP } from 'rapidfab/mappings';

const PartLinkItem = ({ uri, href, isLastItem = false }) => (
  <span key={extractUuid(uri)}>
    <a href={href} rel="noreferrer" target="_blank">
      {getShortUUID(uri)}
    </a>
    {!isLastItem && ', '}
  </span>
);

PartLinkItem.defaultProps = {
  isLastItem: false,
};

PartLinkItem.propTypes = {
  uri: PropTypes.string.isRequired,
  href: PropTypes.string.isRequired,
  isLastItem: PropTypes.bool,
};

const RemanufactureModal = (
  {
    isLineItemsModeEnabled,
    selectedPrints,
    selectedLineItems,
    isRemanufactureModalVisible,
    invertRemanufactureModalStatus,
    submit,
    handleInputChange,
    reason,
    notes,
    loading,
    transformType,
    isPrinting,
  },
) => {
  const [isRedoDetailsModalShown, setIsRedoDetailsModalShown] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const isFailPartSubmitDisabled = reason === '' || notes === '';
  const isRedoThisRunSubmitDisabled = notes === '';
  const isSubmitDisabled =
    loading ||
    isSubmitting ||
    !transformType ||
    (
      isFailPartSubmitDisabled &&
      isRedoThisRunSubmitDisabled
    );
  const isLoading = loading || isSubmitting;
  const remanufactureReasons = Object.keys(REMANUFACTURE_REASONS).map(
    key => ({ key, value: REMANUFACTURE_REASONS[key] }),
  );

  const openRedoDetailsModal = () => {
    setIsRedoDetailsModalShown(true);
  };

  const hideRedoDetailsModal = () => {
    setIsRedoDetailsModalShown(false);
  };

  const onSubmit = () => {
    setIsSubmitting(true);
    submit().finally(() => {
      setIsSubmitting(false);
      invertRemanufactureModalStatus();
    });
  };

  const isOnlyRemanufactureModalVisible =
    isRemanufactureModalVisible && !isRedoDetailsModalShown;

  const renderSelectedItemsForRemanufacture = () => {
    if (isLineItemsModeEnabled) {
      return (
        selectedLineItems?.map((lineItem, index) => {
          const isLastLineItem = index + 1 === selectedLineItems.length;
          const lineItemRouteUri = getRouteURI(ROUTES.ORDER_EDIT, { uuid: extractUuid(lineItem.order) });
          return <PartLinkItem href={lineItemRouteUri} uri={lineItem.uri} isLastItem={isLastLineItem} />;
        })
      );
    }
    return (
      selectedPrints?.map((print, index) => {
        const isLastPrint = index + 1 === selectedPrints.length;
        const pieceRouteUri = getRouteURI(ROUTES.PIECE_EDIT, { uuid: extractUuid(print.piece) });
        return <PartLinkItem href={pieceRouteUri} uri={print.piece} isLastItem={isLastPrint} />;
      })
    );
  };

  return (
    <>
      <Modal show={isOnlyRemanufactureModalVisible} onHide={invertRemanufactureModalStatus} backdrop="static">
        <Modal.Header closeButton>
          <FormattedMessage
            id="RemanufacturePrints"
            defaultMessage="Remanufacture Prints"
          />
        </Modal.Header>
        <Modal.Body>
          <FormGroup>
            <FormLabel>
              <FormattedMessage id="pieces" defaultMessage="Pieces" />
              :&nbsp;
              {renderSelectedItemsForRemanufacture()}
            </FormLabel>
          </FormGroup>

          {
            Object.keys(RUN_TRANSFORM_TYPES_FOR_REWORK_MODAL_MAP).map(runTransformTypeKey => {
              const isRedo = runTransformTypeKey === RUN_TRANSFORM_TYPES.REDO;
              // Disallow Redo option when run operation is 'printing'
              const isOptionDisabled = isRedo && isPrinting;

              return (
                <FormGroup key={runTransformTypeKey} className="mb15">
                  <Form.Check
                    key={runTransformTypeKey}
                    name="transformType"
                    type="radio"
                    label={(
                      <FormattedMessage
                        {...RUN_TRANSFORM_TYPES_FOR_REWORK_MODAL_MAP[
                          runTransformTypeKey
                        ]}
                      />
                    )}
                    checked={transformType === runTransformTypeKey}
                    value={runTransformTypeKey}
                    onChange={handleInputChange}
                    disabled={isOptionDisabled}
                    inline
                  />
                </FormGroup>
              );
            })
          }

          {transformType === RUN_TRANSFORM_TYPES.REMANUFACTURE && (
            <FormGroup className="mb15">
              <FormControl
                as="select"
                name="remanufactureReason"
                onChange={handleInputChange}
              >
                {!reason && (
                  <FormattedMessage id="field.none" defaultMessage="None">{text =>
                    <option value="">{text}</option>}
                  </FormattedMessage>
                )}
                {remanufactureReasons.map(remanufactureReason => (
                  <option key={remanufactureReason.key} value={remanufactureReason.key}>
                    {remanufactureReason.value}
                  </option>
                ))}
              </FormControl>
            </FormGroup>
          )}

          <FormGroup>
            <FormControl
              as="textarea"
              style={{ height: 60 }}
              placeholder="Notes"
              type="text"
              id="remanufactureNotes"
              name="remanufactureNotes"
              value={notes}
              onChange={handleInputChange}
            />
          </FormGroup>

          {transformType === RUN_TRANSFORM_TYPES.REDO && (
            <FormGroup>
              <div className="alert alert-warning">
                <span>
                  <FormattedMessage id="redoWarning" defaultMessage="The option you have chosen will result in the selected pieces being rescheduled for this same process step." />
                </span>
                <span style={{ fontWeight: 'bold' }}>
                  &nbsp;<FormattedMessage id="redoWarningAdditional" defaultMessage="Note: This change cannot be undone." />
                </span>
                &nbsp;
                <Button
                  className="p-a-0"
                  variant="link"
                  role="button"
                  tabIndex={0}
                  onClick={() => openRedoDetailsModal()}
                >
                  More Details
                </Button>
              </div>
            </FormGroup>
          )}
        </Modal.Body>
        <Modal.Footer>
          <Button variant="success" onClick={onSubmit} disabled={isSubmitDisabled}>
            {isLoading ? (
              <Loading />
            ) : (
              <FormattedMessage id="button.submit" defaultMessage="Submit" />
            )}
          </Button>
        </Modal.Footer>
      </Modal>

      <Modal show={isRedoDetailsModalShown} onHide={hideRedoDetailsModal} backdrop="static">
        <Modal.Header closeButton>
          Redo this run only - Explained
        </Modal.Header>
        <Modal.Body>
          <FormattedMessage id="redoNotes" defaultMessage="Piece will be added into a new, separate run that repeats this process step. The new run will be automatically rescheduled to the first available time for the same workstation. Remaining process steps in this piece's production workflow will be rescheduled to resume after the re-run completes." />
        </Modal.Body>
        <Modal.Footer>
          <Button onClick={hideRedoDetailsModal}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

RemanufactureModal.defaultProps = {
  loading: false,
};

RemanufactureModal.propTypes = {
  selectedPrints: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  selectedLineItems: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  isRemanufactureModalVisible: PropTypes.bool.isRequired,
  invertRemanufactureModalStatus: PropTypes.func.isRequired,
  submit: PropTypes.func.isRequired,
  handleInputChange: PropTypes.func.isRequired,
  notes: PropTypes.string.isRequired,
  reason: PropTypes.string.isRequired,
  transformType: PropTypes.string.isRequired,
  loading: PropTypes.bool,
  isPrinting: PropTypes.bool.isRequired,
  isLineItemsModeEnabled: PropTypes.bool.isRequired,
};

export default RemanufactureModal;
