import { useEffect, useState } from 'react';
import _throttle from 'lodash/throttle';

function getPosition(anchor) {
  return anchor ? { x: anchor.scrollLeft, y: anchor.scrollTop } : null;
}

export function isScrolledToBottom(anchor, position, offset = 0) {
  return !!anchor.current && position
    ? position.y + anchor.current.clientHeight + offset >= anchor.current.scrollHeight : undefined;
}

function useElementScroll(anchor) {
  const [scroll, setScroll] = useState(getPosition(anchor.current));

  useEffect(() => {
    if (anchor.current) {
      const handleScroll = _throttle(() => setScroll(getPosition(anchor.current)), 100);
      anchor.current.addEventListener('scroll', handleScroll);

      return () => {
        if (anchor.current) {
          // eslint-disable-next-line react-hooks/exhaustive-deps
          anchor.current.removeEventListener('scroll', handleScroll);
        }
      };
    }
    return null;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [anchor.current]);

  useEffect(() => {
    setTimeout(() => setScroll(getPosition(anchor.current)), 100);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return scroll;
}
export default useElementScroll;
