import _forEach from 'lodash/forEach';
import _replace from 'lodash/replace';
import Config, { THREADS_URL } from 'rapidfab/config';
import _startCase from 'lodash/startCase';
import {
  COMMENT_RELATED_TABLE_NAMES,
  ENCODED_RESOURCES_CONSTANTS,
  ENTITY_TYPES,
  RESOURCES_TO_DECODE,
  ROUTES,
} from 'rapidfab/constants';
import { ENTITY_TYPE_TO_NON_ENTITY_API_RESOURCE_MAP } from 'rapidfab/mappings';
import _findKey from 'lodash/findKey';
import { getShortUUID, isUuid } from 'rapidfab/utils/uuidUtils';

export function isURI(uri) {
  try {
    // eslint-disable-next-line no-unused-vars
    const urlFromUri = new URL(uri);
  } catch {
    return false;
  }
  return true;
}

export const isURIRegex = uri => {
  if (typeof uri !== 'string') {
    return false;
  }

  const match = uri.match(/(http(s)?:\/\/.)?(www\.)?[\w#%+.:=@~-]{2,256}\.[a-z]{2,6}\b([\w#%&+./:=?@~-]*)/g);
  return (match !== null);
};

export const isURLRegex = url => {
  if (typeof url !== 'string') {
    return false;
  }

  const regex = new RegExp('((http|https)://)(www.)?[a-zA-Z0-9@:%._\\+~#?&//=]{2,256}\\.[a-z]{2,6}\\b([-a-zA-Z0-9@:%._\\+~#?&//=]*)');
  const match = url.match(regex);
  return (match !== null);
};

export function getRouteURI(route, params, queryParams, removeHashSymbol) {
  /* As we are using React Router 6, switching route with "#" symbol does not work
     and there is a string concat, for example:
      Current Route: /#/orders
      Desired Route: /#/runs
      Result: /#/orders/#/runs

      So, in case we are using old "window.location" (in class components), we need the "#" symbol
      to be applied, in case we are using React Router routing function as navigate ->
      we do not need it.

      ****As the result, added removeHashSymbol as an additional param taking into account we would
      continue using React Router instead of window.location.
      If you'd like to create RouteURI for navigate(), use removeHashSymbol = true as the last param**.

      */
  let selectedRoute = route;
  if (params) {
    _forEach(params, (value, key) => {
      selectedRoute = _replace(selectedRoute, `:${key}`, encodeURIComponent(value));
    });
  }

  if (queryParams) {
    const queryParamsString = new URLSearchParams(queryParams).toString();
    selectedRoute = `${selectedRoute}?${queryParamsString}`;
  }

  return removeHashSymbol ? selectedRoute : `#${selectedRoute}`;
}

/**
 * @description Generate nautilus URI from UUID and a API resource string.
 * @param {string} uuid
 * @param {string} resource
 * @returns {string}
 */
export const getApiURI = (uuid, resource) => `${Config.HOST.NAUTILUS}/${resource}/${uuid}`;

/**
 * This will check whether the current browser location contains the passed in string value.
 * @param {string} route Return whether current browser location contains this.
 * @returns {boolean}
 */
export const routeURIContains = route => window.location.hash.includes(route);

/**
 * This will check whether the current browser location contains the passed in value split into an array.
 * This may work better in some instances than `routeURIContains()`.
 * @param {Array<string>} routeParts the URI split into an array
 */
export const routeURIArrayPartiallyContains = routeParts => (
  routeParts.every(routeString => window.location.hash.includes(routeString))
);

export const getCustomDDWLibraryUri = (name, restricted) => {
  if (!name) {
    return null;
  }

  // Replace all non-alphanumeric characters (except spaces) with an empty string
  const stringWithoutSymbols = name.replace(/[^\d\sA-Za-z]/g, '');

  // Replace all spaces with hyphens
  const stringWithHyphens = stringWithoutSymbols.replace(/\s+/g, '-');

  const path = restricted ?
    ROUTES.DIGITAL_DESIGN_WAREHOUSE_RESTRICTED :
    ROUTES.DIGITAL_DESIGN_WAREHOUSE;
  return `${path}/${stringWithHyphens
    .toLowerCase()}`;
};

export const buildURIFromUUID = (endpoint, UUID) => `${Config.HOST.NAUTILUS}/${endpoint}/${UUID}`;

export function getCommentRedirectUri(
  comment,
  isCurrentUserRestricted,
  isHawkingUser,
  isManager,
  isRecordOwner,
  isDigitalDesignWarehouseFeatureEnabled) {
  const {
    related_table_name: relatedTableName,
    related_uuid: relatedUUID,
  } = comment;

  let redirectUri = null;

  switch (relatedTableName) {
    case COMMENT_RELATED_TABLE_NAMES.ORDER:
      if (isCurrentUserRestricted) {
        redirectUri = getRouteURI(
          ROUTES.REVIEW_RESTRICTED_ORDER,
          { uuid: relatedUUID },
          { comment: comment.uuid },
        );
      } else {
        redirectUri = getRouteURI(
          ROUTES.ORDER_EDIT,
          { uuid: relatedUUID },
          { comment: comment.uuid },
        );
      }
      break;
    case COMMENT_RELATED_TABLE_NAMES.RUN:
      redirectUri =
        getRouteURI(ROUTES.RUN_EDIT, { uuid: relatedUUID }, {});
      break;
    case COMMENT_RELATED_TABLE_NAMES.PREP_TASK_RECORD:
      redirectUri =
        getRouteURI(ROUTES.PREP_TASK_RECORDS, {}, { task: relatedUUID });
      break;
    case COMMENT_RELATED_TABLE_NAMES.MODEL_LIBRARY:
      if (isHawkingUser) {
        if (!isRecordOwner && isManager) {
          redirectUri = getRouteURI(ROUTES.HAWKING_ADMINISTRATOR_LIBRARY, {}, { uuid: relatedUUID });
        } else {
          redirectUri = getRouteURI(ROUTES.HAWKING_COMPANY_LIBRARY, {}, { uuid: relatedUUID });
        }
      } else {
        if (isDigitalDesignWarehouseFeatureEnabled && isCurrentUserRestricted) {
          redirectUri = getRouteURI(ROUTES.DDW_RESTRICTED_COMPANY_LIBRARY, {}, { uuid: relatedUUID });
          return redirectUri;
        }
        if (isDigitalDesignWarehouseFeatureEnabled && !isCurrentUserRestricted) {
          redirectUri = getRouteURI(ROUTES.DDW_ALL_DESIGNS, {}, { uuid: relatedUUID });
          return redirectUri;
        }
        redirectUri = getRouteURI(ROUTES.MODEL_LIBRARY, {}, { uuid: relatedUUID });
      }
      break;
    default:
      break;
  }

  return redirectUri;
}

export function getEndpointFromURI(uri) {
  if (!uri) {
    throw new Error(`URI '${uri}' is not valid`);
  }

  const uriParts = uri.split('/');
  let uuid = null;

  while (uriParts.length !== 0 && uuid === null) {
    const possibleUUID = uriParts.pop();
    if (isUuid(possibleUUID)) {
      uuid = possibleUUID;
    }
  }

  if (!uuid) {
    throw new Error(`UUID in '${uri}' not found`);
  }

  const endpointName = uriParts.pop();
  const shortUUID = getShortUUID(uuid);

  return { uuid, shortUUID, endpointName };
}

export function getEntityTypeFromUri(uri) {
  const { endpointName } = getEndpointFromURI(uri);

  // Search in aliases
  let entityType = _findKey(
    ENTITY_TYPE_TO_NON_ENTITY_API_RESOURCE_MAP,
    apiResources => apiResources.includes(endpointName),
  );

  if (Object.values(ENTITY_TYPES).includes(endpointName)) {
    // Endpoint name is an entity
    entityType = endpointName;
  }

  if (!entityType) {
    throw new Error(`Entity Type for ${endpointName} endpoint not found`);
  }

  return entityType;
}

export function getEntityTypeTitleFromUri(uri) {
  if (!uri) {
    return null;
  }
  const entityType = getEntityTypeFromUri(uri);
  return _startCase(entityType);
}

export function prepareStepperURI(stepperLinks, params) {
  const stepperModifiedLinks = stepperLinks;

  if (params) {
    _forEach(stepperModifiedLinks, o => {
      const selectedLink = getRouteURI(o.routeName, params);
      // eslint-disable-next-line no-param-reassign
      o.uri = selectedLink;
    });
  }

  return stepperModifiedLinks;
}

export function getQRAppUri(resourceUri) {
  return `${Config.HOST.QR}/scan?resource=${resourceUri}`;
}

export function encodedBarcodeUrl(url) {
  // Assuming the resource URL is in the format (e.g.):
  // https://data.stage-auth.com/printer/d1a49b26-d61a-4c0c-819a-d9c0084d08a5/
  // That is the only format we should support for encoding.
  const parts = url.split('/').filter(part => part.length > 0);
  // Parts for the URL above should be:
  // ['https:', 'data.stage-auth.com', 'printer', 'd1a49b26-d61a-4c0c-819a-d9c0084d08a5']
  // If the structure of the URI is incorrect, return null;
  if (parts.length < 2) {
    // eslint-disable-next-line no-console
    console.error('Barcode URL Encoding Error: URL structure is incorrect');
    return null;
  }

  // Resource type for the above URL will be 'printer'
  const resourceType = parts[parts.length - 2];
  // Removing hyphens from the UUID: 'd1a49b26d61a4c0c819ad9c0084d08a5'
  const uuid = parts[parts.length - 1].replace(/-/g, '');

  // If the UUID format is incorrect for encoding -> return null
  if (!/^[\da-f]{32}$/i.test(uuid)) {
    // eslint-disable-next-line no-console
    console.error('Barcode URL Encoding Error: UUID format is incorrect');
    return null;
  }

  // For the above URL, resourceKey will be 'PRINTER'
  const resourceKey = Object.keys(RESOURCES_TO_DECODE).find(
    key => RESOURCES_TO_DECODE[key] === resourceType,
  );

  // Return null if the resource key is not found
  if (!resourceKey) {
    // eslint-disable-next-line no-console
    console.error('Barcode URL Encoding Error: Resource key not found');
    return null;
  }

  // Resource letter for the above URL will be 'D' due to ENCODED_RESOURCES_CONSTANTS
  const resourceLetter = ENCODED_RESOURCES_CONSTANTS[resourceKey];
  // Return result: 'Dd1a49b26d61a4c0c819ad9c0084d08a5'
  // WHERE: 'D' is the resource letter and 'd1a49b26d61a4c0c819ad9c0084d08a5' is the UUID
  return `${resourceLetter}${uuid}`;
}

export const isURLOrPathRegex = url => {
  if (typeof url !== 'string') {
    return false;
  }
  const isURL = isURLRegex(url);
  if (isURL) return isURL;

  // local file or remote path
  // file:///path/to/file
  // /path/to/file
  const regex = new RegExp(
    '^(file:\\/\\/)?\\/[a-zA-Z0-9@:%._\\+~#?&\\/\\/=]{2,256}\\b([-a-zA-Z0-9@:%._\\+~#?&\\/\\/=]*)',
  );
  const match = url.match(regex);
  return (match !== null);
};

/**
 * Build Threads app route.
 * (not API route).
 */
export const getThreadsAppRouteURI = (resource, id) => (
  `${THREADS_URL}/${resource}/${id}/`
);

export const renderSelectedItemsTitle = hasLineItemsData => {
  const id = hasLineItemsData ? 'nc.selectedLineItems' : 'nc.selectedPieces';
  const defaultMessage = hasLineItemsData ? 'Selected Line Items' : 'Selected Pieces';
  return {
    id,
    defaultMessage,
  };
};
