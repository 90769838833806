import { FormattedMessage } from 'rapidfab/i18n';
import Alert from 'rapidfab/utils/alert';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import {
  getUUIDResource,
  getRouteUUID,
  getNonEmptyMaterialContainersForBatch, getBureauBarcodeFormat, getMaterialLotsByUri,
} from 'rapidfab/selectors';
import Actions from 'rapidfab/actions';

import Loading from 'rapidfab/components/Loading';
import QrContainer from 'rapidfab/components/qr/Container';
import { Col, Container, Row } from 'react-bootstrap';
import BreadcrumbNav from 'rapidfab/components/BreadcrumbNav';
import { getShortUUID } from 'rapidfab/utils/uuidUtils';
import { getRouteURI } from 'rapidfab/utils/uriUtils';
import {
  API_RESOURCES, BUREAU_BARCODE_FORMAT,
  MATERIAL_CONTAINER_STATUSES,
  PAGINATION_IGNORE_DEFAULT_LIMIT,
  ROUTES,
} from 'rapidfab/constants';
import { useParams } from 'react-router-dom';

const BatchContainersContainer = () => {
  const uuid = useSelector(state => getRouteUUID(state));
  const batch = useSelector(state => getUUIDResource(state, uuid));
  const isLoading = useSelector(state =>
    state.ui.nautilus[API_RESOURCES.MATERIAL_BATCH].get.fetching
    || state.ui.nautilus[API_RESOURCES.MATERIAL_LOT].list.fetching
    || state.ui.nautilus[API_RESOURCES.MATERIAL_CONTAINER].list.fetching);
  const route = useParams();
  const bureauBarcodeFormat = useSelector(getBureauBarcodeFormat);
  const isBarcode = bureauBarcodeFormat === BUREAU_BARCODE_FORMAT.BARCODE;
  const containers = useSelector(state => getNonEmptyMaterialContainersForBatch(state, batch));
  const materialLotsByUri = useSelector(getMaterialLotsByUri);

  const dispatch = useDispatch();

  const onInitialize = async currentUUID => {
    if (!currentUUID) {
      return;
    }

    try {
      const batchResult = await dispatch(Actions.Api.nautilus[API_RESOURCES.MATERIAL_BATCH].get(currentUUID, true));
      const { EMPTY, ...containerStatusesExceptEmpty } = MATERIAL_CONTAINER_STATUSES;
      const { uri: batchUri } = batchResult.json;

      const containersResponse = await dispatch(Actions.Api.nautilus[API_RESOURCES.MATERIAL_CONTAINER].list({
        current_batch: batchUri,
        status: Object.values(containerStatusesExceptEmpty),
      }, { limit: PAGINATION_IGNORE_DEFAULT_LIMIT }));

      const { resources: containers } = containersResponse.json ?? [];

      if (containers.length) {
        await dispatch(Actions.Api.nautilus[API_RESOURCES.MATERIAL_LOT].list({
          uri: containers.map(container => container.material_lot).join(','),
        }));
      }
    } catch (error) {
      console.error(error);
      Alert.error(error);
    }
  };

  useEffect(() => {
    (async () => {
      await onInitialize(uuid);
      window.print();
    })();
  }, []);

  if (isLoading || !batch) {
    return (<Loading />);
  }

  const renderContainerType = (containers, isPermanent) => {
    if (!containers.length) return null;

    return (
      <>
        <div className="hide-on-print">
          {isPermanent ? (
            <h2>
              <FormattedMessage
                id="field.permanentContainers"
                defaultMessage="Permanent Containers"
              />
            </h2>
          ) : (
            <h2>
              <FormattedMessage
                id="field.containers"
                defaultMessage="Containers"
              />
            </h2>
          )}
        </div>
        {containers.map(container => {
          const materialName = batch.materials.find(m => m.uri === container.material)?.name;
          const lotUri = container.material_lot || batch.material_lots[0];
          const materialLot = materialLotsByUri[lotUri];

          return (
            <QrContainer
              lotUri={lotUri}
              materialLot={materialLot}
              materialName={materialName}
              container={container}
              isBatchRedirect={isPermanent}
              isBarcode={isBarcode}
            />
          );
        })}
      </>
    );
  };

  const renderContainers = () => {
    if (route?.container && containers?.length) {
      const currentContainer = containers.find(item => item.uuid === route.container);
      if (!currentContainer) return null;
      const materialName = batch.materials.find(m => m.uri === currentContainer?.material)?.name;
      const lotUri = currentContainer.material_lot || batch.material_lots[0];

      return (
        <QrContainer
          lotUri={lotUri}
          materialName={materialName}
          container={currentContainer}
        />
      );
    }

    const permanentContainers = containers.filter(container => !container?.disposable);
    const regularContainers = containers.filter(container => container?.disposable);

    return (
      <>
        {renderContainerType(permanentContainers, true)}
        {renderContainerType(regularContainers, false)}
      </>
    );
  };

  return (
    <Container fluid>
      <Row>
        <Col lg={12}>
          <div className="hide-on-print">
            <BreadcrumbNav
              breadcrumbs={[
                'materialBatches',
                {
                  message: `Batch (${getShortUUID(batch.uuid)})`,
                  href: getRouteURI(ROUTES.MATERIAL_BATCH, { uuid: batch.uuid }),
                },
                `Print Batch ${getShortUUID(batch.uuid)} ${route?.container ? `Container ${getShortUUID(route?.container)}` : 'Containers QR Codes'}`,
              ]}
            />
          </div>
          {renderContainers()}
        </Col>
      </Row>
    </Container>
  );
};

export default BatchContainersContainer;
