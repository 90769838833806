import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import PropTypes from 'prop-types';
import Tooltip from 'rapidfab/components/Tooltip';
import React from 'react';

const ResourceIconTooltip = ({ faIcon,
  customIcon: CustomIcon,
  customIconClassNames,
  text,
  placement,
  iconClassNames }) => {
  if (!text) {
    return null;
  }

  if (!CustomIcon && !faIcon) {
    return null;
  }

  return (
    <Tooltip
      id="resourceiconTooltip"
      placement={placement}
      trigger={CustomIcon ? (
        <span>
          <CustomIcon className={customIconClassNames} />
        </span>
      )
        :
        <FontAwesomeIcon icon={faIcon} className={iconClassNames} />}
    >
      {text}
    </Tooltip>
  );
};

ResourceIconTooltip.defaultProps = {
  placement: 'top',
  iconClassNames: '',
  customIconClassNames: '',
  customIcon: null,
};

ResourceIconTooltip.propTypes = {
  faIcon: PropTypes.shape({}).isRequired,
  text: PropTypes.string.isRequired,
  placement: PropTypes.string,
  iconClassNames: PropTypes.string,
  customIcon: PropTypes.func,
  customIconClassNames: PropTypes.string,
};

export default ResourceIconTooltip;
