import { getStocks } from 'rapidfab/selectors/stock';
import { createSelector } from 'reselect';
import { getPredicate } from 'rapidfab/selectors/helpers/base';
import _filter from 'lodash/filter';

// eslint-disable-next-line import/prefer-default-export
export const getStocksForMaterial = createSelector(
  [getPredicate, getStocks],
  (material, stocks) => {
    const { uri } = material ?? {};
    return _filter(stocks, { material: uri });
  },
);
