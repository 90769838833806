import _get from 'lodash/get';
import _map from 'lodash/map';
import _omit from 'lodash/omit';
import _set from 'lodash/set';
import PropTypes from 'prop-types';
import Actions from 'rapidfab/actions';
import Workflow from 'rapidfab/components/records/workflow/Workflow';
import {
  API_RESOURCES, FEATURES,
  PAGINATION_IGNORE_DEFAULT_LIMIT,
  ROUTES,
  WORKFLOW_TYPES,
} from 'rapidfab/constants';
import { WORKFLOW_CONTAINER } from 'rapidfab/constants/forms';
import * as Selectors from 'rapidfab/selectors';
import { getLabelsByUri, isFeatureEnabled } from 'rapidfab/selectors';
import Alert from 'rapidfab/utils/alert';
import { getSaveStepsDispatchers, saveWorkflow } from 'rapidfab/utils/handleWorkflowSave';
import { getEndpointFromURI, getRouteURI } from 'rapidfab/utils/uriUtils';
import { extractUuid } from 'rapidfab/utils/uuidUtils';
import React, { forwardRef, useEffect, useImperativeHandle, useMemo, useRef } from 'react';
import { FormattedMessage } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import { getIntegratedWorkstations } from 'rapidfab/utils/jeniUtils';

function workflowRedirect(uri) {
  if (uri) {
    window.location.hash = getRouteURI(ROUTES.WORKFLOW_EDIT, { uuid: getEndpointFromURI(uri).uuid });
  } else {
    window.location.hash = getRouteURI(ROUTES.PRODUCTION_WORKFLOW_LIST);
  }
}

const WorkflowContainer = forwardRef((props, forwardedRef) => {
  const isWorkflowContainerIntegrated = !!forwardedRef;

  let uuid = useSelector(Selectors.getRouteUUID);
  let workflow = useSelector(state => Selectors.getUUIDResource(state, uuid));

  if (isWorkflowContainerIntegrated) {
    uuid = props.uuid;
    workflow = props.workflow;
  }

  const specimens = useSelector(state => (workflow ? Selectors.getSpecimensForWorkflow(state, workflow) : null));
  const availablePrinterTypes = useSelector(Selectors.getAvailablePrinterTypes);
  const availablePostProcessorTypes = useSelector(Selectors.getAvailablePostProcessorTypes);
  const shippingTypes = useSelector(Selectors.getShippings);
  const labelsByUri = useSelector(getLabelsByUri);
  const specimenWorkflows = useSelector(Selectors.getSpecimenWorkflows);
  const processStepsRelatedResourcesFetching = useSelector(state =>
    state.ui.nautilus[API_RESOURCES.PROCESS_STEP].list.fetching ||
    state.ui.nautilus[API_RESOURCES.PRINTER_TYPE].list.fetching ||
    state.ui.nautilus[API_RESOURCES.POST_PROCESSOR_TYPE].list.fetching ||
    state.ui.nautilus[API_RESOURCES.SHIPPING].list.fetching);
  const checklistLinkings = useSelector(state =>
    (workflow ? Selectors.getRelatedWorkChecklistLinking(state, workflow.uri) : []));

  const workflowComponentRef = useRef(null);

  const initialValues = workflow;
  WORKFLOW_CONTAINER.STRING_FIELDS.forEach(
    fieldName => {
      const field = _get(initialValues, fieldName);
      if (field === null) {
        _set(initialValues, fieldName, '');
      }
    },
  );

  const initialFormValues = {};
  if (initialValues) {
    Object
      .keys(initialValues)
      .filter(key => WORKFLOW_CONTAINER.FIELDS.includes(key))
      .forEach(key => {
        initialFormValues[key] = initialValues[key];
      });
  }

  const fetching = useSelector(state =>
    state.ui.nautilus[API_RESOURCES.PROCESS_STEP].list.fetching ||
    state.ui.nautilus[API_RESOURCES.WORKFLOW].list.fetching ||
    Selectors.getResourceFetching(state, 'nautilus.specimen'));

  const isSubmitting = useSelector(state =>
    state.ui.nautilus[API_RESOURCES.WORKFLOW].put.fetching ||
    state.ui.nautilus[API_RESOURCES.WORKFLOW].post.fetching ||
    state.ui.nautilus[API_RESOURCES.WORKFLOW].replace.fetching ||
    state.ui.nautilus[API_RESOURCES.PROCESS_STEP].put.fetching ||
    state.ui.nautilus[API_RESOURCES.PROCESS_STEP].post.fetching);

  const modelLibraries = useSelector(state => Selectors.getModelLibrariesByUri(state, workflow));
  const steps = useSelector(state => Selectors.getProcessStepsForWorkflow(state, workflow));
  const postProcessorsByUri = useSelector(Selectors.getPostProcessorsByUri);
  const printersByUri = useSelector(Selectors.getPrintersByUri);
  const printerTypesByUri = useSelector(Selectors.getPrinterTypesByUri);
  const postProcessorTypesByUri = useSelector(Selectors.getPostProcessorTypesByUri);
  const shippingsByUri = useSelector(Selectors.getShippingsByUri);
  const isPowderEnabled = useSelector(
    state => isFeatureEnabled(state, FEATURES.POWDER_WORKFLOW),
  );
  const isAdditiveWorkflowFeatureEnabled = useSelector(
    state => isFeatureEnabled(state, FEATURES.ADDITIVE_WORKFLOW),
  );
  const isGeneralMFGLanguageEnabled = useSelector(state => isFeatureEnabled(state, FEATURES.GENERAL_MFG_LANGUAGE));

  const setWorkFlowTypes = () => {
    if ((isAdditiveWorkflowFeatureEnabled && !isGeneralMFGLanguageEnabled)) {
      return [WORKFLOW_TYPES.ADDITIVE_MANUFACTURING, WORKFLOW_TYPES.SPECIMEN];
    }
    if (isPowderEnabled) {
      return [WORKFLOW_TYPES.POWDER_MANUFACTURING, WORKFLOW_TYPES.SPECIMEN];
    }
    return [WORKFLOW_TYPES.MANUFACTURING, WORKFLOW_TYPES.SPECIMEN];
  };

  const workflowTypes = setWorkFlowTypes();
  const processTypesByUri = { ...printerTypesByUri, ...postProcessorTypesByUri, ...shippingsByUri };

  const { integratedPostProcessorTypes, integratedPrinterTypes } = useMemo(() =>
    getIntegratedWorkstations(availablePrinterTypes, availablePostProcessorTypes),
  [isWorkflowContainerIntegrated, availablePrinterTypes, availablePostProcessorTypes]);

  const selected = {
    uuid,
    fetching,
    isSubmitting,
    specimens,
    modelLibraries,
    initialFormValues,
    steps,
    checklistLinkings,
    availablePrinterTypes: isWorkflowContainerIntegrated ?
      integratedPrinterTypes : availablePrinterTypes,
    availablePostProcessorTypes: isWorkflowContainerIntegrated ?
      integratedPostProcessorTypes : availablePostProcessorTypes,
    shippingTypes,
    specimenWorkflows,
    postProcessorsByUri,
    printersByUri,
    processTypesByUri,
    isPowderEnabled,
    labelsByUri,
    processStepsFetching: processStepsRelatedResourcesFetching,
  };

  const dispatch = useDispatch();
  const onInitialize = currentUUID => {
    if (!currentUUID) {
      // If this is a new workflow we want to see all non-integrated workstations
      // so we filter with the flag false
      dispatch(Actions.Api.nautilus[API_RESOURCES.PRINTER_TYPE]
        .list({ integrated: isWorkflowContainerIntegrated }, { limit: PAGINATION_IGNORE_DEFAULT_LIMIT }, {}, {}, true));
      dispatch(Actions.Api.nautilus[API_RESOURCES.POST_PROCESSOR_TYPE]
        .list({ integrated: isWorkflowContainerIntegrated }, {}, {}, {}, true));
    }
    dispatch(Actions.Api.nautilus[API_RESOURCES.LABEL].list());
    dispatch(Actions.Api.nautilus[API_RESOURCES.PRINTER].list());
    dispatch(Actions.Api.nautilus[API_RESOURCES.POST_PROCESSOR].list());
    dispatch(Actions.Api.nautilus[API_RESOURCES.SHIPPING].list());
    if (!isPowderEnabled) {
      dispatch(Actions.Api.nautilus[API_RESOURCES.MODEL_LIBRARY].list());
    }
    dispatch(Actions.Api.nautilus[API_RESOURCES.WORKFLOW].list({ type: WORKFLOW_TYPES.SPECIMEN }, {}, {}, {}, true));
    if (currentUUID) {
      // If this is a existing workflow we want to see all workstations based on if the workflow is integrated or not
      // so we filter with the flag true or false based on the workflows result
      dispatch(Actions.Api.nautilus[API_RESOURCES.WORKFLOW]
        .get(currentUUID, {}, {}, {}, true))
        .then(workflowResponse => {
          const { uri: workflowUri, integrated } = workflowResponse?.json;

          dispatch(Actions.Api.nautilus[API_RESOURCES.PRINTER_TYPE]
            .list({ integrated }, { limit: PAGINATION_IGNORE_DEFAULT_LIMIT }));

          dispatch(Actions.Api.nautilus[API_RESOURCES.POST_PROCESSOR_TYPE].list({ integrated }));

          dispatch(Actions.Api.nautilus[API_RESOURCES.SPECIMEN].clear('list'));
          dispatch(Actions.Api.nautilus[API_RESOURCES.SPECIMEN].list(
            { parent_workflow: workflowUri },
            null, null, true,
          ));
          dispatch(Actions.Api.nautilus[API_RESOURCES.WORK_CHECKLIST_LINKING].list(
            { related_uri: workflowUri },
            { limit: PAGINATION_IGNORE_DEFAULT_LIMIT },
          ));
          dispatch(Actions.Api.nautilus[API_RESOURCES.PROCESS_STEP].list({ workflows: workflowUri }));
        });
    }
  };
  const loadProcessSteps = processStepUris =>
    dispatch(Actions.Api.nautilus[API_RESOURCES.PROCESS_STEP].list({ uri: processStepUris }));

  const onSave = (payload, deletedSteps, deletedSpecimens, onComplete) => {
    const updatedPayload = { ...payload };
    // delete readonly fields
    delete updatedPayload.flow_time;
    delete updatedPayload.flow_time_queued;

    saveWorkflow(dispatch, updatedPayload)
      .then(response => {
        // Last Updated (user/date) info is set on the backend, and there is no
        // event-stream event for workflows. So GET request is required right
        // after PUT
        const uri = response?.headers?.location;

        dispatch(Actions.Api.nautilus[API_RESOURCES.WORKFLOW].get(extractUuid(uri)));
        const stepsToDelete = deletedSteps.filter(
          stepUUID => steps.find(step_ => extractUuid(step_.uri) === stepUUID)?.workflows.length < 2,
        );
        const deletePromises = _map(stepsToDelete, currentUUID =>
          dispatch(Actions.Api.nautilus[API_RESOURCES.PROCESS_STEP].delete(currentUUID)),
        );
        Promise.all(deletePromises).then(() => {
          const deleteSpecimensPromises = _map(deletedSpecimens, specimen =>
            dispatch(Actions.Api.nautilus[API_RESOURCES.SPECIMEN].delete(specimen.uuid)),
          );
          Promise.all(deleteSpecimensPromises).then(() => {
            if (!extractUuid(payload.uri)) {
              Alert.success(
                <FormattedMessage
                  id="toaster.specimen.created"
                  defaultMessage="Successfully created"
                />);
            } else {
              Alert.success(<FormattedMessage
                id="toaster.specimen.updated"
                defaultMessage="Successfully updated"
              />);
            }
          })
            .finally(() => {
              onComplete(uri);
              if (uri) {
                workflowRedirect(uri);
              }
            });
        });
      });
  };

  /**
   * Expose the onSave to the enclosing components.
   */
  useImperativeHandle(forwardedRef, () => ({
    onSaveHandler() {
      workflowComponentRef.current.onSave(true);
    },
    getWorkflowState() {
      return workflowComponentRef.current.getWorkflowState();
    },
  }));

  const onDelete = currentUUID => {
    if (currentUUID) {
      dispatch(Actions.Api.nautilus[API_RESOURCES.WORKFLOW].delete(currentUUID))
        .then(() => Alert.success(
          <FormattedMessage
            id="toaster.workflow.deleted"
            defaultMessage="Workflow {uuid} successfully deleted."
            values={{ uuid: currentUUID }}
          />,
        ))
        .finally(() => workflowRedirect());
    }
  };
  const onDuplicate = workflowCopy => {
    const { name, steps: currentSteps, type, description, source_workflow, specimens } = workflowCopy;
    const stepCopiesUris = getSaveStepsDispatchers(
      dispatch,
      [],
      _map(currentSteps, step => _omit(step, ['uuid', 'uri'])),
    );
    Promise.all(stepCopiesUris).then(processStepsUris => {
      const payload = {
        description,
        name,
        type,
        source_workflow,
        process_steps: processStepsUris,
        specimens,
      };

      saveWorkflow(dispatch, payload).then(() => {
        Alert.success(<FormattedMessage
          id="toaster.workflow.duplicated"
          defaultMessage="Successfully duplicated"
        />);
        workflowRedirect();
      });
    });
  };
  const onReplace = payload => {
    dispatch(Actions.Api.nautilus[API_RESOURCES.WORKFLOW].replace(payload.uuid, payload))
      .then(response => {
        Alert.success(<FormattedMessage
          id="toaster.workflow.replaced"
          defaultMessage="Successfully replaced"
        />);
        workflowRedirect(response.headers.location);
      })
      .catch(() => Alert.error(
        <FormattedMessage
          id="toaster.error.workflow.failedToReplace"
          defaultMessage="Failed to replace, please check the step order"
        />));
  };

  const saveSteps = (existingSteps, stepsToSave) =>
    getSaveStepsDispatchers(dispatch, existingSteps, stepsToSave);
  const onUnmount = () => {
    // get rid of pesky lingering errors
    dispatch(Actions.UI.clearUIState(['nautilus.location']));
  };

  /* Taking into account when we call /model-library .list() when onInitialize method fires
     it will provide us with 20 models (due to pagination limits) and within 20 models it can
     find 3-5 specimens only. So created the manual method to get all the specimens when new
     workflow is about to be created and "Add Specimen" modal window appears. */
  const handleGetAllSpecimens = async () => {
    await dispatch(Actions.Api.nautilus[API_RESOURCES.MODEL_LIBRARY].list(
      { type: 'specimen' },
      { limit: PAGINATION_IGNORE_DEFAULT_LIMIT },
      {},
      {},
      true));
  };

  useEffect(() => {
    onInitialize(uuid);
  }, [uuid]);

  useEffect(() => {
    if (workflow?.process_steps?.length) {
      loadProcessSteps(workflow?.process_steps);
    }
  }, [JSON.stringify(workflow?.process_steps)]);

  useEffect(() => () => onUnmount(), []);

  const dispatched = { saveSteps,
    onReplace,
    onDelete,
    onDuplicate,
    onSave: props.onOverrideSave || onSave,
    handleGetAllSpecimens };

  return (
    <Workflow
      workflow={workflow}
      workflowTypes={workflowTypes}
      ref={workflowComponentRef}
      fields={initialFormValues}
      {...props}
      {...selected}
      {...dispatched}
    />
  );
});

WorkflowContainer.defaultProps = {
  formRef: null,
  handleForwardedState: null,
  uuid: null,
  onOverrideSave: null,
};

WorkflowContainer.propTypes = {
  loadProcessSteps: PropTypes.func.isRequired,
  formRef: PropTypes.element,
  handleForwardedState: PropTypes.func,
  uuid: PropTypes.string,
  onOverrideSave: PropTypes.func,
  workflow: PropTypes.shape({}).isRequired,
};

export default WorkflowContainer;
