import { createSelector } from 'reselect';
import { getMaterialLots } from 'rapidfab/selectors/materialLot';
import { MATERIAL_LOT_STATUSES } from 'rapidfab/constants';
import _filter from 'lodash/filter';

export const getOnOrderMaterialLots = createSelector(
  [getMaterialLots],
  materialLots => _filter(materialLots, { status: MATERIAL_LOT_STATUSES.ON_ORDER }),
);

export const getInventoryMaterialLots = createSelector(
  [getMaterialLots],
  materialLots => _filter(materialLots, ({ status }) => status !== MATERIAL_LOT_STATUSES.ON_ORDER),
);
