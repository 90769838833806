import Actions from 'rapidfab/actions';
import { API_RESOURCES } from 'rapidfab/constants';
import _uniq from 'lodash/uniq';
import _compact from 'lodash/compact';
import _map from 'lodash/map';
import { loadModelsInChunks } from './model';

// eslint-disable-next-line import/prefer-default-export
export const loadModelLibrariesWithModels = (
  dispatch,
  queryParams,
  limit = 30,
  offset = 0,
  onComplete = null,
) => (
  dispatch(Actions.Api.nautilus[API_RESOURCES.MODEL_LIBRARY].list(
    queryParams,
    { limit, offset },
    {},
    {},
    true,
  ))
    .then(modelLibraryResponse => {
      const modelLibraryObjects = modelLibraryResponse.json.resources;
      const modelUris = _uniq(_compact(_map(modelLibraryObjects, 'additive.model')));
      loadModelsInChunks(dispatch, modelUris);
      if (typeof onComplete === 'function') {
        onComplete(modelLibraryResponse.json);
      }
    })
)
// eslint-disable-next-line no-console
  .catch(error => console.error(error));
