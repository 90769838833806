import { createSelector } from 'reselect';
import { getBuilds } from 'rapidfab/selectors/build';
import _keyBy from 'lodash/keyBy';
import _filter from 'lodash/filter';

// eslint-disable-next-line import/prefer-default-export
export const getBuildsByRunUri = createSelector(
  [getBuilds],
  builds => {
    const buildsWithRuns = _filter(builds, ({ run }) => run);
    return _keyBy(buildsWithRuns, 'run');
  },
);

export const getBuildsByModelerUri = createSelector(
  [getBuilds],
  builds => {
    const buildsWithModelers = _filter(builds, ({ modeler }) => modeler);
    return _keyBy(buildsWithModelers, 'modeler');
  },
);

export const getBuildsByUri = createSelector(
  [getBuilds],
  builds => _keyBy(builds, 'uri'),
);
