/** @typedef {import("rapidfab/types/tools").Tool} Tool */
import { createSelector } from 'reselect';
import { getStateToolingStocks } from 'rapidfab/selectors/baseStateSelectors';
import { getStateResources } from 'rapidfab/selectors/helpers/base';
import _map from 'lodash/map';
import _keyBy from 'lodash/keyBy';

export const getToolingStocks = createSelector(
  [getStateToolingStocks, getStateResources],
  /** @returns {(Tool & {uuid: string})[]} */
  (uuids, resources) => _map(uuids, uuid => resources[uuid]),
);

export const getToolingStocksByUri = createSelector(
  [getToolingStocks],
  toolingStockTypes => _keyBy(toolingStockTypes, 'uri'),
);
