import PropTypes from 'prop-types';
import ToolingStockTypeModal from 'rapidfab/components/inventory/ToolingStockTypeModal';
import {
  getVendorServiceProviders,
  isFeatureEnabled,
} from 'rapidfab/selectors';
import * as Selectors from 'rapidfab/selectors';
import React, { useEffect, useState } from 'react';
import Actions from 'rapidfab/actions';
import _get from 'lodash/get';
import _set from 'lodash/set';
import { API_RESOURCES, FEATURES, SERVICE_PROVIDER_TYPE } from 'rapidfab/constants';
import Alert from 'rapidfab/utils/alert';
import { useDispatch, useSelector } from 'react-redux';
import { useSearchParams } from 'react-router-dom';
import { TOOLING_STOCK_TYPE_CONTAINER } from 'rapidfab/constants/forms';
import { FormattedMessage } from 'react-intl';

const ToolingStockModalContainer = props => {
  const [searchParams, setSearchParams] = useSearchParams();
  const uuid = searchParams.get('uuid');
  const submitting = false;
  const isVendorServiceProviderFeatureEnabled = useSelector(state =>
    isFeatureEnabled(state, FEATURES.VENDOR_SERVICE_PROVIDER),
  );
  const vendorUsers = useSelector(getVendorServiceProviders);

  const toolingStockType = useSelector(state =>
    Selectors.getUUIDResource(state, uuid),
  );

  const isVendorUser = useSelector(Selectors.isVendorServiceProvider);

  const [unarchiving, setUnarchiving] = useState(false);

  const initialFormValues = { ...toolingStockType };

  TOOLING_STOCK_TYPE_CONTAINER.NULL_FIELDS.forEach(fieldName => {
    if (initialFormValues[fieldName] === null) {
      initialFormValues[fieldName] = '';
    }
  });

  const fetching = useSelector(
    state => state.ui.nautilus[API_RESOURCES.TOOLING_STOCK_TYPE].get.fetching,
  );

  const handleCloseModal = () => {
    if (props.isVisible) {
      props.hideModal();
    }
    if (searchParams.has('uuid')) {
      searchParams.delete('uuid');
      setSearchParams(searchParams);
    }
  };

  const initialValues = {};
  Object.keys(initialFormValues)
    .filter(key => TOOLING_STOCK_TYPE_CONTAINER.FIELDS.includes(key))
    .forEach(key => {
      initialValues[key] = initialFormValues[key];
    });

  const formattedVendorUsers = isVendorServiceProviderFeatureEnabled
    ? vendorUsers.filter(Boolean)
      .map(vendorUser => ({
        label: vendorUser.name,
        value: vendorUser.uri,
      }))
    : [];

  const selected = {
    uuid,
    toolingStockType,
    initialFormValues: initialValues,
    submitting,
    fetching,
    unarchiving,
    formattedVendorUsers,
  };

  const dispatch = useDispatch();

  const onInitialize = currentUUID => {
    if (currentUUID) {
      dispatch(Actions.Api.nautilus[API_RESOURCES.TOOLING_STOCK_TYPE]
        .get(currentUUID));
    }
    if (isVendorServiceProviderFeatureEnabled) {
      dispatch(
        Actions.Api.nautilus[API_RESOURCES.SERVICE_PROVIDER]
          .list({ service_provider_type: SERVICE_PROVIDER_TYPE.VENDOR }),
      );
    }
  };

  const onSubmit = async formValues => {
    const payload = { ...formValues };

    TOOLING_STOCK_TYPE_CONTAINER.FLOAT_FIELDS.forEach(fieldName => {
      const field = _get(payload, fieldName);
      if (field) {
        _set(payload, fieldName, Number.parseFloat(field));
      }
    });

    TOOLING_STOCK_TYPE_CONTAINER.NULL_FIELDS.forEach(fieldName => {
      const field = _get(payload, fieldName);
      if (field === '' || field === undefined) {
        _set(payload, fieldName, null);
      }
    });
    // Edit an existing Tooling Stock Type
    if (payload.uuid) {
      await dispatch(
        Actions.Api.nautilus[API_RESOURCES.TOOLING_STOCK_TYPE].put(
          payload.uuid,
          payload,
        ),
      );
      Alert.success(
        <FormattedMessage
          id="toaster.tooling_stock_type.updated"
          defaultMessage="Tool Type {uuid} successfully updated."
          values={{ uuid: payload.uuid }}
        />,
      );
      // Create new Tooling Stock Type
    } else {
      await dispatch(
        Actions.Api.nautilus[API_RESOURCES.TOOLING_STOCK_TYPE].post(payload),
      );
      dispatch(
        Actions.Api.nautilus[API_RESOURCES.TOOLING_STOCK_TYPE].list(),
      );

      Alert.success(
        <FormattedMessage
          id="toaster.tooling_stock_type.created"
          defaultMessage="Tool Type successfully created."
        />,
      );
      props.hideModal();
    }
  };

  const onArchive = async currentUUID => {
    if (currentUUID) {
      await dispatch(
        Actions.Api.nautilus[API_RESOURCES.TOOLING_STOCK_TYPE].delete(
          currentUUID,
        ),
      );
      Alert.success(
        <FormattedMessage
          id="toaster.tooling_stock_type.archived"
          defaultMessage="Tool Type successfully archived."
          values={{ uuid: currentUUID }}
        />,
      );
      handleCloseModal();
    }
  };
  const onUnarchive = async formValues => {
    setUnarchiving(true);
    await onSubmit({ ...formValues, archived: null }, false);
  };

  useEffect(() => {
    onInitialize(uuid);
  }, [uuid]);

  return (
    <ToolingStockTypeModal
      {...props}
      {...selected}
      onArchive={onArchive}
      onUnarchive={onUnarchive}
      onSubmit={onSubmit}
      handleCloseModal={handleCloseModal}
      isVendorUser={isVendorUser}
    />
  );
};

ToolingStockModalContainer.propTypes = {
  isVisible: PropTypes.bool.isRequired,
  hideModal: PropTypes.func.isRequired,
};

export default ToolingStockModalContainer;
