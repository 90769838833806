import cadfileDE from './cadfile/de.json';
import documentsDE from './documents/de.json';
import estimatesDE from './estimates/de.json';
import quoteDE from './quote/de.json';
import shipmentsDE from './shipments/de.json';
import summaryDE from './summary/de.json';

const recordsDE = Object.assign(cadfileDE, documentsDE, estimatesDE, quoteDE, shipmentsDE, summaryDE);

export default recordsDE;
