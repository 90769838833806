import React from 'react';
import PropTypes from 'prop-types';
import { Col, ListGroup, ListGroupItem, Card, Row } from 'react-bootstrap';
import Tooltip from 'rapidfab/components/Tooltip';
import { FormattedMessage } from 'rapidfab/i18n';
import { shipmentResourceType } from 'rapidfab/types';
import { SHIPMENT_STATUS_MAP } from 'rapidfab/mappings';
import { FormattedDate } from 'react-intl';
import ResourceLink from 'rapidfab/components/ResourceLink';

const OrderShipments = ({ shipments }) => {
  // Overlay callbacks are needed since tooltip has no access to intl,
  // so callbacks both for delivery date and label are required
  const deliveryDateCallback = deliveryDate => (
    <FormattedMessage id="expectedDelivery" defaultMessage="Expected Delivery">
      {
        // Passing translated label further
        label => (
          <Tooltip id="shipmentEstimatedDeliveryDate">
            {label}: {deliveryDate}
          </Tooltip>
        )
      }
    </FormattedMessage>
  );
  return (
    <Card bg="dark" className="mb15">
      <Card.Header className="pd-exp inverse">
        <FormattedMessage id="plan.shipments" defaultMessage="Shipments" />
      </Card.Header>
      <div className="card-body-wrapper">
        <Card.Body className="pd-exp">
          <ListGroup fill>
            {shipments.map(shipment => (
              <ListGroupItem key={shipment.uri}>
                <Row>
                  <Col xs={8}>
                    <ResourceLink uri={shipment.uri} />
                  </Col>
                  <Col xs={4}>
                    {SHIPMENT_STATUS_MAP[shipment.status] ? (
                      <FormattedMessage
                        {...SHIPMENT_STATUS_MAP[shipment.status]}
                      />
                    ) : (
                      <FormattedMessage id="notAvailable" defaultMessage="N/A" />
                    )}
                    {shipment.estimated_delivery_date ? (
                      <FormattedDate value={shipment.estimated_delivery_date}>
                        {
                        // passing formatted date further
                          deliveryDate => deliveryDateCallback(deliveryDate)
                        }
                      </FormattedDate>
                    ) : (
                      <FormattedMessage id="notAvailable" defaultMessage="N/A">
                        {
                        // passing translated `N/A` message further
                          na => deliveryDateCallback(na)
                        }
                      </FormattedMessage>
                    )}
                  </Col>
                </Row>
              </ListGroupItem>
            ))}

            {!shipments.length && (
              <ListGroupItem>
                <FormattedMessage
                  id="noShipmentsForOrder"
                  defaultMessage="No shipments associated with this order"
                />
              </ListGroupItem>
            )}
          </ListGroup>
        </Card.Body>
      </div>
    </Card>
  );
};

OrderShipments.propTypes = {
  shipments: PropTypes.arrayOf(shipmentResourceType).isRequired,
};

export default OrderShipments;
