import React, { useState } from 'react';
import PropTypes from 'prop-types';

import { Button,
  OverlayTrigger,
  Tooltip } from 'react-bootstrap';

import { FormattedMessage } from 'rapidfab/i18n';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { USER_ROLES } from 'rapidfab/constants';
import { useIntl } from 'react-intl';
import CustomFieldDetailModalContainer from 'rapidfab/containers/admin/CustomFieldDetailModalContainer';
import CustomFieldModalContainer from 'rapidfab/containers/admin/CustomFieldModalContainer';
import TableWithSearching from 'rapidfab/components/Tables/TableWithSearching';
import { faEye, faInfoCircle, faPencil, faPlus } from '@fortawesome/free-solid-svg-icons';

const MODALS = {
  VIEW: 'view',
  ADD: 'add',
  EDIT: 'edit',
};

const CustomFields = componentProps => {
  const intl = useIntl();
  const positionTooltipMessage = intl.formatMessage({
    id: 'customField.position.info',
    defaultMessage: 'Location in form, higher numbers are higher on the list',
  });
  const { data } = componentProps;
  const [visibileModal, setVisibleModal] = useState(null);
  const isManager = componentProps.role.role === USER_ROLES.MANAGER;
  const NavbarLinks = (
    <Button
      className="pull-right"
      size="sm"
      variant="primary"
      onClick={() => setVisibleModal({ modal: MODALS.ADD })}
    >
      <FontAwesomeIcon icon={faPlus} />{' '}
      <FormattedMessage id="customField.add" defaultMessage="Add Custom Fields" />
    </Button>
  );

  const openViewModal = uri => {
    setVisibleModal({ modal: MODALS.VIEW, uri });
  };
  const openEditModal = uri => {
    setVisibleModal({ modal: MODALS.EDIT, uri });
  };

  const getViewEditButtons = rowData => {
    // Added conditions for manager here
    const shouldManagerShowEdit = !(rowData.is_smart || rowData.type === 'table');
    // NOT AT ALL editable by manager users if:
    // ‘is_smart’ flag is True
    // Custom Field Type is ‘table’
    const shouldShowEditButton = isManager
      ? shouldManagerShowEdit
      : true;

    return (
      <>
        <Button
          variant="default"
          size="xs"
          tabIndex={0}
          onClick={() => openViewModal(rowData.uri)}
          className="spacer-right"
        >
          <FontAwesomeIcon icon={faEye} />
        </Button>
        {
          shouldShowEditButton
            ? (
              <Button
                className="spacer-right"
                variant="primary"
                size="xs"
                tabIndex={0}
                onClick={() => openEditModal(rowData.uri)}
              >
                <FontAwesomeIcon icon={faPencil} />
              </Button>
            )
            : null
        }
      </>
    );
  };

  const columns = [
    {
      type: 'text',
      uid: 'customField.displayName',
      accessor: 'field_name',
      defaultMessage: 'Display Name',
      isSortable: true,
    },
    {
      type: 'text',
      uid: 'customField.fieldKey',
      accessor: 'field_id',
      defaultMessage: 'Field Key',
      isSortable: true,
    },
    {
      type: 'text',
      uid: 'customField.relatedResource',
      accessor: 'related_table_name',
      defaultMessage: 'Related Resource',
      isSortable: false,
    },
    {
      type: 'text',
      uid: 'customField.type',
      accessor: 'type',
      defaultMessage: 'Type',
      isSortable: true,
    },
    {
      type: 'custom',
      uid: 'customField.position',
      accessor: 'position',
      defaultMessage: 'Position',
      isSortable: true,
      Header: () => (
        <>
          <FormattedMessage id="customField.position" defaultMessage="Position" />
          <OverlayTrigger
            placement="top"
            overlay={(
              <Tooltip>
                {positionTooltipMessage}
              </Tooltip>
            )}
          >
            <FontAwesomeIcon className="pr-1 spacer-left" icon={faInfoCircle} />
          </OverlayTrigger>
        </>
      ),
      Cell: ({ value }) => (
        // value null or undefined-> show N/A with info
        (!value && !(typeof (value) === 'number')) ? (
          <div> N/A
            <OverlayTrigger
              placement="top"
              overlay={(
                <Tooltip>
                  <FormattedMessage
                    id="customField.noPosition.info"
                    defaultMessage="Position has been customized. Contact support@authentise.com to request changes."
                  />
                </Tooltip>
              )}
            >
              <FontAwesomeIcon className="pr-1 spacer-left" icon={faInfoCircle} />
            </OverlayTrigger>
          </div>
        ) : (<div>{value}</div>)
      ),
    },
    {
      type: 'bool',
      uid: 'customField.active',
      accessor: 'enabled',
      defaultMessage: 'Active',
      isSortable: false,
    },
    {
      type: 'text',
      uid: 'customField.description',
      accessor: 'description',
      defaultMessage: 'Description',
      isSortable: true,
    },
    {
      type: 'custom',
      uid: 'customField.edit_view',
      accessor: 'rights',
      defaultMessage: 'Edit/View',
      isSortable: true,
      Cell: ({ row: { original } }) => (
        <div className="pull-right">
          {getViewEditButtons(original)}
        </div>
      ),
    },
  ];

  return (
    <>
      <TableWithSearching
        {...componentProps}
        withBreadcrumbs
        navbar={NavbarLinks}
        data={data}
        columns={columns}
        breadcrumbs={['admin', 'customFields']}
        isFilteringEnabled={false}
        showLocationsFilter={false}
        isManualSoringEnabled={false}
        isUpdatedColumnShown={false}
        initialSortedDesc
        initialSortedColumn="field_name"
      />

      {
        visibileModal?.modal === MODALS.VIEW && (
          <CustomFieldDetailModalContainer
            uri={visibileModal.uri}
            onClose={() => setVisibleModal(null)}
            onOpenEditModal={openEditModal}
          />
        )
      }
      {
        visibileModal?.modal === MODALS.ADD && (
          <CustomFieldModalContainer onClose={() => setVisibleModal(null)} />
        )
      }
      {
        visibileModal?.modal === MODALS.EDIT && (
          <CustomFieldModalContainer
            uri={visibileModal.uri}
            onClose={() => setVisibleModal(null)}
          />
        )
      }
    </>
  );
};

CustomFields.propTypes = {
  enabled: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired,
  row: PropTypes.shape({
    original: PropTypes.shape({}),
  }).isRequired,
};

export default CustomFields;
