import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Button, FormControl, Modal } from 'react-bootstrap';
import { FormattedMessage } from 'react-intl';
import { MODAL_TEMPLATES } from 'rapidfab/constants';
import Dropzone from 'react-dropzone';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrash, faUpload } from '@fortawesome/free-solid-svg-icons';
import { pluralWord } from 'rapidfab/utils/stringUtils';

/**
 * A general-purpose info modal component which is to be used with the useModal() hook.
 */
const UseModalComponent = ({
  id,
  modalTemplate,
  headerIcon,
  title,
  bodyText,
  isModalOpen,
  size,
  onHide,
  onConfirm = null,
  isCloseButtonVisible = false,
  modalBodyContent = null,
  modalFooterContent = null,
  isSubmitting = false,
  ...otherProps
}) => {
  const [modalComponentState, setModalComponentState] = useState(null);

  let templateData = {
    bodyContent: null,
    footerContent: null,
  };

  switch (modalTemplate) {
    case MODAL_TEMPLATES.CANCEL_OR_DELETE:
      templateData = {
        ...templateData,
        bodyContent: ([
          <p>Are you sure you want to delete?</p>,
        ]),
        footerContent: ([
          <Button onClick={onHide}>
            <FormattedMessage
              id="button.cancel"
              defaultMessage="Cancel"
            />
          </Button>,
          <Button
            bg="danger"
            variant="danger"
            onClick={onConfirm}
          >
            <FontAwesomeIcon className="spacer-right" icon={faTrash} />
            <FormattedMessage
              id="button.delete"
              defaultMessage="Delete"
            />
          </Button>,
        ]),
      };
      break;

    case MODAL_TEMPLATES.YES_OR_NO:
      templateData = {
        ...templateData,
        footerContent: ([
          <Button
            bg="danger"
            variant="danger"
            onClick={onHide}
          >
            <FormattedMessage
              id="no"
              defaultMessage="No"
            />
          </Button>,
          <Button
            bg="success"
            variant="success"
            onClick={onConfirm}
          >
            <FormattedMessage
              id="yes"
              defaultMessage="Yes"
            />
          </Button>,
        ]),
      };
      break;

    case MODAL_TEMPLATES.TEXT_ENTRY:
      templateData = {
        ...templateData,
        bodyContent: ([
          <p>{otherProps.textEntryBodyText}</p>,
          <FormControl
            autoFocus
            className="mt15"
            type={otherProps.inputType}
            onChange={event => setModalComponentState({
              ...modalComponentState,
              textInput: event.target.value,
            })}
            id="textInput"
          />,
        ]),
        footerContent: ([
          <Button
            bg="danger"
            variant="danger"
            onClick={onHide}
          >
            <FormattedMessage
              id="button.cancel"
              defaultMessage="Cancel"
            />
          </Button>,
          <Button
            bg="primary"
            variant="primary"
            onClick={() => otherProps.onModalConfirm(modalComponentState, onHide)}
          >
            Continue
          </Button>,
        ]),
      };
      break;

    case MODAL_TEMPLATES.UPLOAD_FILE:
      templateData = {
        ...templateData,
        bodyContent: ([
          <Dropzone
            onDropAccepted={data => setModalComponentState({ ...modalComponentState, uploadedFiles: data })}
            validator={otherProps.onDropValidate}
            onDropRejected={otherProps.onDropRejected}
            accept={otherProps.acceptedExtensions}
            maxFiles={20}
            multiple
          >
            {({ getRootProps, getInputProps }) => (
              <div {...getRootProps({ role: 'button' })}>
                <input {...getInputProps()} />
                <h1 className="text-center">Upload</h1>
                <div className="text-center">
                  <FontAwesomeIcon icon={faUpload} size="5x" />
                </div>
                <p className="text-center mt15">
                  Drag files here or click to browse
                </p>
              </div>
            )}
          </Dropzone>,
          modalComponentState?.uploadedFiles?.length && (
            <p>
              {modalComponentState?.uploadedFiles?.length || 0}
              {` ${pluralWord('file', modalComponentState.uploadedFiles)} uploaded`}
            </p>
          ),
        ]),
        footerContent: ([
          <Button
            bg="danger"
            variant="danger"
            onClick={onHide}
          >
            <FormattedMessage
              id="button.cancel"
              defaultMessage="Cancel"
            />
          </Button>,
          <Button
            bg="success"
            variant="success"
            // Not `onConfirm` as data is not passed through in `useModal.jsx`.
            onClick={() => otherProps.onUploadConfirm(modalComponentState.uploadedFiles, onHide)}
            disabled={isSubmitting || !modalComponentState?.uploadedFiles?.length}
          >
            <FormattedMessage
              id="button.upload"
              defaultMessage="Upload"
            />
          </Button>,
        ]),
      };
      break;

    default:
      templateData = {
        ...templateData,
        footerContent: (
          <Button onClick={onConfirm}>
            <FormattedMessage
              id="button.ok"
              defaultMessage="OK"
            />
          </Button>
        ),
      };
      break;
  }

  return (
    <Modal id={id} show={isModalOpen} onHide={onHide} size={size}>
      {title && (
        <Modal.Header closeButton={isCloseButtonVisible}>
          <div className="modal-header-container">
            {headerIcon}
            {title}
          </div>
        </Modal.Header>
      )}
      <Modal.Body>
        {modalBodyContent || bodyText || templateData.bodyContent}
      </Modal.Body>
      <Modal.Footer>
        {modalFooterContent || templateData.footerContent}
      </Modal.Footer>
    </Modal>
  );
};

export default UseModalComponent;

UseModalComponent.propTypes = {
  id: PropTypes.string,
  modalTemplate: PropTypes.string,
  title: PropTypes.string.isRequired,
  bodyText: PropTypes.string.isRequired,
  isModalOpen: PropTypes.bool.isRequired,
  size: PropTypes.string.isRequired,
  onHide: PropTypes.func.isRequired,
  onConfirm: PropTypes.func,
  isCloseButtonVisible: PropTypes.bool,
  modalBodyContent: PropTypes.shape(PropTypes.element),
  modalFooterContent: PropTypes.shape(PropTypes.element),
  isSubmitting: PropTypes.bool,
  headerIcon: PropTypes.element,
};

UseModalComponent.defaultProps = {
  id: null,
  modalTemplate: null,
  isCloseButtonVisible: false,
  modalFooterContent: null,
  modalBodyContent: null,
  onConfirm: null,
  isSubmitting: false,
  headerIcon: null,
};
