import { createSelector } from 'reselect';
import * as baseStateSelectors from 'rapidfab/selectors/baseStateSelectors';
import { getStateResources } from 'rapidfab/selectors/helpers/base';
import _map from 'lodash/map';
import _keyBy from 'lodash/keyBy';

export const getCustomGroups = createSelector(
  [baseStateSelectors.getStateCustomGroups, getStateResources],
  (uuids, resources) => _map(uuids, uuid => resources[uuid]),
);

export const getCustomGroupsByUri = createSelector(
  [getCustomGroups],
  customGroups => _keyBy(customGroups, 'uri'),
);
