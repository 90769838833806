import { createSelector } from 'reselect';
import * as baseStateSelectors from 'rapidfab/selectors/baseStateSelectors';
import { getStateResources } from 'rapidfab/selectors/helpers/base';
import _keyBy from 'lodash/keyBy';

export const getDowntimes = createSelector(
  [baseStateSelectors.getStateDowntimes, getStateResources],
  (uuids, resources) => uuids.map(uuid => resources[uuid]),
);

export const getDowntimesByUri = createSelector(
  [getDowntimes],
  downtimes => _keyBy(downtimes, 'uri'),
);
