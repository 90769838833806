import React from 'react';
import { Button, Col, FormLabel, FormControl, Row } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import { Link } from 'react-router-dom';
import { faExternalLink, faPlus, faRefresh } from '@fortawesome/free-solid-svg-icons';
import { getRouteURI } from 'rapidfab/utils/uriUtils';
import BreadcrumbNav from '../../BreadcrumbNav';
import Search from '../../RecordList/Search';
import Locations from '../../locations';
import SelectMultiple from '../../forms/SelectMultiple';
import { ROUTES } from '../../../constants';
import OrderReportContainer from '../../../containers/OrderReportContainer';
import { MATERIAL_FAMILY_MAP } from '../../../mappings';
import { FormattedMessageMappingOption } from '../../../i18n';

const NavbarLinks = () => (
  <div className="pull-right" style={{ display: 'flex', flexDirection: 'space-evenly: flex-end' }}>
    <OrderReportContainer defaultMessage="Download CSV" />
    <Button
      variant="primary"
      size="sm"
      href={getRouteURI(ROUTES.ORDER_NEW)}
      className="pull-right"
    >
      <FontAwesomeIcon icon={faPlus} />{' '}
      <FormattedMessage id="record.order.add" defaultMessage="Add Order" />
    </Button>
  </div>
);

const OrdersGanttChartHeader = ({
  isRestrictedUser,
  search,
  onSearch,
  onStatusChange,
  refreshRecordsList,
  availableOrderStatusOptions,
  selectedStatuses,
  locations,
  showLocationsFilter,
  materialFamily,
  onChangeMaterialFamily,
  toggleViewModeButtons,
}) => {
  const showLocations = showLocationsFilter && locations.length > 0;
  const breadcrumbs = isRestrictedUser ? ['orders'] : ['plan', 'orders'];
  return (
    <>
      <BreadcrumbNav breadcrumbs={breadcrumbs} />
      <Row>
        <Col xs={{ span: 8 }}>
          {showLocations ? (
            <Locations />
          ) : (
            <div />
          )}
        </Col>
        <Col xs={{ span: 4 }}>
          <NavbarLinks />
        </Col>
      </Row>

      <hr />
      <Row>
        <Col sm={{ span: 12 }} lg={{ span: 6, offset: 1 }} className="mb8">
          <Search
            initialSearch={search}
            onSearch={onSearch}
          />
        </Col>
      </Row>
      <Row>
        <Col sm={{ span: 12 }} lg={{ span: 10, offset: 1 }}>
          <div className="filters-label">Filters:</div>
          <Row>
            <Col sm={{ span: 12 }} md={{ span: 8 }}>
              <div className="form-inline" style={{ lineHeight: '40px' }}>
                <div className="form-group mr15">
                  <FormLabel htmlFor="materialFamilyFilter">
                    Material Family:
                  </FormLabel>
                  <FormControl
                    className="spacer-left"
                    id="materialFamilyFilter"
                    as="select"
                    name="materialFamily"
                    value={materialFamily}
                    onChange={onChangeMaterialFamily}
                  >
                    <FormattedMessage id="all" defaultMessage="All">{text =>
                      <option value="">{text}</option>}
                    </FormattedMessage>
                    {Object.keys(MATERIAL_FAMILY_MAP).map(family => (
                      <FormattedMessageMappingOption
                        key={family}
                        mapping={MATERIAL_FAMILY_MAP}
                        value={family}
                      />
                    ))}
                  </FormControl>
                </div>
                <div className="form-group mr15">
                  <FormLabel htmlFor="statusFilter">
                    Status:
                  </FormLabel>
                  <div className="spacer-left form-control inline-picky-wrapper">
                    <SelectMultiple
                      title="Status"
                      data={Object.values(availableOrderStatusOptions)}
                      labelKey="label"
                      valueKey="status"
                      selectedData={selectedStatuses}
                      handleOnClose={onStatusChange}
                    />
                  </div>
                </div>
                <div className="form-group mr15">
                  <Link to={getRouteURI(ROUTES.QUEUES, {}, {}, true)} target="_blank" rel="noreferrer">
                    <span>Open Workstation Queues &nbsp;</span>
                    <FontAwesomeIcon icon={faExternalLink} />
                  </Link>
                </div>
              </div>
            </Col>
            <Col xs={{ span: 12 }} md={{ span: 4 }}>
              <div className="pull-right">
                <Button variant="primary" onClick={refreshRecordsList} style={{ marginRight: '5px' }}>
                  <FontAwesomeIcon icon={faRefresh} />
                </Button>
                {toggleViewModeButtons}
              </div>
            </Col>
          </Row>
        </Col>
      </Row>
    </>
  );
};

OrdersGanttChartHeader.defaultProps = {
  showLocationsFilter: true,
  materialFamily: [],
};

OrdersGanttChartHeader.propTypes = {
  isRestrictedUser: PropTypes.bool.isRequired,
  search: PropTypes.string.isRequired,
  onSearch: PropTypes.func.isRequired,
  onStatusChange: PropTypes.func.isRequired,
  refreshRecordsList: PropTypes.func.isRequired,
  availableOrderStatusOptions: PropTypes.shape({}).isRequired,
  selectedStatuses: PropTypes.arrayOf(PropTypes.string).isRequired,
  locations: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  materialFamily: PropTypes.arrayOf(PropTypes.string),
  showLocationsFilter: PropTypes.bool,
  onChangeMaterialFamily: PropTypes.func.isRequired,
  toggleViewModeButtons: PropTypes.element.isRequired,
};

export default OrdersGanttChartHeader;
