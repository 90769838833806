import { faWrench } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { ROUTES } from 'rapidfab/constants';
import { getRouteURI } from 'rapidfab/utils/uriUtils';
import { extractUuid, getShortUUID } from 'rapidfab/utils/uuidUtils';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import PropTypes from 'prop-types';

const getExternalResourceLink = (route, uri, uriText) => (
  <a
    href={getRouteURI(route, { uuid: extractUuid(uri) })}
    rel="noreferrer"
    target="_blank"
  >
    {getShortUUID(uriText || uri)}
  </a>
);

const NCSelectedLineItem = ({ selectedLineItems }) => (
  <>
    <div>
      <b> <FormattedMessage id="nc.selectedLineItems" defaultMessage="Selected Line Items" /> </b>
    </div>
    <div className="nc-piece-list">
      {selectedLineItems.map(({ line_item, order, count }) => (
        <div className="nc-piece">
          {getExternalResourceLink(ROUTES.ORDER_EDIT, order, line_item)}:{' '}
          <span><FontAwesomeIcon icon={faWrench} /> {count}</span>
        </div>
      ))}
    </div>
  </>
);

NCSelectedLineItem.propTypes = {
  selectedLineItems: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
};

export default NCSelectedLineItem;
