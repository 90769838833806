import { faBarcode, faQrcode } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import PropTypes from 'prop-types';
import { BUREAU_BARCODE_FORMAT } from 'rapidfab/constants';
import { getBureauBarcodeFormat } from 'rapidfab/selectors';
import React from 'react';
import { useSelector } from 'react-redux';

const BureauBarcodeIcon = ({ size, className, barcodeText, qrCodeText }) => {
  const bureauBarcodeFormat = useSelector(getBureauBarcodeFormat);
  const isBarcode = bureauBarcodeFormat === BUREAU_BARCODE_FORMAT.BARCODE;
  const icon = isBarcode ? faBarcode : faQrcode;
  const shouldDisplayText = barcodeText && qrCodeText;
  const renderedText = isBarcode ? barcodeText : qrCodeText;
  return (
    <>
      <FontAwesomeIcon icon={icon} size={size} className={className} />
      {shouldDisplayText && (renderedText)}
    </>
  );
};

BureauBarcodeIcon.defaultProps = {
  size: 'lg',
  className: '',
  barcodeText: '',
  qrCodeText: '',
};

BureauBarcodeIcon.propTypes = {
  size: PropTypes.string,
  className: PropTypes.string,
  barcodeText: PropTypes.string,
  qrCodeText: PropTypes.string,
};

export default BureauBarcodeIcon;
