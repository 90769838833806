import React, { useEffect, useState } from 'react';
import { faTrash, faUpload } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import _isEmpty from 'lodash/isEmpty';
import PropTypes from 'prop-types';
import Actions from 'rapidfab/actions';
import Loading from 'rapidfab/components/Loading';
import {
  API_RESOURCES,
  MODAL_TEMPLATES,
} from 'rapidfab/constants';
import { useModal } from 'rapidfab/hooks';
import { extractUuid } from 'rapidfab/utils/uuidUtils';
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';
import { Badge, Button, Col, Image, ListGroup, ListGroupItem, Modal, Row } from 'react-bootstrap';
import { FormattedMessage } from 'react-intl';
import { useDispatch } from 'react-redux';

const ManageCarouselModal = ({
  carousel,
  onHide,
  isCarouselFetching,
  isDocumentFetching,
  handleUploadCarouselImage,
  handleRefreshCarouselSnapshots,
}) => {
  const dispatch = useDispatch();
  const [openUploadModal, UploadModal] = useModal();

  const [carouselImages, setCarouselImages] = useState(carousel?.snapshots);
  const [mainCarouselImage, setMainCarouselImage] = useState(carousel?.snapshots[0]);

  useEffect(() => {
    setCarouselImages(carousel?.snapshots);
  }, [JSON.stringify(carousel)]);

  useEffect(() => {
    if (!_isEmpty(carouselImages)) {
      // Handles deletion of the main carousel image.
      const mainCarouselImageSearchResult = carouselImages.find(
        carouselImageItem =>
          carouselImageItem.content === mainCarouselImage.content);

      // There must always be a main image
      if (!mainCarouselImageSearchResult) {
        setMainCarouselImage(carouselImages[0]);
      }
    }
  }, [carouselImages]);

  const onDragEnd = ({ source, destination }) => {
    if (source === null || destination === null) {
      return;
    }

    const updatedCarouselImages = [...carouselImages];

    const swapped = { ...updatedCarouselImages[destination.index] };
    updatedCarouselImages[destination.index] = updatedCarouselImages[source.index];
    updatedCarouselImages[source.index] = swapped;

    setMainCarouselImage(updatedCarouselImages[0]);
    setCarouselImages(updatedCarouselImages);
  };

  const handleSaveCarouselSnapshots = async () => {
    const payload = {
      snapshots: carouselImages.map(carouselImageItem => ({
        snapshot: carouselImageItem.snapshot,
      })),
    };

    await dispatch(Actions.Api.nautilus[API_RESOURCES.CAROUSEL].put(extractUuid(carousel.uri), payload));
    await handleRefreshCarouselSnapshots(carousel.uri);

    onHide();
  };

  const snapshotIsModelType = snapshotUri => snapshotUri.includes(API_RESOURCES.MODEL);

  return ([
    <UploadModal id="carouselUploadFileModal" />,
    <Modal onHide={onHide} show size="lg">
      <Modal.Header closeButton>
        Manage Carousel
      </Modal.Header>
      <Modal.Body>
        <DragDropContext onDragEnd={onDragEnd}>
          <Row>
            <Col>
              <Image
                style={{ objectFit: 'cover' }}
                className="w-100"
                height={500}
                src={mainCarouselImage?.content}
              />
            </Col>
            <Col>
              <Droppable droppableId="carousel-images-list">
                {provided => (
                  <div
                    id="carouselContentWrapper"
                    style={{ maxHeight: 500, overflowY: 'scroll' }}
                    ref={provided.innerRef}
                    {...provided.droppableProps}
                  >
                    <div id="carouselContentHead" className="d-flex justify-content-between align-items-center">
                      <h2>Carousel Content</h2>
                      <Button
                        size="sm"
                        onClick={() => openUploadModal({
                          modalTemplate: MODAL_TEMPLATES.UPLOAD_FILE,
                          title: 'Upload Carousel Image',
                          onUploadConfirm: handleUploadCarouselImage,
                        })}
                      >
                        <FontAwesomeIcon
                          className="spacer-right"
                          icon={faUpload}
                        />
                        <FormattedMessage
                          id="button.upload"
                          defaultMessage="Upload"
                        />
                      </Button>
                    </div>
                    <div className="d-flex spacer-top align-items-center">
                      <p>Drag items to reorder carousel.</p>
                    </div>
                    <ListGroup
                      id="carouselImagesWrapper"
                      className="mt15"
                    >
                      {!_isEmpty(carouselImages) && carouselImages.map((carouselImageItem, index) => (
                        <Draggable
                          style={{ cursor: 'pointer' }}
                          key={carouselImageItem.content}
                          draggableId={`draggable-${carouselImageItem.content}`}
                          index={index}
                        >
                          {provided => (
                            <ListGroupItem
                              ref={provided.innerRef}
                              {...provided.draggableProps}
                              {...provided.dragHandleProps}
                            >
                              <div id="listGroupItemContent" className="d-flex align-items-center justify-content-between">
                                <div id="positionNoAndImage" className="d-flex align-items-center">
                                  <Badge
                                    className="badge badge-sm d-flex align-items-center justify-content-center text-white mr15"
                                    bg="danger"
                                    style={{ borderRadius: 20 }}
                                  >
                                    <span>{index + 1}</span>
                                  </Badge>
                                  <Image
                                    style={{ width: 70, height: 70, objectFit: 'cover', borderRadius: 12 }}
                                    // className="w-100"
                                    src={carouselImageItem.content}
                                  />
                                </div>
                                <div id="deleteAndSelectPrimaryBtns" className="d-flex align-items-center">
                                  {/* <Button
                                    variant={mainCarouselImage.content === carouselImageItem.content ? 'secondary' :
                                     'success'}
                                    onClick={() => setMainCarouselImage(carouselImageItem)}
                                    className="spacer-right"
                                    size="xs"
                                    disabled={mainCarouselImage.content === carouselImageItem.content}
                                  >
                                    <FontAwesomeIcon icon={mainCarouselImage.content === carouselImageItem.content ?
                                      faCheck :
                                      faImage}
                                    />
                                    {' '}
                                    Set as Main
                                  </Button> */}
                                  <Button
                                    onClick={() => setCarouselImages(previous => previous
                                      .filter(deletableImageItem => (
                                        deletableImageItem.snapshot !== carouselImageItem.snapshot
                                      )))}
                                    size="xs"
                                    variant="danger"
                                    disabled={carouselImages.length < 2
                                          || snapshotIsModelType(carouselImageItem.snapshot)}
                                  >
                                    <FontAwesomeIcon icon={faTrash} />
                                    {' '}
                                    <FormattedMessage
                                      id="button.delete"
                                      defaultMessage="Delete"
                                    />
                                  </Button>
                                </div>
                              </div>
                            </ListGroupItem>
                          )}
                        </Draggable>
                      ))}
                      {provided.placeholder}
                    </ListGroup>
                  </div>
                )}
              </Droppable>
            </Col>
          </Row>
        </DragDropContext>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="danger" onClick={onHide}>
          <FormattedMessage
            id="button.close"
            defaultMessage="Close"
          />
        </Button>
        <Button
          bg="success"
          variant="success"
          onClick={handleSaveCarouselSnapshots}
          disabled={isCarouselFetching || isDocumentFetching}
        >
          {(isCarouselFetching
          || isDocumentFetching)
            ? <Loading /> : (
              <FormattedMessage
                id="button.save"
                defaultMessage="Save"
              />
            )}
        </Button>
      </Modal.Footer>
    </Modal>,
  ]);
};

export default ManageCarouselModal;

ManageCarouselModal.propTypes = {
  carousel: PropTypes.shape({
    snapshots: PropTypes.arrayOf(PropTypes.shape({
      content: PropTypes.string,
    })),
  }).isRequired,
  onHide: PropTypes.func.isRequired,
  handleUploadCarouselImage: PropTypes.func.isRequired,
  handleRefreshCarouselSnapshots: PropTypes.func.isRequired,
};
