import { createSelector } from 'reselect';
import { getPredicate } from 'rapidfab/selectors/helpers/base';
import { getProcessProviders } from 'rapidfab/selectors/processProvider';
import { getServicePostProcessors } from 'rapidfab/selectors/postProcessor';
import { getServiceProviders } from 'rapidfab/selectors/serviceProvider';
import _keyBy from 'lodash/keyBy';
import _filter from 'lodash/filter';
import _map from 'lodash/map';
import _find from 'lodash/find';

export const getProcessProvidersByServiceProviderUriForPostProcessor = createSelector(
  [getPredicate, getProcessProviders],
  (processorUri, processProviders) => _keyBy(
    _filter(processProviders, { post_processor: processorUri }),
    'service_provider',
  ),
);

export const getServicePostProcessorsWithServiceProviderData = createSelector(
  [getServicePostProcessors, getProcessProviders, getServiceProviders],
  (postProcessors, processProviders, serviceProviders) => _map(postProcessors, postProcessor => {
    const processProvidersForProcessor = _filter(
      processProviders,
      { post_processor: postProcessor.uri },
    );
    const serviceProvidersForProcessor = _map(
      processProvidersForProcessor,
      ({ service_provider }) => _find(serviceProviders, { uri: service_provider }),
    );
    return { ...postProcessor, serviceProviders: serviceProvidersForProcessor };
  }),
);
