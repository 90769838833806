export const MACHINE_TYPES = {
  PRINTER: 'printer',
  PRINTER_TYPE: 'printer_type',
  JENI_CLUSTER: 'jeni_cluster',
};

export const MACHINE_TYPE_OPTIONS_VALUES = {
  jeni_cluster: 'Jeni-Cluster',
  printer_type: 'Printer-Type',
  printer: 'Printer',
};

export const MACHINE_TYPE_LIST = [MACHINE_TYPES.PRINTER, MACHINE_TYPES.PRINTER_TYPE];
