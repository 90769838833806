import React from 'react';
import PropTypes from 'prop-types';
import City from 'rapidfab/icons/city';
import Time from 'rapidfab/icons/time';
import Dollar from 'rapidfab/icons/dollar';
import Check from 'rapidfab/icons/check';
import CheckWithWarning from 'rapidfab/icons/checkWithWarning';
import Home from 'rapidfab/icons/home';
import Warning from 'rapidfab/icons/warning';
import Error from 'rapidfab/icons/error';
import Info from 'rapidfab/icons/info';
import Chevron from 'rapidfab/icons/chevron';

export const ICON_NAMES = {
  CITY: 'city',
  TIME: 'time',
  DOLLAR: 'dollar',
  CHECK: 'check',
  CHECK_WITH_WARNING: 'check-with-warning',
  WARNING: 'warning',
  HOME: 'home',
  ERROR: 'error',
  INFO: 'info',
  CHEVRON: 'chevron',
};

// Exported to be used in tests only
export const ICON_COMPONENTS_MAP = {
  [ICON_NAMES.CITY]: City,
  [ICON_NAMES.TIME]: Time,
  [ICON_NAMES.DOLLAR]: Dollar,
  [ICON_NAMES.CHECK]: Check,
  [ICON_NAMES.CHECK_WITH_WARNING]: CheckWithWarning,
  [ICON_NAMES.WARNING]: Warning,
  [ICON_NAMES.HOME]: Home,
  [ICON_NAMES.ERROR]: Error,
  [ICON_NAMES.INFO]: Info,
  [ICON_NAMES.CHEVRON]: Chevron,
};

const ICON_SIZE = {
  default: {
    width: '1em',
    height: '1em',
  },
  large: {
    width: '3em',
    height: '3em',
  },
};

const Icon = ({ name, size, ...otherProps }) => {
  if (!ICON_COMPONENTS_MAP[name]) {
    throw new Error(`Requested icon ${name} is not found`);
  }
  const style = ICON_SIZE[size] || ICON_SIZE.default;

  const IconComponent = ICON_COMPONENTS_MAP[name];
  return (
    <IconComponent
      style={style}
      {...otherProps}
    />
  );
};

Icon.defaultProps = {
  size: '',
};

Icon.propTypes = {
  name: PropTypes.oneOf(Object.values(ICON_NAMES)).isRequired,
  size: PropTypes.string,
};

export default Icon;
