import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import Actions from 'rapidfab/actions';
import * as Selectors from 'rapidfab/selectors';
import { loadModelLibrariesWithModels } from 'rapidfab/dispatchers/modelLibrary';
import { API_RESOURCES, PAGINATION_IGNORE_DEFAULT_LIMIT } from 'rapidfab/constants';

import Loading from 'rapidfab/components/Loading';
import DanfossDDWDashboard from 'rapidfab/components/danfossDDW/DanfossDDWDashboard';

const DanfossDDWDashboardContainer = props => {
  const fetching = useSelector(state =>
    state.ui.nautilus[API_RESOURCES.MODEL_LIBRARY].list.fetching ||
    state.ui.nautilus[API_RESOURCES.MODEL].list.fetching ||
    state.ui.nautilus[API_RESOURCES.LABEL].list.fetching);

  const libraries = useSelector(Selectors.getModelLibraries);
  const modelLibraries = libraries.slice(0, 10);
  const modelsByUri = useSelector(Selectors.getModelsByUri);
  const labelsByUri = useSelector(Selectors.getLabelsByUri);
  const bureau = useSelector(Selectors.getBureau);

  const dispatch = useDispatch();
  const handleInitialize = bureauUri => {
    // filter dashboard items by bureau owner
    loadModelLibrariesWithModels(dispatch, { owner: bureauUri });
    dispatch(Actions.Api.nautilus[API_RESOURCES.LABEL].list({}, { limit: PAGINATION_IGNORE_DEFAULT_LIMIT }));
  };

  useEffect(() => {
    handleInitialize(bureau.uri);
  }, []);

  return fetching
    ? <Loading />
    : (
      <DanfossDDWDashboard
        {...props}
        modelLibraries={modelLibraries}
        modelsByUri={modelsByUri}
        labelsByUri={labelsByUri}
      />
    );
};

export default DanfossDDWDashboardContainer;
