import _keyBy from 'lodash/keyBy';
import _map from 'lodash/map';
import { getStateMSNavImportFiles,
  getStateMSNavWorkflows,
  getStateMSNavWorkflowToWorkflows } from 'rapidfab/selectors/baseStateSelectors';
import { getStateResources } from 'rapidfab/selectors/helpers/base';
import { createSelector } from 'reselect';

export const getMSNavImportFiles = createSelector(
  [getStateMSNavImportFiles, getStateResources],
  (uuids, resources) => _map(uuids, uuid => resources[uuid]),
);

export const getMSNavImportWorkflowUris = createSelector(
  [getMSNavImportFiles],
  msnavImportFiles => (
    _map(msnavImportFiles, 'msnav_workflows').flat()
  ),
);

export const getMSNavWorkflows = createSelector(
  [getStateMSNavWorkflows, getStateResources],
  (uuids, resources) => _map(uuids, uuid => resources[uuid]),
);

export const getMSNavWorkflowsByUri = createSelector(
  [getMSNavWorkflows],
  msnavWorkflows => _keyBy(msnavWorkflows, 'uri'),
);

export const getMSNavWorkflowToWorkflows = createSelector(
  [getStateMSNavWorkflowToWorkflows, getStateResources],
  (uuids, resources) => _map(uuids, uuid => resources[uuid]),
);
