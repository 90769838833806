import dayjs from 'dayjs';

// eslint-disable-next-line import/prefer-default-export
export const getLabelColor = (estimated, actual) => {
  // if the actual date is before estimated, return success
  if (dayjs(actual).isSameOrBefore(estimated)) return 'success';
  // if the actual date is a week after the estimated or more, return danger
  if (dayjs(estimated).add(7, 'days').isSameOrBefore(actual)) return 'danger';
  // else return warning
  return 'warning';
};
