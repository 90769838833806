import { faPlus } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import PropTypes from 'prop-types';
import DisabledFor from 'rapidfab/components/DisabledFor';
import DisabledIconWithTooltip from 'rapidfab/components/DisabledIconWithTooltip';
import VisibleFor from 'rapidfab/components/VisibleFor';
import { ROUTES } from 'rapidfab/constants';
import { getRouteURI } from 'rapidfab/utils/uriUtils';
import React from 'react';
import { Button, Col, Row } from 'react-bootstrap';
import { FormattedMessage } from 'react-intl';

const PostProcessorNavbarLinks = ({ isServices, isGroupQualificationsFeatureEnabled, isDebugModeEnabled }) => (
  <Row>
    <Col xs={12}>
      <VisibleFor bureauUserRole>
        <div className="pull-right">
          <DisabledFor
            nonManager
            /* Disable for Non Managers only when `group-qualifications`
              feature is enabled. No limitations otherwise */
            alwaysEnabled={!isGroupQualificationsFeatureEnabled}
            disabledPrefix={
              isDebugModeEnabled ? (
                <DisabledIconWithTooltip
                  id="limitedFunctionalityDebugMode"
                  defaultMessage="This functionality usage is limited. Contact your Bureau Manager if you have any questions. Debug Info: Locked due to configuration of User Groups and Group Qualifications. See docs for more details."
                  placement="bottom"
                />
              )
                : <DisabledIconWithTooltip placement="bottom" />
            }
          >
            {({ disabled }) => (
              <Button
                variant="primary"
                size="sm"
                href={
                  isServices
                    ? getRouteURI(ROUTES.POST_PROCESSOR_SERVICE_NEW)
                    : getRouteURI(ROUTES.POST_PROCESSOR_NEW)
                }
                disabled={disabled}
              >
                <FontAwesomeIcon icon={faPlus} className="spacer-right" />
                {isServices ? (
                  <FormattedMessage
                    id="record.postProcessorService.add"
                    defaultMessage="Add Post Processor Service"
                  />
                ) : (
                  <FormattedMessage
                    id="record.postProcessor.add"
                    defaultMessage="Add Post Processor"
                  />
                )}
              </Button>
            )}
          </DisabledFor>
        </div>
      </VisibleFor>
    </Col>
  </Row>
);

PostProcessorNavbarLinks.propTypes = {
  isServices: PropTypes.bool.isRequired,
  isGroupQualificationsFeatureEnabled: PropTypes.bool.isRequired,
  isDebugModeEnabled: PropTypes.bool.isRequired,
};

export default PostProcessorNavbarLinks;
