import _mean from 'lodash/mean';

import _sumBy from 'lodash/sumBy';
import { MATERIAL_UNITS, MODEL_UNITS } from 'rapidfab/constants';
import { getLineItemEstimatesTypeObjectKey } from './lineItemUtils';

const MATERIAL_VOLUME_UNIT_COEFFICIENTS = {
  [MATERIAL_UNITS.MM3]: {
    [MATERIAL_UNITS.MM3]: 1, // Identity
    [MATERIAL_UNITS.CM3]: 0.001, // 1 mm3 = 0.001 cm3
    [MATERIAL_UNITS.INCH3]: 0.0000610237441, // 1 mm3 = 0.0000610237441 in3
    [MATERIAL_UNITS.L]: 0.000001, // 1 mm³ = 0.000001 L
    [MATERIAL_UNITS.ML]: 0.001, // 1 mm³ = 0.001 mL
  },
  [MATERIAL_UNITS.CM3]: {
    [MATERIAL_UNITS.MM3]: 1000, // 1 cm3 = 1000 mm3
    [MATERIAL_UNITS.CM3]: 1, // Identity
    [MATERIAL_UNITS.INCH3]: 0.0610237441, // 1 cm3 = 0.0610237441 in3
    [MATERIAL_UNITS.L]: 0.001, // 1 cm³ = 0.001 L
    [MATERIAL_UNITS.ML]: 1, // 1 cm³ = 1 mL
  },
  [MATERIAL_UNITS.INCH3]: {
    [MATERIAL_UNITS.MM3]: 16387.064, // 1 in3 = 16 387.064 mm3
    [MATERIAL_UNITS.CM3]: 16.387064, // 1 in3 = 16.387064 cm3
    [MATERIAL_UNITS.INCH3]: 1, // Identity
    [MATERIAL_UNITS.L]: 0.016387064, // 1 in³ ≈ 0.016387064 L
    [MATERIAL_UNITS.ML]: 16.387064, // 1 in³ ≈ 16.387064 mL
  },
  [MATERIAL_UNITS.L]: {
    [MATERIAL_UNITS.MM3]: 1000000, // 1 L = 1,000,000 mm³
    [MATERIAL_UNITS.CM3]: 1000, // 1 L = 1,000 cm³
    [MATERIAL_UNITS.INCH3]: 61.0237441, // 1 L ≈ 61.024 in³
    [MATERIAL_UNITS.L]: 1, // Identity
    [MATERIAL_UNITS.ML]: 1000, // 1 L = 1,000 mL
  },
  [MATERIAL_UNITS.ML]: {
    [MATERIAL_UNITS.MM3]: 1000, // 1 mL = 1,000 mm³
    [MATERIAL_UNITS.CM3]: 1, // 1 mL = 1 cm³
    [MATERIAL_UNITS.INCH3]: 0.0610237441, // 1 mL ≈ 0.061024 in³
    [MATERIAL_UNITS.L]: 0.001, // 1 mL = 0.001 L
    [MATERIAL_UNITS.ML]: 1, // Identity
  },
};

export const MATERIAL_MASS_UNIT_COEFFICIENTS = {
  [MATERIAL_UNITS.GRAM]: {
    [MATERIAL_UNITS.GRAM]: 1,
    // 1 g = 0.001 kg
    [MATERIAL_UNITS.KG]: 0.001,
    // 1 g = 0.00220462 lb
    [MATERIAL_UNITS.LB]: 0.00220462,
  },
  [MATERIAL_UNITS.KG]: {
    // 1 kg = 1000 g
    [MATERIAL_UNITS.GRAM]: 1000,
    [MATERIAL_UNITS.KG]: 1,
    // 1 kg = 2.20462 lb
    [MATERIAL_UNITS.LB]: 2.20462,
  },
  [MATERIAL_UNITS.LB]: {
    // 1 lb = 453.592 g
    [MATERIAL_UNITS.GRAM]: 453.592,
    // 1 lb = 0.453592 kg
    [MATERIAL_UNITS.KG]: 0.453592,
    [MATERIAL_UNITS.LB]: 1,
  },
};

const MODEL_UNIT_COEFFICIENTS = {
  [MODEL_UNITS.MM]: {
    [MODEL_UNITS.M]: 1000,
    [MODEL_UNITS.CM]: 10,
    [MODEL_UNITS.MM]: 1,
    // 1 mm = 0.039370078740157
    [MODEL_UNITS.FEET]: 0.00328084,
    [MODEL_UNITS.INCHES]: 0.039370078740157,
  },
  [MODEL_UNITS.CM]: {
    [MODEL_UNITS.M]: 0.01,
    [MODEL_UNITS.CM]: 1,
    [MODEL_UNITS.MM]: 10,
    // 1 mm = 0.039370078740157
    [MODEL_UNITS.FEET]: 0.0328084,
    [MODEL_UNITS.INCHES]: 0.39370078740157,
  },
  [MODEL_UNITS.M]: {
    [MODEL_UNITS.M]: 1,
    [MODEL_UNITS.CM]: 100,
    [MODEL_UNITS.MM]: 1000,
    // 1 mm = 0.039370078740157
    [MODEL_UNITS.FEET]: 3.28084,
    [MODEL_UNITS.INCHES]: 39.370078740157,
  },
  [MODEL_UNITS.INCHES]: {
    // 1 in = 25.4 mm
    [MODEL_UNITS.M]: 0.254,
    [MODEL_UNITS.CM]: 2.54,
    [MODEL_UNITS.MM]: 25.4,
    [MODEL_UNITS.FEET]: 0.08333,
    [MODEL_UNITS.INCHES]: 1,
  },
  [MODEL_UNITS.FEET]: {
    // 1 in = 25.4 mm
    [MODEL_UNITS.M]: 0.3048,
    [MODEL_UNITS.CM]: 30.48,
    [MODEL_UNITS.MM]: 304.8,
    [MODEL_UNITS.FEET]: 1,
    [MODEL_UNITS.INCHES]: 12,
  },
};

/**
 *
 * @param {number[]} array
 * @param {number} decimalPlaces
 *
 * @returns {number}
 */

export const calculateAverageFromArray = (array, decimalPlaces = 2) => {
  if (!Array.isArray(array)) {
    return null;
  }

  return +Number(_mean(array).toFixed(decimalPlaces));
};

export function calculateDueDate(pieces) {
  if (!pieces) {
    return null;
  }
  const secondsEstimatedTotal = _sumBy(pieces, piece => Number.parseInt(
    // lineItem.estimates.printing_time is for all pieces of line item
    // For one piece we have to divide it by piece quantity
    piece.lineItem[getLineItemEstimatesTypeObjectKey(piece.lineItem)].printing_time / piece.lineItem.quantity,
    10,
  ));

  if (!secondsEstimatedTotal) {
    return null;
  }

  // Convert seconds to milliseconds
  return new Date(Date.now() + (secondsEstimatedTotal * 1000)).toISOString();
}

export function convertLengthToOtherUnit(length, lengthUnit, requestedUnit) {
  if (!MODEL_UNIT_COEFFICIENTS[lengthUnit]) {
    return null;
  }

  if (!MODEL_UNIT_COEFFICIENTS[lengthUnit][requestedUnit]) {
    return null;
  }

  const coef = MODEL_UNIT_COEFFICIENTS[lengthUnit][requestedUnit];

  return length * coef;
}

export function convertVolumeToOtherUnit(value, valueUnit, requestedUnit) {
  if (!MATERIAL_VOLUME_UNIT_COEFFICIENTS[valueUnit]) {
    return null;
  }

  if (!MATERIAL_VOLUME_UNIT_COEFFICIENTS[valueUnit][requestedUnit]) {
    return null;
  }

  const coef = MATERIAL_VOLUME_UNIT_COEFFICIENTS[valueUnit][requestedUnit];

  return value * coef;
}

export function convertMassToOtherUnit(value, valueUnit, requestedUnit) {
  if (!MATERIAL_MASS_UNIT_COEFFICIENTS[valueUnit]) {
    return null;
  }

  if (!MATERIAL_MASS_UNIT_COEFFICIENTS[valueUnit][requestedUnit]) {
    return null;
  }

  const coef = MATERIAL_MASS_UNIT_COEFFICIENTS[valueUnit][requestedUnit];

  return value * coef;
}
