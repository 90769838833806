import React, { useEffect } from 'react';
import Actions from 'rapidfab/actions';
import ConversionComponent from 'rapidfab/components/records/conversion';
import * as Selectors from 'rapidfab/selectors';
import { API_RESOURCES, ROUTES } from 'rapidfab/constants';
import { getRouteURI } from 'rapidfab/utils/uriUtils';
import { extractUuid } from 'rapidfab/utils/uuidUtils';
import { useDispatch, useSelector } from 'react-redux';

import { CONVERSION } from 'rapidfab/constants/forms';
import _get from 'lodash/get';
import _set from 'lodash/set';
import Alert from 'rapidfab/utils/alert';
import { FormattedMessage } from 'react-intl';

// eslint-disable-next-line no-return-assign
const redirect = () => window.location.hash = getRouteURI(ROUTES.CONVERSIONS);

const redirectToNewCurrencyConversion = uri => {
  window.location = getRouteURI(ROUTES.CONVERSION_EDIT, { uuid: extractUuid(uri) });
};

const ConversionContainer = props => {
  const uuid = useSelector(Selectors.getRouteUUID);
  const initialValues = useSelector(Selectors.getRouteUUIDResource);

  const initialFormValues = {};
  if (initialValues) {
    Object
      .keys(initialValues)
      .filter(key => CONVERSION.FIELDS.includes(key))
      .forEach(key => {
        initialFormValues[key] = initialValues[key];
      });
  }

  const submitting = useSelector(state => Selectors.getResourceFetching(
    state,
    'nautilus.currency-conversion',
  ));
  const apiErrors = useSelector(state => Selectors.getResourceErrors(state, 'nautilus.currency-conversion'));

  const selected = {
    uuid,
    initialFormValues,
    submitting,
    apiErrors,
  };

  const dispatch = useDispatch();
  const conversion = Actions.Api.nautilus[API_RESOURCES.CURRENCY_CONVERSION];

  const onInitialize = currentUUID => {
    if (currentUUID) {
      dispatch(conversion.get(currentUUID));
    }
  };

  const onFormSubmit = payload => {
    CONVERSION.FLOAT_FIELDS.forEach(
      fieldName => {
        const field = _get(payload, fieldName);
        if (field) {
          _set(payload, fieldName, Number.parseFloat(field));
        }
      },
    );

    if (payload.uuid) {
      dispatch(conversion.put(payload.uuid, payload)).then(
        Alert.success(
          <FormattedMessage
            id="toaster.conversion.updated"
            defaultMessage="Conversion successfully updated."
          />,
        ),
      );
    } else {
      dispatch(conversion.post(payload))
        .then(response => {
          Alert.success(
            <FormattedMessage
              id="toaster.conversion.created"
              defaultMessage="Conversion successfully created."
            />,
          );
          if (response) redirectToNewCurrencyConversion(response.headers.location);
        });
    }
  };

  const onFormDelete = currentUUID => {
    if (currentUUID) {
      dispatch(conversion.delete(currentUUID))
        .then(() => Alert.success(
          <FormattedMessage
            id="toaster.conversion.deleted"
            defaultMessage="Conversion {uuid} successfully deleted."
            values={{ uuid: currentUUID }}
          />))
        .then(redirect);
    }
  };

  const dispatched = { onFormSubmit, onFormDelete };

  useEffect(() => onInitialize(uuid), []);

  return <ConversionComponent {...props} {...selected} {...dispatched} />;
};

export default ConversionContainer;
