import React from 'react';
import PropTypes from 'prop-types';
import { FEATURES } from 'rapidfab/constants';
import Feature from 'rapidfab/components/Feature';

const NonHawkingFeature = ({ children }) => (
  <Feature featureNames={[FEATURES.HAWKING_DEPLOYMENT, FEATURES.AUTHENTISE_PDM]} isInverted>
    {children}
  </Feature>
);
NonHawkingFeature.propTypes = {
  children: PropTypes.node.isRequired,
};

export default NonHawkingFeature;
