import { createSelector } from 'reselect';
import { getPredicate } from 'rapidfab/selectors/helpers/base';
import { getMaterialPurchaseOrders } from 'rapidfab/selectors/materialPurchaseOrder';
import _filter from 'lodash/filter';

// eslint-disable-next-line import/prefer-default-export
export const getMaterialPurchaseOrdersForMaterial = createSelector(
  [getPredicate, getMaterialPurchaseOrders],
  (material, materialPurchaseOrders) => {
    const materialUri = material.uri;
    return _filter(materialPurchaseOrders, { material: materialUri });
  },
);
