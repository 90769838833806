import { getPredicate } from 'rapidfab/selectors/helpers/base';
import { createSelector } from 'reselect';
import _filter from 'lodash/filter';
import { getPrinterTypes } from '../printerType';
import { getInitialValuesBureau } from '../bureau';

export const getPrinterTypesWithMaterial = createSelector(
  [getInitialValuesBureau, getPrinterTypes],
  (material, printerTypes) => _filter(
    printerTypes, ({ materials }) => materials.includes(material.uri),
  ),
);

export const getPrinterTypesWithMaterialForMaterial = createSelector(
  [getPredicate, getPrinterTypes],
  (material, printerTypes) => _filter(
    printerTypes, ({ materials }) => materials.includes(material?.uri),
  ),
);
