import React from 'react';
import { faCheckCircle, faTimesCircle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { COLORS } from 'rapidfab/constants';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import { useSelector } from 'react-redux';
import { getAddiguruAnalysisLinkedToRun } from 'rapidfab/selectors/addiguru';
import PropTypes from 'prop-types';
import { useSearchParams } from 'react-router-dom';

const AddiguruLinkIndicator = ({ runUri }) => {
  const analysis = useSelector(state => getAddiguruAnalysisLinkedToRun(state, runUri));
  const [searchParams, setSearchParams] = useSearchParams();
  const linked = !!analysis;

  const scrollToAddiguruSection = () => {
    if (searchParams.get('section') !== 'addiguru') {
      searchParams.set('section', 'addiguru');
      setSearchParams(searchParams, { replace: true });
    } else {
      const element = document.querySelector('#addiguru-section');
      element?.scrollIntoView({ block: 'center', behavior: 'smooth' });
    }
  };

  return (
    <button style={{ all: 'unset', cursor: 'pointer' }} type="button" className="mb-1 d-flex align-items-center" onClick={scrollToAddiguruSection}>
      <b className="spacer-right">
        Linked to Addiguru Analysis:
      </b>
      <OverlayTrigger
        placement="right"
        overlay={(
          <Tooltip id="isRunLinkedToBuildTooltip">
            {linked ? 'See AddiGuru Analysis section below for details' : 'See AddiGuru Analysis section below to link this run to an AddiGuru Analysis'}
          </Tooltip>
        )}
      >
        <FontAwesomeIcon
          icon={linked ? faCheckCircle : faTimesCircle}
          style={{
            color: linked ? COLORS.GREEN : COLORS.GRAY,
            fontSize: 24,
          }}
        />
      </OverlayTrigger>
    </button>
  );
};

AddiguruLinkIndicator.propTypes = {
  runUri: PropTypes.string.isRequired,
};

export default AddiguruLinkIndicator;
