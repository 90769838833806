import React, { memo } from 'react';
import PropTypes from 'prop-types';
import {
  Container,
  ButtonToolbar,
  SplitButton,
  FormControl,
  FormGroup,
  FormLabel,
  Dropdown,
  Row,
  Col,
  Card,
  Form as BSForm, OverlayTrigger, Tooltip,
} from 'react-bootstrap';
import { FormattedMessage } from 'react-intl';
import BreadcrumbNav from 'rapidfab/components/BreadcrumbNav';
import SaveButtonTitle from 'rapidfab/components/SaveButtonTitle';
import UserOption from 'rapidfab/components/UserOption';
import { getShortUUID } from 'rapidfab/utils/uuidUtils';
import SelectSingle from 'rapidfab/components/forms/SelectSingle';
import { locationSettingsResourceType, finalFormInputTypes } from 'rapidfab/types';
import { LOCATION_NOTIFICATION_SETTING_BOOLS } from 'rapidfab/constants';
import {
  LOCATION_NOTIFICATION_SETTINGS_BOOLS_MAP,
} from 'rapidfab/mappings';
import Loading from 'rapidfab/components/Loading';
import { Form, Field } from 'react-final-form';
import isEqual from 'lodash/isEqual';
import { FormControlSelect } from 'rapidfab/components/formTools';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faBan,
  faInfoCircle,
} from '@fortawesome/free-solid-svg-icons';
import SubLocations from 'rapidfab/components/records/SubLocations';

const LocationForm = memo(({
  onDelete,
  users,
  countries,
  updatedLocationSettings,
  onChangeLocationSettings,
  onSubmit,
  isSubmitting,
  initialFormValues,
  ...subLocationProps
}) => (
  <Form
    onSubmit={onSubmit}
    initialValues={initialFormValues}
    initialValuesEqual={isEqual}
    render={({ handleSubmit }) => (
      <form onSubmit={handleSubmit}>
        <Container fluid>
          <BreadcrumbNav
            breadcrumbs={['organize', 'locations', initialFormValues?.id ? `${initialFormValues?.name} (${getShortUUID(initialFormValues?.uuid)})` : 'New']}
          />

          <div className="page-header d-flex align-items-center">
            <h4 className="m-a-0">{initialFormValues?.name ?
              `Default Location: ${initialFormValues.name}` : (
                <FormattedMessage
                  id="field.location.new"
                  defaultMessage="New Location"
                />
              )}
            </h4>
            <OverlayTrigger
              placement="top"
              overlay={(
                <Tooltip>
                  <p>
                    <FormattedMessage
                      id="record.location.jumbotron"
                      defaultMessage="Update or add Manufacturing Location (Location) details.  You can limit Orders, Print Runs, or Users to specific locations."
                    />
                  </p>
                </Tooltip>
              )}
            >
              <FontAwesomeIcon className="pr-1 spacer-left" icon={faInfoCircle} />
            </OverlayTrigger>
          </div>

          <Row>

            <Col xs={5}>
              <Card bg="dark">
                <Card.Header className="pd-exp accent d-flex align-items-center justify-content-between">
                  <FormattedMessage
                    id="field.location"
                    defaultMessage="Location"
                  />
                  <ButtonToolbar className="pull-right">
                    <SplitButton
                      id="uxSaveDropdown"
                      type="submit"
                      variant="success"
                      size="sm"
                      title={isSubmitting ? <Loading /> : <SaveButtonTitle />}
                      pullRight
                      disabled={isSubmitting}
                    >
                      <Dropdown.Item
                        eventKey={1}
                        onClick={() => onDelete(initialFormValues?.uuid)}
                        disabled={!initialFormValues?.id}
                      >
                        <FontAwesomeIcon icon={faBan} />{' '}
                        <FormattedMessage id="button.delete" defaultMessage="Delete" />
                      </Dropdown.Item>
                    </SplitButton>
                  </ButtonToolbar>
                </Card.Header>
                <div className="card-body-wrapper-accent">
                  <Card.Body>
                    <FormGroup className="form-group" controlId="uxName">
                      <FormLabel>
                        <FormattedMessage id="field.name" defaultMessage="Name" />: *
                      </FormLabel>
                      <Field
                        name="name"
                        type="text"
                        render={props => (
                          <FormControl
                            {...props.input}
                            required
                          />
                        )}
                      />
                    </FormGroup>
                    <FormGroup className="form-group" controlId="uxAddress">
                      <FormLabel>
                        <FormattedMessage id="field.address" defaultMessage="Address" />:
                        *
                      </FormLabel>
                      <Field
                        name="address"
                        type="text"
                        render={props => (
                          <FormControl
                            {...props.input}
                            as="textarea"
                            required
                          />
                        )}
                      />
                    </FormGroup>
                    <FormGroup className="form-group" controlId="uxCountries">
                      <FormLabel>
                        <FormattedMessage id="field.country" defaultMessage="Country" />:
                        *
                      </FormLabel>
                      <Field
                        name="countries_served"
                        render={props => (
                          <FormControlSelect
                            {...props.input}
                            required
                          >
                            <option key="placeholder" value="" disabled>
                              Select a country
                            </option>
                            {countries.map(country => (
                              <option key={country.code} value={country.code}>
                                {country.name}
                              </option>
                            ))}
                          </FormControlSelect>
                        )}
                      />
                    </FormGroup>
                    <FormGroup className="form-group" controlId="uxPhone">
                      <FormLabel>
                        <FormattedMessage id="field.phone" defaultMessage="Phone" />:
                      </FormLabel>
                      <Field
                        name="phone"
                        type="tel"
                        render={props => (
                          <FormControl
                            {...props.input}
                          />
                        )}
                      />
                    </FormGroup>
                    <FormGroup className="form-group" controlId="uxContact">
                      <FormLabel>
                        <FormattedMessage id="field.contact" defaultMessage="Contact" />:
                      </FormLabel>
                      <Field
                        name="contact"
                        render={props => (
                          <SelectSingle
                            placeholder="Select a Contact"
                            name={props.input.name}
                            data={users}
                            labelKey="username"
                            value={props.input.value}
                            handleOnChange={props.input.onChange}
                            imitateOnChangeEvent
                            renderLabelCallback={UserOption.generateLabel}
                          />
                        )}
                      />
                    </FormGroup>
                  </Card.Body>
                </div>
              </Card>
            </Col>

            <SubLocations {...subLocationProps} />

            <Col xs={5}>
              <Card bg="dark" className="card-body-wrapper p-a mb15 mt30">
                <FormLabel>
                  <FormattedMessage
                    id="record.location.notificationCheckboxesLabel"
                    defaultMessage="The location contacts should receive the following email notifications"
                  />
                  :
                </FormLabel>
                <div>
                  {Object.values(LOCATION_NOTIFICATION_SETTING_BOOLS)
                    .map(key => {
                      const value = updatedLocationSettings[key];
                      const translationObject =
                          LOCATION_NOTIFICATION_SETTINGS_BOOLS_MAP[key];
                      return (
                        <Field
                          name={key}
                          type="checkbox"
                          render={() => (
                            <BSForm.Check
                              key={key}
                              checked={value}
                              onChange={() => onChangeLocationSettings(key, !value)}
                              className="m-l-0 mr15"
                              type="checkbox"
                              label={(<FormattedMessage {...translationObject} />)}
                            />
                          )}
                        />
                      );
                    })}
                </div>
              </Card>
            </Col>
          </Row>
        </Container>
      </form>
    )}
  />
));

LocationForm.propTypes = {
  initialFormValues: PropTypes.shape({
    contact: PropTypes.string.isRequired,
    id: PropTypes.number.isRequired,
    name: PropTypes.string.isRequired,
    uuid: PropTypes.string.isRequired,
    address: PropTypes.string.isRequired,
    countries_served: PropTypes.arrayOf(PropTypes.string).isRequired,
    phone: PropTypes.string.isRequired,
  }).isRequired,
  handleSubmit: PropTypes.func.isRequired,
  onDelete: PropTypes.func.isRequired,
  users: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  countries: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  updatedLocationSettings: locationSettingsResourceType.isRequired,
  onChangeLocationSettings: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  input: finalFormInputTypes.isRequired,
  isSubmitting: PropTypes.bool.isRequired,
};

export default LocationForm;
