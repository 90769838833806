import Constants, { USER_SETTINGS_KEYS } from 'rapidfab/constants';
import _mapValues from 'lodash/mapValues';
import _invert from 'lodash/invert';

const getInitialState = () => ({
  userSettings: _mapValues(_invert(USER_SETTINGS_KEYS), () => null),
});

function reducer(state = getInitialState(), action) {
  switch (action.type) {
    case Constants.STORE_PREFERENCES_USER_SETTINGS:
      // Update local storage
      return { ...state, userSettings: action.userSettings };
    default:
      return state;
  }
}

export default reducer;
