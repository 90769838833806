import _map from 'lodash/map';
import { MATERIAL_BATCH_POWDER_QUALITY_STATUS_MAP } from 'rapidfab/mappings';
import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import Actions from 'rapidfab/actions';
import { getMaterialBatchesTemporary } from 'rapidfab/selectors';

import MaterialBatches from 'rapidfab/components/inventory/MaterialBatches';
import { MATERIAL_BATCH_STATUSES, API_RESOURCES, MATERIAL_CONTAINER_STATUSES, LIST_BY_URIS_CHUNK_SIZE } from 'rapidfab/constants';

import { FormLabel } from 'react-bootstrap';
import withRecordsListHandling from 'rapidfab/containers/hocs/withRecordsListHandling';

import { useDispatch, useSelector } from 'react-redux';
import _chunk from 'lodash/chunk';
import _uniq from 'lodash/uniq';
import _mapValues from 'lodash/mapValues';
import _flatMapDeep from 'lodash/flatMapDeep';
import * as Selectors from 'rapidfab/selectors';
import SelectMultiple from 'rapidfab/components/forms/SelectMultiple';
import isArray from 'lodash/isArray';

const { DONE, CONSUMED, ...ACTIVE_MATERIAL_BATCH_STATUSES } = MATERIAL_BATCH_STATUSES;
const activeMaterialBatchStatuses = Object.values(ACTIVE_MATERIAL_BATCH_STATUSES);

const MaterialBatchesContainer = ({ filters, onFilterUpdate, ...restProps }) => {
  const initialFilterValuesDict = { done: 'Only Done', active: 'Only Active', is_initial_batch: 'Hide Non Initial' };
  const initialFilterValuesDictMap = {};

  Object.keys(initialFilterValuesDict).forEach(key => {
    initialFilterValuesDictMap[key] = { id: `status.${key}`, defaultMessage: initialFilterValuesDict[key] };
  });

  const availableOrderStatusOptions = _mapValues(initialFilterValuesDictMap, (statusOption, status) => ({
    label: statusOption.defaultMessage,
    status: initialFilterValuesDict[status],
  }));

  const availablePowderStatusOptions = _mapValues(MATERIAL_BATCH_POWDER_QUALITY_STATUS_MAP, (statusOption, status) => ({
    label: statusOption.defaultMessage,
    status,
  }));

  const subLocations = useSelector(Selectors.getSubLocations);

  const onCheckboxChange = event => {
    const changedFilters = { ...filters };
    delete changedFilters.is_initial_batch;
    delete changedFilters.status;

    event.forEach(({ status }) => {
      switch (status) {
        case initialFilterValuesDict.is_initial_batch:
          changedFilters.is_initial_batch = true;
          break;
        case initialFilterValuesDict.active:
          changedFilters.status = activeMaterialBatchStatuses;
          break;
        case initialFilterValuesDict.done:
          changedFilters.status = 'done';
          break;
        default:
          break;
      }
    });
    onFilterUpdate(changedFilters);
  };

  const {
    status: statuses = [],
    powder_quality: powderStatuses = [],
  } = filters;

  let selectedStatuses;
  if (isArray(statuses) && statuses.length) {
    const isSelectedFilterStatusActive = statuses.every(status =>
      (Object.values(ACTIVE_MATERIAL_BATCH_STATUSES).includes(status)));
    selectedStatuses = isSelectedFilterStatusActive && availableOrderStatusOptions.active;
    if (filters.is_initial_batch) {
      selectedStatuses = [selectedStatuses, availableOrderStatusOptions.is_initial_batch];
    }
  } else {
    selectedStatuses = availableOrderStatusOptions[statuses];
    if (filters.is_initial_batch) {
      selectedStatuses = selectedStatuses ? [selectedStatuses, availableOrderStatusOptions.is_initial_batch]
        : [availableOrderStatusOptions.is_initial_batch];
    }
  }

  const onPowderStatusChange = selectedStatuses => {
    const changedFilters = { ...filters };
    if (selectedStatuses.length) {
      changedFilters.powder_quality = _map(selectedStatuses, 'status');
    } else {
      delete changedFilters.powder_quality;
    }

    onFilterUpdate(changedFilters);
  };

  const selectedPowderStatuses = powderStatuses.map(status => availablePowderStatusOptions[status]);

  const dispatch = useDispatch();
  const loadMaterialContainers = uris => {
    _chunk(uris, LIST_BY_URIS_CHUNK_SIZE).forEach(urisChunk => {
      dispatch(Actions.Api.nautilus[API_RESOURCES.MATERIAL_CONTAINER].list(
        { status: [MATERIAL_CONTAINER_STATUSES.NEW, MATERIAL_CONTAINER_STATUSES.IN_USE], uri: urisChunk },
        {},
        {},
        {},
        true,
      ),
      );
    });
  };

  useEffect(() => {
    dispatch(Actions.Api.nautilus[API_RESOURCES.SUB_LOCATION].list());
  }, []);

  useEffect(() => {
    if (restProps?.data.length) {
      const uris = _uniq(_flatMapDeep(restProps.data.map(item => item.containers)));
      loadMaterialContainers(uris);
    }
  }, [JSON.stringify(restProps?.data.map(item => item.uri))]);

  return (
    <MaterialBatches
      {...restProps}
      subLocations={subLocations}
      extraFilters={[
        <div key="ordersExtraFilters" className="form-inline" style={{ lineHeight: '40px' }}>
          <div className="form-group mr15 ">
            <FormLabel htmlFor="statusFilter">
              Batch Status:
            </FormLabel>
            <div className="spacer-left form-control inline-picky-wrapper">
              <SelectMultiple
                title="Status"
                data={Object.values(availableOrderStatusOptions)}
                labelKey="label"
                valueKey="status"
                selectedData={selectedStatuses}
                handleOnClose={onCheckboxChange}
              />
            </div>
          </div>
          <div className="form-group mr15 ">
            <FormLabel htmlFor="statusFilter">
              Powder Quality:
            </FormLabel>
            <div className="spacer-left form-control inline-picky-wrapper">
              <SelectMultiple
                title="Status"
                data={Object.values(availablePowderStatusOptions)}
                labelKey="label"
                valueKey="status"
                selectedData={selectedPowderStatuses}
                handleOnClose={onPowderStatusChange}
              />
            </div>
          </div>
        </div>,
      ]}
    />
  );
};

MaterialBatchesContainer.propTypes = {
  onFilterUpdate: PropTypes.func.isRequired,
  filters: PropTypes.shape({
    is_initial_batch: PropTypes.bool,
    status: PropTypes.arrayOf(PropTypes.string),
    powder_quality: PropTypes.arrayOf(PropTypes.string),
  }).isRequired,
};

export default withRecordsListHandling(
  MaterialBatchesContainer,
  getMaterialBatchesTemporary,
  ['material-batch'],
);
