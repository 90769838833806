import React from 'react';
import PropTypes from 'prop-types';
import Loading from 'rapidfab/components/Loading';
import {
  Button,
  Col,
  FormControl,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  ModalTitle,
  OverlayTrigger,
  Row,
  Tooltip,
  Form as BSForm,
} from 'react-bootstrap';
import { finalFormInputTypes, materialTypeResourceType } from 'rapidfab/types';
import FormRow from 'rapidfab/components/FormRow';
import ColorWithOpacityPicker from 'rapidfab/ColorWithOpacityPicker';
import { ANATOMY_ELEMENTS, ANATOMY_FAMILIES, ANATOMY_PROPERTIES, PART_STATUSES } from 'rapidfab/constants';
import _upperFirst from 'lodash/upperFirst';
import { FormControlSelect } from 'rapidfab/components/formTools';
import FileInput from 'rapidfab/components/records/order/edit/FileInput';
import { FormattedMessage } from 'rapidfab/i18n';
import _map from 'lodash/map';
import _get from 'lodash/get';
import _startCase from 'lodash/startCase';
import _find from 'lodash/find';

import { Form, Field } from 'react-final-form';
import { faExclamationCircle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const getPrinterOptions = (printerConfig, values) => {
  const printerOptions = {
    // Using constants list by default
    families: [...ANATOMY_FAMILIES],
    elements: [...ANATOMY_ELEMENTS],
    properties: [...ANATOMY_PROPERTIES],
  };
  const familiesData = _get(printerConfig, 'anatomy.family');
  if (familiesData) {
    printerOptions.families = _map(familiesData, 'name');
    if (values?.printer_anatomy_family) {
      const selectedFamily = _find(familiesData, {
        name: values?.printer_anatomy_family,
      });
      const selectedFamilyElements = _get(selectedFamily, 'element');
      if (selectedFamilyElements) {
        printerOptions.elements = _map(selectedFamilyElements, 'name');

        if (values?.printer_anatomy_element) {
          const selectedElement = _find(selectedFamilyElements, {
            name: values?.printer_anatomy_element,
          });
          const selectedElementProperties = _get(selectedElement, 'property');
          if (selectedElementProperties) {
            printerOptions.properties = _map(selectedElementProperties, 'name');
          }
        }
      }
    }
  }
  if (
    values?.printer_anatomy_family
    && !printerOptions.families.includes(values?.printer_anatomy_family)
  ) {
    printerOptions.families.push(values?.printer_anatomy_family);
  }
  if (
    values?.printer_anatomy_element
    && !printerOptions.elements.includes(values?.printer_anatomy_element)
  ) {
    printerOptions.elements.push(values?.printer_anatomy_element);
  }
  if (
    values?.printer_anatomy_properties
    && !printerOptions.properties.includes(values?.printer_anatomy_properties)
  ) {
    printerOptions.properties.push(values?.printer_anatomy_properties);
  }
  return printerOptions;
};

const AnatomicalModelPartModal = ({
  onClose,
  isLoading,
  isSubmitting,
  part,
  materials,
  onSave,
  file,
  onFileChange,
  printerConfig,
  initialFormValues,
}) => {
  const disabledActions = isLoading || isSubmitting;
  const downloadLocation = part && part.download_location;
  const partStatus = part && part.status;

  return (
    <Modal size="lg" show onHide={onClose} backdrop="static">
      <Form
        onSubmit={onSave}
        initialValues={initialFormValues}
        render={({ handleSubmit, values }) => {
          const availableMaterialNames = _map(materials, 'name');
          if (values?.material && !availableMaterialNames.includes(values?.material)) {
            availableMaterialNames.push(values?.material);
          }

          const printerOptions = getPrinterOptions(printerConfig, values);

          return (
            <form onSubmit={handleSubmit}>
              <ModalHeader closeButton>
                <ModalTitle>
                  {initialFormValues?.uuid ? 'Edit' : 'Create'} Part
                </ModalTitle>
              </ModalHeader>
              <ModalBody>
                {
                  isLoading
                    ? <Loading />
                    : (
                      <Row>
                        <Col xs={6}>
                          <FormRow
                            id="field.name"
                            defaultMessage="Name"
                            isRequired
                          >
                            <Field
                              name="name"
                              render={props => (
                                <FormControl
                                  required
                                  {...props.input}
                                />
                              )}
                            />
                          </FormRow>
                          <FormRow>
                            <Field
                              type="checkbox"
                              name="to_be_printed"
                              render={props => (
                                <BSForm.Check
                                  {...props.input}
                                  label="Print This Object"
                                />
                              )}
                            />
                          </FormRow>
                          <FormRow
                            defaultMessage="Shell Thickness"
                            isRequired={!values?.solid_infill?.checked}
                          >
                            <Field
                              name="hollow_shells"
                              type="number"
                              render={props => (
                                <FormControl
                                  min={0.3}
                                  max={7.14}
                                  step={0.01}
                                  name={props.input.name}
                                  disabled={values?.solid_infill?.checked}
                                  value={values?.solid_infill?.checked ? '' : props.input.value}
                                  onChange={props.input.onChange}
                                  type={props.input.type}
                                  required={!values?.solid_infill?.checked}
                                />
                              )}
                            />
                            <Field
                              type="checkbox"
                              name="solid_infill"
                              render={props => (
                                <BSForm.Check
                                  {...props.input}
                                  label="Solid Infill"
                                />
                              )}
                            />
                          </FormRow>
                          <FormRow
                            defaultMessage="Flexibility (Shore Value)"
                          >
                            <Field
                              name="flexibility"
                              type="number"
                              render={props => (
                                <FormControl
                                  {...props.input}
                                />
                              )}
                            />
                          </FormRow>
                          <FormRow
                            defaultMessage="Rotation X"
                          >
                            <Field
                              name="rotation_x"
                              type="number"
                              render={props => (
                                <FormControl
                                  {...props.input}
                                />
                              )}
                            />
                          </FormRow>
                          <FormRow
                            defaultMessage="Rotation Y"
                          >
                            <Field
                              name="rotation_y"
                              type="number"
                              render={props => (
                                <FormControl
                                  {...props.input}
                                />
                              )}
                            />
                          </FormRow>
                          <FormRow
                            defaultMessage="Rotation Z"
                          >
                            <Field
                              name="rotation_z"
                              type="number"
                              render={props => (
                                <FormControl
                                  {...props.input}
                                />
                              )}
                            />
                          </FormRow>
                          <FormRow
                            id="field.color"
                            defaultMessage="Color"
                          >
                            <Field
                              name="color"
                              render={color_props => {
                                const color_field = { ...color_props.input, ...color_props.meta };
                                return (
                                  <Field
                                    name="color_opacity"
                                    render={props => {
                                      const opacity_field = { ...props.input, ...props.meta };
                                      return (
                                        <ColorWithOpacityPicker
                                          colorField={color_field}
                                          opacityField={opacity_field}
                                        />
                                      );
                                    }}
                                  />
                                );
                              }}
                            />
                          </FormRow>
                        </Col>
                        <Col xs={6}>
                          <FormRow
                            id="field.material"
                            defaultMessage="Material"
                            isRequired
                          >
                            <Field
                              name="material"
                              render={props => (
                                <FormControl
                                  as="select"
                                  required
                                  {...props.input}
                                >
                                  {!initialFormValues?.material && (
                                    <FormattedMessage id="field.choose" defaultMessage="Choose…">{text =>
                                      <option value="" disabled>{text}</option>}
                                    </FormattedMessage>
                                  )}
                                  {availableMaterialNames.map(materialName => (
                                    <option key={materialName} value={materialName}>
                                      {materialName}
                                    </option>
                                  ))}
                                </FormControl>
                              )}
                            />
                          </FormRow>
                          <FormRow
                            defaultMessage="Printer Anatomy Family"
                          >
                            <Field
                              name="printer_anatomy_family"
                              render={props => (
                                <FormControlSelect
                                  id="printerAnatomyFamily"
                                  {...props.input}
                                >
                                  {!initialFormValues?.printer_anatomy_family && (
                                    <FormattedMessage id="field.choose" defaultMessage="Choose…">{text => (
                                      <option
                                        value=""
                                        disabled={initialFormValues?.printer_anatomy_element}
                                      >
                                        {text}
                                      </option>
                                    )}
                                    </FormattedMessage>
                                  )}
                                  {printerOptions.families.map(type => (
                                    <option value={type} key={type}>{_startCase(type)}</option>
                                  ))}
                                </FormControlSelect>
                              )}
                            />
                          </FormRow>
                          <FormRow
                            defaultMessage="Printer Anatomy Element"
                          >
                            <Field
                              name="printer_anatomy_element"
                              render={props => (
                                <FormControlSelect
                                  id="printerAnatomyElement"
                                  {...props.input}
                                >
                                  {!initialFormValues?.printer_anatomy_element && (
                                    <FormattedMessage id="field.choose" defaultMessage="Choose…">{text => (
                                      <option
                                        value=""
                                        disabled={initialFormValues?.printer_anatomy_element}
                                      >
                                        {text}
                                      </option>
                                    )}
                                    </FormattedMessage>
                                  )}
                                  {printerOptions.elements.map(element => (
                                    <option value={element} key={element}>{_startCase(element)}</option>
                                  ))}
                                </FormControlSelect>
                              )}
                            />
                          </FormRow>
                          <FormRow
                            defaultMessage="Printer Anatomy Properties"
                          >
                            <Field
                              name="printer_anatomy_properties"
                              render={props => (
                                <FormControlSelect
                                  id="printerAnatomyProperties"
                                  {...props.input}
                                >
                                  {!initialFormValues?.printer_anatomy_properties && (
                                    <FormattedMessage id="field.choose" defaultMessage="Choose…">{text => (
                                      <option
                                        value=""
                                        disabled={initialFormValues?.printer_anatomy_properties}
                                      >
                                        {text}
                                      </option>
                                    )}
                                    </FormattedMessage>
                                  )}
                                  {printerOptions.properties.map(type => (
                                    <option value={type} key={type}>{_upperFirst(type)}</option>
                                  ))}
                                </FormControlSelect>
                              )}
                            />
                          </FormRow>
                          {downloadLocation && (
                            <FormRow defaultMessage="Uploaded Part">
                              {partStatus === PART_STATUSES.PROCESSING && <Loading inline />}
                              {partStatus === PART_STATUSES.ERROR && (
                                <OverlayTrigger
                                  placement="top"
                                  overlay={(
                                    <Tooltip>
                                      {part.notes || 'Failed to process STL'}
                                    </Tooltip>
                                  )}
                                >
                                  <FontAwesomeIcon icon={faExclamationCircle} />
                                </OverlayTrigger>
                              )}
                              {partStatus === PART_STATUSES.PROCESSED && <a href={downloadLocation}>{part.file_name || 'Download'}</a>}
                            </FormRow>
                          )}
                          <FormRow
                            defaultMessage={downloadLocation ? 'New File Upload' : 'File Upload'}
                            isRequired={!downloadLocation}
                          >
                            <FileInput
                              fileType={FileInput.fileTypes.model}
                              handleFileChange={onFileChange}
                              name={file && file.name}
                              required={!downloadLocation}
                            />
                          </FormRow>
                        </Col>
                      </Row>
                    )
                }
              </ModalBody>
              <ModalFooter>
                <Button
                  type="submit"
                  variant="success"
                  disabled={disabledActions}
                >
                  { isSubmitting ? <Loading /> : 'Save' }
                </Button>
                <Button variant="primary" disabled={isSubmitting} onClick={onClose}>
                  Cancel
                </Button>
              </ModalFooter>
            </form>
          );
        }}
      />
    </Modal>
  );
};

AnatomicalModelPartModal.defaultProps = {
  isSubmitting: false,
  isLoading: false,
  file: null,
  part: null,
  printerConfig: null,
};

AnatomicalModelPartModal.propTypes = {
  onClose: PropTypes.func.isRequired,
  isSubmitting: PropTypes.bool,
  isLoading: PropTypes.bool,
  initialFormValues: PropTypes.shape({
    uuid: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    to_be_printed: PropTypes.bool.isRequired,
    hollow_shells: PropTypes.bool.isRequired,
    solid_infill: PropTypes.bool.isRequired,
    flexibility: PropTypes.bool.isRequired,
    rotation_x: PropTypes.number.isRequired,
    rotation_y: PropTypes.number.isRequired,
    rotation_z: PropTypes.number.isRequired,
    color: PropTypes.string.isRequired,
    color_opacity: PropTypes.number.isRequired,
    material: PropTypes.string.isRequired,
    printer_anatomy_family: PropTypes.string.isRequired,
    printer_anatomy_element: PropTypes.string.isRequired,
    printer_anatomy_properties: PropTypes.string.isRequired,
  }).isRequired,
  materials: PropTypes.arrayOf(materialTypeResourceType).isRequired,
  file: PropTypes.shape({
    name: PropTypes.string,
  }),
  part: PropTypes.shape({
    file_name: PropTypes.string,
    download_location: PropTypes.string,
    status: PropTypes.string,
    notes: PropTypes.string,
  }),
  onFileChange: PropTypes.func.isRequired,
  onSave: PropTypes.func.isRequired,
  printerConfig: PropTypes.shape({
    anatomy: PropTypes.shape({
      family: PropTypes.arrayOf(PropTypes.shape({
        name: PropTypes.string,
      })),
    }),
  }),
  meta: PropTypes.shape({}).isRequired,
  input: finalFormInputTypes.isRequired,
};

export default AnatomicalModelPartModal;
