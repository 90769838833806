import _filter from 'lodash/filter';
import _has from 'lodash/has';

/**
 * Intended for use on queues page; gets NON integrated machines only:
 *
 * If jeni-cluster FF is enabled, exclude machine-types whose `integrated` key is
 * false, or machine-types that have an `integrated` key present.
 *
 * Otherwise, simply exclude any machines types that have an `integrated` key is false.
 *
 */
// eslint-disable-next-line import/prefer-default-export
export const getNonIntegratedMachines = (machines, { isJeniClusterFeatureEnabled }) => {
  let printers = _filter(machines, ({ printer_type }) => !!printer_type);
  let postProcessors = _filter(machines, ({ post_processor_type }) => !!post_processor_type);
  if (isJeniClusterFeatureEnabled) {
    printers = _filter(printers, ({ printer_type }) =>
      !_has(printer_type, 'integrated') || printer_type?.integrated === false);
    postProcessors = _filter(postProcessors, ({ post_processor_type }) =>
      !_has(post_processor_type, 'integrated') || post_processor_type?.integrated === false);
  } else {
    printers = _filter(printers, ({ printer_type }) => !printer_type?.integrated);
    postProcessors = _filter(postProcessors, ({ post_processor_type }) => !post_processor_type?.integrated);
  }

  return {
    printers,
    postProcessors,
  };
};

/**
 * Filters on printer-types and post-processor-types to return integrated workstations only.
 */
export const getIntegratedWorkstations = (printerTypes, postProcessorTypes) => ({
  integratedPrinterTypes: _filter(printerTypes, { integrated: true }),
  integratedPostProcessorTypes: _filter(postProcessorTypes, { integrated: true }),
});
