import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Button, FormControl } from 'react-bootstrap';

const KEY_ENTER = 13;

const Search = ({
  initialSearch,
  onSearch,
}) => {
  const [searchValue, setSearchValue] = useState(initialSearch);

  useEffect(() => {
    if (initialSearch !== searchValue) {
      setSearchValue(initialSearch);
    }
  }, [initialSearch]);

  const onSearchChange = newSearchValue => {
    setSearchValue(newSearchValue);
  };

  const onKeyPress = target => {
    if (target.charCode === KEY_ENTER) {
      onSearch(searchValue);
    }
  };

  return (
    <div id="search-bar-flex-wrapper" style={{ maxWidth: 400 }} className="d-flex w-full gap-2">
      <FormControl
        type="text"
        name="search"
        placeholder="Search"
        onChange={event => onSearchChange(event.target.value)}
        onKeyPress={onKeyPress}
        value={searchValue}
      />
      <Button
        className="record-list-search-btn w-fit-content"
        variant="primary"
        onClick={() => onSearch(searchValue)}
      >
        Submit
      </Button>
    </div>
  );
};

Search.defaultProps = {
  initialSearch: '',
};

Search.propTypes = {
  initialSearch: PropTypes.string,
  onSearch: PropTypes.func.isRequired,
};

export default Search;
