import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { FormattedMessage } from 'rapidfab/i18n';
import {
  ButtonToolbar,
  FormLabel,
  Col,
  FormControl,
  FormGroup,
  Container,
  Dropdown,
  Row,
  SplitButton,
  InputGroup,
  Card,
  ListGroup,
  ListGroupItem,
  Button,
} from 'react-bootstrap';
import SaveButtonTitle from 'rapidfab/components/SaveButtonTitle';
import Tooltip from 'rapidfab/components/Tooltip';
import Feature from 'rapidfab/components/Feature';
import {
  FormControlDate,
  FormControlSelect,
} from 'rapidfab/components/formTools';
import BreadcrumbNav from 'rapidfab/components/BreadcrumbNav';
import ShipmentAddressModalContainer from 'rapidfab/containers/records/ShipmentAddressModalContainer';
import Documents from 'rapidfab/components/records/Documents';
import {
  SHIPMENT_STATUS_MAP,
  SHIPMENT_ADDRESS_TYPES_MAPPING,
} from 'rapidfab/mappings';
import {
  CurrencySymbols,
  SHIPMENT_STATUSES,
  SHIPMENT_ADDRESS_TYPES,
  DOCUMENT_RELATED_TABLE_NAMES,
  ROUTES,
  FEATURES,
  ORDER_SHIPPING_GROUPING_OPTIONS,
  API_RESOURCES,
} from 'rapidfab/constants';
import { getQRAppUri, getRouteURI } from 'rapidfab/utils/uriUtils';
import {
  shipmentResourceType,
  shipmentAddressType as shipmentAddressPropertyType,
  orderType,
  runResourceType, finalFormInputTypes,
} from 'rapidfab/types';

import { Form, Field } from 'react-final-form';
import isEqual from 'lodash/isEqual';
import _omit from 'lodash/omit';
import DownloadPackingSlipButton from 'rapidfab/components/records/order/edit/DownloadPackingSlipButton';
import {
  addTimeZoneToDateString,
  removeTimeZoneFromDateString,
} from 'rapidfab/utils/formHelpers';
import Loading from 'rapidfab/components/Loading';
import { faBan, faEdit } from '@fortawesome/free-solid-svg-icons';

const CostInput = ({
  availableCurrencies,
  costField,
  currencyField,
  label,
  disabled,
}) => (
  <FormGroup>
    <FormLabel>{label}:</FormLabel>
    <InputGroup>
      <InputGroup.Text className="currency-addon">
        <FormControlSelect
          className="currency"
          disabled={disabled}
          {...currencyField}
        >
          {availableCurrencies.map(({ currency }) => (
            <>
              {/* eslint-disable react/no-danger */}
              <option
                key={currency}
                value={currency}
                dangerouslySetInnerHTML={{ __html: CurrencySymbols[currency] }}
              />
              {/* eslint-enable react/no-danger */}
            </>
          ))}
        </FormControlSelect>
      </InputGroup.Text>
      <FormControl disabled={disabled} type="number" {...costField} />
    </InputGroup>
  </FormGroup>
);
CostInput.defaultProps = { disabled: false };
CostInput.propTypes = {
  label: PropTypes.string.isRequired,
  availableCurrencies: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  costField: PropTypes.shape({}).isRequired,
  currencyField: PropTypes.shape({}).isRequired,
  disabled: PropTypes.bool,
};

const ShipmentAddress = ({ address: addressObject }) => {
  const { name, address, email, phone_number } = addressObject;
  if (!name && !address && !email && !phone_number) {
    return <FormattedMessage id="notAvailable" defaultMessage="N/A" />;
  }
  return (
    <div>
      {name && `${name}, `}
      {address && `${address}, `}
      {phone_number && `${phone_number}, `}
      {email}
    </div>
  );
};
ShipmentAddress.defaultProps = {
  address: {
    name: '',
    address: '',
    phone_number: '',
    email: '',
  },
};
ShipmentAddress.propTypes = {
  address: shipmentAddressPropertyType,
};

const Shipment = ({
  onSave,
  availableCurrencies,
  order,
  run,
  shipment,
  initialFormValues,
  submitting,
  isDebugModeEnabled,
}) => {
  const isShipmentForOrder =
    order &&
    order.shipping_grouping === ORDER_SHIPPING_GROUPING_OPTIONS.BY_ORDER;
  // One of SHIPMENT_ADDRESS_TYPES values
  const [modalAddressType, setModalAddressType] = useState(null);

  const handleRedirectToQR = () => {
    const uri = getQRAppUri(shipment.uri);
    window.open(uri, '_blank');
  };

  return (
    <>
      <Form
        onSubmit={onSave}
        initialValues={initialFormValues}
        initialValuesEqual={isEqual}
        render={({ handleSubmit }) => (
          <form onSubmit={handleSubmit}>
            <Container fluid>
              <BreadcrumbNav
                breadcrumbs={['organize', 'shipments', shipment.name || 'New']}
              />
              <div className="clearfix">
                <ButtonToolbar className="pull-right">
                  <SplitButton
                    disabled={submitting}
                    id="uxSaveDropdown"
                    type="submit"
                    variant="success"
                    size="sm"
                    title={submitting ? <Loading /> : <SaveButtonTitle />}
                    pullRight
                  >
                    <Dropdown.Item eventKey={1} onClick={() => {}}>
                      <FontAwesomeIcon icon={faBan} />{' '}
                      <FormattedMessage
                        id="button.delete"
                        defaultMessage="Delete"
                      />
                    </Dropdown.Item>
                  </SplitButton>
                </ButtonToolbar>
              </div>

              <hr />
              <Card bg="dark" border="secondary" className="mb15">
                <Card.Header className="pd-exp inverse">
                  <FormattedMessage
                    id="record.shipment.details"
                    defaultMessage="Shipment Details"
                  />
                </Card.Header>
                <Card.Body className="pd-exp">
                  <Row>
                    <Col sm={6} xs={12}>
                      <FormGroup>
                        <FormLabel>
                          <FormattedMessage id="field.name" defaultMessage="Name" />
                          : *
                        </FormLabel>
                        <Field
                          name="name"
                          type="text"
                          render={props => (
                            <FormControl
                              {...props.input}
                            />
                          )}
                        />
                      </FormGroup>

                      <FormGroup>
                        <FormLabel>
                          <FormattedMessage
                            id="field.status"
                            defaultMessage="Status"
                          />
                          : *
                        </FormLabel>
                        <Field
                          name="status"
                          render={props => {
                            const field = { ...props.input, ...props.meta };
                            return (
                              <FormControlSelect {...field}>
                                {Object.values(SHIPMENT_STATUSES).map(status => (
                                  <FormattedMessage {...SHIPMENT_STATUS_MAP[status]}>
                                    {text => (
                                      <option value={status} key={status}>
                                        {text}
                                      </option>
                                    )}
                                  </FormattedMessage>
                                ))}
                              </FormControlSelect>
                            );
                          }}
                        />
                      </FormGroup>

                      {order && (
                        <div className="mb15">
                          <b className="spacer-right">
                            <FormattedMessage
                              id="field.order"
                              defaultMessage="Order"
                            />
                            :
                          </b>
                          <a
                            href={getRouteURI(ROUTES.ORDER_EDIT, {
                              uuid: order.uuid,
                            })}
                          >
                            {order.name}
                          </a>
                        </div>
                      )}
                      {run && (
                        <div className="mb15">
                          <b className="spacer-right">
                            <FormattedMessage id="field.run" defaultMessage="Run" />
                            :
                          </b>
                          <a
                            href={getRouteURI(ROUTES.RUN_EDIT, { uuid: run.uuid })}
                          >
                            {run.name}
                          </a>
                        </div>
                      )}
                    </Col>

                    <Col xs={12} sm={3} className="shipment">
                      <FormGroup>
                        <FormLabel>
                          <FormattedMessage
                            id="field.estimatedShipDate"
                            defaultMessage="Estimated Ship Date"
                          />
                          :
                        </FormLabel>
                        <Field
                          name="estimated_shipment_date"
                          parse={addTimeZoneToDateString}
                          format={removeTimeZoneFromDateString}
                          render={props => {
                            const field = { ...props.input, ...props.meta };
                            return (
                              <FormControlDate
                                defaultValue={field.value}
                                {..._omit(field, 'value')}
                                disabled
                              />
                            );
                          }}
                        />
                      </FormGroup>
                      <FormGroup>
                        <FormLabel>
                          <FormattedMessage
                            id="field.estimatedReceiveDate"
                            defaultMessage="Estimated Receive Date"
                          />
                          :
                        </FormLabel>
                        <Field
                          name="estimated_delivery_date"
                          parse={addTimeZoneToDateString}
                          format={removeTimeZoneFromDateString}
                          render={props => {
                            const field = { ...props.input, ...props.meta };
                            return (
                              <FormControlDate
                                defaultValue={field.value}
                                {..._omit(field, 'value')}
                                disabled
                              />
                            );
                          }}
                        />
                      </FormGroup>
                    </Col>

                    <Col xs={12} sm={3} className="shipment">
                      <FormGroup>
                        <FormLabel>
                          <FormattedMessage
                            id="field.actualShipDate"
                            defaultMessage="Actual Ship Date"
                          />
                          :
                        </FormLabel>
                        <Field
                          name="actual_shipment_date"
                          parse={addTimeZoneToDateString}
                          format={removeTimeZoneFromDateString}
                          render={props => {
                            const field = { ...props.input, ...props.meta };
                            return (
                              <FormControlDate
                                defaultValue={field.value}
                                {..._omit(field, 'value')}
                              />
                            );
                          }}
                        />
                      </FormGroup>
                      <FormGroup>
                        <FormLabel>
                          <FormattedMessage
                            id="field.actualReceiveDate"
                            defaultMessage="Actual Receive Date"
                          />
                          :
                        </FormLabel>
                        <Field
                          name="actual_delivery_date"
                          parse={addTimeZoneToDateString}
                          format={removeTimeZoneFromDateString}
                          render={props => {
                            const field = { ...props.input, ...props.meta };
                            return (
                              <FormControlDate
                                defaultValue={field.value}
                                {..._omit(field, 'value')}
                              />
                            );
                          }}
                        />
                      </FormGroup>
                      <Field
                        name="actual_cost"
                        initialValue={initialFormValues?.actual_cost}
                        render={cost_props => {
                          const cost_field = { ...cost_props.input, ...cost_props.meta };
                          return (
                            <Field
                              name="currency"
                              initialValue={initialFormValues?.currency}
                              render={currency_props => {
                                const currency_field = { ...currency_props.input, ...currency_props.meta };
                                return (
                                  <CostInput
                                    costField={cost_field}
                                    currencyField={currency_field}
                                    availableCurrencies={availableCurrencies}
                                    label="Cost"
                                  />
                                );
                              }}
                            />
                          );
                        }}
                      />
                    </Col>

                    <Col sm={3} xs={6}>
                      <FormGroup>
                        <FormLabel>
                          <FormattedMessage
                            id="shippingPartner"
                            defaultMessage="Shipping Partner"
                          />
                          :
                        </FormLabel>
                        <Field
                          name="shipping_partner"
                          type="text"
                          render={props => (
                            <FormControl
                              {...props.input}
                            />
                          )}
                        />
                      </FormGroup>
                    </Col>

                    <Col
                      sm={3}
                      xs={6}
                      className="d-flex align-items-end justify-content-end"
                    >
                      <div className="d-flex align-items-center justify-content-center">
                        <Button disabled>
                          <FormattedMessage
                            id="record.shipment.sendToCarrier"
                            defaultMessage="Send to Carrier"
                          />
                        </Button>
                        <Tooltip id="sendToCarrier" placement="right">
                          <FormattedMessage
                            id="record.shipment.sendToCarrierTooltip"
                            defaultMessage="Contact support@authentise.com to discuss carrier integration"
                          />
                        </Tooltip>
                      </div>
                    </Col>

                    <Col sm={6} xs={12}>
                      <FormGroup>
                        <FormLabel>
                          <FormattedMessage
                            id="field.trackingNumber"
                            defaultMessage="Shipping/Tracking #"
                          />
                          :
                        </FormLabel>
                        <Field
                          name="tracking_number"
                          type="text"
                          render={props => (
                            <FormControl
                              {...props.input}
                            />
                          )}
                        />
                      </FormGroup>
                    </Col>
                  </Row>
                </Card.Body>
              </Card>
              <Card bg="dark" border="secondary" className="mb15">
                <Card.Header className="pd-exp inverse d-flex align-items-center justify-content-between">
                  <FormattedMessage
                    id="record.shipment.packageDetails"
                    defaultMessage="Package Details"
                  />
                  <div>
                    {
                      isDebugModeEnabled && (
                        <Button
                          variant="info"
                          size="sm"
                          style={{ marginRight: '1rem' }}
                          onClick={handleRedirectToQR}
                        >
                          View Packing List in QR
                        </Button>
                      )
                    }
                    {
                      isShipmentForOrder && (
                        <Feature featureName={FEATURES.SHIPMENT_FOR_ORDER}>
                          <Feature featureName={FEATURES.SHIPMENT_PACKING_SLIP}>
                            <DownloadPackingSlipButton
                              documentEndpointName={API_RESOURCES.SHIPMENT_PACKING_SLIP}
                              resourceUri={shipment.uri}
                            />
                          </Feature>
                        </Feature>
                      )
                    }
                  </div>
                </Card.Header>
                <Card.Body className="pd-exp">
                  <Row>
                    <Col xs={12} sm={12}>
                      <ListGroup fill>
                        {Object.values(SHIPMENT_ADDRESS_TYPES).map(
                          shipmentAddressType => (
                            <ListGroupItem key={shipmentAddressType}>
                              <Row>
                                <Col xs={4}>
                                  <div>
                                    <FormattedMessage
                                      {...SHIPMENT_ADDRESS_TYPES_MAPPING[
                                        shipmentAddressType
                                      ]}
                                    />
                                    <Button
                                      className="ml15"
                                      size="xs"
                                      variant="primary"
                                      onClick={() =>
                                        setModalAddressType(shipmentAddressType)}
                                    >
                                      <FontAwesomeIcon icon={faEdit} />
                                    </Button>
                                  </div>
                                </Col>
                                <Col xs={8}>
                                  <ShipmentAddress
                                    address={shipment[shipmentAddressType]}
                                  />
                                </Col>
                              </Row>
                            </ListGroupItem>
                          ),
                        )}
                      </ListGroup>
                    </Col>
                  </Row>
                </Card.Body>
              </Card>
              {shipment.uuid && (
                <Documents
                  relatedTable={DOCUMENT_RELATED_TABLE_NAMES.SHIPMENT}
                  relatedUUID={shipment.uuid}
                />
              )}
            </Container>
          </form>
        )}
      />
      {modalAddressType && (
        <ShipmentAddressModalContainer
          onClose={() => setModalAddressType(null)}
          addressType={modalAddressType}
          shipment={shipment}
        />
      )}
    </>
  );
};

Shipment.defaultProps = {
  run: null,
  order: null,
};
Shipment.propTypes = {
  initialFormValues: PropTypes.shape({
    name: PropTypes.string.isRequired,
    estimated_shipment_date: PropTypes.string.isRequired,
    estimated_delivery_date: PropTypes.string.isRequired,
    currency: PropTypes.string.isRequired,
    actual_delivery_date: PropTypes.string.isRequired,
    actual_shipment_date: PropTypes.string.isRequired,
    actual_cost: PropTypes.string.isRequired,
    tracking_number: PropTypes.string.isRequired,
    status: PropTypes.string.isRequired,
    shipping_partner: PropTypes.string.isRequired,
  }).isRequired,
  handleSubmit: PropTypes.func.isRequired,
  onSave: PropTypes.func.isRequired,
  input: finalFormInputTypes.isRequired,
  meta: PropTypes.shape({}).isRequired,
  availableCurrencies: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  shipment: shipmentResourceType.isRequired,
  run: runResourceType,
  order: orderType,
  submitting: PropTypes.bool.isRequired,
  isDebugModeEnabled: PropTypes.bool.isRequired,
};

export default Shipment;
