import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import _find from 'lodash/find';

// eslint-disable-next-line import/no-cycle
import { AnatomicalModelPartModal } from 'rapidfab/components/AnatomicalModel';
import * as Selectors from 'rapidfab/selectors';
import { API_RESOURCES, MANUFACTURING_PROCESS_TO_PRINTER_CONFIGURATION_MAP, PRINTER_CONFIGURATION_JSON } from 'rapidfab/constants';
import { extractUuid } from 'rapidfab/utils/uuidUtils';
import Actions from 'rapidfab/actions';
import Alert from 'rapidfab/utils/alert';

import { ANATOMICAL_MODEL_PART_MODAL_CONTAINER } from 'rapidfab/constants/forms';
import { FormattedMessage } from 'react-intl';

const AnatomicalModelPartModalContainer = props => {
  const { uuid, assemblyUri } = props;

  const bureau = useSelector(Selectors.getBureau);
  const materials = useSelector(Selectors.getMaterials);

  const isLoading = useSelector(state =>
    state.ui.nautilus[API_RESOURCES.ANATOMICAL_MODEL_PART].get.fetching);
  const isSubmitting = useSelector(state =>
    state.ui.nautilus[API_RESOURCES.ANATOMICAL_MODEL_PART].post.fetching ||
    state.ui.nautilus[API_RESOURCES.ANATOMICAL_MODEL_PART].put.fetching);

  const part = useSelector(state => Selectors.getUUIDResource(state, uuid));
  const initialValues = part || {};
  const initialFormValues = {};
  Object
    .keys(initialValues)
    .filter(key => ANATOMICAL_MODEL_PART_MODAL_CONTAINER.FIELDS.includes(key))
    .forEach(key => {
      initialFormValues[key] = initialValues[key];
    });

  const assembly = useSelector(state =>
    Selectors.getUUIDResource(state, assemblyUri && extractUuid(assemblyUri)));
  const printerTechName = assembly && MANUFACTURING_PROCESS_TO_PRINTER_CONFIGURATION_MAP[assembly.printing_tech];
  const printerConfig = printerTechName &&
    _find(PRINTER_CONFIGURATION_JSON.printerTechnology, { name: printerTechName });

  const selected = {
    printerConfig,
    part,
    bureau,
    materials,
    isLoading,
    isSubmitting,
    initialFormValues,
  };

  const [file, setFile] = useState(null);

  const onFileChange = event => {
    const newFile = event.target.files[0];
    setFile(newFile);
  };

  const dispatch = useDispatch();

  // eslint-disable-next-line no-shadow
  const onInitialize = (uuid, bureauUri) => {
    if (uuid) {
      dispatch(Actions.Api.nautilus[API_RESOURCES.ANATOMICAL_MODEL_PART].get(uuid));
    }
    dispatch(Actions.Api.nautilus[API_RESOURCES.MATERIAL].list({ bureau: bureauUri }));
  };

  // eslint-disable-next-line no-shadow
  const onSave = async (payload, file) => {
    const updatedPayload = { ...payload };

    if (updatedPayload.solid_infill) {
      updatedPayload.hollow_shells = null;
    }

    let partRequest;
    let partUri;
    if (updatedPayload.uuid) {
      partUri = updatedPayload.uri;
      partRequest = dispatch(Actions.Api.nautilus[API_RESOURCES.ANATOMICAL_MODEL_PART]
        .put(updatedPayload.uuid, updatedPayload))
        .then(response => {
          Alert.success(
            <FormattedMessage
              id="toaster.anatomicalModel.partUpdated"
              defaultMessage="Part updated successfully."
            />,
          );
          return response;
        });
    } else {
      if (!file) {
        Alert.warning(
          <FormattedMessage
            id="toaster.warning.uploadFile"
            defaultMessage="Please upload a file first."
          />,
        );
        return;
      }
      updatedPayload.anatomical_model_assembly = assemblyUri;
      partRequest = dispatch(Actions.Api.nautilus[API_RESOURCES.ANATOMICAL_MODEL_PART].post(updatedPayload))
        .then(prepTaskResponse => {
          partUri = prepTaskResponse.headers.location;
          Alert.success(
            <FormattedMessage
              id="toaster.anatomicalModel.partCreated"
              defaultMessage="Part created successfully."
            />,
          );
          return prepTaskResponse;
        });
    }

    partRequest.then(async response => {
      const { uploadLocation } = response.headers;
      if (file && uploadLocation) {
        await dispatch(Actions.UploadModel.upload(uploadLocation, file));
      }

      props.onClose(partUri);
    });
  };

  useEffect(() => onInitialize(uuid, bureau.uri), []);

  return (
    <AnatomicalModelPartModal
      {...props}
      {...selected}
      file={file}
      onFileChange={onFileChange}
      onSave={payload => onSave(payload, file)}
    />
  );
};

AnatomicalModelPartModalContainer.propTypes = {
  uuid: PropTypes.string,
  assemblyUri: PropTypes.string.isRequired,
  onClose: PropTypes.func.isRequired,
};
AnatomicalModelPartModalContainer.defaultProps = {
  uuid: null,
};

export default AnatomicalModelPartModalContainer;
