import { createSelector } from 'reselect';
import _map from 'lodash/map';
import * as baseStateSelectors from 'rapidfab/selectors/baseStateSelectors';
import _keyBy from 'lodash/keyBy';
import { getStateResources } from './helpers/base';

export const getModelers = createSelector(
  [baseStateSelectors.getStateModelers, getStateResources],
  (uuids, resources) => _map(uuids, uuid => resources[uuid]),
);

export const getModelersByUri = createSelector([getModelers], modelers =>
  _keyBy(modelers, 'uri'),
);
