import Actions from 'rapidfab/actions';
import ToolingStockTypes from 'rapidfab/components/inventory/ToolingStockTypes';
import ToolingStockModalContainer from 'rapidfab/containers/inventory/ToolingStockTypeModalContainer';
import React, { useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { API_RESOURCES } from 'rapidfab/constants';
import { getToolingStockTypes } from 'rapidfab/selectors/toolingStockType';
import isFetchingInitial from 'rapidfab/utils/isFetchingInitial';
import { useDispatch, useSelector } from 'react-redux';

const ToolingStockTypesListContainer = props => {
  const fetching = useSelector(state =>
    isFetchingInitial(state.ui.nautilus[API_RESOURCES.TOOLING_STOCK_TYPE].list),
  );
  const toolingStockTypes = useSelector(getToolingStockTypes);
  const [searchParams] = useSearchParams();
  const uuid = searchParams.get('uuid');

  const [
    isCreateToolingStockTypeModalShown,
    setIsCreateToolingStockTypeModalShown,
  ] = useState(false);

  const dispatch = useDispatch();

  const onInitialize = () => {
    dispatch(
      Actions.Api.nautilus[API_RESOURCES.TOOLING_STOCK_TYPE].list(
        {},
        {},
        {},
        {},
        true,
      ),
    );
  };

  useEffect(() => onInitialize(), []);

  return (
    <>
      <ToolingStockTypes
        {...props}
        fetching={fetching}
        toolingStockTypes={toolingStockTypes}
        setIsCreateToolingStockTypeModalShown={
          setIsCreateToolingStockTypeModalShown
        }
      />
      {(isCreateToolingStockTypeModalShown || uuid) && (
        <ToolingStockModalContainer
          isVisible={isCreateToolingStockTypeModalShown}
          hideModal={() => setIsCreateToolingStockTypeModalShown(false)}
        />
      )}
    </>
  );
};

export default ToolingStockTypesListContainer;
