import * as Selectors from 'rapidfab/selectors';
import React, { useEffect, useState } from 'react';
import Alert from 'rapidfab/utils/alert';
import { useDispatch, useSelector } from 'react-redux';

import Actions from 'rapidfab/actions';
import { getRouteUUID, getRouteUUIDResource } from 'rapidfab/selectors';

import PostProcessor from 'rapidfab/components/records/PostProcessor';
import { getRouteURI } from 'rapidfab/utils/uriUtils';
import { API_RESOURCES, ROUTES } from 'rapidfab/constants';

const PostProcessorContainer = props => {
  const uuid = useSelector(getRouteUUID);
  const postProcessor = useSelector(getRouteUUIDResource);
  const locations = useSelector(Selectors.getLocations);
  if (postProcessor && postProcessor.is_service) {
    window.location.hash = getRouteURI(ROUTES.POST_PROCESSOR_SERVICE_EDIT, { uuid });
  }

  const [tab, setTab] = useState('1');
  const [location, setLocation] = useState(() => (postProcessor
    ? postProcessor.location
    : (locations.length ? locations[0].uri : null)));
  const [subLocation, setSubLocation] = useState(postProcessor?.sub_location || '');
  const dispatch = useDispatch();

  const onInitialize = async currentUUID => {
    try {
      if (currentUUID) {
        const postProcessorResponse =
          await dispatch(Actions.Api.nautilus[API_RESOURCES.POST_PROCESSOR].get(currentUUID));
        const postProcessor = postProcessorResponse.json;

        if (postProcessor) {
          setLocation(postProcessor.location);
          setSubLocation(postProcessor.sub_location);
        }
      } else {
        setLocation(locations.length ? locations[0].uri : null);
      }
    } catch (error) {
      Alert.error(error.message);
    }
  };

  useEffect(() => {
    onInitialize(uuid);
  }, []);

  const handleSelectTab = currentTab => {
    setTab(currentTab);
  };

  return (
    <PostProcessor
      {...props}
      tab={tab}
      locationState={{
        location,
        setLocation,
      }}
      subLocationState={{
        subLocation,
        setSubLocation,
      }}
      location={location}
      subLocation={subLocation}
      postProcessor={postProcessor}
      handleSelectTab={handleSelectTab}
    />
  );
};

export default PostProcessorContainer;
