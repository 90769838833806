import bureauDA from './bureau/da.json';
import buttonsDA from './buttons/da.json';
import cadfileDA from './cadfile/da.json';
import hawkingDA from './hawking/da.json';
import modalsDA from './modals/da.json';
import othersDA from './others/da.json';
import recordsDA from './records/da.json';
import serviceDA from './service/da.json';
import tooltipsDA from './tooltips/da.json';
import laborDA from './labor/da.json';

import bureauDE from './bureau/de.json';
import buttonsDE from './buttons/de.json';
import cadfileDE from './cadfile/de.json';
import hawkingDE from './hawking/de.json';
import modalsDE from './modals/de.json';
import othersDE from './others/de.json';
import recordsDE from './records/de.json';
import serviceDE from './service/de.json';
import tooltipsDE from './tooltips/de.json';
import laborDE from './labor/de.json';

import bureauEN from './bureau/en.json';
import buttonsEN from './buttons/en.json';
import cadfileEN from './cadfile/en.json';
import hawkingEN from './hawking/en.json';
import modalsEN from './modals/en.json';
import othersEN from './others/en.json';
import recordsEN from './records/en.json';
import serviceEN from './service/en.json';
import tooltipsEN from './tooltips/en.json';
import laborEN from './labor/en.json';

import bureauFR from './bureau/fr.json';
import buttonsFR from './buttons/fr.json';
import cadfileFR from './cadfile/fr.json';
import hawkingFR from './hawking/fr.json';
import modalsFR from './modals/fr.json';
import othersFR from './others/fr.json';
import recordsFR from './records/fr.json';
import serviceFR from './service/fr.json';
import tooltipsFR from './tooltips/fr.json';
import laborFR from './labor/fr.json';

import bureauJA from './bureau/ja.json';
import buttonsJA from './buttons/ja.json';
import cadfileJA from './cadfile/ja.json';
import hawkingJA from './hawking/ja.json';
import modalsJA from './modals/ja.json';
import othersJA from './others/ja.json';
import recordsJA from './records/ja.json';
import serviceJA from './service/ja.json';
import tooltipsJA from './tooltips/ja.json';
import laborJA from './labor/ja.json';

export const componentsDA = Object.assign(
  bureauDA,
  buttonsDA,
  cadfileDA,
  hawkingDA,
  modalsDA,
  othersDA,
  recordsDA,
  serviceDA,
  tooltipsDA,
  laborDA,
);

export const componentsDE = Object.assign(
  bureauDE,
  buttonsDE,
  cadfileDE,
  hawkingDE,
  modalsDE,
  othersDE,
  recordsDE,
  serviceDE,
  tooltipsDE,
  laborDE,
);

export const componentsEN = Object.assign(
  bureauEN,
  buttonsEN,
  cadfileEN,
  hawkingEN,
  modalsEN,
  othersEN,
  recordsEN,
  serviceEN,
  tooltipsEN,
  laborEN,
);

export const componentsFR = Object.assign(
  bureauFR,
  buttonsFR,
  cadfileFR,
  hawkingFR,
  modalsFR,
  othersFR,
  recordsFR,
  serviceFR,
  tooltipsFR,
  laborFR,
);

export const componentsJA = Object.assign(
  bureauJA,
  buttonsJA,
  cadfileJA,
  hawkingJA,
  modalsJA,
  othersJA,
  recordsJA,
  serviceJA,
  tooltipsJA,
  laborJA,
);
