module.exports = {
  /**
   * @deprecated consider to use selectors (see selectors/helpers/permissions)
   */
  has(namespace, right, state) {
    return state.permissions.some(
      permission =>
        permission.namespace === namespace && permission.right === right,
    );
  },
};
