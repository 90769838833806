import React from 'react';
import PropTypes from 'prop-types';
import { Row, Col } from 'react-bootstrap';
import { FormattedMessage } from 'react-intl';
import NetfabbButton from 'rapidfab/components/NetfabbButton';

const ForgeAuth = ({ redirectUrl }) => (
  <Row className="mt15">
    <Col md={{ span: 6, offset: 3 }}>
      <NetfabbButton
        onClick={() => {
          window.location = redirectUrl;
        }}
      >
        <FormattedMessage
          id="record.cadfile.forgeauthorize"
          defaultMessage="Log in with Forge"
        />
      </NetfabbButton>
    </Col>
  </Row>
);

ForgeAuth.propTypes = {
  redirectUrl: PropTypes.string.isRequired,
};

export default ForgeAuth;
