import _isEmpty from 'lodash/isEmpty';
import _map from 'lodash/map';
import Actions from 'rapidfab/actions';
import JeniCluster from 'rapidfab/components/records/JeniCluster';
import { API_RESOURCES, ROUTES } from 'rapidfab/constants';
import { useFetchMultipleResources } from 'rapidfab/hooks';
import { FormattedMessage } from 'rapidfab/i18n';
import * as Selectors from 'rapidfab/selectors';
import { getRouteUUIDResource } from 'rapidfab/selectors';
import Alert from 'rapidfab/utils/alert';
import { saveWorkflow } from 'rapidfab/utils/handleWorkflowSave';
import { getIntegratedWorkstations } from 'rapidfab/utils/jeniUtils';
import { getApiURI, getRouteURI } from 'rapidfab/utils/uriUtils';
import { extractUuid } from 'rapidfab/utils/uuidUtils';
import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

const JeniClusterContainer = props => {
  const dispatch = useDispatch();

  const routeUuid = useSelector(Selectors.getRouteUUID);
  const jeniCluster = useSelector(getRouteUUIDResource);
  const workflow = useSelector(state => Selectors.getUUIDResource(state, extractUuid(jeniCluster?.workflow)));
  const postProcessorTypes = useSelector(Selectors.getPostProcessorTypes);
  const printerTypes = useSelector(Selectors.getPrinterTypes);

  const { LOCATION } = useFetchMultipleResources([
    { resource: API_RESOURCES.JENI_CLUSTER,
      configuration: getApiURI(routeUuid, API_RESOURCES.JENI_CLUSTER),
      method: 'get',
      shouldFetch: !!routeUuid },
    { resource: API_RESOURCES.LOCATION },
  ]);

  const onInitializeWorkflow = async workflowURI => {
    if (workflowURI) {
      await dispatch(Actions.Api.nautilus[API_RESOURCES.WORKFLOW].get(extractUuid(workflowURI)));
    }
  };

  const jeniClusterComponentRef = useRef(null);
  const [jeniClusterPayload, setJeniClusterPayload] = useState(null);

  useEffect(() => {
    onInitializeWorkflow(jeniCluster?.workflow);
  }, [jeniCluster?.workflow]);

  const { data: locations } = LOCATION;

  const { integratedPostProcessorTypes, integratedPrinterTypes } =
    getIntegratedWorkstations(printerTypes, postProcessorTypes);

  const isJeniClusterFetching = useSelector(state =>
    state.ui.nautilus[API_RESOURCES.JENI_CLUSTER].get.fetching);

  const isJeniClusterSubmitting = useSelector(state =>
    state.ui.nautilus[API_RESOURCES.JENI_CLUSTER].post.fetching ||
    state.ui.nautilus[API_RESOURCES.JENI_CLUSTER].put.fetching ||
    state.ui.nautilus[API_RESOURCES.WORKFLOW].post.fetching ||
    state.ui.nautilus[API_RESOURCES.WORKFLOW].put.fetching);

  const isJeniRelatedWorkflowFetching = useSelector(state =>
    state.ui.nautilus[API_RESOURCES.WORKFLOW].get.fetching);

  const isEditingExistingResource = !_isEmpty(jeniCluster);

  const initialFormValues = {
    name: jeniCluster?.name,
    printerType: {
      uri: jeniCluster?.jeni_printer_types[0].uri,
      count: jeniCluster?.jeni_printer_types[0].count,
    },
    postProcessorTypes: _map(
      jeniCluster?.jeni_post_processor_types,
      ({ count, uri }) => ({
        count,
        uri,
      }),
    ),
    location: jeniCluster?.location,
    ipAddress: jeniCluster?.ip_address,
  };

  if (routeUuid && !isJeniClusterFetching && !isJeniClusterSubmitting && !jeniCluster) {
    Alert.error(`JENI Cluster ${routeUuid} not found.`);
  }

  const handleCreateJeniCluster = async payload => {
    const jeniClusterPostResponse = await dispatch(Actions.Api.nautilus[API_RESOURCES.JENI_CLUSTER].post(payload));
    Alert.success('Successfully created JENI Cluster.');

    window.location = getRouteURI(ROUTES.JENI_CLUSTER_EDIT,
      { uuid: extractUuid(jeniClusterPostResponse?.headers.location) }, {}, false);
  };

  const handleUpdateJeniCluster = async payload => {
    await dispatch(Actions.Api.nautilus[API_RESOURCES.JENI_CLUSTER].put(extractUuid(jeniCluster?.uri), payload));

    Alert.success(`Successfully updated JENI Cluster: ${extractUuid(jeniCluster?.uri)}`);
    await dispatch(Actions.Api.nautilus[API_RESOURCES.JENI_CLUSTER].get(extractUuid(jeniCluster?.uri)));
  };

  const handleDeleteJeniCluster = () => {
    dispatch(Actions.Api.nautilus[API_RESOURCES.JENI_CLUSTER].delete(extractUuid(jeniCluster?.uri)));

    Alert.success(`Successfully deleted JENI Cluster: ${extractUuid(jeniCluster?.uri)}`);
    window.location = getRouteURI(ROUTES.JENI_CLUSTERS);
  };

  const onIntegratedWorkflowSave = async (payload, deletedSteps, deletedSpecimens, onComplete) => {
    // Get the state from the child component.
    const integratedWorkflowState = jeniClusterComponentRef.current?.getWorkflowState();

    const updatedPayload = { ...payload };
    // Delete readonly fields
    delete updatedPayload.flow_time;
    delete updatedPayload.flow_time_queued;

    if (!updatedPayload.name || !updatedPayload.process_steps.length) {
      Alert.error('Please ensure that the Workflow Name is entered and Workflow Steps are included before proceeding.');
      return;
    }

    const response = await saveWorkflow(dispatch, updatedPayload, { isIntegrated: true });
    const uri = response?.headers?.location;

    dispatch(Actions.Api.nautilus[API_RESOURCES.WORKFLOW].get(extractUuid(uri)));
    const stepsToDelete = deletedSteps.filter(
      stepUUID => integratedWorkflowState.steps.find(step_ =>
        extractUuid(step_.uri) === stepUUID)?.workflows.length < 2,
    );

    const deletePromises = _map(stepsToDelete, currentUUID =>
      dispatch(Actions.Api.nautilus[API_RESOURCES.PROCESS_STEP].delete(currentUUID)),
    );
    await Promise.all(deletePromises);

    const deleteSpecimensPromises = _map(deletedSpecimens, specimen =>
      dispatch(Actions.Api.nautilus[API_RESOURCES.SPECIMEN].delete(specimen.uuid)),
    );

    await Promise.all(deleteSpecimensPromises);
    if (!extractUuid(payload.uri)) {
      Alert.success(
        <FormattedMessage
          id="toaster.specimen.created"
          defaultMessage="Successfully created"
        />);
    } else {
      Alert.success(<FormattedMessage
        id="toaster.specimen.updated"
        defaultMessage="Successfully updated"
      />);
    }

    onComplete(uri);

    if (isEditingExistingResource) {
      handleUpdateJeniCluster({ ...jeniClusterPayload, workflow: jeniCluster?.workflow });
    } else {
      handleCreateJeniCluster({ ...jeniClusterPayload, workflow: uri });
    }
  };

  const onSubmitJeniCluster = async (formValues, pptState) => {
    jeniClusterComponentRef.current?.onIntegratedWorkflowSave();

    const jeniClusterPayload = {
      name: formValues.name,
      ip_address: formValues.ipAddress,
      jeni_printer_types: [
        {
          count: Number(formValues.printerType.count),
          uri: formValues.printerType.uri,
        },
      ],
      jeni_post_processor_types: pptState.map(pptStateItem => ({
        count: Number(pptStateItem.count),
        uri: pptStateItem.postProcessorType,
      })),
      location: formValues.location,
      workflow: null,
    };

    setJeniClusterPayload(jeniClusterPayload);
  };

  const selected = {
    printerTypes: integratedPrinterTypes,
    postProcessorTypes: integratedPostProcessorTypes,
    locations,
    isJeniClusterFetching,
    isJeniClusterSubmitting,
    isJeniRelatedWorkflowFetching,
    workflow,
  };

  const dispatched = {
    onSubmitJeniCluster,
    handleDeleteJeniCluster,
  };

  return (
    <JeniCluster
      ref={jeniClusterComponentRef}
      jeniCluster={jeniCluster}
      onIntegratedWorkflowSave={onIntegratedWorkflowSave}
      initialFormValues={jeniCluster ? initialFormValues : {}}
      {...props}
      {...selected}
      {...dispatched}
    />
  );
};

export default JeniClusterContainer;
