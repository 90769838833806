import { faClose } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import PropTypes from 'prop-types';
import { Modal, ListGroup, ListGroupItem, Row, Col, Button } from 'react-bootstrap';
import { FormattedMessage } from 'react-intl';
import dayjs from 'dayjs';
import { DATETIME_DEFAULT_FORMAT } from 'rapidfab/constants';
import OrderQuoteExportButton from 'rapidfab/components/records/order/edit/OrderQuoteExportButton';

function OrderQuoteDownloadModal(props) {
  const {
    show,
    orderQuoteDocuments,
    close,
  } = props;

  return (
    <Modal show={show} onHide={close} backdrop="static" dialogClassName="custom-darken-modal">
      <Modal.Header>
        <Modal.Title className="w-100 d-flex align-items-center justify-content-between">
          <FormattedMessage
            id="orderQuote.documents"
            defaultMessage="Order Quote Documents"
          />
          <FontAwesomeIcon
            icon={faClose}
            onClick={close}
            tabIndex={0}
            role="button"
            className="custom-darken-modal-button"
          />
        </Modal.Title>
      </Modal.Header>
      <Modal.Body className="custom-darken-modal--body-scroll">
        <ListGroup fill>
          {orderQuoteDocuments.map(orderQuoteDocument => (
            <ListGroupItem key={orderQuoteDocument.id}>
              <Row>
                <Col xs={8}>
                  {dayjs(orderQuoteDocument.created).format(DATETIME_DEFAULT_FORMAT)}
                </Col>
                <Col xs={4}>
                  <OrderQuoteExportButton
                    asLink
                    asIcon
                    download={orderQuoteDocument.content}
                    downloadLabelText="(download)"
                    pullToLeft={false}
                  />
                </Col>
              </Row>
            </ListGroupItem>
          ))}
        </ListGroup>
      </Modal.Body>
      <Modal.Footer>
        <Button onClick={close}>
          <FormattedMessage id="button.cancel" defaultMessage="Cancel" />
        </Button>
      </Modal.Footer>
    </Modal>
  );
}

OrderQuoteDownloadModal.defaultProps = {
  orderQuoteDocuments: [],
};

OrderQuoteDownloadModal.propTypes = {
  close: PropTypes.func.isRequired,
  orderQuoteDocuments: PropTypes.arrayOf(PropTypes.shape({})),
  show: PropTypes.bool.isRequired,
};

export default OrderQuoteDownloadModal;
