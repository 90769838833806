import React, { useEffect, useState } from 'react';
import { useFetchMultipleResources } from 'rapidfab/hooks';
import { useDispatch, useSelector } from 'react-redux';
import { getRouteUUID, getPrinterTypesByUri, getPrintersByUri, getRouteUUIDResource, getStateEventStreamEvents } from 'rapidfab/selectors';
import { buildURIFromUUID, getRouteURI } from 'rapidfab/utils/uriUtils';
import { useNavigate } from 'react-router-dom';

import { API_RESOURCES, ROUTES, EVENT_STREAM_SELECTOR_RESOURCES } from 'rapidfab/constants';
import Actions from 'rapidfab/actions';
import alert from 'rapidfab/utils/alert';
import { FormattedMessage } from 'rapidfab/i18n';
import machineFieldAddedToSlicerConfig from 'rapidfab/selectors/helpers/addMachineToSlicerConfig';
import { extractUuid } from 'rapidfab/utils/uuidUtils';
import SlicerConfigurationForm from '../SlicerConfigurationForm';

const SlicerConfigurationContainer = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [slicedTestModel, setSlicedTestModel] = useState(null);
  const [sliceLocation, setSliceLocation] = useState(null);
  const [isFetching, setIsFetching] = useState(false);

  const sliceEvent = useSelector(state =>
    getStateEventStreamEvents(state, EVENT_STREAM_SELECTOR_RESOURCES.SLICER));

  const slicerUUID = useSelector(getRouteUUID);
  const slicerConfig = useSelector(state => getRouteUUIDResource(state));
  const printersByURI = useSelector(getPrintersByUri);
  const printerTypeByURI = useSelector(getPrinterTypesByUri);
  const submitting = useSelector(state =>
    state.ui.nautilus[API_RESOURCES.SLICER_CONFIGURATION_RAW].put.fetching);

  const { SLICER_CONFIGURATION_RAW, PRINTER, PRINTER_TYPE, SLICE_TESTING } = useFetchMultipleResources([
    {
      resource: API_RESOURCES.SLICER_CONFIGURATION_RAW,
      method: 'get',
      configuration: buildURIFromUUID(API_RESOURCES.SLICER_CONFIGURATION_RAW, slicerUUID),
    },
    { resource: API_RESOURCES.PRINTER },
    { resource: API_RESOURCES.PRINTER_TYPE },
    {
      resource: API_RESOURCES.SLICE_TESTING,
      method: 'list',
      configuration: [{ slicer_config: buildURIFromUUID(API_RESOURCES.SLICER_CONFIGURATION_RAW, slicerUUID) }],
      clearOnNewRequest: true,
    },
  ],
  );

  const { responseHeaders: slicerConfigHeader } = SLICER_CONFIGURATION_RAW;
  const { data: printers } = PRINTER;
  const { data: printerType } = PRINTER_TYPE;
  const { data: slicedModels } = SLICE_TESTING;

  const slicedModelslist = slicedModels?.map(model => model?.model);

  const loadSlicedModels = async slicedModelslist => {
    // We want to fetch the model-library items that are
    // related to slicing config here and them use the uris of
    // those models to fetch all the actual models to display in the listing tab

    // If there are no is no test models used well fetch all models in model library so we can
    // have theyre dimensions when creating that test

    const { json: listOfModelLibraryModels } = await dispatch(Actions.Api.nautilus[API_RESOURCES.MODEL_LIBRARY].list(!!slicedModelslist.length && { 'additive.model': slicedModelslist }));
    const modelLibrariesUris = listOfModelLibraryModels?.resources
      ?.map(model => model?.additive?.model)
      .filter(Boolean);
    await dispatch(Actions.Api.nautilus[API_RESOURCES.MODEL]
      .list(!!modelLibrariesUris.length && { uri: modelLibrariesUris }));
  };

  useEffect(() => {
    loadSlicedModels(slicedModelslist);
  }, [JSON.stringify(slicedModelslist)]);

  const addUploadLocationToSlicer = () => (
    { slicerConfigUploadLocation: slicerConfigHeader?.uploadLocation }
  );

  const machineFieldAddedToSlicerConfigsData =
    machineFieldAddedToSlicerConfig(
      slicerConfig,
      { printerTypeByURI, printersByURI },
      addUploadLocationToSlicer);

  const onFormSubmit = async payload => {
    const updateSlicerConfigDescriptionPayload = { description: payload.description };
    await dispatch(Actions.Api.nautilus[API_RESOURCES.SLICER_CONFIGURATION_RAW]
      .put(slicerConfig.uuid, updateSlicerConfigDescriptionPayload));
    alert.success(<FormattedMessage id="toaster.slicer.updated" defaultMessage="Slicer Configuration succesfully updated." />);
  };

  const DeleteSlicerConfig = async () => {
    await dispatch(Actions.Api.nautilus[API_RESOURCES.SLICER_CONFIGURATION_RAW]
      .delete(slicerConfig.uuid));
    alert.success(<FormattedMessage id="toaster.slicer.deleted" defaultMessage="Slicer Configuration succesfully deleted." />);
    navigate(getRouteURI(ROUTES.SLICER_CONFIGURATIONS, {}, {}, true));
  };

  const reloadSlicerTestResults = async sliceLocation => {
    const result = await dispatch(Actions.Api.nautilus[API_RESOURCES.SLICE_TESTING].get(extractUuid(sliceLocation)));
    setSlicedTestModel(result.json);
  };

  const initiateSlicingTest = async payload => {
    // This request starts the slicing of the model.
    setIsFetching(true);
    const slicerConfigPayload = {
      slicer_config_raw: buildURIFromUUID(API_RESOURCES.SLICER_CONFIGURATION_RAW, slicerUUID),
      ...payload,
    };
    alert.warning(<FormattedMessage id="toaster.slicing" defaultMessage="Slicing may take a few moments..." />);
    const sliceRecord = await dispatch(Actions.Api.nautilus[API_RESOURCES.SLICE_TESTING].post(slicerConfigPayload));
    const sliceLocation = sliceRecord.headers.location;
    setSliceLocation(sliceLocation);
    // This is the request for refetching the slice file of the test after the post request is made.
    await reloadSlicerTestResults(sliceLocation);
  };

  useEffect(() => {
    // This creates an another request when slice event comes back
    if (sliceEvent) {
      reloadSlicerTestResults(sliceLocation);
      setIsFetching(false);
      alert.success(<FormattedMessage id="toaster.slicer.test.completed" defaultMessage="Slicing Completed" />);
    }
  }, [JSON.stringify(sliceEvent)]);

  const selected = {
    printers,
    slicerConfig: machineFieldAddedToSlicerConfigsData,
    printerType,
    initiateSlicingTest,
    slicedTestModel,
    isFetching,
    setSlicedTestModel,
  };

  return (

    <SlicerConfigurationForm
      onFormSubmit={onFormSubmit}
      onDelete={DeleteSlicerConfig}
      submitting={submitting}
      {...selected}
    />
  );
};

export default SlicerConfigurationContainer;
