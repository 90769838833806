import { createSelector } from 'reselect';
import * as baseStateSelectors from 'rapidfab/selectors/baseStateSelectors';
import { getStateResources } from 'rapidfab/selectors/helpers/base';
import _keyBy from 'lodash/keyBy';

export const getDocuments = createSelector(
  [baseStateSelectors.getStateDocuments, getStateResources],
  (uuids, resources) => uuids.map(uuid => resources[uuid]),
);

export const getDocumentsByUri = createSelector(
  [getDocuments],
  documents => _keyBy(documents, 'uri'),
);
