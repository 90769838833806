import Actions from 'rapidfab/actions';
import { API_RESOURCES, LIST_BY_URIS_CHUNK_SIZE, PAGINATION_IGNORE_DEFAULT_LIMIT } from 'rapidfab/constants';
import _map from 'lodash/map';
import _chunk from 'lodash/chunk';

// eslint-disable-next-line import/prefer-default-export
export const loadDesignFilesWithModels = (dispatch, queryParams) =>
  dispatch(Actions.Api.nautilus[API_RESOURCES.DESIGN_FILE].list(
    queryParams,
    { limit: PAGINATION_IGNORE_DEFAULT_LIMIT },
  )).then(designFilesResponse => {
    const designFiles = designFilesResponse?.json?.resources;

    if (designFiles) {
      const designFileUris = _map(designFiles, 'uri');
      _chunk(designFileUris, LIST_BY_URIS_CHUNK_SIZE).forEach(designFileUrisChunk => {
        dispatch(
          Actions.Api.nautilus[API_RESOURCES.MODEL].list(
            { design_file: designFileUrisChunk },
            { limit: PAGINATION_IGNORE_DEFAULT_LIMIT },
          ),
        );
      });
    }
  });
