import React, { useEffect } from 'react';
import SubLocationsEditModal from 'rapidfab/components/records/SubLocationsEditModal';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import Actions from 'rapidfab/actions';
import _omit from 'lodash/omit';
import * as Selectors from 'rapidfab/selectors';
import { FEATURES, API_RESOURCES } from 'rapidfab/constants';
import { isFeatureEnabled } from 'rapidfab/selectors';
import Alert from 'rapidfab/utils/alert';
import _isEmpty from 'lodash/isEmpty';
import { extractUuid } from 'rapidfab/utils/uuidUtils';

const EditSubLocationModalContainer = props => {
  const { subLocation } = props;

  const dispatch = useDispatch();
  const listOfSensorResources = useSelector(Selectors.getSensorResources);
  const isSensorFeatureEnabled = useSelector(state => isFeatureEnabled(state, FEATURES.SENSORS));

  const subLocationHasSensorRelatedResource =
    (listOfSensorResources?.length === 1 && listOfSensorResources[0]?.sub_location === subLocation?.uri);

  const selected = {
    listOfSensorResources,
    subLocationHasSensorRelatedResource,
    isSensorFeatureEnabled,
  };
  const onInitializeSensorResourcesForSubLocations = async () => {
    dispatch(Actions.Api.nautilus[API_RESOURCES.SENSOR].clear());
    try {
      await dispatch(Actions.Api.nautilus[API_RESOURCES.SENSOR].list({ sub_location: subLocation?.uri }, {}, {}, {}))
        .then(({ json }) => {
          if (_isEmpty(json.resources)) {
            dispatch(Actions.Api.nautilus[API_RESOURCES.SENSOR].list());
          }
        });
    } catch (error) {
      Alert.error(error);
    }
  };

  const updateSensorInformationForSubLocationSubmit = async formValues => {
    const payload = { ...formValues };
    payload.frequency = Number(payload.frequency);

    // Will be adding put method request once BE makes the changes needed for updating a sublocation
    if (payload.serial_number) {
      if (subLocationHasSensorRelatedResource) {
        const sensorSameFrequency = (listOfSensorResources[0]?.frequency === payload.frequency);
        if (!sensorSameFrequency) {
          try {
            const cleanedPayloadForPutRequest = _omit(payload, ['name', 'description', 'state', 'serial_number']);
            const singleSensorResourceUri = extractUuid(listOfSensorResources[0]?.uri);

            await dispatch(Actions.Api.nautilus[API_RESOURCES.SENSOR].put(
              singleSensorResourceUri, cleanedPayloadForPutRequest),
            );
          } catch (error) {
            // eslint-disable-next-line no-console
            console.error(error);
            return;
          }
        }
      } else {
        try {
          payload.sub_location = subLocation?.uri;
          const cleanedPayloadForPostRequest = _omit(payload, ['description', 'name', 'state']);
          await dispatch(Actions.Api.nautilus[API_RESOURCES.SENSOR].post(cleanedPayloadForPostRequest));
        } catch (error) {
          // eslint-disable-next-line no-console
          console.error(error);
          return;
        }
      }
    }
    await props.onSubmit(payload);
  };

  useEffect(() => {
    if (isSensorFeatureEnabled) {
      onInitializeSensorResourcesForSubLocations();
    }
  }, [subLocation?.uri]);

  return (
    <SubLocationsEditModal
      {...props}
      {...selected}
      handleSubmitForm={updateSensorInformationForSubLocationSubmit}
    />
  );
};

EditSubLocationModalContainer.propTypes = {
  subLocation: PropTypes.shape({ uri: PropTypes.string }).isRequired,
  onSubmit: PropTypes.func.isRequired,
};

export default EditSubLocationModalContainer;
