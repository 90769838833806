import React from 'react';
import DanfossDDWSidebar from 'rapidfab/components/danfossDDW/DanfossDDWSidebar';
import { Col, Container, Row } from 'react-bootstrap';
import HawkingMyLibraryContainer from 'rapidfab/containers/hawking/HawkingMyLibraryContainer';
import Feature from 'rapidfab/components/Feature';
import { routeURIContains, routeURIArrayPartiallyContains } from 'rapidfab/utils/uriUtils';
import { ROUTES, FEATURES } from 'rapidfab/constants';
import PropTypes from 'prop-types';
import DanfossDDWCustomLibraryContainer from 'rapidfab/containers/danfossDDW/DanfossDDWCustomLibraryContainer';
import DDWBuyerShoppingCartContainer from 'rapidfab/containers/danfossDDW/DDWBuyerShoppingCartContainer';
import HawkingAdministratorLibraryContainer from 'rapidfab/containers/hawking/HawkingAdministratorLibraryContainer';
import HawkingCompanyLibraryContainer from 'rapidfab/containers/hawking/HawkingCompanyLibraryContainer';

const DanfossDDWLayout = ({
  children,
  restricted,
  groups,
  libraries,
  ...otherProps
}) => {
  const renderDDWLibraries = () => {
    const isDDWBuyerShoppingCartRoute = routeURIArrayPartiallyContains(
      ROUTES.DDW_BUYER_SHOPPING_CART.split('/').slice(1, -1),
    );

    if (routeURIContains(ROUTES.DDW_COMPANY_LIBRARY)) {
      return <HawkingCompanyLibraryContainer />;
    }

    if (routeURIContains(ROUTES.DDW_MY_LIBRARY)) {
      return <HawkingMyLibraryContainer />;
    }

    if (routeURIContains(ROUTES.DDW_ALL_DESIGNS)) {
      return <HawkingAdministratorLibraryContainer />;
    }

    if (isDDWBuyerShoppingCartRoute) {
      return <DDWBuyerShoppingCartContainer />;
    }

    // Render custom library if none of the static routes above match
    return <DanfossDDWCustomLibraryContainer />;
  };

  return (
    restricted ? (
    /* 👇🏼 This renders the entire DOM layout */
      <Container fluid>
        <Row>
          <Col xs={2}>
            <DanfossDDWSidebar
              isRestricted={restricted}
              libraries={libraries}
              groups={groups}
              {...otherProps}
            />
          </Col>
          <Col xs={10}>
            {children}
          </Col>
        </Row>
      </Container>
    )
      : (
    /* 👇🏼 This renders the embedded view (inside AM Flows) only */
        <Container fluid>
          <Row>
            <Feature featureName={FEATURES.STANLEY_X_DEPLOYMENT} isInverted>
              <Col xs={2}>
                <DanfossDDWSidebar
                  isRestricted={restricted}
                  groups={groups}
                  libraries={libraries}
                  {...otherProps}
                />
              </Col>
            </Feature>
            <Col xs={10}>
              {renderDDWLibraries()}
            </Col>
          </Row>
        </Container>
      )
  );
};

export default DanfossDDWLayout;

DanfossDDWLayout.defaultProps = {
  createLibraryModalShown: false,
  groups: [],
  handleCreateLibrary: () => {},
  libraries: [],
};

DanfossDDWLayout.propTypes = {
  children: PropTypes.node.isRequired,
  restricted: PropTypes.bool.isRequired,
  createLibraryModalShown: PropTypes.bool,
  groups: PropTypes.arrayOf(PropTypes.shape({})),
  handleCreateLibrary: PropTypes.func,
  libraries: PropTypes.arrayOf(PropTypes.shape({})),
};
