/*
 * This is the place where all types are stored.
 *
 * All api resources in resources.js
 *
 * Please use statuses from constants (where applicable).
 *
 * Tip: you can use https://transform.tools/json-to-proptypes
 */

export * from 'rapidfab/types/fields';
export * from 'rapidfab/types/resources';
