import PropTypes from 'prop-types';
import React from 'react';
import { useSelector } from 'react-redux';
import OrderPackingSlipPrint from '../../../components/records/order/OrderPackingSlipPrint';
import { FEATURES } from '../../../constants';
import * as Selectors from '../../../selectors';

const OrderPackingSlipPrintContainer = ({ orderUuid }) => {
  const bureauName = useSelector(Selectors.getBureauName);
  const isDanfossUser = useSelector(
    state => Selectors.isFeatureEnabled(state, FEATURES.ORDER_BUSINESS_SEGMENT),
  );
  const isPOCUKOrderFieldsFeatureEnabled = useSelector(
    state => Selectors.isFeatureEnabled(state, FEATURES.POC_UK_ORDER_FIELDS),
  );
  const order = useSelector(state => Selectors.getUUIDResource(state, orderUuid));
  const lineItems = useSelector(state => Selectors.getLineItemsForOrder(state, order));
  const shippingsByUri = useSelector(Selectors.getShippingsByUri);
  const materialsByUri = useSelector(Selectors.getMaterialsByUri);
  const modelsByUri = useSelector(Selectors.getModelsByUri);

  return (
    <OrderPackingSlipPrint
      bureauName={bureauName}
      order={order}
      lineItems={lineItems}
      shippingsByUri={shippingsByUri}
      materialsByUri={materialsByUri}
      modelsByUri={modelsByUri}
      isDanfossUser={isDanfossUser}
      showLineItemImagePreviews={!isPOCUKOrderFieldsFeatureEnabled}
    />
  );
};

OrderPackingSlipPrintContainer.propTypes = {
  orderUuid: PropTypes.string.isRequired,
};

export default React.memo(OrderPackingSlipPrintContainer);
