import classNames from 'classnames';
import { IMPERSONATION_USER_PROFILE_CONTEXT, ROUTES } from 'rapidfab/constants';
import { getRouteURI } from 'rapidfab/utils/uriUtils';
import React from 'react';
import _isEmpty from 'lodash/isEmpty';
import PropTypes from 'prop-types';
import Loading from 'rapidfab/components/Loading';
import { ImpersonateUserProfile } from 'rapidfab/components/navbar/ProfileAvatar';
import {
  faBoltLightning,
  faClose,
  faSearch,
  faUserSecret,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { FormattedMessage } from 'rapidfab/i18n';
import { Col, FormControl, Modal, Row } from 'react-bootstrap';
import { Link } from 'react-router-dom';

const ImpersonationModal = ({
  user,
  handleSearchUser,
  userAdditionalProps,
  onImpersonate,
  onImpersonateEnterPress,
  endImpersonation,
  fetchingProps,
  favoriteUsersProps,
  handleCloseModal,
  emailProps,
}) => {
  const {
    userEmail,
    setUserEmail,
    isUserToSearchAlreadySelected,
    userError,
    impersonatedUser,
    findImpersonatedUserEditedName,
    isAlreadyImpersonating,
    impersonatedUserUri,
  } = userAdditionalProps;

  const {
    isPageCurrentlyReloading,
    isImpersonatingLoading,
    usersFetching,
    isInitialLoading,
    impersonationLoadingStates,
  } = fetchingProps;

  const {
    favoriteUsers,
    favoriteUserNameToEdit,
    handleEditFavoriteUserName,
    handleSetFavoriteUserToEdit,
    setFavoriteUserNameValue,
    favoriteUserNameValue,
    isUserFavorite,
    handleFavoriteUser,
  } = favoriteUsersProps;

  const isFetching = usersFetching || isImpersonatingLoading || isPageCurrentlyReloading;
  const createUserClassNames = classNames({
    'd-flex w100 justify-content-start font-weight-200 font-size-12': true,
    'disabled-interaction': isFetching,
  });

  const searchUserClassNames = classNames({
    'custom-darken-modal-search-button': true,
    'disabled-interaction': isFetching,
  });

  return (
    <Modal
      size="lg"
      show
      onHide={handleCloseModal}
      scrollable
      backdrop="static"
      dialogClassName="custom-darken-modal"
    >
      <Modal.Header>
        <Modal.Title className="w-100 d-flex align-items-center justify-content-between">
          <div className="d-flex align-items-center">
            <FontAwesomeIcon
              icon={faUserSecret}
              className="custom-darken-modal--impersonate-button"
            />

            <p className="custom-darken-modal-title-text">
              { isAlreadyImpersonating ? (
                <FormattedMessage id="change_impersonation" defaultMessage="Change Impersonation" />
              ) : (
                <FormattedMessage id="impersonation" defaultMessage="Impersonation" />
              )}
            </p>
          </div>
          <FontAwesomeIcon
            icon={faClose}
            onClick={handleCloseModal}
            tabIndex={0}
            role="button"
            className="custom-darken-modal-button"
          />
        </Modal.Title>
      </Modal.Header>
      <Modal.Body className="custom-darken-modal--body-scroll custom-darken-modal-impersonation">
        {
          (isAlreadyImpersonating && isInitialLoading) ?
            <Loading /> : (
              <Row className="custom-darken-modal-user-impersonation mb50">
                <Col lg={6}>
                  <p className="mb10">
                    <FormattedMessage
                      id="impersonation.userInputHelperText"
                      defaultMessage="Please type the user name in the input below"
                    />
                  </p>

                  <div className={`d-flex align-items-center ${userError ? '' : 'mb15'}`}>
                    <FormControl
                      autoComplete="off"
                      name="email"
                      type="text"
                      placeholder="Please type the user email to impersonate"
                      value={userEmail}
                      className={userError ? 'custom-darken-modal-search-input-error' : ''}
                      onChange={({ target }) => setUserEmail(target.value)}
                      onBlur={() => {
                        if (!isUserToSearchAlreadySelected) {
                          handleSearchUser(userEmail);
                        }
                      }}
                      onKeyDown={event => {
                        if ((event.key === 'Enter' || event.keyCode === 13) && !_isEmpty(userEmail)) {
                          onImpersonateEnterPress(userEmail);
                        }
                      }}
                      disabled={isFetching}
                    />

                    <FontAwesomeIcon
                      icon={faSearch}
                      onClick={() => handleSearchUser(userEmail)}
                      tabIndex={0}
                      role="button"
                      className={searchUserClassNames}
                    />
                  </div>
                  {userError && <p className="text-danger mt-1 mb30">{userError}</p>}
                  {usersFetching && <Loading inline className="mb15" />}

                  {user && (
                    <div className="profile-avatar-impersonation-container mb15">
                      <ImpersonateUserProfile
                        userProps={{
                          userName: user.name,
                          email: user.username,
                          uri: user.uri,
                        }}
                        fetchingProps={{
                          isImpersonationFetching: isImpersonatingLoading,
                          impersonationLoadingStates,
                        }}
                        context={IMPERSONATION_USER_PROFILE_CONTEXT.SEARCH}
                        onImpersonate={onImpersonate}
                        handleFavoriteUser={handleFavoriteUser}
                        isUserFavorite={isUserFavorite}
                        emailProps={emailProps}
                      />
                    </div>
                  )}
                  <p className={createUserClassNames}>
                    <FormattedMessage id="impersonation.noRequiredUser" defaultMessage="Did not find the required user?" />&nbsp;
                    <Link
                      onClick={handleCloseModal}
                      to={getRouteURI(ROUTES.ADMIN_USERS, null, { isCreateNewUser: true }, true)}
                    >
                      <FormattedMessage id="createNewUser" defaultMessage="Create New User" />
                    </Link>
                  </p>
                </Col>

                {(isAlreadyImpersonating && impersonatedUser) && (
                  <Col lg={5}>
                    <h3 className="custom-darken-modal--currently-impersonating">
                      <FormattedMessage
                        id="impersonation.currentlyImpersonating"
                        defaultMessage="You are currently impersonating:"
                      />
                    </h3>
                    <ImpersonateUserProfile
                      userProps={{
                        userName: findImpersonatedUserEditedName(impersonatedUser),
                        email: impersonatedUser.username,
                        uri: impersonatedUser.uri,
                      }}
                      fetchingProps={{
                        isImpersonationFetching: isImpersonatingLoading,
                        impersonationLoadingStates,
                      }}
                      context={IMPERSONATION_USER_PROFILE_CONTEXT.IMPERSONATED_USER}
                      onImpersonate={onImpersonate}
                      impersonatedUserUri={impersonatedUserUri}
                      isAlreadyImpersonating={isAlreadyImpersonating}
                      endImpersonation={endImpersonation}
                      handleFavoriteUser={handleFavoriteUser}
                      isUserFavorite={isUserFavorite}
                      emailProps={emailProps}
                    />
                  </Col>
                )}

                <Row className="custom-darken-modal-impersonation-custom-gutter custom-darken-modal-user-impersonation mt50">
                  <Col lg={12}>
                    <div className="d-flex align-items-center custom-darken-modal--quick-impersonation-title w-100 mb30">
                      <FontAwesomeIcon
                        icon={faBoltLightning}
                        className="custom-darken-modal--impersonate-button"
                      />
                      <p className="custom-darken-modal-title-text">
                        <FormattedMessage
                          id="impersonation.quickImpersonation"
                          defaultMessage="Quick Impersonation:"
                        />
                      </p>
                    </div>
                    {favoriteUsers.length ? (
                      <div className="profile-avatar-favorite-users">
                        {
                          favoriteUsers.map(favoriteUser => (
                            <ImpersonateUserProfile
                              userProps={{
                                userName: favoriteUser.name,
                                email: favoriteUser.username,
                                uri: favoriteUser.uri,
                              }}
                              fetchingProps={{
                                isImpersonationFetching: isImpersonatingLoading,
                                impersonationLoadingStates,
                              }}
                              impersonatedUserUri={impersonatedUserUri}
                              key={favoriteUser.username}
                              onImpersonate={onImpersonate}
                              endImpersonation={endImpersonation}
                              handleFavoriteUser={handleFavoriteUser}
                              isUserFavorite={isUserFavorite}
                              isAlreadyImpersonating={isAlreadyImpersonating}
                              context={IMPERSONATION_USER_PROFILE_CONTEXT.FAVORITE_USERS}
                              favoriteUsersProps={{
                                favoriteUserNameToEdit,
                                handleEditFavoriteUserName,
                                handleSetFavoriteUserToEdit,
                                setFavoriteUserNameValue,
                                favoriteUserNameValue,
                              }}
                              emailProps={emailProps}
                            />
                          ))
                        }
                      </div>
                    ) : (
                      <p>
                        <FormattedMessage
                          id="impersonation.addFavoriteUserPrompt"
                          defaultMessage='To add the new favorite user, please click on the "Star" button on the User Profile.'
                        />
                      </p>
                    )}
                  </Col>
                </Row>
              </Row>
            )
        }
      </Modal.Body>
    </Modal>
  );
};

ImpersonationModal.defaultProps = {
  user: null,
};

ImpersonationModal.propTypes = {
  user: PropTypes.shape({
    name: PropTypes.string,
    username: PropTypes.string,
    uri: PropTypes.string,
  }),
  handleSearchUser: PropTypes.func.isRequired,
  userAdditionalProps: PropTypes.shape({
    userEmail: PropTypes.string,
    setUserEmail: PropTypes.func,
    isUserToSearchAlreadySelected: PropTypes.bool,
    userError: PropTypes.string,
    impersonatedUser: PropTypes.shape({
      name: PropTypes.string,
      username: PropTypes.string,
      uri: PropTypes.string,
    }),
    impersonatedUserUri: PropTypes.string,
    isAlreadyImpersonating: PropTypes.bool,
    findImpersonatedUserEditedName: PropTypes.func,
    hasImpersonationPermissions: PropTypes.bool,
  }).isRequired,
  onImpersonate: PropTypes.func.isRequired,
  onImpersonateEnterPress: PropTypes.func.isRequired,
  endImpersonation: PropTypes.func.isRequired,
  fetchingProps: PropTypes.shape({
    isPageCurrentlyReloading: PropTypes.bool,
    isImpersonatingLoading: PropTypes.bool,
    usersFetching: PropTypes.bool,
    isInitialLoading: PropTypes.bool,
    impersonationLoadingStates: PropTypes.shape({
      uri: PropTypes.bool,
    }),
  }).isRequired,
  favoriteUsersProps: PropTypes.shape({
    favoriteUsers: PropTypes.arrayOf(PropTypes.shape({
      username: PropTypes.string,
    })),
    isUserFavorite: PropTypes.func,
    handleFavoriteUser: PropTypes.func,
    favoriteUserNameToEdit: PropTypes.shape({
      username: PropTypes.string,
    }),
    handleEditFavoriteUserName: PropTypes.func,
    handleSetFavoriteUserToEdit: PropTypes.func,
    setFavoriteUserNameValue: PropTypes.func,
    favoriteUserNameValue: PropTypes.string,
  }).isRequired,
  handleCloseModal: PropTypes.func.isRequired,
  emailProps: PropTypes.shape({
    handleCopyEmail: PropTypes.func,
    emailsCopiedState: PropTypes.shape({}),
  }).isRequired,
};

export default ImpersonationModal;
