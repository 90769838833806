import React, { useEffect, useState } from 'react';
import { faClose } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button, Modal, Row, Table } from 'react-bootstrap';
import { Form } from 'react-final-form';
import 'rapidfab/styles/componentStyles/custom-darken-modal.scss';
import { useDispatch, useSelector } from 'react-redux';
import Actions from 'rapidfab/actions';
import { API_RESOURCES } from 'rapidfab/constants';
import PropTypes from 'prop-types';
import { getUnlinkedAddiguruAnalyses } from 'rapidfab/selectors/addiguru';
import { extractUuid } from 'rapidfab/utils/uuidUtils';
import { FormattedDateTime } from 'rapidfab/i18n';
import AddiguruAnalysisStatus from './AddiguruAnalysisStatus';

const LinkRunModal = ({ runUri, show, setShow }) => {
  const dispatch = useDispatch();
  const analyses = useSelector(getUnlinkedAddiguruAnalyses);
  const [selectedAnalysis, setSelectedAnalysis] = useState(null);

  // Fetch analyses with no runs
  useEffect(() => {
    dispatch(Actions.Api.nautilus[API_RESOURCES.ADDIGURU_ANALYSIS].list({
      run: null,
    }));
    setSelectedAnalysis(null);
  }, [show]);

  const onSubmit = async () => {
    if (!selectedAnalysis) return;
    const uuid = extractUuid(selectedAnalysis);
    await dispatch(Actions.Api.nautilus[API_RESOURCES.ADDIGURU_ANALYSIS].put(uuid, {
      run: runUri,
    }));
    await dispatch(Actions.Api.nautilus[API_RESOURCES.ADDIGURU_ANALYSIS].list());
    setShow(false);
  };

  return (
    <Modal
      bg="dark"
      show={show}
      scrollable
      backdrop="static"
      dialogClassName="custom-darken-modal"
    >
      <Form
        onSubmit={onSubmit}
        initialValues={{}}
        render={({ handleSubmit }) => (
          <form onSubmit={handleSubmit}>
            <Modal.Header>
              <Modal.Title className="w-100 d-flex align-items-center justify-content-between">
                <div className="d-flex align-items-center">
                  Link Addiguru Analysis to Run
                </div>
                <FontAwesomeIcon
                  icon={faClose}
                  onClick={() => setShow(false)}
                  tabIndex={0}
                  role="button"
                  className="custom-darken-modal-button"
                />
              </Modal.Title>
            </Modal.Header>
            <Modal.Body className="custom-darken-modal--body-scroll">
              <Row className="custom-darken-modal-custom-gutter">
                <Table bordered size="sm" className="m-b-0">
                  <thead>
                    <tr>
                      <th>
                        <strong>
                          Id
                        </strong>
                      </th>
                      <th>
                        <strong>
                          Status
                        </strong>
                      </th>
                      <th>
                        <strong>
                          Start Time
                        </strong>
                      </th>
                      <th>
                        <strong>
                          End Time
                        </strong>
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {analyses.map(analysis => (
                      <tr
                        style={{
                          cursor: 'pointer',
                          background: selectedAnalysis === analysis.uri && '#434857',
                        }}
                        // eslint-disable-next-line unicorn/prevent-abbreviations
                        onClick={() => setSelectedAnalysis(prev => (prev === analysis.uri ? null : analysis.uri))}
                      >
                        <td>
                          {analysis.addiguru_id}
                        </td>
                        <td>
                          <AddiguruAnalysisStatus status={analysis.status} />
                        </td>
                        <td>
                          <FormattedDateTime value={analysis.start_time} />
                        </td>
                        <td>
                          <FormattedDateTime value={analysis.end_time} />
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </Table>
              </Row>
            </Modal.Body>
            <Modal.Footer>
              <Button type="submit" variant="success" disabled={!selectedAnalysis}>
                Link to Analysis
              </Button>
            </Modal.Footer>
          </form>
        )}
      />
    </Modal>
  );
};

LinkRunModal.propTypes = {
  runUri: PropTypes.string.isRequired,
  show: PropTypes.bool.isRequired,
  setShow: PropTypes.func.isRequired,
};

export default LinkRunModal;
