import { createSelector } from 'reselect';
import * as baseStateSelectors from 'rapidfab/selectors/baseStateSelectors';

// eslint-disable-next-line import/prefer-default-export
export const getLocationFilter = createSelector(
  [baseStateSelectors.getStateLocationFilter],
  location => location,
);

export const getSubLocationFilter = createSelector(
  [baseStateSelectors.getStateSubLocationFilter],
  subLocation => subLocation,
);
