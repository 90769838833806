import React from 'react';
import { useSelector } from 'react-redux';
import { getAddiguruAnalysisLinkedToRun, getUnaddressedAnomaliesByAnalysis, getUnaddressedDefectsByAnalysis } from 'rapidfab/selectors/addiguru';
import { Badge } from 'react-bootstrap';
import PropTypes from 'prop-types';

const AddiguruAnomalyCountPill = ({ runUri }) => {
  const analysis = useSelector(state => getAddiguruAnalysisLinkedToRun(state, runUri));
  const defects = useSelector(state => getUnaddressedDefectsByAnalysis(state, analysis?.uri));
  const anomalies = useSelector(state => getUnaddressedAnomaliesByAnalysis(state, analysis?.uri));

  if (!analysis) return null;

  if (defects.length) return <Badge className="spacer-right" bg="danger">{defects.length} Defects</Badge>;
  if (anomalies.length) return <Badge className="spacer-right" bg="warning">{anomalies.length} Anomalies</Badge>;

  return null;
};

AddiguruAnomalyCountPill.propTypes = {
  runUri: PropTypes.string.isRequired,
};

export default AddiguruAnomalyCountPill;
