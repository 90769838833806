import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import * as Selectors from 'rapidfab/selectors';
import Actions from 'rapidfab/actions';
import { API_RESOURCES, PAGINATION_IGNORE_DEFAULT_LIMIT, SERVICE_PROVIDER_JOB_END_STATUSES } from 'rapidfab/constants';
import { getEndpointFromURI } from 'rapidfab/utils/uriUtils';
import Job from 'rapidfab/components/service_providers/Job';
import Loading from 'rapidfab/components/Loading';

const JobListContainer = () => {
  const uuid = useSelector(Selectors.getRouteUUID);
  const bureau = useSelector(Selectors.getBureau);
  const job = useSelector(Selectors.getRouteUUIDResource);
  const order =
    useSelector(state => (job ? Selectors.getUUIDResource(state, getEndpointFromURI(job.order).uuid) : null));
  const lineItem =
    useSelector(state => (job ? Selectors.getUUIDResource(state, getEndpointFromURI(job.line_item).uuid) : null));
  const serviceProvider = useSelector(state => Selectors.getServiceProviderForJob(state, job));
  const fetching = useSelector(state => state.ui.nautilus[API_RESOURCES.SERVICE_PROVIDER].list.fetching ||
    state.ui.nautilus[API_RESOURCES.SERVICE_PROVIDER_JOB].get.fetching ||
    state.ui.nautilus[API_RESOURCES.SERVICE_PROVIDER_V1_ORDER].get.fetching ||
    state.ui.nautilus[API_RESOURCES.SERVICE_PROVIDER_V1_LINE_ITEM].list.fetching);

  const dispatch = useDispatch();

  const onInitialize = routeUUID => {
    dispatch(Actions.Api.nautilus[API_RESOURCES.SERVICE_PROVIDER]
      .list({}, { limit: PAGINATION_IGNORE_DEFAULT_LIMIT }));
    dispatch(Actions.Api.nautilus[API_RESOURCES.SERVICE_PROVIDER_JOB].get(routeUUID)).then(response => {
      const orderEndpoint = getEndpointFromURI(response.json.order);
      const lineItemEndpoint = getEndpointFromURI(response.json.line_item);

      if (!SERVICE_PROVIDER_JOB_END_STATUSES.includes(response.json.status)) {
        // If this is non-completed/declined order,
        // we need to fetch also related order and line item
        dispatch(Actions.Api.nautilus[API_RESOURCES.SERVICE_PROVIDER_V1_ORDER].get(orderEndpoint.uuid));
        dispatch(Actions.Api.nautilus[API_RESOURCES.SERVICE_PROVIDER_V1_LINE_ITEM].get(lineItemEndpoint.uuid));
      }
    });
  };

  const onModelDownload = (name, content) => dispatch(
    Actions.DownloadModel.downloadContent(
      name,
      content,
    ),
  );

  useEffect(() => onInitialize(uuid), []);

  if (fetching || !job) {
    return (<Loading />);
  }

  return (
    <Job
      bureau={bureau}
      order={order}
      job={job}
      serviceProvider={serviceProvider}
      lineItem={lineItem}
      onModelDownload={onModelDownload}
    />
  );
};

export default JobListContainer;
