import { faExclamationTriangle, faWarning } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import { OverlayTrigger, Tooltip, Badge } from 'react-bootstrap';
import PropTypes from 'prop-types';
import { EXPORT_CONTROL_LINE_ITEM_WARNINGS, EXPORT_CONTROL_PIECE_WARNINGS } from 'rapidfab/constants';

export const ExportControlGeneralWarning = ({ children, badgeClassName }) => (
  <OverlayTrigger
    placement="bottom"
    overlay={(
      <Tooltip>
        {children}
      </Tooltip>
    )}
  >
    <Badge bg="warning" className={`badge-lg ${badgeClassName}`}>
      <FontAwesomeIcon className="spacer-right" icon={faWarning} />
      <span>Warning</span>
    </Badge>
  </OverlayTrigger>
);

export const ExportControlWarningIcon = ({ exportControlLabel, isLineItem = false }) => (
  <OverlayTrigger
    placement="top"
    overlay={(
      <Tooltip>
        <b>{exportControlLabel?.name}</b>
        <p>{isLineItem ? EXPORT_CONTROL_LINE_ITEM_WARNINGS[exportControlLabel?.name]
          : EXPORT_CONTROL_PIECE_WARNINGS[exportControlLabel?.name]}
        </p>
      </Tooltip>
    )}
  >
    <FontAwesomeIcon
      icon={faExclamationTriangle}
      color={exportControlLabel?.color}
      className="spacer-left spacer-right"
    />
  </OverlayTrigger>
);

ExportControlWarningIcon.propTypes = {
  exportControlLabel: PropTypes.shape({
    name: PropTypes.string,
    description: PropTypes.string,
    color: PropTypes.string,
  }).isRequired,
  isLineItem: PropTypes.bool.isRequired,
};

ExportControlGeneralWarning.propTypes = {
  children: PropTypes.node.isRequired,
  badgeClassName: PropTypes.string.isRequired,
};
