import Constants from 'rapidfab/constants';

export function storeFilters(filters) {
  return {
    type: Constants.STORE_TRACEABILITY_REPORT_FILTERS,
    filters,
  };
}

export function setFilters(filters) {
  return dispatch => {
    dispatch(storeFilters(filters));
  };
}
