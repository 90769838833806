import React, { useCallback, useState } from 'react';
import ManageShoppingCartsSidebarView from 'rapidfab/components/ShoppingCart/ManageShoppingCartsSidebarView';
import { useSelector } from 'react-redux';
import * as Selectors from 'rapidfab/selectors';
import Feature from 'rapidfab/components/Feature';
import { FEATURES, DDW_PAYMENT_STATUSES } from 'rapidfab/constants';

const ManageShoppingCartsSidebarViewContainer = () => {
  const [filterQuery, setFilterQuery] = useState({
    payment_status: 'ready',
  });

  const shoppingCarts = useSelector(state =>
    Selectors.getShoppingCartsFilterableByQuery(state, filterQuery))
    .filter(shoppingCartItem => shoppingCartItem.status !== 'abandoned');

  const handleFilterShoppingCarts = useCallback(event => {
    const { id, value } = event.target;

    if (id === 'shoppingCartFilters.status') {
      if (value === 'all') {
        setFilterQuery(null);
      } else if (value === DDW_PAYMENT_STATUSES.READY) {
        setFilterQuery({
          ...filterQuery,
          payment_status: 'ready',
        });
      } else if (value === DDW_PAYMENT_STATUSES.COMPLETE) {
        setFilterQuery({
          ...filterQuery,
          payment_status: null,
        });
      } else {
        /* ... */
      }
    }
  }, []);

  return (
    <Feature featureNames={[
      FEATURES.DIGITAL_DESIGN_WAREHOUSE,
      FEATURES.ROBOZE_DDW,
    ]}
    >
      <ManageShoppingCartsSidebarView
        shoppingCarts={shoppingCarts}
        handleFilterShoppingCarts={handleFilterShoppingCarts}
      />
    </Feature>
  );
};

export default ManageShoppingCartsSidebarViewContainer;
