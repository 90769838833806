import { createSelector } from 'reselect';
import _find from 'lodash/find';
import { getServiceProviders } from 'rapidfab/selectors/serviceProvider';
import { getPredicate } from 'rapidfab/selectors/helpers/base';

// eslint-disable-next-line import/prefer-default-export
export const getServiceProviderForJob = createSelector(
  [getPredicate, getServiceProviders],
  (job, serviceProviders) => {
    if (!job) {
      return null;
    }
    return (
      // On Service Provider role side
      _find(serviceProviders, { source_service_provider: job.service_provider })
      // On Bureau side
      || _find(serviceProviders, { uri: job.service_provider })
    );
  },
);
