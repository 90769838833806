import React from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button, Badge, OverlayTrigger, Tooltip } from 'react-bootstrap';
import { FormattedMessage } from 'rapidfab/i18n';
import { LINE_ITEM_STATUS, MODEL_STATUSES } from 'rapidfab/constants';
import isModelPrintable from 'rapidfab/utils/isModelPrintable';
import Feature from 'rapidfab/components/Feature';
import { faExclamationCircle, faMedkit, faQuestionCircle } from '@fortawesome/free-solid-svg-icons';
import { isFeatureEnabled } from 'rapidfab/selectors';
import { FEATURES } from '../../../../constants/features';

const ModelMeshQuality = ({
  model,
  noModelUpload,
  onMeshHealRepair,
  lineItem,
}) => {
  const isPrintable = isModelPrintable(model);
  const isDigitalDesignWarehouseFeatureEnabled = useSelector(
    state => isFeatureEnabled(state, FEATURES.DIGITAL_DESIGN_WAREHOUSE),
  );
  const isRobozeBureauOrderFieldsFeatureEnabled = useSelector(state => isFeatureEnabled(
    state,
    FEATURES.ROBOZE_GIGAFACTORY_BUREAU_ORDER_FIELDS,
  ));
  const isIntegrationCastorFeatureEnabled = useSelector(state => isFeatureEnabled(state, FEATURES.INTEGRATION_CASTOR));

  if (model && isPrintable) {
    return (
      <Badge bg="success" className="badge-sm">
        <FormattedMessage id="field.printable" defaultMessage="Printable" />
      </Badge>
    );
  }

  if (noModelUpload) {
    return (
      <Badge bg="warning" className="badge-sm">
        <FormattedMessage id="field.unknown" defaultMessage="Unknown" />
      </Badge>
    );
  }

  if (model && !isPrintable) {
    const isModelIrreparable = model.status === MODEL_STATUSES.IRREPARABLE;

    const isMeshHealDisabled = isModelIrreparable
      || (lineItem.status === LINE_ITEM_STATUS.ERROR) || (lineItem.status === LINE_ITEM_STATUS.NEW)
      || (lineItem.status === LINE_ITEM_STATUS.CALCULATING_ESTIMATES);
    const isLineItemErrorTooltipVisible = lineItem.status === LINE_ITEM_STATUS.ERROR;
    const isLineItemInfoTooltipVisible = lineItem.status === LINE_ITEM_STATUS.NEW
      || lineItem.status === LINE_ITEM_STATUS.CALCULATING_ESTIMATES;

    return (
      <>
        <Badge bg="danger" className="spacer-right badge-sm">
          {
            isDigitalDesignWarehouseFeatureEnabled &&
            isIntegrationCastorFeatureEnabled &&
            isRobozeBureauOrderFieldsFeatureEnabled ?
              (
                <FormattedMessage id="field.reviewRequired" defaultMessage="Review required" />
              ) : (
                <FormattedMessage id="field.notPrintable" defaultMessage="Unprintable" />
              )
          }
        </Badge>
        <Feature featureName={FEATURES.MESH_HEALING}>
          <Button variant="default" size="xs" className="spacer-right" disabled={isMeshHealDisabled} onClick={() => onMeshHealRepair(model)}>
            <FontAwesomeIcon icon={faMedkit} className="spacer-right" />
            <b>
              <FormattedMessage id="field.healMesh" defaultMessage="Heal Mesh" />
            </b>
          </Button>
        </Feature>
        {
          isLineItemErrorTooltipVisible && (
            <OverlayTrigger
              placement="bottom"
              overlay={
                <Tooltip>Mesh healing is impossible when line item status is Error</Tooltip>
              }
            >
              <FontAwesomeIcon icon={faQuestionCircle} />
            </OverlayTrigger>
          )
        }
        {
          isLineItemInfoTooltipVisible && (
            <OverlayTrigger
              placement="bottom"
              overlay={(
                <Tooltip>Mesh healing cannot be performed while line item status is New or Calculating</Tooltip>
              )}
            >
              <FontAwesomeIcon icon={faQuestionCircle} />
            </OverlayTrigger>
          )
        }
        {
          isModelIrreparable && (
            <OverlayTrigger
              placement="bottom"
              overlay={(
                <Tooltip>
                  Model Healing failed. Cannot heal model. To try again, replace with a new model.
                </Tooltip>
              )}
            >
              <FontAwesomeIcon icon={faExclamationCircle} />
            </OverlayTrigger>
          )
        }
      </>
    );
  }

  return (
    <Badge bg="info" className="badge-sm">
      <FormattedMessage id="field.loading" defaultMessage="Loading" />
    </Badge>
  );
};

ModelMeshQuality.defaultProps = {
  model: null,
  noModelUpload: false,
};

ModelMeshQuality.propTypes = {
  model: PropTypes.shape({
    status: PropTypes.string,
  }),
  lineItem: PropTypes.shape({
    status: PropTypes.string,
  }).isRequired,
  noModelUpload: PropTypes.bool,
  onMeshHealRepair: PropTypes.func.isRequired,
};

export default ModelMeshQuality;
