import _groupBy from 'lodash/groupBy';
import MaterialTypeModalContainer from 'rapidfab/containers/modals/MaterialTypeModalContainer';
import PrinterTypeModalContainer from 'rapidfab/containers/modals/PrinterTypeModalContainer';
import usePagination from 'rapidfab/hooks/usePagination';
import React, { useEffect, useMemo, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import Actions from 'rapidfab/actions';
import { API_RESOURCES, FEATURES, VIEW_MODE_OPTIONS } from 'rapidfab/constants';
import isFetchingInitial from 'rapidfab/utils/isFetchingInitial';
import * as Selectors from 'rapidfab/selectors';

import MaterialsComponent from 'rapidfab/components/inventory/materials';
import { useSearchParams } from 'react-router-dom';

const MaterialsContainer = props => {
  const [searchParams] = useSearchParams();
  const uuid = searchParams.get('uuid');
  const bureau = useSelector(Selectors.getBureauUri);
  const fetching = useSelector(state =>
    isFetchingInitial(
      state.ui.nautilus[API_RESOURCES.MANUFACTURER].list,
      state.ui.nautilus[API_RESOURCES.MATERIAL].list,
    ));
  const printerTypesFetching = useSelector(state =>
    isFetchingInitial(
      state.ui.nautilus[API_RESOURCES.PRINTER_TYPE].list,
    ));
  const materials = useSelector(Selectors.getSortedMaterialsByName);
  const printerTypes = useSelector(Selectors.getPrinterTypes);

  const manufacturers = useSelector(Selectors.getManufacturers);
  const manufacturersByUri = useSelector(Selectors.getManufacturersByUri);
  const externalMaterialDatabaseFeatureEnabled = useSelector(state =>
    Selectors.isFeatureEnabled(state, FEATURES.EXTERNAL_MATERIAL_DB));

  const [isCreateMaterialTypeModalShown, setIsCreateMaterialTypeModalShown] = useState(false);
  const [viewMode, setViewMode] = useState(VIEW_MODE_OPTIONS.CARDS);
  const [printerTypeModalVisible, setPrinterTypeModalVisible] = useState(false);

  const [globalFilter, setGlobalFilter] = useState('');
  const [printerTypeUUID, setPrinterTypeUUID] = useState(null);

  const handleShowPrinterTypeModal = printerTypeUUID => {
    setPrinterTypeModalVisible(true);
    setPrinterTypeUUID(printerTypeUUID);
  };

  const {
    paginationState,
    setTotalItems,
    setPage,
    nextPage,
    prevPage,
    totalPaginatedPages,
  } = usePagination(25);

  const isCardsView = viewMode === VIEW_MODE_OPTIONS.CARDS;

  const dispatch = useDispatch();

  const onInitialize = () => {
    const apiParams = [
      {}, // page
      {}, // searchParams
      {}, // queryParams
      true, // forced
    ];

    dispatch(Actions.Api.nautilus[API_RESOURCES.MANUFACTURER].list({}, ...apiParams));
    dispatch(Actions.Api.nautilus[API_RESOURCES.MATERIAL].list({ bureau }, ...apiParams));
    dispatch(Actions.Api.nautilus[API_RESOURCES.PRINTER_TYPE].list());
  };

  useEffect(() => {
    onInitialize();
  }, [bureau]);

  const filteredMaterials = useMemo(() => {
    if (!globalFilter?.trim()) return materials;

    const filterText = globalFilter.toLowerCase();

    return materials.filter(({ uuid, name, type }) =>
      [uuid, name, type].some(field =>
        field?.toLowerCase()?.includes(filterText),
      ),
    );
  }, [materials, globalFilter]);

  // Update total items and reset page when filters change
  useEffect(() => {
    setTotalItems(filteredMaterials.length);
    // Only reset the page if the current page is out of bounds after filtering
    if (paginationState.offset >= filteredMaterials.length) {
      setPage(0);
    }
  }, [filteredMaterials]);

  // Paginate materials using FE
  const paginatedMaterials = useMemo(() => {
    const { offset, pageLimit } = paginationState;
    return filteredMaterials.slice(offset, offset + pageLimit);
  }, [filteredMaterials, paginationState]);

  // Group materials by type for cards view
  const groupedFilteredMaterials = isCardsView
    ? _groupBy(paginatedMaterials, 'type')
    : {};

  return (
    <>
      <MaterialsComponent
        {...props}
        viewModeProps={{
          viewMode,
          setViewMode,
        }}
        filters={{
          globalFilter,
          setGlobalFilter,
        }}
        pagination={{
          ...paginationState,
          nextPage,
          prevPage,
          setPage,
          totalPaginatedPages,
        }}
        printerTypesFetching={printerTypesFetching}
        printerTypes={printerTypes}
        handleShowPrinterTypeModal={handleShowPrinterTypeModal}
        manufacturersByUri={manufacturersByUri}
        isCardsView={isCardsView}
        fetching={fetching}
        materials={paginatedMaterials}
        groupedMaterials={groupedFilteredMaterials}
        manufacturers={manufacturers}
        externalMaterialDbFeatureEnabled={externalMaterialDatabaseFeatureEnabled}
        setIsCreateMaterialTypeModalShown={setIsCreateMaterialTypeModalShown}
      />
      {(isCreateMaterialTypeModalShown || uuid) && (
        <MaterialTypeModalContainer
          isVisible={isCreateMaterialTypeModalShown}
          hideModal={() => setIsCreateMaterialTypeModalShown(false)}
        />
      )}
      {printerTypeModalVisible && printerTypeUUID && (
        <PrinterTypeModalContainer
          isVisible={printerTypeModalVisible}
          externalUUID={printerTypeUUID}
          hideModal={() => setPrinterTypeModalVisible(false)}
        />
      )}
    </>

  );
};

export default MaterialsContainer;
