import { createStore, applyMiddleware, compose } from 'redux';
import thunkMiddleware from 'redux-thunk';
// import { createLogger } from 'redux-logger';
import apiMiddleware from 'rapidfab/middleware/api';
import Reducer from 'rapidfab/reducers';
import throttle from 'lodash/throttle';

import { loadState, saveState } from '../utils/localStorage';
// TODO: Logger is off for now
// const loggerMiddleware = createLogger({
//   collapsed: true,
// });

/* eslint-disable no-underscore-dangle */
const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
/* eslint-enable no-underscore-dangle */

export default function initializeStore() {
  const persistedState = loadState();
  const store = createStore(
    Reducer,
    persistedState,
    composeEnhancers(applyMiddleware(thunkMiddleware, apiMiddleware)),
  );

  store.subscribe(
    throttle(() => {
      saveState(store.getState());
    }, 1000),
  );

  if (module.hot) {
    // Enable Webpack hot module replacement for reducers
    module.hot.accept('rapidfab/reducers', () => {
      // eslint-disable-next-line global-require
      const nextRootReducer = require('rapidfab/reducers');
      store.replaceReducer(nextRootReducer);
    });
  }

  return store;
}
