import React from 'react';
import PropTypes from 'prop-types';
import MaskedInput from 'react-text-mask';

import { Button, FormLabel, FormGroup, Modal, Tooltip, OverlayTrigger } from 'react-bootstrap';
import { FormattedMessage } from 'rapidfab/i18n';
import Loading from 'rapidfab/components/Loading';
import SaveButtonTitle from 'rapidfab/components/SaveButtonTitle';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircleInfo } from '@fortawesome/free-solid-svg-icons';

const Layout = ({ Table }) => <Table />;
Layout.propTypes = { Table: PropTypes.func.isRequired };

const EditEstimationTimeModal = ({
  show,
  extraFields,
  submit,
  printTime,
  onChange,
  loading,
  onHide,
  modelName,
}) => {
  const printTimeParts = printTime.split(':');
  // Define mask manually based on hour length due to bug in text-mark library
  // See https://github.com/text-mask/text-mask/issues/1017 and ch12797
  const hourLength = Math.max(printTimeParts[0].length, 2);
  const hourMask = Array.from({ length: hourLength }).fill(/\d/);

  const printTimeMask = [...hourMask, ':', /\d/, /\d/, ':', /\d/, /\d/];

  return (
    <Modal show={show} onHide={onHide} backdrop="static">
      <form onSubmit={submit}>
        <Modal.Header closeButton>
          <FormattedMessage
            id="EditLineItemEstimations"
            defaultMessage="Edit Total Line Item Estimations"
          />
        </Modal.Header>
        <Modal.Body>
          <FormGroup className="form-group">
            <FormLabel htmlFor="estimatesPrintTime">
              <FormattedMessage
                id="overrideEstimationsPrintTime"
                defaultMessage="Override the estimated {model} time"
                values={{ model: modelName }}
              />
              :
              <OverlayTrigger
                placement="right"
                overlay={(
                  <Tooltip>
                    This will override the estimated total {modelName} time for all pieces in this line item
                  </Tooltip>
                )}
              >
                <FontAwesomeIcon className="spacer-left spacer-right" icon={faCircleInfo} />
              </OverlayTrigger>

            </FormLabel>
            <MaskedInput
              id="estimatesPrintTime"
              name="estimatesPrintTime"
              className="form-control"
              mask={printTimeMask}
              placeholder="hh:mm:ss"
              value={printTime}
              onChange={onChange}
            />
          </FormGroup>
          {extraFields}
        </Modal.Body>
        <Modal.Footer>
          <Button onClick={onHide}>
            <FormattedMessage id="button.cancel" defaultMessage="Cancel" />
          </Button>
          <Button variant="success" type="submit" disabled={loading}>
            {loading ? <Loading /> : <SaveButtonTitle />}
          </Button>
        </Modal.Footer>
      </form>
    </Modal>
  );
};

EditEstimationTimeModal.defaultProps = {
  loading: false,
  show: false,
  extraFields: [],
  modelName: 'print',
  submit: () => true,
  onHide: () => true,
};

EditEstimationTimeModal.propTypes = {
  show: PropTypes.bool,
  extraFields: PropTypes.node,
  printTime: PropTypes.string.isRequired,
  onHide: PropTypes.func,
  submit: PropTypes.func,
  onChange: PropTypes.func.isRequired,
  loading: PropTypes.bool,
  modelName: PropTypes.string,
};

export default EditEstimationTimeModal;
