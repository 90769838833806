import React from 'react';
import { useSelector } from 'react-redux';

import * as Selectors from 'rapidfab/selectors';

import ModelLibrariesByFiltersContainer from 'rapidfab/containers/ModelLibrariesByFiltersContainer';

const HawkingAdministratorLibraryContainer = props => {
  const modelLibraries = useSelector(Selectors.getModelLibraries);

  return (
    <ModelLibrariesByFiltersContainer
      {...props}
      modelLibraries={modelLibraries}
    />
  );
};

export default HawkingAdministratorLibraryContainer;
