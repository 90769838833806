import _uniq from 'lodash/uniq';

// finds emails mentioned with a preceding "@" within a string and returns the
// emails in an array

export default function findEmails(string) {
  // eslint-disable-next-line max-len
  const emailRegex = /(@+[\d!#$%&'*+/=?^_`a-z{|}~-]+(?:\.[\d!#$%&'*+/=?^_`a-z{|}~-]+)*@(?:[\da-z](?:[\da-z-]*[\da-z])?\.)+[\da-z](?:[\da-z-]*[\da-z])?)/gm;
  const mentionedEmailsWithAtSymbol = string.match(emailRegex) || [];
  return _uniq(mentionedEmailsWithAtSymbol.map(emailWithAtSymbol => emailWithAtSymbol.slice(1)));
}
