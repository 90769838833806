import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import PropTypes from 'prop-types';
import MenuArrow from 'rapidfab/icons/arrow-sub-menu';
import { getRouteURI } from 'rapidfab/utils/uriUtils';
import React, { useRef, useState, Children, cloneElement } from 'react';
import { Nav, NavDropdown } from 'react-bootstrap';
import _isObject from 'lodash/isObject';

const NavSubMenuItem = ({ eventKey, route, icon, title, customIcon, children }) => {
  const [showSubMenu, setShowSubMenu] = useState(false);
  const subMenuTriggerRef = useRef(null);

  const isActive = childRoute => window.location.pathname.includes(childRoute) ||
    window.location.hash.includes(childRoute);

  const clonedChildren = Children.map(children, child => {
    // If the child is not the object - nothing to destructure
    if (!_isObject(child)) {
      return null;
    }
    // Destructure `featureName` and `children` from `child.props`
    const { featureName, children: childPropsChildren, href } = child.props;

    if (featureName) {
      // If the child has a `featureName` prop, clone its children with updated className
      const featureChildren = Children.map(childPropsChildren, featureChild => {
        // Use optional chaining to safely access `featureChild.props.href`
        const className = isActive(featureChild.props?.href) ? 'active' : '';
        return cloneElement(featureChild, { className });
      });

      // Return the cloned Feature component with the new children
      return cloneElement(child, {}, featureChildren);
    }

    // Directly clone the NavDropdown.Item with updated className
    const className = isActive(href) ? 'active' : '';
    return cloneElement(child, { className });
  });

  const handleMouseEnter = () => {
    setShowSubMenu(true);
  };

  const handleMouseLeave = () => {
    setShowSubMenu(false);
  };

  return (
    <Nav.Item className="d-flex align-items-center">
      <NavDropdown.Item eventKey={eventKey} href={getRouteURI(route)}>
        {
          customIcon || <FontAwesomeIcon icon={icon} fixedWidth />
        }{' '}
        {title}
      </NavDropdown.Item>
      <div
        ref={subMenuTriggerRef}
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
        className="nav-sub-menu-trigger"
      >
        <MenuArrow
          onMouseEnter={handleMouseEnter}
          onMouseLeave={handleMouseLeave}
          className="nav-sub-menu-arrow"
        />
        {showSubMenu && (
          <div className="nav-sub-menu-item" onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave}>
            <Nav className="flex-column">
              {clonedChildren}
            </Nav>
          </div>
        )}
      </div>
    </Nav.Item>
  );
};

NavSubMenuItem.defaultProps = {
  customIcon: null,
};

NavSubMenuItem.propTypes = {
  children: PropTypes.node.isRequired,
  eventKey: PropTypes.number.isRequired,
  route: PropTypes.string.isRequired,
  icon: PropTypes.shape({}).isRequired,
  title: PropTypes.node.isRequired,
  customIcon: PropTypes.node,
};

export default NavSubMenuItem;
