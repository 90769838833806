import React from 'react';
import { ROUTES } from 'rapidfab/constants';
import PropTypes from 'prop-types';
import { Card, Col, ListGroup, ListGroupItem, Row } from 'react-bootstrap';
import { FormattedMessage } from 'rapidfab/i18n';
import { extractUuid } from 'rapidfab/utils/uuidUtils';
import { getRouteURI } from 'rapidfab/utils/uriUtils';
import { Link } from 'react-router-dom';
import Loading from 'rapidfab/components/Loading';
import useToolLinks from './hook';

/**
 * @component
 * @param {Object} props
 * @param {string} props.uri
 */
const RunToolLinks = ({ uri }) => {
  const { data, isFetching } = useToolLinks(uri);

  return (
    <Card bg="dark" className="mt-2 mb-2">
      <Card.Header className="pd-exp accent">
        <FormattedMessage
          id="inventory.toolLinks"
          defaultMessage="Linked Tools"
        />
      </Card.Header>
      <div className="card-body-wrapper-accent">
        <ListGroup className="panel--scrollable" fill>
          <ListGroupItem key="header" className="header-item">
            <Row>
              <Col xs={6}>
                <b>
                  <FormattedMessage
                    id="inventory.toolingStock"
                    defaultMessage="Tool"
                  />
                </b>
              </Col>
              <Col xs={6}>
                <b>
                  <FormattedMessage
                    id="field.tooling_stock_type"
                    defaultMessage="Tool Type"
                  />
                </b>
              </Col>
            </Row>
          </ListGroupItem>

          {isFetching ? (
            <Loading />
          ) : (data?.toolLinks?.length ? (
            data?.toolLinks.map(({ uri: link, tool, type }) => (
              <ListGroupItem key={link}>
                <Row>
                  <Col xs={6}>
                    <Link
                      to={getRouteURI(
                        ROUTES.TOOLING_STOCK_DETAIL,
                        { uuid: extractUuid(tool) },
                        {},
                        true,
                      )}
                      replace
                    >
                      {data?.tools[tool]?.name ?? 'Unknown Tool'}
                    </Link>
                  </Col>
                  <Col xs={6}>
                    {data?.toolTypes[type]?.name ?? 'Unknown Tool Type'}
                  </Col>
                </Row>
              </ListGroupItem>
            ))
          ) : (
            <ListGroupItem className="text-center">No Tools linked to this piece</ListGroupItem>
          ))}
        </ListGroup>
      </div>
    </Card>
  );
};

RunToolLinks.propTypes = { uri: PropTypes.string.isRequired };

export default RunToolLinks;
