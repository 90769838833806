import React, { Suspense } from 'react';
import PropTypes from 'prop-types';
import Loading from 'rapidfab/components/Loading';
import ErrorBoundary from 'rapidfab/components/ErrorBoundary';
import { useLocation } from 'react-router-dom';
import AppRoutes from './AppRoutes';

export default function RouterComponent({ session }) {
  const location = useLocation();
  return (
    <Suspense fallback={<Loading />}>
      <ErrorBoundary session={session} routeHash={location.pathname}>
        <AppRoutes session={session} />
      </ErrorBoundary>
    </Suspense>

  );
}

RouterComponent.propTypes = {
  session: PropTypes.shape({
    fetching: PropTypes.bool,
    currentUser: PropTypes.shape({}),
  }).isRequired,
};
