import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'rapidfab/i18n';

const MappedColumn = ({ mapping, value }) => (
  <>
    {mapping[value] ? (
      <FormattedMessage id={mapping[value].id} defaultMessage={mapping[value].defaultMessage} />
    ) : (
      <FormattedMessage id="notAvailable" defaultMessage="N/A" />
    )}
  </>
);

MappedColumn.propTypes = {
  mapping: PropTypes.shape({}).isRequired,
  value: PropTypes.string,
};

MappedColumn.defaultProps = {
  value: null,
};

export default MappedColumn;
