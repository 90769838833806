import { faClose, faWrench } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import PropTypes from 'prop-types';
import Loading from 'rapidfab/components/Loading';
import { ROUTES } from 'rapidfab/constants';
import useElementScroll, { isScrolledToBottom } from 'rapidfab/hooks/useElementScroll';
import { getRouteURI } from 'rapidfab/utils/uriUtils';
import { extractUuid } from 'rapidfab/utils/uuidUtils';
import React, { useEffect, useRef, useState } from 'react';
import { Modal } from 'react-bootstrap';

const BuildPlateLineItemsModeIncompatiblePieces = ({
  show,
  onClose,
  incompatiblePiecesList,
  reworkingPiecesFetching,
  fetchMorePieces,
  lineItemUri,
}) => {
  const [loading, setLoading] = useState(false);

  const [calledForMore, setCalledForMore] = useState(false);

  const anchor = useRef();
  const scrollPosition = useElementScroll(anchor);
  const scrolledToBottom = isScrolledToBottom(anchor, scrollPosition, 20);

  useEffect(() => {
    if (
      !calledForMore && fetchMorePieces
      && (scrolledToBottom && incompatiblePiecesList.length && !reworkingPiecesFetching)
      && !loading) {
      setLoading(true);
      setCalledForMore(true);
      fetchMorePieces(lineItemUri)
        .finally(() => setLoading(false));
    }
  }, [scrolledToBottom, calledForMore, loading, reworkingPiecesFetching]);

  useEffect(() => {
    if (calledForMore && !loading) {
      setCalledForMore(false);
    }
  }, [loading]);

  return (
    <Modal
      size="sm"
      show={show}
      onHide={onClose}
      backdrop="static"
      className="quoteProcessStepModalShow incompatible-pieces-modal"
      dialogClassName="line-item-quote-modal"
      backdropClassName="incompatible-pieces-backdrop"
    >
      <div
        ref={anchor}
        style={{
          overflowY: 'auto',
          maxHeight: '600px',
        }}
      >
        <Modal.Header>
          <Modal.Title className="w-100 d-flex align-items-center justify-content-between">
            <div className="d-flex align-items-center">
              <p className="custom-darken-modal-title-text mt0 ml0">
                Incompatible Pieces
              </p>
            </div>
            <FontAwesomeIcon
              icon={faClose}
              onClick={onClose}
              tabIndex={0}
              role="button"
              className="custom-darken-modal-button"
            />
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {(reworkingPiecesFetching && !incompatiblePiecesList.length)
            ? <Loading />
            : incompatiblePiecesList.map(({ piece_name, piece }, index) => (
              <div key={piece.uri} className="d-flex align-items-center spacer-bottom mb5">
                <FontAwesomeIcon icon={faWrench} className="spacer-right" />
                <a
                  href={getRouteURI(ROUTES.PIECE_EDIT, { uuid: extractUuid(piece) })}
                  target="_blank"
                  rel="noopener noreferrer"
                  className="font-size-16 spacer-right"
                >
                  {piece_name} ({index + 1})
                </a>
              </div>
            ))}
          {loading && (
            <div style={{ padding: '20px' }}>
              <Loading />
            </div>
          )}
        </Modal.Body>
      </div>
    </Modal>
  );
};

BuildPlateLineItemsModeIncompatiblePieces.propTypes = {
  onClose: PropTypes.func.isRequired,
  show: PropTypes.bool.isRequired,
  incompatiblePiecesList: PropTypes.arrayOf(PropTypes.shape({
    piece_name: PropTypes.string,
    piece: PropTypes,
  })).isRequired,
  reworkingPiecesFetching: PropTypes.bool.isRequired,
  fetchMorePieces: PropTypes.func.isRequired,
  lineItemUri: PropTypes.string.isRequired,
};

export default BuildPlateLineItemsModeIncompatiblePieces;
