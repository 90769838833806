import { getEndpointFromURI } from 'rapidfab/utils/uriUtils';

/**
 * Initialize filter buckets with the given keys.
 * @param {Array} keys - The keys to initialize in the filter buckets.
 * @returns {Object} The initialized filter buckets with empty arrays.
 */
export const initializeFilterBuckets = keys => {
  const buckets = {};
  keys.forEach(key => {
    buckets[key] = [];
  });
  return buckets;
};

/**
 * Classify Filter URIs into the correct filter buckets based on the resource mapping.
 * @param {Array} selectedItems - The selected items containing URIs.
 * @param {Object} resourceMapping - The mapping of resource keys to filter bucket keys.
 * @returns {Object} The filter buckets with classified URIs.
 */
export const classifyFilterURIs = (selectedItems, resourceMapping) => {
  const filterBuckets = initializeFilterBuckets(Object.values(resourceMapping));
  selectedItems.forEach(({ value }) => {
    const { endpointName } = getEndpointFromURI(value) ?? {};
    const bucketKey = resourceMapping[endpointName];
    if (bucketKey) {
      filterBuckets[bucketKey].push(value);
    }
  });
  return filterBuckets;
};

/**
 * Update filters based on the filter buckets and existing filters.
 * @param {Object} filterBuckets - The filter buckets with classified URIs.
 * @param {Object} existingFilters - The current filters that need to be updated.
 * @returns {Object} The updated filters.
 */
export const getUpdatedFilters = (filterBuckets, existingFilters) => {
  const updatedFilters = { ...existingFilters };
  Object.entries(filterBuckets).forEach(([key, value]) => {
    if (value.length > 0) {
      updatedFilters[key] = value;
    } else {
      delete updatedFilters[key];
    }
  });
  return updatedFilters;
};
