import AssetRoutes from 'rapidfab/routes/AssetRoutes';
import React from 'react';
import { Routes, Route, Outlet, Navigate } from 'react-router-dom';
import { ROUTES, FEATURES } from 'rapidfab/constants';
import PropTypes from 'prop-types';
import CommentContainer from 'rapidfab/containers/CommentContainer';
import CommentActionContainer from 'rapidfab/containers/CommentActionContainer';
import MyActionsContainer from 'rapidfab/containers/MyActionsContainer';
import DanfossDDWCustomLibraryContainer from 'rapidfab/containers/danfossDDW/DanfossDDWCustomLibraryContainer';
import PrivateRoute from './PrivateRoute';
import Home from '../containers/home';
import Login from '../containers/LoginContainer';
import About from '../components/about';
import ProfileContainer from '../containers/profile/ProfileContainer';
import AnalyticsContainer from '../containers/analytics/AnalyticsContainer';
import AnatomicalModelsContainer from '../containers/AnatomicalModel/AnatomicalModelsContainer';
import CapturesContainer from '../containers/track/CapturesContainer';
import ForgotPass from '../containers/ForgotPasswordContainer';
import SentryTest from '../components/SentryTest';
import PublicRoute from './PublicRoute';
import { ROUTER_PERMISSION } from '../constantsRouter';
import AdminRoutes from './AdminRoutes';
import RecordsRoutes from './RecordsRoutes';
import OrganizeRoutes from './OrganizeRoutes';
import InventoryRoutes from './InventoryRoutes';
import PlanRoutes from './PlanRoutes';
import TrackRoutes from './TrackRoutes';
import NotFound from '../components/404';
import JobListContainer from '../containers/service_providers/JobListContainer';
import JobContainer from '../containers/service_providers/JobContainer';
import EditRestrictedLineItemOrder from '../containers/records/order/restricted/EditRestrictedLineItemOrder';
import OrderRestrictedNew from '../containers/records/order/restricted/NewRestrictedOrder';
import ReviewRestrictedOrder from '../containers/records/order/restricted/ReviewRestrictedOrder';
import OrderRestrictedEdit from '../containers/records/order/restricted/EditRestrictedOrder';
import ResetPasswordContainer from '../containers/ResetPasswordContainer';
import HawkingDashboardContainer from '../containers/hawking/HawkingDashboardContainer';
import HawkingCompanyLibraryContainer from '../containers/hawking/HawkingCompanyLibraryContainer';
import HawkingMyLibraryContainer from '../containers/hawking/HawkingMyLibraryContainer';
import HawkingAdministratorLibraryContainer from '../containers/hawking/HawkingAdministratorLibraryContainer';
import DanfossDDWViewContainer from '../containers/danfossDDW/DanfossDDWViewContainer';
import DanfossDDWDashboardContainer from '../containers/danfossDDW/DanfossDDWDashboardContainer';
import ToolingStocksListContainer from '../containers/inventory/ToolingStocksListContainer';
import ToolingStockContainer from '../containers/records/ToolingStockContainer';
import ToolingStockTypesListContainer from '../containers/inventory/ToolingStockTypesListContainer';

const AppRoutes = ({ session }) =>
  (
    <Routes>
      <Route
        path={ROUTES.LOGIN}
        element={<PublicRoute session={session} component={Login} permission={ROUTER_PERMISSION.GUEST} />}
      />
      <Route
        path={ROUTES.FORGOT_PASSWORD}
        element={<PublicRoute session={session} component={ForgotPass} permission={ROUTER_PERMISSION.GUEST} />}
      />
      <Route
        path={ROUTES.RESET_PASSWORD}
        element={
          <PublicRoute session={session} component={ResetPasswordContainer} permission={ROUTER_PERMISSION.GUEST} />
        }
      />
      <Route
        path={ROUTES.LOGIN_XEROX}
        element={<PublicRoute session={session} component={Login} permission={ROUTER_PERMISSION.GUEST} />}
      />
      <Route
        path={ROUTES.LOGIN_STANLEY_X}
        element={<PublicRoute session={session} component={Login} permission={ROUTER_PERMISSION.GUEST} />}
      />
      <Route
        path={ROUTES.RESET_PASSWORD_XEROX}
        element={
          <PublicRoute session={session} component={ResetPasswordContainer} permission={ROUTER_PERMISSION.GUEST} />
        }
      />
      <Route
        path={ROUTES.FORGOT_PASSWORD_XEROX}
        element={<PublicRoute session={session} component={ForgotPass} permission={ROUTER_PERMISSION.GUEST} />}
      />
      <Route
        path="/"
        // eslint-disable-next-line react/prop-types
        element={session?.role ? <Outlet /> : <Navigate to={ROUTES.LOGIN} />}
      >
        <Route
          index
          path="/"
          element={<PrivateRoute session={session} component={Home} permission={ROUTER_PERMISSION.LOGGED_IN_ONLY} />}
        />
        <Route
          path={ROUTES.ABOUT}
          element={<PrivateRoute session={session} component={About} permission={ROUTER_PERMISSION.ALL} />}
        />
        <Route
          path={ROUTES.COMMENT}
          element={(
            <PrivateRoute
              session={session}
              component={CommentContainer}
              permission={ROUTER_PERMISSION.BUREAU_ONLY}
              features={[FEATURES.MANUFACTURING_EXECUTION_SYSTEM]}
            />
          )}
        />
        <Route
          path={ROUTES.COMMENT_ACTION}
          element={(
            <PrivateRoute
              session={session}
              component={CommentActionContainer}
              permission={ROUTER_PERMISSION.BUREAU_ONLY}
              features={[FEATURES.MANUFACTURING_EXECUTION_SYSTEM]}
            />
          )}
        />
        <Route
          path={ROUTES.MY_ACTIONS}
          element={(
            <PrivateRoute
              session={session}
              component={MyActionsContainer}
              permission={ROUTER_PERMISSION.BUREAU_ONLY}
              features={[FEATURES.MANUFACTURING_EXECUTION_SYSTEM]}
            />
          )}
        />
        <Route
          path={ROUTES.ANALYTICS}
          element={(
            <PrivateRoute
              session={session}
              component={AnalyticsContainer}
              permission={ROUTER_PERMISSION.LOGGED_IN_ONLY}
              eatures={[FEATURES.MACHINE_ANALYTICS]}
            />
          )}
        />
        <Route
          path={ROUTES.CAPTURES}
          element={(
            <PrivateRoute
              session={session}
              component={CapturesContainer}
              permission={ROUTER_PERMISSION.BUREAU_ONLY}
              features={{
                [FEATURES.HAWKING_DEPLOYMENT]: false,
              }}
            />
          )}
        />
        <Route
          path={ROUTES.SENTRY_TEST}
          element={<PrivateRoute session={session} component={SentryTest} permission={ROUTER_PERMISSION.BUREAU_ONLY} />}
        />
        <Route
          path={ROUTES.HAWKING_DASHBOARD}
          element={(
            <PrivateRoute
              session={session}
              component={HawkingDashboardContainer}
              features={[FEATURES.HAWKING_DEPLOYMENT]}
              permission={ROUTER_PERMISSION.BUREAU_ONLY}
            />
          )}
        />
        <Route
          path={ROUTES.HAWKING_COMPANY_LIBRARY}
          element={(
            <PrivateRoute
              session={session}
              component={HawkingCompanyLibraryContainer}
              features={[FEATURES.HAWKING_DEPLOYMENT]}
              permission={ROUTER_PERMISSION.BUREAU_ONLY}
            />
          )}
        />
        <Route
          path={ROUTES.HAWKING_MY_LIBRARY}
          element={(
            <PrivateRoute
              session={session}
              component={HawkingMyLibraryContainer}
              features={[FEATURES.HAWKING_DEPLOYMENT]}
              permission={ROUTER_PERMISSION.BUREAU_ONLY}
            />
          )}
        />
        <Route
          path={ROUTES.HAWKING_ADMINISTRATOR_LIBRARY}
          element={(
            <PrivateRoute
              routeIsHiddenFromRestrictedUsers
              session={session}
              component={HawkingAdministratorLibraryContainer}
              features={[FEATURES.HAWKING_DEPLOYMENT]}
              permission={ROUTER_PERMISSION.BUREAU_ONLY}
            />
          )}
        />
        <Route
          path={ROUTES.SERVICE_PROVIDER_JOB_LIST}
          element={(
            <PrivateRoute
              session={session}
              component={JobListContainer}
              permission={ROUTER_PERMISSION.SERVICE_PROVIDER}
            />
          )}
        />
        <Route
          path={ROUTES.SERVICE_PROVIDER_JOB}
          element={(
            <PrivateRoute
              session={session}
              component={JobContainer}
              permission={ROUTER_PERMISSION.SERVICE_PROVIDER}
            />
          )}
        />
        <Route
          path={ROUTES.VENDOR_TOOLING_STOCK}
          element={(
            <PrivateRoute
              session={session}
              component={ToolingStockContainer}
              permission={ROUTER_PERMISSION.VENDOR_SERVICE_PROVIDER}
            />
          )}
        />
        <Route
          path={ROUTES.VENDOR_TOOLING_STOCKS}
          element={(
            <PrivateRoute
              session={session}
              component={ToolingStocksListContainer}
              permission={ROUTER_PERMISSION.VENDOR_SERVICE_PROVIDER}
            />
          )}
        />
        <Route
          path={ROUTES.VENDOR_TOOLING_STOCK_TYPES}
          element={(
            <PrivateRoute
              session={session}
              component={ToolingStockTypesListContainer}
              permission={ROUTER_PERMISSION.VENDOR_SERVICE_PROVIDER}
            />
          )}
        />
        <Route
          path={ROUTES.ORDER_RESTRICTED_NEW}
          element={(
            <PrivateRoute
              session={session}
              component={OrderRestrictedNew}
              features={[FEATURES.MANUFACTURING_EXECUTION_SYSTEM]}
              permission={ROUTER_PERMISSION.RESTRICTED}
            />
          )}
        />
        <Route
          path={ROUTES.EDIT_RESTRICTED_LINE_ITEM_ORDER}
          element={(
            <PrivateRoute
              session={session}
              component={EditRestrictedLineItemOrder}
              features={[FEATURES.MANUFACTURING_EXECUTION_SYSTEM]}
              permission={ROUTER_PERMISSION.RESTRICTED}
            />
          )}
        />
        <Route
          path={ROUTES.ORDER_RESTRICTED_EDIT}
          element={(
            <PrivateRoute
              session={session}
              component={OrderRestrictedEdit}
              features={[FEATURES.MANUFACTURING_EXECUTION_SYSTEM]}
              permission={ROUTER_PERMISSION.RESTRICTED}
            />
          )}
        />
        <Route
          path={ROUTES.REVIEW_RESTRICTED_ORDER}
          element={(
            <PrivateRoute
              session={session}
              component={ReviewRestrictedOrder}
              features={[FEATURES.MANUFACTURING_EXECUTION_SYSTEM]}
              permission={ROUTER_PERMISSION.RESTRICTED}
            />
          )}
        />
        <Route
          path={ROUTES.PROFILE}
          element={(
            <PrivateRoute
              session={session}
              component={ProfileContainer}
              permission={ROUTER_PERMISSION.BUREAU_ONLY}
            />
          )}
        />
        <Route
          path={ROUTES.ANATOMICAL_MODELS}
          element={(
            <PrivateRoute
              session={session}
              component={AnatomicalModelsContainer}
              features={[FEATURES.ANATOMICAL_MODEL]}
              permission={ROUTER_PERMISSION.BUREAU_ONLY}
            />
          )}
        />
        <Route
          path={ROUTES.DIGITAL_DESIGN_WAREHOUSE}
          element={(
            <PrivateRoute
              session={session}
              component={DanfossDDWViewContainer}
              features={[FEATURES.DIGITAL_DESIGN_WAREHOUSE]}
              permission={ROUTER_PERMISSION.BUREAU_ONLY}
            />
          )}
        />
        <Route
          path={ROUTES.DDW_COMPANY_LIBRARY}
          element={(
            <PrivateRoute
              routeIsHiddenFromRestrictedUsers
              session={session}
              component={DanfossDDWViewContainer}
              features={[FEATURES.DIGITAL_DESIGN_WAREHOUSE]}
              permission={ROUTER_PERMISSION.BUREAU_ONLY}
            />
          )}
        />
        <Route
          path={ROUTES.DDW_MY_LIBRARY}
          element={(
            <PrivateRoute
              routeIsHiddenFromRestrictedUsers
              session={session}
              component={DanfossDDWViewContainer}
              features={[FEATURES.DIGITAL_DESIGN_WAREHOUSE]}
              permission={ROUTER_PERMISSION.BUREAU_ONLY}
            />
          )}
        />
        <Route
          path={ROUTES.DDW_ALL_DESIGNS}
          element={(
            <PrivateRoute
              routeIsHiddenFromRestrictedUsers
              session={session}
              component={DanfossDDWViewContainer}
              features={[FEATURES.DIGITAL_DESIGN_WAREHOUSE]}
              permission={ROUTER_PERMISSION.BUREAU_ONLY}
            />
          )}
        />
        {/* Support for additional custom libraries created */}
        <Route
          path={ROUTES.DDW_CUSTOM_LIBRARY}
          element={(
            <PrivateRoute
              routeIsHiddenFromRestrictedUsers
              session={session}
              component={DanfossDDWViewContainer}
              features={[FEATURES.DIGITAL_DESIGN_WAREHOUSE]}
              permission={ROUTER_PERMISSION.BUREAU_ONLY}
            />
          )}
        />
        <Route
          path={ROUTES.DDW_BUYER_SHOPPING_CART}
          element={(
            <PrivateRoute
              routeIsHiddenFromRestrictedUsers
              session={session}
              component={DanfossDDWViewContainer}
              features={[
                FEATURES.DIGITAL_DESIGN_WAREHOUSE,
                FEATURES.PAYMENT_SYSTEM,
              ]}
              permission={ROUTER_PERMISSION.BUREAU_ONLY}
            />
          )}
        />
        {/* --- Restricted DDW Routes --- */}
        <Route
          path={ROUTES.DDW_RESTRICTED_HOME}
          element={(
            <PrivateRoute
              session={session}
              component={DanfossDDWDashboardContainer}
              features={[FEATURES.DIGITAL_DESIGN_WAREHOUSE]}
              permission={ROUTER_PERMISSION.BUREAU_ONLY}
            />
          )}
        />
        <Route
          path={ROUTES.DDW_RESTRICTED_COMPANY_LIBRARY}
          element={(
            <PrivateRoute
              session={session}
              component={HawkingCompanyLibraryContainer}
              features={[FEATURES.DIGITAL_DESIGN_WAREHOUSE]}
              permission={ROUTER_PERMISSION.BUREAU_ONLY}
            />
          )}
        />
        <Route
          path={ROUTES.DDW_RESTRICTED_MY_LIBRARY}
          element={(
            <PrivateRoute
              session={session}
              component={HawkingMyLibraryContainer}
              features={[FEATURES.DIGITAL_DESIGN_WAREHOUSE]}
              permission={ROUTER_PERMISSION.BUREAU_ONLY}
            />
          )}
        />
        <Route
          path={ROUTES.DDW_RESTRICTED_ADMINISTRATOR_LIBRARY}
          element={(
            <PrivateRoute
              session={session}
              component={HawkingAdministratorLibraryContainer}
              features={[FEATURES.DIGITAL_DESIGN_WAREHOUSE]}
              permission={ROUTER_PERMISSION.BUREAU_ONLY}
            />
          )}
        />
        {/* Support for restricted users -> see custom libraries */}
        <Route
          path={ROUTES.DDW_CUSTOM_LIBRARY_RESTRICTED}
          element={(
            <PrivateRoute
              session={session}
              component={DanfossDDWCustomLibraryContainer}
              features={[FEATURES.DIGITAL_DESIGN_WAREHOUSE]}
              permission={ROUTER_PERMISSION.BUREAU_ONLY}
            />
          )}
        />
        {/* --- End of Restricted DDW Routes --- */}

        <Route path={'/admin/*'} element={<AdminRoutes session={session} />} />
        <Route path={'/organize/*'} element={<OrganizeRoutes session={session} />} />
        <Route path={'/records/*'} element={<RecordsRoutes session={session} />} />
        <Route path={'/inventory/*'} element={<InventoryRoutes session={session} />} />
        <Route path={'/assets/*'} element={<AssetRoutes session={session} />} />
        <Route path={'/plan/*'} element={<PlanRoutes session={session} />} />
        <Route path={'/track/*'} element={<TrackRoutes session={session} />} />
      </Route>
      <Route
        path="*"
        element={<NotFound />}
      />
    </Routes>
  );

AppRoutes.propTypes = {
  session: PropTypes.shape({
    role: PropTypes.string,
  }).isRequired,
};

export default AppRoutes;
