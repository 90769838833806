import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import _concat from 'lodash/concat';

import Actions from 'rapidfab/actions';
import * as Selectors from 'rapidfab/selectors';
import { getRouteURI } from 'rapidfab/utils/uriUtils';
import { LOCATION_FILTER_DEFAULT_VALUES, ROUTES, FEATURES, API_RESOURCES } from 'rapidfab/constants';

import HomeComponent from 'rapidfab/components/home';
import Loading from 'rapidfab/components/Loading';

const HomeContainer = props => {
  const { run, order, location } = useSelector(state => state.ui.nautilus);
  const isServiceProvider = useSelector(Selectors.isServiceProvider);
  const isVendorServiceProvider = useSelector(Selectors.isVendorServiceProvider);
  const isCurrentUserRestricted = useSelector(Selectors.isCurrentUserRestricted);
  const orderStatus = useSelector(Selectors.getOrderStatusChartDataForLocation);
  const locationFilter = useSelector(Selectors.getLocationFilter);
  const isHawkingUser = useSelector(state =>
    Selectors.isFeatureEnabled(state, FEATURES.HAWKING_DEPLOYMENT)
    || Selectors.isFeatureEnabled(state, FEATURES.AUTHENTISE_PDM));
  const isStanleyXUser = useSelector(state =>
    Selectors.isFeatureEnabled(state, FEATURES.STANLEY_X_DEPLOYMENT));

  const selected = {
    apiErrors: _concat(
      order.list.errors,
      run.list.errors,
      location.list.errors,
    ),
    isServiceProvider,
    isCurrentUserRestricted,
    data: {
      orderStatus,
    },
    fetchingRuns: run.list.fetching,
    locationFilter,
    isHawkingUser,
    isStanleyXUser,
  };

  const dispatch = useDispatch();
  const onInitialize = locationsFilter => {
    const params = {};

    if (
      typeof locationsFilter !== 'undefined'
      && locationsFilter !== LOCATION_FILTER_DEFAULT_VALUES.ALL
    ) {
      params.location = locationsFilter;
    }

    dispatch(Actions.Api.nautilus[API_RESOURCES.LOCATION].list());
  };

  const [isRedirecting, setIsRedirecting] = useState();

  useEffect(() => {
    if (!isVendorServiceProvider && !isServiceProvider && !isCurrentUserRestricted) {
      onInitialize(locationFilter);
    }
  }, [locationFilter]);

  const redirectToRoleHomePage = () => {
    if (isServiceProvider) {
      window.location.hash = getRouteURI(ROUTES.SERVICE_PROVIDER_JOB_LIST);
    } else if (isVendorServiceProvider) {
      // Must be above isRestricted since (vendor) service providers are also restricted
      window.location.hash = getRouteURI(ROUTES.VENDOR_TOOLING_STOCKS);
    } else if (isCurrentUserRestricted) {
      window.location.hash = getRouteURI(ROUTES.ORDER_NEW);
    } else if (isHawkingUser || isStanleyXUser) {
      window.location.hash = getRouteURI(ROUTES.HAWKING_DASHBOARD);
    }
  };

  useEffect(() => {
    if (isVendorServiceProvider || isServiceProvider || isCurrentUserRestricted || isHawkingUser) {
      setIsRedirecting(true);
      redirectToRoleHomePage();
    }
  }, [isVendorServiceProvider, isServiceProvider, isCurrentUserRestricted]);

  if (isVendorServiceProvider || isServiceProvider || isCurrentUserRestricted) {
    // Redirect will be done as useEffect hook, but <HomeComponent /> must not be rendered
    return <Loading />;
  }

  if (isRedirecting) {
    return <Loading />;
  }

  return (<HomeComponent {...props} {...selected} />);
};

export default HomeContainer;
