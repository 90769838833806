import React, { useEffect, useState } from 'react';
import { Button, FormControl, Modal, OverlayTrigger, Table, Tooltip } from 'react-bootstrap';
import { FormattedMessage } from 'react-intl';
import FormRow from 'rapidfab/components/FormRow';
import { Field, Form } from 'react-final-form';
import PropTypes from 'prop-types';
import Loading from 'rapidfab/components/Loading';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSave, faTimesCircle, faTrash } from '@fortawesome/free-solid-svg-icons';
import { extractUuid } from 'rapidfab/utils/uuidUtils';
import CancelOrDeleteModal from 'rapidfab/components/CancelOrDeleteModal';
import _isEmpty from 'lodash/isEmpty';
import _keyBy from 'lodash/keyBy';
import _isEqual from 'lodash/isEqual';
import { ROUTES } from 'rapidfab/constants';
import { getRouteURI } from 'rapidfab/utils/uriUtils';
import { useSelector } from 'react-redux';
import * as Selectors from 'rapidfab/selectors';
import TableCellLabels from 'rapidfab/components/TableCellLabels';
import { Link } from 'react-router-dom';
import FormattedLocalizedCost from 'rapidfab/components/FormattedLocalizedCost';
import ShoppingCartItemDataRow from 'rapidfab/components/ShoppingCart/ShoppingCartItemDataRow';
import { getShoppingCartItemsTotalPrice } from 'rapidfab/utils/shoppingCartUtils';

const ViewCartModal = ({
  shoppingCart,
  shoppingCartItems,
  modelsByUri,
  labelsByUri,
  show,
  onClose,
  initialFormValues,
  handleSaveCartOrder,
  isFetchingShoppingCart,
  handleDeleteCartItem,
  handleAbandonCart,
  handleSaveCart,
  materials,
  isIntegrationCastorFeatureEnabled,
  isRobozeDdwFeatureEnabled,
  isRobozeBureauOrderFieldsFeatureEnabled,
  isDigitalDesignWarehouseFeatureEnabled,
}) => {
  const modelLibraries = useSelector(Selectors.getModelLibraries);
  const modelLibrariesByUri = _keyBy(modelLibraries, 'uri');

  const [showConfirmDeleteCartItemModal, setShowConfirmDeleteCartItemModal] = useState(false);
  const [updatedShoppingCartItemsState, setUpdatedShoppingCartItemsState] = useState([]);
  const [deletedCartItemUri, setDeletedCartItemUri] = useState('');

  const isNoShoppingCartItems = _isEmpty(shoppingCartItems);
  const robozeAndCastorFeaturesEnabled = isRobozeBureauOrderFieldsFeatureEnabled && isIntegrationCastorFeatureEnabled;

  const isCostBreakdownUIVisible =
    (shoppingCartItems.some(item => item && item.price_per !== null)
      && isIntegrationCastorFeatureEnabled && isRobozeDdwFeatureEnabled);

  useEffect(() => {
    setUpdatedShoppingCartItemsState(shoppingCartItems);
  }, [shoppingCartItems]);

  const renderCreateOrderButton = () => {
    if (isFetchingShoppingCart) {
      return <Loading />;
    }

    if (robozeAndCastorFeaturesEnabled) {
      return (
        <FormattedMessage
          id="modelLibrary.createRequest"
          defaultMessage="Create Request"
        />
      );
    }
    return (
      <FormattedMessage
        id="modelLibrary.createOrder"
        defaultMessage="Create Order"
      />
    );
  };

  return (
    <Modal size="lg" show={show} onHide={onClose} backdrop="static">
      <Form
        onSubmit={() => handleSaveCartOrder(shoppingCart?.uri)}
        initialValues={initialFormValues}
        render={({ handleSubmit, values }) => (
          <>
            <Modal.Header onHide={onClose} closeButton>
              <FormattedMessage
                id="modelLibrary.shoppingCart.viewCart"
                defaultMessage="View Cart"
              />
            </Modal.Header>
            <Modal.Body>
              <div
                className="d-flex justify-content-between mb15 align-items-start"
              >
                <h1>
                  Shopping Cart
                </h1>
                <div
                  className="d-flex"
                >
                  <OverlayTrigger
                    overlay={(
                      <Tooltip>
                        <FormattedMessage
                          id="modelLibrary.shoppingCart.abandonCart"
                          defaultMessage="Abandon Cart"
                        />
                      </Tooltip>
                    )}
                  >
                    <Button
                      bg="danger"
                      variant="danger"
                      onClick={() => handleAbandonCart(shoppingCart?.uri)}
                    >
                      {isFetchingShoppingCart ?
                        <Loading /> :
                        <FontAwesomeIcon icon={faTimesCircle} />}
                    </Button>
                  </OverlayTrigger>
                  <OverlayTrigger
                    overlay={(
                      <Tooltip>
                        Save Shopping Cart
                      </Tooltip>
                    )}
                  >
                    <Button
                      className="spacer-left"
                      onClick={() => handleSaveCart(shoppingCart?.uri, updatedShoppingCartItemsState, values)}
                      disabled={_isEqual(shoppingCartItems, updatedShoppingCartItemsState)
                        && _isEqual(initialFormValues.order_name, values.order_name)}
                    >
                      <FontAwesomeIcon icon={faSave} />
                    </Button>
                  </OverlayTrigger>
                </div>
              </div>
              <Field
                name="order_name"
                render={({ input }) => (
                  <FormRow
                    id={robozeAndCastorFeaturesEnabled && isDigitalDesignWarehouseFeatureEnabled
                      ? 'requestName' : 'orderName'}
                    defaultMessage={robozeAndCastorFeaturesEnabled && isDigitalDesignWarehouseFeatureEnabled
                      ? 'Request Name' : 'Order Name'}
                  >
                    <FormControl
                      type="text"
                      value={input.value}
                      onChange={event => {
                        input.onChange(event);
                      }}
                    />
                  </FormRow>
                )}
              />
              <div
                className="card-list mb30"
                style={{ overflowY: 'auto' }}
              >
                {isNoShoppingCartItems && (
                  <p>Shopping cart is currently empty.</p>
                )}
                {shoppingCartItems.map((cartItem, cartItemIndex) => {
                  const cartItemModelLibrary = modelLibrariesByUri[cartItem.source];
                  const cartItemModelLibraryModel = modelsByUri[cartItemModelLibrary?.additive.model];
                  const cartItemMaterial = materials.find(({ base_template }) =>
                    base_template === cartItem?.material_override);

                  return (
                    <div
                      style={{ maxWidth: 600 }}
                      className="card"
                      key={cartItem.uri}
                      role="button"
                      tabIndex={0}
                    >
                      <img className="card-img-top" src={cartItemModelLibraryModel?.snapshot_content} alt="" />
                      <div className="card-body d-flex align-items-center justify-content-between">
                        <h5 className="card-title" style={{ maxWidth: '80px' }}>{cartItemModelLibrary?.name}</h5>
                        {
                          cartItem && cartItem.labels && cartItem.labels.length > 0 && (
                            <div className="spacer-top">
                              <TableCellLabels
                                labelUris={cartItem.labels}
                                labelsByUri={labelsByUri}
                              />
                            </div>
                          )
                        }
                        <Field
                          name={`${extractUuid(cartItem.uri)}_quantity`}
                          initialValue={cartItem.quantity}
                          render={({ input }) => (
                            <FormControl
                              onChange={event => {
                                input.onChange(event);

                                const newShoppingCartItemsState = [
                                  ...updatedShoppingCartItemsState,
                                ];

                                const newShoppingCartItem = {
                                  ...newShoppingCartItemsState[cartItemIndex],
                                  quantity: Number(event.target.value),
                                };

                                newShoppingCartItemsState[cartItemIndex] = newShoppingCartItem;

                                // Set new local state on what the user has set the quantity to.
                                setUpdatedShoppingCartItemsState(newShoppingCartItemsState);
                              }}
                              style={{ maxWidth: 70 }}
                              className="ml10"
                              size="sm"
                              type="number"
                              min={1}
                              defaultValue={cartItem.quantity}
                            />
                          )}
                        />
                        {showConfirmDeleteCartItemModal && (

                          <CancelOrDeleteModal
                            modalType="delete"
                            handleConfirm={() => {
                              handleDeleteCartItem(deletedCartItemUri);
                              setDeletedCartItemUri('');
                            }}
                            /* handleOpen actually handles the onClose */
                            handleOpen={() => setShowConfirmDeleteCartItemModal(false)}
                          />

                        )}
                        <Button
                          variant="danger"
                          bg="danger"
                          className="ml0"
                          onClick={() => {
                            setDeletedCartItemUri(cartItem.uri);
                            setShowConfirmDeleteCartItemModal(true);
                          }}
                        >
                          <FontAwesomeIcon icon={faTrash} />
                        </Button>
                      </div>
                      {!!cartItem.price_per && (
                        <div className="card-footer text-white">
                          <table className="w-100">
                            {
                              !robozeAndCastorFeaturesEnabled && (
                                <ShoppingCartItemDataRow
                                  titleId="quote_price_factor.price"
                                  titleDefaultMessage="Price"
                                  value={(
                                    <FormattedLocalizedCost value={cartItem.price_per} />
                                  )}
                                />
                              )
                            }
                            {cartItem.material_override && cartItemMaterial && (
                              <ShoppingCartItemDataRow
                                titleId="material"
                                titleDefaultMessage="Material"
                                value={(
                                  <Link
                                    to={getRouteURI(ROUTES.MATERIALS, null,
                                      { uuid: extractUuid(cartItemMaterial.uri) }, true)}
                                  >
                                    {cartItemMaterial.name}
                                  </Link>
                                )}
                              />
                            )}
                          </table>
                        </div>
                      )}
                    </div>
                  );
                })}
              </div>
              {isCostBreakdownUIVisible && (
                <div id="costBreakdown">
                  <hr />
                  <h2>Summary</h2>
                  <Table className="pd-exp inverse">
                    <thead>
                      <tr>
                        <th className="text-left">
                          <FormattedMessage id="field.name" defaultMessage="Name" />
                        </th>
                        <th className="text-left">
                          <FormattedMessage id="field.material" defaultMessage="Material" />
                        </th>
                        <th className="text-left">
                          <FormattedMessage id="field.quantity" defaultMessage="Quantity" />
                        </th>
                        <th className="text-left">Price Per Unit</th>
                        <th className="text-left">
                          <FormattedMessage id="line_item.quote_price_factor.price" defaultMessage="Price" />
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      {shoppingCartItems.filter(item => item.price_per).map(item => {
                        const cartItemMaterial = materials.find(({ base_template }) =>
                          base_template === item?.material_override);

                        return (
                          <tr>
                            <td>{modelLibrariesByUri[item.source]?.name}</td>
                            <td>
                              {cartItemMaterial ?
                                (
                                  <Link
                                    to={getRouteURI(ROUTES.MATERIALS,
                                      null, { uuid: extractUuid(cartItemMaterial.uri) }, true)}
                                  >
                                    {cartItemMaterial.name}
                                  </Link>
                                ) :
                                'N/A'}
                            </td>
                            <td>{item.quantity}</td>
                            <td>
                              <FormattedLocalizedCost value={item.price_per} />
                            </td>
                            <td>
                              <FormattedLocalizedCost value={item.price_per * item.quantity} />
                            </td>
                          </tr>
                        );
                      })}
                    </tbody>
                    <thead />
                    <tbody>
                      <tr>
                        <td colSpan={3} />
                        <td>
                          <b>
                            <FormattedMessage
                              id="total"
                              defaultMessage="Total"
                            />
                          </b>
                        </td>
                        <td>
                          <FormattedLocalizedCost
                            value={getShoppingCartItemsTotalPrice(shoppingCartItems.filter(item => item.price_per))}
                          />
                        </td>
                      </tr>
                    </tbody>
                  </Table>
                </div>
              )}
            </Modal.Body>
            <Modal.Footer>
              <Button
                bg="danger"
                variant="danger"
                onClick={onClose}
              >
                <FormattedMessage
                  id="button.close"
                  defaultMessage="Close"
                />
              </Button>
              <Button
                bg="success"
                variant="success"
                className="spacer-left"
                onClick={handleSubmit}
                disabled={isFetchingShoppingCart
                || isNoShoppingCartItems}
              >
                {renderCreateOrderButton()}
              </Button>
              {/* </div> */}
            </Modal.Footer>
          </>
        )}
      />
    </Modal>
  );
};

export default ViewCartModal;

ViewCartModal.propTypes = {
  show: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  initialFormValues: PropTypes.shape({
    order_name: PropTypes.string,
  }).isRequired,
  handleSaveCartOrder: PropTypes.func.isRequired,
  shoppingCartItems: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  modelsByUri: PropTypes.shape({}).isRequired,
  shoppingCart: PropTypes.shape({
    uri: PropTypes.string,
  }).isRequired,
  labelsByUri: PropTypes.shape({}).isRequired,
  modelLibrariesByUri: PropTypes.shape({}).isRequired,
  isFetchingShoppingCart: PropTypes.bool.isRequired,
  handleDeleteCartItem: PropTypes.func.isRequired,
  handleAbandonCart: PropTypes.func.isRequired,
  handleSaveCart: PropTypes.func.isRequired,
  materials: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  isIntegrationCastorFeatureEnabled: PropTypes.bool.isRequired,
  isRobozeDdwFeatureEnabled: PropTypes.bool.isRequired,
  isRobozeBureauOrderFieldsFeatureEnabled: PropTypes.bool.isRequired,
  isDigitalDesignWarehouseFeatureEnabled: PropTypes.bool.isRequired,
};
