import React from 'react';
import { Badge, Button, Card, Col, Image, Modal, Row } from 'react-bootstrap';
import { FormattedMessage } from 'react-intl';
import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPrint, faQrcode } from '@fortawesome/free-solid-svg-icons';

export const QrLogonCodeDisclaimerConfirmationModal = ({
  show,
  onConfirm,
}) => (
  <Modal show={show} onHide={() => onConfirm(false)} backdrop="static">
    <Modal.Header closeButton>Invalidation Warning</Modal.Header>
    <Modal.Body>
      <p>
        By generating a new logon QR code, all QRs generated previously
        for this user will be invalidated, do you wish to continue?
      </p>
    </Modal.Body>
    <Modal.Footer>
      <Button variant="danger" onClick={() => onConfirm(false)}>
        <FormattedMessage id="button.cancel" defaultMessage="Cancel" />
      </Button>
      <Button variant="success" onClick={() => onConfirm(true)}>
        Continue
      </Button>
    </Modal.Footer>
  </Modal>
);

const QrLogonCodeModal = ({
  show,
  onHide,
  qrCode,
  user,
}) => {
  const handleOpenPrintPreviewQrImage = () => {
    const temporaryWindow = window.open('', 'Image');
    temporaryWindow.document.write(`<img src="${qrCode}" />`);
    temporaryWindow.document.title = `${user?.username}`;
    temporaryWindow.print();
  };

  const { name, username, emails, uuid } = user;

  return (
    <Modal show={show} onHide={onHide} backdrop="static">
      <Modal.Header closeButton>QR Logon Code</Modal.Header>
      <Modal.Body>
        <Row>
          <Col sm={5}>
            <Image src={qrCode} />
          </Col>
          <Col sm={7}>
            <Card bg="light">
              <Card.Header>User Details</Card.Header>
              <Card.Body>
                <p>Name: {name}</p>
                <p>Username: {username}</p>
                <p>UUID: <Badge className="badge badge-sm text-white" bg="secondary">{emails[0]?.user_uuid || uuid}</Badge></p>
              </Card.Body>
            </Card>
            <Card bg="light" className="spacer-top">
              <Card.Body>
                <p>
                  This is your Active QR Code. Any previously generated QR codes are now invalid.&nbsp;
                  Please print/save this code for your use.
                </p>
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="danger" onClick={onHide}>
          <FormattedMessage id="button.cancel" defaultMessage="Cancel" />
        </Button>
        <Button variant="success" onClick={handleOpenPrintPreviewQrImage}>
          Open in Print Preview
          <FontAwesomeIcon className="spacer-left" icon={faPrint} />
        </Button>
        {/* eslint-disable-next-line react/no-unknown-property */}
        <a download={`logon_code_${username}.png`} href={qrCode}>
          <Button variant="success">
            Download QR Code
            <FontAwesomeIcon className="spacer-left" icon={faQrcode} />
          </Button>
        </a>
      </Modal.Footer>
    </Modal>
  );
};

export default QrLogonCodeModal;

QrLogonCodeDisclaimerConfirmationModal.propTypes = {
  show: PropTypes.bool.isRequired,
  onConfirm: PropTypes.func.isRequired,
};

QrLogonCodeModal.propTypes = {
  show: PropTypes.bool.isRequired,
  onHide: PropTypes.func.isRequired,
  qrCode: PropTypes.string.isRequired,
  user: PropTypes.shape({
    username: PropTypes.string,
    name: PropTypes.string,
    uuid: PropTypes.string,
    emails: PropTypes.arrayOf(PropTypes.shape({
      user_uuid: PropTypes.string,
    })).isRequired,
  }).isRequired,
};
