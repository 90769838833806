import { FormattedMessage } from 'rapidfab/i18n';
import React from 'react';
import { Badge, Button, Container } from 'react-bootstrap';
import PropTypes from 'prop-types';
import { ROUTES } from 'rapidfab/constants';
import { getRouteURI } from 'rapidfab/utils/uriUtils';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus } from '@fortawesome/free-solid-svg-icons';
import TableWithSearching from '../Tables/TableWithSearching';

const JeniClusters = ({
  data,
  fetching,
  locations,
  locationsByUri,
  isServiceProviderRole,
  ...componentProps
}) => {
  const columns = [
    {
      type: 'uuid',
      uid: 'field.id',
      accessor: 'uuid',
      defaultMessage: 'ID',
      resource: 'jeni-cluster',
    },
    {
      type: 'custom',
      uid: 'field.name',
      accessor: 'name',
      defaultMessage: 'Name',
      isSortable: true,
      Cell: ({ row: { original } }) => (
        <div>
          {original.name}
          {original.archived && (
            <Badge bg="default" className="badge-sm ms-2">
              <FormattedMessage id="field.archived" defaultMessage="Archived" />
            </Badge>
          )}
        </div>
      ),
    },
    {
      type: 'location',
      uid: 'field.location',
      accessor: 'location',
      defaultMessage: 'Location',
      resources: locationsByUri,
      resource: 'location',
    },
    {
      type: 'text',
      uid: 'field.status',
      accessor: 'status',
      defaultMessage: 'Status',
      resource: 'jeni-cluster',
    },
  ];

  const NavbarLinks = (
    <Button
      className="pull-right"
      size="sm"
      variant="primary"
      href={getRouteURI(ROUTES.JENI_CLUSTER_NEW)}
      onClick={() => {}}
    >
      <FontAwesomeIcon icon={faPlus} />{' '}
      <FormattedMessage
        id="record.jeniCluster.add"
        defaultMessage="Add JENI Cluster"
      />
    </Button>
  );

  return (
    <Container fluid>
      <TableWithSearching
        {...componentProps}
        locations={locations}
        breadcrumbs={[
          'inventory', 'jeniClusters',
        ]}
        navbar={NavbarLinks}
        tableID="jeniClusters"
        isFetching={fetching}
        data={data}
        columns={columns}
        withDefaultPagination
        isFilteringEnabled
        initialSortedDesc={false}
        initialSortedColumn="name"
        isManualSoringEnabled={false}
        withBreadcrumbs
        isUpdatedColumnShown={false}
      />
    </Container>
  );
};

JeniClusters.propTypes = {
  data: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  locations: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  locationsByUri: PropTypes.shape({}).isRequired,
  isServiceProviderRole: PropTypes.bool.isRequired,
  fetching: PropTypes.bool.isRequired,
  row: PropTypes.shape({
    original: PropTypes.shape({
      uuid: PropTypes.string.isRequired,
      uri: PropTypes.string.isRequired,
      name: PropTypes.string.isRequired,
      archived: PropTypes.string,
    }),
  }).isRequired,
};

export default JeniClusters;
