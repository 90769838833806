export const GUIDELINES_ENGINE_ACTION_TYPES = {
  ADD_WORK_INSTRUCTION: 'add_work_instruction',
  ADD_WORKFLOW_STEP: 'add_workflow_step',
};

export const GUIDELINES_EXPRESSION_EMPTY = {
  field_type: {
    label: null,
    value: null,
  },
  field: {
    label: null,
    value: null,
  },
  comparator: {
    label: null,
    value: null,
  },
  value: {
    label: null,
    uri: null,
  },
  uuid: Math.random().toString(36).slice(2),
  action: {
    type: null,
    target_process_step: null,
    data: null,
  },
};

/* 👇🏼 Initial state for new work instruction */
export const INITIAL_WORK_INSTRUCTION_STATE = {
  additional_instruction_url: '',
  description: '',
  report_type: 'text',
  position: 0,
  required: false,
  report_units: null,
  threshold: null,
  threshold_type: null,
  threshold_action: null,
  threshold_notification: null,
  choices: null,
};

export const GUIDELINES_EXPRESSION_FIELD_TYPES = {
  WORK_INSTRUCTION: 'work-instruction',
  PROCESS_STEP: 'process-step',
  ORDER: 'order',
};

export const GUIDELINES_ENGINE_FIELD_TYPES_MAPPING = {
  'line-item': 'Line Item',
  order: 'Order',
  'process-step': 'Workflow Step',
};
