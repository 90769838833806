import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import {
  getAccessInfoForResourceUuid,
  getCustomGroupsByUri,
  getUUIDResource,
  isFeatureEnabled,
} from 'rapidfab/selectors';
import Actions from 'rapidfab/actions';
import { API_RESOURCES, FEATURES } from 'rapidfab/constants';
import { extractUuid } from 'rapidfab/utils/uuidUtils';
import _get from 'lodash/get';
import _uniq from 'lodash/uniq';
import _find from 'lodash/find';
import DisabledIconWithTooltip from 'rapidfab/components/DisabledIconWithTooltip';
import { customGroupResourceType } from 'rapidfab/types';
import { getEntityTypeTitleFromUri } from 'rapidfab/utils/uriUtils';
import Loading from 'rapidfab/components/Loading';
import Tooltip from 'rapidfab/components/Tooltip';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faLock } from '@fortawesome/free-solid-svg-icons';

/**
 * Renders fragment with child component and tooltip (see renderDisabledPrefix prop)
 *
 * IMPORTANT: Requires "group-qualifications" feature to be enabled,
 * otherwise child component will be rendered WITHOUT any limitations (No `disabled`, etc.).
 *
 * (if access is restricted by "access-info-for-resource" service) with render props pattern.
 * Child must be provided as function callback.
 * Callback receives `disabled` boolean, and `disabledPrefix` jsx which can be used to render prefix manually if needed.
 *
 * Example:
 * <DisabledByAccessInfoCheck
 *   resourceUri={someResourceUri}
 *   actionType={ACCESS_INFO_ACTION_TYPES.EDIT}
 *   renderDisabledPrefix={true}
 * >
 *   {({ disabled, disabledPrefix }) => (<button disabled={disabled}>{disabled ? 'Locked' : 'Unlocked'}</button>)
 * </DisabledByAccessInfoCheck>
 */
const DisabledByAccessInfoCheck = ({
  children,
  onInitialize,
  resourceUri,
  resource,
  actionsInfo,
  customGroupsByUri,
  actionType,
  fetching,
  resourceNameKey,
  renderDisabledPrefix,
  tooltipPlacement,
  isGroupQualificationsFeatureEnabled,
  disableInit,
  handleGetCustomGroups,
  requestCustomGroupsList,
}) => {
  const [initializedUri, setInitializedUri] = useState(null);
  const [disabledGroups, setDisabledGroups] = useState([]);
  if (typeof children !== 'function') {
    throw new TypeError('You have to provide children as a function into DisabledFor component.');
  }

  useEffect(() => {
    if (!isGroupQualificationsFeatureEnabled) {
      // No need to load access info data if group-qualifications feature is disabled
      return;
    }

    if (!resource) {
      // No need to load access info if the resource is not yet in the store
      return;
    }

    if (initializedUri === resource.uri) {
      // Make sure initialization is run only once
      return;
    }

    setInitializedUri(resource.uri);

    if (actionsInfo || disableInit) {
      // No need to load access info in case it is already available in the store
      return;
    }
    onInitialize(resource.uri);
  }, [resource]);

  if (fetching || (resourceUri && !resource)) {
    return <Loading />;
  }

  let accessDisabled = false;
  // Setting disabled prefix to default generic message by default
  let disabledPrefix = <DisabledIconWithTooltip placement={tooltipPlacement} />;

  if (
    // Run checks, when `group-qualifications` feature is enabled
    isGroupQualificationsFeatureEnabled
    // and when uri is provided
    && resourceUri
    // and when accessInfo is available
    && actionsInfo
  ) {
    const disallowedActionInfo = _find(actionsInfo, { type: actionType, allowed: false });
    if (disallowedActionInfo) {
      if (
        disallowedActionInfo?.disallow_by_edit_groups &&
        requestCustomGroupsList &&
        !Object.keys(customGroupsByUri)?.length) {
        handleGetCustomGroups(disallowedActionInfo.disallow_by_edit_groups);
      }
      const disallowedByGroupNames = disallowedActionInfo.disallow_by_edit_groups
        .map(
          groupUri => _get(customGroupsByUri, [groupUri, 'name']),
        )
        .filter(Boolean);
      if (disallowedByGroupNames.length) {
        if (!disabledGroups.length && requestCustomGroupsList) {
          setDisabledGroups(disallowedByGroupNames);
        }
        // Show `prohibited via group` message when there are any group names
        disabledPrefix = (
          <DisabledIconWithTooltip
            id="limitedToGroupFunctionality"
            defaultMessage='Cannot edit this {entityTypeName} "{entityName}" Editing is limited to the Qualified Group "{groupName}" and Bureau Managers. Contact your Bureau Manager if you have any questions.'

            placement={tooltipPlacement}
            values={{
              entityTypeName: getEntityTypeTitleFromUri(resourceUri),
              entityName: resource[resourceNameKey],
              groupName: disallowedByGroupNames.join(', '),
            }}
          />
        );
      } else if (disallowedActionInfo.disallow_reason) {
        // Show BE error message if no groups info available
        disabledPrefix = (
          <Tooltip
            id="accessDisallowedReason"
            placement={tooltipPlacement}
            trigger={(<FontAwesomeIcon icon={faLock} className="spacer-left spacer-right" />)}
          >
            {disallowedActionInfo.disallow_reason}
          </Tooltip>
        );
      }
      accessDisabled = true;
    }
  }

  return (
    <>
      {
        // Render disabled prefix here if it was requested to do so
        accessDisabled && renderDisabledPrefix && disabledPrefix
      }
      {
        // Passing disabledPrefix further to be able to use it custom
        children({ disabled: accessDisabled, disabledPrefix, disabledGroups })
      }
    </>
  );
};

DisabledByAccessInfoCheck.propTypes = {
  resourceUri: PropTypes.string,
  resource: PropTypes.shape({
    uri: PropTypes.string,
    name: PropTypes.string,
  }),
  children: PropTypes.func.isRequired,
  actionsInfo: PropTypes.arrayOf(PropTypes.shape({
    allowed: PropTypes.bool,
    disallow_by_edit_groups: PropTypes.arrayOf(PropTypes.string),
    disallow_reason: PropTypes.string,
    type: PropTypes.string,
  })),
  onInitialize: PropTypes.func.isRequired,
  customGroupsByUri: PropTypes.objectOf(customGroupResourceType).isRequired,
  actionType: PropTypes.string.isRequired,
  fetching: PropTypes.bool.isRequired,
  resourceNameKey: PropTypes.string,
  renderDisabledPrefix: PropTypes.bool,
  tooltipPlacement: PropTypes.string,
  isGroupQualificationsFeatureEnabled: PropTypes.bool.isRequired,
  disableInit: PropTypes.bool,
  requestCustomGroupsList: PropTypes.bool,
  handleGetCustomGroups: PropTypes.func.isRequired,
};

DisabledByAccessInfoCheck.defaultProps = {
  resourceUri: null,
  resource: null,
  actionsInfo: null,
  // Disabled prefix is not rendered by default
  renderDisabledPrefix: false,
  // Assuming that most of the entities will have `name` key which is actually and Entity Name
  // E.g. `run.name`, `piece.name`, etc.
  // Adding an option to override this if needed (e.g. when there is no `name` key for the resource)
  resourceNameKey: 'name',
  tooltipPlacement: undefined,
  disableInit: false,
  requestCustomGroupsList: false,
};

function mapStateToProps(state, { resourceUri }) {
  const resource = resourceUri && getUUIDResource(state, extractUuid(resourceUri));
  const actionsInfo = getAccessInfoForResourceUuid(state, extractUuid(resourceUri));
  const fetching =
    state.ui.nautilus[API_RESOURCES.ACCESS_INFO_FOR_RESOURCE].list.fetching
    || state.ui.nautilus[API_RESOURCES.CUSTOM_GROUP].list.fetching;
  const isGroupQualificationsFeatureEnabled = isFeatureEnabled(state, FEATURES.GROUP_QUALIFICATIONS);
  return {
    fetching,
    resource,
    actionsInfo,
    customGroupsByUri: getCustomGroupsByUri(state),
    isGroupQualificationsFeatureEnabled,
  };
}

const mapDispatchToProps = dispatch => ({
  onInitialize: resourceUri => dispatch(Actions.Api.nautilus[API_RESOURCES.ACCESS_INFO_FOR_RESOURCE]
    .list({ target_uri: resourceUri }))
    .then(
      response => {
        const customGroupUris = _uniq(
          response.json.resources.flatMap(
            resource => resource.actions
              // Ignore group uris for allowed actions
              .filter(action => !action.allowed)
              // disallow_by_edit_groups is a list of Custom group URIs
              .flatMap(action => action.disallow_by_edit_groups),
          ),
        );
        if (customGroupUris.length) {
          // Load Custom groups listed in prohibited actions (group names used in a tooltip)
          dispatch(Actions.Api.nautilus[API_RESOURCES.CUSTOM_GROUP].list({ uri: customGroupUris }));
        }
      },
    ),
  handleGetCustomGroups: customGroupUris =>
    dispatch(Actions.Api.nautilus[API_RESOURCES.CUSTOM_GROUP].list({ uri: customGroupUris })),
});

export default connect(mapStateToProps, mapDispatchToProps)(DisabledByAccessInfoCheck);
