import React, { useEffect, useState, useMemo, useCallback } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';

import Actions from 'rapidfab/actions';
import {
  MODEL_LIBRARY_TYPES, API_RESOURCES,
  FEATURES,
  ROUTES,
  BUILD_STATUS, PAGINATION_IGNORE_DEFAULT_LIMIT,
} from 'rapidfab/constants';
import { loadModelLibrariesWithModels } from 'rapidfab/dispatchers/modelLibrary';
import {
  getBureauUri,
  getLabelsByUri,
  getMaterials,
  getMaterialsByUri,
  getModelsByUri,
  isFeatureEnabled,
  isCurrentUserRestricted,
  getBureau,
  getSession,
  getUsersByUri,
  getShoppingCarts,
  getShoppingCartItems,
  getWorkflowsByUri,
  getIsDebugModeEnabled,
  getModelLibraryCommentsByModelUUID,
} from 'rapidfab/selectors';
import _map from 'lodash/map';
import _omit from 'lodash/omit';
import _isEqual from 'lodash/isEqual';
import _filter from 'lodash/filter';
import ModelLibraries from 'rapidfab/components/organize/ModelLibraries';
import ModelLibraryContainer from 'rapidfab/containers/records/ModelLibraryContainer';
import { useSearchParams } from 'react-router-dom';
import orderBy from 'lodash/orderBy';
import ViewCartModal from 'rapidfab/components/modals/ViewShoppingCartModal';
import { getRouteURI } from 'rapidfab/utils/uriUtils';
import { extractUuid } from 'rapidfab/utils/uuidUtils';
import dayjs from 'dayjs';
import _debounce from 'lodash/debounce';
import _uniq from 'lodash/uniq';
import _compact from 'lodash/compact';
import { loadModelsInChunks } from 'rapidfab/dispatchers/model';
import { loadCastorCostingConfigs } from 'rapidfab/dispatchers/castorCosting';

function getFilterRegex(filter) {
  const isSpecialChar = /[ $()*+.?[\\\]^{|}]+$/;
  const isNumeric = /^\d+$/;
  const characters = [...filter].map(char => {
    if (isSpecialChar.test(char)) {
      return `\\${char}`;
    } if (isNumeric.test(char)) {
      return `[${char}]`;
    }
    return char;
  });
  const expanded = characters.join('');
  return new RegExp(`^.*${expanded}.*$`);
}

const ModelLibrariesByFiltersContainer = props => {
  const {
    ownerUri,
    modelLibraries,
  } = props;

  const bureau = useSelector(getBureau);
  const user = useSelector(getSession);
  const modelLibraryCommentsByModelUUID = useSelector(getModelLibraryCommentsByModelUUID);
  const usersByUri = useSelector(getUsersByUri);
  const bureauUri = useSelector(getBureauUri);
  const fetching = useSelector(state => state.ui.nautilus[API_RESOURCES.MODEL_LIBRARY].list.fetching
    || state.ui.nautilus[API_RESOURCES.LABEL].list.fetching
    || state.ui.nautilus[API_RESOURCES.MATERIAL].list.fetching
    || state.ui.nautilus[API_RESOURCES.MODEL].list.fetching);
  const isFetchingShoppingCart = useSelector(state => state.ui.nautilus[API_RESOURCES.SHOPPING_CART].put.fetching);
  const modelsByUri = useSelector(getModelsByUri);
  const labelsByUri = useSelector(getLabelsByUri);
  const materials = useSelector(getMaterials);
  const materialsByUri = useSelector(getMaterialsByUri);
  const workflowsByUri = useSelector(getWorkflowsByUri);
  const shoppingCarts = useSelector(getShoppingCarts);
  const shoppingCartItems = useSelector(getShoppingCartItems);
  const isDebugModeEnabled = useSelector(getIsDebugModeEnabled);

  /* Selecting 0th index for 👇🏼; further logic updates to this may be needed */
  const shoppingCart = shoppingCarts[0];
  const itemsForCurrentShoppingCart = _filter(shoppingCartItems, { shopping_cart: shoppingCart?.uri });
  const isHawkingDeploymentFeatureEnabled = useSelector(state => isFeatureEnabled(state, FEATURES.HAWKING_DEPLOYMENT) ||
    isFeatureEnabled(state, FEATURES.AUTHENTISE_PDM));

  const isStanleyXDeploymentFeatureEnabled = useSelector(state =>
    isFeatureEnabled(state, FEATURES.STANLEY_X_DEPLOYMENT));

  const isDigitalDesignWarehouseFeatureEnabled = useSelector(state => isFeatureEnabled(
    state, FEATURES.DIGITAL_DESIGN_WAREHOUSE,
  ));
  const isIntegrationCastorFeatureEnabled = useSelector(state => isFeatureEnabled(
    state, FEATURES.INTEGRATION_CASTOR,
  ));
  const isRobozeDdwFeatureEnabled = useSelector(state => isFeatureEnabled(
    state, FEATURES.ROBOZE_DDW,
  ));
  const isRobozeBureauOrderFieldsFeatureEnabled = useSelector(state => isFeatureEnabled(
    state,
    FEATURES.ROBOZE_GIGAFACTORY_BUREAU_ORDER_FIELDS,
  ));

  const isRestrictedUser = useSelector(isCurrentUserRestricted);

  const selected = {
    bureau,
    user,
    bureauUri,
    fetching,
    modelLibraries,
    modelsByUri,
    labelsByUri,
    materialsByUri,
    isHawkingDeploymentFeatureEnabled,
    isStanleyXDeploymentFeatureEnabled,
    isDigitalDesignWarehouseFeatureEnabled,
    isRestrictedUser,
    usersByUri,
    itemsForCurrentShoppingCart,
    shoppingCarts,
    shoppingCartItems,
  };

  const dispatch = useDispatch();

  const [paginationState, setPaginationState] = useState({
    pageLimitValues: [15, 30, 60, 90],
    pageLimit: 30,
    offset: 0,
    activePage: 0,
    totalModelLibraries: 0,
  });

  /* Query for shopping carts that fetches only owned by current user and that are `empty` or `in-progress` */
  const queryShoppingCartsOwnedByCurrentUserFilter = {
    owner: user.uri,
    status: ['empty', 'in-progress'],
  };

  const [searchParams] = useSearchParams();
  const [search, setSearch] = useState('');
  const [debouncedSearch, setDebouncedSearch] = useState('');
  const [toggleAscDesc, setToggleAscDesc] = useState(true);
  const [sortValue, setSortValue] = useState('');
  const [typeFilter, setTypeFilter] = useState(props.typeFilter);
  const [labelFilter, setLabelFilter] = useState(props.labelFilter);
  const [showViewCartModal, setShowViewCartModal] = useState(false);
  const uuid = searchParams.get('uuid');

  const filteredModelLibraries = useMemo(() => {
    const normalizedSearch = search.normalize().toLowerCase();
    const searchRegex = getFilterRegex(normalizedSearch);
    const isFilterApplied = debouncedSearch || typeFilter || labelFilter;
    if (!isFilterApplied) return modelLibraries;
    return modelLibraries.filter(model => {
      const { name,
        created,
        type,
        labels,
        notes,
        custom_field_values,
        workflow,
        updated } = model;

      const {
        layer_thickness,
        base_material,
        support_material,
      } = model.additive;

      const matchesLabel = !labelFilter || labels.includes(labelFilter);
      const matchesType = !typeFilter || type === typeFilter;

      const matchesSearchByName = searchRegex.test(name?.normalize().toLowerCase());

      const matchesSearchByWorkflow =
        searchRegex.test(workflowsByUri[workflow]?.name.normalize().toLowerCase());

      const matchesSearchByBaseMaterial =
        searchRegex.test(materialsByUri[base_material]?.name.normalize().toLowerCase());

      const matchesSearchByLabel =
        labels?.some(label => (searchRegex.test(labelsByUri[label]?.name.normalize().toLowerCase())));

      const matchesSearchBySupportMaterial =
       searchRegex.test(materialsByUri[support_material]?.name.normalize().toLowerCase());

      const matchesSearchByCustomFieldValues =
        custom_field_values?.some(({ value }) => searchRegex.test(value?.normalize().toLowerCase()));

      const matchesSearchByNotes = searchRegex.test(notes?.normalize().toLowerCase()) && notes !== null;

      const matchesSearchByLayerThickness = searchRegex.test(String(layer_thickness).normalize().toLowerCase());

      // // Format the created date as 'YYYY-MM' and match against the search term
      const matchesSearchByCreatedMonth = dayjs(created?.normalize()).format('YYYY-MM').includes(normalizedSearch);

      // // Format the created date as 'YYYY-MM-DD' and match against the search term
      const matchesSearchByCreatedDate = dayjs(created?.normalize()).format('YYYY-MM-DD').includes(normalizedSearch);

      // // Format the updated date as 'YYYY-MM' and match against the search term
      const matchesSearchByUpdatedMonth = dayjs(updated?.normalize()).format('YYYY-MM').includes(normalizedSearch);

      // // Format the updated date as 'YYYY-MM-DD' and match against the search term
      const matchesSearchByUpdatedDate = dayjs(updated?.normalize()).format('YYYY-MM-DD').includes(normalizedSearch);

      const matchesFilteredModelComments =
        modelLibraryCommentsByModelUUID[extractUuid(model.uri)];

      const returnModel = matchesLabel && matchesType &&
        (
          matchesSearchByName
          || matchesSearchByLayerThickness
          || matchesSearchByCreatedMonth
          || matchesSearchByCreatedDate
          || matchesSearchByUpdatedMonth
          || matchesSearchByUpdatedDate
          || matchesSearchByNotes
          || matchesSearchByCustomFieldValues
          || matchesSearchByBaseMaterial
          || matchesSearchBySupportMaterial
          || matchesSearchByWorkflow
          || matchesSearchByLabel
          || matchesFilteredModelComments
        );
      return returnModel;
    });
  }, [debouncedSearch, typeFilter, labelFilter, _map(modelLibraries, 'uri').join('')]);

  const [sortedModelLibraries, setSortedModelLibraries] = useState(filteredModelLibraries);

  const modelsToDisplay = sortedModelLibraries.slice(
    paginationState.activePage * paginationState.pageLimit,
    (paginationState.activePage + 1) * paginationState.pageLimit,
  );

  const totalPaginatedPages =
  Math.ceil((search ? sortedModelLibraries.length : paginationState.totalModelLibraries) / paginationState.pageLimit);

  const fetchData = currentBureauUri => {
    // Show Model Libraries for any owner when ownerUri is empty
    dispatch(Actions.Api.nautilus[API_RESOURCES.MODEL_LIBRARY].clear('list'));
    const filters = ownerUri ? { owner: ownerUri } : {};
    dispatch(Actions.Api.nautilus[API_RESOURCES.LABEL].list());
    dispatch(Actions.Api.nautilus[API_RESOURCES.MATERIAL].list({ bureau: currentBureauUri },
      { limit: PAGINATION_IGNORE_DEFAULT_LIMIT }));
    dispatch(Actions.Api.nautilus[API_RESOURCES.USERS].list());
    loadModelLibrariesWithModels(
      dispatch,
      filters,
      paginationState.totalModelLibraries || PAGINATION_IGNORE_DEFAULT_LIMIT,
      0,
      modelData => setPaginationState({
        ...paginationState,
        totalModelLibraries: modelData.meta.count,
        activePage: paginationState.activePage >= totalPaginatedPages && paginationState.activePage !== 0 ?
          totalPaginatedPages - 1 :
          paginationState.activePage,
      }),
    );
  };

  useEffect(() => {
    if (
      isDigitalDesignWarehouseFeatureEnabled &&
      isRobozeBureauOrderFieldsFeatureEnabled &&
      isIntegrationCastorFeatureEnabled &&
      filteredModelLibraries.length > 0
    ) {
      loadCastorCostingConfigs(dispatch, _map(filteredModelLibraries, 'uri'));
    }
  }, [
    filteredModelLibraries,
    isDigitalDesignWarehouseFeatureEnabled,
    isRobozeBureauOrderFieldsFeatureEnabled,
    isIntegrationCastorFeatureEnabled,
    dispatch,
  ]);

  const fetchDataWithFilters = type => {
    dispatch(Actions.Api.nautilus[API_RESOURCES.MODEL_LIBRARY].clear('list'));

    loadModelLibrariesWithModels(
      dispatch,
      { type },
      paginationState.totalModelLibraries || PAGINATION_IGNORE_DEFAULT_LIMIT,
      0,
      modelData => setPaginationState({
        ...paginationState,
        totalModelLibraries: modelData.meta.count,
        activePage: paginationState.activePage >= totalPaginatedPages && paginationState.activePage !== 0 ?
          totalPaginatedPages - 1 :
          paginationState.activePage,
      }),
    );
  };

  const fetchCommentsforModelLibrary = (text = '') => {
    dispatch(Actions.Api.nautilus[API_RESOURCES.COMMENT].clear());
    dispatch(Actions.Api.nautilus[API_RESOURCES.COMMENT].list({ text_ilike: true, related_table_name: 'model_library', text }));
  };

  useEffect(() => {
    if (typeFilter) {
      // if we set some filter manually (specimen or product) -> get data from BE related to this filter only
      if (debouncedSearch) {
        fetchDataWithFilters(typeFilter);
        fetchCommentsforModelLibrary(debouncedSearch);
        return;
      }
      fetchDataWithFilters(typeFilter);
      fetchCommentsforModelLibrary(search);
    } else {
      // if no filter is set -> fetch all data and track the pagination
      if (debouncedSearch) {
        fetchData(bureauUri, ownerUri);
        fetchCommentsforModelLibrary(debouncedSearch);
        return;
      }
      fetchData(bureauUri, ownerUri);
      fetchCommentsforModelLibrary(search);
    }
  }, [
    typeFilter,
    paginationState.pageLimit,
    paginationState.offset,
    paginationState.activePage,
    ownerUri,
    debouncedSearch,
  ]);

  const onInitialize = async bureauUri => {
    dispatch(Actions.Api.nautilus[API_RESOURCES.WORKFLOW].list());
    dispatch(Actions.Api.nautilus[API_RESOURCES.SHIPPING].list());
    dispatch(Actions.Api.nautilus[API_RESOURCES.MATERIAL].list(bureauUri ? { bureau: bureauUri } : {}));
  };

  useEffect(() => {
    onInitialize(bureauUri);
  }, [bureauUri]);

  const handleSortModelLibraries = sortBy => {
    if (sortValue !== sortBy) {
      setToggleAscDesc(true);
      setSortValue(sortBy.includes('-') ? sortBy.replace('-', '') : sortBy);
    }

    const sort = toggleAscDesc ? sortBy : `-${sortBy}`;

    switch (sort) {
      case 'name':
      case '-name':
        return setSortedModelLibraries(
          orderBy([...filteredModelLibraries], [modelLibrary => modelLibrary.name.toLowerCase()], [toggleAscDesc ? 'asc' : 'desc']));
      case 'updated':
      case '-updated':
        return setSortedModelLibraries(
          orderBy([...filteredModelLibraries], ['updated'], [toggleAscDesc ? 'asc' : 'desc']));
      case 'created':
      case '-created':
        return setSortedModelLibraries(
          orderBy([...filteredModelLibraries], ['created'], [toggleAscDesc ? 'asc' : 'desc']));
      default:
        return setSortedModelLibraries(filteredModelLibraries);
    }
  };

  const handleDeleteCartItem = async shoppingCartItemUri => {
    await dispatch(Actions.Api.nautilus[API_RESOURCES.SHOPPING_CART_ITEM].delete(
      extractUuid(shoppingCartItemUri),
    ));
  };

  const onInitializeModelLibraries = () => {
    /* Calling `/model-library` again as a select number of libraries are retrieved dependent on which
    DDW library is selected see (`company`, `my library`, `administrator`), we want to get all model-libraries
    indiscriminately. */

    const cartModelLibrariesUris = _uniq(_compact(_map(itemsForCurrentShoppingCart, 'source')));
    const modelLibraryFilter = cartModelLibrariesUris.length ? { uri: cartModelLibrariesUris } : {};

    return dispatch(Actions.Api.nautilus[API_RESOURCES.MODEL_LIBRARY].list(
      modelLibraryFilter, {}, {}, {}, true),
    )
      .then(
        modelLibraryResponse => {
          const modelLibraryObjects = modelLibraryResponse.json.resources;
          const modelUris = _uniq(_compact(_map(modelLibraryObjects, 'additive.model')));
          loadModelsInChunks(dispatch, modelUris);
        });
  };

  const onInitializeShoppingCarts = async () => {
    /* Get shopping carts based on filters above */
    const shoppingCartsResponse = await dispatch(Actions.Api.nautilus[API_RESOURCES.SHOPPING_CART].list(
      queryShoppingCartsOwnedByCurrentUserFilter, {}, {}, {}, true,
    ));
    const shoppingCarts = shoppingCartsResponse?.json?.resources;
    const shoppingCartUris = _map(shoppingCarts, 'uri');

    if (shoppingCarts?.length > 0) {
      /* Get shopping cart items for shopping carts fetched above */
      await dispatch(Actions.Api.nautilus[API_RESOURCES.SHOPPING_CART_ITEM]
        .list({ shopping_cart: shoppingCartUris }, {}, {}, {}, true));
    }
  };

  useEffect(() => {
    if (isDigitalDesignWarehouseFeatureEnabled) onInitializeShoppingCarts();
  }, []);

  const refreshShoppingCartsAndItems = async () => {
    // Clear existing shopping-cart and shopping-cart-item data.
    await dispatch(Actions.Api.nautilus[API_RESOURCES.SHOPPING_CART].clear());
    await dispatch(Actions.Api.nautilus[API_RESOURCES.SHOPPING_CART_ITEM].clear());

    // Fetch the shopping carts again to finish the refresh.
    await onInitializeShoppingCarts();
  };

  const handleSaveShoppingCartOrder = async shoppingCartUri => {
    const shoppingCartResponse = await dispatch(Actions.Api.nautilus[API_RESOURCES.SHOPPING_CART].put(
      extractUuid(shoppingCartUri), {
        status: BUILD_STATUS.COMPLETE,
      }));

    window.location = isRestrictedUser ?
      getRouteURI(ROUTES.ORDER_RESTRICTED_EDIT, { uuid: extractUuid(shoppingCartResponse.headers.location) }) :
      getRouteURI(ROUTES.ORDER_EDIT, { uuid: extractUuid(shoppingCartResponse.headers.location) });

    /* prevent users from still interacting with shopping-carts of status `complete` when navigating back
    to the digital-design-warehouse dashboard. */
    refreshShoppingCartsAndItems();
  };

  const handleSaveCart = async (shoppingCartUri, updatedShoppingCartItems, updatedValues) => {
    const cartItemOmittedKeys = ['uri', 'destination', 'source', 'shopping_cart', 'price_per', 'material_override', 'estimator'];

    // Check the updated shopping-cart data is different from existing data
    if (!_isEqual(shoppingCart.order_name, updatedValues.order_name)) {
      dispatch(Actions.Api.nautilus[API_RESOURCES.SHOPPING_CART].put(
        extractUuid(shoppingCartUri),
        { name: updatedValues.order_name },
      ));
    }

    // Check the updated shopping-cart-items are different from our existing ones
    if (!_isEqual(itemsForCurrentShoppingCart, updatedShoppingCartItems)) {
      const updatedShoppingCartItemPromises = _map(updatedShoppingCartItems, cartItem => (
        dispatch(Actions.Api.nautilus[API_RESOURCES.SHOPPING_CART_ITEM].put(
          extractUuid(cartItem.uri),
          // All in JSON data except omitted keys (which currently, is only `quantity`)
          _omit(cartItem, cartItemOmittedKeys),
        ))
      ));

      await Promise.all(updatedShoppingCartItemPromises);
    }
  };

  const handleAbandonCart = async shoppingCartUri => {
    await dispatch(Actions.Api.nautilus[API_RESOURCES.SHOPPING_CART].put(
      extractUuid(shoppingCartUri),
      { status: 'abandoned' },
    ));

    // Perform a refresh, to prevent users from still interacting with shopping-carts of status `abandoned`.
    refreshShoppingCartsAndItems();

    // Then close modal to finish off.
    setShowViewCartModal(false);
  };

  useEffect(() => {
    handleSortModelLibraries(sortValue);
  }, [filteredModelLibraries, sortValue, toggleAscDesc]);

  const handleDebouncedSearch = _debounce(value => setDebouncedSearch(value), 1000);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const debouncedSetSearch = useCallback(handleDebouncedSearch, []);

  useEffect(() => {
    debouncedSetSearch(search);
  }, [search, debouncedSetSearch]);

  return (
    <>
      <ViewCartModal
        isDigitalDesignWarehouseFeatureEnabled={isDigitalDesignWarehouseFeatureEnabled}
        isIntegrationCastorFeatureEnabled={isIntegrationCastorFeatureEnabled}
        isRobozeDdwFeatureEnabled={isRobozeDdwFeatureEnabled}
        isRobozeBureauOrderFieldsFeatureEnabled={isRobozeBureauOrderFieldsFeatureEnabled}
        materials={materials}
        shoppingCart={shoppingCart}
        shoppingCartItems={itemsForCurrentShoppingCart}
        modelsByUri={modelsByUri}
        labelsByUri={labelsByUri}
        show={showViewCartModal}
        onClose={() => setShowViewCartModal(false)}
        handleSaveCartOrder={handleSaveShoppingCartOrder}
        initialFormValues={{ order_name: shoppingCart?.name }}
        modelLibraries={modelLibraries}
        isFetchingShoppingCart={isFetchingShoppingCart}
        handleDeleteCartItem={handleDeleteCartItem}
        handleAbandonCart={handleAbandonCart}
        handleSaveCart={handleSaveCart}
      />
      <ModelLibraries
        {...props}
        {...selected}
        search={search}
        typeFilter={typeFilter}
        labelFilter={labelFilter}
        modelLibraries={modelsToDisplay}
        handleSearchChange={setSearch}
        handleTypeFilterChange={setTypeFilter}
        handleLabelFilterChange={setLabelFilter}
        pagination={{ ...paginationState, setPaginationState, totalPaginatedPages }}
        handleSortModelLibraries={handleSortModelLibraries}
        sortValue={sortValue}
        toggleAscDesc={setToggleAscDesc}
        ascDescValue={toggleAscDesc}
        onInitializeModelLibraries={onInitializeModelLibraries}
        viewCartModalState={[setShowViewCartModal]}
        isDebugModeEnabled={isDebugModeEnabled}
      />
      {uuid && (
        <ModelLibraryContainer
          {...selected}
          uuid={uuid}
          onInitializeShoppingCarts={onInitializeShoppingCarts}
        />
      )}
    </>
  );
};

ModelLibrariesByFiltersContainer.defaultProps = {
  showHeader: true,
  typeFilter: null,
  labelFilter: null,
  queryParams: {
    uuid: null,
  },
  showModelDropZone: true,
  ownerUri: null,
  customLibraryName: null,
};

ModelLibrariesByFiltersContainer.propTypes = {
  modelLibraries: PropTypes.arrayOf(PropTypes.shape({
    type: PropTypes.oneOf(Object.values(MODEL_LIBRARY_TYPES)).isRequired,
  })).isRequired,
  showHeader: PropTypes.bool,
  typeFilter: PropTypes.oneOf(Object.values(MODEL_LIBRARY_TYPES)),
  labelFilter: PropTypes.string,
  queryParams: PropTypes.shape({
    uuid: PropTypes.string,
  }),
  showModelDropZone: PropTypes.bool,
  ownerUri: PropTypes.string,
  shippings: PropTypes.arrayOf(PropTypes.shape({
    uri: PropTypes.string,
  })).isRequired,
  materials: PropTypes.arrayOf(PropTypes.shape({
    uri: PropTypes.string,
  })).isRequired,
  customLibraryName: PropTypes.string,
};

export default ModelLibrariesByFiltersContainer;
