import { useRef, useEffect } from 'react';

/**
 * @description Simply returns the previous value used, relative to re-render or re-call.
 *
 * @param {any} value Value you wish to cache
 * @returns {any}
 */
const usePrevious = value => {
  const ref = useRef();
  useEffect(() => {
    ref.current = value;
  }, [value]);
  return ref.current;
};

export default usePrevious;
