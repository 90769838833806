import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import {
  FormGroup,
  FormLabel,
  FormControl,
  Button,
  Modal,
} from 'react-bootstrap';
import { SketchPicker } from 'react-color';
import Loading from 'rapidfab/components/Loading';
import SaveButtonTitle from 'rapidfab/components/SaveButtonTitle';
import { finalFormInputTypes } from 'rapidfab/types';
import 'rapidfab/styles/hawking/main.scss';

import { Form, Field } from 'react-final-form';

const LabelModal = ({
  onClose,
  uri,
  onCreateLabel,
  onUpdateLabel,
  isHawkingUser,
  initialFormValues,
}) => {
  const [isSaving, setIsSaving] = useState(false);

  const onSubmit = async payload => {
    setIsSaving(true);
    try {
      if (uri) {
        await onUpdateLabel(uri, payload);
      } else {
        await onCreateLabel(payload);
      }
      setIsSaving(false);
      onClose();
    } catch {
      setIsSaving(false);
    }
  };

  return (
    <Modal size="lg" show onHide={onClose} backdrop="static">
      <Modal.Header closeButton>
        <Modal.Title>
          {uri
            ? <FormattedMessage id="label.edit" defaultMessage="Edit Label" />
            : <FormattedMessage id="label.add" defaultMessage="Add Label" />}
        </Modal.Title>
      </Modal.Header>
      <Form
        onSubmit={onSubmit}
        initialValues={initialFormValues}
        render={({ handleSubmit }) => (
          <form onSubmit={handleSubmit}>
            <Modal.Body>
              <FormGroup className="form-group" controlId="labelName">
                <FormLabel>
                  <FormattedMessage
                    id="field.name"
                    defaultMessage="Name"
                  />: *
                </FormLabel>
                <Field
                  name="name"
                  initialValue={initialFormValues?.name}
                  render={props => (
                    <FormControl
                      {...props.input}
                      required
                    />
                  )}
                />
              </FormGroup>
              <FormGroup className="form-group" controlId="labelDescription">
                <FormLabel>
                  <FormattedMessage
                    id="field.description"
                    defaultMessage="Description"
                  />:
                </FormLabel>
                <Field
                  name="description"
                  render={props => (
                    <FormControl
                      as="textarea"
                      {...props.input}
                    />
                  )}
                />
              </FormGroup>
              <FormGroup className="form-group" controlId="labelColor">
                <FormLabel>
                  <FormattedMessage
                    id="field.color"
                    defaultMessage="Color"
                  />: *
                </FormLabel>
                <Field
                  name="color"
                  render={props => (
                    <SketchPicker
                      width={250}
                      presetColors={[]}
                      color={props.input.value}
                      disableAlpha
                      onChangeComplete={({ hex }) => {
                        // color field stored as hex in color field
                        props.input.onChange(hex);
                      }}
                    />
                  )}
                />
              </FormGroup>
            </Modal.Body>
            <Modal.Footer>
              <Button onClick={onClose} variant="default">
                <FormattedMessage id="button.cancel" defaultMessage="Cancel" />
              </Button>
              <Button variant={!isHawkingUser && 'success'} className={isHawkingUser && 'hawking-primary'} type="submit" disabled={isSaving}>
                {isSaving ? <Loading /> : <SaveButtonTitle />}
              </Button>
            </Modal.Footer>
          </form>
        )}
      />
    </Modal>
  );
};

LabelModal.defaultProps = {
  uri: null,
};

LabelModal.propTypes = {
  onClose: PropTypes.func.isRequired,
  initialFormValues: PropTypes.shape({
    color: PropTypes.string.isRequired,
    description: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
  }).isRequired,
  onCreateLabel: PropTypes.func.isRequired,
  onUpdateLabel: PropTypes.func.isRequired,
  uri: PropTypes.string,
  isHawkingUser: PropTypes.bool.isRequired,
  input: finalFormInputTypes.isRequired,
};

export default LabelModal;
