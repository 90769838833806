import React from 'react';
import PropTypes from 'prop-types';

import { Nav, NavItem, NavLink } from 'react-bootstrap';
import { FEATURES, ROUTES } from 'rapidfab/constants';
import { getRouteURI } from 'rapidfab/utils/uriUtils';
import Feature from 'rapidfab/components/Feature';

const WorkflowTabs = ({ activeKey }) => (
  <Feature featureName={FEATURES.PREP_WORKFLOW}>
    <Nav
      variant="tabs"
      activeKey={activeKey}
      // `undefined` is needed to allow `href` in NavItem-s
      onSelect={undefined}
      id="commentsTabs"
    >
      <NavItem>
        <NavLink eventKey="production-workflows" href={getRouteURI(ROUTES.PRODUCTION_WORKFLOW_LIST)}>
          Production Workflows
        </NavLink>
      </NavItem>
      <NavItem>
        <NavLink eventKey="prep-workflows" href={getRouteURI(ROUTES.PREP_WORKFLOW_LIST)}>
          Prep Workflows
        </NavLink>
      </NavItem>
    </Nav>
  </Feature>
);

WorkflowTabs.propTypes = {
  activeKey: PropTypes.oneOf(['production-workflows', 'prep-workflows']).isRequired,
};

export default WorkflowTabs;
