import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { USER_SETTINGS_KEYS, PAGINATION_IGNORE_DEFAULT_LIMIT, API_RESOURCES } from 'rapidfab/constants';
import { Container, Button, Col, Row } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { FormattedMessage } from 'rapidfab/i18n';
import Tour from 'rapidfab/components/Tour';
import Locations from 'rapidfab/components/locations';
import BreadcrumbNav from 'rapidfab/components/BreadcrumbNav';
import Actions from 'rapidfab/actions';
import { connect } from 'react-redux';
import { faRefresh } from '@fortawesome/free-solid-svg-icons';
import GanttChart from './GanttChart';

const tourSteps = [
  {
    target: '.step-1',
    content: <FormattedMessage id="tour.queue.step1" defaultMessage="Welcome to our updated Queues page! Try clicking table headings to zoom in and out of the table." />,
  },
];

class Queues extends Component {
  constructor(props) {
    super(props);

    this.handleScheduleRuns = this.handleScheduleRuns.bind(this);
    this.onCompleteQueuesTour = this.onCompleteQueuesTour.bind(this);
  }

  handleScheduleRuns() {
    const { runs } = this.props;
    if (runs.length === 0) {
      return;
    }
    const run = runs[0];
    this.props.refresh();
    this.props.handleScheduleRuns(run.uri);
  }

  onCompleteQueuesTour() {
    const { settings, onUserSettingsUpdate } = this.props;
    onUserSettingsUpdate({
      ...settings,
      [USER_SETTINGS_KEYS.QUEUES_TOUR_COMPLETE]: true,
    });
  }

  render() {
    const {
      machines,
      events,
      buildsByRunUri,
      runsByUri,
      settings,
      downtimesByUri,
      workSchedulesByPostProcessorType,
      workSchedulesByPrinterType,
      isGeneralMFGLanguageEnabled,
      isDebugModeEnabled,
      defaultStartTime,
      defaultEndTime,
      handleScroll,
      scheduledRuns,
      isJeniClusterFeatureEnabled,
      customFieldsByUri,
    } = this.props;

    return (
      <Container fluid>
        <BreadcrumbNav breadcrumbs={['track', 'queues']} />
        <div className="step-1" />
        <Tour
          steps={tourSteps}
          hide={settings[USER_SETTINGS_KEYS.QUEUES_TOUR_COMPLETE]}
          onClose={this.onCompleteQueuesTour}
        />
        <Row>
          <Col xs={4}>
            <Locations />
          </Col>
          <Col xs={8} style={{ display: 'flex', justifyContent: 'flex-end' }}>
            <Button variant="primary" size="sm" onClick={this.handleScheduleRuns}>
              <FontAwesomeIcon icon={faRefresh} />
            </Button>
          </Col>
        </Row>
        <Row>
          <Col xs={12} className="mt15">
            <GanttChart
              events={events}
              machines={machines}
              buildsByRunUri={buildsByRunUri}
              runsByUri={runsByUri}
              downtimesByUri={downtimesByUri}
              workSchedulesByPostProcessorType={workSchedulesByPostProcessorType}
              workSchedulesByPrinterType={workSchedulesByPrinterType}
              isGeneralMFGLanguageEnabled={isGeneralMFGLanguageEnabled}
              isDebugModeEnabled={isDebugModeEnabled}
              defaultStartTime={defaultStartTime}
              defaultEndTime={defaultEndTime}
              handleGanttScroll={handleScroll}
              scheduledRuns={scheduledRuns}
              isJeniClusterFeatureEnabled={isJeniClusterFeatureEnabled}
              customFieldsByUri={customFieldsByUri}
            />
          </Col>
        </Row>
      </Container>
    );
  }
}

Queues.defaultProps = {
  settings: null,
};

Queues.propTypes = {
  events: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      resourceId: PropTypes.string,
      title: PropTypes.string,
      url: PropTypes.string,
      start: PropTypes.string,
      end: PropTypes.string,
      backgroundColor: PropTypes.string,
      borderColor: PropTypes.string,
    }),
  ).isRequired,
  handleScheduleRuns: PropTypes.func.isRequired,
  refresh: PropTypes.func.isRequired,
  runs: PropTypes.arrayOf(PropTypes.shape({
    uri: PropTypes.string,
  })).isRequired,
  runsByUri: PropTypes.objectOf(PropTypes.shape({})).isRequired,
  machines: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  buildsByRunUri: PropTypes.shape({}).isRequired,
  settings: PropTypes.shape({}),
  customFieldsByUri: PropTypes.shape({}).isRequired,
  onUserSettingsUpdate: PropTypes.func.isRequired,
  downtimesByUri: PropTypes.shape({}).isRequired,
  isGeneralMFGLanguageEnabled: PropTypes.bool.isRequired,
  workSchedulesByPostProcessorType: PropTypes.shape({}).isRequired,
  workSchedulesByPrinterType: PropTypes.shape({}).isRequired,
  isDebugModeEnabled: PropTypes.bool.isRequired,
  handleScroll: PropTypes.func.isRequired,
  defaultStartTime: PropTypes.shape({
    format: PropTypes.func,
  }).isRequired,
  defaultEndTime: PropTypes.shape({
    format: PropTypes.func,
  }).isRequired,
  scheduledRuns: PropTypes.shape([]).isRequired,
  isJeniClusterFeatureEnabled: PropTypes.bool.isRequired,
};

function mapDispatchToProps(dispatch) {
  return {
    refresh: () => {
      dispatch(Actions.Api.nautilus[API_RESOURCES.RUN].list(null, { limit: PAGINATION_IGNORE_DEFAULT_LIMIT }));
    },
  };
}

export default connect(null, mapDispatchToProps)(Queues);
