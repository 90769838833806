import _assign from 'lodash/assign';
import _groupBy from 'lodash/groupBy';
import { createSelector } from 'reselect';
import { getRuns } from 'rapidfab/selectors/run';
import { getOrders } from 'rapidfab/selectors/order';
import { getLocationFilter } from 'rapidfab/selectors/locationFilter';
import { LOCATION_FILTER_DEFAULT_VALUES, ORDER_STATUS } from 'rapidfab/constants';
import _filter from 'lodash/filter';

export const getRunsForLocation = createSelector(
  [getRuns, getLocationFilter],
  (runs, locationFilter) => {
    if (
      typeof locationFilter === 'undefined'
      || locationFilter === LOCATION_FILTER_DEFAULT_VALUES.ALL
    ) {
      return runs;
    }

    return _filter(runs, { location: locationFilter });
  },
);

export const getOrdersForLocation = createSelector(
  [getOrders, getLocationFilter],
  (orders, locationFilter) => {
    if (
      typeof locationFilter === 'undefined'
      || locationFilter === LOCATION_FILTER_DEFAULT_VALUES.ALL
    ) {
      return orders;
    }

    return _filter(orders, { location: locationFilter });
  },
);

const filterRecentOrders = orders => (
  orders.filter(order => {
    const orderDate = new Date(order.created);
    const today = new Date();
    const diffTime = Math.abs(today - orderDate);
    const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
    return diffDays < 60;
  })
);

// get orders that newer than 60 days
export const getRecentOrdersForLocation = createSelector(
  [getOrders, getLocationFilter],
  (orders, locationFilter) => {
    if (
      typeof locationFilter === 'undefined'
      || locationFilter === LOCATION_FILTER_DEFAULT_VALUES.ALL
    ) {
      return filterRecentOrders(orders);
    }

    return filterRecentOrders(_filter(orders, { location: locationFilter }));
  },
);

export const getRunStatusChartDataForLocation = createSelector(
  [getRunsForLocation],
  runs => {
    const groupedByStatus = _assign(
      {
        calculating: [],
        calculated: [],
        'in-progress': [],
        queued: [],
        error: [],
        complete: [],
      },
      _groupBy(runs, 'status'),
    );
    return [
      groupedByStatus.calculating.length + groupedByStatus.calculated.length,
      groupedByStatus.queued.length,
      groupedByStatus['in-progress'].length,
      groupedByStatus.error.length,
      groupedByStatus.complete.length,
    ];
  },
);

export const getOrderStatusChartDataForLocation = createSelector(
  [getRecentOrdersForLocation],
  orders => {
    const groupedByStatus = _assign(
      {
        [ORDER_STATUS.CALCULATING_ESTIMATES]: [],
        [ORDER_STATUS.CANCELLED]: [],
        [ORDER_STATUS.CLONING]: [],
        [ORDER_STATUS.CONFIRMED]: [],
        [ORDER_STATUS.COMPLETE]: [],
        [ORDER_STATUS.ERROR]: [],
        [ORDER_STATUS.IN_PREPARATION]: [],
        [ORDER_STATUS.NEW]: [],
        [ORDER_STATUS.PENDING]: [],
        [ORDER_STATUS.PROCESSING]: [],
        [ORDER_STATUS.POST_PROCESSING]: [],
        [ORDER_STATUS.PRINTING]: [],
        [ORDER_STATUS.SHIPPING]: [],
      },
      _groupBy(orders, 'status'),
    );

    return [
      groupedByStatus[ORDER_STATUS.CALCULATING_ESTIMATES].length,
      groupedByStatus[ORDER_STATUS.CANCELLED].length,
      groupedByStatus[ORDER_STATUS.CLONING].length,
      groupedByStatus[ORDER_STATUS.CONFIRMED].length,
      groupedByStatus[ORDER_STATUS.COMPLETE].length,
      groupedByStatus[ORDER_STATUS.ERROR].length,
      groupedByStatus[ORDER_STATUS.IN_PREPARATION].length,
      groupedByStatus[ORDER_STATUS.NEW].length,
      groupedByStatus[ORDER_STATUS.PENDING].length,
      groupedByStatus[ORDER_STATUS.PROCESSING].length,
      groupedByStatus[ORDER_STATUS.POST_PROCESSING].length,
      groupedByStatus[ORDER_STATUS.PRINTING].length,
      groupedByStatus[ORDER_STATUS.SHIPPING].length,
    ];
  },
);
