import { navbarDA } from './locales/navbar/index';
import { recordsDA } from './locales/records/index';
import { mappingsDA } from './locales/mappings/index';
import { componentsDA } from './locales/components/index';

import { planDA } from './locales/plan/index';
import adminDA from './locales/admin/da.json';
import workDA from './locales/work/da.json';
import inventoryDA from './locales/inventory/da.json';
import manageDA from './locales/organize/da.json';
import mfg from './locales/mfg.json';
import toasterDA from './locales/toaster/da.json';
import restDA from './locales/rest/da.json';

const translation = Object.assign(
  adminDA,
  navbarDA,
  planDA,
  workDA,
  inventoryDA,
  manageDA,
  recordsDA,
  mappingsDA,
  componentsDA,
  mfg,
  toasterDA,
  restDA,
);

export default translation;
