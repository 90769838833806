import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { extractUuid } from 'rapidfab/reducers/makeApiReducers';
import { getUUIDResource } from 'rapidfab/selectors';
import { getEndpointFromURI } from 'rapidfab/utils/uriUtils';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faExternalLink } from '@fortawesome/free-solid-svg-icons';

const ResourceLink = ({ location, name, linkIconOnly }) => (
  <a href={location}>{linkIconOnly ? <FontAwesomeIcon icon={faExternalLink} /> : name}</a>
);

ResourceLink.defaultProps = {
  linkIconOnly: false,
};

ResourceLink.propTypes = {
  location: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  linkIconOnly: PropTypes.bool,
  // Used by mapStateToProps() only  ¯\_(ツ)_/¯
  // eslint-disable-next-line react/no-unused-prop-types
  uri: PropTypes.string.isRequired,
};

const mapStateToProps = (state, ownProps) => {
  const { uri, name } = ownProps;
  const uuid = extractUuid(uri);
  const resource = getUUIDResource(state, uuid);
  const { endpointName } = getEndpointFromURI(uri);
  const location = `/#/records/${endpointName}/${uuid}`;

  let displayName = uri;

  if (resource) {
    displayName = resource.name;
  }

  if (name) {
    displayName = name;
  }
  return { location, name: displayName };
};

export default connect(mapStateToProps)(ResourceLink);
