import React from 'react';
import { faArrowDownWideShort, faChevronLeft, faChevronRight } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button } from 'react-bootstrap';
import { getLineItemsByUri, getWorkflowsByUri } from 'rapidfab/selectors';
import { useSelector } from 'react-redux';
import { getLineItemWorkflowTypeObjectKey } from 'rapidfab/utils/lineItemUtils';
import { capitalizeWords } from 'rapidfab/utils/stringUtils';
import TruncatedTitle from 'rapidfab/components/TruncatedTitle';
import { LINE_ITEM_STATUS_COLOR_CODE_MAPPING } from 'rapidfab/mappings';
import { TEXT_COLOR_CONTRAST } from 'rapidfab/constants';
import PropTypes from 'prop-types';
import ModelView from './ModelView';

const LineItemHeader = ({ lineItemUri, changeSelectedLineItem }) => {
  const lineItem = useSelector(getLineItemsByUri)[lineItemUri];
  const workflow = useSelector(getWorkflowsByUri)[lineItem.workflow];
  const workflowType = getLineItemWorkflowTypeObjectKey(lineItem);
  const lineItemWorkflow = lineItem[workflowType];

  const labelBgColor = LINE_ITEM_STATUS_COLOR_CODE_MAPPING[lineItem?.status];
  const labelTextColor = TEXT_COLOR_CONTRAST[labelBgColor];
  return (
    <div className="quoteProcessStepModalNoBorder list-group-quote resource-list-item-card">
      <div className="d-flex align-items-center justify-content-between mb15">
        <div className="d-flex align-items-center">
          <div className="line-item-quote-modal-model">
            <ModelView lineItemWorkflow={lineItemWorkflow} />
          </div>
          <div>
            <p className="mb5 font-weight-500">{lineItem?.name}</p>
            <div className="d-flex align-items-center mb5 font-weight-500">
              <span
                className="badge badge-sm"
                style={{
                  backgroundColor: labelBgColor,
                  color: labelTextColor,
                }}
              >
                {capitalizeWords(lineItem.status)}
              </span>
              <span className="spacer-left">
                Qty: {lineItem?.quantity}
              </span>
            </div>
            <div className="font-weight-500">
              <FontAwesomeIcon icon={faArrowDownWideShort} className="spacer-right" />
              <TruncatedTitle
                title={workflow?.name || 'N/A'}
                maxTextLength={48}
                inline
                titleClassName="text-white"
              />
            </div>

          </div>
        </div>

        <div>
          <Button
            className="line-item-quote-modal-line-item-btn mr10"
            disabled={!changeSelectedLineItem.hasPrev}
            onClick={changeSelectedLineItem.prev}
          >
            <FontAwesomeIcon icon={faChevronLeft} />
          </Button>
          <Button
            className="line-item-quote-modal-line-item-btn"
            disabled={!changeSelectedLineItem.hasNext}
            onClick={changeSelectedLineItem.next}
          >
            <FontAwesomeIcon icon={faChevronRight} />
          </Button>
        </div>
      </div>
    </div>
  );
};

LineItemHeader.propTypes = {
  lineItemUri: PropTypes.string.isRequired,
  changeSelectedLineItem: PropTypes.shape({
    hasNext: PropTypes.bool,
    hasPrev: PropTypes.bool,
    next: PropTypes.func,
    prev: PropTypes.func,
  }).isRequired,
};

export default LineItemHeader;
