import React, { useEffect, useMemo } from 'react';
import PropTypes from 'prop-types';
import { useSelector, useDispatch } from 'react-redux';
import Actions from 'rapidfab/actions';
import * as Selectors from 'rapidfab/selectors';
import { extractUuid } from 'rapidfab/utils/uuidUtils';
import { API_RESOURCES, FEATURES } from 'rapidfab/constants';
import WorkflowChecklistsModal from 'rapidfab/components/modals/WorkflowChecklistsModal';
import _map from 'lodash/map';
import _uniq from 'lodash/uniq';
import _filter from 'lodash/filter';
import _has from 'lodash/has';

const WorkflowChecklistsModalContainer = props => {
  const dispatch = useDispatch();

  const { workflowUri } = props;
  const { lineItemUri } = props;

  const fetching = useSelector(state =>
    state.ui.nautilus[API_RESOURCES.WORK_CHECKLISTS_FOR_RESOURCE].list.fetching
    || state.ui.nautilus[API_RESOURCES.WORK_INSTRUCTION_REPORT].list.fetching
    || state.ui.nautilus[API_RESOURCES.WORKFLOW].get.fetching
    || state.ui.nautilus[API_RESOURCES.WORKFLOW].put.fetching
    || state.ui.nautilus[API_RESOURCES.PROCESS_STEP].list.fetching
    || state.ui.nautilus[API_RESOURCES.POST_PROCESSOR].list.fetching
    || state.ui.nautilus[API_RESOURCES.PRINTER].list.fetching,
  );

  const prints = useSelector(Selectors.getPrints);
  const workflow = useSelector(state =>
    (workflowUri ? Selectors.getUUIDResource(state, extractUuid(workflowUri)) : null));
  const processSteps = useSelector(state =>
    Selectors.getProcessStepsForWorkflow(state, workflow));
  const workstationsByUri = useSelector(state =>
    Selectors.getWorkstationsByUri(state));
  const workChecklistLinkingsByLineItem = useSelector(state =>
    Selectors.getWorkChecklistLinkingsForResource(state, lineItemUri));
  const workChecklistLinkingsByWorkflow = useSelector(state =>
    Selectors.getWorkChecklistLinkingsForResource(state, workflowUri));
  const workChecklistLinkings = [...workChecklistLinkingsByLineItem, ...workChecklistLinkingsByWorkflow];
  const isGuidelineEngineFeatureEnabled = useSelector(state =>
    Selectors.isFeatureEnabled(state, FEATURES.GUIDELINES_ENGINE));
  const isDebugModeEnabled = useSelector(state =>
    Selectors.getIsDebugModeEnabled(state));
  const usersByUri = useSelector(state =>
    Selectors.getUsersByUri(state));
  const workInstructionsReports = useSelector(state =>
    Selectors.getWorkInstructionsReportsByWorkInstructionUUID(state));

  const selected = {
    fetching,
    workChecklistLinkings,
    processSteps,
    workflow,
    workstationsByUri,
    isGuidelineEngineFeatureEnabled,
    isDebugModeEnabled,
    usersByUri,
  };

  const onInitialize = (workflowUri, lineItemUri) => {
    dispatch(Actions.Api.nautilus[API_RESOURCES.WORK_CHECKLISTS_FOR_RESOURCE].list({
      related_uri: [lineItemUri, workflowUri],
    }));
    dispatch(Actions.Api.nautilus[API_RESOURCES.POST_PROCESSOR].list());
    dispatch(Actions.Api.nautilus[API_RESOURCES.PRINTER].list());
    if (workflowUri) {
      dispatch(Actions.Api.nautilus[API_RESOURCES.WORKFLOW].get(extractUuid(workflowUri)));
      dispatch(Actions.Api.nautilus[API_RESOURCES.PROCESS_STEP].list({ workflows: workflowUri }));
    }
  };

  const handleGetWorkInstructionsReports = prints => {
    const printsUris = _map(prints, 'uri');
    dispatch(Actions.Api.nautilus[API_RESOURCES.WORK_INSTRUCTION_REPORT]
      .list({ print: printsUris }));
  };

  const handleGetUsersForReportValues = userUris => {
    const filteredUris = _filter(userUris, uri => !_has(usersByUri, uri));

    if (filteredUris.length) {
      dispatch(Actions.Api.nautilus[API_RESOURCES.USERS].list({ uri: userUris }));
    }
  };

  useEffect(() => {
    if (prints.length) {
      handleGetWorkInstructionsReports(prints);
    }
  }, [JSON.stringify(prints)]);

  const reportValueUserUris = useMemo(() =>
    _uniq(_map(workInstructionsReports, 'user')), [workInstructionsReports]);

  useEffect(() => {
    if (reportValueUserUris.length) {
      handleGetUsersForReportValues(reportValueUserUris);
    }
  }, [JSON.stringify(reportValueUserUris)]);

  useEffect(() => {
    if (workflowUri && lineItemUri) {
      onInitialize(workflow?.uri ? workflowUri : null, lineItemUri);
    }
  }, [workflowUri, lineItemUri]);

  return (
    <WorkflowChecklistsModal
      {...props}
      {...selected}
      workInstructionsReports={workInstructionsReports}
    />
  );
};

WorkflowChecklistsModalContainer.defaultProps = {
  workflowUri: null,
  workflow: null,
};

WorkflowChecklistsModalContainer.propTypes = {
  workflowUri: PropTypes.string,
  lineItemUri: PropTypes.string.isRequired,
  onInitialize: PropTypes.func.isRequired,
  workflow: PropTypes.shape({
    uri: PropTypes.string.isRequired,
  }),
  guidelineSuggestionsForLineItem: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  workChecklistLinkings: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  handleGetWorkInstructionsReports: PropTypes.func.isRequired,
  handleGetUsersForReportValues: PropTypes.func.isRequired,
};

export default WorkflowChecklistsModalContainer;
