import React, { useState, useMemo } from 'react';
import PropTypes from 'prop-types';

import {
  Badge,
  Button, Card, Col,
  Form,
  FormControl,
  InputGroup, ListGroup, ListGroupItem,
  Modal, Row,
} from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import RCTooltip from 'rc-tooltip';

import {
  FormattedMessage,
  FormattedMessageMappingOption,
} from 'rapidfab/i18n';
import Config from 'rapidfab/config';
import { extractUuid } from 'rapidfab/reducers/makeApiReducers';
import { LINE_ITEM_STATUS_MAP } from 'rapidfab/mappings';
import {
  LINE_ITEM_STATUS,
  FEATURES,
  WORKFLOW_TYPES,
  WORKFLOW_USAGE_STATES,
  ROUTES, DOCUMENT_RELATED_TABLE_NAMES,
} from 'rapidfab/constants';
import { LINE_ITEM_STATUS_TRANSFORMATIONS } from 'rapidfab/transformations';
import getLineItemFormOptions from 'rapidfab/utils/lineItemFormOptions';

import _reject from 'lodash/reject';
import _find from 'lodash/find';
import Feature from 'rapidfab/components/Feature';
import FormRow from 'rapidfab/components/FormRow';
import VisibleFor from 'rapidfab/components/VisibleFor';
import { faExternalLink, faEye, faQuestionCircle, faSpinner, faTriangleExclamation } from '@fortawesome/free-solid-svg-icons';

import WorkPlanViewModalContainer from 'rapidfab/containers/records/order/WorkPlanViewModalContainer';
// eslint-disable-next-line import/no-named-as-default,import/no-named-as-default-member
import CustomFieldList from 'rapidfab/components/forms/CustomFieldList';
// import SelectSingle from 'rapidfab/components/forms/SelectSingle';
import getlineItemCustomFieldReferencesOverrides from 'rapidfab/utils/lineItemCustomFieldReferencesOverrides';
import getVisibleCustomFieldReferencesWithOptions from 'rapidfab/utils/getVisibleCustomFieldReferencesWithOptions';
import FormattedLocalizedCost from 'rapidfab/components/FormattedLocalizedCost';
import DeleteChecklistsConfirmationModal from 'rapidfab/components/modals/DeleteChecklistsConfirmationModal';
import { lineItemType, orderType } from 'rapidfab/types';
import Tooltip from 'rapidfab/components/Tooltip';
import { getRouteURI } from 'rapidfab/utils/uriUtils';
import SelectSingleLazy from 'rapidfab/components/forms/SelectSingleLazy';
import Loading from 'rapidfab/components/Loading';
import Documents from 'rapidfab/components/records/Documents';
import LineItemPiecesContainer from 'rapidfab/containers/records/order/LineItemPiecesContainer';
import SaveDropdownButton from './SaveDropdownButton';
import LineItemDuplicateModal from './LineItemDuplicateModal';

const MODALS = {
  WORKFLOW_CHANGE_CONFIRMATION: 'workflow-change-confirmation',
  VIEW_WORK_PLAN: 'view-work-plan',
  LINE_ITEM_DUPLICATE: 'line-item-duplicate',
};

const ResourceLink = ({ href }) => {
  const attributes = {};

  if (href.startsWith('http')) {
    attributes.target = '_blank';
    attributes.rel = 'noopener noreferrer';
  }

  return (
    <a href={href} {...attributes}>
      Go <FontAwesomeIcon icon={faExternalLink} />
    </a>
  );
};

ResourceLink.propTypes = { href: PropTypes.string.isRequired };

const LineItemPowderWorkflowEditForm = ({
  customFieldValues,
  customLineItemFieldReferences,
  customOrderFieldReferences,
  handleCloseCompleteWarningModal,
  handleInputChange,
  isLineItemSubmitting,
  isLineItemDuplicating,
  lineItem,
  itemName,
  modalTextPending,
  notes,
  // baseMaterial,
  // baseMaterialColor,
  // baseMaterialUrl,
  // baseMaterials,
  // baseMaterialsByLocationUri,
  onSubmit,
  onDuplicate,
  onReloadPrints,
  onReloadChecklistLinkings,
  onDelete,
  onCustomFieldChange,
  quantity,
  designHours,
  showCompleteWarningModal,
  status,
  submitConfirmModal,
  workflow,
  workflows,
  workChecklistLinkings,
  readOnly,
  isDebugModeEnabled,
  isCurrentUserRestricted,
  isUserRestricted,
  order,
  onFetchMoreWorkflows,
  isGuidelineEngineFeatureEnabled,
  guidelineSuggestionsForLineItem,
  fetchingGuidelineSuggestionsForLineItem,
  fetchingProcessSteps,
  anyPiecesHaveModifiedWorkflow,
  isAdditiveWorkflowFeatureEnabled,
  isPowderWorkflowFeatureEnabled,
  workflowTypeEstimatesKey,
  isPiecesOrWorkflowfetching,
  piecesWithModifiedWorkflowByWorkflowURI,
  carpenterTableData,
}) => {
  const [visibleModal, setVisibleModal] = useState(null);
  const [modalType, setModalType] = useState('');

  const isReadOnly = typeof readOnly === 'function' ? readOnly(lineItem) : readOnly;
  const statusOptions = LINE_ITEM_STATUS_TRANSFORMATIONS[lineItem.status];

  const workInstructionsExist = workChecklistLinkings.length > 0;

  const nonSpecimenWorkflows = _reject(workflows, ['type', WORKFLOW_TYPES.SPECIMEN]);
  const usedWorkflow = _find(workflows, { uri: lineItem.workflow });
  const isCustomWorkflow = usedWorkflow && usedWorkflow.line_item !== null;

  const onCloseWorkPlanViewModal = () => {
    onReloadPrints(order.uri);
    onReloadChecklistLinkings();
    setVisibleModal(null);
  };

  const onCloseDuplicateModal = () => setVisibleModal(null);

  const onDeleteChecklistsAndSubmit = () => {
    onSubmit();
    setVisibleModal(null);
  };

  const handleSubmit = event => {
    event.preventDefault();
    // When workflow was changed, and we have some custom checklists added - warn user
    if (workflow !== lineItem.workflow && workChecklistLinkings.length > 0) {
      setVisibleModal(MODALS.WORKFLOW_CHANGE_CONFIRMATION);
      return;
    }

    onSubmit();
  };

  const handleRenderSaveButton = () => {
    if (isReadOnly || (isUserRestricted && lineItem?.status === LINE_ITEM_STATUS.CONFIRMED)) {
      return null;
    }

    return (
      <SaveDropdownButton
        onSubmit={handleSubmit}
        onDelete={onDelete}
        onDuplicate={onDuplicate}
        resourceName="Item"
        actionModalProps={{
          modalType,
          setModalType,
        }}
        disabled={isLineItemSubmitting || isLineItemDuplicating}
      />
    );
  };

  const workflowTypeInitialValue = useMemo(() => {
    if (isAdditiveWorkflowFeatureEnabled) return 'additiveWorkflow';
    if (isPowderWorkflowFeatureEnabled) return 'powderWorkflow';
    return null;
  }, [isPowderWorkflowFeatureEnabled, isAdditiveWorkflowFeatureEnabled]);

  const options = getLineItemFormOptions({
    isReadOnly,
    isNormalUser: !isCurrentUserRestricted,
    workInstructionsExist,
    isCustomWorkflow,
  });

  const lineItemCustomFieldReferencesOverrides = getlineItemCustomFieldReferencesOverrides({
    isNormalUser: !isUserRestricted,
  });

  const visiblelineItemCustomFieldReferencesWithOptions = getVisibleCustomFieldReferencesWithOptions({
    fieldReferences: customLineItemFieldReferences,
    fieldValues: customFieldValues,
    fieldOverrides: lineItemCustomFieldReferencesOverrides,
    parentFieldReferences: customOrderFieldReferences,
    parentFieldValues: order.custom_field_values,
  });

  // Get current baseMaterial Name
  // const currentBaseMaterial = baseMaterials.find(({ uri }) => uri === baseMaterial);
  let bureauCost;
  if (lineItem.estimates && lineItem.estimates.total_cost) {
    bureauCost =
      (
        <FormattedLocalizedCost
          value={lineItem.estimates.total_cost}
        />
      );
  } else if (lineItem.estimates && lineItem[workflowTypeEstimatesKey]?.printing_time === null) {
    bureauCost =
      (
        <div>
          <FontAwesomeIcon icon={faSpinner} spin />
          <span style={{ marginRight: '0.5em' }} />
          Calculating…
        </div>
      );
  } else {
    bureauCost = <FormattedMessage id="notAvailable" defaultMessage="N/A" />;
  }

  // let baseMaterialOptions = order.location
  //   ? (
  //     // Use materials from location if there are any
  //     baseMaterialsByLocationUri[order.location]
  //     // or no materials as a fallback
  //     || []
  //   )
  //   : baseMaterials;
  // if (currentBaseMaterial && !_find(baseMaterialOptions, { uri: currentBaseMaterial.uri })) {
  //   baseMaterialOptions = [currentBaseMaterial, ...baseMaterialOptions];
  // }

  const {
    carpenterAtomizedTableData,
    carpenterSievedTableData,
    carpenterClassifierTableData,
  } = carpenterTableData;

  const renderOutputTable = (tableData = [], label) => {
    if (!tableData.length) return null;

    return (
      <Col xs={6} md={6}>
        <Card className="mt30">
          <Card.Header className="pd-exp accent">{label}</Card.Header>
          <ListGroup fill>
            {tableData.map(({ tableKey, key, value }) => (
              <ListGroupItem className="d-flex" key={key}>
                <p className="powder-custom-field-item">{tableKey}</p>
                <span>{value}</span>
              </ListGroupItem>
            ))}
          </ListGroup>
        </Card>
      </Col>
    );
  };

  return (
    <Form>
      <Row>
        <div className="align-items-end line-item-btn-row">
          {handleRenderSaveButton()}
        </div>
        <Col xs={12} md={6}>
          {/* <Row> */}
          {/*  <Col */}
          {/*    xs={{ */}
          {/*      span: 10, */}
          {/*      offset: 1, */}
          {/*    }} */}
          {/*    lg={{ */}
          {/*      span: 6, */}
          {/*      offset: 4, */}
          {/*    }} */}
          {/*  > */}
          {/*    /!* Logotype just for test purposes, we will substitute it later *!/ */}
          {/*    <img className="logo" src={AuthentiseLogo} alt="Authentise Logo" /> */}
          {/*  </Col> */}
          {/* </Row> */}

          {/* Estimates will remain for now commented out, we will either remove or customize it later */}
          {/* <VisibleFor unrestricted> */}
          {/*  <Row className="m-b"> */}
          {/*    <Col */}
          {/*      xs={12} */}
          {/*      lg={{ */}
          {/*        span: 10, */}
          {/*        offset: 1, */}
          {/*      }} */}
          {/*    > */}
          {/*      <EstimatesAndActuals lineItemUri={uri} /> */}
          {/*    </Col> */}
          {/*  </Row> */}
          {/* </VisibleFor> */}

          <Feature featureName={FEATURES.LINE_ITEM_EXTENDED_DETAILS}>
            <FormRow id="field.itemName" defaultMessage="Item Name">
              <div className="d-flex align-items-center">
                <FormControl
                  name="itemName"
                  value={itemName}
                  type="text"
                  onChange={handleInputChange}
                  disabled={isReadOnly}
                />
              </div>
            </FormRow>
          </Feature>

          <FormRow id="field.itemStatus" defaultMessage="Item Status">
            {!options.status.editable && (
              <Form.Text>
                {
                  // Status might be empty for some time right after LI creation
                  // (after POST, before event-stream UPDATED event)
                  status && (
                    <FormattedMessage
                      id={LINE_ITEM_STATUS_MAP[status].id}
                      defaultMessage={LINE_ITEM_STATUS_MAP[status].message}
                    />
                  )
                }
              </Form.Text>
            )}

            {options.status.editable && (
              <FormControl
                name="status"
                as="select"
                onChange={handleInputChange}
                value={status}
                required
                disabled={isReadOnly}
              >
                {statusOptions &&
                  statusOptions.map(statusOption => (
                    <FormattedMessageMappingOption
                      mapping={LINE_ITEM_STATUS_MAP}
                      value={statusOption}
                      key={statusOption}
                    />
                  ))}
              </FormControl>
            )}
          </FormRow>

          <Feature featureName={FEATURES.DESIGN_COST}>
            {options.designHours.show && (
              <FormRow id="field.design_hours" defaultMessage="Design Hours">
                <FormControl
                  name="designHours"
                  value={designHours}
                  type="number"
                  min="0"
                  onChange={handleInputChange}
                  disabled={isReadOnly}
                />
              </FormRow>
            )}
          </Feature>

          {options.bureauCost.show && (
            <FormRow
              id={options.bureauCost.id}
              defaultMessage={options.bureauCost.defaultMessage}
            >
              <Form.Text>{bureauCost}</Form.Text>
            </FormRow>
          )}

          {options.notes.show && (
            <FormRow
              id="field.notes"
              defaultMessage="Notes"
              isRequired={options.notes.required}
            >
              <FormControl
                name="notes"
                value={notes}
                as="textarea"
                onChange={handleInputChange}
                required={options.notes.required}
                placeholder={
                  options.notes.editable ? options.notes.placeholder : ''
                }
                disabled={!options.notes.editable || isReadOnly}
              />
            </FormRow>
          )}

          <Row className="d-flex flex-wrap">
            {renderOutputTable(carpenterAtomizedTableData, 'Atomized')}
            {renderOutputTable(carpenterSievedTableData, 'Sieved')}
            {renderOutputTable(carpenterClassifierTableData, 'Classifier')}
          </Row>

        </Col>

        <Col xs={12} md={6}>
          <Col xs={12}>
            {options.workflow.show &&
              (status === LINE_ITEM_STATUS.COMPLETE ? (
                <FormRow id="carpenterWorkflow" defaultMessage="Workflow">
                  <p className="form-control-static">Complete</p>
                </FormRow>
              ) : (
                <FormRow
                  id="carpenterWorkflow"
                  defaultMessage="Workflow"
                  isRequired={options.workflow.required}
                >
                  <InputGroup className="w100">
                    <div className="d-flex w-full">
                      <SelectSingleLazy
                        name="workflow"
                        dataTestId="lineItemWorkflow"
                        data={nonSpecimenWorkflows}
                        value={workflow}
                        handleOnChange={handleInputChange}
                        imitateOnChangeEvent
                        required
                        disabled={isReadOnly}
                        isOptionDisabledCallback={item => item.usage_state === WORKFLOW_USAGE_STATES.ARCHIVED}
                        onFetchMore={onFetchMoreWorkflows}
                      />
                      {workflow && (
                        <VisibleFor unrestricted>
                          <InputGroup.Text>
                            {!options.workflow.editable && workInstructionsExist && (
                              <FormattedMessage
                                id="workflowInstructionTooltip"
                                defaultMessage="Production Workflow can’t be changed once work instructions are created for this line item."
                              >
                                {text => (
                                  <Tooltip id="workflowInstructionTooltip">{text}</Tooltip>
                                )}
                              </FormattedMessage>
                            )}
                            <ResourceLink
                              href={getRouteURI(ROUTES.WORKFLOW_EDIT, { uuid: extractUuid(workflow) })}
                            />
                          </InputGroup.Text>
                        </VisibleFor>
                      )}
                    </div>
                  </InputGroup>
                </FormRow>
              ))}

            <VisibleFor unrestricted>
              <FormRow
                id="field.workInstructions"
                defaultMessage="Work Instructions"
                labelSuffix={lineItem.workflow ? null : (
                  <Tooltip
                    id="workflowSelectionTooltip"
                    trigger={(<FontAwesomeIcon icon={faQuestionCircle} />)}
                  >
                    Requires workflow selection
                  </Tooltip>
                )}
              >
                <Button
                  size="xs"
                  variant="primary"
                  className="pos-r"
                  onClick={() => setVisibleModal(MODALS.VIEW_WORK_PLAN)}
                  disabled={!usedWorkflow}
                >
                  <FontAwesomeIcon icon={faEye} />{' '}
                  <FormattedMessage
                    id="button.viewEditWorkInstructions"
                    defaultMessage="View and Edit Work Instructions"
                  />
                  {isGuidelineEngineFeatureEnabled && guidelineSuggestionsForLineItem?.length > 0 && (
                    <Badge
                      className="badge badge-sm d-flex align-items-center justify-content-center text-white pos-a"
                      bg="info"
                      style={{ left: '95%',
                        top: -10,
                        height: 20,
                        width: 20,
                        borderRadius: 20 }}
                    >
                      {guidelineSuggestionsForLineItem.length > 0 && (
                        <span>{guidelineSuggestionsForLineItem.length}</span>
                      )}
                    </Badge>
                  )}
                </Button>
                {(anyPiecesHaveModifiedWorkflow && !isPiecesOrWorkflowfetching) && (
                  <RCTooltip
                    placement="right"
                    destroyTooltipOnHide
                    overlayInnerStyle={{ padding: '10px', wordBreak: 'break-word' }}
                    mouseLeaveDelay={0.4}
                    overlay={(

                      <p>One or more of the pieces for this line-item <br />
                        were manufactured with a different workflow
                      </p>

                    )}
                  >

                    <FontAwesomeIcon icon={faTriangleExclamation} className="spacer-left" style={{ color: '#ffd500' }} />
                  </RCTooltip>
                )}
                {(fetchingGuidelineSuggestionsForLineItem || fetchingProcessSteps)
                  && (
                    <Loading />
                  )}
              </FormRow>
            </VisibleFor>

            {/* Statuses confirmed modal */}
            <Modal
              show={showCompleteWarningModal}
              onClick={handleCloseCompleteWarningModal}
            >
              <Modal.Body>
                {modalTextPending ? (
                  <span>
                    <b>Warning:</b> All work steps for all items in this Line-Items
                    will be marked as complete. Items already added to a Print Run
                    will be left in run batches, to avoid cancelling queued/set runs.
                    The prints in this line item will be marked as complete, and will
                    show up in print statistics. Cancel if you do not want these
                    prints to be counted in statistics.
                  </span>
                ) : (
                  <span>
                    The prints in this line item will be marked as complete, and will
                    show up in print statistics. Cancel if you do not want these
                    prints to be counted in statistics.
                  </span>
                )}
              </Modal.Body>
              <Modal.Footer>
                <Button variant="success" onClick={submitConfirmModal}>
                  Confirm
                </Button>
                <Button variant="primary" onClick={handleCloseCompleteWarningModal}>
                  Cancel
                </Button>
              </Modal.Footer>
            </Modal>

            {visibleModal === MODALS.VIEW_WORK_PLAN && (
              <WorkPlanViewModalContainer
                onClose={onCloseWorkPlanViewModal}
                lineItemUri={lineItem.uri}
                workflowUri={lineItem.workflow}
                guidelineSuggestionsForLineItem={guidelineSuggestionsForLineItem}
                onReloadPrints={onReloadPrints}
                hideWorkInstructionBadge
              />
            )}

            {visibleModal === MODALS.WORKFLOW_CHANGE_CONFIRMATION && (
              <DeleteChecklistsConfirmationModal
                onClose={onCloseWorkPlanViewModal}
                onDecline={onDeleteChecklistsAndSubmit}
                lineItemUri={lineItem.uri}
                workflowUri={lineItem.workflow}
              />
            )}

            {visibleModal === MODALS.LINE_ITEM_DUPLICATE && (
              <LineItemDuplicateModal
                onClose={onCloseDuplicateModal}
                onDuplicate={onDuplicate}
                isLineItemDuplicating={isLineItemDuplicating}
                quantity={quantity}
              />
            )}

            <Feature featureName={FEATURES.TRAVELER}>
              {options.traveler.show && (
                <FormRow id="field.traveler" defaultMessage="Traveler">
                  <a
                    href={`${Config.HOST.NAUTILUS}/traveler/?line_item=${lineItem.uri}`}
                    target="_blank"
                    rel="noopener noreferrer"
                    type="download"
                  >
                    <FormattedMessage
                      id="button.download"
                      defaultMessage="Download"
                    />
                  </a>
                </FormRow>
              )}
            </Feature>

            {/* {options.baseMaterial.show && ( */}
            {/*  <FormRow */}
            {/*    id="field.baseMaterial" */}
            {/*    defaultMessage="Base Material" */}
            {/*    isRequired */}
            {/*  > */}
            {/*    <div className="d-flex align-items-center"> */}
            {/*      <InputGroup className="w100"> */}
            {/*        <div className="d-flex w-full"> */}
            {/*          <SelectSingle */}
            {/*            name="baseMaterial" */}
            {/*            data={baseMaterialOptions} */}
            {/*            value={baseMaterial} */}
            {/*            handleOnChange={handleInputChange} */}
            {/*            required */}
            {/*            imitateOnChangeEvent */}
            {/*            disabled={!options.baseMaterial.editable || isReadOnly} */}
            {/*          /> */}
            {/*          <InputGroup.Text> */}
            {/*            {baseMaterial && ( */}
            {/*              <div */}
            {/*                style={{ */}
            {/*                  margin: '0 auto', */}
            {/*                  width: 20, */}
            {/*                  height: 20, */}
            {/*                  backgroundColor: baseMaterialColor, */}
            {/*                }} */}
            {/*              /> */}
            {/*            )} */}
            {/*          </InputGroup.Text> */}
            {/*          <VisibleFor unrestricted> */}
            {/*            <InputGroup.Text> */}
            {/*              {baseMaterialColor && <ResourceLink href={baseMaterialUrl} />} */}
            {/*            </InputGroup.Text> */}
            {/*          </VisibleFor> */}
            {/*        </div> */}
            {/*      </InputGroup> */}
            {/*      <FormattedMessage */}
            {/*        id="record.lineItem.locationFilterMessage" */}
            {/*        defaultMessage="The Materials you can select from are those Materials */}
            {/* which are available at the Order's Location. */}
            {/*        To see other Materials that may be available, please change the Order's Location. */}
            {/*        To see All Materials available in your bureau, set the Order Location to Any." */}
            {/*      > */}
            {/*        {text => ( */}
            {/*          <Tooltip id="locationFilteringMessage" placement="left">{text}</Tooltip> */}
            {/*        )} */}
            {/*      </FormattedMessage> */}
            {/*    </div> */}
            {/*  </FormRow> */}
            {/* )} */}

            {isDebugModeEnabled &&
                (isAdditiveWorkflowFeatureEnabled || isPowderWorkflowFeatureEnabled) &&
                (
                  <FormRow id="workflowType" defaultMessage="Workflow Type">
                    <FormControl
                      as="select"
                      type="select"
                      value={workflowTypeInitialValue}
                      disabled
                    >
                      <option key="additiveWorkflow" value="additiveWorkflow">
                        Additive
                      </option>
                      <option key="powderWorkflow" value="powderWorkflow">
                        Powder
                      </option>
                    </FormControl>
                  </FormRow>
                )}

            <CustomFieldList
              customFieldReferences={visiblelineItemCustomFieldReferencesWithOptions}
              customFieldValues={customFieldValues}
              onChange={onCustomFieldChange}
              readOnly={isReadOnly}
            />
          </Col>

          <Row>
            <Col xs={{
              span: 9,
              offset: 3,
            }}
            >
              <Documents
                relatedTable={DOCUMENT_RELATED_TABLE_NAMES.LINE_ITEM}
                relatedUUID={extractUuid(lineItem.uri)}
                skipInitialize
              />
            </Col>
          </Row>

          {/* Pieces will remain for MVP, might be removed later */}
          <VisibleFor unrestricted>
            <Row>
              <Col xs={{
                span: 9,
                offset: 3,
              }}
              >
                <LineItemPiecesContainer
                  lineItem={lineItem}
                  piecesModifiedWorkFlowList={piecesWithModifiedWorkflowByWorkflowURI}
                />
              </Col>
            </Row>
          </VisibleFor>
        </Col>
      </Row>

    </Form>
  );
};

LineItemPowderWorkflowEditForm.defaultProps = {
  notes: null,
  isCurrentUserRestricted: false,
  workflow: null,
  designHours: null,
  isLineItemSubmitting: false,
  isLineItemDuplicating: false,
  itemName: null,
  readOnly: false,
  // showRecalculationLineItemModal: false,
  onFetchMoreWorkflows: null,
};

LineItemPowderWorkflowEditForm.propTypes = {
  customFieldValues: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  customLineItemFieldReferences: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  customOrderFieldReferences: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  handleInputChange: PropTypes.func.isRequired,
  isLineItemSubmitting: PropTypes.bool,
  isLineItemDuplicating: PropTypes.bool,
  isCurrentUserRestricted: PropTypes.bool,
  showCompleteWarningModal: PropTypes.bool.isRequired,
  modalTextPending: PropTypes.bool.isRequired,
  lineItem: lineItemType.isRequired,
  itemName: PropTypes.string,
  // baseMaterial: PropTypes.string.isRequired,
  // baseMaterialColor: PropTypes.string.isRequired,
  // baseMaterialUrl: PropTypes.string.isRequired,
  // baseMaterials: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  // baseMaterialsByLocationUri: PropTypes.objectOf(PropTypes.shape({})).isRequired,
  notes: PropTypes.string,
  onSubmit: PropTypes.func.isRequired,
  onDelete: PropTypes.func.isRequired,
  onDuplicate: PropTypes.func.isRequired,
  onCustomFieldChange: PropTypes.func.isRequired,
  onReloadPrints: PropTypes.func.isRequired,
  onReloadChecklistLinkings: PropTypes.func.isRequired,
  handleCloseCompleteWarningModal: PropTypes.func.isRequired,
  submitConfirmModal: PropTypes.func.isRequired,
  quantity: PropTypes.string.isRequired,
  designHours: PropTypes.number,
  status: PropTypes.string.isRequired,
  workflow: PropTypes.string,
  workflows: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  workChecklistLinkings: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  readOnly: PropTypes.oneOfType([PropTypes.bool, PropTypes.func]),
  isDebugModeEnabled: PropTypes.bool.isRequired,
  isUserRestricted: PropTypes.bool.isRequired,
  order: orderType.isRequired,
  onFetchMoreWorkflows: PropTypes.func,
  isGuidelineEngineFeatureEnabled: PropTypes.bool.isRequired,
  guidelineSuggestionsForLineItem: PropTypes.arrayOf(PropTypes.shape({
    resources: PropTypes.arrayOf(PropTypes.shape({})),
  })).isRequired,
  fetchingGuidelineSuggestionsForLineItem: PropTypes.bool.isRequired,
  fetchingProcessSteps: PropTypes.bool.isRequired,
  anyPiecesHaveModifiedWorkflow: PropTypes.bool.isRequired,
  isAdditiveWorkflowFeatureEnabled: PropTypes.bool.isRequired,
  isPowderWorkflowFeatureEnabled: PropTypes.bool.isRequired,
  workflowTypeEstimatesKey: PropTypes.string.isRequired,
  isPiecesOrWorkflowfetching: PropTypes.bool.isRequired,
  piecesWithModifiedWorkflowByWorkflowURI: PropTypes.objectOf(PropTypes.shape({})).isRequired,
  carpenterTableData: PropTypes.shape({
    carpenterAtomizedTableData: PropTypes.arrayOf(PropTypes.shape({})),
    carpenterSievedTableData: PropTypes.arrayOf(PropTypes.shape({})),
    carpenterClassifierTableData: PropTypes.arrayOf(PropTypes.shape({})),
  }).isRequired,
};

export default LineItemPowderWorkflowEditForm;
