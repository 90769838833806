import { selectInputStyles } from 'rapidfab/constants/styles';
import React, { useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import {
  Row,
  Col,
  FormControl,
  Container,
  ButtonToolbar,
  SplitButton,
  Dropdown,
  FormGroup,
  FormLabel,
  InputGroup,
  Form as BSForm,
} from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { SketchPicker } from 'react-color';
import hexRgb from 'hex-rgb';
import { FormattedMessage } from 'react-intl';
import {
  MANUFACTURING_PROCESSES,
  DOCUMENT_RELATED_TABLE_NAMES,
  MATERIAL_UNITS_BY_UNITS_MEASUREMENT_CORE,
  MATERIAL_UNITS_MEASUREMENT_CORE,
  FEATURES, MATERIAL_UNITS_BY_FEATURE, REACT_SELECT_ACTIONS,
} from 'rapidfab/constants';
import { getShortUUID, extractUuid } from 'rapidfab/utils/uuidUtils';
import Loading from 'rapidfab/components/Loading';
import SelectMultiple from 'rapidfab/components/forms/SelectMultiple';
import BreadcrumbNav from 'rapidfab/components/BreadcrumbNav';
import SaveButtonTitle from 'rapidfab/components/SaveButtonTitle';
import Documents from 'rapidfab/components/records/Documents';
import { MATERIAL_FAMILY_MAP, MATERIAL_UNITS_MAP } from 'rapidfab/mappings';
import { FormattedMessageMappingOption } from 'rapidfab/i18n';
import { FormControlCost, FormControlSelect, identity } from 'rapidfab/components/formTools';
import CostTooltip from 'rapidfab/components/CostTooltip';
import MaterialTypeStockPanel from 'rapidfab/components/records/MaterialTypeStockPanel';
import MaterialTypeStockModal from 'rapidfab/components/records/MaterialTypeStockModal';
import UseNonMfgLanguageFeature from 'rapidfab/components/generalMfgLanguage/UseNonMfgLanguageFeature';
import Feature from 'rapidfab/components/Feature';

import { Form, FormSpy, Field } from 'react-final-form';
import { finalFormInputTypes } from 'rapidfab/types';
import MaterialTypeTestPanel from 'rapidfab/components/records/MaterialTypeTestPanel';
import { faBan, faExternalLink } from '@fortawesome/free-solid-svg-icons';
import CreatableSelect from 'react-select/creatable';

const ResourceLink = ({ href }) => (
  <a href={href}>
    Go <FontAwesomeIcon icon={faExternalLink} />
  </a>
);

ResourceLink.propTypes = { href: PropTypes.string.isRequired };

const MaterialForm = ({
  uuid,
  stocks,
  locationsByUri,
  materialsByUri,
  onFormSubmit,
  onDelete,
  manufacturers,
  fromTemplate,
  externalMaterialDbFeatureEnabled,
  defaultCurrency,
  printerTypes,
  printerTypesWithMaterial,
  material,
  materialManagementEnabled,
  initialFormValues,
  submitting,
  materialTestOperationLinkings,
  materialTestOperations,
  materialTestState,
  materialTestInstructions,
  materialTestInstructionsByUri,
  materialTestUnitsByUri,
  isPOCUKOrderFieldsFeatureEnabled,
  handleSubmitManufacturer,
  isManufacturerFetching,
}) => {
  const [form, setForm] = useState({ values: initialFormValues });

  const formattedManufacturers = manufacturers.map(manufacturer => ({
    label: manufacturer.name,
    value: manufacturer.uri,
  })).filter(Boolean);

  let rgbaValue = '';
  if (form?.values?.color) {
    // convert hex color value to { r, g, b }
    const { red: r, green: g, blue: b } = hexRgb(form?.values?.color);
    // rgbaValue is what's passed into color picker
    rgbaValue = { r, g, b, a: form?.values?.color_opacity };
  }

  // Density is needed for Weight User Units only
  const showDensity =
    MATERIAL_UNITS_BY_UNITS_MEASUREMENT_CORE[MATERIAL_UNITS_MEASUREMENT_CORE.WEIGHT]
      .includes(form?.values?.units);

  const isUpdateForm = initialFormValues?.id;
  const [showModal, setShowModal] = useState(false);
  const showUnits = useMemo(() => {
    if (isPOCUKOrderFieldsFeatureEnabled) {
      return MATERIAL_UNITS_BY_FEATURE[FEATURES.POC_UK_ORDER_FIELDS];
    }
    return Object.keys(MATERIAL_UNITS_MAP);
  }, [isPOCUKOrderFieldsFeatureEnabled]);

  const handleManufacturersChange = async (selectedOption, actionMeta, onChange) => {
    // The user would like to create the new manufacturer "on the fly"
    if (actionMeta.action === REACT_SELECT_ACTIONS.CREATE_OPTION) {
      // Send API request to create the new manufacturer with the "name" field
      const manufacturerUri = await handleSubmitManufacturer(selectedOption.label);
      if (manufacturerUri) {
        // Set the new manufacturer URI as the value of the select input
        return onChange(manufacturerUri);
      }
    }
    const newValue = selectedOption ? selectedOption.value : '';
    return onChange(newValue);
  };

  return (
    <Container fluid>
      <Form
        onSubmit={onFormSubmit}
        initialValues={initialFormValues}
        render={({ handleSubmit, form }) => (
          <form id="material" onSubmit={handleSubmit}>
            <BreadcrumbNav
              breadcrumbs={['inventory', 'materials', isUpdateForm ? `${initialFormValues?.name} (${getShortUUID(initialFormValues?.uuid)})` : 'New']}
            />
            <div className="clearfix">
              <ButtonToolbar className="pull-right">
                <SplitButton
                  disabled={submitting}
                  form="material"
                  id="uxSaveDropdown"
                  type="submit"
                  variant="success"
                  size="sm"
                  title={submitting ? <Loading /> : <SaveButtonTitle />}
                  pullRight
                >
                  <Dropdown.Item
                    eventKey={1}
                    onClick={() => onDelete(initialFormValues?.uuid)}
                    disabled={!initialFormValues?.id}
                  >
                    <FontAwesomeIcon icon={faBan} />{' '}
                    <FormattedMessage id="button.delete" defaultMessage="Delete" />
                  </Dropdown.Item>
                </SplitButton>
              </ButtonToolbar>
            </div>

            <hr />

            <Row>
              <Col xs={6}>
                <FormGroup className="form-group" controlId="uxName">
                  <FormLabel>
                    <FormattedMessage id="field.name" defaultMessage="Name" />: *
                  </FormLabel>
                  <Field
                    name="name"
                    type="text"
                    render={props => (
                      <FormControl
                        {...props.input}
                        required
                        disabled={fromTemplate}
                      />
                    )}
                  />
                </FormGroup>
                <FormGroup className="form-group" controlId="uxDescription">
                  <FormLabel>
                    <FormattedMessage
                      id="field.description"
                      defaultMessage="Description"
                    />
                    : *
                  </FormLabel>
                  <Field
                    name="description"
                    render={props => (
                      <FormControl
                        {...props.input}
                        as="textarea"
                        required
                      />
                    )}
                  />
                </FormGroup>
                <FormGroup className="form-group" controlId="uxManufacturer">
                  <FormLabel>
                    <FormattedMessage
                      id="field.manufacturer"
                      defaultMessage="Manufacturer"
                    />
                    : *
                  </FormLabel>
                  <InputGroup>
                    <Field
                      name="manufacturer"
                      render={props => {
                        const selectedOption = formattedManufacturers.find(option =>
                          option.value === props.input.value) || null;
                        return (
                          <CreatableSelect
                            {...props.input}
                            styles={selectInputStyles}
                            isLoading={isManufacturerFetching}
                            placeholder={(
                              <FormattedMessage
                                id="field.selectManufacturer"
                                defaultMessage="Select Manufacturer"
                              />
                            )}
                            isDisabled={isManufacturerFetching}
                            components={{ LoadingIndicator: () => (<Loading inline className="spacer-right" />) }}
                            value={selectedOption}
                            options={formattedManufacturers}
                            required
                            onChange={(value, actionMeta) =>
                              handleManufacturersChange(value, actionMeta, props.input.onChange)}
                            isClearable
                          />
                        );
                      }}
                    />
                    {form?.values?.value && (
                      <InputGroup.Text size="sm">
                        <ResourceLink
                          href={`/#/records/manufacturer/${extractUuid(form?.values?.manufacturer)}`}
                        />
                      </InputGroup.Text>
                    )}
                  </InputGroup>
                </FormGroup>
                <FormGroup className="form-group" controlId="uxColor">
                  <FormLabel>
                    <FormattedMessage id="field.color" defaultMessage="Color" />: *
                  </FormLabel>
                  <Field
                    name="color"
                    validate={value => (value ? undefined : 'Please fill out this field.')}
                    render={color_props => (
                      <>
                        <Field
                          name="color_opacity"
                          render={props => (
                            <SketchPicker
                              width={250}
                              presetColors={[]}
                              color={rgbaValue}
                              onChangeComplete={({ rgb: { a: opacity }, hex }) => {
                                // color field stored as hex in color field
                                color_props.input.onChange(hex === 'transparent' ? '#000000' : hex);
                                // opacity float is stored in its own color_opacity field
                                props.input.onChange(opacity);
                              }}
                            />
                          )}
                        />
                        {
                          color_props.meta.touched && color_props.meta.invalid && (
                            <FormLabel className="text-danger mt-1">
                              <FormattedMessage id="field.required" defaultMessage="Required" />
                            </FormLabel>
                          )
                        }
                      </>
                    )}
                  />
                </FormGroup>
                <FormGroup className="form-group" controlId="uxType">
                  <FormLabel>
                    <FormattedMessage
                      id="field.materialType"
                      defaultMessage="Material Type"
                    />
                    : *
                  </FormLabel>
                  <Field
                    name="type"
                    render={props => (
                      <FormControlSelect
                        {...props.input}
                        required
                      >
                        <option key="placeholder" value="" selected disabled>
                          Select a Material Type
                        </option>
                        <option value="base">Base</option>
                        <option value="support">Support</option>
                      </FormControlSelect>
                    )}
                  />
                </FormGroup>
                <FormGroup className="form-group" controlId="uxFamily">
                  <FormLabel>
                    <FormattedMessage
                      id="field.materialFamily"
                      defaultMessage="Material Family"
                    />
                    :
                  </FormLabel>
                  <Field
                    name="material_family"
                    render={props => (
                      <FormControlSelect
                        {...props.input}
                        disabled={fromTemplate}
                      >
                        <FormattedMessage id="field.none" defaultMessage="None">
                          {text => <option value="">{text}</option>}
                        </FormattedMessage>
                        {Object.keys(MATERIAL_FAMILY_MAP).map(family => (
                          <FormattedMessageMappingOption
                            mapping={MATERIAL_FAMILY_MAP}
                            value={family}
                          />
                        ))}
                      </FormControlSelect>
                    )}
                  />
                </FormGroup>
                <FormGroup className="form-group" controlId="uxFamily">
                  <FormLabel>
                    <UseNonMfgLanguageFeature
                      mfgLanguageComponent={(
                        <FormattedMessage
                          id="manage.printerTypes"
                          defaultMessage="Printer Types"
                        />
                      )}
                      nonMfgLanguageComponent={(
                        <FormattedMessage
                          id="mfg.printerTypes.productionDeviceTypes"
                          defaultMessage="Production Device Types"
                        />
                      )}
                    />
                    :
                  </FormLabel>
                  <Field
                    name="printer_types_selected"
                    render={props => (
                      <SelectMultiple
                        data={printerTypes}
                        labelKey="name"
                        valueKey="uri"
                        multiple
                        selectedData={printerTypesWithMaterial}
                        handleOnClose={props.input.onChange}
                        showBadge={false}
                      />
                    )}
                  />
                </FormGroup>
                <FormGroup className="form-group" controlId="uxUnits">
                  <FormLabel>
                    <FormattedMessage id="field.units" defaultMessage="Units" />: *
                  </FormLabel>
                  <Field
                    name="units"
                    render={props => (
                      <FormControlSelect
                        {...props.input}
                        required
                      >
                        {!props.input.value && (
                          <FormattedMessage id="field.none" defaultMessage="None">
                            {text => (
                              <option value="" selected disabled>
                                {text}
                              </option>
                            )}
                          </FormattedMessage>
                        )}
                        {showUnits.map(unit => (
                          <FormattedMessageMappingOption
                            mapping={MATERIAL_UNITS_MAP}
                            value={unit}
                          />
                        ))}
                      </FormControlSelect>
                    )}
                  />
                </FormGroup>
                {showDensity && (
                  <FormGroup controlId="uxDensity">
                    <FormLabel>
                      {
                        // Density is fixed to `g/cm3`
                        // and not related to selected units (g/kg/lb) so hard-coding it here
                      }
                      <FormattedMessage
                        id="field.density"
                        defaultMessage="Density"
                      />{' '}
                      (g/cm3): *
                    </FormLabel>
                    <Field
                      name="density"
                      type="number"
                      render={props => (
                        <FormControl
                          {...props.input}
                          required
                        />
                      )}
                    />
                    <span>
                      Density required for weight based material measurements. See
                      your material data sheet
                    </span>
                  </FormGroup>
                )}
                <FormGroup className="form-group" controlId="uxCost">
                  <FormLabel>
                    <FormattedMessage
                      id="field.costPerUnit"
                      defaultMessage="Cost Per Unit"
                    />
                    : *
                    <CostTooltip />
                  </FormLabel>
                  <FormControlCost
                    name="cost"
                    initialValue={initialFormValues?.cost}
                    type="number"
                    required
                    currency={defaultCurrency}
                  />
                </FormGroup>
                {externalMaterialDbFeatureEnabled ? (
                  <FormGroup
                    className="form-group"
                    controlId="manufacturingProcess"
                  >
                    <FormLabel>
                      <FormattedMessage
                        id="field.manufacturing_process"
                        defaultMessage="Manufacturing Process"
                      />
                      :
                    </FormLabel>
                    <Field
                      name="manufacturing_process"
                      render={props => (
                        <FormControlSelect
                          {...props.input}
                        >
                          <option value="">None</option>
                          {MANUFACTURING_PROCESSES.map(process => (
                            <option value={process} key={process}>
                              {process}
                            </option>
                          ))}
                        </FormControlSelect>
                      )}
                    />
                  </FormGroup>
                ) : (
                  <Field
                    name="third_party_fulfillment"
                    type="checkbox"
                    initialValue={initialFormValues?.third_party_fulfillment}
                    render={props => (
                      <BSForm.Check
                        {...props.input}
                        label={(
                          <FormattedMessage
                            id="field.fullfilledByThirdParty"
                            defaultMessage="Fullfilled By Third Party"
                          />
                        )}

                      />
                    )}
                  />
                )}
              </Col>
              <Col xs={6}>
                {uuid && (
                  <MaterialTypeStockPanel
                    stocks={stocks}
                    locationsByUri={locationsByUri}
                    materialsByUri={materialsByUri}
                    onOpenModal={() => setShowModal(true)}
                    materialManagementEnabled={materialManagementEnabled}
                  />
                )}
                {uuid && (
                  <Documents
                    panelTitle="Documents"
                    relatedTable={DOCUMENT_RELATED_TABLE_NAMES.MATERIAL}
                    relatedUUID={uuid}
                  />
                )}
                <FormGroup className="form-group" controlId="uxExternalDatasheet">
                  <FormLabel>
                    <FormattedMessage
                      id="field.externalDatasheetUrl"
                      defaultMessage="External Datasheet URL"
                    />
                    :
                  </FormLabel>
                  <Field
                    name="external_datasheet_url"
                    type="url"
                    parse={identity}
                    render={props => (
                      <FormControl {...props.input} />
                    )}
                  />
                </FormGroup>
                <FormGroup className="form-group" controlId="uxExternalID">
                  <FormLabel>
                    <FormattedMessage id="field.erpId" defaultMessage="ERP ID" />:
                  </FormLabel>
                  <Field
                    name="external_id"
                    parse={identity}
                    type="text"
                    render={props => (
                      <FormControl {...props.input} />
                    )}
                  />
                </FormGroup>
                <Feature
                  featureName={FEATURES.MATERIAL_TEST_PANEL}
                >
                  <MaterialTypeTestPanel
                    {...materialTestState}
                    materialTestInstructions={materialTestInstructions}
                    materialTestInstructionsByUri={materialTestInstructionsByUri}
                    materialTestOperations={materialTestOperations}
                    materialTestOperationLinkings={materialTestOperationLinkings}
                    materialTestUnitsByUri={materialTestUnitsByUri}
                    material={material}
                  />
                </Feature>
              </Col>
            </Row>
            <FormSpy
              subscription={{ values: true }}
              onChange={values => setForm(values)}
            />
          </form>
        )}
      />
      {showModal && (
        <MaterialTypeStockModal
          material={material}
          onClose={() => setShowModal(false)}
        />
      )}
    </Container>
  );
};

MaterialForm.propTypes = {
  stocks: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  locationsByUri: PropTypes.objectOf(PropTypes.shape({})).isRequired,
  uuid: PropTypes.string.isRequired,
  onFormSubmit: PropTypes.func.isRequired,
  onDelete: PropTypes.func.isRequired,
  input: finalFormInputTypes.isRequired,
  manufacturers: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  fromTemplate: PropTypes.bool,
  externalMaterialDbFeatureEnabled: PropTypes.bool,
  defaultCurrency: PropTypes.string.isRequired,
  printerTypes: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  printerTypesWithMaterial: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  material: PropTypes.shape({}).isRequired,
  materialManagementEnabled: PropTypes.bool.isRequired,
  initialFormValues: PropTypes.shape({
    name: PropTypes.string,
    description: PropTypes.string,
    manufacturer: PropTypes.string,
    material_type: PropTypes.string,
    density: PropTypes.number,
    printer_types_selected: PropTypes.arrayOf(PropTypes.string),
    cost: PropTypes.number,
    manufacturing_process: PropTypes.string,
    external_datasheet_url: PropTypes.string,
    external_id: PropTypes.string,
    third_party_fulfillment: PropTypes.bool,
    units: PropTypes.string,
    printer_type_selector: PropTypes.string,
    material_family: PropTypes.string,
    type: PropTypes.string,
    color: PropTypes.string,
    id: PropTypes.string,
    uuid: PropTypes.string,
  }).isRequired,
  materialsByUri: PropTypes.objectOf(PropTypes.shape({})).isRequired,
  submitting: PropTypes.bool.isRequired,
  materialTestOperationLinkings: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  materialTestState: PropTypes.arrayOf(PropTypes.func).isRequired,
  materialTestOperations: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  materialTestInstructions: PropTypes.arrayOf(PropTypes.func).isRequired,
  materialTestInstructionsByUri: PropTypes.shape({}).isRequired,
  materialTestUnitsByUri: PropTypes.shape({}).isRequired,
  isPOCUKOrderFieldsFeatureEnabled: PropTypes.bool.isRequired,
  handleSubmitManufacturer: PropTypes.func.isRequired,
  isManufacturerFetching: PropTypes.bool.isRequired,
};

MaterialForm.defaultProps = {
  fromTemplate: false,
  externalMaterialDbFeatureEnabled: false,
};

export default MaterialForm;
