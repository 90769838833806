import React from 'react';
import PropTypes from 'prop-types';

const SlicedModelDownload = ({
  slicedBuildFile,
}) => (
  <span>
    Download:
    <a
      className="ml15 font-size-12"
      href={slicedBuildFile?.content}
    >
      (Read Only)
    </a>
  </span>
);

SlicedModelDownload.propTypes = {
  slicedBuildFile: PropTypes.shape({
    content: PropTypes.string,
  }).isRequired,
};

export default SlicedModelDownload;
