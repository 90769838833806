import TextEditor from '@draft-js-plugins/editor';
import { convertFromRaw, convertToRaw, EditorState } from 'draft-js';
import 'draft-js/dist/Draft.css';
import _camelCase from 'lodash/camelCase';
import _upperFirst from 'lodash/upperFirst';
import PropTypes from 'prop-types';
import Actions from 'rapidfab/actions';
import { API_RESOURCES, RELATED_TABLE_NAMES, THREADS_API_RESOURCES } from 'rapidfab/constants';
import { draftJsEditorStyles } from 'rapidfab/constants/styles';
import { FormattedDateTime } from 'rapidfab/i18n';
import ThreadsLogo from 'rapidfab/images/threads-logo.png';
import * as Selectors from 'rapidfab/selectors';
import Alert from 'rapidfab/utils/alert';
import { safeJSONParse } from 'rapidfab/utils/stringUtils';
import { getShortUUID } from 'rapidfab/utils/uuidUtils';
import React, { useCallback, useEffect, useState } from 'react';
import { Button, Image } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import './styles.scss';
import { getThreadsAppRouteURI } from 'rapidfab/utils/uriUtils';

/**
 * Only rendered when `threads-comments-in-flows` feature flag is enabled.
 */
const ThreadsComment = ({
  comments,
  messageData = null,
  editMode = false,
  resourceTableName = null,
  resourceUUID = null,
}) => {
  const dispatch = useDispatch();

  const usersByUri = useSelector(Selectors.getUsersByUri);
  const commentSubmitting = useSelector(state => state.ui.nautilus[API_RESOURCES.THREADS_MESSAGES].post.fetching
    || state.ui.nautilus[API_RESOURCES.THREADS_MESSAGES].get.fetching,
  );

  /** Manually transformes raw string to be parseable by Draft.js. */
  const transformToDraftContentState = useCallback(stringContent => convertFromRaw({
    blocks: [
      {
        key: 'initial',
        text: stringContent,
        type: 'unstyled',
        depth: 0,
        inlineStyleRanges: [],
        entityRanges: [],
        data: {},
      },
    ],
    entityMap: {},
  }), []);

  const getInitialCommentBodyContent = useCallback(
    () => {
      const [parsedValue, messageDataIsJson] = safeJSONParse(messageData?.comment);
      if (!editMode && messageData?.comment && messageDataIsJson) {
        return EditorState.createWithContent(convertFromRaw(parsedValue));
      }

      // Strange edge case: If comment is a string, but not JSON. Appears to happen on initial post.
      if (!messageDataIsJson && typeof messageData?.comment === 'string') {
        // Manually mimic the Draft.js EditorState by appending the internal JSON format to non-JSON string.
        const contentState = transformToDraftContentState(messageData?.comment);
        return EditorState.createWithContent(contentState);
      }

      return EditorState.createEmpty();
    },
    [messageData?.comment],
  );

  const [threadId, setThreadId] = useState(null);
  const [editorState, setEditorState] = useState(getInitialCommentBodyContent);

  useEffect(() => {
    if (comments?.length && comments?.[0]?.thread_id) {
      setThreadId(comments?.[0]?.thread_id);
    }
  }, [comments]);

  /** Rendered when editMode is false. */
  const renderReadOnlyView = () => {
    const authorName = usersByUri[messageData?.user_uri]?.name || 'Unknown';

    return (
      <div
        id="threads-comment-readonly"
        key={messageData.uri}
        className="comment-item"
      >
        <div className="d-flex flex-1 flex-direction-column">
          <div className="d-flex align-items-center">
            <div className="comment-item-name">{authorName}</div>
            <div className="ml15">
              <FormattedDateTime value={messageData.created} />
            </div>
          </div>
          <TextEditor
            editorState={editorState}
            placeholder={editMode ? 'Add a comment...' : null}
            plugins={[TextEditor]}
            readOnly
          />
        </div>
      </div>
    );
  };

  const EDITOR_MAX_LENGTH = 1024;

  /**
   * `handleBeforeInput` and `handlePastedText` return either `handled` or `not-handled`
   * to indicate whether the event has been fully handled by custom logic.
   */
  const onChangeHandlerTypes = {
    /** Reject */
    HANDLED: 'handled',
    /** Accept */
    NOT_HANDLED: 'not-handled',
  };

  const handleBeforeInput = (inputString, editorState) => {
    const currentContent = editorState.getCurrentContent();
    const currentContentLength = currentContent.getPlainText('').length;

    if (currentContentLength + inputString.length > EDITOR_MAX_LENGTH) {
      return onChangeHandlerTypes.HANDLED;
    }
    return onChangeHandlerTypes.NOT_HANDLED;
  };

  const handlePastedText = (pastedText, html, editorState) => {
    const currentContent = editorState.getCurrentContent();
    const currentContentLength = currentContent.getPlainText('').length;

    if (currentContentLength + pastedText.length > EDITOR_MAX_LENGTH) {
      return onChangeHandlerTypes.HANDLED;
    }
    return onChangeHandlerTypes.NOT_HANDLED;
  };

  const sendThreadsMessage = async () => {
    const payload = {
      // ID of thread. If left as null, a new thread will be created.
      thread_id: threadId,
      // Record UUID of this given resource.
      record_uuid: resourceUUID,
      record_type: resourceTableName === RELATED_TABLE_NAMES.MODEL_LIBRARY ?
        RELATED_TABLE_NAMES.MODEL : resourceTableName,
      // Name of thread.
      name: `Discussion Thread for ${_upperFirst(_camelCase(resourceTableName))} [${getShortUUID(resourceUUID)}]`,
      comment: JSON.stringify(convertToRaw(editorState.getCurrentContent())),
    };
    // Result is automatically inserted into Redux
    const response = await dispatch(Actions.Api.nautilus[API_RESOURCES.THREADS_MESSAGES].post(payload));
    // No thread id means we just created a thread - assign it so we now
    // post messages to said thread
    if (!threadId) {
      setThreadId(response?.json?.thread_id);
      Alert.success(`Created thread ${response?.json?.thread_id} and sent message successfully.`);
    }
    // Clear editor state
    setEditorState(EditorState.createEmpty());
  };

  return (
    <div id="threads-comment-editor-view" className="mt-1">
      {editMode ? (
        <div style={draftJsEditorStyles.editor} className="form-control">
          <TextEditor
            handleBeforeInput={handleBeforeInput}
            handlePastedText={handlePastedText}
            editorState={editorState}
            onChange={setEditorState}
            placeholder="Add a comment..."
            plugins={[TextEditor]}
            readOnly={!editMode}
          />
        </div>
      ) : renderReadOnlyView()}
      {editMode && (
        <div className="d-flex align-items-end justify-content-between">
          {!!comments?.length && (
            <Button
              variant="warning"
              target="_blank"
              type="button"
              size="sm"
              href={getThreadsAppRouteURI(THREADS_API_RESOURCES.THREAD, comments[0]?.thread_id)}
              className="threads-btn"
            >
              <Image src={ThreadsLogo} alt="Threads Logo" width={17} />
              {' '}
              Discuss in Threads
            </Button>
          )}
          <div className="buttons">
            <Button
              disabled={commentSubmitting}
              variant="danger"
              size="sm"
              className="hawking-secondary"
              onClick={() => setEditorState(EditorState.createEmpty())}
            >
              Cancel
            </Button>
            <Button
              disabled={commentSubmitting}
              variant="warning"
              className="threads-btn"
              size="sm"
              type="button"
              onClick={sendThreadsMessage}
            >
              <Image src={ThreadsLogo} alt="Threads Logo" width={17} />
              {' '}
              {comments?.length ? 'Send' : 'Create Thread and Comment'}
            </Button>
          </div>
        </div>
      )}
    </div>
  );
};

export default ThreadsComment;

ThreadsComment.defaultProps = {
  editMode: false,
  resourceTableName: null,
  resourceUUID: null,
};

ThreadsComment.propTypes = {
  editMode: PropTypes.bool,
  comments: PropTypes.arrayOf(PropTypes.shape({
    thread_id: PropTypes.string,
  })).isRequired,
  resourceTableName: PropTypes.string,
  resourceUUID: PropTypes.string,
  messageData: PropTypes.shape({}).isRequired,
};
