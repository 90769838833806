import React, { useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Actions from 'rapidfab/actions';

import Banner from 'rapidfab/components/admin/Banner';
import { API_RESOURCES } from 'rapidfab/constants';
import * as Selectors from 'rapidfab/selectors';

const BannerContainer = () => {
  const uuid = useSelector(Selectors.getBureauUUID);
  const letter = useSelector(Selectors.getBureauMessage);
  const connection = useSelector(Selectors.getBureauLink);
  const isSubmitting = useSelector(Selectors.getIsSubmitting);

  const dispatch = useDispatch();

  const handleSubmit = values => {
    const payload = {
      order_banner: { ...values },
    };
    dispatch(Actions.Api.nautilus[API_RESOURCES.BUREAU].put(uuid, payload));
  };

  const initialValues = useMemo(() => ({
    message: letter || '',
    link: connection || '',
  }), [letter, connection]);

  return (
    <Banner
      submitting={isSubmitting}
      onSubmit={handleSubmit}
      initialValues={initialValues}
    />
  );
};

export default BannerContainer;
