import React, { useState } from 'react';
import PropTypes from 'prop-types';
import {
  ListGroup,
  ListGroupItem,
  Card,
  Row,
  Col,
  NavItem,
  NavLink,
  Nav,
} from 'react-bootstrap';
import ServiceProviderJobContainer from 'rapidfab/containers/records/order/ServiceProviderJobContainer';
import { getShortUUID } from 'rapidfab/utils/uuidUtils';
import Feature from 'rapidfab/components/Feature';
import { FEATURES } from 'rapidfab/constants';

const JOB_TABS = {
  LINE_ITEMS: 'lineItems',
  STEPS: 'steps',
};

const LineItemServiceProviders = ({
  lineItem,
  serviceProviders,
  serviceProvidersByOutsourceProcessStepUri,
  lineItemJobsByServiceProviderUri,
  processStepJobsByServiceProviderUri,
  outsourceProcessSteps,
}) => {
  const [activeTab, setActiveTab] = useState(JOB_TABS.LINE_ITEMS);
  function renderServiceProvidersList(
    serviceProvidersToRender,
    outsourceProcessStep,
  ) {
    if (!serviceProvidersToRender.length) {
      return (
        <ListGroupItem>
          <Row>
            <Col xs={12}>
              <b>
                {outsourceProcessStep && (
                  <span>
                    No Service Providers Available
                    {activeTab === JOB_TABS.STEPS &&
                      ' for this Processor Service'}
                  </span>
                )}
              </b>
            </Col>
          </Row>
        </ListGroupItem>
      );
    }
    return serviceProvidersToRender.map(provider => {
      const uniqueKey = `${getShortUUID(provider.uuid)}-${getShortUUID(
        lineItem,
      )}${
        outsourceProcessStep ? `-${getShortUUID(outsourceProcessStep.uri)}` : ''
      }`;
      const formID = `record.service_provider_${uniqueKey}`;
      let job;

      if (activeTab === JOB_TABS.LINE_ITEMS) {
        job =
          // For regular bureau only (will not work on SP Role side)
          lineItemJobsByServiceProviderUri[provider.uri];
      }

      if (activeTab === JOB_TABS.STEPS) {
        const jobsByProcessStepUri =
          // For regular bureau only (will not work on SP Role side)
          processStepJobsByServiceProviderUri[provider.uri];

        job =
          jobsByProcessStepUri &&
          jobsByProcessStepUri[outsourceProcessStep.uri];
      }

      return (
        <ServiceProviderJobContainer
          key={uniqueKey}
          form={formID}
          provider={provider}
          lineItem={lineItem}
          job={job}
          outsourceProcessStepUri={
            outsourceProcessStep && outsourceProcessStep.uri
          }
        />
      );
    });
  }

  return (
    <Card bg="dark">
      <Card.Header className="pd-exp inverse">Service Providers</Card.Header>
      <div className="card-body-wrapper">
        <ListGroup
          fill
          className="order-providers-list-group panel--scrollable"
        >
          <ListGroupItem key="header" className="header-item mb15">
            <Feature featureName={FEATURES.SERVICE_PROVIDERS}>
              <Row>
                <Nav
                  variant="tabs"
                  activeKey={activeTab}
                  onSelect={setActiveTab}
                >
                  <NavItem>
                    <NavLink eventKey={JOB_TABS.LINE_ITEMS}>Line Items</NavLink>
                  </NavItem>
                  <NavItem>
                    <NavLink eventKey={JOB_TABS.STEPS}>Steps</NavLink>
                  </NavItem>
                </Nav>
              </Row>
            </Feature>
            <Row className="mt15">
              <Col xs={3}>
                <b>Service Provider</b>
              </Col>
              <Col xs={3}>
                <b>Cost</b>
              </Col>
              <Col xs={3}>
                <b>Due</b>
              </Col>
              <Col xs={3}>
                <b>Status</b>
              </Col>
            </Row>
          </ListGroupItem>

          {activeTab === JOB_TABS.LINE_ITEMS &&
            renderServiceProvidersList(serviceProviders)}

          {activeTab === JOB_TABS.STEPS && !outsourceProcessSteps.length && (
            <ListGroupItem>
              <Row>
                <Col xs={12}>
                  <b>
                    <span>
                      No Outsource Process Steps in current Production Workflow
                    </span>
                  </b>
                </Col>
              </Row>
            </ListGroupItem>
          )}

          {activeTab === JOB_TABS.STEPS &&
            outsourceProcessSteps.map(outsourceProcessStep => (
              <>
                {!!outsourceProcessStep && (
                  <ListGroupItem>
                    <Row>
                      <Col xs={12}>
                        <b>
                          <span>
                            {`${outsourceProcessStep.name}${
                              outsourceProcessStep.step_position
                                ? ` (Step ${outsourceProcessStep.step_position})`
                                : ''
                            }`}
                          </span>
                        </b>
                      </Col>
                    </Row>
                  </ListGroupItem>
                )}
                {renderServiceProvidersList(
                  serviceProvidersByOutsourceProcessStepUri[
                    outsourceProcessStep.uri
                  ],
                  outsourceProcessStep,
                )}
              </>
            ))}
        </ListGroup>
      </div>
    </Card>
  );
};

LineItemServiceProviders.propTypes = {
  lineItem: PropTypes.string.isRequired,
  serviceProviders: PropTypes.arrayOf(
    PropTypes.shape({
      uri: PropTypes.string.isRequired,
      source_service_provider: PropTypes.string.isRequired,
    }),
  ).isRequired,
  serviceProvidersByOutsourceProcessStepUri: PropTypes.objectOf(
    PropTypes.shape({}),
  ).isRequired,
  lineItemJobsByServiceProviderUri: PropTypes.objectOf(PropTypes.shape({}))
    .isRequired,
  processStepJobsByServiceProviderUri: PropTypes.objectOf(PropTypes.shape({}))
    .isRequired,
  outsourceProcessSteps: PropTypes.arrayOf(
    PropTypes.shape({
      uri: PropTypes.string.isRequired,
      name: PropTypes.string.isRequired,
      step_position: PropTypes.string.isRequired,
    }),
  ).isRequired,
};

export default LineItemServiceProviders;
