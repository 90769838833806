/* eslint-disable unicorn/no-array-reduce */
import _assign from 'lodash/assign';
import Config from 'rapidfab/config';

import 'isomorphic-fetch';
import _chunk from 'lodash/chunk';
import { LIST_BY_URIS_CHUNK_SIZE, MIME_TYPES } from 'rapidfab/constants';

export const FETCH_CONFIG = {
  headers: {
    Accept: 'application/json',
    'Content-Type': MIME_TYPES.JSON,
  },
};

const GET_CONFIG = {
  headers: {
    Accept: 'application/json',
  },
};

// Converts dictionary into sepida useable filter
export const filtersToQuery = (filters, page, search, query) => {
  let filterParams = '';
  if (filters) {
    filterParams = Object.keys(filters).reduce(
      (formattedFilters, filterKey) => {
        const firstFilterKeySymbol = filterKey[0];

        // A hack to support '>' and '<' in filters.
        // TODO: Find a better way to do this
        if (firstFilterKeySymbol === '<') {
          return `${formattedFilters}&filter[${filterKey.slice(1)}]<${encodeURIComponent(
            filters[filterKey],
          )}`;
        }
        if (firstFilterKeySymbol === '>') {
          return `${formattedFilters}&filter[${filterKey.slice(1)}]>${encodeURIComponent(
            filters[filterKey],
          )}`;
        }

        return `${formattedFilters}&filter[${filterKey}]=${encodeURIComponent(filters[filterKey])}`;
      },
      '',
    );
  }

  let pageParams = '';
  if (page) {
    pageParams = Object.keys(page).reduce(
      (formattedSC, scKey) =>
        `${formattedSC}&page[${scKey}]=${encodeURIComponent(page[scKey])}`,
      '',
    );
  }

  let searchParams = '';
  if (search) {
    searchParams = Object.keys(search).reduce(
      (formattedSC, scKey) =>
        `${formattedSC}&search[${scKey}]=${encodeURIComponent(search[scKey])}`,
      '',
    );
  }

  let queryParams = '';
  if (query) {
    queryParams = Object.keys(query).reduce(
      (formattedSC, scKey) =>
        `${formattedSC}&${scKey}=${encodeURIComponent(query[scKey])}`,
      '',
    );
  }

  let fullQueryString = `${filterParams}${pageParams}${searchParams}${queryParams}`;

  if (fullQueryString) {
    // If string is not empty, there are always should be ampersand at the
    // start of string
    fullQueryString = fullQueryString.slice(1);
  }

  return fullQueryString;
};

function sanitizePayload(payload) {
  if (Array.isArray(payload)) {
    return payload;
  }

  const sanitizedPayload = { ...payload };
  if (sanitizedPayload.id) delete sanitizedPayload.id;
  if (sanitizedPayload.uri) delete sanitizedPayload.uri;
  if (sanitizedPayload.uuid) delete sanitizedPayload.uuid;
  // `_meta` is used internally in Rapidfab and is not related to Nautilus.
  // eslint-disable-next-line no-underscore-dangle
  if (sanitizedPayload._meta) delete sanitizedPayload._meta;
  return sanitizedPayload;
}

function makePut(hostRoot, resource) {
  return (uuid, payload, config) => {
    const sanitizedPayload = sanitizePayload(payload);

    return fetch(
      `${hostRoot}/${resource}/${uuid}/`,
      _assign(
        {},
        FETCH_CONFIG,
        {
          credentials: 'include',
          method: 'put',
          body: JSON.stringify(sanitizedPayload),
        },
        config,
      ),
    );
  };
}

function makeDelete(hostRoot, resource) {
  return (uuid, config) => {
    const url = uuid
      ? `${hostRoot}/${resource}/${uuid}/`
      : `${hostRoot}/${resource}/`;
    return fetch(
      url,
      _assign(
        {},
        FETCH_CONFIG,
        {
          credentials: 'include',
          method: 'delete',
        },
        config,
      ),
    );
  };
}

function makePost(hostRoot, resource) {
  return (payload, config) => {
    let sanitizedPayload = sanitizePayload(payload);
    if (config?.skipSanitize) {
      sanitizedPayload = payload;
    }

    return fetch(
      `${hostRoot}/${resource}/`,
      _assign(
        {},
        FETCH_CONFIG,
        {
          credentials: 'include',
          method: 'post',
          body: JSON.stringify(sanitizedPayload),
        },
        config,
      ),
    );
  };
}

function makeClone(hostRoot, resource) {
  return (uuid, payload, config) => {
    const sanitizedPayload = sanitizePayload(payload);
    return fetch(
      `${hostRoot}/${resource}/${uuid}/clone/`,
      _assign(
        {},
        FETCH_CONFIG,
        {
          credentials: 'include',
          method: 'post',
          body: JSON.stringify(sanitizedPayload),
        },
        config,
      ),
    );
  };
}

function makeReplace(hostRoot, resource) {
  return (uuid, payload, config) => {
    const sanitizedPayload = sanitizePayload(payload);
    return fetch(
      `${hostRoot}/${resource}/${uuid}/replace/`,
      _assign(
        {},
        FETCH_CONFIG,
        {
          credentials: 'include',
          method: 'post',
          body: JSON.stringify(sanitizedPayload),
        },
        config,
      ),
    );
  };
}

function makeGet(hostRoot, resource) {
  return (uuid, config, query) => {
    let url = uuid;
    if (!uuid?.startsWith('http')) {
      url = `${hostRoot}/${resource}/`;
      if (uuid) url += `${uuid}/`;
    }
    let queryParams = '';
    if (query) {
      queryParams = '?';
      queryParams += Object.keys(query).reduce(
        (formattedFilters, filterKey) =>
          `${formattedFilters}&filter[${filterKey}]=${encodeURIComponent(query[filterKey])}`,
        '',
      ).slice(1);
      url += queryParams;
    }

    return fetch(
      url,
      _assign(
        {
          credentials: 'include',
        },
        GET_CONFIG,
        config,
      ),
    );
  };
}

export function doGet(url) {
  return fetch(
    url,
    _assign({
      credentials: 'include',
      GET_CONFIG,
    }),
  );
}

function makeList(hostRoot, resource) {
  return (filters, page, config, searchParams, queryParams, apiVersion) => {
    /* This method checks if we have the filters, then checking if it has
       the Array type of data, and checks the size of the Array.
       If it is more than LIST_BY_URIS_CHUNK_SIZE -> it will return
       the key of the data and its value as an object.
     */
    const handleFiltersToChunk = filters => {
      if (filters) {
        const apiLayers = Object.entries(filters).map(([key, value]) => {
          if (value && Array.isArray(value) && value.length > LIST_BY_URIS_CHUNK_SIZE) {
            return {
              key,
              value,
            };
          }
          return false;
        }).filter(Boolean);

        return apiLayers.length ? apiLayers : null;
      }

      return null;
    };

    /* When the .list() method is called, we will check if
       we have filters and whether we need to chunk the data. */
    const filtersToChunk = handleFiltersToChunk(filters);
    if (filters && filtersToChunk) {
      /* If we find the data which we need to chunk and send the multiple API requests
         instead of the one call with a lot if payload data -> we will use this method below.

         So, the logic below will work ONLY in case we have the data (Array) which size
         is more than LIST_BY_URIS_CHUNK_SIZE, and we need to chunk it and send multiple API calls.
   */
      const filterPromises = filtersToChunk.map(({ key, value }) => {
        /* value = the Array contains of the uris (usually)
           key = the name of the filter we will send API call for
           LIST_BY_URIS_CHUNK_SIZE = the number (size) of the data we need to chunk */
        const chunks = _chunk(value, LIST_BY_URIS_CHUNK_SIZE);
        const chunkPromises = chunks.map(chunkFilters => {
          const query = filtersToQuery({ [key]: chunkFilters }, page, searchParams, queryParams);
          let resourceURI = `${hostRoot}/${resource}/?${query}`;
          if (apiVersion) {
            resourceURI = `${hostRoot}/${apiVersion}/${resource}/?${query}`;
          }
          return fetch(resourceURI, _assign(
            {
              credentials: 'include',
            },
            GET_CONFIG,
            config,
          ));
        });
        return Promise.all(chunkPromises);
      });

      return Promise.all(filterPromises);
    }

    const query = filtersToQuery(filters, page, searchParams, queryParams);

    const fetchConfig = _assign(
      {
        credentials: 'include',
      },
      GET_CONFIG,
      config,
    );
    let resourceURI = `${hostRoot}/${resource}/?${query}`;
    if (apiVersion) {
      resourceURI = `${hostRoot}/${apiVersion}/${resource}/?${query}`;
    }
    return fetch(resourceURI, fetchConfig);
  };
}

export function postForm(
  url,
  payload,
  file,
  method = 'POST',
  withCredentials,
  contentType,
  progressCallback,
  onSuccessCallback,
  contentDisposition,
) {
  const promise = new Promise((resolve, reject) => {
    const data = new FormData();
    Object.keys(payload).forEach(key => {
      data.append(key, payload[key]);
    });
    Object.keys(file).forEach(key => {
      // DropZone component adds `path` to uploaded files due it's internal logic
      // So we have to ignore the `path` here, since it is not allowed by browsers anymore.
      if (key !== 'path') {
        data.append(key, file[key], file[key].name);
      }
    });
    const http = new XMLHttpRequest();
    if (Object.prototype.hasOwnProperty.call(http, 'withCredentials')) {
      http.withCredentials = !!withCredentials;
    }

    const handleProgress = event => {
      const percent = Math.floor((event.loaded / event.total) * 1000) / 10;
      progressCallback(percent);
    };

    http.addEventListener('progress', handleProgress, false);
    if (http.upload) {
      http.upload.onprogress = handleProgress;
    }

    http.onload = () => {
      onSuccessCallback();
      resolve(http.responseText);
    };

    http.addEventListener('error', reject, false);
    http.addEventListener('abort', reject, false);

    http.open(method, url, true);

    // skip content-type for filenames with non-ascii characters quick-fix
    if (contentDisposition && contentType !== MIME_TYPES.OCTET_STREAM) {
      http.setRequestHeader('Content-Disposition', contentDisposition);
    }

    if (contentType) {
      http.setRequestHeader('Content-Type', contentType);
      http.send(file);
    } else {
      http.send(data);
    }
  });
  return promise;
}

const makeApi = api =>
  Object.keys(api).reduce((hosts, host) => {
    const hostRoot = Config.HOST[host.toUpperCase()];
    return { ...hosts,
      [host]: api[host].reduce(
        (resources, resource) =>
          ({ ...resources,
            [resource]: {
              post: makePost(hostRoot, resource),
              list: makeList(hostRoot, resource),
              delete: makeDelete(hostRoot, resource),
              put: makePut(hostRoot, resource),
              get: makeGet(hostRoot, resource),

              clone: makeClone(hostRoot, resource),
              replace: makeReplace(hostRoot, resource),
            } }),
        {},
      ) };
  }, {});

export default makeApi;
