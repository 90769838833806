export default function isOrderQuoteAvailable(
  order,
  orderQuoteFeatureEnabled,
  orderQuoteSpecialProcessingFeatureEnabled,
) {
  // Order quote available for users with
  // Enabled "order-quote" feature AND
  //   Disabled orderQuoteSpecialProcessing Feature
  //   OR
  //   Enabled orderQuoteSpecialProcessing Feature and unchecked special_processing_required input
  if (!order) {
    return false;
  }

  if (!orderQuoteFeatureEnabled) {
    return false;
  }

  if (!orderQuoteSpecialProcessingFeatureEnabled) {
    return true;
  }

  return !order.special_processing_required;
}
