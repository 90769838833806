import React from 'react';
import Loading from 'rapidfab/components/Loading';
import { Button, FormControl, Modal } from 'react-bootstrap';
import { Field, Form } from 'react-final-form';
import { FormattedMessage } from 'react-intl';
import FormRow from 'rapidfab/components/FormRow';
import PropTypes from 'prop-types';

const AddToCartModal = ({
  show,
  onClose,
  handleSaveShoppingCart,
  addToCartFetching,
}) => (
  <Modal show={show}>
    <Form
      onSubmit={formValues => handleSaveShoppingCart(Number(formValues.quantity))}
      render={({ handleSubmit }) => (
        <>
          <Modal.Header onHide={onClose} closeButton>
            <FormattedMessage
              id="modelLibrary.shoppingCart.addToCart"
              defaultMessage="Add to Cart"
            />
          </Modal.Header>
          <Modal.Body>
            <Field
              name="quantity"
              initialValue={1}
              render={({ input }) => (
                <FormRow id="field.quantity" defaultMessage="Quantity">
                  <FormControl
                    name="quantity"
                    type="number"
                    defaultValue={1}
                    onChange={input.onChange}
                  />
                </FormRow>
              )}
            />
          </Modal.Body>
          <Modal.Footer>
            <Button
              bg="danger"
              variant="danger"
              onClick={onClose}
            >
              <FormattedMessage
                id="button.cancel"
                defaultMessage="Cancel"
              />
            </Button>
            <Button
              variant="success"
              bg="success"
              onClick={handleSubmit}
            >
              {addToCartFetching ?
                <Loading /> : (
                  <FormattedMessage
                    id="button.save"
                    defaultMessage="Save"
                  />
                )}
            </Button>
          </Modal.Footer>
        </>
      )}
    />
  </Modal>
);

export default AddToCartModal;

AddToCartModal.propTypes = {
  show: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  handleSaveShoppingCart: PropTypes.func.isRequired,
  model: PropTypes.shape({}).isRequired,
  addToCartFetching: PropTypes.bool.isRequired,
};
