import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import Actions from 'rapidfab/actions';
import { API_RESOURCES, FEATURES } from 'rapidfab/constants';
import withRecordsListHandling from 'rapidfab/containers/hocs/withRecordsListHandling';
import { getLabels, isFeatureEnabled } from 'rapidfab/selectors';

import Labels from 'rapidfab/components/admin/Labels';

const LabelsContainer = props => {
  const isHawkingUser = useSelector(state => isFeatureEnabled(state, FEATURES.HAWKING_DEPLOYMENT));

  const dispatch = useDispatch();
  const onDelete = uuid => dispatch(Actions.Api.nautilus[API_RESOURCES.LABEL].delete(uuid));
  return (
    <Labels {...props} isHawkingUser={isHawkingUser} onDelete={onDelete} />
  );
};

LabelsContainer.propTypes = {
  onInitialize: PropTypes.func.isRequired,
  labels: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
};

export default withRecordsListHandling(
  LabelsContainer,
  getLabels,
  ['label'],
  {
    defaultSort: '-name',
  },
);
