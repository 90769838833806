import { createSelector } from 'reselect';
import * as baseStateSelectors from 'rapidfab/selectors/baseStateSelectors';
import { getPredicate, getStateResources } from 'rapidfab/selectors/helpers/base';
import _map from 'lodash/map';
import _find from 'lodash/find';
import _keyBy from 'lodash/keyBy';

export const getWorkSchedules = createSelector(
  [baseStateSelectors.getStateWorkSchedule, getStateResources],
  (uuids, resources) => _map(uuids, uuid => resources[uuid]),
);

export const getWorkSchedulesForPostProcessorType = createSelector(
  [getPredicate, getWorkSchedules],
  (postProcessorTypeUri, workSchedules) => {
    if (!postProcessorTypeUri) {
      return null;
    }
    return _find(workSchedules, ({ post_processor_type: processorUri }) =>
      processorUri === postProcessorTypeUri);
  },
);
export const getWorkSchedulesForPrinterType = createSelector(
  [getPredicate, getWorkSchedules],
  (printerTypeUri, workSchedules) => {
    if (!printerTypeUri) {
      return null;
    }
    return _find(workSchedules, ({ printer_type: uri }) => uri === printerTypeUri);
  },
);

export const getWorkSchedulesByPostProcessorType = createSelector(
  [getWorkSchedules], workSchedules => _keyBy(workSchedules, 'post_processor_type'),
);

export const getWorkSchedulesByPrinterType = createSelector(
  [getWorkSchedules], workSchedules => _keyBy(workSchedules, 'printer_type'),
);
