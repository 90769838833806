import { resetRcTooltipInnerStyle } from 'rapidfab/constants/styles';
import RCTooltip from 'rc-tooltip';
import React from 'react';
import { Row, Col, FormGroup, Form } from 'react-bootstrap';
import { FormattedMessage } from 'react-intl';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faInfoCircle } from '@fortawesome/free-solid-svg-icons';
import PropTypes from 'prop-types';

import { getRouteUUID } from 'rapidfab/selectors';
import { useSelector } from 'react-redux';
import Tooltip from './Tooltip';

const IntegratedWorkstationCheck = ({
  isIntegratedWorkstationEnabled,
  toggleIntegratedWorkStation,
}) => {
  // Checks to see if were on a existing resource or if we are creating a new resource
  const isExistingMachine = useSelector(getRouteUUID);
  return (
    <FormGroup className="mb15">
      <Row className="d-flex justify-content-between align-items-center">
        <Col xs={7}>
          <FormattedMessage
            id="integratedWorkstation"
            defaultMessage="Integrated Workstation"
          />:

          <RCTooltip
            placement="bottom"
            id="integratedWorkstation.detail.tooltip"
            destroyTooltipOnHide
            overlayInnerStyle={resetRcTooltipInnerStyle}
            mouseLeaveDelay={0.4}
            overlay={(
              <p className="mb0 darkTooltip">
                {isExistingMachine ? (
                  `Workstation ${isIntegratedWorkstationEnabled ? 'is' : 'is not'} integrated`
                ) : (
                  'Choose to set this workstation as an integrated workstation or not'
                ) }
              </p>
            )}
          >
            <FontAwesomeIcon className="spacer-left spacer-right" icon={faInfoCircle} />
          </RCTooltip>
        </Col>
        <Col>
          <div className="d-flex align-items-center pull-right">
            <FormattedMessage
              id="enableintegratedWorkstation"
              defaultMessage="Enable integrated Workstation"
            />
            {!isExistingMachine ? (
              <Form.Check
                checked={isIntegratedWorkstationEnabled}
                type="switch"
                id="custom-switch"
                className="spacer-left"
                onChange={toggleIntegratedWorkStation}
              />
            ) : (
              <Tooltip
                id="integratedWorkstation.detail.tooltip"
                placement="top"
                trigger={(
                  <Form.Check
                    checked={isIntegratedWorkstationEnabled}
                    type="switch"
                    id="custom-switch"
                    className="spacer-left"
                    disabled
                    readOnly
                  />
                )}
              >
                {`This workstation ${isIntegratedWorkstationEnabled ? 'is' : 'is not'} an integrated. You can not change the workstations type`}
              </Tooltip>
            )}

          </div>
        </Col>

      </Row>
    </FormGroup>
  );
};

IntegratedWorkstationCheck.propTypes = {
  toggleIntegratedWorkStation: PropTypes.func.isRequired,
  isIntegratedWorkstationEnabled: PropTypes.bool.isRequired,
};
export default IntegratedWorkstationCheck;
