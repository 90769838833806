import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Button, Col, Container, Modal, Row } from 'react-bootstrap';
import '../../styles/hawking/main.scss';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { FormattedMessage } from 'rapidfab/i18n';
import { useIntl } from 'react-intl';
import { faCheckSquare, faClose, faInfoCircle, faPencil, faSearchPlus, faSquare, faTrash } from '@fortawesome/free-solid-svg-icons';
import { customFieldResourceType } from '../../types';
import { CUSTOM_FIELD_RIGHTS } from '../../constants';
import SelectSingle from '../forms/SelectSingle';
import Tooltip from '../Tooltip';

const CustomFieldDetailModal = ({
  onClose,
  onOpenEditModal,
  isHawkingUser,
  customField,
  onDeleteCustomField,
}) => {
  const intl = useIntl();
  const enabledTooltipMessage = intl.formatMessage({
    id: 'custom_field.detail.enabledTooltip',
    defaultMessage: 'Disabled fields do not take new input, but will show up read-only if old data is in them',
  });
  const entriesTooltipMessage = intl.formatMessage({
    id: 'custom_field.valuesQuantity.tooltip',
    defaultMessage: 'This field has been used in {count} {related_table_name}{count, plural, one {.} other {s.}}',
  },
  { count: customField.use_count, related_table_name: customField.related_table_name },
  );
  const [optionsSelected, setOptionsSelected] = useState(customField.options ? customField.options[0]?.value : null);
  const use = CUSTOM_FIELD_RIGHTS.READ in customField.rights
  && CUSTOM_FIELD_RIGHTS.WRITE in customField.rights ? 'Read-Write' : 'Read Only';
  return (
    <Modal show onHide={onClose} backdrop="static">
      <Modal.Header>
        <div className="d-flex align-items-center w-full">
          <Modal.Title className="w-full">
            <FormattedMessage
              id="custom_field.detail.title"
              defaultMessage="Custom Field: {fieldId}"
              values={{ fieldId: customField.field_id }}
            />
          </Modal.Title>
          <Button
            size="sm"
            className="spacer-right"
            variant="primary"
            style={{ marginRight: 5 }}
            onClick={() => onOpenEditModal(customField.uri)}
            disabled={customField.smart}
          >
            <FontAwesomeIcon icon={faPencil} />
          </Button>
          <Button
            onClick={onClose}
            size="sm"
            className={isHawkingUser && 'hawking-secondary'}
          >
            <FontAwesomeIcon icon={faClose} />
          </Button>
        </div>
      </Modal.Header>
      <Modal.Body>
        <Container fluid>
          <Row className="m-b-sm">
            <Col xs={6}>Related Table: {customField.related_table_name}</Col>
            <Col xs={6}>Field Key: {customField.field_id}</Col>
          </Row>
          <Row className="m-b-sm">
            <Col xs={6}>Data Type: <span className="text-capitalize">{customField.type}</span></Col>
            <Col xs={6}>Data Format: <span className="text-capitalize">{customField.format || 'None'}</span></Col>
          </Row>
          <Row className="m-b-sm">
            <Col xs={6}>
              <FontAwesomeIcon className="spacer-right" icon={customField.is_smart ? faCheckSquare : faSquare} />
              <span>Smart Field</span>
            </Col>
            <Col xs={6}>Units: <span className="text-capitalize">{customField.meta?.unit || 'None'}</span></Col>
          </Row>
          <Row>
            <Col xs={12}>Use: Standard User {use}</Col>
          </Row>
        </Container>
        <hr className="m-a-sm" />
        <div className="m-l-sm m-b-sm"><b>Field Contents:</b></div>
        <Container fluid>
          <Row className="m-b-sm">
            <Col xs={12}>Tooltip: &quot;{customField.meta.tooltip || 'None'}&quot;</Col>
          </Row>
          <Row className="m-b-sm">
            <Col xs={6}>Default Value: {customField.default_value || 'None'}</Col>
            <Col xs={6}>
              <span>Value Required: &nbsp;</span>
              <FontAwesomeIcon className="spacer-right" icon={customField.required ? faCheckSquare : faSquare} />
            </Col>
          </Row>
          {customField.options?.length > 0 && (
            <Row className="m-b-sm">
              <Col xs={6}>Options: </Col>
              <Col xs={6}>
                <div className="picky-spacer-wrapper">
                  <SelectSingle
                    name="options"
                    required
                    data={customField.options}
                    value={optionsSelected}
                    handleOnChange={({ target }) => {
                      setOptionsSelected(target.value);
                    }}
                    valueKey="value"
                    labelKey="key"
                    includeFilter={false}
                    imitateOnChangeEvent
                  />
                </div>
              </Col>
            </Row>
          )}
          <Row id="customFieldValues.enabled" className="m-b-sm">
            <Col xs={12}>
              <FontAwesomeIcon className="spacer-right" icon={customField.enabled ? faCheckSquare : faSquare} />
              <span>Field is Enabled</span>
              <Tooltip
                id="custom_field.detail.tooltip"
                placement="bottom"
                trigger={<FontAwesomeIcon className="spacer-left spacer-right" icon={faInfoCircle} />}
              >
                {enabledTooltipMessage}
              </Tooltip>

            </Col>
          </Row>

          <Row id="customFieldValues.isVisibleForRestricted">
            <Col xs={12}>
              <FontAwesomeIcon className="spacer-right" icon={customField.is_visible_for_restricted ? faCheckSquare : faSquare} />
              <span>Is Visible for Restricted Users</span>
            </Col>
          </Row>
        </Container>

        <hr className="m-a-sm" />
        <div className="m-l-sm m-b-sm"><b>Display Settings:</b></div>
        <Container fluid>
          <Row className="m-b-sm">
            <Col xs={12}>Placeholder Value: {customField.meta.placeholder || 'None'}</Col>
          </Row>
          <Row className="m-b-sm">
            <Col xs={12}>Display Position: {customField.position}</Col>
          </Row>
          <Row className="m-b-sm">
            <Col xs={12}>Display Name: {customField.field_name}</Col>
          </Row>
          <Row className="m-b-sm">
            <Col xs={12}>Description: {customField.description || 'None'}</Col>
          </Row>
        </Container>

      </Modal.Body>
      <Modal.Footer>
        {customField.use_count > 0 && (
          <Tooltip
            id="custom_field.valuesQuantity.tooltip"
            placement="left"
            trigger={<span className="m-r-sm"><FontAwesomeIcon icon={faSearchPlus} /> &nbsp;+{customField.use_count}</span>}
          >
            {entriesTooltipMessage}
          </Tooltip>
        )}
        <Button variant="danger" onClick={() => onDeleteCustomField(customField)} disabled>
          <FontAwesomeIcon icon={faTrash} />
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

CustomFieldDetailModal.propTypes = {
  onClose: PropTypes.func.isRequired,
  customField: customFieldResourceType.isRequired,
  isHawkingUser: PropTypes.bool.isRequired,
  onOpenEditModal: PropTypes.func.isRequired,
  onDeleteCustomField: PropTypes.func.isRequired,
};

export default CustomFieldDetailModal;
