import { createSelector } from 'reselect';
import * as baseStateSelectors from 'rapidfab/selectors/baseStateSelectors';
import { getStateResources } from 'rapidfab/selectors/helpers/base';
import _map from 'lodash/map';
import _keyBy from 'lodash/keyBy';

export const getProcessSteps = createSelector(
  [baseStateSelectors.getStateProcessStep, getStateResources],
  (uuids, resources) => _map(uuids, uuid => resources[uuid]),
);

export const getProcessStepsByUri = createSelector(
  [getProcessSteps],
  processSteps => _keyBy(processSteps, 'uri'),
);

export const getProcessStepsByProcessTypeUri = createSelector(
  [getProcessSteps],
  processSteps => _keyBy(processSteps, 'workstation_type_uri'),
);
