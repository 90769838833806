import React from 'react';
import NewOrderForm from 'rapidfab/components/records/order/new/NewOrderForm';

const NewRestrictedOrderFormContainer = props => (
  <NewOrderForm
    {...props}
  />
);

export default NewRestrictedOrderFormContainer;
