import { getRouteURI } from 'rapidfab/utils/uriUtils';
import { getShortUUID } from 'rapidfab/utils/uuidUtils';
import React from 'react';
import PropTypes from 'prop-types';
import { Button, Col, Container, Row } from 'react-bootstrap';
import { FormattedMessage } from 'react-intl';
import BreadcrumbNav from 'rapidfab/components/BreadcrumbNav';
import Loading from 'rapidfab/components/Loading';
import { ROUTES } from 'rapidfab/constants';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus } from '@fortawesome/free-solid-svg-icons';
import Table from 'rapidfab/components/Tables/Table';
import { Link } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { isVendorServiceProvider } from 'rapidfab/selectors';

const ToolingStockTypes = ({
  toolingStockTypes,
  fetching,
  setIsCreateToolingStockTypeModalShown,
}) => {
  const isVendorUser = useSelector(isVendorServiceProvider);
  const columns = [
    {
      type: 'custom',
      uid: 'field.id',
      accessor: 'uuid',
      defaultMessage: 'ID',
      resource: 'tooling-type',
      Cell: ({ row: { original: data } }) => (
        <Link
          to={getRouteURI(
            isVendorUser
              ? ROUTES.VENDOR_TOOLING_STOCK_TYPES
              : ROUTES.TOOLING_STOCK_TYPES,
            null,
            { uuid: data.uuid },
            true,
          )}
        >
          {getShortUUID(data.uri)}
        </Link>
      ),
    },
    {
      type: 'text',
      uid: 'field.name',
      accessor: 'name',
      defaultMessage: 'Name',
      isSortable: true,
    },
    {
      type: 'text',
      uid: 'field.tooling_stock_refurbish_after',
      accessor: 'refurbish_after',
      defaultMessage: 'Refurbish After',
      isSortable: true,
    },
  ];
  return (
    <Container fluid>
      <BreadcrumbNav breadcrumbs={['inventory', 'toolingStockTypes']} />
      {!isVendorUser && (
        <Row>
          <Col xs={12}>
            <div className="pull-right">
              <Button
                variant="primary"
                size="sm"
                className="pull-right"
                onClick={() => setIsCreateToolingStockTypeModalShown(true)}
              >
                <FontAwesomeIcon icon={faPlus} />{' '}
                <FormattedMessage
                  id="record.tooling_stock_type.add"
                  defaultMessage="Add Tool Type"
                />
              </Button>
            </div>
          </Col>
        </Row>
      )}

      <hr />

      <Row>
        <Col xs={12}>
          {fetching ? (
            <Loading />
          ) : (
            <Table
              tableID="toolingStockTypes"
              data={toolingStockTypes}
              isFetching={fetching}
              columns={columns}
              isFilteringEnabled
              withDefaultPagination
              isManualSoringEnabled={false}
              initialSortedColumn="name"
              initialSortedDesc={false}
              isUpdatedColumnShown={false}
            />
          )}
        </Col>
      </Row>
    </Container>
  );
};

ToolingStockTypes.defaultProps = {
  row: null,
};

ToolingStockTypes.propTypes = {
  toolingStockTypes: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  fetching: PropTypes.bool.isRequired,
  row: PropTypes.shape({
    original: PropTypes.shape({
      uri: PropTypes.string.isRequired,
      uuid: PropTypes.string.isRequired,
      refurbish_after: PropTypes.number.isRequired,
      name: PropTypes.string.isRequired,
    }),
  }),
  setIsCreateToolingStockTypeModalShown: PropTypes.func.isRequired,
};

export default ToolingStockTypes;
