import { createSelector } from 'reselect';
import { getPredicate } from 'rapidfab/selectors/helpers/base';
import { getSpecimens } from 'rapidfab/selectors/specimen';
import { getModelLibraries } from 'rapidfab/selectors/modelLibrary';
import { getSession } from 'rapidfab/selectors/session';
import { getBureau } from 'rapidfab/selectors/bureau';
import _filter from 'lodash/filter';

// eslint-disable-next-line import/prefer-default-export
export const getSpecimensForModelLibrary = createSelector(
  [getPredicate, getSpecimens],
  (modelLibrary, specimens) => (
    modelLibrary
      ? _filter(specimens, { model_library: modelLibrary.uri })
      : []
  ),
);

export const getMyModelLibraries = createSelector(
  [getModelLibraries, getSession],
  (modelLibraries, user) => _filter(modelLibraries, { owner: user.uri }),
);

export const getCompanyModelLibraries = createSelector(
  [getModelLibraries, getBureau],
  (modelLibraries, bureau) => _filter(modelLibraries, { owner: bureau.uri }),
);
