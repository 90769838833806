import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Button } from 'react-bootstrap';
import { connect } from 'react-redux';
import Actions from 'rapidfab/actions';
import * as Selectors from 'rapidfab/selectors';
import { FormattedDateTime, FormattedMessage } from 'rapidfab/i18n';
import MappedColumn from 'rapidfab/components/RecordList/MappedColumn';
import { extractUuid } from 'rapidfab/utils/uuidUtils';
import { API_RESOURCES, COMMENT_ACTION_STATUSES } from 'rapidfab/constants';
import { COMMENT_ACTION_STATUSES_MAP } from 'rapidfab/mappings';
import { faCheck } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Loading from '../Loading';

const commentActionTransformations = {
  [COMMENT_ACTION_STATUSES.NEW]: {
    transformsTo: COMMENT_ACTION_STATUSES.IN_PROGRESS,
    text: (<FormattedMessage id="start" defaultMessage="Start" />),
    variant: 'primary',
  },
  [COMMENT_ACTION_STATUSES.IN_PROGRESS]: {
    transformsTo: COMMENT_ACTION_STATUSES.COMPLETE,
    text: (<FormattedMessage id="status.complete" defaultMessage="Complete" />),
    variant: 'primary',
  },
};

const CommentHeaderAction = (
  { action, onSubmit, usersByUri, currentUser, submittingCommentAction },
) => {
  // submitting from props is related to any comment action update
  // state is related to current action
  const [isSubmitting, setSubmitting] = useState(true);

  useEffect(() => {
    // Is current action is updating and fetching is completed,
    // then comment action is updated
    if (isSubmitting && !submittingCommentAction) {
      setSubmitting(false);
    }
  }, [submittingCommentAction]);

  const assigneeUser = usersByUri[action.assignee_user];
  const isCurrentUserAssignee = currentUser.uri === action.assignee_user;

  // Visible only is user is assigned
  const statusTransformation = isCurrentUserAssignee && commentActionTransformations[action.status];

  const onCommentActionStatusChange = nextStatus => {
    setSubmitting(true);

    onSubmit(extractUuid(action.uri), nextStatus).catch(() => {
      setSubmitting(false);
    });
  };

  const getAssigneeName = () => {
    if (isCurrentUserAssignee) {
      // is rendered in different way
      return null;
    }

    if (assigneeUser) {
      return `${assigneeUser.name} (${assigneeUser.username})`;
    }

    // Assigned, but cannot GET user by URI, which means deleted user
    if (action.assignee_user) {
      return 'Deleted User';
    }

    return ''; // We should not have this case
  };

  const getAssignmentVerboseStatus = () => {
    if (isCurrentUserAssignee && action.status === COMMENT_ACTION_STATUSES.NEW) {
      return null;
    }

    if (action.status === COMMENT_ACTION_STATUSES.COMPLETE) {
      // Handled as prefix "Completed by ..."
      return null;
    }

    // Deleted user case: action has assignee but cannot GET user by uri
    if (!assigneeUser && action.assignee_user) {
      return null;
    }

    return <MappedColumn mapping={COMMENT_ACTION_STATUSES_MAP} value={action.status} />;
  };

  if (!action) {
    // Not loaded yet
    return null;
  }

  return (
    <div className="comment-item-action">
      {
        action.status === COMMENT_ACTION_STATUSES.COMPLETE
          ? <><MappedColumn mapping={COMMENT_ACTION_STATUSES_MAP} value={action.status} /> by</>
          : 'Assigned to'
      }

      {' '}
      { isCurrentUserAssignee ? <strong>you</strong> : getAssigneeName() }

      {' '}
      { getAssignmentVerboseStatus() }

      {' '}
      { action.completed && <>(<FormattedDateTime value={action.completed} />) <FontAwesomeIcon icon={faCheck} /> </> }

      {
        statusTransformation && (
          <Button
            className="m-l"
            size="xs"
            variant={statusTransformation.bsStyle}
            onClick={() => onCommentActionStatusChange(statusTransformation.transformsTo)}
            disabled={isSubmitting}
          >
            {isSubmitting ?
              <Loading inline />
              :
              statusTransformation.text}
          </Button>
        )
      }
    </div>
  );
};

CommentHeaderAction.propTypes = {
  action: PropTypes.shape({
    uri: PropTypes.string,
    status: PropTypes.string,
    assignee_user: PropTypes.string,
    completed: PropTypes.string,
  }).isRequired,
  onSubmit: PropTypes.func.isRequired,
  usersByUri: PropTypes.shape({}).isRequired,
  currentUser: PropTypes.shape({
    uri: PropTypes.string,
  }).isRequired,
  submittingCommentAction: PropTypes.bool.isRequired,
};

function mapStateToProps(state) {
  return {
    currentUser: Selectors.getSession(state),
    submittingCommentAction: state.ui.nautilus[API_RESOURCES.COMMENT_ACTION].put.fetching,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    onSubmit(actionUUID, nextStatus) {
      return dispatch(Actions.Api.nautilus[API_RESOURCES.COMMENT_ACTION].put(actionUUID, {
        status: nextStatus,
      }));
    },
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(CommentHeaderAction);
