import React from 'react';
import PropTypes from 'prop-types';
import {
  getEventReferenceResourceType,
  EVENT_REFERENCE_TYPE_MAPPING,
  isPlainHeadingKeyEvent,
  getCustomEventHeading,
} from 'rapidfab/utils/tracebilityReportEvents';
import EventKeyName from 'rapidfab/components/records/piece/event/EventKeyName';
import EventResourceLink from 'rapidfab/components/records/piece/event/EventResourceLink';

const EventHeading = ({ event, relatedResource, model }) => {
  // Case when event heading is saved as event key
  if (isPlainHeadingKeyEvent(event)) {
    // finding subStringValue in keyValue
    const keyValue = event.key;
    const subStringValue = event.docUuid;
    // link to download document
    const documentContentUrl = event.docContentUrl;
    let eventKeyWithLink = [];

    // finding the subStringValue(uuid which was extracted from regex)
    // in the key value of the event **where key is the data, which we show in the Traceability Report table cell.
    eventKeyWithLink = keyValue.split(' ').map(value => {
      if (value === subStringValue && documentContentUrl) return <a href={documentContentUrl}>{value}{' '}</a>;
      return `${value} `;
    });
    return event.docUuid ? eventKeyWithLink : event.key;
  }

  // In case there is a custom heading - render it instead of default variant
  const customHeading = getCustomEventHeading(event, model);
  if (customHeading) {
    return customHeading;
  }

  const previousEventExists = (event.previous_value && event.previous_value !== 'None') || false;
  const eventModificationActionLabel = (<span> {previousEventExists ? 'change' : 'set'}</span>);

  const referenceResourceUri = event.reference;

  const resourceType = getEventReferenceResourceType(referenceResourceUri);

  const relatedResourceName = relatedResource && relatedResource.name;
  const resourceTypeLabel = EVENT_REFERENCE_TYPE_MAPPING[resourceType]
    // using resource type as a fall-back, when there is no mapping added
    || resourceType;

  // Resource type label is added at the beginning when there a related resource name available
  const eventResourceHeadingFirstPart = relatedResourceName ? resourceTypeLabel : null;
  // Otherwise resource type label might be used as a link name later
  const resourceTitle = relatedResourceName || resourceTypeLabel;

  const eventResourceHeadingMainPart = relatedResource
    // Use link based on resource route if it is available
    ? (
      <EventResourceLink
        url={event.reference}
        name={resourceTitle}
        relatedResource={relatedResource}
      />
    )
    // Use plain text variant otherwise
    : resourceTitle;

  return (
    <>
      {eventResourceHeadingFirstPart && (
        <>
          {eventResourceHeadingFirstPart}
          {' '}
        </>
      )}
      {eventResourceHeadingMainPart && (
        <>
          {eventResourceHeadingMainPart}
          {' '}
        </>
      )}
      <EventKeyName eventKey={event.key} />
      {eventModificationActionLabel}
    </>
  );
};

EventHeading.propTypes = {
  event: PropTypes.shape({
    docUuid: PropTypes.string,
    previous_value: PropTypes.string,
    key: PropTypes.string,
    docContentUrl: PropTypes.string,
    reference: PropTypes.string,

  }).isRequired,
  relatedResource: PropTypes.shape({
    name: PropTypes.string,
  }),
  model: PropTypes.shape({}),
};

EventHeading.defaultProps = {
  relatedResource: null,
  model: null,
};

export default EventHeading;
