import Table from 'rapidfab/components/Tables/Table';
import React from 'react';
import PropTypes from 'prop-types';

const PrintersTable = ({ fetching, printers, columns, renderHeaderView }) => (
  <>
    {renderHeaderView()}
    <Table
      tableID="printers"
      data={printers}
      columns={columns}
      isFilteringEnabled={false}
      withDefaultPagination={false}
      initialSortedDesc={false}
      initialSortedColumn="name"
      isManualSoringEnabled={false}
      isFetching={fetching}
    />
  </>
);

PrintersTable.propTypes = {
  fetching: PropTypes.bool.isRequired,
  printers: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  columns: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  renderHeaderView: PropTypes.func.isRequired,
};

export default PrintersTable;
