import _assign from 'lodash/assign';
import _find from 'lodash/find';
import Constants from 'rapidfab/constants';

const initialQueueState = {
  queue: [],
  errors: [],
  isModalView: false,
};

function fileLoaderReducer(state = initialQueueState, action) {
  switch (action.type) {
    case Constants.FILE_LOADER_SET_MODAL_STATE: {
      return {
        ...state,
        isModalView: true,
      };
    }
    case Constants.FILE_LOADER_REQUEST: {
      // Add a new upload to the queue
      const newUpload = {
        id: action.id,
        file: action.file,
        percent: 0,
        uploading: true,
        isModalView: action.isModalView || false,
      };
      return {
        ...state,
        queue: [...state.queue, newUpload],
      };
    }
    case Constants.FILE_LOADER_PROGRESS: {
      // Update progress for a specific upload
      const updatedQueue = state.queue.map(upload => {
        if (upload.id === action.id) {
          return { ...upload, percent: action.percent };
        }
        return upload;
      });
      return {
        ...state,
        queue: updatedQueue,
      };
    }
    case Constants.FILE_LOADER_SUCCESS: {
      // If some uploading is still in progress, don't close the modal
      const someFilesUploadingIncomplete = state.queue.some(file => file.percent !== 100);
      if (someFilesUploadingIncomplete) {
        return state;
      }
      // Mark the upload as complete (clear the queue) and reset its progress
      return {
        ...state,
        queue: initialQueueState.queue,
        isModalView: false,
      };
    }
    case Constants.FILE_LOADER_CLEAR: {
      // Clear the entire upload queue
      return initialQueueState;
    }
    case Constants.FILE_LOADER_ADD_ERROR: {
      const errors = _assign([], state.errors);

      action.errors.forEach(error => {
        if (!_find(errors, error)) {
          errors.push(error);
        }
      });
      return _assign({}, state, {
        errors,
      });
    }
    default:
      return state;
  }
}

export default fileLoaderReducer;
