import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { API_RESOURCES, EVENTSTREAM_DISCONNECT_TIMEOUT, RECORD_SOURCES } from 'rapidfab/constants';
import Alert from 'rapidfab/utils/alert';
import _filter from 'lodash/filter';
import _startCase from 'lodash/startCase';
import { getStateResources } from 'rapidfab/selectors/helpers/base';
import { getEndpointFromURI } from 'rapidfab/utils/uriUtils';
import PropTypes from 'prop-types';
import { Button, Modal, ModalBody, ModalFooter } from 'react-bootstrap';
import { getStateEventStreamDisconnected } from 'rapidfab/selectors/baseStateSelectors';
import { FormattedMessage } from 'react-intl';

const SUPPORTED_RESOURCE_TYPES = new Set([
  API_RESOURCES.ASSIGNMENT,
  // TODO: Add support for other resource types
]);

// TODO: This might need to be moved somewhere in case any extra logic is added
const getEndpointNameTitle = endpointName =>
  // For now, we just replace `assignment` with `activity` (since that is how this endpoint is shown to users
  (endpointName === API_RESOURCES.ASSIGNMENT ? 'Activity' : _startCase(endpointName));

const EventStreamContainer = ({ eventStream }) => {
  const resources = useSelector(getStateResources);
  const eventStreamDisconnected = useSelector(getStateEventStreamDisconnected);
  const getObservableResources = resourcesList => _filter(
    resourcesList,
    ({ uri, _meta: metaData }) => (
      // metadata might be empty (why?)
      metaData
      // Filter list to the resources created via Event Stream `created` event
      && metaData.source === RECORD_SOURCES.EVENT_STREAM
      // and use whitelisted resource types
      && SUPPORTED_RESOURCE_TYPES.has(getEndpointFromURI(uri).endpointName)
    ),
  );

  const [previousResources, setPreviousResources] = useState(resources);

  const handleNewResourceCreation = resource => {
    const { endpointName } = getEndpointFromURI(resource.uri);

    let resourceTitle;

    switch (endpointName) {
      case API_RESOURCES.ASSIGNMENT:
        resourceTitle = resource.name;
        break;
      default:
        resourceTitle = resource.name || resource.title;
        break;
    }

    // TODO: What if resourceTitle is empty for some reason?
    Alert.success(
      <FormattedMessage
        id="toaster.eventStream.newEndpointAdded"
        defaultMessage="New {endpointNameTitle} added: {resourceTitle}"
        values={{
          endpointNameTitle: getEndpointNameTitle(endpointName),
          resourceTitle,
        }}
      />,
    );
  };

  useEffect(() => {
    const observableResources = getObservableResources(resources);
    observableResources.forEach(observableResource => {
      if (!previousResources[observableResource.uuid]) {
        // Trigger `resource added` handler only if it was not present in the store before
        // Side Note: it might be the case that
        // resource was in the store under `_meta: source: "api"`
        // and was changed to `_meta: source: "event-stream"`
        // No need to show toastr in this case (since current user was the creator of the resource via API)
        handleNewResourceCreation(observableResource);
      }
    });

    setPreviousResources(resources);
  }, [resources]);

  if (!eventStream) return null;

  return (
    <Modal size="sm" show={!!eventStreamDisconnected} onHide={() => eventStream.setDisconnected(false)}>
      <ModalBody>
        <div>
          Realtime data updates have been paused due to {EVENTSTREAM_DISCONNECT_TIMEOUT / 60 / 1000} minutes of
          inactivity. To reconnect for realtime data updates, please refresh the page and reconnect.
          To continue working, please select Close.
        </div>
      </ModalBody>
      <ModalFooter>
        <Button type="button" variant="default" onClick={() => window.location.reload()}>
          Refresh and Reconnect
        </Button>
        <Button type="button" variant="primary" onClick={() => eventStream.setDisconnected(false)}>
          Close
        </Button>
      </ModalFooter>
    </Modal>
  );
};

EventStreamContainer.defaultProps = {
  eventStream: null,
};

EventStreamContainer.propTypes = {
  eventStream: PropTypes.shape({
    setDisconnected: PropTypes.func.isRequired,
  }),
};

export default EventStreamContainer;
