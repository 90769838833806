import React, { useEffect, useState } from 'react';
import { faChevronDown, faChevronUp } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button, Card, Col } from 'react-bootstrap';
import SmoothCollapse from 'react-smooth-collapse';
import { useDispatch, useSelector } from 'react-redux';
import Actions from 'rapidfab/actions';
import { API_RESOURCES } from 'rapidfab/constants';
import { getAddiguruAnalysisLinkedToRun } from 'rapidfab/selectors/addiguru';
import PropTypes from 'prop-types';
import { useSearchParams } from 'react-router-dom';
import AddiguruAnalysisTable from './AnalysisMetadata';
import AddiguruAnomalies from './Anomalies';
import LinkRunModal from './LinkRunModal';
import PointCloud from './PointCloud';

const AddiguruAnalysis = ({ runUri }) => {
  const analysis = useSelector(state => getAddiguruAnalysisLinkedToRun(state, runUri));

  if (!analysis) return (<LinkAddiguruSection runUri={runUri} />);

  return (
    <div className="d-flex card-body-wrapper gap-2 p-2">
      <Col xs={4}>
        <AddiguruAnomalies analysis={analysis} />
      </Col>
      <Col xs={4}>
        <PointCloud analysisUri={analysis.uri} />
      </Col>
      <Col xs={4}>
        <AddiguruAnalysisTable analysis={analysis} />
      </Col>
    </div>
  );
};

const LinkAddiguruSection = ({ runUri }) => {
  const [show, setShow] = useState(false);
  return (
    <div className="d-flex flex-column align-items-center justify-content-center p-4 gap-2">
      <span>No Addiguru analysis is currently linked to this run</span>
      <Button variant="primary" onClick={() => setShow(true)}>
        Link Analysis to Run
      </Button>
      <LinkRunModal runUri={runUri} show={show} setShow={setShow} />
    </div>
  );
};

const AddiguruSection = ({ runUri }) => {
  const [expanded, setExpanded] = useState(false);
  const dispatch = useDispatch();

  const [searchParams] = useSearchParams();

  useEffect(() => {
    if (searchParams.get('section') === 'addiguru') {
      setExpanded(true);
      // eslint-disable-next-line unicorn/prefer-query-selector
      document.getElementById('addiguru-section')?.scrollIntoView({ block: 'start', behavior: 'smooth' });
    }
  }, [searchParams]);

  // Fetch analysis
  useEffect(() => {
    dispatch(Actions.Api.nautilus[API_RESOURCES.ADDIGURU_ANALYSIS].list({ run: runUri }))
      .then(response => {
        const analysis = response.json.resources[0]?.uri;
        if (!analysis) return;
        dispatch(Actions.Api.nautilus[API_RESOURCES.ADDIGURU_ANOMALY].list({ addiguru_analysis: analysis }));
      });
  }, [runUri]);

  return (
    <Card bg="dark" className="table-responsive m-b" id="addiguru-section" data-cy="addiguru-section">
      <Card.Header
        className="pd-exp inverse d-flex align-items-center position-sticky pointer z-index-1000 justify-content-between"
        onClick={() => setExpanded(previous => !previous)}
      >
        <span>Addiguru Analysis</span>
        <FontAwesomeIcon size="sm" icon={expanded ? faChevronUp : faChevronDown} />
      </Card.Header>
      <SmoothCollapse expanded={expanded}>
        <AddiguruAnalysis runUri={runUri} />
      </SmoothCollapse>
    </Card>
  );
};

AddiguruSection.propTypes = {
  runUri: PropTypes.string.isRequired,
};

AddiguruAnalysis.propTypes = {
  runUri: PropTypes.string.isRequired,
};

LinkAddiguruSection.propTypes = {
  runUri: PropTypes.string.isRequired,
};

export default AddiguruSection;
