import React from 'react';
import PropTypes from 'prop-types';
import {
  Col,
  Container,
  ListGroup,
  ListGroupItem,
  Card,
  Row,
} from 'react-bootstrap';

import { PRINT_STATUS_MAP } from 'rapidfab/mappings';

import { FormattedDateTime, FormattedMessage } from 'rapidfab/i18n';

import BreadcrumbNav from 'rapidfab/components/BreadcrumbNav';
import ModelThumbnail from 'rapidfab/components/ModelThumbnail';
import { getShortUUID } from 'rapidfab/utils/uuidUtils';

const PrintComponent = ({ print, order, lineItem, model, piece }) => {
  const breadcrumbs = ['prints', print.id];

  const { status } = print;
  const { created } = lineItem || {};
  const { name, uuid } = order;
  const { uuid: pieceUUID } = piece;
  return (
    <Container fluid className="container">
      <BreadcrumbNav breadcrumbs={breadcrumbs} />

      <hr />

      <Card>
        <Card.Header>Print Summary</Card.Header>
        <Card.Body>
          <Row>
            <Col xs={6}>
              <ModelThumbnail snapshot={model.snapshot_content} />
            </Col>
            <Col xs={6}>
              <ListGroup>
                <ListGroupItem>
                  <div className="clearfix">
                    <div className="pull-left">
                      <span>
                        <FormattedMessage
                          id="field.order"
                          defaultMessage="Order"
                        />
                        :
                      </span>
                    </div>
                    <div className="pull-right">
                      <a href={`#/records/order/${uuid}`}>{name}</a>
                    </div>
                  </div>
                </ListGroupItem>
                <ListGroupItem>
                  <div className="clearfix">
                    <div className="pull-left">
                      <span>Piece:</span>
                    </div>
                    <div className="pull-right">
                      <a href={`#/records/piece/${pieceUUID}`}>
                        Piece ({getShortUUID(pieceUUID)})
                      </a>
                    </div>
                  </div>
                </ListGroupItem>
                <ListGroupItem>
                  <div className="clearfix">
                    <div className="pull-left">
                      <FormattedMessage
                        id="field.status"
                        defaultMessage="Status"
                      />
                    </div>
                    <div className="pull-right">
                      <FormattedMessage
                        id={PRINT_STATUS_MAP[status].id}
                        defaultMessage={PRINT_STATUS_MAP[status].defaultMessage}
                      />
                    </div>
                  </div>
                </ListGroupItem>
                {created && (
                  <ListGroupItem>
                    <div className="clearfix">
                      <div className="pull-left">
                        <FormattedMessage
                          id="created"
                          defaultMessage="Created"
                        />
                      </div>
                      <div className="pull-right">
                        <FormattedDateTime value={created} />
                      </div>
                    </div>
                  </ListGroupItem>
                )}
              </ListGroup>
            </Col>
          </Row>
        </Card.Body>
      </Card>
    </Container>
  );
};

PrintComponent.propTypes = {
  print: PropTypes.shape({
    status: PropTypes.string,
    id: PropTypes.string,
  }).isRequired,
  order: PropTypes.shape({
    name: PropTypes.string,
    uuid: PropTypes.string,
  }).isRequired,
  piece: PropTypes.shape({
    uuid: PropTypes.string,
  }).isRequired,
  lineItem: PropTypes.shape({}),
  model: PropTypes.shape({
    snapshot_content: PropTypes.string,
  }).isRequired,
};

PrintComponent.defaultProps = {
  lineItem: null,
};

export default PrintComponent;
