import React, { useState } from 'react';
import PropTypes from 'prop-types';
import WorkflowChecklistsModalContainer from 'rapidfab/containers/modals/WorkflowChecklistsModalContainer';
import { Button } from 'react-bootstrap';
import { FormattedMessage } from 'react-intl';
import Loading from 'rapidfab/components/Loading';

const DeleteChecklistConfirmationModal = props => {
  const {
    onDecline,
    onClose,
    workflowUri,
    lineItemUri,
  } = props;

  const [isSubmitting, setIsSubmitting] = useState(false);

  const handleSubmit = async () => {
    setIsSubmitting(true);
    await onDecline();
    setIsSubmitting(false);
  };

  return (
    <WorkflowChecklistsModalContainer
      {...props}
      workflowUri={workflowUri}
      lineItemUri={lineItemUri}
      onClose={onClose}
    >
      <div className="item-container">
        <div className="item-header align-items-center">
          <h3>
            <b>
              <FormattedMessage id="workInstructionsInProgress" defaultMessage="Work Instructions In Progress" />
            </b>
          </h3>
        </div>
        <div>
          <div>
            <b>Warning:</b>
            &nbsp;
            <FormattedMessage
              id="discardCustomChecklistsWarning"
              defaultMessage="You have customized Work Instructions for the current Production Workflow. Changing the Production Workflow will discard these Work Instruction customizations. Discard?"
            />
          </div>
        </div>
      </div>
      <div className="clearfix">
        <div className="pull-right">
          <Button className="m-r" variant="danger" onClick={onClose} disabled={isSubmitting}>
            <FormattedMessage id="button.cancel" defaultMessage="Cancel" />
          </Button>
          <Button className="m-r" variant="primary" onClick={handleSubmit} disabled={isSubmitting}>
            {isSubmitting && (
              <>
                <Loading inline />
                &nbsp;
              </>
            )}
            <FormattedMessage id="button.discard" defaultMessage="Discard" />
          </Button>
        </div>
      </div>
    </WorkflowChecklistsModalContainer>
  );
};

DeleteChecklistConfirmationModal.propTypes = {
  workflowUri: PropTypes.string.isRequired,
  lineItemUri: PropTypes.string.isRequired,
  onClose: PropTypes.func.isRequired,
  onDecline: PropTypes.func.isRequired,
};

export default DeleteChecklistConfirmationModal;
