import { createSelector } from 'reselect';
import _filter from 'lodash/filter';
import { getPrepWorkflows } from 'rapidfab/selectors/prepWorkflow';

// eslint-disable-next-line import/prefer-default-export
export const getNonCustomPrepWorkflows = createSelector(
  [getPrepWorkflows],
  workflows => _filter(
    workflows,
    workflow =>
      workflow.line_item === null
      && workflow.order === null
      && (!workflow.pieces || workflow.pieces.length === 0),
  ),
);
