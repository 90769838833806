import NewOrderComponent from 'rapidfab/components/records/order/new/NewOrderComponent';
import { getRequiredDefaultCustomFields } from 'rapidfab/utils/lineItemUtils';
import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import Alert from 'rapidfab/utils/alert';

import Actions from 'rapidfab/actions';
import * as Selectors from 'rapidfab/selectors';
import { extractUuid } from 'rapidfab/reducers/makeApiReducers';
import {
  FEATURES,
  COMMENT_RELATED_TABLE_NAMES, ROUTES, API_RESOURCES, USER_ROLES, MODAL_TEMPLATES,
} from 'rapidfab/constants';

import { orderFormType } from 'rapidfab/types';
import { useNavigate } from 'react-router-dom';
import { getRouteURI } from 'rapidfab/utils/uriUtils';
import _isEmpty from 'lodash/isEmpty';

import { createOrReplaceArray } from 'rapidfab/utils/arrayUtils';
import getInitialCustomFieldValues from 'rapidfab/utils/getInitialCustomFieldValues';
import { useModal } from 'rapidfab/hooks';

const NewOrder = props => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const {
    orderTemplateUri,
    formattedOrderTemplates,
    orderTemplatesFetching,
    handleSetOrderTemplate,
    handleClearForm,
  } = props;

  const bureau = useSelector(Selectors.getBureau);
  const customOrderFieldReferences = useSelector(Selectors.getCustomOrderFieldReferences);
  const isUserRestricted = useSelector(Selectors.isCurrentUserRestricted);
  const submitting = useSelector(state =>
    state.ui.nautilus[API_RESOURCES.MODEL].post.fetching ||
    state.ui.nautilus[API_RESOURCES.ORDER].post.fetching);
  const submittedOrders = useSelector(Selectors.getSubmittedOrders) || [];
  const isOrderBusinessSegmentFeatureEnabled = useSelector(state => Selectors.isFeatureEnabled(
    state, FEATURES.ORDER_BUSINESS_SEGMENT,
  ));
  const currentUserRoles = useSelector(Selectors.getRolesCurrentUser);
  const permissionLocationUris = useSelector(Selectors.getPermissionLocationUris);

  const [openWarningModal, WarningModal] = useModal();

  const saveSubmittedOrders = orders =>
    dispatch(Actions.SubmittedOrders.setSubmittedOrders(orders));

  const onCustomFieldChange = useCallback(([field, customField], state, { changeValue }) => {
    const customFieldValues = state.formState.values.custom_field_values;

    const customFieldValuesReplaced = createOrReplaceArray(
      customFieldValues,
      { custom_field: customField.customFieldReferenceUri },
      { value: customField.value },
    );

    changeValue(state, field, () => (customFieldValuesReplaced));
  }, []);

  const initCustomFieldValues = useCallback(([field], state, { changeValue }) => {
    const customFieldValues = state.formState.values.custom_field_values;

    const updatedCustomOrderFieldValues = getInitialCustomFieldValues(
      customOrderFieldReferences, customFieldValues,
    );
    changeValue(state, field, () => (updatedCustomOrderFieldValues));
  }, [customOrderFieldReferences]);

  const resourcesExist = resources => {
    resources.forEach(resource => {
      if (_isEmpty(props[resource])) {
        Alert.error(
          <FormattedMessage
            id="toaster.error.noResourcesFound"
            defaultMessage="No {resource} found, please create {resource} to create this order."
            values={{ resource }}
          />,
        );
      }
    });

    return resources.every(resource => (
      !_isEmpty(resource)
    ));
  };

  const onSubmit = (payload, showWarning) => {
    if (!resourcesExist([
      'shippings',
      'locations',
    ])) return;

    const userRoles = new Set(currentUserRoles.map(role => role.role));
    const showLocationOrderAccessWarning = !userRoles.has(USER_ROLES.MANAGER)
    && !userRoles.has(USER_ROLES.GLOBAL_USER)
    && userRoles.has(USER_ROLES.LOCATION_USER)
    && !permissionLocationUris.includes(payload.location);
    if (showWarning && showLocationOrderAccessWarning) {
      openWarningModal({
        modalTemplate: MODAL_TEMPLATES.YES_OR_NO,
        title: '',
        bodyText: <FormattedMessage
          id="message.locationOrderAccessWarning"
          defaultMessage="Order not assigned to a location that you have access to. You will no longer have access to this Order until a Global or Manager User re-assigns this to your Location."
        />,
        onConfirm: () => onSubmit(payload, false),
      });
      return;
    }

    const orderPayload = {
      application_type: payload.application_type,
      bureau: bureau.uri,
      due_date: payload.due_date
        ? new Date(payload.due_date).toISOString()
        : null,
      custom_field_values: payload.custom_field_values || [],
      customer_email: payload.customer_email,
      customer_name: payload.customer_name,
      customer_po: payload.customer_po,
      ip_sensitivity: payload.ip_sensitivity,
      location: payload.location,
      name: payload.name,
      order_owner:
        payload.order_owner === '' ? null : payload.order_owner,
      order_type: payload.order_type,
      priority: payload?.priority && Number.parseInt(payload.priority, 10),
      region: payload.region,
      shipping: {
        address: payload.shipping.address,
        name: payload.shipping.name,
        tracking: payload.shipping.tracking,
        uri: payload.shipping.uri,
      },
      shipping_grouping: payload.shipping.uri
        ? payload.shipping_grouping
        : null,
    };

    const requiredCustomFields =
      getRequiredDefaultCustomFields(customOrderFieldReferences, payload.custom_field_values);

    orderPayload.custom_field_values = [
      ...orderPayload.custom_field_values,
      ...requiredCustomFields];

    const initialComment = payload.initial_comment;

    Object.keys(orderPayload).forEach(key => {
      if (orderPayload[key] == null || orderPayload[key] === 'none') {
        delete orderPayload[key];
      }
    });

    dispatch(Actions.Api.nautilus[API_RESOURCES.ORDER].post(orderPayload))
      .then(async response => {
        const orderUuid = extractUuid(response.payload.uri);

        if (initialComment) {
          try {
            await dispatch(Actions.Api.nautilus[API_RESOURCES.COMMENT].post({
              related_table_name: COMMENT_RELATED_TABLE_NAMES.ORDER,
              related_uuid: orderUuid,
              text: initialComment,
            }));
          } catch {
            Alert.error(
              <FormattedMessage
                id="toaster.error.comment.initialComment"
                defaultMessage="Something went wrong with submitting the initial comment. Please add comment manually."
              />);
          }
        }

        try {
          const localStorageOrderLimit = 25;
          submittedOrders.unshift(orderPayload);
          saveSubmittedOrders(
            submittedOrders.slice(0, localStorageOrderLimit),
          );
        } catch {
          // empty
        }
        if (showLocationOrderAccessWarning) {
          dispatch(Actions.Api.nautilus[API_RESOURCES.ORDER].clear('list'));
          navigate(getRouteURI(
            ROUTES.ORDERS,
            {},
            {},
            true));
        } else {
          navigate(getRouteURI(
            ROUTES.ORDER_EDIT,
            { uuid: orderUuid },
            {},
            true));
        }
      },
      )
      .catch(error => {
        // eslint-disable-next-line no-console
        console.error(error);
        Alert.error(error.message);
      });
  };

  const htmlBannerObject = {
    __html: bureau.order_banner.html_message,
  };
  // This html is sanitized by the server
  // eslint-disable-next-line react/no-danger
  const htmlBannerMessageRender = (
    // eslint-disable-next-line react/no-danger
    <div dangerouslySetInnerHTML={htmlBannerObject} />
  );

  return (
    <>
      <WarningModal id="orderLocationWarningModal" />
      <NewOrderComponent
        {...props}
        bannerLink={bureau.order_banner.link}
        bannerMessage={bureau.order_banner.html_message ?
          htmlBannerMessageRender :
          bureau.order_banner.message}
        onSubmit={onSubmit}
        orderTemplateProps={{
          formattedOrderTemplates,
          orderTemplateUri,
          orderTemplatesFetching,
          handleSetOrderTemplate,
          handleClearForm,
        }}
        isUserRestricted={isUserRestricted}
        submitting={submitting}
        isOrderBusinessSegmentFeatureEnabled={isOrderBusinessSegmentFeatureEnabled}
        initCustomFieldValues={initCustomFieldValues}
        onCustomFieldChange={onCustomFieldChange}
        customOrderFieldReferences={customOrderFieldReferences}
      />
    </>
  );
};

NewOrder.propTypes = {
  bureau: PropTypes.shape({
    order_banner: PropTypes.shape({
      message: PropTypes.string,
      html_message: PropTypes.string,
      link: PropTypes.string,
    }),
    uri: PropTypes.string,
  }).isRequired,
  dispatch: PropTypes.func.isRequired,
  isUserRestricted: PropTypes.bool.isRequired,
  isOrderBusinessSegmentFeatureEnabled: PropTypes.bool.isRequired,
  submittedOrders: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  saveSubmittedOrders: PropTypes.func.isRequired,
  orderForm: orderFormType.isRequired,
  shippings: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  locations: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  orderTemplatesFetching: PropTypes.bool.isRequired,
  orderTemplateUri: PropTypes.string.isRequired,
  formattedOrderTemplates: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  handleSetOrderTemplate: PropTypes.func.isRequired,
  handleClearForm: PropTypes.func.isRequired,
};

export default NewOrder;
