import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import Actions from 'rapidfab/actions';
import {
  MODEL_LIBRARY_TYPES,
  API_RESOURCES,
  MODEL_TYPES,
  FEATURES,
  THREADS_API_RESOURCES, DOCUMENT_RELATED_TABLE_NAMES,
} from 'rapidfab/constants';
import {
  getBureauUri,
  getUUIDResource,
  getSpecimensForModelLibrary,
  getModelsByUri,
  getLabelsByUri,
  isFeatureEnabled,
  getZverseConversions,
  getMaterials,
  getWorkflows,
  getShippings,
  isCurrentUserRestricted,
  getThreadsApiRelatedThreadsForUriResource,
  getCarouselForModelLibrary, getSession,
} from 'rapidfab/selectors';
import { extractUuid } from 'rapidfab/utils/uuidUtils';

import Cookies from 'universal-cookie';
import { Alert as BsAlert, Modal } from 'react-bootstrap';
import Loading from 'rapidfab/components/Loading';
import ModelLibrary from 'rapidfab/components/records/model_library/ModelLibrary';
import Alert from 'rapidfab/utils/alert';
import dayjs from 'dayjs';
import { FormattedMessage } from 'react-intl';
import HandleCreateOrderModal from 'rapidfab/components/modals/HandleCreateOrderModal';
import _toNumber from 'lodash/toNumber';
import _map from 'lodash/map';
import _isEmpty from 'lodash/isEmpty';
import AddToCartModal from 'rapidfab/components/modals/AddToShoppingCartModal';
import * as Selectors from 'rapidfab/selectors';
import { pluralWord } from 'rapidfab/utils/stringUtils';

const ModelLibraryContainer = props => {
  const {
    uuid,
    handleClose,
    isShowing = true,
    renderAsOverlay = false,
    shoppingCartItems,
    onInitializeShoppingCarts,
  } = props;

  const threadsApiRelatedThreads = useSelector(state =>
    getThreadsApiRelatedThreadsForUriResource(state, window.location.href));
  const bureauUri = useSelector(getBureauUri);
  const user = useSelector(getSession);
  const modelsByUri = useSelector(getModelsByUri);
  const labelsByUri = useSelector(getLabelsByUri);
  const zverseConversion = useSelector(state => getZverseConversions(state, modelsByUri));
  const modelLibrary = useSelector(state => (uuid ? getUUIDResource(state, uuid) : null));
  const specimens = useSelector(state => getSpecimensForModelLibrary(state, modelLibrary));
  const model = useSelector(
    state => (
      modelLibrary?.additive?.model ? getUUIDResource(state, extractUuid(modelLibrary.additive.model)) : null
    ),
  );
  const isStanleyXUser = useSelector(state => isFeatureEnabled(state, FEATURES.STANLEY_X_DEPLOYMENT));
  const isDanfossUser = useSelector(state => isFeatureEnabled(state, FEATURES.ORDER_BUSINESS_SEGMENT));
  const isDigitalDesignWarehouseFeatureEnabled = useSelector(state =>
    isFeatureEnabled(state, FEATURES.DIGITAL_DESIGN_WAREHOUSE));
  const isThreadsIntegrationFeatureEnabled = useSelector(state =>
    isFeatureEnabled(state, FEATURES.THREADS_INTEGRATION));
  const isRestrictedUser = useSelector(isCurrentUserRestricted);
  const materials = useSelector(getMaterials);
  const workflows = useSelector(getWorkflows);
  const shippings = useSelector(getShippings);
  const carousel = useSelector(state => getCarouselForModelLibrary(state, modelLibrary));
  const createOrderFetching = useSelector(state =>
    state.ui.nautilus[API_RESOURCES.ORDER].post.fetching ||
    state.ui.nautilus[API_RESOURCES.PRODUCT].post.fetching ||
    state.ui.nautilus[API_RESOURCES.LINE_ITEM].post.fetching);
  const addToCartFetching = useSelector(state =>
    state.ui.nautilus[API_RESOURCES.SHOPPING_CART_ITEM].post.fetching ||
    state.ui.nautilus[API_RESOURCES.SHOPPING_CART].post.fetching);
  const isCADToSTLConversionFeatureEnabled = useSelector(state =>
    isFeatureEnabled(state, FEATURES.NATIVE_CAD_TO_STL_CONVERSION));
  const isThreadsApiFetching = useSelector(state =>
    state.ui.threads_api[THREADS_API_RESOURCES.AUTH_LOGIN].post.fetching);
  const isCarouselFetching = useSelector(state =>
    state.ui.nautilus[API_RESOURCES.CAROUSEL].post.fetching ||
    state.ui.nautilus[API_RESOURCES.CAROUSEL].put.fetching);
  const isDocumentFetching = useSelector(state =>
    state.ui.nautilus[API_RESOURCES.DOCUMENT].post.fetching ||
    state.ui.nautilus[API_RESOURCES.DOCUMENT].put.fetching);
  const isRobozeBureauOrderFieldsFeatureEnabled = useSelector(state => isFeatureEnabled(
    state,
    FEATURES.ROBOZE_GIGAFACTORY_BUREAU_ORDER_FIELDS,
  ));
  const isIntegrationCastorFeatureEnabled = useSelector(state =>
    isFeatureEnabled(state, FEATURES.INTEGRATION_CASTOR));
  const customGroupsByUri = useSelector(Selectors.getCustomGroupsByUri);
  const modelLibraryOwnerIsCustomGroup = customGroupsByUri?.[modelLibrary?.owner];
  const userBelongsToCustomGroup = modelLibraryOwnerIsCustomGroup?.members?.includes(user?.uri);
  const [isOrdered, setIsOrdered] = useState(false);
  const [workflowsFetchMoreState, setWorkflowsFetchMoreState] = useState({ offset: 0, count: 1 });
  const [createOrderModalVisible, setCreateOrderModalVisible] = useState(false);
  const [addToCartModalVisible, setAddToCartModalVisible] = useState(false);
  const [showThreadsLoginModal, setShowThreadsLoginModal] = useState(false);
  const [modelSnapshotCarouselInit, setModelSnapshotCarouselInit] = useState(false);
  const [modelUuidBeforeReplacing, setModelUuidBeforeReplacing] = useState(null);
  const [uploadingCarouselImages, setUploadingCarouselImages] = useState(false);
  const robozeAndCastorFeaturesEnabled = isRobozeBureauOrderFieldsFeatureEnabled && isIntegrationCastorFeatureEnabled;

  const cookies = new Cookies();

  const selected = {
    cookies,
    bureauUri,
    modelLibrary,
    specimens,
    modelsByUri,
    labelsByUri,
    model,
    carousel,
    isCADToSTLConversionFeatureEnabled,
    zverseConversion,
    isDanfossUser,
    isStanleyXUser,
    isRestrictedUser,
    isDigitalDesignWarehouseFeatureEnabled,
    isThreadsIntegrationFeatureEnabled,
    shippings,
    workflows,
    isThreadsApiFetching,
    isCarouselFetching,
    isDocumentFetching,
    threadsApiRelatedThreads,
    uploadingCarouselImages,
    isIntegrationCastorFeatureEnabled,
    userBelongsToCustomGroup,
    isRobozeBureauOrderFieldsFeatureEnabled,
  };

  const dispatch = useDispatch();

  const redirect = () => {
    if (renderAsOverlay) {
      /* This is not being rendered from a libary component? handle
      the close from this component's parent */
      handleClose();
    } else {
      /* Otherwise, simply remove the uuid query from the URL route
      hash which will hide this modal */
      const [newHash] = window.location.hash.split('?');
      window.location.hash = newHash;
    }
  };

  const handleModelDownload = (currentModel, contentUriKey, filename) => {
    // Model is guaranteed to be already loaded before download process
    // contentURLKey is string with attribute of `model` object with uri to download.
    const modelUri = currentModel.uri;

    // Fetch is required before download since `.content` / `.original_content` lives for 5 minutes only
    // so need to re-request content link anytime just in case
    return dispatch(Actions.DownloadModel.fetchModel(modelUri)).then(response => dispatch(
      Actions.DownloadModel.downloadContent(
        filename, // Not always model.name, so save as filename from arguments
        response.json[contentUriKey],
      ),
    ));
  };

  const onFetchMoreWorkflows = async () => {
    const limit = 100;
    const response = await dispatch(Actions.Api.nautilus[API_RESOURCES.WORKFLOW].list(
      { include_custom_workflows: true },
      { limit, offset: workflowsFetchMoreState.offset }, {}, { sort: 'name' }, true),
    );
    setWorkflowsFetchMoreState(previous => (
      { offset: previous.offset + limit, count: response?.json.meta?.count || 0 }
    ));
  };

  const handleCreateCarousel = async (modelLibraryUri, model) => {
    const carouselPayload = {
      parent: modelLibraryUri,
      snapshots: [
        {
          source: model?.snapshot,
        },
      ],
    };

    await dispatch(Actions.Api.nautilus[API_RESOURCES.CAROUSEL].post(carouselPayload));
  };

  const handleUpdateCarousel = async (uri, payload) => {
    await dispatch(Actions.Api.nautilus[API_RESOURCES.CAROUSEL].put(uri, payload));
    dispatch(Actions.Api.nautilus[API_RESOURCES.CAROUSEL].clear('list'));

    await dispatch(Actions.Api.nautilus[API_RESOURCES.CAROUSEL].get(uri));
  };

  const handleInitCarouselModelSnapshot = async (model, carousel) => {
    if (!model?.snapshot || !model?.snapshot_content || !carousel?.uri) {
      return;
    }

    const modelSnapshotPayload = {
      snapshots: [{
        snapshot: model.snapshot,
      }],
    };

    await handleUpdateCarousel(extractUuid(carousel.uri), modelSnapshotPayload);
    setModelSnapshotCarouselInit(true);
  };

  const onInitialize = async (currentUUID, currentBureauUri) => {
    const modelLibraryResponse = await dispatch(
      Actions.Api.nautilus[API_RESOURCES.MODEL_LIBRARY].get(currentUUID, false),
    );

    if (modelLibraryResponse?.json) {
      const { uri, type, workflow, additive } = modelLibraryResponse.json;
      if (!model && extractUuid(additive?.model)) {
        await dispatch(Actions.Api.nautilus[API_RESOURCES.MODEL].get(extractUuid(additive?.model)));
      }

      const promises = [];

      if (type === MODEL_LIBRARY_TYPES.SPECIMEN) {
        promises.push(dispatch(Actions.Api.nautilus[API_RESOURCES.SPECIMEN].list({ model_library: uri })));
      }

      if (workflow) {
        // Get the only particular workflow and skip loading 1000+ at once
        promises.push(dispatch(Actions.Api.nautilus[API_RESOURCES.WORKFLOW].get(extractUuid(workflow))));
      } else {
        onFetchMoreWorkflows();
      }

      promises.push(
        dispatch(Actions.Api.nautilus[API_RESOURCES.MATERIAL].list({ bureau: currentBureauUri })),
        dispatch(Actions.Api.nautilus[API_RESOURCES.LABEL].list()),
        dispatch(Actions.Api.nautilus[API_RESOURCES.USERS].list()),
      );

      if (isStanleyXUser || isDanfossUser || isDigitalDesignWarehouseFeatureEnabled) {
        promises.push(
          dispatch(Actions.Api.nautilus[API_RESOURCES.SHIPPING].list()),
        );
      }

      // Check carousel exists for this model-library resource
      await dispatch(Actions.Api.nautilus[API_RESOURCES.CAROUSEL].list({
        parent: modelLibraryResponse.json?.uri,
      }));

      return Promise.all(promises);
    }

    return Promise.resolve();
  };

  const onUpdate = async payload => {
    await dispatch(Actions.Api.nautilus[API_RESOURCES.MODEL_LIBRARY].put(payload.uuid, payload));
    // Loading Labels right after Model Library upload, since Model Library - Labels relation might have changed
    // TODO: This might not be needed once there is an event-stream event for `label.items` update
    return dispatch(Actions.Api.nautilus[API_RESOURCES.LABEL].list());
  };

  const onUpdateModel = async (modelFile, modelLibraryUuid) => {
    setModelUuidBeforeReplacing(model?.uri && extractUuid(model.uri));
    const modelPostResponse = await dispatch(Actions.Api.nautilus[API_RESOURCES.MODEL].post({
      name: modelFile.name,
      // Null means `auto`
      file_unit: null,
      user_unit: null,
      type: MODEL_TYPES.STL,
    }));

    const { location, uploadLocation } = modelPostResponse.headers;

    const payload = {
      additive: {
        model: location,
      },
    };

    await dispatch(Actions.Api.nautilus[API_RESOURCES.MODEL].get(extractUuid(location)));
    await dispatch(Actions.UploadModel.upload(uploadLocation, modelFile));
    await dispatch(Actions.Api.nautilus[API_RESOURCES.MODEL_LIBRARY].put(modelLibraryUuid, payload));
  };

  const onDelete = currentUUID => dispatch(Actions.Api.nautilus[API_RESOURCES.MODEL_LIBRARY].delete(currentUUID))
    .then(() => Alert.success(
      <FormattedMessage
        id="toaster.modelLibrary.deleted"
        defaultMessage="Model library {uuid} has been successfully deleted."
        values={{ uuid: currentUUID }}
      />,
    ))
    .then(redirect);
  const handleModelOriginalContentDownload = currentModel =>
    handleModelDownload(currentModel, 'content', currentModel.name);
  const handleModelContentDownload = currentModel =>
    handleModelDownload(currentModel, 'conversion_original_content', currentModel.conversion_original_filename);
  /* If it was the Zverse conversion, technically we do not have the "Model File"
     in the BE fields, so using "snapshot_content" to have the file to download */
  const handleZverseSnapshotContentDownload = currentModel =>
    handleModelDownload(currentModel, 'snapshot_content', currentModel.conversion_original_filename);

  const handleSaveShoppingCart = async (quantity, uri) => {
    /* Check shopping cart of status `empty` or `in-progress` exists */
    const shoppingCartsResponse = await dispatch(Actions.Api.nautilus[API_RESOURCES.SHOPPING_CART]
      .list({ status: ['empty', 'in-progress'], owner: user.uri }));
    const availableShoppingCarts = shoppingCartsResponse.json?.resources;

    /* Boolean returns whether such empty or in-progress shopping carts were found */
    const shouldCreateNewShoppingCart = _isEmpty(availableShoppingCarts);

    /* Cart item payload */
    const shoppingCartItemPayload = {
      source: modelLibrary?.uri,
      quantity,
      shopping_cart: availableShoppingCarts[0]?.uri,
    };

    if (robozeAndCastorFeaturesEnabled) {
      shoppingCartItemPayload.estimator = uri;
    }

    if (shouldCreateNewShoppingCart) {
      /* Create new shopping cart */
      const shoppingCartResponse = await dispatch(Actions.Api.nautilus[API_RESOURCES.SHOPPING_CART].post({
        name: modelLibrary?.name,
      }));
      await dispatch(Actions.Api.nautilus[API_RESOURCES.SHOPPING_CART_ITEM]
        .post({
          ...shoppingCartItemPayload,
          shopping_cart: shoppingCartResponse.headers.location,
        }));
      // Refresh the Cart list to fetch the newly created shopping cart and sort it as the first index of the array
      dispatch(Actions.Api.nautilus[API_RESOURCES.SHOPPING_CART].clear('list'));
      await dispatch(Actions.Api.nautilus[API_RESOURCES.SHOPPING_CART].list({}, {}, {}, {}, true));
    } else {
      /* Otherwise add the item to the 0th index of the shopping carts found above */
      await dispatch(Actions.Api.nautilus[API_RESOURCES.SHOPPING_CART_ITEM]
        .post(shoppingCartItemPayload));
    }
    setAddToCartModalVisible(false);
    // Update the Shopping Cart Price
    await onInitializeShoppingCarts();
  };

  const handleCreateOrder = async (orderName, pieceQuantity) => {
    const orderPayload = {
      bureau: bureauUri,
      // Sending empty array for now as there are some BE changes to be made before
      custom_field_values: [],
      // Order of $MODEL_LIBRARY.name on $YYY-MM-DD
      name: orderName,
      priority: 65,
      shipping: {
        // Getting the 0th element of the array since there is only one shipping option for now
        uri: shippings[0]?.uri,
      },
    };

    let result = false;

    if (workflows.length && shippings.length) {
      const orderResponse = await dispatch(Actions.Api.nautilus[API_RESOURCES.ORDER].post(orderPayload));
      const productResponse = await dispatch(Actions.Api.nautilus[API_RESOURCES.PRODUCT].post({
        order: orderResponse.payload.uri,
      }));

      const lineItemPayload = {
        autorun: false,
        bureau: bureauUri,
        notes: null,
        priority: orderResponse.payload.priority, // currently line items will inherit the priority of the order
        quantity: _toNumber(pieceQuantity),
        source_model_library: modelLibrary.uri,
        // Getting the 0th element of the array since there is only one workflow option for now
        workflow: workflows[0]?.uri,
        additive: {
          infill_strategy: null,
          materials: {
            base: modelLibrary.base_material || materials[0]?.uri,
            support: null,
          },
          model: model.uri,
          no_model_upload: !model.uri,
          support_strategy: null,
        },
        // sending empty array for now as there are some BE changes to be made before
        custom_field_values: [],
        name: null,
        customer_id: null,
        product: productResponse.json?.uri,
        order: orderResponse.payload.uri,
      };

      const lineItemResponse = await dispatch(Actions.Api.nautilus[API_RESOURCES.LINE_ITEM].post(lineItemPayload));

      if (lineItemResponse.type === 'RESOURCE_POST_SUCCESS') {
        setIsOrdered(true);
        Alert.success(<FormattedMessage
          id="toaster.lineItem.orderCreatedFromModelLibraryItem"
          defaultMessage="An Order was created from this Model Library Item"
        />);

        result = {
          orderUri: orderResponse.headers?.location,
        };
      } else {
        result = false;
      }
    }
    return result;
  };

  const handleSigninToThreads = async (formValues, onUnsuccessfulSignin) => {
    const loginResponse = await dispatch(Actions.Api.threads_api[THREADS_API_RESOURCES.AUTH_LOGIN]
      .post(formValues))
      .catch(() => onUnsuccessfulSignin());

    if (loginResponse) {
      // Set the bearer token as a cookie
      cookies.set('threads_bearer_token', loginResponse?.json?.access_token);
      setShowThreadsLoginModal(false);
    }
  };

  // Handle adding/removing documents from the carousel.
  const handleAddDocumentToCarousel = async document => {
    const carouselContainsModelSnapshot = carousel.snapshots.some(({ source }) => source === model.uri);

    const snapshotsList = [
      ..._map(carousel.snapshots, carouselSnapshotItem => ({
        snapshot: carouselSnapshotItem.snapshot,
      })),
      { snapshot: document?.snapshot },
    ];

    const carouselSnapshotPayload = {
      snapshots: !carouselContainsModelSnapshot ?
        [...snapshotsList, { snapshot: model?.snapshot }] :
        snapshotsList,
    };

    const isAddedToCarousel = carousel.snapshots.some(({ snapshot }) => snapshot === document.snapshot);

    if (carousel) {
      const filteredCarouselSnapshotPayload = {
        snapshots:
        _map(carousel.snapshots, carouselSnapshotItem =>
          ({ snapshot: carouselSnapshotItem.snapshot }))
          .filter(carouselSnapshotItem =>
            carouselSnapshotItem.snapshot !== document.snapshot),
      };

      await handleUpdateCarousel(extractUuid(carousel.uri),
        !isAddedToCarousel ?
          // If not already added to carousel, add snapshot.
          carouselSnapshotPayload :
          // Otherwise, remove snapshot.
          filteredCarouselSnapshotPayload);
    }
  };

  const handleRefreshCarouselSnapshots = async (carouselUri, method = 'list') => {
    dispatch(Actions.Api.nautilus[API_RESOURCES.CAROUSEL].clear(method));
    return dispatch(Actions.Api.nautilus[API_RESOURCES.CAROUSEL].get(extractUuid(carouselUri)));
  };

  const handleUploadCarouselImage = async (files, onHide) => {
    const fileUploadPromises = files.map(async file => {
      const fileUploadPostResponse = await dispatch(Actions.Api.nautilus[API_RESOURCES.DOCUMENT].post({
        name: file?.path,
        related_uuid: extractUuid(modelLibrary.uri),
        related_table_name: DOCUMENT_RELATED_TABLE_NAMES.MODEL_LIBRARY,
      }));

      const { uploadLocation } = fileUploadPostResponse?.headers;
      const { uri } = fileUploadPostResponse?.payload;
      setUploadingCarouselImages(true);
      // The action is entitled `UploadModel` but it appears to work for all files (not only model files).
      await dispatch(Actions.UploadModel.upload(uploadLocation, file, file?.type))
        .catch(error => BsAlert.error(error.message));

      // Get the object from the created documnet
      const documentGetResponse = await dispatch(Actions.Api.nautilus[API_RESOURCES.DOCUMENT]
        .get(extractUuid(uri)));

      return documentGetResponse?.json.snapshot;
    });

    onHide();

    const fileUploadSnapshots = await Promise.all(fileUploadPromises);

    const carouselContainsModelSnapshot = carousel.snapshots.some(({ source }) => source === model.uri);

    if (fileUploadSnapshots?.length) {
      const snapshotsList = [
        ...carousel.snapshots.map(carouselSnapshotItem => ({ snapshot: carouselSnapshotItem.snapshot })),
        ...fileUploadSnapshots.map(snapshot => ({
          snapshot,
        })),
      ];

      const carouselPayload = {
        snapshots: !carouselContainsModelSnapshot ?
          [...snapshotsList, { snapshot: model?.snapshot }] :
          snapshotsList,
      };

      await dispatch(Actions.Api.nautilus[API_RESOURCES.CAROUSEL]
        .put(extractUuid(carousel.uri), carouselPayload));
      await handleRefreshCarouselSnapshots(carousel.uri, 'put');
    }

    setUploadingCarouselImages(false);
    Alert.success(`Carousel ${pluralWord('image', fileUploadSnapshots)} uploaded.`);
  };

  // When opened from Model Libraries page - model library might already be in store
  // so `...[MODEL_LIBRARY].get.fetching` and similar will not work properly here
  const [isInitialLoading, setIsInitialLoading] = useState(true);

  useEffect(() => {
    onInitialize(uuid, bureauUri).finally(() => {
      setIsInitialLoading(false);
    });
  }, [uuid, bureauUri]);

  useEffect(() => {
    if (!carousel?.snapshots?.length && !modelSnapshotCarouselInit) {
      handleInitCarouselModelSnapshot(model, carousel);
    }
  }, [carousel?.snapshots?.length, model?.snapshot_content]);

  // Update carousel images after replacing model
  useEffect(() => {
    if (
      modelUuidBeforeReplacing
      && carousel?.uri
      && carousel?.snapshots?.length
      && model?.snapshot
      && model?.snapshot_content
    ) {
      const oldModelUuid = modelUuidBeforeReplacing;
      const snapshots = carousel.snapshots.map(item => ({ snapshot: item.snapshot }));

      // find the first snapshot by old model uuid
      let snapshotToReplaceIndex = snapshots.findIndex(snapshot => snapshot.snapshot.includes(oldModelUuid));

      // find the first snapshot of the model
      if (snapshotToReplaceIndex === -1) {
        snapshotToReplaceIndex = snapshots.findIndex(snapshot => snapshot.snapshot.includes('/model/'));
      }
      // add a new one if not found
      if (snapshotToReplaceIndex === -1) {
        snapshots.unshift({ snapshot: model.snapshot });
      } else {
        snapshots[snapshotToReplaceIndex] = { snapshot: model.snapshot };
      }

      handleUpdateCarousel(extractUuid(carousel.uri), { snapshots })
        .then(() => {
          dispatch(Actions.Api.nautilus[API_RESOURCES.CAROUSEL].clear('list'));
          dispatch(Actions.Api.nautilus[API_RESOURCES.MODEL_LIBRARY].get(uuid, false));
          dispatch(Actions.Api.nautilus[API_RESOURCES.CAROUSEL].get(extractUuid(carousel.uri)));
        })
        .finally(() => setModelUuidBeforeReplacing(null));
    }
  }, [model?.snapshot, model?.snapshot_content]);

  // Try to fetch an existing thread that this model-library references, if it exists.
  const onInitializeThreadsData = async () => {
    await dispatch(Actions.Api.threads_api[THREADS_API_RESOURCES.THREADS].list(
      {}, {}, {}, {}, false, null,
      // All parameters empty, except the 7th parameter which is the request configuration.
      {
        headers: { Authorization: `Bearer ${cookies.get('threads_bearer_token')}` },
      },
    ));
  };

  const dispatched = {
    handleCreateCarousel,
    handleAddDocumentToCarousel,
    handleUploadCarouselImage,
    handleRefreshCarouselSnapshots,
    handleSaveShoppingCart,
  };

  useEffect(() => {
    if (isThreadsIntegrationFeatureEnabled && cookies.get('threads_bearer_token')) {
      onInitializeThreadsData();
    }
  }, [showThreadsLoginModal]);

  // model library will be null momentarily after it is deleted & before modal is closed
  if (isInitialLoading || !modelLibrary) {
    return (
      <Modal
        show
        onHide={redirect}
        backdrop="static"
        dialogClassName="modal-xl"
      >
        <Loading className="mt30 mb30" />
      </Modal>
    );
  }

  /* When create order modal and add to card is visible, we want to hide <ModelLibrary
  in its place; we do not want to display two layers of modals at once. */
  const isModalLibraryVisible =
    createOrderModalVisible === false &&
    addToCartModalVisible === false;

  return (
    <>
      <HandleCreateOrderModal
        show={createOrderModalVisible}
        onClose={() => setCreateOrderModalVisible(false)}
        handleCreateOrder={handleCreateOrder}
        defaultFormValues={{
          order_name: `Order of ${modelLibrary.name} on ${dayjs().format('YYYY-MM-DD')}`,
          order_quantity: 1,
        }}
        createOrderFetching={createOrderFetching}
        isRestrictedUser={isRestrictedUser}
      />
      <AddToCartModal
        model={model}
        show={addToCartModalVisible}
        handleSaveShoppingCart={handleSaveShoppingCart}
        onClose={() => setAddToCartModalVisible(false)}
        addToCartFetching={addToCartFetching}
      />
      {isModalLibraryVisible && (
        <ModelLibrary
          {...props}
          {...selected}
          {...dispatched}
          onDelete={onDelete}
          onUpdate={onUpdate}
          onUpdateModel={onUpdateModel}
          handleModelContentDownload={handleModelContentDownload}
          handleModelOriginalContentDownload={handleModelOriginalContentDownload}
          handleZverseSnapshotContentDownload={handleZverseSnapshotContentDownload}
          handleClose={redirect}
          createOrderFetching={createOrderFetching}
          isOrdered={isOrdered}
          handleCreateOrder={handleCreateOrder}
          setCreateOrderModalVisible={setCreateOrderModalVisible}
          setAddToCartModalVisible={setAddToCartModalVisible}
          isShowing={isShowing}
          onFetchMoreWorkflows={
            workflowsFetchMoreState.offset < workflowsFetchMoreState.count ? onFetchMoreWorkflows : null
          }
          shoppingCartItems={shoppingCartItems}
          handleSigninToThreads={handleSigninToThreads}
          threadsLoginModalState={[showThreadsLoginModal, setShowThreadsLoginModal]}
        />
      )}
    </>
  );
};

ModelLibraryContainer.defaultProps = {
  isShowing: true,
  renderAsOverlay: false,
  onInitializeShoppingCarts: () => {},
};

ModelLibraryContainer.propTypes = {
  uuid: PropTypes.string.isRequired,
  handleClose: PropTypes.func.isRequired,
  isShowing: PropTypes.bool,
  renderAsOverlay: PropTypes.bool,
  shoppingCartItems: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  onInitializeShoppingCarts: PropTypes.func,
};

export default ModelLibraryContainer;
