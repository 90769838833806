import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { API_RESOURCES, PAGINATION_IGNORE_DEFAULT_LIMIT } from 'rapidfab/constants';
import Actions from 'rapidfab/actions';
import withRecordsListHandling from 'rapidfab/containers/hocs/withRecordsListHandling';
import ServiceProviderJobs from 'rapidfab/components/plan/ServiceProviderJobs';
import * as Selectors from 'rapidfab/selectors';

const ServiceProviderJobsContainer = props => {
  const serviceProvidersByUri = useSelector(Selectors.getServiceProvidersByUri);

  const dispatch = useDispatch();
  const onInitialize = () =>
    dispatch(Actions.Api.nautilus[API_RESOURCES.SERVICE_PROVIDER].list({}, { limit: PAGINATION_IGNORE_DEFAULT_LIMIT }));

  useEffect(() => {
    onInitialize();
  }, []);

  return <ServiceProviderJobs {...props} serviceProvidersByUri={serviceProvidersByUri} />;
};

export default withRecordsListHandling(
  ServiceProviderJobsContainer,
  Selectors.getServiceProviderJobs,
  ['service-provider-job'],
);
