import {
  faCheckCircle,
  faChevronDown,
  faChevronUp,
  faFileExport,
  faXmark,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { truncateFileName } from 'rapidfab/utils/fileUtils';
import PropTypes from 'prop-types';
import Loading from 'rapidfab/components/Loading';
import React, { memo } from 'react';
import { Modal, OverlayTrigger, Tooltip } from 'react-bootstrap';

const UploadFileModalProgressBarBody = ({
  uploadingHeaderTitle,
  isModalView,
  uploadingState,
  hideFileLoader,
  toggleCollapsed,
  isCollapsed,
  allFilesUploaded,
}) => {
  const iconsHeightStyle = { height: '1.2em' };
  return (
    <div className={!isModalView ? 'progress-bar-upload-file' : ''}>
      <div className={`${isModalView
        ? 'progress-bar-upload-file-header-modal'
        : 'progress-bar-upload-file-header'}`}
      >
        <p>
          {
            isCollapsed ? (allFilesUploaded
              ? <FontAwesomeIcon className="spacer-right" icon={faCheckCircle} color="#3FBA03" style={iconsHeightStyle} />
              : <Loading inline className="spacer-right" />)
              : null
          }
          {uploadingHeaderTitle}
        </p>
        {
          !isModalView && (
            <div className="d-flex align-items-center">
              <button className="progress-bar-upload-file-button spacer-right" type="button" onClick={toggleCollapsed}>
                <FontAwesomeIcon icon={isCollapsed ? faChevronUp : faChevronDown} fontSize="16px" />
              </button>
              <button className="progress-bar-upload-file-button" type="button" onClick={hideFileLoader}>
                <FontAwesomeIcon icon={faXmark} fontSize="16px" />
              </button>
            </div>
          )
        }
      </div>
      {
        !isCollapsed && (
          <div className="progress-bar-upload-file-body">
            {uploadingState.map(({ file, percent, formattedTime }) => {
              const fileName = file?.name ?? '';
              const fileNameLong = fileName.length > 30;

              return (
                <div className={`${!isModalView ? 'progress-bar-upload-file-item' : ''}`}>
                  <div className="progress-bar-upload-file-data">
                    <div className="d-flex align-items-center">
                      {
                        fileName && (
                          <div className="d-flex align-items-center">
                            {!percent
                              ? <Loading inline style={{ marginRight: 11 }} iconStyle={iconsHeightStyle} />
                              : <FontAwesomeIcon className="spacer-right" icon={faFileExport} style={iconsHeightStyle} />}
                            <div>
                              {
                                fileNameLong ? (
                                  <OverlayTrigger
                                    placement="top"
                                    className="custom-tooltip"
                                    overlay={(
                                      <Tooltip className="custom-tooltip">
                                        <p className="mb0">{fileName}</p>
                                      </Tooltip>
                                    )}
                                  >
                                    <div className="d-flex align-items-center">
                                      <p className="mb0">
                                        {truncateFileName(fileName, 30) ?? 'File not found'}
                                      </p>
                                    </div>
                                  </OverlayTrigger>
                                ) : (
                                  <p className="mb0">{fileName ?? 'File not found'}</p>
                                )
                              }
                              <p className="progress-bar-upload-file-estimation">{formattedTime}</p>
                            </div>
                          </div>
                        )
                      }
                    </div>
                    {
                      percent === 100
                        ? <FontAwesomeIcon icon={faCheckCircle} color="#3FBA03" style={iconsHeightStyle} />
                        : <span>{percent}%</span>
                    }
                  </div>
                  <div className="upload-file-progress-bar" style={{ width: `${percent}%` }} />
                </div>
              );
            })}
          </div>
        )
      }
    </div>
  );
};

UploadFileModalProgressBarBody.propTypes = {
  uploadingState: PropTypes.arrayOf(PropTypes.shape({
    file: PropTypes.shape({
      name: PropTypes.string,
    }),
    id: PropTypes.string,
    percent: PropTypes.number,
    formattedTime: PropTypes.string,
  })).isRequired,
  uploadingHeaderTitle: PropTypes.string.isRequired,
  isModalView: PropTypes.bool.isRequired,
  hideFileLoader: PropTypes.func,
  toggleCollapsed: PropTypes.func,
  isCollapsed: PropTypes.bool,
  allFilesUploaded: PropTypes.bool,
};

UploadFileModalProgressBarBody.defaultProps = {
  hideFileLoader: () => {},
  toggleCollapsed: () => {},
  isCollapsed: false,
  allFilesUploaded: false,
};

const UploadFileModalProgressBar = memo(({
  uploadingState,
  isModalView,
  uploadingHeaderTitle,
  hideFileLoader,
  toggleCollapsed,
  isCollapsed,
  allFilesUploaded,
}) => {
  if (isModalView) {
    return (
      <Modal
        show
        backdrop="static"
      >
        <Modal.Body>
          <UploadFileModalProgressBarBody
            uploadingState={uploadingState}
            uploadingHeaderTitle={uploadingHeaderTitle}
            isModalView={isModalView}
          />
        </Modal.Body>
      </Modal>
    );
  }

  return (
    <UploadFileModalProgressBarBody
      uploadingState={uploadingState}
      uploadingHeaderTitle={uploadingHeaderTitle}
      isModalView={isModalView}
      hideFileLoader={hideFileLoader}
      toggleCollapsed={toggleCollapsed}
      isCollapsed={isCollapsed}
      allFilesUploaded={allFilesUploaded}
    />
  );
},
);

UploadFileModalProgressBar.propTypes = {
  uploadingState: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  uploadingHeaderTitle: PropTypes.string.isRequired,
  isModalView: PropTypes.bool,
  hideFileLoader: PropTypes.func.isRequired,
  toggleCollapsed: PropTypes.func.isRequired,
  isCollapsed: PropTypes.bool.isRequired,
  allFilesUploaded: PropTypes.bool.isRequired,
};

UploadFileModalProgressBar.defaultProps = {
  isModalView: false,
};

export default UploadFileModalProgressBar;
