import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Actions from 'rapidfab/actions';
import _keyBy from 'lodash/keyBy';
import _forEach from 'lodash/forEach';
import _find from 'lodash/find';
import _filter from 'lodash/filter';
import _map from 'lodash/map';
import _uniqBy from 'lodash/uniqBy';
import MaterialBatchGenealogy from 'rapidfab/components/records/MaterialBatchGenealogy';
import {
  getMaterialsByUri,
  getMaterialContainersForBatch,
  getUUIDResource,
  getMaterialGenealogy, getRouteUUID, getFetchedBatch,
} from 'rapidfab/selectors';
import Loading from 'rapidfab/components/Loading';
import { extractUuid, getShortUUID } from 'rapidfab/utils/uuidUtils';
import { connect } from 'react-redux';
import { API_RESOURCES } from 'rapidfab/constants';
import Alert from 'rapidfab/utils/alert';

const TREE_DIRECTION = {
  CHILDREN: 'children_tree',
  PARENTS: 'parents_tree',
};

class MaterialBatchGenealogyContainer extends Component {
  constructor(props) {
    super(props);

    this.initGenealogyGraph = this.initGenealogyGraph.bind(this);

    this.state = {
      family: null,
      vertexesByUri: {},
      direction: TREE_DIRECTION.CHILDREN,
    };
  }

  componentDidMount() {
    const { uuid } = this.props;
    this.props.onInitialize(uuid);
  }

  componentDidUpdate(prevProps) {
    const { uuid, genealogy } = this.props;

    if (uuid !== prevProps.uuid) {
      // eslint-disable-next-line react/no-did-update-set-state
      this.setState({
        vertexesByUri: {},
        family: null,
      });
      this.props.onInitialize(uuid);
    }

    if (genealogy && !prevProps.genealogy) {
      this.initGenealogyGraph();
    }
  }

  initGenealogyGraph() {
    const { genealogy } = this.props;
    const { direction } = this.state;

    const vertexesByUri = {
      ...(_keyBy(genealogy.batches, 'uri')),
      ...(_keyBy(genealogy.actions, 'uri')),
    };

    let family = _map(
      // Backend marks second parent as is_batch_merged_by_action.
      // Ignoring those node relations for family tree to prevent double-parent cases
      (_filter(genealogy[direction], { is_batch_merged_by_action: false })),
      ({ vertex, parent }) => ({
        ...vertexesByUri[vertex],
        isAction: vertex.includes('/material-batch-action/'),
        isBatch: vertex.includes('/material-batch/'),
        name: getShortUUID(vertex),
        parent: parent && getShortUUID(parent),
      }),
    );

    // Add all ignored actions data to appropriate parent vertex
    _forEach(
      _filter(genealogy[direction], { is_batch_merged_by_action: true }),
      ignoredAction => {
        const parentAction = _find(family, { uri: ignoredAction.parent });
        if (parentAction) {
          parentAction.relatedAction = { ...vertexesByUri[ignoredAction.vertex] };
        }

        const childAction = _find(family, { uri: ignoredAction.vertex });
        if (childAction) {
          childAction.relatedAction = { ...vertexesByUri[ignoredAction.parent] };
        }
      },
    );

    // Identify nodes with missing parents
    const nodesWithMissingParents = family.filter(node =>
      node.parent && !family.some(parent => parent.name === node.parent),
    );

    // Add missing nodes only if there are nodes with missing parents
    if (nodesWithMissingParents.length > 0) {
      const modifiedFamily = family.map(node => {
        const parentExists = family.some(parent => parent.name === node.parent);
        if (!parentExists && node.parent !== 'root') {
          return {
            ...node,
            parent: 'root',
            displayName: `Unable to render ${node.name}`,
            missingData: true,
          };
        }
        return node;
      });

      if (!modifiedFamily.some(node => node.name === 'root batch')) {
        modifiedFamily.push({
          name: 'root',
          displayName: `Batch ${nodesWithMissingParents[0]?.parent}` || 'Root Batch',
          parent: '',
          unableToRenderRoot: true,
        });
      }

      family = modifiedFamily;
    }

    this.setState({
      vertexesByUri,
      family: _uniqBy(family, 'uri'),
    });
  }

  render() {
    const { isLoading, batch, lot } = this.props;
    const { family, vertexesByUri } = this.state;

    if (isLoading || !batch || !lot || !family) {
      return (<Loading />);
    }

    return <MaterialBatchGenealogy {...this.props} family={family} vertexesByUri={vertexesByUri} />;
  }
}

MaterialBatchGenealogyContainer.propTypes = {
  uuid: PropTypes.string.isRequired,
  genealogy: PropTypes.shape({
    target_batch: PropTypes.string.isRequired,
    batches: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
    actions: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  }),
  batch: PropTypes.shape({}),
  lot: PropTypes.shape({}),
  onInitialize: PropTypes.func.isRequired,
  isLoading: PropTypes.bool.isRequired,
};

MaterialBatchGenealogyContainer.defaultProps = {
  genealogy: {},
  batch: null,
  lot: null,
};

function mapDispatchToProps(dispatch) {
  const fetchRelatedBatch = async uuid => {
    await dispatch(Actions.Api.nautilus[API_RESOURCES.MATERIAL_BATCH].clear('get'));
    const { json: initialBatch } = await dispatch(Actions.Api.nautilus[API_RESOURCES.MATERIAL_BATCH]
      .get(uuid, true)) ?? {};

    return initialBatch;
  };

  const fetchRelatedGenealogyData = async (lotUri, batchUUID) => {
    await dispatch(Actions.Api.nautilus[API_RESOURCES.MATERIAL_LOT].get(extractUuid(lotUri), true));
    await dispatch(Actions.Api.nautilus[API_RESOURCES.MATERIAL_GENEALOGY].get(batchUUID));
  };
  return {
    onInitialize: async uuid => {
      try {
        if (!uuid) {
          return;
        }
        // Fetch the Current Batch by the Route UUID
        const currentBatch = await fetchRelatedBatch(uuid);
        // Make sure the current batch has the `initial_batch` value
        const initialBatchUri = currentBatch?.initial_batch;

        // If we have the initial batch - we should check if it's the same as the current batch
        if (initialBatchUri) {
          const isCurrentBatchInitial = extractUuid(currentBatch.initial_batch) === uuid;

          // If the current batch is the initial one - we just need to pull the genealogy data
          if (isCurrentBatchInitial) {
            const { material_lots: lotUri } = currentBatch;
            await fetchRelatedGenealogyData(lotUri[0], uuid);
          } else {
            // The current batch is the child batch, we need to fetch the initial batch first
            const initialBatch = await fetchRelatedBatch(extractUuid(initialBatchUri));

            // If we have the initial batch -> fetch the lots and genealogy data to have the full picture
            if (initialBatch) {
              const { material_lots: lotUri } = initialBatch;
              await fetchRelatedGenealogyData(lotUri[0], extractUuid(initialBatch.uri));
            }
          }
        }
      } catch (error) {
        Alert.error(error.message);
      }
    },
  };
}

function mapStateToProps(state) {
  const uuid = getRouteUUID(state);
  const batch = getFetchedBatch(state);
  // TODO: Change when UI for multiple lots is ready extractUuid(batch.material_lot)
  const lot = batch && getUUIDResource(state, extractUuid(batch.material_lots[0]));

  const isLoading = state.ui.nautilus[API_RESOURCES.MATERIAL_BATCH].get.fetching
    || state.ui.nautilus[API_RESOURCES.MATERIAL_LOT].get.fetching
    || state.ui.nautilus[API_RESOURCES.MATERIAL_GENEALOGY].get.fetching;

  const materialsByUri = batch && getMaterialsByUri(state);

  return {
    isLoading,
    uuid,
    batch,
    lot,
    childBatchToHighlight: uuid !== batch?.uuid ? uuid : null,
    initialBatchToHighlight: uuid === batch?.uuid ? uuid : null,
    material: batch && materialsByUri[batch.material],
    genealogy: getMaterialGenealogy(state),
    containers: getMaterialContainersForBatch(state, batch),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(MaterialBatchGenealogyContainer);
