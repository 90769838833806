import React from 'react';
import PropTypes from 'prop-types';
import _ceil from 'lodash/ceil';
import { RUN_STATUSES } from '../../../constants';
import { runResourceType } from '../../../types';
import Loading from '../../Loading';
import OrderProgressTooltip from './OrderProgressTooltip';

const OrderProgress = ({ order, runs, orderRunsEstimates, orderPieces, printsByUri, isFetching }) => {
  if (isFetching) {
    return <Loading />;
  }
  if (orderPieces.length === 0) {
    return null;
  }
  const piecesScheduled = orderPieces.filter(orderPiece => printsByUri[orderPiece.current_print]?.run);
  const complete = runs.filter(run => [RUN_STATUSES.COMPLETE, RUN_STATUSES.CANCELLED].includes(run.status)).length;
  const total = runs ? runs.length : 0;
  const completePercent = piecesScheduled.length === orderPieces.length ? _ceil((complete * 100) / (total || 1)) : 0;
  const isComplete = total === complete && piecesScheduled.length === orderPieces.length;

  return (
    <div className="d-flex align-items-end">
      <div>
        <div>Progress ({complete} of {total} Runs Complete)</div>
        <div className="order-progress">
          <div
            className={`order-progress__bar ${isComplete && 'order-progress__bar--complete'}`}
            style={{ width: `${completePercent}%` }}
          />
        </div>
      </div>
      <div className="d-flex align-items-end">
        <OrderProgressTooltip
          runs={runs}
          orderRunsEstimates={orderRunsEstimates}
          piecesCount={orderPieces.length}
          piecesScheduledCount={piecesScheduled.length}
          orderDueDate={order.due_date}
        />
      </div>
    </div>
  );
};

OrderProgress.propTypes = {
  order: PropTypes.shape({
    uri: PropTypes.string.isRequired,
    due_date: PropTypes.string.isRequired,
  }).isRequired,
  runs: PropTypes.arrayOf(runResourceType).isRequired,
  orderPieces: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  printsByUri: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  orderRunsEstimates: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  isFetching: PropTypes.bool.isRequired,
};

export default OrderProgress;
