import PropTypes from 'prop-types';
import React, { createContext, useContext, useEffect, useState } from 'react';

const YBugContext = createContext(null);

export const useYBugApi = () => useContext(YBugContext);

export const YBugProvider = ({ ybugId, url, settings = {}, children }) => {
  const [ybugApi, setYbugApi] = useState(null);

  useEffect(() => {
    if (ybugId) {
      // Set global Ybug settings
      window.ybug_settings = {
        ...settings,
        id: ybugId,
        onload: () => {
          setYbugApi(window.Ybug);
          if (settings.onload) {
            settings.onload();
          }
        },
      };

      // Create and append the Ybug script
      const script = document.createElement('script');
      script.defer = true;
      script.src = url || `https://widget.ybug.io/button/${ybugId}.js`;
      if (settings?.nonce) {
        script.nonce = settings.nonce; // Optional: Add nonce for CSP
      }
      document.head.append(script);

      return () => {
        // Cleanup function to remove the script and settings
        if (ybugApi) {
          ybugApi.destroy();
        }
        script.remove();
        delete window.ybug_settings;
      };
    }

    return () => {};
  }, [ybugId, settings]);

  return <YBugContext.Provider value={ybugApi}>{children}</YBugContext.Provider>;
};

YBugProvider.defaultProps = {
  settings: {},
  url: null,
  ybugId: null,
};

YBugProvider.propTypes = {
  ybugId: PropTypes.oneOfType([PropTypes.string, null]),
  url: PropTypes.string,
  settings: PropTypes.shape({}),
  children: PropTypes.node.isRequired,
};
