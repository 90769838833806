import {
  faFloppyDisk,
  faPencil,
  faRightFromBracket,
  faRightToBracket,
  faStar as faStarFilled,
} from '@fortawesome/free-solid-svg-icons';
import { faStar } from '@fortawesome/free-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import Loading from 'rapidfab/components/Loading';
import Tooltip from 'rapidfab/components/Tooltip';
import { IMPERSONATION_USER_PROFILE_CONTEXT, ROUTES } from 'rapidfab/constants';
import { resetRcTooltipInnerStyle } from 'rapidfab/constants/styles';
import { getRouteURI } from 'rapidfab/utils/uriUtils';
import RCTooltip from 'rc-tooltip';
import React from 'react';
import { formatProfileAvatarName, truncateEmail, truncateText } from 'rapidfab/utils/stringUtils';
import { Link } from 'react-router-dom';
import { Image } from 'react-bootstrap';

export const ProfileAvatar = ({
  userName,
  toggleMenu,
  isActive,
  avatarRef,
  size = 'sm',
  customClassName,
  imgSource = null,
  style,
}) => {
  const classes = classNames(
    'profile-avatar',
    {
      'profile-avatar-active': isActive && toggleMenu,
      'profile-avatar-not-interactive': !toggleMenu,
      'profile-avatar-sm': size === 'sm',
      'profile-avatar-md': size === 'md',
    },
    customClassName,
  );

  const handleClick = () => {
    if (toggleMenu) {
      toggleMenu();
    }
  };

  return (
    <div
      ref={avatarRef}
      className={classes}
      style={style}
      tabIndex={0}
      role="button"
      onClick={handleClick}
    >
      {imgSource ? (<Image src={imgSource} width={30} height={30} />) :
        (<span>{formatProfileAvatarName(userName)}</span>)}
    </div>
  );
};

ProfileAvatar.propTypes = {
  userName: PropTypes.string.isRequired,
  toggleMenu: PropTypes.func,
  isActive: PropTypes.bool,
  avatarRef: PropTypes.shape({ current: PropTypes.instanceOf(Element) }),
  imgSource: PropTypes.string,
  style: PropTypes.shape({}),
  size: PropTypes.string,
  customClassName: PropTypes.string,
};

ProfileAvatar.defaultProps = {
  isActive: false,
  toggleMenu: null,
  avatarRef: { current: null },
  imgSource: null,
  style: {},
  customClassName: '',
  size: '',
};

const USER_NAME_MAX_LENGTH = 21;
const USER_EMAIL_MAX_LENGTH = 28;

export const ProfileAvatarFull = ({ userName, email, shouldShowProfile }) => {
  if (!userName || !email) {
    return null;
  }

  const handlePreventNavigate = event => {
    if (!shouldShowProfile) {
      event.preventDefault();
    }
  };

  const renderUserName = () => {
    if (!shouldShowProfile) {
      return 'User';
    }

    return userName.length >= USER_NAME_MAX_LENGTH ? (
      <Tooltip
        id="user-name-truncated"
        placement="top"
        trigger={(<div className="profile-avatar-full-name">{truncateText(userName, USER_NAME_MAX_LENGTH)}</div>)}
      >
        {userName}
      </Tooltip>
    ) : (
      <div className="profile-avatar-full-name">{userName}</div>
    );
  };

  return (
    <Link
      to={getRouteURI(ROUTES.PROFILE, {}, {}, true)}
      onClick={handlePreventNavigate}
      className="profile-avatar-full"
    >
      <ProfileAvatar userName={userName} />
      <div className="profile-avatar-full-details">
        {renderUserName()}
        {
          email.length >= USER_EMAIL_MAX_LENGTH ? (
            <Tooltip
              id="user-email-truncated"
              placement="top"
              trigger={(<div className="profile-avatar-full-email">{truncateEmail(email, USER_EMAIL_MAX_LENGTH)}</div>)}
            >
              {email}
            </Tooltip>
          ) : (
            <div className="profile-avatar-full-email">{email}</div>
          )
        }
      </div>
    </Link>
  );
};

ProfileAvatarFull.propTypes = {
  userName: PropTypes.string.isRequired,
  email: PropTypes.string.isRequired,
  shouldShowProfile: PropTypes.bool.isRequired,
};

export const ImpersonateUserProfile = ({
  onImpersonate,
  isAlreadyImpersonating,
  endImpersonation,
  handleFavoriteUser,
  isUserFavorite,
  favoriteUsersProps,
  userProps,
  fetchingProps,
  context,
  impersonatedUserUri,
  emailProps,
}) => {
  const {
    userName,
    email,
    uri,
  } = userProps;

  const {
    impersonationLoadingStates,
    isImpersonationFetching,
  } = fetchingProps;

  const {
    favoriteUserNameToEdit,
    handleEditFavoriteUserName,
    handleSetFavoriteUserToEdit,
    setFavoriteUserNameValue,
    favoriteUserNameValue,
  } = favoriteUsersProps;

  const {
    handleCopyEmail,
    emailsCopiedState,
  } = emailProps;

  if (!email) {
    return null;
  }

  const isCurrentUserFavorite = isUserFavorite(email);
  const isImpersonatingLoading = impersonationLoadingStates[`${uri}_${context}`];
  const isUserEmailCopied = emailsCopiedState[`${email}_${context}`];
  const isImpersonatingAction = isImpersonationFetching || isImpersonatingLoading;
  const isUserSelectedToEditName = favoriteUserNameToEdit?.username === email;
  const currentImpersonatedUserExistsInFavoriteUsers =
    context === IMPERSONATION_USER_PROFILE_CONTEXT.FAVORITE_USERS
    && uri === impersonatedUserUri;
  const shouldEndImpersonation = (isAlreadyImpersonating && uri === impersonatedUserUri)
    || currentImpersonatedUserExistsInFavoriteUsers;

  const impersonateUserClassNames = classNames({
    'profile-avatar-impersonation': true,
    'profile-avatar-not-interactive': isImpersonatingAction,
    'profile-avatar-impersonation-active': isAlreadyImpersonating,
    'profile-avatar-impersonation-favorite': context === IMPERSONATION_USER_PROFILE_CONTEXT.FAVORITE_USERS,
  });

  const impersonateUserNameClassnames = classNames({
    'custom-darken-modal-impersonate-edit-name': true,
    'custom-darken-modal-impersonate-edit-name-active': isUserSelectedToEditName,
  });

  const favoriteUserClassNames = classNames({
    'custom-darken-modal-impersonate-button': true,
    'profile-avatar-favorite-active': isCurrentUserFavorite,
  });

  const endImpersonationClassNames = classNames({
    'custom-darken-modal-impersonate-button': true,
    'custom-darken-modal-impersonate-button-end': shouldEndImpersonation,
  });

  const renderUserName = () => {
    if (!userName) {
      return 'User';
    }

    const isUserNameTruncated = userName.length >= USER_NAME_MAX_LENGTH;

    const userNameDisplay = (
      <div className="custom-darken-modal--impersonation-full-name">
        {
          isUserSelectedToEditName ? (
            <input
              type="text"
              name="name"
              autoComplete="off"
              value={favoriteUserNameValue}
              onChange={event => setFavoriteUserNameValue(event.target.value)}
              className="custom-darken-modal--impersonation-full-name-input"
            />
          ) : (
            <p className="mb0">
              {isUserNameTruncated ? truncateText(userName, USER_NAME_MAX_LENGTH) : userName}
            </p>
          )
        }
        {context === IMPERSONATION_USER_PROFILE_CONTEXT.FAVORITE_USERS && (
          <FontAwesomeIcon
            icon={isUserSelectedToEditName ? faFloppyDisk : faPencil}
            onClick={() => (isUserSelectedToEditName ?
              handleEditFavoriteUserName(email, favoriteUserNameValue) :
              handleSetFavoriteUserToEdit(email))}
            tabIndex={0}
            role="button"
            className={impersonateUserNameClassnames}
          />
        )}
      </div>
    );

    return isUserNameTruncated ? (
      <RCTooltip
        placement="top"
        id="user-name-truncated"
        destroyTooltipOnHide
        overlayInnerStyle={resetRcTooltipInnerStyle}
        overlay={(
          <p className="mb0 greyTooltip">{userName}</p>
        )}
      >
        { userNameDisplay }
      </RCTooltip>
    ) : userNameDisplay;
  };

  const renderEmail = () => {
    const content = isUserEmailCopied
      ? 'Copied ✔' : (email.length >= USER_EMAIL_MAX_LENGTH
        ? truncateEmail(email, USER_EMAIL_MAX_LENGTH) : email);
    const handleClick = () => handleCopyEmail(email, context);

    if (email.length >= USER_EMAIL_MAX_LENGTH) {
      return (
        <RCTooltip
          placement="bottom"
          id="user-email-truncated"
          destroyTooltipOnHide
          overlayInnerStyle={resetRcTooltipInnerStyle}
          overlay={<p className="mb0 greyTooltip">{email}</p>}
        >
          <div className="profile-avatar-full-email" onClick={handleClick} role="button" tabIndex={0}>
            {content}
          </div>
        </RCTooltip>
      );
    }
    return (
      <div className="profile-avatar-full-email" onClick={handleClick} role="button" tabIndex={0}>
        {content}
      </div>
    );
  };

  const handleImpersonate = () => {
    if (shouldEndImpersonation) {
      return endImpersonation(true, uri, context);
    }
    return onImpersonate(uri, context);
  };

  return (
    <div
      className={impersonateUserClassNames}
    >
      <div className="d-flex align-items-center">
        <ProfileAvatar userName={userName || email} />
        <div className="profile-avatar-full-details">
          {renderUserName()}
          {renderEmail()}
        </div>
      </div>
      <div className="d-flex align-items-center">
        <div className="profile-avatar-separator" />

        <div className="custom-darken-modal-impersonate-button-set">
          <FontAwesomeIcon
            icon={isCurrentUserFavorite ? faStarFilled : faStar}
            onClick={() => handleFavoriteUser(email)}
            tabIndex={0}
            role="button"
            className={favoriteUserClassNames}
          />
          {
            isImpersonatingLoading ? (
              <Loading className="custom-darken-modal-impersonate-button custom-darken-modal-impersonate-loading-button" />
            ) : (
              <FontAwesomeIcon
                icon={shouldEndImpersonation
                  ? faRightFromBracket
                  : faRightToBracket}
                onClick={handleImpersonate}
                tabIndex={0}
                role="button"
                className={endImpersonationClassNames}
              />
            )
          }
        </div>
      </div>
    </div>
  );
};

ImpersonateUserProfile.defaultProps = {
  isAlreadyImpersonating: false,
  endImpersonation: () => {},
  favoriteUsersProps: {},
  impersonatedUserUri: '',
};

ImpersonateUserProfile.propTypes = {
  userProps: PropTypes.shape({
    userName: PropTypes.string,
    email: PropTypes.string,
    uri: PropTypes.string,
  }).isRequired,
  onImpersonate: PropTypes.func.isRequired,
  isAlreadyImpersonating: PropTypes.bool,
  endImpersonation: PropTypes.func,
  handleFavoriteUser: PropTypes.func.isRequired,
  isUserFavorite: PropTypes.func.isRequired,
  favoriteUsersProps: PropTypes.shape({
    favoriteUserNameToEdit: PropTypes.shape({
      username: PropTypes.string,
    }),
    handleEditFavoriteUserName: PropTypes.func,
    handleSetFavoriteUserToEdit: PropTypes.func,
    setFavoriteUserNameValue: PropTypes.func,
    favoriteUserNameValue: PropTypes.string,
  }),
  fetchingProps: PropTypes.shape({
    impersonationLoadingStates: PropTypes.shape({}),
    isImpersonationFetching: PropTypes.bool,
  }).isRequired,
  context: PropTypes.string.isRequired,
  impersonatedUserUri: PropTypes.string,
  emailProps: PropTypes.shape({
    handleCopyEmail: PropTypes.func,
    emailsCopiedState: PropTypes.shape({}),
  }).isRequired,
};
