import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Fa from 'react-fontawesome';
import {
  COLORS,
  PREP_TASK_RECORD_ACTIVE_STATUSES,
  PREP_TASK_RECORD_STATUSES,
} from 'rapidfab/constants';
import { PREP_TASK_RECORD_STATUS_COLOR_MAP } from 'rapidfab/mappings';
import Loading from 'rapidfab/components/Loading';
import { prepTaskRecordType } from 'rapidfab/types';

const StatusButtons = ({ status, onStatusChange }) => {
  const [isProcessing, setIsProcessing] = useState(false);

  const onClick = newStatus => {
    setIsProcessing(true);
    onStatusChange(newStatus).finally(() => {
      setIsProcessing(false);
    });
    return false;
  };

  if (isProcessing) {
    return <Loading />;
  }

  if (!PREP_TASK_RECORD_ACTIVE_STATUSES.includes(status)) {
    return null;
  }

  const renderButton = (newStatus, iconName) => (
    <Fa
      name={iconName}
      size="lg"
      onClick={event => {
        // Prevent triggering PrepTaskRecord modal opening
        event.stopPropagation();
        onClick(newStatus);
      }}
      className="spacer-left spacer-right"
    />
  );

  return (
    <>
      {
        [PREP_TASK_RECORD_STATUSES.ON_HOLD, PREP_TASK_RECORD_STATUSES.QUEUED].includes(status)
        && renderButton(PREP_TASK_RECORD_STATUSES.IN_PROGRESS, 'play-circle')
      }
      {
        status === PREP_TASK_RECORD_STATUSES.IN_PROGRESS
        && renderButton(PREP_TASK_RECORD_STATUSES.ON_HOLD, 'pause-circle')
      }
      {
        status === PREP_TASK_RECORD_STATUSES.QUEUED
        && renderButton(
          PREP_TASK_RECORD_STATUSES.SKIPPED,
          'fast-forward',
        )
      }
      {
        status === PREP_TASK_RECORD_STATUSES.IN_PROGRESS
        && renderButton(
          PREP_TASK_RECORD_STATUSES.COMPLETE,
          'check-circle',
        )
      }
    </>
  );
};

StatusButtons.propTypes = {
  status: PropTypes.string,
  onStatusChange: PropTypes.func.isRequired,
};

StatusButtons.defaultProps = {
  status: null,
};

const PrepTaskProgressItem = ({
  taskInWorkflow,
  prepTaskRecord,
  prepTask,
  onClick,
  onStatusChange,
}) => {
  const { position } = taskInWorkflow;
  const isDisabled = !prepTaskRecord;
  const isInActiveState = prepTaskRecord && PREP_TASK_RECORD_ACTIVE_STATUSES.includes(prepTaskRecord.status);
  const prepTaskRecordStatus = prepTaskRecord && prepTaskRecord.status;

  const bgColor = PREP_TASK_RECORD_STATUS_COLOR_MAP[prepTaskRecordStatus] || COLORS.GRAY;

  const handleStatusChange = newStatus =>
    onStatusChange(prepTaskRecord.uuid, newStatus);

  return (
    <div
      className={`step ${isDisabled ? 'disabled' : ''} ${isInActiveState ? 'active' : ''}`}
      style={{
        // Sync border color with bg color for active state
        borderColor: isInActiveState ? bgColor : null,
      }}
      key={position}
      role="button"
      tabIndex={position}
      onClick={
        isDisabled ? null : () => onClick(prepTaskRecord.uuid)
      }
    >
      <div
        style={{ backgroundColor: bgColor }}
        className="step-circle"
      >
        <span>{position + 1}</span>
      </div>
      <div className="step-title">
        <p>{prepTask.shortname}</p>
        <StatusButtons status={prepTaskRecordStatus} onStatusChange={handleStatusChange} />
      </div>
      <div className="step-bar-left" />
      <div className="step-bar-right" />
    </div>
  );
};

PrepTaskProgressItem.propTypes = {
  taskInWorkflow: PropTypes.shape({
    position: PropTypes.number,
    prep_task: PropTypes.string,
  }).isRequired,
  prepTaskRecord: prepTaskRecordType,
  prepTask: PropTypes.shape({
    shortname: PropTypes.string,
  }).isRequired,
  onClick: PropTypes.func.isRequired,
  onStatusChange: PropTypes.func.isRequired,
};

PrepTaskProgressItem.defaultProps = {
  prepTaskRecord: null,
};

export default PrepTaskProgressItem;
