import React from 'react';
import { useSelector } from 'react-redux';

import * as Selectors from 'rapidfab/selectors';

import { Admin } from 'rapidfab/components/admin';
import { FEATURES } from 'rapidfab/constants';

const AdminContainer = () => {
  const bureauName = useSelector(Selectors.getBureauName);
  const isHawkingUser = useSelector(state => Selectors.isFeatureEnabled(state, FEATURES.HAWKING_DEPLOYMENT));

  return <Admin bureauName={bureauName} isHawkingUser={isHawkingUser} />;
};

export default AdminContainer;
