/* eslint-disable no-shadow */
import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import ExpertConversion from 'rapidfab/components/hawking/ExpertConversion';
import { extractUuid } from 'rapidfab/utils/uuidUtils';
import Actions from 'rapidfab/actions';
import { Col, Row } from 'react-bootstrap';
import { API_RESOURCES } from 'rapidfab/constants';
import Loading from 'rapidfab/components/Loading';
import * as Selectors from 'rapidfab/selectors';

const ExpertConversionContainer = ({ modelUri }) => {
  const model = useSelector(state => Selectors.getUUIDResource(state, extractUuid(modelUri)));
  const { expert_conversion: expertConversionUri } = model;
  const expertConversion = useSelector(state => Selectors.getExpertConversionForModel(state, model));
  const { fetching } = useSelector(state => state.ui.nautilus[API_RESOURCES.EXPERT_CONVERSION].get);
  const usersByUri = useSelector(Selectors.getUsersByUri);
  const sessionUser = useSelector(Selectors.getSession);
  const isConversionExpert = !!usersByUri[sessionUser?.uri]?.conversion_expert_uuid;

  const dispatch = useDispatch();

  const onInitialize = conversionUri => dispatch(
    Actions.Api.nautilus[API_RESOURCES.EXPERT_CONVERSION].get(extractUuid(conversionUri)),
  );

  const onUploadModel = async modelFile => {
    // 'upload-location' link lives for 5 minutes only
    // fetching model right before upload to make sure 'upload-location' link is valid
    const response = await dispatch(Actions.DownloadModel.fetchModel(modelUri));
    dispatch(Actions.UploadModel.upload(response.json['upload-location'], modelFile));
  };

  useEffect(() => {
    if (expertConversionUri) {
      onInitialize(expertConversionUri);
    }
  }, [expertConversionUri]);
  return (
    <Row>
      <Col xs={12}>
        {
          fetching
            ? <Loading />
            : (
              <ExpertConversion
                expertConversion={expertConversion}
                onUploadModel={onUploadModel}
                isConversionExpert={isConversionExpert}
              />
            )
        }
      </Col>
    </Row>
  );
};

ExpertConversionContainer.propTypes = {
  modelUri: PropTypes.string.isRequired,
};

export default ExpertConversionContainer;
