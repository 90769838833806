import _assign from 'lodash/assign';
import _map from 'lodash/map';
import _toArray from 'lodash/toArray';
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import ChartJs from 'chart.js/auto';
import { injectIntl } from 'react-intl';
import 'chartjs-adapter-moment';

export const SeriesStyle = {
  Warning: { color: '#e4d836', hover: '#ccbf1b' },
  Info: { color: '#9f86ff', hover: '#7753ff' },
  Danger: { color: '#e64759', hover: '#dc1e33' },
  Success: { color: '#1bc98e', hover: '#159c6e' },
  Default: { color: '#ffffff', hover: '#e6e6e6' },
  Primary: { color: '#1ca8dd', hover: '#1686b0' },
};

export const SeriesStyles = _toArray(SeriesStyle);

export function randomColorByString(string) {
  let hash = 0;
  for (let index = 0; index < string.length; index += 1) {
    // eslint-disable-next-line no-bitwise
    hash = string.charCodeAt(index) + ((hash << 5) - hash);
  }
  let colour = '#';
  for (let index = 0; index < 3; index += 1) {
    // eslint-disable-next-line no-bitwise
    const value = (hash >> (index * 8)) & 0xFF;
    colour += (`00${value.toString(16)}`).slice(-2);
  }
  return colour;
}

function formatLabel(intl, label) {
  if (typeof label !== 'object') return label;
  return intl.formatMessage(label.props ? label.props : label);
}

function formatLabels(intl, labels) {
  return _map(labels, label => formatLabel(intl, label));
}

class Chart extends Component {
  componentDidMount() {
    const datasets = _map(this.props.data.datasets, (dataset, index) => {
      let style =
        SeriesStyles[index];
      if (!style) {
        style = { color: dataset.backgroundColor[0], hover: dataset.hoverBackgroundColor[0] };
      }
      return _assign(
        {
          borderColor: style.color,
          hoverBorderColor: style.hover,
          backgroundColor: style.color,
          hoverBackgroundColor: style.hover,
          pointHoverColor: style.hover,
          pointBackgroundColor: style.color,
          borderWidth: this.props.type === 'line' ? 2 : 0,
          fill: false,
        },
        dataset,
      );
    });
    /* eslint-disable react/no-string-refs */
    const chart = new ChartJs(this.refs.chart, {
      type: this.props.type,
      data: _assign({}, this.props.data, {
        labels: formatLabels(this.props.intl, this.props.data.labels),
        datasets,
      }),
      options: {
        hover: { mode: 'single' },
        tooltips: {
          callbacks: {
            label: tooltipItem => tooltipItem.yLabel,
          },
        },

        plugins: {
          legend: {
            display: false,
          },
        },
        scales: {
          y:
            {
              ticks: { min: 0 },
            },

        },
        ...this.props.options,
      },
    });
    /* eslint-enable react/no-string-refs */
    /* eslint-disable react/no-did-mount-set-state */
    this.setState({ chart });
    /* eslint-enable react/no-did-mount-set-state */
  }

  componentDidUpdate() {
    const { chart } = this.state;
    const { data } = this.props;
    for (
      let index = 0;
      index < data.datasets.length && index < chart.data.datasets.length;
      index += 1
    ) {
      chart.data.datasets[index].data = data.datasets[index].data;
    }
    chart.data.labels = formatLabels(this.props.intl, data.labels);
    chart.update();
  }

  render() {
    /* eslint-disable react/no-string-refs */
    return <canvas ref="chart" height={this.props.height} width="600" />;
    /* eslint-disable react/no-string-refs */
  }
}

Chart.defaultProps = {
  height: '300',
  options: {},
};

Chart.propTypes = {
  data: PropTypes.shape({
    datasets: PropTypes.arrayOf(PropTypes.shape({
      data: PropTypes.arrayOf(PropTypes.number),
    })).isRequired,
    labels: PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.string, PropTypes.node])),
  }).isRequired,
  intl: PropTypes.shape({}).isRequired,
  options: PropTypes.shape({}),
  type: PropTypes.string.isRequired,
  height: PropTypes.string,
};

export default injectIntl(Chart);
