import React from 'react';
import PropTypes from 'prop-types';
import ResourceReadOnlyViewRow, { RESOURCE_READ_ONLY_VIEW_FIELD_TYPES } from 'rapidfab/components/ResourceReadOnlyView/ResourceReadOnlyViewRow';
import ResourceReadOnlyView from 'rapidfab/components/ResourceReadOnlyView/ResourceReadOnlyView';
import { Col, Container, Row } from 'react-bootstrap';
import { getShortUUID } from 'rapidfab/utils/uuidUtils';
import BreadcrumbNav from 'rapidfab/components/BreadcrumbNav';
import { FormattedMessage } from 'react-intl';
import { CAPTURE_STATUS_MAP } from 'rapidfab/mappings';

const Capture = ({ capture, captureType, user }) => (
  <Container fluid>
    <BreadcrumbNav
      breadcrumbs={['track', 'captures', getShortUUID(capture.uuid)]}
    />
    <Row>
      <Col xs={6}>
        <ResourceReadOnlyView
          withPanelWrapper
          entity={capture}
        >
          <ResourceReadOnlyViewRow
            name="status"
            type={RESOURCE_READ_ONLY_VIEW_FIELD_TYPES.CUSTOM}
            customValue={(
              <FormattedMessage {...CAPTURE_STATUS_MAP[capture.status]} />
            )}
          />
          <ResourceReadOnlyViewRow
            label="Type"
            type={RESOURCE_READ_ONLY_VIEW_FIELD_TYPES.CUSTOM}
            customValue={(captureType && captureType.capture_type)}
          />
          <ResourceReadOnlyViewRow
            label="Interval"
            type={RESOURCE_READ_ONLY_VIEW_FIELD_TYPES.CUSTOM}
            // Interval is in seconds. Converting to minutes (same way as it was done in Scylla historically)
            customValue={(captureType && captureType.interval && captureType.interval / 60)}
          />
          <ResourceReadOnlyViewRow
            name="user"
            type={RESOURCE_READ_ONLY_VIEW_FIELD_TYPES.CUSTOM}
            customValue={user && (`${user.name} (${user.username})`)}
          />
          <ResourceReadOnlyViewRow
            name="created"
            type={RESOURCE_READ_ONLY_VIEW_FIELD_TYPES.DATETIME}
          />
          <ResourceReadOnlyViewRow
            name="updated"
            type={RESOURCE_READ_ONLY_VIEW_FIELD_TYPES.DATETIME}
          />
          <ResourceReadOnlyViewRow
            name="content_parsed_download"
            label="Parsed Content"
            type={RESOURCE_READ_ONLY_VIEW_FIELD_TYPES.CUSTOM}
            customValue={(
              <a href={capture.content_parsed_download}>
                <FormattedMessage
                  id="button.download"
                  defaultMessage="Download"
                />
              </a>
            )}
          />
          <ResourceReadOnlyViewRow
            name="content_download"
            label="Raw Content"
            type={RESOURCE_READ_ONLY_VIEW_FIELD_TYPES.CUSTOM}
            customValue={(
              <a href={capture.content_download}>
                <FormattedMessage
                  id="button.download"
                  defaultMessage="Download"
                />
              </a>
            )}
          />
        </ResourceReadOnlyView>
      </Col>
    </Row>
  </Container>
);

Capture.defaultProps = {
  user: null,
  captureType: null,
};

Capture.propTypes = {
  capture: PropTypes.shape({
    status: PropTypes.string.isRequired,
    user: PropTypes.string.isRequired,
    uuid: PropTypes.string.isRequired,
    created: PropTypes.string.isRequired,
    updated: PropTypes.string.isRequired,
    content_parsed_download: PropTypes.string.isRequired,
    content_download: PropTypes.string.isRequired,
  }).isRequired,
  user: PropTypes.shape({
    username: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    email: PropTypes.string.isRequired,
  }),
  captureType: PropTypes.shape({
    capture_type: PropTypes.string.isRequired,
    interval: PropTypes.number.isRequired,
  }),
};

export default Capture;
