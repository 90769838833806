import { useSelector } from 'react-redux';
import * as Selectors from 'rapidfab/selectors';
import dayjs from 'dayjs';
import { useMemo } from 'react';

const useBureauNotifications = () => {
  const bureauNotifications = useSelector(Selectors.getBureauNotifications);

  const localStorageItemName = 'timestampHasReadBureauNotifications';
  const hasReadBureauNotifications = localStorage.getItem(localStorageItemName);
  const markBureauNotificationsAsRead = () =>
    localStorage.setItem(localStorageItemName, dayjs().toISOString());
  const hasReadBureauNotificationsWithinLast24Hours = dayjs(hasReadBureauNotifications).isAfter(dayjs().subtract(1, 'day'));

  /** Returns if notifications sent within the past 24 hours is present, and/if read timestamp
   * date is nonexistent, or outside of the last 24 hours.
   */
  const hasRecentNotifications = useMemo(() => {
    const oneDayAgo = dayjs().subtract(1, 'day');

    return !hasReadBureauNotificationsWithinLast24Hours &&
      bureauNotifications.some(notification => {
        const createdDate = dayjs(notification.created);
        return createdDate.isAfter(oneDayAgo);
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [bureauNotifications, hasReadBureauNotifications, hasReadBureauNotificationsWithinLast24Hours]);

  return { bureauNotifications, hasRecentNotifications, markBureauNotificationsAsRead };
};

export default useBureauNotifications;
