import _capitalize from 'lodash/capitalize';
import PropTypes from 'prop-types';
import MaterialCard from 'rapidfab/components/inventory/MaterialCard';
import React from 'react';

const MaterialsCards = ({
  groupedMaterials,
  manufacturersByUri,
  printerTypes,
  printerTypesFetching,
  renderHeaderView,
  handleShowPrinterTypeModal,
}) => (
  <>
    {renderHeaderView()}
    <div className="ResourceCardListCards">
      {Object.keys(groupedMaterials).length ?
        Object.entries(groupedMaterials).map(([materialType, materials]) => {
          const materialTypeTitle = materialType !== 'null' ? `${_capitalize(materialType)} Material` : 'Unassigned';

          return (
            <>
              <div className="ResourceCardListLocation">
                <span className="spacer-right">{materialTypeTitle}</span>
              </div>
              <div className="card-list ResourceCardListSmall mb30">
                {materials.map(material => (
                  <MaterialCard
                    material={material}
                    manufacturersByUri={manufacturersByUri}
                    printerTypes={printerTypes}
                    printerTypesFetching={printerTypesFetching}
                    handleShowPrinterTypeModal={handleShowPrinterTypeModal}
                  />
                ))}
              </div>
            </>
          );
        })
        : (
          <h1 className="text-center mt-2">Nothing found</h1>
        )}
    </div>
  </>
);

MaterialsCards.propTypes = {
  groupedMaterials: PropTypes.shape({}).isRequired,
  manufacturersByUri: PropTypes.shape({}).isRequired,
  printerTypes: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  printerTypesFetching: PropTypes.bool.isRequired,
  renderHeaderView: PropTypes.func.isRequired,
  handleShowPrinterTypeModal: PropTypes.func.isRequired,
};

export default MaterialsCards;
