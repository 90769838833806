import PropTypes from 'prop-types';
import { FormattedMessage } from 'rapidfab/i18n';
import React from 'react';
import { FormControl, FormLabel } from 'react-bootstrap';

const FilterItem = ({ label, name, value, onChange, children }) => (
  <div className="form-group mr15">
    <FormLabel>
      {label}:
    </FormLabel>
    <FormControl
      className="spacer-left truncate-data"
      as="select"
      id={name}
      name={name}
      onChange={onChange}
      type="select"
      value={value}
    >
      <FormattedMessage id="all" defaultMessage="All">{text =>
        <option value="">{text}</option>}
      </FormattedMessage>
      {children}
    </FormControl>
  </div>
);

FilterItem.defaultProps = { value: '' };

FilterItem.propTypes = {
  label: PropTypes.node.isRequired,
  name: PropTypes.string.isRequired,
  value: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  children: PropTypes.node.isRequired,
};

export default FilterItem;
