import React, { useState } from 'react';
import PropTypes from 'prop-types';
import {
  USER_SETTINGS_KEYS,
  USER_SETTINGS_VALUES,
  FEATURES,
  UNITS,
  CurrencySymbols,
} from 'rapidfab/constants';
import { Container, Row, Col, Form, Button, Alert } from 'react-bootstrap';
import { FormattedMessage } from 'react-intl';
import BreadcrumbNav from 'rapidfab/components/BreadcrumbNav';
import Feature from 'rapidfab/components/Feature';
import ResourceReadOnlyView from 'rapidfab/components/ResourceReadOnlyView/ResourceReadOnlyView';
import ResourceReadOnlyViewRow from 'rapidfab/components/ResourceReadOnlyView/ResourceReadOnlyViewRow';
import { extractUuid } from 'rapidfab/utils/uuidUtils';
import QrLogonCodeModal, { QrLogonCodeDisclaimerConfirmationModal } from 'rapidfab/components/qr/QrLogonCodeModal';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faQrcode } from '@fortawesome/free-solid-svg-icons';

const Profile = props => {
  const {
    onUserSettingsUpdate,
    onSelectCurrency,
    onSetUnitsMetric,
    onSetUnitsImperial,
    units,
    selectedCurrency,
    availableConversions,
    user,
    getContactlessLogonQRCode,
    isContactlessLoginEnabled,
  } = props;
  let { settings } = props;
  if (!settings) {
    settings = {
      [USER_SETTINGS_KEYS.LINE_ITEM_AUTO_STATUS_ON_PREP_WORKFLOW_COMPLETE]:
        USER_SETTINGS_VALUES.LINE_ITEM_AUTO_STATUS_ON_PREP_WORKFLOW_COMPLETE
          .LEAVE_PENDING,
    };
  }

  const onChangeUserSettingsLineItemAutoStatusOnPrepWorkflowComplete =
    isConfirmLineItem => {
      onUserSettingsUpdate({
        ...settings,
        [USER_SETTINGS_KEYS.LINE_ITEM_AUTO_STATUS_ON_PREP_WORKFLOW_COMPLETE]:
          isConfirmLineItem
            ? USER_SETTINGS_VALUES
              .LINE_ITEM_AUTO_STATUS_ON_PREP_WORKFLOW_COMPLETE.SET_CONFIRMED
            : USER_SETTINGS_VALUES
              .LINE_ITEM_AUTO_STATUS_ON_PREP_WORKFLOW_COMPLETE.LEAVE_PENDING,
      });
    };

  const isUserSettingsLineItemAutoStatusOnPrepWorkflowComplete =
    settings[
      USER_SETTINGS_KEYS.LINE_ITEM_AUTO_STATUS_ON_PREP_WORKFLOW_COMPLETE
    ] ===
    USER_SETTINGS_VALUES.LINE_ITEM_AUTO_STATUS_ON_PREP_WORKFLOW_COMPLETE
      .SET_CONFIRMED;

  const [showQrLogonCodeModal, setShowQrLogonCodeModal] = useState(false);
  const [showQrLogonCodeWarningDisclaimerModal, setShowQrLogonCodeWarningDisclaimerModal] = useState(false);
  const [qrCodeBase64Data, setQrCodeBase64Data] = useState(null);
  const [isLoadingQRCodeData, setIsLoadingQRCodeData] = useState(false);

  const handleGetContactlessLogonQRCode = async () => {
    setIsLoadingQRCodeData(true);
    const qrCodeBase64Data = await getContactlessLogonQRCode(extractUuid(user.uri))
      .catch(error => {
        Alert(error);
        return setIsLoadingQRCodeData(false);
      });
    if (qrCodeBase64Data) {
      setQrCodeBase64Data(qrCodeBase64Data);
      setShowQrLogonCodeModal(true);
      return setIsLoadingQRCodeData(false);
    }
    return setIsLoadingQRCodeData(true);
  };

  const onUserConfirmGetContactlessLogonQRCode = shouldUserProceed => {
    setShowQrLogonCodeWarningDisclaimerModal(false);
    if (shouldUserProceed) {
      handleGetContactlessLogonQRCode();
    }
  };

  return (
    <Container>
      <QrLogonCodeDisclaimerConfirmationModal
        show={showQrLogonCodeWarningDisclaimerModal}
        onConfirm={onUserConfirmGetContactlessLogonQRCode}
      />
      <QrLogonCodeModal
        show={showQrLogonCodeModal}
        onHide={() => setShowQrLogonCodeModal(false)}
        qrCode={qrCodeBase64Data}
        user={user}
      />
      <BreadcrumbNav breadcrumbs={['profile']} />
      <Row>
        <Col xs={12}>
          <h3 className="font-size-24 mt15">Profile</h3>
          <div className="d-flex flex-direction-column">
            <ResourceReadOnlyView entity={user}>
              <ResourceReadOnlyViewRow name="name" />
              <ResourceReadOnlyViewRow name="username" label="Email" />
            </ResourceReadOnlyView>
          </div>
          <h3 className="font-size-24 mt15">Settings</h3>
          <div className="mb30">
            <b>Units</b>
            <Form>
              <Form.Check
                checked={units === UNITS.metric}
                onChange={onSetUnitsMetric}
                type="radio"
                label={(
                  <FormattedMessage id="metric" defaultMessage="Metric" />
                )}
              />
              <Form.Check
                checked={units === UNITS.imperial}
                onChange={onSetUnitsImperial}
                type="radio"
                label={(
                  <FormattedMessage id="imperial" defaultMessage="Imperial" />
                )}
              />
            </Form>
            {/* <Radio checked={units === UNITS.metric} onChange={onSetUnitsMetric}>
              <FormattedMessage id="metric" defaultMessage="Metric" />
            </Radio> */}
            {/* <Radio
              checked={units === UNITS.imperial}
              onChange={onSetUnitsImperial}
            >
              <FormattedMessage id="imperial" defaultMessage="Imperial" />
            </Radio> */}
          </div>
          <div className="mb30">
            <b>Currency</b>
            <Form>
              {availableConversions.map(availableConversion => (
                <Form.Check
                  checked={availableConversion.currency === selectedCurrency}
                  key={availableConversion.currency}
                  onChange={() =>
                    onSelectCurrency(availableConversion.currency)}
                  type="radio"
                  label={(
                    <>
                      <span
                        style={{ margin: '0 2px 0 6px' }}
                        /* eslint-disable-next-line react/no-danger */
                        dangerouslySetInnerHTML={{
                          __html: CurrencySymbols[availableConversion.currency],
                        }}
                      />
                      {availableConversion.currency}
                    </>
                  )}
                />
              ))}
            </Form>
          </div>
          <Feature featureName={FEATURES.PREP_WORKFLOW}>
            <div className="mb30">
              <b>Prep Workflow Settings</b>
              <Form.Check
                name="prepWorkflowLineItemStatus"
                checked={isUserSettingsLineItemAutoStatusOnPrepWorkflowComplete}
                onChange={({ target }) =>
                  onChangeUserSettingsLineItemAutoStatusOnPrepWorkflowComplete(
                    target.checked,
                  )}
                type="checkbox"
              >
                Convert prepped line item to Confirmed status upon completion of
                prep workflow
              </Form.Check>
            </div>
          </Feature>
          {isContactlessLoginEnabled && (
            <div className="mb30">
              <b>Contactless Login</b>
              <div className="spacer-top">
                <Button
                  disabled={isLoadingQRCodeData}
                  onClick={() => setShowQrLogonCodeWarningDisclaimerModal(true)}
                >
                  Get QR Logon Code
                  <FontAwesomeIcon className="spacer-left" icon={faQrcode} />
                </Button>
              </div>
            </div>
          )}
        </Col>
      </Row>
    </Container>
  );
};

Profile.defaultProps = {
  settings: null,
  selectedCurrency: null,
  isContactlessLoginEnabled: false,
};

Profile.propTypes = {
  user: PropTypes.shape({
    uri: PropTypes.string,
  }).isRequired,
  settings: PropTypes.shape({}),
  onUserSettingsUpdate: PropTypes.func.isRequired,
  onSelectCurrency: PropTypes.func.isRequired,
  onSetUnitsMetric: PropTypes.func.isRequired,
  onSetUnitsImperial: PropTypes.func.isRequired,
  selectedCurrency: PropTypes.string,
  availableConversions: PropTypes.arrayOf(
    PropTypes.shape({
      currency: PropTypes.string.isRequired,
    }),
  ).isRequired,
  units: PropTypes.string.isRequired,
  getContactlessLogonQRCode: PropTypes.func.isRequired,
  isContactlessLoginEnabled: PropTypes.bool,
};

export default Profile;
