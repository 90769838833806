import React, { useState } from 'react';
import PropTypes from 'prop-types';
import {
  Button,
  Modal, Row, Col,
} from 'react-bootstrap';
import ListLineItems from 'rapidfab/components/records/order/line_item_dialog/ListLineItems';
import LineItemDetailsForm from 'rapidfab/components/records/order/line_item_dialog/LineItemDetailsForm';
import ConfirmationModal from 'rapidfab/components/ConfirmationModal';
import Actions from 'rapidfab/actions';
import { API_RESOURCES, PAGINATION_IGNORE_DEFAULT_LIMIT } from 'rapidfab/constants';
import { extractUuid } from 'rapidfab/reducers/makeApiReducers';
import * as Selectors from 'rapidfab/selectors';
import { useDispatch, useSelector } from 'react-redux';
import Alert from 'rapidfab/utils/alert';
import './lineItemDialog.scss';
import { FormattedMessage } from 'rapidfab/i18n';

const NewLineItemsModal = ({ files, updateProcessingFiles, updateSelectedFiles }) => {
  const [selectedFiles, setSelectedFiles] = useState(files);
  const [showLeaveConfirmation, setShowLeaveConfirmation] = useState(false);
  const [formData, setFormData] = useState(null);
  const [isFormValid, setIsFormValid] = useState(false);
  const [lineItemStatuses, setLineItemStatuses] = useState(new Map());

  const [isFormsSubmitted, setIsFormsSubmitted] = useState(false);

  const bureau = useSelector(state => Selectors.getBureau(state));
  const order = useSelector(state => Selectors.getRouteUUIDResource(state));
  const fetching = useSelector(state =>
    state.ui.nautilus[API_RESOURCES.MODEL].get.fetching ||
    state.ui.nautilus[API_RESOURCES.LINE_ITEM].post.fetching,
  );
  const dispatch = useDispatch();

  const handleHide = () => {
    setShowLeaveConfirmation(true);
  };

  const handleCloseConfirmation = () => {
    setShowLeaveConfirmation(false);
    updateSelectedFiles([]);
    updateProcessingFiles([]);
  };

  const getModel = async file => (
    dispatch(Actions.Api.nautilus[API_RESOURCES.MODEL].list(
      { design_file: file.uri },
      { limit: PAGINATION_IGNORE_DEFAULT_LIMIT },
      {},
      {},
      true,
    ))
  );

  const saveLineItem = async file => {
    const modelResponse = await getModel(file);
    const model = modelResponse?.json?.resources[0];

    const {
      autorun,
      baseMaterial,
      notes,
      quantity,
      supportMaterial,
      workflow,
      customFields,
      partName,
      customerId,
    } = formData;

    const payload = {
      autorun,
      bureau: bureau.uri,
      notes,
      priority: order.priority, // currently line items will inherit the priority of the order
      quantity: Number.parseInt(quantity, 10) || 1,
      workflow,
      additive: {
        infill_strategy: null,
        materials: {
          base: baseMaterial,
          support: supportMaterial,
        },
        model: model?.uri || null,
        no_model_upload: !model?.uri,
        support_strategy: null,
      },
      custom_field_values: customFields || [],
      name: partName || null,
      customer_id: customerId || null,
      product: file.product,
      order: order.uri,
    };

    if (!payload.additive.materials.support) delete payload.additive.materials.support;

    lineItemStatuses.set(file.uri, { isPending: true });
    setLineItemStatuses(new Map([...lineItemStatuses]));

    dispatch(Actions.Api.nautilus[API_RESOURCES.LINE_ITEM].post(payload))
      .then(lineItemResponse => {
        const createdLineItemUri = lineItemResponse.headers.location;

        lineItemStatuses.set(file.uri, { isPending: false });
        setLineItemStatuses(new Map([...lineItemStatuses]));
        dispatch(Actions.Api.nautilus[API_RESOURCES.LINE_ITEM].get(extractUuid(createdLineItemUri), true, true));
      })
      .catch(error => {
        Alert.error(
          <FormattedMessage
            id="toaster.error.lineItem.saving"
            defaultMessage="Error saving Line Item details"
          />);
        lineItemStatuses.set(file.uri, { error: `Error saving Line Item details: ${error.message}` });
        setLineItemStatuses(new Map([...lineItemStatuses]));
      });
    return file.uri;
  };

  const handleSaveSeveralFiles = async () => {
    const promises = selectedFiles.map(file => saveLineItem(file));
    const processedFiles = await Promise.all(promises);

    const newProcessingFiles = files.filter(item => !processedFiles.includes(item.uri));
    const newSelectedFiles = selectedFiles.filter(item => !processedFiles.includes(item.uri));

    updateProcessingFiles(newProcessingFiles);
    setSelectedFiles(newSelectedFiles);
    Alert.success(
      <FormattedMessage
        id="toaster.lineItem.saved"
        defaultMessage="Line Item details saved successfully."
      />,
    );
    if (files.length === processedFiles.length) {
      updateSelectedFiles([]);
    }
  };

  const handleSave = () => {
    setIsFormsSubmitted(true);
    if (isFormValid) {
      handleSaveSeveralFiles();
    } else {
      Alert.error(
        <FormattedMessage
          id="toaster.error.requiredFields"
          defaultMessage="Please fill in all required fields"
        />);
    }
  };

  return (
    <Modal size="lg" show onHide={handleHide} className="multiple-upload-modal" backdrop="static">
      <Modal.Header closeButton>
        <Modal.Title>
          <h2>
            Uploaded Files
          </h2>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Row>
          <Col md={3}>
            <ListLineItems
              files={files}
              lineItemStatuses={lineItemStatuses}
              selectedFiles={selectedFiles}
              setSelectedFiles={setSelectedFiles}
            />
          </Col>
          <Col md={9} className="bl-light">
            <LineItemDetailsForm
              order={order}
              files={files}
              updateFormData={setFormData}
              isFormsSubmitted={isFormsSubmitted}
              handleFormValidation={setIsFormValid}
            />
          </Col>
        </Row>
      </Modal.Body>

      <Modal.Footer>
        <Button variant="secondary" onClick={handleHide}>
          Cancel
        </Button>
        <Button
          variant="primary"
          onClick={handleSave}
          disabled={fetching || selectedFiles.length === 0}
        >
          Save {selectedFiles?.length > 0 ? `(${selectedFiles.length})` : ''}
        </Button>
      </Modal.Footer>

      {
        showLeaveConfirmation && (
          <div className="confirmation-wrapper">
            <ConfirmationModal
              handleCancel={() => setShowLeaveConfirmation(false)}
              handleConfirm={handleCloseConfirmation}
              message="Warning: It looks like you have unsaved line items.
                  Would you like to continue?"
            />
          </div>
        )
      }
    </Modal>
  );
};

export default NewLineItemsModal;

NewLineItemsModal.propTypes = {
  files: PropTypes.arrayOf({
    uri: PropTypes.string.isRequired,
  }).isRequired,
  updateProcessingFiles: PropTypes.func.isRequired,
  updateSelectedFiles: PropTypes.func.isRequired,
};
