import React from 'react';
import { FormattedMessage } from 'rapidfab/i18n';
import { PIECE_STATUSES } from 'rapidfab/constants';
import _filter from 'lodash/filter';

const LineItemPiecesHeader = pieces => {
  const complete = _filter(
    pieces,
    { status: PIECE_STATUSES.COMPLETE },
  ).length.toString();

  const incomplete = _filter(
    pieces,
    { status: PIECE_STATUSES.INCOMPLETE },
  ).length.toString();

  const total = (pieces ? pieces.length : 0).toString();
  return (
    <FormattedMessage
      id="record.pieceCompleteCount"
      defaultMessage="Pieces - {complete} / {total} complete - {incomplete} piece(s) incomplete"
      values={{ complete, total, incomplete }}
    />
  );
};

export default LineItemPiecesHeader;
