import React, { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { getPrepTaskRecords, getPrepTasks, getPrepWorkflows, getCustomGroups } from 'rapidfab/selectors';
import withRecordsListHandling from 'rapidfab/containers/hocs/withRecordsListHandling';
import PrepTaskRecords from 'rapidfab/components/plan/PrepTaskRecords';
import { FormLabel } from 'react-bootstrap';
import SelectMultiple from 'rapidfab/components/forms/SelectMultiple';
import { useDispatch, useSelector } from 'react-redux';
import Actions from 'rapidfab/actions';
import _mapValues from 'lodash/mapValues';
import _map from 'lodash/map';
import _find from 'lodash/find';
import { PREP_TASK_RECORD_STATUSES_MAP } from 'rapidfab/mappings';
import { API_RESOURCES, PAGINATION_IGNORE_DEFAULT_LIMIT } from 'rapidfab/constants';
import PropTypes from 'prop-types';

const PrepTaskRecordsContainer = ({
  ...otherProps
}) => {
  // Task UUID is provided in URL when user opens link from Comment Mentions email
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const taskUUID = queryParams.get('task');

  // Prepare options list based on statuses for SelectMultiple
  const availableStatusOptions =
    _mapValues(PREP_TASK_RECORD_STATUSES_MAP, (statusOption, status) => ({
      label: statusOption.defaultMessage,
      value: status,
    }));

  const entitiesFiltersTemplate = {
    edit_group: {
      label: 'Groups',
      getter: getCustomGroups,
      nameProperty: 'name',
    },
    prep_workflow: {
      label: 'Prep Workflow',
      getter: getPrepWorkflows,
      nameProperty: 'name',
    },
    prep_task: {
      label: 'Task Name',
      getter: getPrepTasks,
      nameProperty: 'shortname',
    },
  };

  const entitiesFilters = _mapValues(entitiesFiltersTemplate, entitiesFilter => ({
    label: entitiesFilter.label,
    // eslint-disable-next-line react-hooks/rules-of-hooks
    options: useSelector(state => _map(entitiesFilter.getter(state), item => ({
      // Prepare options list based on entities name property for SelectMultiple
      label: item[entitiesFilter.nameProperty],
      value: item.uri,
    }))),
  }));

  const dispatch = useDispatch();

  const onInitialize = () => {
    dispatch(Actions.Api.nautilus[API_RESOURCES.CUSTOM_GROUP].list({}, { limit: PAGINATION_IGNORE_DEFAULT_LIMIT }));
    dispatch(Actions.Api.nautilus[API_RESOURCES.PREP_WORKFLOW]
      .list({ include_custom_workflows: true }, { limit: PAGINATION_IGNORE_DEFAULT_LIMIT }));
    dispatch(Actions.Api.nautilus[API_RESOURCES.PREP_TASK].list({}, { limit: PAGINATION_IGNORE_DEFAULT_LIMIT }));
  };

  useEffect(() => {
    onInitialize();
  }, []);

  const onSelectChange = (name, selectedItems) => {
    const { onFilterUpdate, filters } = otherProps;

    const { [name]: currentFilter, ...changedFilters } = filters;
    if (selectedItems.length) {
      changedFilters[name] = selectedItems.map(item => item.value);
    }

    onFilterUpdate(changedFilters);
  };

  const { status: statuses = [] } = otherProps.filters;

  // Converting array of string to options list for SelectMultiple to work properly
  const selectedStatuses = statuses.map(status => availableStatusOptions[status]);

  const selectedEntitiesItems = _mapValues(entitiesFilters, (entityFilter, entity) => {
    if (!otherProps.filters[entity]) {
      return [];
    }
    // Converting array of string to options list for SelectMultiple to work properly
    return otherProps.filters[entity].map(item => _find(entityFilter.options, { value: item }));
  });

  return (
    <PrepTaskRecords
      {...otherProps}
      initialPrepTaskRecordUUID={taskUUID}
      extraFilters={[
        <div className="form-inline" style={{ lineHeight: '40px' }}>
          {Object.keys(entitiesFilters).map(entity => (
            <div className="form-group mr15" key={entity}>
              <FormLabel htmlFor="groupsFilter">
                {entitiesFilters[entity].label}:
              </FormLabel>
              <div className="spacer-left form-control inline-picky-wrapper">
                <SelectMultiple
                  title={entitiesFilters[entity].label}
                  data={entitiesFilters[entity].options}
                  labelKey="label"
                  valueKey="value"
                  selectedData={selectedEntitiesItems[entity]}
                  handleOnClose={selected => onSelectChange(entity, selected)}
                />
              </div>
            </div>
          ))}
          <div className="form-group mr15">
            <FormLabel htmlFor="statusFilter">
              Status:
            </FormLabel>
            <div className="spacer-left form-control inline-picky-wrapper">
              <SelectMultiple
                title="Status"
                data={Object.values(availableStatusOptions)}
                labelKey="label"
                valueKey="value"
                selectedData={selectedStatuses}
                handleOnClose={selected => onSelectChange('status', selected)}
              />
            </div>
          </div>
        </div>,
      ]}
    />
  );
};

PrepTaskRecordsContainer.propTypes = {
  queryParams: PropTypes.shape({
    task: PropTypes.string,
  }).isRequired,
};

export default withRecordsListHandling(
  PrepTaskRecordsContainer,
  getPrepTaskRecords,
  ['prep-task-record'],
  {
    useLocationFiltering: false,
  },
);
