import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { API_RESOURCES, FEATURES } from 'rapidfab/constants';
import Recalculation from 'rapidfab/components/admin/Recalculation';
import * as Selectors from 'rapidfab/selectors';
import Actions from 'rapidfab/actions';

const RecalculationContainer = () => {
  const { uuid } = useSelector(Selectors.getBureau);
  const recalculationTrigger = useSelector(state => Selectors.isFeatureEnabled(state, FEATURES.RECALCULATION_TRIGGER));
  const submitting = useSelector(state => state.ui.nautilus[API_RESOURCES.BUREAU].put.fetching);

  const [isEnabled, setIsEnabled] = useState('false');

  useEffect(() => {
    if (String(recalculationTrigger) === isEnabled) {
      return;
    }
    setIsEnabled(String(recalculationTrigger));
  }, [recalculationTrigger]);

  const handleInputChange = event => {
    const { value } = event.target;
    setIsEnabled(value);
  };

  const dispatch = useDispatch();

  const handleSubmit = async event => {
    event.preventDefault();

    const payload = {
      enabled: (isEnabled === 'true'),
    };

    try {
      await dispatch(Actions.Api.nautilus[API_RESOURCES.FEATURE].put(uuid, payload));
      await dispatch(Actions.Api.nautilus[API_RESOURCES.FEATURE].list({}, {}, {}, {}, true));
    } catch {
      //  Add error handling;
    }
  };

  return (
    <Recalculation
      enabled={isEnabled}
      submitting={submitting}
      handleSubmit={handleSubmit}
      handleInputChange={handleInputChange}
    />
  );
};

export default RecalculationContainer;
