import { createSelector } from 'reselect';
import * as baseStateSelectors from 'rapidfab/selectors/baseStateSelectors';
import { getStateResources, getPredicate } from 'rapidfab/selectors/helpers/base';
import _map from 'lodash/map';
import _find from 'lodash/find';
import _filter from 'lodash/filter';

export const getAddiguruAnalyses = createSelector(
  [baseStateSelectors.getStateAddiguruAnalyses, getStateResources],
  (uuids, resources) => _map(uuids, uuid => resources[uuid]),
);

export const getAddiguruAnomalies = createSelector(
  [baseStateSelectors.getStateAddiguruAnomalies, getStateResources],
  (uuids, resources) => _map(uuids, uuid => resources[uuid]),
);

export const getAddiguruInstallations = createSelector(
  [baseStateSelectors.getStateAddiguruInstallations, getStateResources],
  (uuids, resources) => _map(uuids, uuid => resources[uuid]),
);

export const getAddiguruInstallationByUuid = createSelector(
  [getPredicate, getAddiguruInstallations],
  (uuid, installations) => _find(installations, ({ uuid })),
);

export const getAddiguruAnalysisLinkedToRun = createSelector(
  [getPredicate, getAddiguruAnalyses],
  (uri, analyses) => _find(analyses, ({ run: uri })),
);

export const getAddiguruAnalysesByPrinter = createSelector(
  [getPredicate, getAddiguruAnalyses],
  (uri, analyses) => _filter(analyses, ({ printer: uri })),
);

export const getUnlinkedAddiguruAnalyses = createSelector(
  [getAddiguruAnalyses],
  analyses => _filter(analyses, ({ run: null })),
);

export const getAddiguruAnomaliesByAnalysis = createSelector(
  [getPredicate, getAddiguruAnomalies],
  (uri, anomalies) => _filter(anomalies, ({ addiguru_analysis: uri })),
);

export const getUnaddressedAnomaliesByAnalysis = createSelector(
  [getPredicate, getAddiguruAnomalies],
  (uri, anomalies) => _filter(anomalies, ({ addiguru_analysis: uri, is_anomaly: true, status: 'unaddressed' })),
);

export const getUnaddressedDefectsByAnalysis = createSelector(
  [getPredicate, getAddiguruAnomalies],
  (uri, anomalies) => _filter(anomalies, ({ addiguru_analysis: uri, is_defect: true, status: 'unaddressed' })),
);
