import { faTableList } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import MaterialBatchCardDetails from 'rapidfab/components/inventory/MaterialBatchCardDetails';
import PermanentContainerRecordComponent from 'rapidfab/components/inventory/PermanentContainerRecordComponent';
import PermanentContainerRecordForm from 'rapidfab/components/inventory/PermanentContainerRecordForm';
import Loading from 'rapidfab/components/Loading';
import PermanentContainerMetadataColumn
  from 'rapidfab/components/records/material_management/PermanentContainerMetadataColumn';
import Table from 'rapidfab/components/Tables/Table';
import { PERMANENT_CONTAINER_ACTION_TYPES_MAP } from 'rapidfab/mappings';
import React, { memo, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import {
  Card,
  Col,
  Container, Nav, NavItem, NavLink, Row,
} from 'react-bootstrap';
import { FormattedMessage } from 'react-intl';
import BreadcrumbNav from 'rapidfab/components/BreadcrumbNav';
import { getShortUUID } from 'rapidfab/utils/uuidUtils';
import FormattedNumberByLocales from '../FormattedNumberByLocales';

const TRACEABILITY_REPORT_TABS = {
  ACTION_LOG: 'action_log',
};

const PermanentContainerRecord = memo(({
  onSubmit,
  isSubmitting,
  initialFormValues,
  loadSubLocations,
  editMode,
  permanentContainer,
  locationData,
  materialData,
  batch,
  isDebugModeEnabled,
  usersByUri,
  permanentContainerActions,
  handleSetEditMode,
  fetchingData,
  onSelectAllMaterials,
  onDeselectAllMaterials,
  onArchivePermanentContainer,
  showArchiveConfirmation,
  handleAbortArchivePermanentContainer,
  materialLotDetailsMap,
  materialLotsFetching,
}) => {
  const { isEditMode } = editMode;
  const { locations, subLocations } = locationData;
  const { permanentContainerSelectedMaterials,
    materials,
    materialResourcesFetching,
    materialsFetching,

    isAllMaterialsButtonSelected,
    setIsAllMaterialsButtonSelected } = materialData;

  const traceabilityReportRef = useRef();
  const [tab, setTab] = useState(TRACEABILITY_REPORT_TABS.ACTION_LOG);

  const columns = [
    isDebugModeEnabled && ({
      debugMode: true,
      type: 'text',
      uid: 'field.id',
      accessor: 'uuid',
      defaultMessage: 'ID',
      short: true,
    }),
    {
      type: 'translatable',
      uid: 'field.type',
      accessor: 'action_type',
      defaultMessage: 'Type',
      mapping: PERMANENT_CONTAINER_ACTION_TYPES_MAP,
    },
    {
      type: 'custom',
      uid: 'details',
      accessor: 'metadata',
      defaultMessage: 'Details',
      Cell: ({ row: { original } }) => (
        <PermanentContainerMetadataColumn
          locations={locations}
          subLocations={subLocations}
          rowData={original}
          materialLotDetailsMap={materialLotDetailsMap}
          materialLotsFetching={materialLotsFetching}
        />
      ),
    },
    {
      type: 'custom',
      uid: 'field.quantity',
      accessor: 'quantity',
      defaultMessage: 'Quantity',
      Cell: ({ row: { original } }) => (
        <FormattedNumberByLocales number={original.quantity} />
      ),
    },
    {
      type: 'time',
      uid: 'field.date',
      accessor: 'created',
      defaultMessage: 'Date',
    },
    {
      type: 'contact',
      uid: 'field.user',
      accessor: 'user',
      defaultMessage: 'User',
      users: usersByUri,
    },
  ];

  if (fetchingData) {
    return <Loading />;
  }

  return (
    <Container fluid>
      <BreadcrumbNav
        breadcrumbs={['inventory', 'materialStocks', 'permanentContainers', initialFormValues?.uri ? `${initialFormValues?.name} (${getShortUUID(initialFormValues?.uri)})` : 'New']}
      />

      <div className="page-header d-flex align-items-center">
        <h3 className="m-a-0">{initialFormValues?.name ?
          `Permanent Container: ${initialFormValues.name}` : (
            <FormattedMessage
              id="field.permanentContainer.new"
              defaultMessage="New Permanent Container"
            />
          )}
        </h3>
      </div>

      <Row>
        <Col xs={{ span: 12 }} md={{ span: 6 }}>
          {
            isEditMode ? (
              <PermanentContainerRecordForm
                initialFormValues={initialFormValues}
                locationData={{
                  locations,
                  subLocations,
                }}
                materialData={{
                  isAllMaterialsButtonSelected,
                  setIsAllMaterialsButtonSelected,
                  permanentContainerSelectedMaterials,
                  materials,
                }}
                onSubmit={onSubmit}
                isSubmitting={isSubmitting}
                loadSubLocations={loadSubLocations}
                onSelectAllMaterials={onSelectAllMaterials}
                onDeselectAllMaterials={onDeselectAllMaterials}
              />
            ) : (
              <PermanentContainerRecordComponent
                permanentContainer={permanentContainer}
                locationData={locationData}
                handleSetEditMode={handleSetEditMode}
                materials={materials}
                materialsFetching={materialsFetching}
                onArchivePermanentContainer={onArchivePermanentContainer}
                isSubmitting={isSubmitting}
                showArchiveConfirmation={showArchiveConfirmation}
                handleAbortArchivePermanentContainer={handleAbortArchivePermanentContainer}
                batch={batch}
                batchFetching={materialResourcesFetching}
              />
            )
          }
          <MaterialBatchCardDetails batch={batch} batchFetching={materialResourcesFetching} />
        </Col>
        <Col xs={{ span: 12 }} md={{ span: 6 }}>
          <div ref={traceabilityReportRef}>
            <Card bg="dark" className="mb15">
              <Card.Header className="pd-exp inverse">
                <FormattedMessage
                  id="container.traceabilityReport"
                  defaultMessage="Container Traceability Report"
                />
              </Card.Header>
              <div className="card-body-wrapper">
                <Card.Body>
                  <Row className="mb15">
                    <Col xs={{ span: 8 }} lg={{ span: 8 }}>
                      <Nav
                        variant="tabs"
                        activeKey={tab}
                        onSelect={setTab}
                      >
                        <NavItem>
                          <NavLink eventKey={TRACEABILITY_REPORT_TABS.ACTION_LOG}>
                            <FontAwesomeIcon icon={faTableList} className="spacer-right" />
                            <FormattedMessage
                              id="tab.actionLog"
                              defaultMessage="Action Log"
                            />
                          </NavLink>
                        </NavItem>
                      </Nav>
                    </Col>
                    {/* Hidden until the BE is ready */}
                    {/* <Col xs={{ span: 4 }} lg={{ span: 4 }}> */}
                    {/*  <TraceabilityReportExportButton batch={batch} /> */}
                    {/* </Col> */}
                  </Row>
                  <Table
                    data={permanentContainerActions}
                    columns={columns}
                    isFilteringEnabled={false}
                    withDefaultPagination={false}
                    isUpdatedColumnShown={false}
                    initialSortedColumn="created"
                    resetDefaultSortingState
                    isDebugModeEnabled={false}
                  />
                </Card.Body>
              </div>
            </Card>
          </div>
        </Col>
      </Row>
    </Container>
  );
});

PermanentContainerRecord.propTypes = {
  initialFormValues: PropTypes.shape({
    uri: PropTypes.string,
    name: PropTypes.string,
  }).isRequired,
  onSubmit: PropTypes.func.isRequired,
  isSubmitting: PropTypes.bool.isRequired,
  loadSubLocations: PropTypes.func.isRequired,
  editMode: PropTypes.shape({
    isEditMode: PropTypes.bool,
    setIsEditMode: PropTypes.func,
  }).isRequired,
  permanentContainer: PropTypes.shape({
    uuid: PropTypes.string,
    name: PropTypes.string,
    status: PropTypes.string,
    material_restrictions: PropTypes.oneOfType(
      [
        PropTypes.arrayOf(PropTypes.string),
        PropTypes.shape({
          name: PropTypes.string,
        }),
      ]),
    location: PropTypes.string,
    sub_location: PropTypes.string,
  }).isRequired,
  locationData: PropTypes.shape({
    location: PropTypes.shape({
      name: PropTypes.string,
    }),
    subLocation: PropTypes.shape({
      name: PropTypes.string,
    }),
    locations: PropTypes.arrayOf(PropTypes.shape({})),
    subLocations: PropTypes.arrayOf(PropTypes.shape({})),
  }).isRequired,
  materialData: PropTypes.shape({
    permanentContainerSelectedMaterials: PropTypes.arrayOf(PropTypes.shape({})),
    materialResourcesFetching: PropTypes.bool,
    materialsFetching: PropTypes.bool,
    materials: PropTypes.arrayOf(PropTypes.shape({
      name: PropTypes.string,
    })),
    isAllMaterialsButtonSelected: PropTypes.bool,
    setIsAllMaterialsButtonSelected: PropTypes.func,
  }).isRequired,
  batch: PropTypes.shape({
    uuid: PropTypes.string,
    name: PropTypes.string,
    status: PropTypes.string,
    material: PropTypes.string,
    quantity: PropTypes.number,
    created: PropTypes.string,
    updated: PropTypes.string,
    metadata: PropTypes.shape({}),
  }),
  isDebugModeEnabled: PropTypes.bool.isRequired,
  usersByUri: PropTypes.shape({}).isRequired,
  permanentContainerActions: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  handleSetEditMode: PropTypes.func.isRequired,
  row: PropTypes.shape({
    original: PropTypes.shape({ quantity: PropTypes.string.isRequired }),
  }),
  fetchingData: PropTypes.bool.isRequired,
  onSelectAllMaterials: PropTypes.func.isRequired,
  onDeselectAllMaterials: PropTypes.func.isRequired,
  onArchivePermanentContainer: PropTypes.func,
  showArchiveConfirmation: PropTypes.bool,
  handleAbortArchivePermanentContainer: PropTypes.func,
  materialLotDetailsMap: PropTypes.instanceOf(Map).isRequired,
  materialLotsFetching: PropTypes.bool.isRequired,
};

PermanentContainerRecord.defaultProps = {
  batch: null,
  row: null,
  onArchivePermanentContainer: null,
  showArchiveConfirmation: false,
  handleAbortArchivePermanentContainer: null,
};

export default PermanentContainerRecord;
