import React from 'react';
import { Badge, Button, Card, ListGroup, ListGroupItem, Table } from 'react-bootstrap';
import { FormControlSelect } from 'rapidfab/components/formTools';
import { Field, Form } from 'react-final-form';
import { getRouteURI } from 'rapidfab/utils/uriUtils';
import { FormattedMessage } from 'react-intl';
import FormattedLocalizedCost from 'rapidfab/components/FormattedLocalizedCost';
import { ROUTES, DDW_PAYMENT_STATUSES } from 'rapidfab/constants';
import { Link } from 'react-router-dom';
import { extractUuid } from 'rapidfab/utils/uuidUtils';
import _map from 'lodash/map';
import _capitalize from 'lodash/capitalize';
import { getShoppingCartItemsTotalPrice } from 'rapidfab/utils/shoppingCartUtils';
import { getPaymentStatusData } from 'rapidfab/utils/paymentUtils';
import PropTypes from 'prop-types';

const DDWPaymentStatus = ({
  payment,
  materials,
  shoppingCartItems,
  handleUpdatePaymentStatus,
  isSubmittingPayment,
  initialFormValues,
}) => (
  <Form
    onSubmit={formValues => handleUpdatePaymentStatus(formValues.status)}
    initialValues={initialFormValues}
    render={({ handleSubmit, values }) => (
      <Card className="m-b" bg="dark" border="info">
        <ListGroup fill>

          <ListGroupItem key="header">
            <b>Payment Status</b>
            <Badge
              bg={getPaymentStatusData(payment).bg}
              className="badge badge-sm spacer-left"
            >
              {getPaymentStatusData(payment).message}
            </Badge>
          </ListGroupItem>
        </ListGroup>
        <Card.Body>
          <Table
            variant="dark"
            style={{ color: '#cfd2da' }}
            id="costBreakdownTable"
            className="pd-exp inverse"
          >
            <thead>
              <tr>
                <th className="text-left">
                  <FormattedMessage id="field.material" defaultMessage="Material" />
                </th>
                <th className="text-left">
                  <FormattedMessage id="field.quantity" defaultMessage="Quantity" />
                </th>
                <th className="text-left">Price Per Unit</th>
                <th className="text-left">
                  <FormattedMessage id="line_item.quote_price_factor.price" defaultMessage="Price" />
                </th>
              </tr>
            </thead>
            {payment && (
              <tbody>
                {shoppingCartItems.filter(item => item.price_per).map(item => {
                  const cartItemMaterial = materials.find(({ base_template }) =>
                    base_template === item?.material_override);

                  return (
                    <tr>
                      <td>
                        <Link
                          to={getRouteURI(ROUTES.MATERIALS, null, { uuid: extractUuid(cartItemMaterial?.uri) },
                            true)}
                        >
                          {cartItemMaterial?.name || 'N/A'}
                        </Link>
                      </td>
                      <td>{item.quantity}</td>
                      <td>
                        <FormattedLocalizedCost value={item.price_per} />
                      </td>
                      <td>
                        <FormattedLocalizedCost value={item.price_per * item.quantity} />
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            )}
            <thead />
            <tbody>
              <tr>
                <td colSpan={2} />
                <td>
                  <b>
                    <FormattedMessage
                      id="total"
                      defaultMessage="Total"
                    />
                  </b>
                </td>
                <td>
                  <FormattedLocalizedCost
                    value={getShoppingCartItemsTotalPrice(shoppingCartItems.filter(item => item.price_per))}
                  />
                </td>
              </tr>
            </tbody>
          </Table>
        </Card.Body>
        <Card.Footer>
          <div className="d-flex align-items-end">
            <Field
              name="status"
              render={({ input }) => (
                <div id="paymentStatusWrapper" className="d-flex align-items-center w-100">
                  <span className="mr15" id="paymentStatusLabel">
                    <b>Status:</b>
                  </span>
                  <FormControlSelect
                    {...input}
                    onChange={input.onChange}
                    value={input.value}
                    className="spacer-top"
                    id="paymentStatus"
                    disabled={!payment}
                  >
                    {_map(Object.values(DDW_PAYMENT_STATUSES), statusString => (
                      <option id={`paymentStatus.${statusString}`} value={statusString}>
                        {_capitalize(statusString)}
                      </option>
                    ))}
                  </FormControlSelect>
                </div>
              )}
            />
            <Button
              bg="success"
              variant="success"
              className="spacer-left"
              onClick={handleSubmit}
              disabled={isSubmittingPayment
                  || !payment
                  || initialFormValues.status === values.status}
            >
              Update
            </Button>
          </div>
        </Card.Footer>
      </Card>
    )}
  />
);

export default DDWPaymentStatus;

DDWPaymentStatus.propTypes = {
  materials: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  shoppingCartItems: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  modelLibrariesByUri: PropTypes.shape({}).isRequired,
  payment: PropTypes.shape({
    status: PropTypes.string,
  }).isRequired,
  handleUpdatePaymentStatus: PropTypes.func.isRequired,
  isSubmittingPayment: PropTypes.bool.isRequired,
  initialFormValues: PropTypes.shape({
    status: PropTypes.string,
  }).isRequired,
};
